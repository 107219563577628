

import { Badge, Popover, Button, PopoverHeader, PopoverBody } from "reactstrap";

import loading from "../../../../src/images/puff.svg";
import { connect } from "react-redux";
import moment from "moment";
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import filter from "lodash/filter";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../components/toastCommon";
import { HOSTMexxar, WARDS } from "../../../configs/api-config";
import axios from "axios";
import ModalComponent from "../../../components/Modal/modalComponent";
import NewOneField from "../../../components/Modal/ModalBody/Admin/newOneField";
import ConfigureWards from "../../../components/Modal/ModalBody/Admin/configureWards";

function HealthroasterMagicResultsTable(props) {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const MexxarApi = WARDS;
  const MexxarApiWards = WARDS;
  const [showAddRow, setShowAddRow] = useState(false);
  const toggleM = () => {
    setPopoverOpen(false);
  };
  const [rowNameData, setRowNameData] = useState([]);
  const {
    theme,
    groupRows,
    addBooking,
    setBookedReferenceNos,
    bookedReferenceNos,
    magicWardsMatch,
    magicWardsNotMatch,
    selectedClients,
    departmentNames,
    selectedDepartments,
    hospitalId,
  } = props;

 
  const [rows, setRows] = useState(null);
  const { addToast } = useToasts();
  const [rowID, setRowID] = useState(false);
  const [clientName, setClientName] = useState(null);
  const [addedWards, setAddedWards] = useState([]);
  const [wards, setWards] = useState(null);
  // const [showConfigWards, setShowConfigWards] = useState(false);
  const [mode, setMode] = useState("");
  const [missingWards, setMissingWards] = useState([]);
  const [extractedWardNames, setExtractedWardNames] = useState([]);
  const [extractedWards, setExtractedWards] = useState([]);

  // setExtractedWards(extractedWards);
  const MexxarHost = HOSTMexxar;



 

  useEffect(() => {
    const getAllRows = async () => {
      try {
        const response = await axios.get(
          HOSTMexxar + "client-wards/clients/" + selectedClients[0].id
        );
        const responseBody = response.data.body;

        if (responseBody.length > 0) {
          const clientData = responseBody[0];
          setClientName(clientData.clientName);

          const extractedWards = responseBody.map((item) => ({
            name: item.wardName,
            id: item.id,
          }));
          setExtractedWards(extractedWards);
          console.log("Missing wards detected with id:", extractedWards);

          const extractedNames = responseBody.map((item) => item.wardName);
          setExtractedWardNames(extractedNames);
          setWardNames(extractedNames);
          setRows(responseBody);

          const matchingWards = extractedNames.filter((ward) =>
            magicWardsMatch.includes(ward)
          );
          const missingWards = magicWardsMatch.filter(
            (ward) => !extractedNames.includes(ward)
          );

          setMissingWards(missingWards);
        } else {
          setClientName("");
          setWardNames([]);
          setRows([]);
          setExtractedWardNames([]);
          setMissingWards([]);
        }
      } catch (error) {
        console.error("Error fetching rows:", error);
        ToastCommon(true, "error", addToast);
      }
    };

    if (selectedClients && selectedClients.length > 0) {
      getAllRows();
    }
  }, [selectedClients, HOSTMexxar, magicWardsMatch, addToast]);

  
  useEffect(() => {
    getWards();
    
    console.log("This is a magic ward testing =========",magicWardsMatch)
    console.log("This is a non magic ward testing =========",magicWardsNotMatch) 
  }, [selectedClients,magicWardsMatch,magicWardsNotMatch]);

  const getWards = async () => {
    try {
      const response = await axios.get(MexxarApiWards);
      const wardsData = response.data.body;
      console.log("Wards Data:", wardsData);
      setWards(wardsData);
    } catch (err) {
      console.error("Error fetching wards:", err);
    }
  };
  useEffect(() => {}, [extractedWardNames, missingWards]);

  useEffect(() => {
    if (missingWards.length > 0) {
      console.log("Missing wards detected:", missingWards);
    }
  }, [missingWards]);


  const addRow = (data) => {
    let duplicate_items = rows.filter((item) => data.rowName === item.name);
  
    if (duplicate_items.length > 0) {
      ToastCommon(true, "duplicate", addToast);
    } else {
      axios
        .post(MexxarApi, {
          name: data.rowName,
          candidateSpecialities: data.specialities,
        })
        .then((res) => {
          if (res.data.status === "success") {
            const wardId = res.data.body[0].id; 
            
            setAddedWards([...addedWards, data.rowName]);
            setShowAddRow(!showAddRow);
  
            ToastCommon(true, "success", addToast);
  
    
            addNewWard(wardId);
          } else {
            ToastCommon(true, "duplicate", addToast);
          }
        })
        .catch((err) => {
          ToastCommon(true, "error", addToast);
        });
    }
  };
  
  const addNewWard = (wardId,wardName) => {
    const selectedClientId = selectedClients[0]?.id;
  
    if (!selectedClientId) {
      console.error("No selected client found.");
      return;
    }
  
    const requestData = {
      clientId: selectedClientId,
      wardId: wardId,
      email: "admin@mexxar.com",
      fax: "",
      phoneNumbers: [],
    };
  
    axios
      .post(MexxarHost + "client-wards", requestData)
      .then((res) => {
        if (res.data.status === "success") {
  
          setMissingWards(prevState => prevState.filter(ward => ward !== wardName));
          ToastCommon(true, "success", addToast);
       
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        console.error("Error adding new ward:", err);
        ToastCommon(true, "error", addToast);
      });
  };
  
  const handleAddWard = (wardName) => {
    const matchingWard = wards.find((ward) => ward.name === wardName);

    if (matchingWard) {
      const wardId = matchingWard.id;

      console.log(`Adding ward '${wardName}' with ID '${wardId}'`);
    } else {
      console.warn(`No matching ward found for '${wardName}'`);
    }
  };

  const [wardNames, setWardNames] = useState([]);

  
  

  

  return (
    <>
      <table className="table text-color">
        <thead>
          {groupRows !== undefined ? (
            <tr className="d-flex">
              <th
                className={` col-1 ${
                  theme === "bg-dark" ? "groupSearch-th" : "w-groupSearch-th"
                }`}
              >
                Request Id
              </th>
              <th
                className={` col-2 ${
                  theme === "bg-dark" ? "groupSearch-th" : "w-groupSearch-th"
                }`}
              >
                Date
              </th>
              <th
                className={` col-1 ${
                  theme === "bg-dark" ? "groupSearch-th" : "w-groupSearch-th"
                }`}
              >
                Start
              </th>
              <th
                className={` col-1 ${
                  theme === "bg-dark" ? "groupSearch-th" : "w-groupSearch-th"
                }`}
              >
                End
              </th>

              <th
                className={` col-2 ${
                  theme === "bg-dark" ? "groupSearch-th" : "w-groupSearch-th"
                }`}
              >
                Ward
              </th>
              <th
                className={` col-1 ${
                  theme === "bg-dark" ? "groupSearch-th" : "w-groupSearch-th"
                }`}
              >
                Request Grade
              </th>
              <th
                className={` col-1 ${
                  theme === "bg-dark" ? "groupSearch-th" : "w-groupSearch-th"
                }`}
              >
                Agency
              </th>
              <th
                className={` col-1 ${
                  theme === "bg-dark" ? "groupSearch-th" : "w-groupSearch-th"
                }`}
              >
                Staff
              </th>

              <th
                className={` col-2 ${
                  theme === "bg-dark" ? "groupSearch-th" : "w-groupSearch-th"
                }`}
              >
                Candidates
              </th>
            </tr>
          ) : (
            <div></div>
          )}
        </thead>
        <tbody>
          {groupRows != undefined ? (
            groupRows.map((groupRow, id) => (
              <tr className="d-flex  " key={id}>
                <td
                  className={`col-1 ${
                    theme === "bg-dark" ? "groupSearch-td" : "w-groupSearch-td"
                  }`}
                >
                  {groupRow.requestId}
                </td>
                <td
                  className={`col-2 ${
                    theme === "bg-dark" ? "groupSearch-td" : "w-groupSearch-td"
                  }`}
                >
                  {moment(groupRow.shiftDate).format("MM/DD/YYYY")}
                </td>

                <td
                  className={`col-1 ${
                    theme === "bg-dark" ? "groupSearch-td" : "w-groupSearch-td"
                  }`}
                >
                  {groupRow.startTime}
                </td>
                <td
                  className={`col-1 ${
                    theme === "bg-dark" ? "groupSearch-td" : "w-groupSearch-td"
                  }`}
                >
                  {groupRow.endTime}
                </td>

                <td
                  className={`col-2 ${
                    theme === "bg-dark" ? "groupSearch-td" : "w-groupSearch-td"
                  }`}
                  style={{
                    color: magicWardsNotMatch.includes(groupRow.ward)
                      ? "danger"
                      : "inherit",
                  }}
                >
                  {groupRow.ward}

                  {missingWards.includes(groupRow.ward) && (
                    <Badge
                      color="danger"
                      size="sm"
                      className="ml-2 pointer"
                      // onClick={() => {
                      //   const matchingWard = wards.find(
                      //     (ward) => ward.name === groupRow.ward
                      //   );
                      //   if (matchingWard) {
                      //     const wardId = matchingWard.id;
                      //     addNewWard(wardId);
                      //   } else {
                      //     console.warn(
                      //       `No matching ward found for '${groupRow.ward}'`
                      //     );
                      //   }
                      // }}
                      onClick={() => {
                        const matchingWard = wards.find((ward) => ward.name === groupRow.ward);
                        if (matchingWard) {
                          const wardId = matchingWard.id;
                          addNewWard(wardId, groupRow.ward); 
                        } else {
                          console.warn(`No matching ward found for '${groupRow.ward}'`);
                        }
                      }}
                    >
                      Add Ward
                    </Badge>
                  )}

                  {!magicWardsMatch.includes(groupRow.ward) &&
                    !addedWards.includes(groupRow.ward) && (
                      <Badge
                        color="danger"
                        size="sm"
                        className="ml-2 pointer"
                        onClick={() => {
                          setShowAddRow(true);
                          setRowID(groupRow.id);
                          setRowNameData(groupRow.ward);
                        }}
                      >
                        Add Ward
                      </Badge>
                    )}
                </td>

                <td
                  className={`col-1 ${
                    theme === "bg-dark" ? "groupSearch-td" : "w-groupSearch-td"
                  }`}
                >
                  {groupRow.requestGrade}
                </td>
                <td
                  className={`col-1 ${
                    theme === "bg-dark" ? "groupSearch-td" : "w-groupSearch-td"
                  }`}
                >
                  {/* {groupRow.Agency} */}
                </td>
                <td
                  className={`col-1 ${
                    theme === "bg-dark" ? "groupSearch-td" : "w-groupSearch-td"
                  }`}
                >
                  {groupRow.Staff}
                </td>

                <td
                  className={`col-2 d-flex ${
                    theme === "bg-dark" ? "groupSearch-td" : "w-groupSearch-td"
                  }`}
                  style={{ width: "100px", overflowX: "auto" }}
                >
                  {groupRow.Staff == undefined || groupRow.Staff == "" ? (
                    groupRow.candidates.map((item, id) => (
                      <>
                        <Badge
                          id={item.candidateId + groupRow.requestId}
                          key={id}
                          className="m-1 btn btn-raised"
                          style={{
                            backgroundColor:
                              item?.associatedAvailability?.bookingCallStatus ==
                              "CALL_ME_BEFORE_BOOKING"
                                ? "#f4c414"
                                : "#28a745",
                          }}
                          // onClick={() => {
                          //   addBooking(groupRow, item);
                          //   setBookedReferenceNos([
                          //     ...bookedReferenceNos,
                          //     {
                          //       ref: groupRow.requestId,
                          //       candidate: item.firstName + " " + item.lastName,
                          //     },
                          //   ]);
                          // }
                          onClick={() => {
                            if (
                              item?.associatedAvailability
                                ?.bookingCallStatus !== "CALL_ME_BEFORE_BOOKING"
                            ) {
                              addBooking(groupRow, item);
                              setBookedReferenceNos([
                                ...bookedReferenceNos,
                                {
                                  ref: groupRow.requestId,
                                  candidate:
                                    item.firstName + " " + item.lastName,
                                },
                              ]);
                            }
                          }}
                        >
                          {item.firstName}&nbsp;{item.lastName}
                          {item?.associatedAvailability?.bookingCallStatus ==
                          "CALL_ME_BEFORE_BOOKING" ? (
                            <FontAwesomeIcon
                              className="text-success mx-2"
                              icon={faPhone}
                            ></FontAwesomeIcon>
                          ) : null}
                        </Badge>
                      </>
                    ))
                  ) : (
                    <Badge key={id} className="m-1 bg-success">
                      Booked
                    </Badge>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <div>
              {/* Loading animation */}
              <div className="d-flex justify-content-center justify-items-center">
                <div>
                  <img src={loading} style={{ height: 100 }}></img>
                </div>
              </div>
            </div>
          )}
        </tbody>
      </table>

      <ModalComponent
        show={showAddRow}
        header="Add New Ward "
        closeModal={() => setShowAddRow(false)}
      >
        <NewOneField
          mode="Add"
          modalType="Ward"
          fieldData={rowNameData}
          closeModal={() => setShowAddRow(false)}
          onCreate={(data) => addRow(data)}
          specialityShow={true}
        />
      </ModalComponent>
    </>
  );
}

function mapStateToProps(state) {
  return {
    theme: state.theme.theme,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(HealthroasterMagicResultsTable);