import React from 'react';
import './button.style.css';
import PropTypes from 'prop-types';

const Button = (props) =>{

    const {text, disabled, outline, color="primary", round, onClick, styles, size="" } = props;

    return (
        <>
            <button disabled={disabled} className={`btn ${ round?'btn-rounded':'' } ${size} btn-${outline?'outline-':''}${color}`} onClick={onClick} style={styles}>{text}</button>
        </>
    );
}

Button.protoTypes = {
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    outline: PropTypes.bool,
    color: PropTypes.oneOf(['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'white', 'light', 'dark']),
    round: PropTypes.bool,
    onClick: PropTypes.func,
    styles: PropTypes.object,
    size: PropTypes.oneOf(['btn-sm', 'btn-md'])
};

export default Button;