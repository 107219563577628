import React, { useEffect, useState } from "react";
// import './App.css';
// import './style.js';
import "./style.css";
import axios from "axios";
import anime from "animejs";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import whiteLableCompanyLogo from "../../../src/logo/candyHealthCare.jpg";
// import whiteLableCompanyLogo from "../../../src/logo/ramsyLogo.png";
// import whiteLableCompanyLogo from "../../../src/logo/mexxar.png";
import logo1 from "../../../src/logo/mexxar.png"; //<---Dont change this when white labeling
import { REGISTER_CANDIDATE } from "../../configs/api-config";

function WelcomePage(props) {
  const MexxarApiCandidate = REGISTER_CANDIDATE;

  const [successStatus, setSuccessStatus] = useState(false);
  const [error, setError] = useState({ message: "", bool: false });

  const query = new URLSearchParams(props.location.search);
  const token = query.get("key");

  const getProfileActiveStatus = () => {
    axios
      .get(MexxarApiCandidate + "/check-profile?key=" + token)
      .then((res) => {
        if (res.data.status === "success") {
          setSuccessStatus(true);
        } else {
          setError({ message: res?.data?.message, bool: false });
          setSuccessStatus(false);
        }
      })
      .catch((err) => {});
  };
  // console.log("password sent successfully.....", successStatus);
  useEffect(() => {
    getProfileActiveStatus();

    anime({
      targets: ".mexxar",
      keyframes: [
        {
          rotateY: 380,
          duration: 5000,
        },
        {
          translateY: 600,
          duration: 10000,
          scale: 0.4,
          duration: 4000,
          easing: "easeInOutExpo",
        },
      ],
      duration: 500,
    });

    anime.timeline({
      targets: ".tfs",
      keyframes: [
        {
          rotateY: 360,
          duration: 5000,
        },
      ],
    });
  }, []);

  return (
    <div style={{ flex: 1 }}>
      <div
        className="mexxar"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <img src={logo1} style={{ height: 320 }}></img>
      </div>

      <div
        className="tfs"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <img src={whiteLableCompanyLogo} style={{ height: 100 }}></img>
      </div>

      <div
        className="text2"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          margin: 15,
        }}
      >
        <div
          className="loader"
          style={{
            backgroundColor: "red",
            marginTop: "-5px",
            marginLeft: "10px",
          }}
        >
          <div className="dot d1"></div>
          <div className="dot d2"></div>
          <div className="dot d3"></div>
          <div className="dot d4"></div>
          <div className="dot d5"></div>
        </div>

        <div style={{ marginLeft: 40 }}>
          <h1>Please wait until we create your Account</h1>
        </div>
      </div>

      {successStatus != true ? (
        <div
          className="success"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            margin: 15,
          }}
        >
          <h5 className="poptext">
            <br></br>Ooops.. Something went wrong!<br></br>Please try again
            later. if this happens more than once please contact your
            consultant. Error: {error?.bool && error?.message}
          </h5>
        </div>
      ) : (
        <div
          className="success"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            margin: 16,
          }}
        >
          <h5 className="poptext">
            <br></br>
            <div style={{ marginTop: "1rem" }}>
              Congratulations! Your account has been successfully created.
              <br></br>
              Please check your email to continue.
            </div>
          </h5>

          <Link to="/auth/login" style={{ marginTop: "2rem" }}>
            <Button>Continue</Button>
          </Link>
        </div>
      )}

      <div className="copyright text-center ">
        <p>Copyright {new Date().getFullYear()} @ Mexxar</p>
        <p>Powered by Mexxar</p>
      </div>
    </div>
  );
}

export default WelcomePage;
