import React, { useState, useEffect } from "react";
import {
  REGISTER_CANDIDATE,
  SEND_MESSAGE,
  CHAT_ROOT,
} from "../../../configs/api-config";
import axios from "axios";
import { Input } from "reactstrap";
import { connect } from "react-redux";
import loading from "../../../../src/images/puff.svg";
import { chatTabAction } from "../../../redux/actions/chatDbPostAction";
import { forEach, filter } from "lodash";
import Avatar from "react-avatar";
import authReducer from "../../../redux/reducers/authReducer";
//import Avatar from "boring-avatars";

const CreateChat = (props) => {
  const { userId } = props;
  const MexxarApi3 = CHAT_ROOT;
  const MexxarApi2 = SEND_MESSAGE;
  const MexxarApi = REGISTER_CANDIDATE;

  const [rows, setRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const getAllRows = () => {
    axios
      .get(MexxarApi3 + "/chat-users?query=&offset=100&page=0")
      .then((res) => {
        setRows(res.data.body);
      })
      .catch((err) => {
        // console.log(err)
        //ToastCommon(true, "error", addToast);
      });
  };

  const createNewChat = (userId) => {
    let messageData = new FormData();
    messageData.append("to", userId);
    messageData.append("messageType", "TEXT");
    messageData.append("message", "Chat Started");

    axios.post(MexxarApi2, messageData).then((res) => {
      props.chatTabAction("Chats");
    });
  };

  const filterByName = (name, dataSet) => {
    let filtered = dataSet;
    if (name !== "") {
      filtered = dataSet.filter((item) => {
        let fullName = item.firstName + item.middleName + item.lastName;
        let firstLastName = item.firstName + item.lastName;

        let searchableString = `${fullName} ${firstLastName} ${item.firstName} ${item.lastName}  `;
        let p = searchableString;

        return p.toLowerCase().includes(name.toLowerCase());
      });
    }
    return filtered;
  };
  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
    // axios
    // .get(MexxarApi3, "/chat-users?query=", e,"&offset=10&page=0")
    // .then((res) => {
    // console.log(res.data.body)
    // setRows(res.data.body);
    // setInitialRows(res.data.body);
    // forEach(res.data.body, function (item) {
    //   ids_Workspace.push({
    //     candidateId: item.candidateId,
    //     primaryPhone: item.primaryPhone,
    //   });
    // });
    //})
    // filterByName(e.target.value, props.headhunter.todayCandidatesList);
  };

  useEffect(() => {
    getAllRows();
  }, []);

  return (
    <div>
      <div className="navbar">
        <div className="input-group flex bg-light rounded">
          <Input
            onChange={(e) => handleSearchOnChange(e)}
            type="text"
            className="form-control no-border no-shadow no-bg typeahead tt-input"
            placeholder="Search Contacts"
            autoComplete="off"
            spellCheck="false"
            dir="auto"
            style={{
              position: "relative",
              verticalAlign: "top",
              backgroundColor: "transparent",
              // textTransform:"lowercase",
            }}
          />
          <span className="input-group-append">
            <button className="btn no-bg no-shadow" type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-search text-fade"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </button>
          </span>
        </div>
      </div>

      <div style={{ height: "252px", overflowY: "auto" }}>
        {rows.length == 0 ? (
          <div className="d-flex justify-content-center align-items-center">
            <div>
              <img src={loading} style={{ height: 100 }}></img>
            </div>
          </div>
        ) : (
          filterByName(searchQuery, rows).map(
            (chatmsg, index) =>
              userId !== chatmsg.id && (
                <div key={index}>
                  <div
                    className="list-item chatBoxes  btn-wave pointer"
                    onClick={() => createNewChat(chatmsg.id)}
                  >
                    <div>
                      {/* <span className="w-40 avatar gd-primary"></span> */}
                      <Avatar
                        // color={Avatar.getRandomColor(['red', 'green', 'blue'])}
                        name={chatmsg.firstName}
                        size="40"
                        round={true}
                        textSizeRatio={1.75}
                      />
                    </div>
                    <div className="flex">
                      <div className="item-author">
                        {chatmsg.firstName} {chatmsg.lastName}
                      </div>
                      <div className="item-except"></div>
                    </div>
                  </div>
                  <hr
                    style={{
                      borderTop: "1px solid #5e676f",
                      margin: "0px 10px 0px 80px",
                    }}
                  />
                </div>
              )
          )
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.auth.item.id,
  };
}

export default connect(mapStateToProps, { chatTabAction })(CreateChat);
//CreateChat//
