import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import filter from "lodash/filter";
import { useToasts } from "react-toast-notifications";
import loading from "../../../../src/images/puff.svg";
import ToastCommon from "../../../components/toastCommon";
import { Add_New_DOCUMENT, HOSTMexxar } from "../../../configs/api-config";
import Delete from "../../../components/Modal/ModalBody/delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import NewOneField from "../../../components/Modal/ModalBody/Admin/newOneField";
import UploadPackDocuments from "./uploadappdoc";

const AppPackDocuments = () => {
  const MexxarApi = Add_New_DOCUMENT;

  const { addToast } = useToasts();

  const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showAddRow, setShowAddRow] = useState(false);
  const [showUploadRow, setShowUploadRow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);

  const getAllRows = () => {
    axios
      .get(HOSTMexxar + "documents/app-pack-candy")
      .then((res) => {
        setRows(res.data.body);
        console.log("res.data.body", res.data.body);
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const deleteRow = (fileName) => {
    console.log("fileName", fileName);
    const encodedFileName = encodeURIComponent(fileName);
    axios
      .delete(
        `${HOSTMexxar}/documents/app-pack-candy?fileName=${encodedFileName}`
      )
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "delete", addToast);
          getAllRows();
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        console.error("Delete error:", err);
        ToastCommon(true, "error", addToast);
      });
  };

  useEffect(() => {
    getAllRows();
  }, []);

  return (
    <DashboardLayout
      title="App Pack Documents"
      subTitle="Manage App Pack Documents "
      target=""
    >
      <div className="d-flex justify-content-between p-2 ml-3">
        <div></div>
        <div>
          {" "}
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddRow(true);
            }}
          >
            Upload Document
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          {rows.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <img src={loading} style={{ height: 100 }}></img>
              </div>
            </div>
          ) : (
            <div>
              <table
                id="datatable"
                className="table table-theme table-row v-middle dataTable no-footer"
                role="grid"
                ariaDescribedby="datatable_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      ariaSort="ascending"
                      ariaLabel="ID: activate to sort column descending"
                      style={{ width: "26px" }}
                    ></th>
                    <th
                      className="sorting_asc"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      ariaSort="ascending"
                      ariaLabel="ID: activate to sort column descending"
                      style={{ width: "26px" }}
                    >
                      <span className="text-muted">ID</span>
                    </th>
                    <th
                      className="sorting_disabled"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      ariaLabel="Owner: activate to sort column ascending"
                    >
                      <span className="text-muted">Document Name </span>
                    </th>
                    <th
                      className="sorting_disabled d-flex justify-content-end mr-3"
                      rowSpan="1"
                      colSpan="1"
                      ariaLabel="Tasks"
                    >
                      <span className="text-muted d-none d-sm-block mr-3">
                        Actions
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows.length > 0
                    ? rows.map((row, i) => {
                        return (
                          <tr className="odd" key={i} role="row">
                            <td
                              style={{ minWidth: "30px", textAlign: "center" }}
                              className="sorting_1"
                            ></td>
                            <td
                              style={{ minWidth: "30px", textAlign: "center" }}
                              className="sorting_1"
                            >
                              <small className="text-muted">{i + 1}</small>
                            </td>
                            <td className="">{row}</td>

                            <td className="d-flex justify-content-end">
                              <FontAwesomeIcon
                              
                                 icon={faTrashAlt}
                                onClick={() => {
                                  setRowID(row);
                                  setShowDeleteRow(true);
                                }}
                                className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave pointer"
                                size="1x"
                              />
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      <ModalComponent
        show={showAddRow}
        header=" "
        closeModal={() => setShowAddRow(false)}
      >
        <UploadPackDocuments
          mode="Add"
          modalType="Document"
          closeModal={() => setShowAddRow(false)}
          getAllRows={getAllRows}
        />
      </ModalComponent>

      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteRow(rowID);
          setShowDeleteRow(false);
        }}
        closeModal={() => setShowDeleteRow(false)}
      />
    </DashboardLayout>
  );
};

export default AppPackDocuments;
