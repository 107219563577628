import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import axios from "axios";
import draftToHtml from "draftjs-to-html";
import { useMsal } from "@azure/msal-react";
import { Editor } from "react-draft-wysiwyg";
import { loginRequest } from "./../../authConfig";
import { EditorState, convertToRaw } from "draft-js";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../components/toastCommon";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { forwardEmail, replyEmail, deleteEmail } from "./graph";
import ThreadDetails from "./ThreadDetails";
import DOMPurify from "dompurify";
import EmailComposeModal from "./EmailComposeModal";
import { connect } from "react-redux";
import EmailFormOutlook from "./EmailFormOutlook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronLeft,
  faDownload,
  faFlag,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

const OutlookBodySection = ({ handleBackClick, item, user, incomingPage }) => {
  const { addToast } = useToasts();
  const { instance, accounts } = useMsal();

  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [bcc, setBcc] = useState("");
  const [body, setBody] = useState("");
  const [file, setFile] = useState(null);
  const [subject, setSubject] = useState("");
  const [showCc, setShowCc] = useState(false);
  const [showBcc, setShowBcc] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [deletedItems, setDeletedItems] = useState(null);
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [showForwardForm, setShowForwardForm] = useState(false);
  const [showReplyALLForm, setShowReplyALLForm] = useState(false);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const handleReply = () => {
    setShowReplyForm(true);
  };
  const handleForward = () => {
    setShowForwardForm(true);
  };

  const handleCcClick = () => {
    setShowCc(!showCc);
  };

  const handleBccClick = () => {
    setShowBcc(!showBcc);
  };

  const [threads, setThreads] = useState([]);

  useEffect(() => {
    RequestConversationMessageGet(item);
    function fetchAttachments() {
      // const attachments = RequestEmailGetAddAttachment(item);
      setAttachments(attachments);
    }
    fetchAttachments();
  }, [item]);

  function readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
    });
  }

  function RequestEmailItemDelete() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        axios
          .post(
            "https://graph.microsoft.com/v1.0/me/messages/" +
              item?.id +
              "/move",
            {
              destinationId: "deleteditems",
            },

            {
              headers: {
                Authorization: "Bearer " + response?.accessToken,
              },
            }
          )
          .then((response) => {
            deleteEmail(response?.accessToken, deletedItems);
          });
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  }

  function RequestEmailFoward() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        const attachments = [];
        if (file) {
          readFileAsBase64(file).then((base64Data) => {
            attachments.push({
              "@odata.type": "#microsoft.graph.fileAttachment",
              name: file.name,
              contentType: file.type,
              contentBytes: base64Data,
            });

            axios
              .post(
                "https://graph.microsoft.com/v1.0/me/messages/" +
                  item?.id +
                  "/forward",
                {
                  message: {
                    toRecipients: [
                      {
                        emailAddress: {
                          address: to,
                        },
                      },
                    ],
                    attachments: attachments,
                  },
                  comment: body,
                },

                {
                  headers: {
                    Authorization: "Bearer " + response?.accessToken,
                  },
                }
              )
              .then((response) => {
                forwardEmail(
                  response?.accessToken,
                  to,
                  cc,
                  bcc,
                  subject,
                  body,
                  file
                );
              })
              .catch((error) => {
                ToastCommon(true, "error", addToast);
              });
          });
        } else {
          axios
            .post(
              "https://graph.microsoft.com/v1.0/me/messages/" +
                item?.id +
                "/forward",
              {
                message: {
                  toRecipients: [
                    {
                      emailAddress: {
                        address: to,
                      },
                    },
                  ],
                },
                comment: body,
              },

              {
                headers: {
                  Authorization: "Bearer " + response?.accessToken,
                },
              }
            )
            .then((response) => {
              forwardEmail(
                response?.accessToken,
                to,
                cc,
                bcc,
                subject,
                body,
                file
              );
            })
            .catch((error) => {
              ToastCommon(true, "error", addToast);
            });
        }
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  }

  function RequestEmailReply() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        const attachments = [];
        if (file) {
          readFileAsBase64(file).then((base64Data) => {
            attachments.push({
              "@odata.type": "#microsoft.graph.fileAttachment",
              name: file.name,
              contentType: file.type,
              contentBytes: base64Data,
            });

            axios
              .post(
                "https://graph.microsoft.com/v1.0/me/messages/" +
                  item?.id +
                  "/reply",
                {
                  message: {
                    toRecipients: [
                      {
                        emailAddress: {
                          address: to,
                        },
                      },
                    ],
                    attachments: attachments,
                  },
                  comment: body,
                },

                {
                  headers: {
                    Authorization: "Bearer " + response?.accessToken,
                  },
                }
              )
              .then((response) => {
                replyEmail(
                  response?.accessToken,
                  to,
                  cc,
                  bcc,
                  subject,
                  body,
                  file
                );
              })
              .catch((error) => {
                ToastCommon(true, "error", addToast);
              });
          });
        } else {
          axios
            .post(
              "https://graph.microsoft.com/v1.0/me/messages/" +
                item?.id +
                "/reply",
              {
                message: {
                  toRecipients: [
                    {
                      emailAddress: {
                        address: to,
                      },
                    },
                  ],
                },
                comment: body,
              },

              {
                headers: {
                  Authorization: "Bearer " + response?.accessToken,
                },
              }
            )
            .then((response) => {
              replyEmail(
                response?.accessToken,
                to,
                cc,
                bcc,
                subject,
                body,
                file
              );
            })
            .catch((error) => {
              ToastCommon(true, "error", addToast);
            });
        }
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  }

  function RequestEmailDelete() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        axios
          .delete("https://graph.microsoft.com/v1.0/me/messages/ " + item?.id, {
            headers: {
              Authorization: "Bearer " + response?.accessToken,
            },
          })
          .then((response) => {
            ToastCommon(true, "delete", addToast);
          })
          .catch((error) => {
            ToastCommon(true, "error", addToast);
          });
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  }

  function RequestConversationMessageGet(item) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        axios
          .get(
            `https://graph.microsoft.com/v1.0/me/messages?$filter=conversationId eq '${item?.conversationId}'`,
            {
              headers: {
                Authorization: "Bearer " + response?.accessToken,
              },
            }
          )
          .then((response) => {
            let array = response?.data?.value;
            array.pop();

            setThreads(array);

            response.data.value.forEach((thread, index) => {
              if (index === 0) {
                return;
              }

              console.log("Thread", index, ":", thread);
              console.log("Thread", index, "subject:", thread.subject);
              console.log(
                "Thread",
                index,
                "sender:",
                thread.sender.emailAddress.name
              );
            });
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  // const sanitizedData = () => ({
  //   __html: DOMPurify.sanitize(item.body.content),
  // });

  const date = new Date(item?.sentDateTime);
  const formattedDate = date.toLocaleString("en-GB", {
    weekday: "short",
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  // ....... RequestDraftEmailSend.....//

  function RequestDraftEmailSend() {
    const url = `https://graph.microsoft.com/v1.0/me/messages/${item?.id}/send`;

    return instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        const attachments = [];
        if (file) {
          readFileAsBase64(file).then((base64Data) => {
            attachments.push({
              "@odata.type": "#microsoft.graph.fileAttachment",
              name: file.name,
              contentType: file.type,
              contentBytes: base64Data,
              isInline: false,
            });

            const options = {
              method: "POST",
              headers: {
                Authorization: `Bearer ${response.accessToken}`,
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                message: {
                  body: {
                    content: body,
                    contentType: "Text",
                  },
                  attachments: attachments,
                },
                saveToSentItems: true,
                hasAttachments: true,
              }),
            };

            return fetch(url, options)
              .then((response) => {
                if (!response.ok) {
                  throw new Error(
                    `Failed to reply to email: ${response.status} ${response.statusText}`
                  );
                }
                return response.json();
              })
              .then((data) => {
                console.log(`Reply sent with ID ${data.id}`);
                console.log("item id:", item?.id);
                console.log("response data:", data);
              })
              .catch((error) => {
                console.error(error);
              });
          });
        } else {
          const options = {
            method: "POST",
            headers: {
              Authorization: `Bearer ${response.accessToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              message: {
                body: {
                  content: body,
                  contentType: "Text",
                },
              },
              saveToSentItems: true,
              hasAttachments: false,
            }),
          };

          return fetch(url, options)
            .then((response) => {
              if (!response.ok) {
                throw new Error(
                  `Failed to reply to email: ${response.status} ${response.statusText}`
                );
              }
              return response.json();
            })
            .then((data) => {
              console.log(`Reply sent with ID ${data.id}`);
              console.log("item id:", item?.id);
              console.log("response data:", data);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  // ....... RequestDraftEmailForward.....//

  function RequestDraftEmailForward() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        console.log("Token acquired successfully Reply Email");
        console.log("API response:", item?.id);

        axios
          .post(
            "https://graph.microsoft.com/v1.0/me/messages/" +
              item?.id +
              "/createForward",
            {
              message: {
                toRecipients: [
                  {
                    emailAddress: {
                      address: to,
                    },
                  },
                ],
              },

              comment: body,
            },

            {
              headers: {
                Authorization: "Bearer " + response.accessToken,
              },
            }
          )
          .then((response) => {
            const body = item?.body;
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  function RequestEmailItemMoveFolder(destinationFolder) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        let folderId;
        switch (destinationFolder) {
          case "archive":
            folderId = "archive";
            break;
          case "trash":
            folderId = "deleteditems";
            break;
          case "inbox":
            folderId = "inbox";
            break;
          case "junkemail":
            folderId = "junkemail";
            break;
          default:
            folderId = "deleteditems";
            break;
        }
        axios
          .post(
            "https://graph.microsoft.com/v1.0/me/messages/" +
              item?.id +
              "/move",
            {
              destinationId: folderId,
            },
            {
              headers: {
                Authorization: "Bearer " + response?.accessToken,
              },
            }
          )
          .then((response) => {
            deleteEmail(response?.accessToken, folderId);
          });
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  }
  // ....... RequestDraftReplyAll.....//

  function RequestDraftReplyAll() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        console.log("Token acquired successfully Reply Email");
        console.log("API response:", item?.id);

        axios
          .post(
            "https://graph.microsoft.com/v1.0/me/messages/" +
              item?.id +
              "/createReplyAll",
            {
              message: {
                toRecipients: [
                  {
                    emailAddress: {
                      address: to,
                    },
                  },
                ],
              },

              comment: body,
            },

            {
              headers: {
                Authorization: "Bearer " + response.accessToken,
              },
            }
          )
          .then((response) => {
            console.log(
              "API response: createReplyAll response------>",
              response.data.id
            );
            const body = item?.body;
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  function RequestEmailItemUpdate(item, flagIconId) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        const newFlagStatus =
          item?.flag.flagStatus === "flagged" ? "notFlagged" : "flagged";

        axios
          .patch(
            `https://graph.microsoft.com/v1.0/me/messages/${item?.id}`,
            {
              isRead: true,
              flag: {
                flagStatus: newFlagStatus,
              },
            },
            {
              headers: {
                Authorization: "Bearer " + response?.accessToken,
              },
            }
          )
          .then((response) => {
            const flagIcon = document.getElementById(flagIconId);
            if (flagIcon) {
              flagIcon.style.color =
                newFlagStatus === "flagged" ? "#E74C3C" : "#d1d1d1";
            }
            item.flag.flagStatus = newFlagStatus;
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  const [flagStatus, setFlagStatus] = useState(item?.flag?.flagStatus);

  useEffect(() => {
    const flagIcon = document.getElementById("flag-icon");

    if (flagIcon) {
      flagIcon.style.color = flagStatus === "flagged" ? "#E74C3C" : "#d1d1d1";
    }
  }, [flagStatus]);

  const handleFlagClick = () => {
    const newFlagStatus = flagStatus === "flagged" ? "notFlagged" : "flagged";
    setFlagStatus(newFlagStatus);

    RequestEmailItemUpdate(item);
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const sanitize = (html) => {
    const rejectedColorRegex = /color=["']?#?000000["']?/g;

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = DOMPurify.sanitize(html, {
      FORBID_ATTR: ["style"],
      ADD_ATTR: ["style"],
      FORBID_TAGS: ["style"],
      FORBID_STYLES: ["color"],
      ALLOWED_STYLE: {
        "text-align": [/^left$/, /^right$/, /^center$/],
      },
    });

    const sanitizedHTML = tempDiv.innerHTML.replace(rejectedColorRegex, "");
    return sanitizedHTML;
  };

  const sanitizedData = () => ({
    __html: sanitize(item?.body.content),
  });

  function base64toBlob(base64String, contentType) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  function base64toBlob(base64String, contentType) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  return (
    <div className="d-flex flex emailBody" id="content-body">
      <div
        className="d-flex flex-column flex"
        style={{ height: "auto", width: "50vh" }}
      >
        <div className="  m-2 card  ">
          {/* {incomingPage === "EmailPage" ? ( */}
          <>
            <div className="px-5 d-flex align-items-end justify-content-end">
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="icon pointer mx-2 btn-raised shadow-none "
                size="lg"
                title="Archive"
                onClick={handleBackClick}
              />

              <FontAwesomeIcon
                icon={faDownload}
                className="icon pointer mx-2  btn-raised shadow-none"
                size="lg"
                title="Archive"
                onClick={() => RequestEmailItemMoveFolder("archive")}
              />

              <FontAwesomeIcon
                icon={faFlag}
                className="icon pointer mx-2  btn-raised shadow-none"
                size="lg"
                style={{
                  color: flagStatus === "flagged" ? "#E74C3C" : "#d1d1d1",
                }}
                title="Flag"
                onClick={handleFlagClick}
              />

              <FontAwesomeIcon
                icon={faTrash}
                className="icon pointer mx-2  btn-raised shadow-none"
                size="lg"
                title="Trash"
                onClick={() => RequestEmailItemMoveFolder("trash")}
              />

              <FontAwesomeIcon
                icon={faBars}
                className="icon pointer mx-2  btn-raised shadow-none"
                style={{ fontSize: "16px" }}
                title="Select"
                onClick={toggleMenu}
              />
              {isOpen && (
                <div className="menu">
                  <ul>
                    <li onClick={() => RequestEmailItemMoveFolder("inbox")}>
                      Move to Inbox
                    </li>
                    <li onClick={() => RequestEmailItemMoveFolder("junkemail")}>
                      Move to Junk Email
                    </li>
                  </ul>
                </div>
              )}
            </div>
            <br />
            <div className=" px-5 d-flex align-items-end justify-content-end ">
              <Button
                className="mr-2  "
                style={{ height: "40px", width: "100px" }}
                onClick={handleReply}
              >
                Reply
              </Button>

              <Button
                className="mr-2 "
                style={{ height: "40px", width: "100px" }}
                onClick={() => setShowReplyALLForm(true)}
              >
                ReplyAll
              </Button>
              <Button
                className="mr-2"
                style={{ height: "40px", width: "100px" }}
                onClick={handleForward}
              >
                Forward
              </Button>

              <Button
                className="mr-2"
                style={{ height: "40px", width: "170px" }}
                onClick={RequestEmailDelete}
              >
                Permanent delete
              </Button>
            </div>
          </>
          {/* ) : null} */}
          {/* {console.log("angi email incoming page---->", incomingPage)}{" "} */}
          <div className="text-sm  px-2 mx-2 ">
            <div
              className="d-flex mb-2 "
              style={{ overflow: "auto", height: "100vh", width: "100%" }}
            >
              <span
                className="avatar gd-warning w-40"
                style={{ marginLeft: "-40px" }}
              ></span>

              <span className="mx-2">
                <b>{item?.subject}</b>
                <br />
                <br />
                <div className="d-flex flex-column">
                  <div>
                    <span>
                      <span className="text-muted"> </span>
                      <span style={{ color: "white" }}>
                        {item?.sender?.emailAddress?.name}
                      </span>
                      <br />
                      <span>{formattedDate}</span>
                      <span className="ml-2">
                        {"From <"}
                        {item?.from?.emailAddress?.address}
                        {">"}
                      </span>
                      <div className="mt-3  " style={{ maxWidth: "60vh" }}>
                        {item?.body?.contentType == "html" && (
                          <p
                            // style={{
                            //   backgroundColor: "inherit",
                            //   color: "inherit",
                            //   whiteSpace: "break-spaces",
                            // }}
                            dangerouslySetInnerHTML={sanitizedData()}
                            className="hidden-image "
                          />
                        )}
                        {item?.body?.contentType == "text" && (
                          <pre
                            // style={{
                            //   backgroundColor: "inherit",
                            //   color: "inherit",
                            //   whiteSpace: "break-spaces",
                            // }}
                            className="hidden-image "
                          >
                            {item?.body?.content}
                          </pre>
                        )}

                        {/* <span style={{ color: "white" }}>
                            {item.bodyPreview}
                          </span> */}
                        <br />
                      </div>
                      <br /> <br />
                    </span>

                    <span>
                      <span className="text-muted"> </span>
                      <br />
                      <ThreadDetails threads={threads} />

                      <span>
                        <br />
                        <br />
                        <span id="attached-files"></span>
                      </span>
                    </span>
                  </div>
                </div>
              </span>
            </div>
            {/* <div className="emailBodyStyles text-color" />  */}
          </div>
        </div>
      </div>

      {/* <EmailComposeModal
        showForm={showReplyForm}
        closeModal={() => setShowReplyForm(false)}
        type="REPLY"
        sender={user}
        threads={threads}
        
        
      /> */}

      {showReplyForm && (
        <EmailFormOutlook
          composeType={"REPLY"}
          emailBody={item?.body}
          closeModal={() => setShowReplyForm(false)}
          from={item?.from}
          dateAndTime={item?.sentDateTime}
          subjectIncomming={item?.subject}
          cc={item?.ccRecipients}
          bcc={item?.bccRecipients}
          to={item?.toRecipients}
          email={item}
        />
      )}
      {showReplyALLForm && (
        <EmailFormOutlook
          composeType={"REPLY_ALL"}
          emailBody={item?.body}
          closeModal={() => setShowReplyALLForm(false)}
          from={item?.from}
          dateAndTime={item?.sentDateTime}
          subjectIncomming={item?.subject}
          cc={item?.ccRecipients}
          bcc={item?.bccRecipients}
          to={item?.toRecipients}
          email={item}
        />
      )}

      {showForwardForm && (
        <EmailFormOutlook
          composeType={"FORWARD"}
          emailBody={item?.body}
          closeModal={() => setShowForwardForm(false)}
          from={item?.from}
          dateAndTime={item?.sentDateTime}
          subjectIncomming={item?.subject}
          cc={item?.ccRecipients}
          bcc={item?.bccRecipients}
          to={item?.toRecipients}
          email={item}
        />
      )}

      {/* 
      <EmailComposeModal
        showForm={showForwardForm}
        closeModal={() => setShowForwardForm(false)}
        type="FORWARD"
        sender={user}
      /> */}

      <style js>{`

    
        

        
         
        input {
          width: 100%;
          padding: 8px 20px;
        }

        .demo-wrapper {
          border: 1px solid rgb(163, 163, 163);
          border-radius: 3px;
        }
        .demo-editor {
          height: 28vh;
          width: 47vw;
          color: rgb(77, 77, 80);
        }
        .close-btn {
          position: absolute;
          top: 10px;
          right: 10px;
          font-size: 20px;
          font-weight: bold;
          background-color: transparent;
          border: none;
          cursor: pointer;
        }
        
        .right-corner {
          display: flex;
          justify-content: flex-end;
      }
      .right-corner > .icon {
        margin-right: 10px /* Adjust the value as per your desired spacing */
      
    }

    .icon {
      position: relative;
    }
    
    .icon::after {
      content: attr(title);
      position: absolute;
      top: -20px; /* Adjust the distance of the tooltip from the icon */
      left: 50%; /* Adjust the position of the tooltip */
      transform: translateX(-50%);
      padding: 5px;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
      font-size: 14px;
      border-radius: 4px;
      white-space: nowrap;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.3s linear;
    }
    
    .icon:hover::after {
      visibility: visible;
      opacity: 1;
    }
    .icon {
      cursor: pointer;
    }
    
    .menu {
      position: absolute;
      top: 30px;
      right: 0;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      padding: 8px;
      z-index: 999;
      
    }
    
    .menu ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }
    
    .menu li {
      padding: 8px;
      cursor: pointer;
      color: #000;
    }
    
    .menu li:hover {
      background-color: #f5f5f5;
    }
    .hidden-image img {
      display: none;
    }
   
    
    
    
    
    
        
       
    
      `}</style>
    </div>
  );
};

function mapStatetoProps(state) {
  return {
    user: state.auth.item,
  };
}

export default connect(mapStatetoProps, {})(OutlookBodySection);
