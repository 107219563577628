import React, { useState, useEffect } from "react";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Alert,
  Row,
} from "reactstrap";
import {
  WARDS,
  REGISTER_CANDIDATE,
  CLIENTS,
} from "../../../configs/api-config";
import axios from "axios";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import { difference, forEach, uniq, uniqBy } from "lodash";

export default function BookingWSCellClick(props) {
  const MexxarWards = WARDS;
  const MexxarApiClients = CLIENTS;
  const MexxarCandidate = REGISTER_CANDIDATE;

  const { onCreate, closeModal, candidateId, type, heading } = props;

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();

  const [likeWards, setLikeWards] = useState(null);
  const [disLikeWards, setDisLikeWards] = useState(null);
  const [wardsInLikeClients, setWardsInLikeClients] = useState(null);

  const getWardData = () => {
    axios
      .all([
        axios.get(MexxarWards),
        axios.get(MexxarCandidate + "/" + candidateId),
      ])

      .then(
        axios.spread((res1, res2, res3) => {
          //  let diffArray = difference(res1.data.body, res2.data.body);

          //get candidat's prefered client's ids

          ////.........Arranging candidate's like ward list.......////
          let likeWardData = [];
          forEach(res2.data.body[0].likeWards, function (item) {
            likeWardData.push({
              value: item.name,
              label: item.name,
              id: item.id,
            });
          });
          setLikeWards(likeWardData);

          ////.........Arranging candidate's Dislike ward list.......////
          let dislikeWardData = [];
          forEach(res2.data.body[0].dislikeWards, function (item) {
            dislikeWardData.push({
              value: item.name,
              label: item.name,
              id: item.id,
            });
          });
          setDisLikeWards(dislikeWardData);

          let preferredClients = res2.data.body[0].preferredClients;

          let mainWardList = [];
          for (let index = 0; index < preferredClients.length; index++) {
            const element = preferredClients[index];

            axios
              .get(MexxarApiClients + "/" + element.id + "/wards")
              .then((res) => {
                if (res.data.status == "success") {
                  let wardList = [];
                  forEach(res.data.body, function (item) {
                    mainWardList.push({
                      value: item.wardName,
                      label: item.wardName,
                      id: item.wardId,
                    });
                  });

                  setWardsInLikeClients(uniqBy(mainWardList, "id"));
                } else {
                  // ToastCommon(true, "duplicate", addToast);
                }
              })
              .catch((err) => {
                
                ToastCommon(true, "error", addToast);
              });
          }

          //with these ids get the wards of each client
          //arrange it as an array
          //get the difference from above diffarray
          //map that to the input list down.
        })
      )
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const onsubmit = () => {
    //......like wards ids .....//
    let likeWardIds = [];
    forEach(likeWards, function (item) {
      likeWardIds.push(item.id);
    });

    //......dislike wards ids .....//

    let disLikeWardIds = [];
    forEach(disLikeWards, function (item) {
      disLikeWardIds.push(item.id);
    });

    //......check if newly added like ward in dislike ward list
    //......if so take that ward from dislike wards and put in like wards.....//
    //......if the type is dislike do the opposite......//
    let temp = [];
    if (type === "Like") {
      let reArrangedsubtractArray = disLikeWardIds.filter(
        (x) => !likeWardIds.filter((y) => y === x).length
      );
      temp = reArrangedsubtractArray;
    } else {
      let reArrangedsubtractArray = likeWardIds.filter(
        (x) => !disLikeWardIds.filter((y) => y === x).length
      );
      temp = reArrangedsubtractArray;
    }

    axios
      .post(MexxarCandidate + "/" + candidateId, {
        likeWards: type === "Like" ? likeWardIds : temp,
        dislikeWards: type === "Like" ? temp : disLikeWardIds,
      })
      .then((res) => {
        if (res.data.status == "success") {
          closeModal();
          ToastCommon(true, "success", addToast);
        } else {
          // ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  useEffect(() => {
    getWardData();
    // getLikeWards();
  }, []);

  return (
    <div>
      <Form>
        <Alert color="danger">Are you sure you want to change the status</Alert>
        <Col sm={12}>Change the status of the booking</Col>
        <FormGroup row className="ml-3 mt-3">
          <Col sm={4}>
            <Input type="radio" name="radio1" />
            <Label>CBC</Label>
          </Col>
          <Col sm={4}>
            <Input type="radio" name="radio1" />
            <Label>CBH</Label>
          </Col>
          <Col sm={4}>
            <Input type="radio" name="radio1" />
            <Label>Amended</Label>
          </Col>
        </FormGroup>

        <div className="d-flex justify-content-end">
          {" "}
          <Button className="mr-2" color="success" type="submit">
            Change
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
}
