import React, { useEffect, useState, useRef } from "react";

import {
  Button,
  ButtonGroup,
  Label,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardTitle,
  CardText,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import {
  faPhone,
  faMicrophoneSlash,
  faMicrophone,
  faCalendarAlt,
  faEnvelope,
  faPeopleArrows,
  faStickyNote,
  faRss,
  faExclamationTriangle,
  faExternalLinkAlt,
  faFileAlt,
  faStar,
  faDigitalTachograph,
  faExternalLink,
  faTty,
  faPlay,
  faPause,
  faPhoneSlash,
  faPhoneVolume,
} from "@fortawesome/free-solid-svg-icons";

import { set, isEmpty, forEach, uniqBy } from "lodash";
import {
  CALL_LOGS,
  HOSTMexxar,
  REGISTER_CANDIDATE,
  RESCHEDULE,
  HostMexxarURL,
} from "../../configs/api-config";
// const device = new Twillio.;
import axios from "axios";
import moment from "moment";
import "./calling.style.css";
import { connect, useDispatch } from "react-redux";
//import { Device } from "twilio-client";
// import { Device } from "@twilio/voice-sdk";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import classnames from "classnames";
import TabNew from "../../pages/email/tabNew";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../components/toastCommon";
import IsPrivileged from "../../util/auth/privilegeHelper.js";
import LoadingModal from "../../components/Modal/loadingModal";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import CallBack from "../../components/Modal/ModalBody/callBack";
import ModalComponent from "../../components/Modal/modalComponent";
import CalendarNav from "../../components/Modal/ModalBody/calendarNav";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import AuditReject from "../../components/Modal/ModalBody/auditReject";
import FeedCard from "../../widgets/feed-card-widget/feed-card-widget";
import BookTrainNav from "../../components/Modal/ModalBody/bookTrainNav";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import PersonalNote from "../candidate-profile/profile/widgets/personalNote";
import RestrictGoBack from "../../components/Modal/ModalBody/restrictGoBack";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import ExpiredDocsSummeryWidget from "../../widgets/expiredDocs/expired-Doc-summery-widget";
import AvatarMexxar from "../../util/common/avatarMexxar";
import DialPad from "../../components/DialPad";
import { DialPadIcon } from "../../assets/icons/svg";
import { storeWebSocketData } from "../../redux/actions/softPhoneIntegrationAction.js";
import store from "../../redux/store.js";
import { v4 as uuidv4 } from "uuid";
import {
  clearReason,
  setCandidateId1,
  setReason,
} from "../../redux/reducers/callSummaryReducer.js";
import { setCustomHeaders } from "../../redux/actions/HorizonActions.js";
import { CandidateActiveStatusBadge } from "../../util/common/candidateActiveStatusBadge.js";

const Calling = (props) => {
  const { auth, reason1, custom } = props;
  // const { auth, custom } = props;

  var HorizonApplicationId = custom?.customHeaders?.horizonApplicationID;

  const MexxarHostAPI = HOSTMexxar;
  const MexxarApiCallLOG = CALL_LOGS;
  const MexxarHostURL = HostMexxarURL;
  const MexxarApiReschedule = RESCHEDULE;
  const MexxarApiCandidate = REGISTER_CANDIDATE;
  const consultantPhoneNo = auth?.item?.companyPhoneNumber;

  const [timer, setTimer] = useState("");
  const [mute, setMute] = useState(false);
  const [hold, setHold] = useState(false);
  const [reason, setReason1] = useState("");
  const [rowID, setRowID] = useState(false);
  const [timeShow, setTimeShow] = useState("");
  const [dontLog, setDontLog] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [startedTime, setStartedTime] = useState("");
  const [framework, setFramework] = useState(null);
  const [isCalling, setIsCalling] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [hcnSelected, setHcnSelected] = useState("");
  const [callStatus, setCallStatus] = useState(null);
  const [speciality, setSpeciality] = useState(null);
  const [loadingLogo, setloadingLogo] = useState(false);
  const [newCallBack, setNewCallBack] = useState(false);
  const [showLoading, setshowLoading] = useState(false);
  const [callingStatus, setCallingStatus] = useState("");
  const [callBackShow, setCallBackShow] = useState(false);
  const [calendarShow, setCalendarShow] = useState(false);
  const [candidateName, setCandidateName] = useState(null);
  const [candidateRole, setCandidateRole] = useState(null);
  const [workFlowStatus, setWorkFlowStatus] = useState("");
  const [allowToGoBack, setAllowToGoBack] = useState(false);
  const [candidateStatus, setCandidateStatus] = useState(0);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showDialPad, setShowDialPad] = useState(false);
  const [showReasonInput, setShowReasonInput] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [candidatePhoneNo, setCandidatePhoneNo] = useState(null);
  const [lanndLine, setlandLine] = useState(null);
  const [secondary, setsecondaryMobile] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [saveCallLogWarning, setSaveCallLogWarning] = useState(false);
  const [isImportant, setIsImportant] = useState(false);
  const [showAssignConsultantModal, setShowAssignConsultantModal] =
    useState(false);

  const [phoneNumberType, setPhoneNumberType] = useState("Primary");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [isImportant, setIsImportant] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const dispatch = useDispatch();

  console.log("reason1", reason1?.reason);
  console.log("reason1", reason1?.candidateId);

  const handlePhoneNumberType = (type) => {
    setPhoneNumberType(type);
    console.log("mobile type", type);
    // Additional logic can be added here if needed
  };

  var storedData = localStorage.getItem("callSummary");

  if (storedData && reason1?.candidateId === candidateId) {
    var parsedData = JSON.parse(storedData);
    var retrievedReason = parsedData.reason;

    console.log(retrievedReason);
  } else {
    console.log("Data not found in localStorage");
  }
  const callState = localStorage.getItem("callState");

  const startTimeString = localStorage.getItem("callHistory");
  const durationString = localStorage.getItem("callHistoryDuration");

  console.log("startTimeString", startTimeString);
  console.log("durationString", durationString);
  const startTime = new Date(startTimeString);
  const startDate = new Date(startTimeString);
  console.log("startDate", startDate);

  const endDate = new Date(startDate.getTime() + durationString * 1000);
  const formattedEndTime = moment(endDate).toISOString(); // Format to ISO string
  
  console.log(endDate); // Output the calculated endTime

  const toggleShowCalendarModal = () => {
    setShowCalendarModal(!showCalendarModal);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  // const toggleC = () => setShowCalendarModal(!showCalendarModal);
  const toggleCB = () => setNewCallBack(!newCallBack);
  const { addToast } = useToasts();

  const appPackSent = () => {
    setshowLoading(true);

    axios
      .put(HOSTMexxar + "candidates/" + candidateId + "/send-app-pack-candy")
      .then((res) => {
        ToastCommon(true, "sent", addToast);
        setshowLoading(false);
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
        setshowLoading(false);
      });
  };
  console.log("reason", reason);
  let lastCallStartedTime = formattedEndTime === null ? new Date() : new Date(); //use when no softphone is assigned

  let lastCallEndedTime = new Date(); //When no softphone is integrated please use this

  const addCallLogToCandidateTable = () => {
    axios
      .put(
        `${HOSTMexxar}candidates/${candidateId}/lastContactedRef?date=${moment(
          lastCallEndedTime
        ).toISOString()}`
      )
      .then((res) => {
        //  setCandidateId(res.data.body);
        if (res.data.status == "success") {
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log("erorrrrrrrrrrrrrrrr", err);
        ToastCommon(true, "error", addToast);
      });
  };

  const [isHangup, setIsHangup] = useState(false);

  const addCallerLog = () => {
    //loging call log

    axios
      .post(MexxarApiCallLOG, {
        candidateId: Number(candidateId),
        reason: reason,
        startTime: moment(lastCallStartedTime).toISOString(),
        endTime: moment(lastCallEndedTime).toISOString(),
        status: callStatus,
        candidateStatus: candidateStatus,
        incomingPhoneNo: 0,
        outgoingPhoneNo: Number(consultantPhoneNo),
        important: isImportant,
      })
      .then((res) => {
        //  setCandidateId(res.data.body);
        if (res.data.status == "success") {
          history.goBack();
          setAllowToGoBack(true);
          // store.dispatch(lastContactedDate(res.data.body));
          console.log("sssss", res.data.body);
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log("erorrrrrrrrrrrrrrrr", err);
        ToastCommon(true, "error", addToast);
      });
  };

  const getCandidateId = () => {
    let id = props.match.params.id;

    setCandidateId(id);
    loadCandidateData(id);
  };

  const mail = () => setShowReasonInput(!showReasonInput);

  let history = useHistory();


  const handleCallbackShow = (callBackShow) => setCallBackShow(callBackShow);
  // const handleHold = (hold) => setHold(!hold);

  const toggleM = () => setShowEmailModal(!showEmailModal);
  const toggleN = () => setShowDialPad(!showDialPad);

  let audioStream;
 
  //**********<<<<<<<<< Use when connecting VOIP service **********>>>>>>>>>//
  // useEffect(() => {
  //   Device.on("connect", function (conn) {
  //     // setIsCalling(true);
  //     // setCallingStatus("Ringing...");
  //     setStartedTime(new Date());
  //     console.log("Phone ringing");
  //   });
  // }, []);

  // useEffect(() => {
  //   Device.on("disconnect", function (conn) {
  //     // setTimeShow("");
  //     // clearInterval(timer);
  //     // setCallingStatus("Canceled call");
  //     setIsCalling(false);
  //     setEndTime(new Date());
  //     console.log("Phone disconnected");
  //   });
  // }, []);
  //**********<<<<<<<<< End **********>>>>>>>>>//

  // const sendDigit = (digit) => {
  //   let outGoinConnection = Device.activeConnection().sendDigits(digit);
  //   outGoinConnection.on("ringing", function () {
  //     setCallingStatus("Ringing...");
  //   });
  // };

  const [token, setToken] = useState(null);
  const [device, setDevice] = useState();
  const uuid = require("uuid");


  ////......Reshedule icon function to reschedule a call........////
  const resheduleCall = (data) => {
    setCallBackShow(true);
    let rescheduleDate = moment(data.date).add(data.time).toISOString();
    axios
      .post(MexxarApiReschedule, {
        callerId: auth?.item?.id,
        receiverId: candidateId,
        callDate: moment(new Date()).toISOString(),
        rescheduleDate: rescheduleDate,
      })
      .then((res) => {
        //  setCandidateId(res.data.body);
        ToastCommon(true, "success", addToast);
        setCallBackShow(false);
      })
      .catch((err) => {
    
      });
  };

  const Register = (data) => {
    // const dispatch = useDispatch();

    let headers = new Headers();
    const randomGuid = uuidv4();
    headers.append("ApplicationId", randomGuid);
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    headers.append("Cache-Control", "no-cache");
    headers.append("Pragma", "no-cache");

    const params = new URLSearchParams();
    params.append("Id", randomGuid);
    params.append("Name", "Mexxar");

    store.dispatch(
      setCustomHeaders({ headers: headers, horizonApplicationID: randomGuid })
    );

    // store.dispatch(setCustomHeaders(params));

    axios({
      url: "https://localhost:10443/api/v1/Register",
      method: "post",
      headers: headers,
      data: params,
    })
      .then((response) => {
        // handle success
      })
      .catch((error) => {
        // handle error
        console.error(error);
      });
  };

  const storedHeaders = localStorage.getItem("customHeaders");
  const getStoredHeaders = () => {
    if (storedHeaders) {
      const headersArray = JSON.parse(storedHeaders);
      return new Headers(headersArray);
    }
    return null;
  };

  useEffect(() => {
    store.dispatch(storeWebSocketData());

    return () => {};
  }, []);


  const [horizonApplicationID, setHorizonApplicationId] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const headers = new Headers();
  headers.append("ApplicationId", HorizonApplicationId);
  headers.append("Content-Type", "application/x-www-form-urlencoded");

  console.log("horizonApplicationID", horizonApplicationID);
  const handleOnClick = async () => {
    if (isCalling) {
      // ... (existing code)
      setIsCalling(false);
      // Uncomment your fetch request for Hangup if necessary
    } else {
      setIsCalling(true);

      console.log("phoneNumberType:", phoneNumberType);
      console.log("candidatePhoneNo:", candidatePhoneNo);
      console.log("secondary:", secondary);
      console.log("lanndLine:", lanndLine);
      const params = new URLSearchParams();
      if (phoneNumberType === "Primary") {
        params.append("destination", candidatePhoneNo);
      }
      if (phoneNumberType === "Secondary") {
        params.append("destination", secondary);
      }
      if (phoneNumberType === "Land") {
        params.append("destination", lanndLine);
      }

      const requestOptions = {
        method: "POST",
        headers: headers,
        body: params,
      };

      try {
        const response = await fetch(
          "https://localhost:10443/api/v1/MakeCall",
          requestOptions
        );
        const data = await response.json();

        console.log(data.UniqueId); // Ensure this prints the correct value

        // Update state within the scope of the async function
        // setCallId(data.UniqueId);
        localStorage.setItem("callId", data.UniqueId);
        // setCallBackShow(false);
      } catch (error) {
        setIsCalling(false);
        console.error("Error Call Hangup:", error);
      }
    }
  };
  const callId = localStorage.getItem("callId");

  const displayPhoneNumber = (phoneNumber) => {
    if (!phoneNumber || phoneNumber.trim() === "") {
      return "N/A";
    }
    return phoneNumber;
  };
  // console.log("callId",callId)

  const handleHangupCall = async () => {
    setIsCalling(false);
    const params = new URLSearchParams();
    params.append("callId", callId);
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: params, // Convert to string
    };

    console.log("hangup", headers);

    fetch("https://localhost:10443/api/v1/Hangup", requestOptions)
      .then((response) => response.json())
      .then((data) => {})
      .catch((error) => {
        setIsCalling(true);
        console.error("Error Call Hangup:", error);
      });

  
  };

  const handleAnswer = async () => {
 
    const params = new URLSearchParams();
    params.append("callId", callId);

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: params, // Convert to string
    };

    console.log("hangup", headers);

    fetch("https://localhost:10443/api/v1/Answer", requestOptions)
      .then((response) => response.json())
     
      .catch((error) => console.error("Error:", error));
  };

  const handleHold = async () => {
    if (!hold) {
      // Hold the call
      const params = new URLSearchParams();
      params.append("callId", callId);
      const requestOptions = {
        method: "POST",
        headers: headers,
        body: params, // Convert to string
      };

      fetch("https://localhost:10443/api/v1/Hold", requestOptions)
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error("Error:", error));
    } else {
      // Unhold the call
      const params = new URLSearchParams();
      params.append("callId", callId);
      const requestOptions = {
        method: "POST",
        headers: headers,
        body: params, // Convert to string
      };

      fetch("https://localhost:10443/api/v1/Unhold", requestOptions)
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error("Error:", error));
    }
    // Toggle the hold state
    setHold(!hold);
  };

  const CallTransfer = (callId) => {
    const destinationNumber = "0094702634403"; // Assuming this is a string

    axios
      .post("https://localhost:10443/api/v1/TransferConsult", {
        callId: callId,
        destination: destinationNumber,
      })
      .then((res) => {
        // Implement logic to handle successful response
        console.log("Transfer successful", res.data); // Example: Logging the response data
      })
      .catch((err) => {
        // Implement logic to handle errors
        console.error("Transfer failed", err); // Example: Logging the error
      });
  };

  const candidateActiveStatus = () => {
    //Candidate status updating ex:Doment,Do not not call
    axios
      .put(
        MexxarApiCandidate +
          "/" +
          candidateId +
          "/" +
          "status" +
          "/" +
          candidateStatus
      )
      .then((res) => {
        //  setCandidateId(res.data.body);

        if (res.data.status == "success") {
          ToastCommon(true, "update", addToast);
          loadCandidateData(candidateId);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const callResponse = () => {
    // HOT NEUTRAL COLD -call response status
    axios
      .put(
        MexxarApiCandidate +
          "/" +
          candidateId +
          "/" +
          "response" +
          "/" +
          hcnSelected
      )
      .then((res) => {
        //  setCandidateId(res.data.body);
        ToastCommon(true, "update", addToast);
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const loadCandidateData = (candidateID) => {
    axios.get(MexxarHostAPI + "candidates/" + candidateID).then((res) => {
      // console.log("candidate data response incoming", res.data.body);

      let frameworksTemp = [];

      if (res.data.body[0]?.preferredClients != undefined) {
        forEach(res.data.body[0]?.preferredClients, function (item) {
          frameworksTemp.push(item.framework);
        });
      }

      let frameworks = uniqBy(frameworksTemp, "id");

      if (frameworks.length > 0) {
        setFramework(frameworks);
      } else {
        setFramework(null);
      }

      let specialityTemp =
        res.data.body[0]?.candidateSpecialities.length === 0
          ? null
          : res.data.body[0]?.candidateSpecialities;

      setSpeciality(specialityTemp);

      setCandidateRole(res.data.body[0]?.candidateRole?.id);
      setCandidatePhoneNo(res.data.body[0]?.primaryMobile);
      setsecondaryMobile(res.data.body[0]?.secondaryMobile);
      setlandLine(res.data.body[0]?.landLine);
      console.log("numbersss", res.data?.body[0]);
      setWorkFlowStatus(res.data.body[0]?.workFlowStatus);
      setCandidateName(
        res.data.body[0]?.firstName + " " + res.data.body[0]?.lastName
      );
      setSelectedCandidate(res.data.body[0]);

      //  setCandidateStatus(res.data.body[0].candidateStatus)
    });
  };

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      // console.log("Error: ", error);
    };
  }

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    // console.log(width, height);
    return {
      width,
      height,
    };
  }

  const saveCallBack = (data) => {
    setSaveCallLogWarning(!saveCallLogWarning);
    // setTimeout(() => {}, 1000);

    if (data == true) {
      // console.log("would like to go back without saving the call back");
      setAllowToGoBack(true);
      history.goBack();
    } else {
      history.goBack();
    }
  };

  const handleTextareaChange = (e) => {
    const newText = e.target.value;
    setReason1(newText);
    store.dispatch(setReason(newText));
    store.dispatch(setCandidateId1(candidateId));
  };

  useEffect(() => {
    const storedData = localStorage.getItem("savereason");

    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setReason1(parsedData.reason);
      setIsImportant(parsedData.isImportant);
    }
  }, []);

  const savingFunction = () => {
    if (callStatus == null) {
      setSaveCallLogWarning(!saveCallLogWarning);
    } else {
      setAllowToGoBack(false);
      addCallerLog();
      addCallLogToCandidateTable();
      store.dispatch(clearReason());
    }
  };

  const goBack = () => {
    if (allowToGoBack) {
      history.goBack();
    }
  };
  const sendToAudit = (data) => {
    axios
      .put(MexxarApiCandidate + "/" + candidateId + "/workflow/AUDIT")
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "success", addToast);
          setShowRejectModal(false);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        //ToastCommon(true, "error", addToast);
      });
  };

  useDidMountEffect(() => {
    callResponse();
    return () => {};
  }, [hcnSelected]);

  useDidMountEffect(() => {
    candidateActiveStatus();
    return () => {};
  }, [candidateStatus]);


  useEffect(() => {
    getCandidateId();
    return () => {};
  }, []);

  useEffect(() => {
    console.log("...........HorizonApp ID.................", HorizonApplicationId);

    if (HorizonApplicationId) {
      // setHorizonApplicationId(HorizonApplicationId);
    } else {
      Register();
    }

    return () => {};
  }, []);


  const [enableMute, setEnableMute] = useState(false);

  // Function to toggle mute/unmute
  const toggleMic = () => {
    setEnableMute((prevState) => !prevState);
    // You can add additional logic here to handle muting/unmuting the microphone in your application
    // For example:
    // if (enableMute) {
    //   // Code to mute the microphone
    // } else {
    //   // Code to unmute the microphone
    // }
  };
  return (
    <DashboardLayout title="Calling Page" subTitle="Call to Candidate">
      <div className="row">
        <div className="col-md-6">
          <div
            className="card"
            style={{ minHeight: "412px", maxHeight: "fit-content" }}
          >
            <div className="card-body">
              <div className="sub-1  mb-1">{callingStatus}</div>
              <div className="sub-1  mb-1"> {callState}</div>
              <AvatarMexxar
                candidateId={candidateId}
                primaryPhone={selectedCandidate?.primaryMobile}
                avatar={selectedCandidate?.avatar}
                gender={selectedCandidate?.gender}
              />

              <div className="sub-1  mb-1">{candidateName}</div>

              <div className="sub-1  mb-1">
                <a
                  className="  btn btn-sm bg-dark-overlay btn-rounded text-white active"
                  style={{
                    backgroundColor: CandidateActiveStatusBadge(
                      selectedCandidate?.status
                    ).badgeColor,
                    cursor: "default",
                  }}
                >
                  <span className="d-none">
                    {
                      CandidateActiveStatusBadge(selectedCandidate?.status)
                        .badgeLable
                    }
                  </span>
                </a>
              </div>

              <div className="sub-1  mb-1">{isCalling ? timeShow : ""}</div>
              <div className="sub-1 flex-wrap">
                <div className="mr-2 " id="callresponse">
                  <ButtonGroup>
                    <Button
                      outline
                      color="info"
                      onClick={() => setHcnSelected("COLD")}
                      active={hcnSelected === "COLD"}
                    >
                      Cold
                    </Button>
                    <Button
                      outline
                      color="secondary"
                      onClick={() => setHcnSelected("NEUTRAL")}
                      active={hcnSelected === "NEUTRAL"}
                    >
                      Neutral
                    </Button>
                    <Button
                      outline
                      color="danger"
                      onClick={() => setHcnSelected("HOT")}
                      active={hcnSelected === "HOT"}
                    >
                      Hot
                    </Button>
                  </ButtonGroup>
                </div>
                <UncontrolledTooltip placement="right" target="callresponse">
                  Call response
                </UncontrolledTooltip>

                <IsPrivileged privilege={["HEADHUNTER_TEMP"]}>
                  <div
                    className=" mx-1 d-flex align-items-center "
                    id="calendarEvents"
                  >
                    <FontAwesomeIcon
                      color="#FF5603"
                      icon={faCalendarAlt}
                      onClick={() => toggleShowCalendarModal()}
                      className="fa-lg mb-2 btn-raised btn-wave pointer"
                    />
                  </div>
                  <UncontrolledTooltip
                    placement="right"
                    target="calendarEvents"
                  >
                    Calendar Events
                  </UncontrolledTooltip>
                </IsPrivileged>
                <div
                  className="mx-1 d-flex align-items-center"
                  id="muteUnmuteCall"
                >
                  <RoundIconButton
                    icon={enableMute ? faMicrophoneSlash : faMicrophone}
                    iconSize="fa-sm"
                    buttonSize="btn-sm"
                    onClick={toggleMic}
                  />
                  <UncontrolledTooltip
                    placement="right"
                    target="muteUnmuteCall"
                  >
                    {enableMute ? "Unmute" : "Mute"}
                  </UncontrolledTooltip>
                </div>

                <div
                  className=" mx-1  d-flex align-items-center "
                  id="callCandidate"
                >
                  <RoundIconButton
                     disabled={selectedCandidate?.status ==="INACTIVE"}
                    buttonColor="#31c971"
                    icon={faPhone}
                    iconSize="fa-sm"
                    buttonSize="btn-sm"
                    click={() => handleOnClick()}
                  />
                </div>

                <UncontrolledTooltip placement="right" target="callCandidate">
                  Call
                </UncontrolledTooltip>

                <div className=" mx-1  d-flex align-items-center  " id="hangUp">
                  <RoundIconButton
                    disabled={!isCalling}
                    buttonColor="#f31f7f"
                    icon={faPhoneSlash}
                    buttonSize="25px"
                    click={() => handleHangupCall()}
                  ></RoundIconButton>
                </div>
                <UncontrolledTooltip placement="right" target="hangUp">
                  Hangup
                </UncontrolledTooltip>

                <div
                  className=" mx-1  d-flex align-items-center "
                  id="holdunholdcall"
                >
                  <RoundIconButton
                    buttonColor={`${!hold ? "#f31f7f" : "#31c971"}`}
                    icon={hold ? faPlay : faPause}
                    iconSize="fa-sm"
                    buttonSize="btn-sm"
                    click={() => handleHold()}
                  />
                </div>

                <IsPrivileged privilege={["ADMIN"]}>
                  <div
                    className="mx-1 d-flex align-items-center"
                    id="callTransfer"
                  >
                    <RoundIconButton
                      buttonColor="#4E4E4E"
                      icon={faPhoneVolume}
                      buttonSize="25px"
                      click={
                        () => CallTransfer() // CallTransfer function is invoked here
                      }
                    ></RoundIconButton>
                  </div>

                  <UncontrolledTooltip placement="right" target="callTransfer">
                    Call Transfer
                  </UncontrolledTooltip>
                </IsPrivileged>

                <UncontrolledTooltip placement="right" target="holdunholdcall">
                  Hold/Unhold
                </UncontrolledTooltip>

                <div
                  className=" mx-1  d-flex align-items-center  "
                  id="emailSMS"
                >
                  <RoundIconButton
                    buttonColor="#458AF7"
                    icon={faEnvelope}
                    buttonSize="25px"
                    click={() => toggleM()}
                  ></RoundIconButton>
                </div>
                <UncontrolledTooltip placement="right" target="emailSMS">
                  Email/SMS
                </UncontrolledTooltip>

                <div
                  className=" mx-1  d-flex align-items-center  "
                  id="dialPad"
                >
                  <RoundIconButton
                    buttonColor="#458AF7"
                    buttonSize="25px"
                    click={() => toggleN()}
                  >
                    <DialPadIcon />
                  </RoundIconButton>
                </div>
                <UncontrolledTooltip placement="right" target="dialPad">
                  DialPad
                </UncontrolledTooltip>

                <IsPrivileged privilege={["HEADHUNTER_TEMP", "EMPLOYEE_TEMP"]}>
                  <div
                    className=" mx-1  d-flex align-items-center  "
                    id="appLinkbutton"
                  >
                    <RoundIconButton
                      buttonColor={
                        speciality !== null && framework !== null
                          ? "#458AF7"
                          : "#f44336"
                      }
                      icon={faExternalLinkAlt}
                      buttonSize="25px"
                      click={
                        speciality !== null && framework !== null
                          ? () => appPackSent()
                          : null
                      }
                    ></RoundIconButton>
                  </div>

                  <UncontrolledTooltip placement="right" target="appLinkbutton">
                    {speciality !== null && framework !== null
                      ? "Send app link"
                      : "Please configure speciality and clients for candidate"}
                  </UncontrolledTooltip>
                </IsPrivileged>

                <IsPrivileged privilege={["EMPLOYEE_TEMP"]}>
                  <div
                    className=" mx-1  d-flex align-items-center  "
                    id="summaryShow"
                  >
                    <RoundIconButton
                      buttonColor="#458AF7"
                      icon={faFileAlt}
                      buttonSize="25px"
                      click={() => setShowRejectModal(!showRejectModal)}
                    ></RoundIconButton>
                  </div>

                  <UncontrolledTooltip placement="right" target="summaryShow">
                    Document Summary
                  </UncontrolledTooltip>
                </IsPrivileged>

                <div
                  className="mx-1 d-flex align-items-center "
                  id="phoneTypeDropdown"
                >
                  <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} disabled={selectedCandidate?.status ==="INACTIVE"}>
                    <DropdownToggle caret color="success">
                      {phoneNumberType}
                      {/* Display the selected phone number type */}
                    </DropdownToggle>
                    <DropdownMenu>
                      {/* Dropdown items for selecting different phone number types */}
                      <DropdownItem
                        onClick={() => handlePhoneNumberType("Primary")}
                      >
                        Primary : {displayPhoneNumber(candidatePhoneNo)}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handlePhoneNumberType("Land")}
                      >
                        Land : {displayPhoneNumber(lanndLine)}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => handlePhoneNumberType("Secondary")}
                      >
                        Secondary : {displayPhoneNumber(secondary)}
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                  <UncontrolledTooltip
                    placement="right"
                    target="phoneTypeDropdown"
                  >
                    Select Phone Type
                  </UncontrolledTooltip>
                </div>
              </div>
              <div className="row px-2">
                <div className="col-md-9 col-sm-12 ">
                  <div className=" ml-3 justify-content-end d-flex flex-column ">
                    <div className="my-1">
                      <Label>
                        <small className="text-mute">Call Summary :</small>

                        <FontAwesomeIcon
                          id="star"
                          icon={faStar}
                          className={`star shadow-none btn-raised pointer ${
                            isImportant ? "text-warning" : ""
                          }`}
                          onClick={() => setIsImportant(!isImportant)}
                        />
                      </Label>
                      <UncontrolledTooltip target="star">
                        Mark as important
                      </UncontrolledTooltip>
                      <textarea
                        onChange={handleTextareaChange}
                        className="form-control"
                        rows="9"
                        name="reason"
                        value={retrievedReason}
                        // status={remember}
                        style={{ width: "100%" }}
                      ></textarea>
                    </div>
                    <div className="my-1 ">
                      <Button
                        disabled={!allowToGoBack}
                        color="info"
                        size="sm"
                        className="mr-2"
                        onClick={goBack}
                      >
                        Go Back
                      </Button>
                      <Link
                        to="#"
                        // to={{
                        //   pathname: "/dashboard/headhunter",
                        // }}
                      >
                        <Button
                          color="info"
                          size="sm"
                          onClick={() => {
                            savingFunction();
                          }}
                        >
                          Save
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-md-3 col-sm-12 d-flex justify-content-center align-items-center">
                  <div className="  ">
                    <div className="row ">
                      {/* <div className="my-1 col-4 col-md-12   d-flex  justify-content-center">
                        <Button
                          color="success"
                          size="sm"
                          className="buttons-custom"
                          onClick={Hold}
                        >
                          Hold
                        </Button>
                      </div> */}
                      {/* <CallStateLabel /> */}

                      <div className="my-1 col-4 col-md-12   d-flex  justify-content-center">
                        <Button
                          color="success"
                          size="sm"
                          className="buttons-custom"
                          onClick={() => setCallStatus(0)}
                        >
                          Answered
                        </Button>
                      </div>
                      <div className="my-1 col-4 col-md-12 d-flex  justify-content-center ">
                        <Button
                          color="success"
                          size="sm"
                          className="buttons-custom"
                          onClick={() => setCallStatus(1)}
                        >
                          No Answer
                        </Button>
                      </div>
                      <div className="my-1 col-4 col-md-12  d-flex  justify-content-center">
                        <Button
                          color="success"
                          size="sm"
                          className="buttons-custom"
                          onClick={() => setCallStatus(2)}
                        >
                          LVM & Whatsapp
                        </Button>
                      </div>
                      <div className="my-1 col-4 col-md-12  d-flex  justify-content-center">
                        <Button
                          color="success"
                          size="sm"
                          className="buttons-custom"
                          onClick={() => {
                            setCallBackShow(true);
                            setCallStatus(3);
                          }}
                        >
                          Reschedule
                        </Button>
                      </div>
                      {selectedCandidate?.status == "ACTIVE" ? (
                        <>
                          <IsPrivileged
                            privilege={[
                              "REGISTRATION",
                              "BOOKING",
                              "MAINTENANCE",
                            ]}
                          >
                            <div className="my-1 col-4 col-md-12  d-flex  justify-content-center">
                              <Button
                                color="warning"
                                size="sm"
                                className="buttons-custom"
                                onClick={() => setCandidateStatus("DORMANT")}
                              >
                                Dormant
                              </Button>
                            </div>
                          </IsPrivileged>
                          <div className="my-1 col-4 col-md-12  d-flex  justify-content-center">
                            <Button
                              color="danger"
                              size="sm"
                              className="buttons-custom"
                              onClick={() => setCandidateStatus("DO_NOT_CALL")}
                            >
                              DNC
                            </Button>
                          </div>
                        </>
                      ) : (
                        ["STAND_BY", "DORMANT", "DO_NOT_CALL"].includes(selectedCandidate?.status)   && (
                          <div className="my-1 col-4 col-md-12  d-flex  justify-content-center">
                            <Button
                              color="success"
                              size="sm"
                              className="buttons-custom"
                              onClick={() => setCandidateStatus("ACTIVE")}
                            >
                              Reactivate
                            </Button>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 card pt-2">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                <FontAwesomeIcon
                  icon={faPeopleArrows}
                  className="mx-2"
                ></FontAwesomeIcon>
                Personal Note
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggle("2");
                }}
              >
                <FontAwesomeIcon
                  icon={faRss}
                  className="mx-2"
                ></FontAwesomeIcon>
                Notes
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div>
                <Row>
                  <Col sm="12">
                    <PersonalNote candidateId={candidateId} />
                  </Col>
                </Row>
              </div>
            </TabPane>
            <TabPane tabId="2">
              <div className="p-2">
                <Row>
                  <Col sm="12">
                    <FeedCard
                      candidateId={props.match.params.id}
                      reload={allowToGoBack}
                    />
                  </Col>
                </Row>
              </div>
            </TabPane>
            
          </TabContent>
        </div>
      </div>
      {/* modal for call back schedule */}
      {/* <div
        className={`modal fade bd-example-modal-sm  ${
          callBackShow ? "show" : ""
        }`}
        id="exampleModalCenter"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        style={{ display: `${callBackShow ? "block" : "none"}` }}
        aria-hidden={!callBackShow}
      > */}

      <ModalComponent
        show={showRejectModal}
        header="Documents Summary"
        className="modal-dialog modal-lg"
        closeModal={() => setShowRejectModal(false)}
      >
        <AuditReject
          selecetData={candidateRole}
          incomingPage={"calling"}
          candidateId={candidateId}
        />

        <div className="mt-3">
          {selectedCandidate?.workFlowStatus == "REGISTRATION" && (
            <IsPrivileged
              privilege={["HEADHUNTER", "REGISTRATION", "MAINTENANCE"]}
            >
              <Button color="success" onClick={sendToAudit}>
                Send To Audit
              </Button>
            </IsPrivileged>
          )}

          <Button
            className="ml-1"
            color="secondary"
            onClick={() => setShowRejectModal(false)}
          >
            Close
          </Button>
        </div>
      </ModalComponent>

      <ModalComponent
        show={callBackShow}
        header="Reschedule"
        closeModal={() => setCallBackShow(false)}
      >
        <SmallCalendar
          onCreate={(data) => resheduleCall(data)}
          closeModal={() => setCallBackShow(false)}
        ></SmallCalendar>
      </ModalComponent>

      <RestrictGoBack
        show={saveCallLogWarning}
        isConfirm={(data) => saveCallBack(data)}
        // closeModal={(data) => setSaveCallLogWarning(!saveCallLogWarning)}
      ></RestrictGoBack>

      <ModalComponent
        show={showCalendarModal}
        header="Add to calender"
        closeModal={() => setShowCalendarModal(false)}
      >
        {/* <CalendarNav closeModal={toggleC} /> */}
        {candidateName && (
          <BookTrainNav
            incommingPage={"callingPage"}
            candidateId={candidateId}
            candidateName={candidateName}
            // successStatus={() => this.getAvailibility(this.props.candidateId)}
            closeModal={() => setShowCalendarModal(false)}
            onCreate={() => setShowCalendarModal(false)}
            // start={this.state.eventDates.start}
            // end={this.state.eventDates.end}
          ></BookTrainNav>
        )}
      </ModalComponent>
      {/* </div> */}
      {/* email sms modal */}
      <ModalComponent
        show={showEmailModal}
        header="Email & sms"
        closeModal={() => toggleM()}
      >
        <EmailNav
          closeModal={() => toggleM()}
          user={selectedCandidate}
          incomingPage="callingPage"
        />
      </ModalComponent>
      <ModalComponent
        size="sm"
        show={showDialPad}
        // header="Dial Pad"
        closeModal={() => toggleN()}
      >
        <DialPad
          applicationId={horizonApplicationID}
          customheaders={storedHeaders}
        ></DialPad>
      </ModalComponent>

      <LoadingModal
        show={showLoading}
        header="Loading"
        closeModal={() => setshowLoading(!showLoading)}
      />
    </DashboardLayout>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    callState: state.websocketData?.Payload,
    reason1: state?.callSummaryReducer,
    custom: state.customHeaderReducer,
  };
}

export default connect(mapStateToProps, {})(Calling);
