import React, { useState } from "react";
import ClientBookings from "./bookings";
import ClientProfiles from "./clientProfiles";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import TabNavigationMenu from "../../util/common/tabNavigation";
import { ClientLable } from "../../configs/api-config";

const ClientPage = () => {
  const [currentTab, setCurrentTab] = useState("clientprofiles");

  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <>
      <DashboardLayout
        title={`${ClientLable} Data`}
        subTitle={`View And Manage Your ${ClientLable}s`}
      >
        <TabNavigationMenu
          activated={currentTab}
          onChangeItem={switchTabHandler}
          itemList={[
            { name: "Client Profiles", key: "clientprofiles" },
            // { name: "Bookings", key: "bookings" },        //only allow for temp staffing
          ]}
        />

        {currentTab === "clientprofiles" && <ClientProfiles />}
        {currentTab === "bookings" && (
          <ClientBookings incomingPage="clientData" />
        )}
      </DashboardLayout>
    </>
  );
};

export default ClientPage;
