// import React, { useState, useEffect } from "react";
// import {
//   faPhone,
//   faMicrophoneSlash,
//   faMicrophone,
//   faUpload,
//   faChevronLeft,
//   faChevronRight,
//   faFileAlt,
// } from "@fortawesome/free-solid-svg-icons";
// import axios from "axios";
// import { toLower } from "lodash";
// // import loadingAnimation from "../../../src/images/puff.svg";
// import loadingAnimation from "../../../../src/images/puff.svg";
// import { HOSTMexxar } from "../../../configs/api-config";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Label, Button, Input, UncontrolledTooltip, Collapse, Card, CardBody, Row, Col, FormGroup, Badge } from "reactstrap";
// import SortFunctionAscending from "../../../components/sortFunction/sortByDateAscending";
// import EmployeePayrollWidget from "../../../widgets/payroll/candidate-payroll-pending-widget";
// import NoSearchResults from "../../errorPages/NoSearchResults";
// import { useSpring, animated } from "react-spring";
// import NoConnection from "../../errorPages/NoConnection";
// import { FunnelIcon } from "../../../assets/icons/svg";
// import TransactionWidget from "./transactionWidget";
// import moment from "moment";
// import TransactionWidgetFilter from "./TransactionWidgetFilter";

// const filterByName = (name, dataSet) => {
//   let filtered = dataSet;
//   if (name !== "") {
//     filtered = dataSet.filter((item) => {
//       let searchableString = `${item.candidateName}   ${item.candidatesId}  ${item.clientId} ${item.clientName}  `;

//       let p = searchableString;
//       return p.toLowerCase().includes(name.toLowerCase());
//     });
//   }
//   let lengthofSearch = filtered.length;
//   return { filtered, lengthofSearch };
// };

// const Transaction= () => {
//   const MexxarAPI = HOSTMexxar;
//   const [rows, setRows] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(false);


  
//   const currentDate = new Date();

//   const yesterDay = moment(moment(currentDate).subtract(7, "days")).format(
//     "YYYY-MM-DD"
//   );
//   const tommorow = moment(moment(currentDate).add(7, "days")).format(
//     "YYYY-MM-DD"
//   );

//   const [searchQuery, setSearchQuery] = useState("");
//   const [isOpenFilter, setIsOpenFilter] = useState(false);
//   const [getEndDate, setGetEndDate] = useState(tommorow);
//   const [resetState, setResetState] = useState(false);
//   const [serviceType, setServiceType] = useState("");


//   const [getStartDate, setGetStartDate] = useState(yesterDay);
//   const [search, setSearch] = useState(false);
//   const handleSearchOnChange = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   const getAllTransactions = () => {
//     setLoading(true);
//     axios
//     .get(MexxarAPI + "transaction/getAll/filter")
//       .then((res) => {
//         setRows(res.data.body);
//         setLoading(false);
//         if (res.data.status == "success") {
//           setRows(res.data.body);
//           setLoading(false);
//         } else if (res.data.status == "failed") {
//           setError(true);
//         }
//       })
//       .catch((err) => {
//         setError(true);
//       });
//   };

//   const animationProps = useSpring({
//     opacity: 1,
//     transform: "translate(0px, 0px)",
//     from: { opacity: 0, transform: "translate(-100px, 0px)" },
//   });

//   useEffect(() => {
//     getAllTransactions();
//     return () => {};
//   }, []);

//   const reset = () => {
//     setGetStartDate(yesterDay);
//     setGetEndDate(tommorow);
//     setResetState(!resetState);
   

//     // getFieldData();
//   };


//   return (
//     <>
//       <div className="form-inline mb-2">
        
//         <Button
//           onClick={() => setIsOpenFilter(!isOpenFilter)}
//           className="btn bg-dark-lt text-dark"
//           // disabled={true}
//         >
//           <FunnelIcon />
//           <span className="mx-1">Filter</span>
//         </Button>

//         <Input
//           onChange={(e) => handleSearchOnChange(e)}
//           type="text"
//           id="searchBar"
//           className="form-control no-border no-shadow no-bg typeahead tt-input"
//           placeholder="Search Candidates..."
//           autoComplete="off"
//           spellCheck="false"
//           dir="auto"
//           style={{
//             position: "relative",
//             verticalAlign: "top",
//             backgroundColor: "transparent",
//             // textTransform: "lowercase",
//           }}
//         />
//       </div>
//       <UncontrolledTooltip target="searchBar">
//         Search candidates by Name/Id/Email/Phone No.
//       </UncontrolledTooltip>

//       <Collapse isOpen={isOpenFilter}>
//         <Card>
//           <CardBody>
//             <Row>
//               <Col>
//                 <Label for="exampleDate">Start Date</Label>
//                 <Input
//                   id="startDate"
//                   type="date"
//                   //defaultValue={getStartDate}
//                   value={getStartDate}
//                   onChange={(e) => setGetStartDate(e.target.value)}
//                   style={{ cursor: "pointer" }}
//                 />
//               </Col>
//               <Col>
//                 <Label for="exampleDate">End Date</Label>
//                 <Input
//                   id="endDate"
//                   type="date"
//                   value={getEndDate}
//                   onChange={(e) => setGetEndDate(e.target.value)}
//                   style={{ cursor: "pointer" }}
//                 />
//               </Col>

//               {/* <Col>
//                 <Label>Shift Type</Label>
//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       value=""
//                       checked={shiftType === ""}
//                       onChange={(e) => setShiftType(e.target.value)}
//                       style={{ cursor: "pointer" }}
//                     />
//                     <Badge color="info">All</Badge>
//                   </Label>
//                 </FormGroup>
//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       value="E"
//                       checked={shiftType === "E"}
//                       onChange={(e) => setShiftType(e.target.value)}
//                       style={{ cursor: "pointer" }}
//                     />
//                     <Badge color="danger">E</Badge>
//                   </Label>
//                 </FormGroup>
//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       value="LD"
//                       checked={shiftType === "LD"}
//                       onChange={(e) => setShiftType(e.target.value)}
//                       style={{ cursor: "pointer" }}
//                     />
//                     <Badge color="secondary">LD</Badge>
//                   </Label>
//                 </FormGroup>
//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       value="N"
//                       checked={shiftType === "N"}
//                       onChange={(e) => setShiftType(e.target.value)}
//                       style={{ cursor: "pointer" }}
//                     />
//                     <Badge color="primary">N</Badge>
//                   </Label>
//                 </FormGroup>
//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       value="L"
//                       checked={shiftType === "L"}
//                       onChange={(e) => setShiftType(e.target.value)}
//                       style={{ cursor: "pointer" }}
//                     />
//                     <Badge color="success">L</Badge>
//                   </Label>
//                 </FormGroup>
//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       value="TW"
//                       checked={shiftType === "TW"}
//                       onChange={(e) => setShiftType(e.target.value)}
//                       style={{ cursor: "pointer" }}
//                     />
//                     <Badge color="warning">TW</Badge>
//                   </Label>
//                 </FormGroup>
//               </Col> */}


//               <Col>
//                 <Label>Credit Service type</Label>
//                 {/* <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       value=""
//                       checked={status === ""}
//                       onChange={(e) => setStatus(e.target.value)}
//                       style={{ cursor: "pointer" }}
//                     />
//                     <Badge color="info">All</Badge>
//                   </Label>
//                 </FormGroup> */}
//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       value="CLIENT_MAP"
//                       checked={serviceType === "CLIENT_MAP"}
//                       onChange={(e) => setServiceType(e.target.value)}
//                       style={{ cursor: "pointer" }}
//                     />
//                     <Badge color="danger">Client Map</Badge>
//                   </Label>
//                 </FormGroup>
//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       value="CHAT"
//                       checked={serviceType === "CHAT"}
//                       onChange={(e) => setServiceType(e.target.value)}
//                       style={{ cursor: "pointer" }}
//                     />
//                     <Badge color="secondary">Chat</Badge>
//                   </Label>
//                 </FormGroup>
//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       value="EMAIL"
//                       checked={serviceType === "EMAIL"}
//                       onChange={(e) => setServiceType(e.target.value)}
//                       style={{ cursor: "pointer" }}
//                     />
//                     <Badge color="primary">Email</Badge>
//                   </Label>
//                 </FormGroup>
//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       value="MESSAGE"
//                       checked={serviceType === "MESSAGE"}
//                       onChange={(e) => setServiceType(e.target.value)}
//                       style={{ cursor: "pointer" }}
//                     />
//                     <Badge color="warning">Message</Badge>
//                   </Label>
//                 </FormGroup>
//                 <FormGroup check>
//                   <Label check>
//                     <Input
//                       type="checkbox"
//                       value="TIME_SHEET"
//                       checked={serviceType === "TIME_SHEET"}
//                       onChange={(e) => setServiceType(e.target.value)}
//                       style={{ cursor: "pointer" }}
//                     />
//                     <Badge color="info">Time Sheet</Badge>
//                   </Label>
//                 </FormGroup>
                
//               </Col>


              
//               <Col>
//                 <div className="d-flex flex-column align-items-end">
//                   <Button
//                     color="success m-1 btn-sm"
//                     style={{ width: "70px" }}
//                     // onClick={handleSearch}
//                     onClick={() => {
//                       setSearch(!search);
//                     }}
//                   >
//                     Search
//                   </Button>
//                   <Button
//                     color="danger m-1 btn-sm"
//                     onClick={reset}
//                     style={{ width: "70px" }}
//                   >
//                     Reset
//                   </Button>
//                 </div>
//               </Col>
//             </Row>
//             <Row></Row>
//           </CardBody>
//         </Card>
//       </Collapse>
//       {error && (
//         <div>
//           <NoConnection error={error}></NoConnection>
//         </div>
//       )}

//       {rows && !loading ? (
//         filterByName(searchQuery, rows).lengthofSearch > 0 ? (
//           filterByName(
//             searchQuery,
//             rows.sort(SortFunctionAscending)
//           ).filtered.map((row, i) => {
//             return (
//               <animated.div style={animationProps} key={i}>
//                 <TransactionWidgetFilter
//                   key={i}
//                   userProfile={row}
//                 ></TransactionWidgetFilter>
//               </animated.div>
//             );
//           })
//         ) : (
//           <NoSearchResults />
//         )
//       ) : null}

//       {loading && !error && (
//         <div className="d-flex justify-content-center align-items-center">
//           <div>
//             <img src={loadingAnimation} style={{ height: 100 }}></img>
//           </div>
//         </div>
//       )}

      
//     </>
//   );
// };

// export default Transaction;

import React, { useState, useEffect } from "react";
import {
  Collapse,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Badge,
  FormGroup,
} from "reactstrap";

import {
  CLIENTS,
  WARDS,
  SHIFTS,
  CREATE_BOOKING,
  HOSTMexxar,
  ClientLable,
} from "../../../configs/api-config";

import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { forEach, isNull, filter } from "lodash";
import NoConnection from "../../errorPages/NoConnection";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { useIsMounted } from "../../../customHooks/useIsMounted";
import BookingSummeryWorkSpace from "../../../widgets/booking-summery-card-booking-workspace/booking-summery-card-booking-workspace";
import { FunnelIcon } from "../../../assets/icons/svg";
import ExternalShiftsCardWidget from "../../../widgets/working-card-widget/externalShiftsCardWidget";
import TransactionWidgetFilter from "./TransactionWidgetFilter";
// import { FormGroup } from "react-bootstrap";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((item) => {
      let searchableString = `${item.candidateName} ${item.referenceNo} ${item.candidatesId}   ${item.clientName} ${item.ward.name}   `;
      let p = searchableString;
      return p.toLowerCase().includes(name.toLowerCase());
    });
  }
  let lengthofSearch = filtered.length;
  return { filtered, lengthofSearch };
};
const Transaction = (props) => {
  const { incomingPage, candidateId, auth, employeeID } = props;

  const isMounted = useIsMounted();
  const MexxarApiShifts = SHIFTS;
  const MexxarApi = CREATE_BOOKING;
  const MexxarApiClients = CLIENTS;
  const MexxarApiDepartments = WARDS;

  const currentDate = new Date();

  const yesterDay = moment(moment(currentDate).subtract(1, "days")).format(
    "YYYY-MM-DD"
  );
  const tommorow = moment(moment(currentDate).add(10, "days")).format(
    "YYYY-MM-DD"
  );

  const [rows, setRows] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [error, setError] = useState(null);
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [initialRows, setInitialRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState([]);
  const [getEndDate, setGetEndDate] = useState(tommorow);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [getStartDate, setGetStartDate] = useState(yesterDay);
  const [serviceType, setServiceType] = useState("");

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  const getAllRows = () => {
    setLoading(true);
    setError(null);

    axios
      .get(HOSTMexxar + "transaction/getAll/filter",{
        params: {
          // status: status == "" ? null : status,
          startDate: getStartDate == undefined ? null : moment(getStartDate).format("YYYY/MM/DD"),
          endDate: getEndDate == undefined ? null : moment(getEndDate).format("YYYY/MM/DD"),
          creditServiceType: serviceType == "" ? null : serviceType
        }
      })
      .then((res) => {
        if (isMounted.current) {
          setLoading(false);
          setRows(res?.data?.body?.content);
          setInitialRows(res?.data?.body?.content);
        }
      })
      .catch((err) => {
        console.log("error----->", err);
        setError(err);

        //console.log(err)
        //toastCommon(true, "error");
      });
  };

  const [resetState, setResetState] = useState(false);

  const reset = () => {
    setGetStartDate(yesterDay);
    setGetEndDate(tommorow);
    setServiceType("");
    setResetState(!resetState);

    // getFieldData();
  };

  useEffect(() => {
    getAllRows();
    // getFieldData();
  }, [search, resetState]);

  const sortFunctionAscending = (a, b) => {
    var dateA = new Date(a.shiftFrom).getTime();
    var dateB = new Date(b.shiftFrom).getTime();
    return dateA > dateB ? 1 : -1;
  };

  return (
    <>
      {/* <p className="card p-3">
        External shifts for date range from {getStartDate} to {getEndDate}{" "}
      </p> */}
      <div className="form-inline">
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
       
      
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Start Date</Label>
                <Input
                  id="startDate"
                  type="date"
                  //defaultValue={getStartDate}
                  value={getStartDate}
                  onChange={(e) => setGetStartDate(e.target.value)}
                />
              </Col>
              <Col>
                <Label for="exampleDate">End Date</Label>
                <Input
                  id="endDate"
                  type="date"
                  value={getEndDate}
                  onChange={(e) => setGetEndDate(e.target.value)}
                />
              </Col>



              <Col>
                <Label>Credit Service type</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value=""
                      checked={serviceType === ""}
                      onChange={(e) => setServiceType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="info">All</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="CLIENT_MAP"
                      checked={serviceType === "CLIENT_MAP"}
                      onChange={(e) => setServiceType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="danger">Client Map</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="CHAT"
                      checked={serviceType === "CHAT"}
                      onChange={(e) => setServiceType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="secondary">Chat</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="EMAIL"
                      checked={serviceType === "EMAIL"}
                      onChange={(e) => setServiceType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="primary">Email</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check >
                  <Label check>
                    <Input
                      type="checkbox"
                      value="MESSAGE"
                      checked={serviceType === "MESSAGE"}
                      onChange={(e) => setServiceType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="warning">Message</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check > 
                  <Label check>
                    <Input
                      type="checkbox"
                      value="TIME_SHEET"
                      checked={serviceType === "TIME_SHEET"}
                      onChange={(e) => setServiceType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="info">Time Sheet</Badge>
                  </Label>
                </FormGroup>
              </Col>

              
              
              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    // onClick={handleSearch}
                    onClick={() => {
                      setSearch(!search);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    onClick={reset}
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}
      {rows && !loading ? (
        filterByName(searchQuery, rows).lengthofSearch > 0 ? (
          filterByName(
            searchQuery,
            rows.sort(sortFunctionAscending)
          ).filtered.map((row, i) => {
            return (
              <TransactionWidgetFilter
                key={i}
                userProfile={row}
                reset={() => setResetState(!resetState)}
              ></TransactionWidgetFilter>
            );
          })
        ) : (
          <NoSearchResults />
        )
      ) : null}
      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(Transaction);
