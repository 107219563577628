import React from "react";
import {
  Label,
  Row,
  Col,
  Badge,
  Input,
  Form,
  FormGroup,
  Button,
} from "reactstrap";
import moment from "moment";
import { ClientLable } from "../../configs/api-config";

function PayrollSummary(props) {
  const { summary, setShowSummary } = props;
  return (
    <div className="card" style={{ width: "100%" }}>
      <div className="card-body ">
        {/* <Form onSubmit={handleSubmit(onSubmit)} className="col-12"></Form> */}
        <Label className="font-weight-bold">Payroll summary</Label>
        <hr />
        <Form>
          <FormGroup>
            <Row className="my-3">
              <Col sm={12} md={6}>
                <Label>First Name </Label>{" "}
                <Badge className="mx-2"> Id : {summary.id}</Badge>
                <Input
                  type="text"
                  defaultValue={summary && summary.firstName}
                  disabled
                />
              </Col>
              <Col sm={12} md={6}>
                <Label>Candidate Role </Label>
                <Input
                  type="text"
                  defaultValue={summary && summary.roleName}
                  disabled
                />
              </Col>
            </Row>

            <Row className="my-3">
              <Col sm={12} md={6}>
                <Label>{ClientLable} Name </Label>
                <Input
                  type="text"
                  defaultValue={summary && summary.paySheets[0].hospitalName}
                  disabled
                />
              </Col>
              <Col sm={12} md={6}>
                <Label>Reference No </Label>
                <Input
                  type="text"
                  defaultValue={
                    summary && summary.paySheets[0].referenceNo.toString()
                  }
                  disabled
                />
              </Col>
            </Row>

            <Row className="my-3">
              <Col sm={12} md={6}>
                <Label>Working Hours </Label>
                <Input
                  type="text"
                  defaultValue={summary && summary.paySheets[0].working}
                  disabled
                />
              </Col>
              <Col sm={12} md={6}>
                <Label>Scanned Date </Label>
                <Input
                  type="date"
                  defaultValue={moment(summary && summary.scannedDate).format(
                    "YYYY-MM-DD"
                  )}
                  disabled
                />
              </Col>
            </Row>

            <Row className="my-3">
              <Col sm={12} md={6}>
                <Label>Employee Taxes (£)</Label>
                <Input
                  type="text"
                  defaultValue={summary && summary.paySheets[0].employeeTaxes}
                  disabled
                />
              </Col>
              <Col sm={12} md={6}>
                <Label>National Insurance (£) </Label>
                <Input
                  type="text"
                  defaultValue={
                    summary && summary.paySheets[0].nationalInsurance
                  }
                  disabled
                />
              </Col>
            </Row>

            <Row className="my-3 ">
              <Col sm={12} md={12}>
                <Label>Total Pay (£)</Label>
                <Input
                  type="text"
                  defaultValue={summary && summary.paySheets[0].payment}
                  disabled
                />
              </Col>
            </Row>
            <Row className="d-flex align-items-end justify-content-end">
              <Button
                color="success "
                className="mr-3"
                onClick={() => setShowSummary(false)}
              >
                Scan Again
              </Button>
            </Row>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
}

export default PayrollSummary;
