// import React, { useState } from "react";
// import JSZip from "jszip";
// import axios from "axios";
// import { Button } from "reactstrap";

// const FileDownloader = ({ fileUrls, folderName }) => {
//   const downloadFiles = async () => {
//     const zip = new JSZip();

//     const downloadPromises = fileUrls.map(async (url, index) => {
//       const response = await fetch(url);
//       const blob = await response.blob();

//       // Extract filename from the URL
//       const fileName = url.split("/").pop().split("?")[0];
//       zip.file(fileName, blob);
//     });

//     Promise.all(downloadPromises)
//       .then(() => {
//         zip.generateAsync({ type: "blob" }).then((content) => {
//           const zipFileName = `${folderName}.zip`;
//           const a = document.createElement("a");
//           a.href = window.URL.createObjectURL(content);
//           a.download = zipFileName;
//           document.body.appendChild(a);
//           a.click();
//           document.body.removeChild(a);
//         });
//       })
//       .catch((error) => console.error("Error downloading files:", error));
//   };

//   return (
//     <div>
//       <Button color="success" onClick={downloadFiles}>
//         Download Timesheets as Zip
//       </Button>
//     </div>
//   );
// };
// export default FileDownloader;

//Newly added file compression----

import React from "react";
import JSZip from "jszip";
import imageCompression from "browser-image-compression";
import { Button } from "reactstrap";

const FileDownloader = ({ fileUrls, folderName }) => {
  const downloadFiles = async () => {
    const zip = new JSZip();

    const downloadPromises = fileUrls.map(async (url) => {
      const response = await fetch(url);
      const blob = await response.blob();

      // Extract filename and extension from the URL
      const fileName = url.split("/").pop().split("?")[0];
      const fileExtension = fileName.split(".").pop().toLowerCase();

      // Check if the file is an image by its extension
      const isImage = ["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(
        fileExtension
      );

      if (isImage) {
        const mimeType = `image/${
          fileExtension === "jpg" ? "jpeg" : fileExtension
        }`;
        const imageFile = new File([blob], fileName, { type: mimeType });

        // Compress image using the File object
        const compressedBlob = await compressImage(imageFile);
        zip.file(fileName, compressedBlob);
      } else {
        zip.file(fileName, blob); // Add non-image files (e.g., PDFs) without compression
      }
    });

    Promise.all(downloadPromises)
      .then(() => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          const zipFileName = `${folderName}.zip`;
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(content);
          a.download = zipFileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      })
      .catch((error) => console.error("Error downloading files:", error));
  };

  // Image compression function
  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 0.8, // Compress to 800KB max
      maxWidthOrHeight: 1920, // Maintain aspect ratio but limit size
      useWebWorker: true, // Use web workers for faster compression
    };
    try {
      const compressedFile = await imageCompression(file, options);

      return compressedFile; // Return the compressed image blob
    } catch (error) {
      console.error("Image compression failed:", error);
      return file; // In case of error, return the original file
    }
  };

  return (
    <div>
      <Button color="success" onClick={downloadFiles}>
        Download Files as Zip
      </Button>
    </div>
  );
};

export default FileDownloader;
