import { useEffect, useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";

export default function useWorkspaceWithFilters(
  url,
  query,
  pageNumber,
  searchAdvanced,
  reset,
  employeeId,
  status = "ACTIVE",
  sort
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setRows([]);
  }, [query, reset]);

  useDidMountEffect(() => {
    setRows([]);
  }, [searchAdvanced, employeeId]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      url: url,
      params: {
        page: pageNumber,
        offset: 50,
        q: query,
        employeeId: employeeId,
        status: status,
        sort1: sort,
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        if (res.data.status == "success") {
          setTotalElements(res.data.body.totalElements);

          const sortedData = res.data.body.content.sort((a, b) => {
            var dateA = new Date(a.lastContactedDate).getTime();
            var dateB = new Date(b.lastContactedDate).getTime();
            return dateA - dateB;
          });

          setRows((prevBooks) => {
            return [...new Set([...prevBooks, ...sortedData])];
          });

          // setRows((prevBooks) => {
          //   return [...new Set([...prevBooks, ...res.data.body.content])];
          // });
          setHasMore(res.data.body.content.length > 0);
        } else {
          setError(true);
        }

        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setLoading(false);
        setError(true);
      });
    return () => cancel();
  }, [searchAdvanced, query, pageNumber, reset, employeeId]);

  return { loading, error, rows, hasMore, totalElements };
}
