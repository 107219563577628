export default function copyToClipboard(text) {
  navigator.clipboard.writeText(text).then(
    function () {
      return true;
    },
    function (err) {
      console.error("Unable to copy text to clipboard", err);

      return false;
    }
  );
}
