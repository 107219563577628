import React from "react";

export const PermDocUploadedStatus = (data) => {
  let badgeColor;
  let badgeLable;
  switch (data) {
    case "NOT_APPLICABLE":
      badgeLable = "Not applicable";
      badgeColor = "teal";
      break;
    case "NOT_UPLOADED":
      badgeLable = "Not uploaded";
      badgeColor = "#f4c414";
      break;
    case "UPLOADED":
      badgeLable = "Uploaded";
      badgeColor = "#31c971";
      break;
  }

  return { badgeColor: badgeColor, badgeLable: badgeLable };
};
