import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Spinner } from "reactstrap";

export default function DocumentDownloading({ onCancel, showCancel = true }) {
  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <FontAwesomeIcon icon={faFileDownload} size="4x" />
      <div className="d-flex justify-content-center align-items-center mt-4">
        <Spinner
          className="mr-3"
          animation="border"
          style={{
            height: "20px",
            width: "20px",
            marginRight: "5px",
          }}
        />
        <span>Please wait while we download your document(s)</span>
      </div>
      {showCancel && (
        <Button className="btn btn-danger mt-3" onClick={onCancel}>
          Cancel
        </Button>
      )}
    </div>
  );
}
