import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faTimes,
  faExpandAlt,
  faFileAlt,
  faTimesCircle,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

import { useForm } from "react-hook-form";

import { useDropzone } from "react-dropzone";
import { split, forEach, filter } from "lodash";

import { useToasts } from "react-toast-notifications";


import {
  Button,
  Label,
  FormGroup,
  Col,

} from "reactstrap";
import ToastCommon from "../../../components/toastCommon";
import { HOSTMexxar } from "../../../configs/api-config";



function UploadPackDocuments({ closeModal, getAllRows }) {


  const { addToast } = useToasts();
  const [fileTypeofUpload, setFileTypeofUpload] = useState("");

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
    padding: 5,
  };
  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const thumb = {
    position: "relative",
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbButton = {
    position: "absolute",
    right: 10,
    bottom: 10,
    background: "rgba(0,0,0,.8)",
    color: "#fff",
    border: 0,
    borderRadius: ".325em",
    cursor: "pointer",
  };


  const onDrop = useCallback((acceptedFiles) => {
    let splitedArray = split(acceptedFiles[0].type, "/");
    let document_TYPE = splitedArray[1];
    setFileTypeofUpload(document_TYPE);

    let totalSize = 0;
    forEach(acceptedFiles, function (file) {
      totalSize = file.size + totalSize;
    });
    // files over 50 mb (Total file size)cannot upload
    if (totalSize > 50000000) {
      setFileOversize(true);
    } else {
      setFileOversize(false);
    }

    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);



  const clearFilesStates = () => {
    setFiles([]);
   
  };


  function readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
    });
  }







  const thumbs = (file, id) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="" />
      </div>
    </div>
  );

  const [files, setFiles] = useState([]);
  const [fileOversize, setFileOversize] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: '.pdf, .png, .jpg, .jpeg',
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0].size > 50 * 1024 * 1024) { 
        setFileOversize(true);
      } else {
        setFileOversize(false);
        setFiles(acceptedFiles);
        setSelectedFile(acceptedFiles[0]);
      }
    }
  });

  const uploadDocument = () => {
    if (!selectedFile) {
      ToastCommon(true, "error", addToast);
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    axios
      .post(HOSTMexxar + 'documents/app-pack-candy/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "success", addToast);
          getAllRows(); 
          closeModal(); 
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        
        ToastCommon(true, "error", addToast);
      });
  };






 
 

           return (
   

                <div
                  className={"d-flex justify-content-center align-items-center"}
                >
                  <FormGroup row>
                    <Col sm={12}>
                   

                      <div
                        className="d-flex justify-content-center align-items-center  "
                        style={{
                          border: "solid 2px  ",
                          borderRadius: "2px",
                          borderColor: "#007bff",
                          width: "460px",
                          height: files.length > 0 ? "50px" : "260px",
                        }}
                        {...getRootProps()}
                      >
                        <input id="dropZone" {...getInputProps()} />

                        <>
                          {isDragActive ? (
                            <p className="m-3 ">Drop the file here ...</p>
                          ) : (
                            <p className="m-3">
                              Drag 'n' drop PDF file here, or click to select
                              file
                            </p>
                          )}
                        </>
                      </div>
                      <div>
                        {files.length > 0 && !fileOversize ? (
                          <div
                            className="d-flex justify-content-center align-items-center p-2"
                            style={{ overflowX: "auto" }}
                          >
                            {files.map((file, id) => {
                              let splitedArray = split(file.type, "/");
                              let document_TYPE = splitedArray[1];

                              if (document_TYPE == "pdf") {
                                return (
                                  <div
                                    key={id}
                                    className="justify-content-center d-flex  flex-column align-items-center p-2"
                                  >
                                    <div>
                                      <FontAwesomeIcon
                                        icon={faFilePdf}
                                        className="fa-5x "
                                      />
                                    </div>
                                    <div>
                                      <small>{file.name} </small>
                                    </div>
                                  </div>
                                );
                              } else if (
                                document_TYPE == "png" ||
                                document_TYPE == "jpg" ||
                                document_TYPE == "JPEG" ||
                                document_TYPE == "jpeg"
                              ) {
                                return (
                                  <div key={id} style={thumbsContainer}>
                                    {thumbs(file, id)}
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    key={id}
                                    className="justify-content-center d-flex  flex-column align-items-center p-2"
                                  >
                                    <div>
                                      <FontAwesomeIcon
                                        icon={faFileAlt}
                                        className="fa-5x "
                                      />
                                    </div>
                                    <div>
                                      {file.name.length > 10 ? (
                                        "File " + id
                                      ) : (
                                        <small>{file.name}</small>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        ) : fileOversize ? (
                          <div className="d-flex justify-content-center mt-2 flex-column align-items-center">
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="fa-3x"
                            ></FontAwesomeIcon>
                            <Label>
                              File exceed limit of 50mb please reduce the files
                              or choose a different size{" "}
                            </Label>
                          </div>
                        ) : null}
                      </div>

                      <div className="d-flex mt-1 mx-2 justify-content-center align-items-center mt-3">
                        <Button
                          className="mx-2 bg-success"
                          onClick={uploadDocument}
                          
                        >
                          Upload
                        </Button>
                        <Button
                          className="mx-2"
                          onClick={closeModal}
                        >
                          Close
                        </Button>
                      </div>
                    </Col>
                  </FormGroup>
                </div>
            
      
  );
}



export default UploadPackDocuments;
