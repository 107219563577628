import React, { useState } from "react";

import DashboardLayout from "../../layouts/dashboard-layout/dashboard";

import { ClientLable } from "../../configs/api-config";
import TabNavigationMenu from "../../util/common/tabNavigation";
import ClientProfiles from "../clients/clientProfiles";
import BulkEmailReports from "./bulkEmailReports";

const BulkReports = () => {
  const [currentTab, setCurrentTab] = useState("bulkReports");

  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <>
      <DashboardLayout
        title={`Bulk Email Reports`}
        subTitle={`View Your Bulk Reports`}
      >
        {/* <div>testtttttttttt</div> */}
        <TabNavigationMenu
          activated={currentTab}
          onChangeItem={switchTabHandler}
          itemList={[
            { name: "Bulk Reports", key: "bulkReports" },
            // { name: "Bookings", key: "bookings" },        //only allow for temp staffing
          ]}
        />

        {currentTab === "bulkReports" && <BulkEmailReports />}
        {/* {currentTab === "bookings" && (
          <ClientBookings incomingPage="clientData" />
        )} */}
      </DashboardLayout>
    </>
  );
};

export default BulkReports;
