import { ADD_FILTERED_HEADHUNTER_WORKSPACE } from "./types";

export const addFilteredData = (data) => (dispatch) => {
  dispatch({
    type: ADD_FILTERED_HEADHUNTER_WORKSPACE,
    payload: data,
  });
};
  


