import React, { useState, useEffect } from "react";
import {
  Label,
  Form,
  FormGroup,
  Col,
  Button,
  Spinner,
  Input,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import store from "../../redux/store";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { forEach, filter, findIndex, isEmpty, isNull } from "lodash";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../components/toastCommon";
import useCandidateList from "../../customHooks/useCandidateList";
import { REGISTER_CANDIDATE, HOSTMexxar } from "../../configs/api-config";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { clientsGetAction } from "../../redux/actions/initialDataGetAction";
import Store from "../../redux/store";
import { saveFiledDataAction } from "../../redux/actions/saveFiledDataAction";
import { updateTimesheetReviewedDataCandy } from "../../redux/actions/timesheetBatchScanAction";

function CandyTimesheetBatchView(props) {
  const {
    // processTimeSheet,
    processLoading,
    selectedCandidate,
    setSelectedCandidate,
    incommingAIdata,
    systemTimeSheetData,
    timeSheetExist,
    reduxClients,
    retry,
    refNum,
    newRefNumber,
    bookingNum,

    selectedFile,
    setPayrollData,
    setShowSummary,
    setPayrollError,
    user,
    timesheet,
    verified,
  } = props;

  const MexxarCandidateApi = REGISTER_CANDIDATE;
  const { register, handleSubmit, errors, clearErrors, reset } = useForm();
  const dispatch = useDispatch();

  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [candidates, setCandidates] = useState([]);
  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  let url = MexxarCandidateApi + "/status/" + filterCustom.value;

  const { loading, error, rows, hasMore } = useCandidateList(
    url,
    filterCustom,
    query,
    pageNumber
  );
  // console.log("ref", refNum)
  const [departmentError, setDepartmentError] = useState(false);
  const [selectedSpeciality, setSelectedSpeciality] = useState(0);
  const [specialities, setSpecialities] = useState([{ id: 0, name: "Select" }]);

  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [departments, setDepartments] = useState([{ id: 0, name: "Select" }]);

  const [refNo, setRefNo] = useState(null);
  const [fieldData, setFieldData] = useState(null);
  const [bookingRefNumber, setBookingRefNumber] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clients, setClients] = useState([{ id: 0, name: "Select" }]);

  const [clientCantFind, setClientCantFind] = useState(false);
  const [departmentCantFind, setDepartmentCantFind] = useState(false);
  const [specialityCantFind, setSpecialityCantFind] = useState(false);

  const [pdfError, setAiError] = useState(false);
  const [aiPending, setAiPending] = useState(false);

  const { addToast } = useToasts();
  const [update, setUpdate] = useState(false);
  const [rateTypes, setRateTypes] = useState([
    { type: "Rate Week Days", key: 0, shortForm: "WEEK" },
    { type: "Rate Saturday", key: 1, shortForm: "SAT" },
    ,
    { type: "Rate Sunday", key: 2, shortForm: "SUN" },
    { type: "Rate Bank Holiday", key: 3, shortForm: "BH" },
  ]);
  const [currentDepartment, setCurrentDepartment] = useState(-1);
  const handleInputChange = (e) => {
    setQuery(e);
  };

  //----------prepare candidates to popultate in dorp down select---------//
  const prepareCandidates = () => {
    let listOfCandidates = [];
    forEach(rows, function (row) {
      listOfCandidates.push({
        value: row.id,
        label: `${row.firstName} ${row.lastName}`,
        id: row.id,
        candidate: row,
      });
    });

    if (query != "") {
      setCandidates(listOfCandidates);
      //console.log("angi process 105----------->", listOfCandidates);
      setSelectedCandidate(listOfCandidates[0]);
    } else {
      setSelectedCandidate([]);
      setCandidates([]);
    }
  };

  const getClientDepartments = (data) => {
    if (data) {
      axios
        .get(HOSTMexxar + "client-wards/clients/" + data)
        .then((res) => {
          let tempDep = [];
          forEach(res.data.body, function (department) {
            tempDep.push({
              id: department.wardId,
              name: department.wardName,
            });
          });
          setDepartments(tempDep);
        })
        .catch((err) => {
          setDepartmentError(err);
          // console.log("err", err);
          // toastCommon(true, "error");
        });
    }
  };

  //--------------[process flow no--00]--------------//

  // const prepareFiledData = () => {
  //   let tempFields;

  //   //when AI scanned ref no is not matching with excisting db bookings data

  //   if (!timeSheetExist) {
  //     if (incommingAIdata) {
  //       tempFields = {
  //         referenceNo: incommingAIdata.Ref,
  //         referenceNo: incommingAIdata.bookingRef,
  //         date: incommingAIdata.Date,

  //         candidate: {
  //           id: incommingAIdata.CandidateId,
  //           value: incommingAIdata.CandidateName,
  //           lable: incommingAIdata.CandidateName,
  //         },
  //         candidateId: incommingAIdata.CandidateId,
  //         startDate: moment(incommingAIdata.Date).format("YYYY-MM-DD"),
  //         startTime: moment(incommingAIdata.Start, "HH:mm").format("HH:mm"),
  //         endDate: moment(incommingAIdata.Date).format("YYYY-MM-DD"),
  //         endTime: moment(incommingAIdata.End, "HH:mm").format("HH:mm"),
  //         client: {
  //           // id: incommingAIdata.clientId,
  //           name: incommingAIdata.HospitalName,
  //         },
  //         department: {
  //           // id: incommingAIdata.wardId,
  //           name: incommingAIdata.Ward,
  //         },
  //         speciality: incommingAIdata.Speciality,
  //         upLift: 0,
  //         bonus: 0,
  //         minsTakenForBreaks: incommingAIdata.Breakmin,
  //         totalHours: incommingAIdata.Totalhours,
  //       };
  //     }
  //   } else {
  //     //when AI scanned refno. is matching with excisting system bookings

  //     if (systemTimeSheetData) {
  //       tempFields = {
  //         referenceNo: systemTimeSheetData.referenceNo,
  //         date: systemTimeSheetData.bookingDate,
  //         candidate: {
  //           id: systemTimeSheetData.candidatesId,
  //           value: systemTimeSheetData.candidateName,
  //           lable: systemTimeSheetData.candidateName,
  //         },
  //         candidateId: systemTimeSheetData?.candidatesId,
  //         startDate: moment(systemTimeSheetData?.shiftFrom).format(
  //           "YYYY-MM-DD"
  //         ),
  //         startTime: moment(systemTimeSheetData?.shiftFrom).format("HH:mm"),
  //         endDate: moment(systemTimeSheetData?.shiftFrom).format("YYYY-MM-DD"),
  //         endTime: moment(systemTimeSheetData?.shiftTo).format("HH:mm"),
  //         client: {
  //           id: systemTimeSheetData.clientId,
  //           name: systemTimeSheetData.clientName,
  //         },
  //         department: {
  //           id: systemTimeSheetData.ward.id,
  //           name: systemTimeSheetData.ward.name,
  //         },
  //         speciality: systemTimeSheetData.speciality,
  //         upLift: systemTimeSheetData.upLift,
  //         bonus: systemTimeSheetData.bonus,
  //       };
  //     }
  //   }

  //   Store.dispatch(saveFiledDataAction(tempFields));
  //   setFieldData(tempFields);
  // };

  // const prepareFiledData = () => {
  //   let tempFields;
  //   console.log("dhanajali2",incommingAIdata)
  //   if (!timeSheetExist) {
  //     if (incommingAIdata && incommingAIdata.data) {
  //       tempFields = {
  //         referenceNo: incommingAIdata.data.bookingref?.value,
  //         date: incommingAIdata.data.date?.value,
  //         candidate: {
  //           id: incommingAIdata.data.candidatename?.value,
  //           value: incommingAIdata.data.firstname?.value,
  //           label: incommingAIdata.data.firstname?.value,
  //         },
  //         candidateId: incommingAIdata.data.candidatename?.value,
  //         startDate: moment(incommingAIdata.data.date?.value, "DD/MM/YY").format("YYYY-MM-DD"),
  //         startTime: moment(incommingAIdata.data.starttime?.value, "HH:mm").format("HH:mm"),
  //         endDate: moment(incommingAIdata.data.date?.value, "DD/MM/YY").format("YYYY-MM-DD"),
  //         endTime: moment(incommingAIdata.data.endtime?.value, "HH:mm").format("HH:mm"),
  //         client: {
  //           name: incommingAIdata.data.clientname?.value,
  //         },
  //         department: {
  //           name: incommingAIdata.data.ward?.value,
  //         },
  //         speciality: incommingAIdata.data.type?.value,
  //         upLift: 0,
  //         bonus: 0,
  //         break: incommingAIdata.data.break?.value,
  //         totalHours: incommingAIdata.data.totalhours?.value,
  //       };
  //     }
  //   } else {

  //     if (systemTimeSheetData) {
  //       tempFields = {
  //         referenceNo: systemTimeSheetData.referenceNo,
  //         date: systemTimeSheetData.bookingDate,
  //         candidate: {
  //           id: systemTimeSheetData.candidatesId,
  //           value: systemTimeSheetData.candidateName,
  //           lable: systemTimeSheetData.candidateName,
  //         },
  //         candidateId: systemTimeSheetData?.candidatesId,
  //         startDate: moment(systemTimeSheetData?.shiftFrom).format(
  //           "YYYY-MM-DD"
  //         ),
  //         startTime: moment(systemTimeSheetData?.shiftFrom).format("HH:mm"),
  //         endDate: moment(systemTimeSheetData?.shiftFrom).format("YYYY-MM-DD"),
  //         endTime: moment(systemTimeSheetData?.shiftTo).format("HH:mm"),
  //         client: {
  //           id: systemTimeSheetData.clientId,
  //           name: systemTimeSheetData.clientName,
  //         },
  //         department: {
  //           id: systemTimeSheetData.ward.id,
  //           name: systemTimeSheetData.ward.name,
  //         },
  //         speciality: systemTimeSheetData.speciality,
  //         upLift: systemTimeSheetData.upLift,
  //         bonus: systemTimeSheetData.bonus,
  //       };
  //     }

  //   }

  //   // if (tempFields) {
  //   //   Store.dispatch(saveFiledDataAction(tempFields));
  //   //   setFieldData(tempFields);
  //   // }
  //   // Store.dispatch(saveFiledDataAction(tempFields));
  //   setFieldData(tempFields);
  // };

  const handleCandidateChange = (data) => {
    // console.log("selectedCandidate angi --->data", data);
    setQuery(data.value);
    // setSelectedCandidate(data);
    // setClients(data.candidate?.preferredClients);
    // setSpecialities(data.candidate?.candidateSpecialities);
    // getClientDepartments(data.candidate?.preferredClients[0]?.id);
  };

  //*--------------prepare end date ------------------*//
  //*--------------if the end time is after 12 midnight add one date to the start date ------------------*//

  function prepEndDate(data) {
    let sTime = data.startTime.split(":")[0];
    let eTime = data.endTime.split(":")[0];

    let endDate;

    if (sTime < eTime) {
      endDate = moment(data.startDate).format("DD/MM/YYYY");
    } else {
      endDate = moment(data.startDate).add(1, "days").format("DD/MM/YYYY");
    }
    return endDate;
  }
  const typeFinder = (data) => {
    let shortForm;
    let type;
    let key;
    switch (data) {
      case "WEEK":
        shortForm = data;
        type = "Rate Week Days";
        key = 0;
        break;
      case "SAT":
        shortForm = data;
        type = "Rate Saturday";
        key = 1;
        break;
      case "SUN":
        shortForm = data;
        type = "Rate Sunday";
        key = 2;
        break;
      case "BH":
        shortForm = data;
        type = "Rate Bank Holiday";
        key = 3;
        break;
    }

    return { shortForm: shortForm, type: type, key: key };
  };

  const onSubmit = async (data) => {
    try {
      console.log("Submitted data:", data);

      // ... (existing code)
      const randomNumber = Math.floor(1000 + Math.random() * 9000);
      const randomNumber2 = Math.floor(1000 + Math.random() * 9000);

      const date = new Date(data?.startDate);

      // console.log("Day type:", dayType);

      const startTime = new Date(`2000-01-01T${data?.startTime}`);
      const endTime = new Date(`2000-01-01T${data?.endTime}`);

      const dayStartTime = new Date(`2000-01-01T06:00:00`);
      const nightStartTime = new Date(`2000-01-01T18:00:00`);

      let payrollDayMode;
      let dayRate;
      let nightRate;
      let rate;
      let nightChargeRate;
      let dayChargeRate;
      let chargeRate;

      if (startTime >= nightStartTime || endTime <= dayStartTime) {
        payrollDayMode = "n";
      } else {
        payrollDayMode = "d";
      }
      console.log("Payroll Day Mode submit:", payrollDayMode);
      console.log(
        "angi candidate",
        candidates[0]?.candidate?.candidateRole?.id
      );
      console.log("cliend id", clients[data?.clientIndex]?.name);
      console.log("speciality", specialities[data?.specialityIndex]?.id);
      console.log("ward", departments[data?.departmentIndex]?.id);
      console.log("band", candidates[0]?.candidate?.candidateBand?.id);
      console.log("client id", clients[data?.clientIndex]?.id);

      const clientId = clients[data?.clientIndex]?.id;
      const specialityId = specialities[data?.specialityIndex]?.id;
      const roleId = candidates[0]?.candidate?.candidateRole?.id;
      const departmentId = departments[data?.departmentIndex]?.id;
      const bandId = candidates[0]?.candidate?.candidateBand?.id;
      const candidateId = candidates[0]?.candidate?.id;

      let form = new FormData();
      form.append("file", selectedFile.file);

      //let result = await getClientRates(clientId, departmentId, bandId, specialityId);
      let result = await getCandidateRates(
        clientId,
        specialityId,
        roleId,
        bandId,
        candidateId
      );
      if (
        result[0].nRate == "" ||
        result[0].dRate == "" ||
        result[0].dChargeRate == "" ||
        result[0].nChargeRate == ""
      ) {
        console.log(
          "No data from the first function. Calling the second function."
        );

        result = await getClientRates(
          clientId,
          departmentId,
          bandId,
          specialityId
        );

        // let result = await getCandidateRates(clientId, specialityId, roleId, bandId, candidateId);
      }

      if (!result || result.length === 0) {
        console.log(
          "No data from getClientRatesDefault. Trying getCandidateRates."
        );
        result = await getClientRatesDefault(
          clientId,
          departmentId,
          bandId,
          specialityId
        );
      }

      const dayOfWeek = date.getDay();
      let dayType;

      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        dayType = typeFinder("WEEK");
      } else if (dayOfWeek === 0) {
        dayType = typeFinder("SUN");
      } else if (dayOfWeek === 6) {
        dayType = typeFinder("SAT");
      } else {
        dayType = typeFinder("BH");
      }

      console.log("Day Type :", dayType.type);

      console.log(`Rate Week Days ==> nRate for Rate Week Days: ${dayType}`);

      // Check if result is valid before iterating
      if (result && result.length > 0) {
        result.forEach((item) => {
          console.log("type", item);
          if (item.type === dayType.type) {
            console.log(`Rates Day types: ${item.type}`);
            nightRate = item.nRate;
            dayRate = item.dRate;
            nightChargeRate = item.nChargeRate;
            dayChargeRate = item.dChargeRate;

            if (payrollDayMode === "n") {
              rate = nightRate;
              chargeRate = nightChargeRate;
              console.log("Night Rate:", rate);
              console.log("Night Charge Rate:", chargeRate);
            } else {
              rate = dayRate;
              chargeRate = dayChargeRate;
              console.log("Day Rate:", rate);
              console.log("Day Charge Rate:", chargeRate);
            }

            console.log("Payroll Day Mode check:", payrollDayMode);
          }
        });

        // let timesheet = {

        //   bookingRef:data?.bookingRef,
        //   candidatename:data?.candidatename,
        //         candidateposition:data?.candidateposition,
        //        starttime:data?.starttime,
        //     break:data?.break,
        //    endtime:data?.endtime,
        //   totalhours:data?.totalhours,

        //         clientname:data?.clientname,

        //         consultantname:data?.consultantname,
        //     type:data?.type,
        //    day:data?.day,
        //       clientauthname:data?.clientauthname,
        //         firstname:data?.firstname,
        //         surname:data?.surname,

        //         ward:data?.ward,
        //         appraisal:data?.appraisal,

        // };

        let timesheet = {
          locumName: candidates[0]?.label,
          locumId: candidates[0]?.id,
          speciality: specialities[data?.specialityIndex]?.id,
          band: candidates[0]?.candidate?.candidateBand?.id,
          hospitalName: clients[data?.clientIndex]?.name,
          dateOfShift: data?.startDate,
          ward: departments[data?.departmentIndex]?.id,
          bookingRef: data?.refNo,
          startTime: data?.startTime,
          endTime: data?.endTime,
          minsTakenForBreaks: data?.minsTakenForBreaks,
          totalHours: data?.totalHours,
          date: data?.date,
          authoriseName: "",
          authorisePosition: "",
          authoriseDate: data?.startDate,
          bookingId: systemTimeSheetData?.id,
          hospitalReviewStatus: 0,
          paymentStatus: 0,
          reviewStatus: 4,
          nightRate: nightRate,
          dayRate: dayRate,
          nightChargeRate: nightChargeRate,
          dayChargeRate: dayChargeRate,
          rate: rate,
          dayType: dayType,
        };

        let exportObject = {
          PaymentPeriod: "",
          ClientID: clients[data.clientIndex]?.id,
          MeritID: randomNumber,
          ExternalID: candidates[0]?.id,
          MeritPlacementID: randomNumber2,
          ExternalPlacementID: "",
          WorkDescription: "Temp Staff",
          PayDescription: "Basic Pay Temp Staff",
          Quantity: data?.totalHours,
          PayRate: rate,
          ChargeRate: chargeRate,
          Reference: data?.refNo,
          Ref2: "",
          Startdate: data?.startDate,
          Enddate: "",
          Comments: "",
          EndUserClient: "",
          ComparatorHolidayDays: "",
          PaymentCurrencyCode: "GBP",
          InvoiceCurrencyCode: "",
          VatAdjust: "",
          Consultant: user,
          dayType: dayType,
        };

        console.log("Updated Timesheet Data:", timesheet);
        console.log("Updated Export Object:", exportObject);
        let dateTimeNow = new Date();

        axios.post(HOSTMexxar + "timesheets/", timesheet).then((res) => {
          if (res.data.status == "success" && res.data.message == "created") {
            updateBookingIfNotMatching(data, res, form);
            dispatch(
              updateTimesheetReviewedDataCandy(
                selectedFile.DBData.referenceNo,
                exportObject,
                dateTimeNow
              )
            );
            verified(selectedFile);
          } else if (
            res.data.status == "success" &&
            res.data.message == "updated"
          ) {
            ToastCommon(true, "update", addToast);
            updateBookingIfNotMatching(data, res, form);
            dispatch(
              updateTimesheetReviewedDataCandy(
                selectedFile.DBData.referenceNo,
                exportObject,
                dateTimeNow
              )
            );
            verified(selectedFile);
            setSavedRefNo(data);
            setSavedRefNo2(data);
            handleButtonClick(data?.refNo);
          } else if (res.data.status == "failed") {
            ToastCommon(true, "error", addToast);
          }
        });

        // })
      } else {
        console.log("No data from getClientRates.");
      }
    } catch (error) {
      console.error("Error in onSubmit:", error);
    }
  };

  //*--------------verify document-------------------*//
  //--------------[process flow no--01]--------------//
  //   const onSubmit = (data) => {
  //     console.log("dhanajali")
  //     // Generate a 4-digit random number

  //     const randomNumber = Math.floor(1000 + Math.random() * 9000);
  //     const randomNumber2 = Math.floor(1000 + Math.random() * 9000);
  //     const firstValue = Math.floor(20 + Math.random() * 8);
  //     const secondValue = Math.floor(firstValue + 3 + Math.random() * 5);

  //     let form = new FormData();
  //     form.append("file", selectedFile.file);

  //     axios.post(HOSTMexxar + "timesheets/", timesheet).then((res) => {
  //       if (res.data.status == "success" && res.data.message == "created") {
  //         // ToastCommon(true, "success", addToast);

  //         // updateBookingIfNotMatching(data, res, form);

  //         // dispatch(
  //         //   updateTimesheetReviewedData(selectedFile.file.preview, exportObject)
  //         // );
  //       } else if (
  //         res.data.status == "success" &&
  //         res.data.message == "updated"
  //       ) {
  //         ToastCommon(true, "update", addToast);
  //         // updateBookingIfNotMatching(data, res, form);

  //         // dispatch(
  //         //   updateTimesheetReviewedData(selectedFile.file.preview, exportObject)
  //         // );
  //         setSavedRefNo(data);

  //         setSavedRefNo2(data);

  // console.log('setsaved ref check', data);
  // console.log('setsavd2 data saved', savedRefNo2);
  //         handleButtonClick(data?.refNo);
  //         getBucketUrl();
  //       } else if (res.data.status == "failed") {
  //         ToastCommon(true, "error", addToast);
  //       }
  //     });
  //   };

  const getClientRatesDefault = async (
    clientId,
    currentDepartment,
    bandId,
    specialityId,
    roleId
  ) => {
    let tempUpdatingArray = [];
    let tempArray = [];

    let defaultWardID = departments.find((x) => x.name === "Default Ward"); //retrive default ward ID

    try {
      //offset is set to 17 since only 16 combinations are coming for the rates
      const res = await axios.get(
        `${HOSTMexxar}client-rates/filter?offset=17&clientId=${clientId}&candidateSpeciality=${1}&roleId=${1}&clientWardId=${
          defaultWardID.id
        }&bandId=${1}`
      );

      if (res.data.body?.content?.length > 0) {
        setUpdate(true);
      } else {
        setUpdate(false);
      }

      let response = res.data.body.content;

      let DayType = ["WEEK", "SAT", "SUN", "BH"];
      let DayNight = ["DAY", "NIGHT"];
      let DefaultSpecial = ["D", "NIGHT"];

      forEach(DayType, function (daytype) {
        //......filter Day Rate.........//

        let ByDayType = filter(response, (item) => {
          return item.dayType === daytype;
        });
        console.log(`Client ID: ${clientId}`);

        // let DayNight = filter(response, (item) => {
        //   return item.dayType === daytype;
        // });

        let DayNightNight = filter(ByDayType, (item) => {
          return item.payRollDayMode === "NIGHT";
        });

        let DayNightDay = filter(ByDayType, (item) => {
          return item.payRollDayMode === "DAY";
        });

        // Special

        let RateModeSpecial = filter(ByDayType, (item) => {
          return item.rateMode === "SPECIAL";
        });

        let RateModeSpecialDay = filter(RateModeSpecial, (item) => {
          return item.payRollDayMode === "DAY";
        });

        let RateModeSpecialNight = filter(RateModeSpecial, (item) => {
          return item.payRollDayMode === "NIGHT";
        });
        // Default

        let RateModeDefault = filter(ByDayType, (item) => {
          return item.rateMode === "DEFAULT";
        });

        let RateModeDefaultDay = filter(RateModeDefault, (item) => {
          return item.payRollDayMode === "DAY";
        });

        let RateModeDefaultNight = filter(RateModeDefault, (item) => {
          return item.payRollDayMode === "NIGHT";
        });

        tempArray.push({
          type: typeFinder(daytype).type,
          key: typeFinder(daytype).key,
          shortForm: daytype,

          nId: null,
          dId: null,
          sDId: null,
          sNId: null,

          nStart: null,
          nEnd: null,
          nRate: null,

          dStart: null,
          dEnd: null,
          dRate: null,

          bRate: null,
        });

        tempUpdatingArray.push({
          type: typeFinder(daytype).type,
          key: typeFinder(daytype).key,
          shortForm: daytype,

          nId:
            RateModeDefaultNight[0] === undefined
              ? ""
              : Number(RateModeDefaultNight[0].id),
          dId:
            RateModeDefaultDay[0] === undefined
              ? ""
              : Number(RateModeDefaultDay[0].id),
          sDId:
            RateModeSpecialDay[0] === undefined
              ? ""
              : Number(RateModeSpecialDay[0].id),
          sNId:
            RateModeSpecialNight[0] === undefined
              ? ""
              : Number(RateModeSpecialNight[0].id),

          nStart:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].startTime,
          nEnd:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].endTime,
          nRate:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].rate,
          nChargeRate:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].chargeRate,

          dStart:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].startTime,
          dEnd:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].endTime,
          dRate:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].rate,
          dChargeRate:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].chargeRate,

          bRate:
            RateModeSpecial[0] === undefined ? "" : RateModeSpecial[0].rate,
          bChargeRate:
            RateModeSpecial[0] === undefined
              ? ""
              : RateModeSpecial[0].chargeRate,
        });

        console.log(
          "angi client rates response------ default3----->",
          tempUpdatingArray
        );

        setRateTypes(tempArray);
        setRateTypes(tempUpdatingArray);
      });

      return tempUpdatingArray;
    } catch (error) {
      console.error("Error fetching client rates:", error);

      return [];
    }
  };

  const getCandidateRates = async (
    clientId,
    specialityId,
    roleId,
    bandId,
    candidateId
  ) => {
    let tempUpdatingArray = [];
    let tempArray = [];

    try {
      const res = await axios.get(
        `${HOSTMexxar}candidate-rates/filter?offset=17&clientId=${clientId}&candidateSpeciality=${specialityId}&roleId=${roleId}&clientWardId=${currentDepartment}&bandId=${bandId}&candidateId=${candidateId}`
      );

      if (res.data.body?.content?.length > 0) {
        setUpdate(true);
      } else {
        setUpdate(false);
      }

      let response = res.data.body?.content;
      let DayType = ["WEEK", "SAT", "SUN", "BH"];
      let DayNight = ["DAY", "NIGHT"];
      let DefaultSpecial = ["D", "NIGHT"];

      forEach(DayType, function (daytype) {
        //......filter Day Rate.........//

        // console.log("Res .........", daytype);

        let ByDayType = filter(response, (item) => {
          return item.dayType === daytype;
        });
        // console.log("A .........", ByDayType);

        // let DayNight = filter(response, (item) => {
        //   return item.dayType === daytype;
        // });
        // console.log("DayNight .........", DayNight);

        let DayNightNight = filter(ByDayType, (item) => {
          return item.payRollDayMode === "NIGHT";
        });
        // console.log("DayNightNight.........", DayNightNight);

        let DayNightDay = filter(ByDayType, (item) => {
          return item.payRollDayMode === "DAY";
        });
        // console.log("DayNightDay.........", DayNightDay);

        // Special

        let RateModeSpecial = filter(ByDayType, (item) => {
          return item.rateMode === "SPECIAL";
        });
        // console.log("RateModeSpecial .........", RateModeSpecial);

        let RateModeSpecialDay = filter(RateModeSpecial, (item) => {
          return item.payRollDayMode === "DAY";
        });
        // console.log("RateModeSpecialDay.........", RateModeSpecialDay);

        let RateModeSpecialNight = filter(RateModeSpecial, (item) => {
          return item.payRollDayMode === "NIGHT";
        });
        // console.log("RateModeSpecialNight.........", RateModeSpecialNight);

        // Default

        let RateModeDefault = filter(ByDayType, (item) => {
          return item.rateMode === "DEFAULT";
        });
        // console.log("RateModeDefault .........", RateModeDefault);

        let RateModeDefaultDay = filter(RateModeDefault, (item) => {
          return item.payRollDayMode === "DAY";
        });
        // console.log("RateModeDefaultDay.........", RateModeDefaultDay);

        let RateModeDefaultNight = filter(RateModeDefault, (item) => {
          return item.payRollDayMode === "NIGHT";
        });
        // console.log("RateModeDefaultNight.........", RateModeDefaultNight);

        tempArray.push({
          type: typeFinder(daytype).type,
          key: typeFinder(daytype).key,
          shortForm: daytype,

          nId: null,
          dId: null,
          sDId: null,
          sNId: null,

          nStart: null,
          nEnd: null,
          nRate: null,

          dStart: null,
          dEnd: null,
          dRate: null,

          bRate: null,
        });

        tempUpdatingArray.push({
          type: typeFinder(daytype).type,
          key: typeFinder(daytype).key,
          shortForm: daytype,

          nId:
            RateModeDefaultNight[0] === undefined
              ? ""
              : Number(RateModeDefaultNight[0].id),
          dId:
            RateModeDefaultDay[0] === undefined
              ? ""
              : Number(RateModeDefaultDay[0].id),
          sDId:
            RateModeSpecialDay[0] === undefined
              ? ""
              : Number(RateModeSpecialDay[0].id),
          sNId:
            RateModeSpecialNight[0] === undefined
              ? ""
              : Number(RateModeSpecialNight[0].id),

          nStart:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].startTime,
          nEnd:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].endTime,
          nRate:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].rate,

          dStart:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].startTime,
          dEnd:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].endTime,
          dRate:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].rate,

          bRate:
            RateModeSpecial[0] === undefined ? "" : RateModeSpecial[0].rate,
        });

        console.log(
          "angi client rates response------3----->",
          tempUpdatingArray
        );

        setRateTypes(tempArray);
        setRateTypes(tempUpdatingArray);
      });

      return tempUpdatingArray;
    } catch (error) {
      console.error("Error fetching client rates:", error);

      return [];
    }
  };

  const getClientRates = async (
    // candidateId,
    clientId,
    currentDepartment,
    bandId,
    specialityId,
    roleId
  ) => {
    let tempUpdatingArray = [];
    let tempArray = [];

    try {
      const res = await axios.get(
        `${HOSTMexxar}client-rates/filter?offset=17&clientId=${clientId}&candidateSpeciality=${specialityId}&clientWardId=${currentDepartment}&bandId=${bandId}
        
       
        `
      );

      if (res.data.body?.content?.length > 0) {
        setUpdate(true);
      } else {
        setUpdate(false);
      }

      let response = res.data.body.content;

      let DayType = ["WEEK", "SAT", "SUN", "BH"];
      let DayNight = ["DAY", "NIGHT"];
      let DefaultSpecial = ["D", "NIGHT"];

      forEach(DayType, function (daytype) {
        //......filter Day Rate.........//

        let ByDayType = filter(response, (item) => {
          return item.dayType === daytype;
        });
        console.log(`Client ID: ${clientId}`);

        // let DayNight = filter(response, (item) => {
        //   return item.dayType === daytype;
        // });

        let DayNightNight = filter(ByDayType, (item) => {
          return item.payRollDayMode === "NIGHT";
        });

        let DayNightDay = filter(ByDayType, (item) => {
          return item.payRollDayMode === "DAY";
        });

        // Special

        let RateModeSpecial = filter(ByDayType, (item) => {
          return item.rateMode === "SPECIAL";
        });

        let RateModeSpecialDay = filter(RateModeSpecial, (item) => {
          return item.payRollDayMode === "DAY";
        });

        let RateModeSpecialNight = filter(RateModeSpecial, (item) => {
          return item.payRollDayMode === "NIGHT";
        });
        // Default

        let RateModeDefault = filter(ByDayType, (item) => {
          return item.rateMode === "DEFAULT";
        });

        let RateModeDefaultDay = filter(RateModeDefault, (item) => {
          return item.payRollDayMode === "DAY";
        });

        let RateModeDefaultNight = filter(RateModeDefault, (item) => {
          return item.payRollDayMode === "NIGHT";
        });

        tempArray.push({
          type: typeFinder(daytype).type,
          key: typeFinder(daytype).key,
          shortForm: daytype,

          nId: null,
          dId: null,
          sDId: null,
          sNId: null,

          nStart: null,
          nEnd: null,
          nRate: null,

          dStart: null,
          dEnd: null,
          dRate: null,

          bRate: null,
        });

        tempUpdatingArray.push({
          type: typeFinder(daytype).type,
          key: typeFinder(daytype).key,
          shortForm: daytype,

          nId:
            RateModeDefaultNight[0] === undefined
              ? ""
              : Number(RateModeDefaultNight[0].id),
          dId:
            RateModeDefaultDay[0] === undefined
              ? ""
              : Number(RateModeDefaultDay[0].id),
          sDId:
            RateModeSpecialDay[0] === undefined
              ? ""
              : Number(RateModeSpecialDay[0].id),
          sNId:
            RateModeSpecialNight[0] === undefined
              ? ""
              : Number(RateModeSpecialNight[0].id),

          nStart:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].startTime,
          nEnd:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].endTime,
          nRate:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].rate,
          nChargeRate:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].chargeRate,

          dStart:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].startTime,
          dEnd:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].endTime,
          dRate:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].rate,
          dChargeRate:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].chargeRate,

          bRate:
            RateModeSpecial[0] === undefined ? "" : RateModeSpecial[0].rate,
          bChargeRate:
            RateModeSpecial[0] === undefined
              ? ""
              : RateModeSpecial[0].chargeRate,
        });

        console.log(
          "angi client rates response------3----->",
          tempUpdatingArray
        );

        setRateTypes(tempArray);
        setRateTypes(tempUpdatingArray);
      });

      return tempUpdatingArray;
    } catch (error) {
      console.error("Error fetching client rates:", error);

      return [];
    }
  };

  // const onSubmit = (data) => {

  //   console.log("Submitted data:", data);

  //   // Generate random values if needed

  //   const randomNumber = Math.floor(1000 + Math.random() * 9000);
  //   const randomNumber2 = Math.floor(1000 + Math.random() * 9000);
  //   const firstValue = Math.floor(20 + Math.random() * 8);
  //   const secondValue = Math.floor(firstValue + 3 + Math.random() * 5);

  //   let timesheet = {
  //     locumName: candidates[0]?.label,
  //     locumId: candidates[0]?.id,
  //     speciality: specialities[data?.specialityIndex]?.id,
  //     bookingRef: data?.refNo,
  //     candidatename: data?.candidatename,
  //     candidateposition: data?.candidateposition,
  //     starttime: data?.starttime,
  //     break: data?.break,
  //     endtime: data?.endtime,
  //     totalhours: data?.totalhours,
  //     clientname: data?.clientname,
  //     consultantname: data?.consultantname,
  //     type: data?.type,
  //     day: data?.day,
  //     clientauthname: data?.clientauthname,
  //     firstname: data?.firstname,
  //     surname: data?.surname,
  //     ward: data?.ward,
  //     bookingId: systemTimeSheetData?.id,
  //     // appraisal: data?.appraisal,
  //   };

  //   let form = new FormData();
  //   form.append("file", selectedFile.file);

  //   axios.post(HOSTMexxar + "timesheets/", timesheet).then((res) => {
  //     if (res.data.status == "success" && res.data.message == "created") {

  //       // updateBookingIfNotMatching(data, res, form);

  //       // dispatch(
  //       //   updateTimesheetReviewedData(selectedFile.file.preview, exportObject)
  //       // );
  //     } else if (
  //       res.data.status == "success" &&
  //       res.data.message == "updated"
  //     ) {
  //       ToastCommon(true, "update", addToast);
  //       // updateBookingIfNotMatching(data, res, form);

  //       // dispatch(
  //       //   updateTimesheetReviewedData(selectedFile.file.preview, exportObject)
  //       // );
  //       setSavedRefNo(data);

  //       setSavedRefNo2(data);

  // console.log('setsaved ref check', data);
  // console.log('setsavd2 data saved', savedRefNo2);
  //       handleButtonClick(data?.refNo);
  //       getBucketUrl();
  //     } else if (res.data.status == "failed") {
  //       ToastCommon(true, "error", addToast);
  //     }
  //   }
  //     )

  //     ;
  // };

  const [matchedRefNo, setMatchedRefNo] = useState(null);
  const [ref3, setRef3] = useState(null);
  const [savedRefNo, setSavedRefNo] = useState(null);

  const [savedRefNo2, setSavedRefNo2] = useState(null);

  const handleButtonClick = async () => {
    try {
      const originalFileName = selectedFile.file.name;
      const fileExtension = originalFileName.split(".").pop();

      const fileName = refNum + "." + fileExtension;

      const newFilename = newRefNumber + "." + fileExtension;

      await changeFilename(fileName, newFilename);
    } catch (error) {
      console.error("Error:", error);
    }
    console.log("selected file check", selectedFile.file);
  };

  const changeFilename = async (fileName, newFileName) => {
    try {
      const changeFilenameFormData = new FormData();
      changeFilenameFormData.append("fileName", fileName);
      changeFilenameFormData.append("newFileName", newFileName);

      const changeFilenameResponse = await axios.put(
        "https://time.mexxar.com/timesheet/file/upload",
        changeFilenameFormData,
        {
          params: {
            bucketName: "mexxar-timesheet",
            folderName: "mex_candy",
          },
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
          mode: "cors",
        }
      );

      if (changeFilenameResponse.status === 200) {
        console.log("Filename changed successfully", changeFilenameResponse);
      } else {
        console.error(
          "Failed to change filename",
          changeFilenameResponse.status
        );
        console.error("Error message:", changeFilenameResponse.data);
      }
    } catch (error) {
      console.error("Error changing filename:", error.message);
    }
  };

  const [generatedUrl, setGeneratedUrl] = useState("");
  const getBucketUrl = async () => {
    try {
      const originalFileName = selectedFile.file.name;
      const fileExtension = originalFileName.split(".").pop();

      const fileName = bookingNum + "." + fileExtension;

      const response = await axios.get(
        "https://time.mexxar.com/timesheet/url",
        {
          params: {
            fileName: fileName,
            folderName: "mex_candy",
            linkDuration: "10",
          },
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
          mode: "cors",
        }
      );

      if (response.status === 200) {
        const url = response.data.body.url;
        setGeneratedUrl(url);
        console.log("URL generated successfully:", url);
      } else {
        console.error("Failed to generate URL", response.status);
        console.error("Error message:", response.data.message);
      }
    } catch (error) {
      console.error("Error generating URL:", error.message);
    }
  };

  //*--------------Update booking details in the system if even one data is miss matching with verifying data------------------*//
  //--------------[process flow no--02]--------------//

  const updateBookingIfNotMatching = (data, response, form) => {
    let shiftSTime = moment(data.startDate).add(data.startTime).toDate();
    let shiftETime = moment(data.startDate).add(data.endTime).toDate();

    let arrangData = {
      id: systemTimeSheetData.id,
      bookingDate: moment(data.startDate).format("YYYY-MM-DD"),
      referenceNo: data.refNo,
      clientsId: Number(clients[data.clientIndex].id),
      //  employeeId: auth.item.id,
      // specialityId: Number(candidateSpecialities[0].id),
      specialityId: Number(specialities[data.specialityIndex].id),
      wardId: Number(departments[data.departmentIndex].id),
      shiftTo: shiftETime,
      shiftFrom: shiftSTime,
    };

    // if any field is not matching with system booking details, update it

    if (
      systemTimeSheetData.referenceNo != data.refNo ||
      systemTimeSheetData.candidatesId != candidates[0].id ||
      moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
        moment(data.startDate).format("YYYY-MM-DD") ||
      moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
        moment(data.startTime).format("HH:mm") ||
      moment(systemTimeSheetData.shiftTo).format("YYYY-MM-DD") !=
        moment(data.endTime).format("YYYY-MM-DD") ||
      moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
        moment(prepEndDate(data)).format("HH:mm") ||
      systemTimeSheetData.clientId != clients[data.clientIndex].id ||
      systemTimeSheetData.ward.id != departments[data.departmentIndex].id ||
      systemTimeSheetData.speciality.id != specialities[data.specialityIndex].id
    ) {
      axios
        .put(HOSTMexxar + "bookings", arrangData)
        .then((res) => {
          if (res.data.status == "success") {
            ToastCommon(true, "success", addToast);
            uploadTimesheet(data, response, form);
          } else if (res.data.status == "failed") {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {});
    }
    // else if fields  are matching with system booking details, upload the Timesheet
    else {
      uploadTimesheet(data, response, form);
    }
  };

  //*--------------upload timesheet------------------*//

  const uploadTimesheet = (data, res, form) => {
    axios
      .put(
        HOSTMexxar +
          "timesheets/" +
          res.data.body[0].id +
          "/upload-timesheetDocument",
        form
      )
      .then((res) => {
        if (res.data.status == "success" && res.data.message == "fetched") {
          ToastCommon(true, "timesheetUploaded", addToast);
          // processTimesheetData(data);
        } else if (res.data.status == "failed") {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  //*--------------process timesheet for payroll calculations------------------*//
  //--------------[process flow no--04]--------------//

  const processTimesheetData = (item) => {
    setAiPending(true);

    let tempData = [
      {
        bookingReference: item.refNo,
        startTime: item.startTime + ":00",
        endTime: item.endTime + ":00",
        startDate: moment(item.startDate).format("DD/MM/YYYY"),
        endDate: prepEndDate(item),
      },
    ];

    axios
      .post(HOSTMexxar + "workspace/payroll", tempData)
      .then((res) => {
        setAiPending(false);
        if (res.data.status == "success") {
          ToastCommon(true, "timesheetProcessed", addToast);
          setPayrollData(res.data.body);
          setShowSummary(true);
        } else if (res.data.status == "failed") {
          setPayrollError(res.data.status);
          ToastCommon(true, "timesheetProcessedError", addToast);
        }
      })
      .catch((err) => {
        setAiError(true);
      });
  };

  useEffect(() => {
    // console.log("angi process 1");
    prepareFiledData();
  }, [timeSheetExist, systemTimeSheetData]);

  useDidMountEffect(() => {
    // console.log("angi process 2");

    prepareCandidates();
    return () => {
      Store.dispatch(saveFiledDataAction(null));
    };
  }, [rows, timeSheetExist]);

  useDidMountEffect(() => {
    if (fieldData) {
      //  console.log("angi process 3");
      setQuery(fieldData.candidate.value);
    }
  }, [fieldData]);

  useDidMountEffect(() => {
    // console.log("angi process 4----------->", selectedCandidate);
    if (selectedCandidate) {
      //  console.log("angi process 4.1", selectedCandidate?.candidate?.preferredClients);
      setClients(selectedCandidate?.candidate?.preferredClients || []);
      setSpecialities(
        selectedCandidate?.candidate?.candidateSpecialities || []
      );
      getClientDepartments(
        selectedCandidate?.candidate?.preferredClients[0]?.id
      );
    } else {
      //  console.log("angi process 4.2");
      setClients([{ id: 0, name: "Select" }]);
      setSpecialities([{ id: 0, name: "Select" }]);
    }
  }, [selectedCandidate]);

  useDidMountEffect(() => {
    if (fieldData && clients) {
      //  console.log("angi process 5");
      let index = findIndex(clients, (item) => {
        //    console.log("angi clients", item);
        //    console.log("angi fieldData", fieldData);
        return item.name == fieldData.client.name;
      });

      if (index === -1) {
        //   console.log("angi process 5.1");
        setClientCantFind(true);
      } else {
        //   console.log("angi process 5.2");
        setClientCantFind(false);
        setSelectedClient(index);
      }
    }
  }, [clients]);

  // useDidMountEffect(() => {
  //   if (fieldData && specialities) {
  //    // console.log("angi process 6 ");
  //     let index = findIndex(specialities, (item) => {
  //       return item.name == fieldData.speciality.name;
  //     });

  //     if (index === -1) {
  //     //  console.log("angi process 6.1");
  //       setSpecialityCantFind(true);
  //     } else {
  //     //  console.log("angi process 6.2");
  //       setSpecialityCantFind(false);
  //       setSelectedSpeciality(index);
  //     }
  //   }
  // }, [specialities]);

  useDidMountEffect(() => {
    if (fieldData && fieldData.speciality && specialities) {
      let index = findIndex(specialities, (item) => {
        return item.name == fieldData.speciality.name;
      });

      if (index === -1) {
        setSpecialityCantFind(true);
      } else {
        setSpecialityCantFind(false);
        setSelectedSpeciality(index);
      }
    }
  }, [specialities, fieldData]);

  useDidMountEffect(() => {
    // console.log("angi process 7");
    store.dispatch(clientsGetAction());
  }, [systemTimeSheetData]);

  useDidMountEffect(() => {
    // {
    //   clients &&
    //     selectedClient &&
    //     clients[selectedClient] != undefined &&
    //    console.log("angi process 8");
    // }
    {
      clients &&
        selectedClient &&
        clients[selectedClient] != undefined &&
        getClientDepartments(clients[selectedClient].id);
    }
  }, [selectedClient]);

  useDidMountEffect(() => {
    if (fieldData && departments) {
      // console.log("angi process 10 ");
      let index = findIndex(departments, (item) => {
        return item.name == fieldData.department.name;
      });

      if (index === -1) {
        //   console.log("angi process 10.1");
        setDepartmentCantFind(true);
      } else {
        //   console.log("angi process 10.2");
        setDepartmentCantFind(false);
        setSelectedDepartment(index);
      }
    }
  }, [departments]);

  useDidMountEffect(() => {
    setFieldData(null);
    setQuery("");
    setSelectedDepartment(0);
    setDepartments([{ id: 0, name: "Select" }]);
    reset();
  }, [selectedFile.file]);

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#121212",
    }),
  };
  const prepareFiledData = () => {
    let tempFields;

    //when AI scanned ref no is not matching with excisting db bookings data

    if (!timeSheetExist) {
      if (incommingAIdata) {
        tempFields = {
          bookingRef: incommingAIdata.bookingref,

          // bookingRef: incommingAIdata.bookingRef,
          date: incommingAIdata.startDate,
          candidate: {
            id: incommingAIdata.CandidateId,
            value: incommingAIdata.CandidateName,
            lable: incommingAIdata.CandidateName,
          },
          candidateId: incommingAIdata.CandidateId,
          startDate: moment(incommingAIdata.Date).format("YYYY-MM-DD"),

          starttime: moment(incommingAIdata.starttime, "HH:mm").format("HH:mm"),
          endtime: moment(incommingAIdata.endtime, "HH:mm").format("HH:mm"),
          // starttime: moment(incommingAIdata.starttime, "HH:mm").format("HH:mm"),
          // endDate: moment(incommingAIdata.Date).format("YYYY-MM-DD"),
          // endtime: moment(incommingAIdata.endtime, "HH:mm").format("HH:mm"),
          // startDate: moment(incommingAIdata.Date).format("YYYY-MM-DD"),
          // startTime: moment(incommingAIdata.Start, "HH:mm").format("HH:mm"),
          // endDate: moment(incommingAIdata.Date).format("YYYY-MM-DD"),
          // endTime: moment(incommingAIdata.End, "HH:mm").format("HH:mm"),
          clientname: incommingAIdata.clientname,
          client: {
            // id: incommingAIdata.clientId,
            name: incommingAIdata.HospitalName,
          },

          department: {
            // id: incommingAIdata.wardId,
            name: incommingAIdata.Ward,
          },
          ward: incommingAIdata.ward,
          type: incommingAIdata.type,
          upLift: 0,
          bonus: 0,
          minsTakenForBreaks: incommingAIdata.Breakmin,
          totalHours: incommingAIdata.Totalhours,
        };
      }
    } else {
      //when AI scanned refno. is matching with excisting system bookings

      if (systemTimeSheetData) {
        tempFields = {
          referenceNo: systemTimeSheetData.referenceNo,
          date: systemTimeSheetData.bookingDate,
          candidate: {
            id: systemTimeSheetData.candidatesId,
            value: systemTimeSheetData.candidateName,
            lable: systemTimeSheetData.candidateName,
          },
          candidateId: systemTimeSheetData?.candidatesId,
          startDate: moment(systemTimeSheetData?.shiftFrom).format(
            "YYYY-MM-DD"
          ),
          startTime: moment(systemTimeSheetData.shiftFrom).format("HH:mm"),
          endTime: moment(systemTimeSheetData.shiftTo).format("HH:mm"),

          // startTime: moment(systemTimeSheetData?.shiftFrom).format("HH:mm"),
          endDate: moment(systemTimeSheetData?.shiftFrom).format("YYYY-MM-DD"),
          // endTime: moment(systemTimeSheetData?.shiftTo).format("HH:mm"),
          client: {
            id: systemTimeSheetData.clientId,
            name: systemTimeSheetData.clientName,
          },
          department: {
            // id: systemTimeSheetData.ward.id,
            name: systemTimeSheetData.ward.name,
          },
          speciality: systemTimeSheetData.speciality,
          upLift: systemTimeSheetData.upLift,
          bonus: systemTimeSheetData.bonus,
        };
      }
    }

    Store.dispatch(saveFiledDataAction(tempFields));
    setFieldData(tempFields);
  };
  console.log("angi props--->", props);
  return (
    <div className="card" style={{ width: "100%" }}>
      <div className="card-body ">
        <Form onSubmit={handleSubmit(onSubmit)} className="col-12">
          <FormGroup
            row
            className="d-flex justify-content-between align-items-center"
          >
            <Label className="ml-3">Process Timesheet </Label>
            <small>
              Please note that the data below is populated against the candidate
              identified from the candidate field.
            </small>
          </FormGroup>

          <hr />

          <FormGroup row>
            <Col md={`${timeSheetExist ? 12 : 10}`}>
              <Label>Ref No</Label>

              <Input
                type="text"
                defaultValue={fieldData && fieldData.referenceNo}
                placeholder="Ref No"
                id="refNo"
                name="refNo"
                onChange={(e) => setRefNo(e.target.value)}
                invalid={
                  !timeSheetExist ||
                  (incommingAIdata &&
                    systemTimeSheetData &&
                    incommingAIdata.bookingRef != systemTimeSheetData.Ref)
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
            {!timeSheetExist && (
              <Col md={2} className="d-flex align-items-end ">
                <Button id="retry" color="success" onClick={() => retry(refNo)}>
                  Retry
                </Button>

                <UncontrolledTooltip target="retry">
                  Retry to find reference id
                </UncontrolledTooltip>
              </Col>
            )}
          </FormGroup>

          <FormGroup row>
            <Col md={12}>
              <Label>Candidate</Label>
              <Select
                id="candidateId"
                name="candidateId"
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedCandidate}
                onChange={(data) => handleCandidateChange(data)}
                isLoading={loading}
                options={candidates}
                placeholder="Search candidates ..."
                onInputChange={handleInputChange}
                noOptionsMessage={() => "No results found"}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
                styles={colourStyles}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={6}>
              <Label>Start Date</Label>
              <Input
                type="Date"
                defaultValue={fieldData && fieldData.date}
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
                    moment(incommingAIdata.date).format("YYYY-MM-DD")
                }
                placeholder="Start Time"
                id="startDate"
                name="startDate"
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
                  moment(incommingAIdata.date).format("YYYY-MM-DD") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>

                      {moment(incommingAIdata.date).format("L")}
                    </li>
                  </ul>
                )}
            </Col>
            <Col md={6}>
              <Label>Day</Label>
              <Input
                type="text"
                id="day"
                name="day"
                defaultValue={incommingAIdata && incommingAIdata.day}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              ></Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={6}>
              <Label>Start Time</Label>

              <Input
                type="time"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
                    moment(incommingAIdata.startTime, "HH:mm").format("HH:mm")
                }
                defaultValue={incommingAIdata && incommingAIdata.starttime}
                // defaultValue={fieldData && fieldData.starttime}
                id="starttime"
                name="startTime"
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
                  moment(incommingAIdata.starttime, "HH:mm").format(
                    "HH:mm"
                  ) && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>
                      {moment(incommingAIdata.starttime, "HH:mm").format("LT")}
                    </li>
                  </ul>
                )}
            </Col>
            <Col md={6}>
              <Label>End Time</Label>

              {/* <Input
                type="endtime"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
                    moment(incommingAIdata.endtime, "HH:mm").format("HH:mm")
                }
                placeholder="End Time"
                id="endtime"
                name="endtime"
                defaultValue={fieldData && fieldData.endtime}
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
                  moment(incommingAIdata.End, "HH:mm").format("HH:mm") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>

                      {moment(incommingAIdata.End, "HH:mm").format("LT")}
                    </li>
                  </ul>
                )} */}

              <Input
                type="time"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
                    moment(incommingAIdata.endtime, "HH:mm").format("HH:mm")
                }
                placeholder="End Time"
                id="endTime"
                name="endTime"
                defaultValue={incommingAIdata && incommingAIdata.endtime}
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
                  moment(incommingAIdata.endtime, "HH:mm").format("HH:mm") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>

                      {moment(incommingAIdata.endtime, "HH:mm").format("LT")}
                    </li>
                  </ul>
                )}
            </Col>
          </FormGroup>

          <FormGroup row>
            {console.log(
              "angi total hours",
              selectedFile?.manuallyReviewed,
              selectedFile?.reviewedData?.Quantity
            )}{" "}
            <Col sm={6}>
              <Label>Total Hours</Label>
              <Input
                type="text"
                placeholder="Total Hours"
                id="totalhours"
                name="totalhours"
                defaultValue={
                  selectedFile?.manuallyReviewed
                    ? selectedFile?.reviewedData?.Quantity
                    : incommingAIdata && incommingAIdata.totalhours
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
            <Col sm={6}>
              <Label>Break Mins.</Label>
              <Input
                type="text"
                placeholder="Break "
                id="break"
                name="break"
                defaultValue={incommingAIdata && incommingAIdata.break}
                invalid={errors.minsTakenForBreaks}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md={12}>
              <Label>Client</Label>

              <Input
                type="select"
                id="clientIndex"
                name="clientIndex"
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
                invalid={
                  !timeSheetExist ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.clientName !==
                      incommingAIdata.clientname)
                }
              >
                {clients.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      style={{ color: "#6A6767" }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Input>

              {clientCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData.clientName?.toLowerCase() !=
                    incommingAIdata.clientname?.toLowerCase() && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.clientname}
                      </li>
                    </ul>
                  ))}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col sm={6}>
              <Label>Ward</Label>
              <Input
                type="select"
                id="departmentIndex"
                name="departmentIndex"
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
                invalid={
                  departmentCantFind ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.ward?.name?.toLowerCase() !=
                      incommingAIdata.ward?.toLowerCase())
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              >
                {departments.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      style={{ color: "#6A6767" }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Input>

              {departmentCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData.ward?.name?.toLowerCase() !=
                    incommingAIdata.ward?.toLowerCase() && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.ward}
                      </li>
                    </ul>
                  ))}
            </Col>
            <Col md={6}>
              <Label>Speciality</Label>
              <Input
                type="select"
                id="specialityIndex"
                name="specialityIndex"
                value={selectedSpeciality}
                onChange={(e) => setSelectedSpeciality(e.target.value)}
                invalid={
                  specialityCantFind ||
                  (incommingAIdata &&
                    systemTimeSheetData &&
                    incommingAIdata?.type?.toLowerCase() !=
                      systemTimeSheetData?.speciality?.name?.toLowerCase())
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              >
                {specialities.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      style={{ color: "#6A6767" }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Input>

              {specialityCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData?.speciality?.name?.toLowerCase() !=
                    incommingAIdata?.type?.toLowerCase() && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.type}
                      </li>
                    </ul>
                  ))}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md={12}>
              <Alert color="info">
                <strong className="text-danger">*</strong> The text in{" "}
                <strong className="text-danger">red</strong> represent
                recognized hand written text{" "}
              </Alert>
            </Col>
            <Col sm={12} className="d-flex justify-content-end mr-5 ">
              {isEmpty(systemTimeSheetData) && (
                <Alert color="danger">
                  We couldn't find a booking with the provided reference number.
                  Please ensure that you have the correct reference number for
                  the relevant booking made for above date and update it if it
                  doesn't match with the timesheet.
                </Alert>
              )}
              {isNull(systemTimeSheetData?.referenceNo) && (
                <Alert color="danger" className="mx-2">
                  We couldn't find the booking reference number. Please enter
                  the relevant reference number above.{" "}
                </Alert>
              )}
              <Button color={"success"} type="submit">
                {aiPending && (
                  <Spinner
                    animation="border"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                  />
                )}
                Verify
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    reduxSpecialities: state.initialDataGetReducer.specialities,
    reduxClients: state.initialDataGetReducer.clients,
    reduxRoles: state.initialDataGetReducer.roles,
    user: state.auth.item.firstName + " " + state.auth.item.lastName,
    timesheet: state.timesheets.timesheets,
  };
}

export default connect(mapStateToProps, {})(CandyTimesheetBatchView);
