import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Badge, Button, Input, Form, Label, FormGroup, Col } from "reactstrap";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { HOSTMexxar } from "../../../configs/api-config";
import ToastCommon from "../../../components/toastCommon";
import ModalComponent from "../../../components/Modal/modalComponent";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import loadingAnimation from "../../../../src/images/puff.svg";
import NewOneField from "../../../components/Modal/ModalBody/Admin/newOneField";
import AddCredit from "../../../components/Modal/ModalBody/Admin/addCredits";
import AddCreditTransaction from "../../../components/Modal/ModalBody/Admin/addCreditTransaction";
import AddAgency from "../../../components/Modal/ModalBody/Admin/addAgency";
import AddAgencyCredit from "../../../components/Modal/ModalBody/Admin/addAgencyCredit";
import TopUpAgencyCredit from "../../../components/Modal/ModalBody/Admin/topUpAgencyCredit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Delete from "../../../components/Modal/ModalBody/delete";
import { connect } from "react-redux";
import TabNavigationMenu from "../../../util/common/tabNavigation";
import TimesheetsReview from "../../payroll/timesheets-review";
import Transaction from "./transaction";
import Credit from "./credit";
import TransactionAll from "./transactionAll";


const CreditSystemSuper = (props) => {
  const {  auth } = props;
  const MexxarApi = HOSTMexxar;
  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();
  const [showAddRow, setShowAddRow] = useState(false);
  const [showAddRowTransaction, setShowAddRowTransaction] = useState(false);
  const [showAddAgencyCredit, setShowAddAgencyCredit] = useState(false);
  const [showAddAgency, setShowAddAgency] = useState(false);
  const [showAddTopUpAgencyCredit, setShowTopUpAgencyCredit] = useState(false);
  const [row, setRowId] = useState();
  const [rows, setRows] = useState(null);
  const [allCredits, setRowsCredit] = useState(null);
  const [rowscredit, setRowsCreditId] = useState(null);
  const [rowsTransaction, setRowsTransactionId] = useState(null);
  const [rowsDateRange, setRowsTransactionDates] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [creditId, setCreditNumber] = useState('');
  const [transactionId, setTransactionId] = useState('');
  const [startDate, setStarttDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [creditIdIdDateRange, setCreditIdIdDateRange] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [creditDetails, setRowsCreditsDetails] = useState('');
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [rowID, setRowID] = useState("");
  const [showDeleteRow1, setShowDeleteRow1] = useState(false);
  const [rowID1, setRowID1] = useState("");
  const [agencyId, setAgencyId] = useState("");
  const [currentTab, setCurrentTab] = useState("transaction");



  const tab = window.location.hash.substring(1);

  useEffect(() => {
    if (tab === "") {
      setCurrentTab("transaction")
    } else {
      setCurrentTab(tab)
    }
  }, [tab])


  
  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };

console.log("auth",auth?.item?.id)
  const handleLabelClick = () => {
    document.getElementById('accountNumber').focus();
  };


  const getAllTransactions = () => {
    axios
      .get(MexxarApi + "transaction")
      .then((res) => {
        setRows(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };

  const getAllCredits = () => {
    axios
      .get(MexxarApi + "credit")
      .then((res) => {
        setRowsCredit(res.data.body);
       
      })
      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };

  const getAllTransactionsByCreditId = () => {
    axios
      .get(`${MexxarApi}transaction/getTransactionByCreditId/${creditId}`)
      .then((res) => {
        setRowsCreditId(res.data.body);
        console.log("rowcredit", res.data.body)
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };

  const getAllTransactionsById = () => {
    axios
      .get(`${MexxarApi}transaction/${transactionId}`)
      .then((res) => {
        setRowsTransactionId(res.data.body);
        console.log("rowtransaction", res.data.body)
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };


  const deleteTransaction = () => {
    axios
      .delete(MexxarApi + "transaction/delete/" + rowID)
      .then((res) => {
        // getAllRows();
        getAllTransactions();
        ToastCommon(true, "delete", addToast);
      })
      .catch((err) => {
        //console.log(err)
        //ToastCommon(true, "update", addToast);
      });
  };

  const deleteCredit = () => {
    axios
      .delete(MexxarApi + "credit/deleteCredit/" + rowID1)
      .then((res) => {
        // getAllRows();
        getAllCredits();
        ToastCommon(true, "delete", addToast);
      })
      .catch((err) => {
        //console.log(err)
        //ToastCommon(true, "update", addToast);
      });
  };
  const getCreditDetails = () => {
    axios
      .get(`${MexxarApi}credit/getCreditByEmployeeId/${auth?.item?.id}`)
      .then((res) => {
        setRowsCreditsDetails(res.data.body);
        console.log("getCreditDetails", res.data.body)
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };
  const handleInputChangeStartDate = (e) => {
    const formattedDate = e.target.value.replace(/-/g, '/');
    setEndDate(moment(formattedDate).format("YYYY/MM/DD"));
  };

  const handleInputChangeEndDate = (e) => {
    const formattedDate = e.target.value.replace(/-/g, '/');
    setEndDate(moment(formattedDate).format("YYYY/MM/DD"));
  };
  const getAllTransactionsByDateRange = () => {
    axios
      .get(`${MexxarApi}transaction/getByDateRange/${creditId}`, {
        params: {
          startDate: startDate,
          endDate: endDate,
        }
      })
      .then((res) => {
        setRowsTransactionDates(res.data.body);
        console.log("rowtransaction", res.data.body)
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };
  console.log("rowtransaction", rowsTransaction)

  const handleGoButtonClick = () => {
    getAllTransactionsByCreditId();
  };
  const handleGoButtonClickTransaction = () => {
    getAllTransactionsById();
  };
  const handleGoButtonClickDateRange = () => {
    getAllTransactionsByDateRange();
  };

  const handleGoButtonClickCreditDetails = () => {
    getCreditDetails();
  };
  const handleReset = () => {
    setCreditNumber('');
    setRowsCreditId([]);
    setTransactionId('');
    setRowsTransactionId([]);
    setRowsCreditsDetails('')
  };
  const onSubmitDateRange = (data) => {
    const startDate = data.startDate;
    const endDate = data.endDate;

  };
  const handleInputChange = (e) => {
    setCreditNumber(e.target.value);
  };
  const handleInputChangeTransaction = (e) => {
    setTransactionId(e.target.value);
  };
  const handleInputChangeCredit = (e) => {
    setEmployeeId(e.target.value);
  };
  const handleInputChangeDateRange = (e) => {
    setCreditIdIdDateRange(e.target.value);
  };

  useEffect(() => {
    getAllTransactions();
  }, []);
  useEffect(() => {
    getAllCredits();
  }, []);

  useEffect(() => {
    getAllTransactionsByCreditId();
  }, []);
  useEffect(() => {
    getAllTransactionsById();
  }, []);
  useEffect(() => {
  getCreditDetails()
}, []);
  return (
    <DashboardLayout title="Credit System" subTitle="Manage Credit System" target="">
      <div style={{ display: 'flex' }}>
        {/* <div>
          {" "}
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddRow(true);
            }}
          >
            Add Credit
          </Button>
        </div> */}
        <div style={{ marginLeft: '8px' }} >
          {" "}
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddRowTransaction(true);

            }}
          >
            Employee Transaction
          </Button>
        </div>
    
        <div style={{ marginLeft: '8px' }}>
          {" "}
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddAgency(true);
            }}
          >
            Add Agency
          </Button>
        </div>
        <div style={{ marginLeft: '8px' }} >
          {" "}
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddAgencyCredit(true);
            }}
          >
            Add Agency Credit
          </Button>
        </div>
        <div style={{ marginLeft: '8px' }} >
          {" "}
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowTopUpAgencyCredit(true);
            }}
          >
            Top Up Agency Credit
          </Button>
        </div>
      </div>
      <br />
    

      <TabNavigationMenu
          activated={currentTab}
          onChangeItem={switchTabHandler}
          itemList={[
            // { name: " All Transaction", key: "alltransaction" },
            { name: "Transaction", key: "transaction" },
            { name: "Credit", key: "credit" },
            // { name: "Verified", key: "verified" },
            // { name: "Reschedules", key: "reschedules" },
          ]}
        />
        {/* {currentTab === "alltransaction" && <TransactionAll></TransactionAll>} */}
        {currentTab === "transaction" && <Transaction></Transaction>}
        {currentTab === "credit" && (
           <Credit></Credit>
        )}
       
      {/* <ModalComponent
        show={showAddRow}
        header="Add New Credit"
        closeModal={() => setShowAddRow(false)}
      >
        <AddCredit
          mode="Add"
          modalType="Credit Amount"
          closeModal={() => setShowAddRow(false)}
        // onCreate={(data) => addRow(data)}
        />
      </ModalComponent> */}
      <ModalComponent
        show={showAddRowTransaction}
        header="Transaction"
        closeModal={() => setShowAddRowTransaction(false)}
      >
        <AddCreditTransaction
          mode="Add"
          modalType="Amount"
          closeModal={() => setShowAddRowTransaction(false)}
        // onCreate={(data) => addRow(data)}
        />
      </ModalComponent>
      <ModalComponent
        show={showAddAgency}
        header="Add Agency"
        closeModal={() => setShowAddAgency(false)}
      >
        <AddAgency
          mode="Add"
          modalType="Agency Name"
          closeModal={() => setShowAddAgency(false)}
        // onCreate={(data) => addRow(data)}
        />
      </ModalComponent>
      <ModalComponent
        show={showAddAgencyCredit}
        header="Add Agency Credit"
        closeModal={() => setShowAddAgencyCredit(false)}
      >
        <AddAgencyCredit
          mode="Add"
          modalType="Credit Balance"
          agencyId={agencyId}
          closeModal={() => setShowAddAgencyCredit(false)}
        // onCreate={(data) => addRow(data)}
        />
      </ModalComponent>
      <ModalComponent
        show={showAddTopUpAgencyCredit}
        header="Top Up Agency Credit"
        closeModal={() => setShowTopUpAgencyCredit(false)}
      >
        <TopUpAgencyCredit
          mode="Add"
          modalType="Credit Balance"
          closeModal={() => setShowTopUpAgencyCredit(false)}
        // onCreate={(data) => addRow(data)}
        />
      </ModalComponent>
      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteTransaction();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(!showDeleteRow)}
      />
      <Delete
        show={showDeleteRow1}
        isConfirm={() => {
          deleteCredit();
          setShowDeleteRow1(!showDeleteRow1);
        }}
        closeModal={() => setShowDeleteRow1(!showDeleteRow1)}
      />
    </DashboardLayout>


  );
};


function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(CreditSystemSuper);


{/* <div className="row">
<div className="col-md-6">


<row>
    <h5>All Transactions</h5>
    <div style={{ overflow: 'auto', maxHeight: '400px' }}>
      {loading ? (
        <div className="text-center">
          <img src={loadingAnimation} alt="Loading..." />
        </div>
      ) : (
        <div>
          {rows && rows.map((transaction) => (
            <div className="card" key={transaction.id}>
              <div className="list list-row">
                <div className="list-item" data-id={transaction.id}>
                  <div className="flex">
                    <div className="item-feed h-4x">

                      <div className="capitalize">{transaction.id}</div>
                      <div className="capitalize">{transaction.description}</div>
                      <div className="text-muted capitalize">{transaction.amount}</div>
                      <div className="text-muted capitalize">{transaction.transactionType}</div>
                    </div>
                  </div>
                  <div className="no-wrap">
                    <div className="item-date text-muted text-sm d-none d-md-block">
                      {moment(transaction.date).format("llll")}
                    </div>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave pointer"
                      size="1x"
                      onClick={() => {
                        setShowDeleteRow(true);
                        setRowID(transaction.id);
                      }}
                    />
                  </div>


                  <div>
                    <FontAwesomeIcon
                      icon={faEye}
                      className="shadow-none fa-lg mb-2  btn-raised btn-wave pointer"
                      size="1x"
                      onClick={() => {
                        // setShowDeleteRow(true);
                        // setRowID(transaction.id);
                        setTransactionId(transaction.id)
                        getAllTransactionsById();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  </row>




</div>

<div className="col-md-6" >
 <row>

  
 </row>

  <row>
    <h5>All Credits</h5>
    <div style={{ overflow: 'auto', maxHeight: '600px' }}>
      {loading ? (
        <div className="text-center">
          <img src={loadingAnimation} alt="Loading..." />
        </div>
      ) : (
        <div>
          {allCredits && allCredits.map((allCredits) => (
            <div className="card" key={allCredits.id}>
              <div className="list list-row">
                <div className="list-item" data-id={allCredits.id}>
                  <div className="flex">
                    <div className="item-feed h-5x">
                      <div className="capitalize">{allCredits.id}</div>
                      <div className="capitalize">credit Balance : {allCredits.creditBalance}</div>
                      <div className="text-muted capitalize">Agency name: {allCredits?.agency?.name}</div>
                      <div className="text-muted capitalize">Email: {allCredits?.agency?.email}</div>
                      <div className="text-muted capitalize">Credit service Type: {allCredits?.creditServiceType}</div>
                      {/* <div className="text-muted capitalize">{allCredits.transactionType}</div> */}
//                     </div>
//                   </div>
               
//                   <div>
//                     <FontAwesomeIcon
//                       icon={faEye}
//                       className="shadow-none fa-lg mb-2  btn-raised btn-wave pointer"
//                       size="1x"
//                       onClick={() => {
//                         setShowAddAgencyCredit(true);
//                         setAgencyId(allCredits?.agency?.id)
//                       }}
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       )}

//     </div>
//   </row>
// </div>


// </div> */}
