import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  faPhone,
  faCalendarAlt,
  faPoundSign,
  faEnvelope,
  faHeart,
  faHeartBroken,
  faUsers,
  faCalendar,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Popover,
  PopoverHeader,
  PopoverBody,
  UncontrolledPopover,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";

import {
  CLIENTS,
  WARDS,
  SHIFTS,
  BOOKING_WORKSPACE_CANDIDATELIST,
  HOSTMexxar,
  HostMexxarURL,
  RESCHEDULE,
  CREATE_BOOKING,
  BOOKING_ASSIGMENT_CODES,
  ClientLable,
  CompanyName,
  BotURL,
} from "../../../configs/api-config";
import {
  _,
  forEach,
  filter,
  difference,
  concat,
  compact,
  flatten,
  isNull,
  uniq,
  uniqBy,
  uniqWith,
  isEqual,
  split,
  isUndefined,
  toLower,
} from "lodash";

import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import { useToasts } from "react-toast-notifications";
import loading from "../../../../src/images/puff.svg";
import BookingExcelTable from "./booking-excel-table";
import ToastCommon from "../../../components/toastCommon";
import Email from "../../../components/Modal/ModalBody/email";
import { useIsMounted } from "../../../customHooks/useIsMounted";
import LikeWard from "../../../components/Modal/ModalBody/likeWard";
import EmailNav from "../../../components/Modal/ModalBody/emailNav";
import ModalComponent from "../../../components/Modal/modalComponent";
import QuickDraft from "../../../components/Modal/ModalBody/QuickDraft";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import GroupSearch from "../../../components/Modal/ModalBody/groupSearch";
import DislikeWard from "../../../components/Modal/ModalBody/dislikeWard";
import CalendarNav from "../../../components/Modal/ModalBody/calendarNav";
import SmallCalendar from "../../../components/Modal/ModalBody/smallCalendar";
import BookingSummery from "../../../components/Modal/ModalBody/bookingSummery";
import HospitalRateModal from "../../../components/Modal/ModalBody/hospitalRate";
import ClientRateModal from "../../../components/Modal/ModalBody/clientRateModal";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import BookingWSCellClick from "../../../components/Modal/ModalBody/bookingWSCellClick";
import ClientRateBookingModal from "../../../components/Modal/ModalBody/hospitalRateBookingWorkspace";
import { FunnelIcon } from "../../../assets/icons/svg";
import AvatarMexxar from "../../../util/common/avatarMexxar";
import HealthroasterMagicResultsTable from "./HealthroasterMagicResultsTable";
import BookingWorkspaceDefaultView from "./BookingWorkspaceDefaultView";
import NHSPMagicResultsTable from "./NHSPMagicResultsTable";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { sendEmail } from "../../email/graph";
import BookingConfigure from "../../../components/Modal/ModalBody/bookingConfigure";

const BookingWorkspace = (props) => {
  const isMounted = useIsMounted();
  const { addToast } = useToasts();

  let currentDate = moment(new Date()).format("YYYY/MM/DD");
  let DefaultEndDate = moment(new Date()).add(15, "days").format("YYYY/MM/DD");

  const MexxarApiClients = CLIENTS;
  const MexxarApiDepartments = WARDS;
  const MexxarApiShifts = SHIFTS;
  const MexxarUrl = HostMexxarURL;
  const MexxarApiReschedule = RESCHEDULE;
  const MexxarCreateBooking = CREATE_BOOKING;
  const MexxarAssigments = BOOKING_ASSIGMENT_CODES;
  const MexxarApiBookingWorkspace = BOOKING_WORKSPACE_CANDIDATELIST;

  const [calendarShow, setCalendarShow] = useState(false);
  const [magicSearchON, setMagicSearchON] = useState(false);

  ////..filter...../////

  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedShifts, setSelectedShifts] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const [type, setType] = useState();
  const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState(null);
  const [shifts, setShifts] = useState([]);
  const [dayData, setDayData] = useState(null);
  const [clients, setClients] = useState([]);
  const [groupRows, setGroupRows] = useState([]);
  const [initialRows, setInitialRows] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [groupSearch, setGroupSearch] = useState(false);
  const [endDate, setEndDate] = useState(DefaultEndDate);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [startDate, setStartDate] = useState(currentDate);
  const [selectedRowData, setSelectedRowData] = useState();
  const [groupSearchType, setGroupSearchType] = useState();
  const [showLikeModal, setShowLikeModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showQuickModal, setShowQuickModal] = useState(false);
  const [groupSearchNow, setGroupSearchNow] = useState(false);
  const [resetSheetData, setResetSheetData] = useState(false);
  const [magicSearchData, setMagicSearchData] = useState(null);
  const [showCellSummery, setShowCellSummery] = useState(false);
  const [showDislikeModal, setShowDislikeModal] = useState(false);
  const [bookedReferenceNos, setBookedReferenceNos] = useState([]);
  const [clientRateCandidate, setClientRateCandidate] = useState();
  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [showAdvanceColumn, setShowAdvanceColumn] = useState(false);
  const [showGroupSearchModal, setShowGroupSearchModal] = useState(false);
  const [showConfigureModal,   setShowConfigureModal] = useState(false);
  const [showCellStatusChange, setShowCellStatusChange] = useState(false);
  const [showHospitalRateModal, setShowHospitalRateModal] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const resheduleCall = (data) => {
    let rescheduleDate = moment(data.date).add(data.time).toISOString();
    axios
      .post(MexxarApiReschedule, {
        callerId: 1,
        receiverId: rowID,
        callDate: moment(new Date()).toISOString(),
        rescheduleDate: rescheduleDate,
      })
      .then((res) => {
        //  setCandidateId(res.data.body);
        ToastCommon(true, "success", addToast);
        setCalendarShow(!calendarShow);
      })
      .catch((err) => {
        //console.log("error", err);
        ToastCommon(true, "error", addToast);
      });
  };

  const filterByName = (name, dataSet) => {
    let filtered = dataSet;
    if (name !== "") {
      filtered = dataSet.filter((item) => {
        let fullName = item.firstName + item.middleName + item.lastName;
        let firstLastName = item.firstName + item.lastName;

        let searchableString = `${fullName} ${firstLastName} ${item.candidate.firstName} ${item.candidate.lastName} ${item.candidate.email} ${item.candidate.primaryPhone}`;
        let p = searchableString;
        return p.toLowerCase().includes(name.toLowerCase());
      });
    }
    return filtered;
  };
  // const getFieldData = () => {
  //   axios
  //     .all([
  //       axios.get(MexxarApiClients),
  //       axios.get(MexxarApiDepartments),
  //       axios.get(MexxarApiShifts),
  //     ])
  //     .then(
  //       axios.spread((res1, res2, res3) => {

  //         let tempClientsArray = [];
  //         forEach(res1.data.body, function (item) {
  //           tempClientsArray.push({
  //             value: item.name,
  //             label: item.name,
  //             id: item.id,
  //           });
  //         });

  //         let tempDepartmentArray = [];
  //         forEach(res2.data.body, function (item) {
  //           tempDepartmentArray.push({
  //             value: item.name,
  //             label: item.name,
  //             id: item.id,
  //           });
  //         });

  //         let tempShiftsArray = [];
  //         forEach(res3.data.body, function (item) {
  //           tempShiftsArray.push({
  //             value: item.name,
  //             label: item.name,
  //             id: item.id,
  //           });
  //         });

  //         if (isMounted.current) {
  //           setShifts(tempShiftsArray);
  //           setClients(tempClientsArray);
  //           setDepartments(tempDepartmentArray);
  //         }
  //       })
  //     )
  //     .catch((err) => {
  //       //console.log(err)
  //       //toastCommon(true, "error");
  //     });
  // };

  const checkPermissions = (permission) => {
    let array = filter(props.auth.item.userRole.permissions, (item) => {
      return item.name == permission;
    });
    return array;
  };

  // const recordMissingBookings=()=>{
  //   let data=  {
  //     requestedId:,
  //     dateStart:,
  //     endDate:,
  //     ward:,
  //     requestGrade:,
  //     agency:,
  //     staff:,
  //     candidates:
  // }
  //   axios.post(HOSTMexxar+"magicsearch/addHS",data)
  //   .then((res)=>{})
  // }

  //+++++++++++++++++++++++++++++Record missing bookings+++++++++++++++++++++++++++++//

  //record missing bookings for reporting purposes and sending alerts to candidates, so candidates can book by them self

  // const missingBookingRecord = async (data) => {

  //   if (magicSearchData) {
  //     try {
  //       if (data[0].type === "NHSP") {
  //         const response = await axios({
  //           method: "POST",
  //           url: HOSTMexxar + "magicsearch/addNHSP",
  //           params: { clientId: data[0].hospitalId },
  //           data: data[0].shifts,
  //         }).then((res) => {});
  //       }
  //       if (data[0].type === "HR") {
  //         const updatedShifts = await Promise.all(
  //           data[0].shifts.map(async (item) => {
  //             // Add error checking for invalid date format
  //             if (!moment(item.shiftDate, "DD-MMM-YY").isValid()) {
  //               throw new Error("Invalid date format: " + item.shiftDate);
  //             }

  //             const isoDate = moment(item.shiftDate, "DD-MMM-YY").toISOString();
  //             return {
  //               ...item,
  //               shiftDate: isoDate,
  //             };
  //           })
  //         );

  //         const response = await axios({
  //           method: "POST",
  //           url: HOSTMexxar + "magicsearch/addHS",
  //           params: { clientId: data[0].hospitalId },
  //           data: updatedShifts,
  //         }).then((res) => {});

  //         // Do something with the response if needed
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //       // Handle errors
  //     }
  //   }
  // };

  // const missingBookingRecord = async (data) => {
  //   console.log("Received Data:", data); // Log the received data

  //   if (magicSearchData) {
  //     try {
  //       // Iterate over each data item (assuming data is an array with one item in your example)
  //       data.forEach(async (item, index) => {
  //         console.log(`Processing Item ${index + 1}:`);
  //         console.log("Hospital:", item.hospital);
  //         console.log("Hospital ID:", item.hospitalId);

  //         // Iterate over shifts array and log details for each shift
  //         item.shifts.forEach((shift, shiftIndex) => {

  //           console.log("Ward:", shift.ward);
  //         });

  //         if (item.type === "NHSP") {
  //           console.log("Processing NHSP type data...");
  //           const response = await axios({
  //             method: "POST",
  //             url: HOSTMexxar + "magicsearch/addNHSP",
  //             params: { clientId: item.hospitalId },
  //             data: item.shifts,
  //           });
  //           console.log("NHSP API Response:", response.data); // Log NHSP API response
  //         }

  //         if (item.type === "HR") {
  //           console.log("Processing HR type data...");
  //           const updatedShifts = await Promise.all(
  //             item.shifts.map(async (shift) => {
  //               // Add error checking for invalid date format
  //               if (!moment(shift.shiftDate, "DD-MMM-YY").isValid()) {
  //                 throw new Error("Invalid date format: " + shift.shiftDate);
  //               }

  //               const isoDate = moment(shift.shiftDate, "DD-MMM-YY").toISOString();
  //               return {
  //                 ...shift,
  //                 shiftDate: isoDate,
  //               };
  //             })
  //           );

  //           const response = await axios({
  //             method: "POST",
  //             url: HOSTMexxar + "magicsearch/addHS",
  //             params: { clientId: item.hospitalId },
  //             data: updatedShifts,
  //           });
  //           console.log("HR API Response:", response.data); // Log HR API response
  //         }
  //       });
  //     } catch (error) {
  //       console.error("Error:", error);
  //       // Handle errors
  //     }
  //   } else {
  //     console.log("magicSearchData is not available. Skipping processing."); // Log if magicSearchData is not available
  //   }
  // };
  const [departmentNames, setDepartmentNames] = useState([]);

  const getFieldData = () => {
    axios
      .all([
        axios.get(MexxarApiClients),
        axios.get(MexxarApiDepartments),
        axios.get(MexxarApiShifts),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          // Logging response data received from API calls

          // console.log("Departments Data:", res2.data);
          // console.log("Shifts Data:", res3.data);

          // // Extracting department names from the response body
          // const departmentNames = res2.data.body.map((department) => department.name);

          // console.log("Department Names:", departmentNames);
          const extractedDepartmentNames = res2.data.body.map(
            (department) => department.name
          );
          console.log("Department Names:", extractedDepartmentNames);

          // Store department names in state
          setDepartmentNames(extractedDepartmentNames);

          let tempClientsArray = [];
          forEach(res1.data.body, function (item) {
            tempClientsArray.push({
              value: item.name,
              label: item.name,
              id: item.id,
            });
          });

          let tempDepartmentArray = [];
          forEach(res2.data.body, function (item) {
            tempDepartmentArray.push({
              value: item.name,
              label: item.name,
              id: item.id,
            });
          });

          let tempShiftsArray = [];
          forEach(res3.data.body, function (item) {
            tempShiftsArray.push({
              value: item.name,
              label: item.name,
              id: item.id,
            });
          });

          if (isMounted.current) {
            // Logging arrays being set in state
            console.log("Shifts Array:", tempShiftsArray);
            console.log("Clients Array:", tempClientsArray);
            console.log("Departments Array:", tempDepartmentArray);

            setShifts(tempShiftsArray);
            setClients(tempClientsArray);
            setDepartments(tempDepartmentArray);
            // setDepartments(tempDepartmentArray.map((department) => department.name));
          }
        })
      )
      .catch((err) => {
        console.error("Error fetching data:", err);
        // Handle error, e.g., display toast message
        //toastCommon(true, "error");
      });
  };

  const [matchingWards, setMatchingWards] = useState([]);
  const [nonMatchingWards, setNonMatchingWards] = useState([]);

  const [hospitalId, setHospitalId] = useState(null);

  const missingBookingRecord = async (data) => {
    console.log("Received Data:", data); // Log the received data

    if (magicSearchData) {
      try {
        // Get departmentNames for cross-referencing

        // Initialize arrays to store matching and non-matching wards
        const matchingWards = [];
        const nonMatchingWards = [];

        // Iterate over each item in Received Data
        data.forEach((item) => {
          if (item.type === "NHSP" || item.type === "HR") {
            item.shifts.forEach((shift) => {
              if (departmentNames.includes(shift.ward)) {
                matchingWards.push(shift.ward); // Ward matches a department name
              } else {
                nonMatchingWards.push(shift.ward); // Ward does not match any department name
              }
            });
          }
        });
        setMatchingWards(matchingWards);
        setNonMatchingWards(nonMatchingWards);
        console.log("Matching Wards with Department Names:", matchingWards);
        console.log("Non-Matching Wards:", nonMatchingWards);

        // Continue processing based on item type (NHSP or HR)
        if (data[0].type === "NHSP") {
          const response = await axios({
            method: "POST",
            url: HOSTMexxar + "magicsearch/addNHSP",
            params: { clientId: data[0].hospitalId },
            data: data[0].shifts,
          });
          console.log("NHSP API Response:", response.data);
          // Log NHSP API response
        }

        if (data[0].type === "HR") {
          const updatedShifts = await Promise.all(
            data[0].shifts.map(async (shift) => {
              // Add error checking for invalid date format
              if (!moment(shift.shiftDate, "DD-MMM-YY").isValid()) {
                throw new Error("Invalid date format: " + shift.shiftDate);
              }

              const isoDate = moment(
                shift.shiftDate,
                "DD-MMM-YY"
              ).toISOString();
              return {
                ...shift,
                shiftDate: isoDate,
              };
            })
          );

          const response = await axios({
            method: "POST",
            url: HOSTMexxar + "magicsearch/addHS",
            params: { clientId: data[0].hospitalId },
            data: updatedShifts,
          });
          console.log("HR API Response:", response.data);
          setHospitalId(data[0].hospitalId); // Log HR API response
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle errors
      }
    } else {
      console.log("magicSearchData is not available. Skipping processing."); // Log if magicSearchData is not available
    }
  };

  //my old code. optimizes it. delete if the above optimised code works perfectly fine
  // const missingBookingRecord = (data) => {
  //   if (magicSearchData) {
  //     if (data[0].type == "NHSP") {
  //       axios({
  //         method: "POST",
  //         url: HOSTMexxar + "magicsearch/addNHSP",
  //         params: { clientId: data[0].hospitalId },
  //         data: data[0].shifts,
  //       }).then((res) => {});
  //     }
  //     if (data[0].type == "HR") {
  //       const updatedData = data[0].shifts.map(item => {
  //         const isoDate = moment(item.shiftDate, "DD-MMM-YY").toISOString();
  //         return {
  //           ...item,
  //           shiftDate: isoDate
  //         };
  //       });

  //       axios({
  //         method: "POST",
  //         url: HOSTMexxar + "magicsearch/addHS",
  //         params: { clientId: data[0].hospitalId },
  //         data: updatedData,
  //       }).then((res) => {});
  //     }
  //   }
  // };

  useDidMountEffect(() => {
    getAgencies();
  }, [clients]);

  useDidMountEffect(() => {
    missingBookingRecord(magicSearchData);
  }, [magicSearchData]);

  ////+++++++++++++++++++++++++++++jonny's magic serch endpoint+++++++++++++++++++++++++++++//

  const jonnysMagicSerch = (data) => {
    axios
      .post(
        HOSTMexxar +
          "magicsearch/process?clientId=" +
          data.hospitalId +
          "&employeeId=" +
          props.employeeID,
        data.shifts
      )
      .then((res) => {
        if (res.data.status == "success") {
          setGroupRows(res.data.body);
        } else {
          setGroupRows([]);
        }
      })
      .catch((error) => {
        setGroupRows([]);
      });
  };

  /////+++++++++++++++++++++++++++++End+++++++++++++++++++++++++++++/////

  //+++++++++++++++++++++++++++++Preparing group search data to map+++++++++++++++++++++++++++++//

  /*
  get candidates who likes the selected hospital
  sort them by available date    sorted_by_availableDate
  sort them by ward ids          sorted_by_wardId
  sort them by assigment id      sorted_by_assigmentId


  then take a shift row and filter or group by available date       filter_byDate 
  get filter_by_date and filter it by wardId                        filter_byDate_byWardId
  get filter_byDate_byWardId filter it by assigment id              filter_byDate_byWardId_byAssigmentId
  get filter_byDate_byWardId_byAssigmentId filter it by shifttype   filter_byDate_byWardId_byAssigmentId_byShift 

  introduced break points if any critieria doesnot match and get out of the loop

  */

  const groupSearchPrepare = (data) => {
    console.log("test angi magic search data", data);
    setMagicSearchData(data);

    var groupRowsArray = [];
    if (data != undefined) {
      setGroupSearchType(data[0].type);

      if (data[0].type == "NHSP") {
        forEach(data[0].shifts, function (shift) {
          let tempselectedClientsArray = [];

          tempselectedClientsArray.push({
            value: data[0].hospital,
            label: data[0].hospital,
            id: data[0].hospitalId,
          });

          // var tempCli = filter(clients, (client) => {
          //   return client.value === data[0].hospitalId;
          // });

          setSelectedClients(tempselectedClientsArray);

          var tempDepart = filter(departments, (department) => {
            return department.value === shift.ward;
          });

          // setSelectedDepartments(tempDepart);

          // setSelectedDate(moment(shift.shiftDate).format("YYYY-MM-DD"));

          let splitedStartTime = split(shift.startTime, ":");
          let ST = Number(splitedStartTime[0]);

          let splitedEndTime = split(shift.endTime, ":");
          let ET = Number(splitedEndTime[0]);

          let shiftType;

          if (ST <= 12 && ST >= 6 && ET <= 16 && ET >= 10) {
            //  console.log("====++condition o1 working");

            var tempArray = filter(shifts, (shift) => {
              return shift.value === "E";
            });
            shiftType = tempArray;
          } else if (ST <= 12 && ST >= 6 && ET <= 22 && ET >= 17) {
            // console.log("====+++condition o2 working");
            var tempArray = filter(shifts, (shift) => {
              return shift.value === "LD";
            });
            shiftType = tempArray;
          } else if (ST <= 16 && ST >= 11 && ET <= 23 && ET >= 17) {
            //   console.log("====+++condition o3 working");
            var tempArray = filter(shifts, (shift) => {
              return shift.value === "L";
            });
            shiftType = tempArray;
          } else if (ST <= 20 && ST >= 16 && ET <= 24 && ET >= 22) {
            //   console.log("===+++condition o4 working");
            var tempArray = filter(shifts, (shift) => {
              return shift.value === "TW";
            });
            shiftType = tempArray;
          } else if (ST <= 20 && ST >= 16 && ET <= 3 && ET >= 1) {
            //  console.log("===+++ condition o5 working");
            var tempArray = filter(shifts, (shift) => {
              return shift.value === "TW";
            });
            shiftType = tempArray;
          } else if (ST <= 22 && ST >= 18 && ET <= 10 && ET >= 1) {
            // console.log("===++++condition o6 working");
            var tempArray = filter(shifts, (shift) => {
              return shift.value === "N";
            });
            shiftType = tempArray;
          } else {
            shiftType = null;
          }

          // console.log("=======group search  tempDepart", tempDepart);
          // console.log(
          //   "=======group search  tempselectedClientsArray",
          //   tempselectedClientsArray
          // );
          // console.log(
          //   "=======group search  date",
          //   moment(shift.shiftDate).format("YYYY-MM-DD")
          // );
          // console.log("=======group search  shiftType", shiftType);
          // //          console.log("=======group search  assigment", assignment);

          //  setSelectedShifts(shiftType);

          //setGroupSearchNow(true);

          /////////////////////////////////////////////////////////////////////////////////////////////////
          /////////////////////////////////////Group Filter////////////////////////////////////////////////
          /////////////////////////////////////////////////////////////////////////////////////////////////

          var groupDataSet = initialRows;
          var groupAvailibilityGivenCandidates = [];

          for (let index = 0; index < groupDataSet.length; index++) {
            const element = groupDataSet[index];

            let availibilityGiven = filter(element.dayData, (item) => {
              return "AVAILABLE" === item.availabilityStatus;
            });

            let availableGivenSelectedTemp = filter(
              availibilityGiven,
              (item) => {
                return (
                  moment(shift.shiftDate).format("YYYY-MM-DD") ===
                  moment(item.date).format("YYYY-MM-DD")
                );
              }
            );

            if (availableGivenSelectedTemp.length !== 0) {
              //dateResultArray.push(...tDateArray);

              groupAvailibilityGivenCandidates.push({
                candidate: element.candidate,
                dayData: element.dayData,
              });
            }
          }
          // console.log(
          //   "groupAvailibilityGivenCandidates 505",
          //   groupAvailibilityGivenCandidates
          // );

          //********....get candidates available for given clients......********//
          let result_3 = [];
          forEach(tempselectedClientsArray, function (selectedClient) {
            forEach(groupAvailibilityGivenCandidates, function (value) {
              let A = filter(value.candidate.preferredClients, (item) => {
                return selectedClient.id === item.id;
              });

              if (A.length !== 0) {
                result_3.push({
                  candidate: value.candidate,
                  dayData: value.dayData,
                });
              }
            });
          });

          let uniqDateClientFiltered = uniqWith(result_3, isEqual);
          //*************end************//

          //console.log("NHSP result 3", uniqDateClientFiltered);

          //********....get candidates available for given clients and shifts......********//
          let result_4 = [];
          forEach(shiftType, function (selectedShift) {
            forEach(uniqDateClientFiltered, function (value) {
              let A = filter(value.dayData, (item) => {
                if (
                  item.shift != null &&
                  moment(shift.shiftDate).format("YYYY-MM-DD") ===
                    moment(item.date).format("YYYY-MM-DD")
                ) {
                  return selectedShift.id == item.shift.id;
                }

                return false;
              });

              if (A.length !== 0) {
                result_4.push({
                  candidate: value.candidate,
                  dayData: value.dayData,
                });
              }
            });
          });

          let uniqDateClientShiftFiltered = uniqWith(result_4, isEqual);

          // console.log("NHSP result 4", uniqDateClientShiftFiltered);

          let result_7 = [];

          forEach(uniqDateClientShiftFiltered, function (value) {
            // console.log(
            //   "testing.... result_7....value before",
            //   value.candidate.assignments
            // );

            let A = filter(value.candidate.assignments, (item) => {
              // console.log("testing.... result_7....value", item.code);
              // console.log(
              //   "testing.... result_7....shift.assignment",
              //   shift.assignment
              // );

              return (
                shift.assignment == item.code ||
                shift.secondaryAssignemnt == item.code
              );
            });

            if (A.length !== 0) {
              result_7.push({
                candidate: value.candidate,
                dayData: value.dayData,
              });
            }
          });

          let uniqDateClientShiftAssigmentFiltered = uniqWith(
            result_7,
            isEqual
          );
          // console.log(
          //   "testing.... result_7",
          //   uniqDateClientShiftAssigmentFiltered
          // );

          //********....get candidates available for given clients......********//

          let result_5 = [];
          forEach(tempDepart, function (selectedDepartment) {
            forEach(uniqDateClientShiftAssigmentFiltered, function (value) {
              let A = filter(value.candidate.likeWards, (item) => {
                return selectedDepartment.id === item.id;
              });

              if (A.length !== 0) {
                result_5.push({
                  firstName: value.candidate.firstName,
                  lastName: value.candidate.lastName,
                  candidateId: value.candidate.id,
                  speciality: value.candidate.candidateSpecialities,
                  band: value.candidate.candidateBand.name,

                  //  dayData: value.dayData,
                });
              }
            });
          });

          // console.log("testing.... result_5", uniqWith(result_5, isEqual));

          //  let uniqDateClientShiftBANDSpecialityFiltered = uniqWith(
          //    result_7,
          //    isEqual
          //  );

          let result_6 = [];
          result_6.push({
            AvailableCandidates: uniqWith(result_5, isEqual),
            startTime: shift.startTime,
            endTime: shift.endTime,
            note: shift.note,
            refNo: shift.refNo,
            secondaryAssignemnt: shift.secondaryAssignemnt,
            sex: shift.sex,
            shiftDate: shift.shiftDate,
            training: shift.training,
            ward: shift.ward,
            assignment: shift.assignment,
            type: data[0].type,
          });
          let uniqDateClientShiftDepartmentFiltered = uniqWith(
            result_6,
            isEqual
          );

          // console.log(
          //   "=======+++++++++++++++++group search  results++++++++++++++++++++++",
          //   result_6
          // );
          groupRowsArray.push(...result_6);
        });
      } else if ((data[0].type = "HR")) {
        let tempselectedClientsArray = [];

        tempselectedClientsArray.push({
          value: data[0].hospital,
          label: data[0].hospital,
          id: data[0].hospitalId,
        });

        // var tempCli = filter(clients, (client) => {
        //   return client.value === data[0].hospitalId;
        // });

        setSelectedClients(tempselectedClientsArray);

        jonnysMagicSerch(data[0]); //<-------use when using jonny's magic search

        //++++++Only show one available candidate for candidate column when BOT works++++++//
        //use when using the BOT.This is needed when we only consider about the BOT.And bot needs only one available candidate.
        //this code needs to change according to the jonny's results.so re-write
        //  let botShowOnlyOneAvailableCandidate = [];
        //  if (checkPermissions("BOT").length > 0) {
        //    let temp = uniqWith(result_5, isEqual);
        //    if (temp.length > 0) {
        //      botShowOnlyOneAvailableCandidate.push(temp[0]);
        //    }
        //  } else {
        //    botShowOnlyOneAvailableCandidate = uniqWith(result_5, isEqual);
        //  }
        //************refactore and rewrite below code for use with "BOT" */
        //++++++ only output result when candidates available++++++//
        //++++++ use to send notification to candidates about the availibility  +++++++//
        //++++++ use for the BOT++++++//

        // if (checkPermissions("BOT").length > 0) {
        //   let onlyAvailableResults = filter(result_6, (item) => {
        //     return (
        //       (item.AvailableCandidates.length > 0 && item.Staff == "") ||
        //       item.Staff == undefined
        //     );
        //   });

        //   groupRowsArray.push(...onlyAvailableResults);
        // } else {
        //   groupRowsArray.push(...result_6);
        // }
      }

      setGroupRows(groupRowsArray);
    } else {
    }
    setShowGroupSearchModal(false);
    setGroupSearch(true);
  };

  // console.log("=========== health roster", groupRows);

  // useDidMountEffect(() => {
  //   handleSearch();

  //   return () => {
  //     setGroupSearchNow(false);
  //   };
  // }, [groupSearchNow]);

  const [bookedOrAvailable, setBookedOrAvailable] = useState(false);

  const handleSearch = () => {
    //console.log("check if  search active or not #####################");

    var dataSet = rows;

    var available = [];
    var dateResultArray = [];
    var clientsResultArray = [];
    var wardResultArray = [];
    var shiftResultArray = [];
    var availableGivenSelectedTempResult = [];

    var filteredResult = [];

    /////........main dataset incomming for filteration(all candidates)......//////
    for (let index = 0; index < dataSet.length; index++) {
      const element = dataSet[index];

      // ********....get available candidates ......********//

      // var availibilityGiven = filter(element.dayData, (item) => {
      //   return "AVAILABLE" === item.availabilityStatus;
      // });

      var availibilityGiven;

      if (bookedOrAvailable == true) {
        availibilityGiven = filter(element.dayData, (item) => {
          return "AVAILABLE" === item.availabilityStatus;
        });
      } else if (bookedOrAvailable == false) {
        availibilityGiven = filter(element.dayData, (item) => {
          return "BOOKED" === item.availabilityStatus;
        });
      }

      if (availibilityGiven.length !== 0) {
        //  available.push(...availibilityGiven);
        available.push({
          candidate: element.candidate,
          dayData: element.dayData,
        });
      }

      //*******end**********//

      // ********....get candidates available for given date......********//

      var tDateArray = filter(element.dayData, (item) => {
        return selectedDate === moment(item.date).format("YYYY-MM-DD");
      });

      if (tDateArray.length !== 0) {
        //dateResultArray.push(...tDateArray);

        dateResultArray.push({
          candidate: element.candidate,
          dayData: element.dayData,
        });
      }

      //*******end**********//

      // ********....get "available" candidates for given date......********//

      let availableGivenSelectedTemp = filter(availibilityGiven, (item) => {
        return selectedDate === moment(item.date).format("YYYY-MM-DD");
      });

      if (availableGivenSelectedTemp.length !== 0) {
        //dateResultArray.push(...tDateArray);

        availableGivenSelectedTempResult.push({
          candidate: element.candidate,
          dayData: element.dayData,
        });
      }
      //*******end**********//

      // ********....get candidates available for given wards......********//
      var tempWardResults = [];

      if (selectedDepartments != null) {
        for (let index = 0; index < selectedDepartments.length; index++) {
          const selectedDepartment = selectedDepartments[index];

          let tempArray = filter(element.candidate.likeWards, (item) => {
            return selectedDepartment.id === item.id;
          });
          if (tempArray.length !== 0) {
            tempWardResults.push(...tempArray);
          }
        }
      }

      if (tempWardResults.length !== 0) {
        wardResultArray.push({
          candidate: element.candidate,
          dayData: element.dayData,
        });
      }

      //*******end**********//

      // ********....get candidates available for given shifts......********//
      var tempShiftResults = [];
      if (selectedShifts != null) {
        for (let index = 0; index < selectedShifts.length; index++) {
          const selectedShift = selectedShifts[index];

          let tempArray = filter(element.dayData, (item) => {
            if (item.shift != null) {
              return selectedShift.id == item.shift.id;
            }
            return false;
          });

          tempShiftResults.push(...tempArray);
        }
      }

      if (tempShiftResults.length !== 0) {
        shiftResultArray.push({
          candidate: element.candidate,
          dayData: element.dayData,
        });
      }

      //*******end**********//
    }

    //********.....setting arrays length as 0 if the filter field is empty ......********//
    let w = isNull(selectedDate) ? 0 : selectedDate.length;
    let x = isNull(selectedClients) ? 0 : selectedClients.length;
    let y = isNull(selectedShifts) ? 0 : selectedShifts.length;
    let z = isNull(selectedDepartments) ? 0 : selectedDepartments.length;

    //*******Check what fileds are selected***********//

    if (w > 0 && x > 0 && y > 0 && z > 0) {
      // *********===========Start================*********//
      //********....get candidates available for given clients......********//
      let result_3 = [];
      forEach(selectedClients, function (selectedClient) {
        forEach(availableGivenSelectedTempResult, function (value) {
          let A = filter(value.candidate.preferredClients, (item) => {
            // console.log("*********===========================************", item.id);
            return selectedClient.id === item.id;
          });

          if (A.length !== 0) {
            result_3.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientFiltered = uniqWith(result_3, isEqual);
      //*************end************//

      //********....get candidates available for given clients and shifts......********//
      let result_4 = [];
      forEach(selectedShifts, function (selectedShift) {
        forEach(uniqDateClientFiltered, function (value) {
          // console.log("inside shift filter element3s", element3.id);
          let A = filter(value.dayData, (item) => {
            if (
              item.shift != null &&
              selectedDate === moment(item.date).format("YYYY-MM-DD")
            ) {
              return selectedShift.id == item.shift.id;
            }

            return false;
          });

          if (A.length !== 0) {
            result_4.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftFiltered = uniqWith(result_4, isEqual);

      //********....get candidates available for given clients......********//
      let result_5 = [];
      forEach(selectedDepartments, function (selectedDepartment) {
        forEach(uniqDateClientShiftFiltered, function (value) {
          let A = filter(value.candidate.likeWards, (item) => {
            return selectedDepartment.id === item.id;
          });

          if (A.length !== 0) {
            result_5.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftDepartmentFiltered = uniqWith(result_5, isEqual);
      setRows(uniqDateClientShiftDepartmentFiltered);

      // *********===========end==================*********//
    } else if (w > 0 && x > 0 && y > 0 && z == 0) {
      // *********===========Start================*********//

      // {*****when--->>> date=true  client=true shift=true department=false ****}//

      //********....get candidates available for given clients......********//
      let result_3 = [];
      forEach(selectedClients, function (selectedClient) {
        forEach(availableGivenSelectedTempResult, function (value) {
          let A = filter(value.candidate.preferredClients, (item) => {
            return selectedClient.id === item.id;
          });

          if (A.length !== 0) {
            result_3.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientFiltered = uniqWith(result_3, isEqual);

      //*************end************//

      //********....get candidates available for given clients and shifts......********//
      let result_4 = [];
      forEach(selectedShifts, function (selectedShift) {
        forEach(uniqDateClientFiltered, function (value) {
          let A = filter(value.dayData, (item) => {
            if (
              item.shift != null &&
              selectedDate === moment(item.date).format("YYYY-MM-DD")
            ) {
              return selectedShift.id == item.shift.id;
            }

            return false;
          });

          if (A.length !== 0) {
            result_4.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftFiltered = uniqWith(result_4, isEqual);

      setRows(uniqDateClientShiftFiltered);

      // *********===========end==================*********//
    } else if (w > 0 && x == 0 && y == 0 && z == 0) {
      // console.log("inside if condition .............date only");
      // *********================================*********//
      // *********===========Start================*********//
      // *********================================*********//

      // {*****when--->>> date=true  client=false shift=false department=false ****}//

      setRows(availableGivenSelectedTempResult);
      // *********================================*********//
      // *********===========end==================*********//
      // *********================================*********//
    } else if (w == 0 && x > 0 && y == 0 && z == 0) {
      // {*****when--->>> date=false  client=true shift=false department=false ****}//
      //  console.log("inside if condition .............clients only");

      //********....get candidates available for given clients......********//
      let result_3 = [];
      forEach(selectedClients, function (selectedClient) {
        forEach(available, function (value) {
          let A = filter(value.candidate.preferredClients, (item) => {
            return selectedClient.id === item.id;
          });

          if (A.length !== 0) {
            result_3.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientFiltered = uniqWith(result_3, isEqual);

      //*************end************//

      setRows(uniqDateClientFiltered);
    } else if (w == 0 && x == 0 && y > 0 && z == 0) {
      // *********================================*********//
      // *********===========Start================*********//
      // *********================================*********//

      // {*****when--->>> date=true  client=false shift=true department=false ****}//
      // console.log("inside if condition .............shifts only");

      //********....get candidates available for given clients and shifts......********//
      let result_4 = [];
      forEach(selectedShifts, function (selectedShift) {
        forEach(available, function (value) {
          let A = filter(value.dayData, (item) => {
            if (item.shift != null) {
              return selectedShift.id == item.shift.id;
            }

            return false;
          });

          if (A.length !== 0) {
            result_4.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftFiltered = uniqWith(result_4, isEqual);

      setRows(uniqDateClientShiftFiltered);
    } else if (w == 0 && x == 0 && y == 0 && z > 0) {
      // *********================================*********//
      // *********===========Start================*********//
      // *********================================*********//

      // console.log("inside if condition .............department only");
      // {*****when--->>> date=true  client=false shift=false department=true ****}//

      let result_5 = [];
      forEach(selectedDepartments, function (selectedDepartment) {
        forEach(available, function (value) {
          let A = filter(value.candidate.likeWards, (item) => {
            return selectedDepartment.id === item.id;
          });

          if (A.length !== 0) {
            result_5.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftDepartmentFiltered = uniqWith(result_5, isEqual);
      setRows(uniqDateClientShiftDepartmentFiltered);
      // *********================================*********//
      // *********===========end==================*********//
      // *********================================*********//
    } else if (w == 0 && x == 0 && y > 0 && z > 0) {
      // *********================================*********//
      // *********===========Start================*********//
      // *********================================*********//
      // console.log(
      //   "inside if condition .............shifts and department only"
      // );
      // {*****when--->>> date=true  client=false shift=false department=false ****}//
      //********....get candidates available for given clients and shifts......********//
      let result_4 = [];
      forEach(selectedShifts, function (selectedShift) {
        forEach(available, function (value) {
          let A = filter(value.dayData, (item) => {
            if (item.shift != null) {
              return selectedShift.id == item.shift.id;
            }

            return false;
          });

          if (A.length !== 0) {
            result_4.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftFiltered = uniqWith(result_4, isEqual);

      //********....get candidates available for given clients......********//
      let result_5 = [];
      forEach(selectedDepartments, function (selectedDepartment) {
        forEach(uniqDateClientShiftFiltered, function (value) {
          let A = filter(value.candidate.likeWards, (item) => {
            return selectedDepartment.id === item.id;
          });

          if (A.length !== 0) {
            result_5.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftDepartmentFiltered = uniqWith(result_5, isEqual);
      setRows(uniqDateClientShiftDepartmentFiltered);

      // *********================================*********//
      // *********===========end==================*********//
      // *********================================*********//
    } else if (w == 0 && x > 0 && y > 0 && z > 0) {
      // {*****when--->>> date=false  client=true shift=true department=true ****}//

      // *********================================*********//
      // *********===========Start================*********//
      // *********================================*********//
      //********....get candidates available for given clients......********//
      let result_3 = [];
      forEach(selectedClients, function (selectedClient) {
        forEach(available, function (value) {
          let A = filter(value.candidate.preferredClients, (item) => {
            return selectedClient.id === item.id;
          });

          if (A.length !== 0) {
            result_3.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientFiltered = uniqWith(result_3, isEqual);
      //*************end************//

      //********....get candidates available for given clients and shifts......********//
      let result_4 = [];
      forEach(selectedShifts, function (selectedShift) {
        forEach(uniqDateClientFiltered, function (value) {
          let A = filter(value.dayData, (item) => {
            if (item.shift != null) {
              return selectedShift.id == item.shift.id;
            }

            return false;
          });

          if (A.length !== 0) {
            result_4.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftFiltered = uniqWith(result_4, isEqual);

      //********....get candidates available for given clients......********//
      let result_5 = [];
      forEach(selectedDepartments, function (selectedDepartment) {
        forEach(uniqDateClientShiftFiltered, function (value) {
          let A = filter(value.candidate.likeWards, (item) => {
            return selectedDepartment.id === item.id;
          });

          if (A.length !== 0) {
            result_5.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftDepartmentFiltered = uniqWith(result_5, isEqual);
      setRows(uniqDateClientShiftDepartmentFiltered);

      // *********================================*********//
      // *********===========end==================*********//
      // *********================================*********//
    } else if (w == 0 && x > 0 && y == 0 && z > 0) {
      // {*****when--->>> date=false  client=true shift=false department=true ****}//

      // console.log(
      //   "inside if condition .............clients and department only"
      // );

      // *********================================*********//
      // *********===========Start================*********//
      // *********================================*********//
      //********....get candidates available for given clients......********//
      let result_3 = [];
      forEach(selectedClients, function (selectedClient) {
        forEach(available, function (value) {
          let A = filter(value.candidate.preferredClients, (item) => {
            return selectedClient.id === item.id;
          });

          if (A.length !== 0) {
            result_3.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientFiltered = uniqWith(result_3, isEqual);
      //*************end************//

      //********....get candidates available for given clients......********//
      let result_5 = [];
      forEach(selectedDepartments, function (selectedDepartment) {
        forEach(uniqDateClientFiltered, function (value) {
          let A = filter(value.candidate.likeWards, (item) => {
            return selectedDepartment.id === item.id;
          });

          if (A.length !== 0) {
            result_5.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftDepartmentFiltered = uniqWith(result_5, isEqual);
      setRows(uniqDateClientShiftDepartmentFiltered);

      // *********================================*********//
      // *********===========end==================*********//
      // *********================================*********//
    } else if (w == 0 && x > 0 && y > 0 && z == 0) {
      // {*****when--->>> date=false  client=true shift=true department=false ****}//

      // *********================================*********//
      // *********===========Start================*********//
      // *********================================*********//
      //********....get candidates available for given clients......********//
      let result_3 = [];
      forEach(selectedClients, function (selectedClient) {
        forEach(available, function (value) {
          let A = filter(value.candidate.preferredClients, (item) => {
            return selectedClient.id === item.id;
          });

          if (A.length !== 0) {
            result_3.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientFiltered = uniqWith(result_3, isEqual);
      //*************end************//

      //********....get candidates available for given clients and shifts......********//
      let result_4 = [];
      forEach(selectedShifts, function (selectedShift) {
        forEach(uniqDateClientFiltered, function (value) {
          let A = filter(value.dayData, (item) => {
            if (item.shift != null) {
              return selectedShift.id == item.shift.id;
            }

            return false;
          });

          if (A.length !== 0) {
            result_4.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftFiltered = uniqWith(result_4, isEqual);

      setRows(uniqDateClientShiftFiltered);

      // *********================================*********//
      // *********===========end==================*********//
      // *********================================*********//
    } else if (w > 0 && x > 0 && y == 0 && z > 0) {
      // {*****when--->>> date=false  client=true shift=false department=false ****}//

      // *********================================*********//
      // *********===========Start================*********//
      // *********================================*********//
      //********....get candidates available for given clients......********//
      let result_3 = [];
      forEach(selectedClients, function (selectedClient) {
        forEach(availableGivenSelectedTempResult, function (value) {
          let A = filter(value.candidate.preferredClients, (item) => {
            return selectedClient.id === item.id;
          });

          if (A.length !== 0) {
            result_3.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientFiltered = uniqWith(result_3, isEqual);
      //*************end************//

      //********....get candidates available for given clients......********//
      let result_5 = [];
      forEach(selectedDepartments, function (selectedDepartment) {
        forEach(uniqDateClientFiltered, function (value) {
          let A = filter(value.candidate.likeWards, (item) => {
            return selectedDepartment.id === item.id;
          });

          if (A.length !== 0) {
            result_5.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftDepartmentFiltered = uniqWith(result_5, isEqual);
      setRows(uniqDateClientShiftDepartmentFiltered);

      // *********================================*********//
      // *********===========end==================*********//
      // *********================================*********//
    } else if (w > 0 && x == 0 && y > 0 && z > 0) {
      // {*****when--->>> date=true  client=false shift=true department=true ****}//

      // *********================================*********//
      // *********===========Start================*********//
      // *********================================*********//

      //********....get candidates available for given clients and shifts......********//
      let result_4 = [];
      forEach(selectedShifts, function (selectedShift) {
        forEach(availableGivenSelectedTempResult, function (value) {
          let A = filter(value.dayData, (item) => {
            if (
              item.shift != null &&
              selectedDate === moment(item.date).format("YYYY-MM-DD")
            ) {
              return selectedShift.id == item.shift.id;
            }

            return false;
          });

          if (A.length !== 0) {
            result_4.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftFiltered = uniqWith(result_4, isEqual);

      //********....get candidates available for given clients......********//
      let result_5 = [];
      forEach(selectedDepartments, function (selectedDepartment) {
        forEach(uniqDateClientShiftFiltered, function (value) {
          let A = filter(value.candidate.likeWards, (item) => {
            return selectedDepartment.id === item.id;
          });

          if (A.length !== 0) {
            result_5.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftDepartmentFiltered = uniqWith(result_5, isEqual);
      setRows(uniqDateClientShiftDepartmentFiltered);

      // *********================================*********//
      // *********===========end==================*********//
      // *********================================*********//
    } else if (w > 0 && x == 0 && y > 0 && z == 0) {
      // {*****when--->>> date=true  client=false shift=true department=false ****}//

      // *********================================*********//
      // *********===========Start================*********//
      // *********================================*********//

      //*************end************//

      //********....get candidates available for given clients and shifts......********//
      let result_4 = [];
      forEach(selectedShifts, function (selectedShift) {
        //console.log("***********H*************************H*************************H**************", selectedClient.id);
        forEach(availableGivenSelectedTempResult, function (value) {
          // console.log("inside shift filter element3s", element3.id);
          let A = filter(value.dayData, (item) => {
            if (
              item.shift != null &&
              selectedDate === moment(item.date).format("YYYY-MM-DD")
            ) {
              return selectedShift.id == item.shift.id;
            }

            return false;
          });

          if (A.length !== 0) {
            result_4.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftFiltered = uniqWith(result_4, isEqual);

      setRows(uniqDateClientShiftFiltered);

      // *********================================*********//
      // *********===========end==================*********//
      // *********================================*********//
    } else if (w > 0 && x == 0 && y == 0 && z > 0) {
      // {*****when--->>> date=false  client=false shift=false department=true ****}//

      // *********================================*********//
      // *********===========Start================*********//
      // *********================================*********//

      //********....get candidates available for given clients......********//
      let result_5 = [];
      forEach(selectedDepartments, function (selectedDepartment) {
        forEach(availableGivenSelectedTempResult, function (value) {
          let A = filter(value.candidate.likeWards, (item) => {
            return selectedDepartment.id === item.id;
          });

          if (A.length !== 0) {
            result_5.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientShiftDepartmentFiltered = uniqWith(result_5, isEqual);
      setRows(uniqDateClientShiftDepartmentFiltered);

      // *********================================*********//
      // *********===========end==================*********//
      // *********================================*********//
    } else if (w == 0 && x == 0 && y == 0 && z == 0) {
      // {*****when--->>> date=false  client=false shift=false department=false ****}//
      //only give the same results
    } else if (w > 0 && x > 0 && y == 0 && z == 0) {
      //********....get candidates available for given shifts......********//
      let result_3 = [];
      forEach(selectedClients, function (selectedClient) {
        forEach(availableGivenSelectedTempResult, function (value) {
          let A = filter(value.candidate.preferredClients, (item) => {
            return selectedClient.id === item.id;
          });

          if (A.length !== 0) {
            result_3.push({
              candidate: value.candidate,
              dayData: value.dayData,
            });
          }
        });
      });

      let uniqDateClientFiltered = uniqWith(result_3, isEqual);
      setRows(uniqDateClientFiltered);
    }
  };

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const MexxarHostAPI = HOSTMexxar;

  const reset = () => {
    //getAllRows();

    setMagicSearchData(null);
    setMagicSearchON(false);
    setRows(initialRows);
    setGroupSearch(false);
    setSelectedShifts([]);
    setSelectedClients([]);
    setSelectedDate([]);
    setSelectedDepartments([]);
  };

  useEffect(() => {
    getFieldData();

    //    getDepartments();
    //   getShifts();
  }, []);

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);
  const [selectedCandidate, setSelectedCandidate] = useState();

  const getAllRows = () => {
    axios
      .get(
        MexxarApiBookingWorkspace +
          "?employeeId=" +
          props.employeeID +
          "&startDate=" +
          moment(moment(startDate).subtract(1, "days")).format("YYYY/MM/DD") +
          "&endDate=" +
          moment(endDate).format("YYYY/MM/DD")
      )
      .then((res) => {
        // console.log("booking workspace data", res.data.body);

        let response = res.data.body;
        let datesTemp = [];
        forEach(response.dayData, function (item) {
          datesTemp.push({});
        });

        if (isMounted.current) {
          setInitialRows(res.data.body);
          setRows(res.data.body);
        }
      })
      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };

  useDidMountEffect(() => {
    if (magicSearchData != null && magicSearchON == true) {
      if (
        magicSearchData[0].type == "NHSP" ||
        magicSearchData[0].type == "HR"
      ) {
        //+++++++when booking a candidate from health roaster magic search table ,system detects that and mark it as a booked shift++++//
        let tempGroupSearch = [];
        forEach(magicSearchData[0].shifts, function (row) {
          let candidateName;
          let tempresult = filter(bookedReferenceNos, (bookedref) => {
            candidateName = bookedref.candidate;
            return row.RequestId === bookedref.ref;
          });

          if (tempresult.length > 0) {
            tempGroupSearch.push({ ...row, staff: tempresult[0].candidate });
            // row.staff = candidateName;
            // tempGroupSearch.push({ ...row });
          } else {
            tempGroupSearch.push(row);
          }
        });

        let tempMagicSearchData = [];

        tempMagicSearchData.push({
          ...magicSearchData[0],
          shifts: tempGroupSearch,
        });
        groupSearchPrepare(tempMagicSearchData);
      }
    }
  }, [initialRows]);

  const [clientListForBOTbookings, setClientListForBOTbookings] = useState([]);
  const [selecetedBotBookingClient, seSelecetedBotBookingClient] =
    useState(null);


    var storedAgency = JSON.parse(localStorage.getItem("selectedAgency"));
    
  const getAgencies = () => {
    let url = BotURL+"api/config/agency";

    axios.get(url).then((res) => {
      let response = res.data;

      // Log the client list from the CRM
      console.log("Agency client list from the CRM -->", clients);

      // Create a new list with updated items
      const updatedResponse = response?.map((agency) => {
        const client = clients?.find(
          (client) => client.value === agency.agencyName
        );
        return {
          ...agency,
          clientId: client ? client.id : null,
        };
      });

      // Log the updated response
      console.log(
        "Updated agency client list from the CRM -->",
        updatedResponse
      );

      // Update the state with the new list
      setClientListForBOTbookings(updatedResponse);
    });
  };

  const addBookingAutoOrManual = (data, candidate) => {
    if (autoBookingEnable) {
      addBookingWithBot(data, candidate);
    } else {
      addBooking(data, candidate);
    }
  };
  const addBookingWithBot = (data, candidate) => {
    axios
      .post(
        `${BotURL}api/booking/Candy/${selecetedBotBookingClient.agencyName}/book`,
        {
          firstName: candidate.firstName,
          lastName: candidate.lastName,
          bookingRef: data.requestId,
        }
      )
      .then((res) => {
        ToastCommon(true, "success", addToast);
      });
  };

  const addBooking = (data, candidate) => {
    // let BStatus;
    // check ? (BStatus = 0) : (BStatus = 1);
    // console.log("incoming data from magic booking data", data);
    // console.log("incoming data from magic booking candidate ", candidate);

    let shiftStartingDate = moment(data.shiftDate).format("YYYY-MM-DD");
    let shiftStartingTime = moment(data.startTime, "HH:mm").format("HH:mm:ss");

    let shiftEndingTime = moment(data.endTime, "HH:mm").format("HH:mm:ss");

    let shiftSTime = moment(shiftStartingDate).add(shiftStartingTime).toDate();
    let shiftETime = moment(shiftStartingDate).add(shiftEndingTime).toDate();

    let currentDate = moment(new Date()).toDate();

    // let selectedCandidateId =
    //   candidateId != undefined ? candidateId : Number(selectedCandidates);

    let splitedStartTime = split(data.startTime, ":");
    let ST = Number(splitedStartTime[0]);

    let splitedEndTime = split(data.endTime, ":");
    let ET = Number(splitedEndTime[0]);

    let shiftType;

    if (ST <= 12 && ST >= 6 && ET <= 16 && ET >= 10) {
      //  console.log("====++++++++++++++++++++++ condition o1 working");

      var tempArray = filter(shifts, (shift) => {
        return shift.value === "E";
      });
      shiftType = tempArray;
    } else if (ST <= 12 && ST >= 6 && ET <= 22 && ET >= 17) {
      // console.log("====++++++++++++++++++++++ condition o2 working");
      var tempArray = filter(shifts, (shift) => {
        return shift.value === "LD";
      });
      shiftType = tempArray;
    } else if (ST <= 16 && ST >= 11 && ET <= 23 && ET >= 17) {
      //   console.log("====++++++++++++++++++++++ condition o3 working");
      var tempArray = filter(shifts, (shift) => {
        return shift.value === "L";
      });
      shiftType = tempArray;
    } else if (ST <= 20 && ST >= 16 && ET <= 24 && ET >= 22) {
      //   console.log("===++++++++++++++++++++++ condition o4 working");
      var tempArray = filter(shifts, (shift) => {
        return shift.value === "TW";
      });
      shiftType = tempArray;
    } else if (ST <= 20 && ST >= 16 && ET <= 3 && ET >= 1) {
      //  console.log("===++++++++++++++++++++++ condition o5 working");
      var tempArray = filter(shifts, (shift) => {
        return shift.value === "TW";
      });
      shiftType = tempArray;
    } else if (ST <= 22 && ST >= 18 && ET <= 10 && ET >= 1) {
      // console.log("===++++++++++++++++++++++ condition o6 working");
      var tempArray = filter(shifts, (shift) => {
        return shift.value === "N";
      });
      shiftType = tempArray;
    } else {
      shiftType = null;
    }

    // console.log("shiftTypeshiftType shiftType", shiftType);

    //  axios
    // .get(MexxarAssigments + data.RequestGrade )
    // .then((res) => {
    //   let  specialityIdOfMagic=res.data.body[0].speciality.id

    // })
    let assigmentCode;

    if (magicSearchData[0].type == "NHSP") {
      assigmentCode = data.assignment;
    } else if (magicSearchData[0].type == "HR") {
      assigmentCode = data.requestGrade;
    }
    axios
      .all([
        axios.get(MexxarAssigments + assigmentCode),
        axios.get(HOSTMexxar + "client-wards/clients/" + selectedClients[0].id),
      ])
      .then(
        axios.spread((res1, res2) => {
          let specialityIdOfMagic = res1.data.body[0].speciality.id;
          let tempDepart = filter(res2.data.body, (department) => {
            // console.log("++++++++++++++department", department.value);
            // console.log("++++++++++++++shift.ward", shift.ward);

            return department.wardName === data.ward;
          });

          console.log("Data from client-wards API:", res2.data.body);
          let arrangData = {
            bookingDate: shiftStartingDate,
            bookingStatus: 0,
            createdDate: currentDate,
            referenceNo: data.requestId,
            candidateId: candidate.candidateId,
            clientsId: Number(selectedClients[0].id),
            // employeeId: props.auth.item.id,
            specialityId: specialityIdOfMagic,
            shiftId: Number(shiftType[0].id),
            wardId: Number(tempDepart[0].wardId),
            shiftTo: shiftETime,
            shiftFrom: shiftSTime,
            bonus: data.bonus !== undefined ? Number(data.bonus) : null,
            upLift: data.upLift !== undefined ? Number(data.upLift) : null,
          };

          axios
            .post(MexxarCreateBooking, arrangData)
            .then((res) => {
              if (res.data.status == "success") {
                getAllRows();
                ToastCommon(true, "success", addToast);
                sendBookingConfirmationEmail(
                  arrangData,
                  candidate,
                  selectedClients[0],
                  tempDepart[0]
                );
              } else if (res.data.status == "success") {
                ToastCommon(true, "already-booked", addToast);
              } else ToastCommon(true, "error", addToast);
            })
            .catch((err) => {
              // setDisableCreateButton(false);
              ToastCommon(true, "error", addToast);
            });
        })
      );
  };

  const { instance, accounts } = useMsal();

  //Function : sending the confirmation email to the candidate

  const sendBookingConfirmationEmail = (
    data,
    candidateWhenClick,
    selectedClient,
    selectedWard
  ) => {
    let subject = `Booking Confirmation: ${data?.referenceNo}`;
    let body = `<p>Hello ${
      candidateWhenClick?.firstName + " " + candidateWhenClick?.lastName
    },</p>
                  <p>We are writing to confirm your booking for ${moment(
                    data?.bookingDate
                  ).format(
                    "ddd, DD-MM-YYYY"
                  )} with ${CompanyName}. Please find the details below:</p>
                  <ul>
                    <li>Reference no: ${data?.referenceNo}</li>
                    <li>Date: ${data?.bookingDate}</li>
                    <li>Hospital: ${selectedClient?.value}</li>
                    <li>Ward: ${selectedWard?.wardName}</li>
                    <li>Start time: ${moment
                      .utc(data?.shiftFrom)
                      .local()
                      .format("h:mm A")}</li>
                    <li>End time: ${moment
                      .utc(data?.shiftTo)
                      .local()
                      .format("h:mm A")}</li>
                </ul>
    `;
    let to = [
      {
        id: candidateWhenClick?.candidateId,
        value: candidateWhenClick?.email,
        label:
          candidateWhenClick?.firstName + " " + candidateWhenClick?.lastName,
      },
    ];
    let cc = [];
    let bcc = [];
    let file = [];

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        sendEmail(
          response?.accessToken,
          to,
          cc,
          bcc,
          subject,
          body,
          file,
          props.user
        )
          .then((response) => {
            ToastCommon(true, "sent", addToast);
          })
          .catch((error) => {});
      })
      .catch((error) => console.log(error));
  };

  const showCallShellModal = (day, row) => {
    if (day.availabilityStatus === "BOOKED") {
      setShowCellSummery(!showCellSummery);
    } else if (day.availabilityStatus === "AVAILABLE") {
      setShowCalendarModal(!showCalendarModal);
    } else if (day.availabilityStatus === "NOT_MARKED") {
      setShowCalendarModal(!showCalendarModal);
      // setShowCellStatusChange(!showCellStatusChange);
    } else if (day.availabilityStatus === "VACATION") {
      setShowCalendarModal(!showCalendarModal);
      // setShowCellStatusChange(!showCellStatusChange);
    }
    setDayData(day);
    setSelectedRowData(row);
  };

  useEffect(() => {
    getAllRows();
    // }, [rowID || resetSheetData]);\
  }, [resetSheetData, props.employeeID]);

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#6A6767",
    }),
  };

  const [availibilityLoading, setAvailibilityLoading] = useState({
    bool: false,
    client: "",
  });
  const [availibilityLoadingError, setAvailibilityLoadingError] = useState({
    bool: false,
    client: "",
  });

  const getAvailableBookingListForAutoBooking = (client) => {
     setAvailibilityLoading({ bool: true, client: client });
     setAvailibilityLoadingError({ bool: false, client: "" });

    let AutoBookingUrl = `${BotURL}api/booking/Candy/${client?.label}`;
    async function fetchShifts() {
      try {
        const res = await axios.get(AutoBookingUrl);

        const shifts = res.data.items.map((shift) => ({
          shiftDate: shift.date,
          endTime: shift.end,
          RequestGrade: shift.requestGrade,
          RequestId: shift.requestId,
          startTime: shift.start,
          ward: shift.unit,
        }));
        setAvailibilityLoading({ bool: false, client: "" });
        return shifts;
      } catch (error) {
        setAvailibilityLoadingError({ bool: true, client: client });
        setAvailibilityLoading({ bool: false, client: "" });
        throw new Error("Error fetching shifts: " + error.message);
      }
    }


 
    async function fetchDataAndPrepare() {
      try {
        const shifts = await fetchShifts();
        const data = [
          {
            type: "HR",
            hospital: client?.label,
            hospitalId: client?.id,
            shifts: shifts,
          },
        ];
        groupSearchPrepare(data);
      } catch (error) {
        console.error(error.message);
      }
    }

    fetchDataAndPrepare();
  };

    

  const [autoBookingEnable, setAutoBookingEnable] = useState(false);

  useDidMountEffect(() => {
    localStorage.setItem("AutoBookingStatus", autoBookingEnable);
  }, [autoBookingEnable]);

  useEffect(() => {
    const autoBookingStatus = localStorage.getItem("AutoBookingStatus");

    if (autoBookingStatus == "true") {
      setAutoBookingEnable(true);
    } else {
      localStorage.setItem("AutoBookingStatus", false);
    }
  }, []);

  return (
    <div>
      <div className="form-inline">
        <div>
          <small>
            Auto Booking
            <Input
              className="mx-2 pointer"
              type="checkbox"
              value="REMEMBER_AUTO_BOOKING"
              checked={autoBookingEnable}
              onChange={() => setAutoBookingEnable(!autoBookingEnable)}
            ></Input>
          </small>
        </div>
        <Button
          onClick={() => {
            setShowConfigureModal(true);
            // setMagicSearchON(true);
          }}
          color="secondary m-1 btn-sm"
          style={{ width: "100px" }}
          id="configure"
        >
          <small>Configure</small>
        </Button>
        <Button
          onClick={() => {
            setShowGroupSearchModal(true);
            setMagicSearchON(true);
          }}
          color="success m-1 btn-sm"
          style={{ width: "100px" }}
          id="magicSearch"
        >
          <small>Magic Search</small>
        </Button>
        <Button
          color="danger m-1 btn-sm mr-2"
          style={{ width: "70px" }}
          onClick={reset}
          id="magicSearchReset"
        >
          <small>Reset</small>
        </Button>
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
          id="searchBar"
        />
        <UncontrolledTooltip target="searchBar">
          Search by Candidate name/Email/Phone Number
        </UncontrolledTooltip>
      </div>
      <br></br>

      <div
        className="d-flex flex-row w-auto "
        style={{ overflowX: "auto" }}
      >
        {storedAgency?.map((client, id) => {
           return (
            <>
            <Button
              className="d-flex flex-column"
              disabled={isNull(client.id)}
              onClick={() => {
                {
                  getAvailableBookingListForAutoBooking(client);
                  seSelecetedBotBookingClient(client);
                }
              }}
              color="info m-1 btn-sm"
            >
              <small>{client?.label}</small>
              {availibilityLoading.bool &&
                availibilityLoading.client?.id === client?.id && (
                  <Spinner
                    className="mx-2  "
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                    loading={availibilityLoading.bool}
                  />
                )}
              {availibilityLoadingError.bool &&
                availibilityLoadingError.client?.id === client?.id && (
                  <FontAwesomeIcon
                    color="warning"
                    icon={faExclamationCircle}
                  ></FontAwesomeIcon>
                )}
            </Button>           
            </>
          );
        })}
      </div>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody style={{ zIndex: 999 }}>
            <Row>
              <Col>
                <Label>Date</Label>
                <Input
                  value={selectedDate}
                  id="filter_ID"
                  type="date"
                  onChange={(e) => setSelectedDate(e.target.value)}
                ></Input>
              </Col>
              <Col>
                <Label>{ClientLable}s</Label>
                <Select
                  isMulti
                  value={selectedClients}
                  styles={colourStyles}
                  z-index="20"
                  name="clients"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={clients}
                  onChange={(data) => setSelectedClients(data)}
                />
              </Col>
              <Col>
                <Label>Shift</Label>
                <Select
                  value={selectedShifts}
                  styles={colourStyles}
                  isMulti
                  name="shifts"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={shifts}
                  onChange={(data) => setSelectedShifts(data)}
                />
              </Col>
              <Col>
                <Label>Departments</Label>
                <Select
                  value={selectedDepartments}
                  styles={colourStyles}
                  isMulti
                  name="ShifTtype"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={departments}
                  onChange={(data) => setSelectedDepartments(data)}
                />
              </Col>
              <Col>
                <div className="d-flex flex-column align-items-end">
                  {/* <Button
                    onClick={() =>
                      setShowGroupSearchModal(!showGroupSearchModal)
                    }
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                  >
                    Magic Search
                  </Button> */}

                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        // value="EXPIRED"
                        checked={bookedOrAvailable}
                        onChange={() =>
                          setBookedOrAvailable(!bookedOrAvailable)
                        }
                      />{" "}
                      <Badge color="danger" className="mr-1">
                        Type : {bookedOrAvailable ? "Availibility" : "Bookings"}{" "}
                      </Badge>
                    </Label>
                  </FormGroup>

                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={() => handleSearch()}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={reset}
                  >
                    Reset
                  </Button>
                  {/* <Button color="primary m-1 btn-sm" style={{ width: "70px" }}>
                    Quick Draft
                  </Button> */}
                </div>
              </Col>
            </Row>
            <Row></Row>
          </CardBody>
        </Card>
      </Collapse>

      <div className="p-2">
        <Row>
          <Col md={4}>
            <FontAwesomeIcon icon={faUsers} className={"ml-2 mr-3"} />
            <Label>{initialRows.length} Candidates</Label>
            {!groupSearch && (
              <div className="ml-2">
                <label className="ui-check m-0">
                  <Input
                    type="checkbox"
                    onChange={() => setShowAdvanceColumn(!showAdvanceColumn)}
                  />
                  <i></i>
                </label>
                <Label
                  className="text-color text-sm mt-3"
                  id="showAdvanceColumn"
                >
                  Advanced Options
                </Label>

                <UncontrolledTooltip
                  placement="right"
                  target="showAdvanceColumn"
                >
                  Check to see advanced options
                </UncontrolledTooltip>
              </div>
            )}
          </Col>
          <Col md={3}>
            <small>From</small>
            <Input
              type="date"
              name="startDate"
              defaultValue={moment(startDate).format("YYYY-MM-DD")}
              onChange={(e) => setStartDate(e.target.value)}
            ></Input>
          </Col>
          <Col md={3}>
            <small>To</small>

            <Input
              type="date"
              name="endDate"
              defaultValue={moment(endDate).format("YYYY-MM-DD")}
              onChange={(e) => setEndDate(e.target.value)}
            ></Input>
          </Col>
          <Col md={2}>
            <Button className="mt-3 bg-success" onClick={() => getAllRows()}>
              Set Range
            </Button>
          </Col>
        </Row>
      </div>

      {/* //.....Start of the row mapping.....// */}

      {!groupSearch && (
        <BookingWorkspaceDefaultView
          showAdvanceColumn={showAdvanceColumn}
          rows={rows}
          setSelectedCandidate={setSelectedCandidate}
          searchQuery={searchQuery}
          showEmailModal={showEmailModal}
          setShowEmailModal={setShowEmailModal}
          setCalendarShow={setCalendarShow}
          calendarShow={calendarShow}
          setRowID={setRowID}
          setClientRateCandidate={setClientRateCandidate}
          setShowHospitalRateModal={setShowHospitalRateModal}
          showHospitalRateModal={showHospitalRateModal}
          setShowLikeModal={setShowLikeModal}
          showLikeModal={showLikeModal}
          setShowDislikeModal={setShowDislikeModal}
          showDislikeModal={showDislikeModal}
          showCallShellModal={showCallShellModal}
        />
      )}
      {/* {groupSearch && groupSearchType == "NHSP" && (
        <NHSPMagicResultsTable
        magicWardsMatch={matchingWards}
        magicWardsNotMatch={nonMatchingWards}
          groupRows={groupRows}
          addBooking={addBooking}
          setBookedReferenceNos={setBookedReferenceNos}
          bookedReferenceNos={bookedReferenceNos}
        />
      )}

      {groupSearch && groupSearchType == "HR" && (
        <HealthroasterMagicResultsTable
          magicWardsMatch={matchingWards}
          magicWardsNotMatch={nonMatchingWards}
          groupRows={groupRows}
          addBooking={addBooking}
          setBookedReferenceNos={setBookedReferenceNos}
          bookedReferenceNos={bookedReferenceNos}
        />
      )} */}
      {groupSearch && groupSearchType === "NHSP" && (
        <NHSPMagicResultsTable
          magicWardsMatch={matchingWards}
          magicWardsNotMatch={nonMatchingWards}
          groupRows={groupRows}
          addBooking={addBookingAutoOrManual}
          setBookedReferenceNos={setBookedReferenceNos}
          bookedReferenceNos={bookedReferenceNos}
          departmentNames={departmentNames}
        />
      )}

      {groupSearch && groupSearchType === "HR" && (
        <HealthroasterMagicResultsTable
          magicWardsMatch={matchingWards}
          magicWardsNotMatch={nonMatchingWards}
          groupRows={groupRows}
          addBooking={addBooking}
          setBookedReferenceNos={setBookedReferenceNos}
          bookedReferenceNos={bookedReferenceNos}
          selectedClients={selectedClients}
          departmentNames={departmentNames}
          selectedDepartments={selectedDepartments}
          hospitalId={hospitalId}
        />
      )}
      {/* //.....end of the row mapping.....// */}

      <ModalComponent
        show={calendarShow}
        header="Reschedule a call"
        closeModal={() => setCalendarShow(false)}
      >
        <SmallCalendar
          onCreate={(data) => resheduleCall(data)}
          closeModal={() => setCalendarShow(false)}
        />
      </ModalComponent>

      <ModalComponent
        show={showCellSummery}
        header="Booking Summary"
        closeModal={() => setShowCellSummery(false)}
      >
        <BookingSummery
          // currentResetState={resetSheetData}
          //reset={(e) => setResetSheetData(e)}
          candidate={selectedCandidate}
          candidateId={rowID}
          dayData={dayData}
          heading="Booking summary"
          selectedRowData={selectedRowData}
          closeModal={() => setShowCellSummery(false)}
          reset={(e) => setResetSheetData(e)}
          currentResetState={resetSheetData}
          incomingPage="BOOKING_WORKSPACE"
        />
      </ModalComponent>

      <ModalComponent
        show={showCellStatusChange}
        header="Change status"
        closeModal={() => setShowCellStatusChange(false)}
      >
        <BookingWSCellClick
          //currentResetState={resetSheetData}
          //reset={(e) => setResetSheetData(e)}
          //candidateId={rowID}

          heading="Change booking status"
          closeModal={() => setShowCellStatusChange(false)}
        />{" "}
      </ModalComponent>
      <ModalComponent
        show={showLikeModal}
        header="Add Like Departments"
        closeModal={() => setShowLikeModal(false)}
      >
        <LikeWard
          closeModal={() => setShowLikeModal(false)}
          candidateId={rowID}
          currentResetState={resetSheetData}
          reset={(e) => setResetSheetData(e)}
          type="Like"
          heading="Add Like Departments"
        />
      </ModalComponent>

      <ModalComponent
        show={showDislikeModal}
        header="Add Dislike Departments"
        closeModal={() => setShowDislikeModal(false)}
      >
        <LikeWard
          closeModal={() => setShowDislikeModal(false)}
          candidateId={rowID}
          currentResetState={resetSheetData}
          reset={(e) => setResetSheetData(e)}
          type="DisLike"
          heading="Add Dislike Departments"
        />
      </ModalComponent>

      <ModalComponent
        show={showEmailModal}
        header="Email and SMS"
        closeModal={() => setShowEmailModal(false)}
      >
        <EmailNav
          closeModal={() => setShowEmailModal(false)}
          incomingPage="Bookings"
          user={
            selectedCandidate != undefined && selectedCandidate != null
              ? selectedCandidate
              : null
          }
        />
      </ModalComponent>

      <ModalComponent
        show={showCalendarModal}
        header="Calendar"
        closeModal={() => {
          setShowCalendarModal(false);
          setDayData();
          setResetSheetData(!resetSheetData);
        }}
      >
        <CalendarNav
          candidate={selectedCandidate}
          selectedRowData={selectedRowData}
          dayData={dayData}
          currentResetState={resetSheetData}
          // reset={(e) => setResetSheetData(e)}
          candidateId={rowID}
          closeModal={() => {
            setShowCalendarModal(false);
            setDayData();
            setResetSheetData(!resetSheetData);
          }}
        />{" "}
      </ModalComponent>

      <ModalComponent
        className="modal-dialog modal-lg"
        show={showHospitalRateModal}
        header="Candidate Rate"
        closeModal={() => setShowHospitalRateModal(false)}
      >
        <ClientRateBookingModal
          candidate={clientRateCandidate}
          closeModal={() => setShowHospitalRateModal(false)}
        />

        {/*<ClientRateModal toggle={()=> setShowHospitalRateModal(!showHospitalRateModal)} client={{id : 1}}></ClientRateModal>*/}
      </ModalComponent>

      <ModalComponent
        className="modal-dialog modal-lg"
        show={showGroupSearchModal}
        header="Magic Search"
        closeModal={() => setShowGroupSearchModal(false)}
      >
        <GroupSearch
          closeModal={() => setShowGroupSearchModal(false)}
          clients={clients}
          onCreate={(data) => {
            groupSearchPrepare(data);
            // setGroupSearch(true);
          }}
          // setGroupSearch(true);
        />
      </ModalComponent>


      <ModalComponent
        className="modal-dialog modal-lg"
        show={showConfigureModal}
        header="Configure"
        closeModal={() => setShowConfigureModal(false)}
      >
        <BookingConfigure
          closeModal={() => setShowConfigureModal(false)}
          clients={clients}
          agencyList={clientListForBOTbookings}
          onCreate={(data) => {
            groupSearchPrepare(data);
            // setGroupSearch(true);
          }}
          // setGroupSearch(true);
        />
      </ModalComponent>
      <ModalComponent
        show={showQuickModal}
        header="Timesheet Summary"
        closeModal={() => setShowQuickModal(false)}
      >
        {/* <QuickDraft closeModal={()=>setShowQuickModal(!showQuickModal)} /> */}
      </ModalComponent>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    theme: state.theme.theme,
    auth: state.auth,
    user: state.auth.item,
  };
}

export default connect(mapStateToProps, {})(BookingWorkspace);
