import { useEffect, useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";

export default function useCandidateList(
  url,
  filterCustom,
  query,
  pageNumber,
  searchAdvanced,
  resetResults,
  offset = 20,
 ) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [totalPages, setTotalPages] = useState(2);

  useEffect(() => {
    setRows([]);
  }, [query]);

 

  useDidMountEffect(() => {
    setRows([]);
  }, [searchAdvanced]);

  useDidMountEffect(() => {
    setRows([]);
  }, [resetResults]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      url: url,
      params: { page: pageNumber, offset: offset, q: query },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setTotalElements(res.data.body.totalElements);
        setTotalPages(res.data.body.totalPages);
        setRows((prevBooks) => {
          return [...new Set([...prevBooks, ...res.data.body.content])];
        });
        // setHasMore(res.data.body.content.length > 0);  removed.in  case bug arrise.replace below with this

        setHasMore(Number(offset) === Number(res.data.body.numberOfElements));

        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setLoading(false);
        setError(true);
      });
    return () => cancel();
  }, [searchAdvanced, query, pageNumber, resetResults]);

  return { loading, error, rows, hasMore, totalElements, totalPages };
}
