import axios from "axios";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";
import ToastCommon from "../../../toastCommon";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import newClient from "../newClient";
import { useToasts } from "react-toast-notifications";

export default function UpdateHospitalNote(props) {
  const { register, handleSubmit, errors } = useForm();
  const [addKillLog, setaddKillLog] = useState(false);
  
  const { addToast } = useToasts();
  const {
    onCreate,
    closeModal1,
    mode,
    modalType,
    fieldDataOne,
    fieldDataTwo,
    rowNoteID,
    rowID,
    auth,
    rowvalue,
    action
  } = props;
  const [value, setValue] = useState("");

  const changeHandler = (e) => {
    const value = e.target.value;
    const capitalizedValue = value.replace(/\b\w/g, (char) => char.toUpperCase());
    setValue(capitalizedValue);
  };




  const updateNote = () => {
    const noteValue = value.trim() !== "" ? value : rowvalue;
    axios
      .put("https://permapi.mexxar.com/clientNote/" + rowNoteID, {
        note: noteValue,
        employee: auth,
        clients: rowID,
      })
      .then((res) => {
        if (res.data.status === "Success") {
          action();
          ToastCommon(true, "success", addToast);
        } else {
         
        }
      })
      .catch((err) => {
        console.error("Error updating note:", err);
        
      });
  };
  

  console.log("RowID", rowID)
  return (
    <Form >
      <FormGroup row>
        <Label sm={4}>Note</Label>
        <Col sm={8}>
          <Input
            //  value={rowvalue}
            onChange={changeHandler}
            type="text"
            name="note"
            defaultValue={rowvalue}
            invalid={errors.note}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allowed only 30 characters",
              },
            })}
          ></Input>
          {errors.todo && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.todo?.message}
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>



      <Row className="justify-content-end">
        <div className="d-flex ">
          {" "}
          <Button
            className="mr-2"
            color="success"
           

            onClick={() => {
              updateNote();
              closeModal1();
             
            }}
          >
            Update
          </Button>{" "}
          {/* </Link> */}
          <Button color="secondary" onClick={() => {
            closeModal1();
          }} >
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}
