import React, { useState } from "react";
import axios from "axios";
import { forEach } from "lodash";
import { useEffect } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  CANDIDATE_SPECIALITY,
  TRAININGPROVIDERS,
} from "../../../../configs/api-config";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";

export default function NotificationModal2(props) {
  //   const MexxarApiSpeciality = CANDIDATE_SPECIALITY;
  //   const MexxarApiTrainingProviders = TRAININGPROVIDERS;

  const { register, handleSubmit, errors } = useForm();
  const {
    onCreate,
    closeModal,
    mode,
    modalType,
    modalType1,
    modalType2,
    modalType3,
    modalType4,
    modalType5,
    modalType6,
    modalType7,
    modalType8,
    modalType9,
    modalType10,
    modalType11,
    modalType12,
    modalType13,
    rowNameDataalert1, 
    rowNameDataalert2, 
   rowNameDataalert3, 
   rowNameDatrestrictedDays, 
   rowNameDataheadhunterStatus, 
    
   rowNameDatabookingStatus, 
   rowNameDataregistrationStatus,
   
    rowNameDataauditStatus,
    rowNameDatamaintenanceStatus,
    rowNameDatadbsLiveCheckTimer,
    rowNameDatanmcLiveCheckTimer,
    rowNameDatahcpcLiveCheckTimer,
    rowNameDatarestrictedDaysRtw,
    rowNameDatauploadDBSPdf,
    rowNameDatauploadHCPCPdf,
    rowNameDatauploadNMCPdf
   

  } = props;


  const [addKillLog, setaddKillLog] = useState(false);
  

  const onSubmit = (data) => {
    console.log(data);
    setaddKillLog(true);

    let arrangedData = {
        rowAlert1: data.rowAlert1,
        rowAlert2: data.rowAlert2,
        rowAlert3: data.rowAlert3,
        rowRestrictedDays: data.rowRestrictedDays,
        rowHeadhunterStatus: data.rowHeadhunterStatus,
        rowBookingStatus: data.rowBookingStatus,
        rowRegistrationStatus: data.rowRegistrationStatus,
        rowAuditStatus: data.rowAuditStatus,
        rowMaintenanceStatus: data.rowMaintenanceStatus,
        rowDbsLiveCheckTimer: data.rowDbsLiveCheckTimer,
        rowNmcLiveCheckTimer: data.rowNmcLiveCheckTimer,
        rowHcpcLiveCheckTimer: data.rowHcpcLiveCheckTimer,
        rowRestrictedDaysRtw: data.rowRestrictedDaysRtw,
        rowuploadDBSPdf:data.rowuploadDBSPdf,
        rowuploadHCPCPdf:data.rowuploadDBSPdf,
        rowuploadNMCPdf:data.rowuploadNMCPdf
      

    };

    onCreate(arrangedData);
};

console.log ('babab',  rowNameDataalert1)
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={4}>{modalType1}</Label>
        <Col sm={8}>
          <Input
            type="text"
            name="rowAlert1"
            className="capitalize"
            defaultValue={rowNameDataalert1}
            invalid={errors.bName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
        </Col>

      </FormGroup>

      <FormGroup row>
        <Label sm={4}>{modalType2}</Label>
        <Col sm={8}>
          <Input
            type="text" 
            name="rowAlert2"
            className="capitalize"
            defaultValue={rowNameDataalert2}
            invalid={errors.rName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
        </Col>
      </FormGroup>

    
      <FormGroup row>
        <Label sm={4}>{modalType3}</Label>
        <Col sm={8}>
          <Input
            type="text"
            name="rowAlert3"
            className="capitalize"
            defaultValue={rowNameDataalert3}
            invalid={errors.bName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
        </Col>

      </FormGroup>


      <FormGroup row>
        <Label sm={4}>{modalType4}</Label>
        <Col sm={8}>
          <Input
            type="text"
            name="rowRestrictedDays"
            className="capitalize"
            defaultValue={rowNameDatrestrictedDays}
            invalid={errors.bName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
        </Col>

      </FormGroup>

      <FormGroup row>
        <Label sm={4}>{modalType5}</Label>
        <Col sm={8}>
          <select
            name="rowuploadDBSPdf"
            className="capitalize form-control"
            defaultValue={rowNameDatauploadDBSPdf}
            ref={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          >
            {/* <option value="" disabled>Select a day</option> */}
            <option value="ENABLE">ENABLE</option>
            <option value="DISABLE">DISABLE</option>
         
          </select>
         
        </Col>
      </FormGroup> 
     


      <FormGroup row>
        <Label sm={4}>{modalType6}</Label>
        <Col sm={8}>
          <select
            name="rowuploadHCPCPdf"
            className="capitalize form-control"
            defaultValue={rowNameDatauploadHCPCPdf}
            ref={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          >
            {/* <option value="" disabled>Select a day</option> */}
            <option value="ENABLE">ENABLE</option>
            <option value="DISABLE">DISABLE</option>
         
          </select>
         
        </Col>
      </FormGroup> 

     

      <FormGroup row>
        <Label sm={4}>{modalType7}</Label>
        <Col sm={8}>
          <select
            name="rowuploadNMCPdf"
            className="capitalize form-control"
            defaultValue={rowNameDatauploadNMCPdf}
            ref={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          >
   
            <option value="ENABLE">ENABLE</option>
            <option value="DISABLE">DISABLE</option>
         
          </select>
         
        </Col>
      </FormGroup> 


     {/* <FormGroup row>
        <Label sm={4}>{modalType8}</Label>
        <Col sm={8}>
          <select
            name="rowAuditStatus"
            className="capitalize form-control"
            defaultValue={rowNameDataauditStatus}
            ref={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          >
       \
            <option value="ACTIVE">ACTIVE</option>
            <option value="INACTIVE">INACTIVE</option>
         
          </select>
         
        </Col>
      </FormGroup>   */}



{/* <FormGroup row>
        <Label sm={4}>{modalType9}</Label>
        <Col sm={8}>
          <select
            name="rowMaintenanceStatus"
            className="capitalize form-control"
            defaultValue={rowNameDatamaintenanceStatus}
            ref={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          >
            
            <option value="ACTIVE">ACTIVE</option>
            <option value="INACTIVE">INACTIVE</option>
         
          </select>
         
        </Col>
      </FormGroup>  */}


   <FormGroup row>
        <Label sm={4}>{modalType10}</Label>
        <Col sm={8}>
          <Input
            type="text"
            name="rowDbsLiveCheckTimer"
            className="capitalize"
            defaultValue={rowNameDatadbsLiveCheckTimer}
            invalid={errors.bName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
          <div>Uses as months so need to add a title to as the user to add months such as 1, 2 ,3 months ect</div>  
        </Col>

      </FormGroup>
      <FormGroup row>
        <Label sm={4}>{modalType11}</Label>
        <Col sm={8}>
          <Input
            type="text"
            name="rowNmcLiveCheckTimer"
            className="capitalize"
            defaultValue={rowNameDatanmcLiveCheckTimer}
            invalid={errors.bName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
          <div>Uses as months so need to add a title to as the user to add months such as 1, 2 ,3 months ect</div>  
        </Col>

      </FormGroup>


      <FormGroup row>
        <Label sm={4}>{modalType12}</Label>
        <Col sm={8}>
          <Input
            type="text"
            name="rowHcpcLiveCheckTimer"
            className="capitalize"
            defaultValue={rowNameDatahcpcLiveCheckTimer}
            invalid={errors.bName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
            
          ></Input>
           <div>Uses as months so need to add a title to as the user to add months such as 1, 2 ,3 months ect</div>  
        </Col>

      </FormGroup>
 

      <FormGroup row>
        <Label sm={4}>{modalType13}</Label>
        <Col sm={8}>
          <Input
            type="text"
            name="rowRestrictedDaysRtw"
            className="capitalize"
            defaultValue={rowNameDatarestrictedDaysRtw}
            invalid={errors.bName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
            
          ></Input>
           <div>Uses as months so need to add a title to as the user to add months such as 1, 2 ,3 months ect</div>  
        </Col>

      </FormGroup>
 










      <Row className="justify-content-end">
        <div className="d-flex ">
          {" "}
          <Button
            className="mr-2"
            color="success"
            type="submit"
            disabled={addKillLog}
          >
            {mode}
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}
