import React, { useState, useEffect } from "react";

import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Alert,
  Input,
} from "reactstrap";

import axios from "axios";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { forEach, filter, split, uniqBy } from "lodash";
import ToastCommon from "../../../../../components/toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  CompanyName,
  REGISTER_CANDIDATE,
} from "../../../../../configs/api-config";
import useDidMountEffect from "../../../../../components/Modal/ModalBody/intialRunUseEffect";

const CriminalRecordsCheck = () => {
  const MexxarApi = REGISTER_CANDIDATE;

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();

  const [yesIds, setYesIds] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [newQArray, setNewQArray] = useState([]);
  const [editable, setEditable] = useState(false);
  const [questionsId, setQuestionsId] = useState([]);
  const [candidateID, setCandidateID] = useState([]);
  const [basicDetails, setBasicDetails] = useState([]);

  const [questions, setQuestions] = useState([
    {
      questionNo: 1,
      question:
        "Do you have any convictions, cautions, reprimands or final warnings that are not “protected” as defined by the Rehabilitation of Offenders Act 1974 (Exceptions) Order 1975 (as amended in 2013) by SI 2013 1198? ",
    },
    {
      questionNo: 2,
      question:
        "Do you have any convictions, cautions, reprimands or final warnings which would not be filtered in line with current guidance?",
    },
    {
      questionNo: 3,
      question:
        "Have you had a Police check in another country within the last 6 months? If so, please provide details below and enclose a copy if held.",
    },
    {
      questionNo: 4,
      question:
        "Have you ever been suspended or are you currently under investigation by an NHS Trust, professional body or any other organisation?",
    },
    {
      questionNo: 5,
      question:
        "Have you ever had an Enhanced Disclosure and Barring Service (DBS) check? (formerly Criminal Records Bureau check or CRB) ",
    },
  ]);

  const getCandidateID = () => {
    let pathArray = window.location.pathname.split("/");
    const CandidateId = Number(pathArray[3]);
    //**********CandidateId and candidateID have same value but variable names are different*******/
    setCandidateID(CandidateId);
  };

  const getData = () => {
    axios
      .get(
        MexxarApi +
          "/" +
          candidateID +
          "/advance-profile-details/category/CRIMINAL_RECORDS"
      )
      .then((res) => {
        //.......check if a new form filling...............//
        if (res.data.body.length === 0) {
          // console.log(" new form filling ");
          setNewQArray(questions);
        } else {
          //.......check if a update form filling...............//
          let basicData = [];

          let CRQ6 = filter(res.data.body, (item) => {
            return item.identifier === "CRQ6";
          });

          let CRQ7 = filter(res.data.body, (item) => {
            return item.identifier === "CRQ7";
          });

          basicData.push({
            CRQ6ID: CRQ6.length > 0 ? CRQ6[0].id : "",
            CRQ6: CRQ6.length > 0 ? CRQ6[0].value : "",
            CRQ7ID: CRQ7.length > 0 ? CRQ7[0].id : "",
            CRQ7: CRQ7.length > 0 ? CRQ7[0].value : "",
          });

          setBasicDetails(basicData[0]);

          //.........radio buttons incoming data arranging..............//
          let data_List = [];
          //.......Filter radio buttons by the number of CRQ (ex:CRQ1) only getting from 1 to 5 ......//
          for (let i = 1; i < 6; i++) {
            forEach(res.data.body, function (item) {
              {
                if (item.identifier.includes(i))
                  data_List.push({
                    id: Number(item.id),
                    identifier: "CRQ" + i,
                    value: item.value,
                  });
              }
            });
          }

          setDataList(data_List);

          //...........creating new question array ,because this is a data update so incoming response id should be include........//
          let newQuestionArray = [];
          let yesArray = [];

          //..........check if this a update or new form data entry.............//
          if (data_List.length > 0) {
            forEach(questions, function (question) {
              let ifTrue = filter(data_List, (item) => {
                let splitedquestinNo = split(item.identifier, "Q");

                // console.log("question no.............", splitedquestinNo[1]);
                return question.questionNo == Number(splitedquestinNo[1]);
              });

              if (ifTrue.length > 0) {
                newQuestionArray.push({
                  id: ifTrue[0].id,
                  questionNo: question.questionNo,
                  question: question.question,
                  yes: ifTrue[0].value === "true" ? true : false,
                  no: ifTrue[0].value === "true" ? false : true,
                });
              } else {
                newQuestionArray.push({
                  id: "",
                  questionNo: question.questionNo,
                  question: question.question,
                  yes: false,
                  no: false,
                });
              }
            });
          }

          forEach(newQuestionArray, function (item) {
            if (item.yes === true) {
              yesArray.push(item.questionNo);
            }
          });

          setNewQArray(newQuestionArray);
          setYesIds(yesArray);
        }
        //........basic data getting from server to map (other fileds than radio buttons).....//
      })
      .catch((err) => {
        //   console.log(err);
        // toastCommon(true, "error");
      });
  };

  const onSubmit = (data) => {
    let basicDetails = [];
    forEach(data, function (value, key) {
      //.....check if incoming form data doesnot have value....this happens if user didnt fill the data previously.....//
      if (value === "") {
      } else {
        if (dataList.length > 0) {
          for (let i = 6; i < 8; i++) {
            let valueTemp = data["CRQ" + i];
            let idTemp = data["CRQ" + i + "ID"];

            basicDetails.push({
              id: Number(idTemp),
              identifier: "CRQ" + i,
              value: valueTemp,
              detailCategory: 2,
            });
          }
        } else {
          basicDetails.push({
            identifier: key,
            value: value,
            detailCategory: 2,
          });
        }
      }
    });

    //radio button data adding to the api
    let AdvanceDetailsArray = [];

    if (dataList.length > 0) {
      forEach(newQArray, function (item) {
        //......check if question no of hardcode(question state) question matches with newquestion array....//
        let ifTrue = filter(yesIds, (id) => {
          return item.questionNo == id;
        });

        ///........if incoming radio button is checked before turn value to true so that check button default value can set when page is loading......///
        if (ifTrue.length !== 0) {
          AdvanceDetailsArray.push({
            id: item.id,
            identifier: "CRQ" + item.questionNo,
            value: true,
            detailCategory: 2,
          });
        } else {
          ///........if incoming radio button is *not checked before turn value to false so that check button default value can set as *false when page is loading......///

          AdvanceDetailsArray.push({
            id: item.id,
            identifier: "CRQ" + item.questionNo,
            value: false,
            detailCategory: 2,
          });
        }
      });
    } else {
      //.....if this is new form filling no need to add id ,therefore submith form data with out id....//
      forEach(newQArray, function (item) {
        let ifTrue = filter(yesIds, (id) => {
          return item.questionNo == id;
        });

        if (ifTrue.length !== 0) {
          AdvanceDetailsArray.push({
            identifier: "CRQ" + item.questionNo,
            value: true,
            detailCategory: 2,
          });
        } else {
          AdvanceDetailsArray.push({
            identifier: "CRQ" + item.questionNo,
            value: false,
            detailCategory: 2,
          });
        }
      });
    }
    //...add basic details array and radio button array to send the form data through api....///
    let combinedArray = AdvanceDetailsArray.concat(uniqBy(basicDetails, "id"));

    axios
      .post(
        MexxarApi + "/" + candidateID + "/advance-profile-details",
        combinedArray
      )
      .then((res) => {
        if (res.data.status === "success") {
          getData();
          // setShowAddRow(!showAddRow);
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err);
        ToastCommon(true, "error", addToast);
      });
  };
  const handleEdit = () => {
    if (editable) {
      //edit call
    }
    setEditable(!editable);
  };

  const addIds = (e) => {
    setYesIds([...yesIds, Number(e.target.value)]);
  };

  const deleteIds = (e) => {
    let deleteN = filter(yesIds, (item) => {
      return item !== Number(e.target.value);
    });
    setYesIds(deleteN);
  };

  useDidMountEffect(() => {
    getData();
  }, [candidateID]);

  useEffect(() => {
    getCandidateID();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <div className="col-md-12 d-flex justify-content-between align-items-center">
              <h5>Criminal Records Check </h5>
              {/* <FormGroup row>
                <button
                  className={`btn btn-wave btn-raised ${
                    editable ? "bg-warning " : "bg-danger "
                  }`}
                  type="button"
                  onClick={() => handleEdit()}
                >
                  {editable ? "Save" : "Edit"}
                  {editable ? (
                    <FontAwesomeIcon icon={faCheck} className="ml-3" />
                  ) : (
                    <FontAwesomeIcon icon={faEdit} className="ml-3" />
                  )}
                </button>
              </FormGroup> */}
            </div>
          </div>

          <div className="card-body">
            <Alert color="info">
              Applicants for Healthcare positions are exempt from the
              Rehabilitation of Offenders Act 1974. You are required to declare
              prosecutions or convictions, including those considered ‘spent’
              under this Act.
            </Alert>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {newQArray.map((question, id) => {
                return (
                  <FormGroup row key={id}>
                    <Col sm={8} md={8}>
                      <Label>
                        {" "}
                        {question.questionNo}. {question.question}
                      </Label>
                    </Col>
                    <Col sm={2} md={2}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            name={"radio" + question.questionNo}
                            value={question.questionNo}
                            onChange={addIds}
                            defaultChecked={question.yes}
                          />
                          Yes
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm={2} md={2}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            name={"radio" + question.questionNo}
                            value={question.questionNo}
                            onChange={deleteIds}
                            defaultChecked={question.no}
                          />
                          No
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                );
              })}

              <Row>
                <Col md={4}>
                  <Label for="dbsCheckCompany">
                    Company that conducted the check
                  </Label>

                  <Input
                    type="text"
                    name="CRQ6"
                    defaultValue={basicDetails.CRQ6}
                    innerRef={register}
                  />

                  <Input
                    type="hidden"
                    name="CRQ6ID"
                    defaultValue={basicDetails.CRQ6ID}
                    innerRef={register}
                  />
                </Col>
                <Col md={8}>
                  <Label for="dbsCheckCompany">
                    If you have signed up for the DBS Update Service, please
                    provide the details of your DBS number:
                  </Label>
                  <Input
                    type="text"
                    name="CRQ7"
                    defaultValue={basicDetails.CRQ7}
                    innerRef={register}
                  />
                  <Input
                    type="hidden"
                    name="CRQ7ID"
                    defaultValue={basicDetails.CRQ7ID}
                    innerRef={register}
                  />
                </Col>
              </Row>
              <Alert className="text-justify">
                {CompanyName} will undertake an Enhanced DBS check on your
                behalf. You cannot be placed in any setting without a current
                DBS check. It is a legal requirement for you to provide the
                original documentation for your DBS application to be processed.
                Photocopied documents will not be accepted for your DBS
                application. Original documents will be returned as soon as your
                application has been processed. If at any stage of your
                employment with {CompanyName} we receive information not
                declared in your initial application you will be removed from
                your position.
              </Alert>
              <div className="d-flex justify-content-between mt-2">
                <div></div>
                <div>
                  <Button className="mr-2" color="success" type="submit">
                    Save
                  </Button>
                  {/* <Button color="success" onClick={() => {}}>
                    Next
                  </Button> */}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CriminalRecordsCheck;
