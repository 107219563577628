import React, { useState } from "react";
import axios from "axios";
import Data from "./quickDraft.json";
import { Button, Badge } from "reactstrap";
import "./quick-Draft.style.css";
import Delete from "../../components/Modal/ModalBody/delete";
import ModalComponent from "../../components/Modal/modalComponent";

const QuickDraftWidget = (props) => {
  const { userProfile } = props;
  axios.get("bookings.json").then((res) => {
    this.setState({
      booking: res.data,
    });
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toggleShowDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const toggleD = () => setShowDeleteModal(!showDeleteModal);

  return (
    <div className="">
      {Data.map((shift, i) => {
        return (
          <div className="card mb-2 " key={i}>
            <div className="card-body">
              <div className="flex flex-row d-flex justify-content-between ">
                <div className="d-flex  " style={{ width: "250px" }}>
                  <div className="d-flex align-items-center mr-3 ">
                    <span className="mx-2">
                      <b className="badge badge-circle sm text-primary"></b>
                    </span>
                  </div>
                  <div>
                    <div>{shift.hospital}</div>

                    <div className="item-mail text-muted h-1x d-none d-sm-block">
                      <time
                        dateTime="2020-12-20T00:00:00Z"
                        data-local="time"
                        data-format="%B %e, %Y %l:%M%P"
                        title="December 20, 2020 at 5:30am IST"
                        data-localized=""
                        aria-label="December 20, 2020 5:30am"
                      >
                        <small>{shift.sheduleTime} </small>
                      </time>
                    </div>
                  </div>
                </div>
                <div style={{ width: "60px" }}>
                  <Badge color="warning">{shift.repeat}</Badge>
                </div>
                <div className="textCenter-Custom">
                  <div>No of Candidates</div>
                  <div className="text-muted"> {shift.NoOfCandidates}</div>
                </div>
                <div className="textCenter-Custom">
                  <div>Shift Type</div>
                  <div className="text-muted">{shift.type}</div>
                </div>
                <div className="textCenter-Custom">
                  <div>Ward</div>
                  <div className="text-muted">{shift.ward}</div>
                </div>
                <div>
                  <Button color="success" className="mr-2">
                    Show
                  </Button>
                  <Button
                    color="danger"
                    onClick={() => toggleShowDeleteModal()}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      })}

      <ModalComponent
        show={showDeleteModal}
        header="E mail and sms"
        closeModal={toggleD}
      >
        <Delete></Delete>
        <div className="d-flex justify-content-end">
          <Button className="mr-2" color="success" onClick={toggleD}>
            Yes
          </Button>
          <Button color="secondary" onClick={toggleD}>
            No
          </Button>
        </div>
      </ModalComponent>
    </div>
  );
};

export default QuickDraftWidget;
