// import React, { Component } from "react";
// import { withRouter } from "react-router-dom";
// import decode from "jwt-decode";

// class TokenValidityCheck extends Component {
//   constructor(props) {
//     super(props);
//     props.history.listen(() => {
//       const jwtToken = localStorage.getItem("jwtToken");

//       if (jwtToken) {
//         const decodedJwt = decode(jwtToken);
//         if (decodedJwt.exp * 1000 < Date.now()) {
//           props.logOut();
//         }
//       }
//     });
//   }
//   render() {
//     return <div></div>;
//   }
// }
// export default withRouter(TokenValidityCheck);
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import decode from "jwt-decode";
import { connect } from "react-redux";

class TokenValidityCheck extends Component {
  constructor(props) {
    super(props);
    // const { authToken,loggedInUser } = this.props;
    props.history.listen(() => {
      const authToken = localStorage.getItem("jwtToken");

      if (authToken) {
        const decodedJwt = decode(authToken);
        if (decodedJwt.exp * 1000 < Date.now()) {
          props.logOut();
        }
      }
    });
  }
  render() {
    return <div></div>;
  }
}

function mapStateToProps(state) {
  return {
    userId: state.auth.item.id,
    theme: state.theme.theme,
    reduxChatScriptId: state.ChatRedux.chatfunkyId,
    // authToken: state?.authReducer1?.authToken,
    // loggedInUser:state?.authReducer1?.loggedInUser
  };
}


const ConnectedApp = connect(mapStateToProps)(TokenValidityCheck);
export default withRouter(ConnectedApp);
// export default withRouter(TokenValidityCheck);