import { CHANGE_THEME } from "./types";

export const changeTheme = (data) => (dispatch) => {
  dispatch({
    type: CHANGE_THEME,
    payload: data,
  });
};
  




