import React, { useEffect, useState } from "react";
import General from "./advanceDetails/general";
import Referral from "./advanceDetails/referral";
import Trainings from "./advanceDetails/trainings";
import NextOfKeen from "./advanceDetails/nextOfKeen";
import References from "./advanceDetails/references";
import PaymentDetails from "./advanceDetails/paymentDetails";
import ReferenceForm from "../../../referenceForm/referenceForm";
import SkillsExperience from "./advanceDetails/skillsExperience";
import SubmitApplication from "./advanceDetails/submitApplication";
import EmploymentHistory from "./advanceDetails/employmentHistory";
import EligibilityToWork from "./advanceDetails/eligibilityToWork";
import ImmunisationHistory from "./advanceDetails/immunisationHistory";
import MedicalQuestionnaire from "./advanceDetails/medicalQuestionnaire";
import CriminalRecordsCheck from "./advanceDetails/criminalRecordsCheck";
import RegistrationGoverningBodies from "./advanceDetails/registrationGoverningBodies";
import IndemnityInsurenceProviderDetails from "./advanceDetails/indemnityInsurenceProviderDetails";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Profile from "../profile";

const AdvanceDetails = (props) => {
  const { candidateId } = props;
  const [currentTab, setCurrentTab] = useState("1");
  const history = useHistory();
  const tab = window.location.hash.substring(1);
   useEffect(() => {
    if (tab === "") {
      setCurrentTab("1")
    } else {
      setCurrentTab(tab)
    }
  }, [tab])

  const switchTabHandler1 = (tab) => {
    setCurrentTab(tab);
    //  history.push(`#${tab}`);
  };

  return (
    <div>
      <TabNavigationMenu
        activated={currentTab}
        onChangeItem={switchTabHandler1}
        itemList={[
          { name: "General", key: "1" },
          { name: "Next Of Kin", key: "2" },
          { name: "Criminal Records Check", key: "3" },
          { name: "Skills & Experience", key: "4" },
          { name: "Employment History", key: "5" },
          { name: "References", key: "6" },
          { name: "Complete Registration", key: "7" },
        ]}
      />
   
      {currentTab === "1" && <General />}
      {currentTab === "2" && <NextOfKeen />}
      {currentTab === "3" && <CriminalRecordsCheck />}
      {currentTab === "4" && <SkillsExperience />}
      {currentTab === "5" && <EmploymentHistory />}
      {currentTab === "6" && <References candidateId={candidateId} />}
      {currentTab === "7" && <SubmitApplication candidateId={candidateId} />}
    </div>
  );
};



export const TabNavigationMenu = (props) => {
  const { activated, onChangeItem, itemList } = props;

  return (
    <div className="card">
      <div className="card-header">
        <strong>Advanced Details</strong>
      </div>
      <div className="card-body">
        <form id="form" noValidate="">
          <div id="rootwizard">
            <ul className="nav mb-3 flex">
              {itemList.map((item) => (
                <li className="nav-item" key={item.key}>
                  <a
                    className={`nav-link text-center ${
                      item.key === activated ? "active" : ""
                    }`}
                    href={`#${item.key}`}
                    onClick={(e) => {
                      e.preventDefault();
                      onChangeItem(item.key);
                    }}
                  >
                    <span className="w-32 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-success">
                      {item.key}
                    </span>
                    <div className="mt-2">
                      <div className="text-muted">{item.name}</div>
                    </div>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </form>
      </div>
    </div>

  );
};

export default AdvanceDetails;