// import React, { Component } from "react";
// import "./splash-screen.style.css";
// import PrivacyStatements from "../../widgets/privacy-statement/privacy-statement";
// // import splashScreenlogo from "../../images/candiHealthCare.jpg";
// // import splashScreenlogo from "../../images/ramsyLogo.png";
// // import splashScreenlogo from "../../images/EmergencyPersonnel.png";
// import splashScreenlogo from "./mexxarLogo.png";


// import { CompanyName } from "../../configs/api-config";

// function LoadingMessage() {
//   return (
//     <div className="green-back">
//       <div className="center-div">
//         <img className="logo-animate" alt="LOGO" src={splashScreenlogo} />
//       </div>
//       <div className="splash-privacy text-color">
//         <p>Copyright {new Date().getFullYear()} @ Mexxar</p>
//         <p>Powered by Mexxar</p>
//       </div>
//     </div>
//   );
// }

// function withSplashScreen(WrappedComponent) {
//   return class extends Component {
//     constructor(props) {
//       super(props);
//       this.state = {
//         loading: true,
//       };
//     }

//     async componentDidMount() {
//       try {
//         setTimeout(() => {
//           this.setState({
//             loading: false,
//           });
//         }, 5000);
//       } catch (err) {
//         this.setState({
//           loading: false,
//         });
//       }
//     }

//     render() {
//       // while checking user session, show "loading" message
//       if (this.state.loading) return LoadingMessage();

//       // otherwise, show the desired route
//       return <WrappedComponent {...this.props} />;
//     }
//   };
// }

// export default withSplashScreen;


import React, { Component } from "react";
import "./splash-screen.style.css";
import PrivacyStatements from "../../widgets/privacy-statement/privacy-statement";
// import splashScreenlogo from "../../images/candiHealthCare.jpg";
import { Provider } from 'react-redux';
import store from '../../redux/store.js';
import splashScreenlogo from "../../images/candiHealthCare.jpg";
// import splashScreenlogo from "../../images/ramsyLogo.png";
// import splashScreenlogo from "../../images/EmergencyPersonnel.png";
// import splashScreenlogo from "./mexxarLogo.png";


import { CompanyName } from "../../configs/api-config";

function LoadingMessage() {
  return (
    <div className="green-back">
      <div className="center-div">
        <img className="logo-animate" alt="LOGO" src={splashScreenlogo} />
      </div>
      <div className="splash-privacy text-color">
        <p>Copyright {new Date().getFullYear()} @ Mexxar</p>
        <p>Powered by Mexxar</p>
      </div>
    </div>
  );
}

function withSplashScreen(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
      };
    }

    async componentDidMount() {
      try {
        setTimeout(() => {
          this.setState({
            loading: false,
          });
        }, 5000);
      } catch (err) {
        this.setState({
          loading: false,
        });
      }
    }

    render() {
      // while checking user session, show "loading" message
      if (this.state.loading) return LoadingMessage();

      // otherwise, show the desired route
      return <Provider store={store}> <WrappedComponent {...this.props} /></Provider>;
    }
  };
}

export default withSplashScreen;