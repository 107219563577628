import axios from "axios";
import React, { useState, useEffect } from "react";
import { Badge } from "reactstrap";
import { HOSTMexxar } from "../../configs/api-config";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import moment from "moment";
import loadingAnimation from "../../../src/images/puff.svg";
const Tickets = () => {
  const MexxarApi = HOSTMexxar;
  const [rows, setRows] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllRows = () => {
    setLoading(true);
    axios
      .get(MexxarApi + "tickets")
      .then((res) => {
        setLoading(false);
        setRows(res.data.body);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  useEffect(() => {
    getAllRows();
  }, []);

  return (
    <>
      <DashboardLayout title="Tickets" subTitle="Issue Tracker">
        {rows === null && loading && (
          <div className="d-flex justify-content-center align-items-center">
            {/* Loading animation */}
            <div>
              <img src={loadingAnimation} style={{ height: 100 }}></img>
            </div>
          </div>
        )}

        {rows == null ? null : (
          <div>
            {rows.map((body) => (
              <div className="card">
                <div className="list list-row">
                  <div className="list-item" data-id={body.id}>
                    <div className="flex">
                      <div className="item-feed h-2x">
                        <div className="capitalize">{body.title}</div>

                        <div className="text-muted capitalize">{body.body}</div>
                      </div>
                    </div>
                    <div className="no-wrap">
                      <div className="item-date text-muted text-sm d-none d-md-block">
                        {moment(body.createdDate).format("llll")}
                      </div>
                    </div>
                    <div className="no-wrap">
                      <Badge color="warning " className="btn-sm">
                        {body.status}
                      </Badge>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </DashboardLayout>
    </>
  );
};

export default Tickets;
