// callSummaryReducer.js
const SET_REASON = 'SET_REASON';
const CLEAR_REASON = 'CLEAR_REASON';
const SET_CANDIDATE_ID = 'SET_CANDIDATE_ID';

export const setReason = (reason) => (dispatch) => {
  dispatch({
    type: SET_REASON,
    payload: reason,
  });

  // Save to localStorage
  localStorage.setItem('callSummary', JSON.stringify({ reason }));
};

export const setCandidateId1 = (candidateId) => (dispatch) => {
  dispatch({
    type: SET_CANDIDATE_ID,
    payload: candidateId,
  });

  
};

export const clearReason = () => (dispatch) => {
  dispatch({
    type: CLEAR_REASON,
  });

  // Clear from localStorage
  localStorage.removeItem('callSummary');
};

const storedState = JSON.parse(localStorage.getItem('callSummary'));
const defaultState = { reason: '', isImportant: false, candidateId: '' };
// const initialState = storedState ? { ...defaultState, ...storedState } : defaultState ;
const initialState = {
  candidateId: '',
  reason:''
}


const callSummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    
    case SET_REASON:
      console.log("Reason",action.payload)
      return {
        ...state,
        reason: action.payload,
      };
    case SET_CANDIDATE_ID:
      console.log("candidate ID",action.payload)
      return {
        ...state,
        candidateId: action.payload,
      };
    case CLEAR_REASON:
      return {
        ...state,
        reason: '',
      };
    default:
      return state;
  }
};

export default callSummaryReducer;
