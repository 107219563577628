import React, { useState, useEffect } from "react";
import {
  Label,
  Form,
  FormGroup,
  Col,
  Button,
  Spinner,
  Input,
  Alert,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import store from "../../redux/store";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { forEach, findIndex } from "lodash";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../components/toastCommon";
import useCandidateList from "../../customHooks/useCandidateList";
import { REGISTER_CANDIDATE, HOSTMexxar, ClientLable } from "../../configs/api-config";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { clientsGetAction } from "../../redux/actions/initialDataGetAction";
import Store from "../../redux/store";
import { saveFiledDataAction } from "../../redux/actions/saveFiledDataAction";

function TimesheetData(props) {
  const {
    processTimeSheet,
    processLoading,
    selectedCandidates,
    incommingAIdata,
    systemTimeSheetData,
    timeSheetFalse,
    reduxClients,
    retry,
    selectedFile,
    setPayrollData,
    setShowSummary,
    setPayrollError,
  } = props;
  const MexxarCandidateApi = REGISTER_CANDIDATE;
  const { register, handleSubmit, errors, clearErrors, reset } = useForm();

  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [candidates, setCandidates] = useState([]);

  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  let url = MexxarCandidateApi + "/status/" + filterCustom.value;

  const { loading, error, rows, hasMore } = useCandidateList(
    url,
    filterCustom,
    query,
    pageNumber
  );

  const [departmentError, setDepartmentError] = useState(false);
  const [selectedSpeciality, setSelectedSpeciality] = useState(0);
  const [specialities, setSpecialities] = useState([{ id: 0, name: "Select" }]);

  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [departments, setDepartments] = useState([{ id: 0, name: "Select" }]);

  const [refNo, setRefNo] = useState(null);
  const [fieldData, setFieldData] = useState(null);

  const [selectedClient, setSelectedClient] = useState();
  const [clients, setClients] = useState([{ id: 0, name: "Select" }]);

  const [clientCantFind, setClientCantFind] = useState(false);
  const [departmentCantFind, setDepartmentCantFind] = useState(false);
  const [specialityCantFind, setSpecialityCantFind] = useState(false);

  const [pdfError, setAiError] = useState(false);
  const [aiPending, setAiPending] = useState(false);

  const { addToast } = useToasts();

  const handleInputChange = (e) => {
    setQuery(e);
  };

  //----------prepare candidates to popultate in dorp down select---------//
  const prepareCandidates = () => {
    let listOfCandidates = [];
    forEach(rows, function (row) {
      listOfCandidates.push({
        value: row.id,
        label: `${row.firstName} ${row.lastName}`,
        id: row.id,
        candidate: row,
      });
    });

    if (query != "") {
      setCandidates(listOfCandidates);
    } else {
      setCandidates([]);
    }
  };

  const getClientDepartments = (data) => {
    if (data) {
      axios
        .get(HOSTMexxar + "client-wards/clients/" + data)
        .then((res) => {
          let tempDep = [];
          forEach(res.data.body, function (department) {
            tempDep.push({
              id: department.wardId,
              name: department.wardName,
            });
          });
          setDepartments(tempDep);
        })
        .catch((err) => {
          setDepartmentError(err);
          // console.log("err", err);
          // toastCommon(true, "error");
        });
    }
  };

  //--------------[process flow no--00]--------------//

  const prepareFiledData = () => {
    let tempFields;

    //when ref no is not matching with excisting db bookings data

    if (timeSheetFalse) {
      if (incommingAIdata) {
        tempFields = {
          referenceNo: incommingAIdata.Ref,
          date: incommingAIdata.Date,
          candidate: {
            id: incommingAIdata.CandidateId,
            value: incommingAIdata.CandidateName,
            lable: incommingAIdata.CandidateName,
          },
          candidateId: incommingAIdata.CandidateId,
          startDate: moment(incommingAIdata.Date).format("YYYY-MM-DD"),
          startTime: moment(incommingAIdata.Start, "HH:mm").format("HH:mm"),
          endDate: moment(incommingAIdata.Date).format("YYYY-MM-DD"),
          endTime: moment(incommingAIdata.End, "HH:mm").format("HH:mm"),
          client: {
            // id: incommingAIdata.clientId,
            name: incommingAIdata.HospitalName,
          },
          department: {
            // id: incommingAIdata.wardId,
            name: incommingAIdata.Ward,
          },
          speciality: incommingAIdata.Speciality,
          upLift: 0,
          bonus: 0,
          minsTakenForBreaks: incommingAIdata.Breakmin,
          totalHours: incommingAIdata.Totalhours,
        };
      }
    } else {
      //when refno. is matching with excisting system bookings

      if (systemTimeSheetData) {
        tempFields = {
          referenceNo: systemTimeSheetData.referenceNo,
          date: systemTimeSheetData.bookingDate,
          candidate: {
            id: systemTimeSheetData.candidatesId,
            value: systemTimeSheetData.candidateName,
            lable: systemTimeSheetData.candidateName,
          },
          candidateId: systemTimeSheetData.candidatesId,
          startDate: moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD"),
          startTime: moment(systemTimeSheetData.shiftFrom).format("HH:mm"),
          endDate: moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD"),
          endTime: moment(systemTimeSheetData.shiftTo).format("HH:mm"),
          client: {
            id: systemTimeSheetData.clientId,
            name: systemTimeSheetData.clientName,
          },
          department: {
            id: systemTimeSheetData.ward.id,
            name: systemTimeSheetData.ward.name,
          },
          speciality: systemTimeSheetData.speciality,
          upLift: systemTimeSheetData.upLift,
          bonus: systemTimeSheetData.bonus,
        };
      }
    }

    Store.dispatch(saveFiledDataAction(tempFields));
    setFieldData(tempFields);
  };

  useEffect(() => {
    prepareCandidates();
    return () => {
      Store.dispatch(saveFiledDataAction(null));
    };
  }, [rows, timeSheetFalse]);

  useDidMountEffect(() => {
    store.dispatch(clientsGetAction());
  }, [systemTimeSheetData]);

  useDidMountEffect(() => {
    prepareFiledData();
  }, [timeSheetFalse, systemTimeSheetData]);

  useDidMountEffect(() => {
    {
      clients &&
        selectedClient &&
        clients[selectedClient] != undefined &&
        getClientDepartments(clients[selectedClient].id);
    }
  }, [selectedClient]);

  useDidMountEffect(() => {
    if (fieldData && clients) {
      let index = findIndex(clients, (item) => {
        return item.name == fieldData.client.name;
      });

      if (index === -1) {
        setClientCantFind(true);
      } else {
        setClientCantFind(false);
        setSelectedClient(index);
      }
    }
  }, [clients]);

  useDidMountEffect(() => {
    if (fieldData && departments) {
      let index = findIndex(departments, (item) => {
        return item.name == fieldData.department.name;
      });

      if (index === -1) {
        setDepartmentCantFind(true);
      } else {
        setDepartmentCantFind(false);
        setSelectedDepartment(index);
      }
    }
  }, [departments]);

  useDidMountEffect(() => {
    if (fieldData && specialities) {
      let index = findIndex(specialities, (item) => {
        return item.name == fieldData.speciality.name;
      });

      if (index === -1) {
        setSpecialityCantFind(true);
      } else {
        setSpecialityCantFind(false);
        setSelectedSpeciality(index);
      }
    }
  }, [specialities]);

  useDidMountEffect(() => {
    if (candidates.length > 0) {
      setClients(candidates[0].candidate.preferredClients);
      setSpecialities(candidates[0].candidate.candidateSpecialities);
      getClientDepartments(candidates[0].candidate.preferredClients[0].id);
    } else {
      setClients([{ id: 0, name: "Select" }]);
      setSpecialities([{ id: 0, name: "Select" }]);
    }
  }, [candidates]);

  const handleCandidateChange = (data) => {
    selectedCandidates(data);
    setClients(data.candidate.preferredClients);
    setSpecialities(data.candidate.candidateSpecialities);
    getClientDepartments(data.candidate.preferredClients[0].id);
  };

  //*--------------prepare end date ------------------*//
  //*--------------if the end time is after 12 midnight add one date to the start date ------------------*//

  function prepEndDate(data) {
    let sTime = data.startTime.split(":")[0];
    let eTime = data.endTime.split(":")[0];

    let endDate;

    if (sTime < eTime) {
      endDate = moment(data.startDate).format("DD/MM/YYYY");
    } else {
      endDate = moment(data.startDate).add(1, "days").format("DD/MM/YYYY");
    }
    return endDate;
  }

  //*--------------verify document-------------------*//
  //--------------[process flow no--01]--------------//
  const onSubmit = (data) => {
    let timesheet = {
      locumName: candidates[0].label,
      locumId: candidates[0].id,
      speciality: specialities[data.specialityIndex].id,
      band: candidates[0].candidate.candidateBand.id,
      hospitalName: clients[data.clientIndex].name,
      dateOfShift: data.startDate,
      ward: departments[data.departmentIndex].id,
      bookingRef: data.refNo,
      startTime: data.startTime,
      endTime: data.endTime,
      minsTakenForBreaks: data.Breakmin,
      totalHours: data.totalHours,
      locumDate: data.startDate,
      authoriseName: "",
      authorisePosition: "",
      authoriseDate: data.startDate,
      bookingId: systemTimeSheetData.id,
      hospitalReviewStatus: 0,
      paymentStatus: 0,
      reviewStatus: 4,
    };

    let form = new FormData();
    form.append("file", selectedFile);

    axios.post(HOSTMexxar + "timesheets/", timesheet).then((res) => {
      if (res.data.status == "success" && res.data.message == "created") {
        // ToastCommon(true, "success", addToast);
        updateBookingIfNotMatching(data, res, form);
      } else if (
        res.data.status == "success" &&
        res.data.message == "updated"
      ) {
        ToastCommon(true, "update", addToast);
        updateBookingIfNotMatching(data, res, form);

        // uploadTimesheet(data, res, form);
      } else if (res.data.status == "failed") {
        ToastCommon(true, "error", addToast);
      }
    });
  };

  //*--------------Update booking details in the system if even one data is miss matching with verifying data------------------*//
  //--------------[process flow no--02]--------------//

  const updateBookingIfNotMatching = (data, response, form) => {
    let shiftSTime = moment(data.startDate).add(data.startTime).toDate();
    let shiftETime = moment(data.startDate).add(data.endTime).toDate();

    let arrangData = {
      id: systemTimeSheetData.id,
      bookingDate: moment(data.startDate).format("YYYY-MM-DD"),
      referenceNo: data.refNo,
      clientsId: Number(clients[data.clientIndex].id),
      //  employeeId: auth.item.id,
      // specialityId: Number(candidateSpecialities[0].id),
      specialityId: Number(specialities[data.specialityIndex].id),
      wardId: Number(departments[data.departmentIndex].id),
      shiftTo: shiftETime,
      shiftFrom: shiftSTime,
    };

    // if any field is not matching with system booking details, update it

    if (
      systemTimeSheetData.referenceNo != data.refNo ||
      systemTimeSheetData.candidatesId != candidates[0].id ||
      moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
        moment(data.startDate).format("YYYY-MM-DD") ||
      moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
        moment(data.startTime).format("HH:mm") ||
      moment(systemTimeSheetData.shiftTo).format("YYYY-MM-DD") !=
        moment(data.endTime).format("YYYY-MM-DD") ||
      moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
        moment(prepEndDate(data)).format("HH:mm") ||
      systemTimeSheetData.clientId != clients[data.clientIndex].id ||
      systemTimeSheetData.ward.id != departments[data.departmentIndex].id ||
      systemTimeSheetData.speciality.id != specialities[data.specialityIndex].id
    ) {
      axios
        .put(HOSTMexxar + "bookings", arrangData)
        .then((res) => {
          if (res.data.status == "success") {
            ToastCommon(true, "success", addToast);
            uploadTimesheet(data, response, form);
          } else if (res.data.status == "failed") {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {});
    } else {
      uploadTimesheet(data, response, form);
    }
  };

  //*--------------upload timesheet------------------*//

  const uploadTimesheet = (data, res, form) => {
    axios
      .put(
        HOSTMexxar +
          "timesheets/" +
          res.data.body[0].id +
          "/upload-timesheetDocument",
        form
      )
      .then((res) => {
        if (res.data.status == "success" && res.data.message == "fetched") {
          ToastCommon(true, "timesheetUploaded", addToast);
          processTimesheetData(data);
        } else if (res.data.status == "failed") {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  //*--------------process timesheet for payroll calculations------------------*//
  //--------------[process flow no--04]--------------//

  const processTimesheetData = (item) => {
    setAiPending(true);

    let tempData = [
      {
        bookingReference: item.refNo,
        startTime: item.startTime + ":00",
        endTime: item.endTime + ":00",
        startDate: moment(item.startDate).format("DD/MM/YYYY"),
        endDate: prepEndDate(item),
      },
    ];

    axios
      .post(HOSTMexxar + "workspace/payroll", tempData)
      .then((res) => {
        setAiPending(false);
        if (res.data.status == "success") {
          ToastCommon(true, "timesheetProcessed", addToast);
          setPayrollData(res.data.body);
          setShowSummary(true);
        } else if (res.data.status == "failed") {
          setPayrollError(res.data.status);
          ToastCommon(true, "timesheetProcessedError", addToast);
        }
      })
      .catch((err) => {
        setAiError(true);
      });
  };

  useDidMountEffect(() => {
    if (fieldData) {
      setQuery(fieldData.candidate.value);
    }
  }, [fieldData]);

  useDidMountEffect(() => {
    setFieldData(null);
    setQuery("");
    setSelectedDepartment(0);
    setDepartments([{ id: 0, name: "Select" }]);
    reset();
  }, [selectedFile]);

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#121212",
    }),
  };

  return (
    <div className="card" style={{ width: "100%" }}>
      <div className="card-body ">
        <Form onSubmit={handleSubmit(onSubmit)} className="col-12">
          <FormGroup
            row
            className="d-flex justify-content-between align-items-center"
          >
            <Label className="ml-3">Process Timesheet </Label>
            <Button
              className="m-2"
              color={`${timeSheetFalse ? "danger" : "success"}`}
              onClick={() => {
                processTimeSheet();
              }}
            >
              {processLoading && (
                <Spinner
                  animation="border"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "5px",
                  }}
                />
              )}
              Process
            </Button>
          </FormGroup>

          <hr />

          <FormGroup row>
            <Col md={`${timeSheetFalse ? 10 : 12}`}>
              <Label>Ref No</Label>
              <Input
                type="text"
                defaultValue={fieldData && fieldData.referenceNo}
                placeholder="Ref No"
                id="refNo"
                name="refNo"
                onChange={(e) => setRefNo(e.target.value)}
                invalid={
                  timeSheetFalse ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.referenceNo != incommingAIdata.Ref)
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
            {timeSheetFalse && (
              <Col md={2} className="d-flex align-items-end ">
                <Button color="success" onClick={() => retry(refNo)}>
                  Retry
                </Button>
              </Col>
            )}
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <Label>Candidate</Label>
              <Select
                id="candidateId"
                name="candidateId"
                className="basic-multi-select"
                classNamePrefix="select"
                value={candidates[0]}
                onChange={(data) => handleCandidateChange(data)}
                isLoading={loading}
                options={candidates}
                placeholder="Search candidates ..."
                onInputChange={handleInputChange}
                noOptionsMessage={() => "No results found"}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
                styles={colourStyles}
              />
            </Col>
            <Col md={6}>
              <Label>Candidate Id</Label>
              <Input
                type="text"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData.candidatesId !=
                    incommingAIdata.CandidateId
                }
                defaultValue={fieldData && fieldData.candidateId}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                systemTimeSheetData.candidatesId !=
                  incommingAIdata.CandidateId && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>

                      {incommingAIdata.CandidateId}
                    </li>
                  </ul>
                )}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <Label>Start Date</Label>
              <Input
                type="Date"
                defaultValue={fieldData && fieldData.startDate}
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
                    moment(incommingAIdata.Date).format("YYYY-MM-DD")
                }
                placeholder="Start Time"
                id="startDate"
                name="startDate"
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
                  moment(incommingAIdata.Date).format("YYYY-MM-DD") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>

                      {moment(incommingAIdata.Date).format("L")}
                    </li>
                  </ul>
                )}
            </Col>
            <Col md={6}>
              <Label>Start Time</Label>
              <Input
                type="time"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
                    moment(incommingAIdata.Start, "HH:mm").format("HH:mm")
                }
                defaultValue={fieldData && fieldData.startTime}
                id="startTime"
                name="startTime"
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
                  moment(incommingAIdata.Start, "HH:mm").format("HH:mm") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>
                      {moment(incommingAIdata.Start, "HH:mm").format("LT")}
                    </li>
                  </ul>
                )}
            </Col>{" "}
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <Label>End Date</Label>
              <Input
                type="Date"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftTo).format("YYYY-MM-DD") !=
                    moment(incommingAIdata.Date).format("YYYY-MM-DD")
                }
                defaultValue={fieldData && fieldData.endDate}
                id="endDate"
                name="endDate"
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftTo).format("YYYY-MM-DD") !=
                  moment(incommingAIdata.Date).format("YYYY-MM-DD") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>
                      {moment(incommingAIdata.Date).format("L")}
                    </li>
                  </ul>
                )}
            </Col>
            <Col md={6}>
              <Label>End Time</Label>
              <Input
                type="time"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
                    moment(incommingAIdata.End, "HH:mm").format("HH:mm")
                }
                placeholder="End Time"
                id="endTime"
                name="endTime"
                defaultValue={fieldData && fieldData.endTime}
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
                  moment(incommingAIdata.End, "HH:mm").format("HH:mm") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>

                      {moment(incommingAIdata.End, "HH:mm").format("LT")}
                    </li>
                  </ul>
                )}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <Label>{ClientLable}</Label>
              <Input
                type="select"
                id="clientIndex"
                name="clientIndex"
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
                // invalid={errors.clientId}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
                invalid={
                  clientCantFind ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.clientName !=
                      incommingAIdata.HospitalName)
                }
              >
                {clients.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      style={{ color: "#6A6767" }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Input>

              {clientCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData.clientName !=
                    incommingAIdata.HospitalName && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.HospitalName}
                      </li>
                    </ul>
                  ))}
            </Col>
            <Col md={6}>
              <Label>Department</Label>
              <Input
                type="select"
                id="departmentIndex"
                name="departmentIndex"
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
                invalid={
                  departmentCantFind ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.ward.name != incommingAIdata.Ward)
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              >
                {departments.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      style={{ color: "#6A6767" }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Input>

              {departmentCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData.ward.name != incommingAIdata.Ward && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.Ward}
                      </li>
                    </ul>
                  ))}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={12}>
              <Label>Specialities</Label>
              <Input
                type="select"
                id="specialityIndex"
                name="specialityIndex"
                value={selectedSpeciality}
                onChange={(e) => setSelectedSpeciality(e.target.value)}
                invalid={
                  specialityCantFind ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.speciality.name !=
                      incommingAIdata.Speciality)
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              >
                {specialities.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      style={{ color: "#6A6767" }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Input>

              {specialityCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData.speciality.name !=
                    incommingAIdata.Speciality && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.Speciality}
                      </li>
                    </ul>
                  ))}
            </Col>

            {/* <Col sm={3}>
              <Label>Up lift</Label>
              <Input
                type="text"
                placeholder="Up lift"
                id="upLift"
                name="upLift"
                // invalid={errors.upLift}
                defaultValue={fieldData && fieldData.upLift}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                  maxLength: {
                    value: 30,
                    message: "This field only allowed only 30 characters",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please input a number!",
                  },
                })}
              />{" "}
               
            </Col> */}

            {/* <Col sm={3}>
              <Label>Bonus</Label>
              <Input
                type="text"
                placeholder="Bonus"
                id="bonus"
                name="bonus"
                defaultValue={fieldData && fieldData.bonus}
                invalid={errors.bonus}
              />{" "}
              {errors.bonus && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {errors.bonus?.message}
                  </li>
                </ul>
              )}
            </Col> */}
          </FormGroup>
          <FormGroup row>
            <Col sm={6}>
              <Label>Break Mins.</Label>
              <Input
                type="text"
                placeholder="Break Mins."
                id="minsTakenForBreaks"
                name="minsTakenForBreaks"
                defaultValue={incommingAIdata && incommingAIdata.Breakmin}
                // invalid={errors.minsTakenForBreaks}
              />
            </Col>
            <Col sm={6}>
              <Label>Total Hours</Label>
              <Input
                type="text"
                placeholder="Total Hours"
                id="totalHours"
                name="totalHours"
                defaultValue={incommingAIdata && incommingAIdata.Totalhours}
                // invalid={errors.totalHours}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={12}>
              <Alert color="info">
                <strong className="text-danger">*</strong> The text in{" "}
                <strong className="text-danger">red</strong> represent
                recognized hand written text{" "}
              </Alert>
            </Col>
            <Col sm={12} className="d-flex justify-content-end mr-5 ">
              <Button color={"success"} type="submit">
                {aiPending && (
                  <Spinner
                    animation="border"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                  />
                )}
                Verify
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    reduxSpecialities: state.initialDataGetReducer.specialities,
    reduxClients: state.initialDataGetReducer.clients,
    reduxRoles: state.initialDataGetReducer.roles,
  };
}

export default connect(mapStateToProps, {})(TimesheetData);
