import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { result, forEach, filter } from "lodash";
import useDidMountEffect from "./intialRunUseEffect";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../toastCommon";
import {
    Button,
    Label,
    Input,
    Form,
    FormGroup,
    Col,
    Alert,
    UncontrolledTooltip,
} from "reactstrap";
import {
    REGISTER_CANDIDATE,
    WARDS,
    SHIFTS,
    CREATE_BOOKING,
    BOOKING_WORKSPACE_CANDIDATELIST,
    HOSTMexxar,
    ClientLable,
    CompanyName,
} from "../../../configs/api-config";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { sendEmail } from "../../../pages/email/graph";
import outlookLogo from "../../../../src/images/Outlook-Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import useCandidateList from "../../../customHooks/useCandidateList";

function BookingCancellationReason(props) {
    const { register, handleSubmit, watch, errors } = useForm();

    const {
        onCreate,
        closeModal,
        candidateId,
        reset,
        currentResetState,
        dayData,
        candidateWhenClick,
        auth,
        incommingPage,
        user,
        userProfile,
    } = props;

    const MexxarApi = HOSTMexxar;
    const MexxarShift = SHIFTS;
    const MexxarApiDepartments = WARDS;
    const MexxarCandidate = REGISTER_CANDIDATE;
    const MexxarCreateBooking = CREATE_BOOKING;
    const MexxarApiBookingWorkspace = BOOKING_WORKSPACE_CANDIDATELIST;

    const { addToast } = useToasts();

    const [query, setQuery] = useState("");

    const [check, setCheck] = useState(false);
    const [shifts, setShifts] = useState(null);
    const [likeWards, setLikeWards] = useState([]);
    const [candidates, setCandidates] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [likeClients, setLikeClients] = useState([]);
    const [currentShift, setCurrentShift] = useState(0);
    const [selectedClient, setSelectedClient] = useState(0);
    const [selectedWard, setSelectedWard] = useState(0);
    const [dataErrorStatus, setDataErrorStatus] = useState(false);


    console.log("userProfile", userProfile)


    return (
        <>
            {userProfile?.cancellationNote === null || userProfile?.cancellationNote === undefined ? (
                <div>No reason added</div>
            ) : (
                <div>Reason : {userProfile?.cancellationNote}</div>
            )}
             <div className="d-flex justify-content-end">
            <Button color="secondary" onClick={() => closeModal()}>
                Close
            </Button>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        user: state.auth.item,
    };
}

export default connect(mapStateToProps, {})(BookingCancellationReason);
