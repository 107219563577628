import React, { useState, useEffect } from "react";
import axios from "axios";
import "./employee-profile.style.css";
import NoConnection from "../errorPages/NoConnection";
import loadingAnimation from "../../../src/images/puff.svg";
import { Add_NEW_EMPLOYEE } from "../../configs/api-config";
import NoSearchResults from "../errorPages/NoSearchResults";
import { useIsMounted } from "../../customHooks/useIsMounted";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import { Button, Input, Collapse, Row, Col, Card, CardBody } from "reactstrap";
import EmployeeList from "../../widgets/working-card-widget/employees-list-widget";
import { FunnelIcon } from "../../assets/icons/svg";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((item) => {
      let searchableString = `${item.firstName} ${item.lastName} ${item.email} ${item.companyPhoneNumber} ${item.teamName} ${item.usersId} `;
      let p = searchableString;
      return p.toLowerCase().includes(name.toLowerCase());
    });
  }
  let lengthofSearch = filtered.length;
  return { filtered, lengthofSearch };
};

const Employee = () => {
  const MexxarApiEmployee = Add_NEW_EMPLOYEE;
  const isMounted = useIsMounted();

  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  const getAllRows = () => {
    axios
      .get(MexxarApiEmployee)
      .then((res) => {
        if (isMounted.current) {
          setLoading(false);
          setRows(res.data.body);
        }
      })
      .catch((err) => {
        setError(err);
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    getAllRows();
  }, []);

  return (
    <DashboardLayout title="Employees" subTitle="Manage Employee Profiles">
      <div className="form-inline">
        <Button className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Employees..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
      </div>
      <br></br>

      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col></Col>
              <Col></Col>
              <Col></Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button color="success m-1 btn-sm" style={{ width: "70px" }}>
                    Search
                  </Button>
                  <Button color="danger m-1 btn-sm" style={{ width: "70px" }}>
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {rows && !loading ? (
        filterByName(searchQuery, rows).lengthofSearch > 0 ? (
          filterByName(searchQuery, rows).filtered.map((row, i) => {
            return <EmployeeList key={i} userProfile={row}></EmployeeList>;
          })
        ) : (
          <NoSearchResults page="reschedule" />
        )
      ) : null}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
};

export default Employee;
