import React, { useState } from "react";
import axios from "axios";
import { forEach } from "lodash";
import { useEffect } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  Add_NEW_EMPLOYEE,
  CANDIDATE_SPECIALITY,
  HOSTMexxar,
  TRAININGPROVIDERS,
} from "../../../../configs/api-config";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { employeesGetAction } from "../../../../redux/actions/initialDataGetAction";
import store from "../../../../redux/store";
import ToastCommon from "../../../toastCommon";
import { useToasts } from "react-toast-notifications";

const TopUpAgencyCredit = (props) => {
  const MexxarApiSpeciality = CANDIDATE_SPECIALITY;
  const MexxarApiTrainingProviders = TRAININGPROVIDERS;
  const MexxarEmployee = Add_NEW_EMPLOYEE;

  const { register, handleSubmit, errors } = useForm();
  const {
    onCreate,
    closeModal,
    mode,
    modalType,
    fieldData,
    specialityShow,
    specialityIncomingData,
    trainingProviderShow,
    trainingProviderIncomingData,
    reduxEmployees
  } = props;

  const [speciality, setSpeciality] = useState([]);
  const [trainingProviders, setTrainingProviders] = useState([]);
  const [addKillLog, setaddKillLog] = useState(false);
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [selectedTrainingProviders, setSelectedTrainingProviders] = useState(
    []
  );
  const [transaction, setTransaction] = useState([]);
  const [creditBalance, setCreditBalance] = useState([]);

  const [creditServiceType, setCreditServiceType] = useState('');
  const { addToast } = useToasts();


  useEffect(() => {
    if (!reduxEmployees) {
      store.dispatch(employeesGetAction());
    }
  }, []);

  const getTransactions = () => {
    axios
      .get("http://3.10.249.82:8080/transactionType")
      .then((res) => {
        let consultantArray = [];

        forEach(res.data.body, function (item) {
          consultantArray.push({
            value: item.type,
            label: item.type,
            id: item.id,
          });
        });

        setTransaction(consultantArray);
      })
      .catch((err) => {
        //console.log("cannot get specialities ",err)
        // toastCommon(true, "error");
      });
  };





  const handleSubmit1 = () => {
    // if (newNote.trim() === "") {
    //   ToastCommon(true, "error", addToast);
    //   return;
    // }

    axios
      .patch(`${HOSTMexxar}credit/1?creditBalance=${creditBalance}&creditServiceType=${creditServiceType}`, {


      })
      .then((res) => {
        // setStatus(res?.data?.status);
        if (res.data.status == "Success") {
          ToastCommon(true, "success", addToast);
          // getNotes();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        // ToastCommon(true, "error", addToast);
      });
  };


  const getSpecialities = () => {
    axios
      .get(MexxarApiSpeciality)
      .then((res) => {
        let specialities = [];
        forEach(res.data.body, function (item) {
          specialities.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });
        setSpeciality(specialities);
      })
      .catch((err) => {
        //console.log(err)
      });
  };


  const getTrainingProviders = () => {
    axios
      .get(MexxarApiTrainingProviders)
      .then((res) => {
        let trainingProviders = [];
        forEach(res.data.body, function (item) {
          trainingProviders.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });
        setTrainingProviders(trainingProviders);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  useEffect(() => {
    if (specialityShow === true) {
      setSelectedSpecialities(specialityIncomingData);
      getSpecialities();
    }
    if (trainingProviderShow === true) {
      setSelectedTrainingProviders(trainingProviderIncomingData);
      getTrainingProviders();
    }
  }, []);

  const onSubmit = (data) => {
    setaddKillLog(true);

    if (specialityShow === true) {
      let specilityIDArray = [];
      forEach(selectedSpecialities, function (item) {
        specilityIDArray.push(item.id);
      });

      let arrangedData = {
        rowName: data.rowName,
        specialities: specilityIDArray,
      };
      onCreate(arrangedData);
    } else if (trainingProviderShow === true) {
      let arrangedData = {
        rowName: data.rowName,
        trainingProviderId: selectedTrainingProviders.id,
      };
      onCreate(arrangedData);
    } else {
      onCreate(data);
    }
  };



  return (
    <Form >
      <FormGroup row>
        <Label sm={4}>{modalType}</Label>
        <Col sm={8}>
          <Input
            type="text"
            name="rowName"
            className="capitalize"
            defaultValue={fieldData}
            invalid={errors.bName}
            onChange={(e) => {
              setCreditBalance(e.target.value);
            }}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
        </Col>
      </FormGroup>

      {/* 
      <FormGroup row>
        <Label sm={4}>Crdit Service Type</Label>
        <Col sm={8}>
          <Input
            type="text"
            name="rowName"
            className="capitalize"
            defaultValue={fieldData}
            invalid={errors.bName}
            onChange={(e) => {
              setCreditServiceType(e.target.value);
            }}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
        </Col>
      </FormGroup> */}
      <FormGroup row>
        <Label sm={4}>Credit Service Type</Label>
        <Col sm={8}>
          <select
            name="creditServiceType"
            className="form-control capitalize"
            defaultValue={fieldData}
            onChange={(e) => {
              setCreditServiceType(e.target.value);
            }}
            ref={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          >
            <option value="">Select Type</option>
            <option value="CLIENT_MAP">Client Map</option>
            <option value="CHAT">Chat</option>
            <option value="EMAIL">Email</option>
            <option value="MESSAGE">Message</option>
            <option value="TIME_SHEET">Time Sheet</option>
          </select>
          {errors.creditServiceType && (
            <div className="invalid-feedback">
              {errors.creditServiceType.message}
            </div>
          )}
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <div className="d-flex ">
          {" "}
          <Button
            className="mr-2"
            color="success"
            onClick={() => {
              handleSubmit1();
              closeModal();
            }}
          >
            {mode}
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}
function mapStateToProps(state) {
  return {
    auth: state.auth,
    reduxEmployees: state.initialDataGetReducer.employees,
  };
}

export default connect(mapStateToProps)(TopUpAgencyCredit);
