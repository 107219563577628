import React, { useState, useEffect, useCallback, useRef } from "react";
import Select from "react-select";
import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import NoConnection from "../errorPages/NoConnection";
import { CREATE_BOOKING, ClientLable } from "../../configs/api-config";
import loadingAnimation from "../../../src/images/puff.svg";
import NoSearchResults from "../errorPages/NoSearchResults";
import { useIsMounted } from "../../customHooks/useIsMounted";
import BookingSummeryWorkSpace from "../../widgets/booking-summery-card-booking-workspace/booking-summery-card-booking-workspace";
import useBookingList from "../../customHooks/useBookingList";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { FunnelIcon } from "../../assets/icons/svg";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((item) => {
      let searchableString = `${item.candidateName}  ${item.candidatesId}  ${item.referenceNo}  ${item.clientId} ${item.clientName} ${item.ward.name}   `;
      let p = searchableString;
      return p.toLowerCase().includes(name.toLowerCase());
    });
  }
  let lengthofSearch = filtered.length;
  return { filtered, lengthofSearch };
};

const ClientBookings = (props) => {
  const { incomingPage } = props;
  const MexxarApi = CREATE_BOOKING;
  const isMounted = useIsMounted();

  // const [rows, setRows] = useState([]);
  // const [error, setError] = useState(null);
  // const [loading, setLoading] = useState(true);
  const [initialRows, setInitialRows] = useState([]);

  const [filterCustom, setFilterCustom] = useState({
    value: "CONFIRM",
    bool: true,
  });

  // const [query, setQuery] = useState("");
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchAdvanced, setsearchAdvanced] = useState(false);

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  // const handleSearchOnChange = (e) => {
  //   setSearchQuery(e.target.value);
  // };

  const getAllRows = () => {
    // axios
    //   .get(MexxarApi)
    //   .then((res) => {
    //     if (isMounted.current) {
    //       setLoading(false);
    //       setRows(res.data.body);
    //       setInitialRows(res.data.body);
    //     }
    //   })
    //   .catch((err) => {
    //     //console.log(err)
    //     setError(err);
    //   });
  };

  // useEffect(() => {
  //   getAllRows();
  // }, []);

  const handleCheck = (e) => {
    if (e.target.checked) {
      let data = e.target.value;

      switch (data) {
        case "UNCONFIRMED":
          setFilterCustom({ value: data, bool: true });
          break;
        case "CONFIRM":
          setFilterCustom({ value: data, bool: true });
          break;
        case "ATTENDED":
          setFilterCustom({ value: data, bool: true });
          break;
        case "UN_ATTENDED":
          setFilterCustom({ value: data, bool: true });
          break;

        case "CANCELED_BY_HOSPITAL":
          setFilterCustom({ value: data, bool: true });
          break;
        case "CANCELED_BY_CANDIDATE":
          setFilterCustom({ value: data, bool: true });
          break;
        case "PENDING":
          setFilterCustom({ value: data, bool: true });
          break;

        case "AMENDED":
          setFilterCustom({ value: data, bool: true });
      }
    } else {
      let data = e.target.value;

      switch (data) {
        case "UNCONFIRMED":
          setFilterCustom({ value: data, bool: false });
          break;
        case "CONFIRM":
          setFilterCustom({ value: data, bool: false });
          break;
        case "ATTENDED":
          setFilterCustom({ value: data, bool: false });
          break;
        case "UN_ATTENDED":
          setFilterCustom({ value: data, bool: false });
          break;

        case "CANCELED_BY_HOSPITAL":
          setFilterCustom({ value: data, bool: false });
          break;
        case "CANCELED_BY_CANDIDATE":
          setFilterCustom({ value: data, bool: false });
          break;
        case "PENDING":
          setFilterCustom({ value: data, bool: false });
          break;

        case "AMENDED":
          setFilterCustom({ value: data, bool: false });
          break;
      }
    }
  };

  let url = MexxarApi + "/filter-list/status/" + filterCustom.value;

  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  const { loading, error, rows, hasMore, totalElements } = useBookingList(
    url,
    filterCustom,
    query,
    pageNumber,
    searchAdvanced
  );
  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function handleSearch(e) {
    setQuery(e.target.value);
    setPageNumber(1);
  }

  const [searchStatus, setSearchStatus] = useState({
    status: false,
    data: null,
  });

  useDidMountEffect(() => {
    setPageNumber(1);
  }, [searchAdvanced]);

  // const handleSearchAdvance = () => {
  //   setSearchStatus({ status: true, data: result_3 });
  // };

  const reset = () => {
    // setRows(initialRows);
    setSearchStatus({ status: false, data: null });
    setFilterCustom({ value: "CONFIRM", bool: true });
  };

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#6A6767",
    }),
  };

  return (
    <>
      <div className="form-inline">
        <Label>
          {totalElements} {totalElements == 1 ? "Booking" : "Bookings"}
        </Label>
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>

        <Input
          onChange={handleSearch}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Bookings..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
          }}
        />
        <UncontrolledTooltip target="searchBar">
          Search bookings by Candidate Name/Candidate Id/{ClientLable}/Ward/Reference
          No.
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              {/* <Col>
                <Label>Date</Label>
                <Input type="date"></Input>
              </Col>
              <Col>
                <Label>Hospital</Label>
                <Select
                  // value={candidate.specialities}
                  isMulti
                  name="hospitals"
                  styles={colourStyles}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  //options={Hospitals}
                />
              </Col>
              <Col>
                <Label>Shift</Label>
                <Select
                  // value={candidate.specialities}
                  isMulti
                  name="DocStatus"
                  styles={colourStyles}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  //options={ShiftTypes}
                />
              </Col>
              <Col>
                <Label>Ward</Label>
                <Select
                  // value={candidate.specialities}
                  isMulti
                  name="ShifTtype"
                  styles={colourStyles}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  //options={Wards}
                />
              </Col>
              <Col></Col>
              <Col>
                <Label>Company Proccesing</Label>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />
                    <Badge href="#" color="primary">
                      All
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="warning">
                      pending
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="warning">
                      T-pending
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="danger">
                      declined
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="warning">
                      manual review
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="success">
                      approved
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="success">
                      proccesing
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="success">
                      payed
                    </Badge>
                  </Label>
                </FormGroup>
              </Col> */}
              {/* <Col>
                <p>Call Response</p>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={filterCustom.value === "ATTENDED" ? true : false}
                      onChange={(e) => handleCheck(e)}
                      value="ATTENDED"
                    />
                    <Badge color="success">Attended</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={
                        filterCustom.value === "UN_ATTENDED" ? true : false
                      }
                      onChange={(e) => handleCheck(e)}
                      value="UN_ATTENDED"
                    />
                    <Badge color="secondary">Un Attended</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={
                        filterCustom.value === "CANCELED_BY_HOSPITAL"
                          ? true
                          : false
                      }
                      onChange={(e) => handleCheck(e)}
                      value="CANCELED_BY_HOSPITAL"
                    />{" "}
                    <Badge color="danger">Cancled By Hosplital</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={
                        filterCustom.value === "CANCELED_BY_CANDIDATE"
                          ? true
                          : false
                      }
                      onChange={(e) => handleCheck(e)}
                      value="CANCELED_BY_CANDIDATE"
                    />{" "}
                    <Badge color="danger">Cancled By Candidate</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={filterCustom.value === "PENDING" ? true : false}
                      onChange={(e) => handleCheck(e)}
                      value="PENDING"
                    />{" "}
                    <Badge color="warning">Pending</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={filterCustom.value === "AMENDED" ? true : false}
                      onChange={(e) => handleCheck(e)}
                      value="AMENDED"
                    />{" "}
                    <Badge color="warning">Amended</Badge>
                  </Label>
                </FormGroup>
              </Col> */}
              <Col>
                <p>Booking Status </p>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={filterCustom.value === "CONFIRM" ? true : false}
                      onChange={(e) => handleCheck(e)}
                      value="CONFIRM"
                    />
                    <Badge color="success">Confirmed</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={
                        filterCustom.value === "UNCONFIRMED" ? true : false
                      }
                      onChange={(e) => handleCheck(e)}
                      value="UNCONFIRMED"
                    />
                    <Badge color="secondary">UnConfirmed</Badge>
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={() => {
                      setsearchAdvanced(!searchAdvanced);
                      //handleSearchAdvance();
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    onClick={() => reset()}
                    color="danger m-1 btn-sm"
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>

      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {/* {rows && !loading ? (
        filterByName(searchQuery, rows).lengthofSearch > 0 ? (
          filterByName(searchQuery, rows).filtered.map((row, i) => {
            return (
              <BookingSummeryWorkSpace
                key={i}
                userProfile={row}
              ></BookingSummeryWorkSpace>
            );
          })
        ) : (
          <NoSearchResults page="reschedule" />
        )
      ) : null} */}

      {rows &&
        rows.map((row, index) => {
          if (rows.length === index + 1) {
            return (
              <div ref={lastBookElementRef} key={row.id}>
                <BookingSummeryWorkSpace userProfile={row} />
              </div>
            );
          } else {
            return (
              <div key={row.id}>
                <BookingSummeryWorkSpace userProfile={row} />
              </div>
            );
          }
        })}

      {/* Loading animation */}
      {loading && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      {rows.length === 0 && !loading && <NoSearchResults />}
    </>
  );
};

export default ClientBookings;
