import React, { useState, useEffect } from "react";
// import "./working-card-widget.style.css";
import { Badge, Card, CardBody, Button } from "reactstrap";
import logo from "../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMars,
  faVenus,
  faPhone,
  faEnvelope,
  faCalendar,
  faPhoneSlash,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import ModalComponent from "../../components/Modal/modalComponent";
import { HOSTMexxar, HostMexxarURL } from "../../configs/api-config";
import {
  ApplicationStatus,
  GenderSwitch,
  ConversationResponse,
} from "../../util/common";
import loading from "../../../src/images/puff.svg";
import AvatarMexxar from "../../util/common/avatarMexxar";

const RecivedDocsWidget = (props) => {
  const { userProfile } = props;
  const MexxarUrl = HostMexxarURL;

  const [calendarShow, setCalendarShow] = useState(false);
  let isStatus = "pending";
  const handleDocView = () => {};
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const [certificates, setCertificates] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const handleSelectedCandidate = (userProfile) =>
    setSelectedCandidate(userProfile);
  const [selectedCandidate, setSelectedCandidate] = useState({});

  const toggleShowEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };

  const MexxarAPI = HOSTMexxar;

  const toggleM = () => setShowEmailModal(!showEmailModal);

  return (
    <>
      <div
        className=" card d-flex flex-row main bg-light p-1 "
        style={{ minWidth: "900px", marginBottom: "5px" }}
        onClick={() => handleDocView()}
      >
        <AvatarMexxar
          candidateId={userProfile?.candidateId}
          primaryPhone={userProfile?.primaryPhone}
          avatar={userProfile?.avatar}
          gender={userProfile?.gender}
        />
        <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
          <div className="d-flex ml-1 mb-1">
            <div>
              {userProfile.candidateFirstName}&nbsp;
              {userProfile.candidateLastName}{" "}
            </div>
          </div>
          <div className=" d-flex ">
            <div className="mx-2 ">
              <Link
                to={{
                  pathname: "/dashboard/call",
                  aboutParams: {
                    imageLink: userProfile.image,
                  },
                }}
              >
                <RoundIconButton
                  buttonColor="#00C426"
                  icon={faPhone}
                  iconSize="fa-md"
                  buttonSize="25px"
                ></RoundIconButton>
              </Link>
            </div>
            <div className="mx-2 ">
              <RoundIconButton
                buttonColor="#458AF7"
                icon={faEnvelope}
                click={() => toggleShowEmailModal()}
                buttonSize="25px"
              ></RoundIconButton>
            </div>
            <div className=" mx-2  d-flex align-items-center ">
              <FontAwesomeIcon
                color="#FF5603"
                icon={faCalendarAlt}
                onClick={() => handleCalendarShow(true)}
                className="fa-lg mb-2 btn-raised btn-wave "
              />
            </div>
          </div>
        </div>

        <div className="d-flex  align-items-center" style={{ flex: 1 }}>
          <FontAwesomeIcon
            // color={userProfile.gender == "1" ? "#458AF7" : "#f54394"}
            color={GenderSwitch(userProfile.gender).genderColor}
            // icon={userProfile.gender == "1" ? faMars : faVenus}
            icon={GenderSwitch(userProfile.gender).genderSymbol}
          />
        </div>

        <div
          className="d-flex flex-column  align-items-center  "
          style={{ flex: 3 }}
        >
          <div style={{ height: "4vh" }}>Document name</div>
          <small
            className="d-flex flex-column text-center"
            style={{ height: "3vh" }}
          >
            {userProfile.documentName}
          </small>
          {/* {userProfile.percentage} */}
        </div>

        <div
          className="d-flex flex-column  align-items-center "
          style={{ flex: 3 }}
        >
          <div style={{ height: "4vh" }}>Document status</div>
          <div style={{ height: "3vh" }}>
            {(() => {
              switch (userProfile.confirmation) {
                case "PENDING":
                  return <Badge className="bg-warning">pending</Badge>;

                case "APPROVE":
                  return <Badge className="bg-success">confirmed</Badge>;

                case "DECLINE":
                  return <Badge className="bg-danger">declined</Badge>;
                default:
                  return null;
                  break;
              }
            })()}
          </div>
          <div style={{ height: "3vh" }}></div>
        </div>
        <div
          className="d-flex flex-column  align-items-center "
          style={{ flex: 4 }}
        >
          <div style={{ height: "4vh" }}>Submitted on</div>

          <div className="d-flex justify-content-center ">
            <span className="mr-1">
              <small className="text-muted">
                {moment(userProfile.uploadedDate).format("ddd MMM DD YYYY")}
              </small>
            </span>
            <span className="mr-1">
              <small className="text-muted">
                {" "}
                @ {moment(userProfile.uploadedDate).format("hh:mm a")}
              </small>
            </span>
          </div>
          <div style={{ height: "3vh" }}></div>
        </div>
        <div
          className="d-flex align-items-center justify-content-center "
          style={{ flex: 2 }}
        >
          <Link
            to={{
              pathname:
                "/dashboard/candidate-profile/" +
                userProfile.candidateId +
                "/+4545454545/compliance",
            }}
          >
            <Button
              style={{ width: "70px" }}
              className="bg-success text-light no-border"
            >
              Show
            </Button>
          </Link>
        </div>
      </div>

      <div
        className="d-flex align-items-center text-success"
        style={{ flex: 2 }}
      >
        {/* {userProfile.percentage} */}
      </div>

      <ModalComponent
        show={calendarShow}
        header="Reschedule a call"
        closeModal={() => handleCalendarShow(false)}
      >
        <SmallCalendar closeModal={() => handleCalendarShow(false)} />{" "}
      </ModalComponent>

      <ModalComponent
        show={showEmailModal}
        header="E mail and sms"
        closeModal={() => toggleM()}
      >
        <hr />
        <EmailNav closeModal={() => toggleM()} user={userProfile} />
      </ModalComponent>
    </>
  );
};

export default RecivedDocsWidget;
