import React, { useEffect, useState } from "react";

import {
  faPhone,
  faPhoneSlash,
  faRocket,
  faCheck,
  faCheckCircle,
  faListAlt,
  faDatabase,
  faHospitalAlt,
  faHeadset,
} from "@fortawesome/free-solid-svg-icons";

import "./dashboard.style.css";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Header from "../../widgets/header/header";
import Footer from "../../widgets/footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SideNavigation from "../../widgets/side-navigation/side-navigation";

const DashboardLayout = (props) => {
  const {
    children,
    title,
    subTitle,
    RegisterNewButtonShow,
    targetsShow,
    target,
    showAuditTarget,
    showTodoIcon,
    settings,
    showPayrollTargets,
    showHeadhunterTargets,
    summeryData,
    dashboardType,
  } = props;

  //  console.log("summery response...summeryData",summeryData);

  const isRegistraionTargetsShow = targetsShow;
  const isShowAuditTarget = showAuditTarget;
  const isShowPayrollTargets = showPayrollTargets;
  const isShowHeadhunterTargets = showHeadhunterTargets;

  const isTodoIconShow = showTodoIcon;

  const isRegisterNewButtonShow = RegisterNewButtonShow;

  const [summeryDataState, setSummeryDataState] = useState({
    dailyCallingTarget: 0,
    outgoingAnsweredCount: 0,
    callBackCount: 0,
    incomingAnsweredCount: 0,
    dailyCleared: 0,
    verified: 0,
    declined: 0,

  });
  useEffect(() => {
    setSummeryDataState(summeryData);
    console.log(summeryData)
  }, []);

  return (
    <>
      {/* {settings.isSideNavigationOpen && <SideNavigation></SideNavigation>} */}
      <SideNavigation></SideNavigation>
      <div
        id="main"
        className="layout-column flex "
        style={{ overflowX: "hidden" }}
      >
        <div id="header" className="page-header bg-body sticky">
          <Header></Header>
        </div>
        <div id="content" className="flex ">
          <div
            className={`page-hero page-container  d-flex align-items-center ${
              settings.isDashboardOpen ? "" : "hide"
            }`}
            id="page-hero"
          >
            {title && (
              <>
                <div className="padding d-flex">
                  <div className="page-title">
                    <h2 className="text-md text-highlight">{title}</h2>
                    {dashboardType == "main" ? (
                      <h5 className="mt-3">{subTitle}</h5>
                    ) : (
                      <small className="text-muted">{subTitle}</small>
                    )}
                  </div>
                  <div className="flex"></div>
                </div>
                <span className="flex"></span>
              </>
            )}
            {isRegistraionTargetsShow ? (
              <div className=" customAlignmentsSub  mr-5">
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2   ">Daily C Target</label>
                  </div>
                  <div>
                    <FontAwesomeIcon className="text-warning" icon={faRocket} />
                  </div>
                  <div>
                  <label className="  ">
                      {isNaN(summeryData?.remainingCallingTarget
)
                        ? 0
                        : summeryData?.remainingCallingTarget
                      }
                    </label>
                    /
                    <label className="   ">
                      
                      {isNaN(summeryData?.dailyCallingTarget)
                        ? 0
                        : summeryData?.dailyCallingTarget}
                    </label>
                  </div>
                </div>

 {/* <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">R-Calls</label>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="text-blue"
                      icon={faHeadset}
                    />
                  </div>
                  <div>
                    <label className="  ">
                      {isNaN(summeryData?.remainingCallingTarget
)
                        ? 0
                        : summeryData?.remainingCallingTarget
                      }
                    </label>
                  </div>
                </div> */}


                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Answered</label>
                  </div>
                  <div>
                    <FontAwesomeIcon className="text-success" icon={faPhone} />
                  </div>
                  <div>
                    <label className="   ">
                      {isNaN(summeryData?.outgoingAnsweredCount)
                        ? 0
                        : summeryData?.outgoingAnsweredCount}
                    </label>
                  </div>
                </div>
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Unanswered</label>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="text-danger"
                      icon={faPhoneSlash}
                    />
                  </div>
                  <div>
                    <label className="  ">
                      {isNaN(
                        summeryData?.outgoingMissedCount +
                          summeryData?.voicemailCount
                      )
                        ? 0
                        : summeryData?.outgoingMissedCount +
                          summeryData?.voicemailCount}
                    </label>
                  </div>
                </div>
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Call Backs</label>
                  </div>
                  <div>
                    <FontAwesomeIcon className="text-danger" icon={faPhone} />
                  </div>
                  <div>
                    <label className="  ">
                      {isNaN(summeryData?.callBackCount)
                        ? 0
                        : summeryData?.callBackCount}
                    </label>
                  </div>
                </div>
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Incomings</label>
                  </div>
                  <div>
                    <FontAwesomeIcon className="text-success" icon={faPhone} />
                  </div>
                  <div>
                    <label className="  ">
                      {isNaN(summeryData?.incomingAnsweredCount)
                        ? 0
                        : summeryData?.incomingAnsweredCount}
                    </label>
                  </div>
                </div>
                
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Sent To Audit</label>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="text-success"
                      icon={faCheckCircle}
                    />
                  </div>
                  <div>
                    <label className="  ">
                      {isNaN(summeryData?.dailyCleared)
                        ? 0
                        : summeryData?.dailyCleared}
                    </label>
                  </div>
                </div>
                
               
              </div>
            ) : null}

            {showHeadhunterTargets ? (
              <div className=" customAlignmentsSub  mr-5">
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Daily C Target</label>
                  </div>
                  <div>
                    <FontAwesomeIcon className="text-warning" icon={faRocket} />
                  </div>
                  <div>
                    {/* <label className="  ">
                      {isNaN(summeryData?.dailyCallingTarget)
                        ? 0
                        : summeryData?.dailyCallingTarget}
                    </label> */}
                     <label className="  ">
                      {isNaN(summeryData?.remainingCallingTarget
)
                        ? 0
                        : summeryData?.remainingCallingTarget
                      }
                    </label>
                    /
                    <label className="   ">
                      {isNaN(summeryData?.dailyCallingTarget)
                        ? 0
                        : summeryData?.dailyCallingTarget}
                    </label>
                  </div>
                </div>

                {/* <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">R-Calls
                    </label>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="text-blue"
                      icon={faHeadset}
                    />
                  </div>
                  <div>
                    <label className="  ">
                      {isNaN(summeryData?.remainingCallingTarget
)
                        ? 0
                        : summeryData?.remainingCallingTarget
                      }
                    </label>
                  </div>
                </div>

 */}


                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Answered</label>
                  </div>
                  <div>
                    <FontAwesomeIcon className="text-success" icon={faPhone} />
                  </div>
                  <div>
                    <label className="  ">
                      {isNaN(summeryData?.outgoingAnsweredCount)
                        ? 0
                        : summeryData?.outgoingAnsweredCount}
                    </label>
                  </div>
                </div>
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Unanswered</label>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="text-danger"
                      icon={faPhoneSlash}
                    />
                  </div>
                  <div>
                    <label className="  ">
                      {isNaN(
                        summeryData?.outgoingMissedCount +
                          summeryData?.voicemailCount
                      )
                        ? 0
                        : summeryData?.outgoingMissedCount +
                          summeryData?.voicemailCount}
                    </label>
                  </div>
                </div>


                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Call Backs</label>
                  </div>
                  
                  <div>
                    <FontAwesomeIcon className="text-danger" icon={faPhone} />
                  </div>
                  <div>
                    <label className="  ">
                      {isNaN(summeryData?.callBackCount)
                        ? 0
                        : summeryData?.callBackCount}
                    </label>
                  </div>
                </div>
              

                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Incomings</label>
                  </div>
                  <div>
                    <FontAwesomeIcon className="text-success" icon={faPhone} />
                  </div>
                  <div>
                    <label className="  ">
                      {isNaN(summeryData?.incomingAnsweredCount)
                        ? 0
                        : summeryData?.incomingAnsweredCount}
                    </label>
                  </div>
                </div>

                
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Sent To Reg</label>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="text-success"
                      icon={faCheckCircle}
                    />
                  </div>
                  <div>
                    <label className="  ">
                      {isNaN(summeryData?.dailyCleared)
                        ? 0
                        : summeryData?.dailyCleared}
                    </label>
                  </div>
                </div>
                           
              </div>
              
            ) : null}

            

            {isShowPayrollTargets ? (
              <div className=" customAlignmentsSub  mr-5">
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Pending</label>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="text-warning"
                      icon={faDatabase}
                    />
                  </div>
                  <div>
                    <label className="  ">{summeryData?.pending}</label>
                  </div>
                </div>
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Cleared</label>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="text-success"
                      icon={faCheckCircle}
                    />
                  </div>
                  <div>
                    <label className="   ">{summeryData?.cleared}</label>
                  </div>
                </div>
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">H-Pending</label>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="text-warning"
                      icon={faHospitalAlt}
                    />
                  </div>
                  <div>
                    <label className="   ">
                      {summeryData?.hospitalPending}
                    </label>
                  </div>
                </div>
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">H-Cleared </label>
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className="text-success"
                      icon={faHospitalAlt}
                    />
                  </div>
                  <div>
                    <label className="  ">{summeryData?.hospitalCleared}</label>
                  </div>
                </div>
                {/* <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Call Backs</label>
                  </div>
                  <div>
                    <FontAwesomeIcon className="text-danger" icon={faPhone} />
                  </div>
                  <div>
                    <label className="  ">{summeryData?.hospitalCleared}</label>
                  </div>
                </div> */}
                {/* <div className="customAlignments">
                  <div>
                    <label className="  ">Payment Pending</label>
                  </div>
                  <div>
                    <FontAwesomeIcon className="text-success" icon={faPhone} />
                  </div>
                  <div>
                    <label className="   ">3</label>
                  </div>
                </div> */}
              </div>
            ) : null}

            {isShowAuditTarget ? (
              <div className=" customAlignmentsSub  mr-5">
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Daily C Target</label>
                  </div>
                  <div>
                    <FontAwesomeIcon className="text-warning" icon={faRocket} />
                  </div>
                  <div>
                    <label className="  ">
                      {isNaN(summeryData?.dailyCallingTarget)
                        ? 0
                        : summeryData?.dailyCallingTarget}
                    </label>
                  </div>
                </div>
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Verified</label>
                  </div>
                  <div>
                    <FontAwesomeIcon className="text-success" icon={faCheck} />
                  </div>
                  <div>
                    <label className="  ">
                      {isNaN(summeryData?.verified) ? 0 : summeryData?.verified}
                    </label>
                  </div>
                </div>
                <div className="customAlignments">
                  <div>
                    <label className=" mr-2 ">Declined</label>
                  </div>
                  <div className="text-danger">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-x-circle mx-2"
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="15" y1="9" x2="9" y2="15"></line>
                      <line x1="9" y1="9" x2="15" y2="15"></line>
                    </svg>
                  </div>
                  <div>
                    <label className=" ">
                      {isNaN(summeryData?.declined) ? 0 : summeryData?.declined}
                    </label>
                  </div>
                </div>
              </div>
            ) : null}



            {isRegisterNewButtonShow ? (
              <div className=" justify-content-end  mr-5">
                <label className=" mr-2 ">Add Candidate</label>
                <Link to="/dashboard/candidates/register">
                  <Button
                    className="btn-raised"
                    style={{
                      backgroundColor: "teal",
                      height: "35px",
                      width: "35px",
                      borderRadius: "17px",
                      border: "0px",
                    }}
                  >
                    +
                  </Button>
                  {/* <button className="btn btn-lg btn-raised btn-wave btn-icon btn-rounded mb-2 teal text-white">

                  </button> */}
                </Link>
              </div>
            ) : null}
            {isTodoIconShow ? (
              <div>
                <button className="btn btn-lg btn-raised btn-wave btn-icon btn-rounded mb-2 mr-3 teal text-white">
                  <FontAwesomeIcon icon={faListAlt} className="fa-xs" />
                </button>
              </div>
            ) : null}
          </div>
          <div className="page-content page-container">
            <div className="padding">{children}</div>
          </div>
        </div>
        <div id="footer" className="page-footer hide">
          <Footer />
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    settings: state.appSettings,
  };
}

export default connect(mapStateToProps, {})(DashboardLayout);