import {
  REGISTER_USER,
  LOGIN_USER,
  SET_AUTHENTICATION,
  UNAUTHENTICATED,
  INVALID_USER_CREDENTIALS,
  SET_LOGGING_PROGRESS,
  SYSTEM_ERROR,
} from "./types";
import Axios from "axios";
import { SIGN_IN } from "../../configs/api-config";
import SetAuthorizationToken from "../../util/auth/SetAuthorizationToken";
import { persistedStore } from "../store";
import { setAuthToken, setLoggedInUser } from "./loginUserAction";

export const setAuthetication = (data) => (dispatch) => {
  dispatch({
    type: SET_AUTHENTICATION,
    payload: data,
  });
};

export const loginUser = (loginData) => (dispatch) => {
  dispatch({
    type: SET_LOGGING_PROGRESS,
    payload: null,
  });
  Axios.post(SIGN_IN, loginData)
    .then((res) => {
      if (res.data.status == "success") {
        let loggedinUser = {
          ...res.data.body[1],
          companyPhoneNumber: res.data.body[2]?.companyPhoneNumber || "",
        };

        const token = res.data.body[0].token;
        localStorage.setItem("jwtToken", token);
        localStorage.setItem("user", JSON.stringify(loggedinUser));

        dispatch(setAuthToken(token));
        dispatch(setLoggedInUser(loggedinUser));
        window.location.reload();

        dispatch({
          type: LOGIN_USER,
          payload: { status: true, loggedinUser },
        });

        setTimeout(() => {
          SetAuthorizationToken(token);
          setAuthetication(res.data.body[1]);
        }, 100);
      } else {
        dispatch({
          type: INVALID_USER_CREDENTIALS,
          payload: {},
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: SYSTEM_ERROR,
        payload: {},
      });
    });
};

export const signOutAction = () => (dispatch) => {
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("user");
  sessionStorage.removeItem("allOtherData");

  delete Axios.defaults.headers.common["Authorization"];
  dispatch({
    type: UNAUTHENTICATED,
    payload: {},
  });
  // window.location.reload();
};
