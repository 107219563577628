import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import filter from "lodash/filter";
import { Button } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../components/toastCommon";
import { CONFIGURE_DOCS, HOSTMexxar } from "../../../configs/api-config";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Delete from "../../../components/Modal/ModalBody/delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import ConfigureDoc from "../../../components/Modal/ModalBody/Admin/configureDocuments";
import useConfigureDocsFilter from "../../../customHooks/useConfigureDocsFilter";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";

const SetFrameworkRelationship = () => {
  const MexxarApi = CONFIGURE_DOCS;

  const { addToast } = useToasts();

  /***************** Search filter ******************/
  const [query, setQuery] = useState(null);
  const [reset, setReset] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchAdvanced, setSearchAdvanced] = useState(false);

  // const [rows, setRows] = useState(null);
  const [rowID, setRowID] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showAddRow, setShowAddRow] = useState(false);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);

  let url = HOSTMexxar + "documents/combine-docs/filter";

  const { loading, error, rows, hasMore, totalElements } =
    useConfigureDocsFilter(url, query, pageNumber, searchAdvanced, reset);
  // const getAllRows = () => {
  //   axios
  //     .get(MexxarApi)
  //     .then((res) => {
  //       // setRows(res.data.body);
  //     })
  //     .catch((err) => {
  //       //console.log(err)
  //       ToastCommon(true, "error", addToast);
  //     });
  // };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const addRow = (data) => {
    const FId = Number(data.frameworkId);
    const DId = Number(data.documentId);
    const LId = Number(data.levelId);
    const SId = Number(data.candidateSpecialityId);
    const RId = Number(data.candidateRoleId);

    // if (duplicate_items.length > 0) {
    //   toastCommon(true, "duplicate");
    // } else {
    // alert("done");

    // toastSuccess()
    axios
      .post(MexxarApi, [
        {
          frameworkId: FId,
          documentId: DId,
          levelId: LId,
          candidateSpecialityId: SId,
          candidateRoleId: RId,
        },
      ])
      .then((res) => {
        if (res.data.status == "success") {
          // getAllRows();
          setPageNumber(1);
          setShowAddRow(!showAddRow);
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });

    //}
  };

  const updateRow = (data) => {
    const FId = Number(data.frameworkId);
    const DId = Number(data.documentId);
    const LId = Number(data.levelId);
    const SId = Number(data.candidateSpecialityId);
    const RId = Number(data.candidateRoleId);
    const TId = data.documentType;

    axios({
      method: "PUT",
      url: MexxarApi + "/updateType?",
      params: {
        frameworkId: FId,
        documentId: DId,
        levelId: LId,
        specialityId: SId,
        roleId: RId,
        newType: TId,
      },
    })
      .then((res) => {
        if (res.data.status == "success") {
          // getAllRows();
          setPageNumber(1);
          setShowUpdateRow(!showUpdateRow);
          ToastCommon(true, "update", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const deleteRow = () => {
    axios
      .delete(MexxarApi + "/" + rowID)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "delete", addToast);
          // getAllRows();
          setPageNumber(1);
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "update", addToast);
      });
  };

  const deleteAllRows = () => {
    selectedItems.forEach((item) => {
      axios
        .delete(MexxarApi + "/" + item)
        .then((res) => {
          //  getAllRows();
          setPageNumber(1);
          setSelectedItems([]);
          setSelectAll(false);
        })
        .catch((err) => {
          //console.log(err)
        });
    });
    ToastCommon(true, "delete", addToast);
  };

  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
    } else {
      setSelectedItems([...selectedItems, rowId]);
    }
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  useEffect(() => {
    // getAllRows();
  }, []);

  /******* Use to set page number to default value of 1 when changing the employee or search advance filters  *******/
  useDidMountEffect(() => {
    setPageNumber(1);
  }, [searchAdvanced]);
  /*******End *******/

  return (
    <DashboardLayout
      title="Configure Frameworks"
      subTitle="Configure Frameworks With Role, Speciality, Framework, Level And Document"
      target=""
    >
      <div className="d-flex justify-content-between p-2 ml-3">
        <div>
          {selectedItems.length > 0 ? (
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                setShowDeleteAll(true);
              }}
            >
              Delete
            </Button>
          ) : null}
        </div>
        <div>
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowUpdateRow(true);
            }}
          >
            Update Configuration
          </Button>
          <Button
            color="primary"
            className="mx-2"
            size="sm"
            onClick={() => {
              setShowAddRow(true);
            }}
          >
            Configure Document
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <table
            id="datatable"
            className="table table-theme table-row v-middle dataTable no-footer"
            role="grid"
            ariaDescribedby="datatable_info"
          >
            <thead>
              <tr role="row">
                <th
                  className="sorting_asc"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaSort="ascending"
                  ariaLabel="ID: activate to sort column descending"
                  style={{ width: "26px" }}
                >
                  <label className="ui-check m-0">
                    <input
                      type="checkbox"
                      name="id"
                      checked={selectAll}
                      onChange={() => checkAll()}
                    />{" "}
                    <i></i>
                  </label>
                </th>
                <th
                  className="sorting_asc"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaSort="ascending"
                  ariaLabel="ID: activate to sort column descending"
                  style={{ width: "26px" }}
                >
                  <span className="text-muted">ID</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Role</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Speciality</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Framework</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Level</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Document</span>
                </th>

                <th
                  className="sorting_disabled d-flex justify-content-end mr-3"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Tasks"
                >
                  <span className="text-muted d-none d-sm-block mr-3">
                    Actions
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {rows == null ? (
                ""
              ) : (
                <>
                  {rows?.map((row, i) => {
                    if (rows.length === i + 1) {
                      return (
                        <tr
                          ref={lastBookElementRef}
                          key={row.id}
                          className="odd"
                          role="row"
                        >
                          <td
                            style={{ minWidth: "30px", textAlign: "center" }}
                            className="sorting_1"
                          >
                            <label className="ui-check m-0">
                              <input
                                type="checkbox"
                                name="id"
                                checked={isSelected(row.id)}
                                onChange={() => onItemSelect(row.id)}
                              />{" "}
                              <i></i>
                            </label>
                          </td>
                          <td
                            style={{ minWidth: "30px", textAlign: "center" }}
                            className="sorting_1"
                          >
                            <small className="text-muted">{i + 1}</small>
                          </td>
                          {row.candidateRole == null ? (
                            ""
                          ) : (
                            <>
                              {" "}
                              <td className="">{row.candidateRole.name}</td>
                              <td className="">
                                {row.candidateSpeciality.name}
                              </td>
                              <td className="">{row.framework.name}</td>
                              <td className="">{row.level.name}</td>
                              <td className="">{row.document.name}</td>{" "}
                            </>
                          )}

                          <td className="d-flex justify-content-end">
                            {/* <FontAwesomeIcon
                        // color="#448bff"
                        icon={faEdit}
                        className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3"
                        onClick={() => {
                          setShowUpdateRow(true);
                          setRowID(row.id);
                          setRowNameData(row.name);
                        }}
                        size="1x"
                      /> */}
                            <FontAwesomeIcon
                              // color="#FF5603"
                              icon={faTrashAlt}
                              onClick={() => {
                                setShowDeleteRow(true);
                                setRowID(row.id);
                              }}
                              className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave "
                              size="1x"
                            />
                          </td>
                        </tr>
                      );
                    } else {
                      return (
                        <tr
                          ref={lastBookElementRef}
                          key={row.id}
                          className="odd"
                          role="row"
                        >
                          <td
                            style={{ minWidth: "30px", textAlign: "center" }}
                            className="sorting_1"
                          >
                            <label className="ui-check m-0">
                              <input
                                type="checkbox"
                                name="id"
                                checked={isSelected(row.id)}
                                onChange={() => onItemSelect(row.id)}
                              />{" "}
                              <i></i>
                            </label>
                          </td>
                          <td
                            style={{ minWidth: "30px", textAlign: "center" }}
                            className="sorting_1"
                          >
                            <small className="text-muted">{i + 1}</small>
                          </td>
                          {row.candidateRole == null ? (
                            ""
                          ) : (
                            <>
                              {" "}
                              <td className="">{row.candidateRole.name}</td>
                              <td className="">
                                {row.candidateSpeciality.name}
                              </td>
                              <td className="">{row.framework.name}</td>
                              <td className="">{row.level.name}</td>
                              <td className="">{row.document.name}</td>{" "}
                            </>
                          )}

                          <td className="d-flex justify-content-end">
                            {/* <FontAwesomeIcon
                        // color="#448bff"
                        icon={faEdit}
                        className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3"
                        onClick={() => {
                          setShowUpdateRow(true);
                          setRowID(row.id);
                          setRowNameData(row.name);
                        }}
                        size="1x"
                      /> */}
                            <FontAwesomeIcon
                              // color="#FF5603"
                              icon={faTrashAlt}
                              onClick={() => {
                                setShowDeleteRow(true);
                                setRowID(row.id);
                              }}
                              className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave "
                              size="1x"
                            />
                          </td>
                        </tr>
                      );
                    }
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <ModalComponent
        show={showAddRow}
        header="Configure Document"
        className="modal-dialog modal-lg"
        closeModal={() => setShowAddRow(false)}
      >
        <ConfigureDoc
          mode="Add"
          closeModal={() => setShowAddRow(false)}
          onCreate={(data) => addRow(data)}
        />
      </ModalComponent>

      <ModalComponent
        show={showUpdateRow}
        header="Update Configuration"
        className="modal-dialog modal-lg"
        closeModal={() => setShowUpdateRow(false)}
      >
        <ConfigureDoc
          mode="Update"
          closeModal={() => setShowUpdateRow(false)}
          onCreate={(data) => updateRow(data)}
        />
      </ModalComponent>

      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteRow();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(false)}
      />
      <Delete
        show={showDeleteAll}
        isConfirm={() => {
          deleteAllRows();
          setShowDeleteAll(!showDeleteAll);
        }}
        closeModal={() => setShowDeleteAll(false)}
      />
    </DashboardLayout>
  );
};

export default SetFrameworkRelationship;
