export function generateAvatar(name, folderName) {
    const words = name?.split(" ");
    let initials = "";

    words?.forEach((word) => {
      if (word?.length > 0 && initials?.length < 2) {
        initials += word[0]?.toUpperCase();
      }
    });

    let colorClass = "";
    switch (folderName) {
      case "Inbox":
        colorClass = "gd-info";
        break;
      case "Drafts":
        colorClass = "gd-warning";
        break;
      case "Junk Email":
        colorClass = "gd-danger";
        break;
      case "Deleted Items":
        colorClass = "gd-secondary";
        break;
      case "Sent Items":
        colorClass = "gd-success";
        break;
      case "Archive":
        colorClass = "secondary";
        break;
      case "Outbox":
        colorClass = "warning";
        break;
      case "Conversation History":
        colorClass = "secondary";
        break;
      default:
        colorClass = "gd-primary";
    }

    return {
      initials: initials,
      colorClass: colorClass,
    };
  }