import {
  SYSTEM_ERROR,
  REGISTER_NEW_CANDIDATE_INPROGRESS,
  CANDIDATE_EXISTS,
  CANDIDATE_REGISTRATION_SUCCESS,
  FETCH_TODAY_HEADHUNTER_CANDIDATES,
  FETCH_TODAY_HEADHUNTER_CANDIDATES_INPROGRESS,
} from "../actions/types";

const initialState = {
  regsitrationSuccess: false,
  alreadyExsist: false,
  systemError: false,
  inProgress: false,
  todayCandidatesList: [],
  todayCandidatesListInProgress: false,
  message: "This candidate already registered in the system!",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SYSTEM_ERROR:
      return {
        ...state,
        systemError: true,
        inProgress: false,
        message: "Something went wrong! Please try again.",
        regsitrationSuccess: false,
        alreadyExsist: false,
      };
    case REGISTER_NEW_CANDIDATE_INPROGRESS:
      return {
        ...state,
        regsitrationSuccess: false,
        inProgress: true,
        systemError: false,
        message: "",
        alreadyExsist: false,
      };
    case CANDIDATE_REGISTRATION_SUCCESS:
      return {
        ...state,
        regsitrationSuccess: true,
        inProgress: false,
        systemError: false,
        alreadyExsist: false,
        message: "New candidate added successfully!",
      };
    case CANDIDATE_EXISTS:
      return {
        ...state,
        regsitrationSuccess: false,
        inProgress: false,
        systemError: true,
        alreadyExsist: true,
        message: "This candidate already registered in the system!",
      };
    case FETCH_TODAY_HEADHUNTER_CANDIDATES:
      return {
        ...state,
        todayCandidatesList: action.payload.data.candidates,
      };
    case FETCH_TODAY_HEADHUNTER_CANDIDATES_INPROGRESS:
      return {
        ...state,
        todayCandidatesListInProgress: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
}
