import React, { useState } from "react";
import QuickDrafts from "./quickDraft";
import BookingDrafts from "./bookingDrafts";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import TabNavigationMenu from "../../util/common/tabNavigation";

const Drafts = () => {
  const [currentTab, setCurrentTab] = useState("quickDrafts");

  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <>
      <DashboardLayout
        title="Quick Drafts"
        subTitle="Get Ready With Your Bookings"
      >
        <TabNavigationMenu
          activated={currentTab}
          onChangeItem={switchTabHandler}
          itemList={[
            { name: "Quick Drafts", key: "quickDrafts" },
            { name: "Booking Drafts", key: "bookingdrafts" },
          ]}
        />

        {currentTab === "quickDrafts" && <QuickDrafts></QuickDrafts>}
        {currentTab === "bookingdrafts" && <BookingDrafts />}
      </DashboardLayout>
    </>
  );
};

export default Drafts;
