import React, { useEffect, useState } from "react";
import moment from "moment";

const NotificationCard = (props) => {
  const { notification } = props;

  return (
    <div
      className={`card mb-2 mx-2 ${
        notification.viewedAt == moment(new Date()).format()
          ? "bg-secondary"
          : null
      }`}
    >
      <div className="card-body mt-2 p-1">
        <div className="d-flex flex-row justify-content-between ">
          <div className="d-flex  " style={{ width: "450px" }}>
            <div className="d-flex align-items-center mr-3 ">
              <span className="mx-2">
                <b className="badge badge-circle sm text-primary"></b>
              </span>
            </div>
            <div>
              <small>{notification.tittle}</small>
              <div className="item-mail text-muted h-1x d-none d-sm-block">
                <time>
                  <small>{moment(notification.date).format("llll")}</small>
                </time>
              </div>
              <div className="item-mail text-muted h-1x d-none d-sm-block">
                <time>
                  <small>{notification.body}</small>
                </time>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;