import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { Button, Label, Form, Input } from "reactstrap";
import { HOSTMexxar } from "../../../configs/api-config";
import ToastCommon from "../../toastCommon";

function Text(props) {
  const MexxarAPI = HOSTMexxar;

  const { register, handleSubmit, errors } = useForm();
  const { user, template, closeModal, auth, incomingPage } = props;
  const { addToast } = useToasts();

  const [candidates, setCandidates] = useState([]);
  const [templateContent, setTemplateContent] = useState();
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const onSubmit = (data) => {
    let sms = {
      from: auth.item.companyPhoneNumber,
      to: selectedCandidate.value,
      message: data.smsBody,
    };

    axios
      .post(MexxarAPI + "twillio/message", sms)
      .then((res) => {
        if (res.data.status == "success" || res.data.message == "created") {
          ToastCommon(true, "send", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const getCandidates = () => {
    axios.get(MexxarAPI + "candidates/my").then((res) => {
      let listOfCandidates = [];
      for (let i = 0; i < res.data.body.length; i++) {
        listOfCandidates.push({
          value: res.data.body[i].primaryMobile,
          label: res.data.body[i].firstName + " " + res.data.body[i].lastName,
          id: res.data.body[i].id,
          candidate: res.data.body[i],
        });
      }
      setCandidates(listOfCandidates);
    });
  };

  const selectedUsersFromSelecters = (data) => {
    setSelectedCandidate(data);

    if (template != undefined && template != null) {
      let temp = template.template.replace("{toName}", String(data.label));
      let temp2 = temp.replace("{fromName}", `- ${user.firstName}`);
      setTemplateContent(temp2);
    }
  };

  useEffect(() => {
    getCandidates();
    if (incomingPage == "Bookings" || incomingPage == "FullCandidateList") {
      setSelectedCandidate({
        value: user.primaryMobile,
        label: user.firstName + " " + user.lastName,
        id: user.id,
        candidate: user,
      });
    } else {
      setSelectedCandidate({
        value: user.primaryPhone,
        label: user.candidateFirstName + " " + user.candidateLastName,
        id: user.candidateId,
        candidate: user,
      });
    }
    return () => {};
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row mt-3">
        <Label>To Candidate</Label>
        {candidates != undefined && candidates != null ? (
          <Select
            type="select"
            placeholder="Candidate Phone Number"
            name="candidate"
            className="col-12"
            defaultValue={
              (user && incomingPage == "Bookings") ||
              incomingPage == "FullCandidateList" ||
              incomingPage == "callingPage"
                ? {
                    value: user.primaryMobile,
                    label: user.firstName + " " + user.lastName,
                    id: user.id,
                    candidate: user,
                  }
                : {
                    value: user.primaryPhone,
                    label:
                      user.candidateFirstName + " " + user.candidateLastName,
                    id: user.candidateId,
                    candidate: user,
                  }
            }
            onChange={(data) => selectedUsersFromSelecters(data)}
            options={candidates}
            innerRef={register({
              required: {
                value: true,
                message: "Phone Number Required",
              },
              pattern: {
                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email address!",
              },
            })}
          />
        ) : null}
        {errors.email && <p>{errors.email.message}</p>}
      </div>
      <div>
        <Label>Message</Label>
        <Input
          type="textarea"
          className="form-control"
          id="exampleFormControlTextarea1"
          defaultValue={
            templateContent === undefined || templateContent === null
              ? ""
              : templateContent
          }
          rows="5"
          name="smsBody"
          innerRef={register({
            required: {
              value: true,
              message: "Body required",
            },
          })}
        />
      </div>
      <div className="mt-3">
        {" "}
        <div className="d-flex justify-content-end">
          <Button className="mr-2" color="success" type="submit">
            Send
          </Button>{" "}
          <Button color="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(Text);
