import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { WARDS } from "../../../../configs/api-config";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";

const ConfigureWards = (props) => {
  const MexxarApiWards = WARDS;

  const { onCreate, closeModal, rowID, mode, clientId, rowData } = props;
  const { register, handleSubmit, errors } = useForm();
  const { addToast } = useToasts();

  const [wards, setWards] = useState(null);
  const [contactPersons, setContactPersons] = useState([{}]);

  const onSubmit = (data) => {
    let phoneNumbers = [];

    for (let i = 0; i < 1000; i++) {
      if (!data["Cname" + i]) {
        break;
      }
      let name = data["Cname" + i];
      let phone = data["CPPhone" + i];
      let fax = data["CFax" + i];
      let email = data["CEmail" + i];

      phoneNumbers.push({
        name: name,
        phoneNumber: phone,
        fax: fax,
        email: email,
      });
    }

    let ArrangedWardData;
    if (mode === "Add") {
      ArrangedWardData = {
        clientId: clientId,
        email: data.email,
        fax: data.fax,
        wardId: Number(data.wardId),
        phoneNumbers: phoneNumbers,
      };
    } else {
      ArrangedWardData = {
        id: rowData.id,
        email: data.email,
        fax: data.fax,
        clientId: clientId,
        wardId: Number(data.wardId),
        phoneNumbers: phoneNumbers,
      };
    }
    onCreate(ArrangedWardData);
  };

  const getWards = () => {
    axios
      .get(MexxarApiWards)
      .then((res) => {
        setWards(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  // remove all addmore fields at once
  const removeMTAddMore = () => {
    contactPersons.pop(contactPersons);
    setContactPersons([...contactPersons]);
  };

  let filtered = contactPersons.filter(
    (item) => Object.keys(item).length === 0
  );

  useEffect(() => {
    if (mode === "Update") {
      setContactPersons(rowData.phoneNumbers);
    }
    getWards();
  }, []);

  return (
    <Form className="col-12" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={12}>
          <Label>Department</Label>
          {wards != null ? (
            <Input
              type="select"
              id="wardId"
              name="wardId"
              defaultValue={rowData != undefined ? rowData.wardId : ""}
              // invalid={errors.candidateRoleId}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            >
              <option value={0}>Select</option>
              {wards.map((items, id) => {
                return (
                  <option value={items.id} key={id}>
                    {items.name}
                  </option>
                );
              })}
            </Input>
          ) : null}
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>Email</Label>
            <Input
              type="text"
              name={"email"}
              defaultValue={rowData != undefined ? rowData.email : ""}
              invalid={errors.email}
              innerRef={register({
                required: {
                  value: true,
                  message: "E-mail required",
                },
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter a valid email address!",
                },
              })}
            ></Input>
            {errors.email && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.email.message}
                  <span className="text-danger ml-1">*</span>
                </li>
              </ul>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>Fax</Label>
            <Input
              type="text"
              name={"fax"}
              defaultValue={rowData != undefined ? rowData.fax : ""}
              invalid={errors.primaryMobile}
              innerRef={register({
                required: {
                  value: false,
                  message: "This field is required!",
                },
                pattern: {
                  value:
                    /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                  message: "Please enter a valid fax number",
                },
              })}
            ></Input>
            {errors.fax && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.fax.message}
                  <span className="text-danger ml-1">*</span>
                </li>
              </ul>
            )}
          </FormGroup>
        </Col>
        <hr />

        <Label>Contact Details</Label>
        {contactPersons.map((data, id) => {
          return (
            <Row form key={id}>
              <Col md={3}>
                <FormGroup>
                  <Label>Name</Label>

                  <Input
                    type="text"
                    name={"Cname" + id}
                    innerRef={register({ required: false, max: 15 })}
                    defaultValue={mode === "Update" ? data.name : ""}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Contact No</Label>
                  <Input
                    type="text"
                    name={"CPPhone" + id}
                    defaultValue={mode === "Update" ? data.phoneNumber : ""}
                    innerRef={register}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Fax</Label>

                  <Input
                    type="text"
                    name={"CFax" + id}
                    defaultValue={mode === "Update" ? data.fax : ""}
                    innerRef={register}
                  ></Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label>Email</Label>

                  <Input
                    type="text"
                    name={"CEmail" + id}
                    defaultValue={mode === "Update" ? data.fax : ""}
                    innerRef={register}
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
          );
        })}
        <Col>
          <div className="d-flex justify-content-between mb-2">
            <div></div>
            <div>
              <Button
                color="success font-weight-bold "
                style={{ borderRadius: "50px" }}
                onClick={() => setContactPersons([...contactPersons, {}])}
              >
                +
              </Button>

              {filtered.length > 0 ? (
                <Button
                  color="danger font-weight-bold  mx-2"
                  style={{
                    borderRadius: "80px",
                    width: "35px",
                    height: "35px",
                  }}
                  onClick={() => removeMTAddMore()}
                >
                  -
                </Button>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>
      <Row className="justify-content-end">
        <div className="d-flex ">
          {" "}
          <Button className="mr-2" color="success" type="submit">
            Save
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
};

export default ConfigureWards;
