import axios from "axios";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";
import ToastCommon from "../../../toastCommon";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import newClient from "../newClient";
import { useToasts } from "react-toast-notifications";
import { HOSTMexxar } from "../../../../configs/api-config";

export default function RejectForm(props) {
  const { register, handleSubmit, errors } = useForm();
  // const MexxarApiRoot = HOSTMexxar;
  const [addKillLog, setaddKillLog] = useState(false);
  const [rejectDisabled, setRejectDisabled] = useState(true);

  const { addToast } = useToasts();
  const {
    onCreate,
    closeModa12,
    mode,
    modalType,
    fieldDataOne,
    fieldDataTwo,
    rowNoteID,
    rowID,
    auth,
    rowvalue,
    action,
    getData,
    reset
  } = props;
  const [value1, setValue1] = useState("");


  // const changeHandler1 = ({ target }) => {
  //     setValue1(target.value);
  // };

  const changeHandler1 = (event) => {
    const { value } = event.target;
    const trimmedValue = value.trim();
    setValue1(trimmedValue);
    setRejectDisabled(trimmedValue === "");
  };


  const RejectForm = () => {
    const body = "Reference form reject"

    axios
      .patch(`${HOSTMexxar}reference-form/rejectForm/${rowID}?rejectReason=${value1}`)
      .then((res) => {
        console.log(res)
        if (res.data.status == "success") {
          reset()
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

 
  

   return (
    <Form >
      <FormGroup row>
        <Label sm={4}>Reason</Label>
        <Col sm={8}>
          <Input
            //  value={rowvalue}
            onChange={changeHandler1}
            type="text"
            name="reason"
            // defaultValue={rowvalue}
            invalid={errors.reason}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allowed only 30 characters",
              },
            })}
          ></Input>
          {errors.reason && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.reason?.message}
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>
      <Row className="justify-content-end">
        <div className="d-flex ">
          <Button
            className="mr-2"
            color="success"
            onClick={() => {
              RejectForm();
              
            }}
            disabled={rejectDisabled}
          >
            Reject
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModa12()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}