import React, { useEffect, useState } from "react";
import {
  EMPLOYEE_PERMISSIONS,
  EMPLOYEE_TEAMS,
  HOSTMexxar,
  HostMexxarURL,
  TRUSTS,
  WARDS,
} from "../../configs/api-config";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { Bar } from "react-chartjs-2";
import { Label, Button, ButtonGroup, Col, Row, Input } from "reactstrap";
import { forEach, uniqWith, isEqual, filter, isNull, sumBy } from "lodash";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import {
  clientsGetAction,
  departmentsGetAction,
  employeesGetAction,
} from "../../redux/actions/initialDataGetAction";
import store from "../../redux/store";
import { connect } from "react-redux";

const DailyData = {
  labels: [
    "Janet",
    "Leeny",
    "Tom",
    "Matt",
    "Natasha",
    "James",
    "Janet",
    "Leeny",
    "Tom",
    "Matt",
    "Natasha",
    "James",
    "Janet",
    "Leeny",
    "Tom",
    "Matt",
    "Natasha",
    "James",
  ],
  datasets: [
    {
      label: "No of Clearence",
      data: [2, 1, 3, 4, 2, 4, 1, 0, 2, 5, 2, 3, 9, 8, 2, 1, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const MissingBookings = (props) => {
  let demo = false;
  const MexxarApi = HOSTMexxar;
  const MexxarTeams = EMPLOYEE_TEAMS;
  const MexxarTeamMembers = EMPLOYEE_PERMISSIONS;
  const MexxarApiTrust = TRUSTS;
  const mexxarWards = WARDS
  const { reduxClients, reduxEmployees,reduxWards } = props;

  const defaultsTypes = [
    // {
    //   id: 1,
    //   label: "By Trust",
    //   value: "trust",
    // },
    {
      id: 1,
      label: "By Client",
      value: "client",
    },
    // {
    //   id: 1,
    //   label: "By Team",
    //   value: "team",
    // },
    // {
    //   id: 1,
    //   label: "By Employee",
    //   value: "employee",
    // },
    {
      id: 2,
      label: "By Wards",
      value: "ward",
    },
  ];
  const [team, setTeam] = useState(null);
  const [trust, setTrust] = useState(null);
  const [secondSelectOption, setSecondSelectOption] = useState(null);
  const [type, setType] = useState(defaultsTypes);
  const [dateTo, setDateTo] = useState();
  const [range, setRange] = useState(false);
  const [dateFrom, setDateFrom] = useState();
  const [dataList, setDataList] = useState([]);
  const [rangeType, setRangeType] = useState("YEARLY");
  const [noOfbookings, setNoOfBookings] = useState([]);
  const [noOfMissingBookings, setNoOfMissingBookings] = useState([]);
  const [employees, setEmployees] = useState();
  const [clients, setClients] = useState();
  const [wards, setWards] = useState();
  const [selectedSecondSelectOption, setSelectedSecondSelectOption] =
    useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [dummpyDataBooking, setDummpyDataBooking] = useState();
  const [dummpyDataMissingBookings, setDummpyDataMissingBookings] = useState();



  console.log("wards",reduxWards)
  
  console.log("reduxClients",reduxClients)
  const yearData = [
    String(Number(currentYear) - 9),
    String(Number(currentYear) - 8),
    String(Number(currentYear) - 7),
    String(Number(currentYear) - 6),
    String(Number(currentYear) - 5),
    String(Number(currentYear) - 4),
    String(Number(currentYear) - 3),
    String(Number(currentYear) - 2),
    String(Number(currentYear) - 1),
    String(Number(currentYear)),
  ];

  const monthData = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

const jsonData=[
  [
    {
        "body": {
            "reportType": "Missed Bookings",
            "startDate": "2023-10-01",
            "endDate": "2023-10-31",
            "items": [
                {
                    "itemCountMissedShiftsByHospital": 450,
                    "itemCountMissedShiftsByHospitalAndWard": 55,
                    "itemCountMissedShiftsByDateRange": 400
                }
            ],
            "reportFEFormatted": null,
            "totalItems": 1
        },
        "status": "success",
        "message": "data retrieved successfully"
    },
    {
        "body": {
            "reportType": "Successfull Bookings",
            "startDate": "2023-09-15",
            "endDate": "2023-10-10",
            "items": [
                {
                    "itemCountMissedShiftsByHospital": 620,
                    "itemCountMissedShiftsByHospitalAndWard": 75,
                    "itemCountMissedShiftsByDateRange": 580
                }
            ],
            "reportFEFormatted": null,
            "totalItems": 1
        },
        "status": "success",
        "message": "report data fetched"
    },
 
]

]
const missedBookingsData = jsonData[0][0].body;
const successfulBookingsData = jsonData[0][1].body;




  const weekData = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const demoTrusts = [
    "Aintree University Hospital NHS Foundation Trust",
    "Airedale NHS Foundation Trust",
  ];
  const demoClients = [
    "Kingston Hospital",
    "St. Thomas' Hospital",
    "Vale Hospital",
    "DDRC Healthcare",
    "South Hams Hospital",
    "Derriford Hospital",
  ];

  const demoWards = [
    "Ward 11",
    "Keats Ward",
    "A&E Paediatrics",
    "Blyth Ward",
    "Alexandra Ward",
    "Derwent Ward",
    "Kennet Ward",
    "Cambridge Ward",
    "Critical Care Unit",
    "Day Surgery Unit",
    "Astor Ward",
    "Westlands Unit Nursing",
    "Malton Ward",
  ];

  const demoTeams = [
    { id: 1, label: "Central London Team", value: "Central London Team" },
    { id: 2, label: "South London Team", value: "South London Team" },
  ];
  const demoEmployees = [
    { id: 1, label: "Mexxar Admin", value: "Mexxar Admin" },
    { id: 2, label: "Tom Bourne", value: "Tom Bourne" },
    { id: 3, label: "Shehara Karunarathne", value: "Shehara Karunarathne" },
  ];

  const demoEmployeeslabels = [
    "Mexxar Admin",
    "Tom Bourne",
    "Shehara Karunarathne",
  ];

  //  const dataList1 = jsonData.map(item => item.body.reportType);

  const data = {
    labels: dataList,
    datasets: [
      {
        label: "Missed Shifts By Hospital",
        // data: dummpyDataMissingBookings,    //use this for dummy data
         data: noOfMissingBookings,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: "Missed Shifts By Hospital And Ward",
        // data: dummpyDataBooking,           //use this for dummy data
        data:noOfbookings,
        backgroundColor: "rgb(54, 162, 235)",
        borderColor: "rgba(54, 162, 235, 0.2)",
        // yAxisID: 'y-axis-2',
      },
      {
        label: "Missed Shifts By Date Range",
        // data: dummpyDataMissingBookings,    //use this for dummy data
         data: noOfMissingBookings,
         backgroundColor: "rgb(244, 196, 20)",
        borderColor: "rgba(244, 196, 20, 0.2)",
        borderWidth: 1,
      },
    
    ],
  };


//   const data = {
//     labels: [missedBookingsData.startDate+"-"+missedBookingsData.endDate, successfulBookingsData.startDate+"-"+successfulBookingsData.endDate],
//     datasets: [
//         {
//             label: 'ItemCount Missed Shifts By Date Range',
//             backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(75, 192, 192, 0.2)'],
//             borderColor: ['rgba(255, 99, 132, 1)', 'rgba(75, 192, 192, 1)'],
//             borderWidth: 1,
//             data: [
//                 missedBookingsData.items[0].itemCountMissedShiftsByHospital,
//                 successfulBookingsData.items[0].itemCountMissedShiftsByHospital
//             ],
//         },
//     ],
// };
    // datasets: [
      // {
      //   label: "Missed Bookings",
      //   data: dummpyDataMissingBookings,    //use this for dummy data
      //   // data: noOfMissingBookings,
      //   backgroundColor: "rgba(255, 99, 132, 0.2)",
      //   borderColor: "rgba(255, 99, 132, 1)",
      //   borderWidth: 1,
      // },
      // {
      //   label: "Successfull Bookings",
      //   data: dummpyDataBooking,           //use this for dummy data
      //   data:noOfbookings,
      //   backgroundColor: "rgb(54, 162, 235)",
      //   borderColor: "rgba(54, 162, 235, 0.2)",
      //   // yAxisID: 'y-axis-2',
      // },
    

  
  // const data = {
  //   labels: datasets.map(dataset => dataset.label),
  //   datasets: datasets,
  // };
console.log(data);

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  var currentYear = moment().year();

  const getTeams = () => {
    axios
      .get(MexxarTeams)
      .then((res) => {
        let teamArray = [];

        forEach(res.data.body, function (item) {
          teamArray.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });

        setTeam(teamArray);
      })
      .catch((err) => {
        //console.log("cannot get specialities ",err)
        // toastCommon(true, "error");
      });
  };

  const [startDateShowOnly, setStartDateShowOnly] = useState();

  const [endDateShowOnly, setEndDateShowOnly] = useState();

  const getAllRows = () => {
    //according to the userrole change the performance data in the chart
    //currently showing the looged in user's data

    // setStartAndEndDates();

    let startTempDate;
    let endTempDate;

    // if (rangeType == "DAILY") {
    //   startTempDate = moment(startDate).format("YYYY/MM/DD");
    // } else

    if (rangeType == "MONTHLY" && range == true) {
      startTempDate = moment(dateFrom).format("YYYY/MM/DD");

      endTempDate = moment(dateTo).format("YYYY/MM/DD");
    } else if (rangeType == "DEFAULT") {
      startTempDate = moment(dateFrom).format("YYYY/MM/DD");

      endTempDate = moment(dateFrom).format("YYYY/MM/DD");
    } else if (rangeType == "WEEKLY") {
      startTempDate = moment(moment().startOf("isoWeek").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(moment().endOf("isoWeek").toDate()).format(
        "YYYY/MM/DD"
      );
    } else if (rangeType == "MONTHLY") {
      startTempDate = moment(moment().startOf("month").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(moment().endOf("month").toDate()).format(
        "YYYY/MM/DD"
      );
    } else if (rangeType == "YEARLY") {
      startTempDate = moment(moment().startOf("year").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(moment().endOf("year").toDate()).format(
        "YYYY/MM/DD"
      );
    }
    setStartDateShowOnly(startTempDate);
    setEndDateShowOnly(endTempDate);

    {
      selectedSecondSelectOption &&
        axios({
          method: "get",
          url:
            HOSTMexxar +
            "reports/filteredMissedShifts" +
            // selectedType.value +
            "?" ,
            // selectedSecondSelectOption.id,
          params: {
            clientsName:"Kingston Hospital",
            ward:"ward",
            startDate: startTempDate,
            endDate: moment(moment(endTempDate).add(1, "days")).format(
              "MM/DD/YYYY"
            ),
            dateRange: rangeType,
          },
          // url:"https://permapi.mexxar.com/reports/filteredMissedShifts?clientsName=Kingston Hospital&ward=Keats Ward&startDate=2023/11/22&endDate=2023/12/17"
        })
          .then((res) => {
            if (res.data.body.length > 0) {
              // getIndividualResults(res.data.body);
            }
          })
          .catch((err) => {
            //console.log(err)
            // ToastCommon(true, "error", addToast);
          });
    }
  };

  // console.log(
  //   "bookings---------->",
  //   noOfbookings,
  //   selectedType,
  //   selectedSecondSelectOption
  // );

  useEffect(() => {
    if (selectedType) {
      // if (selectedType.label === "By Trust") {
      //   if (selectedSecondSelectOption.id === 1) {
      //     setDataList(clients.splice(0, 3));
      //     if (rangeType == "YEARLY") {
      //       setDummpyDataBooking([345000, 255340, 435000, 125340, 323440]);
      //       setDummpyDataMissingBookings([3600, 5620, 8577, 2645, 2365]);
      //     } else if (rangeType == "MONTHLY") {
      //       setDummpyDataBooking([14520, 15622, 11452, 12255, 14585, 15699]);
      //       setDummpyDataMissingBookings([223, 325, 148, 260, 351, 133]);
      //     } else if (rangeType == "WEEKLY") {
      //       setDummpyDataBooking([
      //         4520, 5622, 1452, 2255, 4585, 5699, 5462, 5545, 5523, 5566, 2545,
      //         1554, 5562, 1455,
      //       ]);
      //       setDummpyDataMissingBookings([
      //         23, 25, 48, 60, 51, 33, 30, 88, 75, 63, 56, 60, 52, 32,
      //       ]);
      //     } else if (rangeType == "DAILY") {
      //       setDummpyDataBooking([
      //         152, 256, 145, 231, 254, 115, 155, 156, 124, 125, 322, 124, 232,
      //         214,
      //       ]);
      //       setDummpyDataMissingBookings([
      //         7, 8, 5, 10, 14, 25, 11, 45, 14, 17, 14, 55, 14, 56, 11, 5,
      //       ]);
      //     } else if (rangeType == "DEFAULT") {
      //       setDummpyDataBooking([
      //         254, 115, 155, 156, 124, 125, 322, 152, 256, 145, 231, 124, 232,
      //         214,
      //       ]);
      //       setDummpyDataMissingBookings([
      //         45, 14, 17, 14, 55, 14, 56, 11, 5, 7, 8, 5, 10, 14, 25, 11,
      //       ]);
      //     }
      //   } else if (selectedSecondSelectOption.id === 2) {
      //     setDataList(clients.splice(3, 5));
      //     if (rangeType == "YEARLY") {
      //       setDummpyDataBooking([255622, 125340, 443020]);
      //       setDummpyDataMissingBookings([4600, 8577, 4512]);
      //     } else if (rangeType == "MONTHLY") {
      //       setDummpyDataBooking([24520, 12255, 14585]);
      //       setDummpyDataMissingBookings([123, 325, 320]);
      //     } else if (rangeType == "WEEKLY") {
      //       setDummpyDataBooking([5520, 5660, 4585]);
      //       setDummpyDataMissingBookings([63, 60, 56]);
      //     } else if (rangeType == "DAILY") {
      //       setDummpyDataBooking([652, 560, 660]);
      //       setDummpyDataMissingBookings([8, 9, 10]);
      //     } else if (rangeType == "DEFAULT") {
      //       setDummpyDataBooking([300, 325, 300]);
      //       setDummpyDataMissingBookings([50, 26, 43]);
      //     }
      //   }
      // } 
     if (selectedType.label === "By Client") {
        if (selectedSecondSelectOption.id === 1) {
          setDataList(clients.splice(0, 3));
          if (rangeType == "YEARLY") {
            setDummpyDataBooking([13450, 15420, 13450, 13652]);
            setDummpyDataMissingBookings([900, 825, 650]);
          } else if (rangeType == "MONTHLY") {
            setDummpyDataBooking([600, 562, 450, 560]);
            setDummpyDataMissingBookings([32, 35, 26]);
          } else if (rangeType == "WEEKLY") {
            setDummpyDataBooking([120, 90, 85]);
            setDummpyDataMissingBookings([15, 25, 20]);
          } else if (rangeType == "DAILY") {
            setDummpyDataBooking([10, 26, 14]);
            setDummpyDataMissingBookings([7, 8, 5]);
          } else if (rangeType == "DEFAULT") {
            setDummpyDataBooking([12, 19, 20]);
            setDummpyDataMissingBookings([2, 6, 5]);
          }
        } else if (selectedSecondSelectOption.id === 2) {
          setDataList(clients.splice(4, 5));
          if (rangeType == "YEARLY") {
            setDummpyDataBooking([13450, 13652, 15420, 13450, 13650]);
            setDummpyDataMissingBookings([825, 650, 650, 456, 780]);
          } else if (rangeType == "MONTHLY") {
            setDummpyDataBooking([562, 560, 480, 460, 466]);
            setDummpyDataMissingBookings([35, 26, 42, 35, 32]);
          } else if (rangeType == "WEEKLY") {
            setDummpyDataBooking([90, 85, 78, 65, 69]);
            setDummpyDataMissingBookings([15, 20, 30, 28, 25]);
          } else if (rangeType == "DAILY") {
            setDummpyDataBooking([10, 14, 12, 16, 17]);
            setDummpyDataMissingBookings([7, 5, 3, 5, 1]);
          } else if (rangeType == "DEFAULT") {
            setDummpyDataBooking([12, 19, 17, 16, 14]);
            setDummpyDataMissingBookings([2, 5, 1, 5, 6]);
          }
        }
      } 
      // else if (selectedType.label === "By Team") {
      //   if (selectedSecondSelectOption.id === 1) {
      //     setDataList(demoEmployeeslabels.splice(0, 2));
      //     if (rangeType == "YEARLY") {
      //       setDummpyDataBooking([13450, 15420, 13450, 13652]);
      //       setDummpyDataMissingBookings([900, 825, 650]);
      //     } else if (rangeType == "MONTHLY") {
      //       setDummpyDataBooking([600, 562, 450, 560]);
      //       setDummpyDataMissingBookings([32, 35, 26]);
      //     } else if (rangeType == "WEEKLY") {
      //       setDummpyDataBooking([120, 90, 85]);
      //       setDummpyDataMissingBookings([15, 25, 20]);
      //     } else if (rangeType == "DAILY") {
      //       setDummpyDataBooking([10, 26, 14]);
      //       setDummpyDataMissingBookings([7, 8, 5]);
      //     } else if (rangeType == "DEFAULT") {
      //       setDummpyDataBooking([12, 19, 20]);
      //       setDummpyDataMissingBookings([2, 6, 5]);
      //     }
      //   } else if (selectedSecondSelectOption.id === 2) {
      //     setDataList(demoEmployeeslabels.splice(4, 5));
      //     if (rangeType == "YEARLY") {
      //       setDummpyDataBooking([13450, 13652, 15420, 13450, 13650]);
      //       setDummpyDataMissingBookings([825, 650, 650, 456, 780]);
      //     } else if (rangeType == "MONTHLY") {
      //       setDummpyDataBooking([562, 560, 480, 460, 466]);
      //       setDummpyDataMissingBookings([35, 26, 42, 35, 32]);
      //     } else if (rangeType == "WEEKLY") {
      //       setDummpyDataBooking([90, 85, 78, 65, 69]);
      //       setDummpyDataMissingBookings([15, 20, 30, 28, 25]);
      //     } else if (rangeType == "DAILY") {
      //       setDummpyDataBooking([10, 14, 12, 16, 17]);
      //       setDummpyDataMissingBookings([7, 5, 3, 5, 1]);
      //     } else if (rangeType == "DEFAULT") {
      //       setDummpyDataBooking([12, 19, 17, 16, 14]);
      //       setDummpyDataMissingBookings([2, 5, 1, 5, 6]);
      //     }
      //   }
      // }
       else if (selectedType.label === "By Ward") {
        if (selectedSecondSelectOption.id === 1) {
          setDataList(wards.splice(0, 2));
          if (rangeType == "YEARLY") {
            setDummpyDataBooking([13450, 15420, 13450, 13652]);
            setDummpyDataMissingBookings([900, 825, 650]);
          } else if (rangeType == "MONTHLY") {
            setDummpyDataBooking([600, 562, 450, 560]);
            setDummpyDataMissingBookings([32, 35, 26]);
          } else if (rangeType == "WEEKLY") {
            setDummpyDataBooking([120, 90, 85]);
            setDummpyDataMissingBookings([15, 25, 20]);
          } else if (rangeType == "DAILY") {
            setDummpyDataBooking([10, 26, 14]);
            setDummpyDataMissingBookings([7, 8, 5]);
          } else if (rangeType == "DEFAULT") {
            setDummpyDataBooking([12, 19, 20]);
            setDummpyDataMissingBookings([2, 6, 5]);
          }
        } else if (selectedSecondSelectOption.id === 2) {
          setDataList(wards.splice(4, 5));
          if (rangeType == "YEARLY") {
            setDummpyDataBooking([13450, 13652, 15420, 13450, 13650]);
            setDummpyDataMissingBookings([825, 650, 650, 456, 780]);
          } else if (rangeType == "MONTHLY") {
            setDummpyDataBooking([562, 560, 480, 460, 466]);
            setDummpyDataMissingBookings([35, 26, 42, 35, 32]);
          } else if (rangeType == "WEEKLY") {
            setDummpyDataBooking([90, 85, 78, 65, 69]);
            setDummpyDataMissingBookings([15, 20, 30, 28, 25]);
          } else if (rangeType == "DAILY") {
            setDummpyDataBooking([10, 14, 12, 16, 17]);
            setDummpyDataMissingBookings([7, 5, 3, 5, 1]);
          } else if (rangeType == "DEFAULT") {
            setDummpyDataBooking([12, 19, 17, 16, 14]);
            setDummpyDataMissingBookings([2, 5, 1, 5, 6]);
          }
        }
      }
      //  else if (selectedType.label === "By Employee") {
      //   if (selectedSecondSelectOption.id === 1) {
      //     setDataList(demoEmployeeslabels.splice(1, 3));
      //     if (rangeType == "YEARLY") {
      //       setDummpyDataBooking([13450, 15420, 13450, 13652]);
      //       setDummpyDataMissingBookings([900, 825, 650]);
      //     } else if (rangeType == "MONTHLY") {
      //       setDummpyDataBooking([600, 562, 450, 560]);
      //       setDummpyDataMissingBookings([32, 35, 26]);
      //     } else if (rangeType == "WEEKLY") {
      //       setDummpyDataBooking([120, 90, 85]);
      //       setDummpyDataMissingBookings([15, 25, 20]);
      //     } else if (rangeType == "DAILY") {
      //       setDummpyDataBooking([10, 26, 14]);
      //       setDummpyDataMissingBookings([7, 8, 5]);
      //     } else if (rangeType == "DEFAULT") {
      //       setDummpyDataBooking([12, 19, 20]);
      //       setDummpyDataMissingBookings([2, 6, 5]);
      //     }
      //   } else if (selectedSecondSelectOption.id === 2) {
      //     setDataList(demoEmployeeslabels.splice(4, 5));
      //     if (rangeType == "YEARLY") {
      //       setDummpyDataBooking([13450, 13652, 15420, 13450, 13650]);
      //       setDummpyDataMissingBookings([825, 650, 650, 456, 780]);
      //     } else if (rangeType == "MONTHLY") {
      //       setDummpyDataBooking([562, 560, 480, 460, 466]);
      //       setDummpyDataMissingBookings([35, 26, 42, 35, 32]);
      //     } else if (rangeType == "WEEKLY") {
      //       setDummpyDataBooking([90, 85, 78, 65, 69]);
      //       setDummpyDataMissingBookings([15, 20, 30, 28, 25]);
      //     } else if (rangeType == "DAILY") {
      //       setDummpyDataBooking([10, 14, 12, 16, 17]);
      //       setDummpyDataMissingBookings([7, 5, 3, 5, 1]);
      //     } else if (rangeType == "DEFAULT") {
      //       setDummpyDataBooking([12, 19, 17, 16, 14]);
      //       setDummpyDataMissingBookings([2, 5, 1, 5, 6]);
      //     }
      //   }
      // }
    }
  }, [selectedSecondSelectOption, rangeType]);

  const getIndividualResults = (response) => {
    let missingBookings = [];
    let successfulBookings = [];
    let xAxis = [];

    forEach(response, function (record) {
      missingBookings.push(record.missingBookings);
      successfulBookings.push(record.successfulBookings);

      xAxis.push(record.name);
    });

    setNoOfBookings(successfulBookings);
    setNoOfMissingBookings(missingBookings);
    setDataList(xAxis);
  };

  const getTrusts = () => {
    axios
      .get(MexxarApiTrust)
      .then((res) => {
        let array = [];
        forEach(res.data.body, function (item) {
          array.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });
        setTrust(array);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const getEmployees = () => {
    let names = [];
    reduxEmployees.forEach((item) => {
      names.push({
        id: item.id,
        label: item.firstName + " " + item.lastName,
        value: item.firstName + " " + item.lastName,
      });
    });
    setEmployees(names);
  };

  const getClients = () => {
    let names = [];
    reduxClients.forEach((item) => {
      names.push({
        id: item.id,
        label: item.name,
        value: item.name,
      });
    });
    setClients(names);
  };
  const getWards = () => {
    let names = [];
    reduxWards.forEach((item) => {
      names.push({
        id: item.id,
        label: item.name,
        value: item.name,
      });
    });
    setWards(names);
  };

  useEffect(() => {
    if (!reduxEmployees) {
      store.dispatch(employeesGetAction());
    }
    if (!reduxClients) {
      store.dispatch(clientsGetAction());
    }
    if (!reduxWards) {
      store.dispatch(departmentsGetAction());
    }


    getTrusts();
    getTeams();
  }, []);

  useEffect(() => {
    
    getIndividualResults();
   
  }, [clients,wards]);


  useEffect(() => {
    if (reduxEmployees) {
      getEmployees();
    }
  }, [reduxEmployees]);

  useEffect(() => {
    if (reduxClients) {
      getClients();
    }
  }, [reduxClients]);

  useEffect(() => {
    if (reduxWards) {
      getWards();
    }
  }, [reduxWards]);

  const chooseTypeForSecondSelectOptions = (key) => {
    let seconOptionLable = key.label;
    switch (key.value) {
      case "trust":
        setSecondSelectOption(trust.slice(0, 2));
        setSelectedSecondSelectOption(trust[0]);
        break;
      case "client":
        setSecondSelectOption(clients.slice(0));
        setSelectedSecondSelectOption(clients[0]);

        break;
        case "ward":
          setSecondSelectOption(wards.slice(0));
          setSelectedSecondSelectOption(wards[0]);
  
          break;
      case "team":
        setSecondSelectOption(demoTeams);
        setSelectedSecondSelectOption(demoTeams[0]);
        break;
      case "employee":
        setSecondSelectOption(demoEmployees);
        setSelectedSecondSelectOption(demoEmployees[0]);

        break;

      default:
        break;
    }
    return seconOptionLable;
  };

  useDidMountEffect(() => {
    getAllRows();
  }, [selectedSecondSelectOption, dateFrom, dateTo, rangeType]);

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#6A6767",
    }),
  };

  useDidMountEffect(() => {
    if (selectedType) {
      chooseTypeForSecondSelectOptions(selectedType);
    }
  }, [selectedType]);

  return (
    <div className="card">
      <div className="card-body">
        <div className="header">
          <Row>
            <Col>
              <Label>Bookings Reports </Label>
              <ButtonGroup
                className="ml-3"
                size="sm"
                style={{
                  zIndex: 0,
                }}
              >
                <Button
                  disabled={isNull(selectedSecondSelectOption) ? true : false}
                  onClick={() => {
                    setRangeType("DEFAULT");
                    setRange(false);
                  }}
                  className={rangeType === "DEFAULT" && "active"}
                >
                  Daily
                </Button>
                <Button
                  disabled={isNull(selectedSecondSelectOption) ? true : false}
                  onClick={() => {
                    setRangeType("WEEKLY");
                    setRange(false);
                  }}
                  className={rangeType === "WEEKLY" && "active"}
                >
                  Weekly
                </Button>
                <Button
                  disabled={isNull(selectedSecondSelectOption) ? true : false}
                  onClick={() => {
                    setRangeType("MONTHLY");
                    setRange(false);
                  }}
                  className={rangeType === "MONTHLY" && "active"}
                >
                  Monthly
                </Button>
                <Button
                  disabled={isNull(selectedSecondSelectOption) ? true : false}
                  onClick={() => {
                    setRangeType("YEARLY");
                    setRange(false);
                  }}
                  className={rangeType === "YEARLY" && "active"}
                >
                  Yearly
                </Button>
                <Button
                  disabled={isNull(selectedSecondSelectOption) ? true : false}
                  onClick={() => {
                    setRangeType("DEFAULT");
                    setRange(true);
                  }}
                  className={rangeType === "DEFAULT" && "active"}
                >
                  Range
                </Button>

                {/* <Button >Yearly</Button> */}
              </ButtonGroup>
              {rangeType != "DEFAULT" && range == false ? (
                <>
                  <Label className="mx-3">From : {startDateShowOnly}</Label>
                  <Label className="mx-3">To : {endDateShowOnly} </Label>
                </>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={4} lg={4} xl={4}>
              <Label>Type</Label>
              {isNull(selectedType) ? (
                <Label className="mx-3">
                  * Please select a type to load the chart
                </Label>
              ) : null}

              <Select
                // isMulti
                name="candidateSpecialityId"
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(data) => setSelectedType(data)}
                options={type}
                styles={colourStyles}
              />
            </Col>
            <Col sm={12} md={4} lg={4} xl={4}>
              <Label>{selectedType && selectedType.label} </Label>
              {isNull(secondSelectOption) ? (
                <Label className="mx-3">
                  * Please select a second option to load the chart
                </Label>
              ) : null}

              <Select
                // isMulti
                name="candidateSpecialityId"
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedSecondSelectOption}
                onChange={(data) => setSelectedSecondSelectOption(data)}
                options={secondSelectOption}
                styles={colourStyles}
              />
            </Col>
            {/* <Col sm={12} md={4} lg={4} xl={4}>
              <Label>{selectedType && selectedType.label} </Label>
              {isNull(secondSelectOption) ? (
                <Label className="mx-3">
                  * Please select a second option to load the chart
                </Label>
              ) : null}

              <Select
                // isMulti
                name="candidateSpecialityId"
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedSecondSelectOption}
                onChange={(data) => setSelectedSecondSelectOption(data)}
                options={secondSelectOption}
                styles={colourStyles}
              />
            </Col> */}
            {rangeType == "DEFAULT" || range == true ? (
              <Col sm={12} md={rangeType == "DEFAULT" ? 4 : 2}>
                {rangeType == "DEFAULT" ? (
                  <Label for="exampleDate">Date</Label>
                ) : (
                  <Label for="exampleDate">Start Date</Label>
                )}

                <Input
                  type="date"
                  name="dateFrom"
                  id="dateFrom"
                  onChange={(e) => setDateFrom(e.target.value)}
                  placeholder=""
                  defaultValue={moment(
                    moment(new Date()).subtract(1, "days")
                  ).format("YYYY-MM-DD")}
                />
              </Col>
            ) : null}

            {range == true ? (
              <Col sm={12} md={2} lg={2} xl={2}>
                <Label for="exampleDate">End Date</Label>
                <Input
                  type="date"
                  name="dateTo"
                  id="dateTo"
                  onChange={(e) => setDateTo(e.target.value)}
                  placeholder=""
                  defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                />
              </Col>
            ) : null}
          </Row>

          <div className="links"></div>
        </div>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    reduxClients: state.initialDataGetReducer.clients,
    reduxEmployees: state.initialDataGetReducer.employees,
    reduxWards: state.initialDataGetReducer.departments,
  };
}

export default connect(mapStateToProps, {})(MissingBookings);
