import { combineReducers } from "redux";
import authReducer from "./authReducer";
import headhunterReducer from "./headhunterReducer";
import appSettingsReducer from "./appSettingsReducer";
import ChatReducer from "./chatReducer";
import chatActiveIdReducer from "./chatActiveIdReducer";
import chatDbPostReducer from "./chatDbPostReducer";
import chatDbGetReducer from "./chatDbGetReducer";
import changeThemeReducer from "./changeThemeReducer";
import heahunterFilteredReducer from "./heahdunterFilteredReducer";
import notificationReducer from "./notificationReducer";
import chatNewNotificationReducer from "./chatNewNotificationReducer";

import initialDataGetReducer from "./initialDataGetReducer";
import emailServerReducer from "./emailServerReducer";
import saveFiledDataReducer from "./saveFiledDataReducer"; 
import locallySaveTimesheetsReducer from "./locallySaveTimesheetsReducer";
import locallySaveMobileTimesheetsReducer from "./locallySaveMobileTimesheetsReducer";
// import softPhoneIntegrationReducer from "./softPhoneIntegrationReducer";
// import { reducer } from "react-hot-toast/dist/core/store";
import reducers from "./softPhoneIntegrationReducer";
// import callHistoryReducer from "./callHistoryReducer";
import callHistoryReducer from './callHistoryReducer';
import { configureStore } from '@reduxjs/toolkit';
 import callSummaryReducer from './callSummaryReducer';
// import { lo } from '@reduxjs/toolkit';
// import callStateReducer from './callStateSlice';
import customHeaderReducer from './HorizonReducer';
import authReducer1 from './loginUserReducer';


// import websocketReducer from "./softPhoneIntegrationReducer";

export default combineReducers({
  auth: authReducer,
  headhunter: headhunterReducer,
  appSettings: appSettingsReducer,
  ChatRedux: ChatReducer,
  theme: changeThemeReducer,
  chatDbGetReducer: chatDbGetReducer,
  chatDbPostReducer: chatDbPostReducer,
  heahunterFiltered: heahunterFilteredReducer,
  chatActiveIdReducer: chatActiveIdReducer,
  chatNewNotificationReducer: chatNewNotificationReducer,
  notificationReducer: notificationReducer,
  initialDataGetReducer: initialDataGetReducer,
  emailServerReducer: emailServerReducer,
  fieldData: saveFiledDataReducer,
  timesheets: locallySaveTimesheetsReducer,
  websocketData : reducers,
  callHistoryReducer : callHistoryReducer,
  callSummaryReducer:callSummaryReducer,
  customHeaderReducer:customHeaderReducer,
  authReducer1:authReducer1,
  locallySaveTimesheetsReducer:locallySaveTimesheetsReducer,
  mobileTimesheets:locallySaveMobileTimesheetsReducer,
  // callState: callStateReducer,
});
