import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faTimes,
  faExpandAlt,
  faFileAlt,
  faTimesCircle,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import store from "../../redux/store";
import { useDropzone } from "react-dropzone";
import { split, forEach, filter } from "lodash";
import CreatableSelect from "react-select/creatable";
import { useToasts } from "react-toast-notifications";
import {
  REGISTER_CANDIDATE,
  HostMexxarEmail,
  CommonEmailReplySignatureName,
  CommonEmailReplySignaturePosition,
  CommonEmailReplySignatureEmail,
  CompanyPhoneNumber,
  CompanyName,
  CompanyAddress,
  EmailSignatureFooter,
  CompanyWebsite,
} from "../../configs/api-config";
import useCandidateList from "../../customHooks/useCandidateList";
import usePrepareEmployee from "../../customHooks/usePrepareEmployee";
import {
  Button,
  Label,
  FormGroup,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownItem,
} from "reactstrap";
import RichTextEditor from "./consultantEmail/emailForm/RichtextEditor";
import ModalComponent from "../../components/Modal/modalComponent";
import ToastCommon from "../../components/toastCommon";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { replyEmail, sendEmail } from "./graph";
import DropDown from "../../components/DropDown";

function EmailFormOutlook(props) {
  const {
    closeModal,
    composeType,
    emailServerReducer,
    from,
    dateAndTime,
    subjectIncomming,
    cc,
    bcc,
    to,
    emailBody,
    user,
    email,
  } = props;

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
    padding: 5,
  };
  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const thumb = {
    position: "relative",
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbButton = {
    position: "absolute",
    right: 10,
    bottom: 10,
    background: "rgba(0,0,0,.8)",
    color: "#fff",
    border: 0,
    borderRadius: ".325em",
    cursor: "pointer",
  };

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();
  const { employees } = usePrepareEmployee();

  const [files, setFiles] = useState([]);
  const [sending, setSending] = useState(false);
  const [subject, setSubject] = useState("");
  const [heading, setHeading] = useState("");
  const [bodyTags, setBodyTags] = useState("");
  const [toggleCC, setToggleCC] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const [toggleBCC, setToggleBCC] = useState(false);
  const [employeeRecipients, setEmployeeRecipients] = useState(null);
  const [candidateRecipients, setCandidateRecipients] = useState(null);
  const [uploadAcceptedFiles, setUploadAcceptedFiles] = useState([]);

  const [ccRecipients, setCCRecipients] = useState([]);
  const [fileOversize, setFileOversize] = useState(false);
  const [fileTypeofUpload, setFileTypeofUpload] = useState("");
  const [selectedBccEmail, setSelectedBccEmail] = useState([]);
  const [showHideFileUpload, setShowHideFileUpload] = useState(false);
  const [selectedCCemployees, setSelectedCCemployees] = useState([]);
  const [sendingBody, setSendingBody] = useState(null);
  const [originReplyRecipientRemoved, setOriginReplyRecipientRemoved] =
    useState(false);

  const { instance, accounts } = useMsal();

  const selectedAccount = JSON.parse(
    localStorage.getItem("selectedOutlookAccount")
  );

  const subjectHeadingPlacing = (type) => {
    switch (type) {
      case "NEW":
        setSubject("");
        setHeading("New Message");
        setBodyTags("");
        break;
      case "FORWARD":
        setSubject("Fwd: " + email?.subject);
        setHeading("Fwd: " + email?.subject);

        const toRecipients = to
          .map(
            (recipient) =>
              `${recipient?.emailAddress?.name} ${"<"} ${
                recipient?.emailAddress?.address
              }${">"}`
          )
          .join(", ");
        if (emailBody.contentType == "text") {
          setBodyTags(
            `\r\n\r\n---------- Write above this line -----------\r\n---------- Forwarded message ----------\r\nFrom: ${
              from?.emailAddress?.address
            }\r\nDate: ${moment(dateAndTime).format(
              "ddd DD YYYY hh:mm a"
            )}\r\nSubject: ${subjectIncomming}\r\nTo: ${toRecipients}\r\n\r\n`
          );
        } else {
          setBodyTags(`
        <br/>
        <br/>
        <span>---------- Write above this line -----------</span> 
        <br/>
        <span>---------- Forwarded message ----------</span>
        <br/>
        <small>From: ${from?.emailAddress?.address}</small><br/>
        <small>Date: ${moment(dateAndTime).format(
          "ddd DD YYYY hh:mm a"
        )}</small><br/>
        <small>Subject: ${subjectIncomming}</small><br/>
        <small>To: ${toRecipients}</small><br/><br/>
      `);
        }
        break;

      case "REPLY":
        setSubject("Re: " + email?.subject);
        setHeading("Re: " + email?.subject);

        if (emailBody.contentType == "text") {
          setBodyTags(
            `\r\n\r\n-----Reply above this line-----\r\nOn ${moment(
              dateAndTime
            ).format("ddd DD YYYY hh:mm a")} from ${
              from?.emailAddress?.address
            } "wrote:"\r\n\r\n`
          );
        } else {
          setBodyTags(
            `<br/>
          <br/>
          <span>-----Reply above this line-----<span> 
          <br/>
          <small>On ${moment(dateAndTime).format("ddd DD YYYY hh:mm a")} from ${
              from?.emailAddress?.address
            } "wrote:"</small> 
         <br/>  
         <br/>
         `
          );
        }

        break;
      case "REPLY_ALL":
        setSubject("Re: " + email?.subject);
        setHeading("Re: " + email?.subject);
        if (emailBody.contentType == "text") {
          setBodyTags(
            `\r\n\r\n-----Reply above this line-----\r\nOn ${moment(
              dateAndTime
            ).format("ddd DD YYYY hh:mm a")} from ${
              from?.emailAddress?.address
            } "wrote:"\r\n\r\n`
          );
        } else {
          setBodyTags(
            `<br/>
            <br/>
            <span>-----Reply above this line-----<span> 
            <br/>
            <small>On ${moment(dateAndTime).format(
              "ddd DD YYYY hh:mm a"
            )} from ${from?.emailAddress?.address} "wrote:"</small> 
           <br/>  
           <br/>
           `
          );
        }
        break;

      default:
        break;
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    let splitedArray = split(acceptedFiles[0].type, "/");
    let document_TYPE = splitedArray[1];
    setFileTypeofUpload(document_TYPE);

    let totalSize = 0;
    forEach(acceptedFiles, function (file) {
      totalSize = file.size + totalSize;
    });
    // files over 50 mb (Total file size)cannot upload
    if (totalSize > 50000000) {
      setFileOversize(true);
    } else {
      setFileOversize(false);
    }

    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const clearFilesStates = () => {
    setFiles([]);
    setShowHideFileUpload(false);
  };

  const sendingEmailTypeSelector = (body) => {
    switch (composeType) {
      case "NEW":
        RequestEmailSend(body);
        break;
      case "FORWARD":
        RequestEmailReply(body, "forward");
        break;
      case "REPLY":
        RequestEmailReply(body, "reply");
        break;
      case "REPLY_ALL":
        RequestEmailReply(body, "reply");
        break;

      default:
        break;
    }
  };

  function readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
    });
  }

  async function processAttachments() {
    const attachments = [];

    if (uploadAcceptedFiles.length > 0) {
      for (const item of uploadAcceptedFiles) {
        const base64Data = await readFileAsBase64(item);
        attachments.push({
          "@odata.type": "#microsoft.graph.fileAttachment",
          name: item.name,
          contentType: item.type,
          contentBytes: base64Data,
        });
      }
    }

    return attachments;
  }

  function RequestEmailReply(body, replyType) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: selectedAccount ? selectedAccount : accounts[0],
      })
      .then(async (response) => {
        const attachments = await processAttachments();
        //removing the previous email from the body
        //we added the previous email because we needed to display the previouse emails to the user.
        const startIndex = body.body.indexOf("-----Reply above this line-----");

        let trimmedContent;
        if (composeType == "FORWARD") {
          trimmedContent = body.body;
        } else {
          trimmedContent = body.body.substring(0, startIndex);
        }

        let toRecipients = [];
        let ccRecipients = [];
        let bccRecipients = [];

        let tempCCSelected = [];
        let tempBCCSelected = [];
        let tempCandidateSelected = [];
        let tempEmployeeSelected = [];
        let tempFrom = [from];

        tempCCSelected = selectedCCemployees
          ? selectedCCemployees.map((recipient) => ({
              emailAddress: {
                address: recipient.value,
                name: recipient.label,
              },
            }))
          : [];

        tempBCCSelected = selectedBccEmail
          ? selectedBccEmail.map((recipient) => ({
              emailAddress: {
                address: recipient.value,
                name: recipient.label,
              },
            }))
          : [];

        tempCandidateSelected = candidateRecipients
          ? candidateRecipients.map((recipient) => ({
              emailAddress: {
                address: recipient.value,
                name: recipient.label,
              },
            }))
          : [];

        tempEmployeeSelected = employeeRecipients
          ? employeeRecipients.map((recipient) => ({
              emailAddress: {
                address: recipient.value,
                name: recipient.label,
              },
            }))
          : [];

        if (composeType == "REPLY_ALL") {
          ccRecipients = (ccRecipients || []).concat(tempCCSelected || []);
          bccRecipients = (bcc || []).concat(tempBCCSelected || []);
        } else {
          ccRecipients = tempCCSelected;
          bccRecipients = tempBCCSelected;
        }

        if (composeType == "FORWARD" || originReplyRecipientRemoved) {
          toRecipients = (tempCandidateSelected || []).concat(
            tempEmployeeSelected || []
          );
        } else {
          toRecipients = (tempFrom || []).concat(
            tempCandidateSelected || [],
            tempEmployeeSelected || []
          );
        }

        //Signature for the reply

        let sendingPersonal = user
          ? user
          : {
              name: `${CommonEmailReplySignatureName} | ${CommonEmailReplySignaturePosition}`,
              email: `${CommonEmailReplySignatureEmail}`,
              companyPhoneNumber: `${CompanyPhoneNumber}`,
            };
        const signature = `
      <div>
         <p style="margin: 0; line-height: 1.5;">Best regards,</p>
          <p style="margin: 0; line-height: 1.5;">${
            sendingPersonal?.firstName + " " + sendingPersonal?.lastName
          }</p>
          <p style="margin: 0; line-height: 1.5;">${CompanyName} | ${CompanyAddress}</p>
          <p style="margin: 0; line-height: 1.5;">${
            sendingPersonal?.email
          } | ${CompanyWebsite}</p>
          <p style="margin: 0; line-height: 1.5;">${
            sendingPersonal?.companyPhoneNumber
          }</p>
          <br/>
          <small style="text-align: justify;">
         ${EmailSignatureFooter}
      </small>
      </div>
    `;
        axios
          .post(
            "https://graph.microsoft.com/v1.0/me/messages/" +
              email.id +
              "/" +
              replyType,
            {
              message: {
                toRecipients: toRecipients,
                ccRecipients: ccRecipients,
                bccRecipients: bccRecipients,
                attachments: attachments,
              },
              comment: `${trimmedContent}<br><br>${signature}`,
            },

            {
              headers: {
                Authorization: "Bearer " + response?.accessToken,
              },
            }
          )
          .then((response) => {
            ToastCommon(true, "sent", addToast);
            closeModal();
          })
          .catch((error) => {
            ToastCommon(true, "error", addToast);
          });
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  }

  //const sendEmail = (data) => {
  // let recipients = [];
  // let fromEmailSplit = split(from, /[<>]/);
  // // let fromEmail = fromEmailSplit[1];
  // if (composeType == "REPLY") {
  //   let replyRecipient = [{ value: fromEmailSplit }];
  //   recipients = employeeRecipients
  //     .concat(candidateRecipients)
  //     .concat(replyRecipient);
  // } else {
  //   recipients = employeeRecipients.concat(candidateRecipients);
  // }
  // let recipientsList = [];
  // if (recipients.length > 0) {
  //   for (let i = 0; i < recipients.length; i++) {
  //     recipientsList.push(recipients[i].value);
  //   }
  // }
  // let ccEmailList = [];
  // if (selectedCCemployees) {
  //   for (let i = 0; i < selectedCCemployees.length; i++) {
  //     ccEmailList.push(selectedCCemployees[i].value);
  //   }
  // }
  // let bccEmail = [];
  // if (selectedBccEmail) {
  //   for (let i = 0; i < selectedBccEmail.length; i++) {
  //     bccEmail.push(selectedBccEmail[i].value);
  //   }
  // }
  // const fd = new FormData();
  // fd.append("subject", subject);
  // fd.append("body", data.body);
  // fd.append("mailto", recipientsList);
  // fd.append("mailcc", ccEmailList);
  // fd.append("mailbcc", bccEmail);
  // //    fd.append("mailBody", prepareData);
  // // fd.append(
  // //   "mailBody",
  // //   new Blob([JSON.stringify(prepareData)], {
  // //     type: "application/json",
  // //   })
  // // );
  // if (uploadAcceptedFiles.length > 0) {
  //   forEach(uploadAcceptedFiles, function (file) {
  //     fd.append("attachment", file);
  //   });
  // }
  // setSending(true);
  // axios
  //   .post(HostMexxarEmail + "email/newmail", fd)
  //   .then(function (response) {
  //     if (response.data.status === "success") {
  //       ToastCommon(true, "sent", addToast);
  //       clearFilesStates();
  //       closeModal(false);
  //     }
  //     setSending(false);
  //   })
  //   .catch(function (error) {
  //     setSending(false);
  //     ToastCommon(true, "error", addToast);
  //     console.log("error", error);
  //   });
  //};

  function RequestEmailSend(body) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: selectedAccount ? selectedAccount : accounts[0],
      })
      .then((response) => {
        let recipients = [];

        recipients = (employeeRecipients || []).concat(
          candidateRecipients || []
        );

        sendEmail(
          response?.accessToken,
          recipients,
          selectedCCemployees,
          selectedBccEmail,
          subject,
          body?.body,
          uploadAcceptedFiles,
          user
        )
          .then((response) => {
            // clearForm();
            closeModal();
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  }

  const thumbs = (file, id) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="" />
      </div>
    </div>
  );

  /*get candiates list */
  const MexxarCandidateApi = REGISTER_CANDIDATE;

  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  const [candidates, setCandidates] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [queryCandidate, setQueryCandidate] = useState("");

  let url = MexxarCandidateApi + "/status/" + filterCustom.value;

  const { loading, error, rows, hasMore } = useCandidateList(
    url,
    filterCustom,
    queryCandidate,
    pageNumber
  );

  const getCandidates = () => {
    let listOfCandidates = [];
    for (let i = 0; i < rows.length; i++) {
      listOfCandidates.push({
        value: rows[i].email,
        label: `${rows[i].firstName} ${rows[i].lastName}`,
        id: rows[i].id,
        // candidate: rows[i],
      });
    }

    if (queryCandidate != "") {
      setCandidates(listOfCandidates);
    } else {
      setCandidates([]);
    }
  };

  useEffect(() => {
    getCandidates();
  }, [rows]);

  const handleInputChange = (e) => {
    setQueryCandidate(e);
  };

  /*remove uploded files function*/
  const removeFiles = (name) => {
    let newUplofilesads = filter(uploadAcceptedFiles, (file) => {
      return file.name !== name;
    });

    setUploadAcceptedFiles(newUplofilesads);
  };

  const uploadFiles = () => {
    let temp_1 = uploadAcceptedFiles;
    setUploadAcceptedFiles((oldArray) => [...files, ...temp_1]);
  };

  const removeCCrecipients = (data) => {
    let replyAllToRecipentList = ccRecipients?.filter(
      (item) => item.emailAddress.address !== data
    );
    setCCRecipients(replyAllToRecipentList);
  };

  const setInitialCCRecipients = () => {
    if (composeType == "REPLY_ALL") {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: selectedAccount ? selectedAccount : accounts[0],
        })
        .then((response) => {
          // Get the value from session storage
          const userName = response.account.username;
          let replyAllToRecipentList = to?.filter(
            (item) => item.emailAddress.address !== userName
          );

          let ccRecipients = (cc || []).concat(replyAllToRecipentList || []);

          setCCRecipients(ccRecipients);
        })
        .catch((error) => console.log(error));
    } else {
      setCCRecipients([]);
    }
  };
  useEffect(() => {
    subjectHeadingPlacing(composeType);
    setInitialCCRecipients();
  }, []);

  function RequestEmailCreateDraft() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: selectedAccount ? selectedAccount : accounts[0],
      })
      .then((response) => {
        console.log("Token acquired successfully Create Draft Email");

        const message = {
          subject: "Assignmet reports",
          body: {
            contentType: "HTML",
            content: "Have you submitted your Assignment reports yet?",
          },
          toRecipients: [
            {
              emailAddress: {
                address: "nipunikaherath9@gmail.com",
              },
            },
          ],
        };

        axios
          .post("https://graph.microsoft.com/v1.0/me/messages", message, {
            headers: {
              Authorization: "Bearer " + response.accessToken,
            },
          })
          .then((response) => {
            console.log("API response:", response.data.id);
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  // const toggleCloseModal = () => {
  //   if (sendingBody)
  //   closeModal(false);

  // };

  return (
    <div className=" aside-deck mb-1 mr-2">
      <div
        className={` alert p-0 m-0 b-a ${minimize && "card-hide-body"}`}
        style={{
          width: minimize && "300px",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <div className="card-header d-flex align-items-center bg-body">
          <div className="flex h-1x mx-1 mail-to">{heading}</div>
          <div className="ml-auto no-wrap">
            {minimize ? (
              <FontAwesomeIcon
                className="btn-raised mx-2 pointer"
                onClick={() => setMinimize(!minimize)}
                icon={faExpandAlt}
              />
            ) : (
              <FontAwesomeIcon
                className="btn-raised mx-2 pointer"
                onClick={() => setMinimize(!minimize)}
                icon={faMinus}
              />
            )}
            <FontAwesomeIcon
              className="btn-raised btn-wave mx-1 pointer"
              onClick={() => closeModal(false)}
              icon={faTimes}
            />{" "}
          </div>
        </div>

        <div
          className="card-body flex-container wrap "
          style={{
            backgroundColor: "whitesmoke",
            // height: "70vh",
            width: "50vw",
          }}
        >
          <div>
            <div className="mb-2  ">
              <CreatableSelect
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                placeholder="Consultant Recipients"
                isClearable
                isMulti
                options={employees}
                value={employeeRecipients}
                onChange={(data) => setEmployeeRecipients(data)}
              />
            </div>
            <div className="mb-2  ">
              <CreatableSelect
                id="CandidateRecipients"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                placeholder="Candidate Recipients"
                onInputChange={handleInputChange}
                value={candidateRecipients}
                onChange={(data) => setCandidateRecipients(data)}
                isLoading={loading}
                options={candidates}
                isClearable
                isMulti
              />
              <UncontrolledTooltip target="CandidateRecipients">
                Please type the name of the candidate
              </UncontrolledTooltip>
            </div>
            <div className=" mb-2 d-flex flex-row">
              <input
                type="text"
                className="form-control text-dark mr-2"
                placeholder="Subject"
                defaultValue={subject}
                onChange={(e) => setSubject(e.target.value)}
              />

              <Button
                onClick={() => setToggleCC(!toggleCC)}
                className="mx-2"
                style={{ width: "70px" }}
              >
                {selectedCCemployees && selectedCCemployees.length} CC
              </Button>
              <Button
                onClick={() => setToggleBCC(!toggleBCC)}
                style={{ width: "70px" }}
              >
                {selectedBccEmail && selectedBccEmail.length} BCC
              </Button>
            </div>

            {(composeType == "REPLY" || composeType == "REPLY_ALL") &&
              !originReplyRecipientRemoved && (
                <>
                  <div className="d-flex">
                    <small className="text-blue">Reply To :</small>
                    <small className="ml-3 btn-raised no-shadow">
                      {from?.emailAddress?.address}{" "}
                      <FontAwesomeIcon
                        id="replyRemove"
                        className="ml-1  no-shadow pointer"
                        icon={faTimes}
                        onClick={() => setOriginReplyRecipientRemoved(true)}
                      />
                    </small>
                  </div>

                  <UncontrolledTooltip target={"replyRemove"}>
                    Do you want to change the reply address ?
                  </UncontrolledTooltip>
                </>
              )}
            {composeType == "REPLY_ALL" && ccRecipients.length ? (
              <div className="d-flex">
                <DropDown label="CC: show recipients">
                  <div
                    className="ml-3 "
                    style={{
                      zIndex: 99999, // Ensure it sits on top of other content
                    }}
                  >
                    {ccRecipients
                      ? ccRecipients.map((item, id) => {
                          return (
                            <small className="ml-3 btn-raised no-shadow">
                              {item?.emailAddress?.name} &nbsp;
                              {`${"<"}`}
                              {item.emailAddress.address}
                              {`${">"}`}
                              <FontAwesomeIcon
                                className="ml-1  no-shadow pointer"
                                icon={faTimes}
                                onClick={() =>
                                  removeCCrecipients(item?.emailAddress?.name)
                                }
                              />
                            </small>
                          );
                        })
                      : null}
                  </div>
                </DropDown>
              </div>
            ) : null}

            {(composeType == "REPLY" || "REPLY_ALL") &&
            originReplyRecipientRemoved &&
            !candidateRecipients &&
            !employeeRecipients ? (
              <Label className="text-danger">
                Reply recipient removed.Please add a new email to send the email
              </Label>
            ) : null}
            {toggleCC && (
              <div className="form-row">
                <CreatableSelect
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  type="select"
                  isMulti
                  isClearable
                  placeholder="CC"
                  name="ccEmail"
                  className="col-12"
                  value={selectedCCemployees}
                  onChange={(data) => setSelectedCCemployees(data)}
                  options={employees}
                  innerRef={register({
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email address!",
                    },
                  })}
                />
                {errors.email && <p>{errors.email.message}</p>}
              </div>
            )}

            {toggleBCC && (
              <div className="form-row mt-2">
                <CreatableSelect
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  type="select"
                  isMulti
                  isClearable
                  placeholder="bcc"
                  name="bccEmail"
                  className="col-12"
                  value={selectedBccEmail}
                  onChange={(data) => setSelectedBccEmail(data)}
                  options={employees}
                  innerRef={register({
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email address!",
                    },
                  })}
                />
                {errors.email && <p>{errors.email.message}</p>}
              </div>
            )}

            <div className="flex-wrap flex-column mt-2">
              <RichTextEditor
                editorActive={() => {
                  setToggleCC(false);
                  setToggleBCC(false);
                }}
                // fileOversize={fileOversize}
                // files={files}
                composeType={composeType}
                clearFilesStates={() => clearFilesStates()}
                showHideFileUpload={(data) => setShowHideFileUpload(data)}
                bodyTags={bodyTags}
                sendingEmailBody={(data) => {
                  sendingEmailTypeSelector(data);
                  setSendingBody(data);
                }}
                emailBody={emailBody}
                sending={sending}
              />
            </div>

            <div className="flex-wrap flex-column mt-2">
              <div
                style={{
                  overflowY: "auto",
                  maxHeight: "50px",
                }}
              >
                {uploadAcceptedFiles &&
                  uploadAcceptedFiles.map((file, id) => {
                    return (
                      <div
                        key={id}
                        className="m-1 p-1 d-flex justify-content-between align-items-center text-align-center"
                        style={{
                          backgroundColor: "#dad6d6",
                          borderRadius: "5px",
                        }}
                      >
                        <div className="ml-2 text-dark ">{file.name}</div>
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-dark mr-2 pointer btn-raised"
                          style={{ boxShadow: "none" }}
                          onClick={() => removeFiles(file.name)}
                        />
                      </div>
                    );
                  })}
              </div>

              <ModalComponent
                show={showHideFileUpload}
                header="Upload your documents"
                closeModal={() => {
                  clearFilesStates();
                }}
              >
                <div
                  className={"d-flex justify-content-center align-items-center"}
                >
                  <FormGroup row>
                    <Col sm={12}>
                      {/* <Label>Please upload excel file to search </Label> */}

                      <div
                        className="d-flex justify-content-center align-items-center  "
                        style={{
                          border: "solid 2px  ",
                          borderRadius: "2px",
                          borderColor: "#007bff",
                          width: "460px",
                          height: files.length > 0 ? "50px" : "260px",
                        }}
                        {...getRootProps()}
                      >
                        <input id="dropZone" {...getInputProps()} />

                        <>
                          {isDragActive ? (
                            <p className="m-3 ">Drop the file here ...</p>
                          ) : (
                            <p className="m-3">
                              Drag 'n' drop excel file here, or click to select
                              file
                            </p>
                          )}
                        </>
                      </div>
                      <div>
                        {files.length > 0 && !fileOversize ? (
                          <div
                            className="d-flex justify-content-center align-items-center p-2"
                            style={{ overflowX: "auto" }}
                          >
                            {files.map((file, id) => {
                              let splitedArray = split(file.type, "/");
                              let document_TYPE = splitedArray[1];

                              if (document_TYPE == "pdf") {
                                return (
                                  <div
                                    key={id}
                                    className="justify-content-center d-flex  flex-column align-items-center p-2"
                                  >
                                    <div>
                                      <FontAwesomeIcon
                                        icon={faFilePdf}
                                        className="fa-5x "
                                      />
                                    </div>
                                    <div>
                                      <small>{file.name} </small>
                                    </div>
                                  </div>
                                );
                              } else if (
                                document_TYPE == "png" ||
                                document_TYPE == "jpg" ||
                                document_TYPE == "JPEG" ||
                                document_TYPE == "jpeg"
                              ) {
                                return (
                                  <div key={id} style={thumbsContainer}>
                                    {thumbs(file, id)}
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    key={id}
                                    className="justify-content-center d-flex  flex-column align-items-center p-2"
                                  >
                                    <div>
                                      <FontAwesomeIcon
                                        icon={faFileAlt}
                                        className="fa-5x "
                                      />
                                    </div>
                                    <div>
                                      {file.name.length > 10 ? (
                                        "File " + id
                                      ) : (
                                        <small>{file.name}</small>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        ) : fileOversize ? (
                          <div className="d-flex justify-content-center mt-2 flex-column align-items-center">
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="fa-3x"
                            ></FontAwesomeIcon>
                            <Label>
                              File exceed limit of 50mb please reduce the files
                              or choose a different size{" "}
                            </Label>
                          </div>
                        ) : null}
                      </div>

                      <div className="d-flex mt-1 mx-2 justify-content-center align-items-center mt-3">
                        <Button
                          className="mx-2 bg-success"
                          onClick={() => {
                            setShowHideFileUpload(false);
                            uploadFiles();
                            clearFilesStates();
                          }}
                        >
                          Upload
                        </Button>
                        <Button
                          className="mx-2"
                          onClick={() => {
                            clearFilesStates();
                          }}
                        >
                          Close
                        </Button>
                      </div>
                    </Col>
                  </FormGroup>
                </div>
              </ModalComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    emailServerReducer: state.emailServerReducer,
    user: state.auth.item,
  };
}

export default connect(mapStateToProps)(EmailFormOutlook);
