import React, { useState } from "react";
import axios from "axios";
import { forEach } from "lodash";
import { useEffect } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  CANDIDATE_SPECIALITY,
  TRAININGPROVIDERS,
} from "../../../../configs/api-config";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";

export default function BankHolidayField(props) {
  //   const MexxarApiSpeciality = CANDIDATE_SPECIALITY;
  //   const MexxarApiTrainingProviders = TRAININGPROVIDERS;

  const { register, handleSubmit, errors } = useForm();
  const {
    onCreate,
    closeModal,
    mode,
    modalType,
    modalType1,
    modalType2,
    fieldData,
    rowNameData,
    rowNameDay

  } = props;

  //   const [speciality, setSpeciality] = useState([]);
  //   const [trainingProviders, setTrainingProviders] = useState([]);
  const [addKillLog, setaddKillLog] = useState(false);
  //   const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  //   const [selectedTrainingProviders, setSelectedTrainingProviders] = useState(
  //     []
  //   );

  //   const getSpecialities = () => {
  //     axios
  //       .get(MexxarApiSpeciality)
  //       .then((res) => {
  //         let specialities = [];
  //         forEach(res.data.body, function (item) {
  //           specialities.push({
  //             value: item.name,
  //             label: item.name,
  //             id: item.id,
  //           });
  //         });
  //         setSpeciality(specialities);
  //       })
  //       .catch((err) => {
  //         //console.log(err)
  //       });
  //   };

  //   const getTrainingProviders = () => {
  //     axios
  //       .get(MexxarApiTrainingProviders)
  //       .then((res) => {
  //         let trainingProviders = [];
  //         forEach(res.data.body, function (item) {
  //           trainingProviders.push({
  //             value: item.name,
  //             label: item.name,
  //             id: item.id,
  //           });
  //         });
  //         setTrainingProviders(trainingProviders);
  //       })
  //       .catch((err) => {
  //         //console.log(err)
  //       });
  //   };

  //   useEffect(() => {
  //     if (specialityShow === true) {
  //       setSelectedSpecialities(specialityIncomingData);
  //       getSpecialities();
  //     }
  //     if (trainingProviderShow === true) {
  //       setSelectedTrainingProviders(trainingProviderIncomingData);
  //       getTrainingProviders();
  //     }
  //   }, []);

  const onSubmit = (data) => {
    console.log(data)
    setaddKillLog(true);

    // if (specialityShow === true) {
    //   let specilityIDArray = [];
    //   forEach(selectedSpecialities, function (item) {
    //     specilityIDArray.push(item.id);
    //   });

    let arrangedData = {
      rowName: data.rowName,
      rowDate: data.rowDate,
      rowDay: data.rowDay,
    };
    onCreate(arrangedData);
  
  };



  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={4}>{modalType}</Label>
        <Col sm={8}>
          <Input
            type="text"
            name="rowName"
            className="capitalize"
            defaultValue={fieldData}
            invalid={errors.bName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
        </Col>

      </FormGroup>

      <FormGroup row>
        <Label sm={4}>{modalType1}</Label>
        <Col sm={8}>
          <Input
            type="date"
            name="rowDate"
            className="capitalize"
            defaultValue={rowNameData?.format("YYYY-MM-DD")}
            invalid={errors.rName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
        </Col>
      </FormGroup>

      {/* <FormGroup row>
        <Label sm={4}>{modalType2}</Label>
        <Col sm={8}>
          <select
            name="rowDay"
            className="capitalize form-control"
            defaultValue={rowNameDay}
            ref={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          >
            <option value="" disabled>Select a day</option>
            <option value="SUNDAY">Sunday</option>
            <option value="MONDAY">Monday</option>
            <option value="TUESDAY">Tuesday</option>
            <option value="WEDNESDAY">Wednesday</option>
            <option value="THURSDAY">Thursday</option>
            <option value="FRIDAY">Friday</option>
            <option value="SATURDAY">Saturday</option>
          </select>
          {errors.rowDay && (
            <div className="text-danger">{errors.rowDay.message}</div>
          )}
        </Col>
      </FormGroup> */}

      {/* {specialityShow ? (
        <FormGroup row>
          <Label sm={4} for="candidateSpecialityId">
            Speciality
          </Label>
          <Col sm={8}>
            <Select
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999,color:"text-dark" }) }}
              defaultValue={specialityIncomingData}
              isMulti
              name="specialities"
              className="basic-multi-select"
              classNamePrefix="select"
              // onChange={(data) => setSelectedSpecialities(data)}
              onChange={(data) => arrangeSpecialities(data)}
              options={speciality}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
                maxLength: {
                  value: 30,
                  message: "This field only allowed only 30 characters",
                },
              })}
            />
          </Col>
        </FormGroup>
      ) : null}

      {trainingProviderShow ? (
        <FormGroup row>
          <Label sm={4} for="candidateSpecialityId">
            Training Providers
          </Label>
          <Col sm={8}>
            <Select
              menuPortalTarget={document.body}
              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              defaultValue={trainingProviderIncomingData}
              //  isMulti
              name="specialities"
              className="basic-multi-select"
              classNamePrefix="select"
              // onChange={(data) => setSelectedSpecialities(data)}
              onChange={(data) => arrangeTrainingProviders(data)}
              options={trainingProviders}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
                maxLength: {
                  value: 30,
                  message: "This field only allowed only 30 characters",
                },
              })}
            />
          </Col>
        </FormGroup>
      ) : null} */}

      <Row className="justify-content-end">
        <div className="d-flex ">
          {" "}
          <Button
            className="mr-2"
            color="success"
            type="submit"
            disabled={addKillLog}
          >
            {mode}
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}
