//**************Important//**************
//If a ward is assign to a client. That ward have its own id as well wardId
//if we use /ward/ Api it returns id of the ward
//if we use /client-ward/ api it retrurns client's ward id, which comes as "id" in return object. which is what we should use in retriving and setting rates

import React, { useState, useEffect } from "react";
import {
  specialitiesGetAction,
  clientsGetAction,
  rolesGetAction,
  departmentsGetAction,
  bandsGetAction,
} from "../../../redux/actions/initialDataGetAction";

import axios from "axios";
import { connect } from "react-redux";
import store from "../../../redux/store";
import { forEach, filter } from "lodash";
import { useForm } from "react-hook-form";
import useDidMountEffect from "./intialRunUseEffect";
import { Button, Input, Form, FormGroup, Label, Col, Alert } from "reactstrap";
import {
  CLIENT_RATE_DATA,
  ClientLable,
  HOSTMexxar,
} from "../../../configs/api-config";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import { UncontrolledTooltip } from "reactstrap/lib";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import sortByCustomOrder from "../../../util/common/sortByCustomOrder";

function ClientRateModal(props) {
  const MexxarApiClientRate = CLIENT_RATE_DATA;

  const { addToast } = useToasts();

  const { register, handleSubmit, errors } = useForm();
  const {
    toggle,
    client,
    reduxSpecialities,
    reduxClients,
    reduxDepartments,
    reduxRoles,
    reduxBands,
  } = props;

  //drop down lists
  const [roles, setRoles] = useState(null);
  const [clients, setClients] = useState(null);
  const [bands, setBands] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [departmentsWithoutDefaultWard, setDepartmentsWithoutDefaultWard] =
    useState(null);

  const [update, setUpdate] = useState(false);
  const [clientRate, setClientRate] = useState();
  const [editable, setEditable] = useState(false);
  const [specialities, setSpecialities] = useState(null);
  const [clientID, setClientID] = useState(client.id);

  // current states
  const [currentRole, setCurrentRole] = useState(1);
  const [currentBand, setCurrentBand] = useState(1);
  const [currentSpeciality, setCurrentSpeciality] = useState(1);
  const [currentClient, setCurrentClient] = useState(-1);
  const [currentDepartment, setCurrentDepartment] = useState(-1);

  //prefixed rate types to set ids for each section
  const [rateTypes, setRateTypes] = useState([
    { type: "Rate Week Days", key: 0, shortForm: "WEEK" },
    { type: "Rate Saturday", key: 1, shortForm: "SAT" },
    ,
    { type: "Rate Sunday", key: 2, shortForm: "SUN" },
    { type: "Rate Bank Holiday", key: 3, shortForm: "BH" },
  ]);

  //For default rates
  const [defaultRatesBool, setDefaultRatesBool] = useState(true);
  const [defaultRatesNotAddedError, setDefaultRatesNotAddedError] =
    useState(false);
  const [isdefineRatesByWards, setIsdefineRatesByWards] = useState(false);

  // Deefault Ward Set //
  const [defaultWard, setDefaultWard] = useState(null);

  const onSubmit = (data) => {
    //check if this is an update or new entry
    let defaultWardID = departments.find((x) => x.wardName === "Default Ward");

    let currentDepartmentTemp = defaultRatesBool
      ? // ? defaultWardID.id.
        parseInt(defaultWardID.id, 10)
      : currentDepartment;
    let roleTemp = defaultRatesBool ? "1" : data.role;
    let bandTemp = defaultRatesBool ? "1" : data.band;
    let specialityTemp = defaultRatesBool ? "1" : data.speciality;

    if (update == false) {
      let tempArray = [];
      for (let i = 0; i < 4; i++) {
        let BonusRate = Number(data[i + "BonusRate"]);
        let BonusChargeRate = Number(data[i + "BonusChargeRate"]);
        let DayRate = Number(data[i + "DayRate"]);
        let DayChargeRate = Number(data[i + "DayChargeRate"]);
        let DStartTime = data[i + "DStartTime"] + ":00";
        let DEndTime = data[i + "DEndTime"] + ":00";
        let NightRate = Number(data[i + "NightRate"]);
        let NightChargeRate = Number(data[i + "NightChargeRate"]);
        let NStartTime = data[i + "NStartTime"] + ":00";
        let NEndTime = data[i + "NEndTime"] + ":00";

        tempArray.push(
          //......Bonus Day Rate .......//
          {
            dayType: i,
            payRollDayMode: 0,
            rate: BonusRate,
            chargeRate: BonusChargeRate,
            rateMode: 1,
            clientId: clientID,
            clientWardId: currentDepartmentTemp,
            specialityId: Number(specialityTemp),
            roleId: Number(roleTemp),
            bandId: Number(bandTemp),
            startTime: DStartTime,
            endTime: DEndTime,
          },
          //......Bonus Night Rate .......//
          {
            dayType: i,
            payRollDayMode: 1,
            rate: BonusRate,
            chargeRate: BonusChargeRate,
            rateMode: 1,
            clientId: clientID,
            clientWardId: currentDepartmentTemp,
            specialityId: Number(specialityTemp),
            roleId: Number(roleTemp),
            bandId: Number(bandTemp),
            startTime: NStartTime,
            endTime: NEndTime,
          },
          //......Day Rate.......//
          {
            dayType: i,
            payRollDayMode: 0,
            rate: DayRate,
            chargeRate: DayChargeRate,
            rateMode: 0,
            clientId: clientID,
            specialityId: Number(specialityTemp),
            clientWardId: currentDepartmentTemp,
            roleId: Number(roleTemp),
            bandId: Number(bandTemp),
            startTime: DStartTime,
            endTime: DEndTime,
          },
          //......Night Rate.......//
          {
            dayType: i,
            payRollDayMode: 1,
            rate: NightRate,
            chargeRate: NightChargeRate,
            rateMode: 0,
            clientId: clientID,
            specialityId: Number(specialityTemp),
            clientWardId: currentDepartmentTemp,
            roleId: Number(roleTemp),
            bandId: Number(bandTemp),
            startTime: NStartTime,
            endTime: NEndTime,
          }
        );
      }

      forEach(tempArray, function (data) {
        axios
          .post(MexxarApiClientRate, data)
          .then((res) => {
            if (res.data.status == "success") {
              // getData(clientID);
              // setShowAddRow(!showAddRow);
              // toastCommon(true, "success");
            } else {
              //   toastCommon(true, "duplicate");
            }
          })
          .catch((err) => {
            ToastCommon(true, "error", addToast);
          });
      });
      toggle(client);
    } else {
      let tempArray = [];

      const addSecondsIfNeeded = (time) =>
        time.includes(":")
          ? time + (time.split(":").length === 2 ? ":00" : "")
          : "";

      for (let i = 0; i < 4; i++) {
        let BonusRate = Number(data[i + "BonusRate"]);
        let BonusChargeRate = Number(data[i + "BonusChargeRate"]);
        let DayRate = Number(data[i + "DayRate"]);
        let DayChargeRate = Number(data[i + "DayChargeRate"]);
        let DStartTime = addSecondsIfNeeded(data[i + "DStartTime"]);
        let DEndTime = addSecondsIfNeeded(data[i + "DEndTime"]);
        let NightRate = Number(data[i + "NightRate"]);
        let NightChargeRate = Number(data[i + "NightChargeRate"]);
        let NStartTime = addSecondsIfNeeded(data[i + "NStartTime"]);
        let NEndTime = addSecondsIfNeeded(data[i + "NEndTime"]);

        let DayRateId = data[i + "DayRate" + "ID"];
        let NightRateId = data[i + "NightRate" + "ID"];
        let BonusRateDayId = data[i + "BonusRateD" + "ID"];
        let BonusRateNightId = data[i + "BonusRateN" + "ID"];

        tempArray.push(
          //......Bonus Day Rate .......//
          {
            id: Number(BonusRateDayId),
            dayType: i,
            payRollDayMode: 0,
            rate: BonusRate,
            chargeRate: BonusChargeRate,
            rateMode: 1,
            clientId: clientID,
            specialityId: Number(specialityTemp),
            clientWardId: currentDepartmentTemp,
            roleId: Number(roleTemp),
            bandId: Number(bandTemp),
            startTime: DStartTime,
            endTime: DEndTime,
          },

          //......Bonus Night Rate .......//
          {
            id: Number(BonusRateNightId),
            dayType: i,
            payRollDayMode: 1,
            rate: BonusRate,
            chargeRate: BonusChargeRate,
            rateMode: 1,
            clientId: clientID,
            specialityId: Number(specialityTemp),
            clientWardId: currentDepartmentTemp,
            roleId: Number(roleTemp),
            bandId: Number(bandTemp),
            startTime: NStartTime,
            endTime: NEndTime,
          },
          //......Day Rate.......//
          {
            id: Number(DayRateId),
            dayType: i,
            payRollDayMode: 0,
            rate: DayRate,
            chargeRate: DayChargeRate,
            rateMode: 0,
            clientId: clientID,
            specialityId: Number(specialityTemp),
            clientWardId: currentDepartmentTemp,
            roleId: Number(roleTemp),
            bandId: Number(bandTemp),
            startTime: DStartTime,
            endTime: DEndTime,
          },
          //......Night Rate.......//
          {
            id: Number(NightRateId),
            dayType: i,
            payRollDayMode: 1,
            rate: NightRate,
            chargeRate: NightChargeRate,
            rateMode: 0,
            clientId: clientID,
            specialityId: Number(specialityTemp),
            clientWardId: currentDepartmentTemp,
            roleId: Number(roleTemp),
            bandId: Number(bandTemp),
            startTime: NStartTime,
            endTime: NEndTime,
          }
        );
      }

      forEach(tempArray, function (data) {
        axios
          .put(MexxarApiClientRate, data)
          .then((res) => {
            if (res.data.status === "success") {
              // getData(clientID);
              // setShowAddRow(!showAddRow);
              // toastCommon(true, "success");
            } else {
              //   toastCommon(true, "duplicate");
            }
          })
          .catch((err) => {
            //console.log(err)
            //toastCommon(true, "error");
          });
      });
      toggle(client);
    }
  };

  const typeFinder = (data) => {
    let shortForm;
    let type;
    let key;
    switch (data) {
      case "WEEK":
        shortForm = data;
        type = "Rate Week Days";
        key = 0;
        break;
      case "SAT":
        shortForm = data;
        type = "Rate Saturday";
        key = 1;
        break;
      case "SUN":
        shortForm = data;
        type = "Rate Sunday";
        key = 2;
        break;
      case "BH":
        shortForm = data;
        type = "Rate Bank Holiday";
        key = 3;
        break;
    }

    return { shortForm: shortForm, type: type, key: key };
  };

  const getData = (clientId) => {
    //check if this is an update or new entry
    //if the rates are already added to the system setUpdate==true

    axios
      .get(MexxarApiClientRate + "/client/" + clientId)
      .then((res) => {
        if (res.data.body.length > 0) {
          setUpdate(true);
        } else {
          setUpdate(false);
        }

        let response = res.data.body;

        let DayType = ["WEEK", "SAT", "SUN", "BH"];
        let DayNight = ["DAY", "NIGHT"];
        let DefaultSpecial = ["D", "NIGHT"];

        let tempUpdatingArray = [];
        let tempArray = [];
        forEach(DayType, function (daytype) {
          //......filter Day Rate.........//

          let ByDayType = filter(response, (item) => {
            return item.dayType === daytype;
          });

          // let DayNight = filter(response, (item) => {
          //   return item.dayType === daytype;
          // });

          let DayNightNight = filter(ByDayType, (item) => {
            return item.payRollDayMode === "NIGHT";
          });

          let DayNightDay = filter(ByDayType, (item) => {
            return item.payRollDayMode === "DAY";
          });

          // Special

          let RateModeSpecial = filter(ByDayType, (item) => {
            return item.rateMode === "SPECIAL";
          });

          let RateModeSpecialDay = filter(RateModeSpecial, (item) => {
            return item.payRollDayMode === "DAY";
          });

          let RateModeSpecialNight = filter(RateModeSpecial, (item) => {
            return item.payRollDayMode === "NIGHT";
          });

          // Default

          let RateModeDefault = filter(ByDayType, (item) => {
            return item.rateMode === "DEFAULT";
          });

          let RateModeDefaultDay = filter(RateModeDefault, (item) => {
            return item.payRollDayMode === "DAY";
          });

          let RateModeDefaultNight = filter(RateModeDefault, (item) => {
            return item.payRollDayMode === "NIGHT";
          });

          tempArray.push({
            type: typeFinder(daytype).type,
            key: typeFinder(daytype).key,
            shortForm: daytype,

            nId: 0,
            dId: 0,
            sDId: 0,
            sNId: 0,

            nStart: 0,
            nEnd: 0,
            nRate: 0,
            nChargeRate: 0,

            dStart: 0,
            dEnd: 0,
            dRate: 0,
            dChargeRate: 0,

            bRate: 0,
            bChargeRate: 0,
          });

          tempUpdatingArray.push({
            type: typeFinder(daytype).type,
            key: typeFinder(daytype).key,
            shortForm: daytype,

            nId: Number(RateModeDefaultNight[0].id),
            dId: Number(RateModeDefaultDay[0].id),
            sDId: Number(RateModeSpecialDay[0].id),
            sNId: Number(RateModeSpecialNight[0].id),

            nStart: RateModeDefaultNight[0].startTime,
            nEnd: RateModeDefaultNight[0].endTime,
            nRate: RateModeDefaultNight[0].rate,
            nChargeRate: RateModeDefaultNight[0].chargeRate,

            dStart: RateModeDefaultDay[0].startTime,
            dEnd: RateModeDefaultDay[0].endTime,
            dRate: RateModeDefaultDay[0].rate,
            dChargeRate: RateModeDefaultDay[0].chargeRate,

            bRate: RateModeSpecial[0].rate,
            bChargeRate: RateModeSpecial[0].chargeRate,
          });
        });
        setRateTypes(tempArray);
        setRateTypes(tempUpdatingArray);
      })
      .catch((err) => {
        //   console.log(err);
        // toastCommon(true, "error");
      });
  };

  const getClientRates = (
    clientId,
    specialityId,
    roleId,
    currentDepartment,
    bandId
  ) => {
    axios
      .get(
        `${HOSTMexxar}client-rates/filter?offset=17&clientId=${clientId}&specialityId=${specialityId}&roleId=${roleId}&clientWardId=${currentDepartment}&bandId=${bandId}`
      )
      .then((res) => {
        if (res.data.body?.content?.length > 0) {
          setUpdate(true);
        } else {
          setUpdate(false);
        }

        let response = res.data.body.content;
        let DayType = ["WEEK", "SAT", "SUN", "BH"];
        let DayNight = ["DAY", "NIGHT"];
        let DefaultSpecial = ["D", "NIGHT"];

        let tempUpdatingArray = [];
        let tempArray = [];
        forEach(DayType, function (daytype) {
          //......filter Day Rate.........//

          let ByDayType = filter(response, (item) => {
            return item.dayType === daytype;
          });
          console.log(`Client ID: ${clientId}`);

          // let DayNight = filter(response, (item) => {
          //   return item.dayType === daytype;
          // });

          let DayNightNight = filter(ByDayType, (item) => {
            return item.payRollDayMode === "NIGHT";
          });

          let DayNightDay = filter(ByDayType, (item) => {
            return item.payRollDayMode === "DAY";
          });

          // Special

          let RateModeSpecial = filter(ByDayType, (item) => {
            return item.rateMode === "SPECIAL";
          });

          let RateModeSpecialDay = filter(RateModeSpecial, (item) => {
            return item.payRollDayMode === "DAY";
          });

          let RateModeSpecialNight = filter(RateModeSpecial, (item) => {
            return item.payRollDayMode === "NIGHT";
          });
          // Default

          let RateModeDefault = filter(ByDayType, (item) => {
            return item.rateMode === "DEFAULT";
          });

          let RateModeDefaultDay = filter(RateModeDefault, (item) => {
            return item.payRollDayMode === "DAY";
          });

          let RateModeDefaultNight = filter(RateModeDefault, (item) => {
            return item.payRollDayMode === "NIGHT";
          });

          tempArray.push({
            type: typeFinder(daytype).type,
            key: typeFinder(daytype).key,
            shortForm: daytype,

            nId: null,
            dId: null,
            sDId: null,
            sNId: null,

            nStart: null,
            nEnd: null,
            nRate: null,

            dStart: null,
            dEnd: null,
            dRate: null,

            bRate: null,
          });

          tempUpdatingArray.push({
            type: typeFinder(daytype).type,
            key: typeFinder(daytype).key,
            shortForm: daytype,

            nId:
              RateModeDefaultNight[0] === undefined
                ? ""
                : Number(RateModeDefaultNight[0].id),
            dId:
              RateModeDefaultDay[0] === undefined
                ? ""
                : Number(RateModeDefaultDay[0].id),
            sDId:
              RateModeSpecialDay[0] === undefined
                ? ""
                : Number(RateModeSpecialDay[0].id),
            sNId:
              RateModeSpecialNight[0] === undefined
                ? ""
                : Number(RateModeSpecialNight[0].id),

            nStart:
              RateModeDefaultNight[0] === undefined
                ? ""
                : RateModeDefaultNight[0].startTime,
            nEnd:
              RateModeDefaultNight[0] === undefined
                ? ""
                : RateModeDefaultNight[0].endTime,
            nRate:
              RateModeDefaultNight[0] === undefined
                ? ""
                : RateModeDefaultNight[0].rate,
            nChargeRate:
              RateModeDefaultNight[0] === undefined
                ? ""
                : RateModeDefaultNight[0].chargeRate,

            dStart:
              RateModeDefaultDay[0] === undefined
                ? ""
                : RateModeDefaultDay[0].startTime,
            dEnd:
              RateModeDefaultDay[0] === undefined
                ? ""
                : RateModeDefaultDay[0].endTime,
            dRate:
              RateModeDefaultDay[0] === undefined
                ? ""
                : RateModeDefaultDay[0].rate,
            dChargeRate:
              RateModeDefaultDay[0] === undefined
                ? ""
                : RateModeDefaultDay[0].chargeRate,

            bRate:
              RateModeSpecial[0] === undefined ? "" : RateModeSpecial[0].rate,
            bChargeRate:
              RateModeSpecial[0] === undefined
                ? ""
                : RateModeSpecial[0].chargeRate,
          });
          console.log("RateModeDefaultNight:", RateModeDefaultNight);
          console.log("nStart:", RateModeDefaultNight[0]?.startTime);
          console.log("nEnd:", RateModeDefaultNight[0]?.endTime);
        });

        setRateTypes(tempArray);
        setRateTypes(tempUpdatingArray);
      });
  };

  const getClient = (clientId) => {
    axios.get(`${HOSTMexxar}client-rates/client/${clientId}`).then((res) => {
      if (res.data.body[0] !== undefined) {
        setClientRate(res.data.body[0]);
        setCurrentSpeciality(res.data.body[0].speciality.id);
        setCurrentRole(res.data.body[0].role.id);
        setCurrentBand(res.data.body[0].candidateBand.id);
      }
    });
  };

  useEffect(() => {
    setClients(reduxClients);

    //Setting up default selectable options as Role:Nurse,Band-5,Speciality-General hence this is the mostly used combination
    const sortedRoles = sortByCustomOrder(reduxRoles, ["Nurse"], "name");
    const sortedBands = sortByCustomOrder(reduxBands, ["Band 5"], "name");
    const sortedSpecialities = sortByCustomOrder(
      reduxSpecialities,
      ["General Wards"],
      "name"
    );

    setRoles(sortedRoles);
    setBands(sortedBands);
    setSpecialities(sortedSpecialities);

    setCurrentRole(sortedRoles?.[0].id);
    setCurrentBand(sortedBands?.[0].id);
    setCurrentSpeciality(sortedSpecialities?.[0].id);
  }, [
    reduxSpecialities,
    reduxClients,
    reduxDepartments,
    reduxRoles,
    reduxBands,
  ]);

  useEffect(() => {
    store.dispatch(specialitiesGetAction());
    store.dispatch(clientsGetAction());
    store.dispatch(departmentsGetAction());
    store.dispatch(rolesGetAction());
    store.dispatch(bandsGetAction());

    setCurrentClient(client.id);
  }, []);

  useEffect(() => {
    // getData(client.id);
    // getClient(client.id);
  }, []);

  useEffect(() => {
    if (
      currentRole &&
      currentClient &&
      currentSpeciality &&
      currentDepartment &&
      currentBand &&
      departments
    ) {
      let defaultWardID = departments?.find(
        (x) => x.wardName === "Default Ward"
      );

      let currentDepartmentTemp = defaultRatesBool
        ? defaultWardID?.id
        : currentDepartment;

      if (currentDepartmentTemp === undefined) {
        setDefaultRatesNotAddedError(true);
      } else {
        if (defaultRatesBool) {
          getClientRates(currentClient, "1", "1", currentDepartmentTemp, "1");
        } else {
          getClientRates(
            currentClient,
            currentSpeciality,
            currentRole,
            currentDepartmentTemp,
            currentBand
          );
        }

        setDefaultRatesNotAddedError(false);
      }
    }
  }, [
    departments,
    currentRole,
    currentClient,
    currentSpeciality,
    currentDepartment,
    currentBand,
    defaultRatesBool,
    isdefineRatesByWards,
  ]);

  // useDidMountEffect(() => {
  //   axios
  //     .get(HOSTMexxar + "client-wards/clients/" + currentClient)
  //     .then((res) => {
  //       //remove the default ward from the drop down
  //       let filteredData = res.data.body.filter(
  //         (item) => item.wardName !== "Default Ward"
  //       );

  //       setDepartments(res.data.body);
  //       console.log("Departmants check",res.data.body )
  //       setDepartmentsWithoutDefaultWard(filteredData);
  //       if (filteredData.length > 0) {
  //         setCurrentDepartment(filteredData[0].id);
  //       } else {
  //         setCurrentDepartment(-1);
  //       }
  //     })
  //     .catch((err) => {
  //       // console.log("err", err);
  //     });
  // }, [currentClient]);
  // useDidMountEffect(() => {
  //   axios
  //     .get(HOSTMexxar + "client-wards/clients/" + currentClient)
  //     .then((res) => {

  //       let filteredData = res.data.body.filter(
  //         (item) => item.wardName !== "Default Ward"
  //       );

  //       setDepartments(res.data.body);
  //       setDepartmentsWithoutDefaultWard(filteredData);
  //       // console.log("Response check",res.data.body)

  //       let defaultWardItem = res.data.body.find(
  //         (item) => item.wardName === "Default Ward"
  //       );

  //       if (defaultWardItem) {
  //         setDefaultWard(defaultWardItem.id);
  //       } else {
  //         setDefaultWard(null);
  //       }
  //      console.log("Default ward id check",defaultWardItem.id)

  //       if (filteredData.length > 0) {
  //         setCurrentDepartment(filteredData[0].id);
  //       } else {
  //         setCurrentDepartment(-1);
  //       }
  //     })
  //     .catch((err) => {
  //       // Handle error
  //       console.error("Error fetching client wards:", err);
  //     });
  // }, [currentClient]);

  useEffect(() => {
    axios
      .get(HOSTMexxar + "client-wards/clients/" + currentClient)
      .then((res) => {
         let sortedWards = sortByCustomOrder(res?.data?.body, ["Default Ward","Escalation Rates"], "wardName");

        setDepartments(sortedWards);

        let defaultWardItem = sortedWards.find(
          (item) => item.wardName === "Default Ward"
        );

        // This means the codebase will set the clientWard parameter to the ID of the "Default Ward," enabling the user to define and retrieve rates without specifying a particular ward.
        if (defaultWardItem) {
          setCurrentDepartment(defaultWardItem.id); // Set the current department to the default ward ID
        } else {
          if (sortedWards.length > 0) {
            setCurrentDepartment(sortedWards?.[0].id);
          } else {
            setCurrentDepartment(-1);
          }
        }
      })
      .catch((err) => {
        // Handle error
        console.error("Error fetching client wards:", err);
      });
  }, [currentClient]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={4}>Set/Show defaults rates</Label>
        <Col sm={8}>
          <Input
            onClick={() => setDefaultRatesBool(!defaultRatesBool)}
            type="checkbox"
            checked={defaultRatesBool}
            // placeholder="name"
            name="defaultRates"
            id="defaultRates"
            className="pointer"
          ></Input>
          <Alert color="info">
            <li>
              Please note that the rates you define will be used for payout
              calculations.
            </li>
            <li>
              If you need to set rates based on a specific combination, please
              uncheck the relevant option.
            </li>
          </Alert>
        </Col>
      </FormGroup>
      {!defaultRatesBool && (
        <>
          <FormGroup row>
            <Label sm={3}>Role</Label>
            <Col sm={8}>
              <Input
                type="select"
                name="role"
                defaultValue={currentRole && currentRole}
                innerRef={register}
                onChange={(selected) => setCurrentRole(selected.target.value)}
              >
                {roles &&
                  roles.map((role, key) => (
                    <option value={role.id} key={key}>
                      {role.name}
                    </option>
                  ))}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Band</Label>
            <Col sm={8}>
              <Input
                type="select"
                name="band"
                defaultValue={currentBand && currentBand}
                innerRef={register}
                onChange={(selected) => setCurrentBand(selected.target.value)}
              >
                {bands &&
                  bands.map((band, key) => (
                    <option value={band.id} key={key}>
                      {band.name}
                    </option>
                  ))}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Speciality</Label>
            <Col sm={8}>
              <Input
                type="select"
                name="speciality"
                value={Number(currentSpeciality)}
                innerRef={register}
                onChange={(selected) =>
                  setCurrentSpeciality(selected.target.value)
                }
              >
                {specialities &&
                  specialities.map((speciality, key) => (
                    <option value={speciality.id} key={key}>
                      {speciality.name}
                    </option>
                  ))}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>{ClientLable} Name</Label>
            <Col sm={8}>
              <Input
                type="select"
                name="client"
                disabled
                className="pointer-disabled"
                value={currentClient}
                innerRef={register}
                onChange={(selected) => setCurrentClient(selected.target.value)}
              >
                {clients &&
                  clients.map((client, key) => (
                    <option value={client.id} key={key}>
                      {client.name}
                    </option>
                  ))}
              </Input>
            </Col>
          </FormGroup>
          {/* <FormGroup row>
            <Label sm={3}>Department Name</Label>
            <Col sm={8}>
              <Input
                type="select"
                name="deapartment"
                value={currentDepartment}
                innerRef={register}
                onChange={(selected) =>
                  setCurrentDepartment(selected.target.value)
                }
              >
                {departmentsWithoutDefaultWard &&
                  departmentsWithoutDefaultWard.map((department, key) => (
                    <option value={department.id} key={key}>
                      {department.wardName}
                    </option>
                  ))}
              </Input>
            </Col>
        
          </FormGroup>
          <FormGroup row>
        <Label sm={4}>Configurates By Wards</Label>
        <Col sm={8}>

          <Input
           type="checkbox"
            // placeholder="name"
            name="defaultRates"
            id="defaultRates"
          ></Input>
          <Alert color="success">
          Add to default ward rates
          </Alert>
        </Col>
      </FormGroup> */}
          {/* {!isdefineRatesByWards && (
        <FormGroup row>
          <Label sm={3}>Department Name</Label>
          <Col sm={8}>
            <Input
              type="select"
              name="department"
              value={currentDepartment}
              innerRef={register}
              onChange={(selected) => setCurrentDepartment(selected.target.value)}
            >
              {departmentsWithoutDefaultWard &&
                departmentsWithoutDefaultWard.map((department, key) => (
                  <option value={department.id} key={key}>
                    {department.wardName}
                  </option>
                ))}
            </Input>
          </Col>
        </FormGroup>
      )}

      <FormGroup row>
      
        <Col sm={8}>
          <Input
            type="checkbox"
            name="defaultRates"
            id="defaultRates"
            checked={isdefineRatesByWards}
            onChange={handleCheckboxChange}
          />
          <Alert color="success">
            {isdefineRatesByWards ? "Add to default ward rates" : "Department Name"}
          </Alert>
        </Col>
      </FormGroup> */}
          {/* <FormGroup row>
            <Label sm={4}>Define rates by specific Ward/Department</Label>
            <Col sm={8}>
              <Input
                className="mt-2 pointer"
                type="checkbox"
                name="defaultRates"
                id="defaultRates"
                checked={isdefineRatesByWards}
                onChange={handleCheckboxChange}
              />
            </Col>
          </FormGroup> */}
          <FormGroup row>
            <>
              <Label sm={3}>Department Name</Label>
              <Col sm={8}>
                <Input
                  type="select"
                  name="department"
                  value={currentDepartment}
                  innerRef={register}
                  onChange={(e) => setCurrentDepartment(e.target.value)}
                >
                  {departments &&
                    departments.map((department, key) => (
                      <option value={department.id} key={key}>
                        {department.wardName}
                      </option>
                    ))}
                </Input>
              </Col>
            </>
          </FormGroup>
        </>
      )}

      {defaultRatesNotAddedError ? (
        <div className="d-flex justify-content-center">
          <Alert color="danger">
            Please add "Default Ward" to this client before setting up the rates
          </Alert>
        </div>
      ) : (
        <div className="col-12 col-sm-12 ">
          <div>
            <div className="card-body">
              {rateTypes?.map((rateType, id) => {
                return (
                  <div className="d-flex flex-row" key={id}>
                    <div
                      className="d-flex justify-content-start align-items-center"
                      style={{ width: "300px" }}
                    >
                      {rateType.type}
                    </div>
                    <div
                      className="d-flex flex-column "
                      style={{ width: "900px" }}
                    >
                      {/* <div className="d-flex justify-content-center m-1">Week Days</div> */}
                      <div className="d-flex flex-row justify-content-center m-1">
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "150px" }}
                        >
                          Night
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "100px" }}
                        >
                          N.P.R
                          <FontAwesomeIcon
                            id="NPR"
                            className="ml-1"
                            icon={faExclamationCircle}
                          />
                        </div>

                        <UncontrolledTooltip target="NPR">
                          Night Pay Rate
                        </UncontrolledTooltip>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "100px" }}
                        >
                          N.C.R
                          <FontAwesomeIcon
                            id="NCR"
                            className="ml-1"
                            icon={faExclamationCircle}
                          />
                        </div>
                        <UncontrolledTooltip target="NCR">
                          Night Charge Rate
                        </UncontrolledTooltip>

                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "150px" }}
                        >
                          Day
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "100px" }}
                        >
                          D.P.R
                          <FontAwesomeIcon
                            id="DPR"
                            className="ml-1"
                            icon={faExclamationCircle}
                          />
                        </div>
                        <UncontrolledTooltip target="DPR">
                          Day Pay Rate
                        </UncontrolledTooltip>

                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "100px" }}
                        >
                          D.C.R
                          <FontAwesomeIcon
                            id="DCR"
                            className="ml-1"
                            icon={faExclamationCircle}
                          />
                        </div>
                        <UncontrolledTooltip target="DCR">
                          Day Charge Rate
                        </UncontrolledTooltip>

                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "100px" }}
                        >
                          Bonus P
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ width: "100px" }}
                        >
                          Bonus C
                        </div>
                      </div>
                      <div className="d-flex flex-row justify-content-center">
                        <div className="d-flex flex-column">
                          <div
                            className="d-flex justify-content-center align-items-center m-1"
                            style={{ width: "150px" }}
                          >
                            S-
                            <Input
                              className="mx-1"
                              type="time"
                              placeholder=""
                              name={rateType.key + "NStartTime"}
                              defaultValue={rateType.nStart}
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                          </div>
                          <div
                            className="d-flex justify-content-center align-items-center m-1"
                            style={{ width: "150px" }}
                          >
                            E-
                            <Input
                              className="mx-1"
                              type="time"
                              placeholder=""
                              name={rateType.key + "NEndTime"}
                              defaultValue={rateType.nEnd}
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="text"
                            name={rateType.key + "NightRate"}
                            defaultValue={rateType.nRate}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          <Input
                            type="hidden"
                            name={rateType.key + "NightRate" + "ID"}
                            defaultValue={rateType.nId}
                            innerRef={register({
                              required: {
                                value: false,
                                message: "",
                              },
                            })}
                          />
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="text"
                            name={rateType.key + "NightChargeRate"}
                            defaultValue={rateType.nChargeRate}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          {/* <Input
                          type="hidden"
                          name={rateType.key + "NightChargeRate" + "ID"}
                          defaultValue={rateType.nId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        /> */}
                        </div>
                        <div className="d-flex flex-column">
                          <div
                            className="d-flex justify-content-center align-items-center m-1"
                            style={{ width: "150px" }}
                          >
                            <Input
                              type="time"
                              placeholder=""
                              name={rateType.key + "DStartTime"}
                              defaultValue={rateType.dStart}
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                          </div>
                          <div
                            className="d-flex justify-content-center align-items-center m-1"
                            style={{ width: "150px" }}
                          >
                            <Input
                              type="time"
                              placeholder=""
                              name={rateType.key + "DEndTime"}
                              defaultValue={rateType.dEnd}
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="text"
                            name={rateType.key + "DayRate"}
                            defaultValue={rateType.dRate}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          <Input
                            type="hidden"
                            name={rateType.key + "DayRate" + "ID"}
                            defaultValue={rateType.dId}
                            innerRef={register({
                              required: {
                                value: false,
                                message: "",
                              },
                            })}
                          />
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="text"
                            name={rateType.key + "DayChargeRate"}
                            defaultValue={rateType.dChargeRate}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                          {/* <Input
                          type="hidden"
                          name={rateType.key + "DayChargeRate" + "ID"}
                          defaultValue={rateType.dId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        /> */}
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="text"
                            name={rateType.key + "BonusRate"}
                            defaultValue={rateType.bRate}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />

                          <Input
                            type="hidden"
                            name={rateType.key + "BonusRateD" + "ID"}
                            defaultValue={rateType.sDId}
                            innerRef={register({
                              required: {
                                value: false,
                                message: "",
                              },
                            })}
                          />

                          <Input
                            type="hidden"
                            name={rateType.key + "BonusRateN" + "ID"}
                            defaultValue={rateType.sNId}
                            innerRef={register({
                              required: {
                                value: false,
                                message: "",
                              },
                            })}
                          />
                        </div>{" "}
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "100px" }}
                        >
                          <Input
                            type="text"
                            name={rateType.key + "BonusChargeRate"}
                            defaultValue={rateType.bChargeRate}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />

                          {/* <Input
                          type="hidden"
                          name={rateType.key + "BonusChargeRateD" + "ID"}
                          defaultValue={rateType.sDId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        /> */}

                          {/* <Input
                          type="hidden"
                          name={rateType.key + "BonusChargeRateN" + "ID"}
                          defaultValue={rateType.chargeRate}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="d-flex justify-content-end mt-3">
                <Button className="mr-2" color="success" type="submit">
                  {update ? "Update" : "Create"}
                </Button>
                <Button color="secondary" onClick={toggle}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    reduxSpecialities: state.initialDataGetReducer.specialities,
    reduxClients: state.initialDataGetReducer.clients,
    reduxDepartments: state.initialDataGetReducer.departments,
    reduxRoles: state.initialDataGetReducer.roles,
    reduxBands: state.initialDataGetReducer.bands,
  };
}

export default connect(mapStateToProps, {})(ClientRateModal);
