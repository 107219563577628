const TabNavigationMenu = (props) => {
  const { activated, onChangeItem, itemList } = props;
 
  return (
    <div className="d-flex  my-2">
      <div className="nav-active-border b-success px-3">
        <ul className="nav text-sm  " role="tablist">
          {itemList.map((item) => {
            return (
              <li
                className={`nav-item `}
                onClick={() => onChangeItem(item.key)}
                key={item.key}
              >
                <a
                  className={` nav-link ${
                    item.key === activated ? "active" : ""
                  }`}
                  href={`#${activated}`}
                  data-toggle="tab"
                >
                  {item.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default TabNavigationMenu;
