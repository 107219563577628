import {
  EMAIL_BODY_OTHER_VALUES,
  EMAIL_BODY_POSTS,
  EMAIL_SERVER_CREDENTIALS,
} from "./types";

export const emailServerCredentials = (data) => (dispatch) => {
  // if (localStorage.email && localStorage.type && localStorage.password) {
  //   dispatch({
  //     type: EMAIL_SERVER_CREDENTIALS,
  //     payload: data,
  //   });
  // } else {
    dispatch({
      type: EMAIL_SERVER_CREDENTIALS,
      payload: data,
    });
  // }
};

export const emailBodyOtherValues = (data) => (dispatch) => {
  dispatch({
    type: EMAIL_BODY_OTHER_VALUES,
    payload: data,
  });
};

export const emailBodyPosts = (data) => (dispatch) => {
  dispatch({
    type: EMAIL_BODY_POSTS,
    payload: data,
  });
};
