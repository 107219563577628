import React, { useState, useEffect } from "react";
import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import {
  RESCHEDULE_MAINTENANCE_WORKSPACE,
  REGISTER_CANDIDATE,
  HostMexxarWS,
} from "../../../../configs/api-config";

import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { Client as StompClient } from "@stomp/stompjs";
import loading from "../../../../../src/images/puff.svg";
import ToastCommon from "../../../../components/toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toLower, isNull, filter, forEach, uniqBy } from "lodash";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import useDidMountEffect from "../../../../components/Modal/ModalBody/intialRunUseEffect";
import MaintenanceCallBackWidget from "../../../../widgets/working-card-widget/call-back-widget";
import SortFunctionDescendingReschedule from "../../../../components/sortFunction/sortByDateDescending.js";
import { FunnelIcon } from "../../../../assets/icons/svg";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((item) => {
      let fullName =
        item.candidateFirstName +
        item.candidateSecondName +
        item.candidateLastName;
      let firstLastName = item.candidateFirstName + item.candidateLastName;

      let searchableString = `${fullName} ${firstLastName} ${item.candidateFirstName} ${item.candidateId} ${item.candidateLastName} ${item.email} ${item.mobileNumber}`;
      let p = searchableString;
      return p.toLowerCase().includes(name.toLowerCase());
    });
  }
  return filtered;
};

const MaintenanceCallBacks = (props) => {
  const MexxarApiCandidate = REGISTER_CANDIDATE;
  const MexxarApi = RESCHEDULE_MAINTENANCE_WORKSPACE;

  const { updateTargets, employeeID } = props;
  const { addToast } = useToasts();

  const currentDate = new Date();
  const yesterDay = moment(moment(currentDate).subtract(1, "days")).format(
    "YYYY/MM/DD"
  );
  const tommorow = moment(moment(currentDate).add(1, "days")).format(
    "YYYY/MM/DD"
  );

  const [rows, setRows] = useState([]);
  const [remember, setRemember] = useState(true);
  const [dateTo, setDateTo] = useState(tommorow);
  const [checkDate, setCheckDate] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [initialRows, setInitialRows] = useState([]);
  const [dateFrom, setDateFrom] = useState(yesterDay);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [hot, setHOT] = useState({ value: "", bool: false });
  const [cold, setCOLD] = useState({ value: "", bool: false });
  const [neutral, setNeutral] = useState({ value: "", bool: false });
  const [expired, setEXPIRED] = useState({ value: "", bool: false });
  const [expiring, setEXPIRING] = useState({ value: "", bool: false });
  const [selectedDate, setSelectedDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCheck = (e) => {
    if (e.target.checked) {
      let data = e.target.value;
      switch (data) {
        case "HOT":
          setHOT({ value: data, bool: true });
          break;
        case "COLD":
          setCOLD({ value: data, bool: true });
          break;
        case "NEUTRAL":
          setNeutral({ value: data, bool: true });
          break;
        case "EXPIRING":
          setEXPIRING({ value: data, bool: true });
          break;

        case "EXPIRED":
          setEXPIRED({ value: data, bool: true });
          break;

        case "REMEMBER":
          setRemember(e.target.checked);
          break;
      }
    } else {
      let data = e.target.value;

      switch (data) {
        case "HOT":
          setHOT({ value: "", bool: false });
          break;
        case "COLD":
          setCOLD({ value: "", bool: false });
          break;
        case "NEUTRAL":
          setNeutral({ value: "", bool: false });
          break;
        case "EXPIRING":
          setEXPIRING({ value: "", bool: false });
          break;

        case "EXPIRED":
          setEXPIRED({ value: "", bool: false });
          break;

        case "REMEMBER":
          setRemember(false);
          break;
      }
    }
  };

  const handleSearch = () => {
    //  console.log("selected Date",selectedDate)

    let dateToTemp;
    let dateFromTemp;

    if (dateFrom == yesterDay) {
      dateFromTemp = moment(moment(currentDate).subtract(1, "days")).format(
        "YYYY/MM/DD"
      );
    } else
      dateFromTemp = moment(moment(dateFrom).subtract(1, "days")).format(
        "YYYY/MM/DD"
      );

    if (dateTo == tommorow) {
      dateToTemp = moment(moment(currentDate).add(1, "days")).format(
        "YYYY/MM/DD"
      );
    } else
      dateToTemp = moment(moment(dateTo).add(1, "days")).format("YYYY/MM/DD");

    axios
      .get(
        MexxarApi +
          "?employeeId=" +
          employeeID +
          "&startDate=" +
          dateFromTemp +
          "&endDate=" +
          dateToTemp
      )
      .then((res) => {
        if (hot.bool == true || cold.bool == true || neutral.bool == true) {
          //...filtered candidates according to the call response filter......//

          let selectedCallReponses = [hot.value, cold.value, neutral.value];

          let callResponseTemp = [];
          forEach(selectedCallReponses, function (selectedCallReponse) {
            let A = filter(res.data.body, (row) => {
              return selectedCallReponse === row.candidateCallResponseStatus;
            });

            if (A.length !== 0) {
              callResponseTemp.push(...A);
            }
          });
          setRows(callResponseTemp);

          setInitialRows(callResponseTemp);
        } else {
          setRows(res.data.body);
          setInitialRows(res.data.body);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const getAllRows = () => {
    axios
      .get(
        MexxarApi +
          "?employeeId=" +
          employeeID +
          "&startDate=" +
          yesterDay +
          "&endDate=" +
          tommorow
      )
      .then((res) => {
        setRows(res.data.body);
        setInitialRows(res.data.body);
        // getLocal();
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  //....reset function......//
  const [reset, setReset] = useState(false);

  const resetFilters = () => {
    setHOT({ value: "", bool: false });

    setCOLD({ value: "", bool: false });

    setNeutral({ value: "", bool: false });

    setEXPIRING({ value: "", bool: false });

    setEXPIRED({ value: "", bool: false });
    //  setSelectedDate([]);
    setRows(initialRows);

    setReset(!reset);
  };
  //console.log("reschedule rows",rows)
  const checkDateBefore = () => {
    setCheckDate(moment(dateFrom).isBefore(dateTo));
  };

  useDidMountEffect(() => {
    checkDateBefore();
  }, [dateTo, dateFrom]);

  useDidMountEffect(() => {
    handleSearch();
    return () => {
      setReset(!reset);
    };
  }, [reset]);

  // useDidMountEffect(() => {
  //   handleSearch();
  // }, [initialRows]);

  useEffect(() => {
    getAllRows();
    updateTargets();
    checkDateBefore();

    let client = new StompClient();
    client.configure({
      brokerURL: HostMexxarWS + "wsc",
      onConnect: () => {
        //console.log("onConnect");
        client.subscribe(
          `/headhunter/${JSON.parse(localStorage.user).id}`,
          (message) => {
            getAllRows();
          }
        );
      },

      //Helps during debugging, remove in production
      debug: (str) => {
        //console.log(new Date(), str);
      },
    });
    client.activate();
  }, [employeeID]);

  return (
    <>
      <div className="form-inline">
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            textTransform: "lowercase",
          }}
        />

        <UncontrolledTooltip target="searchBar">
          Search candidates by Name/Id/Email/Phone No.
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Start Date</Label>
                <Input
                  type="date"
                  name="dateFrom"
                  id="dateFrom"
                  value={dateFrom}
                  onChange={(e) => setDateFrom(e.target.value)}
                  placeholder=""
                  defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                />
                {!checkDate && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="mx-1 bg"
                      />
                      Invalid Date
                    </li>
                  </ul>
                )}
              </Col>
              <Col>
                <Label for="exampleDate">End Date</Label>
                <Input
                  type="date"
                  name="dateTo"
                  id="dateTo"
                  value={dateTo}
                  onChange={(e) => setDateTo(e.target.value)}
                  placeholder=""
                  defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                />
              </Col>
              <Col>
                <Label>Call Response</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="HOT"
                      checked={hot.bool}
                      onChange={handleCheck}
                    />
                    <Badge color="danger">Hot</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="NEUTRAL"
                      checked={neutral.bool}
                      onChange={handleCheck}
                    />
                    <Badge color="secondary">Neutral</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="COLD"
                      checked={cold.bool}
                      onChange={handleCheck}
                    />
                    <Badge color="primary">Cold</Badge>
                  </Label>
                </FormGroup>
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    onClick={handleSearch}
                    style={{ width: "70px" }}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    onClick={resetFilters}
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>

      {rows.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center">
          {/* Loading animation */}
          <div>
            <img src={loading} style={{ height: 100 }}></img>
          </div>
        </div>
      ) : (
        <div>
          {filterByName(
            searchQuery,
            rows.sort(SortFunctionDescendingReschedule)
          ).map((row, id) => {
            return (
              <MaintenanceCallBackWidget
                key={id}
                userProfile={row}
              ></MaintenanceCallBackWidget>
            );
          })}
        </div>
      )}
      {/* <MaintenanceCallBackWidget /> */}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(MaintenanceCallBacks);
