import React from "react";
import { faMars, faVenus } from "@fortawesome/free-solid-svg-icons";
export const ConsultantBadge = (data) => {
  let consultantColor;
  switch (data) {
    case "HEADHUNTER":
      // genderSymbol = Headhunter;
      consultantColor = "#ffc107";
      break;
    case "REGISTRATION":
      // genderSymbol = faVenus;
      consultantColor = "#ff9800";
      break;
    case "MAINTENANCE":
      // genderSymbol = " ";
      consultantColor = "#4caf50";
      break;
    case "BOOKING":
      // genderSymbol = " ";
      consultantColor = "#03a9f4";
      break;
  }

  return { consultantColor: consultantColor };
};
