import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { Document, Page, pdfjs } from "react-pdf";
import { useToasts } from "react-toast-notifications";
import { Button, Label, Input, Form } from "reactstrap";
import { HOSTMexxar } from "../../../configs/api-config";
import ToastCommon from "../../../components/toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
 
export default function ShowReport(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const { payroll, closeModal, report } = props;
  const { register, handleSubmit, errors } = useForm();
  const { addToast } = useToasts();

  // Modal configuration states
  const MexxarAPI = HOSTMexxar;

  const [emailList, setEmailList] = useState([]);
  const [emailTo, setEmailTo] = useState("Hospital");
  const [selectedEmail, setSelectedEmail] = useState();

  // pdf config
  const [numPages, setNumPages] = useState(null);
  const [reportFile, setReportFile] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const onSubmit = (data) => {
    ToastCommon(true, "sent", addToast);
    closeModal()

  };

  const getCandidates = () => {
    axios.get(`${MexxarAPI}candidates/my`).then((res) => {
      let listOfCandidates = [];
      for (let i = 0; i < res.data.body.length; i++) {
        listOfCandidates.push({
          value: res.data.body[i].email,
          label: res.data.body[i].firstName + " " + res.data.body[i].lastName,
          id: res.data.body[i].id,
          receiver: res.data.body[i],
        });
      }
      setEmailList(listOfCandidates);
    });
  };

  const getHospitals = () => {
    axios.get(`${MexxarAPI}clients`).then((res) => {
      let listOfHospitals = [];
      for (let i = 0; i < res.data.body.length; i++) {
        listOfHospitals.push({
          value: res.data.body[i].email,
          label: res.data.body[i].name,
          id: res.data.body[i].id,
          receiver: res.data.body[i],
        });
      }
      setEmailList(listOfHospitals);
    });
  };

  const configureModal = () => {
    if (payroll) {
      setEmailTo("Candidate");
      getCandidates();
    } else {
      setEmailTo("Hospital");
      getHospitals();
    }
  };

  // pdf viewer

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const pdfNextPage = () => {
    if (numPages > pageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const pdfPreviousPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      //console.log("Error: ", error);
    };
  }

  useEffect(() => {
    configureModal();
    setReportFile(report);
    // getBase64(report, (result) => {
    //   setReportFile(result);
    // });
  }, [report]);

  return (
    <Form className="col-12" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row mt-3">
        <Label>To {emailTo}</Label>
        {emailList != undefined && emailList != null ? (
          <Select
            type="select"
            isMulti
            placeholder="Recipient Email"
            name="reciverEmail"
            className="col-12"
            onChange={(selectedEmail) => {
              setSelectedEmail(selectedEmail);
            }}
            options={emailList}
            innerRef={register({
              required: {
                value: true,
                message: "E mail required",
              },
              pattern: {
                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email address!",
              },
            })}
          />
        ) : null}
        {errors.email && <p>{errors.email.message}</p>}
      </div>
      <div className="form-row mt-3">
        <Label>Body</Label>
        <Input
          type="textarea"
          // defaultValue={
          //   template === undefined || template === null
          //     ? ""
          //     : templateContent != undefined || templateContent != null
          //     ? templateContent
          //     : ""
          // }
          className="form-control"
          name="emailBody"
          id="emailBodyId"
          rows="5"
          innerRef={register({ required: true })}
        />
      </div>
      <div className="form-row mt-3">
        <Document
          file={
            reportFile != null && reportFile != undefined ? reportFile : null
          }
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} width={400} height={400} />
        </Document>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <div style={{ width: "5px" }}>
          <FontAwesomeIcon
            icon={faAngleLeft}
            onClick={pdfPreviousPage}
            className="  btn-raised btn-wave "
            style={{
              boxShadow: "none",
            }}
          />
        </div>
        <div className="m-2">
          Page {pageNumber} of {numPages}
        </div>
        <div style={{ width: "5px" }}>
          <FontAwesomeIcon
            icon={faAngleRight}
            className="  btn-raised btn-wave "
            onClick={pdfNextPage}
            style={{
              boxShadow: "none",
            }}
          />
        </div>
      </div>
      <div className="mt-3">
        <div className="d-flex justify-content-end">
          <Button className="mr-2" color="success" type="submit">
            Send
          </Button>{" "}
          <Button color="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
}
