import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import Event from "./event";
import { filter, forEach } from "lodash";
import Training from "./training";
import AddBooking from "./addBooking";
import { connect } from "react-redux";
import moment, { invalid } from "moment";
import Availibility from "./availibility";
import TabNavigationMenu from "../../../util/common/tabNavigation";
import TabNavigationMenuModal from "../../../util/common/tabNavigationMenuModal";

const BookTrainNav = (props) => {
  const {
    candidate,
    closeModal,
    onCreate,
    start,
    reset,
    end,
    errorAlert,
    resetAlert,
    selectedDateArray,
    successStatus,
    incommingPage,
    candidateName,
    candidateId,
    auth,
    dateAvailablities,
  } = props;
  const [items, setItems] = useState([]);
  const [dayData, setDayData] = useState(null);
  const [currentTab, setCurrentTab] = useState("Booking");
  const [candidateWhenClick, SetCandidateWhenClick] = useState(null);

  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  const prepareDefaultCandidate = () => {
    let listOfCandidates;
    if (candidateName != undefined) {
      listOfCandidates = {
        value: candidateName,
        label: candidateName,
        id: Number(candidateId),
        email: candidate?.email,
      };
      SetCandidateWhenClick(listOfCandidates);
    } else {
      SetCandidateWhenClick(null);
    }
  };

  const checkPermissions = (permission) => {
    let array = filter(auth.item.userRole.permissions, (item) => {
      return item.name == permission;
    });
    return array;
  };

  const setTabs = (temp) => {
    if (incommingPage == "employeeCalendar" || incommingPage == "callingPage") {
      setItems([
        { name: "Training", key: "Training" },
        { name: "Event", key: "Event" },
      ]);
      setCurrentTab("Training");
    } else {
      if (checkPermissions("ADMIN").length > 0) {
        setItems([
          { name: "Availability", key: "Availability" },
          { name: "Booking", key: "Booking" },
          { name: "Training", key: "Training" },
          { name: "Event", key: "Event" },
        ]);
        setCurrentTab("Availability");
      } else if (checkPermissions("BOOKING").length > 0) {
        if (temp.availabilityStatus == "NOT_AVAILABLE") {
          setItems([{ name: "Availability", key: "Availability" }]);
        } else {
          setItems([
            { name: "Availability", key: "Availability" },
            { name: "Booking", key: "Booking" },
          ]);
        }

        setCurrentTab("Booking");
      } else if (
        checkPermissions("MAINTENANCE").length > 0 ||
        checkPermissions("REGISTRATION").length > 0 ||
        checkPermissions("HEADHUNTER").length > 0
      ) {
        setItems([
          { name: "Training", key: "Training" },
          { name: "Event", key: "Event" },
        ]);

        setCurrentTab("Training");
      } else if (checkPermissions("AUDIT").length > 0) {
        setItems([{ name: "Event", key: "Event" }]);
        setCurrentTab("Event");
      } else if (checkPermissions("CANDIDATE").length > 0) {
        setItems([{ name: "Availability", key: "Availability" }]);
        setCurrentTab("Availability");
      }
    }
  };

  useEffect(() => {
    prepareDefaultCandidate();

    // let availabilityStatus = "NOT_MARKED";
    let temp = {
      date: start,
      availabilityStatus: "NOT_MARKED",
      id: null,
      shift: null,
    };
    if (dateAvailablities) {
      forEach(dateAvailablities, function (date) {
        let d1 = moment(date.date).format("L");
        let d2 = moment(start).format("L");
        if (d1 === d2) {
          temp = {
            date: start,
            availabilityStatus: date.availibility,
            id: date.id,
            shift: date.shiftObject,
          };
        }
      });
    } else {
      temp = {
        date: start,
        availabilityStatus: "NOT_MARKED",
        id: null,
        shift: null,
      };
    }

    setDayData({
      date: temp.date,
      availabilityStatus: temp.availabilityStatus,
      id: temp.id,
      shift: temp.shift,
    });

    if (dateAvailablities) {
      forEach(dateAvailablities, function (date) {
        let d1 = moment(date.date).format("L");
        let d2 = moment(start).format("L");
        if (d1 === d2) {
          setDayData({
            date: start,
            availabilityStatus: date.availibility,
            id: date.id,
            shift: date.shiftObject,
          });
        }
      });
    } else {
      setDayData({
        date: start,
        availabilityStatus: "NOT_MARKED",
        id: null,
        shift: null,
      });
    }

    setTabs(temp);
  }, []);

  return (
    <>
      <Modal isOpen={errorAlert}>
        <ModalHeader className="border-bottom bg-danger">Alert</ModalHeader>
        <ModalBody>
          Selected date is booked or unavailable please select a different date.
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={resetAlert}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <TabNavigationMenuModal
         activated={currentTab}
        onChangeItem={switchTabHandler}
        itemList={items}
      />

      {currentTab === "Availability" && (
        <Availibility
          candidateWhenClick={candidateWhenClick}
          successStatus={successStatus}
          start={moment(start).format("YYYY-MM-DD")}
          end={moment(end).format("YYYY-MM-DD")}
          // onCreate={(data) => onCreate({ tab: currentTab, body: data })}
          closeModal={closeModal}
          reset={reset}
          selectedDateArray={selectedDateArray}
          dayData={dayData != null ? dayData : ""}
        />
      )}

      {currentTab === "Booking" && (
        <AddBooking
          candidateId={candidateId}
          candidateWhenClick={candidateWhenClick}
          incommingPage={incommingPage}
          dayData={dayData != null ? dayData : ""}
          end={end}
          onCreate={(data) => onCreate({ tab: currentTab, body: data })}
          closeModal={closeModal}
        />
      )}
      {currentTab === "Training" && (
        <Training
        candidateId={candidateId}
          start={start}
          candidateWhenClick={candidateWhenClick}
          end={end}
          onCreate={(data) => onCreate({ tab: currentTab, body: data })}
          closeModal={closeModal}
          
        />
      )}
      {currentTab === "Event" && (
        <Event
          candidateId={candidateId}
          start={start}
          candidateWhenClick={candidateWhenClick}
          end={end}
          onCreate={(data) => onCreate({ tab: currentTab, body: data })}
          closeModal={closeModal}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(BookTrainNav);
