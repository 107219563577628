import { useEffect, useState } from "react";
import axios from "axios";
import { useIsMounted } from "./useIsMounted";

export default function useWorkspaceFetch(url, searchStatus, employeeID) {
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);

  const getData = () => {
    setLoading(true);
    setError(false);
    axios({
      method: "GET",
      url: url + employeeID,
    })
      .then((res) => {
        if (isMounted.current) {
          setRows(res.data.body);
          setLoading(false);
        }
      })
      .catch((e) => {
        setError(true);
      });
  };

  useEffect(() => {
    if (!searchStatus.status && employeeID != undefined) {
      getData();
    } else {
      setRows(searchStatus.data);
    }
  }, [url, searchStatus, employeeID]);

  return { loading, error, rows };
}
