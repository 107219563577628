import React, { useState, useEffect } from "react";
import axios from "axios";
import filter from "lodash/filter";
import { Button } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../components/toastCommon";
import Delete from "../../../components/Modal/ModalBody/delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import {
  Add_NEW_EMPLOYEE,
  CONSULTANT_ROLE,
  HOSTMexxar,
  EMPLOYEE_TEAMS,
} from "../../../configs/api-config";
import CreateNewUser from "../../../components/Modal/ModalBody/Admin/createNewUser";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";

const CreateNewConsultant = () => {
  const MexxarHost = HOSTMexxar;
  const MexxarApi = Add_NEW_EMPLOYEE;
  const MexxarConsultantRoles = CONSULTANT_ROLE;
  const MexxarTeams = EMPLOYEE_TEAMS;

  const { addToast } = useToasts();

  const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [rowNameData, setRowNameData] = useState([]);
  const [showAddRow, setShowAddRow] = useState(false);
  const [employeeData, setEmployeeData] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [incomingRoles, setIncomingRoles] = useState(null);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);

  const [consultantRoles, setConsultantRoles] = useState([]);
  const [consultantTeams, setConsultantTeams] = useState([]);

  const getConsultantUpdateData = (row) => {
    if (row.id != rowID) {
      setEmployeeData(null);
      axios
        .get(MexxarHost + "employee/" + row.id)
        .then((res) => {
          let roleTemp = {
            id: res.data.body[0].employeeRoleId,
            value: res.data.body[0].employeeRole,
            label: res.data.body[0].employeeRole,
          };

          setIncomingRoles(roleTemp);
          setEmployeeData(res.data.body[0]);
        })
        .catch((err) => {
          ToastCommon(true, "error", addToast);
        });
    }
  };

  // useDidMountEffect(() => {
  //   getConsultantUpdateData();
  // }, [rowID]);

  const getAllRows = () => {
    axios
      .get(MexxarApi)
      .then((res) => {
        //console.log("hello",res.data)
        setRows(res.data.body);
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const addRow = (data) => {
    axios
      .post(MexxarApi, {
        firstName: data.firstName,
        lastName: data.lastName,
        avatar: null,
        email: data.email,
        password: data.password,
        emailPassword: data.emailPassword,
        userRoleId: Number(data.userRoleId),
        dailyTarget: 0,
        teamId: Number(data.teamId),
        gender: data.gender,
        companyPhoneNumber: data.companyPhoneNumber,
      })
      .then((res) => {
        if (res.data.status == "success") {
          getAllRows();
          setShowAddRow(!showAddRow);
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const deleteRow = () => {
    axios
      .delete(MexxarApi + "/" + rowID)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "delete", addToast);
          getAllRows();
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const updateRow = (data) => {
    axios
      .put(MexxarApi, {
        id: rowID,
        firstName: data.firstName,
        lastName: data.lastName,
        avatar: null,
        email: data.email,
        //password: data.password,
        emailPassword: data.emailPassword,
        userRoleId: Number(data.userRoleId),
        teamId: Number(data.teamId),
        gender: data.gender,
        companyPhoneNumber: data.companyPhoneNumber,
      })
      .then((res) => {
        if (res.data.status == "success") {
          getAllRows();
          setShowUpdateRow(false);
          setEmployeeData(null);
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        // console.log(err);
        //        ToastCommon(true, "error", addToast);
      });
  };

  const deleteAllRows = () => {
    selectedItems.forEach((item) => {
      axios
        .delete(MexxarApi + "/" + item)
        .then((res) => {
          if (res.data.status == "success") {
            getAllRows();
            setSelectedItems([]);
            setSelectAll(false);
            ToastCommon(true, "delete", addToast);
          } else {
            ToastCommon(true, "cannotDelete", addToast);
          }
        })

        .catch((err) => {
          //console.log(err)
        });
    });
  };

  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
    } else {
      setSelectedItems([...selectedItems, rowId]);
    }
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  const getConsultantRoles = () => {
    axios
      .get(MexxarConsultantRoles)
      .then((res) => {
        setConsultantRoles(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const getConsultantTeams = () => {
    axios
      .get(MexxarTeams)
      .then((res) => {
        setConsultantTeams(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  useEffect(() => {
    getAllRows();
    getConsultantTeams();
    getConsultantRoles();
  }, []);

  return (
    <DashboardLayout
      title="Add Consultants"
      subTitle="Manage New Consultants "
      target=""
    >
      <div className="d-flex justify-content-between p-2 ml-3">
        <div>
          {selectedItems.length > 0 ? (
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                setShowDeleteAll(true);
              }}
            >
              Delete
            </Button>
          ) : null}
        </div>
        <div>
          {" "}
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddRow(true);
            }}
          >
            Add Consultant
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <table
            id="datatable"
            className="table table-theme table-row v-middle dataTable no-footer"
            role="grid"
            ariaDescribedby="datatable_info"
          >
            <thead>
              <tr role="row">
                <th
                  className="sorting_asc"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaSort="ascending"
                  ariaLabel="ID: activate to sort column descending"
                  style={{ width: "26px" }}
                >
                  <label className="ui-check m-0">
                    <input
                      type="checkbox"
                      name="id"
                      checked={selectAll}
                      onChange={() => checkAll()}
                    />{" "}
                    <i></i>
                  </label>
                </th>
                <th
                  className="sorting_asc"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaSort="ascending"
                  ariaLabel="ID: activate to sort column descending"
                  style={{ width: "26px" }}
                >
                  <span className="text-muted">ID</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Name</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Role </span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Email </span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Company T.P </span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Status</span>
                </th>
                <th
                  className="sorting_disabled d-flex justify-content-end mr-3"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Tasks"
                >
                  <span className="text-muted d-none d-sm-block mr-3">
                    Actions
                  </span>
                </th>
              </tr>
            </thead>

            <tbody>
              {rows.map((row, i) => {
                return (
                  <tr className="odd" key={i} role="row">
                    <td
                      style={{ minWidth: "30px", textAlign: "center" }}
                      className="sorting_1"
                    >
                      <label className="ui-check m-0">
                        <input
                          type="checkbox"
                          name="id"
                          checked={isSelected(row.id)}
                          onChange={() => onItemSelect(row.id)}
                        />{" "}
                        <i></i>
                      </label>
                    </td>
                    <td
                      style={{ minWidth: "30px", textAlign: "center" }}
                      className="sorting_1"
                    >
                      <small className="text-muted">{i + 1}</small>
                    </td>

                    <td className="">
                      {row.firstName}&nbsp;{row.lastName}
                    </td>
                    <td className="">{row.employeeRole}</td>
                    <td className="">{row.email}</td>
                    <td className="">{row.companyPhoneNumber}</td>
                    <td className="">{row.status}</td>

                    <td className="d-flex justify-content-end">
                      <FontAwesomeIcon
                        // color="#448bff"
                        icon={faEdit}
                        className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3"
                        onClick={() => {
                          setShowUpdateRow(true);
                          setRowID(row.id);
                          setRowNameData(row.firstName);
                          getConsultantUpdateData(row);
                        }}
                        size="1x"
                      />
                      {/* <FontAwesomeIcon
                        // color="#FF5603"
                        icon={faTrashAlt}
                        onClick={() => {
                          setShowDeleteRow(true);
                          setRowID(row.id);
                        }}
                        className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave "
                        size="1x"
                      /> */}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      <ModalComponent
        show={showAddRow}
        header="Add New Consultant "
        closeModal={() => setShowAddRow(false)}
      >
        <CreateNewUser
          mode="Add"
          // modalType="Framework"
          closeModal={() => setShowAddRow(false)}
          onCreate={(data) => addRow(data)}
          consultantRoles={consultantRoles}
          consultantTeams={consultantTeams}
        />
      </ModalComponent>
      <ModalComponent
        show={showUpdateRow}
        header="Update Consultant"
        closeModal={() => {
          setShowUpdateRow(false);
        }}
      >
        <CreateNewUser
          mode="Update"
          incomingRoles={incomingRoles}
          employeeData={employeeData && employeeData}
          // modalType="Framework"
          id={rowID}
          closeModal={() => {
            setShowUpdateRow(false);
            //  setEmployeeData(null);
          }}
          onCreate={(data) => updateRow(data)}
          consultantRoles={consultantRoles}
          consultantTeams={consultantTeams}
        />

        {/* <NewOneField
          mode="Update"
          modalType="Framework"
          fieldData={rowNameData}
          closeModal={() => setShowUpdateRow(!showUpdateRow)}
          onCreate={(data) => updateRow(data)}
        /> */}
      </ModalComponent>
      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteRow();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(false)}
      />
      <Delete
        show={showDeleteAll}
        isConfirm={() => {
          deleteAllRows();
          setShowDeleteAll(!showDeleteAll);
        }}
        closeModal={() => setShowDeleteAll(false)}
      />
    </DashboardLayout>
  );
};

export default CreateNewConsultant;
