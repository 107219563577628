import React, { useState } from "react";

import { connect } from "react-redux";
import CandidateProfilePayrollUploadedWidget from "../../../widgets/payroll/candidate-profile-payroll-uploaded-widget";

const PayrollSummery = (props) => {
  const { candidateId,candidate,reload } = props;

  return (
    <>
      <CandidateProfilePayrollUploadedWidget
        candidateId={candidateId}
        candidate={candidate}
        reload={reload}
      ></CandidateProfilePayrollUploadedWidget>
    </>
  );
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, {})(PayrollSummery);
