import React from "react";
import { CompanyName } from "../../configs/api-config";

const Footer = () => {
  return (
    <>
      <div className="d-flex p-3">
        <p>Copyright {new Date().getFullYear()} @ Mexxar</p>
        <p>Powered by Mexxar</p>
      </div>
    </>
  );
};

export default Footer;
