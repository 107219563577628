import React, { useState, useEffect } from "react";
import { Card } from "reactstrap";
import { faRocket, faPhone, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { HOSTMexxar } from "../../../configs/api-config";
import { connect } from "react-redux";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";

const SmsAlert = (props) => {
  const { style, auth, demoSms } = props;
  const MexxarAPI = HOSTMexxar;

  const [sms, setSms] = useState(0);

  const getReceivedMessages = (number) => {
    axios
      .get(`${MexxarAPI}chat/get-by-from/${number}`)
      .then((receivedSMSRes) => {
        let receivedSMS = receivedSMSRes.data.body;

        setSms(receivedSMS.length);
      });
  };

  useEffect(() => {
    getReceivedMessages();
  }, []);

  useDidMountEffect(() => {
    if (demoSms) {
      setSms(demoSms);
    }
  }, [demoSms]);

  return (
    <div
      style={{ ...style }}
      className="alert card alert-dismissible fade show "
      role="alert"
    >
      <div className="d-flex">
        <span className="w-40 avatar circle gd-warning">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-mail"
          >
            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
            <polyline points="22,6 12,13 2,6"></polyline>
          </svg>
        </span>
        <div className="d-sm-flex">
          <div className="mx-3 align-self-start">
            <h6>
              Welcome back, {auth.item.firstName} {auth.item.lastName}
            </h6>
            {sms ===0 ?    <small>You have no new SMSs</small>:   <small>You have {sms} new SMSs</small>}
           </div>
          {/* <div className="mx-3">
            <a
              href="#"
              className="btn btn-sm btn-rounded btn-outline-warning my-2"
            >
              Today's tasks
            </a>
          </div> */}
        </div>
      </div>
      {/* <button
        type="button"
        className="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button> */}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(SmsAlert);
