import React, { Component, useEffect, post } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RoundIconButton from "../../../components/RoundIconButton/round-icon-button";
import {
  Card,
  Badge,
  CardBody,
  Label,
  UncontrolledTooltip,
  Col,
  Row,
  Collapse,
} from "reactstrap";
import axios from "axios";
import { Button, Input } from "reactstrap";
import {
  faChevronLeft,
  faChevronRight,
  faCut,
  faPeopleArrows,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { HOSTMexxar, SCHEDULE_TRAINING } from "../../../configs/api-config";
import { connect } from "react-redux";
import moment from "moment";
import loadingAnimation from "../../../../src/images/puff.svg";
import { useIsMounted } from "../../../customHooks/useIsMounted";
import NoConnection from "../../../pages/errorPages/NoConnection";
import NoSearchResults from "../../../pages/errorPages/NoSearchResults";
import { forEach, isNull, filter } from "lodash";
import { FunnelIcon } from "../../../assets/icons/svg";

const EmployeeUpcommingTasks = (props) => {
  const { userProfile, style, userId, candidateId } = props;
  const [dataList, setDataList] = useState([]);
  const MexxarAPI = HOSTMexxar;

  const MexxarApi = SCHEDULE_TRAINING;

  const currentDate = new Date();

  const yesterDay = moment(moment(currentDate).subtract(1, "days")).format(
    "YYYY-MM-DD"
  );
  const tommorow = moment(moment(currentDate).add(1, "days")).format(
    "YYYY-MM-DD"
  );

  const [rows, setRows] = useState([]);
  const [shifts, setShifts] = useState([]);
  const [error, setError] = useState(null);
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [initialRows, setInitialRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState([]);
  const [getEndDate, setGetEndDate] = useState(tommorow);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [getStartDate, setGetStartDate] = useState(yesterDay);

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getEvents = () => {
    setLoading(true);
    /**
     *
     *
     *  Events
     **/

    // Created temporary array to save maped events.
    let eventsArray = [];

    /**
     *  Training booking
     **/

    axios
      .get(
        MexxarApi +
          "/range?userId=" +
          userId +
          "&start=" +
          moment(getStartDate).format("YYYY/MM/DD") +
          "&end=" +
          moment(getEndDate).format("YYYY/MM/DD") +
          "&type=EMPLOYEE"
      )
      .then((res) => {
        setLoading(false);

        if ((res.data.status = "success")) {
          let training = res.data.body;

          for (let i = 0; i < training.length; i++) {
            let title = "";
            if (
              training[i].trainings[0] != undefined ||
              training[i].trainings[0] != null
            ) {
              if (training[i].trainings.length > 1) {
                title = "Multiple Trainings";
              } else {
                title = training[i].trainings[0].name;
              }
            }

            let body = [];
            for (let x = 0; x < training[i].trainings.length; x++) {
              body.push(training[i].trainings[x].name);
            }

            let taggedCandidatesList = [];
            for (let x = 0; x < training[i].candidates.length; x++) {
              taggedCandidatesList.push(
                `${training[i].candidates[x].firstName} ${training[i].candidates[x].lastName} id : ${training[i].candidates[x].id}`
              );
            }

            eventsArray.push({
              id: training[i].id,
              title: title,
              body: body,
              content: moment(training[i].startDate).format("LLLL"),
              recordedEmployeeName: training[i].recordedEmployeeName,
              // taggedCandidates: String(taggedCandidatesList),
              taggedCandidates: taggedCandidatesList,
            });
          }
          setDataList(eventsArray);
        }
      })
      .catch((err) => {
        setError(err);
      });
  };
  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  const handleSearch = () => {
    let w = isNull(selectedDate) ? 0 : selectedDate.length;

    //...filtered candidates according to the date filter......//

    let dateTemp = [];

    let A = filter(initialRows, (row) => {
      return selectedDate === moment(row.bookingDate).format("YYYY-MM-DD");
    });

    if (A.length !== 0) {
      dateTemp.push(...A);
    }

    if (w > 0) {
      setRows(dateTemp);
    } else {
      setRows(initialRows);
    }
  };

  const [resetState, setResetState] = useState(false);

  const reset = () => {
    setGetStartDate(yesterDay);
    setGetEndDate(tommorow);
    setResetState(!resetState);
    // getFieldData();
  };

  useEffect(() => {
    getEvents();
  }, [search, resetState]);

  const sortFunctionAscending = (a, b) => {
    var dateA = new Date(a.content).getTime();
    var dateB = new Date(b.content).getTime();
    return dateA > dateB ? 1 : -1;
  };
  //   const shortCut = (event) => {
  //    console.log("key",event.key)
  //     if (event.key === "Shift") {
  //      ;
  //    }
  //   }
  //   document.addEventListener('keydown', function(event) {
  //     console.log(event.key)
  //     if (event.key === "Shift" && event.key ==="F"
  //     );
  //     console.log("running");{
  //      ;
  //     };
  // });

  return (
    <div style={style}>
      {/* <Card /> */}
      <div className="" style={{ ...style }}>
        {/* <Label className="ml-3 mt-3">Upcoming Tasks</Label>

        <hr /> */}

        <div className="form-inline">
          <Button
            // onKeyDown={(e)=>shortCut(e)}
            onClick={toggleFilter}
            className="btn bg-dark-lt text-dark"
          >
            <FunnelIcon />
            <span className="mx-1">Filter</span>
          </Button>
          <Input
            id="searchBar"
            onChange={(e) => handleSearchOnChange(e)}
            type="text"
            className="form-control no-border no-shadow no-bg typeahead tt-input"
            placeholder="Search Trainings..."
            autoComplete="off"
            spellCheck="false"
            dir="auto"
            style={{
              position: "relative",
              verticalAlign: "top",
              backgroundColor: "transparent",
              // textTransform: "lowercase",
            }}
          />
          <UncontrolledTooltip target="searchBar">
            Search by Date range
          </UncontrolledTooltip>
        </div>

        <br></br>
        <Collapse isOpen={isOpenFilter}>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Label for="exampleDate">Start Date</Label>
                  <Input
                    id="startDate"
                    type="date"
                    //defaultValue={getStartDate}
                    value={getStartDate}
                    onChange={(e) => setGetStartDate(e.target.value)}
                  />
                </Col>
                <Col>
                  <Label for="exampleDate">End Date</Label>
                  <Input
                    id="endDate"
                    type="date"
                    value={getEndDate}
                    onChange={(e) => setGetEndDate(e.target.value)}
                  />
                </Col>

                <Col>
                  <div className="d-flex flex-column align-items-end">
                    <Button
                      color="success m-1 btn-sm"
                      style={{ width: "70px" }}
                      // onClick={handleSearch}
                      onClick={() => {
                        setSearch(!search);
                      }}
                    >
                      Search
                    </Button>
                    <Button
                      color="danger m-1 btn-sm"
                      onClick={reset}
                      style={{ width: "70px" }}
                    >
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row></Row>
            </CardBody>
          </Card>
        </Collapse>

        <div style={{ overflow: "auto", ...style }}>
          {error && <NoConnection error={error}></NoConnection>}
          {dataList.length == 0 && !loading && (
            <NoSearchResults></NoSearchResults>
          )}
          {loading && (
            <div className="d-flex justify-content-center align-items-center">
              {/* Loading animation */}
              <img src={loadingAnimation} style={{ height: 100 }}></img>
            </div>
          )}
          {dataList.length > 0 &&
            !loading &&
            dataList.sort(sortFunctionAscending).map((post, i) => {
              return (
                <div key={i} className="d-flex card ">
                  <div
                    className="d-flex card mb-1 p-3 "
                    style={{ width: "100%" }}
                  >
                    <div className=" list-item ">
                      <div className="timeline timeline-theme animates animates-fadeInUp">
                        <div className="tl-item active">
                          <div className="tl-dot"></div>
                          <div className="tl-content">
                            <div>
                              <div className=" ">{post.title}</div>
                              {post.body != null &&
                              post.body != undefined &&
                              post.body.length > 1 ? (
                                <small className="tl-content ">
                                  {String(post.body)}
                                </small>
                              ) : null}
                            </div>
                            <div
                              className="tl-date text-muted mt-1"
                              style={{
                                maxWidth: "400px",
                                fontSize: "12px",
                              }}
                            >
                              {
                                <div>
                                  {post.content} <br /> Assigned by :{" "}
                                  {post.recordedEmployeeName}
                                  <br />
                                  {post.taggedCandidates != null &&
                                  post.taggedCandidates != "" &&
                                  post.taggedCandidates != undefined ? (
                                    <>
                                      {/* Assigned to : {post.taggedCandidates} */}
                                      Assigned to :
                                      {post.taggedCandidates.map(
                                        (candidate, i) => {
                                          return (
                                            <>
                                              {" "}
                                              <Badge color="danger" key={i}>
                                                {candidate}
                                              </Badge>{" "}
                                            </>
                                          );
                                        }
                                      )}
                                    </>
                                  ) : null}
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.auth.item.userTypeId,
  };
}

// export default UpcommingTasksWidget;
export default connect(mapStateToProps)(EmployeeUpcommingTasks);
