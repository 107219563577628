import React from "react";

export const ConversationResponse = (data) => {
  let badgeColor;
  let badgeLable;
  switch (data) {
    case "COLD":
      badgeLable = "Cold";
      badgeColor = "primary";
      break;
    case "NEUTRAL":
      badgeLable = "Neutral";
      badgeColor = "secondary";
      break;
    case "HOT":
      badgeLable = "Hot";
      badgeColor = "danger";
      break;
  }

  return { badgeColor: badgeColor, badgeLable: badgeLable };
};
