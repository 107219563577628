import React, { useState, useEffect } from "react";
import Training from "./training";
import AddBooking from "./addBooking";
import Availibility from "./availibility";
import TabNavigationMenuModal from "../../../util/common/tabNavigationMenuModal";

const CalendarNav = (props) => {
  const {
    candidate,
    closeModal,
    onCreate,
    candidateId,
    reset,
    currentResetState,
    dayData,
    selectedRowData,
    successStatus,
  } = props;

  const [items, setItems] = useState([]);
  const [oncreteDummy, setOncreteDummy] = useState(null);
  const [currentTab, setCurrentTab] = useState("Availability");
  const [candidateWhenClick, SetCandidateWhenClick] = useState(null);

  const switchTabHandler2 = (tab) => {
    setCurrentTab(tab);
  
  };

  const prepareDefaultCandidate = () => {
    let listOfCandidates;
    if (selectedRowData != undefined || selectedRowData != null) {
      listOfCandidates = {
        value:
          selectedRowData.candidate.firstName +
          " " +
          selectedRowData.candidate.lastName,
        label:
          selectedRowData.candidate.firstName +
          " " +
          selectedRowData.candidate.lastName,
        id: selectedRowData.candidate.id,
        email: candidate?.email,
      };
      SetCandidateWhenClick(listOfCandidates);
    } else {
      SetCandidateWhenClick(null);
    }
  };

  useEffect(() => {
    prepareDefaultCandidate();
    setItems([
      { name: "Availability", key: "Availability" },
      { name: "Booking", key: "Booking" },
    ]);
  }, []);

  return (
    <>
      <TabNavigationMenuModal
        activated={currentTab}
        onChangeItem={switchTabHandler2}
        itemList={items}
      />
      {currentTab === "Availability" && (
        <Availibility
          candidateWhenClick={candidateWhenClick}
          successStatus={successStatus}
          start={dayData ? dayData.date : ""}
          end={dayData ? dayData.date : ""}
          currentResetState={currentResetState}
          reset={reset}
          closeModal={closeModal}
          dayData={dayData ? dayData : ""}
        />
      )}
      {currentTab === "Booking" && (
        <AddBooking
          dayData={dayData ? dayData : ""}
          currentResetState={currentResetState}
          candidateWhenClick={candidateWhenClick}
          reset={reset}
          candidateId={candidateId}
          onCreate={(data) => setOncreteDummy(data)}
          closeModal={closeModal}
        />
      )}
      {currentTab === "Training" && (
        <Training
          candidateWhenClick={candidateWhenClick}
          closeModal={closeModal}
        />
      )}
     
    </>
  );
};

export default CalendarNav;
