import axios from "axios";
import React, { useCallback, useState } from "react";
import { HOSTMexxar, REGISTER_CANDIDATE } from "../../configs/api-config";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import {
  Alert,
  Button,
  Form,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import "./proPicUploadEdit.css";
import Cropper from "react-cropper";
import { useDropzone } from "react-dropzone";
import { split } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faUserCheck } from "@fortawesome/free-solid-svg-icons";

function ProPicUploadEdit(props) {
  const {
    closeModal,
    image,
    setModalPreviewPicture,
    loadProfilePicture,
    candidateId,
    reload 

  } = props;
  const { addToast } = useToasts();

  let Mexxar = REGISTER_CANDIDATE;

  const [newImage, setNewImage] = useState(null);
  const [fileuploaded, setFileuploaded] = useState(false);
  const [croppedImgSrc, setCroppedImgSrc] = useState(null);
  const [edit, setEdit] = useState(false);
  const [uploadingStatus, setUploadingStatus] = useState("NOTUPLOADED");

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const saveProPic = (e) => {
    e.preventDefault();
    setUploadingStatus("LOADING");
    var fileToUpload;
    const form = new FormData();
    if (croppedImgSrc) {
      fileToUpload = dataURLtoFile(croppedImgSrc, newImage[0].name);

      form.append("pro_pic", fileToUpload);
    } else {
      form.append("pro_pic", newImage[0]);
    }

    axios
      .put(Mexxar + "/" + candidateId + "/upload-profile-picture/", form)
      .then((res) => {
        loadProfilePicture(res.data.body[0]);
        setUploadingStatus("UPLOADED");

        let url = String(
          HOSTMexxar +
            String(res.data.body[0].avatar).replace("pro_pic", "pro-pic")
        );

        setModalPreviewPicture(url);
        setEdit(true);
        reload()
      })
      .catch(() => {
        setUploadingStatus("FAILED");
      });
  };

  const deleteProPic = () => {
    axios
      .delete(Mexxar + "/" + candidateId + "/delete-profile-picture")
      .then((res) => {
        if (res.data.status == "success") {
          setModalPreviewPicture();
          closeModal(false);
          ToastCommon(true, "success", addToast);
          // reset(!currentResetState);
          loadProfilePicture({ avatar: null });
          reload()
        } else {
          ToastCommon(true, "error", addToast);
        }
      });
  };

  let cropper = React.createRef();

  const handleCropChange = () => {
    const croppedImgData = cropper.current.cropper
      .getCroppedCanvas()
      .toDataURL();
    setCroppedImgSrc(croppedImgData);
    setUploadingStatus("RECROPPED");
  };

  const { getRootProps, getInputProps } = useDropzone({
    //  accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFileuploaded(true);

      setNewImage(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  return (
    <Form onSubmit={saveProPic}>
      <ModalBody className="p-4 ">
        <div className="d-flex justify-content-center align-items-center flex-column">
          <div>
            <label for="file-input">
              {edit ? (
                fileuploaded ? (
                  <Cropper
                    id="cropper"
                    ref={cropper}
                    src={newImage && newImage[0].preview}
                    cropend={() => handleCropChange()}
                    autoCropArea={1}
                    // viewMode={1} //if needed to restrict the crop area use this
                    aspectRatio={1 / 1}
                    cropBoxResizable={false}
                    minCropBoxWidth={"800px"}
                    minCropBoxHeight={"800px"}
                  />
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      border: "solid 2px ",
                      borderRadius: "2px",
                      borderColor: "#007bff",
                      width: "300px",
                      height: "300px",
                      margin: "10px",
                    }}
                    {...getRootProps()}
                  >
                    <input id="dropZone" {...getInputProps()} />

                    <p className="m-3">Drop the file here ...</p>
                  </div>
                )
              ) : image ? (
                <img src={image} width={"200px"} height={"200px"}></img>
              ) : (
                <div className="d-flex justify-content-center align-items-center">
                  Loading...
                </div>
              )}
            </label>
          </div>
          <div className="pt-3">
            {(uploadingStatus === "NOTUPLOADED" ||
              uploadingStatus === "RECROPPED") &&
              fileuploaded && <div> Drag and adjust to crop. </div>}

            {uploadingStatus === "LOADING" && (
              <Alert color={"warning"}>
                Uploading. Please wait...
                <Spinner
                  animation="border"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginLeft: "5px",
                  }}
                />
              </Alert>
            )}
            {uploadingStatus === "UPLOADED" && (
              <Alert>
                Succesfully updated. <FontAwesomeIcon icon={faCheck} />
              </Alert>
            )}
          </div>

          <div>
            <ModalFooter>
              {edit ? (
                <>
                  {uploadingStatus === "NOTUPLOADED" ||
                    (uploadingStatus === "RECROPPED" && (
                      <Button className="mr-2" type="submit" color="success">
                        Save
                      </Button>
                    ))}
                  {uploadingStatus === "FAILED" && (
                    <Button className="mr-2" type="submit" color="success">
                      Retry
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <Button
                    className="mr-2"
                    type="button"
                    onClick={() => setEdit(true)}
                    color="success"
                  >
                    Edit
                  </Button>
                  <Button color="danger" onClick={deleteProPic}>
                    Delete
                  </Button>
                </>
              )}
              <Button
                color="secondary"
                onClick={() => {
                  closeModal(false);
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </div>
      </ModalBody>
    </Form>
  );
}

export default ProPicUploadEdit;
