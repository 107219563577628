import React from "react";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";

const NotFound = () => {
  return (
    <DashboardLayout>
      <div className="d-flex align-items-center w-100 h-100 justify-content-center">
        <p className="h3 text-center">
          <span className="h2">Ooops,</span> The page you are looking for cannot be found!
        </p>
      </div>
    </DashboardLayout>
  );
};

export default NotFound;
