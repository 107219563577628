import React, { useState, useEffect } from "react";
import "./working-card-widget.style.css";
import { Badge, Card, CardBody, Button } from "reactstrap";
import logo from "../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMars,
  faVenus,
  faPhone,
  faEnvelope,
  faCalendar,
  faPhoneSlash,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import ModalComponent from "../../components/Modal/modalComponent";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import EmailNav from "../../components/Modal/ModalBody/emailNav";

const DocViewCallingWidget = (props) => {
  const { userProfile } = props;
  const [calendarShow, setCalendarShow] = useState(false);
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const [certificates, setCertificates] = useState([]);

  const [showEmailModal, setShowEmailModal] = useState(false);

  const toggleM = () => setShowEmailModal(!showEmailModal);

  useEffect(() => {
    axios
      .get("http://207.246.85.110/mockData/doc-view-calling.json")
      .then((res) => {
        setCertificates(res.data.data);
      });
  }, []);

  return (
    <div style={{ minWidth: "800px" }}>
      {certificates.map((post, i) => {
        return (
          <div
            key={i}
            className=" d-flex flex flex-row main  p-1 "
            style={{ marginBottom: "5px", flex: 1 }}
          >
            <Link
              to={{
                pathname: "/dashboard/candidate-profile/1",
              }}
            >
              <div
                className="d-flex align-items-center p-1 mr-3"
                style={{ flex: 1 }}
              >
                {/* <image src={logo} /> */}
                <span className="w-64 avatar ">
                  <img src={post.image} alt="."></img>
                </span>
                {/* <i className="on"></i> */}
              </div>
            </Link>
            <div className="pl-2 d-flex flex-column " style={{ flex: 1 }}>
              <div className="d-flex mx-3 mb-1">
                <div>
                  {post.firstName} {post.lastName}{" "}
                  <FontAwesomeIcon
                    color="#458AF7"
                    icon={post.gender == "male" ? faMars : faVenus}
                  />
                </div>
              </div>
              <div className=" d-flex ">
                <div className="mx-3">
                  <Link
                    to={{
                      pathname: "/dashboard/call",
                      aboutParams: {
                        imageLink: post.image,
                      },
                    }}
                  >
                    <RoundIconButton
                      buttonColor="#00C426"
                      icon={faPhone}
                      iconSize="fa-md"
                      buttonSize="25px"
                    ></RoundIconButton>
                  </Link>
                </div>
                <div className="mx-3 ">
                  <RoundIconButton
                    buttonColor="#458AF7"
                    icon={faEnvelope}
                    click={() => toggleM()}
                    buttonSize="25px"
                  ></RoundIconButton>
                </div>
                <div className=" mx-3  d-flex align-items-center ">
                  <FontAwesomeIcon
                    color="#FF5603"
                    icon={faCalendarAlt}
                    onClick={() => handleCalendarShow(true)}
                    className="fa-lg mb-2 btn-raised btn-wave "
                  />
                </div>
              </div>
            </div>{" "}
            <div className="d-flex align-items-center mr-3">
              <Badge color="secondary">RGN</Badge>
            </div>
          </div>
        );
      })}
      <ModalComponent
        show={calendarShow}
        header="Reschedule a call"
        closeModal={() => handleCalendarShow(false)}
      >
        <SmallCalendar />{" "}
        <div className="mt-3">
          {" "}
          <div className="d-flex justify-content-end">
            <Button className="mr-2" color="success">
              Send
            </Button>{" "}
            <Button color="secondary" onClick={() => handleCalendarShow(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        show={showEmailModal}
        header="Email and SMS"
        closeModal={toggleM}
      >
        <EmailNav />
        <div className="mt-3">
          {" "}
          <div className="d-flex justify-content-end">
            <Button className="mr-2" color="success">
              Send
            </Button>{" "}
            <Button color="secondary" onClick={toggleM}>
              Cancel
            </Button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};
export default DocViewCallingWidget;
