import React, { useState, useEffect } from "react";
import "./working-card-widget.style.css";
import { Badge, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import axios from "axios";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import ModalComponent from "../../components/Modal/modalComponent";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import {
  AUDIT_WORKSPACE_DATA,
  RESCHEDULE,
  HOSTMexxar,
  HostMexxarURL,
} from "../../configs/api-config";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import { isNull } from "lodash";
import moment from "moment";

import {
  ApplicationStatus,
  ProfileStatus,
  GenderSwitch,
  ConversationResponse,
} from "../../util/common";
import { result } from "lodash";
import AvatarMexxar from "../../util/common/avatarMexxar";
import { connect } from "react-redux";
const WorkingCardAuditWidget = (props) => {
  const { userProfile, tab,auth } = props;
  const MexxarApi = AUDIT_WORKSPACE_DATA;
  const MexxarApiReschedule = RESCHEDULE;
  const MexxarUrl = HostMexxarURL;

  // const [rows, setRows] = useState([]);
  // const [rowID, setRowID] = useState(false);

  const { addToast } = useToasts();
  const MexxarHostAPI = HOSTMexxar;
  const MexxarUrlHost = HOSTMexxar;
  const handleSelectedCandidate = (userProfile) =>
    setSelectedCandidate(userProfile);
  const [selectedCandidate, setSelectedCandidate] = useState({});

  const [calendarShow, setCalendarShow] = useState(false);

  const [showEmailModal, setShowEmailModal] = useState(false);

  const [profilePicture, setProfilePicture] = useState();

  const toggleShowEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };
  console.log("auth",auth?.item?.firstName)
  
  const toggleM = () => setShowEmailModal(!showEmailModal);

  // const resheduleCall = (data) => {
  //   let rescheduleDate = moment(data.date).add(data.time).toISOString();
  //   axios
  //     .post(MexxarApiReschedule, {
  //       callerId: 1,
  //       receiverId: rowID,
  //       callDate: moment(new Date()).toISOString(),
  //       rescheduleDate: rescheduleDate,
  //     })
  //     .then((res) => {
  //       //  setCandidateId(res.data.body);
  //       ToastCommon(true, "success", addToast);
  //       setCalendarShow(!calendarShow);
  //     })
  //     .catch((err) => {
  //       //console.log("erorrrrrrrrrrrrrrrr", err);
  //       ToastCommon(true, "error", addToast);
  //     });
  // };

  // function getBase64(file, cb) {
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = function () {
  //     cb(reader.result);
  //   };
  //   reader.onerror = function (error) {
  //     console.log("Error: ", error);
  //   };
  // }

  // const loadCandidateData = () => {
  //   axios
  //     .get(MexxarHostAPI + "candidates/" + userProfile.candidateId)
  //     .then((res) => {
  //       if (
  //         res.data.body[0].avatar == null ||
  //         res.data.body[0].avatar == undefined
  //       ) {
  //         res.data.body[0].gender === "MALE"
  //           ? setProfilePicture(
  //               "http://207.246.85.110/images/maleCandidate.png"
  //             )
  //           : setProfilePicture(
  //               "http://207.246.85.110/images/femaleCandidate.png"
  //             );
  //       } else {
  //         const config = { responseType: "blob" };
  //         axios
  //           .get(
  //             MexxarHostAPI +
  //               String(res.data.body[0].avatar).replace("pro_pic", "pro-pic"),
  //             config
  //           )
  //           .then((file) => {
  //             getBase64(new File([file.data], "sample"), (result) => {
  //               setProfilePicture(result);
  //             });
  //           });
  //       }
  //     });
  // };

  // const getAllRows = () => {
  //   axios
  //     .get(MexxarApi)
  //     .then((res) => {
  //       console.log("hello", res.data.body);
  //       //setRows(res.data.body);
  //       let result = [];

  //       forEach(res.data.body, function (candidate) {
  //         let FCF = filter(candidate.frameworkPercentages, (item) => {
  //           return item.completePercentage === 0;
  //         });

  //         console.log("LEngth of FCF", FCF.length);

  //         if (FCF.length === 0) {
  //         } else {
  //           result.push({
  //             candidateName: candidate.candidateName,
  //             employeeId: candidate.employeeId,
  //             gender: candidate.gender,
  //             pro_pic: candidate.pro_pic,
  //             compliantOn: candidate.compliantOn,
  //             profileStatus: candidate.profileStatus,
  //             framework: FCF.frameworkName,
  //           });
  //         }

  //         setRows(result);
  //         console.log("result", result);
  //       });
  //     })
  //     .catch((err) => {
  //       //console.log(err)
  //       ToastCommon(true, "error", addToast);
  //     });
  // };
  // useEffect(() => {
  //   loadCandidateData();

  //   getAllRows();
  // }, []);
  // console.log("userprofile ****************************", userProfile);
  // console.log("audit workspace arrange data set", rows);

  
  return (
    <>
      <div
        className=" card d-flex flex-row main bg-light p-1 "
        style={{ minWidth: "900px", marginBottom: "5px" }}
      >
        <AvatarMexxar
          candidateId={userProfile?.candidateId}
          primaryPhone={userProfile?.primaryPhone}
          avatar={userProfile?.pro_pic}
          gender={userProfile?.gender}
        />
        <div
          className="d-flex  align-items-center"
          style={{ flex: 3, maxWidth: "150px" }}
        >
           <div className="d-flex flex-column ml-1 mb-1 mt-1">
      <div>{userProfile.candidateName}</div>
<br/>
{/* <span className="badge badge-success  text-uppercase" >{auth?.item?.firstName} {auth?.item?.lastName}</span> */}

  
    </div>
          
        </div>
        <div className="d-flex  align-items-center" style={{ flex: 1 }}>
          <FontAwesomeIcon
            // color={row.gender == "1" ? "#458AF7" : "#f54394"}
            color={GenderSwitch(userProfile.gender).genderColor}
            // icon={row.gender == "1" ? faMars : faVenus}
            icon={GenderSwitch(userProfile.gender).genderSymbol}
          />
        </div>
        <div
          className=" d-flex align-items-center  justify-content-center  flex-column "
          style={{ flex: 2 }}
        >
          <div>
            {" "}
            <Badge color="primary">{userProfile.framework}</Badge>
          </div>
          <div>
            {" "}
            <Badge color="info">{userProfile.speciality}</Badge>
          </div>
        </div>
        <div className="d-flex  align-items-center" style={{ flex: 2 }}></div>
        <div
          className="d-flex flex-column  align-items-center "
          style={{ flex: 3 }}
        >
          <div style={{ height: "4vh" }}>Profile Status</div>
          <div style={{ height: "3vh" }}>
            <div className="d-flex  align-items-center" style={{ flex: 1 }}>
              <Badge
                color={ProfileStatus(userProfile.profileStatus).badgeColor}
              >
                {ProfileStatus(userProfile.profileStatus).badgeLable}
              </Badge>
            </div>
          </div>
          <div style={{ height: "3vh" }}></div>
        </div>
      
        {/* <div style={{ flex: 1 }}>
                  <div style={{ height: "4vh", flex: 1 }}></div>
                  <div style={{ height: "3vh", flex: 1 }}></div>
                  <div style={{ height: "3vh", flex: 1 }}></div>
                </div> */}
        <div
          className="d-flex flex-column  align-items-center "
          style={{ flex: 4 }}
        >
          <div style={{ height: "4vh" }}>Submitted to Audit</div>

          <div className="d-flex justify-content-center ">
            {isNull(userProfile.compliantOn) ? (
              <></>
            ) : (
              <>
                {" "}
                <span className="mr-1">
                  <small className="text-muted">
                    {moment(userProfile.compliantOn).format("ddd MMM DD YYYY")}
                  </small>
                </span>
                <span className="mr-1">
                  <small className="text-muted">
                    @{moment(userProfile.compliantOn).format("hh:mm a")}
                  </small>
                </span>
              </>
            )}
          </div>


          
          <div style={{ height: "3vh" }}></div>
        </div>
        
        <div
          className="d-flex align-items-center justify-content-center "
          style={{ flex: 3 }}
        >
          <Link
            to={{
              pathname:
                "/dashboard/candidate-profile/" +
                userProfile.candidateId +
                "/+4545454545/compliance",
            }}
          >
            <Button
              style={{ width: "70px" }}
              className="bg-success text-light no-border"
            >
              Show
            </Button>
          
          </Link>
        </div>

        
        <div
          className={`modal fade bd-example-modal-sm  ${
            calendarShow ? "show" : ""
          }`}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          style={{ display: `${calendarShow ? "block" : "none"}` }}
          aria-hidden={!calendarShow}
        ></div>
      </div>

      {/* <ModalComponent show={calendarShow} header="Reschedule a call"
                closeModal={() => setCalendarShow(false)}
>
        <SmallCalendar
          onCreate={(data) => resheduleCall(data)}
          closeModal={() => setCalendarShow(false)}
        />
      </ModalComponent> */}

      {/* <ModalComponent show={showEmailModal} header="E mail and sms"
                closeBody={(data) => toggleM(data)}
>
        <EmailNav
          closeBody={(data) => toggleM(data)}
          user={selectedCandidate}
        />
      </ModalComponent> */}
    </>
  );
};



function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})( WorkingCardAuditWidget);

