import React, { useState, useEffect } from "react";
import axios from "axios";
import filter from "lodash/filter";
import { isNull, toLower } from "lodash";
import { Button, Input } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import { HOSTMexxar } from "../../../configs/api-config";
import NoConnection from "../../errorPages/NoConnection";
import ToastCommon from "../../../components/toastCommon";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import Delete from "../../../components/Modal/ModalBody/delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import ConfigureWards from "../../../components/Modal/ModalBody/Admin/configureWards";
import { FunnelIcon } from "../../../assets/icons/svg";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((item) => {
      let searchableString = `${item.wardName} ${item.email} `;

      let p = toLower(searchableString);
      return p.includes(name);
    });
  }
  let lengthofSearch = filtered.length;
  return { filtered, lengthofSearch };
};

const ClientsWards = () => {
  let pathArray = window.location.pathname.split("/");
  var ClientId = Number(pathArray[4]);

  const MexxarApi = HOSTMexxar;

  const { addToast } = useToasts();

  const [mode, setMode] = useState("");
  const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState(0);
  const [error, setError] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showWards, setShowWards] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [clientName, setClientName] = useState(null);
  const [rowNameData, setRowNameData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showAddRow, setShowAddRow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);
  const [showConfigWards, setShowConfigWards] = useState(false);

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getAllRows = () => {
    axios
      .get(MexxarApi + "client-wards/clients/" + ClientId)
      .then((res) => {
        setLoading(false);

        if (res.data.body.length > 0) {
          setClientName(res.data.body[0].clientName);
        }
        setRows(res.data.body);
        //   setContactPersons(res.data.body[0].phoneNumbers);
      })
      .catch((err) => {
        setError(err);
        ToastCommon(true, "error", addToast);
      });
  };

  const addNewWard = (data) => {
    if (mode == "Add") {
      axios
        .post(MexxarApi + "client-wards", data)
        .then((res) => {
          if (res.data.status == "success") {
            setShowConfigWards(!showConfigWards);

            ToastCommon(true, "success", addToast);
            getAllRows();
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          ToastCommon(true, "error", addToast);
        });
    } else {
      axios
        .put(MexxarApi + "client-wards", data)
        .then((res) => {
          if (res.data.status == "success") {
            setShowConfigWards(!showConfigWards);

            ToastCommon(true, "success", addToast);
            getAllRows();
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          ToastCommon(true, "error", addToast);
        });
    }
  };

  // const addRow = (data) => {
  //   axios
  //     .post(MexxarApi, {
  //       name: data.name,
  //       email: data.email,
  //       primaryMobile: data.primaryMobile,
  //       secondaryMobile: data.secondaryMobile,
  //     })
  //     .then((res) => {
  //       if (res.data.status == "success") {
  //         getAllRows();
  //         setShowAddRow(!showAddRow);
  //         ToastCommon(true, "success", addToast);
  //       } else {
  //         //toastCommon(true, "duplicate");
  //       }
  //     })
  //     .catch((err) => {
  //       //console.log(err)
  //       // toastCommon(true, "error");
  //     });
  // };

  // const updateRow = (data) => {
  //   axios
  //     .put(MexxarApi, {
  //       id: rowID,
  //       name: data.name,
  //       email: data.email,
  //     })
  //     .then((res) => {
  //       if (res.data.status == "success") {
  //         getAllRows();
  //         setShowUpdateRow(!showUpdateRow);
  //         ToastCommon(true, "success", addToast);
  //       } else {
  //         // toastCommon(true, "duplicate");
  //       }
  //     })
  //     .catch((err) => {
  //       //console.log(err)
  //       // toastCommon(true, "error");
  //     });
  // };

  const deleteRow = () => {
    //console.log(rowID);

    axios
      .delete(MexxarApi + "client-wards/" + rowID)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "delete", addToast);
          getAllRows();
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const deleteAllRows = () => {
    //console.log(rowID);
    selectedItems.forEach((item) => {
      axios
        .delete(MexxarApi + "client-wards/" + item)
        .then((res) => {
          getAllRows();
          setSelectedItems([]);
          setSelectAll(false);
        })
        .catch((err) => {
          ToastCommon(true, "error", addToast);
        });
    });
  };

  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
    } else {
      setSelectedItems([...selectedItems, rowId]);
    }
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  useEffect(() => {
    getAllRows();
  }, []);

  return (
    <DashboardLayout
      title={
        rows.length > 0
          ? "Departments of " + clientName
          : "No Departments Added"
      }
      subTitle="Manage Departments "
    >
      <div className="form-inline">
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          className="form-control no-border no-shadow no-bg typeahead tt-input "
          placeholder="Search Departments..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
          }}
        />
      </div>
      <div className="d-flex justify-content-between p-2 ml-3">
        <div>
          {selectedItems.length > 0 ? (
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                setShowDeleteAll(true);
              }}
            >
              Delete
            </Button>
          ) : null}
        </div>
        <div>
          {" "}
          {/* <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddRow(true);
            }}
          >
            Add Department
          </Button> */}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <table
            id="datatable"
            className="table table-theme table-row v-middle dataTable no-footer"
            role="grid"
            aria-describedby="datatable_info"
          >
            <thead>
              <tr role="row">
                <th
                  className="sorting_asc"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  aria-sort="ascending"
                  aria-label="ID: activate to sort column descending"
                  style={{ width: "26px" }}
                >
                  <label className="ui-check m-0">
                    <input
                      type="checkbox"
                      name="id"
                      checked={selectAll}
                      onChange={() => checkAll()}
                    />{" "}
                    <i></i>
                  </label>
                </th>
                <th
                  className="sorting_asc"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  aria-sort="ascending"
                  aria-label="ID: activate to sort column descending"
                  style={{ width: "26px" }}
                >
                  <span className="text-muted">ID</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  aria-label="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Department</span>
                </th>

                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  aria-label="Owner: activate to sort column ascending"
                >
                  <span className="text-muted"> </span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  aria-label="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Fax</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  aria-label="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Email</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  aria-label="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">T.p</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {error && (
                <tr>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td>
                    <NoConnection error={error}></NoConnection>
                  </td>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                </tr>
              )}

              {rows && !loading ? (
                filterByName(searchQuery, rows).lengthofSearch > 0 ? (
                  filterByName(searchQuery, rows).filtered.map((row, i) => {
                    return (
                      <tr className="odd" key={i} role="row">
                        <td
                          style={{ minWidth: "30px", textAlign: "center" }}
                          className="sorting_1"
                        >
                          <label className="ui-check m-0">
                            <input
                              type="checkbox"
                              name="id"
                              checked={isSelected(row.id)}
                              onChange={() => onItemSelect(row.id)}
                            />{" "}
                            <i></i>
                          </label>
                        </td>
                        <td
                          style={{ minWidth: "30px", textAlign: "center" }}
                          className="sorting_1"
                        >
                          <small className="text-muted">{i + 1}</small>
                        </td>
                        <td className="">{row.wardName}</td>
                        <td className=""></td>
                        {isNull(row.fax) ? "" : <td className="">{row.fax}</td>}
                        {isNull(row.email) ? (
                          ""
                        ) : (
                          <td className="">{row.email}</td>
                        )}
                        <td className="">
                          {row.phoneNumbers.length > 0 &&
                          row.phoneNumbers != undefined ? (
                            <>
                              {row.phoneNumbers.length > 0
                                ? row.phoneNumbers[0].phoneNumber
                                : ""}
                            </>
                          ) : (
                            ""
                          )}
                        </td>

                        <td className=" ">
                          <div className="d-flex justify-content-end align-items-center">
                            {" "}
                            <div>
                              {" "}
                              {/* <FontAwesomeIcon
                          // color="#448bff"
                          icon={faEdit}
                          className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3 "
                          onClick={() => {
                            setShowUpdateRow(true);
                            setRowID(row.id);
                            setRowNameData(row.name);
                          }}
                          size="1x"
                        /> */}
                            </div>
                            <div>
                              {" "}
                              <FontAwesomeIcon
                                // color="#FF5603"
                                icon={faTrashAlt}
                                onClick={() => {
                                  setShowDeleteRow(!showDeleteRow);
                                  // setMode("Update");
                                  // setShowConfigWards(true);
                                  setRowID(row.id);
                                }}
                                className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave"
                                size="1x"
                              />
                            </div>
                            <div>
                              <Button
                                color="primary "
                                size="sm"
                                onClick={() => {
                                  setMode("Update");
                                  setShowConfigWards(true);
                                  setRowID(row.id);
                                  setRowData(row);
                                }}
                              >
                                Show Contacts
                              </Button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                    <td>
                      <NoSearchResults />
                    </td>
                    <td> </td>
                    <td> </td>
                    <td> </td>
                  </tr>
                )
              ) : null}

              {loading && !error && (
                <tr>
                  <td> </td>
                  <td> </td>
                  <td> </td>
                  <td></td>
                  <td>
                    <img src={loadingAnimation} style={{ height: 100 }}></img>
                  </td>
                  <td> </td>
                  <td> </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <ModalComponent
        show={showConfigWards}
        header="Configure departments "
        className="modal-dialog modal-lg"
        scrollable={true}
        closeModal={() => setShowConfigWards(false)}
      >
        <ConfigureWards
          mode={mode}
          rowData={rowData}
          clientId={ClientId}
          onCreate={(data) => addNewWard(data)}
          closeModal={() => setShowConfigWards(false)}
        />
      </ModalComponent>

      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteRow();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(!showDeleteRow)}
      />
      <Delete
        show={showDeleteAll}
        isConfirm={() => {
          deleteAllRows();
          setShowDeleteAll(!showDeleteAll);
        }}
        closeModal={() => setShowDeleteAll(!showDeleteAll)}
      />
    </DashboardLayout>
  );
};

export default ClientsWards;
