import { useEffect, useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";
import moment from "moment";

export default function useFetch(
  url,
  pageNumber,
  searchAdvanced,
  resetResults,
  reload,
  getStartDate,
  getEndDate ,
  importantStatus
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  useDidMountEffect(() => {
    setRows([]);
  }, [searchAdvanced]);

  useDidMountEffect(() => {
    setRows([]);
  }, [reload, resetResults]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      url: url,
      params: { 
        page: pageNumber, 
        offset: 1000, 
        q: "", 
        startDate: moment(getStartDate).format("YYYY/MM/DD"), 
        endDate: moment(getEndDate).format("YYYY/MM/DD"),
        important: importantStatus
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setTotalElements(res.data.body.totalElements);
        const sortedData = res.data.body.content.sort((a, b) => {
          var dateA = new Date(a.endTime).getTime();
          var dateB = new Date(b.endTime).getTime();
          return dateB - dateA;
        });

        setRows((prevBooks) => {
          return [...new Set([...prevBooks, ...sortedData])];
        });

        setHasMore(res.data.body.content.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [searchAdvanced, pageNumber, resetResults, reload]);

  return { loading, error, rows, hasMore, totalElements };
}
