import React, { useState, useEffect } from "react";
import "./working-card-widget.style.css";
import { Badge, Card, CardBody, Button } from "reactstrap";
import logo from "../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

import {
  faMars,
  faVenus,
  faCaretSquareRight,
  faCaretSquareLeft,
  faPhone,
  faEnvelope,
  faCalendar,
  faPhoneSlash,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import ModalComponent from "../../components/Modal/modalComponent";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import {
  RESCHEDULE_REGISTRATION_WORKSPACE,
  RESCHEDULE,
  HOSTMexxar,
  HostMexxarURL,
} from "../../configs/api-config";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

import {
  ApplicationStatus,
  GenderSwitch,
  ConversationResponse,
} from "../../util/common";

const RegistrationWorkingCardWidget = (props) => {
  const { userProfile, tab, auth } = props;
  //  const MexxarApi = RESCHEDULE_REGISTRATION_WORKSPACE;
  const MexxarApiReschedule = RESCHEDULE;
  const MexxarUrl = HostMexxarURL;
  const MexxarHostAPI = HOSTMexxar;

  // const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState(false);
  const [showLastContacted, setShowLastContacted] = useState(false);

  const { addToast } = useToasts();

  const [calendarShow, setCalendarShow] = useState(false);

  const [showEmailModal, setShowEmailModal] = useState(false);
  const handleShowLastContacted = () =>
    setShowLastContacted(!showLastContacted);
  const toggleShowEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };
  const handleSelectedCandidate = (userProfile) =>
    setSelectedCandidate(userProfile);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [profilePicture, setProfilePicture] = useState();

  const toggleM = () => setShowEmailModal(!showEmailModal);

  // const loadCandidateData = () => {
  //   axios
  //     .get(MexxarHostAPI + "candidates/" + userProfile.candidateId)
  //     .then((res) => {
  //       if (
  //         res.data.body[0].avatar == null ||
  //         res.data.body[0].avatar == undefined ||
  //         res.data.body[0].avatar == ""
  //       ) {
  //         res.data.body[0].gender === "MALE"
  //           ? setProfilePicture(
  //               "http://207.246.85.110/images/maleCandidate.png"
  //             )
  //           : setProfilePicture(
  //               "http://207.246.85.110/images/femaleCandidate.png"
  //             );
  //       } else {
  //         const config = { responseType: "blob" };
  //         axios
  //           .get(
  //             MexxarHostAPI +
  //               String(res.data.body[0].avatar).replace("pro_pic", "pro-pic"),
  //             config
  //           )
  //           .then((file) => {
  //             getBase64(new File([file.data], "sample"), (result) => {
  //               setProfilePicture(result);
  //             });
  //           });
  //       }
  //     });
  // };

  // function getBase64(file, cb) {
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = function () {
  //     cb(reader.result);
  //   };
  //   reader.onerror = function (error) {
  //     console.log("Error: ", error);
  //   };
  // }

  const resheduleCall = (data) => {
    let rescheduleDate = moment(data.date).add(data.time).toISOString();
    axios
      .post(MexxarApiReschedule, {
        callerId: auth.item.id,
        receiverId: userProfile.candidateId,
        callDate: moment(new Date()).toISOString(),
        rescheduleDate: rescheduleDate,
      })
      .then((res) => {
        //  setCandidateId(res.data.body);
        ToastCommon(true, "success", addToast);
        setCalendarShow(!calendarShow);
      })
      .catch((err) => {
        //console.log("erorrrrrrrrrrrrrrrr", err);
        ToastCommon(true, "error", addToast);
      });
  };

  // useEffect(() => {
  //   loadCandidateData();
  // }, []);

  return (
    <>
      <div
        className=" card d-flex flex-row main bg-light p-1 "
        style={{ minWidth: "900px", marginBottom: "5px" }}
      >
        <div className="d-flex align-items-center p-1">
          <Link
            to={{
              pathname:
                "/dashboard/candidate-profile/" +
                userProfile.candidateId +
                "/" +
                userProfile.primaryPhone,
            }}
          >
            {/* <image src={logo} /> */}
            <span
              className="w-64 avatar"
              style={{
                maxHeight: "10rem",
                maxWidth: "10rem",
                borderRadius: "50%",
              }}
            >
              <img
                src={
                  userProfile.avatar == null
                    ? userProfile.gender === "MALE"
                      ? MexxarUrl + "images/maleCandidate.png"
                      : MexxarUrl + "images/femaleCandidate.png"
                    : null
                }
                alt=""
                style={{
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "cover",
                }}
              ></img>
            </span>
            {/* <i className="on"></i> */}
          </Link>
        </div>
        <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
          <div className="d-flex ml-1 mb-1">
            <div>
              {userProfile.candidateFirstName} {userProfile.candidateLastName}{" "}
            </div>
          </div>
          <div className=" d-flex ">
            <div className="mx-2 ">
              {/* <Link
                    to={{
                      pathname: "/dashboard/call/"+userProfile.candidateId+"/"+userProfile.primaryPhone,
                      aboutParams: {
                        imageLink: userProfile.image,
                      },
                    }} */}
              <Link to={"/dashboard/call/" + userProfile.candidateId}>
                <RoundIconButton
                  buttonColor="#00C426"
                  icon={faPhone}
                  iconSize="fa-md"
                  buttonSize="25px"
                ></RoundIconButton>
              </Link>
            </div>
            <div className="mx-2 ">
              <RoundIconButton
                buttonColor="#458AF7"
                icon={faEnvelope}
                buttonSize="25px"
                click={() => {
                  toggleShowEmailModal();
                  handleSelectedCandidate(userProfile);
                }}
              ></RoundIconButton>
            </div>
            <div className=" mx-2  d-flex align-items-center ">
              <FontAwesomeIcon
                color="#FF5603"
                icon={faCalendarAlt}
                onClick={() => {
                  setCalendarShow(!calendarShow);
                  setRowID(userProfile.candidateId);
                }}
                className="fa-lg mb-2 btn-raised btn-wave "
              />
            </div>
          </div>
        </div>
        <div className="d-flex  align-items-center" style={{ flex: 1 }}>
          <FontAwesomeIcon
            // color={userProfile.gender == "1" ? "#458AF7" : "#f54394"}
            color={GenderSwitch(userProfile.gender).genderColor}
            // icon={userProfile.gender == "1" ? faMars : faVenus}
            icon={GenderSwitch(userProfile.gender).genderSymbol}
          />
        </div>

        <div
          className="d-flex align-items-center justify-content-center flex-column"
          style={{ flex: 3 }}
        >
          <div style={{ height: "3vh", flex: 1 }}>Call Response</div>
          <div style={{ height: "3vh", flex: 1 }}>
            <Badge
              color={
                ConversationResponse(userProfile.candidateCallResponseStatus)
                  .badgeColor
              }
            >
              {
                ConversationResponse(userProfile.candidateCallResponseStatus)
                  .badgeLable
              }
            </Badge>
          </div>
          <div style={{ height: "3vh", flex: 1 }}></div>
        </div>

        <div className="d-flex  align-items-center" style={{ flex: 2 }}></div>
        <div className="d-flex  align-items-center" style={{ flex: 3 }}>
          <div style={{ height: "7vh", flex: 1 }}>
            <div className="flex-row">
              <div>
                <span
                  style={{ marginLeft: "5px" }}
                  className="badge badge-circle text-danger mr-1"
                  data-original-title="Required"
                  data-placement="top"
                  data-toggle="tooltip"
                ></span>
                Expired {userProfile.expiredDocCount}
              </div>
              <div>
                <span
                  style={{ marginLeft: "5px" }}
                  className="badge badge-circle text-warning mr-1"
                  data-original-title="Required"
                  data-placement="top"
                  data-toggle="tooltip"
                ></span>
                Expiring {userProfile.expiringDocCount}
              </div>
              <div></div>
            </div>
          </div>
        </div>
        {/* <div style={{ flex: 1 }}>
                  <div style={{ height: "4vh", flex: 1 }}></div>
                  <div style={{ height: "3vh", flex: 1 }}></div>
                  <div style={{ height: "3vh", flex: 1 }}></div>
                </div> */}
        <div className="d-flex flex-column " style={{ flex: 4 }}>
          {userProfile.lastContactedUserName === null ? null : (
            <div>
              {!showLastContacted ? (
                <div>
                  <div
                    style={{
                      height: "4vh",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="d-flex justify-content-between">
                      <lable></lable>
                      <lable>Reschedule</lable>
                      <FontAwesomeIcon
                        icon={faCaretSquareRight}
                        onClick={() => handleShowLastContacted()}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <div style={{ height: "3vh" }}>
                      {userProfile.rescheduledCallerName}
                    </div>
                    <div className="d-flex justify-content-center ">
                      <span className="mr-1">
                        <small className="text-muted">
                          {moment(userProfile.rescheduledDate).format(
                            "ddd MMM DD YYYY"
                          )}
                        </small>
                      </span>
                      <span className="mr-1">
                        <small className="text-muted">
                          {" "}
                          {moment(userProfile.rescheduledDate).format(
                            "hh:mm a"
                          )}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div style={{ height: "4vh" }}>
                    <div className="d-flex justify-content-between">
                      <lable></lable>
                      <lable>Last Contacted By</lable>
                      <FontAwesomeIcon
                        icon={faCaretSquareLeft}
                        onClick={() => handleShowLastContacted()}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column  align-items-center ">
                    <div style={{ height: "3vh" }}>
                      {userProfile.lastContactedUserName}
                    </div>
                    <div className="d-flex justify-content-center ">
                      <span className="mr-1">
                        <small className="text-muted">
                          {moment(userProfile.lastContactedDate).format(
                            "ddd MMM DD YYYY"
                          )}
                        </small>
                      </span>
                      <span className="mr-1">
                        <small className="text-muted">
                          {" "}
                          {moment(userProfile.lastContactedDate).format(
                            "hh:mm a"
                          )}
                        </small>
                      </span>
                    </div>{" "}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div style={{ flex: 4 }}>
          <div
            className="d-flex justify-content-center mb-1 "
            style={{ maxHeight: "6vh" }}
          >
            Number of Calls
          </div>
          <div
            className=" d-flex justify-content-around "
            style={{ height: "3vh" }}
          >
            <div>
              <FontAwesomeIcon
                color="#00C426"
                icon={faPhone}
                className="fa-flip-horizontal"
              />
            </div>
            <div>
              <FontAwesomeIcon
                color="#F80055"
                icon={faPhoneSlash}
                className="fa-flip-horizontal"
              />
            </div>
          </div>
          <div
            className="d-flex justify-content-around"
            style={{ height: "3vh" }}
          >
            <div>{userProfile.answeredCount}</div>
            <div>{userProfile.notAnsweredCount}</div>
          </div>
        </div>
        <div
          className={`modal fade bd-example-modal-sm  ${
            calendarShow ? "show" : ""
          }`}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          style={{ display: `${calendarShow ? "block" : "none"}` }}
          aria-hidden={!calendarShow}
        ></div>
      </div>

      <ModalComponent
        show={calendarShow}
        header="Reschedule a call"
        closeModal={() => setCalendarShow(false)}
      >
        <SmallCalendar
          onCreate={(data) => resheduleCall(data)}
          closeModal={() => setCalendarShow(false)}
        />
      </ModalComponent>

      <ModalComponent
        show={showEmailModal}
        header="E mail and sms"
        closeModal={() => toggleM()}
      >
        <EmailNav closeModal={() => toggleM()} user={selectedCandidate} />
      </ModalComponent>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(RegistrationWorkingCardWidget);
