import React, { useState, useEffect } from "react";
import axios from "axios";
import "./profile.style.css";
import { forEach } from "lodash";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../components/toastCommon";
import loadingAnimation from "../../../../src/images/puff.svg";
import { Button, Input, Label, Form, Badge } from "reactstrap";
import IsPrivileged from "../../../util/auth/privilegeHelper.js";
import { ModalBody, ModalFooter, ModalHeader, Modal } from "reactstrap";
import { HOSTMexxar, HostMexxarURL } from "../../../configs/api-config";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";

const EditableInputUI = (props) => {
  const { title, id, data } = props;
  const [editable, setEditable] = useState(false);
  const handleEdit = () => {
    if (editable) {
      //edit call
    }
    setEditable(!editable);
  };
  const { addToast } = useToasts();

  return (
    <div className="form-group row flex">
      <label className="col-sm-4 col-form-label">{title}</label>

      <div className="col-sm-6">
        <input
          className={`form-control ${editable ? "border-inherit" : "border-0"}`}
          type="text"
          placeholder={data}
          disabled={!editable}
        />
      </div>
      <div className="col-sm-2 ">
        <button
          className="btn btn-outline-secondary "
          type="button"
          onClick={() => handleEdit()}
        >
          {editable ? "Save" : "Edit"}
        </button>
      </div>
    </div>
  );
};

const ProfileEmployee = (props) => {
  const MexxarAPI = HOSTMexxar;
  const MexxarURL = HostMexxarURL;

  const [summery, setSummery] = useState({});
  const [candidateConsultants, setCandidateConsultants] = useState({});

  const [uploadModal, setUploadModal] = useState(false);
  const [modalPreviewPicture, setModalPreviewPicture] = useState();
  const [profilePicture, setProfilePicture] = useState();

  const { register, handleSubmit, errors } = useForm();

  const [showHospitals, setShowHospitals] = useState(false);
  const [candidate, setCandidate] = useState({});
  const [editable, setEditable] = useState(true);
  const { addToast } = useToasts();
  const [employeeData, setEmployeeData] = useState([]);

  const [userRoles, setUserRoles] = useState(null);
  const [currentUserRoles, setCurrentUserRoles] = useState(0);

  const [candidateId, setCandidateId] = useState();

  const [value, setValue] = useState("");
  const [teams, setTeams] = useState();
  const [selectedTeams, setSelectedTeams] = useState(0);
  const [employeeGender, setEmployeeGender] = useState(employeeData.gender);
  const [employeeStatus, setEmployeeStatus] = useState(employeeData.status);
  // const employeeId = auth.item.id;

  const handleEdit = () => {
    if (editable) {
      //edit call
    }
    setEditable(!editable);
  };

  const saveProfileImage = (data) => {
    data.preventDefault();
    let form = new FormData();
    form.append("pro_pic", data.target[0].files[0]);
    axios
      .put(
        MexxarAPI + "employee/" + candidateId + "/upload-profile-picture/",
        form
      )
      .then((res) => {
        loadProfilePicture(res.data.body[0]);
        setUploadModal(false);
        window.location.reload();
      });
  };

  const upload = (data) => {
    let form = new FormData();
    form.append("pro_pic", data.target.files[0]);
    getBase64(data.target.files[0], (result) => {
      setModalPreviewPicture(result);
    });
  };

  const loadModalPreviewPicture = (data) => {
    if (data.avatar == null) {
      data.gender === "MALE"
        ? setModalPreviewPicture(MexxarURL + "images/maleCandidate.png")
        : setModalPreviewPicture(MexxarURL + "images/femaleCandidate.png");
    } else {
      let url = String(
        MexxarAPI + String(data.avatar).replace("pro_pic", "pro-pic")
      );
      const config = { responseType: "blob" };
      axios.get(url, config).then((res) => {
        getBase64(new File([res.data], "sample"), (result) => {
          setModalPreviewPicture(result);
        });
      });
    }
  };

  const loadProfilePicture = (data) => {
    if (data.avatar == null || data.avatar == "" || data.avatar.undefined) {
      data.gender === "MALE"
        ? setProfilePicture(MexxarURL + "images/maleEmployee.png")
        : setProfilePicture(MexxarURL + "images/femaleEmployee.png");
    } else {
      let url = String(
        MexxarAPI + String(data.avatar).replace("pro_pic", "pro-pic")
      );
      const config = { responseType: "blob" };
      axios.get(url, config).then((res) => {
        getBase64(new File([res.data], "sample"), (result) => {
          setProfilePicture(result);
        });
      });
    }
  };

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      // console.log("Error: ", error);
    };
  }

  ////....check if update or not the select field array fields......////
  const chekIfUpdateSelectField = (data, idList) => {
    //console.log("data reciving", data);
    let tempArray = [];

    if (data !== null) {
      forEach(data, function (item) {
        tempArray.push(item.id);
      });
    } else {
      tempArray = idList;
    }

    return tempArray;
  };

  const updateTargets = (data) => {
    axios
      .put(
        MexxarAPI +
          "employee/" +
          data.id +
          "/add_target?dailyTarget=" +
          0 +
          "&clearanceTarget=" +
          data.clearanceTarget +
          "&dailyCallingTarget=" +
          data.dailyCallingTarget +
          "&dailyCallSpokenTarget=" +
          data.dailyCallSpokenTarget
      )
      .then((res) => {
        if (res.data.status == "success" && res.data.message == "updated") {
          ToastCommon(true, "update", addToast);
        } else if (res.data.status == "failed") {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };
  const onSubmit = (data) => {
    let pathArray = window.location.pathname.split("/");
    const employeeId = Number(pathArray[3]);
    let employeeData = [];

    employeeData = {
      id: employeeId,
      firstName: data.firstName,
      lastName: data.lastName,
      // avatar,
      // email: data.email,
      // password,
      userRoleId: Number(data.employeeRole),
      companyPhoneNumber: data.primaryMobile,
      //dailyTarget: Number(data.dailyTarget),
      clearanceTarget: Number(data.clearanceTarget),
      dailyCallingTarget: Number(data.dailyCallingTarget),
      dailyCallSpokenTarget: Number(data.dailyCallSpokenTarget),
      teamId: Number(data.teamsId),
      gender: data.gender,
      emailPassword: data.emailPassword,
      status: data.status,
    };

    axios
      .put(MexxarAPI + "employees", employeeData)
      .then((res) => {
        if (res.data.status == "success" && res.data.message == "created") {
          ToastCommon(true, "success", addToast);
          console.log("employeeData", employeeData);

          updateTargets(employeeData);
        } else if (
          res.data.status == "success" &&
          res.data.message == "updated"
        ) {
          ToastCommon(true, "update", addToast);
        } else if (res.data.status == "failed") {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        console.log(err);
        ToastCommon(true, "error", addToast);
      });
  };

  const getData = () => {
    let pathArray = window.location.pathname.split("/");
    const employeeId = Number(pathArray[3]);

    axios.get(MexxarAPI + "user-role").then((res) => {
      setUserRoles(res.data.body);
    });

    axios
      .get(MexxarAPI + "employee/" + employeeId)
      .then((res) => {
        setEmployeeData(res.data.body);
        setCurrentUserRoles(res.data.body[0].employeeRoleId);
        setSelectedTeams(res.data.body[0].teamId);
        loadProfilePicture(res.data.body[0]);
        loadModalPreviewPicture(res.data.body[0]);
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const changeHandler = ({ target }) => {
    setValue(target.value.toUpperCase());
  };
  useEffect(() => {
    axios.get(MexxarAPI + "team").then((res) => {
      let tempArray = [];
      for (let i = 0; i < res.data.body.length; i++) {
        tempArray.push(res.data.body[i]);
      }
      setTeams(tempArray);
    });

    if (props.match.params.id != null) {
      setCandidateId(props.match.params.id);
      getData();
    }
  }, []);

  return (
    <>
      <DashboardLayout title="Employees" subTitle="Manage Employee" target="">
        {employeeData.length === 0 ? (
          <div className={"d-flex justify-content-center "}>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="card ">
                <Form onSubmit={handleSubmit(onSubmit)}>
                  <div className="card-header d-flex justify-content-between">
                    <h5 className="card-title heading-personal ">
                      Basic Details
                    </h5>
                    <div>
                      <IsPrivileged privilege={["SUPER_ADMIN", "ADMIN"]}>
                        {" "}
                        <Button className="mr-2" color="success" type="submit">
                          Save
                        </Button>
                      </IsPrivileged>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="row row-sm">
                      <div className=" candidateID-area col-md-12">
                        <div className="d-flex justify-content-center my-2  ">
                          <span className="w-64 avatar ">
                            <img
                              src={profilePicture ? profilePicture : ""}
                              onClick={() => setUploadModal(true)}
                              style={{
                                height: "4rem",
                                width: "4rem",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                            ></img>
                          </span>
                        </div>

                        {/* <span className="badge badge-warning text-uppercase mr-3">
                       { employeeData[0] == undefined
                                ? ""
                                : employeeData[0].employeeRole}
                        </span> */}

                        <Badge color="info">
                          id:
                          {employeeData[0] == undefined
                            ? ""
                            : employeeData[0].id}
                        </Badge>
                      </div>

                      <div className="col-md-12 col-sm-12 m-2"></div>
                      <div className="col-sm-6 col-md-4 ">
                        <div className="md-form-group float-label">
                          <label>First Name</label>
                          <Input
                            name="firstName"
                            id="firstName"
                            className={`${
                              editable ? "border-inherit" : "border-0"
                            }`}
                            defaultValue={
                              employeeData[0] == undefined
                                ? ""
                                : employeeData[0].firstName
                            }
                            disabled={!editable}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-4">
                        <div className="md-form-group float-label">
                          <label>Last Name</label>
                          <Input
                            name="lastName"
                            id="lastName"
                            className={`${
                              editable ? "border-inherit" : "border-0"
                            }`}
                            defaultValue={
                              employeeData[0] == undefined
                                ? ""
                                : employeeData[0].lastName
                            }
                            disabled={!editable}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                              maxLength: {
                                value: 30,
                                message:
                                  "This field only allowed only 30 characters",
                              },
                              pattern: {
                                value: /^[a-zA-Z]+$/,
                                message: "Letters only allowed for this field",
                              },
                            })}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6 col-md-4">
                        <div className="md-form-group float-label">
                          <label>Gender</label>
                          <Input
                            name="gender"
                            id="genderId"
                            type="select"
                            defaultValue={
                              employeeData[0] == undefined
                                ? ""
                                : employeeData[0].gender
                            }
                            className={`${
                              editable ? "border-inherit" : "border-0"
                            }`}
                            onChange={(data) => setEmployeeGender(data)}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          >
                            <option value="" style={{ color: "#6A6767" }}>
                              Select
                            </option>
                            <option value={"MALE"} style={{ color: "#6A6767" }}>
                              Male
                            </option>
                            <option
                              value={"FEMALE"}
                              style={{ color: "#6A6767" }}
                            >
                              Female
                            </option>
                            <option
                              value={"OTHER"}
                              style={{ color: "#6A6767" }}
                            >
                              Other
                            </option>
                          </Input>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12 ">
                        <label>Targets </label>
                        <hr />
                      </div>

                      <div className="col-sm-6 col-md-4">
                        <label>Clearance Target</label>
                        <Input
                          name="clearanceTarget"
                          id="clearanceTargetId"
                          defaultValue={
                            employeeData[0] == undefined
                              ? ""
                              : employeeData[0].clearanceTarget
                          }
                          className={`${
                            editable ? "border-inherit" : "border-0"
                          }`}
                          //  onChange={(data) => setEmployeeGender(data)}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <label>Daily Calling Target</label>
                        <Input
                          name="dailyCallingTarget"
                          id="dailyCallingTargetId"
                          defaultValue={
                            employeeData[0] == undefined
                              ? ""
                              : employeeData[0].dailyCallingTarget
                          }
                          className={`${
                            editable ? "border-inherit" : "border-0"
                          }`}
                          //  onChange={(data) => setEmployeeGender(data)}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <label>Daily Call Spoken Target</label>
                        <Input
                          name="dailyCallSpokenTarget"
                          id="dailyCallSpokenTargetId"
                          defaultValue={
                            employeeData[0] == undefined
                              ? ""
                              : employeeData[0].dailyCallSpokenTarget
                          }
                          className={`${
                            editable ? "border-inherit" : "border-0"
                          }`}
                          //  onChange={(data) => setEmployeeGender(data)}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                      </div>

                      <div className="col-sm-12">
                        <div className="md-form-group">
                          <label>Email Address</label>
                          <Input
                            name="email"
                            id="email"
                            disabled={true}
                            defaultValue={
                              employeeData[0] == undefined
                                ? ""
                                : employeeData[0].email
                            }
                            className={`${
                              editable ? "border-inherit" : "border-0"
                            }`}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                              maxLength: {
                                value: 50,
                                message:
                                  "This field only allowed only 50 characters",
                              },
                              pattern: {
                                value:
                                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Please enter a valid email address!",
                              },
                            })}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="md-form-group">
                          <label>Mobile Number (Primary)</label>
                          <Input
                            name="primaryMobile"
                            id="primaryMobile"
                            defaultValue={
                              employeeData[0] == undefined
                                ? ""
                                : employeeData[0].companyPhoneNumber
                            }
                            className={`${
                              editable ? "border-inherit" : "border-0"
                            }`}
                            disabled={!editable}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                              // pattern: {
                              //   value: /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                              //   message: "Please enter a valid phone number",
                              // },
                            })}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="md-form-group">
                          <Label for="teamsId">Team</Label>
                          <Input
                            id="teamsId"
                            name="teamsId"
                            type="select"
                            // isMulti
                            value={selectedTeams}
                            invalid={errors.wardId}
                            onChange={(e) => setSelectedTeams(e.target.value)}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          >
                            {teams &&
                              teams.map((item, id) => {
                                return (
                                  <option
                                    value={item.id}
                                    key={id}
                                    style={{ color: "#6A6767" }}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Input>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="md-form-group">
                          <label>Email Password</label>
                          <Input
                            name="emailPassword"
                            id="emailPassword"
                            type="password"
                            defaultValue={
                              employeeData[0] == undefined
                                ? ""
                                : employeeData[0].emailPassword
                            }
                            className={`${
                              editable ? "border-inherit" : "border-0"
                            }`}
                            disabled={!editable}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="md-form-group">
                          <label>Employee Role</label>
                          <Input
                            name="employeeRole"
                            id="employeeRoleId"
                            type="select"
                            // defaultValue={
                            //   employeeData[0] == undefined
                            //     ? ""
                            //     : employeeData[0].employeeRole
                            // }
                            className={`${
                              editable ? "border-inherit" : "border-0"
                            }`}
                            value={currentUserRoles}
                            onChange={(e) =>
                              setCurrentUserRoles(e.target.value)
                            }
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          >
                            {userRoles &&
                              userRoles.map((item, id) => {
                                return (
                                  <option
                                    value={item.id}
                                    key={id}
                                    style={{ color: "#6A6767" }}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                          </Input>
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="md-form-group">
                          <label>Employee Status</label>
                          <Input
                            name="status"
                            id="statusId"
                            type="select"
                            defaultValue={
                              employeeData[0] == undefined
                                ? ""
                                : employeeData[0].status
                            }
                            // value={employeeData[0]?.status || ""}
                            className={`${
                              editable ? "border-inherit" : "border-0"
                            }`}
                            onChange={(data) => setEmployeeStatus(data)}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          >
                            <option value="" style={{ color: "#6A6767" }}>
                              Select
                            </option>
                            <option value="ACTIVE" style={{ color: "#6A6767" }}>
                              ACTIVE
                            </option>
                            <option
                              value="INACTIVE"
                              style={{ color: "#6A6767" }}
                            >
                              INACTIVE
                            </option>
                            {/* {employeeData.map((employee, index) => (
    <option
      value={employee.status}
      key={index}
      style={{ color: "#6A6767" }}
    >
      {employee.status}
    </option>
  ))} */}
                          </Input>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
      </DashboardLayout>
      <Modal isOpen={uploadModal} scrollable={false}>
        <Form onSubmit={saveProfileImage}>
          {"Text" && (
            <ModalHeader className="modal-header bg-primary">
              <span style={{ color: "#ffffff" }}>
                {"Upload Profile Picture"}
              </span>
            </ModalHeader>
          )}
          <ModalBody className="p-4 ">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <label for="file-input">
                  <img
                    src={modalPreviewPicture}
                    width={"200px"}
                    height={"200px"}
                  ></img>
                </label>

                <input
                  id="file-input"
                  type="file"
                  onChange={upload}
                  style={{ display: "none" }}
                />
              </div>
              <div className="mt-2">
                {" "}
                <label>Click to change profile picture</label>
              </div>
              <div>
                {"modalActions" && (
                  <ModalFooter className="p-4">
                    <Button className="mr-2" type="submit" color="success">
                      Save
                    </Button>{" "}
                    <Button
                      color="danger"
                      onClick={() => {
                        axios
                          .delete(
                            MexxarAPI +
                              "employee/" +
                              props.userId +
                              "/delete-profile-picture"
                          )
                          .then((res) => {
                            if (res.data.status == "success") {
                              setModalPreviewPicture();

                              setUploadModal(false);
                              ToastCommon(true, "success", addToast);
                              // reset(!currentResetState);
                              window.location.reload();
                            } else {
                              ToastCommon(true, "error", addToast);
                            }
                          });
                      }}
                    >
                      Delete
                    </Button>{" "}
                    <Button
                      color="secondary"
                      onClick={() => {
                        setModalPreviewPicture();

                        setUploadModal(false);
                      }}
                    >
                      Close
                    </Button>
                  </ModalFooter>
                )}
              </div>
            </div>
          </ModalBody>
        </Form>
      </Modal>
    </>
  );
};

function mapStatetoProps(state) {
  return {
    userId: state.auth.item.userTypeId,
  };
}

export default connect(mapStatetoProps, {})(ProfileEmployee);
