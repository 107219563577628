import React, { useState, useEffect } from "react";
import {
  USERROLES,
  CONFIGURE_USER_ROLES_PERMISSIONS,
} from "../../../configs/api-config";
import axios from "axios";
import { isNull } from "lodash";
import filter from "lodash/filter";
import { Button } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../components/toastCommon";
import Delete from "../../../components/Modal/ModalBody/delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import NewTwoFields from "../../../components/Modal/ModalBody/Admin/newTwoFields";
import ConfigureUserRoles from "../../../components/Modal/ModalBody/Admin/configureUserRoles";

const ConsultantRoles = () => {
  const MexxarApi = USERROLES;
  const MexxarApiGetPermissions = CONFIGURE_USER_ROLES_PERMISSIONS;

  const { addToast } = useToasts();

  const [rows, setRows] = useState(null);
  const [rowID, setRowID] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [rowNameData, setRowNameData] = useState({});
  const [showAddRow, setShowAddRow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);

  const getAllRows = () => {
    axios
      .get(MexxarApi)
      .then((res) => {
        setRows(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };
  const getAllPermissions = () => {
    axios
      .get(MexxarApiGetPermissions)
      .then((res) => {
        setPermissions(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const addRow = (data) => {
    let duplicate_items = filter(rows, (item) => {
      return data.rowName == item.name;
    });
    if (duplicate_items.length > 0) {
      ToastCommon(true, "duplicate", addToast);
    } else {
      axios
        .post(MexxarApi, {
          name: data.rowName,
          description: data.rowFieldTwo,
        })
        .then((res) => {
          if (res.data.status == "success") {
            getAllRows();
            setShowAddRow(!showAddRow);
            ToastCommon(true, "success", addToast);
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    }
  };

  const deleteRow = () => {
    axios
      .delete(MexxarApi + "/" + rowID)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "delete", addToast);
          getAllRows();
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const updateRowPermissions = (data) => {
    axios
      .put(MexxarApi + "/" + rowID + "/add-permissions", data.permissionList)
      .then((res) => {
        if (res.data.status == "success") {
          getAllRows();
          setShowUpdateRow(!showUpdateRow);
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  //updating name or description
  const updateRowBasic = (data) => {
    axios
      .put(MexxarApi, {
        id: rowID,
        name: data.formData.rowName,
        description: data.formData.rowFieldTwo,
      })
      .then((res) => {
        if (res.data.status == "success") {
          // getAllRows();
          setShowUpdateRow(!showUpdateRow);
          updateRowPermissions(data);

          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const deleteAllRows = () => {
    //console.log(rowID);
    selectedItems.forEach((item) => {
      axios
        .delete(MexxarApi + "/" + item)
        .then((res) => {
          getAllRows();
          setSelectedItems([]);
          setSelectAll(false);
          res.data.status == "failed"
            ? ToastCommon(true, "cannot", addToast)
            : ToastCommon(true, "delete", addToast);
        })
        .catch((err) => {
          ToastCommon(true, "error", addToast);
        });
    });
  };
  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
    } else {
      setSelectedItems([...selectedItems, rowId]);
    }
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };
 

  useEffect(() => {
    getAllRows();
    getAllPermissions();
  }, []);

  return (
    <DashboardLayout title="User Roles" subTitle="Manage User Roles " target="">
      <div className="d-flex justify-content-between p-2 ml-3">
        <div>
          {selectedItems?.length > 0 ? (
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                setShowDeleteAll(true);
              }}
            >
              Delete
            </Button>
          ) : null}
        </div>
        <div>
          {" "}
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddRow(true);
            }}
          >
            Add User Role
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <table
            scrollabl
            id="datatable"
            className="table table-theme table-row v-middle dataTable no-footer overflow-auto"
            role="grid"
            ariaDescribedby="datatable_info"
          >
            <thead>
              <tr role="row">
                <th
                  className="sorting_asc"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaSort="ascending"
                  ariaLabel="ID: activate to sort column descending"
                  style={{ width: "26px" }}
                >
                  <label className="ui-check m-0">
                    <input
                      type="checkbox"
                      name="id"
                      checked={selectAll}
                      onChange={() => checkAll()}
                    />{" "}
                    <i></i>
                  </label>
                </th>
                <th
                  className="sorting_asc"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaSort="ascending"
                  ariaLabel="ID: activate to sort column descending"
                  style={{ width: "26px" }}
                >
                  <span className="text-muted">ID</span>
                </th>
                <th
                  className="sorting_disabled"
                  tabIndex="0"
                  aria-controls="datatable"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Owner: activate to sort column ascending"
                >
                  <span className="text-muted">Role </span>
                </th>

                <th
                  className="sorting_disabled d-flex justify-content-end mr-3"
                  rowSpan="1"
                  colSpan="1"
                  ariaLabel="Tasks"
                >
                  <span className="text-muted d-none d-sm-block mr-3">
                    Actions
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {isNull(rows) ? (
                ""
              ) : (
                <>
                  {rows?.map((row, i) => {
                    return (
                      <tr className="odd" key={i} role="row">
                        <td
                          style={{ minWidth: "30px", textAlign: "center" }}
                          className="sorting_1"
                        >
                          <label className="ui-check m-0">
                            <input
                              type="checkbox"
                              name="id"
                              checked={
                                row.name == "ADMIN" ? null : isSelected(row.id)
                              }
                              onChange={() => onItemSelect(row.id)}
                            />{" "}
                            <i></i>
                          </label>
                        </td>
                        <td
                          style={{ minWidth: "30px", textAlign: "center" }}
                          className="sorting_1"
                        >
                          <small className="text-muted">{i + 1}</small>
                        </td>
                        <td className="">{row.name}</td>

                        <td className="d-flex justify-content-end">
                          <FontAwesomeIcon
                            // color="#448bff"
                            icon={faEdit}
                            className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3 pointer"
                            onClick={() => {
                              setShowUpdateRow(true);
                              setRowID(row.id);
                              setRowNameData({
                                rowName: row.name,
                                rowDescription: row.description,
                              });
                            }}
                            size="1x"
                          />
                          {row.name == "ADMIN" ? null : (
                            <FontAwesomeIcon
                              // color="#FF5603"
                              icon={faTrashAlt}
                              onClick={() => {
                                setShowDeleteRow(true);
                                setRowID(row.id);
                              }}
                              className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave pointer "
                              size="1x"
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <ModalComponent
        show={showAddRow}
        header="Add New Consultant Role "
        closeModal={() => setShowAddRow(false)}
      >
        <NewTwoFields
          fieldDataOne=""
          fieldDataTwo=""
          mode="Add"
          modalType="Consultant role"
          closeModal={() => setShowAddRow(false)}
          onCreate={(data) => addRow(data)}
        />
      </ModalComponent>
      <ModalComponent
        show={showUpdateRow}
        header="Update Consultant Role"
        className="modal-dialog modal-lg"
        closeModal={() => setShowUpdateRow(false)}
      >
        <ConfigureUserRoles
          mode="Update"
          rowID={rowID}
          modalType="Consultant Role"
          fieldDataOne={rowNameData.rowName}
          fieldDataTwo={rowNameData.rowDescription}
          permissions={permissions}
          closeModal={() => setShowUpdateRow(false)}
          onCreate={(data) => {
            // updateRowPermissions(data);
            updateRowBasic(data);
          }}
        />
      </ModalComponent>
      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteRow();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(false)}
      />
      <Delete
        show={showDeleteAll}
        isConfirm={() => {
          deleteAllRows();
          setShowDeleteAll(!showDeleteAll);
        }}
        closeModal={() => setShowDeleteAll(false)}
      />
    </DashboardLayout>
  );
};

export default ConsultantRoles;
