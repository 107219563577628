import React, { useEffect, useState } from "react";

import axios from "axios";
import Previews from "../audit/docView/Previews";
import TimesheetData from "./TimesheetData";
import {
  Row,
  Col,
  FormGroup,
  Container,
  UncontrolledTooltip,
  Button,
  Spinner,
  Label,
  Alert,
} from "reactstrap";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { HOSTMexxar } from "../../configs/api-config";
import { faCheck, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { forEach, isNull } from "lodash";
import ToastCommon from "../../components/toastCommon";
import PayrollSummary from "./PayrollSummary";
import moment from "moment";
import PendingTimesheetsforVerification from "./pendingTimesheetsforVerificationCandy";
import { connect, useDispatch } from "react-redux";
import {
  addAITimesheetData,
  addSystemTimesheetData,
  deleteAllTimesheetsLocally,
  saveTimesheetsLocally,
  timesheetExistStatus,
  updateScanProcessStatus,
  logFailedTimesheets,
  scanStatusIndividual,
  finalManualReviewConfirmation,
  saveGeneratedUrl,
  updateTimesheetFileName,
  updateTimesheetUrl,
  preVerifiedStatus,
} from "../../redux/actions/timesheetBatchScanAction";
import PreviewsBatchTimeSheets from "../audit/docView/PreviewsBatchTimeSheets";
import TimesheetDataBatchView from "./TimesheetDataBatchView";
import * as XLSX from "xlsx";
// import TimesheetDataBatchView2 from "./TimesheetDataBatchView2";
// import TimeSheetDataBatchView2 from "./TimeSheetDataBatchView2";
import TimesheetDataBatchView2 from "./TimesheetDataBatchView2";
import CandyTimeSheetBatchView from "./CandyTimeSheetBatchView";
import FileDownloader from "./FileDownloader";
import CandyTimeSheetBatchViewAngi from "./CandyTimeSheetBatchViewAngi";

//******************Process******************/
//1. Upload timesheets(one or more up to 100 timesheets)
//2. Show uploaded Timehseets as a list
//3. Have a function to show the uploaded timesheets- (when user clicks image icon show the relavent Timesheet
//4. Click process-Start the timesheet processing.Show each process timesheet as a list
//5. Click Review option to review the Timesheet by comparing AI process data and DB data with view of the Timesheet
//6. Once all the timesheets been reviewed or the successfully reviewed timesheets should be import as an excelsheet.
//7. Only export the timesheets been reviewed

function InstructionUI() {
  return (
    <div className="card d-flex flex-column align-items-start justify-content-center p-5 text-justify">
      <h2>C-Timesheet upload-Beta</h2>

      <h6>Please upload timesheets to start the scanning process</h6>
      <p className="ml-2">How to scan timesheets:</p>
      <small className="ml-2">
        1. Upload one or more timesheets to the left-side Drag & Drop Box. 📂
      </small>
      <small className="ml-2">
        2. Please wait while all your files are being uploaded. ⏳
      </small>
      <small className="ml-2">
        3. Once timesheets are uploaded, click the "Process" button to begin the
        AI scan. 🚀
      </small>
      <small className="ml-2">
        4. Wait until the scan process is complete. This might take some time.
        Enjoy a cup of coffee ☕️ :)
      </small>
      <small className="ml-2">
        5. Once the timesheets are processed, you will be able to review them
        manually. 👀
      </small>
      <small className="ml-2">6. Click "Verify" for each timesheet. ✅</small>
      <small className="ml-2">
        7. When all the verification is done, click "Confirm" to end the
        verification process. ✔️
      </small>
      <small className="ml-2">
        8. Finally, click "Export" to download your ".xlsx" 📊 file. Enjoy😉
      </small>
      <p className="ml-2 mt-2">
        Note: If there are any errors during the above process, we will provide
        sufficient instructions to resolve them. If you are unable to resolve
        any issues, please contact the "Mexxar Team" for support. 📧
      </p>
    </div>
  );
}
function CandyTimeSheetBatchScan(props) {
  const {
    timesheets,
    scanProcessingStatus,
    failedTimesheets,
    finalManualReview,
  } = props;
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();

  const [fileUploaded, setFileUploaded] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [processLoading, setProcessLoading] = useState(null);
  const [incommingAIdata, setIncommingAIdata] = useState(null);
  const [systemTimeSheetData, setSystemTimeSheetData] = useState(null);
  const [timeSheetFalse, setTimeSheetFalse] = useState(null);
  const [showSummary, setShowSummary] = useState(false);
  const [payrollData, setPayrollData] = useState(null);
  const [payrollError, setPayrollError] = useState(null);
  const [documentUploaded, setDocumentUploaded] = useState(false);
  const [review, setReview] = useState([]);
  const [selectedFileForReview, setSelectedFileForReview] = useState(null);
  const [savedRefNo, setSavedRefNo] = useState(null);
  const [savedRefNo2, setSavedRefNo2] = useState(null);
  const [ref3, setRef3] = useState(null);







  

  const handleChangeStatus = (files) => {
    //if the user navigates to some other page and returns the data should remain same as before
    // if (timesheets.length > 0) {
    //   return;
    // }

    setSelectedFiles(files);

    //adding timeSheetExist: false, AIModalData: {}, DBData: {}, to each file
    const updatedFiles = files.map((file, index) => {
      const oneSecond = 1000; // 1 second in milliseconds
      const newDateTime = new Date(Date.now() + index * oneSecond); // Add index * 1 second

      return {
        file: file,
        fileName: file.path.split(".")[0],
        fileExtention: file.path.split(".").pop(),
        timeSheetExist: false,
        AIModalData: {},
        DBData: {},
        AIModalDataLoadingStatus: {},
        DBData: {},
        manuallyReviewed: false,
        generatedUrl: "",
        s3bucketfileurl: "",
        timesheetReviewDateTime: newDateTime,
        aIReviewStatus: "NOT_STARTED", //"NOT_STARTED","NOT_MATCHING","MATCHING",
        reviewedData: {},
        scanStatus: {
          status: "NOT_STARTED", //"NOT_STARTED, "SCANNING","DONE","FAILED"
          message: "",
        },
      };
    });
    dispatch(saveTimesheetsLocally(updatedFiles));
  };

  const [warningTimesheetprocessed,setWarningTimesheetprocessed]=useState({data:{},bool:false})
 
 
 
  //use to check if the timesheet is already verified

  const checkTimesheetProcessStatus = async (query, start, end) => {
    try {
      // Format dates
      const startDate = moment(start).format("YYYY/MM/DD");
      const endDate = moment(end).format("YYYY/MM/DD");
  
      // Build URL
      const url = `${HOSTMexxar}workspace/payrollfilter?ps=PENDING&hrs=PENDING&rs=VERIFIED`;
  
      // Perform the request
      const response = await axios.get(url, {
        params: {
          q: query,
          start: startDate,
          end: endDate,
        },
       });
  
      // Check response data
      return {
        data: response.data.body?.content?.length > 0 ? response.data.body?.content : {},
        bool: response.data.body?.content?.length > 0,
      };
    } catch (error) {
      console.error(error);
      return { data: {}, bool: false };
    }
  };
  //ToDo:Next define an redux action to store the warning status and show to the user that the timesheet is already reviewed
  //inform not to continue unless supervising the timesheet to avoid double payments

  const getTimesheetFromSystem = (data, file) => {
    if (data) {
      axios
        .get(HOSTMexxar + "bookings/reference/" + data)
        .then((res) => {
          console.log("getTimesheetFromSystem Response:", res.data);
 

          setSystemTimeSheetData(res.data.body);
          dispatch(addSystemTimesheetData(file?.preview, res.data.body));

          if (res.data.message === "Not Existing") {
            setTimeSheetFalse(true);
            setSavedRefNo2(data);

            console.log("Before retry, reference number saved:", savedRefNo2);
            dispatch(timesheetExistStatus(file.preview, false));
          } else if (res.data.message === "fetched") {
            setTimeSheetFalse(false);
            dispatch(timesheetExistStatus(file.preview, true));

            setSavedRefNo(data);
             // Call checkTimesheetProcessStatus and handle the result
          const query = res.data?.body?.referenceNo;  
          const shiftFrom = moment(res.data?.body?.shiftFrom);
          const shiftTo = moment(res.data?.body?.shiftTo);

          const start = shiftFrom.subtract(1, 'days').format("YYYY/MM/DD");
          const end = shiftTo.add(1, 'days').format("YYYY/MM/DD");

          checkTimesheetProcessStatus(query, start, end)
            .then(statusResult => {
               if (statusResult.bool){
                dispatch(
                  preVerifiedStatus(
                    file?.preview,
                    "PRE_VERIFIED",
                    "This timesheet has been pre-verified"
                  )
                );
              }else{
                dispatch(
                  preVerifiedStatus(
                    file?.preview,
                    "NOT_VERIFIED",
                    ""
                  )
                );
  
              }
           
              
            })
            .catch(error => {
              console.error("Error fetching timesheet process status:", error);
            });
            

            

            console.log("candy booking reference check", data);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };


  const refNoSet = (data, file) => {
    const bookingRefData = data.bookingref;

    if (bookingRefData && bookingRefData.value) {
        let bookingRef = bookingRefData.value;

        // Remove spaces from bookingRef value
        bookingRef = bookingRef.replace(/\s+/g, '');
        
        console.log("Booking Reference:", bookingRef);

        getTimesheetFromSystem(bookingRef, file);
    } else {
        console.error("Booking Reference not found in data:", data);
    }
};


  const [bookingRefNumber, setBookingRefNumber] = useState(null);

  const getRefNo2 = (data) => {
    const bookingref = data.bookingref;

    console.log("dj2", bookingref);
    const ref = data[bookingref + "Ref"].value;
    console.log("dj", ref);

    setBookingRefNumber(ref);

    console.log("previously scanned ref no:", bookingRefNumber);

    return ref;
  };

  // // set AI data
  const prepareAIData = (data, file) => {
 
    let arrangeData = {};


    let bookingRefValue = data.bookingref.value.replace(/\s+/g, '');

    arrangeData["Breakmin"] = data.break.value;
    arrangeData["Date"] = moment(data.date.value, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );
    arrangeData["Day"] = data.day.value;
    arrangeData["End"] = data.endtime.value;
     arrangeData["Ref"] = bookingRefValue; 
    arrangeData["Start"] = data.starttime.value;
    arrangeData["Totalhours"] = data.totalhours.value;
    arrangeData["Ward"] = data.ward.value;
    arrangeData["Band"] = data.band.value;
    arrangeData["HospitalName"] = data.clientname.value;
    arrangeData["CandidateId"] = "";
    arrangeData["CandidateName"] = data.candidatename.value ;
    arrangeData["Speciality"] = data.type.value || "";

    setIncommingAIdata(arrangeData);
    dispatch(addAITimesheetData(file.preview, arrangeData));
  };

 

  const processTimeSheet = async (file) => {
    setProcessLoading(true);

    const fd = new FormData();
    fd.append("file", file);

    axios({
      method: "post",
      url: "https://time.mexxar.com/uploadtimesheet/candy",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      // withCredentials: true,
      data: fd,
      mode: "cors",
    })
      .then((res) => {
        if (res.data.status == "success") {
          refNoSet(res.data.body, file);
          prepareAIData(res.data.body, file);
        }

        setProcessLoading(false);
      })
      .catch((e) => {
        // console.log(e);
        setProcessLoading(false);
      });
  };

  useDidMountEffect(() => {
    setSystemTimeSheetData(null);
    setIncommingAIdata(null);
  }, [selectedFiles, showSummary]);

  useDidMountEffect(() => {
    if (isNull(incommingAIdata) && isNull(systemTimeSheetData)) {
      setSelectedFiles(null);
      setDocumentUploaded(!documentUploaded);
    }
  }, [showSummary]);

  const processBatchTimeSheet = async () => {
    let noOfFiles = selectedFiles?.length;

    for (let i = 0; i < noOfFiles; i++) {
      await processTimeSheet(selectedFiles[i]);
    }
  };


  const processTimesheetsSequentially = async (timesheets) => {
    setSelectedFileForReview(null);
    dispatch(updateScanProcessStatus("SCANNING"));
    setProcessLoading(true);
    // getBucketUrl();

    try {
      for (let i = 0; i < timesheets.length; i++) {
        await processSingleTimesheet(timesheets[i].file);
      }

      dispatch(updateScanProcessStatus("DONE"));
    } catch (error) {
      dispatch(updateScanProcessStatus("INTERRUPTED"));
    }

    setProcessLoading(false);
  };

  // Get file url in S3 Bucket //

  const saveBookingRef = (data) => {
    if (data && data.bookingref && data.bookingref.value) {
        let bookingRef = data.bookingref.value;
        
        // Remove spaces from bookingRef value
        bookingRef = bookingRef.replace(/\s+/g, '');
        
        setBookingRefNumber(bookingRef);
        console.log("Booking ref saved:", bookingRef);
        return bookingRef;
    } else {
        console.error("Booking ref not available");
        return null;
    }
};


  
//-------------------Working codes----------------------------------//

  const processSingleTimesheet = async (file) => {
    dispatch(scanStatusIndividual(file?.preview, "SCANNING", ""));
    try {
      const fd = new FormData();
      fd.append("file", file, file.name);
      console.log("checkfile name", file);

      const scanResponse = await axios({
        method: "post",
        url: "https://time.mexxar.com/uploadtimesheet/candy",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        },
        data: fd,
        mode: "cors",
      });
      console.log("Scan response", scanResponse.data.body);
      const { bookingref, candidatename } = scanResponse.data.body;

      if (
        bookingref?.confidence === null ||
        candidatename?.confidence === null
      ) {
        setErrorMessage(
          "Wrong file uploaded or file is corrupted. 'Booking reference' or 'Candidate name' is missing."
        );

        dispatch(
          logFailedTimesheets(
            file,
            "File contains null values in critical fields"
          )
        );
        dispatch(
          scanStatusIndividual(
            file?.preview,
            "FAILED",
            "File contains null values in critical fields"
          )
        );
        return;
      }
      if (scanResponse.data.status === "success") {
        const bookingRef = saveBookingRef(scanResponse.data.body);


        const s3UploadFormData = new FormData();

        const referenceNumber = scanResponse.data.body;
        s3UploadFormData.append("file", file);

        s3UploadFormData.append("fileName", bookingRef);

        const s3UploadResponse = await axios.post(
          "https://time.mexxar.com/timesheet/file/upload",
          s3UploadFormData,
          {
            params: {
              bucketName: "mexxar-timesheet",
              folderName: "mex_candy",
            },
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            },
            mode: "cors",
          }
        );

        refNoSet(scanResponse.data.body, file);
        // console.log("angi s3 upload function-------1---->",);

        prepareAIData(scanResponse.data.body, file);
        // console.log("angi s3 upload function-------2---->",);

        dispatch(scanStatusIndividual(file?.preview, "DONE", "success"));
        // console.log("angi s3 upload function-------3---->",);

        dispatch(scanStatusIndividual(file?.preview, "DONE", "success"));
        // console.log("angi s3 upload function-------4---->",);

        dispatch(updateTimesheetFileName(file?.preview, bookingRef)); //Change the file name to the refernce number
        getBucketUrl(file?.preview, bookingRef);

        // console.log("angi s3 upload function------5----->", bookingRef);

        if (
          s3UploadResponse.status === "failed" &&
          s3UploadResponse.message !== "File Already Exists"
        ) {
          dispatch(logFailedTimesheets(file, "S3 upload failed"));
          dispatch(
            scanStatusIndividual(file?.preview, "FAILED", "S3 upload failed")
          );
        }
      } else {
        dispatch(logFailedTimesheets(file, scanResponse.data.message));

        dispatch(
          scanStatusIndividual(
            file?.preview,
            "FAILED",
            scanResponse.data.message
          )
        );
      }
    } catch (error) {
      dispatch(logFailedTimesheets(file, error.message));

      dispatch(scanStatusIndividual(file?.preview, "FAILED", error.message));
    }
  };
  const [errorMessage, setErrorMessage] = useState(null);
 
  
  
  function getFileExtension(fileName) {
    return fileName.split(".").pop();
  }

  const [generatedUrl, setGeneratedUrl] = useState("");
  const getBucketUrl = async (fileId, BookingReferenceNo) => {
    try {
      let timesheet = timesheets.filter(function (item) {
        return item.file.preview == fileId;
      });

      if (timesheet.length === 0) {
        console.error("No matching timesheet found for fileId:", fileId);
        return;
      }

      const fileName =
        BookingReferenceNo + "." + getFileExtension(timesheet[0].file.name);
      const fileExtension = timesheet[0].fileExtention;
      console.log("File extension:", fileExtension);
      // const fileName = BookingReferenceNo + "." + timesheet[0].fileExtention;

      const response = await axios.get(
        "https://time.mexxar.com/timesheet/url",
        {
          params: {
            fileName: fileName,
            folderName: "mex_candy",
            linkDuration: "86400",
          },
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
          mode: "cors",
        }
      );

      if (response.status === 200) {
        const url = response.data.body.url;

        const expirationTime = new Date();
        expirationTime.setMinutes(expirationTime.getMinutes() + 0);

        localStorage.setItem("generatedUrl", url);
        localStorage.setItem(
          "generatedUrlExpiration",
          expirationTime.toISOString()
        );

        // console.log(
        //   "angi s3 getBucketUrl function-----response.status=200------>",
        //   fileId
        // );

        setGeneratedUrl(url);
        dispatch(saveGeneratedUrl(url));
        dispatch(updateTimesheetUrl(fileId, url));

        console.log("  URL generated successfully:", url);
      } else {
        console.error("Failed to generate URL", response.status);
        console.error("Error message:", response.data.message);
      }
    } catch (error) {
      console.error("Error generating URL:", error.message);
    }
  };

  const toggleReview = (data) => {
    const index = data.file;
    // Toggle to review

    if (review.includes(index.preview)) {
      setReview(review.filter((rowIndex) => rowIndex !== index.preview));
      setSelectedFileForReview(null);
    } else {
      setReview([index.preview]);
      setSelectedFileForReview(data);
    }
  };

  const extractReviewedData = () => {
    return timesheets.map((obj) => obj.reviewedData);
  };

  //********************* Export data to excel sheet *********************//
  const [alertMessage, setAlertMessage] = useState(""); // State for alert messages

  const exportToExcel = () => {
    console.log("angi export to excell data", timesheets);
    const reviewedData = extractReviewedData();

    // Create a new workbook and worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(reviewedData);

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate the Excel file and download it
    XLSX.writeFile(workbook, "reviewed_data.xlsx");
  };

  const restart = () => {
    dispatch(deleteAllTimesheetsLocally());
    setSelectedFileForReview(null);
  };

  //clear the selected candidate when changing the reviewing timehsheet
  useDidMountEffect(() => {
    setSelectedCandidate(null);
  }, [review]);

  const [timesheetImageList, setTimesheetImageList] = useState([]);
  const prepareTimesheetUrlList = () => {
    let listOfTimesheetUrls = timesheets.map((x) => x.s3bucketfileurl);
    setTimesheetImageList(listOfTimesheetUrls);
  };

  const SortFunction = (a, b) => {
    var dateA = new Date(a.timesheetReviewDateTime).getTime();
    var dateB = new Date(b.timesheetReviewDateTime).getTime();
    return dateA > dateB ? 1 : -1;
  };

  return (
    <div
      className="  m-3 p-3 "
      // className="d-flex p-2 m-2 align-items-between justify-content-center "
      style={{ width: "100%", overflowX: "hidden" }}
    >
      <div className="row justify-content-around ">
        <div
          className="  navbar-expand-lg "
          style={{ position: "fixed", top: "50px", left: "50px", zIndex: 999 }}
        >
          <Link
            to={{
              pathname: "/dashboard/payroll",
            }}
          >
            <div className="btn btn-sm btn-icon btn-rounded btn-raised  bg-dark-overlay ">
              <FontAwesomeIcon
                className="feather feather-more-vertical text-fade"
                icon={faChevronLeft}
              />
            </div>
          </Link>
        </div>

        <div class="card p-3  col-sm-12 col-md-6 d-flex align-items-between justify-content-start h-80">
          {timesheets?.length > 0 && (
            <>
              <Button
                className="m-2"
                disabled={scanProcessingStatus == "SCANNING"}
                color={`${timeSheetFalse ? "danger" : "success"}`}
                onClick={() => {
                  // processBatchTimeSheet();
                  processTimesheetsSequentially(timesheets);
                }}
              >
                {scanProcessingStatus == "SCANNING" && (
                  <Spinner
                    animation="border"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                  />
                )}
                {scanProcessingStatus == "SCANNING" && "Processing"}
                {scanProcessingStatus == "INTERRUPTED" && "Retry"}
                {scanProcessingStatus == "NOT_STARTED" && "Process"}
                {scanProcessingStatus == "DONE" && (
                  <>
                    <FontAwesomeIcon icon={faCheck} className="mx-2" />{" "}
                    Reprocess
                  </>
                )}
              </Button>
              {scanProcessingStatus == "SCANNING" && (
                <Button onClick={restart}>Cancel</Button>
              )}
              {scanProcessingStatus == "DONE" && (
                <Button onClick={restart}>Start New Set</Button>
              )}
            </>
          )}

          <PreviewsBatchTimeSheets
            showFile={selectedFileForReview}
            expandStatus={expand}
            fileUploaded={(data) => setFileUploaded(data)}
            onUpload={(data) => handleChangeStatus(data)}
            documentUploaded={documentUploaded}
            showUploadSection={scanProcessingStatus !== "SCANNING"}
            review={review}
            failedTimesheets={failedTimesheets}
          />
        </div>
        <div class="col-sm-12 col-md-6">
          {/* {timesheetBatchScanAIData?.timesheetsAiData?.map((item)=>{
            return(
              <PendingTimesheetsforVerification toggleReview={toggleReview} />
            )
          })} */}

          {timesheets?.length == 0 && <InstructionUI />}

          {timesheets?.length > 0 && (
            <div className="card">
              <div className="card-body">
                <h5>Process Your Timesheets </h5>
                <p className="text-justify">
                  Hi,Please wait while Mexxi 🤖, our Mexxaar AI, scans your
                  timesheets. Take a break, have a ☕️, and enjoy as Mexxi works
                  its magic. ✨
                </p>
              </div>
            </div>
          )}

          {/***** Allocate 15 seconds avarage time for one timesheet *****/}
          {timesheets?.length > 0 && (
            <div className="card">
              <div className="card-body">
                <h6>
                  You have uploaded {timesheets?.length} Timesheet(s) to scan.{" "}
                </h6>
              </div>
            </div>
          )}

          {scanProcessingStatus == "DONE" && (
            <div className="card">
              <div className="card-body p-2">
              <><Label>Please click the confirm button once the review process is complete to export the timesheets to an Excel sheet.</Label>
              <Button
                  onClick={() => {
                    dispatch(finalManualReviewConfirmation("CONFIRMED"));
                    prepareTimesheetUrlList();
                  }}
                  color={`${
                    failedTimesheets?.length > 0 ? "warning" : "success"
                  }`}
                >
                  Confirm
                </Button>
                </>
              </div>

              {failedTimesheets?.length > 0 && (
                <div className="mx-3">
                  <Label>
                    These files were not included due to the errors mentioned.
                    Please scan them separately or retry scanning them, and
                    review the files before confirming your submission
                  </Label>

                  <Alert className=" " color="danger">
                    <p>
                      {errorMessage && (
                        <div className="error-message">{errorMessage}</div>
                      )}
                    </p>
                  </Alert>
                  <ul>
                    {failedTimesheets?.map((file, index) => {
                      return (
                        <div
                          className="d-flex align-items-center justify-content-between "
                          key={index}
                        >
                          <div className="d-flex flex-column">
                            <small> File Name: {file?.file?.name} </small>
                            <small> Reason : {file?.errorMessage}</small>
                            {/* <small> Attampts : {file?.attampts}</small>   //do this later */}
                          </div>
                          <div>
                            <small>
                              <Button
                                onClick={() =>
                                  processSingleTimesheet(file?.file)
                                }
                              >
                                Retry
                              </Button>
                            </small>
                          </div>
                        </div>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          )}

          {scanProcessingStatus == "DONE" &&
            finalManualReview == "CONFIRMED" && (
              <div className="card">
                <div className="card-body p-2">
                  <label className="mx-2">
                    Please note that only reviewed timesheets will be exported
                    to the Excel sheet.
                  </label>
                  <div className="d-flex flex-row">
                    <Button
                      className="mx-1"
                      onClick={exportToExcel}
                      color={`${
                        failedTimesheets?.length > 0 ? "warning" : "success"
                      }`}
                    >
                      Export to excel sheet
                    </Button>
                    <FileDownloader fileUrls={timesheetImageList} />
                  </div>
                </div>
              </div>
            )}
          {timesheets?.sort(SortFunction).map((file, index) => {
            /* {timesheets?.map((file, index) => { */

            return (
              <div key={index}>
                <PendingTimesheetsforVerification
                  toggleReview={toggleReview}
                  file={file}
                  review={review}
                />
                {review.includes(file.file.preview) && (
                  <CandyTimeSheetBatchViewAngi
                    selectedFile={file}
                    refNum={ref3}
                    bookingNum={bookingRefNumber}
                    newRefNumber={savedRefNo}
                    systemTimeSheetData={file?.DBData}
                    timeSheetExist={file?.timeSheetExist}
                    incommingAIdata={file?.AIModalData}
                    // processTimeSheet={processTimeSheet}
                    processLoading={processLoading}
                    setShowSummary={(data) => setShowSummary(data)}
                    selectedCandidate={selectedCandidate}
                    setSelectedCandidate={(data) => setSelectedCandidate(data)}
                    retry={(data) => getTimesheetFromSystem(data, file?.file)}
                    setPayrollData={(data) => {
                      setPayrollData(data);
                    }}
                    setPayrollError={(data) => {
                      setPayrollError(data);
                    }}
                    verified={toggleReview}
                  />

                  // <TimesheetDataBatchView2></TimesheetDataBatchView2>
                )}
              </div>
            );
          })}

          {/********* For batch scan only *********/}

          {showSummary && payrollData && (
            <PayrollSummary
              summary={payrollData}
              setShowSummary={(data) => {
                setShowSummary(data);
                setPayrollData(null);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    timesheets: state?.timesheets?.timesheets,
    scanProcessingStatus:
      state?.timesheets?.scanProcessingStatus || "NOT_STARTED",
    failedTimesheets: state?.timesheets?.failedTimesheets || [],
    finalManualReview:
      state?.timesheets?.finalManualReviewConfirmation || "NOT_CONFIRMED",
  };
}

export default connect(mapStateToProps, {})(CandyTimeSheetBatchScan);
