// import React from "react";
// import axios from "axios";
// import { HOSTMexxar, TRAININGS } from "../../configs/api-config";

// export const sendNotification = (data) => {
//   const {notificationType, title, content, candidateId, startDate, addToast}=data
//   const MexxarApiTRAININGS = TRAININGS;

//   switch(notificationType) {
//     case "EVENT":
//       sendRequest({
//         notificationType: "EVENT",
//         title: title,
//         content: content,
//         candidateId: candidateId,
//         eventDate: startDate
//       }, addToast);
//       break;
//     case "TRAINING":

//           sendRequest({
//             notificationType: "TRAINING",
//             title: title,
//             content: content,
//             candidateId: candidateId,
//             eventDate: startDate
//           }, addToast);
      
//       break;
//     case "BOOKING":
//       sendRequest({
//         notificationType: "BOOKING",
//         title: title,
//         content: content,
//         candidateId: candidateId,
//         eventDate: startDate
//       }, addToast);
//       break;
//     default:
//       console.error("Invalid notificationType:", notificationType);
//       break;
//   }
// };

// const sendRequest = (requestData, addToast) => {
//   axios.post(
//     "https://permapi.mexxar.com/mobile/notifications/fcm/create",
//     requestData,
//     {
//       params: {
//         topic: "mexxar-candidate-" + requestData.candidateId
//       }
//     }
//   )
//   .then((res) => {
//     if (res.data.status === "success") {

//     } else {
//       console.error("Notification sending failed:", res.data);
      
//     }
//   })
//   .catch((err) => {
//     console.error("Error sending notification:", err);
  
//   });
// };


import React from "react";
import axios from "axios";
import { HOSTMexxar, TRAININGS } from "../../configs/api-config";

export const sendNotification = (data) => {
  const { notificationType, title, content, candidateId, startDate, addToast } = data;
  const MexxarApiTRAININGS = TRAININGS;

  switch (notificationType) {
    case "EVENT":
    case "TRAINING":
    case "BOOKING":
    case "TIME_SHEET": // New case for TIME_SHEET
      sendRequest({
        notificationType: notificationType,
        title: title,
        content: content,
        candidateId: candidateId,
        eventDate: startDate
      }, addToast);
      break;
    default:
      console.error("Invalid notificationType:", notificationType);
      break;
  }
};

const sendRequest = (requestData, addToast) => {
  axios.post(
    "https://permapi.mexxar.com/mobile/notifications/fcm/create",
    requestData,
    {
      params: {
        topic: "mexxar-candidate-" + requestData.candidateId
      }
    }
  )
  .then((res) => {
    if (res.data.status === "success") {
      // Optionally handle success response
    } else {
      console.error("Notification sending failed:", res.data);
      // Optionally display an error message
    }
  })
  .catch((err) => {
    console.error("Error sending notification:", err);
    // Optionally display an error message
  });
};

