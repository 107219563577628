import React, { useState } from "react";
import {
  faInbox,
  faPaperPlane,
  faPenAlt,
  faRadiation,
  faTrash,
  faPowerOff,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
// import "../css/style.css";
// import "../css/theme.css";
// import "../css/bootstrap.css";
import { Link } from "react-router-dom";
import { MailBox } from "./All_Mail_Request/MailboxRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import EmailForm from "./emailForm/EmailForm";

var x = 0;

const EmailSideNav = (props) => {
  const {
    setMail,
    unRead,
    creadentials,
    setType,
    page,
    active,
    toggleRefresh,
  } = props;

  const [showComposeModal, setShowComposeModal] = useState(false);

  const toggleComposeModal = () => {
    setShowComposeModal(!showComposeModal);
  };

  if (unRead !== -10) {
    x = unRead;
  }

  const EmptyEmailBox = () => {
    setMail([]);
    page(1);
    active(0);
  };

  const setMailboxType = (value) => {
    setType(value);
  };

  return (
    <div className="fade aside aside-sm" id="content-aside" style={{marginTop:"2rem"}}>
      <div
        className="modal-dialog d-flex flex-column w-md bg-body"
        id="mail-nav"
      >
        <div className="navbar">
          <span className="text-md">Email</span>
          <FontAwesomeIcon
            className="btn-raised pointer "
            style={{ boxShadow: "none" }}
            icon={faRedo}
            onClick={toggleRefresh}
          />
        </div>
        <div className="scrollable hover">
          <div className="p-2 px-3">
            <button
              className="btn btn-sm btn-block box-shadows btn-rounded gd-danger text-white"
              id="btn-new-mail"
              onClick={toggleComposeModal}
            >
              New mail
            </button>
          </div>
          <div className="sidenav my-3">
            <nav className="nav-active-text-primary" data-nav>
              <ul className="nav">
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      EmptyEmailBox();
                      MailBox(creadentials, "INBOX", setMail, 1);
                      setMailboxType("INBOX");
                    }}
                  >
                    <span className="nav-icon">
                      <FontAwesomeIcon icon={faInbox} />
                    </span>
                    <span className="nav-text">Inbox</span>
                    {x !== 0 && (
                      <span className="nav-badge">
                        <b className="badge badge-pill gd-info">{x}</b>
                      </span>
                    )}
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      EmptyEmailBox();
                      MailBox(creadentials, "SENT", setMail, 1);
                      setMailboxType("SENT");
                    }}
                  >
                    <span className="nav-icon">
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </span>
                    <span className="nav-text">Sent</span>
                  </Link>
                </li>
                {/* <li>
                  <Link
                    onClick={() => {
                      EmptyEmailBox();
                      MailBox(creadentials, "DRAFTS", setMail, 1);
                      setMailboxType("DRAFTS");
                    }}
                  >
                    <span className="nav-icon">
                      <FontAwesomeIcon icon={faPenAlt} />
                    </span>
                    <span className="nav-text">Draft</span>
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      EmptyEmailBox();
                      MailBox(creadentials, "SPAM", setMail, 1);
                      setMailboxType("SPAM");
                    }}
                  >
                    <span className="nav-icon">
                      <FontAwesomeIcon icon={faRadiation} />
                    </span>
                    <span className="nav-text">Spam</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      EmptyEmailBox();
                      MailBox(creadentials, "TRASH", setMail, 1);
                      setMailboxType("TRASH");
                    }}
                  >
                    <span className="nav-icon">
                      <FontAwesomeIcon icon={faTrash} />
                    </span>
                    <span className="nav-text name">Trash</span>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/dashboard/email/loginPage"
                    onClick={() => {
                      EmptyEmailBox();
                      localStorage.removeItem("email");
                      localStorage.removeItem("password");
                      localStorage.removeItem("totalMails");
                    }}
                  >
                    <span className="nav-icon">
                      <FontAwesomeIcon icon={faPowerOff} />
                    </span>
                    <span className="nav-text name">Logout</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      {showComposeModal ? (
        <EmailForm composeType={"NEW"} showComposeModal={toggleComposeModal} />
      ) : null}
    </div>
  );
};
export default EmailSideNav;
