import axios from "axios";
import store from "../../../../redux/store";
import { emailBodyPosts } from "../../../../redux/actions/emailServerAction";
import { useState } from "react";
import { HostMexxarEmail } from "../../../../configs/api-config";

const MailBox = (creadentials = [], Mailtype, setMail, page) => {
  creadentials.type = Mailtype;
  delete creadentials.timestamp;
  setMail([]);
  axios
    .post(
      HostMexxarEmail + "email/mail/?page=" + page + "&offset=10",
      creadentials
    )
    .then(function (response) {
      if (response.data.body.totalMail === 0) {
        setMail(-10);
      } else {
        setMail(response.data);
        store.dispatch(emailBodyPosts(response.data.body));
      }
    })
    .catch(function (error) {
      console.log("error", error);
    });
};

const ClickPage = (creadentials = [], Mailtype, setMail, page) => {
  creadentials.type = Mailtype;

  setMail([]);
  axios
    .post(
      HostMexxarEmail + "/email/mail/?page=" + page + "&offset=10",
      creadentials
    )
    .then(function (response) {
      if (response.data.body.length === 0) {
        setMail(-10);
        store.dispatch(emailBodyPosts(response.data.body));
      } else {
        setMail(response.data);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

const ReadMail = (creadentials, timestamp, setPosts) => {
  creadentials.timestamp = timestamp;
  axios
    .post(HostMexxarEmail + "email/read", creadentials)
    .then(function (response) {
      setPosts(response.data.body);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const MoveToTrash = (creadentials, id, mail, setMail, page) => {
  let data = {
    email: creadentials.email,
    id: id,
    type: creadentials.type,
  };

  axios
    .post(HostMexxarEmail + "email/trash", data)
    .then(function (response) {
      console.log(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });

  MailBox(creadentials, mail, setMail, page);
};

const Search = (email, keyword, type, setMail) => {
  setMail([]);
  var requestbody = {
    email: email,
    searchKeyword: keyword,
    type: type,
  };
  axios
    .post(HostMexxarEmail + "email/search", requestbody)
    .then(function (response) {
      if (response.data.body.totalMail === 0) {
        setMail(-10);
      } else {
        setMail(response.data);
        console.log(response.data);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

const SendEmail = (email, keyword, type, setMail) => {
  axios
    .post(HostMexxarEmail, email)
    .then(function (response) {
      if (response.data.body.length === 0) {
        setMail(-10);
      } else {
        setMail(response.data);
      }
    })
    .catch(function (error) {
      console.log(error);
    });
};

export { MailBox, ClickPage, ReadMail, MoveToTrash, Search, SendEmail };
