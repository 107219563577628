import React, { useState, useEffect } from "react";
import {
  Label,
  Form,
  FormGroup,
  Col,
  Button,
  Spinner,
  Input,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import store from "../../redux/store";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { forEach, findIndex, filter, isEmpty, isNull } from "lodash";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../components/toastCommon";
import useCandidateList from "../../customHooks/useCandidateList";
import {
  REGISTER_CANDIDATE,
  HOSTMexxar,
  ClientLable,
} from "../../configs/api-config";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { clientsGetAction } from "../../redux/actions/initialDataGetAction";
import Store from "../../redux/store";
import { saveFiledDataAction } from "../../redux/actions/saveFiledDataAction";
import { updateTimesheetReviewedDataCandy } from "../../redux/actions/timesheetBatchScanAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

function CandyTimesheetDataBatchViewAngi(props) {
  const {
    // processTimeSheet,
    processLoading,
    selectedCandidate,
    setSelectedCandidate,
    incommingAIdata,
    systemTimeSheetData,
    timeSheetExist,
    reduxClients,
    retry,
    retry2,
    selectedFile,
    setPayrollData,
    setShowSummary,
    setPayrollError,
    user,
    timesheet,
    verified,
  } = props;

  const MexxarCandidateApi = REGISTER_CANDIDATE;
  const { register, handleSubmit, errors, clearErrors, reset } = useForm();
  const dispatch = useDispatch();

  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [candidates, setCandidates] = useState([]);
  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  let url = MexxarCandidateApi + "/status/" + filterCustom.value;

  const { loading, error, rows, hasMore } = useCandidateList(
    url,
    filterCustom,
    query,
    pageNumber
  );

  const [departmentError, setDepartmentError] = useState(false);
  const [selectedSpeciality, setSelectedSpeciality] = useState(0);
  const [specialities, setSpecialities] = useState([{ id: 0, name: "Select" }]);

  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [departments, setDepartments] = useState([{ id: 0, name: "Select" }]);

  const [refNo, setRefNo] = useState(null);
  const [fieldData, setFieldData] = useState(null);

  const [selectedClient, setSelectedClient] = useState(null);
  const [clients, setClients] = useState([{ id: 0, name: "Select" }]);

  const [clientCantFind, setClientCantFind] = useState(false);
  const [departmentCantFind, setDepartmentCantFind] = useState(false);
  const [specialityCantFind, setSpecialityCantFind] = useState(false);

  const [pdfError, setAiError] = useState(false);
  const [aiPending, setAiPending] = useState(false);
  const [defaultRates404, setDefaultRates404] = useState(false);
  //prefixed rate types to set ids for each section
  const [rateTypes, setRateTypes] = useState([
    { type: "Rate Week Days", key: 0, shortForm: "WEEK" },
    { type: "Rate Saturday", key: 1, shortForm: "SAT" },
    ,
    { type: "Rate Sunday", key: 2, shortForm: "SUN" },
    { type: "Rate Bank Holiday", key: 3, shortForm: "BH" },
  ]);

  const { addToast } = useToasts();

  const handleInputChange = (e) => {
    setQuery(e);
  };

  console.log("incommingAIdata...........", incommingAIdata);
  //----------prepare candidates to popultate in dorp down select---------//
  const prepareCandidates = () => {
    let listOfCandidates = [];
    forEach(rows, function (row) {
      listOfCandidates.push({
        value: row.id,
        label: `${row.firstName} ${row.lastName}`,
        id: row.id,
        candidate: row,
      });
    });

    if (query != "") {
      setCandidates(listOfCandidates);
      //console.log("angi process 105----------->", listOfCandidates);
      setSelectedCandidate(listOfCandidates[0]);
    } else {
      setSelectedCandidate([]);
      setCandidates([]);
    }
  };

  const getClientDepartments = (data) => {
    if (data) {
      axios
        .get(HOSTMexxar + "client-wards/clients/" + data)
        .then((res) => {
          let tempDep = [];
          forEach(res.data.body, function (department) {
            tempDep.push({
              id: department.id, //from client-wards API- clientWardId is id.
              name: department.wardName,
              wardId:department.wardId
            });
          });
          setDepartments(tempDep);
        })
        .catch((err) => {
          setDepartmentError(err);
          // console.log("err", err);
          // toastCommon(true, "error");
        });
    }
  };

  //--------------[process flow no--00]--------------//

  const prepareFiledData = () => {
    let tempFields;

    //when AI scanned ref no is not matching with excisting db bookings data

    if (!timeSheetExist) {
      if (incommingAIdata) {
        tempFields = {
          referenceNo: incommingAIdata.Ref.replace(/\s/g, ""),
          date: incommingAIdata.Date,
          candidate: {
            id: incommingAIdata.CandidateId,
            value: incommingAIdata.CandidateName,
            lable: incommingAIdata.CandidateName,
          },
          candidateId: incommingAIdata.CandidateId,
          startDate: moment(incommingAIdata.Date).format("YYYY-MM-DD"),
          startTime: moment(incommingAIdata.Start, "HH:mm").format("HH:mm"),
          endDate: moment(incommingAIdata.Date).format("YYYY-MM-DD"),
          endTime: moment(incommingAIdata.End, "HH:mm").format("HH:mm"),
          client: {
            // id: incommingAIdata.clientId,
            name: incommingAIdata.HospitalName,
          },
          department: {
            // id: incommingAIdata.wardId,
            name: incommingAIdata.Ward,
          },
          speciality: incommingAIdata.Speciality,
          upLift: 0,
          bonus: 0,
          minsTakenForBreaks: incommingAIdata.Breakmin,
          totalHours: incommingAIdata.Totalhours,
        };
      }
    } else {
      //when AI scanned refno. is matching with excisting system bookings

      if (systemTimeSheetData) {
        tempFields = {
          referenceNo: systemTimeSheetData.referenceNo.replace(/\s/g, ""),
          date: systemTimeSheetData.bookingDate,
          candidate: {
            id: systemTimeSheetData.candidatesId,
            value: systemTimeSheetData.candidateName,
            lable: systemTimeSheetData.candidateName,
          },
          candidateId: systemTimeSheetData?.candidatesId,
          startDate: moment(systemTimeSheetData?.shiftFrom).format(
            "YYYY-MM-DD"
          ),
          startTime: moment(systemTimeSheetData?.shiftFrom).format("HH:mm"),
          endDate: moment(systemTimeSheetData?.shiftFrom).format("YYYY-MM-DD"),
          endTime: moment(systemTimeSheetData?.shiftTo).format("HH:mm"),
          client: {
            id: systemTimeSheetData.clientId,
            name: systemTimeSheetData.clientName,
          },
          department: {
            id: systemTimeSheetData.ward.id,
            name: systemTimeSheetData.ward.name,
          },
          speciality: systemTimeSheetData.speciality,
          upLift: systemTimeSheetData.upLift,
          bonus: systemTimeSheetData.bonus,
        };
      }
    }

    Store.dispatch(saveFiledDataAction(tempFields));
    setFieldData(tempFields);
  };

  const handleCandidateChange = (data) => {
    // console.log("selectedCandidate angi --->data", data);
    setQuery(data.value);

  };

  //*--------------prepare end date ------------------*//
  //*--------------if the end time is after 12 midnight add one date to the start date ------------------*//

  function prepEndDate(data) {
    let sTime = data.startTime.split(":")[0];
    let eTime = data.endTime.split(":")[0];

    let endDate;

    if (sTime < eTime) {
      endDate = moment(data.startDate).format("DD/MM/YYYY");
    } else {
      endDate = moment(data.startDate).add(1, "days").format("DD/MM/YYYY");
    }
    return endDate;
  }

  const typeFinder = (data) => {
    let shortForm;
    let type;
    let key;
    switch (data) {
      case "WEEK":
        shortForm = data;
        type = "Rate Week Days";
        key = 0;
        break;
      case "SAT":
        shortForm = data;
        type = "Rate Saturday";
        key = 1;
        break;
      case "SUN":
        shortForm = data;
        type = "Rate Sunday";
        key = 2;
        break;
      case "BH":
        shortForm = data;
        type = "Rate Bank Holiday";
        key = 3;
        break;
    }

    return { shortForm: shortForm, type: type, key: key };
  };

  //Get Candidate/Client and Client's Default Rates Function
  const getRates = async (
    clientId,
    specialityId,
    roleId,
    currentDepartment,
    bandId,
    type,
    candidateId = ""
  ) => {
    setDefaultRates404(false);
    let tempUpdatingArray = [];
    let tempArray = [];

    try {
      let ratesUrl = "";
      if (type === "CLIENT") {
        ratesUrl = `${HOSTMexxar}client-rates/filter?offset=17&clientId=${clientId}&specialityId=${specialityId}&roleId=${roleId}&clientWardId=${currentDepartment}&bandId=${bandId}`;
      } else if (type === "CANDIDATE") {
        ratesUrl = `${HOSTMexxar}candidate-rates/filter?offset=17&clientId=${clientId}&secialityId=${specialityId}&roleId=${roleId}&clientWardId=${currentDepartment}&bandId=${bandId}&candidateId=${candidateId}`;
      }

      const res = await axios.get(ratesUrl);

      let response = res.data.body.content;
      if (response.length === 0) {
        return [];
      }

      let DayType = ["WEEK", "SAT", "SUN", "BH"];
      let DayNight = ["DAY", "NIGHT"];
      let DefaultSpecial = ["D", "NIGHT"];

      forEach(DayType, function (daytype) {
        //......filter Day Rate.........//

        let ByDayType = filter(response, (item) => {
          return item.dayType === daytype;
        });
        console.log(`Client ID: ${clientId}`);

        // let DayNight = filter(response, (item) => {
        //   return item.dayType === daytype;
        // });

        let DayNightNight = filter(ByDayType, (item) => {
          return item.payRollDayMode === "NIGHT";
        });

        let DayNightDay = filter(ByDayType, (item) => {
          return item.payRollDayMode === "DAY";
        });

        // Special

        let RateModeSpecial = filter(ByDayType, (item) => {
          return item.rateMode === "SPECIAL";
        });

        let RateModeSpecialDay = filter(RateModeSpecial, (item) => {
          return item.payRollDayMode === "DAY";
        });

        let RateModeSpecialNight = filter(RateModeSpecial, (item) => {
          return item.payRollDayMode === "NIGHT";
        });
        // Default

        let RateModeDefault = filter(ByDayType, (item) => {
          return item.rateMode === "DEFAULT";
        });

        let RateModeDefaultDay = filter(RateModeDefault, (item) => {
          return item.payRollDayMode === "DAY";
        });

        let RateModeDefaultNight = filter(RateModeDefault, (item) => {
          return item.payRollDayMode === "NIGHT";
        });

        tempArray.push({
          type: typeFinder(daytype).type,
          key: typeFinder(daytype).key,
          shortForm: daytype,

          nId: null,
          dId: null,
          sDId: null,
          sNId: null,

          nStart: null,
          nEnd: null,
          nRate: null,

          dStart: null,
          dEnd: null,
          dRate: null,

          bRate: null,
        });

        tempUpdatingArray.push({
          type: typeFinder(daytype).type,
          key: typeFinder(daytype).key,
          shortForm: daytype,

          nId:
            RateModeDefaultNight[0] === undefined
              ? ""
              : Number(RateModeDefaultNight[0].id),
          dId:
            RateModeDefaultDay[0] === undefined
              ? ""
              : Number(RateModeDefaultDay[0].id),
          sDId:
            RateModeSpecialDay[0] === undefined
              ? ""
              : Number(RateModeSpecialDay[0].id),
          sNId:
            RateModeSpecialNight[0] === undefined
              ? ""
              : Number(RateModeSpecialNight[0].id),

          nStart:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].startTime,
          nEnd:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].endTime,
          nRate:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].rate,
          nChargeRate:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].chargeRate,

          dStart:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].startTime,
          dEnd:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].endTime,
          dRate:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].rate,
          dChargeRate:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].chargeRate,

          bRate:
            RateModeSpecial[0] === undefined ? "" : RateModeSpecial[0].rate,
          bChargeRate:
            RateModeSpecial[0] === undefined
              ? ""
              : RateModeSpecial[0].chargeRate,
        });

        setRateTypes(tempArray);
        setRateTypes(tempUpdatingArray);
      });

      return tempUpdatingArray;
    } catch (error) {
      console.error("Error fetching client rates:", error);

      return [];
    }
  };

  //*--------------verify document-------------------*//
  //--------------[process flow no--01]--------------//
  const onSubmit = async (data) => {
    console.log("selected ward angi--->", selectedDepartment);

    try {
      console.log("On Submitted data:", data);

      //Rates in order
      //1.Candidate Rate -To check if the candidate has his/hers own rates,
      //2.Client Rate -If Candidate has no rates assign to them self get the Client's Rate of that Candidate
      //3.Default Client Rate-If the Client does not have specific combination rate added to them get the Default rate of that client-(Always filter by "Default Ward" since the default rate is set
      // for the combination of bandID=1,SpecialityId=1,RoleId=1,wardId=1)
      //4.If thats also not assigned throw an error saying "Please setup defaults rates for this client"
      //5.Identify the shift type by checking above response and "day"value
      //6.Get the correct rate from that.and assign to pay and charge rate accordingly
      //ToDo: if user change the ref number update the s3 bucket file name
      //ToDo: create a blob from the s3 bucket url and upload the time sheet when verification is doing

      console.log("cliend name", clients[data?.clientIndex]?.name);
      console.log("speciality", specialities[data?.specialityIndex]?.id);
      console.log("ward", departments[data?.departmentIndex]?.id);
      console.log("band", candidates[0]?.candidate?.candidateBand?.id);
      console.log("client id", clients[data?.clientIndex]?.id);
      console.log("candidate id", candidates[0]?.candidate?.id);

      const clientId = clients[data?.clientIndex]?.id;
      const specialityId = specialities[data?.specialityIndex]?.id;
      const roleId = candidates[0]?.candidate?.candidateRole?.id;
      const departmentId = departments[data?.departmentIndex]?.id;
      const bandId = candidates[0]?.candidate?.candidateBand?.id;
      const candidateId = candidates[0]?.candidate?.id;

      let candidateRatesResults = [];
      let clientRatesResults = [];
      let clientDefaultRatesResults = [];

      let rates = [];
      let ClientRateForCandidateRates = [];
      //1.Get Candidate Rate
      candidateRatesResults = await getRates(
        clientId,
        specialityId,
        roleId,
        departmentId,
        bandId,
        "CANDIDATE",
        candidateId
      );
      rates = candidateRatesResults;

      //1b.If candidate rates are available,get relavent charge rates(Since candidate table doesn't  have charge rates)
      if (candidateRatesResults?.length !== 0) {
        clientRatesResults = await getRates(
          clientId,
          specialityId,
          roleId,
          departmentId,
          bandId,
          "CLIENT"
        );
        ClientRateForCandidateRates = clientRatesResults;
       
      }
   
      if (candidateRatesResults?.length === 0) {
        //2.Get Client Rate
        clientRatesResults = await getRates( clientId, specialityId, roleId, departmentId, bandId, "CLIENT");
        rates = clientRatesResults;
        if (clientRatesResults.length === 0) {
          //3.Get Clients Default Rate
          //Default Rates are assigned through "Default Ward"
          let defaultWardID = departments.find(
            (x) => x.name === "Default Ward"
          );

          clientDefaultRatesResults = await getRates( clientId, "1", "1", defaultWardID.id, "1", "CLIENT");
          rates = clientDefaultRatesResults;

          if (clientDefaultRatesResults.length === 0) {
            setDefaultRates404(true);
          }
        }
      }
      console.log(
        "Angi clientDefaultRatesResults id",
        clientDefaultRatesResults
      );
      console.log("Angi candidateRatesResults id", candidateRatesResults);

      const date = new Date(data?.startDate);

      const dayOfWeek = date.getDay();
      let dayType;

      if (dayOfWeek >= 1 && dayOfWeek <= 5) {
        dayType = typeFinder("WEEK");
      } else if (dayOfWeek === 0) {
        dayType = typeFinder("SUN");
      } else if (dayOfWeek === 6) {
        dayType = typeFinder("SAT");
      } else {
        dayType = typeFinder("BH"); //<----get bank holidays from the Mexxar bank holiday api
      }

      let chargeRate, payRate;

      const dayTypeMacthingRateObject = rates.find(
        (obj) => obj.shortForm === dayType.shortForm
      );
      const dayTypeMacthingRateObjectForCandidateChargeRates = ClientRateForCandidateRates.find(   //Only use  when the canidate have their own rates(we are using this to get client's charge rate for candidate's combinations )
        (obj) => obj.shortForm === dayType.shortForm
      );

      if (dayTypeMacthingRateObject) {
        const startNight = dayTypeMacthingRateObject.nStart.split(":")[0];
        const endNight = dayTypeMacthingRateObject.nEnd.split(":")[0];
        const startDay = dayTypeMacthingRateObject.dStart.split(":")[0];
        const endDay = dayTypeMacthingRateObject.dEnd.split(":")[0];

        const startHour = parseInt(data.startTime.split(":")[0], 10);

        let rateType;
        if (
          startHour >= parseInt(startNight, 10) ||
          startHour < parseInt(endNight, 10)
        ) {
          rateType = "Night";
        } else if (
          startHour >= parseInt(startDay, 10) &&
          startHour < parseInt(endDay, 10)
        ) {
          rateType = "Day";
        } else {
          rateType = "Night";
        }

        // Extract relevant rates based on rateType
     

        if (rateType === "Night") {
          payRate = dayTypeMacthingRateObject.nRate;
          chargeRate =candidateRatesResults?.length>0 ? dayTypeMacthingRateObjectForCandidateChargeRates.nChargeRate: dayTypeMacthingRateObject.nChargeRate;
        } else {
          payRate = dayTypeMacthingRateObject.dRate;
          chargeRate = candidateRatesResults?.length>0 ? dayTypeMacthingRateObjectForCandidateChargeRates.dChargeRate:  dayTypeMacthingRateObject.dChargeRate;
        }
      } else {
        console.log(`No object found with shortForm:  `);
      }

      // Generate a 4-digit random number
      // const randomNumber = Math.floor(1000 + Math.random() * 9000);
      // const randomNumber2 = Math.floor(1000 + Math.random() * 9000);
      // const firstValue = Math.floor(20 + Math.random() * 8);
      // const secondValue = Math.floor(firstValue + 3 + Math.random() * 5);

      let exportObject = {
        PaymentPeriod: "",
        ClientID: clients[data.clientIndex]?.id,
        MeritID: candidates[0]?.candidate?.meritId ?? "",
        ExternalID: candidates[0]?.id,
        MeritPlacementID: "",
        ExternalPlacementID: "",
        WorkDescription: "Temp Staff",
        PayDescription: "Basic Pay Temp Staff",
        Quantity: data?.totalHours,
        PayRate: payRate,
        ChargeRate: chargeRate,
        Reference: data?.refNo,
        Ref2: "",
        Startdate: data?.startDate,
        Enddate: "",
        Comments: "",
        EndUserClient: "",
        ComparatorHolidayDays: "",
        PaymentCurrencyCode: "GBP",
        InvoiceCurrencyCode: "",
        VatAdjust: "",
        Consultant: user,
      };

     

      let timesheet = {
        locumName: candidates[0]?.label,
        locumId: candidates[0]?.id,
        speciality: specialities[data?.specialityIndex]?.id,
        band: candidates[0]?.candidate?.candidateBand?.id,
        hospitalName: clients[data?.clientIndex]?.name,
        dateOfShift: data?.startDate,
        ward: departments[data?.departmentIndex]?.id,
        bookingRef: data?.refNo,
        startTime: data?.startTime,
        endTime: data?.endTime,
        minsTakenForBreaks: data?.minsTakenForBreaks,
        totalHours: data?.totalHours,
        locumDate: data?.startDate,
        authoriseName: "",
        authorisePosition: "",
        authoriseDate: data?.startDate,
        bookingId: systemTimeSheetData?.id,
        hospitalReviewStatus: 0,
        paymentStatus: 0,
        reviewStatus: 4,
      };

      let form = new FormData();
      form.append("file", selectedFile.file);
      let dateTimeNow = new Date();
      // console.log("export object angi---?", exportObject);
      axios.post(HOSTMexxar + "timesheets/", timesheet).then((res) => {
        if (res.data.status == "success" && res.data.message == "created") {
          // ToastCommon(true, "success", addToast);
          updateBookingIfNotMatching(data, res, form);
          dispatch(
            updateTimesheetReviewedDataCandy(
              selectedFile.DBData.referenceNo,
              exportObject,
              dateTimeNow
            )
          );
          verified(selectedFile);
        } else if (
          res.data.status == "success" &&
          res.data.message == "updated"
        ) {
          ToastCommon(true, "update", addToast);
          updateBookingIfNotMatching(data, res, form);
          dispatch(
            updateTimesheetReviewedDataCandy(
              selectedFile.DBData.referenceNo,
              exportObject,
              dateTimeNow
            )
          );
          verified(selectedFile);
          // uploadTimesheet(data, res, form);
        } else if (res.data.status == "failed") {
          ToastCommon(true, "error", addToast);
        }
      });
    } catch (error) {
      console.error("Error in onSubmit:", error);
    }
  };

  //*--------------Update booking details in the system if even one data is miss matching with verifying data------------------*//
  //--------------[process flow no--02]--------------//

  const updateBookingIfNotMatching = (data, response, form) => {
    let shiftSTime = moment(data.startDate).add(data.startTime).toDate();
    let shiftETime = moment(data.startDate).add(data.endTime).toDate();

    let arrangData = {
      id: systemTimeSheetData.id,
      bookingDate: moment(data.startDate).format("YYYY-MM-DD"),
      referenceNo: data.refNo,
      clientsId: Number(clients[data.clientIndex].id),
      //  employeeId: auth.item.id,
      // specialityId: Number(candidateSpecialities[0].id),
      specialityId: Number(specialities[data.specialityIndex].id),
      wardId: Number(departments[data.departmentIndex].wardId),          //<---------change this later to  Number(departments[data.departmentIndex].id) which is the client's wardId 
      shiftTo: shiftETime,
      shiftFrom: shiftSTime,
    };

    // if any field is not matching with system booking details, update it

     console.log("data.refNo",data.refNo)
    console.log("systemTimeSheetData.referenceNo",systemTimeSheetData.referenceNo)
    if (
      systemTimeSheetData.referenceNo != data.refNo ||
      systemTimeSheetData.candidatesId != candidates[0].id ||
      moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
        moment(data.startDate).format("YYYY-MM-DD") ||
      moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
        moment(data.startTime).format("HH:mm") ||
      moment(systemTimeSheetData.shiftTo).format("YYYY-MM-DD") !=
        moment(data.endTime).format("YYYY-MM-DD") ||
      moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
        moment(prepEndDate(data)).format("HH:mm") ||
      systemTimeSheetData.clientId != clients[data.clientIndex].id ||
      systemTimeSheetData.ward.id != departments[data.departmentIndex].id ||
      systemTimeSheetData.speciality.id != specialities[data.specialityIndex].id
    ) {
      axios
        .put(HOSTMexxar + "bookings", arrangData)
        .then((res) => {
          if (res.data.status == "success") {
            ToastCommon(true, "success", addToast);
            uploadTimesheet(data, response, form);
          } else if (res.data.status == "failed") {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {});
    }
    // else if fields  are matching with system booking details, upload the Timesheet
    else {
      uploadTimesheet(data, response, form);
    }
  };

  //*--------------upload timesheet------------------*//

  const uploadTimesheet = (data, res, form) => {
    axios
      .put(
        HOSTMexxar +
          "timesheets/" +
          res.data.body[0].id +
          "/upload-timesheetDocument",
        form
      )
      .then((res) => {
        if (res.data.status == "success" && res.data.message == "fetched") {
          ToastCommon(true, "timesheetUploaded", addToast);
          // processTimesheetData(data);
        } else if (res.data.status == "failed") {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  //*--------------process timesheet for payroll calculations------------------*//
  //--------------[process flow no--04]--------------//

  const processTimesheetData = (item) => {
    setAiPending(true);

    let tempData = [
      {
        bookingReference: item.refNo,
        startTime: item.startTime + ":00",
        endTime: item.endTime + ":00",
        startDate: moment(item.startDate).format("DD/MM/YYYY"),
        endDate: prepEndDate(item),
      },
    ];

    axios
      .post(HOSTMexxar + "workspace/payroll", tempData)
      .then((res) => {
        setAiPending(false);
        if (res.data.status == "success") {
          ToastCommon(true, "timesheetProcessed", addToast);
          setPayrollData(res.data.body);
          setShowSummary(true);
        } else if (res.data.status == "failed") {
          setPayrollError(res.data.status);
          ToastCommon(true, "timesheetProcessedError", addToast);
        }
      })
      .catch((err) => {
        setAiError(true);
      });
  };

  //two types of data coming
  //a.AI data(incommingAIdata)
  //b.DB Data(systemTimeSheetData)
  //*********** Process ***********/
  //1.When systemTimeSheetData comes prepare them to populate in the form
  //     a.Check if timehsheet ref no is can be found in the system -using timeSheetExist flag to determine this
  //     b.If its matching then populate system data directly to the form (fieldData)
  //     c.else populate incommingAIdata to the form (fieldData)
  //         c.0. Since AI Scanned booking reference not available follow below process to find it from the system
  //         c.1. Type the correct ref id in the reference filed and click "Retry"
  //                if : available then populate new found data in the fieldData-  **call prepareFiledData()
  //                   click verufy with other data.This will update the current booking details in the DB
  //                else : user cannot change data.Since no booking is made with given timehseet ref no(No booking Id to update the data in the DB)
  //     d.

  useEffect(() => {
    // console.log("angi process 1");
    prepareFiledData();
  }, [timeSheetExist, systemTimeSheetData]);

  useDidMountEffect(() => {
    // console.log("angi process 2");

    prepareCandidates();
    return () => {
      Store.dispatch(saveFiledDataAction(null));
    };
  }, [rows, timeSheetExist]);

  useDidMountEffect(() => {
    if (fieldData) {
      //  console.log("angi process 3");
      setQuery(fieldData.candidate.value);
    }
  }, [fieldData]);

  useDidMountEffect(() => {
    // console.log("angi process 4----------->", selectedCandidate);
    if (selectedCandidate) {
      //  console.log("angi process 4.1", selectedCandidate?.candidate?.preferredClients);
      setClients(selectedCandidate?.candidate?.preferredClients || []);
      setSpecialities(
        selectedCandidate?.candidate?.candidateSpecialities || []
      );
      getClientDepartments(
        selectedCandidate?.candidate?.preferredClients[0]?.id
      );
    } else {
      //  console.log("angi process 4.2");
      setClients([{ id: 0, name: "Select" }]);
      setSpecialities([{ id: 0, name: "Select" }]);
    }
  }, [selectedCandidate]);

  useDidMountEffect(() => {
    if (fieldData && clients) {
      //  console.log("angi process 5");
      let index = findIndex(clients, (item) => {
        //    console.log("angi clients", item);
        //    console.log("angi fieldData", fieldData);
        return item.name == fieldData.client.name;
      });

      if (index === -1) {
        //   console.log("angi process 5.1");
        setClientCantFind(true);
      } else {
        //   console.log("angi process 5.2");
        setClientCantFind(false);
        setSelectedClient(index);
      }
    }
  }, [clients]);

  useDidMountEffect(() => {
    if (fieldData && specialities) {
      // console.log("angi process 6 ");
      let index = findIndex(specialities, (item) => {
        return item.name == fieldData.speciality?.name;
      });

      if (index === -1) {
        //  console.log("angi process 6.1");
        setSpecialityCantFind(true);
      } else {
        //  console.log("angi process 6.2");
        setSpecialityCantFind(false);
        setSelectedSpeciality(index);
      }
    }
  }, [specialities, fieldData]);

  useDidMountEffect(() => {
    // console.log("angi process 7");
    store.dispatch(clientsGetAction());
  }, [systemTimeSheetData]);

  useDidMountEffect(() => {
    // {
    //   clients &&
    //     selectedClient &&
    //     clients[selectedClient] != undefined &&
    //    console.log("angi process 8");
    // }
    {
      clients &&
        selectedClient &&
        clients[selectedClient] != undefined &&
        getClientDepartments(clients[selectedClient].id);
    }
  }, [selectedClient]);

  useDidMountEffect(() => {
    if (fieldData && departments) {
      // console.log("angi process 10 ");
      let index = findIndex(departments, (item) => {
        return item.name == fieldData.department.name;
      });

      if (index === -1) {
        //   console.log("angi process 10.1");
        setDepartmentCantFind(true);
      } else {
        //   console.log("angi process 10.2");
        setDepartmentCantFind(false);
        setSelectedDepartment(index);
      }
    }
  }, [departments]);

  useDidMountEffect(() => {
    // console.log("angi process 11 clearing every state");
    setFieldData(null);
    setQuery("");
    setSelectedDepartment(0);
    setDepartments([{ id: 0, name: "Select" }]);
    reset();
  }, [selectedFile.file]);

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#121212",
    }),
  };
  // console.log("selectedCandidate angi", selectedCandidate);
  return (
    <div className="card " style={{ width: "100%" }}>
      <div className="card-body bg-light">
        <Form onSubmit={handleSubmit(onSubmit)} className="col-12">
          <FormGroup
            row
            className="d-flex justify-content-between align-items-center"
          >
            <Label className="ml-3">Process Timesheet </Label>
            <small>
              Please note that the data below is populated against the candidate
              identified from the candidate field.
            </small>
          
          </FormGroup>

          <hr />

          <FormGroup row>
            <Col md={`${timeSheetExist ? 12 : 10}`}>
              <Label>Ref No</Label>
              {/* {console.log("hi angi---?useeffect ", fieldData)} */}
              <Input
                type="text"
                defaultValue={fieldData && fieldData.referenceNo}
                placeholder="Ref No"
                id="refNo"
                name="refNo"
                onChange={(e) => setRefNo(e.target.value)}
                invalid={
                  !timeSheetExist ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.referenceNo.replace(/\s/g, "") !=
                      incommingAIdata.Ref.replace(/\s/g, ""))
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
            {!timeSheetExist && (
              <Col md={2} className="d-flex align-items-end ">
                <Button id="retry" color="success" onClick={() => retry(refNo)}>
                  Retry
                </Button>

                <UncontrolledTooltip target="retry">
                  Retry to find reference id
                </UncontrolledTooltip>
              </Col>
            )}
          </FormGroup>

          <FormGroup row>
            <Col md={12}>
              <Label>Candidate</Label>
              <Select
                id="candidateId"
                name="candidateId"
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedCandidate}
                onChange={(data) => handleCandidateChange(data)}
                isLoading={loading}
                options={candidates}
                placeholder="Search candidates ..."
                onInputChange={handleInputChange}
                noOptionsMessage={() => "No results found"}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
                styles={colourStyles}
              />
            </Col>
            <Col md={6}>
             
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <Label>Start Date</Label>
              <Input
                type="Date"
                defaultValue={fieldData && fieldData.startDate}
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
                    moment(incommingAIdata.Date).format("YYYY-MM-DD")
                }
                placeholder="Start Time"
                id="startDate"
                name="startDate"
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
                  moment(incommingAIdata.Date).format("YYYY-MM-DD") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>

                      {moment(incommingAIdata.Date).format("YYYY-MM-DD")}
                    </li>
                  </ul>
                )}
            </Col>
            <Col md={6}>
              <Label>Start Time</Label>
              <Input
                type="time"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
                    moment(incommingAIdata.Start, "HH:mm").format("HH:mm")
                }
                defaultValue={fieldData && fieldData.startTime}
                id="startTime"
                name="startTime"
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
                  moment(incommingAIdata.Start, "HH:mm").format("HH:mm") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>
                      {moment(incommingAIdata.Start, "HH:mm").format("LT")}
                    </li>
                  </ul>
                )}
            </Col>{" "}
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <Label>End Date</Label>
              <Input
                type="Date"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftTo).format("YYYY-MM-DD") !=
                    moment(incommingAIdata.Date).format("YYYY-MM-DD")
                }
                defaultValue={fieldData && fieldData.endDate}
                id="endDate"
                name="endDate"
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftTo).format("YYYY-MM-DD") !=
                  moment(incommingAIdata.Date).format("YYYY-MM-DD") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>
                      {moment(incommingAIdata.Date).format("L")}
                    </li>
                  </ul>
                )}
            </Col>
            <Col md={6}>
              <Label>End Time</Label>
              <Input
                type="time"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
                    moment(incommingAIdata.End, "HH:mm").format("HH:mm")
                }
                placeholder="End Time"
                id="endTime"
                name="endTime"
                defaultValue={fieldData && fieldData.endTime}
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
                  moment(incommingAIdata.End, "HH:mm").format("HH:mm") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>

                      {moment(incommingAIdata.End, "HH:mm").format("LT")}
                    </li>
                  </ul>
                )}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <Label>{ClientLable}</Label>
              <Input
                type="select"
                id="clientIndex"
                name="clientIndex"
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
                invalid={
                  clientCantFind ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.clientName?.toLowerCase() !=
                      incommingAIdata.HospitalName?.toLowerCase())
                }
              >
                {clients.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      style={{ color: "#6A6767" }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Input>

              {clientCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData.clientName?.toLowerCase() !=
                    incommingAIdata.HospitalName?.toLowerCase() && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.HospitalName}
                      </li>
                    </ul>
                  ))}
            </Col>
            <Col md={6}>
              <Label>Department</Label>
              <Input
                type="select"
                id="departmentIndex"
                name="departmentIndex"
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
                invalid={
                  departmentCantFind ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.ward?.name?.toLowerCase() !=
                      incommingAIdata.Ward?.toLowerCase())
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              >
                {departments.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      style={{ color: "#6A6767" }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Input>

              {departmentCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData.ward?.name?.toLowerCase() !=
                    incommingAIdata.Ward?.toLowerCase() && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.Ward}
                      </li>
                    </ul>
                  ))}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={12}>
              <Label>Specialities</Label>
              <Input
                type="select"
                id="specialityIndex"
                name="specialityIndex"
                value={selectedSpeciality}
                onChange={(e) => setSelectedSpeciality(e.target.value)}
                invalid={
                  specialityCantFind ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData?.speciality?.name?.toLowerCase() !=
                      incommingAIdata?.Speciality?.toLowerCase())
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              >
                {specialities.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      style={{ color: "#6A6767" }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Input>

              {specialityCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData?.speciality?.name?.toLowerCase() !=
                    incommingAIdata?.Speciality?.toLowerCase() && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.Speciality}
                      </li>
                    </ul>
                  ))}
            </Col>

         
          </FormGroup>
          <FormGroup row>
            <Col sm={6}>
              <Label>Break Mins.</Label>
              <Input
                type="text"
                placeholder="Break Mins."
                id="minsTakenForBreaks"
                name="minsTakenForBreaks"
                defaultValue={incommingAIdata && incommingAIdata.Breakmin}
                invalid={errors.minsTakenForBreaks}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
            <Col sm={6}>
              <Label>Total Hours</Label>
              <Input
                type="text"
                placeholder="Total Hours"
                id="totalHours"
                name="totalHours"
                defaultValue={
                  selectedFile?.manuallyReviewed
                    ? selectedFile?.reviewedData?.Quantity
                    : incommingAIdata && incommingAIdata.Totalhours
                }
                invalid={
                  errors.totalHours ||
                  (selectedFile?.reviewedData?.Quantity > 24 &&
                    incommingAIdata.Totalhours > 24)
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
              {selectedFile?.reviewedData?.Quantity > 24 &&
                incommingAIdata.Totalhours > 24 && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      Total hours are exceeding 24 hours
                    </li>
                  </ul>
                )}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={12}>
              <Alert color="info">
                <strong className="text-danger">*</strong> The text in{" "}
                <strong className="text-danger">red</strong> represent
                recognized hand written text{" "}
              </Alert>
            </Col>
            <Col sm={12} className="d-flex justify-content-end mr-5 ">
              {isEmpty(systemTimeSheetData) && (
                <Alert color="danger" className="mx-2">
                  We couldn't find a booking with the provided reference number.
                  Please ensure that you have the correct reference number for
                  the relevant booking made for above date and update it if it
                  doesn't match with the timesheet.
                </Alert>
              )}
              {isNull(systemTimeSheetData?.referenceNo) && (
                <Alert color="danger" className="mx-2">
                  We couldn't find the booking reference number. Please enter
                  the relevant reference number above.{" "}
                </Alert>
              )}
              {defaultRates404 && (
                <Alert color="danger" className="mx-2">
                  We couldn't find rates for above candidate or client. Please
                  set up rates and try again later. Note: Please check with your
                  Admin if the "Default rates" for above {ClientLable}.
                </Alert>
              )} 
              {selectedFile?.manuallyReviewed && (
                <Alert color="warning" className="mx-2">This timesheet has already been reviewed. Please verify again only if necessary.</Alert>
              )}

              {selectedFile?.preVerifiedAndProccessedStatus?.status === "PRE_VERIFIED" && (
                <Alert color="danger" className="mx-2">Already Processed: This timesheet has already been reviewed and verified. Please check before re-processing.</Alert>
              )}

              <Button color={`${selectedFile?.preVerifiedAndProccessedStatus?.status === "PRE_VERIFIED" ? "danger": "success" } `} type="submit" >
                {aiPending && (
                  <Spinner
                    animation="border"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                  />
                )}
                Verify
                {selectedFile?.preVerifiedAndProccessedStatus?.status === "PRE_VERIFIED" &&  <FontAwesomeIcon className="mx-2" icon={faExclamationTriangle}></FontAwesomeIcon>}
               
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    reduxSpecialities: state.initialDataGetReducer.specialities,
    reduxClients: state.initialDataGetReducer.clients,
    reduxRoles: state.initialDataGetReducer.roles,
    user: state.auth.item.firstName + " " + state.auth.item.lastName,
    timesheet: state.timesheets.timesheets,
  };
}

export default connect(mapStateToProps, {})(CandyTimesheetDataBatchViewAngi);
