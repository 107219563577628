import React, { useState, useEffect } from "react";
import "./working-card-widget.style.css";
import { Badge, Card, CardBody, Button } from "reactstrap";
import { connect } from "react-redux";
import logo from "../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMars,
  faVenus,
  faPhone,
  faEnvelope,
  faCalendar,
  faPhoneSlash,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import ModalComponent from "../../components/Modal/modalComponent";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import {
  Add_NEW_EMPLOYEE,
  HOSTMexxar,
  HostMexxarURL,
} from "../../configs/api-config";
import {
  ApplicationStatus,
  GenderSwitch,
  ConversationResponse,
} from "../../util/common";
import { CheckImage } from "../../components/CashImage/CashImageFunction";
import notNull from "../../util/common/notNull";

const EmployeeList = (props) => {
  // const MexxarApiEmployee = Add_NEW_EMPLOYEE;
  const { userProfile, auth } = props;
  const [calendarShow, setCalendarShow] = useState(false);
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const handleSelectedEmployee = (employeeUser) =>
    setSelectedEmployee(employeeUser);
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const MexxarAPI = HOSTMexxar;
  const MexxarURL = HostMexxarURL;
  const [showEmailModal, setShowEmailModal] = useState(false);

  const toggleShowEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };
  const [profilePicture, setProfilePicture] = useState(null);

  const toggleM = () => setShowEmailModal(!showEmailModal);

  const [employees, setEmployees] = useState([]);
  const MexxarUrl = HostMexxarURL;

  const loadEmployeeProPic = () => {
    if (!notNull(userProfile.avatar)) {
      if (userProfile.gender === "MALE") {
        setProfilePicture(MexxarUrl + "images/maleEmployee.png");
      } else if (userProfile.gender === "FEMALE") {
        setProfilePicture(MexxarUrl + "images/femaleEmployee.png");
      } else {
        setProfilePicture(MexxarUrl+"images/default.jpg");
      }
    } else {
      setProfilePicture(
        MexxarAPI + String(userProfile.avatar).replace("pro_pic", "pro-pic")
      );
    }
  };

  useEffect(() => {
    loadEmployeeProPic();
  }, [userProfile]);

  return (
    <div style={{ minWidth: "800px" }}>
      <div
        className=" card d-flex flex-row main bg-light p-2 "
        style={{ marginBottom: "5px" }}
      >
        <div className="d-flex align-items-center p-1">
          <Link to={"/dashboard/employee-profile/" + userProfile.id}>
            <div>
              {/* <image src={logo} /> */}
              <span className="w-64 avatar">
                {profilePicture ? (
                  <div>
                    <img
                      src={profilePicture}
                      alt=""
                      style={{
                        height: "4rem",
                        width: "4rem",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    ></img>
                  </div>
                ) : null}
              </span>
              {/* <i className="on"></i> */}
            </div>
          </Link>
        </div>
        <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
          <div className="d-flex ml-1 mb-1">
            <div>
              {userProfile.firstName}&nbsp;{userProfile.lastName}
            </div>
          </div>
          <div className=" d-flex ">
            <div className="mx-2 ">
              {/* <Link to={"/dashboard/call/" + userProfile.id}>
                <RoundIconButton
                  buttonColor="#00C426"
                  icon={faPhone}
                  iconSize="fa-md"
                  buttonSize="25px"
                ></RoundIconButton>
              </Link> */}
            </div>

            {/* <div className="mx-2 ">
              <RoundIconButton
                buttonColor="#458AF7"
                icon={faEnvelope}
                click={() => {
                  toggleShowEmailModal();
                  handleSelectedEmployee(userProfile);
                }}
                buttonSize="25px"
              ></RoundIconButton>
            </div> */}
          </div>
        </div>
        <div
          className="d-flex  align-items-center  flex-column"
          style={{ flex: 1 }}
        >
          <div style={{ height: "4vh", flex: 1 }}> </div>
          <div style={{ height: "6vh", flex: 1 }}>
            <FontAwesomeIcon
              // color={userProfile.gender == "1" ? "#458AF7" : "#f54394"}
              color={GenderSwitch(userProfile.gender).genderColor}
              // icon={userProfile.gender == "1" ? faMars : faVenus}
              icon={GenderSwitch(userProfile.gender).genderSymbol}
            />
          </div>
        </div>

        <div
          className="d-flex align-items-center flex-column"
          style={{ flex: 1 }}
        >
          <div style={{ height: "4vh", flex: 1 }}> </div>
          <div style={{ height: "6vh", flex: 1 }}>
            {" "}
            <Badge color="info">id:{userProfile.id}</Badge>
          </div>
        </div>

        <div
          className="d-flex align-items-center flex-column  "
          style={{ flex: 4 }}
        >
          <div style={{ height: "4vh", flex: 1 }}>Role</div>
          <div style={{ height: "6vh", flex: 1 }}>
            {userProfile.employeeRole}
          </div>
        </div>

        <div
          className="d-flex align-items-center flex-column "
          style={{ flex: 5 }}
        >
          <div style={{ height: "4vh" }}>Team</div>
          <div style={{ height: "3vh" }}>Team id : {userProfile.teamId}</div>
          <div>Team Name : {userProfile.teamName}</div>
          <div
            className="d-flex justify-content-center "
            style={{ height: "3vh" }}
          ></div>
        </div>
        <div
          className="d-flex align-items-center flex-column"
          style={{ flex: 4 }}
        >
          <div style={{ height: "4vh", flex: 1 }}>Status</div>
          <div style={{ height: "6vh", flex: 1 }}>{userProfile.status}</div>
        </div>
      </div>

      <ModalComponent
        show={calendarShow}
        header="Reschedule a call"
        closeModal={() => handleCalendarShow(false)}
      >
        <SmallCalendar />{" "}
        <div className="mt-3">
          {" "}
          <div className="d-flex justify-content-end">
            <Button className="mr-2" color="success">
              Send
            </Button>{" "}
            <Button color="secondary" onClick={() => handleCalendarShow(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showEmailModal}
        header="Email"
        closeModal={() => toggleM()}
      >
        {/* {console.log("Employee : ", selectedEmployee)} */}
        <EmailNav
          closeModal={() => toggleM()}
          user={selectedEmployee}
        ></EmailNav>
        {/* <div className="mt-3">
          {" "}
          <div className="d-flex justify-content-end">
            <Button className="mr-2" color="success" type="submit">
              Send
            </Button>{" "}
            <Button color="secondary" onClick={toggleM}>
              Cancel
            </Button>
          </div>
        </div> */}
      </ModalComponent>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(EmployeeList);
