import { ADD_FILTERED_HEADHUNTER_WORKSPACE } from "../actions/types";

const initialState = {
  filtered: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_FILTERED_HEADHUNTER_WORKSPACE:
      return {
        // ...state,
        filtered: action.payload,
      };

    default:
      return state;
  }
}
