import {
  ADD_MOBILE_AI_TIMESHEET_DATA,
  UPDATE_AI_TIMESHEET_DATA,
  DELETE_AI_TIMESHEET_DATA,
  GET_AI_TIMESHEET_DATA,
  ADD_MOBILE_SYSTEM_TIMESHEET_DATA,
  UPDATE_SYSTEM_TIMESHEET_DATA,
  DELETE_SYSTEM_TIMESHEET_DATA,
  GET_SYSTEM_TIMESHEET_DATA,
  REMOVE_TIMESHEETS_LOCALLY_ALL,
  REMOVE_TIMESHEETS_LOCALLY_ONE,
  GET_LOCAL_TIMESHEETS,
  MOBILE_TIME_SHEET_EXIST_OR_NOT,
  UPDATE_MOBILE_TIMESHEET_REVIEWED_DATA,
  UPDATE_TIMESHEET_SCAN_PROCESSING_STATUS,
  LOG_FAILED_TIMESHEETS,
  SCAN_MOBILE_STATUS_INDIVIDUAL,
  UPDATE_MOBILE_TIMESHEET_AI_REVIEWED_STATUS,
  FINAL_MANUALLY_REVIEW_CONFIRMATION,
  SAVE_GENERATED_URL,
  SAVE_MOBILE_TIMESHEETS_LOCALLY,
  PRE_MOBILE_VERIFIED_STATUS,
} from "./types";

import store from "../store";

//AI  timesheet data

export const addAITimesheetData = (timesheetId, AIModalData) => (dispatch) => {
  dispatch({
    type: ADD_MOBILE_AI_TIMESHEET_DATA,
    payload: {
      id: timesheetId,
      AIModalData: AIModalData,
    },
  });
};

//system timesheet data

export const addSystemTimesheetData = (timesheetId, DBData) => (dispatch) => {
  // console.log("Angi addSystemTimesheetData--->",timesheetId, DBData)
  dispatch({
    type: ADD_MOBILE_SYSTEM_TIMESHEET_DATA,
    payload: {
      id: timesheetId,
      DBData: DBData,
    },
  });
};

export const timesheetExistStatus =
  (timesheetId, timeSheetExist) => (dispatch) => {
    dispatch({
      type: MOBILE_TIME_SHEET_EXIST_OR_NOT,
      payload: {
        id: timesheetId,
        timeSheetExist: timeSheetExist,
      },
    });
  };

export const updateScanProcessStatus = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_TIMESHEET_SCAN_PROCESSING_STATUS,
    payload: data,
  });
};

export const saveGeneratedUrl = (generatedUrl) => ({
  type: SAVE_GENERATED_URL,
  payload: generatedUrl,
});

export const updateTimesheetReviewedData =
  (id, data, dateTimeNow) => (dispatch) => {
    dispatch({
      type: UPDATE_MOBILE_TIMESHEET_REVIEWED_DATA,
      payload: {
        id: id,
        reviewedData: data,
        timesheetReviewDateTime: dateTimeNow || "",
      },
    });
  };

export const updateTimesheetAIReviewedStatus = (id, status) => (dispatch) => {
  dispatch({
    type: UPDATE_MOBILE_TIMESHEET_AI_REVIEWED_STATUS,
    payload: {
      id: id,
      status: status,
    },
  });
};

export const finalManualReviewConfirmation = (status) => (dispatch) => {
  dispatch({
    type: FINAL_MANUALLY_REVIEW_CONFIRMATION,
    payload: status,
  });
};

export const logFailedTimesheets = (file, errorMessage) => (dispatch) => {
  dispatch({
    type: LOG_FAILED_TIMESHEETS,
    payload: {
      file,
      errorMessage,
    },
  });
};

export const scanStatusIndividual = (id, status, message) => (dispatch) => {
  dispatch({
    type: SCAN_MOBILE_STATUS_INDIVIDUAL,
    payload: {
      id,
      status,
      message,
    },
  });
};

export const preVerifiedStatusMobile = (id, status, message) => (dispatch) => {
  dispatch({
    type: PRE_MOBILE_VERIFIED_STATUS,
    payload: {
      id,
      status,
      message,
    },
  });
};


export const saveMobileTimesheetsLocally = (files) => (dispatch) => {
  dispatch({
    type: SAVE_MOBILE_TIMESHEETS_LOCALLY,
    payload: files,
  });
};

export const deleteOneTimesheetsLocally = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_TIMESHEETS_LOCALLY_ONE,
    payload: id,
  });
};

export const deleteAllTimesheetsLocally = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_TIMESHEETS_LOCALLY_ALL,
    payload: id,
  });
};
