import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";

export default function TodoModal(props) {
  const { register, handleSubmit, errors } = useForm();
  const [addKillLog, setaddKillLog] = useState(false);
  const {
    onCreate,
    closeModal,
    mode,
    modalType,
    fieldDataOne,
    fieldDataTwo,
  } = props;
  const [value, setValue] = useState("");
  const changeHandler = ({ target }) => {
    setValue(target.value);
  };

  const onSubmit = (data) => {
    onCreate(data);
    setaddKillLog(true);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={4}>{modalType}</Label>
        <Col sm={8}>
          <Input
            value={value}
            onChange={changeHandler}
            type="text"
            name="todo"
            defaultValue={fieldDataOne}
            invalid={errors.todo}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allowed only 30 characters",
              },
            })}
          ></Input>
          {errors.todo && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.todo?.message}
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4} for="notifyDate">
          Date
        </Label>
        <Col sm={8}>
          <Input
            type="date"
            name="notifyDate"
            id="notifyDate"
            innerRef={register}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={4} for="notifyTime">
          Time
        </Label>
        <Col sm={8}>
          <Input
            type="time"
            name="notifyTime"
            id="notifyTime"
            innerRef={register}
          />
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <div className="d-flex ">
          {" "}
          <Button
            className="mr-2"
            color="success"
            type="submit"
            disabled={addKillLog}
          >
            {mode}
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}
