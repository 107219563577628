import React from "react";
import { ModalBody, ModalFooter, ModalHeader, Modal } from "reactstrap";
import { Button } from "reactstrap";

function calendarEvent() {
  return (
    <Modal isOpen={true}>
      <ModalHeader className="border-bottom bg-danger">Alert</ModalHeader>
      <ModalBody>
        Selected date is booked or unavailable please select a different date.
      </ModalBody>
      <ModalFooter>
        <Button color="secondary">Close</Button>
      </ModalFooter>
    </Modal>
  );
}

export default calendarEvent();
