import { CHANGE_THEME } from "../actions/types";

const initialState = {
  theme: "bg-dark",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_THEME:
      return {
        ...state,
        theme: action.payload,
      };

    default:
      return state;
  }
}
