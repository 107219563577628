import React, { useState, useEffect } from "react";
import { Badge, Button, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import ModalComponent from "../../../components/Modal/modalComponent";
import TimeSheet from "../../../components/Modal/ModalBody/timeSheet";
import SmallCalendar from "../../../components/Modal/ModalBody/smallCalendar";
import { HOSTMexxar } from "../../../configs/api-config";
import TimeSheetUpload from "../../../components/Modal/ModalBody/timeSheetUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const TransactionWidgetFilter = (props) => {
    const { userProfile, refreshPage } = props;
    const [calendarShow, setCalendarShow] = useState(false);
    const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
    const [showTimeModal, setShowTimeModal] = useState(false);
    const [data, setData] = useState();
    //   const [rows, setRows] = useState(null);
    const MexxarApi = HOSTMexxar;

    const toggleShowTimeModal = (data) => {
        setData(data);
        setShowTimeModal(!showTimeModal);
    };


    //   const getAllTransactions = () => {
    //     axios
    //       .get(MexxarApi + "transaction/getAll/filter")
    //       .then((res) => {
    //         setRows(res.data.body);
    //       })
    //       .catch((err) => {
    //         //console.log(err)
    //         // ToastCommon(true, "error", addToast);
    //       });
    //   };



    //   useEffect(() => {
    //     getAllTransactions();
    //   }, []);
    console.log("userProfile", userProfile);
    return (

        <>
            <div className="">
                <div>
                    <div className="card  list-row mb-2 ">
                        <div className="list-item " data-id="7" data-sr-id="44">
                            <div className="d-flex align-items-center mr-1 ">
                                <span className="mx-1">
                                    <b className="badge badge-circle sm text-primary"></b>
                                </span>
                            </div>


                            <div className="flex" style={{ flex: 4 }}>
                                <a className="item-author text-color" data-pjax-state="">
                                    {userProfile.refNo}
                                </a>
                                <div>
                                    <small className="text-muted">{userProfile?.employee?.usersName}</small>
                                </div>
                                <div className="item-mail text-muted h-1x d-none d-sm-block">
                                    <span className="mr-1">
                                        <small className="text-muted">
                                            {moment(userProfile?.employee?.createdDate).format("ddd MMM DD YYYY")} by{" "}
                                            {userProfile?.employee?.employeeRole}
                                        </small>
                                    </span>
                                </div>
                            </div>

                            {/* <div
              className="d-flex justify-content-center align-items-center flex-column"
              style={{ flex: 2 }}
            >
              {(() => {
                switch (userProfile.bookingStatus) {
                  case "CONFIRM":
                    return (
                      <div className="mr-5">
                        <Badge color="success">confirm</Badge>
                      </div>
                    );

                  default:
                    return (
                      <div className="mr-5">
                        <Badge color="warning">unconfirm</Badge>
                      </div>
                    );
                    break;
                }
              })()}
            </div> */}



                            <div
                                className="d-flex justify-content-center align-items-center flex-column"
                                style={{ flex: 2 }}
                            >
                                <div>Amount</div>
                                <div>{userProfile.amount}</div>
                            </div>

                            <div
                                className="d-flex justify-content-center align-items-center flex-column"
                                style={{ flex: 2 }}
                            >
                                <div>Date :</div>

                                <div> {moment(userProfile?.Date).format("ddd MMM DD YYYY")}</div>
                            </div>
                            <div
                                className="d-flex justify-content-center align-items-center flex-column"
                                style={{ flex: 2 }}
                            >
                                <div>Email :</div>
                                <div>{userProfile?.employee?.email}</div>
                            </div>
                            <div
                                className="d-flex justify-content-center align-items-center flex-column"
                                style={{ flex: 2 }}
                            >
                                <div>Credit Service Type :</div>
                                <div>
                                    {/* {userProfile.creditServiceType} */}



                                    {(() => {
                                        switch (userProfile.creditServiceType) {
                                            case "CLIENT_MAP":
                                                return <Badge className="bg-secondary">Client Map</Badge>;

                                            case "CHAT":
                                                return <Badge className="bg-secondary">Chat</Badge>;

                                            case "EMAIL":
                                                return <Badge className="bg-secondary">Email</Badge>;
                                            case "MESSAGE":
                                                return <Badge className="bg-secondary">Message</Badge>;
                                            case "TIME_SHEET":
                                                return <Badge className="bg-secondary">Time Sheet</Badge>;
                                            default:
                                                return null;
                                                break;
                                        }
                                    })()}</div>
                            </div>


                        </div></div></div></div>

            <ModalComponent
                show={calendarShow}
                header="Log new call back"
                closeModal={() => handleCalendarShow(false)}
            >
                <SmallCalendar />
                <div className="mt-3">
                    {" "}
                    <Button color="success">Send</Button>{" "}
                    <Button color="secondary" onClick={() => handleCalendarShow(false)}>
                        Cancel
                    </Button>
                </div>
            </ModalComponent>
            <ModalComponent
                show={showTimeModal}
                header="Upload Time Sheet"
                size="lg"
                closeModal={() => setShowTimeModal(false)}
            >
                <TimeSheetUpload
                    data={data}
                    closeModal={() => setShowTimeModal(false)}
                    getCandidates={refreshPage}
                ></TimeSheetUpload>
            </ModalComponent>
        </>

    );
};

export default TransactionWidgetFilter;
