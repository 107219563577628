// actions.js

export const setAuthToken = (token) => ({
    type: 'SET_AUTH_TOKEN',
    payload: token,
  });
  
  export const setLoggedInUser = (user) => ({
    type: 'SET_LOGGED_IN_USER',
    payload: user,
  });
  