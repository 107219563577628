import React, { useState, useEffect } from "react";
import { Card, CardBody, Label } from "reactstrap";
import axios from "axios";
import Data from "./bookings.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPhone,
  faMicrophoneSlash,
  faMicrophone,
  faUpload,
  faChevronLeft,
  faChevronRight,
  faUser,
  faPhoneSquare,
  faMapPin,
  faMapMarkerAlt,
  faExpand,
  faPeopleArrows,
  faEdit,
  faCheck,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Badge } from "reactstrap";
import "bootstrap/dist/css/bootstrap.css";
import CircleCard from "../../components/CircleContent/button";
import loading from "../../../src/images/puff.svg";
import { CREATE_BOOKING } from "../../configs/api-config";
import moment from "moment";

const BookingSummery = (props) => {
  // const { userProfile } = props;
  // axios.get("bookings.json").then((res) => {
  //   console.log(res);
  //   this.setState({
  //     booking: res.data,
  //   });
  // });

  const MexxarApi = CREATE_BOOKING;
  const [rows, setRows] = useState([]);

  const [certificates, setCertificates] = useState([]);

  const getAllRows = () => {
    axios
      .get(MexxarApi+"/employee-booking")
      .then((res) => {
        //console.log("hello",res.data)

        setRows(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
        //toastCommon(true, "error");
      });
  };
  useEffect(() => {
    getAllRows();
  }, []);

  //console.log("booking of candidate", rows);

  return (
    <div className="">
      {rows.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center">
          {/* Loading animation */}
          <div>
            <img src={loading} style={{ height: 100 }}></img>
          </div>
        </div>
      ) : (
        <div>
          {rows.map((row, id) => {
            return (
              <div key={id} className="card  list-row mb-2 ">
                <div className="list-item " data-id="7" data-sr-id="44">
                  <div className="d-flex align-items-center mr-1 ">
                    <span className="mx-1">
                      <b className="badge badge-circle sm text-primary"></b>
                    </span>
                  </div>

                  <div>
                    <a data-pjax-state="" href="/employee/candidate/6">
                      <span>
                        {/* className="w-40 avatar bg-warning" */}
                        {/* {(() => {
                          switch (row.status) {
                            case "pending":
                              return (
                                <CircleCard
                                  subHeading="Pending"
                                  bgColor="warning"
                                />
                              );

                            case "completed":
                              return (
                                <CircleCard
                                  subHeading="Completed"
                                  bgColor="success"
                                />
                              );

                            case "cancled-by-the-hospital":
                              return (
                                <CircleCard subHeading="CBC" bgColor="danger" />
                              );

                            case "cancled-by-the-candidate":
                              return (
                                <CircleCard subHeading="CBH" bgColor="danger" />
                              );

                            case "did-not-attend":
                              return (
                                <CircleCard
                                  subHeading="Amended"
                                  bgColor="primary"
                                />
                              );

                            default:
                              return null;
                              break;
                          }
                        })()} */}

                        {/* {row.statusColor ? (
                    <FontAwesomeIcon icon={faCheck} className="ml-3" />
                  ) : (
                    <FontAwesomeIcon icon={faEdit} className="ml-3" />
                  )} */}
                      </span>
                    </a>
                  </div>
                  <div className="flex">
                    <a
                      className="item-author text-color"
                      data-pjax-state=""
                      href="/employee/candidate/6"
                    >
                      {row.clientName}
                    </a>

                    <div className="item-mail text-muted h-1x d-none d-sm-block">
                      <span className="mr-1">
                        <small className="text-muted">
                          {moment(row.bookingDate).format("ddd MMM DD YYYY")} by{" "}
                          {row.employeeName}
                        </small>
                      </span>

                      {/* <time
                        datetime="2020-12-20T00:00:00Z"
                        data-local="time"
                        data-format="%B %e, %Y %l:%M%P"
                        title="December 20, 2020 at 5:30am IST"
                        data-localized=""
                        aria-label="December 20, 2020 5:30am"
                      >
                        <small>{row.bookingDate} </small>
                      </time> */}
                    </div>
                  </div>

                  <div className="d-flex ">
                    {(() => {
                      switch (row.bookingStatus) {
                        case "Confirmed":
                          return (
                            <div className="mr-5">
                              <Badge color="success">confirm</Badge>
                            </div>
                          );

                        default:
                          return (
                            <div className="mr-5">
                              <Badge color="warning">unconfirm</Badge>
                            </div>
                          );
                          break;
                      }
                    })()}
                  </div>

                  <div>
                    <div>Shift Type</div>
                    <div>{row.shift.shortform}</div>
                  </div>
                  <div>
                    <div>Ward</div>
                    <div>{row.ward.name}</div>
                  </div>
                  <div>
                    <div>Reference No.</div>
                    <div>{row.referenceNo}</div>
                  </div>
                  {/* <div>
                    <div className="item-action dropdown">
                      <a
                        className="text-muted"
                        data-pjax-state=""
                        data-toggle="dropdown"
                        href="#"
                      >
                        <svg
                          className="feather feather-more-vertical"
                          fill="none"
                          height="16"
                          strokeLinecap="round"
                         strokeLinejoin="round"
                          strokeWidth="2"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          width="16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="12" cy="5" r="1"></circle>
                          <circle cx="12" cy="19" r="1"></circle>
                        </svg>
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-right bg-black"
                        role="menu"
                      >
                        <a className="dropdown-item" data-pjax-state="" href="#">
                          See detail
                        </a>
                        <a className="dropdown-item download" data-pjax-state="">
                          Download
                        </a>
                        <a className="dropdown-item edit" data-pjax-state="">
                          Edit
                        </a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item trash" data-pjax-state="">
                          Delete item
                        </a>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default BookingSummery;
