import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Badge } from "reactstrap";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { HOSTMexxar } from "../../../configs/api-config";
import ToastCommon from "../../../components/toastCommon";
import ModalComponent from "../../../components/Modal/modalComponent";
import { Button, Input, Form, Label, FormGroup, Col } from "reactstrap";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import loadingAnimation from "../../../../src/images/puff.svg";

const TicketsSuper = () => {
  const MexxarApi = HOSTMexxar;
  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();
  const [showAddRow, setShowAddRow] = useState(false);
  const [row, setRowId] = useState();
  const [rows, setRows] = useState(null);
  const [loading, setLoading] = useState(false);

  const getAllRows = () => {
    setLoading(true);
    axios
      .get(MexxarApi + "tickets")
      .then((res) => {
        setLoading(false);
        setRows(res.data.body);
      })
      .catch((err) => {
        setLoading(false);
        ToastCommon(true, "error", addToast);
      });
  };

  const onSubmit = (data) => {
    let templateBody = row;
    templateBody.createdDate = moment().format();
    templateBody.status = data.statusUpdate;

    axios
      .post(MexxarApi + "tickets", templateBody)
      .then((res) => {
        if (res.data.status == "success" || res.data.message == "created") {
          getAllRows();
          ToastCommon(true, "success", addToast);
          setShowAddRow(!showAddRow);
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  useEffect(() => {
    getAllRows();
  }, []);

  return (
    <DashboardLayout title="Tickets" subTitle="Manage Tickets" target="">
      {/* <div>
          {" "}
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddRow(true);
            }}

            style={{margin:"0.5rem"}}
          >
            Add a ticket
          </Button>
        </div> */}

      <ModalComponent
        show={showAddRow}
        header="Add New Email Template"
        closeModal={() => setShowAddRow(false)}
      >
        <Form className="col-12" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup row>
            <Label sm={4} for="userRoleId">
              Status
            </Label>
            <Col sm={8}>
              <Input
                type="select"
                id="statusUpdate"
                name="statusUpdate"
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              >
                <option value="PENDING">PENDING</option>
                <option value="SOLVED">SOLVED</option>
              </Input>
            </Col>
          </FormGroup>

          <div className=" mt-3 d-flex justify-content-end pointer">
            <Button className="mr-2" color="success" type="submit">
              Update
            </Button>{" "}
            <Button color="danger" onClick={() => setShowAddRow(false)}>
              Cancel
            </Button>
          </div>
        </Form>
      </ModalComponent>

      <br></br>

      {rows === null && loading && (
        <div className="d-flex justify-content-center align-items-center">
          {/* Loading animation */}
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
      {rows == null ? null : (
        <div>
          {rows.map((body) => (
            <div className="card">
              <div className="list list-row">
                <div className="list-item" data-id={body.id}>
                  <div className="flex">
                    <div className="item-feed h-2x">
                      <div className="capitalize">{body.title}</div>

                      <div className="text-muted capitalize">{body.body}</div>
                    </div>
                  </div>
                  <div className="no-wrap">
                    <div className="item-date text-muted text-sm d-none d-md-block">
                      {moment(body.createdDate).format("llll")}
                    </div>
                  </div>
                  <div
                    className="no-wrap pointer "
                    onClick={() => {
                      setShowAddRow(true);
                      setRowId(body);
                    }}
                  >
                    <Badge
                      color="warning "
                      className="btn-sm btn-wave btn-raised "
                    >
                      {body.status}
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </DashboardLayout>
  );
};

export default TicketsSuper;
