

import { ModalBody } from "react-bootstrap";
import {
  Col,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  Row,
  Form,
  Badge,
  Button,
} from "reactstrap";
import * as XLSX from "xlsx";

export default function ExpiringExpiredDocumentList(props) {
  const { closeModal, data, status } = props;

  let documents = status.type === "Expired" ? data.expiredDocuments : data.expiringDocuments;


  const exportToExcel = () => {
    const fileName = status.type === "Expired" ? "Expired_data.xlsx" : "Expiring_data.xlsx";
  
    console.log("Exporting to Excel with file name:", fileName);
    
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(documents);
  
    XLSX.utils.book_append_sheet(workbook, worksheet, "documents");
  
    XLSX.writeFile(workbook, fileName);
  };
  
  return (
    <Modal isOpen={status.bool} toggle={() => closeModal(false)}>
      <ModalHeader
        className="modal-header bg-primary"
        close={
          <button className="close" onClick={() => closeModal(false)}>
            ×
          </button>
        }
      >
        {status.type} Documents
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Row>
              <Label className="col-3 col-md-7">Document</Label>
              <Label className="col-2 col-md-3">Exp Date</Label>
              <Label className="col-2 col-md-2">Status</Label>
            </Row>
            {documents.length === 0 ? (
              <div>No documents available</div>
            ) : (
              <div id="table-to-xls">
                {documents.map((doc, id) => {
                  return (
                    <Row key={id}>
                      <Col md={7}>
                        <Label>{doc.document_Name}</Label>
                      </Col>
                      <Col md={3}>
                        <Label>{doc.expireDate}</Label>
                      </Col>
                      <Col md={2}>
                        {status.type === "Expired" ? (
                          <Badge color="danger" key={id}>
                            Expired
                          </Badge>
                        ) : (
                          <Badge color="warning" key={id}>
                            Expiring
                          </Badge>
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </div>
            )}
          </FormGroup>
          {documents.length !== 0 && (
            <div className="d-flex flex-row">
              <Button
               color="success"
                onClick={exportToExcel}
                className="mx-1"
              >
            Data Export
              </Button>
            </div>
          )}
        </Form>
      </ModalBody>
    </Modal>
  );
}
