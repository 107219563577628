import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";

import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../../components/toastCommon";
import { forEach, isNull, filter, uniq, uniqBy } from "lodash";
import loadingAnimation from "../../../../../src/images/puff.svg";
import { useIsMounted } from "../../../../customHooks/useIsMounted";
import { HOSTMexxar, Add_New_DOCUMENT } from "../../../../configs/api-config";
import ExpDocsWidget from "../../../../widgets/working-card-widget/exp-docs-widget";
import useDidMountEffect from "../../../../components/Modal/ModalBody/intialRunUseEffect";
import ModalComponent from "../../../../components/Modal/modalComponent";
import SnapShotNav from "../../../../components/Modal/ModalBody/snapShotNav";
import NoSearchResults from "../../../errorPages/NoSearchResults";
import { FunnelIcon } from "../../../../assets/icons/svg";
import notNull from "../../../../util/common/notNull";
import useExpriedExpiringDocsFilters from "../../../../customHooks/useExpriedExpiringDocsFilters";
import { useSpring, animated } from "react-spring";
import NoConnection from "../../../errorPages/NoConnection";
import * as XLSX from "xlsx";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((item) => {
      let fullName =
        item.candidateFirstName +
        item.candidateMiddleName +
        item.candidateLastName;
      let firstLastName = item.candidateFirstName + item.candidateLastName;

      let searchableString = `${fullName} ${firstLastName} ${item.candidateFirstName} ${item.candidateLastName} ${item.email} ${item.primaryPhone}`;
      let p = searchableString;
      return p.toLowerCase().includes(name.toLowerCase());
    });
  }
  return filtered;
};

const ExpDocs = (props) => {
  const MexxarAPI = HOSTMexxar;
  const MexxarApiDocList = Add_New_DOCUMENT;

  const { updateTargets, employeeID } = props;
  const { userProfile, ref } = props;
  const isMounted = useIsMounted();
  const { addToast } = useToasts();

  const [page, setPage] = useState(1);
  const [range, setRange] = useState(null);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  /***************** Search filter ******************/
  const [query, setQuery] = useState(null);
  const [reset, setReset] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [remember, setRemember] = useState(true); /// <--------filter settings options saving and retreving/ if need activate later with below checkbox
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchAdvanced, setSearchAdvanced] = useState(false);
  const [callResponse, setCallResponse] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [initialRows, setInitialRows] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [documentsList, setDocumentsList] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [selectedItemsForSnapshot, setSelectedItemsForSnapshot] = useState();
  const [showSnapshotModal, setShowSnapshotModal] = useState(false);
  const [docError, setDocError] = useState(false);
  const [docStatus, setDocStatus] = useState("ALL");


  const [filterCustom, setFilterCustom] = useState({
    value: "EXPIRED",
    bool: true,
  });



  const handleSearch = (e) => {
    if (e.target.value == "") {
      setQuery(null);
    } else {
      setQuery(e.target.value);
    }
    setPageNumber(1);
  };

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  const getDocList = () => {
    axios
      .get(MexxarApiDocList)
      .then((res) => {
        let docListArray = [];

        forEach(res.data.body, function (item) {
          docListArray.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });

        if (isMounted.current) {
          setDocumentsList(docListArray);
        }
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  let url = `${HOSTMexxar}workspace/get-employee-candidates-exp-docs/detailed?`;

  let sort = true;
  const { loading, error, rows, hasMore, totalElements } =
    useExpriedExpiringDocsFilters(
      url,
      query,
      pageNumber,
      searchAdvanced,
      reset,
      employeeID,
      selectedDocuments.value,
      range,
      docStatus
    );

  const observer = useRef();
  const lastComponetRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const [loadingExport, setLoadingExport] = useState(false);




  //....reset function......//
  const resetFilters = () => {
    setReset(!reset);
    setDocStatus("ALL");
    setPageNumber(1);
    setSelectedDocuments([]);
  };

  useDidMountEffect(() => {
    //    handleSearch();
    return () => {
      setReset(!reset);
    };
  }, [reset]);

  useEffect(() => {
    // getAllRows();
    getDocList();
    updateTargets();
    return () => { };
  }, []);

  /******* Use to set page number to default value of 1 when changing the employee or search advance filters  *******/
  useDidMountEffect(() => {
    setPageNumber(1);
  }, [searchAdvanced, employeeID]);

  const handleCheck = (e) => {
    setDocError(false);
    setSelectedDocuments([]);
    setDocStatus(e);
  };

  function sortFunction(a, b) {
    var dateA = new Date(a.uploadedDate).getTime();
    var dateB = new Date(b.uploadedDate).getTime();
    return dateA < dateB ? 1 : -1;
  }

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  const toggleSnapshotModal = () => {
    let tempArray = [];

    if (!selectAll) {
      for (let i = 0; i < rows.length; i++) {
        for (let x = 0; x < selectedItems.length; x++) {
          if (selectedItems[x] == rows[i].candidateId) {
            tempArray.push(rows[i]);
          }
        }
      }
    } else if (selectAll) {
      for (let i = 0; i < rows.length; i++) {
        tempArray.push(rows[i]);
      }
    }

    setSelectedItemsForSnapshot(tempArray);
    setShowSnapshotModal(!showSnapshotModal);
  };

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#6A6767",
    }),
  };
  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  useDidMountEffect(() => {
    setDocError(false);
    return () => { };
  }, [selectedDocuments]);


  useDidMountEffect(() => {
    setDocError(false);

    return () => { };
  }, [selectedDocuments]);
  const fetchAllPages = async () => {
    let allRows = [];
    let currentPage = 1;
    let hasMorePages = true;
    let totalPagesFetched = 0;
    while (hasMorePages) {
      try {
        const response = await axios.get(url, {
          params: {
            page: currentPage,
            q: query,
            mode: docStatus,
            offset: 20,
            expiringDays: range,
            employeeId: employeeID,
            "q-document": selectedDocuments.value,
          },
        });

        const data = response.data.body;
        console.log(`Fetching page ${currentPage}`, data.content);
        allRows = [...allRows, ...data.content];
        totalPagesFetched++;
        currentPage++;

        hasMorePages = data.totalPages >= currentPage;
      } catch (err) {
        console.error("Error fetching data for export:", err);
        hasMorePages = false;
      }
    }

    console.log("Total pages fetched:", totalPagesFetched);

    console.log("All fetched data:", allRows);
    return allRows;
  };


  const exportToExcel = async () => {
    setLoadingExport(true);
    try {
      const allRows = await fetchAllPages();
      console.log("Loooooooding data for Excel");

      const dataForExcel = allRows.map((row) => {
        const expiredDocumentNames = row.expiredDocuments.map(
          (doc) => `${doc.document_Name} - ${doc.expireDate}`
        );
        const expiringDocumentNames = row.expiringDocuments.map(
          (doc) => `${doc.document_Name} - ${doc.expireDate}`
        );

        const expiringSoonDocuments = row.expiringDocuments.filter((doc) => {
          const expirationDate = new Date(doc.expireDate);
          const sevenDaysLater = new Date();
          sevenDaysLater.setDate(sevenDaysLater.getDate() + 7);
          return expirationDate <= sevenDaysLater;
        });

        const expiringSoonDocumentNames = expiringSoonDocuments.map(
          (doc) => `${doc.document_Name} - ${doc.expireDate}`
        );

        const maxLen = Math.max(
          expiredDocumentNames.length,
          expiringDocumentNames.length,
          expiringSoonDocumentNames.length
        );

        const candidateDocuments = [];
        for (let i = 0; i < maxLen; i++) {
          candidateDocuments.push({
            CandidateId: row.candidateId,
            CandidateName: `${row.candidateFirstName} ${row.candidateLastName}`,
            WorkFlowStatus: row.workFlowStatus,
            ExpiredDocCount: row.expiredDocCount,
            ExpiringDocCount: row.expiringDocCount,
            ExpiredDocument: expiredDocumentNames[i] || "",
            ExpiringDocument: expiringDocumentNames[i] || "",
            ExpiringSoonDocument: expiringSoonDocumentNames[i] || "",
          });
        }

        return candidateDocuments;
      });

      const flattenedDataForExcel = dataForExcel.flat();
      console.log("Final data for Excel:", flattenedDataForExcel);

      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(flattenedDataForExcel);

      XLSX.utils.book_append_sheet(workbook, worksheet, "Candidates");

      const filename = `${moment(new Date()).format(
        "YYYY-MM-DD"
      )}-Expiring Expired Docs Report.xlsx`;

      XLSX.writeFile(workbook, filename);


      setLoadingExport(false);
    } catch (error) {
      console.error("Failed to export data:", error);
      addToast("Failed to export data", { appearance: "error" });


      setLoadingExport(false);
    }
  };


  return (
    <>
      <div className="form-inline ">
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={(e) => handleSearch(e)}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            //textTransform: "lowercase",
          }}
        />

        <UncontrolledTooltip target="searchBar">
          Search candidates by Name/Id/Email/Phone No.
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label>Document</Label>
                <Select
                  value={selectedDocuments}
                  // isMulti
                  onChange={(data) => setSelectedDocuments(data)}
                  name="documentName"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={documentsList}
                  styles={colourStyles}
                />
                {docError && (
                  <span className="text-danger">
                    Please select a document to show results
                  </span>
                )}
              </Col>
              {docStatus !== "EXPIRED" ? (
                <Col>
                  <Label>Range</Label>
                  <Input
                    type="input"
                    defaultValue={60}
                    onChange={(e) => setRange(e.target.value)}
                    style={{ width: "100px" }}
                  />
                </Col>
              ) : null}

              <Col>
                <Label>Status</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      value="ALL"
                      checked={docStatus === "ALL"}
                      onChange={(e) => handleCheck(e.target.value)}
                      type="checkbox"
                    />
                    <Badge color="info">All</Badge>
                  </Label>
                </FormGroup>

                <FormGroup check>
                  <Label check>
                    <Input
                      value="EXPIRING"
                      checked={docStatus === "EXPIRING"}
                      onChange={(e) => handleCheck(e.target.value)}
                      type="checkbox"
                    />{" "}
                    <Badge color="warning">Expiring</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="EXPIRED"
                      checked={docStatus === "EXPIRED"}
                      onChange={(e) => handleCheck(e.target.value)}
                    />
                    <Badge color="danger">Expired</Badge>
                  </Label>
                </FormGroup>
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={() => {
                      setSearchAdvanced(!searchAdvanced);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={resetFilters}
                  >
                    Reset
                  </Button>



                  <Button
                    color="warning m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={exportToExcel}
                    disabled={loadingExport}
                  >
                    {loadingExport ? <Spinner size="sm" /> : "Export"}
                  </Button>


                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>

      {rows?.map((row, i) => {
        if (rows.length === i + 1) {
          return (
            <animated.div
              style={animationProps}
              ref={lastComponetRef}
              key={row.email}
            >
              <ExpDocsWidget key={i} userProfile={row}></ExpDocsWidget>
            </animated.div>
          );
        } else {
          return (
            <animated.div style={animationProps} key={row.email}>
              <ExpDocsWidget key={i} userProfile={row}></ExpDocsWidget>
            </animated.div>
          );
        }
      })}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}
      {!loading && !error && rows.length == 0 && <NoSearchResults />}
      {showSnapshotModal ? (
        <ModalComponent
          show={showSnapshotModal}
          header="Mailshot"
          closeModal={() => setShowSnapshotModal(false)}
        >
          <SnapShotNav
            closeModal={() => setShowSnapshotModal(false)}
            user={selectedItemsForSnapshot}
          />
        </ModalComponent>
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(ExpDocs);