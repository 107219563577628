import React, { useState, useEffect } from "react";
import axios from "axios";
import { forEach, filter, isEmpty, isNull, find } from "lodash";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../toastCommon";
import { HOSTMexxar, CompanyName, REGISTER_CANDIDATE } from "../../../configs/api-config";
import {
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Alert,
  Input,
} from "reactstrap";
import notNull from "../../../util/common/notNull";
import useDidMountEffect from "./intialRunUseEffect";
import moment from "moment";
import ModalComponent from "../modalComponent";
import ResubmitForm from "./Admin/resubmit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { UncontrolledTooltip } from "reactstrap/lib";

function ReferenceFormTemplateHistory(props) {
  const { candidateId, refId, closeModal, rows, page, type, mode = "POST", identifier, getData,reset } = props;
  const MexxarApi = REGISTER_CANDIDATE;

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();
  // const [rows, setRows] = useState(null);
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const [identifierOneAvailable, setIdentifierOneAvailable] = useState(false);
  const [identifierTwoAvailable, setIdentifierTwoAvailable] = useState(false);
  const [error, setError] = useState(null);
  const [sendEmail, setSendEmail] = useState(false);

  //common filter to extract data wth key and values //
  const filterData = (data, identifier) => {
    let data_List = [];

    let id = data[identifier + "ID" + refId];
    let value = data[identifier + refId];

    data_List.push({
      id: Number(id),
      identifier: identifier + refId,
      value: value,
      detailCategory: 7,
    });

    return data_List;
  };



  const onSubmit = (data) => {
    setPostLoading(true);

    let AdvanceDetailsArray = [];

    //check if this is update-form-filling or new-form-filling
    if (rows && notNull(rows[0])) {
      let A = filterData(data, "rJobTitle");
      let B = filterData(data, "rBand");
      let C = filterData(data, "rDateOfEmployement");
      let D = filterData(data, "rDateOfTermination");
      let E = filterData(data, "rOrganisationName");
      let F = filterData(data, "rTelephoneNumber");
      let G = filterData(data, "rEmail");
      let H = filterData(data, "rAddress");
      let I = filterData(data, "refereeName");
      let J = filterData(data, "rPostcode");

      let updatingArray = A.concat(B, C, D, E, F, G, H, I, J);
      AdvanceDetailsArray = updatingArray;
    } else {
      forEach(data, function (value, key) {
        if (value === "") {
        } else {
          AdvanceDetailsArray.push({
            identifier: key,
            value: value,
            detailCategory: 7,
          });
        }
      });
    }

    axios
      .post(
        MexxarApi + "/" + candidateId + "/advance-profile-details",
        AdvanceDetailsArray
      )
      .then((res) => {
        if (res.data.status === "success") {
          // getAllRows();
          // setShowAddRow(!showAddRow);
          // getData()
          ToastCommon(true, "success", addToast);
          if (sendEmail) {
            sendRef();
          }
        } else if (res.data.status === "failed") {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err);
        ToastCommon(true, "error", addToast);
      });
    setPostLoading(false);
  };


  const sendRef = () => {
    setPostLoading(true);
    setSendEmail(false);
    axios({
      method: "post",
      url:
        HOSTMexxar +
        "candidates/" +
        candidateId +
        "/send-reference-email?identifier=" +
        refId,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      // withCredentials: true,
      mode: "cors",
    })
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "send", addToast);
          // closeModal()
          // getData();
        } else if (res.data.status == "failed") {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
    setPostLoading(false);
  };


  const [isOpen, setIsOpen] = useState(false);

  const toggleList = () => {
    setIsOpen(!isOpen);
  };

  var candidateBand;


  const [ukey, setUkey] = useState("");
  const [signed, setSigned] = useState(false);
  const [candidateName, setCandidateName] = useState("");
  const [referenceForm, setReferenceForm] = useState(null);
  const [referenceFormStatus, setReferenceFormStatus] = useState(null);
  const [employeementDateError, setEmployeementDateError] = useState(false);
  const [dateOfEmployement, setDateOfEmployement] = useState("");
  const [terminationDate, setTerminationDate] = useState("");
  const [showResubmitForm, setShowResubmitForm] = useState(false);



  const [IsWorkEmail, setIsWorkEmail] = useState(true);

  const intialBands = [
    "Band 1",
    "Band 2",
    "Band 3",
    "Band 4",
    "Band 5",
    "Band 6",
    "Band 7",
    "Band 8",
    "Band 9",
  ];



  const [bands, setBands] = useState(intialBands);

  const [selectedBand, setSelectedBand] = useState(-1);


  const questions = [
    {
      questionNo: "colleagueRelationships",
      question: "Colleague Relationships",
    },
    {
      questionNo: "patientRelationships",
      question: "Patient Relationships",
    },
    {
      questionNo: "attitude",
      question: "Attitude",
    },
    {
      questionNo: "reliability",
      question: "Reliability",
    },
    {
      questionNo: "clinicalCompetency",
      question: "Clinical Competency",
    },
    {
      questionNo: "flexibility",
      question: "Flexibility",
    },
  ];



  const getReferenceForm = () => {
    axios
      .get(`${HOSTMexxar}reference-form/candidate/${candidateId}`)
      .then((res) => {
        if (res.data.status == "success") {
          if (res.data.body.length > 0) {
            let rFData = filter(res.data.body, function (item) {
              return Number(item.identifier) == Number(identifier);
            });
            setReferenceForm(rFData[0]);
            setReferenceFormStatus(rFData[0]?.referenceStatus);
          } else {
            setReferenceForm(null);
          }
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };




  const checkBoxStatus = (question, identifier) => {
    let checkStatus = find(referenceForm, function (value, key) {
      if (key == question && value == identifier) {
        return true;
      } else {
        return false;
      }
    });
    return checkStatus;
  };





  useDidMountEffect(() => {
    if (moment(terminationDate).isSameOrBefore(moment(dateOfEmployement))) {
      setEmployeementDateError(true);
    } else {
      setEmployeementDateError(false);
    }
  }, [dateOfEmployement, terminationDate]);

  useEffect(() => {
    if (referenceForm?.band != null ?? undefined) {
      setSelectedBand(referenceForm?.band);
    }
  }, [bands, referenceForm]);

  useEffect(() => {
    getReferenceForm();
    getData()
    return () => { };
  }, [referenceFormStatus]);




  return (
    <>
      <div>
        <Form onSubmit={handleSubmit(onSubmit)}>

          <div>

            {/* <ReferenceFormTemplateHistory /> */}
            <div className="">


              <div className="card-header pl-0 ml-0 col-md-12 d-flex justify-content-between align-items-center">
                <h5>Reference {refId + 1}</h5>
              </div>
            </div>
            <br />
            <Alert className="d-flex align-items-justify  justify-content-justify flex-column" color="warning">
              <p>Your submission has been rejected. Please resubmit a new form.</p>

            </Alert>
            <div className="mt-2">
              <FormGroup row>
                <Col sm={12} md={4}>
                  <Label>
                    Job Title <span className="text-danger">*</span>
                  </Label>

                  <Input
                    type="name"
                    name={"rJobTitle" + refId}
                    placeholder="Job Title "
                    defaultValue={rows && rows[0]?.rJobTitle}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  />
                  <Input
                    type="hidden"
                    innerRef={register}
                    name={"rJobTitleID" + refId}
                    defaultValue={rows && rows[0]?.rJobTitleID}
                  />
                </Col>
                <Col sm={12} md={4}>
                  <Label>Band</Label>
                  <Input
                    type="text"
                    name={"rBand" + refId}
                    placeholder="Band"
                    defaultValue={rows && rows[0]?.rBand}
                    innerRef={register({
                      required: {
                        value: false,
                        message: "This field is required!",
                      },
                    })}
                  />
                  <Input
                    type="hidden"
                    innerRef={register}
                    name={"rBandID" + refId}
                    defaultValue={rows && rows[0]?.rBandID}
                  />
                </Col>
                <Col sm={12} md={4}>
                  <Label>Organisation Name</Label>
                  <Input
                    type="name"
                    name={"rOrganisationName" + refId}
                    placeholder="Organisation Name"
                    defaultValue={rows && rows[0]?.rOrganisationName}
                    innerRef={register({
                      required: {
                        value: false,
                        message: "This field is required!",
                      },
                    })}
                  />
                  <Input
                    type="hidden"
                    innerRef={register}
                    name={"rOrganisationNameID" + refId}
                    defaultValue={rows && rows[0]?.rOrganisationNameID}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12} md={6}>
                  <Label>Date of Employment</Label>
                  <Input
                    type="date"
                    name={"rDateOfEmployement" + refId}
                    placeholder="Date of employment"
                    defaultValue={rows && rows[0]?.rDateOfEmployement}
                    innerRef={register({
                      required: {
                        value: false,
                        message: "This field is required!",
                      },
                    })}
                  />
                  <Input
                    type="hidden"
                    innerRef={register}
                    name={"rDateOfEmployementID" + refId}
                    defaultValue={rows && rows[0]?.rDateOfEmployementID}
                  />
                </Col>
                <Col sm={12} md={6}>
                  <Label>Date of Termination</Label>
                  <Input
                    type="date"
                    name={"rDateOfTermination" + refId}
                    placeholder="Date of termination"
                    defaultValue={rows && rows[0]?.rDateOfTermination}
                    innerRef={register({
                      required: {
                        value: false,
                        message: "This field is required!",
                      },
                    })}
                  />
                  <Input
                    type="hidden"
                    innerRef={register}
                    name={"rDateOfTerminationID" + refId}
                    defaultValue={rows && rows[0]?.rDateOfTerminationID}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12} md={4}>
                  <Label>
                    Referee Name <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="name"
                    name={"refereeName" + refId}
                    placeholder="Referee's Name"
                    defaultValue={rows && rows[0]?.refereeName}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  />
                  <Input
                    type="hidden"
                    innerRef={register}
                    name={"refereeNameID" + refId}
                    defaultValue={rows && rows[0]?.refereeNameID}
                  />
                </Col>
                <Col sm={12} md={4}>
                  <Label>Telephone Number</Label>
                  <Input
                    type="text"
                    name={"rTelephoneNumber" + refId}
                    placeholder="Telephone Number"
                    defaultValue={rows && rows[0]?.rTelephoneNumber}
                    innerRef={register({
                      required: {
                        value: false,
                        message: "This field is required!",
                      },
                      pattern: {
                        value:
                          /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                        message: "Please enter a valid phone number",
                      },
                    })}
                  />
                  <Input
                    type="hidden"
                    innerRef={register}
                    name={"rTelephoneNumberID" + refId}
                    defaultValue={rows && rows[0]?.rTelephoneNumberID}
                  />
                </Col>
                <Col sm={12} md={4}>
                  <Label>
                    Referee Email <span className="text-danger">*</span>
                  </Label>

                  <Input
                    type="rEmail"
                    name={"rEmail" + refId}
                    placeholder="Refree Email"
                    defaultValue={rows && rows[0]?.rEmail}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                      pattern: {
                        value:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: "Please enter a valid email address!",
                      },
                    })}
                  />
                  <Input
                    type="hidden"
                    innerRef={register}
                    name={"rEmailID" + refId}
                    defaultValue={rows && rows[0]?.rEmailID}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm={12} md={8}>
                  <Label>Address</Label>
                  <Input
                    type="text"
                    name={"rAddress" + refId}
                    placeholder="Refree Address"
                    defaultValue={rows && rows[0]?.rAddress}
                    innerRef={register({
                      required: {
                        value: false,
                        message: "This field is required!",
                      },
                    })}
                  />
                  <Input
                    type="hidden"
                    innerRef={register}
                    name={"rAddressID" + refId}
                    defaultValue={rows && rows[0]?.rAddressID}
                  />
                </Col>
                <Col sm={12} md={4}>
                  <Label>
                    Post Code <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name={"rPostcode" + refId}
                    placeholder="Postal code"
                    defaultValue={rows && rows[0]?.rPostcode}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  />
                  <Input
                    type="hidden"
                    innerRef={register}
                    name={"rPostcodeID" + refId}
                    defaultValue={rows && rows[0]?.rPostcodeID}
                  />
                </Col>
              </FormGroup>
            </div>
          </div>
          <div className="d-flex justify-content-between my-4">
            <div></div>
            <div className="d-flex align-items-center"></div>
            {referenceForm?.referenceStatus === "REJECTED" && (
              <div>
                <Button
                  className="mr-2"
                  color="warning"
                  onClick={() => {
                    setShowResubmitForm(true);
                  }}
                >
                  Resubmit
                </Button>

              </div>
            )}

          </div>
        </Form>


      </div>

      <br />


      <div className="">
        <div className=" pl-0 ml-0 col-md-12 d-flex justify-content-between align-items-center">
          <h5>Previous Reference Form </h5>


          {isOpen ? (
            <FontAwesomeIcon
              icon={faChevronUp}
              size={8}
              onClick={toggleList}
              className="pointer"
              id="comments"
            />

          ) : (
            <FontAwesomeIcon
              icon={faChevronDown}
              size={8}
              onClick={toggleList}
              className="pointer"
              id="comments"
            />
          )}


          <UncontrolledTooltip target="comments">
            Expand Previous Rejected Reference Form
          </UncontrolledTooltip>
        </div>
      </div>
      <br />

      <div className={`row ${mode == "POST" && "mx-5"}`}>
        <div className={`col-md-12  ${mode == "POST" && "p-5"}`}>
          {mode == "POST" && (
            <div className={`${mode == "POST" && "px-3 mx-5"}`}>
              <div className="card-header d-flex justify-content-start text-align-center">
                {/* <image src="../../../src/images/candiHealthCare.jpg" /> */}
                <h2>REFERENCE REQUEST FORM</h2>
                {/* <FormGroup row>
                <button
                  className={`btn btn-wave btn-raised ${
                    editable ? "bg-warning " : "bg-danger "
                  }`}
                  type="button"
                  onClick={() => handleEdit()}
                >
                  {editable ? "Save" : "Edit"}
                  {editable ? (
                    <FontAwesomeIcon icon={faCheck} className="ml-3" />
                  ) : (
                    <FontAwesomeIcon icon={faEdit} className="ml-3" />
                  )}
                </button>
              </FormGroup> */}
              </div>

              <div className="m-3">
                <div>
                  <Alert className="text-justify">
                    The following person has applied to work for us at{" "}
                    <b>{CompanyName}</b>&nbsp; and you have been nominated by them
                    to be their referee. We will need the following information
                    from you in order to assess their suitability to work for us.
                    Please fill this information out as accurately as possible. We
                    would like to take this opportunity to thank you for your
                    assistance in this matter.
                  </Alert>
                  <Alert color="info">
                    <span className="text-danger mx-2">*</span>
                    Note : Please fill all the fields before submitting the form.
                  </Alert>
                </div>
              </div>
            </div>
          )}

          {isOpen && (
            <div className={`${mode == "POST" && "p-3 mx-5"}`}>
              <div className="">
                <>
                  <div className="  col-md-12 d-flex justify-content-between align-items-center">
                    <h5>Candidate Employment Details</h5>
                  </div>

                  <div className="mt-2">
                    <FormGroup row className="m-3">
                      <Col sm={12} md={4}>
                        <Label>Candidate Name</Label>
                        <Input
                          type="name"
                          name={"candidateName"}
                          placeholder="John Smith"
                          defaultValue={
                            referenceForm
                              ? referenceForm?.candidateName
                              : candidateName
                          }
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                      </Col>
                      <Col sm={12} md={4}>
                        <Label>Employed at</Label>
                        <Input
                          type="name"
                          name="employedAt"
                          invalid={errors.employedAt}
                          defaultValue={
                            referenceForm && referenceForm?.employedAt
                          }
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            maxLength: {
                              value: 100,
                              message:
                                "This field only allowed only 100 characters",
                            },
                            pattern: {
                              value: "/^[a-zA-Z0-9/()]+$/",
                              message:
                                "This field only allows letters and numbers.",
                            },
                          })}
                        />
                        {errors.employedAt && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.employedAt?.message}
                            </li>
                          </ul>
                        )}
                      </Col>
                      <Col sm={12} md={4}>
                        <Label>Job Title</Label>
                        <Input
                          type="name"
                          name="jobTittle"
                          placeholder="A&E Nurse"
                          invalid={errors.jobTittle}
                          defaultValue={referenceForm && referenceForm?.jobTittle}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            maxLength: {
                              value: 100,
                              message:
                                "This field only allowed only 100 characters",
                            },
                            pattern: {
                              value: "/^[a-zA-Z0-9/()]+$/",
                              message:
                                "This field only allows letters and numbers.",
                            },
                          })}
                        />
                        {errors.jobTittle && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.jobTittle?.message}
                            </li>
                          </ul>
                        )}
                      </Col>
                    </FormGroup>
                    <FormGroup row className="m-3">
                      <Col sm={12} md={6}>
                        <Label>Date of Employment</Label>
                        <Input
                          type="date"
                          name="dateOfEmployment"
                          invalid={errors.dateOfEmployment}
                          // placeholder="Date of employment"
                          onChange={(e) => setDateOfEmployement(e.target.value)}
                          defaultValue={
                            referenceForm &&
                            moment(referenceForm?.dateOfEmployment).format(
                              "YYYY-MM-DD"
                            )
                          }
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                        {errors.dateOfEmployment && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.dateOfEmployment?.message}
                            </li>
                          </ul>
                        )}
                      </Col>
                      <Col sm={12} md={6}>
                        <Label>Date of Termination</Label>
                        <Input
                          type="date"
                          name="dateOfTermination"
                          onChange={(e) => setTerminationDate(e.target.value)}
                          invalid={
                            errors.dateOfTermination || employeementDateError
                          }
                          // placeholder="Date of employment"
                          defaultValue={
                            referenceForm &&
                            moment(referenceForm?.dateOfTermination).format(
                              "YYYY-MM-DD"
                            )
                          }
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                        {(errors.dateOfTermination || employeementDateError) && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.dateOfTermination?.message}
                            </li>
                          </ul>
                        )}
                      </Col>
                    </FormGroup>
                    <FormGroup row className="m-3">
                      <Col sm={12} md={12}>
                        <Label>Reason for Leaving</Label>
                        <Input
                          type="name"
                          name="reasonForLeaving"
                          invalid={errors.reasonForLeaving}
                          placeholder="Reason For Leaving"
                          defaultValue={
                            referenceForm && referenceForm?.reasonForLeaving
                          }
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            maxLength: {
                              value: 600,
                              message:
                                "This field only allowed only 600 characters",
                            },
                            pattern: {
                              value: "/^[a-zA-Z0-9/()]+$/",
                              message:
                                "This field only allows letters and numbers.",
                            },
                          })}
                        />
                        {errors.reasonForLeaving && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.reasonForLeaving?.message}
                            </li>
                          </ul>
                        )}
                      </Col>
                    </FormGroup>
                    <hr />
                    <div className="col-md-12 d-flex justify-content-between align-items-center mb-3">
                      <h5>Candidate Assessment </h5>
                    </div>

                    {questions?.map((question, id) => {
                      return (
                        <FormGroup row key={id} className="m-3">
                          <Col sm={12} md={12} lg={4}>
                            <Label>
                              {id + 1}. {question?.question}
                            </Label>
                            {errors[question.questionNo] && (
                              <ul
                                className="list-unstyled text-sm mt-1 text-muted filled"
                                id="parsley-id-119"
                              >
                                <li className="parsley-required text-danger">
                                  {errors[question.questionNo].message}
                                </li>
                              </ul>
                            )}
                          </Col>
                          <Col sm={12} md={12} lg={2}>
                            <FormGroup check>
                              <Label>
                                <Input
                                  type="radio"
                                  className="pointer"
                                  name={question?.questionNo}
                                  value={4}
                                  checked={
                                    referenceForm &&
                                    checkBoxStatus(
                                      question?.questionNo,
                                      "VERY_GOOD"
                                    )
                                  }
                                  innerRef={register({
                                    required: {
                                      value: true,
                                      message: "This field is required!",
                                    },
                                  })}
                                />
                                Excellent
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col
                            sm={12}
                            md={12}
                            lg={1}
                          // style={{ marginLeft: "-10px", marginRight: "5px" }}
                          >
                            <FormGroup check>
                              <Label>
                                <Input
                                  type="radio"
                                  className="pointer"
                                  name={question?.questionNo}
                                  value={3}
                                  checked={
                                    referenceForm &&
                                    checkBoxStatus(question?.questionNo, "GOOD")
                                  }
                                  innerRef={register({
                                    required: {
                                      value: true,
                                      message: "This field is required!",
                                    },
                                  })}
                                />
                                Good
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col sm={12} md={12} lg={2}>
                            <FormGroup check>
                              <Label>
                                <Input
                                  type="radio"
                                  className="pointer"
                                  name={question?.questionNo}
                                  value={2}
                                  checked={
                                    referenceForm &&
                                    checkBoxStatus(
                                      question?.questionNo,
                                      "SATISFACTORY"
                                    )
                                  }
                                  innerRef={register({
                                    required: {
                                      value: true,
                                      message: "This field is required!",
                                    },
                                  })}
                                />
                                Satisfactory
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col sm={12} md={12} lg={1}>
                            <FormGroup check>
                              <Label>
                                <Input
                                  type="radio"
                                  className="pointer"
                                  name={question?.questionNo}
                                  value={1}
                                  checked={
                                    referenceForm &&
                                    checkBoxStatus(question?.questionNo, "POOR")
                                  }
                                  innerRef={register({
                                    required: {
                                      value: true,
                                      message: "This field is required!",
                                    },
                                  })}
                                />
                                Poor
                              </Label>
                            </FormGroup>
                          </Col>

                          <Col sm={12} md={12} lg={2}>
                            <FormGroup check>
                              <Label>
                                <Input
                                  type="radio"
                                  className="pointer"
                                  name={question?.questionNo}
                                  value={0}
                                  checked={
                                    referenceForm &&
                                    checkBoxStatus(
                                      question?.questionNo,
                                      "VERY_POOR"
                                    )
                                  }
                                  innerRef={register({
                                    required: {
                                      value: true,
                                      message: "This field is required!",
                                    },
                                  })}
                                />
                                Unsatisfactory
                              </Label>
                            </FormGroup>
                          </Col>
                        </FormGroup>
                      );
                    })}
                    <hr />
                    <div className=" col-md-12 d-flex justify-content-between align-items-center mb-3">
                      <h5>How Would You Rate This Candidate Overall?</h5>
                    </div>
                    <FormGroup row className="m-3">
                      <Col sm={12} md={12} lg={4}>
                        <Label> Rating</Label>
                        {errors.rating && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.rating?.message}
                            </li>
                          </ul>
                        )}
                      </Col>
                      <Col sm={12} md={12} lg={2}>
                        <FormGroup check>
                          <Label>
                            <Input
                              type="radio"
                              className="pointer"
                              name="rating"
                              value={"VERY_GOOD"}
                              invalid={errors.rating}
                              checked={
                                referenceForm &&
                                referenceForm?.rating == "VERY_GOOD"
                              }
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                            Excellent
                          </Label>
                        </FormGroup>
                      </Col>

                      <Col
                        sm={12}
                        md={12}
                        lg={1}
                      // style={{ marginLeft: "-10px", marginRight: "5px" }}
                      >
                        <FormGroup check>
                          <Label>
                            <Input
                              type="radio"
                              className="pointer"
                              name="rating"
                              value={"GOOD"}
                              invalid={errors.rating}
                              checked={
                                referenceForm && referenceForm?.rating == "GOOD"
                              }
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                            Good
                          </Label>
                        </FormGroup>
                      </Col>

                      <Col sm={12} md={12} lg={2}>
                        <FormGroup check>
                          <Label>
                            <Input
                              type="radio"
                              className="pointer"
                              name="rating"
                              value={"SATISFACTORY"}
                              invalid={errors.rating}
                              checked={
                                referenceForm &&
                                referenceForm?.rating == "SATISFACTORY"
                              }
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                            Satisfactory
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col sm={12} md={12} lg={1}>
                        <FormGroup check>
                          <Label>
                            <Input
                              type="radio"
                              className="pointer"
                              name="rating"
                              value={"POOR"}
                              invalid={errors.rating}
                              checked={
                                referenceForm && referenceForm?.rating == "POOR"
                              }
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                            Poor
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col sm={2} md={2}>
                        <FormGroup check>
                          <Label>
                            <Input
                              type="radio"
                              className="pointer"
                              name={"rating"}
                              value={"VERY_POOR"}
                              invalid={errors.rating}
                              checked={
                                referenceForm &&
                                referenceForm?.rating == "VERY_POOR"
                              }
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                            Unsatisfactory
                          </Label>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="m-3">
                      <Col sm={12} md={12} lg={8}>
                        <Label>Would you re-employ this candidate?</Label>
                        {errors.reEmployCandidate && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.reEmployCandidate?.message}
                            </li>
                          </ul>
                        )}
                      </Col>
                      <Col sm={2} md={2}>
                        <FormGroup check>
                          <Label>
                            <Input
                              type="radio"
                              className="pointer"
                              name={"reEmployCandidate"}
                              value={"true"}
                              invalid={errors.reEmployCandidate}
                              checked={
                                referenceForm && referenceForm?.reEmployCandidate
                              }
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                            Yes
                          </Label>
                        </FormGroup>
                      </Col>

                      <Col sm={12} md={12} lg={2}>
                        <FormGroup check>
                          <Label>
                            <Input
                              type="radio"
                              className="pointer"
                              name={"reEmployCandidate"}
                              invalid={errors.reEmployCandidate}
                              value={"false"}
                              checked={
                                referenceForm && !referenceForm?.reEmployCandidate
                              }
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                            No
                          </Label>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                    <hr />
                    <Col sm={12} md={12} lg={12}>
                      <Alert className="text-justify">
                        In order to protect the public, the post for which the
                        application is being made is exempt from Section 4 (2) of
                        the Rehabilitation of Offenders Act 1974 by virtue of the
                        Rehabilitation of Offenders Act 1974 (Exceptions) Order
                        1975. It is not therefore in any way contrary to the Act
                        to reveal any information you may have concerning
                        convictions which would otherwise be considered as 'spent'
                        in relation to this application and which you consider
                        relevant to the applicant's suitability for employment.
                        Any such information will be kept in strictest confidence
                        and used only in consideration of the suitability of this
                        applicant for a position where such an exemption is
                        appropriate.
                      </Alert>
                    </Col>

                    <hr />

                    <div className="col-md-12 d-flex justify-content-between align-items-center">
                      <h5>Your Signature </h5>
                    </div>
                    <FormGroup row className="m-3">
                      <Col sm={12} md={12} lg={6}>
                        <Label>Referee Name</Label>
                        <Input
                          type="name"
                          name="referenceName"
                          placeholder="Referee's Name"
                          invalid={errors.telephoneNo}
                          defaultValue={
                            referenceForm && referenceForm?.referenceName
                          }
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            maxLength: {
                              value: 100,
                              message:
                                "This field only allowed only 100 characters",
                            },
                            pattern: {
                              value: "/^[a-zA-Z0-9/()]+$/",
                              message:
                                "This field only allows letters and numbers.",
                            },
                          })}
                        />
                        {errors.referenceName && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.referenceName?.message}
                            </li>
                          </ul>
                        )}
                      </Col>
                      <Col sm={12} md={12} lg={6}>
                        <Label>Telephone Number </Label>
                        <Input
                          type="text"
                          name="telephoneNo"
                          placeholder="Telephone Number"
                          invalid={errors.telephoneNo}
                          defaultValue={
                            referenceForm && referenceForm?.telephoneNo
                          }
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            maxLength: {
                              value: 20,
                              message:
                                "This field only allowed only 20 characters",
                            },

                          })}
                        />
                        {errors.telephoneNo && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.telephoneNo?.message}
                            </li>
                            <li className="parsley-required text-danger">
                              +44 xxxx xxxx xxx
                            </li>
                            <li className="parsley-required text-danger">
                              0xxxx xxxx xxx
                            </li>
                          </ul>
                        )}
                      </Col>
                    </FormGroup>
                    <FormGroup row className="m-3">
                      <Col sm={12} md={12} lg={12}>
                        <Label>Referee Email </Label>

                        <Input
                          type="Email"
                          name={"refereeEmail"}
                          placeholder="Refree Email"
                          defaultValue={
                            referenceForm && referenceForm?.refereeEmail
                          }
                          invalid={errors.refereeEmail}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            maxLength: {
                              value: 50,
                              message:
                                "This field only allowed only 50 characters",
                            },
                            pattern: {
                              value:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Please enter a valid email address!",
                            },
                          })}
                        />
                        {errors.refereeEmail && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.refereeEmail?.message}
                            </li>
                          </ul>
                        )}
                      </Col>
                    </FormGroup>
                    <FormGroup row className="m-3">
                      <Col sm={12} md={4} lg={4}>
                        <Label>Is this a work email Address ?</Label>
                      </Col>

                      <Col sm={12} md={4} lg={4}>
                        <FormGroup check>
                          <Label>
                            <Input
                              type="radio"
                              className="pointer"
                              name={"IsWorkEmail"}
                              onChange={() => setIsWorkEmail(true)}
                              value={true}
                              invalid={errors.IsWorkEmail}
                              checked={
                                referenceForm && referenceForm?.IsWorkEmail
                              }
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                            Yes
                          </Label>
                        </FormGroup>
                      </Col>

                      <Col sm={12} md={4} lg={4}>
                        <FormGroup check>
                          <Label>
                            <Input
                              type="radio"
                              className="pointer"
                              name={"IsWorkEmail"}
                              onChange={() => setIsWorkEmail(false)}
                              invalid={errors.IsWorkEmail}
                              value={false}
                              checked={
                                referenceForm && !referenceForm?.IsWorkEmail
                              }
                              innerRef={register({
                                required: {
                                  value: true,
                                  message: "This field is required!",
                                },
                              })}
                            />
                            No
                          </Label>
                        </FormGroup>
                        {errors.IsWorkEmail && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.IsWorkEmail?.message}
                            </li>
                          </ul>
                        )}
                      </Col>
                    </FormGroup>

                    <FormGroup row className="m-3">
                      <Col sm={12} md={12} lg={8}>
                        <Label>Address</Label>
                        <Input
                          type="text"
                          name="address"
                          placeholder="Refree Address"
                          defaultValue={referenceForm && referenceForm?.address}
                          invalid={errors.address}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            maxLength: {
                              value: 200,
                              message:
                                "This field only allowed only 200 characters",
                            },

                          })}
                        />
                        {errors.address && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.address?.message}
                            </li>
                          </ul>
                        )}
                      </Col>
                      <Col sm={12} md={12} lg={4}>
                        <Label>Post Code</Label>
                        <Input
                          type="text"
                          name="postCode"
                          placeholder="Postal code"
                          defaultValue={referenceForm && referenceForm?.postCode}
                          invalid={errors.postCode}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            maxLength: {
                              value: 30,
                              message:
                                "This field only allowed only 30 characters",
                            },

                          })}
                        />

                        {errors.postCode && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.postCode?.message}
                            </li>
                          </ul>
                        )}
                      </Col>
                    </FormGroup>
                    <FormGroup row className="m-3">

                      <Col sm={12} md={12} lg={6}>
                        <Label for="band">
                          Band <span className="text-danger">*</span>
                        </Label>
                        <Input
                          // disabled={mode === "POST" ? false : true}
                          type="select"
                          id="band"
                          name="band"
                          // defaultValue={referenceForm ? referenceForm?.band : -1}
                          value={selectedBand}
                          invalid={errors.band || selectedBand === -1}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                          onChange={(e) => {
                            setSelectedBand(e.target.value);
                          }}
                        >
                          <option value={-1}>Select</option>
                          {bands &&
                            bands.map((band, id) => {
                              return (
                                <option
                                  value={band}
                                  key={id}
                                  style={{ color: "#6A6767" }}
                                >
                                  {band}
                                </option>
                              );
                            })}
                        </Input>
                        {(errors.band || selectedBand === -1) && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.band?.message}
                            </li>
                          </ul>
                        )}
                      </Col>

                      <Col sm={12} md={12} lg={6}>
                        {/* <Label>Reason</Label>
                          <Input
                            type="textarea"
                            name="rejectReason"
                            placeholder="Reason"
                            invalid={errors.rejectReason}
                            defaultValue={
                              referenceForm && referenceForm?.rejectReason
                            }
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },

                              // pattern: {
                              //   value:
                              //     /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                              //   message: "Please enter a valid phone number",
                              // },
                            })}
                          /> */}

                      </Col>


                    </FormGroup>
                    {mode == "POST" && (
                      <div className="card-header">
                        <div className="col-md-12  ">
                          <Alert className="col-md-12 d-flex justify-content-center align-items-center ">
                            <div>
                              <b>
                                <h4>Signature:</h4>
                              </b>
                              <small className="mb-3">
                                By signing this reference form, I declare that all
                                the information provide is accurate and factual to
                                my knowledge and that this information may be
                                shared with employers.
                              </small>
                            </div>
                            <div className="round mb-1 mx-4 mt-3">
                              <input
                                type="checkbox"
                                id="signature"
                                checked={signed}
                                onChange={() => setSigned(!signed)}
                              />
                              <label for="signature"></label>
                            </div>
                          </Alert>
                        </div>
                      </div>
                    )}
                  </div>  <Alert className="d-flex align-items-justify justify-content-justify flex-column" color="danger">
                    <p>Reason for rejection : {referenceForm && referenceForm?.rejectReason}</p>

                  </Alert>
                  {mode == "POST" && !IsWorkEmail && (
                    <div className="card-header">
                      <div className="col-md-12  ">
                        <Alert className="col-md-12 d-flex justify-content-start align-items-center ">
                          <div>
                            <b>
                              <h4>Note:</h4>
                            </b>
                            <small className="mb-3">
                              Please be kind enough to download the reference form
                              from here, fill it out, stamp it from your hospital,
                              and send it to info@candyhealthcare.co.uk.
                            </small>
                            <br />
                            <br />
                            <small>Stamp:</small>
                            <div
                              style={{
                                height: "100px",
                                width: "100%",
                                border: "1px solid",
                                padding: "5px",
                              }}
                            ></div>
                          </div>
                        </Alert>
                      </div>
                    </div>
                  )}

                  <div>
                    {(Object.keys(errors).length !== 0 ||
                      selectedBand === -1) && (
                        <div className="card-header">
                          <div className="col-md-12  ">
                            <Alert
                              color="danger"
                              className="col-md-12 d-flex justify-content-start align-items-center   "
                            >
                              <div>
                                <b>
                                  <h4 className="text-danger">Error:</h4>
                                </b>
                                <li className="mb-3">
                                  One of more field(s) are not valid.
                                </li>
                                {employeementDateError ? (
                                  <li>
                                    Please check the employment start and
                                    termination dates.
                                  </li>
                                ) : null}
                              </div>
                            </Alert>
                          </div>

                        </div>
                      )}


                  </div>


                </>






              </div>     </div>)}
          <ModalComponent
            show={showResubmitForm}
            header={`Resubmit Form`}
            className={`modal-dialog modal-custom ${showResubmitForm ? 'modal-dialog-centered' : ''}`}
            closeModal={() => {
              setShowResubmitForm(false);
            }}
          >

            <ResubmitForm
              rowID={referenceForm?.id}
              mode="Resubmit"
              reset={reset}
              // auth={auth?.item.id}
              // rowvalue={rowvalue}
              // rowNoteID={rowNoteID}
              // action={getNotes}
              // success={() => {
              //   // setReset(!reset);
              //   setShowUpdateRow(false);
              // }}
              closeModal1={() => {
                setShowResubmitForm(false);
              }}
            />


          </ModalComponent>


        </div></div>

    </>
  );
}

export default ReferenceFormTemplateHistory;
