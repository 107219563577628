import { useState } from "react";
import { Col, Modal, ModalHeader, Row, ModalBody, Label } from "reactstrap";
import { Spinner, Button } from "reactstrap";
import noData from "../../images/noData.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const { Document, Page, pdfjs } = require("react-pdf"); //eslint-disable-line
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; //eslint-disable-line

const PdfViewerModal = ({
  actionButton = false,
  actionButtonName,
  downloadPDF,
  onClickAction,
  isOpen,
  setOpen,
  path,
  title,
  error = false,
  type ,
}) => {
  const onClose = () => {
    setOpen(!isOpen);
  };

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [renderedPageNumber, setRenderedPageNumber] = useState(0);

  const onDocumentLoadSuccess = (pdf) => {
    setNumPages(pdf.numPages);
    setLoading(false);
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };
  const previousPage = () => {
    changePage(-1);
  };
  const nextPage = () => {
    changePage(+1);
  };

  const isLoading = renderedPageNumber !== pageNumber;

  return (
    <Modal
      keyboard={false}
      isOpen={isOpen}
      toggle={onClose}
      role="dialog"
      autoFocus
      centered
      className=""
      tabIndex={-1}
      size="lg"
      style={{ width: 700, maxHeight: 800 }}
    >
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
        {loading && !error && (
          <div>
            Please wait while we load your pdf ...
            <Spinner
              color={"info"}
              message="Loading Document"
              loading={loading}
            />
          </div>
        )}
        {error && (
          <div className="d-flex flex-column text-center justify-content-center ">
            <Label>Trust Id online check is pending </Label>
            <img src={noData} alt="." style={{ height: "auto" }} />
            <small>
              It seems trust Id check is still pending.Please try again later
            </small>
          </div>
        )}
        <div>
          {!loading && (
            <div
              className="d-flex align-items-center align-text-center justify-content-center"
              style={{
                marginTop: 10,
                height: 30,
              }}
            >
              <Button
                id=""
                icon="fa fa-chevron-left"
                onClick={previousPage}
                disabled={pageNumber <= 1}
                style={{ height: "1.2em" }}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>

              <Label className="m-2">
                {pageNumber} / {numPages}
              </Label>

              <Button
                id=""
                icon="fa fa-chevron-right"
                onClick={nextPage}
                disabled={pageNumber === numPages}
                style={{ height: "1.2em" }}
              >
                {" "}
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            </div>
          )}{" "}
          {/*eslint-disable-line*/}
          {!loading && (
            <hr
              style={{
                background: "black",
                color: "black",
                height: "3px",
                width: "80%",
              }}
            />
          )}{" "}
          {/*eslint-disable-line*/}
          <Document
            file={path}
            onLoadSuccess={onDocumentLoadSuccess}
            loading=""
            error=""
          >
            {isLoading && renderedPageNumber ? ( //eslint-disable-line
              <Page
                renderTextLayer={false}
                renderAnnotationLayer={false}
                key={renderedPageNumber}
                className="prevPage"
                pageNumber={renderedPageNumber}
                loading={null}
              />
            ) : null}
          
            <Page
              renderTextLayer={false}
              renderAnnotationLayer={false}
              key={pageNumber}
              pageNumber={pageNumber}
              onRenderSuccess={() => setRenderedPageNumber(pageNumber)}
              loading={null}
            />
          </Document>
        </div>

        <Row>
          <Col md={12} className="d-flex justify-content-end mr-4 my-2 ">
            {actionButton && !error && (
              <Button className="mx-2" color="success" onClick={onClickAction}>
                {actionButtonName}
              </Button>
            )}
            <Button color="success" onClick={downloadPDF}>
              Download
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default PdfViewerModal;
