import React, { useState } from "react";

import {
  faExclamationTriangle,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";
import { HOSTMexxar, HostMexxarURL } from "../../../configs/api-config";
import { Link } from "react-router-dom";

export default function ForgotPasswordResetPassword(props) {
  const { register, handleSubmit, errors } = useForm();
  const { onCreate, closeModal, mode, id, type, candidateEmail, candidateId } =
    props;

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successStatus, setSuccessStatus] = useState(false);

  const [success, setSuccess] = useState(false);

  const resetAccountPassword = async () => {
    try {
      await axios
        .post(
          // "http://104.238.170.244:8090/forgot-password/forgot-password-reset-password?userId=" +
          //   id +
          //   "&newPassword=" +
          //   newPassword +
          //   "&confirmPassword=" +
          //   confirmPassword
          "https://permapi.mexxar.com/forgot-password/forgot-password-reset-password?userId=" +
          id +
          "&newPassword=" +
          newPassword +
          "&confirmPassword=" +
          confirmPassword
        )
        .then((res) => {
          if (res.data.status == "success") {
            setSuccess(true);
          } else {
            setSuccessStatus(false);
          }
        });
    } catch (e) {
      setErrorMessage(e.message);
    }
  };

  const onSubmit = (data) => {
    //  onCreate(data);
    if (confirmPassword == newPassword) {
      setPasswordMatch(false);
      resetAccountPassword();
    } else {
      setPasswordMatch(true);
    }
  };
  return (
    <div className="content-container">
      {/* //       <div className="container-fluid">
        //             <Row></Row>
        //         <div className="side-left" >
        //              </div>
        //      <div className="side-right "> */}
      {/* <div className="logo-container p-4">
               <img className="logo-center" src={logo} alt="LOGO"></img>
                    </div> */}

      {!success ? (
        <>
          <div className="">
            <h4>Reset your password</h4>
          </div>
          <br></br>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup row>
              <Label sm={4}>New Password</Label>
              <Col sm={7}>
                <Input
                  onChange={(e) => setNewPassword(e.target.value)}
                  type={showPassword ? "text" : "password"}
                  name="newPassword"
                  invalid={errors.bName}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                    maxLength: {
                      value: 50,
                      message: "This field only allowed only 50 characters",
                    },
                    pattern: {
                      value:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                      message:
                        "Password must contain at least one Capital, Lower, Number, Special Character and 8 characters",
                    },
                  })}
                ></Input>
                {errors.bName && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      {errors.bName?.message}
                    </li>
                  </ul>
                )}{" "}
              </Col>

              <Col
                sm={1}
                className="d-flex align-items-center  justify-content-start"
              >
                {showPassword ? (
                  <FontAwesomeIcon
                    icon={faEye}
                    className=" btn-raised btn-wave"
                    onClick={() => setShowPassword(false)}
                    style={{
                      boxShadow: "none",
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    className=" btn-raised btn-wave"
                    onClick={() => setShowPassword(true)}
                    style={{
                      boxShadow: "none",
                    }}
                  />
                )}
              </Col>
            </FormGroup>

            <FormGroup row>
              <Label sm={4}>Confirm Password</Label>
              <Col sm={7}>
                <Input
                  type={showPassword ? "text" : "password"}
                  name="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  invalid={errors.confirmPassword || passwordMatch}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                    maxLength: {
                      value: 50,
                      message: "This field only allowed only 50 characters",
                    },
                    pattern: {
                      value:
                        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                      message:
                        "Password must contain at least one Capital, Lower, Number, Special Character and 8 characters",
                    },
                  })}
                ></Input>
                {errors.confirmPassword && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      {errors.confirmPassword?.message}
                    </li>
                  </ul>
                )}
                {passwordMatch && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        color="warning"
                      />{" "}
                      Password does not match
                    </li>
                  </ul>
                )}
              </Col>

              <Col
                sm={1}
                className="d-flex align-items-center  justify-content-start"
              >
                {showPassword ? (
                  <FontAwesomeIcon
                    icon={faEye}
                    className=" btn-raised btn-wave"
                    onClick={() => setShowPassword(false)}
                    style={{
                      boxShadow: "none",
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    className=" btn-raised btn-wave"
                    onClick={() => setShowPassword(true)}
                    style={{
                      boxShadow: "none",
                    }}
                  />
                )}
              </Col>
            </FormGroup>

            <Row className="justify-content-end">
              <div className="d-flex ">
                {" "}
                <Button
                  className="mr-2"
                  color="success"
                  type="submit"
                  //disabled={passwordMatch}
                >
                  {mode}
                  Submit
                </Button>{" "}
              </div>
            </Row>
          </Form>
        </>
      ) : (
        <div className="align center">
          <h4>Password Reset Successful</h4>
          <Row className="justify-content-end">
            <div className="d-flex pt-4 ">
              {" "}
              <Link to="/">
                <Button className="mr-2" color="success" type="submit">
                  Continue
                </Button>{" "}
              </Link>
            </div>
          </Row>
        </div>
      )}
    </div>
    //     </div>
    //  </div>
  );
}
