import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { DateAndTime } from "../ListofMails";
import EmailForm from "../emailForm/EmailForm";
import "./emailBody.css"
import DownloadAttachment from "../emailMessageContent/emailAttachments";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReply,
  faTrash,
  faChevronLeft,
  faShare,
} from "@fortawesome/free-solid-svg-icons";
import store from "../../../../redux/store";
import { connect } from "react-redux";
import axios from "axios";
import { Button, UncontrolledTooltip } from "reactstrap";
import { MoveToTrash } from "../All_Mail_Request/MailboxRequest";
import { HostMexxarEmail } from "../../../../configs/api-config";
import DOMPurify from "dompurify";

function BodySection(props) {
  const {
    // imageLink,
    // dateAndTime,
    // subject,
    attachments,
    // from,
    // cc,
    // bcc,
    // type,
    children,
    // post,

    emailServerReducer,
  } = props;
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [showForwardModal, setShowForwardModal] = useState(false);

  const toggleReplyModal = () => {
    setShowReplyModal(!showReplyModal);
  };
  const toggleForwardModal = () => {
    setShowForwardModal(!showForwardModal);
  };

  if (emailServerReducer.otherValues) {
    var imageLink = emailServerReducer.otherValues[0];
    var from = emailServerReducer.otherValues[1];
    var dateAndTime = emailServerReducer.otherValues[2];
    var subjectIncomming = emailServerReducer.otherValues[3];
    var cc = emailServerReducer.otherValues[4];
    var bcc = emailServerReducer.otherValues[5];
    var type = emailServerReducer.otherValues[6];
  } else {
    var element = JSON.parse(localStorage.getItem("element"));
    var imageLink = element[0];
    var from = element[1];
    var dateAndTime = element[2];
    var subjectIncomming = element[3];
    var cc = element[4];
    var bcc = element[5];
    var type = element[6];
  }

  const [emailBody, setEmailBody] = useState(null);

  const ReadMail = (creadentials, timestamp) => {
    creadentials.timestamp = timestamp;

    axios
      .post(HostMexxarEmail + "email/read", creadentials)
      .then(function (response) {
        setEmailBody(response.data.body);
        //store.dispatch(emailBodyPosts(response.data.body));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const [id, setId] = useState(0);
  useEffect(() => {
    var pathArray = window.location.pathname.split("/");

    var timestamp = pathArray[5];
    let id = pathArray[6];

    setId(id);
    ReadMail(emailServerReducer.credentials, timestamp);
  }, []);

  const sanitizedData = () => ({
    __html: DOMPurify.sanitize(emailBody.message),
  });

  return (
    <div className="d-flex flex" id="content-body">
      {showReplyModal ? (
        <EmailForm
          composeType={"REPLY"}
          emailBody={emailBody}
          showComposeModal={toggleReplyModal}
          imageLink={imageLink}
          from={from}
          dateAndTime={dateAndTime}
          subjectIncomming={subjectIncomming}
          cc={cc}
          bcc={bcc}
          type={type}
          credentials={emailServerReducer.credentials}
        />
      ) : null}
      {showForwardModal ? (
        <EmailForm
          composeType={"FORWARD"}
          emailBody={emailBody}
          showComposeModal={toggleForwardModal}
          imageLink={imageLink}
          from={from}
          dateAndTime={dateAndTime}
          subjectIncomming={subjectIncomming}
          cc={cc}
          bcc={bcc}
          type={type}
          credentials={emailServerReducer.credentials}
        />
      ) : null}
      <div className="d-flex flex-column flex">
        <div className="navbar">
          <div className="d-none d-md-flex align-items-center">
            <Link to={"/dashboard/email/" + "inbox"}>
              <FontAwesomeIcon
                id="goBack"
                icon={faChevronLeft}
                className="mx-3"
              />
              Go Back
              <UncontrolledTooltip target="goBack">Go back</UncontrolledTooltip>
            </Link>

            {/* <span className="avatar gd-warning w-32">{imageLink}</span>
            <span className="mx-2">
              {type === "SENT" ? (
                <span>
                  <span className="text-muted">To </span>
                  <span style={{ color: "red" }}>{from}</span>
                  {cc !== "" && (
                    <span>
                      <span className="text-muted">, CC </span>
                      <span style={{ color: "blue" }}>{cc} </span>
                    </span>
                  )}
                  {bcc !== "" && (
                    <span>
                      <span className="text-muted">, BCC </span>
                      <span style={{ color: "blue" }}>{bcc} </span>
                    </span>
                  )}
                </span>
              ) : (
                <span>
                  <span className="text-muted">From </span>
                  <span style={{ color: "red" }}>{from}</span>
                  {cc !== "" && (
                    <span>
                      <span className="text-muted">, CC </span>
                      <span style={{ color: "blue" }}>{cc}</span>
                    </span>
                  )}
                </span>
              )}

              <span className="text-xs text-muted">
                on {DateAndTime(dateAndTime)}
              </span>
            </span> */}
          </div>
          <a
            href="app.mail.html"
            className="d-md-none btn-go-back btn btn-sm bg-white"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </a>
          <span className="flex"></span>
          <ul className="nav no-border">
            <li className="nav-item">
              <Link
                to="#"
                className="nav-link px-2"
                id="btn-reply-mail"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Reply"
              >
                <FontAwesomeIcon
                  onClick={() => {
                    !showReplyModal && toggleReplyModal();
                  }}
                  icon={faReply}
                />
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link px-2"
                data-toggle="tooltip"
                data-placement="bottom"
                title="forward"
              >
                <FontAwesomeIcon
                  icon={faShare}
                  onClick={() => {
                    !showForwardModal && toggleForwardModal();
                  }}
                />
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link px-2"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Delete"
              >
                {/* <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => {
                    MoveToTrash(
                      emailServerReducer.credentials,
                      [id],
                      "INBOX",
                      setMail,
                      1
                    );
                  }}
                /> */}
              </a>
            </li>
          </ul>
        </div>
        <div className="p-5 m-5 card">
          <div className="">
            <div className="text-sm  p-5 m-5 ">
              <h3 className=" text-highlight mb-3">{subjectIncomming}</h3>
              <div className="d-flex mb-2">
                <span
                  className="avatar  gd-warning w-40"
                  style={{ marginLeft: "-40px" }}
                >
                  {imageLink}
                </span>
                <span className="mx-2">
                  <div className="d-flex flex-column">
                    <div>
                      {type === "SENT" ? (
                        <span>
                          <span className="text-muted">To </span>
                          <span style={{ color: "orange" }}>{from}</span>
                          {cc !== "" && (
                            <span>
                              <span className="text-muted">, CC </span>
                              <span style={{ color: "blue" }}>{cc} </span>
                            </span>
                          )}
                          {bcc !== "" && (
                            <span>
                              <span className="text-muted">, BCC </span>
                              <span style={{ color: "blue" }}>{bcc} </span>
                            </span>
                          )}
                        </span>
                      ) : (
                        <span>
                          <span className="text-muted">From </span>
                          <span style={{ color: "orange" }}>{from}</span>
                          {cc !== "" && (
                            <span>
                              <span className="text-muted">, CC </span>
                              <span style={{ color: "blue" }}>{cc}</span>
                            </span>
                          )}
                        </span>
                      )}
                    </div>
                    <div>
                      <span className="text-muted">
                        on {DateAndTime(dateAndTime)}
                      </span>
                    </div>
                  </div>
                </span>
              </div>
              {emailBody && (
                <div
                  className="emailBodyStyles text-color"
                  dangerouslySetInnerHTML={sanitizedData()}
                />
              )}
              {emailBody && (
                <DownloadAttachment
                  attachments={emailBody.attachment}
                  body={emailServerReducer.credentials}
                  mailType={type}
                  timestamp={dateAndTime}
                ></DownloadAttachment>
              )}
              <div className="mt-5">
                <Button
                  className="mr-2"
                  onClick={() => {
                    !showReplyModal && toggleReplyModal();
                  }}
                >
                  Reply
                </Button>
                <Button
                  className="m-2"
                  onClick={() => {
                    !showForwardModal && toggleForwardModal();
                  }}
                >
                  Forward
                </Button>
              </div>
            </div>
          </div>
          {/* {attachments} */}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    emailServerReducer: state.emailServerReducer,
  };
}

export default connect(mapStateToProps)(BodySection);
