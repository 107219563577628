import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { connect } from "react-redux";
import { uniq, findIndex } from "lodash";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCandidateList from "../../../customHooks/useCandidateList";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { HOSTMexxar, REGISTER_CANDIDATE } from "../../../configs/api-config";
import { Button, Label, Input, Form, Col, FormGroup, Alert } from "reactstrap";
import { sendNotification } from "../../../util/common/sendNotification";

function Event(props) {
  const MexxarApi = HOSTMexxar;
  const MexxarCandidateApi = REGISTER_CANDIDATE;

  const { onCreate, closeModal, start, end, auth, candidateWhenClick,candidateId, event } = props;

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();

  let loggedInEmployee = {
    value: auth.item.firstName + " " + auth.item.lastName,
    label: auth.item.firstName + " " + auth.item.lastName,
    id: auth.item.userTypeId,
  };

  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  let url = MexxarCandidateApi + "/status/" + filterCustom.value;

  const [query, setQuery] = useState("");
  const [date, setDate] = useState(false);
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [employees, setEmployees] = useState(null);
  const [candidates, setCandidates] = useState([]);
  const [endDateFlag, setEndDateFlag] = useState(null);
  const [startDateFlag, setStartDateFlag] = useState(null);
  const [checkBoxError, setCheckBoxError] = useState(false);
  const [dataErrorStatus, setDataErrorStatus] = useState(false);
  const [ownCheckBoxClicked, setOwnCheckBoxClicked] = useState(true);
  const [endDataErrorStatus, setEndDataErrorStatus] = useState(false);
  const [indexOfDefaultEmployee, setIndexOfDefaultEmployee] = useState(null);
  const [employeeCheckBoxClicked, setEmployeeCheckBoxClicked] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([
    loggedInEmployee,
  ]);
  const [candidateCheckBoxClicked, setCandidateCheckBoxClicked] =
    useState(false);
  const [selectedCandidates, setSelectedCandidates] =
    useState(candidateWhenClick);

  const { loading, error, rows, hasMore } = useCandidateList(
    url,
    filterCustom,
    query,
    pageNumber
  );

   const getCandidates = () => {
    let listOfCandidates = [];
    for (let i = 0; i < rows.length; i++) {
      listOfCandidates.push({
        value: rows[i].id,
        label: `${rows[i].firstName} ${rows[i].lastName}`,
        id: rows[i].id,
        candidate: rows[i],
      });
    }

    if (query != "") {
      setCandidates(listOfCandidates);
    } else {
      setCandidates([]);
    }
  };

  const handleInputChange = (e) => {
    setQuery(e);
  };

  const getEmployees = () => {
    axios.get(MexxarApi + "employees").then((res) => {
      let listOfEmployees = [];
      for (let i = 0; i < res.data.body.length; i++) {
        listOfEmployees.push({
          value: res.data.body[i].id,
          label: `${res.data.body[i].firstName} ${res.data.body[i].lastName}`,
          id: res.data.body[i].id,
          employee: res.data.body[i],
        });
      }
      /* {find index of default employee from emplyee list} */
      let index = findIndex(listOfEmployees, (item) => {
        return item.id == loggedInEmployee.id;
      });
      setIndexOfDefaultEmployee(index);
      setEmployees(listOfEmployees);
    });
  };

  const createEvent = (data) => {
    let employeeUsers = [];
    let candidateUsers = [];

    if (selectedCandidates != null) {
      if (candidateWhenClick) {
        candidateUsers.push(selectedCandidates.id);
      } else {
        for (let i = 0; i < selectedCandidates.length; i++) {
          candidateUsers.push(selectedCandidates[i].id);
        }
      }
    }

    if (selectedEmployees != undefined || selectedEmployees != null) {
      if (employeeCheckBoxClicked || ownCheckBoxClicked) {
        for (let i = 0; i < selectedEmployees.length; i++) {
          employeeUsers.push(selectedEmployees[i].id);
        }
      } else {
        employeeUsers = [];
      }
    }

    if (ownCheckBoxClicked) {
      employeeUsers.push(loggedInEmployee.id);
    }

    let array = {
      name: data.EventName,
      startDateTime: moment(data.StartDate).add(data.StartTime).toDate(),
      endDateTime: moment(data.EndDate).add(data.EndTime).toDate(),
      employees: employeeUsers == null ? [] : uniq(employeeUsers),
      candidates: candidateUsers == null ? [] : candidateUsers,
    };

    axios
      .post(MexxarApi + "events", array)
      .then((res) => {
        if (res.data.status == "success") {
          closeModal();
          onCreate(data);
          // EventsNotifications();
          sendNotification({
            notificationType: "EVENT",
            title: data.EventName,
            content: "New Event Created",
            candidateId: candidateUsers.length > 0 ? candidateUsers[0] : null,
            startDate: array.startDateTime, 
            addToast: addToast 
          });
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const checkDate = () => {
    let startDateTemp = "";
    if (startDateFlag != null && startDateFlag != undefined) {
      startDateTemp = startDateFlag;
    } else {
      startDateTemp = startDate;
    }

    let endDateTemp = "";
    if (endDateFlag != null && endDateFlag != undefined) {
      endDateTemp = endDateFlag;
    } else {
      endDateTemp = endDate;
    }
    setDate(moment(endDateTemp).isBefore(startDateTemp));
  };

  const onSubmit = (data) => {
    if (
      ownCheckBoxClicked ||
      employeeCheckBoxClicked ||
      candidateCheckBoxClicked
    ) {
      if (!dataErrorStatus && !endDataErrorStatus && !date) {
        createEvent(data);
      }
    } else {
      setCheckBoxError(true);
    }
  };

  const onCheckHandler = (e) => {
    switch (e.target.value) {
      case "OWN":
        setOwnCheckBoxClicked(!ownCheckBoxClicked);
        setEmployeeCheckBoxClicked(false);
        setCandidateCheckBoxClicked(false);
        break;
      case "EMPLOYEE":
        setEmployeeCheckBoxClicked(!employeeCheckBoxClicked);
        // if (e.target.checked == false) setSelectedEmployees(null);
        if (e.target.checked == false) {
          setSelectedEmployees(null);
        } else {
          setSelectedEmployees([loggedInEmployee]);
        }
        break;
      case "CANDIDATE":
        setCandidateCheckBoxClicked(!candidateCheckBoxClicked);
        if (e.target.checked == false) setSelectedCandidates(null);
        else {
          setSelectedCandidates(candidateWhenClick);
        }
        break;

      default:
        break;
    }
    /*setting the error alert off*/
    if (e.target.checked) {
      setCheckBoxError(false);
    }
  };
  console.log("selected  events",event)
  useEffect(() => {
    getCandidates();
    return () => {};
  }, [rows]);

  useEffect(() => {
    checkDate();

    return () => {};
    console.log()
  }, [startDate, endDate, startDateFlag, endDateFlag]);

  useEffect(() => {
    setStartDate(moment(start).format("YYYY-MM-DD"));
    setEndDate(moment(end).format("YYYY-MM-DD"));
    getEmployees();
    return () => {};
  }, []);

  const EventsNotifications = () => {
   
        axios
          .post(
            "https://permapi.mexxar.com/mobile/notifications/fcm/create",
            {
              notificationType: "EVENT",
              title: "Event Notification",
              content: "Event Notification",
              candidateId: candidateId,
              eventDate: startDate
            },
            {
              params: {
                topic: "mexxar-candidate-" + candidateId
              }
            }
          )
          .then((res) => {
            if (res.data.status == "success") {
           
            } else {
              ToastCommon(true, "error", addToast);
            }
          })
          .catch((err) => {
            ToastCommon(true, "error", addToast);
          });
  };

  return (
    <>
      <Form className="col-12" onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Label sm={3}>Event Name</Label>
          <Col sm={9}>
            <Input
              id="EventName"
              type="text"
              placeholder=""
              name="EventName"
              invalid={errors.EventName}
              className="text-capitalize "
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            ></Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>Start</Label>
          <Col sm={5}>
            <Input
              type="date"
              name="StartDate"
              innerRef={register}
              invalid={errors.postCode || dataErrorStatus || date}
              defaultValue={moment(start).format("YYYY-MM-DD")}
              onChange={(e) => {
                setDataErrorStatus(
                  moment(e.target.value).format("l") == moment().format("l")
                    ? false
                    : moment(e.target.value).isBefore()
                );
                setStartDateFlag(e.target.value);
              }}
            />
            {dataErrorStatus ||
              (date && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">Invalid Date</li>
                </ul>
              ))}
          </Col>
          <Col sm={4}>
            <Input
              type="time"
              name="StartTime"
              innerRef={register}
              defaultValue={moment(start).format("hh:mm")}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>End</Label>
          <Col sm={5}>
            <Input
              type="date"
              name="EndDate"
              innerRef={register}
              invalid={errors.postCode || endDataErrorStatus || date}
              defaultValue={moment(end).format("YYYY-MM-DD")}
              onChange={(e) => {
                setEndDataErrorStatus(
                  moment(e.target.value).format("l") == moment().format("l")
                    ? false
                    : moment(e.target.value).isBefore()
                );
                setEndDateFlag(e.target.value);
              }}
            />
            {endDataErrorStatus ||
              (date && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">Invalid Date</li>
                </ul>
              ))}
          </Col>
          <Col sm={4}>
            <Input
              type="time"
              name="EndTime"
              innerRef={register}
              defaultValue={moment(end).format("hh:mm")}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Tag</Label>
          <Col sm={3} className="mt-2">
            <Label>
              <Input
                type="checkbox"
                name="type-radio"
                value="OWN"
                checked={ownCheckBoxClicked}
                innerRef={register}
                onChange={(e) => onCheckHandler(e)}
              />
              Own
            </Label>
         

          </Col>
       
          <Col sm={3} className="mt-2">
            <Label>
              <Input
                type="checkbox"
                name="type-radio"
                value="EMPLOYEE"
                disabled={ownCheckBoxClicked}
                checked={employeeCheckBoxClicked}
                innerRef={register}
                onChange={(e) => onCheckHandler(e)}
              />
              Consultant
            </Label>
          </Col>
          <Col sm={3} className="mt-2">
            <Label>
              <Input
                type="checkbox"
                name="type-radio"
                value="CANDIDATE"
                disabled={ownCheckBoxClicked}
                checked={candidateCheckBoxClicked}
                innerRef={register}
                onChange={(e) => onCheckHandler(e)}
              />
              Candidate
            </Label>
          </Col>
        </FormGroup>
        {ownCheckBoxClicked && (
        <Alert >
        <div>
        Caution: Untick to select Consultant/Candidate
                            </div>
        </Alert>
        )}
        {employeeCheckBoxClicked && !ownCheckBoxClicked ? (
          <FormGroup row>
            <Col sm={3}>
              <Label>Tag Employess</Label>
            </Col>
            <Col sm={9}>
              <Select
                defaultValue={employees && employees[indexOfDefaultEmployee]}
                isMulti
                name="employeeId"
                className="basic-multi-select"
                classNamePrefix="select"
                options={employees ? employees:[]}
                onChange={(data) => setSelectedEmployees(data)}
                isLoading={loading}
                noOptionsMessage={() => "No results found"}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
          </FormGroup>
        ) : null}
        {candidateCheckBoxClicked && !ownCheckBoxClicked ? (
          <FormGroup row>
            <Col sm={3}>
              <Label>Tag Candidates</Label>
            </Col>
            <Col sm={9}>
              {candidates != null ? (
                <Select
                  isMulti
                  name="candidateId"
                  defaultValue={candidateWhenClick ? candidateWhenClick : ""}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => setSelectedCandidates(data)}
                  isLoading={loading}
                  options={candidates}
                  placeholder="Search candidates ..."
                  onInputChange={handleInputChange}
                  noOptionsMessage={() => "No results found"}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                />
              ) : null}
            </Col>
          </FormGroup>
        ) : null}

        {checkBoxError && (
          <Alert color="danger">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="mx-2"
            ></FontAwesomeIcon>
            Please Tag a candidate or consultant
          </Alert>
        )}

        <div className="d-flex justify-content-end">
          {" "}
          <Button className="mr-2" color="success" type="submit">
            Create
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(Event);
