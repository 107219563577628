import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
  Col,
} from "reactstrap";

const AddRejectNoteModal = ({ isOpen, toggle, onSave = false }) => {
  const [noteReject, setNoteReject] = useState("");

  const handleSave = () => {
    // onSave({ noteReject});
    onSave(String(noteReject));
    setNoteReject("");

  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="modal-header bg-primary" toggle={toggle}>
        Add Reject Note
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Col sm={12}>
              <Label for="note">Note:</Label>
              <Input
                type="textarea"
                name="note"
                id="note"
                placeholder="Enter your note here"
                value={noteReject}
                onChange={(e) => setNoteReject(e.target.value)}
              />
            </Col>
          </FormGroup>
        
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={handleSave}>
          Save
        </Button>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddRejectNoteModal;
