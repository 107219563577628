import React from "react";
import TodoWidget from "../../widgets/todo-widget/todo";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";

const TodoPage = () => {
  return (
    <DashboardLayout title="To Do List" subTitle=" " showTodoIcon={true}>
      <TodoWidget></TodoWidget>
    </DashboardLayout>
  );
};
export default TodoPage;
