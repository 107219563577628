import {
  NEW_CHAT_RECEIVED,
  TODO_NOTIFICATION,
  RESCHEDULE_NOTIFICATION,
  EVENT_NOTIFICATION,
} from "./types";

export const chatNewNotificationAction = (id, message) => (dispatch) => {
  dispatch({
    type: NEW_CHAT_RECEIVED,
    id: id,
    message: message,
  });
};
