import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { storeWebSocketData } from "../../redux/actions/softPhoneIntegrationAction.js";

const callStateSoftphone = ({ websocketData, storeWebSocketData }) => {
//   useEffect(() => {
//     // Assuming you have a way to receive websocket data and update the store
//     // For example, you might have a WebSocket connection handling incoming data
//     // When new data arrives, you can dispatch it to the store using storeWebSocketData
//     const newDataFromWebSocket = /* Your websocket data */;
//     storeWebSocketData(newDataFromWebSocket);
//   }, [storeWebSocketData]);

  return (
    <div>
      {/* Access and display the changing CallState here */}
      {websocketData && websocketData.Calls && websocketData.Calls.length > 0 && (
        <div>
          {websocketData.Calls.map((call, index) => (
            <div key={index}>
              CallState: {call.CallState}
              {/* Other details from the call */}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  websocketData: state.websocketData,
});

export default connect(mapStateToProps, { storeWebSocketData })(callStateSoftphone);
