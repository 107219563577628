import React, { useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { useState } from "react";
import { connect } from "react-redux";
import { HOSTMexxar } from "../../../configs/api-config";
import NoConnection from "../../../pages/errorPages/NoConnection";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../../pages/errorPages/NoSearchResults";
import { isNull } from "lodash";
import { Alert } from "reactstrap";

const UpcommingTasksWidget = (props) => {
  const { userProfile, style, userId, candidateId, incommingPage } = props;
  const [dataList, setDataList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const MexxarAPI = HOSTMexxar;
  var Today = moment(new Date()).format("YYYY/MM/DD");
  var EndDate = moment(moment(Today).add(7, "days")).format("YYYY/MM/DD");

  const getEvents = () => {
    setLoading(true);
    axios
      .get(
        `${MexxarAPI}events/range?userId=${userId}&start=${Today}&end=${EndDate}&type=EMPLOYEE`
      )
      .then((eventsRes) => {
        let events = eventsRes.data.body;

        let eventsArray = [];

        for (let i = 0; i < events.length; i++) {
          eventsArray.push({
            id: events[i].id,
            title: `Event : ${events[i].name}`,
            body: events[i].name,
            content: moment(events[i].startDateTime).format("LLLL"),
            recordedEmployeeName: events[i].recordedEmployeeName,
          });
        }

        /**
         *  Training booking
         **/
        axios
          .get(
            `${MexxarAPI}training-booking/range?userId=${userId}&start=${Today}&end=${EndDate}&type=EMPLOYEE`
          )
          .then((trainingRes) => {
            let training = trainingRes.data.body;

            for (let i = 0; i < training.length; i++) {
              let title = "";
              if (
                training[i].trainings[0] != undefined ||
                training[i].trainings[0] != null
              ) {
                if (training[i].trainings.length > 1) {
                  title = "Multiple Trainings";
                } else {
                  title = training[i].trainings[0].name;
                }
              }

              let body = [];
              for (let x = 0; x < training[i].trainings.length; x++) {
                body.push(training[i].trainings[x].name);
              }

              eventsArray.push({
                id: training[i].id,
                title: `Training : ${title}`,
                body: String(body),
                content: moment(training[i].startDate).format("LLLL"),
                recordedEmployeeName: training[i].recordedEmployeeName,
              });
            }
            setLoading(false);
            setDataList(eventsArray);
          })
          .catch((error) => {
            setError(true);
          });
      })
      .catch((error) => {
        setError(true);
      });
  };

  const getTrainingOfCandidate = (candidateId) => {
    setLoading(true);

    let eventsArray = [];

    axios
      .get(
        `${MexxarAPI}training-booking/range?userId=${candidateId}&start=${Today}&end=${EndDate}&type=CANDIDATE`
      )
      .then((trainingRes) => {
        let training = trainingRes.data.body;

        for (let i = 0; i < training.length; i++) {
          let title = "";
          if (
            training[i].trainings[0] != undefined ||
            training[i].trainings[0] != null
          ) {
            if (training[i].trainings.length > 1) {
              title = "Multiple Trainings";
            } else {
              title = training[i].trainings[0].name;
            }
          }

          let body = [];
          for (let x = 0; x < training[i].trainings.length; x++) {
            body.push(training[i].trainings[x].name);
          }

          eventsArray.push({
            id: training[i].id,
            title: title,
            body: body,
            content: moment(training[i].startDate).format("LLLL"),
            recordedEmployeeName: training[i].recordedEmployeeName,
          });
        }
        setLoading(false);
        setDataList(eventsArray);
      })
      .catch((error) => {
        setError(true);
      });
  };

  useEffect(() => {
    candidateId != undefined && candidateId != null
      ? getTrainingOfCandidate(candidateId)
      : getEvents();

    return () => {};
  }, []);

  const sortFunctionAscending = (a, b) => {
    var dateA = new Date(a.content).getTime();
    var dateB = new Date(b.content).getTime();
    return dateA > dateB ? 1 : -1;
  };

  return (
    <div style={style}>
      {/* <Card /> */}
      {/* <Label className="ml-3 mt-3">Upcoming Tasks</Label>
 
        <hr /> */}

      <div className="m-2 p-2" style={{ overflow: "auto", ...style }}>
        {error && (
          <div>
            <NoConnection error={error}></NoConnection>
          </div>
        )}

        {dataList
          ? dataList.sort(sortFunctionAscending).map((post, i) => {
              return (
                <div key={i}>
                  <div className="card mb-2">
                    <div className="p-3 ">
                      <div className=" list-item ">
                        <div className="timeline timeline-theme animates animates-fadeInUp">
                          <div className="tl-item active">
                            <div className="tl-dot"></div>
                            <div className="tl-content">
                              <div>
                                <div className=" ">{post.title}</div>
                                {post.body != null &&
                                post.body != undefined &&
                                post.body.length > 1 ? (
                                  <small className="tl-content ">
                                    {/* {String(post.body)} */}
                                  </small>
                                ) : null}
                              </div>
                              <div
                                className="tl-date text-muted mt-1"
                                style={{ maxWidth: "300px" }}
                              >
                                {
                                  <time
                                    dateTime="2020-08-23T14:54:51Z"
                                    data-local="time"
                                    data-format="%B %e, %Y %l:%M%P"
                                    title="August 23, 2020 at 8:24pm IST"
                                    data-localized=""
                                    aria-label="August 23, 2020 8:24pm"
                                  >
                                    {post.content} <br /> assigned by{" "}
                                    {post.recordedEmployeeName}
                                  </time>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
        {loading && !error && (
          <div className="d-flex justify-content-center align-items-center">
            <div>
              <img src={loadingAnimation} style={{ height: 100 }}></img>
            </div>
          </div>
        )}

        {!loading &&
          !error &&
          ((dataList && dataList.length == 0) || isNull(dataList)) &&
          (incommingPage === "Dashboard" ? (
            <div
              className="d-felx justify-content-between align-items-center mt-3 "
              style={{ textAlign: "center" }}
            >
              <Alert>No matching results found</Alert>
            </div>
          ) : (
            <NoSearchResults />
          ))}
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.auth.item.userTypeId,
  };
}

export default connect(mapStateToProps)(UpcommingTasksWidget);
