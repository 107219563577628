import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";

export default function NewTwoFields(props) {
  const { register, handleSubmit, errors } = useForm();
  const {
    onCreate,
    closeModal,
    mode,
    modalType,
    fieldDataOne,
    fieldDataTwo,
  } = props;
  const [value, setValue] = useState("");
  const [addKillLog, setaddKillLog] = useState(false);
  const changeHandler = ({ target }) => {
    setValue(target.value.toUpperCase());
  };

  const onSubmit = (data) => {
    setaddKillLog(true);
    onCreate(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={4}>{modalType}</Label>
        <Col sm={8}>
          <Input
            value={value}
            onChange={changeHandler}
            type="text"
            name="rowName"
            defaultValue={fieldDataOne}
            invalid={errors.bName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
          {errors.bName && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.bName?.message}
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Descriptiion</Label>
        <Col sm={8}>
          <Input
            type="text"
            name="rowFieldTwo"
            defaultValue={fieldDataTwo}
            invalid={errors.description}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 50,
                message: "This field only allowed only 50 characters",
              },
            })}
          ></Input>
          {errors.description && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.description?.message}
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <div className="d-flex ">
          {" "}
          <Button
            className="mr-2"
            color="success"
            type="submit"
            disabled={addKillLog}
          >
            {mode}
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}
