import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Label,
  Form,
  FormGroup,
  Col,
} from "reactstrap";
import { forEach } from "lodash";
import Select from "react-select";
import { connect } from "react-redux";
import { useMsal } from "@azure/msal-react";

function BookingConfigure(props) {
  const { register, handleSubmit } = useForm();
  const { closeModal, agencyList } = props;
  const [selectedAgency, setSelectedAgency] = useState([]);
  const [agency, setAgency] = useState([]);
  
  const { instance, accounts } = useMsal();
  const storedAgency = JSON.parse(localStorage.getItem("selectedAgency"));

 
  const getAgencies = () => {
    let agencyArray = [];
    forEach(agencyList, function (item) {
      agencyArray.push({
        value: item.agencyName,
        label: item.agencyName + `${item.type === "DATABASE" ? "-DB" : ""}`,
        id: item.clientId,
      });
    });
    setAgency(agencyArray);
  };

  useEffect(() => {
    getAgencies();
    if (storedAgency) {
      setSelectedAgency(storedAgency);
    }
  }, [agencyList]);

  const onSubmit = (data) => {
    if (selectedAgency && selectedAgency.length > 0) {
      localStorage.setItem("selectedAgency", JSON.stringify(selectedAgency));
    } else {
      localStorage.removeItem("selectedAgency");
    }
    closeModal();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={3}>Agency Name</Label>
        <Col sm={8}>
          {agency.length > 0 && (
            <Select
              isMulti
              name="agency"
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                  color: "#333333",
                }),
              }}
              defaultValue={storedAgency}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => setSelectedAgency(data)}
              options={agency}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            />
          )}
        </Col>
      </FormGroup>

      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="success" type="submit">
          Save
        </Button>
        <Button color="secondary" onClick={() => closeModal()}>
          Cancel
        </Button>
      </div>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(BookingConfigure);
