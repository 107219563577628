
// import React, { useState, useEffect, useRef } from "react";

// import {
//   Button,
 
// } from "reactstrap";


// import "./compliance.style.css";
// import { connect } from "react-redux";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


// const ComplianceQuestionnaire2 = (props) => {
//   const {
//     candidateData,
//     candidateId,
//   } = props;



//   const questions = [

//     { id: 0, label: `Hi ${candidateData?.firstName} ${candidateData?.lastName}, Would you like Mexxi's 🤖 support for your RTW documents?`, nextOnYes: 1, nextOnNo: 0 },
//     { id: 1, label: "Do you have a UK passport?", nextOnYes: 2, nextOnNo: 4 },
//     { id: 2, label: "Are you a British citizen?This does not include these kinds of British citizenship:", nextOnYes: 3,nextOnNo: 4 },
//     { id: 3, label: "You are entitled to work in the UK Please upload the following:- RTW - Passport " },
//     { id: 4, label: "Do you have a Certificate of Registration or Naturalisation as a British Citizen? ",nextOnYes: 5, nextOnNo: 7},
//     { id: 5, label: "You need to show proof of your permission to work in the UK-Please upload the following:- RTW - An official letter or document " },
//     { id: 6, label: "Do you have a Certificate of Registration or Naturalisation as a British Citizen?Please upload the following:- RTW - An official letter or document" },
//     { id: 7, label: "You need to show proof of your permission to work in the UK" },
//     { id: 8, label: "Are you a British Citizen/From the Channel Islands or Isle of Man" , nextOnYes: 8,nextOnNo: 9},
//     { id: 9, label: "Please upload the following" },
//     { id: 10, label: "From the EU, EEA, or",nextOnYes: 10 },
//     { id: 11, label: "Do you have an EU, EEA or Swiss passport or National Identity Card or are you a named person in your parent's or child's passport? ." , nextOnYes: 12,nextOnNo: 11 },
  
//     { id: 12, label: "Do you have a Registration Certificate or document certifying permanent residence from the Home Office?"  , nextOnYes: 14,nextOnNo: 13},
//     { id: 13, label: "You are entitled to work in the UK" },
//     { id: 14, label: "Do you have a current Biometric Residence Permit or current passport endorsed to show you?re allowed to stay indefinitely in the UK"  , nextOnYes: 15,nextOnNo: 16},
//     { id: 15, label: "You are entitled to work in the UK" },

//     { id: 16, label: "the person is entitled to work in the UK" },
//     { id: 17, label: "Does the worker have a current Immigration Status Document",nextOnYes: 17,nextOnNo: 18 },
//     { id: 18, label: "You may be entitled to work in the UK  " },
//     { id: 19, label: "Do you have a current passport with a current endorsement from the Home Office?",nextOnYes: 19,nextOnNo:20 },
//     { id: 20, label: "You are entitled to work in the UK Set the expiry date to when this card expires" },
//     { id: 21, label: "Do you have a current Biometric Residence Permit?",nextOnYes: 21,nextOnNo:22 },
//     { id: 22, label: "You are entitled to work in the UK" },
//     { id: 23, label: "Does the worker have an Immigration Status Document?" ,nextOnYes: 23,nextOnNo:24},
//     { id: 24, label: "You are entitled to work in the UK while their documents are valid." },
//     { id: 25, label: "Do you have a Certificate of Application issued by the Home Office?" ,nextOnYes: 25,nextOnNo:26},
//     { id: 26, label: "You may be entitled to work in the UK." },
//     { id: 27, label: "Do you have an Application Registration Card?",nextOnYes: 27,nextOnNo:28 },
//     { id: 28, label: "You may be entitled to work in the UK" },
//     { id: 29, label: "Do you have an outstanding application with the Home Office that was made before your previous leave expired or have an outstanding appeal against a Home Office decision?",nextOnYes: 29,nextOnNo:30 },
//     { id: 30, label: "You may be entitled to work in the UK." },
//     { id: 31, label: "You do not have enough evidence of your entitlement to work in the UK.Check with the Home Office if the applicant is a Commonwealth citizen but does not have the right documents - they might still have the right to work in   the UK. " },
    

//   ];


//   const [isUkCitizen, setIsUkCitizen] = useState(false);
//   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

//      const [questionsToDisplay, setQuestionIndex] = useState([{  id: 0, label: `Hi ${candidateData?.firstName} ${candidateData?.lastName}, Would you like Mexxi's 🤖 support for your RTW documents?`, nextOnYes: 1, nextOnNo: 0 }]);

//   const [invalidOptionSelected, setInvalidOptionSelected] = useState(false);
 
//   useEffect(() => {
//     setQuestionIndex([{ id: 0, label: `Hi ${candidateData?.firstName} ${candidateData?.lastName}, Would you like Mexxi's 🤖 support for your RTW documents?`, nextOnYes: 1, nextOnNo: 0 }]);
//   }, [candidateData]);
//   const [isLoading, setIsLoading] = useState(false);
//   const [showTyping, setShowTyping] = useState(false);

//   const chatHistoryRef = useRef(null);
//   useEffect(() => {
//     if (chatHistoryRef.current) {
//       chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
//     }
//   }, [questionsToDisplay]);
  
//   const handleYesButtonClick = () => {
//     const nextQuestionIndex = questions[currentQuestionIndex].nextOnYes;
//     if (nextQuestionIndex !== undefined) {
//       setShowTyping(true); 
//       setTimeout(() => {
//         setCurrentQuestionIndex(nextQuestionIndex);
//         const nextQuestion = questions[nextQuestionIndex];
//         setQuestionIndex(prevQuestions => [...prevQuestions, { id: -1, label: "Yes", nextOnYes: -1, nextOnNo: -1 }]);
//         setTimeout(() => {
//           setQuestionIndex(prevQuestions => [...prevQuestions, nextQuestion]);
//           setIsLoading(false);
//           setShowTyping(false);
//           if (currentQuestionIndex === 1) {
//             setIsUkCitizen(true); // Set state variable
//             console.log(`Candidate ${candidateData?.firstName} ${candidateData?.lastName} is a UK citizen.`); // Log to console
//           }
//         }, 2200); 
//       }, 2000); 
//     } else {
//       setInvalidOptionSelected(true);
//     }
//   };
  
//   const handleNoButtonClick = () => {
//     const nextQuestionIndex = questions[currentQuestionIndex].nextOnNo;
//     if (nextQuestionIndex !== undefined) {
//       setShowTyping(true); 
//       setTimeout(() => {
//         setCurrentQuestionIndex(nextQuestionIndex);
//         const nextQuestion = questions[nextQuestionIndex];
//         setQuestionIndex(prevQuestions => [...prevQuestions, { id: -1, label: "No", nextOnYes: -1, nextOnNo: -1 }]);
//         setTimeout(() => {
//           setQuestionIndex(prevQuestions => [...prevQuestions, nextQuestion]);
//           setIsLoading(false);
//           setShowTyping(false);
//         }, 2200); 
//       }, 2000); 
//     } else {
//       setInvalidOptionSelected(true);
//     }
//   };
  

  
//   const resetQuestionnaire = () => {
//     setCurrentQuestionIndex(0);

//     setInvalidOptionSelected(false);
//     setIsUkCitizen(false); 
    
//   };

 

//   return (

//     <div class="container">
//   <div class="row clearfix">
//     <div class="col-lg-12">
//         <div class="chat-app">
        


//             <div class="chat">
               


//             <div class="chat-header clearfix">
//             <br/>
//             <br/>
//                     <div class="row">
                     
//                     <div class="col-lg-12 text-left ">

//                         </div>
                       
//                     </div>
//                 </div>

   
//                 <div className="chat-history" ref={chatHistoryRef} style={{ maxHeight: '500px', overflowY: 'auto' }}>

//               <ul className="m-b-0">
             

           
// <li className="clearfix">




// {questionsToDisplay.map((question, index) => (
//     question ? (
//         <li key={question.id} className="clearfix">
//             <div class="message-data text-right">
//                 {question.label === "Yes" || question.label === "No" ? (
//                     <>
//                         <span class="message-data-time">{new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>
//                         <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="avatar"/>
//                     </>
//                 ) : null}
//             </div>
            
//             <div className={(question.label === "Yes" || question.label === "No") ? "message my-message float-right" : "message my-message2 anim-typewriter  "}>
//                 {question.label}
//             </div>
            
//             {showTyping && index === questionsToDisplay.length - 1 && (
            	 
//             	 <div class="message3 my-message3">
//                   <div className="typing">
//                     <div className="typing__dot"></div>
//                     <div className="typing__dot"></div>
//                     <div className="typing__dot"></div>
//                   </div>
//                </div>
//             )}

// <div class="message-data text-left">
//                 {question.label === "Yes" || question.label === "No" ? (
//                     <>
                       
//                         <img src="https://img.freepik.com/premium-psd/3d-futuristic-robot-avatar-closeup-portrait-isolated-transparent-background-png-psd_888962-1625.jpg" alt="avatar"/>
//                         <span class="message-data-time">{new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>
//                     </>
//                 ) : null}
//             </div>

           
//         </li>
//     ) : null
// ))}

   
// </li> 


              
//               </ul>
//             </div>

            
//             <div className="chat-message clearfix">
//               <div className="input-group mb-0">
//               {invalidOptionSelected && <div className= 'text-md '>Let's rethink our approach to this question with Mexxi🤖.......</div>}
//                   {currentQuestionIndex < questions.length - 1 && !invalidOptionSelected && (
//                     <>
                    
// <div 
//   className="mr-2 ml-2 hover-fx wrap svg-container" 
//   onClick={handleYesButtonClick}
// >

  
 


//   <svg 
//     xmlns="http://www.w3.org/2000/svg" 
//     id="Layer_1" 
//     data-name="Layer 1" 
//     viewBox="0 0 448 512"
//     width="40" 
//     height="40" 
//     style={{ fill: 'white' }}
//     className="svg-icon"
//   >
//    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
 
//   </svg>
// </div>

// <div 
//   className="mr-2 ml-2 hover-fx wrap svg-container" 
//   onClick={handleNoButtonClick}
// >
// <svg xmlns="http://www.w3.org/2000/svg"
//  id="Layer_1"
//   data-name="Layer 1"
//   viewBox="0 0 384 512"
//    width="40" 
//     height="40" 
//     style={{ fill: 'white' }}
//     className="svg-icon"
    
//     >
      
//       <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
      
//       </svg>
// </div>


   
//                     </>
                    
//                   )}
//              {invalidOptionSelected && (



//                     <Button color="primary" className="mr-2 ml-2" onClick={resetQuestionnaire}>
//                       Restart
//                     </Button>
//                   )}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>


               
       





//   );
// };

// function mapStateToProps(state) {
//   return {
//     auth: state.auth,
//   };
// }

// export default connect(mapStateToProps, {})(ComplianceQuestionnaire2);

import React, { useState, useEffect, useRef } from "react";
import { Button } from "reactstrap";
import "./compliance.style.css";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ComplianceQuestionnaire2 = (props) => {
  const { candidateData } = props;

  const questions = [
    { id: 0, label: `Hi ${candidateData?.firstName} ${candidateData?.lastName}, would you like Mexxi's 🤖 support for your RTW documents?`, nextOnYes: 1, nextOnNo: 0 },
    { id: 1, label: "Do you have a UK passport?", nextOnYes: 2, nextOnNo: 4 },
    { id: 2, label: "Are you a British citizen? This does not include these types of British citizenship: British Nationals Overseas (e.g., from Hong Kong), British protected persons (from the former British Empire), British subjects (e.g., from India before 1949), British Overseas Territories citizens, or British Overseas citizens (e.g., from Malaysia, Kenya).", nextOnYes: 3, nextOnNo: 4 },
    { id: 3, label: "You are entitled to work in the UK. Please upload a copy of your passport, including any page with the expiry date and applicant's details (e.g., nationality, date of birth, and photograph) including endorsements (e.g., a work visa). Please upload the following: RTW - Passport", nextOnYes: 0, nextOnNo: 0 },
    { id: 4, label: "Do you have a Certificate of Registration or Naturalisation as a British Citizen?", nextOnYes: 5, nextOnNo: 7 },
    { id: 5, label: "You need to show proof of your permission to work in the UK. Please upload an official letter or document from a government agency (e.g., HM Revenue and Customs, Department for Work and Pensions, or the Social Security Agency in Northern Ireland) or a previous employer, showing your name and National Insurance number; Certificate of Registration or Naturalisation as a British Citizen. Please upload the following: RTW - An official letter or document", nextOnYes: 0, nextOnNo: 0 },
    { id: 6, label: "You need to show proof of your permission to work in the UK.", nextOnYes: 0, nextOnNo: 0 },
    { id: 8, label: "Are you a British Citizen/from the Channel Islands or Isle of Man?", nextOnYes: 8, nextOnNo: 9 },
    { id: 9, label: "Please upload the following: RTW - An official letter or document, RTW - Full Birth/Adoption Certificate", nextOnYes: 0, nextOnNo: 0 },
    { id: 10, label: "From the EU, EEA, or Switzerland? A non-EEA family member of someone from the EU,EEA or Switzerland.From somewhere else", nextOnYes: 10, nextOnNo: 0 },
    { id: 11, label: "Do you have an EU, EEA, or Swiss passport or National Identity Card, or are you a named person in your parent's or child's passport? Hint: A named person is someone who appears on someone else's passport, e.g., a parent.", nextOnYes: 12, nextOnNo: 11 },
    { id: 12, label: "Do you have a Registration Certificate or document certifying permanent residence from the Home Office?", nextOnYes: 14, nextOnNo: 13 },
    { id: 13, label: "You are entitled to work in the UK. You need to upload a copy of your passport, including any page with the expiry date and applicant's details (e.g., nationality, date of birth, and photograph) including endorsements (e.g., a work visa), or your entire National Identity Card. Please upload the following: RTW - Passport, RTW - National Identity Card", nextOnYes: 0, nextOnNo: 0 },
    { id: 14, label: "Do you have a current Biometric Residence Permit or a current passport endorsed to show you’re allowed to stay indefinitely in the UK? The permit or passport endorsement needs to show that it’s been issued by the Home Office, it’s current, in your name, and shows that you have one of the following: indefinite leave to enter or remain, no time limit to your stay in the UK, a certificate of entitlement to the Right of Abode, or is exempt from immigration control. The passport must also be current.", nextOnYes: 32, nextOnNo: 15 },
    { id: 15, label: "You are entitled to work in the UK. Please upload the following: RTW - Registration Certificate or document certifying permanent residence from the Home Office", nextOnYes: 0, nextOnNo: 0 },
    { id: 16, label: "Does the worker have a current Immigration Status Document? Please upload both sides of your Biometric Residence Permit and a copy of your passport, including any page with the expiry date and applicant's details (e.g., nationality, date of birth, and photograph) including endorsements (e.g., a work visa both sides of your Biometric Residence Permit).", nextOnYes: 16, nextOnNo: 17 },
    { id: 17, label: "You may be entitled to work in the UK. You need to have both of the following: a current Immigration Status Document issued by the Home Office, stating that the worker has indefinite leave to remain or has no time limit to their stay in the UK, and an official letter or document from a government agency. Please upload the following: RTW - Immigration Status Document, RTW - An official letter or document", nextOnYes: 0, nextOnNo: 0 },
    { id: 18, label: "Do you have a current passport with a current endorsement from the Home Office? It must show that the worker can do the work you’re offering and has a legal right to stay in the UK.", nextOnYes: 31, nextOnNo: 19 },
    { id: 19, label: "You are entitled to work in the UK. Set the expiry date to when this card expires.", nextOnYes: 0, nextOnNo: 0 },
    { id: 20, label: "Do you have a current Biometric Residence Permit?", nextOnYes: 20, nextOnNo: 21 },
    { id: 21, label: "You are entitled to work in the UK. Set the expiry date to when this endorsement expires. Please upload a scan of both sides of your Biometric Residence Permit. The permit needs to show all of the following: it’s been issued by the Home Office, it’s in the worker’s name, the worker is allowed to stay in the UK, the worker is allowed to do the work you’re offering. Please upload the following: RTW - Entire Biometric Residence Permit", nextOnYes: 0, nextOnNo: 0 },
    { id: 22, label: "Does the worker have an Immigration Status Document? It must show that you are allowed to do the work we’re offering.", nextOnYes: 23, nextOnNo: 27 },
    { id: 23, label: "You are entitled to work in the UK while their documents are valid. Set the expiry date to when this endorsement expires. You need to have both of the following: a current Immigration Status Document issued by the Home Office, stating that the worker has indefinite leave to remain or has no time limit to their stay in the UK, and an official letter or document from a government agency (e.g., HM Revenue and Customs, Department for Work and Pensions, or the Social Security Agency in Northern Ireland) or previous employer, showing their name and National Insurance number. Please upload the following: RTW - Immigration Status Document, RTW - An official letter or document", nextOnYes: 0, nextOnNo: 0 },
    { id: 24, label: "Do you have a Certificate of Application issued by the Home Office? The certificate must state that the worker is a family member of an EEA, EU, or Swiss national and is allowed to work.", nextOnYes: 24, nextOnNo: 26 },
    { id: 25, label: "You may be entitled to work in the UK. You must ask the Home Office to check your worker’s immigration employment status. If the Home Office check says they’re allowed to work, the worker can work for 6 months from the date of the check. You’ll then have to make another check. You may get a civil penalty if the Home Office says the worker isn’t allowed to do the work. UK Visas and Immigration: Email NWEUROCOARequests@homeoffice.gsi.gov.uk, or mail UK Visas and Immigration, European Applications Security Team COA Requests, PO Box 306, Liverpool, L3 9PP. Please upload the following: RTW - Certificate of Application", nextOnYes: 0, nextOnNo: 0 },
    { id: 26, label: "Do you have an Application Registration Card? The card must be issued by the Home Office stating you can do the type of work we’re offering.", nextOnYes: 27, nextOnNo: 28 },
    { id: 27, label: "You may be entitled to work in the UK. You must ask the Home Office to check your worker’s immigration employment status. If the Home Office check says they’re allowed to work, the worker can work for 6 months from the date of the check. You’ll then have to make another check. You may get a civil penalty if the Home Office says the worker isn’t allowed to do the work you’re offering. We are confirming your right to work with the Home Office and will notify you of the outcome.", nextOnYes: 0, nextOnNo: 0 },
    { id: 28, label: "Do you have an outstanding application with the Home Office that was made before your previous leave expired, or have an outstanding appeal against a Home Office decision? The previous Home Office leave or Home Office decision must have allowed the worker to do the work you’re offering.", nextOnYes: 28, nextOnNo: 29 },
    { id: 29, label: "You may be entitled to work in the UK. You must ask the Home Office to check your worker’s immigration employment status. If the Home Office check says they’re allowed to work, the worker can work for 6 months from the date of the check. You’ll then have to make another check. You may get a civil penalty if the Home Office says the worker isn’t allowed to do the work you’re offering. We are confirming your right to work with the Home Office and will notify you of the outcome.", nextOnYes: 0, nextOnNo: 0 },
    { id: 30, label: "You do not have enough evidence of your entitlement to work in the UK. Check with the Home Office if the applicant is a Commonwealth citizen but does not have the right documents—they might still have the right to work in the UK.", nextOnYes: 0, nextOnNo: 0 },
    { id: 31, label: "Are you from the EU, EEA, or Switzerland, or a non-EEA family member of someone from the EU, EEA, or Switzerland, or from somewhere else?", nextOnYes: 10, nextOnNo: 11 },
    { id: 32, label: "You may be entitled to work in the UK. Set the expiry date to when this card expires. Please upload a copy of your passport, including any page with the expiry date and applicant’s details (e.g., nationality, date of birth, and photograph) including endorsements (e.g., a work visa). Please upload the following: RTW - Passport", nextOnYes: 0, nextOnNo: 0 },
    { id: 33, label: "The person is entitled to work in the UK. Please upload both sides of your Biometric Residence Permit and a copy of your passport, including any page with the expiry date and applicant’s details (e.g., nationality, date of birth, and photograph) including endorsements (e.g., a work visa). Please upload the following: RTW - Passport, RTW - Entire Biometric Residence Permit", nextOnYes: 0, nextOnNo: 0 },
];


  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [questionsToDisplay, setQuestionsToDisplay] = useState([{ id: 0, label: `Hi ${candidateData?.firstName} ${candidateData?.lastName}, Would you like Mexxi's 🤖 support for your RTW documents?`, nextOnYes: 1, nextOnNo: 0 }]);
  const [invalidOptionSelected, setInvalidOptionSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showTyping, setShowTyping] = useState(false);
  const [isUkCitizen, setIsUkCitizen] = useState(false);
  const chatHistoryRef = useRef(null);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [questionsToDisplay]);

  useEffect(() => {
    setQuestionsToDisplay([{ id: 0, label: `Hi ${candidateData?.firstName} ${candidateData?.lastName}, Would you like Mexxi's 🤖 support for your RTW documents?`, nextOnYes: 1, nextOnNo: 0 }]);
  }, [candidateData]);

  const handleYesButtonClick = () => {
    const nextQuestionIndex = questions[currentQuestionIndex].nextOnYes;
    if (nextQuestionIndex !== undefined) {
      setShowTyping(true); 
      setTimeout(() => {
        setCurrentQuestionIndex(nextQuestionIndex);
        const nextQuestion = questions[nextQuestionIndex];
        setQuestionsToDisplay(prevQuestions => [...prevQuestions, { id: -1, label: "Yes", nextOnYes: -1, nextOnNo: -1 }]);
        setTimeout(() => {
          setQuestionsToDisplay(prevQuestions => [...prevQuestions, nextQuestion]);
          setIsLoading(false);
          setShowTyping(false);
          if (currentQuestionIndex === 1) {
            setIsUkCitizen(true); 
            console.log(`Candidate ${candidateData?.firstName} ${candidateData?.lastName} is a UK citizen.`); 
          }
        }, 2200); 
      }, 2000); 
    } else {
      setInvalidOptionSelected(true);
    }
  };

  const handleNoButtonClick = () => {
    const nextQuestionIndex = questions[currentQuestionIndex].nextOnNo;
    if (nextQuestionIndex !== undefined) {
      setShowTyping(true); 
      setTimeout(() => {
        setCurrentQuestionIndex(nextQuestionIndex);
        const nextQuestion = questions[nextQuestionIndex];
        setQuestionsToDisplay(prevQuestions => [...prevQuestions, { id: -1, label: "No", nextOnYes: -1, nextOnNo: -1 }]);
        setTimeout(() => {
          setQuestionsToDisplay(prevQuestions => [...prevQuestions, nextQuestion]);
          setIsLoading(false);
          setShowTyping(false);
        }, 2200); 
      }, 2000); 
    } else {
      setInvalidOptionSelected(true);
    }
  };

  const resetQuestionnaire = () => {
    setCurrentQuestionIndex(0);
    setQuestionsToDisplay([{ id: 0, label: `Hi ${candidateData?.firstName} ${candidateData?.lastName}, Would you like Mexxi's 🤖 support for your RTW documents?`, nextOnYes: 1, nextOnNo: 0 }]);
    setInvalidOptionSelected(false);
    setIsUkCitizen(false); 
  };

  return (
    <div className="container">
      <div className="row clearfix">
        <div className="col-lg-12">
          <div className="chat-app">
            <div className="chat">
              <div className="chat-header clearfix">
                <br/>
                <br/>
                <div className="row">
                  <div className="col-lg-12 text-left"></div>
                </div>
              </div>

              <div className="chat-history" ref={chatHistoryRef} style={{ maxHeight: '500px', overflowY: 'auto' }}>
                <ul className="m-b-0">
                  {questionsToDisplay.map((question, index) => (
                    <li key={question.id} className="clearfix">
                      <div className="message-data text-right">
                        {question.label === "Yes" || question.label === "No" ? (
                          <>
                            <span className="message-data-time">{new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>
                            <img src="https://bootdey.com/img/Content/avatar/avatar7.png" alt="avatar"/>
                          </>
                        ) : null}
                      </div>

                      <div className={(question.label === "Yes" || question.label === "No") ? "message my-message float-right" : "message my-message2 anim-typewriter"}>
                        {question.label}
                      </div>

                      {showTyping && index === questionsToDisplay.length - 1 && (
                        <div className="message3 my-message3">
                          <div className="typing">
                            <div className="typing__dot"></div>
                            <div className="typing__dot"></div>
                            <div className="typing__dot"></div>
                          </div>
                        </div>
                      )}

                      <div className="message-data text-left">
                        {question.label === "Yes" || question.label === "No" ? (
                          <>
                            <img src="https://img.freepik.com/premium-psd/3d-futuristic-robot-avatar-closeup-portrait-isolated-transparent-background-png-psd_888962-1625.jpg" alt="avatar"/>
                            <span className="message-data-time">{new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>
                          </>
                        ) : null}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="chat-message clearfix">
                <div className="input-group mb-0">
                  {invalidOptionSelected && <div className='text-md'>Let's rethink our approach to this question with Mexxi🤖.......</div>}
                  { !invalidOptionSelected && (
                    <>
                      <div className="mr-2 ml-2 hover-fx wrap svg-container" onClick={handleYesButtonClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 448 512" width="40" height="40" style={{ fill: 'white' }} className="svg-icon">
                          <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                        </svg>
                      </div>

                      <div className="mr-2 ml-2 hover-fx wrap svg-container" onClick={handleNoButtonClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 384 512" width="40" height="40" style={{ fill: 'white' }} className="svg-icon">
                          <path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/>
                        </svg>
                      </div>
                    </>
                  )}
                  {invalidOptionSelected && (
                    <Button color="primary" className="mr-2 ml-2" onClick={resetQuestionnaire}>
                      Restart
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(ComplianceQuestionnaire2);
