import {
  REGISTER_USER,
  LOGIN_USER,
  SET_AUTHENTICATION,
  UNAUTHENTICATED,
  INVALID_USER_CREDENTIALS,
  SET_LOGGING_PROGRESS,
  SYSTEM_ERROR,
} from "../actions/types";

const initialState = {
  isAuthenticated: false,
  item: {},
  invalidCredentials: false,
  systemError: false,
  loggingInProgress: false,
  permissions: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REGISTER_USER:
      return {
        ...state,
        item: action.payload,
        loggingInProgress: false,
        systemError: false,
      };
    case INVALID_USER_CREDENTIALS:
      return {
        ...state,
        isAuthenticated: action.payload.status,
        item: action.payload.data,
        invalidCredentials: true,
        loggingInProgress: false,
        systemError: false,
      };
    case LOGIN_USER:
      console.log("login auth function angi----2---?", action.payload)
      return {
        ...state,
        isAuthenticated: action.payload.status,
        item: action.payload,
        permissions: action.payload.permissions,
        invalidCredentials: false,
        loggingInProgress: false,
        systemError: false,
      };
    case SET_AUTHENTICATION:
      return {
        ...state,
        isAuthenticated: true,
        item: action.payload,
        permissions: action.payload.userRole.permissions,
        invalidCredentials: false,
        systemError: false,
      };
    case UNAUTHENTICATED:
      // window.location.reload();
      return {
        ...state,
        isAuthenticated: false,
        item: {},
        invalidCredentials: false,
        systemError: false,
      };
    case SET_LOGGING_PROGRESS:
      
      return {
        ...state,
        loggingInProgress: true,
        systemError: false,
      };
    case SYSTEM_ERROR:
      return {
        ...state,
        loggingInProgress: false,
        systemError: true,
      };
    default:
      return state;
  }
}
