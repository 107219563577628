import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";

import { connect } from "react-redux";
import { forEach, filter, split } from "lodash";
import { useSpring, animated } from "react-spring";

import NoConnection from "../../errorPages/NoConnection";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { useIsMounted } from "../../../customHooks/useIsMounted";
import {
  CANDIDATES_IN_REGISTRATION_STATUS,
  HOSTMexxar,
} from "../../../configs/api-config";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import HeadHunterRegistrationStatusWidget from "../../../widgets/working-card-widget/headhunter-registration-status-widgets";
import { FunnelIcon } from "../../../assets/icons/svg";
import useWorkspaceWithFilters from "../../../customHooks/useWorkspaceWithFilters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HeadHunterRegistrationStatus = (props) => {
  const { employeeID } = props;
  const MexxarApi = CANDIDATES_IN_REGISTRATION_STATUS;

  /***************** Search filter ******************/
  const [query, setQuery] = useState(null);
  const [reset, setReset] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [remember, setRemember] = useState(true); /// <--------filter settings options saving and retreving/ if need activate later with below checkbox
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchAdvanced, setSearchAdvanced] = useState(false);
  const [callResponse, setCallResponse] = useState("");
  const [workFlow, setWorkFlow] = useState("");

  let url =
    HOSTMexxar +
    "workspace/headhunter/in-registration/filter?&callResponse=" +
    callResponse +
    "&workflow=" +
    workFlow;

  const { loading, error, rows, hasMore, totalElements } =
    useWorkspaceWithFilters(
      url,
      query,
      pageNumber,
      searchAdvanced,
      reset,
      employeeID
    );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function handleSearch(e) {
    if (e.target.value == "") {
      setQuery(null);
    } else {
      setQuery(e.target.value);
    }
    setPageNumber(1);
  }

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  //....reset function......//
  const resetFilters = () => {
    setReset(!reset);
    setCallResponse("");
    setWorkFlow("");
  };
  /******* Use to set page number to default value of 1 when changing the employee or search advance filters  *******/
  useDidMountEffect(() => {
    setPageNumber(1);
  }, [searchAdvanced, employeeID]);
  /*******End *******/

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  return (
    <>
      <div className="form-inline">
        <FontAwesomeIcon icon={faUsers} className={"ml-2 mr-3  "} />
        <Label className={"mr-3"}>
          {totalElements}{" "}
          {totalElements.length == 1 ? "Candidate" : "Candidates"}
        </Label>
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={handleSearch}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
        <UncontrolledTooltip target="searchBar">
          Search candidates by Name/Id/Email/Phone No./Work Flow Status
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              {/* <Col>
                <Label>Call Response</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value=""
                      checked={callResponse === ""}
                      onChange={(e) => setCallResponse(e.target.value)}
                    />
                    <Badge color="info">All</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="HOT"
                      checked={callResponse === "HOT"}
                      onChange={(e) => setCallResponse(e.target.value)}
                    />
                    <Badge color="danger">Hot</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="NEUTRAL"
                      checked={callResponse === "NEUTRAL"}
                      onChange={(e) => setCallResponse(e.target.value)}
                    />
                    <Badge color="secondary">Neutral</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="COLD"
                      checked={callResponse === "COLD"}
                      onChange={(e) => setCallResponse(e.target.value)}
                    />
                    <Badge color="primary">Cold</Badge>
                  </Label>
                </FormGroup>
              </Col> */}

              {/* <Col></Col> */}
              <Col>
                <Label>Workflow</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value=""
                      checked={workFlow === ""}
                      onChange={(e) => setWorkFlow(e.target.value)}
                    />
                    <Badge color="info">All</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="HEADHUNTER"
                      checked={workFlow === "HEADHUNTER"}
                      onChange={(e) => setWorkFlow(e.target.value)}
                    />
                    <Badge color="warning">Headhunting</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="REGISTRATION"
                      checked={workFlow === "REGISTRATION"}
                      onChange={(e) => setWorkFlow(e.target.value)}
                    />{" "}
                    <Badge color="success">Registation</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="AUDIT"
                      checked={workFlow === "AUDIT"}
                      onChange={(e) => setWorkFlow(e.target.value)}
                    />{" "}
                    <Badge style={{ backgroundColor: "#8bc34a" }}>Audit</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="MAINTENANCE"
                      checked={workFlow === "MAINTENANCE"}
                      onChange={(e) => setWorkFlow(e.target.value)}
                    />{" "}
                    <Badge color="danger">Maintenance</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="BOOKING"
                      checked={workFlow === "BOOKING"}
                      onChange={(e) => setWorkFlow(e.target.value)}
                    />{" "}
                    <Badge color="success">Booking</Badge>
                  </Label>
                </FormGroup>
              </Col>

              <Col></Col>
              <Col>
                <div className="d-flex flex-column align-items-end">
                  <div>
                    <Button
                      color="success m-1 btn-sm"
                      style={{ width: "90px" }}
                      onClick={() => {
                        setSearchAdvanced(!searchAdvanced);
                      }}
                    >
                      Search
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="danger m-1 btn-sm"
                      style={{ width: "90px" }}
                      onClick={() => {
                        resetFilters();
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                  {/* <div className="mr-2">
                    <small>
                      <Input
                        type="checkbox"
                        value="REMEMBER"
                        checked={remember}
                        onChange={()=>setRemember(!remember))}
                      ></Input>
                      Remember
                    </small>
                  </div> */}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>

      {rows?.map((row, i) => {
        if (rows.length === i + 1) {
          return (
            <animated.div
              style={animationProps}
              ref={lastBookElementRef}
              key={row.email}
            >
              <HeadHunterRegistrationStatusWidget key={i} userProfile={row} />
            </animated.div>
          );
        } else {
          return (
            <animated.div style={animationProps} key={row.email}>
              <HeadHunterRegistrationStatusWidget key={i} userProfile={row} />
            </animated.div>
          );
        }
      })}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}
      {!loading && !error && rows.length == 0 && <NoSearchResults />}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(HeadHunterRegistrationStatus);
