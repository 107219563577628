import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import axios from "axios";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import NoConnection from "../../errorPages/NoConnection";
import ToastCommon from "../../../components/toastCommon";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { HOSTMexxar, REGISTER_CANDIDATE } from "../../../configs/api-config";
import { useIsMounted } from "../../../customHooks/useIsMounted";
import AuditRejectedWidget from "../../../widgets/working-card-widget/audit-rejected-widget";
import { FunnelIcon } from "../../../assets/icons/svg";
import useWorkspaceWithFilters from "../../../customHooks/useWorkspaceWithFilters";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import { useSpring, animated } from "react-spring";

const RegistrationAuditRejected = (props) => {
  const { updateTargets, employeeID } = props;

  var auditStatus = "DECLINE";

  /***************** Search filter ******************/
  const [query, setQuery] = useState(null);
  const [reset, setReset] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [remember, setRemember] = useState(true); /// <--------filter settings options saving and retreving/ if need activate later with below checkbox
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchAdvanced, setSearchAdvanced] = useState(false);
  const [callResponse, setCallResponse] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");

  let url =
    HOSTMexxar +
    "candidates/audit/filter/?&auditStatus=" +
    auditStatus +
    "&serviceType=TEMPORARY";

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  const { loading, error, rows, hasMore, totalElements } =
    useWorkspaceWithFilters(
      url,
      query,
      pageNumber,
      searchAdvanced,
      reset,
      employeeID
    );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function handleSearch(e) {
    if (e.target.value == "") {
      setQuery(null);
    } else {
      setQuery(e.target.value);
    }
    setPageNumber(1);
  }

  // const getAllRows = () => {
  //   axios
  //     .get(MexxarApi + "?employeeId=" + employeeID + "/audit/DECLINE")
  //     .then((res) => {
  //       if (isMounted.current) {
  //         setLoading(false);
  //         setRows(res.data.body.content);
  //         // setInitialRows(res.data.body);
  //       }
  //     })
  //     .catch((err) => {
  //       //console.log(err)
  //       setError(err);
  //       //  ToastCommon(true, "error", addToast);
  //     });
  // };

  //....reset function......//
  const resetFilters = () => {
    setReset(!reset);
    setCallResponse("");
    setApplicationStatus("");
  };

  useEffect(() => {
    // getAllRows();
    updateTargets();
  }, [employeeID]);

  /******* Use to set page number to default value of 1 when changing the employee or search advance filters  *******/
  useDidMountEffect(() => {
    setPageNumber(1);
  }, [searchAdvanced, employeeID]);
  /*******End *******/

  function sortFunction(a, b) {
    var dateA = new Date(a.auditedOn).getTime();
    var dateB = new Date(b.auditedOn).getTime();
    return dateA < dateB ? 1 : -1;
  }

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  return (
    <>
      <div className="form-inline">
        <Button className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={(e) => handleSearch(e)}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />

        <UncontrolledTooltip target="searchBar">
          Search candidates by Name/Id/Email/Phone No.
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col></Col>
              <Col></Col>
              <Col>
                <Label>Profile Status</Label>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />
                    <Badge href="#" color="warning">
                      Processing
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="danger">
                      Declined
                    </Badge>
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button color="success m-1 btn-sm" style={{ width: "70px" }}>
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={() => {
                      resetFilters();
                    }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>

      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {rows?.map((row, i) => {
        if (rows.length === i + 1) {
          return (
            <animated.div
              style={animationProps}
              ref={lastBookElementRef}
              key={row.email}
            >
              <AuditRejectedWidget key={i} userProfile={row} />
            </animated.div>
          );
        } else {
          return (
            <animated.div style={animationProps} key={row.email}>
              <AuditRejectedWidget key={i} userProfile={row} />
            </animated.div>
          );
        }
      })}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      {!loading && !error && rows.length == 0 && <NoSearchResults />}
    </>
  );
};

export default RegistrationAuditRejected;
