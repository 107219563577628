import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Row,
  Col,
} from "reactstrap";

import axios from "axios";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { HOSTMexxar } from "../../../configs/api-config";
import NoConnection from "../../errorPages/NoConnection";
import ToastCommon from "../../../components/toastCommon";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import ReferenceWidget from "../../../widgets/working-card-widget/reference-widget";
import { FunnelIcon } from "../../../assets/icons/svg";
import useWorkspaceWithFilters from "../../../customHooks/useWorkspaceWithFilters";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import { useSpring, animated } from "react-spring";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Reference = (props) => {
  const { updateTargets, employeeID, inCommingPage } = props;

  /***************** Search filter ******************/
  const [query, setQuery] = useState(null);
  const [reset, setReset] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [remember, setRemember] = useState(true); /// <--------filter settings options saving and retreving/ if need activate later with below checkbox
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchAdvanced, setSearchAdvanced] = useState(false);
  const [callResponse, setCallResponse] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");
  const [candidateStatus, setCandidateStatus] = useState("ACTIVE");
  const { addToast } = useToasts();

    let url = `${HOSTMexxar}workspace/reference-form/filter?workflow=${inCommingPage}&employeeId=${1}&serviceType=${"TEMPORARY"}`;

  // let url =`${HOSTMexxar}workspace/reference-form/filter?workflow=${inCommingPage}&employeeId=${1}&serviceType=${"TEMPORARY"}&page=1&offset=50&employeeId=1&status=ACTIVE`


  const { loading, error, rows, hasMore, totalElements } =
    useWorkspaceWithFilters(
      url,
      query,
      pageNumber,
      searchAdvanced,
      reset,
      employeeID,
      candidateStatus
    );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function handleSearch(e) {
    if (e.target.value == "") {
      setQuery(null);
    } else {
      setQuery(e.target.value);
    }
    setPageNumber(1);
  }

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  //....reset function......//
  const resetFilters = () => {
    setPageNumber(1);
    setReset(!reset);
  };

  /******* Use to set page number to default value of 1 when changing the employee or search advance filters  *******/
  useDidMountEffect(() => {
    setPageNumber(1);
    updateTargets();
  }, [searchAdvanced, employeeID]);
  /*******End *******/


  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  return (
    <>
      <div className="form-inline">
        <FontAwesomeIcon icon={faUsers} className={"ml-2 mr-3  "} />
        <Label className={"mr-3"}>
          {totalElements}{" "}
          {totalElements.length == 1 ? "Candidate" : "Candidates"}
        </Label>
        <Button className="btn bg-dark-lt text-dark" disabled={true}>
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={(e) => handleSearch(e)}
          type="text"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col></Col>

              <Col></Col>
              <Col>
                <Label>Profile Status</Label>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />
                    <Badge color="warning">processing</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge color="danger">declined</Badge>
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button color="success m-1 btn-sm" style={{ width: "70px" }}>
                    Search
                  </Button>
                  <Button color="danger m-1 btn-sm" style={{ width: "70px" }}>
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {rows?.map((row, i) => {
        if (rows.length === i + 1) {
          return (
            <animated.div
              style={animationProps}
              ref={lastBookElementRef}
              key={row.email}
            >
              <ReferenceWidget
                key={i}
                userProfile={row}
                getData1={ ()=>resetFilters()}
              ></ReferenceWidget>
            </animated.div>
          );
        } else {
          return (
            <animated.div style={animationProps} key={row.email}>
              <ReferenceWidget
                key={i}
                userProfile={row}
                getData1={ ()=>resetFilters()}
              ></ReferenceWidget>
            </animated.div>
          );
        }
      })}
      {!loading && !error && rows.length == 0 && <NoSearchResults />}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
      
    </>
  );
};

function mapStateToProps(state) {
  return {
    headhunter: state.headhunter,
  };
}

export default connect(mapStateToProps, {})(Reference);
