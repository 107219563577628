import React from "react";
import { useState } from "react";
import DOMPurify from "dompurify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export default function ThreadDetails({ threads }) {
  const [isOpen, setIsOpen] = useState(new Array(threads.length).fill(false));

  function toggleCard(index) {
    setIsOpen((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  }

  const sanitizedData = (thread) => ({
    __html: DOMPurify.sanitize(thread.body.content),
  });

  return (
    <div>
      {Array.from(threads)
        .reverse()
        .map((thread, index) => (
          <div key={index} className="card bg-secondary">
            <div
              className="card-header pointer d-flex justify-content-between mb-2"
              onClick={() => toggleCard(index)}
            >
              <span style={{ fontWeight: "bold" }}>
                {thread.sender.emailAddress.name}:
              </span>
              {/* <span>Thread {index + 1}:</span> */}
              {isOpen[index] ? (
                <FontAwesomeIcon icon={faChevronUp}></FontAwesomeIcon>
              ) : (
                <FontAwesomeIcon icon={faChevronDown}></FontAwesomeIcon>
              )}
            </div>

            {isOpen[index] && thread && thread?.body?.contentType == "html" && (
              <p
                style={{
                  backgroundColor: "white",
                  color: "inherit",
                  whiteSpace: "break-spaces",
                }}
                dangerouslySetInnerHTML={sanitizedData(thread)}
              />
            )}
            {isOpen[index] && thread && thread?.body?.contentType == "text" && (
              <pre
                style={{
                  backgroundColor: "inherit",
                  color: "inherit",
                  whiteSpace: "break-spaces",
                }}
              >
                {thread?.body?.content}
              </pre>
            )}

            {/* {isOpen[index] && (
            <div className="card-content">
              <span>{thread.bodyPreview}</span>
            </div>
          )} */}
          </div>
        ))}

      <style js>{`

    
.card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  padding: 1rem;
}

.card-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.card-content > div {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
}

.card-content > div:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.card-content span:first-child {
  font-weight: bold;
}

.card-header .material-icons {
  display: none;
}


`}</style>
    </div>
  );
}
