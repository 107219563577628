

import { CHAT_DB_POST, CHAT_ARRAY, CHAT_TAB } from "../actions/types";

const initialState = {
  chatDbPostLoad: [],
  selectedTab: "Chats"
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHAT_DB_POST:
      return {
        ...state,
        chatDbPostLoad: action.payload,
      };
    case CHAT_ARRAY:
      return {
        ...state,
        chatDbPostLoad: findChatAndReplace(state.chatDbPostLoad,action.payload),
      };
    case CHAT_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
 
    default:
     
      return state;
  }
}

const findChatAndReplace = (chatList, newChat) =>
{
  // console.log(chatList); 
  // console.log(newChat);
  let index=null;
  let arr = chatList.find((item, id)=>{
    // console.log(item.roomId);
    // console.log(newChat.roomId);
    // console.log(item.roomId==newChat.roomId);
    if(item.roomId==newChat.roomId){
      index=id;
    }
    return item.roomId==newChat.roomId;
  });

  // console.log(arr);
  if (index!=null)
    chatList.splice(index, 1);
  
  return [newChat, ...chatList];
}
