import React, { useState, useEffect } from "react";
import axios from "axios";
import filter from "lodash/filter";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";
import { HOSTMexxar, TRUSTS } from "../../../configs/api-config";
import { useToasts } from "react-toast-notifications";
import loading from "../../../../src/images/puff.svg";
import ToastCommon from "../../../components/toastCommon";
import Delete from "../../../components/Modal/ModalBody/delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import NewOneField from "../../../components/Modal/ModalBody/Admin/newOneField";
import { useForm } from "react-hook-form";
import TrustModal from "../../../components/Modal/ModalBody/Admin/trustModal";

const Trusts = () => {
  const MexxarApi = TRUSTS;

  const { register, handleSubmit, errors, reset } = useForm();

  const { addToast } = useToasts();

  const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showAddRow, setShowAddRow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [errorPostCode, setErrorPostCode] = useState(false);

  const getAllRows = () => {
    setSelectedRow(null);
    axios
      .get(MexxarApi)
      .then((res) => {
        setRows(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  // const addRow = (data) => {
  //   let duplicate_items = filter(rows, (item) => {
  //     return data.rowName == item.name;
  //   });

  //   if (duplicate_items.length > 0) {
  //     ToastCommon(true, "duplicate", addToast);
  //   } else {
  //     axios
  //       .post(MexxarApi, {
  //         name: data.rowName,
  //       })
  //       .then((res) => {
  //         if (res.data.status == "success") {
  //           getAllRows();
  //           setShowAddRow(!showAddRow);
  //           ToastCommon(true, "success", addToast);
  //         } else {
  //           ToastCommon(true, "cannot", addToast);
  //         }
  //       })
  //       .catch((err) => {
  //         //console.log(err)
  //         ToastCommon(true, "error", addToast);
  //       });
  //   }
  // };

  const deleteRow = () => {
    axios
      .delete(MexxarApi + "/" + rowID)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "delete", addToast);
          getAllRows();
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const updateRow = (data) => {
    let duplicate_items = filter(rows, (item) => {
      return data?.name == item.name;
    });

    if (duplicate_items.length > 1) {
      //length should be more than 1, hence filter capture the current trust name
      ToastCommon(true, "duplicate", addToast);
    } else {
      axios
        .put(MexxarApi, {
          id: rowID,
          name: data.name,
          houseNumber: data.houseNumber,
          streetName: data.streetName,
          city: data.city,
          state: data.state,
          postalCode: data.postalCode.code ?? data.postalCode,
          contactPersonName: data.contactPersonName,
          contactPersonDesignation: data.contactPersonDesignation,
          contactPersonEmail: data.contactPersonEmail,
          website: data.website,
        })
        .then((res) => {
          if (res.data.status == "success") {
            getAllRows();
            setShowUpdateRow(false);
            ToastCommon(true, "update", addToast);
          } else {
            ToastCommon(true, "cannot", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    }
  };

  const deleteAllRows = () => {
    selectedItems.forEach((item) => {
      axios
        .delete(MexxarApi + "/" + item)
        .then((res) => {
          getAllRows();
          setSelectedItems([]);
          setSelectAll(false);
        })
        .catch((err) => {
          //console.log(err)
        });
    });
    ToastCommon(true, "delete", addToast);
  };
  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
    } else {
      setSelectedItems([...selectedItems, rowId]);
    }
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  useEffect(() => {
    getAllRows();
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    houseNumber: "",
    streetName: "",
    city: "",
    state: "",
    postalCode: "",
    contactPersonName: "",
    contactPersonDesignation: "",
    contactPersonEmail: "",
    website: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const addRow = (data) => {
    let duplicate_items = filter(rows, (item) => {
      return data.name == item.name;
    });

    if (duplicate_items.length > 0) {
      ToastCommon(true, "duplicate", addToast);
    } else {
      axios
        .post(MexxarApi, {
          name: data.name,
          houseNumber: data.houseNumber,
          streetName: data.streetName,
          city: data.city,
          state: data.state,
          postalCode: data.postalCode,
          contactPersonName: data.contactPersonName,
          contactPersonDesignation: data.contactPersonDesignation,
          contactPersonEmail: data.contactPersonEmail,
          website: data.website,
        })
        .then((res) => {
          if (res.data.status == "success") {
            getAllRows();
            setShowAddRow(!showAddRow);
            ToastCommon(true, "success", addToast);
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    }
  };
  const onSubmit = () => {
    addRow(formData);
    setShowAddRow(false);
  };

  const [showtrustform, setTrustForm] = useState(false);
  const [selectedCounty, setSelectedCounty] = useState({
    id: "",
    value: "",
    label: "",
  });

  const checkPostalCode = (postalCode) => {
    axios.get(HOSTMexxar + "postal-codes/code/" + postalCode).then((res) => {
      if (res.data.body[0]) {
        setErrorPostCode(false);
        setSelectedCounty(res.data.body[0]?.county);
      } else {
        setErrorPostCode(true);
        setSelectedCounty("");
      }
    });
  };

  return (
    <DashboardLayout title="Trusts" subTitle="Manage Trusts " target="">
      <div className="d-flex justify-content-between p-2 ml-3">
        <div>
          {selectedItems.length > 0 ? (
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                setShowDeleteAll(true);
              }}
            >
              Delete
            </Button>
          ) : null}
        </div>
        <div>
          {" "}
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddRow(true);
            }}
          >
            Add Trust
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          {rows.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center">
              {/* Loading animation */}
              <div>
                <img src={loading} style={{ height: 100 }}></img>
              </div>
            </div>
          ) : (
            <div>
              <table
                id="datatable"
                className="table table-theme table-row v-middle dataTable no-footer"
                role="grid"
                ariaDescribedby="datatable_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      ariaSort="ascending"
                      ariaLabel="ID: activate to sort column descending"
                      style={{ width: "26px" }}
                    >
                      <label className="ui-check m-0">
                        <input
                          type="checkbox"
                          name="id"
                          checked={selectAll}
                          onChange={() => checkAll()}
                        />{" "}
                        <i></i>
                      </label>
                    </th>
                    <th
                      className="sorting_asc"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      ariaSort="ascending"
                      ariaLabel="ID: activate to sort column descending"
                      style={{ width: "26px" }}
                    >
                      <span className="text-muted">ID</span>
                    </th>
                    <th
                      className="sorting_disabled"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      ariaLabel="Owner: activate to sort column ascending"
                    >
                      <span className="text-muted">Trust</span>
                    </th>

                    <th
                      className="sorting_disabled d-flex justify-content-end mr-3"
                      rowSpan="1"
                      colSpan="1"
                      ariaLabel="Tasks"
                    >
                      <span className="text-muted d-none d-sm-block mr-3">
                        Actions
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, i) => {
                    return (
                      <tr className="odd" key={i} role="row">
                        <td
                          style={{ minWidth: "30px", textAlign: "center" }}
                          className="sorting_1"
                        >
                          <label className="ui-check m-0">
                            <input
                              type="checkbox"
                              name="id"
                              checked={isSelected(row.id)}
                              onChange={() => onItemSelect(row.id)}
                            />{" "}
                            <i></i>
                          </label>
                        </td>
                        <td
                          style={{ minWidth: "30px", textAlign: "center" }}
                          className="sorting_1"
                        >
                          <small className="text-muted">{i + 1}</small>
                        </td>

                        <td className="">{row.name}</td>

                        <td className="d-flex justify-content-end">
                          <FontAwesomeIcon
                            // color="#448bff"
                            icon={faEdit}
                            className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3"
                            onClick={() => {
                              setShowUpdateRow(true);
                              setRowID(row.id);
                              setSelectedRow(row);
                            }}
                            size="1x"
                          />
                          <FontAwesomeIcon
                            // color="#FF5603"
                            icon={faTrashAlt}
                            onClick={() => {
                              setShowDeleteRow(true);
                              setRowID(row.id);
                            }}
                            className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave "
                            size="1x"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      {/* <ModalComponent
        show={showAddRow}
        header="Add New Trust "
        closeModal={() => setShowAddRow(false)}
      >
        <NewOneField
          mode="Add"
          modalType="Trust"
          closeModal={() => setShowAddRow(false)}
          onCreate={(data) => addRow(data)}
        />
      </ModalComponent> */}
      <TrustModal
        rows={rows}
        show={showAddRow}
        mode="Add"
        onCreate={addRow}
        closeModal={() => {
          setShowAddRow(false);
          getAllRows();
          setShowAddRow(false);
        }}
        fieldData={selectedRow}
      />
      <TrustModal
        rows={rows}
        show={showUpdateRow}
        mode="Update"
        onCreate={updateRow}
        closeModal={() => {
          setShowUpdateRow(false);
          getAllRows();
          setShowAddRow(false);
        }}
        fieldData={selectedRow}
      />

    
      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteRow();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(false)}
      />
      <Delete
        show={showDeleteAll}
        isConfirm={() => {
          deleteAllRows();
          setShowDeleteAll(!showDeleteAll);
        }}
        closeModal={() => setShowDeleteAll(false)}
      />
    </DashboardLayout>
  );
};

export default Trusts;
