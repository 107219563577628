import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { result, forEach, filter } from "lodash";
import useDidMountEffect from "./intialRunUseEffect";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../toastCommon";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
import {
  REGISTER_CANDIDATE,
  WARDS,
  SHIFTS,
  CREATE_BOOKING,
  BOOKING_WORKSPACE_CANDIDATELIST,
  HOSTMexxar,
  ClientLable,
  CompanyName,
} from "../../../configs/api-config";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { sendEmail } from "../../../pages/email/graph";
import outlookLogo from "../../../../src/images/Outlook-Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import useCandidateList from "../../../customHooks/useCandidateList";

function BookACandidate(props) {
  const { register, handleSubmit, watch, errors } = useForm();

  const {
    onCreate,
    closeModal,
    candidateId,
    reset,
    currentResetState,
    dayData,
    candidateWhenClick,
    auth,
    incommingPage,
    user,
    userProfile,
  } = props;

  const MexxarApi = HOSTMexxar;
  const MexxarShift = SHIFTS;
  const MexxarApiDepartments = WARDS;
  const MexxarCandidate = REGISTER_CANDIDATE;
  const MexxarCreateBooking = CREATE_BOOKING;
  const MexxarApiBookingWorkspace = BOOKING_WORKSPACE_CANDIDATELIST;

  const { addToast } = useToasts();

  const [query, setQuery] = useState("");

  const [check, setCheck] = useState(false);
  const [shifts, setShifts] = useState(null);
  const [likeWards, setLikeWards] = useState([]);
  const [candidates, setCandidates] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [likeClients, setLikeClients] = useState([]);
  const [currentShift, setCurrentShift] = useState(0);
  const [selectedClient, setSelectedClient] = useState(0);
  const [selectedWard, setSelectedWard] = useState(0);
  const [dataErrorStatus, setDataErrorStatus] = useState(false);
  const [dateSelected, setDateSelected] = useState(null);
  const [showSpecialRates, setShowSpecialRates] = useState(false);
  const [previousBooking, setPreviousBooking] = useState(false);

  const [candidateSpecialities, setCandidateSpecialities] = useState();
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [matchingLikeWardsWithClient, setMatchingLikeWardsWithClient] =
    useState(null);
  const [selectedCandidates, setSelectedCandidates] = useState("");
  const [outlookLoggedIn, setOutlookLoggedIn] = useState(false);
  const [outlookLoggedInError, setOutlookLoggedInError] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);

  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  let url = REGISTER_CANDIDATE + "/status/" + filterCustom.value;

  const { loading, error, rows, hasMore } = useCandidateList(
    url,
    filterCustom,
    query,
    pageNumber
  );

  const onSubmit = (data) => {
    if (!dataErrorStatus) {
      addBooking(data);
    }
  };

  const addBooking = (data) => {
    let BStatus;
    check ? (BStatus = 1) : (BStatus = 0);

    let shiftStartingDate = moment(userProfile.date).format("YYYY-MM-DD");
    let shiftStartingTime = moment(userProfile.startTime, "HH:mm").format(
      "HH:mm:ss"
    );

    let shiftEndingTime = moment(userProfile.endTime, "HH:mm").format(
      "HH:mm:ss"
    );

    let shiftSTime = moment(shiftStartingDate).add(shiftStartingTime).toDate();
    let shiftETime = moment(shiftStartingDate).add(shiftEndingTime).toDate();

    let currentDate = moment(new Date()).toDate();

    let arrangData = {
      bookingDate: shiftStartingDate,
      bookingStatus: BStatus,
      bookingAttendedStatus: 4,
      createdDate: currentDate,
      referenceNo: userProfile.refNo,
      candidateId: selectedCandidates.id,
      clientsId: Number(userProfile.clients.id),
      //  employeeId: auth.item.id,
      // specialityId: Number(candidateSpecialities[0].id),
      specialityId: Number(data.specialityId),
      shiftId: Number(data.shiftId),
      // wardId: Number(userProfile.departmentId),//change later
      wardId: 1,
      shiftTo: shiftETime,
      shiftFrom: shiftSTime,
      bonus: data.bonus !== undefined ? Number(data.bonus) : 0,
      upLift: data.upLift !== undefined ? Number(data.upLift) : 0,
    };
    setDisableCreateButton(true);

    axios
      .post(MexxarCreateBooking, arrangData)
      .then((res) => {
        if (res.data.status == "success") {
          closeModal();

          //sending the confirmation email to the candidate
          if (!previousBooking) {
            // sendBookingConfirmationEmail(
            //   arrangData,
            //   selectedCandidates,
            //   selectedClient,
            //   selectedWard
            // );
          }

          ToastCommon(true, "success", addToast);
          onCreate(data);
        } else {
          ToastCommon(true, "duplicate", addToast);
          setDisableCreateButton(false);
        }
      })
      .catch((err) => {
        // setDisableCreateButton(false);
        console.log("eroor------->", err);
        ToastCommon(true, "error", addToast);
      });
  };

  const { instance, accounts } = useMsal();

  //Function : sending the confirmation email to the candidate
  const sendBookingConfirmationEmail = (
    data,
    candidateWhenClick,
    selectedClient,
    selectedWard
  ) => {
    let subject = `Booking Confirmation: ${data?.referenceNo}`;
    let body = `<p>Hello ${candidateWhenClick?.value},</p>
                <p>We are writing to confirm your booking for ${moment(
                  data?.bookingDate
                ).format(
                  "ddd, DD-MM-YYYY"
                )} with ${CompanyName}. Please find the details below:</p>
                <ul>
                    <li>Reference no: ${data?.referenceNo}</li>
                    <li>Date: ${moment(data?.bookingDate).format(
                      "ddd, DD-MM-YYYY"
                    )}</li>
                    <li>${ClientLable}: ${selectedClient?.name}</li>
                    <li>Ward: ${selectedWard?.name}</li>
                    <li>Start time: ${moment
                      .utc(data?.shiftFrom)
                      .local()
                      .format("h:mm A")}</li>
                    <li>End time: ${moment
                      .utc(data?.shiftTo)
                      .local()
                      .format("h:mm A")}</li>
                </ul>
    `;

    let to = [
      {
        id: candidateWhenClick?.id,
        value: candidateWhenClick?.email,
        label: candidateWhenClick?.value,
      },
    ];
    let cc = [];
    let bcc = [];
    let file = [];

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        sendEmail(response?.accessToken, to, cc, bcc, subject, body, file, user)
          .then((response) => {
            ToastCommon(true, "sent", addToast);
            closeModal();
          })
          .catch((error) => {});
      })
      .catch((error) => console.log(error));
  };

  const getDateField = () => {
    axios
      .get(
        MexxarApiBookingWorkspace +
          "?startDate=" +
          moment(dateSelected).format("YYYY/MM/DD") +
          "&endDate=" +
          moment(dateSelected).add(1, "days").format("YYYY/MM/DD")
      )
      .then((res) => {
        // setInitialRows(res.data.body);

        let listOfCandidates = [];
        for (let i = 0; i < res.data.body.length; i++) {
          listOfCandidates.push({
            value:
              res.data.body[i].candidate.firstName +
              " " +
              res.data.body[i].candidate.lastName,
            label:
              res.data.body[i].candidate.firstName +
              " " +
              res.data.body[i].candidate.lastName,
            id: res.data.body[i].candidate.id,
            // candidate: res.data.body[i],
          });
        }
        setCandidates(listOfCandidates);
      })
      .catch((err) => {
        //console.log(err)
      });
  };
  const getCandidateSpecialities = (candidateId) => {
    axios
      .all([
        axios.get(MexxarShift),
        axios.get(MexxarCandidate + "/" + candidateId),
      ])
      .then(
        axios.spread((res1, res2) => {
          let preferedClient = [];
          forEach(res2.data.body[0].preferredClients, function (client) {
            preferedClient.push({
              id: client.id,
              name: client.name,
            });
          });

          let specialities = [];
          forEach(
            res2.data.body[0].candidateSpecialities,
            function (speciality) {
              specialities.push({
                id: speciality.id,
                name: speciality.name,
              });
            }
          );

          // setShifts(res1.data.body);
          setLikeWards(res2.data.body[0].likeWards);
          setLikeClients(preferedClient);
          setCandidateSpecialities(specialities);
          setSelectedClient(preferedClient[0]);
        })
      )
      .catch((err) => {});
  };
  console.log("Angi selectedCandidate=====>", selectedCandidates);
  useDidMountEffect(() => {
    getCandidateSpecialities(selectedCandidates.id);
  }, [selectedCandidates]);

  const getShifts = () => {
    axios.get(MexxarShift).then((res3) => {
      setShifts(res3.data.body);
    });
  };

  const getwardsInSelectedClient = () => {
    axios
      .get(MexxarApi + "client-wards/clients/" + selectedClient.id)
      .then((res) => {
        let wardsList = [];
        forEach(res.data.body, function (ward) {
          let matchingPrefferedwards = filter(likeWards, (item) => {
            return ward?.id === item?.id;
          });

          // ...matchingPrefferedwards...
          if (matchingPrefferedwards.length > 0) {
            wardsList.push({
              id: ward.wardId,
              name: ward.wardName,
              label: ward.wardName,
            });
          }
        });

        setSelectedWard(wardsList[0]);
        setMatchingLikeWardsWithClient(wardsList);
      });
  };

  function checkOutlookLoginStatus() {
    if (accounts.length > 0) {
      // User is logged in
      setOutlookLoggedIn(true);
    } else {
      // User is not logged in
      setOutlookLoggedIn(false);
    }
  }

  const handleLoginOutLook = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        // Successful login

        if (response && response?.account) {
          setOutlookLoggedIn(true);
        } else {
          setOutlookLoggedIn(false);
          setOutlookLoggedInError(true);
        }
      })
      .catch((error) => {
        // Handle login error
        setOutlookLoggedIn(false);
        setOutlookLoggedInError(true);
      });
  };

  useEffect(() => {
    // Call the function to check the login status
    checkOutlookLoginStatus();
  }, []);

  useDidMountEffect(() => {
    getwardsInSelectedClient();
    return () => {};
  }, [selectedClient]);
  useEffect(() => {
    getDateField();
    return () => {};
  }, [dateSelected]);

  const handleInputChange = (e) => {
    setQuery(e);
  };
  const getCandidates = () => {
    let listOfCandidates = [];
    for (let i = 0; i < rows.length; i++) {
      listOfCandidates.push({
        value: rows[i].id,
        label: `${rows[i].firstName} ${rows[i].lastName}`,
        id: rows[i].id,
        candidate: rows[i],
      });
    }

    if (query != "") {
      setCandidates(listOfCandidates);
    } else {
      setCandidates([]);
    }
  };

  useEffect(() => {
    getCandidates();
    return () => {};
  }, [rows]);

  useEffect(() => {
    getShifts();
    return () => {};
  }, []);

  function checkIdExists(selectedId, array) {
    return array.some((item) => item.id === selectedId);
  }
   return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Col sm={3}>
            <Label>Tag Candidates</Label>
          </Col>
          <Col sm={9}>
            <Select
              // isMulti
              name="candidateId"
              className="basic-multi-select"
              placeholder="Search candidates ..."
              classNamePrefix="select"
              onChange={(data) => setSelectedCandidates(data)}
              isLoading={loading}
              options={candidates}
              onInputChange={handleInputChange}
              noOptionsMessage={() => "No results found"}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Candidate Specialities</Label>
          <Col sm={8}>
            <Input
              type="select"
              id="specialityId"
              name="specialityId"
              // invalid={errors.wardId}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            >
              {candidateSpecialities &&
                candidateSpecialities.map((item, id) => {
                  return (
                    <option value={item.id} key={id}>
                      {item.name}
                    </option>
                  );
                })}
            </Input>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={3}>Shift type</Label>
          <Col sm={8}>
            <Input
              type="select"
              id="shiftId"
              name="shiftId"

              value={currentShift}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
              onChange={(e) => setCurrentShift(e.target.value)}
            >
              <option value={0}>Select</option>

              <>
                {shifts &&
                  shifts.map((item, id) => {
                    return (
                      <option value={item.id} key={id}>
                        {item.name}
                      </option>
                    );
                  })}
              </>
            </Input>
            <Label className="mt-2 ml-2">
              Start Time: {userProfile?.startTime.slice(0, -3)} To End Time:{" "}
              {userProfile?.endTime.slice(0, -3)}
            </Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={3}>{ClientLable}</Label>
          <Col sm={8}>
            <Label className="mt-2">{userProfile?.clients?.name}</Label>
          </Col>
        </FormGroup>
        {/* <FormGroup row>
          <Label sm={3}>Shift Confirmation</Label>
          <Col sm={4}>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  value="NHS"
                  defaultChecked={!check}
                  onChange={changeHandler}
                />
                Confirmed
              </Label>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  value="HealthRoster"
                  onChange={changeHandler}
                />
                Unconfirmed
              </Label>
            </FormGroup>
          </Col>
        </FormGroup> */}

        {/* <FormGroup row>
          <Col sm={4}>
            <Label>Confirm Status</Label>
          </Col>
          <Col sm={4}>
            <Label>
              <Input
                type="radio"
                name="Confirmstatus"
                value={0}
                defaultChecked={
                  dayData.bookingCallStatus == "CALL_ME_BEFORE_BOOKING"
                    ? true
                    : false
                }
                innerRef={register}
                onChange={changeHandler}
              />
              Call Before Booking
            </Label>
          </Col>
          <Col sm={4}>
            <Label>
              <Input
                type="radio"
                name="Confirmstatus"
                value={1}
                defaultChecked={
                  dayData.bookingCallStatus == "DO_NOT_CALL_ME_BEFORE_BOOKING"
                    ? true
                    : false
                }
                innerRef={register}
                onChange={changeHandler}
              />
              Book Directly
            </Label>
          </Col>
        </FormGroup> */}
        {/* <FormGroup row>
          <Label sm={4}>Previous Booking</Label>
          <Col sm={4}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="previousBooking"
                  value={previousBooking}
                  onClick={() => setPreviousBooking(!previousBooking)}
                />
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>Special Booking</Label>
          <Col sm={4}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  name="type-radio"
                  // value="speacialBookings"
                  defaultChecked={showSpecialRates}
                  onClick={() => setShowSpecialRates(!showSpecialRates)}
                />
              </Label>
            </FormGroup>
          </Col>
        </FormGroup> */}

        {/* {showSpecialRates ? (
          <FormGroup row>
            <Label sm={3}>Special Rate (£)</Label>

            <Col sm={4}>
              <Input
                type="text"
                placeholder="Up lift"
                name="upLift"
                invalid={errors.upLift}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                  maxLength: {
                    value: 30,
                    message: "This field only allowed only 30 characters",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please input a number!",
                  },
                })}
              />{" "}
              {errors.upLift && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {errors.upLift?.message}
                  </li>
                </ul>
              )}
            </Col>
            <Col sm={4}>
              <Input
                type="text"
                placeholder="Bonus"
                name="bonus"
                invalid={errors.bonus}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                  maxLength: {
                    value: 30,
                    message: "This field only allowed only 30 characters",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please input a number!",
                  },
                })}
              />{" "}
              {errors.bonus && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {errors.bonus?.message}
                  </li>
                </ul>
              )}
            </Col>
          </FormGroup>
        ) : null} */}

        {!checkIdExists(userProfile?.clients?.id, likeClients) &&
          (selectedCandidates && (
            <Alert
              className="d-flex align-items-center justify-content-center flex-column "
              color="danger"
            >
              <li>
                This {ClientLable} is not assgined to selected candidate.Please select
                a different candidate to book the shift
              </li>
            </Alert>
          ))}
        {!outlookLoggedIn && (
          <Alert
            className="d-flex align-items-center justify-content-center flex-column "
            color="danger"
          >
            <p>
              To send a booking confirmation via email, kindly ensure that you
              are logged in first
            </p>
            <img src={outlookLogo} style={{ height: 100 }}></img>
            <u className="text-info pointer ml-4" onClick={handleLoginOutLook}>
              Login
            </u>
          </Alert>
        )}
        <div className="d-flex justify-content-end">
          {!previousBooking && (
            <Button
              className="mr-2"
              color="success"
              type="submit"
              disabled={
                currentShift ===0 ||
                disableCreateButton ||
                !outlookLoggedIn ||
                !checkIdExists(userProfile?.clients?.id, likeClients)
              }
            >
              Create
            </Button>
          )}
          <Button color="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.auth.item,
  };
}

export default connect(mapStateToProps, {})(BookACandidate);
