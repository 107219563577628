import React, { useState } from 'react';
import "./dropDownCss.css"

const DropDown = ({ label, children }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
   return (
    <div className="dropdown">
      <a
        href="#"
        className={`text-sm dropdown-toggle ${dropdownOpen ? 'active' : ''}`}
        onClick={toggleDropdown}
      >
        {label}
      </a>
      {dropdownOpen && <div className="d-flex flex-column">{children}</div>}
    </div>
  );
};

export default DropDown;
