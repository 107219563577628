import React, { useState } from "react";
import Select from "react-select";
import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Col,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import CreditControllVerifiedWidget from "../../widgets/working-card-widget/credit-controll-verified-widget";
import { FunnelIcon } from "../../assets/icons/svg";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((item) => {
      let searchableString = `${item.firstName} ${item.lastName} ${item.email} ${item.mobileNumber}`;
      return searchableString.includes(name);
    });
  }
  return filtered;
};

const CreditControllVerified = (props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const Hospitals = [
    { value: "Kingsmill Hospital", label: "KMH" },
    { value: "Central London", label: "CLH" },
  ];
  const Wards = [
    { value: "ITU", label: "ITU" },
    { value: " A&E", label: "A&E" },
  ];
  const ShiftTypes = [
    { value: "Day", label: "day" },
    { value: " Night", label: "Night" },
    { value: "EarlyMorning", label: "EarlyMorning" },
    { value: " LateEvening", label: "LateEvening" },
    { value: "LongDay", label: "LongDay" },
    { value: " LongNight", label: "LongNight" },
  ];

  return (
    <>
      <div className="form-inline">
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            textTransform: "lowercase",
          }}
        />
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label>Date</Label>
                <Input type="date"></Input>
              </Col>
              <Col>
                <Label>Hospital</Label>
                <Select
                  // value={candidate.specialities}
                  isMulti
                  name="hospitals"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={Hospitals}
                />
              </Col>
              <Col>
                <Label>Shift</Label>
                <Select
                  // value={candidate.specialities}
                  isMulti
                  name="DocStatus"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={ShiftTypes}
                />
              </Col>
              <Col>
                <Label>Ward</Label>
                <Select
                  // value={candidate.specialities}
                  isMulti
                  name="ShifTtype"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={Wards}
                />
              </Col>
              <Col>
                <Label>Company Payment</Label>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />
                    <Badge href="#" color="primary">
                      All
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="success">
                      Paid
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="danger">
                      Unpaid
                    </Badge>
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <Label>Hospital Proccesing</Label>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="primary">
                      All
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="success">
                      H-Cleared
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="warning">
                      H-Pending
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="info">
                      H-Processing
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="checkbox" />{" "}
                    <Badge href="#" color="danger">
                      H-Declined
                    </Badge>
                  </Label>
                </FormGroup>
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button color="success m-1 btn-sm" style={{ width: "70px" }}>
                    Search
                  </Button>
                  <Button color="danger m-1 btn-sm" style={{ width: "70px" }}>
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>

      <CreditControllVerifiedWidget></CreditControllVerifiedWidget>
    </>
  );
};

function mapStateToProps(state) {
  return {
    headhunter: state.headhunter,
  };
}

export default connect(mapStateToProps, {})(CreditControllVerified);
