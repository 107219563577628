export default function sortByCustomOrder(items, priorityItems = ["Default Item", "Special Item"], key = "wardName") {
  // Extract the priority items based on the provided priorityItems array

  console.log("angi sorted item ---->",items,priorityItems,key)
  const extractedItems = priorityItems.map((itemName) => {
    const index = items?.findIndex((item) => item?.[key] === itemName);
    return index !== -1 ? items?.splice(index, 1)[0] : null;
  }).filter(Boolean); // Remove any null values (if item not found)

  // Reinsert the extracted items at the beginning of the items array
  items?.unshift(...extractedItems);

  return items;
}
