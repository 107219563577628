import { useEffect, useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";
import moment from "moment";

export default function useBulkFetch(
  url,
  query,
  pageNumber,
  searchAdvanced,
  reset,
  status,
  type,
  getEndDate,
  getStartDate,
  search

) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setRows([]);
  }, [query]);

  useDidMountEffect(() => {
    setRows([]);
  }, [searchAdvanced,reset]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      url: url,
      params: {
        status: status === "" ? null : status,
        type: type === "" ? null : type,
        startDate: getStartDate === undefined ? null : moment(getStartDate).format("YYYY/MM/DD"),
        endDate: getEndDate === undefined ? null : moment(getEndDate).format("YYYY/MM/DD"),
        page: pageNumber,
        offset: 20,
        search: query,
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        if (res.data.status == "success") {
          setTotalElements(res.data.body.totalElements);
          // setRows((prevBooks) => {
          //   return [...new Set([...prevBooks, ...res.data.body.content])];
          // });
          setHasMore(res.data.body.content.length > 0);



          const sortedData = res.data.body.content.sort((a, b) => {
            var dateA = new Date(a.createdDateTime).getTime();
            var dateB = new Date(b.createdDateTime).getTime();
            return dateA - dateB;
          });
          console.log("sortedData",sortedData)

          setRows((prevBooks) => {
            return [...new Set([...prevBooks, ...sortedData])];
          });






        } else {
          setError(true);
        }

        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setLoading(false);
        setError(true);
      });
    return () => cancel();
  }, [searchAdvanced, query, pageNumber,reset]);

  return { loading, error, rows, hasMore, totalElements };
}
