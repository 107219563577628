import React, { useState, useEffect } from "react";
import "./dialPad.css";
import {
  faExternalLinkAlt,
  faMicrophone,
  faMicrophoneSlash,
  faPause,
  faPhone,
  faPhoneSlash,
  faPlay,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../RoundIconButton/round-icon-button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "reactstrap";
import {
  DialPadDeleteIcon,
  DialPadHangup,
  DialPadHold,
} from "../../assets/icons/svg";
import ModalComponent from "../Modal/modalComponent";

const DialPad = (props) => {
  const { applicationId ,customheaders } = props;
  const [input, setInput] = useState("");
  const [showDialPad, setShowDialPad] = useState(false);
  const [isCalling, setIsCalling] = useState(false);
  // const [callId, setCallId] = useState(false);

  const handleNumberClick = (number) => {
    setInput((prevInput) => prevInput + number);
  };

  console.log(applicationId)
  // const handleNumberClick = (number) => {
  //   const inputElement = document.querySelector('.dialPadInput');
  //   const startPos = inputElement.selectionStart;
  //   const endPos = inputElement.selectionEnd;

  //   const newInput =
  //     input.slice(0, startPos) + number + input.slice(endPos);

  //   setInput(newInput);
  // };

  // const callId = localStorage.getItem('callId');

  // const handleClearClick = () => {
  //   setInput("");
  // };
  const toggleN = () => setShowDialPad(!showDialPad);

  const headers = new Headers();
  headers.append("ApplicationId", applicationId);
  headers.append("Content-Type", "application/x-www-form-urlencoded");

  console.log("headers",headers)
  const handleOnClick = async () => {
    console.log("headers",headers)
    const params = new URLSearchParams();
    // params.append('id', 'be940a6f-74f0-48e5-9572-2a1352fdb0a0');
    params.append("destination", input);

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: params, // assuming params is a valid body
    };

    fetch("https://localhost:10443/api/v1/MakeCall", requestOptions)
      .then((response) => response.json())
      .then((res) => {
        //  setCandidateId(res.data.body);
        console.log("callID onclick",res.UniqueId);
        localStorage.setItem("callId2", res.UniqueId);
        // setCallId(res.UniqueId)
        // setCallBackShow(false);
      })
      .catch((error) => console.error("Error:", error));
  };
  const handleDeleteClick = () => {
    setInput((prevInput) => prevInput.slice(0, -1));
  };

  // const [isCalling, setIsCalling] = useState(false);
  const [hold, setHold] = useState(false);
  const [isHangup, setIsHangup] = useState(false);

  const callId2 = localStorage.getItem("callId2");

  const handleHangupCall = async () => {

    const headers = new Headers();
    headers.append("ApplicationId", applicationId);
    headers.append("Content-Type", "application/x-www-form-urlencoded");
  
    // setIsCalling(false);

    const params = new URLSearchParams();

    console.log("Call Id", callId2);
    params.append("callId", callId2);

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: params, // Convert to string
    };

    console.log("hangup", headers);

    fetch("https://localhost:10443/api/v1/Hangup", requestOptions)
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error("Error:", error));
  };

  const handleHold = async () => {
    const headers = new Headers();
    headers.append("ApplicationId", applicationId);
    headers.append("Content-Type", "application/x-www-form-urlencoded");
  
    if (!hold) {
      // Hold the call
      const params = new URLSearchParams();
      params.append("callId", callId2);
      const requestOptions = {
        method: "POST",
        headers: headers,
        body: params, // Convert to string
      };

      fetch("https://localhost:10443/api/v1/Hold", requestOptions)
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error("Error:", error));
    } else {
      // Unhold the call
      const params = new URLSearchParams();
      params.append("callId", callId2);
      const requestOptions = {
        method: "POST",
        headers: headers,
        body: params, // Convert to string
      };

      fetch("https://localhost:10443/api/v1/Unhold", requestOptions)
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error("Error:", error));
    }
    // Toggle the hold state
    setHold(!hold);
  };

  const numberButtons = [1, 2, 3, 4, 5, 6, 7, 8, 9, "*", 0, "#"];

  useEffect(() => {
    const handleKeyDown = (event) => {
      const { key } = event;
      if (/[0-9]/.test(key)) {
        handleNumberClick(key);
      } else if (key === "Backspace") {
        handleDeleteClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleClearClick = () => {
    setInput("");
  };

  // const handleDeleteClick = () => {
  //   setInput((prevInput) => {
  //     const selectionStart = document.activeElement.selectionStart;
  //     const selectionEnd = document.activeElement.selectionEnd;
  //     if (selectionStart === selectionEnd) {
  //       // If no text is selected, treat as backspace
  //       const newInput =
  //         prevInput.slice(0, selectionStart - 1) +
  //         prevInput.slice(selectionEnd);
  //       return newInput;
  //     } else {
  //       // If text is selected, delete the selected portion
  //       const newInput =
  //         prevInput.slice(0, selectionStart) + prevInput.slice(selectionEnd);
  //       return newInput;
  //     }
  //   });
  // };
  var vid = document.getElementById(callId2);

  function enableMute() {
    vid.muted = true;
  }

  function disableMute() {
    vid.muted = false;
  }

  // const CallTransfer = (callId) => {
  //   const destinationNumber = '0094702634403'; // Assuming this is a string

  //   axios
  //     .post("https://localhost:10443/api/v1/TransferConsult", {
  //       callId: callId,
  //       destination: destinationNumber,
  //     })
  //     .then((res) => {
  //       // Implement logic to handle successful response
  //       console.log("Transfer successful", res.data); // Example: Logging the response data
  //     })
  //     .catch((err) => {
  //       // Implement logic to handle errors
  //       console.error("Transfer failed", err); // Example: Logging the error
  //     });
  // };

  const CallTransfer = async () => {
    const headers = new Headers();
    headers.append("ApplicationId", applicationId);
    headers.append("Content-Type", "application/x-www-form-urlencoded");
    headers.append("Cache-Control", "no-cache");
    headers.append("Pragma", "no-cache");
  
    const params = new URLSearchParams();
    const destination = '0094702634403';
    // const headers = new Headers(); // Define headers
  
    // Assuming callId2 and applicationId are defined somewhere
    console.log("Call Id transfer", callId2);
    params.append("callId", callId2);
    params.append("destination", destination);
  
    // Check if applicationId is defined before using it in headers
    // if (applicationId) {
    //   headers.append("ApplicationId", applicationId);
    //   headers.append("Content-Type", "application/x-www-form-urlencoded");
    // }
  
    const requestOptions = {
      method: "POST",
      headers: headers,
      body: params, // Include params in the body
    };
  
    console.log("transfer", headers);
  
    try {
      const response = await fetch("https://localhost:10443/api/v1/TransferConsult", requestOptions);
      const data = await response.json();
      console.log(data); // Log the response data
    } catch (error) {
      console.error("Error:", error);
    }
  };
  
  
 

  return (
    <div className="dial-pad">
      <div className="dialInputArea">
        <input
          className="dialPadInput"
          type="text"
          value={input}
          autoFocus
          // onKeyDown={(e) => {
          //   if (e.key === "Backspace") {
          //     // handleDeleteClick();
          //   } else if (/^[0-9]$/.test(e.key)) {
          //     e.preventDefault();
          //     // handleNumberClick(e.key);
          //   }
          // }}
          // onChange={(e) => setInput(e.target.value)}
        />


      </div>

      <div className="number-buttons  ">
        {numberButtons.map((number, index) => (
          <button
            key={index}
            className={`number-button`}
            onClick={() => {
              handleNumberClick(number.toString());
            }}
          >
            {number}
          </button>
        ))}
      </div>
      <Row className="mt-3 align-items-center">
        <Col md="2" className="ml-2">
          <RoundIconButton
            buttonColor="#f31f7f"
            icon={faPhoneSlash}
            buttonSize="btn-md"
            click={() => handleHangupCall()}
          ></RoundIconButton>
        </Col>

        <Col md="2" className="ml-2">
          <RoundIconButton
            buttonColor={`${!hold ? "#f31f7f" : "#31c971"}`}
            icon={hold ? faPlay : faPause}
            iconSize="fa-md"
            buttonSize="btn-md"
            click={() => handleHold()}
          />
        </Col>

        <Col md="2" className="ml-2">
          <RoundIconButton
            buttonColor={`${"#31c971"}`}
            icon={faPhone}
            iconSize="fa-md"
            buttonSize="btn-md"
            click={() => {
              handleOnClick();
              // openSmallWindow();
            }}
          />
        </Col>

        <Col md="2" className="ml-2">
        <RoundIconButton
                      buttonColor="#458AF7"
                      icon={faExternalLinkAlt}
                      iconSize="fa-md"
                      buttonSize="btn-md"
                      click={() => CallTransfer() // CallTransfer function is invoked here
                       
                      }
                    ></RoundIconButton>
                  </Col>  
        <Col md="2" className="ml-2">
          <RoundIconButton
            buttonColor={`${"#f31f7f"}`}
            iconSize="fa-md"
            buttonSize="btn-md"
            click={() => {
              handleDeleteClick();
              // openSmallWindow();
            }}
          >
            <DialPadDeleteIcon />
          </RoundIconButton>
        </Col>
      </Row>

      <ModalComponent
        size="sm"
        show={showDialPad}
        // header="Dial Pad"
        closeModal={() => toggleN()}
      >
        <DialPad></DialPad>
      </ModalComponent>
    </div>
  );
};

export default DialPad;
