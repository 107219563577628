import React, { useState } from "react";
import { connect } from "react-redux";
import ChatUsers from "./components/chatUsers";
import CreateChat from "./components/createChat";
import { chatTabAction } from "../../redux/actions/chatDbPostAction";
import TabNavigationMenu from "../../util/common/tabNavigation";

const ChatTabs = (props) => {
  // const [currentTab, setCurrentTab] = useState("timesheetsReview");
  const [chatScriptIdfromChatJS, setChatScriptIdfromChatJS] = useState([]);
  const chatscriptIdHandler = (childData) => {
    setChatScriptIdfromChatJS(childData);
    // console.log(chatScriptIdfromChatJS);
  };

  const switchTabHandler = (tab) => {
    props.chatTabAction(tab);
  };

  return (
    <>
      <TabNavigationMenu
        activated={props.selectedTab}
        onChangeItem={switchTabHandler}
        itemList={[
          { name: "Chats", key: "Chats" },
          { name: "Contacts", key: "Contacts" },
        ]}
      />

      {props.selectedTab === "Chats" && (
        <ChatUsers chatScriptIdCallBack={chatscriptIdHandler} />
      )}

      {props.selectedTab === "Contacts" && (
        <CreateChat chatScriptIdCallBack={chatscriptIdHandler} />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    selectedTab: state.chatDbPostReducer.selectedTab,
  };
}

// function mapDispactchToProps(dispatch){
//   return {
//     changeTab: chatTabAction
//   }
// }

export default connect(mapStateToProps, { chatTabAction })(ChatTabs);
