import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
  Col,
} from "reactstrap";

const AddNoteModal = ({ isOpen, toggle, onSave, important = false }) => {
  const [note, setNote] = useState("");
  const [isImportant, setIsImportant] = useState(important);
  const handleSave = () => {
    onSave({ note, isImportant });
    setNote("");
    setIsImportant(false);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="modal-header bg-primary" toggle={toggle}>
        Add Note
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup row>
            <Col sm={12}>
              <Label for="note">Note:</Label>
              <Input
                type="textarea"
                name="note"
                id="note"
                placeholder="Enter your note here"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col className="ml-4">
              <Label check>
                <Input
                  type="checkbox"
                  disabled={important}
                  checked={isImportant}
                  onChange={(e) => setIsImportant(e.target.checked)}
                />
                Mark as Important
              </Label>
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="success" onClick={() => {
          handleSave();
          toggle();
        }}>
          Save
        </Button>
        <Button color="secondary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddNoteModal;
