import { STORE_SOFTPHONE_WEBSOCKET_DATA } from "../actions/types";

const initialState = {
  websocketData: false,
};

export default function reducers(state = initialState, action) {
  switch (action.type) {
    case STORE_SOFTPHONE_WEBSOCKET_DATA:
      console.log("softphone data", action.payload);
      return {
        ...state,
        websocketData: action.payload,
      };
    default:
      return state;
  }
};
