import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: "2aa83f48-3489-4817-91f4-27bcf7de213c",
    authority: "https://login.microsoftonline.com/common/",
    redirectUri: "/dashboard/outlook/loginPage",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
  eventCallbacks: {
    loginSuccess: (response) => {
      localStorage.setItem("outlookLoggedInAngi", "true");
    },
    logoutSuccess: () => {
      localStorage.setItem("outlookLoggedInAngi", "false");
    },
  },
};

export const loginRequest = {
  scopes: [
    "User.Read",
    "mail.send",
    "mail.read",
    "Mail.ReadWrite",
    "Mail.Send",
    "offline_access",
  ],
};

export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
  sendEmailMeEndpoint: "https://graph.microsoft.com/v1.0/me/sendMail",
  getMessagesMeEndpoint: "https://graph.microsoft.com/v1.0/me/messages?$top=8",
  getMailFoldersEndpoint: "https://graph.microsoft.com/v1.0/me/mailFolders",
};
