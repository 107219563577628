import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const RoundIconButton = (props) => {
  const {
    icon,
    buttonColor = "#000000",
    iconColor = "#FFFFFF",
    iconSize = "fa-sm",
    buttonSize = "btn-md",
    click,
    disabled,
    children
  } = props;
  return (
    <button
      disabled={disabled}
      style={{ backgroundColor: buttonColor }}
      className={`btn btn-raised btn-wave btn-icon btn-rounded mb-2 light-green text-white  ${buttonSize}`}
      onClick={click}
    >
      {icon ? (
        <FontAwesomeIcon
          color={iconColor}
          icon={icon}
          className={` ${iconSize}`}
        />
      ) : (
         children 
      )}
    </button>
  );
};
export default RoundIconButton;
