import React, { useState, useEffect } from "react";
import "./working-card-widget.style.css";
import {
  Badge,
  Card,
  CardBody,
  Button,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import logo from "../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

import {
  faMars,
  faVenus,
  faPhone,
  faEnvelope,
  faCalendar,
  faPhoneSlash,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import ModalComponent from "../../components/Modal/modalComponent";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import {
  HOSTMexxar,
  MAINTENANCE_WORKSPACE_DATA,
  RESCHEDULE,
  HostMexxarURL,
} from "../../configs/api-config";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

import {
  ApplicationStatus,
  GenderSwitch,
  ConversationResponse,
} from "../../util/common";
import AvatarMexxar from "../../util/common/avatarMexxar";

const WorkingCardWidgetMaintenance = (props) => {
  const {
    userProfile,
    tab,
    auth,
    isSelected,
    onItemSelect,
    selectAll,
    employeeID,
    employeeName,
  } = props;

  const MexxarApi = MAINTENANCE_WORKSPACE_DATA;
  const MexxarHostAPI = HOSTMexxar;
  const MexxarUrl = HostMexxarURL;
  const MexxarUrlHost = HOSTMexxar;
  const MexxarApiReschedule = RESCHEDULE;
  // const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState(false);
  const [bookingConsultants, setBookingConsultants] = useState([]);

  const { addToast } = useToasts();

  const [calendarShow, setCalendarShow] = useState(false);

  const [showEmailModal, setShowEmailModal] = useState(false);

  const toggleShowEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };
  const [profilePicture, setProfilePicture] = useState();

  const toggleM = () => setShowEmailModal(!showEmailModal);

  // const loadCandidateData = () => {
  //   axios
  //     .get(MexxarHostAPI + "candidates/" + userProfile.candidateId)
  //     .then((res) => {
  //       console.log("maintenance workspace data", res.data.body[0].bookings);

  //       setBookingConsultants(res.data.body[0].bookings);
  //       if (
  //         res.data.body[0].avatar == null ||
  //         res.data.body[0].avatar == undefined ||
  //         res.data.body[0].avatar == ""
  //       ) {
  //         res.data.body[0].gender === "MALE"
  //           ? setProfilePicture(
  //               "http://207.246.85.110/images/maleCandidate.png"
  //             )
  //           : setProfilePicture(
  //               "http://207.246.85.110/images/femaleCandidate.png"
  //             );
  //       } else {
  //         const config = { responseType: "blob" };
  //         axios
  //           .get(
  //             MexxarHostAPI +
  //               String(res.data.body[0].avatar).replace("pro_pic", "pro-pic"),
  //             config
  //           )
  //           .then((file) => {
  //             getBase64(new File([file.data], "sample"), (result) => {
  //               setProfilePicture(result);
  //             });
  //           });
  //       }
  //     });
  // };
  // function getBase64(file, cb) {
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = function () {
  //     cb(reader.result);
  //   };
  //   reader.onerror = function (error) {
  //     console.log("Error: ", error);
  //   };
  // }
  const resheduleCall = (data) => {
    let rescheduleDate = moment(data.date).add(data.time).toISOString();
    axios
      .post(MexxarApiReschedule, {
        callerId: auth.item.id,
        receiverId: userProfile.candidateId,
        callDate: moment(new Date()).toISOString(),
        rescheduleDate: rescheduleDate,
      })
      .then((res) => {
        //  setCandidateId(res.data.body);
        ToastCommon(true, "success", addToast);
        setCalendarShow(!calendarShow);
      })
      .catch((err) => {
        //console.log("erorrrrrrrrrrrrrrrr", err);
        ToastCommon(true, "error", addToast);
      });
  };

  // useEffect(() => {
  //   loadCandidateData();
  // }, []);
 


const dbcCheckStatus = userProfile?.complianceDbsCheck?.dbsCheckStatus;
const nmcSendStatus = userProfile?.complianceNmcCheck?.nmcSendStatus;
const hcpcCheckStatus = userProfile?.complianceHcpcCheck?.hcpcCheckStatus;


const submittedStatuses = ["BLANK_NO_NEW_INFO", "NON_BLANK_NO_NEW_INFO", "NEW_INFO", "REGISTERED", "SUBMITTED"];
const invalidStatuses = ["INVALID", "NOT_FOUND"];


const isDbsSubmitted = submittedStatuses.includes(dbcCheckStatus);
const isNmcSubmitted = submittedStatuses.includes(nmcSendStatus);
const isHcpcSubmitted = submittedStatuses.includes(hcpcCheckStatus);


const invalidDocuments = [
  { label: "DBS", status: dbcCheckStatus },
  { label: "NMC", status: nmcSendStatus },
  { label: "HCPC", status: hcpcCheckStatus }
].filter(item => invalidStatuses.includes(item.status));


const hasDbs = isDbsSubmitted; 
const hasNmcOrHcpc = isNmcSubmitted || isHcpcSubmitted;
const isAllSubmitted = hasDbs && hasNmcOrHcpc;
  return (
    <>
      {/* <div
      className=" card d-flex flex-row main bg-light p-1 "
      style={{ minWidth: "900px", marginBottom: "5px" }}
    > */}

      <div
        className="card p-1 d-flex flex-column bg-light "
        style={{ minWidth: "900px", marginBottom: "5px" }}
      >
        <div className={`d-flex  `}>
          {!selectAll ? (
            <div className="d-flex align-items-center mr-3 ">
              <span className="mx-2">
                {/* <b className="badge badge-circle sm text-primary"></b> */}
                <label className="ui-check m-0">
                  <input
                    type="checkbox"
                    name="id"
                    checked={isSelected(userProfile.candidateId)}
                    onChange={() => onItemSelect(userProfile.candidateId)}
                  />{" "}
                  <i></i>
                </label>
              </span>
            </div>
          ) : null}

          <AvatarMexxar
            candidateId={userProfile?.candidateId}
            primaryPhone={userProfile?.primaryPhone}
            avatar={userProfile?.proPic}
            gender={userProfile?.gender}
          />

          <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
            <div
              className="d-flex ml-1 mb-1"
              id={`candidateID${userProfile.candidateId}`}
            >
              <Label>
                {userProfile.candidateFirstName}&nbsp;
                {userProfile.candidateLastName}
              </Label>
            </div>
            <UncontrolledTooltip
              className="d-flex flex-column justify-content-start align-items-center p-2"
              target={`candidateID${userProfile.candidateId}`}
            >
              <div className="d-flex border-bottom mb-2 "> Show more</div>
              <span className="d-flex">
                ID:&nbsp;
                <Badge className="p-1 m-0">{userProfile.candidateId}</Badge>
              </span>
              <div>
                <Label className="text-left">
                  Full name: {userProfile.candidateFirstName}&nbsp;
                  {userProfile.candidateSecondName}
                  &nbsp;
                  {userProfile.candidateLastName}
                </Label>
              </div>
            </UncontrolledTooltip>
            <div className=" d-flex ">
              <div className="mx-2 ">
                {/* <Link
                    to={{
                      pathname: "/dashboard/call/"+userProfile.candidateId+"/"+userProfile.primaryPhone,
                      aboutParams: {
                        imageLink: userProfile.image,
                      },
                    }} */}
                <Link to={"/dashboard/call/" + userProfile.candidateId}>
                  <RoundIconButton
                    buttonColor="#00C426"
                    icon={faPhone}
                    iconSize="fa-md"
                    buttonSize="25px"
                  ></RoundIconButton>
                </Link>
              </div>
              <div className="mx-2 ">
                <RoundIconButton
                  buttonColor="#458AF7"
                  icon={faEnvelope}
                  buttonSize="25px"
                  click={() => {
                    toggleShowEmailModal();
                  }}
                ></RoundIconButton>
              </div>
              <div className=" mx-2  d-flex align-items-center ">
                <FontAwesomeIcon
                  color="#FF5603"
                  icon={faCalendarAlt}
                  onClick={() => {
                    setCalendarShow(!calendarShow);
                    setRowID(userProfile.candidateId);
                  }}
                  className="fa-lg mb-2 btn-raised btn-wave "
                />
              </div>
            </div>
            {/* <div className="mx-2 ">
         <span className="badge badge-success  text-uppercase" >{auth?.item?.firstName} {auth?.item?.lastName}</span>
          </div> */}
          </div>

          <div className="d-flex  align-items-center" style={{ flex: 1 }}>
            <FontAwesomeIcon
              // color={userProfile.gender == "1" ? "#458AF7" : "#f54394"}
              color={GenderSwitch(userProfile.gender).genderColor}
              // icon={userProfile.gender == "1" ? faMars : faVenus}
              icon={GenderSwitch(userProfile.gender).genderSymbol}
            />
          </div>

          <div
            className="d-flex align-items-center justify-content-center flex-column"
            style={{ flex: 3 }}
          >
            <div style={{ height: "3vh", flex: 1 }}>Call Response</div>
            <div style={{ height: "3vh", flex: 1 }}>
              <Badge
                color={
                  ConversationResponse(userProfile.candidateCallResponseStatus)
                    .badgeColor
                }
              >
                {
                  ConversationResponse(userProfile.candidateCallResponseStatus)
                    .badgeLable
                }
              </Badge>
              
            </div>
         
        {!isAllSubmitted && (
        <div className="mt-3">
          <Badge color="danger">Documents Missing</Badge>
        </div>
      )}
         {/* {invalidDocuments.length > 0 && (
        <div className="mt-3">
          <Badge color="warning">
            {invalidDocuments.map(doc => `${doc.label} ${doc.status}`).join(', ')}
          </Badge>
        </div>
      )}   */}
{/* 
<div className="mt-3">
        <p><strong>DBS Check Status:</strong> {dbcCheckStatus || 'Not provided'}</p>
        <p><strong>NMC Check Status:</strong> {nmcSendStatus || 'Not provided'}</p>
        <p><strong>HCPC Check Status:</strong> {hcpcCheckStatus || 'Not provided'}</p>
      </div> */}
          </div>
          <div className="d-flex  align-items-center" style={{ flex: 2 }}></div>
          <div className="d-flex  align-items-center" style={{ flex: 3 }}>
            <div style={{ height: "7vh", flex: 1 }}>
              <div className="flex-row">
                <div>
                  <span
                    style={{ marginLeft: "5px" }}
                    className="badge badge-circle text-danger mr-1"
                    data-original-title="Required"
                    data-placement="top"
                    data-toggle="tooltip"
                  ></span>
                  Expired {userProfile.expiredDocCount}
                </div>
                <div>
                  <span
                    style={{ marginLeft: "5px" }}
                    className="badge badge-circle text-warning mr-1"
                    data-original-title="Required"
                    data-placement="top"
                    data-toggle="tooltip"
                  ></span>
                  Expiring {userProfile.expiringDocCount}
                </div>
                <div></div>
              </div>
            </div>
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ height: "4vh", flex: 1 }}></div>
            <div style={{ height: "3vh", flex: 1 }}>
              {bookingConsultants.length < 0 ? (
                <Badge color="warning">ABC</Badge>
              ) : (
                ""
              )}
            </div>
            <div style={{ height: "3vh", flex: 1 }}></div>
          </div>
          <div style={{ flex: 4 }}>
            {userProfile.lastContactedUserName === null ? null : (
              <div className="d-flex align-items-center flex-column ">
                <div style={{ height: "4vh" }}>Last Contacted By</div>

                <div style={{ height: "3vh" }}>
                  {userProfile.lastContactedUserName}
                </div>

                <div className="d-flex justify-content-center ">
                  <span className="mr-1">
                    <small className="text-muted">
                      {moment(userProfile.lastContactedDate).format(
                        "ddd MMM DD YYYY"
                      )}
                    </small>
                  </span>
                  <span className="mr-1">
                    <small className="text-muted">
                      {" "}
                      {moment(userProfile.lastContactedDate).format("hh:mm a")}
                    </small>
                  </span>
                </div>
              </div>
            )}
          </div>

          <div style={{ flex: 4 }}>
            <div
              className="d-flex justify-content-center mb-1 "
              style={{ maxHeight: "6vh" }}
            >
              Number of Calls
            </div>
            <div
              className=" d-flex justify-content-around "
              style={{ height: "3vh" }}
            >
              <div>
                <FontAwesomeIcon
                  color="#00C426"
                  icon={faPhone}
                  className="fa-flip-horizontal"
                />
              </div>
              <div>
                <FontAwesomeIcon
                  color="#F80055"
                  icon={faPhoneSlash}
                  className="fa-flip-horizontal"
                />
              </div>
            </div>
            <div
              className="d-flex justify-content-around"
              style={{ height: "3vh" }}
            >
              <div>{userProfile.answeredCount}</div>
              <div>{userProfile.notAnsweredCount}</div>
            </div>
          </div>

          <div
            className={`modal fade bd-example-modal-sm  ${
              calendarShow ? "show" : ""
            }`}
            id="exampleModalCenter"
            //  tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            style={{ display: `${calendarShow ? "block" : "none"}` }}
            aria-hidden={!calendarShow}
          ></div>
        </div>
        {console.log(" headhunter details---?", props)}
{/* 
        <div
          className="mx-2  "
          style={{
            width: "100%",
            overflowX: "auto",
            whiteSpace: "nowrap",
            paddingRight: "16px",
          }}
        >
          <span
            className="badge badge-success text-uppercase mr-auto"
            style={{ marginLeft: "34px" }}
          >
            {userProfile?.maintains?.map((maintains, index) => (
              <div key={index}>{maintains.usersName}</div>
            ))}
          </span>
        </div> */}

<div className="mx-2 mr-12" style={{ width: '100%', overflowX: 'auto', whiteSpace: 'nowrap', paddingRight: '16px' }}>

  {userProfile?.maintains?.map((maintains, index) => (
    <span key={index} className="badge badge-success text-uppercase mr-1">
      {maintains.usersName}
    </span>
  ))}
</div>

        <ModalComponent show={calendarShow} header="Reschedule a call">
          <SmallCalendar
            onCreate={(data) => resheduleCall(data)}
            closeModal={() => setCalendarShow(!calendarShow)}
          />
        </ModalComponent>

        <ModalComponent show={showEmailModal} header="E mail and sms">
          <EmailNav closeModal={() => toggleM()} user={userProfile} />
        </ModalComponent>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(WorkingCardWidgetMaintenance);
