import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import {
    CANDIDATE_SPECIALITY,
    TRAININGPROVIDERS,
    ClientLable,
    HOSTMexxar,
} from "../../../../configs/api-config";
import { Alert, Button, Form, Input, UncontrolledTooltip } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import HospitalsMap from "../../../../widgets/hospitalsMap/hospitalsMap";
import HospitalsMapOnboarding from "../../../../widgets/hospitalsMap/hospitalMapOnboarding";

const AddMap = (props) => {
    const MexxarAPI = HOSTMexxar;

    const { register, handleSubmit, errors } = useForm();
    const { initialDataGetReducer, defaultPostalCode = "", mode } = props;

    const [clientMapStatus, setClientMapStatus] = useState(false);

    const [input, setInput] = useState(null);
    const [postalCode, setPostalCode] = useState(null);

    const getPostalCodeLongLati = (defaultPostalCode) => {
        if (defaultPostalCode) {
            axios
                .get(MexxarAPI + "postal-codes/code/" + defaultPostalCode)
                .then((res) => {
                    setPostalCode(res.data.body[0]);
                });
        }
    };

    useEffect(() => {
        if (defaultPostalCode) {
            getPostalCodeLongLati(defaultPostalCode);
        }
    }, [defaultPostalCode]);

    return (
        <Form>
            <div></div>
            <div className="row">
                <label className="col-6 col-md-3 col-sm-3">Postal code</label>
                <div className="col-md-5">
                    <div className="row">
                        <div className="col-md-8">
                            <Input
                                type="text"
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                defaultValue={defaultPostalCode}
                            />
                        </div>
                        <div className="col-md-4">
                            <Button color="success" onClick={() => getPostalCodeLongLati(input)}>
                                Search
                            </Button>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <div className="row">
                <div className="col-12">
                    <HospitalsMapOnboarding
                        mode="default"
                        candidateId={1}
                        enable={true}
                        postalCode={postalCode != null ? postalCode : postalCode}
                    ></HospitalsMapOnboarding>
                </div>
            </div>
        </Form>
    );
};

function mapStateToProps(state) {
    return {
        initialDataGetReducer: state?.initialDataGetReducer?.employees,
    };
}

export default connect(mapStateToProps, {})(AddMap);
