import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faTimes,
  faExpandAlt,
  faFileAlt,
  faTimesCircle,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import store from "../../../../redux/store";
import { DateAndTime } from "../ListofMails";
import RichTextEditor from "./RichtextEditor";
import { useDropzone } from "react-dropzone";
import { split, forEach, filter } from "lodash";
import CreatableSelect from "react-select/creatable";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../../components/toastCommon";
import {
  REGISTER_CANDIDATE,
  HostMexxarEmail,
} from "../../../../configs/api-config";
import useCandidateList from "../../../../customHooks/useCandidateList";
import ModalComponent from "../../../../components/Modal/modalComponent";
import usePrepareEmployee from "../../../../customHooks/usePrepareEmployee";
import { Button, Label, FormGroup, Col, UncontrolledTooltip } from "reactstrap";

function EmailForm(props) {
  const {
    showComposeModal,
    composeType,
    emailServerReducer,
    imageLink,
    from,
    dateAndTime,
    subjectIncomming,
    cc,
    bcc,
    type,
    credentials,
    emailBody,
  } = props;

  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
    padding: 5,
  };
  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const thumb = {
    position: "relative",
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbButton = {
    position: "absolute",
    right: 10,
    bottom: 10,
    background: "rgba(0,0,0,.8)",
    color: "#fff",
    border: 0,
    borderRadius: ".325em",
    cursor: "pointer",
  };

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();
  const { employees } = usePrepareEmployee();

  const [files, setFiles] = useState([]);
  const [sending, setSending] = useState(false);
  const [subject, setSubject] = useState("");
  const [heading, setHeading] = useState("");
  const [bodyTags, setBodyTags] = useState("");
  const [toggleCC, setToggleCC] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const [toggleBCC, setToggleBCC] = useState(false);
  const [employeeRecipients, setEmployeeRecipients] = useState([]);
  const [candidateRecipients, setCandidateRecipients] = useState([]);
  const [uploadAcceptedFiles, setUploadAcceptedFiles] = useState([]);

  const [fileOversize, setFileOversize] = useState(false);
  const [fileTypeofUpload, setFileTypeofUpload] = useState("");
  const [selectedBccEmail, setSelectedBccEmail] = useState(null);
  const [showHideFileUpload, setShowHideFileUpload] = useState(false);
  const [selectedCCandidates, setSelectedCCandidates] = useState(null);

  const subjectHeadingPlacing = (type) => {
    switch (type) {
      case "NEW":
        setSubject("");
        setHeading("New Message");
        setBodyTags("");
        break;
      case "FORWARD":
        setSubject("Fwd:");
        setHeading("Fwd:");
        setBodyTags(
          `<br/>
        <br/>
        <p> ---------- Forwarded message ---------<br>
        From:${from}<br>
        Date:${new Date(moment(dateAndTime).format("LLLL"))}<br>
        Subject:${subjectIncomming}<br>
       To:${credentials.email} </p> `
        );
        break;
      case "REPLY":
        setSubject("Re:");
        setHeading("Re:");
        setBodyTags(
          `
          <br/>
          <br/> 
         <p>On ${new Date(
           moment(dateAndTime).format("LLLL")
         )} from ${from} "wrote:"</p> `
        );

        break;

      default:
        break;
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    let splitedArray = split(acceptedFiles[0].type, "/");
    let document_TYPE = splitedArray[1];
    setFileTypeofUpload(document_TYPE);

    let totalSize = 0;
    forEach(acceptedFiles, function (file) {
      totalSize = file.size + totalSize;
    });
    // files over 50 mb (Total file size)cannot upload
    if (totalSize > 50000000) {
      setFileOversize(true);
    } else {
      setFileOversize(false);
    }

    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  const clearFilesStates = () => {
    setFiles([]);
    setShowHideFileUpload(false);
  };

  const sendEmail = (data) => {
    let recipients = [];
    let fromEmailSplit = split(from, /[<>]/);
    // let fromEmail = fromEmailSplit[1];

    if (composeType == "REPLY") {
      let replyRecipient = [{ value: fromEmailSplit }];

      recipients = employeeRecipients
        .concat(candidateRecipients)
        .concat(replyRecipient);
    } else {
      recipients = employeeRecipients.concat(candidateRecipients);
    }

    let recipientsList = [];
    if (recipients.length > 0) {
      for (let i = 0; i < recipients.length; i++) {
        recipientsList.push(recipients[i].value);
      }
    }

    let ccEmailList = [];
    if (selectedCCandidates) {
      for (let i = 0; i < selectedCCandidates.length; i++) {
        ccEmailList.push(selectedCCandidates[i].value);
      }
    }

    let bccEmail = [];
    if (selectedBccEmail) {
      for (let i = 0; i < selectedBccEmail.length; i++) {
        bccEmail.push(selectedBccEmail[i].value);
      }
    }

    let prepareData = {
      username: store.getState().emailServerReducer.credentials.email,
      password: store.getState().emailServerReducer.credentials.password,
      subject: subject,
      body: data.body,
      mailto: recipientsList,
      mailcc: ccEmailList,
      mailbcc: bccEmail,
    };

    const fd = new FormData();

    fd.append(
      "username",
      store.getState().emailServerReducer.credentials.email
    );
    fd.append(
      "password",
      store.getState().emailServerReducer.credentials.password
    );
    fd.append("subject", subject);
    fd.append("body", data.body);
    fd.append("mailto", recipientsList);
    fd.append("mailcc", ccEmailList);
    fd.append("mailbcc", bccEmail);

    //    fd.append("mailBody", prepareData);

    // fd.append(
    //   "mailBody",
    //   new Blob([JSON.stringify(prepareData)], {
    //     type: "application/json",
    //   })
    // );

    if (uploadAcceptedFiles.length > 0) {
      forEach(uploadAcceptedFiles, function (file) {
        fd.append("attachment", file);
      });
    }

    setSending(true);

    axios
      .post(HostMexxarEmail + "email/newmail", fd)
      .then(function (response) {
        if (response.data.status === "success") {
          ToastCommon(true, "sent", addToast);
          clearFilesStates();
          showComposeModal(false);
        }
        setSending(false);
      })
      .catch(function (error) {
        setSending(false);

        ToastCommon(true, "error", addToast);
        console.log("error", error);
      });
  };

  const thumbs = (file, id) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img src={file.preview} style={img} alt="" />
      </div>
    </div>
  );

  /*get candiates list */

  const MexxarCandidateApi = REGISTER_CANDIDATE;

  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  const [candidates, setCandidates] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [queryCandidate, setQueryCandidate] = useState("");

  let url = MexxarCandidateApi + "/status/" + filterCustom.value;

  const { loading, error, rows, hasMore } = useCandidateList(
    url,
    filterCustom,
    queryCandidate,
    pageNumber
  );

  const getCandidates = () => {
    let listOfCandidates = [];
    for (let i = 0; i < rows.length; i++) {
      listOfCandidates.push({
        value: rows[i].email,
        label: `${rows[i].firstName} ${rows[i].lastName}`,
        id: rows[i].id,
        // candidate: rows[i],
      });
    }

    if (queryCandidate != "") {
      setCandidates(listOfCandidates);
    } else {
      setCandidates([]);
    }
  };

  useEffect(() => {
    getCandidates();
  }, [rows]);

  const handleInputChange = (e) => {
    setQueryCandidate(e);
  };

  /*remove uploded files function*/
  const removeFiles = (name) => {
    let newUplofilesads = filter(uploadAcceptedFiles, (file) => {
      return file.name !== name;
    });

    setUploadAcceptedFiles(newUplofilesads);
  };

  const uploadFiles = () => {
    let temp_1 = uploadAcceptedFiles;
    setUploadAcceptedFiles((oldArray) => [...files, ...temp_1]);
  };

  useEffect(() => {
    subjectHeadingPlacing(composeType);
  }, []);

  return (
    <div className="card aside-deck mb-1">
      <div
        className={` alert p-0 m-0 b-a ${minimize && "card-hide-body"}`}
        style={{
          width: minimize && "300px",
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <div className="card-header d-flex align-items-center bg-body">
          <div className="flex h-1x mx-1 mail-to">{heading}</div>
          <div className="ml-auto no-wrap">
            {minimize ? (
              <FontAwesomeIcon
                className="btn-raised mx-2 pointer"
                onClick={() => setMinimize(!minimize)}
                icon={faExpandAlt}
              />
            ) : (
              <FontAwesomeIcon
                className="btn-raised mx-2 pointer"
                onClick={() => setMinimize(!minimize)}
                icon={faMinus}
              />
            )}
            <FontAwesomeIcon
              className="btn-raised btn-wave mx-1 pointer"
              onClick={() => showComposeModal(false)}
              icon={faTimes}
            />{" "}
          </div>
        </div>

        <div
          className="card-body flex-container wrap "
          style={{
            backgroundColor: "whitesmoke",
            // height: "70vh",
            width: "50vw",
          }}
        >
          <div>
            <div className="mb-2  ">
              <CreatableSelect
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                placeholder="Consultant Recipients"
                isClearable
                isMulti
                options={employees}
                onChange={(data) => setEmployeeRecipients(data)}
              />
            </div>
            <div className="mb-2  ">
              <CreatableSelect
                id="CandidateRecipients"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                placeholder="Candidate Recipients"
                onInputChange={handleInputChange}
                onChange={(data) => setCandidateRecipients(data)}
                isLoading={loading}
                options={candidates}
                isClearable
                isMulti
              />
              <UncontrolledTooltip target="CandidateRecipients">
                Please type the name of the candidate
              </UncontrolledTooltip>
            </div>
            <div className=" mb-2 d-flex flex-row">
              <input
                type="text"
                className="form-control text-dark mr-2"
                placeholder="Subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />

              <Button
                onClick={() => setToggleCC(!toggleCC)}
                className="mx-2"
                style={{ width: "70px" }}
              >
                {selectedCCandidates && selectedCCandidates.length} CC
              </Button>
              <Button
                onClick={() => setToggleBCC(!toggleBCC)}
                style={{ width: "70px" }}
              >
                {selectedBccEmail && selectedBccEmail.length} BCC
              </Button>
            </div>

            {composeType == "REPLY" && <Label>Reply To : {from}</Label>}
            {toggleCC && (
              <div className="form-row">
                <CreatableSelect
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  type="select"
                  isMulti
                  isClearable
                  placeholder="CC"
                  name="ccEmail"
                  className="col-12"
                  onChange={(data) => setSelectedCCandidates(data)}
                  options={employees}
                  innerRef={register({
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email address!",
                    },
                  })}
                />
                {errors.email && <p>{errors.email.message}</p>}
              </div>
            )}

            {toggleBCC && (
              <div className="form-row mt-2">
                <CreatableSelect
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  type="select"
                  isMulti
                  isClearable
                  placeholder="bcc"
                  name="bccEmail"
                  className="col-12"
                  onChange={(data) => setSelectedBccEmail(data)}
                  options={employees}
                  innerRef={register({
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Please enter a valid email address!",
                    },
                  })}
                />
                {errors.email && <p>{errors.email.message}</p>}
              </div>
            )}

            <div className="flex-wrap flex-column mt-2">
              <RichTextEditor
                editorActive={() => {
                  setToggleCC(false);
                  setToggleBCC(false);
                }}
                // fileOversize={fileOversize}
                // files={files}
                composeType={composeType}
                clearFilesStates={() => clearFilesStates()}
                showHideFileUpload={(data) => setShowHideFileUpload(data)}
                bodyTags={bodyTags}
                sendingEmailBody={(data) => sendEmail(data)}
                emailBody={emailBody}
                credentials={credentials}
                sending={sending}
              />
            </div>

            <div className="flex-wrap flex-column mt-2">
              <div
                style={{
                  overflowY: "auto",
                  maxHeight: "50px",
                }}
              >
                {uploadAcceptedFiles &&
                  uploadAcceptedFiles.map((file, id) => {
                    // console.log(
                    //   "UploadAcceptedFiles inside",
                    //   uploadAcceptedFiles
                    // );
                    return (
                      <div
                        key={id}
                        className="m-1 p-1 d-flex justify-content-between align-items-center text-align-center"
                        style={{
                          backgroundColor: "#dad6d6",
                          borderRadius: "5px",
                        }}
                      >
                        <div className="ml-2 text-dark ">{file.name}</div>
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-dark mr-2 pointer btn-raised"
                          style={{ boxShadow: "none" }}
                          onClick={() => removeFiles(file.name)}
                        />
                      </div>
                    );
                  })}
              </div>

              <ModalComponent
                show={showHideFileUpload}
                header="Upload your documents"
                closeModal={() => {
                  clearFilesStates();
                }}
              >
                <div
                  className={"d-flex justify-content-center align-items-center"}
                >
                  <FormGroup row>
                    <Col sm={12}>
                      {/* <Label>Please upload excel file to search </Label> */}

                      <div
                        className="d-flex justify-content-center align-items-center  "
                        style={{
                          border: "solid 2px  ",
                          borderRadius: "2px",
                          borderColor: "#007bff",
                          width: "460px",
                          height: files.length > 0 ? "50px" : "260px",
                        }}
                        {...getRootProps()}
                      >
                        <input id="dropZone" {...getInputProps()} />

                        <>
                          {isDragActive ? (
                            <p className="m-3 ">Drop the file here ...</p>
                          ) : (
                            <p className="m-3">
                              Drag 'n' drop excel file here, or click to select
                              file
                            </p>
                          )}
                        </>
                      </div>
                      <div>
                        {files.length > 0 && !fileOversize ? (
                          <div
                            className="d-flex justify-content-center align-items-center p-2"
                            style={{ overflowX: "auto" }}
                          >
                            {files.map((file, id) => {
                              let splitedArray = split(file.type, "/");
                              let document_TYPE = splitedArray[1];

                              if (document_TYPE == "pdf") {
                                return (
                                  <div
                                    key={id}
                                    className="justify-content-center d-flex  flex-column align-items-center p-2"
                                  >
                                    <div>
                                      <FontAwesomeIcon
                                        icon={faFilePdf}
                                        className="fa-5x "
                                      />
                                    </div>
                                    <div>
                                      <small>{file.name} </small>
                                    </div>
                                  </div>
                                );
                              } else if (
                                document_TYPE == "png" ||
                                document_TYPE == "jpg" ||
                                document_TYPE == "JPEG" ||
                                document_TYPE == "jpeg"
                              ) {
                                return (
                                  <div key={id} style={thumbsContainer}>
                                    {thumbs(file, id)}
                                  </div>
                                );
                              } else {
                                return (
                                  <div
                                    key={id}
                                    className="justify-content-center d-flex  flex-column align-items-center p-2"
                                  >
                                    <div>
                                      <FontAwesomeIcon
                                        icon={faFileAlt}
                                        className="fa-5x "
                                      />
                                    </div>
                                    <div>
                                      {file.name.length > 10 ? (
                                        "File " + id
                                      ) : (
                                        <small>{file.name}</small>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          </div>
                        ) : fileOversize ? (
                          <div className="d-flex justify-content-center mt-2 flex-column align-items-center">
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              className="fa-3x"
                            ></FontAwesomeIcon>
                            <Label>
                              File exceed limit of 50mb please reduce the files
                              or choose a different size{" "}
                            </Label>
                          </div>
                        ) : null}
                      </div>

                      <div className="d-flex mt-1 mx-2 justify-content-center align-items-center mt-3">
                        <Button
                          className="mx-2 bg-success"
                          onClick={() => {
                            setShowHideFileUpload(false);
                            uploadFiles();
                            clearFilesStates();
                          }}
                        >
                          Upload
                        </Button>
                        <Button
                          className="mx-2"
                          onClick={() => {
                            clearFilesStates();
                          }}
                        >
                          Close
                        </Button>
                      </div>
                    </Col>
                  </FormGroup>
                </div>
              </ModalComponent>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    emailServerReducer: state.emailServerReducer,
  };
}

export default connect(mapStateToProps)(EmailForm);
