import React from "react";
import axios from "axios";
import moment from "moment";
import { Badge, Button } from "reactstrap";
import toast, { Toaster } from "react-hot-toast";
import { TODO, HostMexxarURL, ClientLable } from "../../configs/api-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faEnvelope } from "@fortawesome/free-solid-svg-icons";

export const ToastNotifications = (heading, data) => {
  var header;
  var subHeader;
  var subHeader2;
  var subHeader3;
  var subHeader4;
  var categoryColor;
  var avatarLetter;
  var position;
  
  switch (heading) {
    case "Chat":
      header = data;
      categoryColor = "success";
      break;
    case "Todo":
      subHeader = data.body;
      subHeader2 = `on ${moment(data.date).format("MMMM Do YYYY, h:mm a")}`;
      categoryColor = "warning";
      avatarLetter = "T";
      position = "center";
      break;
    case "Reschedule":
      subHeader = `${data.receiverName} requested a call back`;
      subHeader2 = `on ${moment(data.rescheduleDate).format(
        "MMMM Do YYYY, h:mm a"
      )}`;
      categoryColor = "success";
      avatarLetter = "R";
      position = "center";
      break;
    case "Event":
      subHeader = data.name;
      subHeader2 = `${moment(data.startDateTime).format(
        "ddd MMM DD YYYY, h:mm a"
      )} to ${moment(data.endDateTime).format("ddd MMM DD YYYY, h:mm a")} `;
      categoryColor = "info";
      avatarLetter = "E";
      position = "center";
      break;

    case "Training":
      subHeader = data.trainings && data.trainings[0].name;
      subHeader2 = `${moment(data.startDateTime).format(
        "ddd MMM DD YYYY, h:mm a"
      )} to ${moment(data.endDateTime).format("ddd MMM DD YYYY, h:mm a")} `;
      categoryColor = "success";
      avatarLetter = "T";
      position = "center";
      break;
      
    case "Unconfirmed Booking":
      subHeader = data.candidateName;
      subHeader2 = ` ${
        "Ref id: " +
        data.referenceNo +
        " on " +
        moment(data.bookingDate).format("ddd MMM DD YYYY")
      }`;
      subHeader3 = data.clientName;
      subHeader4 = data.wardName;
      categoryColor = "warning";
      avatarLetter = "UB";
      position = "center";

      break;
  }
  let todoDone = false;
  const todo = (t) => {
    axios
      .post(TODO, {
        id: data.id,
        userId: data.userId,
        createDate: data.createDate,
        notifyDate: data.notifyDate,
        body: data.body,
        done: true,
      })
      .then((res) => {
        if (res.data.status == "success") {
          todoDone = true;
          toast.dismiss(t.id);
        }
      })
      .catch((err) => {
        todoDone = false;

        //console.log(err)
      });
  };
 
  
  toast.custom(
    (t) => (
      <div
        className="d-flex flex-column bg-light p-3 shadow-sm   "
        style={{
          minWidth: "400px",
          boxShadow: "initial",
          borderRadius: "15px",
          // transform: `translate(${5}px)`,
          transform: 'translateY(-20.641px) !important'
        }}
       
      >
        <div className="d-flex flex-row justify-content-between border-bottom pb-1">
          <div className="font-weight-bold ml-1">{heading}</div>
          <FontAwesomeIcon
            className="pointer btn-raised"
            onClick={() => toast.dismiss(t.id)}
            icon={faTimes}
            style={{ boxShadow: "none" }}
          />
        </div>
        <div className="d-flex flex-row ">
          <div className="d-flex  align-items-center">
            <span
              className={`w-40 avatar gd-${categoryColor}`}
              data-toggle-class="loading"
            >
              {heading == "Chat" ? (
                <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
              ) : (
                avatarLetter
              )}
            </span>
          </div>
          <div
            className="d-flex flex-column p-2 "
            style={{ minWidth: "150px" }}
          >
            <div
              className="font-weight-bold text-md  text-justify ml-1 "
              style={{ fontSize: "16px" }}
            >
              {subHeader}
            </div>

            {heading === "Unconfirmed Booking" && (
              <>
                <small
                  className="font-weight-light text-sm text-justify ml-1 text-muted"
                  style={{ fontSize: "14px" }}
                >
                  {ClientLable}: {subHeader3}
                </small>
                <small
                  className="font-weight-light text-sm text-justify ml-1 text-muted"
                  style={{ fontSize: "14px" }}
                >
                  Ward: {subHeader4}
                </small>
              </>
            )}

            <small
              className="font-weight-light text-sm text-justify ml-1 text-muted"
              style={{ fontSize: "14px" }}
            >
              {subHeader2}
            </small>

            {heading == "Training" && (
              <>
                <small
                  className="font-weight-light   text-justify ml-1 text-muted"
                  style={{ fontSize: "14px" }}
                >
                  <Badge className="p-1 mx-1" color="info">
                    {data && data.recordedEmployee.firstName}{" "}
                    {data && data.recordedEmployee.lastName}
                  </Badge>
                </small>
                <small
                  className="font-weight-light   text-justify ml-1 text-muted"
                  style={{ fontSize: "14px" }}
                >
                  Assigned To :
                  <div className="d-flex  align-items-center">
                    <span
                      className={`w-25 avatar gd-${"success"}`}
                      data-toggle-class="loading"
                    >
                      {data?.candidates?.map((candidate, id) => {
                        return (
                          <option value={candidate.id} key={id}>
                            {candidate.firstName.split("")[0]}
                            {candidate.lastName.split("")[0]}
                          </option>
                        );
                      })}
                    </span>
                  </div>
                </small>
              </>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-end ">
          {heading == "Todo" && (
            <Button
              color={`${todoDone ? "success" : "warning"}`}
              className="mx-2 btn-sm"
              onClick={() => todo(t.id)}
            >
              Done
            </Button>
          )}
          {heading == "Reschedule" && (
            <a
              href={`${HostMexxarURL}dashboard/call/${data.receiverId}`}
              target="_blank"
            >
              <Button
                color="success"
                className="mx-2 btn-sm"
                onClick={() => toast.dismiss(t.id)}
              >
                Call
              </Button>
            </a>
          )}
          {heading == "Chat" && (
            <Button
              color="success"
              className="mx-2 btn-sm"
              onClick={() => todo(t.id)}
            >
              Chat
            </Button>
          )}
          <Button
            color="secondary"
            className="btn-sm"
            onClick={() => toast.dismiss(t.id)}
          >
            Close
          </Button>
        </div>
      </div>
    ),
    {
      duration: `${heading == "Reschedule" ? 4500000 : 5000}`,
      // height: "300px",
      // position: `${heading == "Reschedule" ? "bottom-right" : "bottom-right"}`,
      position: "bottom-right",
      style: {
        position: "relative",
        // bottom: "0px",
        // left: "50%",
        // maxWidth: "450px",
        // zIndex: 1,
        // translateX: "-50%",
      },
    }
  );
};
export default ToastNotifications;
