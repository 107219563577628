import React, { useState, useEffect } from "react";
import "./working-card-widget.style.css";
import {
  Badge,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Label,
} from "reactstrap";
import logo from "../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

import {
  faMars,
  faVenus,
  faPhone,
  faEnvelope,
  faCalendar,
  faPhoneSlash,
  faCalendarAlt,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import ModalComponent from "../../components/Modal/modalComponent";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import {
  HEADHUNTER_WORKSPACE_DATA,
  HOSTMexxar,
  RESCHEDULE,
  REGISTER_CANDIDATE,
  HostMexxarURL,
} from "../../configs/api-config";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

import {
  ApplicationStatus,
  GenderSwitch,
  ConversationResponse,
} from "../../util/common";
import AssignConsultant from "../../components/Modal/ModalBody/assignConsultant";
import { forEach } from "lodash";
import AvatarMexxar from "../../util/common/avatarMexxar";

const HeadhunterWorkingCardWidget = (props) => {
  const {
    userProfile,
    tab,
    workflowStatusUpdated,
    auth,
    isSelected,
    onItemSelect,
    rowId,
    selectAll,
  } = props;
  const MexxarApi = HEADHUNTER_WORKSPACE_DATA;
  const MexxarUrl = HostMexxarURL;

  const MexxarApiCandidate = REGISTER_CANDIDATE;
  const handleSelectedCandidate = (userProfile) =>
    setSelectedCandidate(userProfile);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const MexxarHostAPI = HOSTMexxar;
  const MexxarApiReschedule = RESCHEDULE;
  const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState(false);
  const [candidateUser, setCandidateUser] = useState();
  const [checkedStatus, setCheckedStatus] = useState();

  const { addToast } = useToasts();

  const [showAssignConsultantModal, setShowAssignConsultantModal] =
    useState(false);

  const [calendarShow, setCalendarShow] = useState(false);

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [profilePicture, setProfilePicture] = useState();
  const toggleShowEmailModal = (user) => {
    setShowEmailModal(!showEmailModal);
    setCandidateUser(user);
  };

  // function getBase64(file, cb) {
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = function () {
  //     cb(reader.result);
  //   };
  //   reader.onerror = function (error) {
  //     console.log("Error: ", error);
  //   };
  // }

  const toggleM = () => setShowEmailModal(!showEmailModal);
  ////......reshedule icon function to reschedule a call........////
  const resheduleCall = (data) => {
    let rescheduleDate = moment(data.date).add(data.time).toISOString();
    axios
      .post(MexxarApiReschedule, {
        callerId: auth.item.id,
        receiverId: userProfile.candidateId,
        callDate: moment(new Date()).toISOString(),
        rescheduleDate: rescheduleDate,
      })
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "success", addToast);
          setCalendarShow(!calendarShow);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log("eror in getting response", err);
        // ToastCommon(true, "error", addToast);
      });
  };

  const getAllRows = () => {
    // axios
    //   .get(MexxarApi)
    //   .then((res) => {
    //     //   console.log("hello",res.data.body)
    //     setRows(res.data.body);
    //   })
    //   .catch((err) => {
    //     //console.log(err)
    //     ToastCommon(true, "error", addToast);
    //   });
  };

  const updateWorkFlowStatus = (data) => {
    let consultantIds = [];
    forEach(data, function (item) {
      consultantIds.push(item.id);
    });

    axios
      .all([
        axios.put(
          MexxarApiCandidate +
            "/" +
            userProfile.candidateId +
            "/add-employees?role=REGISTRAR",
          consultantIds
        ),
        axios.put(
          MexxarApiCandidate +
            "/" +
            userProfile.candidateId +
            "/workflow/REGISTRATION"
        ),
      ])
      .then(
        axios.spread((res1, res2) => {
          if (res1.data.status == "success" && res2.data.status == "success") {
            ToastCommon(true, "success", addToast);
            workflowStatusUpdated(true);

            setShowAssignConsultantModal(!showAssignConsultantModal);
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
      )
      .catch((err) => {
        //console.log(err)
        //ToastCommon(true, "error", addToast);
      });
  };

  return (
    <>
      <div
        style={{
          minWidth: "900px",
          marginBottom: "5px",

          borderColor:
            userProfile.candidateApplicationStatus === "COMPLETED"
              ? "#cccccc"
              : null,
          border:
            userProfile.candidateApplicationStatus === "COMPLETED"
              ? "2px green solid"
              : "1px",
        }}
        className={` card p-1 d-flex flex-column ${
          userProfile.candidateApplicationStatus === "COMPLETED"
            ? "btn-raised btn-wave text-success "
            : "bg-light"
        }`}
      >
        <div className={`d-flex `}>
          {!selectAll ? (
            <div className="d-flex align-items-center mr-3 ">
              <span className="mx-2">
                {/* <b className="badge badge-circle sm text-primary"></b> */}
                <label className="ui-check m-0">
                  <input
                    type="checkbox"
                    name="id"
                    checked={isSelected(userProfile.candidateId)}
                    onChange={() => onItemSelect(userProfile.candidateId)}
                  />{" "}
                  <i></i>
                </label>
              </span>{" "}
            </div>
          ) : null}

          <AvatarMexxar
            candidateId={userProfile?.candidateId}
            primaryPhone={userProfile?.primaryPhone}
            avatar={userProfile?.proPic}
            gender={userProfile?.gender}
          />

          <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
            <div
              className="d-flex ml-1 mb-1"
              id={`candidateID${userProfile.candidateId}`}
            >
              <Label>
                {userProfile.candidateFirstName}&nbsp;
                {userProfile.candidateLastName}
              </Label>
            </div>
            <UncontrolledTooltip
              className="d-flex flex-column justify-content-start align-items-center p-2   bg-light"
              target={`candidateID${userProfile.candidateId}`}
            >
              <div className="d-flex border-bottom mb-2 bold "> Show more</div>
              <span className="d-flex">
                ID:&nbsp;{" "}
                <Badge className="p-1 m-0">{userProfile.candidateId}</Badge>
              </span>
              <div>
                <Label className="text-left">
                  Full name: {userProfile.candidateFirstName}&nbsp;
                  {userProfile.candidateSecondName}
                  &nbsp;
                  {userProfile.candidateLastName}{" "}
                </Label>
              </div>
            </UncontrolledTooltip>

            <div className=" d-flex ">
              <div className="mx-2 " id="call">
                {/* <Link
                    to={{
                      pathname: "/dashboard/call/"+userProfile.candidateId+"/"+userProfile.primaryPhone,
                      aboutParams: {
                        imageLink: userProfile.image,
                      },
                    }} */}
                <Link to={"/dashboard/call/" + userProfile.candidateId}>
                  <RoundIconButton
                    buttonColor="#00C426"
                    icon={faPhone}
                    iconSize="fa-md"
                    buttonSize="25px"
                  ></RoundIconButton>
                </Link>
                <UncontrolledTooltip placement="top" target="call">
                  Call
                </UncontrolledTooltip>
              </div>
              <div className="mx-2 " id="email">
                <RoundIconButton
                  buttonColor="#458AF7"
                  icon={faEnvelope}
                  buttonSize="25px"
                  click={() => {
                    toggleShowEmailModal(userProfile);
                    handleSelectedCandidate(userProfile);
                  }}
                ></RoundIconButton>
                <UncontrolledTooltip placement="top" target="email">
                  Email
                </UncontrolledTooltip>
              </div>
              <div className=" mx-2  d-flex align-items-center pointer">
                <FontAwesomeIcon
                  id="calender"
                  color="#FF5603"
                  icon={faCalendarAlt}
                  onClick={() => {
                    setCalendarShow(!calendarShow);
                    setRowID(rowId);
                  }}
                  className="fa-lg mb-2 btn-raised btn-wave  "
                />
                <UncontrolledTooltip placement="top" target="calender">
                  Calender
                </UncontrolledTooltip>
              </div>
            </div>
          </div>

          <div className="d-flex  align-items-center" style={{ flex: 1 }}>
            <FontAwesomeIcon
              // color={userProfile.gender == "1" ? "#458AF7" : "#f54394"}
              color={GenderSwitch(userProfile.gender).genderColor}
              // icon={userProfile.gender == "1" ? faMars : faVenus}
              icon={GenderSwitch(userProfile.gender).genderSymbol}
            />
          </div>
          <div
            className="d-flex align-items-center justify-content-center flex-column"
            style={{ flex: 3 }}
          >
            <div style={{ height: "4vh", flex: 1 }}>Call Response</div>
            <div style={{ height: "3vh", flex: 1 }}>
              {" "}
              <Badge
                color={
                  ConversationResponse(userProfile.candidateCallResponseStatus)
                    .badgeColor
                }
              >
                {
                  ConversationResponse(userProfile.candidateCallResponseStatus)
                    .badgeLable
                }
              </Badge>
            </div>
            <div style={{ height: "3vh", flex: 1 }}></div>
          </div>
          <div
            className="d-flex align-items-center flex-column"
            style={{ flex: 4 }}
          >
            <div style={{ height: "4vh", flex: 1 }}>Application Status</div>
            <div style={{ height: "3vh", flex: 1 }}>
              <Badge
                // color={ApplicationStatus(userProfile.candidateApplicationStatus).badgeColor}
                style={{
                  backgroundColor: ApplicationStatus(
                    userProfile.candidateApplicationStatus
                  ).badgeColor,
                }}
              >
                {
                  ApplicationStatus(userProfile.candidateApplicationStatus)
                    .badgeLable
                }
              </Badge>
            </div>
            <div style={{ height: "3vh", flex: 1 }}>
              {userProfile.candidateApplicationStatus === "COMPLETED" ? (
                <>
                  <Badge
                    className="fa-lg btn-raised btn-wave mr-2  "
                    id="sendToRegistration"
                    style={{
                      cursor: "pointer",
                      backgroundColor: ApplicationStatus(
                        userProfile.candidateApplicationStatus
                      ).badgeColor,
                    }}
                    onClick={() =>
                      setShowAssignConsultantModal(!showAssignConsultantModal)
                    }
                  >
                    Send to Registration
                  </Badge>
                  <UncontrolledTooltip target={"sendToRegistration"}>
                    Click to send the candidate to registrations
                  </UncontrolledTooltip>
                </>
              ) : null}
            </div>
          </div>

          {/* <div style={{ flex: 1 }}>
          <div style={{ height: "4vh", flex: 1 }}></div>
          <div style={{ height: "3vh", flex: 1 }}></div>
          <div style={{ height: "3vh", flex: 1 }}></div>
        </div> */}
          <div style={{ flex: 4 }}>
            {userProfile.lastContactedUserName === null ? null : (
              <div className="d-flex align-items-center flex-column">
                <div style={{ height: "4vh" }}>Last Contacted By</div>

                <div style={{ height: "3vh" }}>
                  {userProfile.lastContactedUserName}
                </div>

                <div className="d-flex justify-content-center ">
                  <span className="mr-1">
                    <small>
                      {moment(userProfile.lastContactedDate).format(
                        "ddd MMM DD YYYY"
                      )}
                    </small>
                  </span>
                  <span className="mr-1">
                    <small>
                      {" "}
                      {moment(userProfile.lastContactedDate).format("hh:mm a")}
                    </small>
                  </span>
                </div>
              </div>
            )}
          </div>
          <div style={{ flex: 4 }}>
            <div
              className="d-flex justify-content-center mb-1 "
              style={{ maxHeight: "6vh" }}
            >
              Number of Calls
            </div>
            <div
              className=" d-flex justify-content-around "
              style={{ height: "3vh" }}
            >
              <div>
                <FontAwesomeIcon
                  id="answered"
                  color="#00C426"
                  icon={faPhone}
                  className="fa-flip-horizontal"
                />
                <UncontrolledTooltip placement="top" target="answered">
                  Answered
                </UncontrolledTooltip>
              </div>
              <div>
                <FontAwesomeIcon
                  id="unanswered"
                  color="#F80055"
                  icon={faPhoneSlash}
                  className="fa-flip-horizontal"
                />
                <UncontrolledTooltip placement="top" target="unanswered">
                  Unanswered
                </UncontrolledTooltip>
              </div>
            </div>
            <div
              className="d-flex justify-content-around"
              style={{ height: "3vh" }}
            >
              <div>{userProfile.answeredCount}</div>
              <div>{userProfile.notAnsweredCount}</div>
            </div>

            
    
       
     

            
          </div>

          <div
            className={`modal fade bd-example-modal-sm  ${
              calendarShow ? "show" : ""
            }`}
            id="exampleModalCenter"
            //  tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            style={{ display: `${calendarShow ? "block" : "none"}` }}
            aria-hidden={!calendarShow}
          ></div>


       
          {/* {userProfile.candidateApplicationStatus === "PENDING" ? (
            <div className="d-flex  align-items-center" style={{ flex: 1 }}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                className="fa-lg btn-raised btn-wave mr-2 text-success "
                onClick={()=>updateWorkFlowStatus()}
              ></FontAwesomeIcon>
            </div>
          ) : null} */}
        </div>

        <div className="mx-2 mr-12" style={{ width: '100%', overflowX: 'auto', whiteSpace: 'nowrap', paddingRight: '16px' }}>

  {userProfile?.headhunters?.map((headhunters, index) => (
    <span key={index} className="badge badge-success text-uppercase mr-1">
      {headhunters.usersName}
    </span>
  ))}
</div>

  
</div>

     
       


    
      <ModalComponent
        show={showAssignConsultantModal}
        header="Assign a consultant"
        closeModal={() => setShowAssignConsultantModal(false)}
      >
        <AssignConsultant
          onCreate={(data) => updateWorkFlowStatus(data)}
          workflowToBe="REGISTRATION"
          closeModal={() => setShowAssignConsultantModal(false)}
        />
      </ModalComponent>

      <ModalComponent
        show={calendarShow}
        header="Reschedule a call"
        closeModal={() => setCalendarShow(false)}
      >
        <SmallCalendar
          onCreate={(data) => resheduleCall(data)}
          closeModal={() => setCalendarShow(false)}
        />
      </ModalComponent>

      <ModalComponent
        show={showEmailModal}
        header="E mail and sms"
        closeModal={() => toggleM()}
      >
        <EmailNav closeModal={() => toggleM()} user={candidateUser} />
      </ModalComponent>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(HeadhunterWorkingCardWidget);
