import React, { useState, useEffect } from "react";
import {
  REGISTRATION_WORKSPACE_SUMMARY,
  Add_NEW_EMPLOYEE,
} from "../../configs/api-config";
import {
  faArrowAltCircleUp,
  faArrowAltCircleDown,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { filter } from "lodash";
import { connect } from "react-redux";
import RecivedDocs from "./workspace/docs";
import Reference from "./workspace/reference";
import RegistrationCallBacks from "./workspace/callBacks";
import ComplianceFully from "./workspace/complianceFully";
import { useIsMounted } from "../../customHooks/useIsMounted";
import { UncontrolledTooltip, Label, Input } from "reactstrap";
import CallBackPage from "../headhunter/callBacks/callBackPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RegistrationAuditRejected from "./workspace/auditRejected";
import RegistrationWorkSpace from "./workspace/registrationWorkSpace";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import TabNavigationMenu from "../../util/common/tabNavigation";
import ComplianceFullyNew from "./workspace/complianceFullyNew";

const Registration = (props) => {
  const { auth } = props;

  const MexxarApi = REGISTRATION_WORKSPACE_SUMMARY;
  const MexxarApiEmployee = Add_NEW_EMPLOYEE;
  const isMounted = useIsMounted();

  const [summery, setSummery] = useState({});
  const [employee, setEmployee] = useState([]);
  const [showEmployee, setShowEmployee] = useState(false);
  const [defaultEmployee, setDefaultEmployee] = useState([]);
  const [rememberEmployee, setRememberEmployee] = useState(false);
  const [employeeID, setEmployeeID] = useState(auth.item.userTypeId);
  const [currentTab, setCurrentTab] = useState("registrationWorkspace");

  const tab = window.location.hash.substring(1);
  useEffect(() => {
    if (tab === "") {

      setCurrentTab("registrationWorkspace")
    } else {
      setCurrentTab(tab)
    }
  }, [tab])


  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  const getData = () => {
    if (employeeID && isMounted.current) {
      axios
        .get(MexxarApi + "?employeeId=" + Number(employeeID))
        .then((res) => {
          if (isMounted.current) {
            setSummery(res.data.body[0]);
          }
        })
        .catch((err) => {
          //   console.log(err);
        });
    }
  };

  const getEmployees = (workspaceEmployeeSave, employeeId) => {
    axios
      .get(MexxarApiEmployee)
      .then((res) => {
        if (isMounted.current) {
          let reponse = res.data.body;
          let names = [];
          reponse.forEach((item) => {
            names.push({
              id: item.id,
              firstName: item.firstName,
              lastName: item.lastName,
            });
          });
          setEmployee(names);

          if (workspaceEmployeeSave == "true") {
            let tempEmployee = filter(names, (employee) => {
              return employee.id === Number(employeeId);
            });
            // console.log("employee", tempEmployee);
            setDefaultEmployee(tempEmployee);
          } else {
            let tempEmployee = filter(names, (employee) => {
              return employee.id === auth.item.userTypeId;
            });
            // console.log("employee", tempEmployee);
            setDefaultEmployee(tempEmployee);
          }
        }
      })
      .catch((err) => {
        //console.log(err)
        // toastCommon(true, "error");
      });
  };

  useDidMountEffect(() => {
    localStorage.setItem("WorkspaceEmployeeSave", rememberEmployee);
    localStorage.setItem("WorkspaceEmployeeId", employeeID);
  }, [rememberEmployee]);

  useDidMountEffect(() => {
    localStorage.setItem("WorkspaceEmployeeSave", rememberEmployee);
    localStorage.setItem(
      "WorkspaceEmployeeId",
      employeeID == 0 ? auth.item.userTypeId : employeeID
    );
    getData();
  }, [employeeID]);

  useEffect(() => {
    const employeeId = localStorage.getItem("WorkspaceEmployeeId");
    const workspaceEmployeeSave = localStorage.getItem("WorkspaceEmployeeSave");

    getEmployees(workspaceEmployeeSave, employeeId);

    if (workspaceEmployeeSave == "true") {
      setRememberEmployee(true);
      setShowEmployee(true);
      setEmployeeID(Number(employeeId));
    } else {
      localStorage.setItem("WorkspaceEmployeeSave", false);
      localStorage.setItem("WorkspaceEmployeeId", auth.item.userTypeId);
      setEmployeeID(auth.item.userTypeId);
    }

    getData();
  }, []);

  return (
    <>
      <DashboardLayout
        title="Registration Workspace"
        subTitle="Acheive Your Daily Targets"
        target=""
        targetsShow={true}
        RegisterNewButtonShow={true}
        summeryData={summery}
      >
        <div className="d-flex align-items-end flex-column">
          {showEmployee ? (
            <div>
              <FontAwesomeIcon
                icon={faArrowAltCircleUp}
                className="btn-raised"
                id="arrowUp"
                style={{ boxShadow: "none", cursor: "pointer" }}
                onClick={() => setShowEmployee(false)}
              ></FontAwesomeIcon>
            </div>
          ) : (
            <div>
              <FontAwesomeIcon
                icon={faArrowAltCircleDown}
                id="arrowDown"
                className="btn-raised"
                style={{ boxShadow: "none", cursor: "pointer" }}
                onClick={() => setShowEmployee(true)}
              ></FontAwesomeIcon>{" "}
              <UncontrolledTooltip placement="left" target="arrowDown">
                Click to show/hide consultants
              </UncontrolledTooltip>
            </div>
          )}

          {showEmployee ? (
            <div>
              <Label className={"mr-3"}>Get Candidates by Employee</Label>
              {employee.length > 0 ? (
                <Input
                  type="select"
                  id="assigneeId"
                  style={{ width: "200px" }}
                  name="assigneeId"
                  value={employeeID.id}
                  defaultValue={employeeID}
                  onChange={(e) => setEmployeeID(e.target.value)}
                >
                  {/* <option value={0}>Select</option> */}

                  {employee.map((item, id) => {
                    return (
                      <option key={id} value={item.id}>
                        {item.firstName} {item.lastName}
                      </option>
                    );
                  })}
                </Input>
              ) : null}
              <div>
                <small>
                  Remember
                  <Input
                    className="mx-2 pointer"
                    type="checkbox"
                    value="REMEMBEREMPLOYEE"
                    checked={rememberEmployee}
                    onChange={() => setRememberEmployee(!rememberEmployee)}
                  ></Input>
                </small>
              </div>
            </div>
          ) : null}
        </div>

        <TabNavigationMenu
          activated={currentTab}
          onChangeItem={switchTabHandler}
          itemList={[
            { name: "Workspace", key: "registrationWorkspace" },
            { name: "Rescheduled", key: "reschedule" },
            { name: "Received Docs", key: "reciveddocs" },
            { name: "Audit Rejected ", key: "auditRejected" },
            { name: "Cleared Candidates", key: "clearedCandidates" },
            // { name: "Cleared Candidates2", key: "clearedCandidates2" },

            { name: "Reference - Beta ", key: "reference" },
          ]}
        />

        {currentTab === "registrationWorkspace" && (
          <RegistrationWorkSpace
            updateTargets={() => getData()}
            employeeID={employeeID}
          />
        )}
        {currentTab === "reschedule" && (
          <CallBackPage
            updateTargets={() => getData()}
            employeeID={employeeID}
            page="REGISTRATION"
          />
          // <RegistrationCallBacks
          //   updateTargets={() => getData()}
          //   employeeID={employeeID}
          // />
        )}
        {currentTab === "reciveddocs" && (
          <RecivedDocs
            updateTargets={() => getData()}
            employeeID={employeeID}
            incomingPage={"REGISTRATION"}
          />
        )}
        {currentTab === "auditRejected" && (
          <RegistrationAuditRejected
            updateTargets={() => getData()}
            employeeID={employeeID}
          />
        )}
        {currentTab === "clearedCandidates" && (
          <ComplianceFully employeeID={employeeID} />
        )}
        {/* {currentTab === "clearedCandidates2" && (
          <ComplianceFullyNew
            updateTargets={() => getData()}
            employeeID={employeeID}
          />
        )} */}
        {currentTab === "reference" && (
          <Reference
            inCommingPage="REGISTRATION"
            updateTargets={() => getData()}
            employeeID={employeeID}
          />
        )}
      </DashboardLayout>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Registration);
