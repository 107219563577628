import React, { useState } from "react";

import {
  faExclamationTriangle,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { HOSTMexxar } from "../../../../configs/api-config";
import ToastCommon from "../../../../components/toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";

export default function PasswordReset(props) {
  const { register, handleSubmit, errors } = useForm();
  const { onCreate, closeModal, mode, id, type, candidateEmail, candidateId } =
    props;

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const MexxarHost = HOSTMexxar;
  const { addToast } = useToasts();

  const resetAccountPassword = () => {
    const fd = new FormData();
    fd.append("pwd", newPassword);
    if (type == "employee") {
      // axios
      //   .post(
      //     MexxarHost +
      //       "candidates/reset-password?email=" +
      //       candidateEmail +
      //       "&pwd=" +
      //       newPassword
      //   )
      axios
        .post(MexxarHost + "employee/" + id + "/reset-password", fd)
        .then((res) => {
          if (res.data.status == "success") {
            ToastCommon(true, "resetPassword", addToast);
            closeModal();
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          ToastCommon(true, "error", addToast);
        });
    } else if (type == "candidate") {
      // axios
      //   .post(MexxarHost + "user/reset-password", fd)
      axios
        .post(
          MexxarHost +
            "candidates/reset-password?email=" +
            candidateEmail +
            "&pwd=" +
            newPassword
        )
        .then((res) => {
          if (res.data.status == "success") {
            ToastCommon(true, "resetPassword", addToast);
            closeModal();
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
        });
    }
  };

  const onSubmit = (data) => {
    //  onCreate(data);

    if (confirmPassword == newPassword) {
      setPasswordMatch(false);
      resetAccountPassword();
    } else {
      setPasswordMatch(true);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={4}>New Password</Label>
        <Col sm={7}>
          <Input
            onChange={(e) => setNewPassword(e.target.value)}
            type={showPassword ? "text" : "password"}
            name="newPassword"
            invalid={errors.bName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 50,
                message: "This field only allowed only 50 characters",
              },
              pattern: {
                value:
                  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                message:
                  "Password must contain at least one Capital, Lower, Number, Special Character and 8 characters",
              },
            })}
          ></Input>
          {errors.bName && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.bName?.message}
              </li>
            </ul>
          )}{" "}
        </Col>

        <Col
          sm={1}
          className="d-flex align-items-center  justify-content-start"
        >
          {showPassword ? (
            <FontAwesomeIcon
              icon={faEye}
              className=" btn-raised btn-wave"
              onClick={() => setShowPassword(false)}
              style={{
                boxShadow: "none",
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faEyeSlash}
              className=" btn-raised btn-wave"
              onClick={() => setShowPassword(true)}
              style={{
                boxShadow: "none",
              }}
            />
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Confirm Password</Label>
        <Col sm={7}>
          <Input
            type={showPassword ? "text" : "password"}
            name="confirmPassword"
            onChange={(e) => setConfirmPassword(e.target.value)}
            invalid={errors.confirmPassword || passwordMatch}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 50,
                message: "This field only allowed only 50 characters",
              },
            })}
          ></Input>
          {errors.confirmPassword && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.confirmPassword?.message}
              </li>
            </ul>
          )}
          {passwordMatch && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                <FontAwesomeIcon icon={faExclamationTriangle} color="warning" />{" "}
                Password does not match
              </li>
            </ul>
          )}
        </Col>

        <Col
          sm={1}
          className="d-flex align-items-center  justify-content-start"
        >
          {showPassword ? (
            <FontAwesomeIcon
              icon={faEye}
              className=" btn-raised btn-wave"
              onClick={() => setShowPassword(false)}
              style={{
                boxShadow: "none",
              }}
            />
          ) : (
            <FontAwesomeIcon
              icon={faEyeSlash}
              className=" btn-raised btn-wave"
              onClick={() => setShowPassword(true)}
              style={{
                boxShadow: "none",
              }}
            />
          )}
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <div className="d-flex ">
          {" "}
          <Button
            className="mr-2"
            color="success"
            type="submit"
            //disabled={passwordMatch}
          >
            {mode}
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}
