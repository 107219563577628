import { useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";
import { HOSTMexxar } from "../configs/api-config";

export default function usePostalCodeValidation() {
  const [postalCode, setPostalCode] = useState("");
  const [loadingPostalCode, setLoadingPostalCode] = useState(true);
  const [errorPostCode, setErrorPostCode] = useState(false);
  const [postalData, setPostalData] = useState({
    id: "",
    code: "",
    latitude: "",
    longitude: "",
    county: "",
    district: "",
    ward: "",
    country: "",
  });
  

  useDidMountEffect(() => {
    setLoadingPostalCode(true);
    setErrorPostCode(false);
    let cancel;
    axios({
      method: "GET",
      url: HOSTMexxar + "postal-codes/code/" + postalCode,
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setErrorPostCode(false);
        setLoadingPostalCode(false);
        if (res.data.status == "success") {
          setPostalData(res.data.body[0]);
        } else if (res.data.status == "failed") {
          setPostalData({
            id: "",
            code: "",
            latitude: "",
            longitude: "",
            county: "",
            district: "",
            ward: "",
            country: "",
          });
          setErrorPostCode(true);
        }
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setErrorPostCode(true);
        setLoadingPostalCode(false);
      });
    return () => cancel();
  }, [postalCode]);

  return {
    loadingPostalCode,
    errorPostCode,
    postalData,
    setPostalData,
    setPostalCode,
  };
}
