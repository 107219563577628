import React, { useState, useEffect, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Button, Label, Input, Form, FormGroup, Col, Badge ,Alert} from "reactstrap";
import { split, forEach, findKey, slice, filter } from "lodash";
import { CLIENTS, ClientLable } from "../../../configs/api-config";
import Select from "react-select";
import * as XLSX from "xlsx";
import moment from "moment";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import outlookLogo from "../../../../src/images/Outlook-Logo.png";

function GroupSearch(props) {
  const { register, handleSubmit, errors } = useForm();
  const { onCreate, closeModal, clients, auth } = props;
  const [check, setCheck] = useState(false);
  const [clientData, setClientData] = useState(null);
  const [hRExcelData, setHRExcelData] = useState([]);
  const [hRExcelDataJony, setHRExcelDataJony] = useState([]);

  const [clientIdBot, setClientIdBot] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [fileuploaded, setFileuploaded] = useState(false);
  const [clientNameBot, setClientNameBot] = useState(null);
  const [noClientSelected, setNoClientSelected] = useState(false);
  const [noFile, setNoFile] = useState(false);
  const [outlookLoggedIn, setOutlookLoggedIn] = useState(false);
  const [outlookLoggedInError, setOutlookLoggedInError] = useState(false);

  const onSubmit = (data) => {
    //  console.log("form data hospitalName incoming from form submiting", data);
    //  console.log("form data hospitalName", data.searchText);
    // let splitedArray = split(data.hospital, "/");
    // let hospital = splitedArray[1];
    // let hospitalId = Number(splitedArray[0]);
    // console.log("clientData", clientData);

    if (clientData && fileuploaded) {
      let hospital;
      let hospitalId;

      if (clientNameBot != null && clientIdBot != null) {
        hospital = clientNameBot;
        hospitalId = Number(clientIdBot);
      } else {
        hospital = clientData.value;
        hospitalId = clientData.id;
      }

      if (check == true) {
        //  console.log("form data hospitalName", splitedArray[1]);

        let enteredText = data.searchText;

        let numberOfLineBreaks = enteredText.split("\n");
        let characterCount = enteredText.length + numberOfLineBreaks;

        let preparedData = [];
        const numLineBreaks = numberOfLineBreaks.length;

        for (let i = 0; i < numLineBreaks; i++) {
          let processedText = numberOfLineBreaks[i].substring(1);

          if (numLineBreaks - 2 == i) {
            processedText = `${numberOfLineBreaks[i]} `;
          }
          let preparedColumn = {};
          processedText = processedText.replace(` ${hospital}`, "");
          processedText = processedText.split(" ");
          //console.log("Line:", processedText);
          if (numLineBreaks - 2 == i) {
            processedText.shift();
          }
          preparedColumn["refNo"] = processedText[1];
          preparedColumn["shiftDate"] = processedText[2];
          preparedColumn["startTime"] = processedText[3];
          preparedColumn["endTime"] = processedText[4];
          preparedColumn["secondaryAssignemnt"] =
            processedText[processedText.length - 1];
          preparedColumn["note"] = processedText[processedText.length - 2];
          preparedColumn["sex"] = processedText[processedText.length - 3];
          preparedColumn["training"] = processedText[processedText.length - 4];
          preparedColumn["assignment"] =
            processedText[processedText.length - 6];

          let remainWords = processedText.length - 9;
          let wardEndingPlace = processedText.length - 5;

          let wardName = "";
          for (let wardI = 5; wardI < wardEndingPlace - 1; wardI++) {
            wardName += " " + processedText[wardI];
          }

          preparedColumn["ward"] = wardName.trim();
          preparedData.push(preparedColumn);
        }
        let arrangedArray = [
          {
            type: "NHSP",
            hospital: hospital,
            hospitalId: hospitalId,
            shifts: preparedData,
          },
        ];
        //console.log("arrangedArray...NHSP..............", arrangedArray);
        onCreate(arrangedArray);
      } else {
        let tempHRExcelData = [];
        let noOfrecords;
        if (firstPageOnly == true && checkPermissions("BOT").length == 0) {
          //tempHRExcelData = hRExcelData.slice(0, 50);
          tempHRExcelData = hRExcelDataJony.slice(0, 50); //use this for jonny's endpoint
        } else {
          //tempHRExcelData = hRExcelData;
          tempHRExcelData = hRExcelDataJony; //use this for jonny's endpoint
        }

        let arrangedArray = [
          {
            type: "HR",
            hospital: hospital,
            hospitalId: hospitalId,
            shifts: tempHRExcelData,
          },
        ];

        // console.log("arrangedArray...Health Roaster...", tempHRExcelData);

        onCreate(arrangedArray);
      }
    } else {
      setNoClientSelected(true);
      setNoFile(true);
    }
  };
  const changeHandler = (e) => {
    if (e.target.value === "NHS") {
      setCheck(true);
    } else if (e.target.value === "HealthRoster") {
      setCheck(false);
    }
  };
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [firstPageOnly, setFirstPageOnly] = useState(true);

  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    readExcel(acceptedFiles[0]);
    setFileuploaded(true);
    setNoFile(false);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    disabled: !clientData,
  });

  const readExcel = (file) => {
    //  console.log("====================================",file);

    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file, {
        type: "binary",
        cellText: false,
        cellDates: true,
      });
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws, {
          raw: false,
          dateNF: "yyyy-mm-dd",
        });
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((promise) => {
      //  console.log("======promise=====",promise);

      //********previous un-optimized version *********/
      //if any bugs arise.please reverst to this
      // let tempHRdata = [];

      // for (let i = 0; i < promise.length; i++) {
      //   let element = promise[i];
      //   if (element != undefined) {
      //     tempHRdata.push({
      //       Agency: element.Agency,
      //       shiftDate: element.Date,
      //       endTime: element.End,
      //       RequestGrade: element["Request Grade"],
      //       RequestId: element["Request Id"],
      //       startTime: element.Start,
      //       ward: element.Ward,
      //       staff: element.Staff,
      //     });
      //   }
      // }
      //********End*********/

      const tempHRdata = promise.filter(Boolean).map((element) => ({
        Agency: element.Agency,
        shiftDate: element.Date,
        endTime: element.End,
        RequestGrade: element["Request Grade"],
        RequestId: element["Request Id"],
        startTime: element.Start,
        ward: element.Ward,
        staff: element.Staff,
      }));
      setHRExcelData(tempHRdata);

      // ------uncomment when using jonny's magic serach
      const tempHRdataJony = promise.filter(Boolean).map((element) => ({
        shiftDate: element.Date,
        endTime: element.End,
        RequestGrade: element["Request Grade"],
        RequestId: element["Request Id"],
        startTime: element.Start,
        ward: element.Ward,
      }));
      setHRExcelDataJony(tempHRdataJony);
    });
  };

  const checkPermissions = (permission) => {
    let array = filter(auth.item.userRole.permissions, (item) => {
      return item.name == permission;
    });
    return array;
  };

  const customStyles = {
    container: () => ({
      border: "1px solid #f54394",
      borderRadius: "4px",
      boxShadow: "0 0 0 1px #f54394",
    }),
  };

  const { instance, accounts } = useMsal();

  function checkOutlookLoginStatus() {
    if (accounts.length > 0) {
      // User is logged in
      setOutlookLoggedIn(true);
    } else {
      // User is not logged in
      setOutlookLoggedIn(false);
    }
  }

  const handleLoginOutLook = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        // Successful login

        if (response && response?.account) {
          setOutlookLoggedIn(true);
        } else {
          setOutlookLoggedIn(false);
          setOutlookLoggedInError(true);
        }
      })
      .catch((error) => {
        // Handle login error
        setOutlookLoggedIn(false);
        setOutlookLoggedInError(true);
      });
  };

  useEffect(() => {
    // Call the function to check the login status
    checkOutlookLoginStatus();
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Col sm={4}>
          <FormGroup tag="fieldset">
            <Label>Please Select</Label>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  value="NHS"
                  onChange={changeHandler}
                />
                NHSP
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input
                  type="radio"
                  name="radio1"
                  value="HealthRoster"
                  defaultChecked={!check}
                  onChange={changeHandler}
                />
                Health Roster
              </Label>
            </FormGroup>
            {check ? null : checkPermissions("BOT").length > 0 ? null : (
              <>
                <label className="ui-check m-0">
                  <input
                    type="checkbox"
                    name="id"
                    id="firstPageOnly"
                    checked={firstPageOnly}
                    onChange={() => setFirstPageOnly(!firstPageOnly)}
                  />{" "}
                  <i></i>
                </label>
                First page only
              </>
            )}
          </FormGroup>
        </Col>

        <Col sm={8}>
          <Label sm={4}>{ClientLable} Name</Label>

          {/* <Select
            isMulti
            name="hospitals"
            className="basic-multi-select"
            classNamePrefix="select"
            options={clients}
          /> */}

          {clients != null ? (
            <Select
              //isMulti
              type="select"
              name="hospital"
              id="client"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => {
                setClientData(data);
                setNoClientSelected(false);
              }}
              styles={noClientSelected && customStyles}
              options={clients}
              onFocus={() => setNoClientSelected(false)}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            />
          ) : null}

          {noClientSelected && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                Please select a client.
              </li>
            </ul>
          )}

          {checkPermissions("BOT").length > 0 ? (
            <div>
              <Label className="ml-2 mt-1">{ClientLable} Name Bot</Label>
              <Input
                type="text"
                id="clentName"
                onChange={(e) => setClientNameBot(e.target.value)}
              ></Input>
              <Label className="ml-2 mt-1">{ClientLable} Id Bot</Label>
              <Input
                type="text"
                id="clientId"
                onChange={(e) => setClientIdBot(e.target.value)}
              ></Input>
            </div>
          ) : null}

          {/* {clients != null ? (
            <select
              name="cars"
              id="cars"
              onChange={(e) => setClientData(e.target.value)}
            >
              <option value={0}>Select</option>
              {clients.map((item, id) => {
                return (
                  <option
                    value={{
                      id: item.id,
                      label: item.label,
                      value: item.label,
                    }}
                    key={id}
                  >
                    {item.label}
                  </option>
                );
              })}
            </select>
          ) : null} */}

          {/* <input 
            type="select"
            id="hospital"
            name="hospital"
            onChange={(data) => setClientData(data)}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          >
            <option value={0}>Select</option>
            {clients.map((item, id) => {
              return (
                <option value={item.label} key={id}>
                  {item.label}
                </option>
              );
            })}
          </input> */}
        </Col>
      </FormGroup>

      {check ? (
        <FormGroup row>
          <Col sm={12}>
            <Label>Search </Label>
            <Input
              type="textarea"
              name="searchText"
              id="exampleText"
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            />
          </Col>
        </FormGroup>
      ) : (
        <div className={"d-flex justify-content-center align-items-center"}>
          <FormGroup row>
            <Col sm={12}>
              {/* <Label>Please upload excel file to search </Label> */}

              <div
                className="d-flex justify-content-center align-items-center"
                style={{
                  border: "solid 2px  ",
                  borderRadius: "2px",
                  borderColor: noFile ? "#f54394" : "#007bff",
                  width: "450px",
                  margin: "10px",
                }}
                {...getRootProps()}
              >
                <input id="dropZone" {...getInputProps()} />

                {fileuploaded ? (
                  <p className="m-3">Uploaded successfully</p>
                ) : (
                  <>
                    {" "}
                    {isDragActive ? (
                      <p className="m-3">Drop the file here ...</p>
                    ) : (
                      <p className="m-3">
                        1. Select a {ClientLable}
                        <br />
                        2. Drag 'n' drop excel file here, or click to select
                        file
                      </p>
                    )}
                  </>
                )}
              </div>

              {checkPermissions("BOT").length > 0 ? (
                <Input
                  type="file"
                  name="fileBot"
                  id="fileBot"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    readExcel(file);
                    setFileuploaded(true);
                    setNoFile(false);
                  }}
                  //  innerRef={register({
                  //    required: {
                  //      value: true,
                  //      message: "This field is required!",
                  //    },
                  //  })}
                />
              ) : null}
              {noClientSelected && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    Please select a file.
                  </li>
                </ul>
              )}
            </Col>
          </FormGroup>
        </div>
      )}

      {!outlookLoggedIn && (
        <Alert
          className="d-flex align-items-center justify-content-center flex-column "
          color="danger"
        >
          <p>
            To send a booking confirmation via email, kindly ensure that you are
            logged in first
          </p>
          <img src={outlookLogo} style={{ height: 100 }}></img>
          <u className="text-info pointer ml-4" onClick={handleLoginOutLook}>
            Login
          </u>
        </Alert>
      )}
      <div className="d-flex justify-content-end">
        <Button
          id="FinalSearch"
          className="mr-2"
          color="success"
          type="submit"
          disabled={!outlookLoggedIn}
        >
          Search
        </Button>
        <Button id="close" color="secondary" onClick={() => closeModal()}>
          Cancel
        </Button>
      </div>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(GroupSearch);
