import React, { useEffect, useState } from "react";
import {
  faTrashAlt,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { forEach } from "lodash";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../../components/toastCommon";
import { Button, Input, Form, Alert, Label } from "reactstrap";
import { PERSONAL_NOTE } from "../../../../configs/api-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Delete from "../../../../components/Modal/ModalBody/delete";
import useDidMountEffect from "../../../../components/Modal/ModalBody/intialRunUseEffect";

const PersonalNote = (props) => {
  const MexxarPersonalNote = PERSONAL_NOTE;

  const { candidateId } = props;

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();

  const [cID, setCID] = useState("");
  const [rows, setRows] = useState([]);
  const [add, setAdd] = useState(false);
  const [rowID, setRowID] = useState("");
  const [content, setContent] = useState();
  const [showDeleteRow, setShowDeleteRow] = useState(false);

  const getAllRows = () => {
    axios
      .get(MexxarPersonalNote + "/" + cID)
      .then((res) => {
        let PersonalNotes = [];

        forEach(res.data.body[0].notes, function (item) {
          PersonalNotes.push(item.note);
        });
        setRows(res.data.body[0].notes);
        // setContent(PersonalNotes);
      })
      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };

  const deleteRow = () => {
    axios
      .delete(MexxarPersonalNote + "/" + rowID)
      .then((res) => {
        getAllRows();
        ToastCommon(true, "delete", addToast);
      })
      .catch((err) => {
        //console.log(err)
        //ToastCommon(true, "update", addToast);
      });
  };

  const onSubmit = (data, e) => {
    if (data.Pnote == "") {
      ToastCommon(true, "addANote", addToast);
    } else {
      if (rows.length > 0) {
        axios
          .post(MexxarPersonalNote + "/candidate/" + candidateId, {
            note: data.Pnote,
          })
          .then((res) => {
            if (res.data.status == "success") {
              getAllRows();
              ToastCommon(true, "success", addToast);
              e.target.reset();
            } else {
              //ToastCommon(true, "duplicate", addToast);
            }
          })
          .catch((err) => {
            //console.log(err)
            // ToastCommon(true, "error", addToast);
          });
      } else {
        axios
          .post(MexxarPersonalNote, {
            candidateId: candidateId,
            notes: [data.Pnote],
          })
          .then((res) => {
            if (res.data.status == "success") {
              getAllRows();
              ToastCommon(true, "success", addToast);
              e.target.reset();
            } else {
              //ToastCommon(true, "duplicate", addToast);
            }
          })
          .catch((err) => {
            //console.log(err)
            // ToastCommon(true, "error", addToast);
          });
      }
    }
  };

  useDidMountEffect(() => {
    // addRow();
  }, [add]);
  useEffect(() => {
    setCID(candidateId);
  }, [candidateId]);

  useDidMountEffect(() => {
    getAllRows();
  }, [cID]);

  return (
    <div className="row   ">
      <div className="card-body col-md-12">
        <div className=" d-flex border-bottom p-1 ">
          <div className="ml-3 "></div>
        </div>

        <div className={"mx-3"}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className={"d-flex justify-content-between mt-2"}>
              <Input
                className="mr-1"
                type="text"
                placeholder=""
                // onChange={(e) => handleChange(e)}
                name="Pnote"
                id="Pnote"
                innerRef={register}
              />

              <Button className="mr-1 " color="success" type="submit">
                +
              </Button>
            </div>
            <Label className="mt-2">Your Notes :</Label>
          </Form>
        </div>
        <div style={{ overflowY: "auto", maxHeight: "90vh" }}>
          {rows.length > 0 ? (
            rows
              .slice(0)
              .reverse()
              .map((note, id) => {
                return (
                  <div
                    className="collection-item mx-3 mt-2"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    key={note.id}
                  >
                    <span>{note.note}</span>
                    <FontAwesomeIcon
                      // color="#FF5603"
                      icon={faTrashAlt}
                      onClick={() => {
                        setShowDeleteRow(true);
                        setRowID(note.id);
                      }}
                      className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave pointer "
                      size="1x"
                    />
                  </div>
                );
              })
          ) : (
            <div className="text-mute mx-3 mt-2 ">
              <Alert color="info">
                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
                Add a personal note
              </Alert>
            </div>
          )}
        </div>
      </div>

      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteRow();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(!showDeleteRow)}
      />
    </div>
  );
};

export default PersonalNote;
