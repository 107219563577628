import React from "react";
import { Card, CardBody, Label } from "reactstrap";
import axios from "axios";
import Data from "../booking-summery-card/bookings.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPhone,
  faMicrophoneSlash,
  faMicrophone,
  faUpload,
  faChevronLeft,
  faChevronRight,
  faUser,
  faPhoneSquare,
  faMapPin,
  faMapMarkerAlt,
  faExpand,
  faPeopleArrows,
  faEdit,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
const UnconfirmedBookingWidget = (props) => {
  const { userProfile } = props;
  axios.get("bookings.json").then((res) => {
    this.setState({
      booking: res.data,
    });
  });
  return (
    <div className="">
      {Data.map((booking,i) => {
        return (
          <div key={i}  className="card  list-row mb-2 ">
            <div className="list-item  " data-id="7" data-sr-id="44">
              <div>
                <label className="ui-check m-0">
                  <input name="id" type="checkbox" value="7" />
                  <i></i>
                </label>
              </div>

              <div>
                <a data-pjax-state="" href="/employee/candidate/6">
                  <span
                    className="w-40 avatar bg-primary"
                    data-toggle-className="loading"
                  >
                    {/* {booking.statusColor ? (
                    <FontAwesomeIcon icon={faCheck} className="ml-3" />
                  ) : (
                    <FontAwesomeIcon icon={faEdit} className="ml-3" />
                  )} */}
                  </span>
                </a>
              </div>
              <div className="flex">
                <div> {booking.title}</div>

                <div className="item-mail text-muted h-1x d-none d-sm-block">
                  <time
                    dateTime="2020-12-20T00:00:00Z"
                    data-local="time"
                    data-format="%B %e, %Y %l:%M%P"
                    title="December 20, 2020 at 5:30am IST"
                    data-localized=""
                    aria-label="December 20, 2020 5:30am"
                  >
                    <small>{booking.time} </small>
                  </time>
                </div>
              </div>

              <div>
                <div>Shift Type</div>
                <div>{booking.type}</div>
              </div>
              <div>
                <div>Ward</div>
                <div>{booking.ward}</div>
              </div>
              <div>
                <div>Reference No.</div>
                <div>{booking.referenceNo}</div>
              </div>
              <div>
                <div className="item-action dropdown">
                  <a
                    className="text-muted"
                    data-pjax-state=""
                    data-toggle="dropdown"
                    href="#"
                  >
                    <svg
                      className="feather feather-more-vertical"
                      fill="none"
                      height="16"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      width="16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="12" cy="12" r="1"></circle>
                      <circle cx="12" cy="5" r="1"></circle>
                      <circle cx="12" cy="19" r="1"></circle>
                    </svg>
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right bg-black"
                    role="menu"
                  >
                    <a className="dropdown-item" data-pjax-state="" href="#">
                      See detail
                    </a>
                    <a className="dropdown-item download" data-pjax-state="">
                      Download
                    </a>
                    <a className="dropdown-item edit" data-pjax-state="">
                      Edit
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item trash" data-pjax-state="">
                      Delete item
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default UnconfirmedBookingWidget;
