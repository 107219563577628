import React from "react";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import "./employee-profile.style.css";

const EmployeeProfile = () => {
  return (
    <DashboardLayout
      title="Employee Profile"
      subTitle="Employee Profile"
    ></DashboardLayout>
  );
};

export default EmployeeProfile;
