import React, { useState, useEffect } from "react";
import ReferenceFormTemplate from "../../../../../components/Modal/ModalBody/referenceFormTemplate";
import { forEach, filter } from "lodash";
import {
  CompanyName,
  REGISTER_CANDIDATE,
} from "../../../../../configs/api-config";
import axios from "axios";
import notNull from "../../../../../util/common/notNull";
import { Alert } from "reactstrap";

function identifyObject(objects) {
  let zeroArr = [];
  let oneArr = [];
  let twoArr = [];
  objects.forEach((obj) => {
    let lastChar = obj.identifier[obj.identifier.length - 1];
    let propertyName = obj.identifier.slice(0, -1);
    let newObject = {};
    newObject[propertyName + "ID"] = obj.id;
    newObject[propertyName] = obj.value;

    if (lastChar === "0") {
      zeroArr.push(newObject);
    } else if (lastChar === "1") {
      oneArr.push(newObject);
    } else if (lastChar === "2") {
      twoArr.push(newObject);
    }
  });

  let finalZeros = Object.assign({}, ...zeroArr);
  let finalOnes = Object.assign({}, ...oneArr);
  let finalTwos = Object.assign({}, ...twoArr);

  return { finalZeros, finalOnes, finalTwos };
}

const References = (props) => {
  let pathArray = window.location.pathname.split("/");
  var CandidateId = Number(pathArray[3]);

  const MexxarApi = REGISTER_CANDIDATE;

  const [candidateID, setCandidateID] = useState(null);
  const [rows0, setRows0] = useState([]);
  const [rows1, setRows1] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [getLoading, setGetLoading] = useState(false);
  const [postLoading, setPostLoading] = useState(false);

  const getData = () => {
    setGetLoading(true);
    axios
      .get(
        MexxarApi +
          "/" +
          CandidateId +
          "/advance-profile-details/category/REFERENCES"
      )
      .then((res) => {
        let list = res.data.body;
        let result = identifyObject(list);

        setRows0([result?.finalZeros]);
        setRows1([result?.finalOnes]);
        setRows2([result?.finalTwos]);
      })
      .catch((err) => {
        // ToastCommon(true, "error", addToast);
      });
    setGetLoading(false);
  };

  useEffect(() => {
    getData();
    return () => {};
  }, []);

  useEffect(() => {
    let pathArray = window.location.pathname.split("/");
    var CandidateId = Number(pathArray[3]);
    setCandidateID(CandidateId);
  }, []);
  const toggleR = () => {};
  return (
    <div className=" col-md-12 card p-4 ">
      {candidateID && (
        <>
          <Alert>
            <b>Note : </b>Please provide 2 referee details so that {CompanyName}{" "}
            can contact them for references
          </Alert>
          <ReferenceFormTemplate
            closeModal={() => toggleR()}
            page="AdvancedDetails"
            candidateId={candidateID}
            refId={0}
            rows={rows0}
            getData={()=>{}}
          />
          <ReferenceFormTemplate
            closeModal={() => toggleR()}
            page="AdvancedDetails"
            candidateId={candidateID}
            refId={1}
            rows={rows1}
            getData={()=>{}}

          />
          <ReferenceFormTemplate
            closeModal={() => toggleR()}
            page="AdvancedDetails"
            candidateId={candidateID}
            refId={2}
            rows={rows2}
            getData={()=>{}}

          />
        </>
      )}
    </div>
  );
};

export default References;
