import React, { useState, useEffect } from "react";
import { Button, Col, Label, Input, UncontrolledTooltip } from "reactstrap";
import axios from "axios";
import moment from "moment";
import ModalComponent from "../../components/Modal/modalComponent";
import TimeSheet from "../../components/Modal/ModalBody/timeSheet";
import Summery from "../../components/Modal/ModalBody/summary";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import ShowReport from "../../components/Modal/ModalBody/showReport";
import { ClientLable, HOSTMexxar } from "../../configs/api-config";
import filter from "lodash/filter";

const PayrollSummeryWidget = () => {
  const [rows, setRows] = useState(null);
  const [certificates, setCertificates] = useState([]);
  const MexxarAPI = HOSTMexxar;
  const [calendarShow, setCalendarShow] = useState(false);
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [data, setData] = useState();
  const [showSummary, setShowSummary] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showHospitalModal, setShowHospitalModal] = useState(false);
  const [showClientModal, setShowClientModal] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [receivedReport, setReceivedReport] = useState();

  const toggleShowHospitalModal = () => {
    // let slectedRows = filter(selectedItems, (item) => {
    //   return item == rowId;
    // });

    let tempArray = [];

    for (let i = 0; i < certificates.length; i++) {
      for (let x = 0; x < selectedItems.length; x++) {
        if (certificates[i].id === selectedItems[x]) {
          tempArray.push(certificates[i]);
        }
      }
    }

    // console.log('====================================');
    // console.log("selectedItems Rows : ", selectedItems);
    // console.log("Selected Rows : ", tempArray);
    // console.log('====================================');

    // axios.post(`${MexxarAPI}workspace/credit-control/generate-report`, tempArray, {responseType: 'blob',  headers: {"Accept": "application/pdf"}}).then((response)=>{
    axios
      .post(`${MexxarAPI}workspace/credit-control/generate-report`, tempArray)
      .then((response) => {
        // console.log('====================================');
        let contentType = response.headers["content-type"];
        const blob = new Blob([response], { type: contentType });
        //  console.log("Responce generated report :", response);
        setReceivedReport(response);
        setShowReport(!showReport);
        // console.log('====================================');
      });

    setShowHospitalModal(!showHospitalModal);
  };

  const toggleShowClientModal = () => {
    setShowClientModal(!showClientModal);
  };

  const toggleShowTimeModal = (data) => {
    setData(data);
    setShowTimeModal(!showTimeModal);
  };

  const toggleShowSummary = (data) => {
    setData(data);
    setShowSummary(!showSummary);
  };

  const getCandidates = () => {
    axios
      .get(MexxarAPI + "workspace/payroll?ps=PENDING&hrs=PENDING&rs=CLEARED")
      .then((candidates) => {
        setCertificates(candidates.data.body);
        setRows(candidates.data.body);
      });
  };

  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
    } else {
      setSelectedItems([...selectedItems, rowId]);
    }
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  const isSelected = (id) => {
    // setShowProccedButton(false);
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getCandidates();
  }, []);

  return (
    <div style={{ minWidth: "800px" }}>
      <div className="ml-2">
        <label className="ui-check m-0">
          <Input
            type="checkbox"
            name="id"
            checked={selectAll}
            onChange={() => checkAll()}
          />
          <i></i>
        </label>
        <Label className="text-color text-sm" id="selectAll">
          Select
        </Label>
        <UncontrolledTooltip placement="right" target="selectAll">
          Procced timesheets to {ClientLable}s
        </UncontrolledTooltip>

        {selectedItems.length > 0 ? (
          <div className="mb-2">
            <Button
              className="primary mx-3"
              onClick={() => toggleShowHospitalModal()}
            >
              Proceed
            </Button>
            {/* <Button
              className="primary mx-3"
              onClick={() => toggleShowClientModal()}
            >
              Proceed to Candidate
            </Button> */}
          </div>
        ) : null}
      </div>
      {certificates.map((post, i) => {
        return (
          <div key={i} className="card mb-2 ">
            <div className="card-body">
              <div className="flex flex-row d-flex justify-content-between ">
                <div className="d-flex  " style={{ width: "250px" }}>
                  <div className="d-flex align-items-center mr-3 ">
                    <span className="mx-2">
                      {/* <b className="badge badge-circle sm text-primary"></b> */}
                      <label className="ui-check m-0">
                        <input
                          type="checkbox"
                          name="id"
                          checked={isSelected(post.id)}
                          onChange={() => {
                            onItemSelect(post.id);
                          }}
                        />{" "}
                        <i></i>
                      </label>
                    </span>
                  </div>
                  <div>
                    <div>{post.booking.candidateName}</div>

                    <div className="item-mail text-muted h-1x d-none d-sm-block">
                      <time
                        dateTime="2020-12-20T00:00:00Z"
                        data-local="time"
                        data-format="%B %e, %Y %l:%M%P"
                        title="December 20, 2020 at 5:30am IST"
                        data-localized=""
                        aria-label="December 20, 2020 5:30am"
                      >
                        <small>
                          Booking Date :{" "}
                          {moment(post.booking.bookingDate).format("llll")}
                        </small>
                      </time>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ width: "60px" }}
                ></div>

                <div className="textCenter-Custom">
                  <div>Hospital Name</div>
                  <div className="text-muted">{post.hospitalName}</div>
                </div>
                <div className="textCenter-Custom">
                  <div>Submited Date</div>
                  <div className="text-muted">
                    {" "}
                    {moment(post.submitedDate).format("llll")}
                  </div>
                </div>
                <div>
                  <Button
                    color="success"
                    className="mr-2"
                    onClick={() => {
                      toggleShowTimeModal(post);
                    }}
                  >
                    Show
                  </Button>
                  <Button
                    color="success"
                    className="mr-2"
                    onClick={() => {
                      toggleShowSummary(post);
                    }}
                  >
                    Summary
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <ModalComponent
        show={calendarShow}
        header="Log new call back"
        closeModal={() => handleCalendarShow(false)}
      >
        <SmallCalendar />
        <div className="mt-3">
          {" "}
          <Button color="success">Send</Button>{" "}
          <Button color="secondary" onClick={() => handleCalendarShow(false)}>
            Cancel
          </Button>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showTimeModal}
        header="Time Sheet"
        closeModal={() => setShowTimeModal(false)}
      >
        <TimeSheet
          data={data}
          closeModal={() => setShowTimeModal(false)}
        ></TimeSheet>
        <div className="mt-4">
          <Col sm={{ size: 8, offset: 8 }}></Col>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showSummary}
        header="Candidate's Credit Summary"
        closeModal={() => setShowSummary(false)}
      >
        <Summery data={data} closeModal={() => setShowSummary(false)}></Summery>
        {/* <TimeSheet
          data={data}
          closeModal={() => setShowSummary(!showSummary)}
        ></TimeSheet> */}
        <div className="mt-4">
          <Col sm={{ size: 8, offset: 8 }}></Col>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showReport}
        header="Report"
        closeModal={() => setShowReport(false)}
      >
        <ShowReport
          report={receivedReport}
          payroll={false}
          closeModal={() => setShowReport(false)}
        ></ShowReport>
      </ModalComponent>
    </div>
  );
};

export default PayrollSummeryWidget;
