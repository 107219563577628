import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Label, Button, ButtonGroup } from "reactstrap";

const data = {
  labels: [
    "Headhunting",
    "Registration",
    "Maintenance",
    "Audit",
    "Booking",
    "Payroll",
  ],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(54, 162, 235, 0.2)",
        "rgba(255, 206, 86, 0.2)",
        "rgba(75, 192, 192, 0.2)",
        "rgba(153, 102, 255, 0.2)",
        "rgba(255, 159, 64, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(54, 162, 235, 1)",
        "rgba(255, 206, 86, 1)",
        "rgba(75, 192, 192, 1)",
        "rgba(153, 102, 255, 1)",
        "rgba(255, 159, 64, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const DoughnutChart = () => (
  <div className="card">
    <div className="card-body">
      <div className="header">
        <Label className="title">
          Team Performance{" "}
          <ButtonGroup className="ml-3" size="sm">
            <Button>Daily</Button>
            <Button>Weekly</Button>
            <Button>Monthly</Button>
            <Button>Yearly</Button>
          </ButtonGroup>
        </Label>
      </div>

      <Doughnut data={data} />
    </div>
  </div>
);

export default DoughnutChart;
