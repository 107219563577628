import React from "react";
import { useState } from "react";
import { HOSTMexxar } from "../../../configs/api-config";
import SMSTab from "./smsTab";
import TabNavigationMenu from "../../../util/common/tabNavigation";
import TabNavigationMenuModal from "../../../util/common/tabNavigationMenuModal";

const SMSWIDGET = (props) => {
  const { style, userPhoneNo } = props;
   
  const [currentTab, setCurrentTab] = useState("received");

  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <>
      <TabNavigationMenuModal
        activated={currentTab}
        onChangeItem={switchTabHandler}
        itemList={[
          { name: "Received", key: "received" },
          { name: "Sent", key: "sent" },
        ]}
      />

      {currentTab === "sent" && (
        <SMSTab userPhoneNo={userPhoneNo} display={"RECEIVED"} />
      )}
      {currentTab === "received" && (
        <SMSTab userPhoneNo={userPhoneNo} display={"SENT"} />
      )}
    </>
  );
};

export default SMSWIDGET;
