import firebase from "../firebase";

// MachineToMachinePage /keys CRUD karanne by this..
// if needs to do any functionalities use this file

const db = firebase.ref("/chat");

class ChatDataService {
  getAll(chatScriptId) {
    let chatKey = firebase.ref("/chat/" + chatScriptId);
    return chatKey;
  }

  getAllUsers(chatScriptId) {
    let chatKey = firebase.ref("/users");
    return chatKey;
  }

  create(chat, chatScriptId) {
    // console.log("chatservice working", chat, chatScriptId);
    // add.Chat.component eken thamai chatScriptId eka Ennne..
    let chatKey = firebase.ref("/chat/" + chatScriptId);
    return chatKey.push(chat);
  }

  update(key, value) {
    return db.child(key).update(value);
  }

  delete(key) {
    return db.child(key).remove();
  }

  deleteAll() {
    return db.remove();
  }
}

export default new ChatDataService();
