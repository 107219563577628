import {
  ADD_AI_TIMESHEET_DATA,
  UPDATE_AI_TIMESHEET_DATA,
  DELETE_AI_TIMESHEET_DATA,
  GET_AI_TIMESHEET_DATA,
  ADD_SYSTEM_TIMESHEET_DATA,
  UPDATE_SYSTEM_TIMESHEET_DATA,
  DELETE_SYSTEM_TIMESHEET_DATA,
  GET_SYSTEM_TIMESHEET_DATA,
  SAVE_TIMESHEETS_LOCALLY,
  REMOVE_TIMESHEETS_LOCALLY_ALL,
  REMOVE_TIMESHEETS_LOCALLY_ONE,
  GET_LOCAL_TIMESHEETS,
  TIME_SHEET_EXIST_OR_NOT,
  UPDATE_TIMESHEET_REVIEWED_DATA,
  UPDATE_TIMESHEET_NAME_AND_EXTENTION,
  UPDATE_TIMESHEET_URL,
  UPDATE_TIMESHEET_SCAN_PROCESSING_STATUS,
  LOG_FAILED_TIMESHEETS,
  SCAN_STATUS_INDIVIDUAL,
  UPDATE_TIMESHEET_AI_REVIEWED_STATUS,
  UPDATE_TIMESHEET_AI_REVIEWED_STATUS_CANDY,
  FINAL_MANUALLY_REVIEW_CONFIRMATION,
  SAVE_GENERATED_URL,
  UPDATE_TIMESHEET_REVIEWED_DATA_CANDY,
  PRE_VERIFIED_STATUS,
} from "./types";

import store from "../store";

//AI  timesheet data

export const addAITimesheetData = (timesheetId, AIModalData) => (dispatch) => {
  dispatch({
    type: ADD_AI_TIMESHEET_DATA,
    payload: {
      id: timesheetId,
      AIModalData: AIModalData,
    },
  });
};

//system timesheet data

export const addSystemTimesheetData = (timesheetId, DBData) => (dispatch) => {
  // console.log("Angi addSystemTimesheetData--->",timesheetId, DBData)
  dispatch({
    type: ADD_SYSTEM_TIMESHEET_DATA,
    payload: {
      id: timesheetId,
      DBData: DBData,
    },
  });
};

export const timesheetExistStatus =
  (timesheetId, timeSheetExist) => (dispatch) => {
    dispatch({
      type: TIME_SHEET_EXIST_OR_NOT,
      payload: {
        id: timesheetId,
        timeSheetExist: timeSheetExist,
      },
    });
  };

export const updateScanProcessStatus = (data) => (dispatch) => {
  dispatch({
    type: UPDATE_TIMESHEET_SCAN_PROCESSING_STATUS,
    payload: data,
  });
};

export const saveGeneratedUrl = (generatedUrl) => ({
  type: SAVE_GENERATED_URL,
  payload: generatedUrl,
});

export const updateTimesheetReviewedData = (id, data) => (dispatch) => {
  dispatch({
    type: UPDATE_TIMESHEET_REVIEWED_DATA,
    payload: {
      id: id,
      reviewedData: data,
    },
  });
};

export const updateTimesheetReviewedDataCandy =
  (id, data, dateTimeNow) => (dispatch) => {
    console.log("angi updateTimesheetReview--->", id, data, dateTimeNow);
    dispatch({
      type: UPDATE_TIMESHEET_REVIEWED_DATA_CANDY,
      payload: {
        id: id,
        reviewedData: data,
        timesheetReviewDateTime: dateTimeNow || "",
      },
    });
  };
export const updateTimesheetFileName = (id, fileName) => (dispatch) => {
  dispatch({
    type: UPDATE_TIMESHEET_NAME_AND_EXTENTION,
    payload: {
      id: id,
      fileName: fileName,
    },
  });
};

export const updateTimesheetUrl = (id, s3BucketUrl) => (dispatch) => {
  dispatch({
    type: UPDATE_TIMESHEET_URL,
    payload: {
      id: id,
      s3BucketUrl: s3BucketUrl,
    },
  });
};

export const updateTimesheetAIReviewedStatus = (id, status) => (dispatch) => {
  dispatch({
    type: UPDATE_TIMESHEET_AI_REVIEWED_STATUS,
    payload: {
      id: id,
      status: status,
    },
  });
};
export const updateTimesheetAIReviewedStatusCandy =
  (id, status) => (dispatch) => {

    console.log(
      "updateTimesheetAIReviewedStatusCandy",
      id, status
    );
    dispatch({
      type: UPDATE_TIMESHEET_AI_REVIEWED_STATUS_CANDY,
      payload: {
        id: id,
        status: status,
      },
    });
  };

export const finalManualReviewConfirmation = (status) => (dispatch) => {
  dispatch({
    type: FINAL_MANUALLY_REVIEW_CONFIRMATION,
    payload: status,
  });
};

export const logFailedTimesheets = (file, errorMessage) => (dispatch) => {
  dispatch({
    type: LOG_FAILED_TIMESHEETS,
    payload: {
      file,
      errorMessage,
    },
  });
};

export const scanStatusIndividual = (id, status, message) => (dispatch) => {
  dispatch({
    type: SCAN_STATUS_INDIVIDUAL,
    payload: {
      id,
      status,
      message,
    },
  });
};

export const preVerifiedStatus = (id, status, message) => (dispatch) => {
  dispatch({
    type: PRE_VERIFIED_STATUS,
    payload: {
      id,
      status,
      message,
    },
  });
};

export const saveTimesheetsLocally = (files) => (dispatch) => {
  dispatch({
    type: SAVE_TIMESHEETS_LOCALLY,
    payload: files,
  });
};

export const deleteOneTimesheetsLocally = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_TIMESHEETS_LOCALLY_ONE,
    payload: id,
  });
};

export const deleteAllTimesheetsLocally = (id) => (dispatch) => {
  dispatch({
    type: REMOVE_TIMESHEETS_LOCALLY_ALL,
    payload: id,
  });
};
