

import React, { useEffect, useState } from "react";
import Text from "./text";
import axios from "axios";
import _, { filter } from "lodash";
import SnapShot from "./snapShot";
import { connect } from "react-redux";
import { Label, Input, FormGroup, Col, Form } from "reactstrap";
import { HOSTMexxar } from "../../../configs/api-config";
import TabNavigationMenu from "../../../util/common/tabNavigation";
import useDidMountEffect from "./intialRunUseEffect";
import ReactQuill from "react-quill";

const SnapShotNavEmail = (props) => {
    const MexxarAPI = HOSTMexxar;

    const { closeBody, user, closeModal, auth, userProfile } = props;

    const [items, setItems] = useState([]);
    const [currentTab, setCurrentTab] = useState("email");
    const [selectedTemplate, setSelectedTemplate] = useState();
    const [emailTemplateList, setEmailTemplateList] = useState(null);
    const [templateContent, setTemplateContent] = useState("");
    console.log("userProfile", userProfile)

    return (
        <>
            <Form className="col-12" >
                <div className="form-row mt-3">
                    <Label>Subject</Label>
                    <Input
                        type="text"
                        name="subject"
                        defaultValue={userProfile?.emailContent?.subject}
                        readOnly={true}
                    // innerRef={register({ required: true, max: 35 })}
                    />
                </div>
                <div className="form-row mt-3">
                    <Label>Content Type</Label>
                    <Input
                        type="text"
                        name="subject"
                        defaultValue={userProfile?.emailContent?.type}
                        readOnly={true}
                    // innerRef={register({ required: true, max: 35 })}
                    />
                </div>
                <div className="form-row my-4">
                    <ReactQuill
                        className="email-body-editor mb-4"
                        style={{ width: "100%" }}
                        readOnly={true}
                        defaultValue={userProfile?.emailContent?.content}
                    />
                </div>


                {/* <div className="form-row mt-4 ml-3">
        <Label>
          <Input
            className="pointer"
            type="checkbox"
            name="type-radio"
            value="RemoveDefaultSignature"
            // checked={removeDefaultSignature}
            // innerRef={register}
            // onChange={(e) => toggleDefaultSignature(e)}
          />
          Remove default signature
        </Label>
       
      </div> */}
                <div className="mt-4">
                    {/* <div className="d-flex justify-content-end">
          <Button className="mr-2" color="success" type="submit">
            Send
          </Button>{" "}
          <Button color="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </div> */}
                </div>
            </Form>
            {/* <TabNavigationMenu
        activated={currentTab}
        onChangeItem={switchTabHandler}
        itemList={items}
      /> */}

            {/* {currentTab === "email" && (
        <SnapShot
          closeModal={closeModal}
          selectedUser={user}
          template={selectedTemplate}
          templateBody={templateContent}
          setTemplateContent={setTemplateContent}
        />
      )}
      {currentTab === "text" && (
        <Text closeModal={closeModal} user={user} template={selectedTemplate} />
      )} */}
        </>
    );
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps, {})(SnapShotNavEmail);
