import React, { useEffect } from "react";
import "./loadingModal.css";
import rocket from "./rocket.png";
import { ModalBody, ModalFooter, ModalHeader, Modal } from "reactstrap";



const LoadingModal = (props) => {
  const {
    modalActions,
    children,
    header,
    show = false,
    toggleShow,
    className,
    scroll=false
  } = props;

  useEffect(() => {

    
    // function star() {


    //   let count = 50;
    //   let scene = document.querySelector('.scene');

    //   let i = 0;
    //   while (i < count){
    //     let star = document.createElement('i');
    //     let x = Math.floor(Math.random()* window.innerWidth);

    //     let duration = Math.random() *1;
    //     let h = Math.random() * 100;
    //     console.log("star function")
    //     star.style.left = x+"px";
    //     star.style.width = 1 + "px";
    //     star.style.height =h +"px";
    //     star.style.animationDuration = duration + 's';
    //     i++;
    //     return () =>{
    //     document.scene.appendChild();
    //     }
       
    //   }
    // }

    // star();
 
    //TODO: Do your stuff here
  }, []);

  return (
    
      <Modal isOpen={show} className={className} scrollable= {scroll} style={{backgroundColor:"#192034"}}>
        {/* {header && (
          <ModalHeader className="modal-header bg-primary" toggle={toggleShow}>
            <span style={{ color: "#ffffff" }}>{header}</span>
          </ModalHeader>
        )}
        <ModalBody className="p-4">
          {children}
          {modalActions && (
            <ModalFooter className="p-4">{modalActions}</ModalFooter>
          )}
        </ModalBody> */}
        <div className="scene">
          <div className="rocket">
            <img src={rocket}/>
          </div>

        </div>

      </Modal>
      

    
  );
};

export default LoadingModal;
