// import React, { useState } from "react";
// import JSZip from "jszip";
// import axios from "axios";
// import { Button } from "reactstrap";

// const FileDownloaderForMobile = ({ files, folderName }) => {
//   const downloadFiles = async () => {
//     const zip = new JSZip();

//     const downloadPromises = files.map(async (file, index) => {
//       try {
//         const response = await axios.get(file.url, {
//           responseType: "arraybuffer", // Ensure the response is treated as binary data
//           headers: {
//             "Content-Type": "image/jpeg", // Specify that you expect an image file (change this according to your image format)
//           },
//         });
//         const blob = new Blob([response.data], { type: "image/jpeg" }); // Create a blob from the binary data
//         const fileExtractedUrl = file.url.split("/").pop().split("?")[0]; // Extract filename from the URL
//         const fileName = file.bookingRef.includes(".")
//           ? file.bookingRef
//           : `${file.bookingRef}.${fileExtractedUrl.split(".").pop()}`;

//         zip.file(fileName, blob); // Add the image to the zip file
//       } catch (error) {
//         console.error("Error downloading file:", error);
//       }
//     });

//     Promise.all(downloadPromises)
//       .then(() => {
//         zip.generateAsync({ type: "blob" }).then((content) => {
//           const zipFileName = `${folderName + "-" + new Date()}.zip`;
//           const a = document.createElement("a");
//           a.href = window.URL.createObjectURL(content);
//           a.download = zipFileName;
//           document.body.appendChild(a);
//           a.click();
//           document.body.removeChild(a);
//         });
//       })
//       .catch((error) => console.error("Error downloading files:", error));
//   };

//   return (
//     <div>
//       <Button color="success" onClick={downloadFiles}>
//         Download Timesheets as Zip mobile
//       </Button>
//     </div>
//   );
// };
// export default FileDownloaderForMobile;

import React from "react";
import JSZip from "jszip";
import axios from "axios";
import { Button } from "reactstrap";
import imageCompression from "browser-image-compression";

const FileDownloaderForMobile = ({ files, folderName }) => {
  const downloadFiles = async () => {
    const zip = new JSZip();

    const downloadPromises = files.map(async (file, index) => {
      try {
        const response = await axios.get(file.url, {
          responseType: "arraybuffer", // Ensure the response is treated as binary data
          headers: {
            "Content-Type": "image/jpeg", // Specify the expected file type (adjust based on file type)
          },
        });

        // Create a Blob from the binary data
        const blob = new Blob([response.data], { type: "image/jpeg" });

        // Extract filename from the URL
        const fileExtractedUrl = file.url.split("/").pop().split("?")[0];
        const fileName = file.bookingRef.includes(".")
          ? file.bookingRef
          : `${file.bookingRef}.${fileExtractedUrl.split(".").pop()}`;

        // Check if the file is an image
        const isImage = ["jpg", "jpeg", "png", "gif", "bmp", "webp"].includes(
          fileName.split(".").pop().toLowerCase()
        );

        if (isImage) {
          // Convert blob to File for compression
          const imageFile = new File([blob], fileName, { type: "image/jpeg" });

          // Compress the image
          const compressedBlob = await compressImage(imageFile);
          zip.file(fileName, compressedBlob); // Add compressed image to zip
        } else {
          zip.file(fileName, blob); // Add non-image files without compression
        }
      } catch (error) {
        console.error("Error downloading file:", error);
      }
    });

    Promise.all(downloadPromises)
      .then(() => {
        zip.generateAsync({ type: "blob" }).then((content) => {
          const zipFileName = `${folderName}-${new Date().toISOString()}.zip`;
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(content);
          a.download = zipFileName;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        });
      })
      .catch((error) => console.error("Error downloading files:", error));
  };

  // Image compression function
  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 0.8, // Compress to around 800KB max
      maxWidthOrHeight: 1920, // Keep aspect ratio with size limits
      useWebWorker: true, // Use web workers for faster compression
    };

    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile; // Return compressed image blob
    } catch (error) {
      console.error("Image compression failed:", error);
      return file; // In case of error, return original file
    }
  };

  return (
    <div>
      <Button color="success" onClick={downloadFiles}>
        Download Timesheets as Zip (Mobile)
      </Button>
    </div>
  );
};

export default FileDownloaderForMobile;
