import React, { Component, useState } from "react";

import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { Input, Label } from "reactstrap";

const SliderComponent = (props) => {
  const { onStop } = props;
  const [sliderValue, setSliderValue] = useState(5);
  const onChange = (value) => {
    //console.log("onChange value",value); //eslint-disable-line
    setSliderValue(value);
  };
  function log(value) {
   
     onStop(value);
  }

  return (
    <div className=" ">
      <div className="d-flex  align-items-center">
        <div className="d-flex  align-items-center">
          <Label>Set distance</Label>
        </div>
        <div className="d-flex align-items-center m-2">
          <Input
            type="text"
            style={{ width: "80px" }}
            value={sliderValue}
          ></Input>
          <Label className="m-2">mi</Label>
        </div>
      </div>

      <Slider
       // dots
        step={1}
        defaultValue={5}
        onAfterChange={log}
        max={20}
        onChange={onChange}
        // dotStyle={{ borderColor: "orange" }}
        //  activeDotStyle={{ borderColor: "yellow" }}
      />
    </div>
  );
};
export default SliderComponent;
