import React, { useState } from "react";
import {
  faExclamationCircle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { Button, Alert } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../../../components/toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { REGISTER_CANDIDATE } from "../../../../../configs/api-config";

const MexxarApiCandidate = REGISTER_CANDIDATE;
const SubmitApplication = (props) => {
  const { candidateId } = props;

  const { addToast } = useToasts();
  const [editable, setEditable] = useState(false);

  // const handleEdit = () => {
  //   if (editable) {
  //     //edit call
  //   }
  //   setEditable(!editable);
  // };

  const updateApplicationStatus = () => {
    axios
      .put(MexxarApiCandidate + "/" + candidateId + "/application/COMPLETED")
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        // toastCommon(true, "error");
      });
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-center">
          <Alert color="info">
            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
            Are you sure you want to submit the application pack now?
          </Alert>
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div>
            {" "}
            <FontAwesomeIcon
              color="success"
              className={"fa-5x my-3 fa-tint"}
              icon={faCheckCircle}
            />
          </div>
          <div>
            <Button color="success " onClick={updateApplicationStatus}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubmitApplication;
