import React, { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  Button,
  UncontrolledTooltip,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Col,
  Row,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

import {
  faMars,
  faVenus,
  faPhone,
  faEnvelope,
  faCalendar,
  faPhoneSlash,
  faCalendarAlt,
  faCheckCircle,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import ModalComponent from "../../components/Modal/modalComponent";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import {
  HEADHUNTER_WORKSPACE_DATA,
  HOSTMexxar,
  RESCHEDULE,
  REGISTER_CANDIDATE,
  HostMexxarURL,
  JOB_BOARD,
} from "../../configs/api-config";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import moment from "moment";

import {
  ApplicationStatus,
  GenderSwitch,
  ConversationResponse,
  PermDocUploadedStatus,
  ProfileStatus,
} from "../../util/common";
import AssignConsultant from "../../components/Modal/ModalBody/assignConsultant";
import { forEach } from "lodash";
import ShowJob from "../../components/Modal/ModalBody/showJob";
import Delete from "../../components/Modal/ModalBody/delete";

const JobBoardWidget = (props) => {
  const { job } = props;

  const [rowID, setRowID] = useState(false);
  const [candidateUser, setCandidateUser] = useState();
  const [checkedStatus, setCheckedStatus] = useState();
  const [showJob, setShowJob] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);

  const { addToast } = useToasts();

  const [showAssignConsultantModal, setShowAssignConsultantModal] =
    useState(false);

  const [calendarShow, setCalendarShow] = useState(false);

  const [showEmailModal, setShowEmailModal] = useState(false);

  const toggleShowEmailModal = (user) => {
    setShowEmailModal(!showEmailModal);
    setCandidateUser(user);
  };

  function deleteJob() {
    axios
      .delete(JOB_BOARD + job.jobListingId)
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "delete", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  }

  function updateNoOfPositions(noOfPostions) {
    axios
      .put(JOB_BOARD + job.jobListingId + "?updateNo=" + noOfPostions)
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "update", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  }

  return (
    <div style={{ minWidth: "700px" }}>
      <div className="card p-1 m-1 d-flex flew-wrap">
        <Row>
          <Col xs={4}>
            <div className="p-2 align-items-start d-flex">
              <div className="d-flex p-2 flex-column ">
                <div className="p-1 capitalize">{job.jobTitle}</div>
                <div className="p-1">
                  <Badge color="success">{job.requiredRoleName} </Badge>&nbsp;
                  <Badge color="success">{job.requiredBandName} </Badge>
                </div>

                <div className="p-1">
                  {job.requiredSpecialities &&
                    job.requiredSpecialities.map((item, id) => {
                      return (
                        <div key={id}>
                          <Badge color="warning">{item.name}</Badge>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </Col>

          <Col xs={3}>
            <div className="d-flex p-2 flex-column align-items-center">
              <div className="p-1">Client</div>
              <div className="p-1 capitalize"> Kingston Hospital</div>
            </div>
          </Col>

          <Col xs={3}>
            <div className="d-flex p-2 flex-column align-items-center">
              <div className="p-1">No of Positions</div>
              <div className="p-1 w-50">
                <Input
                  type="number"
                  name="noOfPositionsAvailable"
                  defaultValue={job.noOfPositionsAvailable}
                  onChange={(e) => updateNoOfPositions(e.target.value)}
                ></Input>
              </div>
            </div>
          </Col>
          <Col
            xs={2}
            className="d-flex flex-column  align-items-center justify-content-center "
          >
            <div className=" ">
              <Button
                color="success"
                className="m-2"
                onClick={() => setShowJob(true)}
              >
                View
              </Button>
            </div>
            <div className=" ">
              <Button color="danger" onClick={() => setDeleteShow(true)}>
                Delete
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <ModalComponent
        show={showEmailModal}
        header="E mail"
        closeModal={() => setShowEmailModal(false)}
      >
        <EmailNav
          incomingPage="permCompliants"
          closeModal={() => {
            setShowEmailModal(false);
            // getClientApplications();
          }}
          user={
            {
              // selectedClient: selectedClient,
              // candidate: props.candidateData,
              // docIds: docIds,
            }
          }
        />
      </ModalComponent>

      <Delete
        show={deleteShow}
        isConfirm={() => {
          deleteJob();
          setDeleteShow(false);
        }}
        closeModal={() => setDeleteShow(false)}
      />

      <ModalComponent
        show={showJob}
        header="Job Description"
        className="modal-lg"
        closeModal={() => setShowJob(false)}
      >
        <ShowJob closeModal={() => setShowJob(false)} job={job} />
      </ModalComponent>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(JobBoardWidget);
