import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ClickPage, MailBox } from "../All_Mail_Request/MailboxRequest";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

const Pagination = (props) => {
  const {
    // totalMails,
    // crededentials,
    mailtype,
    setMail,
    page,
    setPage,
    active,
    setactive,
    emailData,
  } = props;
  // var totalEmailCount = emailData.posts.totalMail;
  // var credentials = emailData.credentials;

  var totalEmailCount = emailData.posts
    ? emailData.posts.totalMail
    : localStorage.getItem("totalMails");
  var credentials = emailData.credentials;

  const totalpage =
    totalEmailCount % 10 > 0 && totalEmailCount !== 0
      ? parseInt(totalEmailCount / 10) + 1
      : parseInt(totalEmailCount / 10);

  const setsate = (nav) => {
    if (nav > 0 && page < totalpage) {
      setPage(page + 1);
      MailBox(credentials, mailtype, setMail, page + 1);
    } else if (nav < 0 && page !== 1) {
      setPage(page - 1);
      MailBox(credentials, mailtype, setMail, page - 1);
    }
  };

  const [currentPageNo, setCurrentPageNo] = useState(1);

  return (
    <div className="px-3 py-3 mt-auto">
      <div className="d-flex align-items-center">
        <div className="flex d-flex flex-row">
          <FontAwesomeIcon
            onClick={() => {
              setsate(-1);
            }}
            icon={faChevronLeft}
            className="my-2 mx-2 btn-raised pointer"
            style={{ boxShadow: "none" }}
          />

          {totalpage >= 2 ? (
            <div className="pagination pagination-sm mx-1">
              <li
              // className={page === currentPageNo ? "active" : "inactive"}
              >
                <Link
                  to="#"
                  className="page"
                  // to={
                  //   "/email/" +
                  //   mailtype.toLowerCase() +
                  //   "/page/" +
                  //   (page === totalpage ? page - 1 : page)
                  // }
                  onClick={() => {
                    {
                      page === totalpage ? setPage(page - 1) : setPage(page);
                    }
                    // setactive(0);
                    // ClickPage(
                    //   credentials,
                    //   mailtype,
                    //   setMail,
                    //   page === totalpage ? page - 1 : page
                    // );page
                    MailBox(
                      credentials,
                      mailtype,
                      setMail,
                      page === totalpage ? page - 1 : page
                    );
                  }}
                >
                  {page === totalpage ? page - 1 : page}
                </Link>
              </li>
              <li
              // className={page === currentPageNo ? "active" : "inactive"}
              >
                <Link
                  to="#"
                  className="page"
                  // to={
                  //   "/email/" +
                  //   mailtype.toLowerCase() +
                  //   "/page/" +
                  //   (page < totalpage ? page + 1 : page)
                  // }
                  onClick={() => {
                    {
                      page < totalpage ? setPage(page + 1) : setPage(page);
                    }

                    //  setactive(1);
                    // ClickPage(
                    //   credentials,
                    //   mailtype,
                    //   setMail,
                    //   page < totalpage ? page + 1 : page
                    // );
                    MailBox(
                      credentials,
                      mailtype,
                      setMail,
                      page < totalpage ? page + 1 : page
                    );
                  }}
                >
                  {page < totalpage ? page + 1 : page}
                </Link>
              </li>
            </div>
          ) : (
            <div className="pagination pagination-sm mx-1">
              <li
              // className="active"
              >
                <Link
                  to="#"
                  className="page"
                  // to={
                  //   "/email/" +
                  //   mailtype.toLowerCase() +
                  //   "/page/" +
                  //   (page < totalpage ? page + 1 : page)
                  // }
                  onClick={() => {
                    {
                      page < totalpage ? setPage(page + 1) : setPage(page);
                    }
                    // setactive(1);

                    // ClickPage(
                    //   credentials,
                    //   mailtype,
                    //   setMail,
                    //   page < totalpage ? page + 1 : page
                    // );
                    MailBox(
                      credentials,
                      mailtype,
                      setMail,
                      page < totalpage ? page + 1 : page
                    );
                  }}
                >
                  {page < totalpage ? page + 1 : page}
                </Link>
              </li>
            </div>
          )}

          <FontAwesomeIcon
            icon={faChevronRight}
            onClick={() => {
              setsate(1);
            }}
            className="my-2 mx-2 btn-raised pointer"
            style={{ boxShadow: "none" }}
          />

          <label className="my-1 mx-3">Page No: {page}</label>
        </div>
        <div>
          <span className="text-muted">Total:</span>
          <span id="count">{totalEmailCount}</span>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    emailData: state.emailServerReducer,
  };
}

export default connect(mapStateToProps)(Pagination);
