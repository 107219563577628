import React from "react";
import { Alert, Button, Modal } from "reactstrap";

export default function Approve(props) {
  const { isConfirm, closeModal, show = false, incommingPage,  } = props;
  return (
    <Modal
      isOpen={show}
      className="text-gray "
      centered={true}
      style={{ width: "350px" }}
      backdrop={true}
      keyboard={true}
      toggle={closeModal}
    >
      <div className="p-3">
        <p className="font-weight-bold">Confirm Approve</p>
        <p>Are you sure you want to Approve this Form?</p>
        {/* {incommingPage == "permDocs" && (
          <Alert color="danger">
            Caution:When you delete this document all the associated
            applications with this document will be permantly deleted
          </Alert>
        )} */}
        <div className="float-right">
          <Button color="success" className="mr-1" onClick={() => isConfirm()}>
            Yes
          </Button>
          <Button color="secondary" onClick={() => closeModal()}>
            No
          </Button>
        </div>
      </div>
    </Modal>
  );
}
