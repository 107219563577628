import React, { useState } from "react";
import axios from "axios";
import { forEach } from "lodash";
import { useEffect } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  CANDIDATE_SPECIALITY,
  CVSOURCE,
  HOSTMexxar,
  TRAININGPROVIDERS,
} from "../../../../configs/api-config";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Row,
  FormFeedback,
  Alert,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import ToastCommon from "../../../toastCommon";
import { useToasts } from "react-toast-notifications";
import { connect } from "react-redux";
import store from "../../../../redux/store";
import { cvSourcesGetAction } from "../../../../redux/actions/initialDataGetAction";

const AddUser = (props) => {
  const MexxarApiSpeciality = CANDIDATE_SPECIALITY;
  const MexxarApiTrainingProviders = TRAININGPROVIDERS;

  const { register, handleSubmit, errors } = useForm();
  const {
    onCreate,
    closeModal,
    mode,
    modalType,
    fieldData,
    specialityShow,
    specialityIncomingData,
    trainingProviderShow,
    trainingProviderIncomingData,
    rowID,
    initialDataGetReducer,
    getAllRows,
    auth,
    resetFilters,
    lastContacted,
    mobileReg=false,
    // cvSources,
  } = props;

  const [speciality, setSpeciality] = useState([]);
  const [trainingProviders, setTrainingProviders] = useState([]);
  const [addKillLog, setaddKillLog] = useState(false);
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [selectedTrainingProviders, setSelectedTrainingProviders] = useState(
    []
  );
  const [rows, setRows] = useState([]);
  const [cvSources, setCvSources] = useState([]);
  const { addToast } = useToasts();
  const MexxarApiCVSources = CVSOURCE;

  const getCVSources = () => {
    axios
      .get(MexxarApiCVSources)
      .then((res) => {
        setCvSources(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
      });
  };
  // console.log("rows", rows)
  // console.log("speciality", speciality)
  // console.log("rowID", rowID)
  console.log("initialDataGetReducer", auth);

  useEffect(() => {
    getCVSources();
  }, []);

  const getSpecialities = () => {
    axios
      .get(MexxarApiSpeciality)
      .then((res) => {
        let specialities = [];
        forEach(res.data.body, function (item) {
          specialities.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });
        console.log("specialities", specialities);
        setSpeciality(specialities);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const [selectedCVSource, setSelectedCVSource] = useState({
    id: "",
    value: "",
    label: "",
  });

  const reArrangeCVSources = (data) => {
    let reArranged = [];
    forEach(data, function (item) {
      reArranged.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    return reArranged;
  };
  const getTrainingProviders = () => {
    axios
      .get(MexxarApiTrainingProviders)
      .then((res) => {
        let trainingProviders = [];
        forEach(res.data.body, function (item) {
          trainingProviders.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });
        setTrainingProviders(trainingProviders);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const getRowById = () => {
    axios
      .get(HOSTMexxar + (mobileReg?"mobileSignUp/":"onboarding/") + rowID)
      .then((res) => {
        console.log("hello", res?.data?.body)
        setRows(res?.data?.body??[]);

        ;
      })

      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };

  useEffect(() => {
    getRowById();
  }, []);

  useEffect(() => {
    if (specialityShow === true) {
      setSelectedSpecialities(specialityIncomingData);
      getSpecialities();
    }
    if (trainingProviderShow === true) {
      setSelectedTrainingProviders(trainingProviderIncomingData);
      getTrainingProviders();
    }
  }, []);
  console.log("lastContacted....", lastContacted);

  const onSubmit = (data) => {
    data.employeeId = auth?.item?.id;
    data.id = rowID;

    if (data.lastContactedRef) {
      const dateObj = new Date(data.lastContactedRef);
      const formattedDate = `${dateObj.getFullYear()}/${(dateObj.getMonth() + 1)
        .toString()
        .padStart(2, "0")}/${dateObj.getDate().toString().padStart(2, "0")}`;
      data.lastContactedRef = formattedDate;
    }
    if (mode === "Update") {
      UpdateUser(data);
      data.rowID = rowID;
    } else {
      axios
        .post(HOSTMexxar + "onboarding", data)
        .then((res) => {
          ToastCommon(true, "success", addToast);
          closeModal();
          resetFilters();
        })
        .catch((err) => {
          ToastCommon(true, "error", addToast);
        });
    }
  };
  const UpdateUser = (data) => {
    data.lastContactedRef = lastContacted;
    axios
      .put(HOSTMexxar + (mobileReg?"mobileSignUp/update":"onboarding"), data)
      .then((res) => {
        // Handle success response
        ToastCommon(true, "success", addToast);
        closeModal();
        resetFilters();
      })
      .catch((err) => {
        // Handle error response
        ToastCommon(true, "error", addToast);
      });
  };

  const arrangeSpecialities = (data) => {
    setSelectedSpecialities(data);
  };
  const arrangeTrainingProviders = (data) => {
    setSelectedTrainingProviders(data);
  };

  const [gender, setGender] = useState("");

  useEffect(() => {
    setGender(rows?.gender??"");
  }, [rows.gender]);

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const [selectedSource, setSelectedSource] = useState("");

  useEffect(() => {
    // Set the initial value from rows.candidateSource.name if it exists
    if (rows?.candidateSource?.name) {
      setSelectedSource(rows.candidateSource.id);
    }
  }, [rows?.candidateSource?.name]);

  const handleSourceChange = (e) => {
    setSelectedSource(e.target.value);
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={4}>First Name  <span className="text-danger ml-1">*</span></Label>
       
        <Col sm={8}>
          <Input
            type="text"
            name="firstName"
            className="capitalize"
            defaultValue={rows.firstName}
            invalid={errors.firstName ? true : false}
            innerRef={register({
              required: "This field is required!",
              maxLength: {
                value: 150,
                message: "This field only allows up to 150 characters",
              },
            })}
          />
          <FormFeedback>
            {errors.firstName && errors.firstName.message}
          </FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Last Name <span className="text-danger ml-1">*</span> </Label>
         <Col sm={8}>
          <Input
            type="text"
            name="lastName"
            className="capitalize"
            defaultValue={rows.lastName}
            invalid={errors.lastName ? true : false}
            innerRef={register({
              required: "This field is required!",
              maxLength: {
                value: 150,
                message: "This field only allows up to 150 characters",
              },
            })}
          />
          <FormFeedback>
            {errors.lastName && errors.lastName.message}
          </FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Email <span className="text-danger ml-1">*</span></Label>
         <Col sm={8}>
          <Input
            type="text"
            name="email"
            defaultValue={rows.email}
            invalid={errors.email ? true : false}
            innerRef={register({
              required: "This field is required!",
              maxLength: {
                value: 150,
                message: "This field only allows up to 150 characters",
              },
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Invalid email address",
              },
            })}
          />
          <FormFeedback>{errors.email && errors.email.message}</FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Primary Mobile <span className="text-danger ml-1">*</span></Label>
         <Col sm={8}>
          <Input
            type="text"
            name="primaryMobile"
            className="capitalize"
            defaultValue={rows.primaryMobile}
            invalid={errors.primaryMobile ? true : false}
            innerRef={register({
              required: "This field is required!",
              maxLength: {
                value: 15, // Assuming mobile number length shouldn't exceed 15 digits
                message: "Mobile number can't exceed 15 digits",
              },
              pattern: {
                value: /^[0-9]+$/, // Ensures only numbers are allowed
                message: "Only numeric values are allowed",
              },
            })}
          />
          <FormFeedback>
            {errors.primaryMobile && errors.primaryMobile.message}
          </FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Secondary Mobile/WhatsApp </Label>
        <Col sm={8}>
          <Input
            type="text"
            name="secondaryMobile"
            className="capitalize"
            defaultValue={rows.secondaryMobile}
            invalid={errors.secondaryMobile ? true : false}
            innerRef={register({
              // required: "This field is required!",
              maxLength: {
                value: 15,
                message: "Mobile number can't exceed 15 digits",
              },
              pattern: {
                value: /^[0-9]+$/,
                message: "Only numeric values are allowed",
              },
            })}
          />
          <FormFeedback>
            {errors.secondaryMobile && errors.secondaryMobile.message}
          </FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Land Line</Label>
        <Col sm={8}>
          <Input
            type="text"
            name="landLine"
            className="capitalize"
            defaultValue={rows.landLine}
            invalid={errors.landLine ? true : false}
            innerRef={register({
              // required: "This field is required!",
              maxLength: {
                value: 15,
                message: "Landline number can't exceed 15 digits",
              },
              pattern: {
                value: /^[0-9]+$/,
                message: "Only numeric values are allowed",
              },
            })}
          />
          <FormFeedback>
            {errors.landLine && errors.landLine.message}
          </FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Gender <span className="text-danger ml-1">*</span></Label>
         <Col sm={8}>
          <Input
            type="select"
            name="gender"
            value={gender}
            onChange={handleGenderChange}
            innerRef={register({
              required: "Please select a gender",
            })}
            invalid={errors.gender}
          >
            <option value="">Select</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </Input>
          <FormFeedback>{errors.gender && errors.gender.message}</FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>NMC Number</Label>
        <Col sm={8}>
          <Input
            type="text"
            name="nmcNumber"
            className="capitalize"
            defaultValue={rows.nmcNumber}
            invalid={errors.nmcNumber ? true : false}
            innerRef={register({
              // required: "This field is required!",
              maxLength: {
                value: 20,
                message: "This field only allows up to 20 characters",
              },
              pattern: {
                value: 20,
                message: "This field only allows up to 20 characters",
              },
            })}
          />
          <FormFeedback>
            {errors.nmcNumber && errors.nmcNumber.message}
          </FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Address <span className="text-danger ml-1">*</span></Label>
         <Col sm={8}>
          <Input
            type="text"
            name="address"
            className="capitalize"
            defaultValue={rows.address}
            invalid={errors.address ? true : false}
            innerRef={register({
              required: "This field is required!",
              maxLength: {
                value: 150,
                message: "This field only allows up to 150 characters",
              },
            })}
          />
          <FormFeedback>
            {errors.address && errors.address.message}
          </FormFeedback>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Postal code <span className="text-danger ml-1">*</span></Label>
         <Col sm={8}>
          <Input
            type="text"
            name="postalCode"
            className="capitalize"
            defaultValue={rows.postalCode}
            invalid={errors.postalCode ? true : false}
            innerRef={register({
              required: "This field is required!",
              maxLength: {
                value: 150,
                message: "This field only allows up to 150 characters",
              },
            })}
          />
          <FormFeedback>
            {errors.postalCode && errors.postalCode.message}
          </FormFeedback>
        </Col>
      </FormGroup>
      {mode === "Add" && (
        <FormGroup row>
          <Label sm={4}>Last Contacted</Label>
          <Col sm={8}>
            <Input
              type="date"
              name="lastContactedRef"
              defaultValue={rows.lastContactedRef}
              invalid={errors.lastContactedRef ? true : false}
              innerRef={register({
                // required: "This field is required!",
                maxLength: {
                  value: 150,
                  message: "This field only allows up to 150 characters",
                },
              })}
            ></Input>
            <FormFeedback>
              {errors.lastContactedRef && errors.lastContactedRef.message}
            </FormFeedback>
          </Col>
        </FormGroup>
      )}
      <FormGroup row>
        <Label sm={4}>CV Source <span className="text-danger ml-1">*</span></Label>
 
        <Col sm={8}>
          <Input
            type="select"
            id="candidateSourceId"
            name="candidateSourceId"
            value={selectedSource}
            onChange={handleSourceChange}
            invalid={!!errors.candidateSourceId}
            innerRef={register({
              required: "Please select a CV source",
            })}
          >
            <option value="" style={{ color: "#6A6767" }}>
              Select
            </option>
            {cvSources.map((value, i) => (
              <option value={value.id} key={i} style={{ color: "#6A6767" }}>
                {value.name}
              </option>
            ))}
          </Input>
          <FormFeedback>
            {errors.candidateSourceId && errors.candidateSourceId.message}
          </FormFeedback>
        </Col>
      </FormGroup>
      <Alert color="info">
        Note: Fields marked with
        <span className="text-danger mx-2">*</span>are required{" "}
      </Alert>
      <Row className="justify-content-end">
        <div className="d-flex">
          <Button
            className="mr-2"
            color="success"
            type="submit"
            disabled={addKillLog}
            // onClick={() => closeModal()}
          >
            {mode}
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
};
function mapStateToProps(state) {
  return {
    headhunter: state.headhunter,
    auth: state.auth,
    initialDataGetReducer: state?.initialDataGetReducer?.employees,
    cvSources: state.initialDataGetReducer.cvSources,
  };
}

export default connect(mapStateToProps, {})(AddUser);
