import React, { useState } from "react";
import PayrollSummery from "./payrollSummery";
import CreditControllClient from "./creditControll-Client";
import CreditControllVerified from "./creditControll-Verified";
import CreditControllCandidatesCallBacks from "./callBacksCandidates";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import TabNavigationMenu from "../../util/common/tabNavigation";

const CreditControll = () => {
  const [currentTab, setCurrentTab] = useState("workspace");

  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <>
      <DashboardLayout
        title="Credit Control"
        subTitle="Credit Controlling Process"
        showPayrollTargets={true}
      >
        <TabNavigationMenu
          activated={currentTab}
          onChangeItem={switchTabHandler}
          itemList={[
            { name: "Workspace", key: "workspace" },
            // { name: "Call Backs", key: "callBacks" },
            { name: "Client", key: "client" },
            { name: "Proceed Payment", key: "proceed" },
          ]}
        />
        {currentTab === "workspace" && <PayrollSummery />}
        {/* {currentTab === "callBacks" && <CreditControllCandidatesCallBacks />} */}
        {currentTab === "client" && <CreditControllClient />}
        {currentTab === "proceed" && <CreditControllVerified />}
      </DashboardLayout>
    </>
  );
};

export default CreditControll;
