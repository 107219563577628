import { CHAT_DB_POST, CHAT_ARRAY, CHAT_TAB } from "./types";
import Axios from "axios";
import { last } from "lodash";
import { GET_CHATROOM } from "../../configs/api-config";

export const chatDbPostAction = (data) => (dispatch) => {
  dispatch({
    type: CHAT_DB_POST,
    payload: data,
  });
};

export const chatArrayAction = (data) => (dispatch) => {
  Axios.get(`${GET_CHATROOM}?room_id=${data}`).then((res) => {
    let last_message = last(res.data.body[0].messages, (item) => {
      return item;
    });

    dispatch({
      type: CHAT_ARRAY,
      payload: res.data.body[0],
    });

    // dispatch({
    //   type: DELEIVERED_ACTION_UPDATE,
    //   payload: res.data.body[0],
    // });
  });
};

export const chatTabAction = (data) => (dispatch) => {
  // console.log("Changed action");
  dispatch({
    type: CHAT_TAB,
    payload: data,
  });
};
