import { NEW_CHAT_RECEIVED } from "../actions/types";

const initialState = {
  id: 0,
  message: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NEW_CHAT_RECEIVED:
      return {
        ...state,
        id: action.id,
        message: action.message,
      };

    default:
      return state;
  }
}
