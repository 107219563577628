import React, { useState } from "react";
import {
  Collapse,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Col,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import UnconfirmedBookingWidget from "../../widgets/draft-Widgets/unconfirmed-Bookings-widget";
import { FunnelIcon } from "../../assets/icons/svg";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((item) => {
      let searchableString = `${item.firstName} ${item.lastName} ${item.email} ${item.mobileNumber}`;
      return searchableString.includes(name);
    });
  }
  return filtered;
};

const BookingDrafts = (props) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  const DocStatus = [
    { value: "ITU", label: "ITU" },
    { value: "Speciality 02", label: "Speciality 02" },
    { value: "Speciality 03", label: "Speciality 03" },
  ];

  return (
    <>
      <div className="form-inline">
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            textTransform: "lowercase",
          }}
        />
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Date</Label>
                <Input
                  type="date"
                  name="date"
                  id="exampleDate"
                  placeholder=""
                />
              </Col>

              <Col></Col>
              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button color="success m-1 btn-sm" style={{ width: "70px" }}>
                    Search
                  </Button>
                  <Button color="danger m-1 btn-sm" style={{ width: "70px" }}>
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>
      {/* {filterByName(searchQuery, props.headhunter.todayCandidatesList).map(
        (item, i) => {
          return (
            <UnconfirmedBookingWidget
              
            ></UnconfirmedBookingWidget>
          );
        }
      )} */}
      <UnconfirmedBookingWidget />
    </>
  );
};

function mapStateToProps(state) {
  return {
    headhunter: state.headhunter,
  };
}

export default connect(mapStateToProps, {})(BookingDrafts);
