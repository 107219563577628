import React, { Component } from "react";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { forEach } from "lodash";
import ChatService from "../services/chat.service";
import { HOSTMexxar } from "../../../configs/api-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";

const MexxarApi = HOSTMexxar;

class AddChat extends Component {
  constructor(props) {
    super(props);
    this.onChangeTitle = this.onChangeTitle.bind(this);
    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.clearChatInputField = this.clearChatInputField.bind(this);
    this.state = {
      chatarray: [],
      currentTutorial: null,
      currentIndex: -1,
      title: "",
      description: "",
      published: false,
      time: "",
      submitted: false,
      isActive: true,
      isOnline: true,
      sender: "",
      fileUploadView: false,
    };
    this.messageInputRef = React.createRef();
  }
  componentDidMount() {
    this.messageInputRef.current.focus();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activechatId !== this.props.activechatId)
      this.clearChatInputField();
    this.messageInputRef.current.focus();
  }

  onChangeTitle(e) {
    this.setState({
      title: e.target.value,
    });
  }

  onChangeDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  // this will be used to pack the data to be sent to the firebase
  sendMessage() {
    let data = {
      title: this.state.title,
      description: this.state.description,
      published: false,
      time: moment().format("YYYYMMDD, h:mm:ss a"),
      isActive: this.state.isActive,
      isOnline: this.state.isOnline,
      sender: this.props.userId,
    };

    if (this.props.files.length > 0) {
      let messageData = new FormData();
      messageData.append("to", this.props.activechatId);
      messageData.append("messageType", "FILE");
      messageData.append("message", "Attachment");
      ChatService.create(data, this.props.reduxChatScriptId);

      forEach(this.props.files, function (file) {
        messageData.append("file", file);

        axios
          .post(MexxarApi + "chat/send", messageData)
          .then((res) => {
            // this.setState({
            //   submitted: true,
            // });
          })
          .catch((e) => {
            // console.log(e);
          });
      });
      this.clearChatInputField();
    } else {
      let messageData = new FormData();
      messageData.append("to", this.props.activechatId);
      messageData.append("messageType", "TEXT");
      messageData.append("message", data.title);
      // console.log("data !", data);
      // console.log("data !", this.props.reduxChatScriptId);

      ChatService.create(data, this.props.reduxChatScriptId);
      axios
        .post(MexxarApi + "chat/send", messageData)
        .then(() => {
          // console.log("Created new item successfully!");
          // this.setState({
          //   submitted: true,
          // });
        })
        .catch((e) => {
          // console.log(e);
        });
      this.clearChatInputField();
    }
  }

  enterToSend = (event) => {
    if (event.key === "Enter") {
      this.sendMessage();
    }
  };

  //clearing the chat input box after sending a message
  clearChatInputField() {
    this.props.showHideFileUpload(false);
    this.props.clearFilesStates();
    this.setState({
      title: "",
      description: "",
      published: false,
      time: "",
      submitted: false,
    });
  }

  render() {
    return (
      <div className="submit-form">
        <div>
          <div className="input-group">
            {/* input box  */}
            <input
              type="text"
              className=" form-control p-3 no-shadow 
                typeahead tt-input form-control bg-light b-t-accent mx-2
                "
              ref={this.messageInputRef}
              placeholder="Say something ..."
              id="newField"
              required
              value={
                this.props.files.length > 0 ? "Attachment" : this.state.title
              }
              disabled={this.props.files.length > 0 ? true : false}
              onChange={this.onChangeTitle}
              name="title"
              onKeyDown={this.enterToSend}
              style={{ background: "transparent" }}
            />

            {/* button  */}
            <button
              className="btn btn-icon btn-rounded gd-success "
              type="button"
              id="newBtn"
              onClick={this.sendMessage}
              disabled={
                this.props.reduxChatScriptId == "waiting" ||
                this.props.fileOversize ||
                this.state.title.trim().length === 0
                  ? this.props.files.length > 0
                    ? false
                    : true
                  : false
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-up"
              >
                <line x1="12" y1="19" x2="12" y2="5"></line>
                <polyline points="5 12 12 5 19 12"></polyline>
              </svg>
            </button>
            <button
              className="btn btn-icon btn-rounded gd-success ml-2"
              type="button"
              id="newBtn"
              // disabled={
              //   this.props.reduxChatScriptId == "waiting" ? true : false
              // }
              onClick={() => {
                console.log("showHideFileUpload-----2");
                this.props.showHideFileUpload(true);
              }}
            >
              <FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userId: state.auth.item.id,
    activechatId: state.chatActiveIdReducer.chatActive,
    reduxChatScriptId: state.ChatRedux.chatfunkyId,
  };
}
export default connect(mapStateToProps, {})(AddChat);
