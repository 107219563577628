import {
  EMAIL_BODY_OTHER_VALUES,
  EMAIL_BODY_POSTS,
  EMAIL_SERVER_CREDENTIALS,
} from "../actions/types";

const initialState = {
  otherValues: null,
  posts: null,
  credentials: {
    // email: "abdullai@mexxar.com",
    // password: "MexxarAb1",
    // type: "INBOX",
    email: localStorage.email ? localStorage.getItem("email") : null,
    password: localStorage.password ? localStorage.getItem("password") : null,
    type: localStorage.type ? localStorage.getItem("type") : null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case EMAIL_SERVER_CREDENTIALS:
      return {
        ...state,
        credentials: action.payload,
      };
    case EMAIL_BODY_OTHER_VALUES:
      return {
        ...state,
        otherValues: action.payload,
      };
    case EMAIL_BODY_POSTS:
      return {
        ...state,
        posts: action.payload,
      };

    default:
      return state;
  }
}
