import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Label } from "reactstrap";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";

const IncomingMessageModal = (props) => {
  const { closeModal, show = false } = props;

  useEffect(() => {
    //TODO: Do your stuff here
  }, []);

  return (
    <>
      <Modal
        isOpen={show}
        //toggle={toggle}
        className="text-gray "
        centered={true}
        style={{ width: "350px" }}
        backdrop={true}
        keyboard={true}
      >
        <div className="p-3">
          <div>
            <div className="d-flex justify-content-center">
              <RoundIconButton
                buttonColor="#28a745"
                icon={faEnvelope}
                iconSize="fa-m"
                buttonSize="btn-lg"
              />
            </div>

            <div className="d-flex justify-content-center">
              <Label>Incomming Message</Label>
            </div>

            <div
              className="mt-2 d-flex justify-content-center"
              style={{ flex: 2 }}
            >
              <div
                className="d-flex justify-content-center"
                style={{ flex: 1 }}
              >
                <Button color="secondary" onClick={() => closeModal(false)}>
                  Cancel
                </Button>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ flex: 1 }}
              >
                <Link to={`/dashboard/sms`}>
                  <Button color="primary" onClick={() => closeModal(false)}>
                    View
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IncomingMessageModal;
