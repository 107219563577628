

import { CHAT_DB_GET } from "../actions/types";

const initialState = {
  chatDbGetLoad: ["k","b"],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CHAT_DB_GET:
      return {
        ...state,
        chatDbGetLoad: action.payload,
      };

 
    default:
     
      return state;
  }
}
