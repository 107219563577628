import React, { useEffect, useState } from "react";
import {
    EMPLOYEE_PERMISSIONS,
    EMPLOYEE_TEAMS,
    HOSTMexxar,
    HostMexxarURL,
    TRUSTS,
    WARDS,
} from "../../configs/api-config";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { Bar } from "react-chartjs-2";
import { Label, Button, ButtonGroup, Col, Row, Input } from "reactstrap";
import { forEach, uniqWith, isEqual, filter, isNull, sumBy } from "lodash";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import {
    clientsGetAction,
    departmentsGetAction,
    employeesGetAction,
} from "../../redux/actions/initialDataGetAction";
import store from "../../redux/store";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";

const MissingBookings1 = (props) => {
    let demo = false;
    const MexxarApi = HOSTMexxar;
    const MexxarTeams = EMPLOYEE_TEAMS;
    const MexxarTeamMembers = EMPLOYEE_PERMISSIONS;
    const MexxarApiTrust = TRUSTS;
    const mexxarWards = WARDS
    const { reduxClients, reduxEmployees, reduxDepartments } = props;
    const { register, handleSubmit, watch, errors } = useForm();

    const defaultsTypes = [
        // {
        //   id: 1,
        //   label: "By Trust",
        //   value: "trust",
        // },
        {
            id: 1,
            label: "By Client",
            value: "client",
        },
        // {
        //   id: 1,
        //   label: "By Team",
        //   value: "team",
        // },
        // {
        //   id: 1,
        //   label: "By Employee",
        //   value: "employee",
        // },
        {
            id: 2,
            label: "By Wards",
            value: "ward",
        },
    ];
    const [team, setTeam] = useState(null);
    const [trust, setTrust] = useState(null);
    const [secondSelectOption, setSecondSelectOption] = useState(null);
    const [type, setType] = useState(defaultsTypes);
    const [dateTo, setDateTo] = useState();
    const [range, setRange] = useState(false);
    const [dateFrom, setDateFrom] = useState(moment(new Date ()).subtract(1, 'months'));
    const [dataList, setDataList] = useState([]);
    const [rangeType, setRangeType] = useState("YEARLY");
    const [noOfbookings, setNoOfBookings] = useState([]);
    const [noOfMissingBookings, setNoOfMissingBookings] = useState([]);
    const [employees, setEmployees] = useState();
    const [clients, setClients] = useState();
    const [clientss, setClientss] = useState();
    const [wards, setWards] = useState();
    const [wardss, setWardss] = useState();
    const [selectedSecondSelectOption, setSelectedSecondSelectOption] =
        useState(null);
    const [selectedSecondSelectOption1, setSelectedSecondSelectOption1] =
        useState(null);
    const [selectedType, setSelectedType] = useState(null);
    const [dummpyDataBooking, setDummpyDataBooking] = useState();
    const [dummpyDataMissingBookings, setDummpyDataMissingBookings] = useState();

    const [itemCountMissedShiftsByHospital, setMissedShiftsByHospital] = useState();
    const [itemCountMissedShiftsByHospitalAndWard, setMissedShiftsByHospitalAndWard] = useState();
    const [itemCountMissedShiftsByDateRange, setMissedShiftsByDateRange] = useState();
    const [selectedClient, setSelectedClient] = useState([]);
    const [selectedWard, setSelectedWard] = useState([]);
    const [matchingLikeWardsWithClient, setMatchingLikeWardsWithClient] =
        useState(null);


    useEffect(() => {
        // setSpecialities(reduxSpecialities);
        setClients(reduxClients);
        setWards(reduxDepartments);
        // setRoles(reduxRoles);
        // setBands(reduxBands);
    }, [
        reduxDepartments,
        reduxClients,

    ]);


    // console.log("wards", reduxWards)

    console.log("reduxClients", reduxClients)
    const yearData = [
        String(Number(currentYear) - 9),
        String(Number(currentYear) - 8),
        String(Number(currentYear) - 7),
        String(Number(currentYear) - 6),
        String(Number(currentYear) - 5),
        String(Number(currentYear) - 4),
        String(Number(currentYear) - 3),
        String(Number(currentYear) - 2),
        String(Number(currentYear) - 1),
        String(Number(currentYear)),
    ];

    const monthData = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];


    // const resetState = () => {
    //     setTeam(null);
    //     setTrust(null);
    //     setSecondSelectOption(null);
    //     setType(defaultsTypes);
    //     setDateTo(moment(new Date()).format("YYYY-MM-DD"));
    //     setRange(moment(new Date()).format("YYYY-MM-DD"));
    //     setDateFrom(moment(new Date()).format("YYYY-MM-DD"));
    //     setDataList([]);
    //     setRangeType("YEARLY");
    //     setNoOfBookings([]);
    //     setNoOfMissingBookings([]);
    //     setEmployees();
    //     setClients();
    //     setClientss();
    //     setWards();
    //     setWardss();
    //     setSelectedSecondSelectOption(null);
    //     setSelectedSecondSelectOption1(null);
    //     setSelectedType(null);
    //     setDummpyDataBooking();
    //     setDummpyDataMissingBookings();
    //     setMissedShiftsByHospital();
    //     setMissedShiftsByHospitalAndWard();
    //     setMissedShiftsByDateRange();
    //     setSelectedClient(0);
    //     setSelectedWard(0);
    //     setMatchingLikeWardsWithClient(null);
    //   };
    const getwardsInSelectedClient = () => {
        axios
            .get(MexxarApi + "client-wards/clients/" + selectedClient?.id)
            .then((res) => {
                let wardsList = [];
                forEach(res.data.body, function (ward) {
                    wardsList?.push({
                        id: ward.wardId,
                        name: ward.wardName,
                        label: ward.wardName,
                    });
                });

                if (wardsList.length > 0){
                    setSelectedWard(wardsList[0]);
                    setMatchingLikeWardsWithClient(wardsList);
                }else{
                    setSelectedWard([]);
                setMatchingLikeWardsWithClient(null);
                }
                
            }).catch(()=>{
                setSelectedWard([]);
                setMatchingLikeWardsWithClient(null);
            })
    };

    const data = {
        labels: ["Hospital", "Hospital And Ward", "Date Range"],
        datasets: [
            {
                label: "Missed Booking Shifts",
                data: [
                    itemCountMissedShiftsByHospital,
                    itemCountMissedShiftsByHospitalAndWard,
                    itemCountMissedShiftsByDateRange
                ],
                backgroundColor: [
                    "rgb(54, 162, 235)",
                    "rgb(54, 162, 235)",
                    "rgb(54, 162, 235)",
                ],
                borderColor: [
                    "rgb(54, 162, 235)",
                    "rgb(54, 162, 235)",
                    "rgb(54, 162, 235)",
                ],
                borderWidth: 1,
                barPercentage: 0.2,
            },
        ],
    };

    const options = {
        plugins: {
            datalabels: {
                display: true,
                color: 'black',
                formatter: function (value, context) {
                    return data.labels[context.dataIndex];
                }
            }
        }
    };

    const config = {
        type: 'bar',
        data: data,
        options: options
    };




    console.log(data);

    // const options = {
    //     scales: {
    //         yAxes: [
    //             {
    //                 ticks: {
    //                     beginAtZero: true,
    //                 },
    //             },
    //         ],
    //     },
    //     //     barPercentage: 0.4, 
    //     // categoryPercentage: 0.4,
    // };

    var currentYear = moment().year();

    const getTeams = () => {
        axios
            .get(MexxarTeams)
            .then((res) => {
                let teamArray = [];

                forEach(res.data.body, function (item) {
                    teamArray.push({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                    });
                });

                setTeam(teamArray);
            })
            .catch((err) => {
                //console.log("cannot get specialities ",err)
                // toastCommon(true, "error");
            });
    };

    const [startDateShowOnly, setStartDateShowOnly] = useState();

    const [endDateShowOnly, setEndDateShowOnly] = useState();



//--------------------FilterMissedShifts---------------------//


    const getAllRows = () => {
        {
            // selectedSecondSelectOption &&
            axios({
                method: "get",
                url:
                    HOSTMexxar +
                    "reports/filteredMissedShifts" +
                    //  selectedType.value +
                    "?",
                // selectedSecondSelectOption.id,
                params: {
                    clientsName: selectedClient?.name,
                    ward: selectedWard?.name,
                    startDate: moment(dateFrom).format("YYYY/MM/DD"), 
                    endDate: moment(dateTo).format("YYYY/MM/DD"),
                    // dateRange: rangeType,
                },

                // moment(moment(endTempDate).add(1, "days")).format("YYYY/MM/DD")
                // url:"https://permapi.mexxar.com/reports/filteredMissedShifts?clientsName=Kingston Hospital&ward=Keats Ward&startDate=2023/11/22&endDate=2023/12/17"
            })
                .then((res) => {
                    console.log(selectedClient?.name)
                    console.log(res?.data?.body?.items[0]?.itemCountMissedShiftsByHospital)
                    console.log(res?.data?.body?.items[0]?.itemCountMissedShiftsByHospitalAndWard)
                    console.log(res?.data?.body?.items[0]?.itemCountMissedShiftsByDateRange)
                    setMissedShiftsByHospital(res?.data?.body?.items[0]?.itemCountMissedShiftsByHospital)
                    setMissedShiftsByHospitalAndWard(res?.data?.body?.items[0]?.itemCountMissedShiftsByHospitalAndWard)
                    setMissedShiftsByDateRange(res?.data?.body?.items[0]?.itemCountMissedShiftsByDateRange)

                    // getIndividualResults(res.data.body)

                    console.log("get", res.data.body)


                    if (res.data.body.length > 0) {
                        // getIndividualResults(res.data.body);
                    }
                })
                .catch((err) => {
                    //console.log(err)
                    // ToastCommon(true, "error", addToast);
                });
        }
    };


    console.log("clientss", clients)
    console.log("wardss", wardss)





    // useEffect(() => {
    //     if (reduxDepartments && clientss) {
    //         // Pass the selected client ID to getWards
    //         getWards(clientss.id);
    //     }
    // }, [reduxDepartments, clientss]);



    const getIndividualResults = (response) => {
        let missingBookings = [];
        let successfulBookings = [];
        let xAxis = [];

        forEach(response, function (record) {
            missingBookings.push(record.missingBookings);
            successfulBookings.push(record.successfulBookings);

            xAxis.push(record.name);
        });

        setNoOfBookings(successfulBookings);
        setNoOfMissingBookings(missingBookings);
        setDataList(xAxis);
    };

    const getTrusts = () => {
        axios
            .get(MexxarApiTrust)
            .then((res) => {
                let array = [];
                forEach(res.data.body, function (item) {
                    array.push({
                        value: item.name,
                        label: item.name,
                        id: item.id,
                    });
                });
                setTrust(array);
            })
            .catch((err) => {
                //console.log(err)
            });
    };

    const getEmployees = () => {
        let names = [];
        reduxEmployees.forEach((item) => {
            names.push({
                id: item.id,
                label: item.firstName + " " + item.lastName,
                value: item.firstName + " " + item.lastName,
            });
        });
        setEmployees(names);
    };

    // const getClients = () => {
    //     let names = [];
    //     reduxClients?.forEach((item) => {
    //         names.push({
    //             id: item.id,
    //             label: item.name,
    //             value: item.name,
    //         });
    //     });
    //     setClients(names);
    // };
    const getWards = () => {
        let names = [];
        reduxDepartments.forEach((item) => {
            names.push({
                id: item.id,
                label: item.name,
                value: item.name,
            });
        });
        setWards(names);
    };

    useEffect(() => {
        if (!reduxEmployees) {
            store.dispatch(employeesGetAction());
        }
        if (!reduxClients) {
            store.dispatch(clientsGetAction());
        }
        if (!reduxDepartments) {
            store.dispatch(departmentsGetAction());
        }


        // getTrusts();
        // getTeams();
    }, []);

    useEffect(() => {

        getIndividualResults();

    }, [clients, wards]);

    useDidMountEffect(() => {
        getwardsInSelectedClient();
        console.log("angi selected client use effect works")
        return () => { };
    }, [selectedClient]);

    useEffect(() => {
        if (reduxEmployees) {
            getEmployees();
        }
    }, [reduxEmployees]);

    // useEffect(() => {
    //     if (reduxClients) {
    //         getClients();
    //     }
    // }, [reduxClients]);

    useEffect(() => {
        if (reduxDepartments) {
            getWards();
        }
    }, [reduxDepartments]);



  

    const dropdownWidth = 50;


    const colourStyles = {
        option: (provided, state) => ({
            ...provided,
            color: "#6A6767",
            // width: dropdownWidth
        }),
    };
 


    return (
        <div className="card">
            <div className="card-header">
                <h4>Missing Shifts Reports</h4>
            </div>
            <div className="card-body">
                <div >
                    <Row>
                        <Col sm={10} md={4} lg={4} xl={3}>
                            <Label>Client</Label>
                            <Input
                                type="select"
                                id="clientId"
                                className="basic-multi-select"
                                style={{ backgroundColor: 'white', color: 'black' }}
                                onChange={(e) => {
                                    const selectedOption = clients?.find(
                                        (item) => item.id === parseInt(e.target.value)
                                    );
                                    setSelectedClient(selectedOption);
                                    console.log("selectedOption",selectedOption)
                                }}
                                invalid={errors?.clientId}
                                innerRef={register({
                                    required: {
                                        value: true,
                                        message: "This field is required!",
                                    },
                                })}
                            >
                                <option value={0}  >
                                    Select Client
                                </option>
                                {clients?.map((item, id) => {
                                     return (
                                        <option value={item?.id} key={id}>
                                            {item?.name}
                                        </option>
                                    );
                                })}
                            </Input>

                        </Col>

                        <Col sm={12} md={4} lg={4} xl={3}>
                            <Label>Ward</Label>
                            <Input
                                type="select"
                                style={{ backgroundColor: 'white', color: 'black' }}
                                id="departmentId"
                                className="basic-multi-select"
                                invalid={errors.ward}
                                onChange={(e) => {
                                    const selectedOption = matchingLikeWardsWithClient.find(
                                        (item) => item.id === parseInt(e.target.value)
                                    );
                                    setSelectedWard(selectedOption);
                                    console.log(selectedOption)
                                }}
                                innerRef={register({
                                    required: {
                                        value: true,
                                        message: "This field is required!",
                                    },
                                })}
                            >
                                <option value={0}>Select Department</option>
                                {matchingLikeWardsWithClient &&
                                    matchingLikeWardsWithClient.map((item, id) => {
                                        return (
                                            <option value={item.id} key={id}>
                                                {item?.label}
                                            </option>
                                        );
                                    })}
                            </Input>
                        </Col>

                        <Col sm={12} md={rangeType == "DEFAULT" ? 4 : 2}>
                            <Label for="startDate">Start Date</Label>
                            <Input
                                type="date"
                                name="dateFrom"
                                id="startDate"
                                onChange={(e) => setDateFrom(e.target.value)}
                                placeholder=""
                                defaultValue={moment(dateFrom).format("YYYY-MM-DD")}
                            />
                        </Col>

                        <Col sm={12} md={rangeType == "DEFAULT" ? 4 : 2}>
                            <Label for="endDate">End Date</Label>
                            <Input
                                type="date"
                                name="dateTo"
                                id="endDate"
                                onChange={(e) => setDateTo(e.target.value)}
                                placeholder=""
                                defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                            />
                        </Col>

                        <Col sm={12} md={4} lg={2} xl={2} className="d-flex align-items-end">
                            <Button color="success" onClick={getAllRows} style={{ marginBottom: "8px", marginRight: "8px" }}>
                                Search
                            </Button>
                           
                        </Col>
                    </Row>



                    <br />
                </div>
                <Bar data={data} options={options} />
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        // reduxClients: state.initialDataGetReducer.clients,
        reduxEmployees: state.initialDataGetReducer.employees,
        // reduxWards: state.initialDataGetReducer.departments,
        reduxClients: state.initialDataGetReducer.clients,
        reduxDepartments: state.initialDataGetReducer.departments,
    };
}

export default connect(mapStateToProps, {})(MissingBookings1);