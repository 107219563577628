import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import filter from "lodash/filter";
import { Button } from "reactstrap";
import React, { useState, useEffect } from "react";
import loading from "../../../../src/images/puff.svg";
import { useToasts } from "react-toast-notifications";
import { TRAININGS } from "../../../configs/api-config";
import ToastCommon from "../../../components/toastCommon";
import Delete from "../../../components/Modal/ModalBody/delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import NewOneField from "../../../components/Modal/ModalBody/Admin/newOneField";

const Tranings = () => {
  const MexxarApi = TRAININGS;

  const { addToast } = useToasts();

  const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [rowNameData, setRowNameData] = useState([]);
  const [showAddRow, setShowAddRow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [rowTrainingProviderData, setRowTrainingProviderData] = useState([]);

  const addRow = (data) => {
    let duplicate_items = filter(rows, (item) => {
      return data.rowName == item.name;
    });

    if (duplicate_items.length > 0) {
      ToastCommon(true, "duplicate", addToast);
    } else {
      axios
        .post(MexxarApi, {
          name: data.rowName,
          trainingProviderId: data.trainingProviderId,
        })
        .then((res) => {
          if (res.data.status == "success") {
            getAllRows();
            setShowAddRow(!showAddRow);
            ToastCommon(true, "success", addToast);
          } else {
            ToastCommon(true, "duplicate", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    }
  };

  const deleteRow = () => {
    axios
      .delete(MexxarApi + "/" + rowID)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "delete", addToast);
          getAllRows();
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "update", addToast);
      });
  };

  const updateRow = (data) => {
    let duplicate_items = filter(rows, (item) => {
      return data.rowName == item.name;
    });

    if (duplicate_items.length > 0) {
      ToastCommon(true, "duplicate", addToast);
    } else {
      axios
        .put(MexxarApi, {
          id: rowID,
          name: data.rowName,
          trainingProviderId: data.trainingProviderId,
          status: 1,
        })
        .then((res) => {
          if (res.data.status == "success") {
            getAllRows();
            setShowUpdateRow(!showUpdateRow);
            ToastCommon(true, "update", addToast);
          } else {
            ToastCommon(true, "duplicate", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    }
  };

  const getAllRows = () => {
    axios
      .get(MexxarApi)
      .then((res) => {
        setRows(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
    } else {
      setSelectedItems([...selectedItems, rowId]);
    }
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };
  const deleteAllRows = () => {
    selectedItems.forEach((item) => {
      axios
        .delete(MexxarApi + "/" + item)
        .then((res) => {
          getAllRows();
          setSelectedItems([]);
          setSelectAll(false);
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    });
  };

  const updateTrainingProviders = (data) => {
    let defaultTrainingProviders = {
      id: data.id,
      value: data.name,
      label: data.name,
    };

    setRowTrainingProviderData(defaultTrainingProviders);
  };

  useEffect(() => {
    getAllRows();
  }, []);

  return (
    <DashboardLayout title="Trainings" subTitle="Manage Trainings" target="">
      <div className="d-flex justify-content-between p-2 ml-3">
        <div>
          {selectedItems.length > 0 ? (
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                setShowDeleteAll(true);
              }}
            >
              Delete
            </Button>
          ) : null}
        </div>
        <div>
          {" "}
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddRow(true);
            }}
          >
            Add Trainings
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          {rows.length === 0 ? (
            <div className="d-flex justify-content-center align-items-center">
              {/* Loading animation */}
              <div>
                <img src={loading} style={{ height: 100 }}></img>
              </div>
            </div>
          ) : (
            <div>
              <table
                id="datatable"
                className="table table-theme table-row v-middle dataTable no-footer"
                role="grid"
                ariaDescribedby="datatable_info"
              >
                <thead>
                  <tr role="row">
                    {/* <th>
                  <label className="ui-check m-0">
                    <input type="checkbox" name="id" value="15" /> <i></i>
                  </label>
                </th> */}
                    <th
                      className="sorting_asc"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      ariaSort="ascending"
                      ariaLabel="ID: activate to sort column descending"
                      style={{ width: "26px" }}
                    >
                      <label className="ui-check m-0">
                        <input
                          type="checkbox"
                          name="id"
                          checked={selectAll}
                          onChange={() => checkAll()}
                        />{" "}
                        <i></i>
                      </label>
                    </th>
                    <th
                      className="sorting_asc"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      ariaSort="ascending"
                      ariaLabel="ID: activate to sort column descending"
                      style={{ width: "26px" }}
                    >
                      <span className="text-muted">ID</span>
                    </th>
                    <th
                      className="sorting_disabled"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      ariaLabel="Owner: activate to sort column ascending"
                    >
                      <span className="text-muted">Trainings</span>
                    </th>
                    <th
                      className="sorting_disabled"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      ariaLabel="Owner: activate to sort column ascending"
                    >
                      <span className="text-muted">Training provider</span>
                    </th>
                    <th
                      className="sorting_disabled d-flex justify-content-end mr-3"
                      rowSpan="1"
                      colSpan="1"
                      ariaLabel="Tasks"
                    >
                      <span className="text-muted d-none d-sm-block mr-3">
                        Actions
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, i) => {
                    return (
                      <tr className="odd" key={i} role="row">
                        <td
                          style={{ minWidth: "30px", textAlign: "center" }}
                          className="sorting_1"
                        >
                          <label className="ui-check m-0">
                            <input
                              type="checkbox"
                              name="id"
                              checked={isSelected(row.id)}
                              onChange={() => onItemSelect(row.id)}
                            />{" "}
                            <i></i>
                          </label>
                        </td>
                        <td
                          style={{ minWidth: "30px", textAlign: "center" }}
                          className="sorting_1"
                        >
                          <small className="text-muted">{i + 1}</small>
                        </td>
                        <td className="">{row.name}</td>
                        <td className="">{row.trainingProvider.name}</td>

                        <td className="d-flex justify-content-end">
                          <FontAwesomeIcon
                            // color="#448bff"
                            icon={faEdit}
                            className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3"
                            onClick={() => {
                              setShowUpdateRow(true);
                              setRowID(row.id);
                              setRowNameData(row.name);
                              updateTrainingProviders(row.trainingProvider);
                            }}
                            size="1x"
                          />
                          <FontAwesomeIcon
                            // color="#FF5603"
                            icon={faTrashAlt}
                            onClick={() => {
                              setShowDeleteRow(true);
                              setRowID(row.id);
                            }}
                            className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave "
                            size="1x"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>

      <ModalComponent
        show={showAddRow}
        header="Add New Trainings"
        closeModal={() => setShowAddRow(false)}
      >
        <NewOneField
          mode="Add"
          modalType="Trainings"
          closeModal={() => setShowAddRow(false)}
          onCreate={(data) => addRow(data)}
          trainingProviderShow={true}
        />
      </ModalComponent>
      <ModalComponent
        show={showUpdateRow}
        header="Update Trainings"
        closeModal={() => setShowUpdateRow(false)}
      >
        <NewOneField
          mode="Update"
          modalType="Trainings"
          fieldData={rowNameData}
          closeModal={() => setShowUpdateRow(false)}
          onCreate={(data) => updateRow(data)}
          trainingProviderShow={true}
          trainingProviderIncomingData={rowTrainingProviderData}
        />
      </ModalComponent>
      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteRow();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(false)}
      />
      <Delete
        show={showDeleteAll}
        isConfirm={() => {
          deleteAllRows();
          setShowDeleteAll(!showDeleteAll);
        }}
        closeModal={() => setShowDeleteAll(false)}
      />
    </DashboardLayout>
  );
};

export default Tranings;
