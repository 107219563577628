import axios from "axios";
import { FRAMEWORK, CANDIDATE_SPECIALITY, REGISTER_CANDIDATE, HOSTMexxar } from "../configs/api-config";
import { filter, round } from "lodash";
import moment from "moment";

const CompliancePercentageCalculationwithWhiteList = async (candidateId, candidateRole, speciality, framework, candidateBand) => {
  const url_1 = `${REGISTER_CANDIDATE}/${candidateId}/documents/framework/${framework}/role/${candidateRole}/speciality/${speciality}`;
  const url_2 = `${HOSTMexxar}frameworkDocWhitelist/filter?candidateId=${candidateId}&type=CANDIDATE&candidateBandId=${candidateBand}&candidateSpecialityId=${speciality}&candidateRoleId=${candidateRole}&frameworkId=${framework}&levelId=1`;

  try {
    const [res1, res2] = await Promise.all([axios.get(url_1, { params: { offset: 200 } }), axios.get(url_2)]);
    const response = res1.data?.body.filter((obj1) =>
      res2.data.body?.content.some((obj2) => obj2.document?.id === obj1.documentId)
    );

    const levels = [1, 2, 3].map(level => ({
      name: `Level 0${level}`,
      levelId: level,
      color: "#456543",
      expDocs: filter(response, item => item.levelId === level && moment(item.expireDate).isSameOrBefore(new Date())),
      pendingDocs: filter(response, item => item.levelId === level && item.expireDate === null),
      uploadedDocs: filter(response, item => item.levelId === level && moment(new Date()).isSameOrBefore(item.expireDate)),
    }));

    const calculateDocStatus = (expireDate) => {
      if (!expireDate) return { avatarColorCode: "#607d8b", avatarSymbol: "?" };
      const diffDays = Math.floor((moment(expireDate) - new Date()) / 86400e3);

      if (diffDays <= 0) return { avatarColorCode: "#e91e63", avatarSymbol: "Exp" };
      if (diffDays <= 30) return { avatarColorCode: "#ff9800", avatarSymbol: diffDays };
      if (diffDays <= 45) return { avatarColorCode: "#cddc39", avatarSymbol: diffDays };
      return { avatarColorCode: "#31c971", avatarSymbol: diffDays };
    };

    levels.forEach(level => {
      level.certificates = level.expDocs.concat(level.pendingDocs, level.uploadedDocs).map(item => ({
        id: item.documentId,
        certificateName: item.documentName,
        expireDate: item.expireDate,
        issueDate: item.issueDate,
        documentLink: item.documentLink,
        confirmation: item.confirmation,
        ...calculateDocStatus(item.expireDate),
      }));
    });

    const rearrangedArray = levels.map(level => ({
      levelName: level.name,
      levelId: level.levelId,
      color: level.color,
      expDocsCount: level.expDocs.length,
      pendingDocsCount: level.pendingDocs.length,
      uploadedDocsCount: level.uploadedDocs.length,
      certificates: level.certificates,
    }));

    const requiredDocAmmount = response.length;
    const missingDocCount = levels.reduce((acc, level) => acc + level.expDocs.length + level.pendingDocs.length, 0);
    const compliancePercentage = round(((requiredDocAmmount - missingDocCount) / requiredDocAmmount) * 100) || 0;

    return {
      certificateData: rearrangedArray,
      compliancePercentage,
    };
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export default CompliancePercentageCalculationwithWhiteList;
