import React, { useState } from "react";
import "./working-card-widget.style.css";
import {
  Badge,
  Button,
  ButtonGroup,
} from "reactstrap";
import logo from "../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faSearchLocation,
  faEdit,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import axios from "axios";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import ModalComponent from "../../components/Modal/modalComponent";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import {
  RESCHEDULE,
  HOSTMexxar,
  HostMexxarURL,
} from "../../configs/api-config";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import Select from "react-select";

import { UncontrolledTooltip } from "reactstrap/lib";
import OnboardingCall from "../../components/Modal/ModalBody/onboardingCall";
import AddUser from "../../components/Modal/ModalBody/Admin/addUser";
import OnboardingCallDelete from "../../components/Modal/ModalBody/onboardingCallDelete";
import AddMap from "../../components/Modal/ModalBody/Admin/addMap";
import Delete from "../../components/Modal/ModalBody/delete";

const options = [
  { value: "PENDING", label: "Pending" },
  { value: "CALLED", label: "Call" },
  { value: "REGISTERED", label: "Register" },
  { value: "DECLINE", label: "Decline" },
];

const OnboardingCallBackWidget = (props) => {
  const {
    userProfile,
    resetFilters,
    updateUserProfileStatus,
    isSelected,
    onItemSelect,
    mobileCandidate = false,
  } = props;
  const MexxarApiReschedule = RESCHEDULE;

  const { addToast } = useToasts();
  const [calendarShow, setCalendarShow] = useState(false);
  const [candidatePhoneNo, setCandidatePhoneNo] = useState(null);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showCall, setShowCall] = useState(false);
  const [showCallDelete, setShowCallDelete] = useState(false);
  const [showRowID1, setRowID1] = useState(false);
  const [showAddRow, setShowAddRow] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showAddMap, setShowMap] = useState(false);
  const [lanndLine, setlandLine] = useState(null);
  const [secondary, setsecondaryMobile] = useState(null);
  const [userprofile, setUserprofile] = useState(null);
  const [selectedOption, setSelectedOption] = useState(
   mobileCandidate?userProfile.mobileAppSignUpStatus: userProfile.onboardingStatus
  );
  const [postalCode, setpostalCode] = useState(null);
  const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState(false);
  const toggleM = () => setShowEmailModal(!showEmailModal);

  const resheduleCall = (data) => {
    let rescheduleDate = moment(data.date).add(data.time).toISOString();
    axios
      .post(MexxarApiReschedule, {
        callerId: 1,
        receiverId: rowID,
        callDate: moment(new Date()).toISOString(),
        rescheduleDate: rescheduleDate,
      })
      .then((res) => {
        ToastCommon(true, "success", addToast);
        setCalendarShow(!calendarShow);
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const handleStatusChange = (newStatus) => {
    axios
      .patch(
        mobileCandidate
          ? `${HOSTMexxar}mobileSignUp/status/${userProfile.id}?mobileAppSignUpStatus=${newStatus}`
          : `${HOSTMexxar}onboarding/${userProfile.id}?onboardingStatus=${newStatus}`
      )
      .then(() => {
        ToastCommon(true, "success", addToast);
        updateUserProfileStatus(userProfile.id, newStatus);
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };




  const deleteOneItem = () => {
    axios
      .delete(HOSTMexxar + "onboarding/" + rowID)
      .then((res) => {
        if (res.data.status === "Success") {
          ToastCommon(true, "delete", addToast);
          resetFilters();
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "update", addToast);
      });
  };


  console.log("rows", rows);

  return (
    <>
      <div
        className=" card d-flex flex-row main bg-light p-1 "
        style={{ minWidth: "900px", marginBottom: "5px" }}
      >
        <div className="d-flex align-items-center p-1">
          <input
            type="checkbox"
            checked={isSelected(userProfile.id)}
            onChange={() => onItemSelect(userProfile.id)}
          />
          <i className="on"></i>
        </div>

        <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
          <div className="d-flex ml-1 mb-1">
            <div>
              {userProfile?.firstName} {userProfile?.lastName}
            </div>
          </div>
          <div className=" d-flex ">
            <div className="mx-2 ">
              <button
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#31c971",
                  border: "none",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowCall(!showCall);
                  setUserprofile(userProfile);
                  setRowID1(userProfile?.id);
                  setCandidatePhoneNo(userProfile?.primaryMobile);
                  setsecondaryMobile(userProfile?.secondaryMobile);
                  setlandLine(userProfile?.landLine);
                  console.log("call");
                }}
              >
                <FontAwesomeIcon color="#ffffff" icon={faPhone} size="sm" />
              </button>
            </div>

            <div></div>

            <button
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <FontAwesomeIcon
                id="hospitalMapToggle"
                color="#00C426"
                icon={faSearchLocation}
                className="fa-flip-horizontal"
                onClick={() => {
                  setShowMap(true);
                  setpostalCode(userProfile?.postalCode);
                }}
              />
              <UncontrolledTooltip target="hospitalMapToggle">
                Hospital Map
              </UncontrolledTooltip>
            </button>
          </div>
        </div>

        <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
          <div className=" d-flex ">
            <div className="mx-2 "></div>
          </div>
        </div>

        <div className="d-flex  align-items-center" style={{ flex: 1 }}>
          <FontAwesomeIcon />
        </div>

        <div
          className="d-flex align-items-center justify-content-center flex-column"
          style={{ flex: 3 }}
        >
          <div style={{ height: "3vh", flex: 1 }}>Onboarding Status</div>
          <div style={{ height: "3vh", flex: 1 }}>
            {selectedOption === "PENDING" && (
              <Badge
                className="m-1 p-1 bg-danger align-items-center justify-content-center d-flex"
                style={{ color: "black" }}
              >
                Pending
              </Badge>
            )}

            {selectedOption === "REGISTERED" && (
              <Badge className="m-1 p-1 bg-secondary align-items-center justify-content-center d-flex">
                Registered
              </Badge>
            )}
            {selectedOption === "DECLINE" && (
              <Badge className="m-1 p-1 bg-primary align-items-center justify-content-center d-flex">
                Decline
              </Badge>
            )}
            {selectedOption === "CALLED" && (
              <Badge className="m-1 p-1 bg-success align-items-center justify-content-center d-flex">
                Called
              </Badge>
            )}
          </div>
          <div style={{ height: "3vh", flex: 1 }}></div>
        </div>

        <div className="d-flex  align-items-center" style={{ flex: 1 }}></div>
        <div className="d-flex  align-items-center" style={{ flex: 3 }}>
          <div style={{ height: "7vh", flex: 1 }}>
            <div className="flex-row">
              <div
                className="d-flex align-items-center justify-content-center flex-column"
                style={{ flex: 3 }}
              >
                <div style={{ height: "3vh", flex: 1 }}>Created On :</div>
                <div style={{ height: "3vh", flex: 1 }}>
                  {userProfile?.createdDate ? (
                    <>
                      {moment(userProfile?.createdDate).format(
                        "ddd MMM DD YYYY"
                      )}
                    </>
                  ) : (
                    "N/A"
                  )}
                </div>
                <div style={{ height: "3vh", flex: 1 }}></div>
              </div>

              <div></div>
            </div>
          </div>
        </div>

        <div className="d-flex  align-items-center" style={{ flex: 1 }}></div>
        <div className="d-flex  align-items-center" style={{ flex: 3 }}>
          <div style={{ height: "7vh", flex: 1 }}>
            <div className="flex-row">
              <div
                className="d-flex align-items-center justify-content-center flex-column"
                style={{ flex: 3 }}
              >
                <div style={{ height: "3vh", flex: 1 }}>Last Contacted :</div>
                <div style={{ height: "3vh", flex: 1 }}>
                  {userProfile?.lastContactedRef ? (
                    <>
                      {moment(userProfile?.lastContactedRef).format(
                        "ddd MMM DD YYYY"
                      )}
                    </>
                  ) : (
                    "N/A"
                  )}
                </div>
                <div style={{ height: "3vh", flex: 1 }}></div>
              </div>

              <div></div>
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center" style={{ flex: 1 }}>
          <Select
            menuPortalTarget={document.body}
            onChange={(data) => {
              setSelectedOption(data?.value);
              setRowID1(userProfile.id);
              handleStatusChange(data.value);
            }}
            defaultValue={selectedOption?.label}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                color: "#333333",
              }),
              control: (base) => ({
                ...base,
                width: 100,
                height: 20,
              }),
            }}
            options={options}
          />
          <div style={{ height: "3vh", flex: 1 }}></div>
          <Link
            to={{
              pathname: "/dashboard/candidates/register1",
              state: { user: userProfile },
            }}
          >
            <ButtonGroup className="ml-3" size="sm">
              <Button>Register</Button>
            </ButtonGroup>
          </Link>

          <div className="d-flex align-items-center ml-3">
            <FontAwesomeIcon
              id="edit"
              icon={faEdit}
              className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-2 pointer"
              onClick={() => {
                setShowAddRow(true);
                setRowID1(userProfile.id);
              }}
              size="1x"
            />
            <UncontrolledTooltip placement="top" target="edit">
              Edit
            </UncontrolledTooltip>

            <FontAwesomeIcon
              id="delete"
              icon={faTrashAlt}
              onClick={() => {
                setShowDeleteRow(true);
                setRowID(userProfile.id);
              }}
              className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-2 pointer"
              size="1x"
            />
            <UncontrolledTooltip placement="top" target="delete">
              Delete
            </UncontrolledTooltip>
          </div>
        </div>


        <div
          className={`modal fade bd-example-modal-sm  ${
            calendarShow ? "show" : ""
          }`}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          style={{ display: `${calendarShow ? "block" : "none"}` }}
          aria-hidden={!calendarShow}
        ></div>
      </div>

      <ModalComponent
        show={calendarShow}
        header="Reschedule a call"
        closeModal={() => setCalendarShow(false)}
      >
        <SmallCalendar
          onCreate={(data) => resheduleCall(data)}
          closeModal={() => setCalendarShow(false)}
        />
      </ModalComponent>


      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteOneItem();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(false)}
      />
      <ModalComponent
        show={showAddRow}
        header="Update User "
        closeModal={() => setShowAddRow(false)}
      >
        <AddUser
          mode="Update"
          modalType="Document"
          closeModal={() => setShowAddRow(false)}
          rowID={showRowID1}
          resetFilters={resetFilters}
          lastContacted={moment(userProfile?.lastContactedRef).format(
            "YYYY/M/D"
          )}
          mobileReg={mobileCandidate}
        />
      </ModalComponent>

      <ModalComponent
        show={showCall}
        style={{ width: "50px" }}
        className="modal-dialog modal-sm"
        header="Call User"
        closeModal={() => setShowCall(false)}
      >
        <OnboardingCall
          rowID={showRowID1}
          candidatePhoneNo={candidatePhoneNo}
          lanndLine={lanndLine}
          secondaryMobile={secondary}
          userprofile={userprofile}
          resetFilters={resetFilters}
          closeModal={() => setCalendarShow(false)}
        />
      </ModalComponent>

      <ModalComponent
        show={showCallDelete}
        style={{ width: "50px" }}
        className="modal-dialog modal-sm"
        closeModal={() => setShowCallDelete(false)}
      >
        <OnboardingCallDelete
          rowID={showRowID1}
          closeModal={() => setShowCallDelete(false)}
          modalType="Dates count"
          mode="Delete"
        />
      </ModalComponent>

      <ModalComponent
        show={showEmailModal}
        header="E mail and sms"
        closeModal={() => toggleM()}
      >
        <EmailNav closeModal={() => toggleM()} user={userProfile} />
      </ModalComponent>

      <ModalComponent
        show={showAddMap}
        header="Location"
        closeModal={() => setShowMap(false)}
        className="modal-dialog modal-xl"
      >
        <AddMap
          mode="individual"
          closeModal={() => setShowMap(false)}
          defaultPostalCode={postalCode}
          candidateId={userProfile?.id}
          // rowID={showRowID1}
        />
      </ModalComponent>
    </>
  );
};
export default OnboardingCallBackWidget;
