import React from "react";
import MultiChart from "../chartJS.js/chart";
import VerticalBar from "../chartJS.js/employeePerformance";
import OohTrackerTable from "../chartJS.js/oohTrackerTable";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import MaintenanceTrackerTable from "../chartJS.js/maintenanceTrackerTable";
import RegistrationTrackerTables from "../chartJS.js/registrationTrackerTables";
import MissingBookings from "../chartJS.js/missingBookings";
import MissingBookings1 from "../chartJS.js/missingBookings1";
import MissingBooking2 from "../chartJS.js/missingBooking2";

const Reports = () => {
  return (
    <DashboardLayout
      title="Reports"
      subTitle="Analyse Your Business With Reports"
      target=""
    >
      <VerticalBar />
      <MultiChart />
      <RegistrationTrackerTables />
      <OohTrackerTable />
      {/* <RegistrationTrackerTables /> */}
      {/* <MaintenanceTrackerTable /> */}
      {/* <MissingBookings /> */}
      <MissingBookings1 />
      <MissingBooking2/>

      {/* <DoughnutChart /> */}
    </DashboardLayout>
  );
};
export default Reports;
