import React, { useEffect, useState } from "react";
import {
  MailBox,
  MoveToTrash,
  Search,
} from "./All_Mail_Request/MailboxRequest";
import { Link } from "react-router-dom";
import ListofMails from "./ListofMails.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import Delete from "../../../components/Modal/ModalBody/delete";

const MainBodyOfMails = ({
  page,
  empty,
  mails,
  mail,
  credentials,
  setPost,
  setOther,
  children,
  setMail,
}) => {
  let checkedMails = [];
  let value = undefined;

  function changevalue(e) {
    value = undefined;
    value = e.target.value;
  }

  const [searchQuery, setSearchQuery] = useState("");
  const [showDeleteRow, setShowDeleteRow] = useState();

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const enterToSend = (event) => {
    if (event.key === "Enter") {
      Search(credentials.email, searchQuery, mail, setMail);
    }
  };
  const deleteEmail = () => {};

  return (
    <div className="d-flex flex" id="content-body" style={{marginTop:"1rem"}}>
      <div className="d-flex flex-column flex" id="mail-list">
        <div className="p-3">
          <div className="toolbar ">
            <div className="btn-group">
              <Link
                to="#"
                // to={"/email/" + mail.toLowerCase()}
                className="btn btn-sm btn-icon btn-white"
                data-toggle="tooltip"
                title="Trash"
                id="btn-trash"
                onClick={() => {
                  MoveToTrash(credentials, checkedMails, mail, setMail, page);
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Link>
              {/* <button
                className="btn btn-sm btn-icon btn-white sort "
                data-sort="item-title"
                data-toggle="tooltip"
                title="Sort"
              >
                <i className="sorting" />
              </button> */}
            </div>
            <div className="flex input-group">
              <Input
                onChange={(e) => handleSearchOnChange(e)}
                type="text"
                id="searchBar"
                className="form-control no-border no-shadow no-bg typeahead tt-input"
                placeholder="Search by email..."
                autoComplete="off"
                spellCheck="false"
                dir="auto"
                style={{
                  position: "relative",
                  verticalAlign: "top",
                  backgroundColor: "transparent",
                  // textTransform: "lowercase",
                }}
                onKeyDown={(e) => enterToSend(e)}
              />

              <span className="input-group-append">
                <Link to="#" className="btn btn-white no-border btn-sm">
                  <span className="d-flex text-muted">
                    <FontAwesomeIcon
                      icon={faSearch}
                      onClick={() =>
                        Search(credentials.email, searchQuery, mail, setMail)
                      }
                    />
                  </span>
                </Link>
              </span>
            </div>

            <button
              data-toggle="modal"
              data-target="#content-aside"
              data-modal
              className="btn btn-sm btn-icon btn-white d-md-none"
            >
              <i data-feather="menu" />
            </button>
          </div>
        </div>
        <ListofMails
          empty={empty}
          mailList={mails}
          checkedMails={checkedMails}
          mail={mail}
          credentials={credentials}
          setPosts={setPost}
          setOther={setOther}
        />
        <div>{children}</div>
      </div>

      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          MoveToTrash(credentials, checkedMails, mail, setMail, page);
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(!showDeleteRow)}
      />
    </div>
  );
};

export default MainBodyOfMails;
