import React, { useCallback, useEffect } from "react";
import {
  faFilePdf,
  faFileAlt,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import "../chat.style.css";
import "./support/ncode.css";
import { useState } from "react";
import { connect } from "react-redux";
import { split, forEach, find } from "lodash";
import AddChat from "./addChat.component";
import { Document, Page } from "react-pdf";
import { useDropzone } from "react-dropzone";
import ChatConversation from "./ChatConversation";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../components/toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormGroup, Col, Button, Label } from "reactstrap";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";

const ChatRoom = (props) => {
  const { chatPostLoad, activeChat } = props;

  const { addToast } = useToasts();

  const [files, setFiles] = useState([]);
  const [fileuploaded, setFileuploaded] = useState(false);
  const [fileOversize, setFileOversize] = useState(false);
  const [fileTypeofUpload, setFileTypeofUpload] = useState("");
  const [showHideFileUpload, setShowHideFileUpload] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    let splitedArray = split(acceptedFiles[0].type, "/");
    let document_TYPE = splitedArray[1];
    setFileTypeofUpload(document_TYPE);

    let totalSize = 0;
    forEach(acceptedFiles, function (file) {
      totalSize = file.size + totalSize;
    });
    // files over 50 mb (Total file size)cannot upload
    if (totalSize > 50000000) {
      setFileOversize(true);
    } else {
      setFileOversize(false);
    }
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });
  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };
  const thumb = {
    display: "inline-flex",
    justify: "center",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 300,
    height: 300,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbs = (file, id) => (
    <div className={"m-1"} key={id}>
      <div key={file.name}>
        <div className="d-flex justify-items-center align-items-center flex-column">
          <div style={{ width: "100px", height: "100px" }}>
            {" "}
            <img
              src={file.preview}
              width="100px"
              height="100px"
              style={{ objectFit: "scale-down" }}
            />
          </div>

          {file.name.length > 10 ? "Image " + id : <Label>{file.name}</Label>}
        </div>
      </div>
    </div>
  );

  const clearFilesStates = () => {
    setFiles([]);
  };
  const [activeChatName, setActiveChatName] = useState();

  useDidMountEffect(() => {
    let activeChatName = find(chatPostLoad, function (item) {
      return item.user2sId == activeChat;
    });

    if (activeChatName != undefined) {
      setActiveChatName(
        activeChatName.user2sFirstName + " " + activeChatName.user2sLastName
      );
    }
  }, [activeChat]);

  // this callback function to grab stuff from the firechat
  return (
    <div className="d-flex flex pr-md-3 " id="content-body">
      <div className="d-flex flex-column flex card m-0 mb-md-3" id="chat-list">
        <div className="navbar flex-nowrap b-b">
          <button className="d-md-none btn btn-sm btn-icon no-bg">
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-menu"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </span>
          </button>
          <span className=" d-flex flex-column mx-1">
            <div className="text-md text-highlight mx-2">Messages</div>{" "}
            <div className="text-sm text-highlight mx-2">{activeChatName}</div>{" "}
          </span>
          <span className="flex"></span>
          <div></div>
        </div>
        <div className=" hover">
          <div className="list">
            <div className="p-3">
              <div className="chat-list">
                {!showHideFileUpload ? (
                  <ChatConversation />
                ) : (
                  <>
                    <div
                      className={
                        "d-flex justify-content-center align-items-center"
                      }
                    >
                      <FormGroup row>
                        <Col sm={12}>
                          {/* <Label>Please upload excel file to search </Label> */}

                          <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                              border: "solid 2px  ",
                              borderRadius: "2px",
                              borderColor: "#007bff",
                              width: "500px",
                              height: files.length > 0 ? "50px" : "260px",
                            }}
                            {...getRootProps()}
                          >
                            <input id="dropZone" {...getInputProps()} />

                            <>
                              {isDragActive ? (
                                <p className="m-3">Drop the file here ...</p>
                              ) : (
                                <p className="m-3">
                                  Drag 'n' drop excel file here, or click to
                                  select file
                                </p>
                              )}
                            </>
                          </div>
                          <div>
                            {files.length > 0 && !fileOversize ? (
                              <>
                                <div className="justify-content-center d-flex    align-items-center p-2">
                                  {files.map((file, id) => {
                                    let splitedArray = split(file.type, "/");
                                    let document_TYPE = splitedArray[1];

                                    if (document_TYPE == "pdf") {
                                      return (
                                        <div
                                          key={id}
                                          className="justify-content-center d-flex  flex-column align-items-center p-2"
                                        >
                                          <div>
                                            <FontAwesomeIcon
                                              icon={faFilePdf}
                                              className="fa-5x "
                                            />
                                          </div>
                                          <div>
                                            <small>{file.name} </small>
                                          </div>
                                        </div>
                                      );
                                    } else if (
                                      document_TYPE == "png" ||
                                      document_TYPE == "jpg" ||
                                      document_TYPE == "JPEG" ||
                                      document_TYPE == "jpeg"
                                    ) {
                                      return (
                                        <div key={id} style={thumbsContainer}>
                                          {thumbs(file, id)}
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div
                                          key={id}
                                          className="justify-content-center d-flex  flex-column align-items-center p-2"
                                        >
                                          <div>
                                            <FontAwesomeIcon
                                              icon={faFileAlt}
                                              className="fa-5x "
                                            />
                                          </div>
                                          <div>
                                            {file.name.length > 10 ? (
                                              "File " + id
                                            ) : (
                                              <small>{file.name}</small>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    }
                                  })}
                                </div>
                              </>
                            ) : fileOversize ? (
                              <div className="d-flex justify-content-center mt-2 flex-column align-items-center">
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  className="fa-3x"
                                ></FontAwesomeIcon>
                                <Label>
                                  File exceed limit of 50mb please reduce the
                                  files or choose a different size{" "}
                                </Label>
                              </div>
                            ) : null}
                          </div>

                          <div className="d-flex mt-1 justify-content-center align-items-center">
                            <Button
                              onClick={() => {
                                setShowHideFileUpload(false);
                                clearFilesStates();
                              }}
                            >
                              Close
                            </Button>
                          </div>

                          {/* <Input
                type="file"
                name="file"
                id="file"
                onChange={(e) => {
                  const file = e.target.files[0];
                  readExcel(file);
                }}
                //  innerRef={register({
                //    required: {
                //      value: true,
                //      message: "This field is required!",
                //    },
                //  })}
               /> */}
                        </Col>
                      </FormGroup>
                    </div>
                  </>
                )}
              </div>
              <div className="hide">
                <div
                  className="chat-item"
                  id="chat-item"
                  style={{
                    visibility: "visible",
                    transform: "none",
                    opacity: 1,
                    transition:
                      "transform 0.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0s, opacity 0.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0s",
                  }}
                >
                  <a href="#" className="avatar w-40">
                    <img className="image" src="" alt="" />
                  </a>
                  <div className="chat-body">
                    <div className="chat-content rounded msg block bg-body"></div>
                    <div className="chat-date date"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-auto b-t" id="chat-form">
          <div className="p-2">
            <div className="px-3">
              {/* <div className="toolbar my-1">
                          <a href="#" className="text-muted mx-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-image"
                            >
                              <rect
                                x="3"
                                y="3"
                                width="18"
                                height="18"
                                rx="2"
                                ry="2"
                              ></rect>
                              <circle cx="8.5" cy="8.5" r="1.5"></circle>
                              <polyline points="21 15 16 10 5 21"></polyline>
                            </svg>
                          </a>
                          <a href="#" className="text-muted mx-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-camera"
                            >
                              <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
                              <circle cx="12" cy="13" r="4"></circle>
                            </svg>
                          </a>
                          <a href="#" className="text-muted mx-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-map-pin"
                            >
                              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                              <circle cx="12" cy="10" r="3"></circle>
                            </svg>
                          </a>
                          <a href="#" className="text-muted mx-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-paperclip"
                            >
                              <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                            </svg>
                          </a>
                        </div> */}
            </div>

            {/* chat text box... */}

            <AddChat
              fileOversize={fileOversize}
              files={files}
              clearFilesStates={() => clearFilesStates()}
              showHideFileUpload={(data) => setShowHideFileUpload(data)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    userId: state.auth.item.id,
    // reduxChatScriptId: state.ChatRedux.chatfunkyId,
    chatPostLoad: state.chatDbPostReducer.chatDbPostLoad,
    activeChat: state.chatActiveIdReducer.chatActive,
  };
}

export default connect(mapStateToProps, {})(ChatRoom);
