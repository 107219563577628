import React, { useEffect, useState } from "react";
import "./docView.style.css";
import { split } from "lodash";
import { Document, Page, pdfjs } from "react-pdf";
import { useDropzone } from "react-dropzone";
import "react-dropzone-uploader/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faImage,
  faRedo,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import ImageEdit from "../../../widgets/image-edit/image-edit";
import { connect } from "react-redux";
import { saveGeneratedUrl } from "../../../redux/actions/timesheetBatchScanAction";

const thumbsContainer = {
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  // marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  justify: "center",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 300,
  height: 300,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};

const dropzoneLarge = {
  display: "flex",
  border: "2px solid #2c3763",
  height: "40rem",
  width: "80%",
  padding: 10,
  justifyContent: "center",
  alignItems: "center",
};

const dropzoneSmall = {
  display: "flex",
  border: "2px solid #2c3763",
  height: "5rem",
  width: "80%",
  padding: 10,
  justifyContent: "center",
  alignItems: "center",
};

function PreviewsBatchTimeSheets(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const {
    fileUploaded,
    documentUploaded,
    documentPreUploaded,
    expandStatus,
    showUploadSection,
    incomingData,
    showFile,
    review,
    locallySaveTimesheetsReducer,
    failedTimesheets
  } = props;
  const [zoom, setZoom] = useState(1);
  const [files, setFiles] = useState([]);
  const [croppedFile, setCroppedFile] = useState([]);
  const [savePottentialFile, setSavePottentialFile] = useState([]);
  const [imageZoom, setImageZoom] = useState(400);
  const [fileTypeofUpload, setFileTypeofUpload] = useState("");
  const [editModal, setEditmodal] = useState(false);
  const [editableImage, setEditableImage] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [dropZoneActivateStatus, setDropZoneActivateStatus] = useState(false);

  useDidMountEffect(() => {
    if (incomingData == null) {
      setFiles([]);
    }
  }, [incomingData]);
  const modalCallback = (type) => {
    if (type == "save") {
      setFiles(croppedFile); //preview the edited image
      props.onUpload(savePottentialFile); //passing to another component to upload to the db
    }
    setEditmodal(false);
  };

  const cropImage = (data) => {
    setEditmodal(data.show);
    setEditableImage(data.image);
    // remove();
  };
  const [generatedUrl, setGeneratedUrl] = useState("");

  useEffect(() => {
    const storedUrl = localStorage.getItem("generatedUrl");

    if (storedUrl) {
      const expirationTime = localStorage.getItem("generatedUrlExpiration");

      if (expirationTime && new Date(expirationTime) > new Date()) {
        setGeneratedUrl(storedUrl);
      } else {
        localStorage.removeItem("generatedUrl");
        localStorage.removeItem("generatedUrlExpiration");
      }
    }
  }, []);

  useEffect(() => {
    const storedUrl = localStorage.getItem("generatedUrl");
    if (storedUrl) {
      setGeneratedUrl(storedUrl);
    }
  }, []);

  useEffect(() => {
    const storedUrl = localStorage.getItem("generatedUrl");

    if (storedUrl) {
      const expirationTime = localStorage.getItem("generatedUrlExpiration");

      if (expirationTime && new Date(expirationTime) > new Date()) {
        setGeneratedUrl(storedUrl);
      } else {
        localStorage.removeItem("generatedUrl");
        localStorage.removeItem("generatedUrlExpiration");
      }
    }
  }, []);

  // Run this
  const { getRootProps, getInputProps } = useDropzone({
    //  accept: "image/*",
    onDrop: (acceptedFiles) => {
      let splitedArray = split(acceptedFiles[0].type, "/");
      let document_TYPE = splitedArray[1];
      // console.log("document_TYPE", document_TYPE);

      setFileTypeofUpload(document_TYPE);

      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      props.onUpload(acceptedFiles);
    },
  });
  const [showImageModal, setShowImageModal] = useState([]);

  const toggleModal = (index) => {
    // Toggle the visibility of the clicked row level 2
    if (showImageModal.includes(index)) {
      setShowImageModal(
        showImageModal.filter((rowIndex) => rowIndex !== index)
      );
    } else {
      setShowImageModal([...showImageModal, index]);
    }
  };

  const thumbs = files.map((file, id) => (
    <div className={""} key={id}>
      <div key={file.name}>
        <div>
         
        
        </div>
      </div>

  


      <br />
    </div>
  ));


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const pdfNextPage = () => {
    if (numPages > pageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const pdfPreviousPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };
  const rotateLeft = () => {
    setRotationAngle(angle => angle + 90);
  };
  
  
  const rotateRight = () => {
    setRotationAngle(angle => angle - 90);
  };
  const setScale = () => {
    setDropZoneActivateStatus(false);
    // if (documentPreUploaded){
    //   setDropZoneActivateStatus(false);
    // }else if (!documentPreUploaded){
    //   setDropZoneActivateStatus(true);
    // }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const editedImagePReviewFunction = (data) => {
    var f = new File(["image"], "image.jpeg", {
      type: "image/jpeg",
      lastModified: new Date(),
    });
    Object.assign(f, {
      preview: data,
      path: "image.jpeg",
    });

    setCroppedFile([f]);
    // setFiles([f]); //preview the edited image

    var fileToUpload = dataURLtoFile(data, editableImage.name);
    setSavePottentialFile([fileToUpload]);
    // props.onUpload([fileToUpload]); //passing to another component to upload to the db
  };

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useDidMountEffect(() => {
    if (files.length > 0) {
      fileUploaded(true);
    } else {
      fileUploaded(false);
    }
  }, [files]);

  useEffect(() => {
    setFiles([]);
  }, [documentUploaded]);

  

  const [rotationAngle, setRotationAngle] = useState(0);

  const rotateImage = () => {
    setRotationAngle(rotationAngle + 90);
  };

  return (
    <>
      <section className="container d-flex flex-column align-items-center justify-content-center">
        {showUploadSection && (
          <div
            {...getRootProps({ className: "dropzone" })}
            onMouseOver={() => setDropZoneActivateStatus(true)}
            onMouseOut={() => setScale()}
            style={dropZoneActivateStatus ? dropzoneLarge : dropzoneSmall}
          >
            <input {...getInputProps()} />
            <div className="d-flex flex-column align-items-center justify-content-center">
              <p>Drag n' drop or click here to select a Timesheet</p>
              <p>Only JPEG or JPG and PDF formats are allowed</p>
            </div>
          </div>
        )}

        {showFile?.fileExtention == "pdf" && showFile?.s3bucketfileurl ? (
          <>
            <div className="justify-content-center d-flex  flex-column align-items-center p-2">
              <div height="1000px">
                <Document
                  // file={showFile?.file?.preview}
                  file={showFile?.s3bucketfileurl}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page
                    pageNumber={pageNumber}
                    scale={zoom}
                    width={expandStatus ? 700 : 400}
                    height={expandStatus ? 700 : 400}
                    rotate={rotationAngle}
                  />
                </Document>
              </div>
              <div className="justify-content-center d-flex flex-column align-items-center">
                <div className="" style={{ width: "500px" }}>
                  <Input
                    type="range"
                    // min={1}
                    // max={2}
                    // value={zoom}
                    // name="range"
                    min={0.5} // Minimum zoom level (50%)
                    max={2}    // Maximum zoom level (200%)
                    step={0.1} // Incremental step for smooth zooming
                    value={zoom}
                    onChange={(e) => {
                      setZoom(e.target.value);
                    }}
                  />
                           
                </div>{" "}
              </div>
              
              <div className="d-flex align-items-center justify-content-center">
                <div style={{ width: "5px" }}>
                  <FontAwesomeIcon
                    icon={faAngleLeft}
                    onClick={pdfPreviousPage}
                    className="  btn-raised btn-wave pointer shadow-none"
                  />
                </div>
                <div className="m-2">
                  Page {pageNumber} of {numPages}
                </div>
                <div style={{ width: "5px" }}>
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className="  btn-raised btn-wave pointer shadow-none"
                    onClick={pdfNextPage}
                  />

               
                </div>
              </div>
              <br/>
              <div className="justify-content-center d-flex flex-column align-items-center">
      <div style={{ position: 'absolute', zIndex: 9999 }}>
        <Button style={{ borderRadius: '20px' }} color="primary" onClick={rotateLeft}>
          <FontAwesomeIcon icon={faRedo} />
        </Button>
        {' '}
        <Button style={{ borderRadius: '20px' }} color="primary" onClick={rotateRight}>
          <FontAwesomeIcon icon={faUndo} />
        </Button>
      </div>
    </div>
            </div>
          </>
        ) : (
          <div style={thumbsContainer}>
            {/* {thumbs} */}
            {review?.includes(showFile?.file?.preview) && (
              <>
                {/* <img
                  src={showFile?.file?.preview}
                  style={{
                    maxWidth: "100%",
                    transform: `rotate(${rotationAngle}deg)`,
                  }} */}
<br/><br/><br/><br/>
                  
                <img
                  src={showFile?.s3bucketfileurl}
                  style={{
                    maxWidth: "100%",
                    transform: `rotate(${rotationAngle}deg)`,
                  }}
                  width={
                    expandStatus
                      ? imageZoom > 400
                        ? imageZoom
                        : 1000
                      : imageZoom > 400
                      ? imageZoom
                      : 400
                  }
                />

            

<br/><br/>
<br/><br/>
                <div className="justify-content-center d-flex flex-column align-items-center">
                  <div className="" style={{ width: "400px" }}>
                    <Input
                      type="range"
                      min={400}
                      max={600}
                      value={imageZoom}
                      name="range"
                      onChange={(e) => {
                        // console.log("Zoom :", e.target.value);
                        setImageZoom(e.target.value);
                      }}
                    />
                    {/* <img src={generatedUrl} alt="S3 Image"style={{ width: "500px" }}  /> */}
                  </div>
                </div>
                <br/> 

                <div className="justify-content-center d-flex flex-column align-items-center">
                <div style={{ position: 'absolute', zIndex: 9999 }}>
                <Button style={{ borderRadius: '20px' }} color="primary" onClick={rotateLeft}>
  <FontAwesomeIcon icon={faRedo} />
  </Button>{' '}
 

  <Button style={{ borderRadius: '20px' }} color="primary" onClick={rotateRight}>
  <FontAwesomeIcon icon={faUndo} />
  </Button>
                </div>
                </div>

                {/* <Button onClick={rotateImage}>Rotate Image</Button> */}
                
              </>
            )}
          </div>
        )}
      </section>

      {/* <Modal isOpen={editModal} toggle={modalCallback} size="xl">
        <ModalHeader toggle={modalCallback}>Edit Image</ModalHeader>
        <ModalBody>
          <ImageEdit
            image={editableImage}
            editedImage={(data) => editedImagePReviewFunction(data)}
          ></ImageEdit>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => modalCallback("save")}>
            Save
          </Button>{" "}
          <Button color="secondary" onClick={() => modalCallback("cancel")}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal> */}
    </>
  );
}

function mapStateToProps(state) {
  return {
    reduxSpecialities: state.initialDataGetReducer.specialities,
    reduxClients: state.initialDataGetReducer.clients,
    reduxRoles: state.initialDataGetReducer.roles,
    user: state.auth.item.firstName + " " + state.auth.item.lastName,
    timesheet: state.timesheets.timesheets,
    locallySaveTimesheetsReducer:
      state.locallySaveTimesheetsReducer?.timesheets,
  };
}

export default connect(mapStateToProps, {})(PreviewsBatchTimeSheets);
