import React, { useState, useEffect } from "react";
import axios from "axios";
import { filter } from "lodash";
import { useForm } from "react-hook-form";
import { USERROLES } from "../../../../configs/api-config";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";

const ConfigureUserRoles = (props) => {
  const MexxarApi = USERROLES;
  const {
    onCreate,
    closeModal,
    rowID,
    mode,
    permissions,
    fieldDataOne,
    fieldDataTwo,
  } = props;

  const { register, handleSubmit, errors } = useForm();

  const [updating, setUpdating] = useState([]);
  const [value, setValue] = useState(fieldDataOne);
  const [permissionIds, setPermissionIds] = useState([]);
  const changeHandler = ({ target }) => {
    setValue(target.value.toUpperCase());
  };

  const onSubmit = (data) => {
    onCreate({ permissionList: permissionIds, formData: data });
  };

  const geTRowData = () => {
    axios
      .get(MexxarApi + "/" + rowID)
      .then((res) => {
        let ids = [];
        let rowRolePermissions = [];
        let updatingPermissions = [];
        rowRolePermissions = res.data.body[0].permissions;
        rowRolePermissions.forEach((item) => {
          ids.push(item.id);
        });
        permissions.forEach((item) => {
          let ifTure = filter(ids, (data) => {
            return item.id == data;
          });
          if (ifTure.length !== 0) {
            updatingPermissions.push({
              id: item.id,
              description: item.description,
              allow: true,
              deny: false,
            });
          } else {
            updatingPermissions.push({
              id: item.id,
              description: item.description,
              allow: false,
              deny: true,
            });
          }
        });
        setUpdating(updatingPermissions);
        setPermissionIds(ids);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  useEffect(() => {
    geTRowData();
  }, []);

  const addIds = (e) => {
    setPermissionIds([...permissionIds, Number(e.target.value)]);
  };

  const deleteIds = (e) => {
    let deleteN = filter(permissionIds, (item) => {
      return item !== Number(e.target.value);
    });
    setPermissionIds(deleteN);
  };

  return (
    <div className=" ">
      <Form className="col-12  " onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Label sm={4}>Consultant role</Label>
          <Col sm={8}>
            <Input
              value={value}
              onChange={changeHandler}
              type="text"
              name="rowName"
              //defaultValue={fieldDataOne}
              invalid={errors.bName}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
                maxLength: {
                  value: 50,
                  message: "This field only allowed only 50 characters",
                },
              })}
            ></Input>
            {errors.bName && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.bName?.message}
                </li>
              </ul>
            )}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={4}>Descriptiion</Label>
          <Col sm={8}>
            <Input
              type="text"
              name="rowFieldTwo"
              defaultValue={fieldDataTwo}
              invalid={errors.description}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
                maxLength: {
                  value: 100,
                  message: "This field only allowed only 100 characters",
                },
              })}
            ></Input>
            {errors.description && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.description?.message}
                </li>
              </ul>
            )}
          </Col>
        </FormGroup>
        <hr />
        <Row>
          <Col md={6}>Permissions </Col>
        </Row>
        <br />
        <div className=""> 
          {updating.map((data, id) => {
            return (
              <Row>
                <Col md={6}>
                  <small className="">{data.description} </small>
                </Col>
                <Col md={3}>
                  <FormGroup check>
                    <Label>
                      <Input
                        type="radio"
                        name={"radio" + id}
                        value={data.id}
                        onChange={addIds}
                        defaultChecked={data.allow}
                        //checked={data.allow}
                      />
                      Allow
                    </Label>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup check>
                    <Label>
                      <Input
                        type="radio"
                        name={"radio" + id}
                        value={data.id}
                        onChange={deleteIds}
                        defaultChecked={data.deny}
                      />
                      Deny
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            );
          })}
        </div>

        <Row className="justify-content-end">
          <div className="d-flex ">
            <Button className="mr-2" color="success" type="submit">
              Save
            </Button>{" "}
            <Button color="secondary" onClick={() => closeModal()}>
              Close
            </Button>
          </div>
        </Row>
      </Form>
    </div>
  );
};

export default ConfigureUserRoles;
