import { useEffect, useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";

export default function useExpriedExpiringDocsFilters(
  url,
  query,
  pageNumber,
  searchAdvanced,
  reset,
  employeeId,
  qDocument,
  range,
  docStatus
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setRows([]);
  }, [query, reset]);

  useDidMountEffect(() => {
    setRows([]);
  }, [searchAdvanced, employeeId]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    let offset = 50;

    // if (searchAdvanced || query !== null) {
    //   offset = 150;
    // }

    axios({
      method: "GET",
      url: url,
      params: {
        page: pageNumber,
        offset: offset,
        q: query,
        employeeId: employeeId,
        "q-document": qDocument,
        expiringDays: range,
       },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        if (res.data.status === "success") {
          setTotalElements(res.data.body.totalElements);

          const filteredResponse = res.data.body.content.filter((item) => {
            if (docStatus === "ALL") {
              return item.expiredDocCount > 0 || item.expiringDocCount > 0;
            } else if (docStatus === "EXPIRED") {
              return item.expiredDocCount > 0;
            } else if (docStatus === "EXPIRING") {
              return item.expiringDocCount > 0;
            }
            return false;
          });

          setRows((prevBooks) => {
            return [...new Set([...prevBooks, ...filteredResponse])];
          });

          setHasMore(res.data.body.content.length > 0);
        } else {
          setError(true);
        }

        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setLoading(false);
        setError(true);
      });

    return () => cancel();
  }, [searchAdvanced, query, pageNumber, reset, employeeId]);

  return { loading, error, rows, hasMore, totalElements };
}
