import { useEffect, useState } from "react";
import store from "../redux/store";
import { employeesGetAction } from "../redux/actions/initialDataGetAction";

export default function usePrepareEmployee() {
  const [employees, setEmployees] = useState(null);

  let employeeReduxList = store.getState().initialDataGetReducer.employees;

  useEffect(() => {
    let listOfEmployees = [];

    if (employeeReduxList) {
      for (let i = 0; i < employeeReduxList.length; i++) {
        listOfEmployees.push({
          value: employeeReduxList[i].email,
          label:
            employeeReduxList[i].firstName +
            " " +
            employeeReduxList[i].lastName,
          id: employeeReduxList[i].id,
          employee: employeeReduxList[i],
        });
        setEmployees(listOfEmployees);
      }
    } else {
      store.dispatch(employeesGetAction());
    }
  }, [employeeReduxList]);

  return { employees };
}
