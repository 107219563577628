import React, { useState, useEffect } from "react";
import axios from "axios";
import { forEach, filter } from "lodash";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import {
  ClientLable,
  CANDIDATE_RATE_DATA,
  HOSTMexxar,
} from "../../../configs/api-config";
import { Button, Input, Form, FormGroup, Label, Col, Alert } from "reactstrap";
import useDidMountEffect from "./intialRunUseEffect";
import sortByCustomOrder from "../../../util/common/sortByCustomOrder";

function ClientRateBookingModal(props) {
  const MexxarApiCandidateRate = CANDIDATE_RATE_DATA;
  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();
  const { closeModal, candidate, toggle } = props;

  const [roles, setRoles] = useState();
  const [clients, setClients] = useState();
  const [update, setUpdate] = useState(false);
  const [departments, setDepartments] = useState();
  const [currentDepartment, setCurrentDepartment] = useState(-1);
  const [editable, setEditable] = useState(false);
  const [specialities, setSpecialities] = useState();
  const [currentClient, setCurrentClient] = useState(-1);
  const [currentSpeciality, setCurrentSpeciality] = useState(
    candidate.candidate.candidateSpecialities[0].id
  );
  const [candidateData, setCandidateID] = useState(candidate.candidate);
  const [rateTypes, setRateTypes] = useState([
    { type: "Rate Week Days", key: 0 },
    { type: "Rate Saturday", key: 1 },
    ,
    { type: "Rate Sunday", key: 2 },
    { type: "Rate Bank Holiday", key: 3 },
  ]);

  const [rateType, setRateType] = useState("Candidate");

  const toggleRates = (e) => {
    if (e.target.value === "Candidate") {
      setRateType(ClientLable);
    } else if (e.target.value === ClientLable) {
      setRateType("Candidate");
    }
  };

  const typeFinder = (data) => {
    let shortForm;
    let type;
    let key;
    switch (data) {
      case "WEEK":
        shortForm = data;
        type = "Rate Week Days";
        key = 0;
        break;
      case "SAT":
        shortForm = data;
        type = "Rate Saturday";
        key = 1;
        break;
      case "SUN":
        shortForm = data;
        type = "Rate Sunday";
        key = 2;
        break;
      case "BH":
        shortForm = data;
        type = "Rate Bank Holiday";
        key = 3;
        break;
    }
    return { shortForm: shortForm, type: type, key: key };
  };

  const getRates = (clientId, specialityId, roleId, bandId) => {
    let typeOfRate =
      rateType === "Candidate" ? "candidate-rates" : "client-rates";
    const candidateIdParam =
      rateType === "Candidate" ? `&candidateId=${candidateData.id}` : "";

    const url = `${HOSTMexxar}${typeOfRate}/filter?offset=17&clientId=${clientId}&specialityId=${specialityId}&roleId=${roleId}&clientWardId=${currentDepartment}&bandId=${bandId}${candidateIdParam}`;

    axios.get(url).then((res) => {
      if (res.data.body?.content?.length > 0) {
        setUpdate(true);
      } else {
        setUpdate(false);
      }

      let response = res.data.body?.content;
      let DayType = ["WEEK", "SAT", "SUN", "BH"];
      let DayNight = ["DAY", "NIGHT"];
      let DefaultSpecial = ["D", "NIGHT"];

      let tempUpdatingArray = [];
      let tempArray = [];
      forEach(DayType, function (daytype) {
        //......filter Day Rate.........//

        // console.log("Res .........", daytype);

        let ByDayType = filter(response, (item) => {
          return item.dayType === daytype;
        });
        // console.log("A .........", ByDayType);

        // let DayNight = filter(response, (item) => {
        //   return item.dayType === daytype;
        // });
        // console.log("DayNight .........", DayNight);

        let DayNightNight = filter(ByDayType, (item) => {
          return item.payRollDayMode === "NIGHT";
        });
        // console.log("DayNightNight.........", DayNightNight);

        let DayNightDay = filter(ByDayType, (item) => {
          return item.payRollDayMode === "DAY";
        });
        // console.log("DayNightDay.........", DayNightDay);

        // Special

        let RateModeSpecial = filter(ByDayType, (item) => {
          return item.rateMode === "SPECIAL";
        });
        // console.log("RateModeSpecial .........", RateModeSpecial);

        let RateModeSpecialDay = filter(RateModeSpecial, (item) => {
          return item.payRollDayMode === "DAY";
        });
        // console.log("RateModeSpecialDay.........", RateModeSpecialDay);

        let RateModeSpecialNight = filter(RateModeSpecial, (item) => {
          return item.payRollDayMode === "NIGHT";
        });
        // console.log("RateModeSpecialNight.........", RateModeSpecialNight);

        // Default

        let RateModeDefault = filter(ByDayType, (item) => {
          return item.rateMode === "DEFAULT";
        });
        // console.log("RateModeDefault .........", RateModeDefault);

        let RateModeDefaultDay = filter(RateModeDefault, (item) => {
          return item.payRollDayMode === "DAY";
        });
        // console.log("RateModeDefaultDay.........", RateModeDefaultDay);

        let RateModeDefaultNight = filter(RateModeDefault, (item) => {
          return item.payRollDayMode === "NIGHT";
        });
        // console.log("RateModeDefaultNight.........", RateModeDefaultNight);

        tempArray.push({
          type: typeFinder(daytype).type,
          key: typeFinder(daytype).key,
          shortForm: daytype,

          nId: null,
          dId: null,
          sDId: null,
          sNId: null,

          nStart: null,
          nEnd: null,
          nRate: null,

          dStart: null,
          dEnd: null,
          dRate: null,

          bRate: null,
        });

        tempUpdatingArray.push({
          type: typeFinder(daytype).type,
          key: typeFinder(daytype).key,
          shortForm: daytype,

          nId:
            RateModeDefaultNight[0] === undefined
              ? ""
              : Number(RateModeDefaultNight[0].id),
          dId:
            RateModeDefaultDay[0] === undefined
              ? ""
              : Number(RateModeDefaultDay[0].id),
          sDId:
            RateModeSpecialDay[0] === undefined
              ? ""
              : Number(RateModeSpecialDay[0].id),
          sNId:
            RateModeSpecialNight[0] === undefined
              ? ""
              : Number(RateModeSpecialNight[0].id),

          nStart:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].startTime,
          nEnd:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].endTime,
          nRate:
            RateModeDefaultNight[0] === undefined
              ? ""
              : RateModeDefaultNight[0].rate,

          dStart:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].startTime,
          dEnd:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].endTime,
          dRate:
            RateModeDefaultDay[0] === undefined
              ? ""
              : RateModeDefaultDay[0].rate,

          bRate:
            RateModeSpecial[0] === undefined ? "" : RateModeSpecial[0].rate,
        });
      });

      setRateTypes(tempArray);
      setRateTypes(tempUpdatingArray);
    });
  };

  useEffect(() => {
    if (candidate !== null && candidate !== undefined) {
      // getCandidateRateById(candidate.candidate.id);
      setClients(candidate.candidate.preferredClients);
      setCurrentClient(candidate.candidate?.preferredClients?.[0]?.id);

      if (
        candidate.candidate.candidateSpecialities != undefined ||
        candidate.candidate.candidateSpecialities != null
      ) {
        setSpecialities(candidate.candidate.candidateSpecialities);
      }

      if (
        candidate.candidate.candidateRole != undefined ||
        candidate.candidate.candidateRole != null
      ) {
        setRoles(candidate.candidate?.candidateRole);
      }
    }
  }, [candidate]);

  useEffect(() => {
    if (
      currentClient !== undefined &&
      currentClient !== null &&
      currentSpeciality !== undefined &&
      currentSpeciality !== null
    ) {
      getRates(
        currentClient,
        currentSpeciality,
        candidate.candidate.candidateRole.id,
        candidate.candidate.candidateBand.id
      );
    }
  }, [currentClient, currentSpeciality, currentDepartment, rateType]);

  useDidMountEffect(() => {
    axios
      .get(HOSTMexxar + "client-wards/clients/" + currentClient)
      .then((res) => {
        let wards = res.data.body;

        // Sort array so that the "Default Ward" is first, "Escalation Ward" in second of the list
        const sortedWards = sortByCustomOrder(
          wards,
          ["Default Ward", "Escalation Rates"],
          "wardName"
        );

        setDepartments(sortedWards);

        if (sortedWards.length > 0) {
          setCurrentDepartment(sortedWards[0]?.id);
        } else {
          setCurrentDepartment(-1);
        }
      })
      .catch((err) => {
        // console.log("err", err);
      });
  }, [currentClient]);

  const onSubmit = (data) => {
    console.log(data);
    //check if this is an update or new entry
    if (update == false) {
      let tempArray = [];
      for (let i = 0; i < 4; i++) {
        let BonusRate = Number(data[i + "BonusRate"]);
        let DayRate = Number(data[i + "DayRate"]);
        let DStartTime = data[i + "DStartTime"] + ":00";
        let DEndTime = data[i + "DEndTime"] + ":00";
        let NightRate = Number(data[i + "NightRate"]);
        let NStartTime = data[i + "NStartTime"] + ":00";
        let NEndTime = data[i + "NEndTime"] + ":00";
        tempArray.push(
          //......Bonus Day Rate .......//
          {
            dayType: i,
            payRollDayMode: 0,   // This should be 0 for Day Rates
            rate: BonusRate,

            rateMode: 1,
            candidateId: candidateData.id,
            specialityId: Number(currentSpeciality),
            roleId: candidateData.candidateRole.id,
            bandId: candidateData.candidateBand.id,
            startTime: DStartTime,
            endTime: DEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
          },
          //......Bonus Night Rate .......//
          {
            dayType: i,
            payRollDayMode: 1,    // This should be 1 for Night Rates
            rate: BonusRate,

            rateMode: 1,
            candidateId: candidateData.id,
            specialityId: Number(currentSpeciality),
            roleId: candidateData.candidateRole.id,
            bandId: candidateData.candidateBand.id,
            startTime: NStartTime,
            endTime: NEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
          },
          //......Day Rate.......//
          {
            dayType: i,
            payRollDayMode: 0, // This should be 0 for Day Rates
            rate: DayRate,

            rateMode: 0,
            candidateId: candidateData.id,
            specialityId: Number(currentSpeciality),
            roleId: candidateData.candidateRole.id,
            bandId: candidateData.candidateBand.id,
            startTime: DStartTime,
            endTime: DEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
          },
          //......Night Rate.......//
          {
            dayType: i,
            payRollDayMode: 1,    // This should be 1 for Night Rates
            rate: NightRate,
            rateMode: 0,
            candidateId: candidateData.id,
            specialityId: currentSpeciality,
            roleId: candidateData.candidateRole.id,
            bandId: candidateData.candidateBand.id,
            startTime: NStartTime,
            endTime: NEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
          }
        );
      }

        forEach(tempArray, function (data) {
        axios
          .post(MexxarApiCandidateRate, data)
          .then((res) => {
            if (res.data.status == "success") {
              // getData(clientID);
              // setShowAddRow(!showAddRow);
              ToastCommon(true, "success", addToast);
            } else {
              ToastCommon(true, "duplicate", addToast);
            }
          })
          .catch((err) => {
            ToastCommon(true, "error", addToast);
          });
      });
      closeModal(true);
    } else {
      let tempArray = [];

      const addSecondsIfNeeded = (time) =>
        time.includes(":")
          ? time + (time.split(":").length === 2 ? ":00" : "")
          : "";

      for (let i = 0; i < 4; i++) {
        let BonusRate = Number(data[i + "BonusRate"]);
        let DayRate = Number(data[i + "DayRate"]);
        let DStartTime = addSecondsIfNeeded(data[i + "DStartTime"]);
        let DEndTime = addSecondsIfNeeded(data[i + "DEndTime"]);
        let NightRate = Number(data[i + "NightRate"]);
        let NStartTime = addSecondsIfNeeded(data[i + "NStartTime"]);
        let NEndTime = addSecondsIfNeeded(data[i + "NEndTime"]);
        let DayRateId = data[i + "DayRate" + "ID"];
        let NightRateId = data[i + "NightRate" + "ID"];
        let BonusRateDayId = data[i + "BonusRateD" + "ID"];
        let BonusRateNightId = data[i + "BonusRateN" + "ID"];

        tempArray.push(
          //......Bonus Day Rate .......//
          {
            id: Number(BonusRateDayId),
            dayType: i,
            payRollDayMode: 0,         // This should be 0 for Day Rates
            rate: BonusRate,
            rateMode: 1,
            candidateId: candidateData.id,
            specialityId: Number(currentSpeciality),
            roleId: candidateData.candidateRole.id,
            bandId: candidateData.candidateBand.id,
            startTime: DStartTime,
            endTime: DEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
          },

          //......Bonus Night Rate .......//
          {
            id: Number(BonusRateNightId),
            dayType: i,
            payRollDayMode: 1,     // This should be 1 for Night Rates
            rate: BonusRate,

            rateMode: 1,
            candidateId: candidateData.id,
            specialityId: Number(currentSpeciality),
            roleId: candidateData.candidateRole.id,
            bandId: candidateData.candidateBand.id,
            startTime: NStartTime,
            endTime: NEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
          },
          //......Day Rate.......//
          {
            id: Number(DayRateId),
            dayType: i,
            payRollDayMode: 0,   // This should be 0 for Day Rates
            rate: DayRate,
            rateMode: 0,
            candidateId: candidateData.id,
            specialityId: Number(currentSpeciality),
            roleId: candidateData.candidateRole.id,
            bandId: candidateData.candidateBand.id,
            startTime: DStartTime,
            endTime: DEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
          },
          //......Night Rate.......//
          {
            id: Number(NightRateId),
            dayType: i,
            payRollDayMode: 1,     // This should be 1 for Night Rates
            rate: NightRate,

            rateMode: 0,
            candidateId: candidateData.id,
            specialityId: Number(currentSpeciality),
            roleId: candidateData.candidateRole.id,
            bandId: candidateData.candidateBand.id,
            startTime: NStartTime,
            endTime: NEndTime,
            clientId: currentClient,
            clientWardId: currentDepartment,
          }
        );
      }

       forEach(tempArray, function (data) {
        axios
          .put(MexxarApiCandidateRate, data)
          .then((res) => {
            if (res.data.status === "success") {
              // getData(clientID);
              // setShowAddRow(!showAddRow);
              ToastCommon(true, "success", addToast);
            } else {
              ToastCommon(true, "duplicate", addToast);
            }
          })
          .catch((err) => {
            console.log(err);
            ToastCommon(true, "error", addToast);
          });
      });
      closeModal(true);
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={6}>{rateType} rates for the following combination</Label>
        <Col sm={6} className="d-flex justify-content-end ">
          <Button value={rateType} color="info" onClick={(e) => toggleRates(e)}>
            {rateType} Rates
          </Button>
        </Col>
      </FormGroup>{" "}
      <FormGroup row>
        <Label sm={3}>Role</Label>
        <Col sm={9}>
          <Label sm={3}>{candidate?.candidate?.candidateRole?.name}</Label>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Band</Label>
        <Col sm={9}>
          <Label sm={3}>{candidate?.candidate?.candidateBand?.name}</Label>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Speciality</Label>
        <Col sm={9}>
          {specialities != null && specialities != undefined ? (
            <Input
              type="select"
              name="specialities"
              innerRef={register}
              onChange={(selected) =>
                setCurrentSpeciality(selected.target.value)
              }
            >
              {specialities.map((speciality, key) => (
                <option value={speciality.id} key={key}>
                  {speciality.name}
                </option>
              ))}
            </Input>
          ) : null}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>{ClientLable} Name</Label>
        <Col sm={9}>
          {clients != null && clients != undefined ? (
            <Input
              type="select"
              name="hospital"
              innerRef={register}
              onChange={(selected) => setCurrentClient(selected.target.value)}
            >
              {clients.map((client, key) => (
                <option value={client.id} key={key}>
                  {client.name}
                </option>
              ))}
            </Input>
          ) : null}
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Department Name</Label>
        <Col sm={9}>
          {departments != null && departments != undefined ? (
            <Input
              type="select"
              name="deapartment"
              // defaultValue={deapartment.id}
              value={currentDepartment}
              innerRef={register}
              onChange={(selected) =>
                setCurrentDepartment(selected.target.value)
              }
            >
              {departments.map((department, key) => (
                <option value={department.id} key={key}>
                  {department.wardName}
                </option>
              ))}
            </Input>
          ) : null}
        </Col>
      </FormGroup>{" "}
      <FormGroup row>
        <Col sm={12}>
          <Alert color={"info"} className="mt-2 text-sm">
            <li>
              {" "}
              To assign or view rates without specifying wards/department, use
              'Default Ward'.
            </li>
            <li>
              {" "}
              To define rates by ward/department, select the relevant
              ward/department under each {ClientLable}.
            </li>
            <li>
              {" "}
              Select 'Escalation Rates' from the ward/department list to view or
              set "Escalation Rates".
            </li>
          </Alert>
        </Col>
      </FormGroup>
      <div className="col-12 col-sm-12 ">
        <div>
          <div className="card-body">
            {rateTypes.map((rateType, id) => {
              return (
                <div className="d-flex flex-row" key={id}>
                  <div
                    className="d-flex justify-content-start align-items-center"
                    style={{ width: "150px" }}
                  >
                    {rateType.type}
                  </div>
                  <div
                    className="d-flex flex-column "
                    style={{ width: "600px" }}
                  >
                    {/* <div className="d-flex justify-content-center m-1">Week Days</div> */}
                    <div className="d-flex flex-row justify-content-center m-1">
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "130px" }}
                      >
                        Night
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "80px" }}
                      >
                        N. Rate
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "120px" }}
                      >
                        Day
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "60px" }}
                      >
                        D. Rate
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ width: "80px" }}
                      >
                        Bonus
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                      <div className="d-flex flex-column">
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "120px" }}
                        >
                          <Input
                            type="time"
                            placeholder=""
                            name={rateType.key + "NStartTime"}
                            defaultValue={rateType.nStart}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "120px" }}
                        >
                          <Input
                            type="time"
                            placeholder=""
                            name={rateType.key + "NEndTime"}
                            defaultValue={rateType.nEnd}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center m-1"
                        style={{ width: "60px" }}
                      >
                        <Input
                          type="text"
                          name={rateType.key + "NightRate"}
                          defaultValue={rateType.nRate}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                        <Input
                          type="hidden"
                          name={rateType.key + "NightRate" + "ID"}
                          defaultValue={rateType.nId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        />
                      </div>
                      <div className="d-flex flex-column">
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "120px" }}
                        >
                          <Input
                            type="time"
                            placeholder=""
                            name={rateType.key + "DStartTime"}
                            defaultValue={rateType.dStart}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                        <div
                          className="d-flex justify-content-center align-items-center m-1"
                          style={{ width: "120px" }}
                        >
                          <Input
                            type="time"
                            placeholder=""
                            name={rateType.key + "DEndTime"}
                            defaultValue={rateType.dEnd}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center m-1"
                        style={{ width: "60px" }}
                      >
                        <Input
                          type="text"
                          name={rateType.key + "DayRate"}
                          defaultValue={rateType.dRate}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                        <Input
                          type="hidden"
                          name={rateType.key + "DayRate" + "ID"}
                          defaultValue={rateType.dId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        />
                      </div>
                      <div
                        className="d-flex justify-content-center align-items-center m-1"
                        style={{ width: "60px" }}
                      >
                        <Input
                          type="text"
                          name={rateType.key + "BonusRate"}
                          defaultValue={rateType.bRate}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />

                        <Input
                          type="hidden"
                          name={rateType.key + "BonusRateD" + "ID"}
                          defaultValue={rateType.sDId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        />

                        <Input
                          type="hidden"
                          name={rateType.key + "BonusRateN" + "ID"}
                          defaultValue={rateType.sNId}
                          innerRef={register({
                            required: {
                              value: false,
                              message: "",
                            },
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {rateType === "Candidate" && (
              <Alert color="warning" className="my-2 text-sm">
                Press save only if you need to make changes to the default
                values.
              </Alert>
            )}
            <div className="d-flex justify-content-end mt-3 mr-3">
              {rateType === "Candidate" && (
                <Button color="success" type="submit" className="mx-3">
                  Save
                </Button>
              )}
              <Button color="secondary" onClick={closeModal}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

export default ClientRateBookingModal;
