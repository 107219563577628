import React, { useState, useRef, useCallback } from "react";
// import "./workspace.style.css";
import { connect } from "react-redux";
import { HOSTMexxar } from "../../../../configs/api-config";

import {
  Collapse,
  Card,
  CardBody,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  FormGroup,
  Badge,
} from "reactstrap";

import useDidMountEffect from "../../../../components/Modal/ModalBody/intialRunUseEffect";
import loadingAnimation from "../../../../../src/images/puff.svg";
import { useSpring, animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FunnelIcon } from "../../../../assets/icons/svg";
import NoConnection from "../../../errorPages/NoConnection";
import NoSearchResults from "../../../errorPages/NoSearchResults";
import ModalComponent from "../../../../components/Modal/modalComponent";
import AddUser from "../../../../components/Modal/ModalBody/Admin/addUser";
import OnboardingCallBackWidget from "../../../../widgets/working-card-widget/onboarding-callBackWidget";
import useOnboardingFetch from "../../../../customHooks/useOnboardingFetch";
import moment from "moment";
import axios from "axios";
import ToastCommon from "../../../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import Delete from "../../../../components/Modal/ModalBody/delete";

const OnboardingMobileCandidates = (props) => {
  const {
    // specialities,
    // roles,
    bands,
    addressWards,
    counties,
    districts,
    cvSources,
  } = props;

  var workflow = "HEADHUNTER";

  /***************** Search filter ******************/
  const [query, setQuery] = useState(null);
  const [reset, setReset] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchAdvanced, setSearchAdvanced] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showAddRow, setShowAddRow] = useState(false);

  const filterByName = (name, dataSet) => {
    let filtered = dataSet;
    if (name !== "") {
      filtered = dataSet.filter((item) => {
        console.log("item", item);
        let searchableString = `${item?.candidate?.firstName} ${item?.candidate?.lastName} ${item?.user?.firstName} ${item?.user?.lastName} ${item?.toEmail} ${item?.user?.email}     `;
        let p = searchableString;
        return p.toLowerCase().includes(name.toLowerCase());
      });
    }
    let lengthofSearch = filtered.length;
    return { filtered, lengthofSearch };
  };

  const currentDate = new Date();

  const yesterDay = moment(moment(currentDate).subtract(7, "days")).format(
    "YYYY-MM-DD"
  );
  const tommorow = moment(moment(currentDate).add(7, "days")).format(
    "YYYY-MM-DD"
  );
  const [notifications, setNotification] = useState({ value: "", bool: false });
  const [getStartDate, setGetStartDate] = useState(yesterDay);
  const [getEndDate, setGetEndDate] = useState(tommorow);
  const [resetState, setResetState] = useState(false);
  const [status, setStatus] = useState("");
  const [search, setSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { addToast } = useToasts();

  function handleSearch(e) {
    if (e.target.value == "") {
      setQuery(null);
    } else {
      setQuery(e.target.value);
    }
    setPageNumber(1);
  }

  let url = HOSTMexxar + "mobileSignUp/getAll/filter";

  const handleSelectItem = (id) => {
    if (selectedItems.includes(id)) {
      setSelectedItems(selectedItems.filter((item) => item !== id));
    } else {
      setSelectedItems([...selectedItems, id]);
    }
  };

  const { loading, error, rows, hasMore, totalElements } = useOnboardingFetch(
    url,
    query,
    pageNumber,
    searchAdvanced,
    reset,
    status,
    getEndDate,
    getStartDate,
    search
  );

  const [userProfiles, setUserProfiles] = useState([]);

  const updateUserProfileStatus = (id, newStatus) => {
    rows((prevProfiles) =>
      prevProfiles.map((profile) =>
        profile.id === id
          ? { ...profile, onboardingStatus: newStatus }
          : profile
      )
    );
  };

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  console.log("rows", rows);

  const resetFilters = () => {
    setReset(!reset);
    setStatus("");
    setPageNumber(1);
    setGetStartDate(yesterDay);
    setGetEndDate(tommorow);
  };
  const deleteAllRows = () => {
    axios
      .request({
        url: HOSTMexxar + "onboarding/delete-multiple",
        method: "DELETE",
        data: { onboardingIds: selectedItems },
      })
      .then((res) => {
        resetFilters();
        setSelectedItems([]);
        setSelectAll(false);
      })
      .catch((err) => {
        console.log(err);
      });

    ToastCommon(true, "delete", addToast);
  };

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  /******* Use to set page number to default value of 1 when changing the employee or search advance filters  *******/
  useDidMountEffect(() => {
    setPageNumber(1);
    return () => {};
  }, [searchAdvanced, reset]);
  /*******End *******/

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  return (
    <>
      <div className="form-inline ">
        <FontAwesomeIcon icon={faUser} className={"ml-2 mr-3  "} />
        <Label className={"mr-3"}>
          {totalElements}{" "}
          {/* {totalElements.length == 1 ? "Candidate" : "Candidates"} */}
        </Label>
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={handleSearch}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
        <UncontrolledTooltip target="searchBar">
          Search candidates by Candidate Name/email
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label>Status</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value=""
                      checked={status === ""}
                      onChange={(e) => setStatus(e.target.value)}
                    />
                    <Badge color="info">All</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="PENDING"
                      checked={status === "PENDING"}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="danger">Pending</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="REGISTERED"
                      checked={status === "REGISTERED"}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="secondary">Register</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="DECLINE"
                      checked={status === "DECLINE"}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="primary">Decline</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="CALLED"
                      checked={status === "CALLED"}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="success">Call</Badge>
                  </Label>
                </FormGroup>
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={() => {
                      setSearchAdvanced(!searchAdvanced);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    onClick={() => {
                      resetFilters();
                    }}
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
            <Row></Row>
          </CardBody>
        </Card>
      </Collapse>

      <div className="d-flex justify-content-between p-2 ml-3">
        <div>
          {selectedItems.length > 0 && (
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                setShowDeleteAll(true);
              }}
            >
              Delete
            </Button>
          )}
        </div>
        <div>
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddRow(true);
            }}
          >
            Add User
          </Button>
        </div>
      </div>

      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {rows?.map((row, i) => {
        if (rows.length === i + 1) {
          return (
            <animated.div
              style={animationProps}
              ref={lastBookElementRef}
              key={row.email}
            >
              <OnboardingCallBackWidget
                userProfile={row}
                isSelected={() => selectedItems.includes(row.id)}
                onItemSelect={() => handleSelectItem(row.id)}
                selectAll={selectAll}
                totalElements={totalElements}
                resetFilters={resetFilters}
                updateUserProfileStatus={updateUserProfileStatus}
                mobileCandidate={true}
              />
            </animated.div>
          );
        } else {
          return (
            <animated.div style={animationProps} key={row.email}>
              <OnboardingCallBackWidget
                userProfile={row}
                isSelected={() => selectedItems.includes(row.id)}
                onItemSelect={() => handleSelectItem(row.id)}
                selectAll={selectAll}
                totalElements={totalElements}
                resetFilters={resetFilters}
                updateUserProfileStatus={updateUserProfileStatus}
                mobileCandidate={true}
              />
            </animated.div>
          );
        }
      })}
      {rows && !loading
        ? filterByName(searchQuery, rows).length > 0
          ? filterByName(searchQuery, rows).map((row, i) => (
              <OnboardingCallBackWidget
                key={i}
                userProfile={row}
                status={status}
                reset={() => setResetState((prevState) => !prevState)}
                resetFilters={resetFilters}
                updateUserProfileStatus={updateUserProfileStatus}
              />
            ))
          : null
        : null}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      {!loading && !error && rows.length == 0 && <NoSearchResults />}
      <Delete
        show={showDeleteAll}
        isConfirm={() => {
          deleteAllRows();
          setShowDeleteAll(!showDeleteAll);
        }}
        closeModal={() => setShowDeleteAll(false)}
      />

      <ModalComponent
        show={showAddRow}
        header="Add New User "
        closeModal={() => setShowAddRow(false)}
      >
        <AddUser
          mode="Add"
          modalType="Document"
          closeModal={() => setShowAddRow(false)}
          resetFilters={resetFilters}
          //   onCreate={(data) => addRow(data)}
        />
      </ModalComponent>
    </>
  );
};

function mapStateToProps(state) {
  return {
    headhunter: state.headhunter,
    heahunterFiltered: state.heahunterFiltered,
    auth: state.auth,
    specialities: state.initialDataGetReducer.specialities,
    roles: state.initialDataGetReducer.roles,
    bands: state.initialDataGetReducer.bands,
  };
}

export default connect(mapStateToProps, {})(OnboardingMobileCandidates);
