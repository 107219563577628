// import React, { useState, useEffect } from "react";
// import { Button, Col, Spinner, UncontrolledTooltip } from "reactstrap";
// import axios from "axios";
// import moment from "moment";
// import ModalComponent from "../../components/Modal/modalComponent";
// import TimeSheet from "../../components/Modal/ModalBody/timeSheet";
// import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
// import { HOSTMexxar } from "../../configs/api-config";
// import TimeSheetUpload from "../../components/Modal/ModalBody/timeSheetUpload";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faEye,

//   faExclamationCircle,
//   faRobot,
//   faTimes,
//   faCheck,
// } from "@fortawesome/free-solid-svg-icons";
// import { isMatch } from "lodash";
// import { useDispatch } from "react-redux";
// import { updateTimesheetAIReviewedStatus, updateTimesheetReviewedData ,saveGeneratedUrl} from "../../redux/actions/timesheetBatchScanAction";

// const PendingTimesheetsforVerification = (props) => {
//   const { userProfile, refreshPage, toggleReview, id, file, toggleAIReview } =
//     props;

//   const { AIModalData, DBData } = file;
//   const dispatch = useDispatch();

//   const [calendarShow, setCalendarShow] = useState(false);
//   const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
//   const [showTimeModal, setShowTimeModal] = useState(false);
//   const [data, setData] = useState();
//   const [urlRedux, setGeneratedUrlInRedux] = useState();

//   const toggleShowTimeModal = (data) => {
//     setData(data);
//     setShowTimeModal(!showTimeModal);
//   };
//   // const setGeneratedUrlInRedux = (url) => {
//     dispatch(saveGeneratedUrl(urlRedux));
//     // console.log(urlRedux)

//     useEffect(() => {

//       dispatch(saveGeneratedUrl(urlRedux));
//     // console.log(urlRedux)

//     }, [urlRedux]);

//   // };
//   const {

//     refNum,
//     newRefNumber,

//     selectedFile2,

//   } = props;

//   // console.log("selectedFile2  angi--->:", selectedFile2);

//   const onSubmit = (data) => {

//     // Generate a 4-digit random number
//       const randomNumber = Math.floor(1000 + Math.random() * 9000);
//       const randomNumber2 = Math.floor(1000 + Math.random() * 9000);
//       const firstValue = Math.floor(20 + Math.random() * 8);
//       const secondValue = Math.floor(firstValue + 3 + Math.random() * 5);

//     let exportObject = {
//       PaymentPeriod: "",
//       ClientID: DBData?.clientId,
//       MeritID: randomNumber,
//       ExternalID:  DBData?.candidatesId,
//       MeritPlacementID: randomNumber2,
//       ExternalPlacementID: "",
//       WorkDescription: "Temp Staff",
//       PayDescription: "Basic Pay Temp Staff",
//       Quantity: AIModalData?.Totalhours,
//       PayRate: firstValue,
//       ChargeRate: secondValue,
//       Reference: DBData?.referenceNo,
//       Ref2: "",
//       Startdate: DBData?.bookingDate,
//       Enddate: "",
//       Comments: "",
//       EndUserClient: "",
//       ComparatorHolidayDays: "",
//       PaymentCurrencyCode: "GBP",
//       InvoiceCurrencyCode: "",
//       VatAdjust: "",
//       Consultant: "user",
//     };

//     let timesheet = {
//       locumName: DBData?.candidateName,
//       locumId: DBData?.candidatesId,
//       speciality: DBData?.speciality?.id,
//       band: Number(AIModalData?.Band),
//       hospitalName: DBData?.clientName,
//       dateOfShift: DBData?.bookingDate,
//       ward: DBData?.id,
//       bookingRef: DBData?.referenceNo,
//       startTime: AIModalData?.Start,
//       endTime: AIModalData?.End,
//       minsTakenForBreaks: AIModalData?.Breakmin,
//       totalHours: AIModalData?.Totalhours,
//       locumDate: DBData?.bookingDate,
//       authoriseName: "",
//       authorisePosition: "",
//       authoriseDate: DBData?.bookingDate,
//       bookingId: DBData?.id,
//       hospitalReviewStatus: 0,
//       paymentStatus: 0,
//       reviewStatus: 4,
//     };

//     let form = new FormData();
//     form.append("file", file.file);

//     axios.post(HOSTMexxar + "timesheets/", timesheet).then((res) => {
//       if (res.data.status == "success" && res.data.message == "created") {
//         // ToastCommon(true, "success", addToast);

//         uploadTimesheet(data, res, form);

//         dispatch(updateTimesheetReviewedData(file.file.preview, exportObject));
//       } else if (
//         res.data.status == "success" &&
//         res.data.message == "updated"
//       ) {
//         // ToastCommon(true, "update", addToast);
//         uploadTimesheet(data, res, form);

//         dispatch(updateTimesheetReviewedData(file.file.preview, exportObject));
//         // uploadTimesheet(data, res, form);
//       } else if (res.data.status == "failed") {
//         // ToastCommon(true, "error", addToast);
//       }
//     });
//   };

//   const uploadTimesheet = (data, res, form) => {
//     axios
//       .put(
//         HOSTMexxar +
//           "timesheets/" +
//           res.data.body[0].id +
//           "/upload-timesheetDocument",
//         form
//       )
//       .then((res) => {
//         if (res.data.status == "success" && res.data.message == "fetched") {
//           // ToastCommon(true, "timesheetUploaded", addToast);

//         } else if (res.data.status == "failed") {
//           // ToastCommon(true, "error", addToast);
//         }
//       })
//       .catch((err) => {
//         // ToastCommon(true, "error", addToast);
//       });
//   };

//   useEffect(() => {
//     // Check if all fields in incommingAIdata match the corresponding fields in systemTimeSheetData

//     if (file?.scanStatus?.status == "DONE" && file.AIModalData && file.DBData) {

//       //  getBucketUrl();

//       const unmatchedFields = [];

//       const compareIgnoreCase = (value1, value2, fieldName) => {
//         if (typeof value1 === 'string' && typeof value2 === 'string') {
//           return value1.toLowerCase() === value2.toLowerCase();
//         } else if (value1 === undefined || value2 === undefined) {
//           return { field: fieldName, value: value1 || value2 };
//         }
//         return false;
//       };

//       if (!compareIgnoreCase(AIModalData.Ref, DBData.referenceNo, 'Ref')) {
//         unmatchedFields.push("Ref");
//       }
//       if (!compareIgnoreCase(AIModalData.Ward, DBData.ward?.name, 'Ward')) {
//         unmatchedFields.push("Ward");
//       }
//       if (!compareIgnoreCase(AIModalData.CandidateId, DBData.candidatesId?.toString(), 'CandidateId')) {
//         unmatchedFields.push("CandidateId");
//       }
//       if (!compareIgnoreCase(AIModalData.CandidateName, DBData.candidateName, 'CandidateName')) {
//         unmatchedFields.push("CandidateName");
//       }
//       if (!compareIgnoreCase(AIModalData.Speciality, DBData.speciality?.name, 'Speciality')) {
//         unmatchedFields.push("Speciality");
//       }
//       if (!compareIgnoreCase(moment(AIModalData.Date).format('YYYY-MM-DD'), DBData.bookingDate, 'Date')) {
//         unmatchedFields.push("Date");
//       }
//       if (!compareIgnoreCase(moment(AIModalData.End, 'HH:mm').format('HH:mm'), moment(DBData.shiftTo).format('HH:mm'), 'End')) {
//         unmatchedFields.push("End");
//       }
//       if (!compareIgnoreCase(moment(AIModalData.Start, 'HH:mm').format('HH:mm'), moment(DBData.shiftFrom).format('HH:mm'), 'Start')) {
//         unmatchedFields.push("Start");
//       }
//       if (!compareIgnoreCase(AIModalData.HospitalName, DBData.clientName, 'HospitalName')) {
//         unmatchedFields.push("HospitalName");
//       }

//       // console.log("Unmatched Fields:", unmatchedFields);
//       // console.log("angi AIModalData?.Start", AIModalData?.Start);
//       // console.log("angi DBData?.shiftFrom",moment(DBData?.shiftFrom).format('HH:mm'));
//       // console.log("angi AIModalData?.CandidateName?",AIModalData?.CandidateName);

//       const isAMatch =
//             AIModalData?.Ref?.toLowerCase() === DBData?.referenceNo?.toLowerCase() &&
//             AIModalData?.Ward?.toLowerCase() === DBData?.ward?.name?.toLowerCase() &&
//            (AIModalData?.CandidateId?.toLowerCase() === DBData?.candidatesId?.toString()?.toLowerCase() ||
//              AIModalData?.CandidateName?.toLowerCase() === DBData?.candidateName?.toLowerCase()) &&
//             AIModalData?.Speciality?.toLowerCase() === DBData?.speciality?.name?.toLowerCase() &&
//             moment(AIModalData?.Date).format('YYYY-MM-DD') === DBData?.bookingDate &&
//             moment(AIModalData?.Start, 'HH:mm').format('HH:mm') === moment(DBData?.shiftFrom).format('HH:mm') &&
//             moment(AIModalData?.End, 'HH:mm').format('HH:mm') === moment(DBData?.shiftTo).format('HH:mm') &&
//             AIModalData?.HospitalName?.toLowerCase() === DBData?.clientName?.toLowerCase();

//       if (isAMatch) {
//         dispatch(
//           updateTimesheetAIReviewedStatus(file?.file?.preview, "MATCHING")

//         );

//         onSubmit()

//       } else {
//         dispatch(
//           updateTimesheetAIReviewedStatus(file?.file?.preview, "NOT_MATCHING")
//         );
//       }
//     }

//     // Add more fields for comparison if needed
//   }, [file.scanStatus.status,file.DBData]);

//   const [generatedUrl, setGeneratedUrl] = useState('');
//   const [bookingRef, setBookingRef] = useState(selectedFile2?.AIModalData?.Ref);

//   // const getBucketUrl = async () => {
//   //           console.log('Angi 1 URL generated successfully:');

//   //   try {
//   //     const originalFileName = selectedFile2.name;
//   //     const fileExtension = originalFileName.split('.').pop();

//   //     const fileName = bookingRef + '.' + fileExtension;

//   //     const response = await axios.get(
//   //       'https://time.mexxar.com/timesheet/url',
//   //       {
//   //         params: {
//   //           fileName: fileName,
//   //           folderName: 'mex_candy',
//   //           linkDuration: '10000',
//   //         },
//   //         headers: {
//   //           'Access-Control-Allow-Origin': '*',
//   //           'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
//   //         },
//   //         mode: 'cors',
//   //       }
//   //     );

//   //     if (response.status === 200) {
//   //       const url = response.data.body.url;
//   //       setGeneratedUrlInRedux(url);

//   //       const expirationTime = new Date();
//   //       expirationTime.setMinutes(expirationTime.getMinutes() + 0);

//   //       localStorage.setItem('generatedUrl', url);
//   //       localStorage.setItem('generatedUrlExpiration', expirationTime.toISOString());

//   //       // setGeneratedUrl(url);
//   //       dispatch(saveGeneratedUrl(url));

//   //     } else {
//   //       console.error('Failed to generate URL', response.status);
//   //       console.error('Error message:', response.data.message);
//   //     }
//   //   } catch (error) {
//   //     console.error('Error generating URL:', error.message);
//   //   }
//   // };

//   // useEffect(() => {

//   //   const storedUrl = localStorage.getItem('generatedUrl');

//   //   if (storedUrl) {
//   //     const expirationTime = localStorage.getItem('generatedUrlExpiration');

//   //     if (expirationTime && new Date(expirationTime) > new Date()) {
//   //       setGeneratedUrl(storedUrl);
//   //     } else {

//   //       localStorage.removeItem('generatedUrl');
//   //       localStorage.removeItem('generatedUrlExpiration');
//   //     }
//   //   }
//   // }, []);

//   //console.log("angi missing data check",file)
//   return (
//     <div>
//       <div className="card mb-2 ">
//         <div className="card-body">
//           <div className="flex flex-row d-flex justify-content-between align-items-center">
//             <div className="d-flex  " style={{ width: "500px" }}>
//               <div className="d-flex align-items-center mr-3 ">
//                 <span className="mx-2">
//                   <b className="badge badge-circle sm text-primary"></b>
//                 </span>
//               </div>
//               <div>
//                 <small>
//                   {file?.file?.name}
//                   {/* {moment(userProfile.bookingDate).format("llll")} */}
//                 </small>
//               </div>
//             </div>
//             <div
//               className="d-flex align-items-center"
//               style={{ width: "60px" }}
//             ></div>

//             {/* <div className="textCenter-Custom">
//                   <div>Hospital Name</div>
//                   <div className="text-muted">{userProfile.hospitalName}</div>
//                 </div> */}
//             {/* <div className="textCenter-Custom">
//               <div>Booking Date</div>
//               <div className="text-muted">
//                 {" "}
//                 {moment(userProfile.bookingDate).format("llll")}
//               </div>
//             </div> */}
//             {file.scanStatus.status == "NOT_STARTED" && (
//               <FontAwesomeIcon
//                 icon={faExclamationCircle}
//                 style={{ color: "#ffc107" }}
//                 className="mx-2"
//               />
//             )}
//             {file.scanStatus.status == "DONE" && (
//               <>
//               <FontAwesomeIcon
//                 id="scanStatusDone"
//                 icon={faCheck}
//                 style={{ color: "#28a745" }}
//                 className="mx-2"
//               />
//               <UncontrolledTooltip target="scanStatusDone">Timsheet scaned and ready for review</UncontrolledTooltip>
//               </>
//             )}
//             {file.scanStatus.status == "SCANNING" && (
//               <>
//                 <Spinner
//                     id="scanStatusProccessing"
//                     animation="border"
//                     style={{
//                       color: "#ffc107",
//                       height: "20px",
//                       width: "20px",
//                       marginRight: "5px",
//                     }}
//               />
//              <UncontrolledTooltip target="scanStatusProccessing">Timsheet is scanning</UncontrolledTooltip>
//              </>

//             )}

//             {file.scanStatus.status == "FAILED" && (
//               <>
//               <FontAwesomeIcon
//                 id="scanStatusFailed"
//                 icon={faTimes}
//                 style={{ color: "#dc3545" }}
//                 className="mx-2"
//               />
//                 <UncontrolledTooltip target="scanStatusFailed">Timsheet is scanned failed</UncontrolledTooltip>
//               </>
//             )}
//             {file.aIReviewStatus == "NOT_MATCHING" && (
//               <>
//                 <FontAwesomeIcon
//                   id="aiReviewStatusNotMatching"
//                   icon={faEye}
//                   style={{ color: "#dc3545" }}
//                   className="mx-2"
//                 />
//                 <UncontrolledTooltip target="aiReviewStatusNotMatching">Manual review required</UncontrolledTooltip>
//                 </>

//             )}
//             {file.aIReviewStatus == "MATCHING" && (
//               <>
//                <FontAwesomeIcon
//                 id="aiReviewStatusMatching"
//                 icon={faRobot}
//                 style={{ color: "#28a745" }}
//                 className="mx-2"
//                />
//                <UncontrolledTooltip target="aiReviewStatusMatching">Mexxi reviewed the timsheet data</UncontrolledTooltip>
//               </>

//             )}

//             {file?.scanStatus?.status == "DONE" && (

//               <div>
//                 <Button
//                   color="success"
//                   className="mr-2"
//                   onClick={() => toggleReview(file)}

//                 >
//                   Review
//                 </Button>
//               </div>
//             )}
//           </div>
//         </div>
//       </div>

//       <ModalComponent
//         show={calendarShow}
//         header="Log new call back"
//         closeModal={() => handleCalendarShow(false)}
//       >
//         <SmallCalendar />
//         <div className="mt-3">
//           {" "}
//           <Button color="success">Send</Button>{" "}
//           <Button color="secondary" onClick={() => handleCalendarShow(false)}>
//             Cancel
//           </Button>
//         </div>
//       </ModalComponent>
//       <ModalComponent
//         show={showTimeModal}
//         header="Upload Time Sheet"
//         size="lg"
//         closeModal={() => setShowTimeModal(false)}
//       >
//         <TimeSheetUpload
//           data={data}
//           closeModal={() => setShowTimeModal(false)}
//           getCandidates={refreshPage}
//         ></TimeSheetUpload>
//       </ModalComponent>
//     </div>
//   );
// };

// export default PendingTimesheetsforVerification;

import React, { useState, useEffect } from "react";
import { Button, Col, Spinner, UncontrolledTooltip } from "reactstrap";
import axios from "axios";
import moment from "moment";
import ModalComponent from "../../components/Modal/modalComponent";
import TimeSheet from "../../components/Modal/ModalBody/timeSheet";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import { HOSTMexxar } from "../../configs/api-config";
import TimeSheetUpload from "../../components/Modal/ModalBody/timeSheetUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faExclamationCircle,
  faRobot,
  faTimes,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { isMatch } from "lodash";
import { useDispatch } from "react-redux";
import {
  updateTimesheetAIReviewedStatus,
  updateTimesheetReviewedData,
  saveGeneratedUrl,
} from "../../redux/actions/timesheetBatchScanAction";

const PendingTimesheetsforVerification = (props) => {
  const { userProfile, refreshPage, toggleReview, id, file, toggleAIReview } =
    props;

  const {
    refNum,
    newRefNumber,

    selectedFile2,
  } = props;

  const { AIModalData, DBData } = file;
  const dispatch = useDispatch();

  const [calendarShow, setCalendarShow] = useState(false);
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [data, setData] = useState();
  const [urlRedux, setGeneratedUrlInRedux] = useState();

  const toggleShowTimeModal = (data) => {
    setData(data);
    setShowTimeModal(!showTimeModal);
  };
  // const setGeneratedUrlInRedux = (url) => {
  dispatch(saveGeneratedUrl(urlRedux));
  // console.log(urlRedux)

  useEffect(() => {
    dispatch(saveGeneratedUrl(urlRedux));
    // console.log(urlRedux)
  }, [urlRedux]);

  // };

  // console.log("selectedFile2  angi--->:", selectedFile2);

  const onSubmit = (data) => {
    // Generate a 4-digit random number
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    const randomNumber2 = Math.floor(1000 + Math.random() * 9000);
    const firstValue = Math.floor(20 + Math.random() * 8);
    const secondValue = Math.floor(firstValue + 3 + Math.random() * 5);

    let exportObject = {
      PaymentPeriod: "",
      ClientID: DBData?.clientId,
      MeritID: randomNumber,
      ExternalID: DBData?.candidatesId,
      MeritPlacementID: randomNumber2,
      ExternalPlacementID: "",
      WorkDescription: "Temp Staff",
      PayDescription: "Basic Pay Temp Staff",
      Quantity: AIModalData?.Totalhours,
      PayRate: firstValue,
      ChargeRate: secondValue,
      Reference: DBData?.referenceNo,
      Ref2: "",
      Startdate: DBData?.bookingDate,
      Enddate: "",
      Comments: "",
      EndUserClient: "",
      ComparatorHolidayDays: "",
      PaymentCurrencyCode: "GBP",
      InvoiceCurrencyCode: "",
      VatAdjust: "",
      Consultant: "user",
    };

    let timesheet = {
      locumName: DBData?.candidateName,
      locumId: DBData?.candidatesId,
      speciality: DBData?.speciality?.id,
      band: Number(AIModalData?.Band),
      hospitalName: DBData?.clientName,
      dateOfShift: DBData?.bookingDate,
      ward: DBData?.id,
      bookingRef: DBData?.referenceNo,
      startTime: AIModalData?.Start,
      endTime: AIModalData?.End,
      minsTakenForBreaks: AIModalData?.Breakmin,
      totalHours: AIModalData?.Totalhours,
      locumDate: DBData?.bookingDate,
      authoriseName: "",
      authorisePosition: "",
      authoriseDate: DBData?.bookingDate,
      bookingId: DBData?.id,
      hospitalReviewStatus: 0,
      paymentStatus: 0,
      reviewStatus: 4,
    };

    let form = new FormData();
    form.append("file", file.file);

    axios.post(HOSTMexxar + "timesheets/", timesheet).then((res) => {
      if (res.data.status == "success" && res.data.message == "created") {
        // ToastCommon(true, "success", addToast);

        uploadTimesheet(data, res, form);

        dispatch(updateTimesheetReviewedData(file.file.preview, exportObject));
      } else if (
        res.data.status == "success" &&
        res.data.message == "updated"
      ) {
        // ToastCommon(true, "update", addToast);
        uploadTimesheet(data, res, form);

        dispatch(updateTimesheetReviewedData(file.file.preview, exportObject));
        // uploadTimesheet(data, res, form);
      } else if (res.data.status == "failed") {
        // ToastCommon(true, "error", addToast);
      }
    });
  };

  const uploadTimesheet = (data, res, form) => {
    axios
      .put(
        HOSTMexxar +
          "timesheets/" +
          res.data.body[0].id +
          "/upload-timesheetDocument",
        form
      )
      .then((res) => {
        if (res.data.status == "success" && res.data.message == "fetched") {
          // ToastCommon(true, "timesheetUploaded", addToast);
        } else if (res.data.status == "failed") {
          // ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        // ToastCommon(true, "error", addToast);
      });
  };

  useEffect(() => {
    // Check if all fields in incommingAIdata match the corresponding fields in systemTimeSheetData

    if (file?.scanStatus?.status == "DONE" && file.AIModalData && file.DBData) {
      getBucketUrl();

      const unmatchedFields = [];

      const compareIgnoreCase = (value1, value2, fieldName) => {
        if (typeof value1 === "string" && typeof value2 === "string") {
          return value1.toLowerCase() === value2.toLowerCase();
        } else if (value1 === undefined || value2 === undefined) {
          return { field: fieldName, value: value1 || value2 };
        }
        return false;
      };

      if (!compareIgnoreCase(AIModalData.Ref, DBData.referenceNo, "Ref")) {
        unmatchedFields.push("Ref");
      }
      if (!compareIgnoreCase(AIModalData.Ward, DBData.ward?.name, "Ward")) {
        unmatchedFields.push("Ward");
      }
      if (
        !compareIgnoreCase(
          AIModalData.CandidateId,
          DBData.candidatesId?.toString(),
          "CandidateId"
        )
      ) {
        unmatchedFields.push("CandidateId");
      }
      if (
        !compareIgnoreCase(
          AIModalData.CandidateName,
          DBData.candidateName,
          "CandidateName"
        )
      ) {
        unmatchedFields.push("CandidateName");
      }
      if (
        !compareIgnoreCase(
          AIModalData.Speciality,
          DBData.speciality?.name,
          "Speciality"
        )
      ) {
        unmatchedFields.push("Speciality");
      }
      if (
        !compareIgnoreCase(
          moment(AIModalData.Date).format("YYYY-MM-DD"),
          DBData.bookingDate,
          "Date"
        )
      ) {
        unmatchedFields.push("Date");
      }
      if (
        !compareIgnoreCase(
          moment(AIModalData.End, "HH:mm").format("HH:mm"),
          moment(DBData.shiftTo).format("HH:mm"),
          "End"
        )
      ) {
        unmatchedFields.push("End");
      }
      if (
        !compareIgnoreCase(
          moment(AIModalData.Start, "HH:mm").format("HH:mm"),
          moment(DBData.shiftFrom).format("HH:mm"),
          "Start"
        )
      ) {
        unmatchedFields.push("Start");
      }
      if (
        !compareIgnoreCase(
          AIModalData.HospitalName,
          DBData.clientName,
          "HospitalName"
        )
      ) {
        unmatchedFields.push("HospitalName");
      }

      // console.log("Unmatched Fields:", unmatchedFields);
      // console.log("angi AIModalData?.Start", AIModalData?.Start);
      // console.log("angi DBData?.shiftFrom",moment(DBData?.shiftFrom).format('HH:mm'));
      // console.log("angi AIModalData?.CandidateName?",AIModalData?.CandidateName);

      const isAMatch =
        AIModalData?.Ref?.toLowerCase() ===
          DBData?.referenceNo?.toLowerCase() &&
        AIModalData?.Ward?.toLowerCase() ===
          DBData?.ward?.name?.toLowerCase() &&
        (AIModalData?.CandidateId?.toLowerCase() ===
          DBData?.candidatesId?.toString()?.toLowerCase() ||
          AIModalData?.CandidateName?.toLowerCase() ===
            DBData?.candidateName?.toLowerCase()) &&
        AIModalData?.Speciality?.toLowerCase() ===
          DBData?.speciality?.name?.toLowerCase() &&
        moment(AIModalData?.Date).format("YYYY-MM-DD") ===
          DBData?.bookingDate &&
        moment(AIModalData?.Start, "HH:mm").format("HH:mm") ===
          moment(DBData?.shiftFrom).format("HH:mm") &&
        moment(AIModalData?.End, "HH:mm").format("HH:mm") ===
          moment(DBData?.shiftTo).format("HH:mm") &&
        AIModalData?.HospitalName?.toLowerCase() ===
          DBData?.clientName?.toLowerCase();

      if (isAMatch) {
        dispatch(
          updateTimesheetAIReviewedStatus(file?.file?.preview, "MATCHING")
        );

        onSubmit();
      } else {
        dispatch(
          updateTimesheetAIReviewedStatus(file?.file?.preview, "NOT_MATCHING")
        );
      }
    }

    // Add more fields for comparison if needed
  }, [file.scanStatus.status, file.DBData]);

  const [generatedUrl, setGeneratedUrl] = useState("");
  const [bookingRef, setBookingRef] = useState(selectedFile2?.AIModalData?.Ref);

  // console.log("AngiselectedFile2:", selectedFile2);

  const getBucketUrl = async () => {
    console.log("Angi 1 URL generated successfully:");

    try {
      const originalFileName = selectedFile2.name;
      const fileExtension = originalFileName.split(".").pop();

      const fileName = bookingRef + "." + fileExtension;

      const response = await axios.get(
        "https://time.mexxar.com/timesheet/url",
        {
          params: {
            fileName: fileName,
            folderName: "mex_candy",
            linkDuration: "10000",
          },
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
          mode: "cors",
        }
      );

      if (response.status === 200) {
        const url = response.data.body.url;
        setGeneratedUrlInRedux(url);

        const expirationTime = new Date();
        expirationTime.setMinutes(expirationTime.getMinutes() + 0);

        localStorage.setItem("generatedUrl", url);
        localStorage.setItem(
          "generatedUrlExpiration",
          expirationTime.toISOString()
        );

        // setGeneratedUrl(url);
        dispatch(saveGeneratedUrl(url));
      } else {
        console.error("Failed to generate URL", response.status);
        console.error("Error message:", response.data.message);
      }
    } catch (error) {
      console.error("Error generating URL:", error.message);
    }
  };

  useEffect(() => {
    const storedUrl = localStorage.getItem("generatedUrl");

    if (storedUrl) {
      const expirationTime = localStorage.getItem("generatedUrlExpiration");

      if (expirationTime && new Date(expirationTime) > new Date()) {
        setGeneratedUrl(storedUrl);
      } else {
        localStorage.removeItem("generatedUrl");
        localStorage.removeItem("generatedUrlExpiration");
      }
    }
  }, []);

  console.log("angi 140-file-->", file?.file.name);
  return (
    <div>
      <div className="card mb-2 ">
        <div className="card-body">
          <div className="flex flex-row d-flex justify-content-between align-items-center">
            <div className="d-flex  " style={{ width: "500px" }}>
              <div className="d-flex align-items-center mr-3 ">
                <span className="mx-2">
                  <b className="badge badge-circle sm text-primary"></b>
                </span>
              </div>
              <div>
                <small>
                  {/* {file?.file?.name} */}
                  {file?.file?.name}
                  {/* {moment(userProfile.bookingDate).format("llll")} */}
                </small>
              </div>
            </div>
            <div
              className="d-flex align-items-center"
              style={{ width: "60px" }}
            ></div>

            {/* <div className="textCenter-Custom">
                  <div>Hospital Name</div>
                  <div className="text-muted">{userProfile.hospitalName}</div>
                </div> */}
            {/* <div className="textCenter-Custom">
              <div>Booking Date</div>
              <div className="text-muted">
                {" "}
                {moment(userProfile.bookingDate).format("llll")}
              </div>
            </div> */}
            {file.scanStatus.status == "NOT_STARTED" && (
              <FontAwesomeIcon
                icon={faExclamationCircle}
                style={{ color: "#ffc107" }}
                className="mx-2"
              />
            )}
            {file.scanStatus.status == "DONE" && (
              <>
                <FontAwesomeIcon
                  id="scanStatusDone"
                  icon={faCheck}
                  style={{ color: "#28a745" }}
                  className="mx-2"
                />
                <UncontrolledTooltip target="scanStatusDone">
                  Timsheet scaned and ready for review
                </UncontrolledTooltip>
              </>
            )}
            {file.scanStatus.status == "SCANNING" && (
              <>
                <Spinner
                  id="scanStatusProccessing"
                  animation="border"
                  style={{
                    color: "#ffc107",
                    height: "20px",
                    width: "20px",
                    marginRight: "5px",
                  }}
                />
                <UncontrolledTooltip target="scanStatusProccessing">
                  Timsheet is scanning
                </UncontrolledTooltip>
              </>
            )}

            {file.scanStatus.status == "FAILED" && (
              <>
                <FontAwesomeIcon
                  id="scanStatusFailed"
                  icon={faTimes}
                  style={{ color: "#dc3545" }}
                  className="mx-2"
                />
                <UncontrolledTooltip target="scanStatusFailed">
                  Timsheet is scanned failed
                </UncontrolledTooltip>
              </>
            )}
            {file.aIReviewStatus == "NOT_MATCHING" && (
              <>
                <FontAwesomeIcon
                  id="aiReviewStatusNotMatching"
                  icon={faEye}
                  style={{ color: "#dc3545" }}
                  className="mx-2"
                />
                <UncontrolledTooltip target="aiReviewStatusNotMatching">
                  Manual review required
                </UncontrolledTooltip>
              </>
            )}
            {file.aIReviewStatus == "MATCHING" && (
              <>
                <FontAwesomeIcon
                  id="aiReviewStatusMatching"
                  icon={faRobot}
                  style={{ color: "#28a745" }}
                  className="mx-2"
                />
                <UncontrolledTooltip target="aiReviewStatusMatching">
                  Mexxi reviewed the timsheet data
                </UncontrolledTooltip>
              </>
            )}

            {file?.scanStatus?.status == "DONE" && (
              <div>
                <Button
                  color="success"
                  className="mr-2"
                  onClick={() => toggleReview(file)}
                >
                  Review
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>

      <ModalComponent
        show={calendarShow}
        header="Log new call back"
        closeModal={() => handleCalendarShow(false)}
      >
        <SmallCalendar />
        <div className="mt-3">
          <Button color="success">Send</Button>{" "}
          <Button color="secondary" onClick={() => handleCalendarShow(false)}>
            Cancel
          </Button>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showTimeModal}
        header="Upload Time Sheet"
        size="lg"
        closeModal={() => setShowTimeModal(false)}
      >
        <TimeSheetUpload
          data={data}
          closeModal={() => setShowTimeModal(false)}
          getCandidates={refreshPage}
        ></TimeSheetUpload>
      </ModalComponent>
    </div>
  );
};

export default PendingTimesheetsforVerification;
