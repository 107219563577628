import React, { useState, useEffect } from "react";
import "./working-card-widget.style.css";
import { Badge, Card, CardBody, Button, Label } from "reactstrap";
import logo from "../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

import {
    faMars,
    faVenus,
    faPhone,
    faEnvelope,
    faCalendar,
    faPhoneSlash,
    faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import ModalComponent from "../../components/Modal/modalComponent";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import {
    HOSTMexxar,
    RESCHEDULE,
    HostMexxarURL,
} from "../../configs/api-config";
import {
    CandidateStage,
    GenderSwitch,
    ConversationResponse,
    CandidateActiveStatusBadge,
} from "../../util/common";
import {
    CandidateActiveStatus,
    CandidateStatus,
} from "../../util/common/candidateStatus";

import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { UncontrolledTooltip } from "reactstrap";
import AvatarMexxar from "../../util/common/avatarMexxar";
import SnapShotnavEmail from "../../components/Modal/ModalBody/snapShotnavEmail";

const BulkCardWidget = (props) => {
    const MexxarUrl = HostMexxarURL;
    const MexxarUrlHost = HOSTMexxar;

    const MexxarApiReschedule = RESCHEDULE;
    const { addToast } = useToasts();

    const {
        userProfile,
        auth,
        isSelected,
        onItemSelect,
        selectAll,
        contactView,
        totalElements
    } = props;
    const [calendarShow, setCalendarShow] = useState(false);
    const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);

    const [showEmailNavModal, setShowEmailNavModal] = useState(false);
    const [candidateUser, setCandidateUser] = useState();
    const [selectedCandidate, setSelectedCandidate] = useState({});
    const [data, setData] = useState();


    console.log("userProfile", userProfile)
    const toggleEmailModal = (data) => {
        setShowEmailNavModal(!showEmailNavModal);
        setData(data)
    };
    const [profilePicture, setProfilePicture] = useState();
    const MexxarHostAPI = HOSTMexxar;

    const resheduleCall = (data) => {
        let rescheduleDate = moment(data.date).add(data.time).toISOString();
        //  console.log("erschedule data and time",data)
        axios
            .post(MexxarApiReschedule, {
                callerId: auth.item.id,
                receiverId: userProfile.id,
                callDate: moment(new Date()).toISOString(),
                rescheduleDate: rescheduleDate,
            })
            .then((res) => {
                //  setCandidateId(res.data.body);
                ToastCommon(true, "success", addToast);
                setCalendarShow(!calendarShow);
            })
            .catch((err) => {
                //console.log("eror in getting response", err);
                // ToastCommon(true, "error", addToast);
            });
    };

    // function getBase64(file, cb) {
    //   let reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onload = function () {
    //     cb(reader.result);
    //   };
    //   reader.onerror = function (error) {
    //     console.log("Error: ", error);
    //   };
    // }

    // const toggleM = () => setShowEmailModal(!showEmailNavModal);

    // useEffect(() => {
    //   loadCandidateData();
    // }, []);

    return (
        <>
            <div
                className=" card d-flex flex-row main bg-light p-1 "
                style={{ marginBottom: "5px" }}
            >



                <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
                    <div className="d-flex ml-1 mb-1" id={`candidateID${userProfile.id}`}>

                        <div className="d-flex align-items-center mr-3 ">
                            <span className="mx-2">
                                <RoundIconButton
                                    buttonColor="gray"
                                    icon={faEnvelope}
                                    // click={() => toggleShowEmailModal(userProfile)}
                                    buttonSize="60px"
                                ></RoundIconButton>
                            </span>
                        </div>
                        <div>
                            <div>{userProfile.candidate?.firstName}&nbsp;{userProfile.candidate?.lastName}</div>
                            <small>
                                {/* Date :{" "} */}
                                {moment(userProfile.createdDateTime).format("llll")}
                            </small>
                        </div>
                        <div className=" d-flex ">


                        </div>
                        {/* <div className="mx-2 ">
                            <RoundIconButton
                                buttonColor="gray"
                                icon={faEnvelope}
                                // click={() => toggleShowEmailModal(userProfile)}
                                buttonSize="60px"
                            ></RoundIconButton>

                        </div>

                        {userProfile.candidate?.firstName}&nbsp;{userProfile.candidate?.lastName} */}
                        {/* <div className=" mx-2  d-flex align-items-center ">
              <FontAwesomeIcon
                color="#FF5603"
                icon={faCalendarAlt}
                onClick={() => handleCalendarShow(true)}
                className="fa-lg mb-2 btn-raised btn-wave "
              />
            </div> */}
                    </div>

                </div>

                {/* <div
                    className="d-flex align-items-center justify-content-center flex-column"
                    style={{ flex: 3 }}
                >
                    <div>
                        <Badge color={"warning"}>{userProfile.status}</Badge>
                    </div>

                    <div style={{ height: "3vh", flex: 1 }}></div>
                </div> */}

                <div
                    className="d-flex flex-column align-items-center "
                    style={{ flex: 3 }}
                >
                    <div style={{ height: "4vh" }}>
                        Status
                    </div>
                    <div style={{ height: "3vh", flex: 1 }}>
                        {userProfile.status === "SENT" ? (
                            <Badge color={"success"}>Sent</Badge>
                        ) : userProfile.status === "DELIVERED" ? (
                            <Badge color={"primary"}>Delivered</Badge>
                        ) : userProfile.status === "BOUNCED" ? (
                            <Badge color={"warning"}>Bounced</Badge>
                        ) : userProfile.status === "COMPLAINT" ? (
                            <Badge color={"danger"}>Complaint</Badge>
                        ) : null}
                    </div>
                    <div style={{ height: "3vh" }}></div>
                </div>
                <div
                    className="d-flex flex-column align-items-center "
                    style={{ flex: 3 }}
                >
                    <div style={{ height: "4vh" }}>
                        From Email
                    </div>
                    <div style={{ height: "3vh", flex: 1 }}>
                        {userProfile?.user?.email}
                    </div>
                    <div style={{ height: "3vh" }}></div>
                </div>
                <div
                    className="d-flex flex-column align-items-center "
                    style={{ flex: 3 }}
                >
                    <div style={{ height: "4vh" }}>
                        To Email
                    </div>
                    <div style={{ height: "3vh", flex: 1 }}>
                        {userProfile?.toEmail}
                    </div>
                    <div style={{ height: "3vh" }}></div>
                </div>
                <div>
                    <Button
                        id="E-Shot"
                        className="bg-success "
                        onClick={() => toggleEmailModal()}
                    >
                        Show Email
                    </Button>
                </div>


                {/* <div style={{ flex: 2 }}>
          <div style={{ height: "4vh", flex: 1 }}></div>
          <div style={{ height: "3vh", flex: 1 }}></div>
          <div style={{ height: "3vh", flex: 1 }}></div>
        </div> */}


                {/* <div
          className="d-flex align-items-center flex-column "
          style={{ flex: 3 }}
        >
          <div style={{ height: "4vh" }}>
            {contactView ? (
              <Label>County</Label>
            ) : (
              <Label>Profile Status</Label>
            )}
          </div>
          <div style={{ height: "3vh" }}>
            {contactView ? (
              <div className="d-flex flex-column align-items-center">
                <Label>{userProfile?.postalCode?.county}</Label>
                <Label>{userProfile?.postalCode?.code}</Label>
              </div>
            ) : (
              <Label>
                <Badge
                  style={{
                    backgroundColor: CandidateActiveStatusBadge(
                      userProfile.status
                    ).badgeColor,
                  }}
                >
                  {CandidateActiveStatusBadge(userProfile.status).badgeLable}
                </Badge>
              </Label>
            )}
          </div> */}



            </div>
            <div>
                {/* <Label className="text-left">
  Employee Names: 
  {userProfile.headhunters.map((headhunter, index) => (
    <span key={index}>{headhunter.usersName}</span>
  ))}
</Label> */}

                {/* </div> */}

            </div>
            <ModalComponent
                show={calendarShow}
                header="Reschedule a call"
                closeModal={() => setCalendarShow(false)}
            >
                <SmallCalendar
                    onCreate={(data) => resheduleCall(data)}
                    closeModal={() => setCalendarShow(false)}
                />
            </ModalComponent>

            {/* <ModalComponent
                show={showEmailModal}
                header="E mail and sms"
                closeModal={() => toggleM()}
            >
                <EmailNav
                    closeModal={() => toggleM()}
                    user={candidateUser}
                    incomingPage="FullCandidateList"
                />
            </ModalComponent> */}

            <ModalComponent
                show={showEmailNavModal}
                header="View Email"
                size="lg"
                closeModal={() => setShowEmailNavModal(false)}
            >
                <SnapShotnavEmail
                    data={data}
                    closeModal={() => setShowEmailNavModal(false)}
                    userProfile={userProfile}
                // getCandidates={refreshPage}
                ></SnapShotnavEmail>
            </ModalComponent>
        </>
    );
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
    };
}

export default connect(mapStateToProps)(BulkCardWidget);
