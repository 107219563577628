import React, { useState, useEffect } from "react";
import "./workspace.style.css";
import { connect } from "react-redux";
import { addFilteredData } from "../../../redux/actions/headhunterFilteredAction";
import {
  HOSTMexxar,
  HostMexxarWS,
  HostMexxarURL,
  EMPLOYEE_PERMISSIONS,
  Add_NEW_EMPLOYEE,
} from "../../../configs/api-config";
import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";

import axios from "axios";
import NoData from "../../errorPages/NoData";
import { Client as StompClient } from "@stomp/stompjs";
import NoConnection from "../../errorPages/NoConnection";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import useWorkspaceFetch from "../../../customHooks/useWorkspaceFetch";
import { HEADHUNTER_WORKSPACE_DATA } from "../../../configs/api-config";
import SnapShotNav from "../../../components/Modal/ModalBody/snapShotNav";
import { forEach, filter, without, split, isNull, toLower } from "lodash";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import SortFunctionAscending from "../../../components/sortFunction/sortByDateAscending";
import HeadhunterWorkingCardWidgetPerm from "../working-card-headhunter-widget";
import { useSpring, animated } from "react-spring";
import { WorkspaceListLoader } from "../../../components/ContentLoaders/SVGLoaders";
import useWorkspaceFetchPerm from "../../../customHooks/useWorkspaceFetchPerm";
import {
  specialitiesGetAction,
  rolesGetAction,
} from "../../../redux/actions/initialDataGetAction";
import Store from "../../../redux/store";
import { FunnelIcon } from "../../../assets/icons/svg";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((element) => {
      let item = element.headhunterWorkSpaceDto;
      let fullName =
        item.candidateFirstName + !isNull(item.candidateSecondName) &&
        item.candidateSecondName + item.candidateLastName;
      let firstLastName =
        item.candidateFirstName + " " + item.candidateLastName;

      let searchableString = `${fullName} ${firstLastName} ${item.candidateFirstName} ${item.candidateId}  ${item.candidateLastName} ${item.email} ${item.primaryPhone}`;
      let p = searchableString;
      return p.toLowerCase().includes(name.toLowerCase());
    });
  }
  let lengthofSearch = filtered.length;
  return { filtered, lengthofSearch };
};

const HeadhunterWorkspacePerm = (props) => {
  const { addFilteredData, updateTargets, employeeID, specialities, roles } =
    props;
  const MexxarApi = HEADHUNTER_WORKSPACE_DATA;

  let url = MexxarApi + "?employeeId=";

  // let urlPerm = HOSTMexxar + "workspace/permanent/headhunter?employeeId=";

  const [reset, setReset] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [hot, setHOT] = useState({ value: "", bool: false });
  const [cold, setCOLD] = useState({ value: "", bool: false });
  const [showSnapshotModal, setShowSnapshotModal] = useState(false);
  const [neutral, setNeutral] = useState({ value: "", bool: false });
  const [pending, setPENDING] = useState({ value: "", bool: false });
  const [notSent, setNOTSENT] = useState({ value: "", bool: false });
  const [expired, setEXPIRED] = useState({ value: "", bool: false });
  const [completed, setCOMPLETED] = useState({ value: "", bool: false });
  const [processing, setPROCESSING] = useState({ value: "", bool: false });
  const [selectedItemsForSnapshot, setSelectedItemsForSnapshot] = useState();
  const [searchStatus, setSearchStatus] = useState({
    status: false,
    data: null,
  });

  const [speciality, setSpeciality] = useState([]);
  const [role, setRole] = useState([]);
  const [band, setBand] = useState([]);
  const [selectedSpecialities, setSelectedSpecialities] = useState({
    id: 0,
    value: "",
    label: "",
  });
  const [selectedRole, setSelectedRole] = useState({
    id: 0,
    value: "",
    label: "",
  });
  const [selectedBand, setSelectedBand] = useState({
    id: 0,
    value: "",
    label: "",
  });

  let urlPerm =
    HOSTMexxar +
    "permanent/headhunter?band=" +
    selectedBand.id +
    "&speciality=" +
    selectedSpecialities.id +
    "&role=" +
    selectedRole.id +
    "&employeeId=";

  ///......filter settings options saving and retreving .......///
  const [remember, setRemember] = useState(true);

  //Perm Workspace GET hook

  const { rowsPerms, loadingPerm, errorPerm } = useWorkspaceFetchPerm(
    urlPerm,
    searchStatus,
    employeeID
  );

  //Temp Workspace GET hook
  const { rows, loading, error } = useWorkspaceFetch(
    url,
    searchStatus,
    employeeID
  );

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  const [initialRows, setInitialRows] = useState([]);
  // const [rows, setRows] = useState([]);

  const handleCheck = (e) => {
    if (e.target.checked) {
      let data = e.target.value;

      switch (data) {
        case "HOT":
          setHOT({ value: e.target.value, bool: true });
          break;
        case "COLD":
          setCOLD({ value: e.target.value, bool: true });
          break;
        case "NEUTRAL":
          setNeutral({ value: e.target.value, bool: true });
          break;
        case "PENDING":
          setPENDING({ value: e.target.value, bool: true });
          break;
        case "NOT_SENT":
          setNOTSENT({ value: e.target.value, bool: true });
          break;
        case "PROCESSING":
          setPROCESSING({ value: e.target.value, bool: true });
          break;
        case "EXPIRED":
          setEXPIRED({ value: e.target.value, bool: true });
          break;
        case "COMPLETED":
          setCOMPLETED({ value: e.target.value, bool: true });
          break;
        case "REMEMBER":
          setRemember(e.target.checked);
          break;
      }
    } else {
      let data = e.target.value;

      switch (data) {
        case "HOT":
          setHOT({ value: "", bool: false });
          break;
        case "COLD":
          setCOLD({ value: "", bool: false });
          break;
        case "NEUTRAL":
          setNeutral({ value: "", bool: false });
          break;
        case "PENDING":
          setPENDING({ value: "", bool: false });
          break;
        case "NOT_SENT":
          setNOTSENT({ value: "", bool: false });
          break;
        case "PROCESSING":
          setPROCESSING({ value: "", bool: false });
          break;
        case "EXPIRED":
          setEXPIRED({ value: "", bool: false });
          break;
        case "COMPLETED":
          setCOMPLETED({ value: "", bool: false });
          break;
        case "REMEMBER":
          setRemember(false);
          break;
      }
    }
  };

  const handleSearch = () => {
    let option1 = [hot.value, cold.value, neutral.value];
    let option2 = [
      pending.value,
      notSent.value,
      processing.value,
      expired.value,
      completed.value,
    ];

    let x, y;
    if (hot.bool == true || cold.bool == true || neutral.bool == true) {
      x = 1;
    } else {
      x = 0;
    }

    if (
      pending.bool == true ||
      notSent.bool == true ||
      processing.bool == true ||
      expired.bool == true ||
      completed.bool == true
    ) {
      y = 1;
    } else {
      y = 0;
    }

    let callResponseFiltered = [];
    forEach(option1, function (response) {
      let A = filter(rowsPerms, (row) => {
        return (
          response === row.headhunterWorkSpaceDto.candidateCallResponseStatus
        );
      });

      callResponseFiltered.push(...A);
    });

    let ApplicationStatusFiltered = [];
    forEach(option2, function (response) {
      let A = filter(rowsPerms, (row) => {
        return (
          response === row.headhunterWorkSpaceDto.candidateApplicationStatus
        );
      });

      ApplicationStatusFiltered.push(...A);
    });

    if (x > 0 && y > 0) {
      let ApplicationStatusFiltered = [];
      forEach(option2, function (response) {
        let A = filter(callResponseFiltered, (row) => {
          return (
            response === row.headhunterWorkSpaceDto.candidateApplicationStatus
          );
        });

        ApplicationStatusFiltered.push(...A);
      });
      setSearchStatus({ status: true, data: ApplicationStatusFiltered });

      // setRows(ApplicationStatusFiltered);
    } else if (x > 0 && y == 0) {
      setSearchStatus({ status: true, data: callResponseFiltered });

      // setRows(callResponseFiltered);
    } else if (x == 0 && y > 0) {
      setSearchStatus({ status: true, data: ApplicationStatusFiltered });

      // setRows(ApplicationStatusFiltered);
    } else if (x == 0 && y == 0) {
      setSearchStatus({ status: false, data: null });
      // setRows(initialRows);
    }
  };

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // const getAllRows = () => {
  //   axios
  //     .get(MexxarApi + "?employeeId=" + employeeID)
  //     .then((res) => {
  //       setRows(res.data.body);
  //       setInitialRows(res.data.body);

  //       let ids_Workspace = [];
  //       forEach(res.data.body, function (item) {
  //         ids_Workspace.push({
  //           candidateId: item.candidateId,
  //           primaryPhone: item.primaryPhone,
  //         });
  //       });
  //       addFilteredData(ids_Workspace);
  //     })
  //     .catch((err) => {
  //       //console.log(err)
  //       //ToastCommon(true, "error", addToast);
  //     });
  // };

  const resetFilters = () => {
    setReset(!reset);
    setHOT({ value: "", bool: false });
    setCOLD({ value: "", bool: false });
    setNeutral({ value: "", bool: false });
    setPENDING({ value: "", bool: false });
    setNOTSENT({ value: "", bool: false });
    setEXPIRED({ value: "", bool: false });
    setCOMPLETED({ value: "", bool: false });
    setPROCESSING({ value: "", bool: false });
    setSearchStatus({ status: false, data: null });

    setSelectedSpecialities({ id: 0, value: "", label: "" });
    setSelectedRole({ id: 0, value: "", label: "" });
    setSelectedBand({ id: 0, value: "", label: "" });

    //  setRows(initialRows);
  };

  const refreshWorkflow = (data) => {
    if (data) {
      setReset(true);
      // getAllRows();
    }
  };

  const toggleSnapshotModal = () => {
    // let selectedCandidates = filter(rows, (item) => {
    //   return item.candidateId === "SPECIAL";
    // });
    let tempArray = [];

    if (!selectAll) {
      for (let i = 0; i < rowsPerms.length; i++) {
        for (let x = 0; x < selectedItems.length; x++) {
          if (selectedItems[x] == rowsPerms[i].candidateId) {
            tempArray.push(rowsPerms[i]);
          }
        }
      }
    } else if (selectAll) {
      for (let i = 0; i < rowsPerms.length; i++) {
        tempArray.push(rowsPerms[i]);
      }
    }

    setSelectedItemsForSnapshot(tempArray);
    setShowSnapshotModal(!showSnapshotModal);
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rowsPerms.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
      return false;
    } else {
      setSelectedItems([...selectedItems, rowId]);
      return true;
    }
  };

  useDidMountEffect(() => {
    let ids_Workspace = [];
    forEach(rowsPerms, function (item) {
      ids_Workspace.push({
        candidateId: item.candidateId,
        primaryPhone: item.primaryPhone,
      });
    });
    addFilteredData(ids_Workspace);
  }, [rowsPerms]);

  useDidMountEffect(() => {
    handleSearch();
    return () => {
      setReset(!reset);
    };
  }, [reset]);

  /****************websocket***********/
  useEffect(() => {
    //    getAllRows();
    updateTargets();

    let client = new StompClient();
    client.configure({
      brokerURL: HostMexxarWS + "wsc",
      onConnect: () => {
        //console.log("onConnect");
        client.subscribe(
          `/headhunter/${JSON.parse(localStorage.user).id}`,
          () => {
            setReset(true);

            //getAllRows();
          }
        );
      },
    });
    client.activate();
  }, []);

  // useDidMountEffect(() => {
  //   getAllRows();
  // }, [employeeID]);

  const rearrangeSpecialities = () => {
    let specialitiesTemp = [];
    specialities.forEach((item) => {
      specialitiesTemp.push({
        value: item.name,
        label: item.name + `${item?.type=="DATABASE" ? "-DB": ""}`,
        id: item.id,
      });
    });
    setSpeciality(specialitiesTemp);
  };

  const rearrangeRoles = () => {
    let rolesTemp = [];
    roles.forEach((item) => {
      rolesTemp.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    setRole(rolesTemp);
  };

  const rearrangeBands = (data) => {
    let bandsTemp = [];
    data.forEach((item) => {
      bandsTemp.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    setBand(bandsTemp);
  };

  useEffect(() => {
    if (specialities) {
      rearrangeSpecialities();
    }
    return () => {};
  }, [specialities]);

  useEffect(() => {
    if (roles) {
      rearrangeRoles();
    }
    return () => {};
  }, [roles]);

  const getBands = () => {
    axios.get(HOSTMexxar + "candidate-bands").then((bands) => {
      rearrangeBands(bands.data.body);
    });
  };

  useEffect(() => {
    Store.dispatch(specialitiesGetAction());
    Store.dispatch(rolesGetAction());
    getBands();

    return () => {};
  }, []);

  return (
    <>
      <div className="form-inline ">
        {/* <label className="ui-check ml-1">
          <Input
            type="checkbox"
            name="id"
            checked={selectAll}
            onChange={() => checkAll()}
          />
          <i></i>
        </label>
        <Label className="text-color text-sm" id="selectAll">
          Select All
        </Label>
        <UncontrolledTooltip placement="right" target="selectAll">
          Mailshot
        </UncontrolledTooltip>

        {selectedItems.length > 0 ? (
          <div className="mb-2">
            <Button
              id="mailshot"
              className="primary mx-3"
              onClick={() => toggleSnapshotModal()}
            >
              Mailshot
            </Button>
            <UncontrolledTooltip placement="right" target="mailshot">
              Send bulk emails to candidates.
            </UncontrolledTooltip>
          </div>
        ) : null} */}
        <FontAwesomeIcon icon={faUsers} className={"ml-2 mr-3  "} />
        {/* <Label className={"mr-3"}>{initialRows.length} Candidates</Label> */}
        <Label className={"mr-3"}>
          {rowsPerms && rowsPerms.length}{" "}
          {rowsPerms && rowsPerms.length == 1 ? "Candidate" : "Candidates"}
        </Label>

        <Label className={"mr-3"}></Label>

        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
        <UncontrolledTooltip target="searchBar">
          Search candidates by Name/Id/Email/Phone No.
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label>Call Response</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="HOT"
                      checked={hot.bool}
                      onChange={handleCheck}
                    />
                    <Badge color="danger">Hot</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="NEUTRAL"
                      checked={neutral.bool}
                      onChange={handleCheck}
                    />
                    <Badge color="secondary">Neutral</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="COLD"
                      checked={cold.bool}
                      onChange={handleCheck}
                    />
                    <Badge color="primary">Cold</Badge>
                  </Label>
                </FormGroup>
              </Col>
              <Col>
                <p>Speciality </p>

                <Select
                  // isMulti
                  value={selectedSpecialities}
                  name="candidateSpecialityId"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => setSelectedSpecialities(data)}
                  options={speciality}
                />
              </Col>
              <Col>
                <p>Role </p>

                <Select
                  // isMulti
                  value={selectedRole}
                  name="candidateSpecialityId"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => setSelectedRole(data)}
                  options={role}
                />
              </Col>
              <Col>
                <p>Band </p>

                <Select
                  // isMulti
                  value={selectedBand}
                  name="candidateSpecialityId"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => setSelectedBand(data)}
                  options={band}
                />
              </Col>
              <Col>
                <div className="d-flex flex-column align-items-end">
                  <div>
                    <Button
                      color="success m-1 btn-sm"
                      style={{ width: "90px" }}
                      onClick={handleSearch}
                    >
                      Search
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="danger m-1 btn-sm"
                      style={{ width: "90px" }}
                      onClick={() => {
                        resetFilters();
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                  {/* <div className="mr-2">
                    <small>
                      <Input
                        type="checkbox"
                        value="REMEMBER"
                        checked={remember}
                        onChange={handleCheck}
                      ></Input>
                      Remember
                    </small>
                  </div> */}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>

      {loadingPerm && !errorPerm && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      {!loadingPerm && errorPerm && (
        <div>
          <NoConnection error={errorPerm}></NoConnection>
        </div>
      )}

      
      

      {rowsPerms &&
        !loadingPerm &&
        !errorPerm &&
        (filterByName(searchQuery, rowsPerms).lengthofSearch > 0 ? (
          filterByName(
            searchQuery,
            rowsPerms.sort(SortFunctionAscending)
          ).filtered.map((row, i) => {
            return (
              <animated.div key={i} style={{ zIndex: 100 - i }}>
                <div key={i}>
                  <HeadhunterWorkingCardWidgetPerm
                    // key={i}
                    rowId={i}
                    userProfile={row.headhunterWorkSpaceDto}
                    docStatus={row.candidatePermDocStatuses}
                    workflowStatusUpdated={(data) => refreshWorkflow(data)}
                    isSelected={(id) => isSelected(id)}
                    onItemSelect={(id) => onItemSelect(id)}
                    selectAll={selectAll}
                  ></HeadhunterWorkingCardWidgetPerm>
                </div>
              </animated.div>
            );
          })
        ) : (
          <NoSearchResults />
        ))}

      {/* {loading && !error && (
        <div style={{ width: "100%", minWidth: "800px" }}>
          <WorkspaceListLoader />
        </div>
      )} */}

      {/* {!rows.length && !loading && <NoData />} */}

      {showSnapshotModal ? (
        <ModalComponent
          show={showSnapshotModal}
          header="Mailshot"
          closeModal={() => setShowSnapshotModal(false)}
        >
          <SnapShotNav
            closeModal={() => setShowSnapshotModal(false)}
            user={selectedItemsForSnapshot}
          />
        </ModalComponent>
      ) : null}
    </>
  );
};

function mapStateToProps(state) {
  return {
    headhunter: state.headhunter,
    heahunterFiltered: state.heahunterFiltered,
    auth: state.auth,
    specialities: state.initialDataGetReducer.specialities,
    roles: state.initialDataGetReducer.roles,
  };
}

export default connect(mapStateToProps, {
  addFilteredData,
})(HeadhunterWorkspacePerm);
