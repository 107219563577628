import React, { useState } from "react";
import axios from "axios";
import Previews from "../audit/docView/Previews";
import TimesheetData from "./TimesheetData";
import {
  Row,
  Col,
  FormGroup,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { HOSTMexxar } from "../../configs/api-config";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { forEach, isNull } from "lodash";
import ToastCommon from "../../components/toastCommon";
import PayrollSummary from "./PayrollSummary";
import moment from "moment";

function TimeSheetScan() {
  const [expand, setExpand] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [processLoading, setProcessLoading] = useState(null);
  const [incommingAIdata, setIncommingAIdata] = useState(null);
  const [systemTimeSheetData, setSystemTimeSheetData] = useState(null);
  const [timeSheetFalse, setTimeSheetFalse] = useState(null);
  const [showSummary, setShowSummary] = useState(false);
  const [payrollData, setPayrollData] = useState(null);
  const [payrollError, setPayrollError] = useState(null);
  const [documentUploaded, setDocumentUploaded] = useState(false);

  const handleChangeStatus = (files) => {
    setSelectedFile(files[0]);
  };

  const getTimesheetFromSystem = (data) => {
    if (data) {
      // setTimeSheetFalse(false)
      axios
        .get(HOSTMexxar + "bookings/reference/" + data)
        .then((res) => {
          setSystemTimeSheetData(res.data.body);
          //if the reference number is not excisting show alert
          if (res.data.message == "Not Existing") {
            setTimeSheetFalse(true);
          } else if (res.data.message == "fetched") {
            setTimeSheetFalse(false);
          }
        })
        .catch((e) => {
          // console.log(e);
        });
    }

    // ToastCommon();
  };

  //AI extract reference no from timesheet data
  const refNoSet = (data) => {
    let tempArray = [];
    tempArray.push([data]);

    forEach(data, function (value, key) {
      const regExpStr = "d[0-9]Ref";
      const result = new RegExp(regExpStr).test(key.toString());

      if (result) {
        if (value.confidence) {
          //getTimesheetFromSystem("182937463748958");

          getTimesheetFromSystem(value.value);
        }
      }
    });
  };

  // set AI data
  const prepareAIData = (data) => {
    let tempArray = [];
    tempArray.push([data]);

    let arrangeData = {};

    forEach(data, function (value, key) {
      let temp = [
        "Breakmin",
        "Date",
        "End",
        "Ref",
        "Start",
        "Totalhours",
        "Ward",
      ];

      let tempDateArray = [];

      forEach(temp, function (element) {
        const regExpStr = "d[0-9]" + element;
        const result = new RegExp(regExpStr).test(key.toString());

        if (result) {
          if (value.confidence) {
            // console.log("element", element);
            // console.log("key", key);
            // console.log("value------------>", value.value);
            // console.log("arrangeData[element]--->", arrangeData[element]);
            // console.log("value.value--->", value.value);

            // arrangeData[element] = value.value;
            if (element == "Start" || element == "End") {
              let condition = moment(value.value, "HH:mm", true).isValid();

              if (condition) {
                arrangeData[element] = value.value;
              }
            } else if (element == "Date") {
              let date = moment(value.value);
              if (date.isValid()) {
                arrangeData[element] = value.value;
              }
            } else {
              arrangeData[element] = value.value;
            }
          }
        }
      });
    });

    arrangeData["Band"] = data.band.value;
    arrangeData["HospitalName"] = data.hospitalName.value;
    arrangeData["CandidateId"] = data.locumId.value;
    arrangeData["CandidateName"] = data.locumName.value;
    arrangeData["Speciality"] = data.speciality.value;

    setIncommingAIdata(arrangeData);
  };

  const processTimeSheet = () => {
    setProcessLoading(true);

    const fd = new FormData();
    fd.append("file", selectedFile);

    axios({
      method: "post",
      url: "https://time.mexxar.com/uploadtimesheet/",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
      // withCredentials: true,
      data: fd,
      mode: "cors",
    })
      .then((res) => {
        if (res.data.status == "success") {
          refNoSet(res.data.body);
          prepareAIData(res.data.body);
        }

        setProcessLoading(false);
      })
      .catch((e) => {
        // console.log(e);
        setProcessLoading(false);
      });
  };

  useDidMountEffect(() => {
    setSystemTimeSheetData(null);
    setIncommingAIdata(null);
  }, [selectedFile, showSummary]);

  useDidMountEffect(() => {
    if (isNull(incommingAIdata) && isNull(systemTimeSheetData)) {
      setSelectedFile(null);
      setDocumentUploaded(!documentUploaded);
    }
  }, [showSummary]);

  return (
    <div
      className="  m-3 p-3 "
      // className="d-flex p-2 m-2 align-items-between justify-content-center "
      style={{ width: "100%", overflowX: "hidden" }}
    >
      <div className="row  justify-content-around ">
        <div
          className="  navbar-expand-lg "
          style={{ position: "fixed", top: "50px", left: "50px", zIndex: 999 }}
        >
          <Link
            to={{
              pathname: "/dashboard/payroll",
            }}
          >
            <a className="btn btn-sm btn-icon btn-rounded btn-raised  bg-dark-overlay ">
              <FontAwesomeIcon
                className="feather feather-more-vertical text-fade"
                icon={faChevronLeft}
              />
            </a>
          </Link>
        </div>

        <div class="col-sm-12 col-md-6 d-flex align-items-between justify-content-center h-80">
          <Previews
            expandStatus={expand}
            fileUploaded={(data) => setFileUploaded(data)}
            onUpload={(data) => handleChangeStatus(data)}
            documentUploaded={documentUploaded}
            showUploadSection={!showSummary && !payrollData}
          />
        </div>
        <div class="col-sm-12 col-md-6">
          {!showSummary && (
            <TimesheetData
              selectedFile={selectedFile}
              systemTimeSheetData={systemTimeSheetData}
              timeSheetFalse={timeSheetFalse}
              incommingAIdata={incommingAIdata}
              processTimeSheet={processTimeSheet}
              processLoading={processLoading}
              setShowSummary={(data) => setShowSummary(data)}
              selectedCandidates={(data) => setSelectedCandidate(data)}
              retry={(data) => getTimesheetFromSystem(data)}
              setPayrollData={(data) => {
                setPayrollData(data);
              }}
              setPayrollError={(data) => {
                setPayrollError(data);
              }}
            />
          )}
          {showSummary && payrollData && (
            <PayrollSummary
              summary={payrollData}
              setShowSummary={(data) => {
                setShowSummary(data);
                setPayrollData(null);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default TimeSheetScan;
