import React from "react";
import { useForm } from "react-hook-form";
import { Button, Input, Form, FormGroup, Col } from "reactstrap";

export default function RejectReasonInsideReview(props) {
  const { type, body, closeModal } = props;
  const onSubmit = (data) => {
    //TODO: Doo your stuff here
  };

  return (
    <div className="p-1">
      <Form>
        <FormGroup row>
          <Col sm={12}>
            <Input
              disabled={true}
              type="textarea"
              name="text"
              defaultValue={body}
              id="exampleText"
            />
          </Col>
        </FormGroup>

        <div className="mt-4">
          <Col sm={{ size: 8, offset: 8 }}>
            <Button color="secondary" onClick={closeModal}>
              Close
            </Button>
          </Col>
        </div>
      </Form>
    </div>
  );
}
