


import React, { useState, useEffect } from "react";
import axios from "axios";
import filter from "lodash/filter";
import { Button } from "reactstrap";
import { CVSOURCE } from "../../../configs/api-config";
import { useToasts } from "react-toast-notifications";
import loading from "../../../../src/images/puff.svg";
import ToastCommon from "../../../components/toastCommon";
import Delete from "../../../components/Modal/ModalBody/delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import NewOneField from "../../../components/Modal/ModalBody/Admin/newOneField";
import BankHolidayField from "../../../components/Modal/ModalBody/Admin/bankHolidayFields";
import NoData from "../../errorPages/NoData";
import moment from "moment";

const BankHoliday = () => {
  const MexxarApi = CVSOURCE;

  const { addToast } = useToasts();

  const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [rowNameData, setRowNameData] = useState([]);
  const [rowNameDate, setRowNameDate] = useState([]);
  const [rowNameDay, setRowNameDay] = useState([]);
  const [showAddRow, setShowAddRow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [loading1, setLoading] = useState(true); 

  const getAllRows = () => {
    axios
      .get("https://permapi.mexxar.com/holiday")
      .then((res) => {
        setRows(res.data.body);
        setLoading(false);
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
        setLoading(false);

      });
  };

  // const addRow = (data) => {
  //   // let duplicate_items = filter(rows, (item) => {
  //   //   return data.rowName == item.name,data.rowDate==item.date;
  //   // });

  //   // if (duplicate_items.length > 0) {
  //   //   ToastCommon(true, "duplicate", addToast);

  //   // } else {
  //     axios
  //       .post("https://permapi.mexxar.com/holiday", {
  //         name: data.rowName,
  //         date: data.date
  //       })
  //       .then((res) => {
  //         if (res.data.status == "success") {
  //           getAllRows();
  //           setShowAddRow(!showAddRow);
  //           ToastCommon(true, "success", addToast);
  //          } else {
  //           ToastCommon(true, "duplicate", addToast);
  //         }
  //       })
  //       .catch((err) => {
  //         //console.log(err)
  //         ToastCommon(true, "error", addToast);
  //       });
  //   // }
  // };

  const addRow = (data) => {
    let duplicate_items = filter(rows, (item) => {
      return data.rowName === item.name && data.rowDate === item.date;
    });

    if (duplicate_items.length > 0) {
      ToastCommon(true, "duplicate", addToast);
    } else {
      axios
        .post("https://permapi.mexxar.com/holiday", {
          name: data.rowName,
          date: data.rowDate,
          // day: data.rowDay,
        })
        .then((res) => {
          if (res.data.status == "Success") {
            getAllRows();
            setShowAddRow(!showAddRow);
            ToastCommon(true, "success", addToast);
          } else {
            ToastCommon(true, "duplicate", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    }
  };



  const deleteRow = () => {
    axios
      .delete("https://permapi.mexxar.com//holiday/" + rowID)
      .then((res) => {
        if (res.data.status === "Success") {
          ToastCommon(true, "delete", addToast);
          getAllRows();
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const updateRow = (data) => {
    let duplicate_items = filter(rows, (item) => {
      return data.rowName === item.name && data.rowDate === item.date;
    });

    if (duplicate_items.length > 0) {
      ToastCommon(true, "duplicate", addToast);
    } else {
      axios
        .put("https://permapi.mexxar.com/holiday/" + rowID, {
          // id: rowID,
          name: data.rowName,
          date: data.rowDate,
          day: data.rowDay,
        })
        .then((res) => {
          if (res.data.status == "Success") {
            getAllRows();
            setShowUpdateRow(!showUpdateRow);
            ToastCommon(true, "success", addToast);

          } else {
            ToastCommon(true, "duplicate", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);

        });
    }
  };

  const deleteAllRows = () => {
    selectedItems.forEach((item) => {
      axios
        .delete("https://permapi.mexxar.com/holiday/" + item)
        .then((res) => {
          getAllRows();
          setSelectedItems([]);
          setSelectAll(false);
        })
        .catch((err) => {
          //console.log(err)
        });
    });
    ToastCommon(true, "delete", addToast);
  };
  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
    } else {
      setSelectedItems([...selectedItems, rowId]);
    }
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  useEffect(() => {
    getAllRows();
  }, []);

  return (
    <DashboardLayout title="Bank Holidays" subTitle="Manage Bank Holidays " target="">
      <div className="d-flex justify-content-between p-2 ml-3">
        <div>
          {selectedItems.length > 0 ? (
            <Button
              color="danger"
              size="sm"
              onClick={() => {
                setShowDeleteAll(true);
              }}
            >
              Delete
            </Button>
          ) : null}
        </div>
        <div>
          {" "}
          <Button
            color="primary"
            size="sm"
            onClick={() => {
              setShowAddRow(true);
            }}
          >
            Add Holiday
          </Button>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">

          {loading1 === null && (
            <div className="d-flex justify-content-center align-items-center">
              {/* Loading animation */}
              <div>
                <img src={loading} style={{ height: 100 }} alt="Loading" />
              </div>
            </div>
          )}

          {!loading1 && rows.length === 0 && <NoData />}
          {!loading1 && rows.length > 0 && (
            <div>
              <table
                id="datatable"
                className="table table-theme table-row v-middle dataTable no-footer"
                role="grid"
                ariaDescribedby="datatable_info"
              >
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      ariaSort="ascending"
                      ariaLabel="ID: activate to sort column descending"
                      style={{ width: "26px" }}
                    >
                      <label className="ui-check m-0">
                        <input
                          type="checkbox"
                          name="id"
                          checked={selectAll}
                          onChange={() => checkAll()}
                        />{" "}
                        <i></i>
                      </label>
                    </th>
                    <th
                      className="sorting_asc"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      ariaSort="ascending"
                      ariaLabel="ID: activate to sort column descending"
                      style={{ width: "26px" }}
                    >
                      <span className="text-muted">ID</span>
                    </th>
                    <th
                      className="sorting_disabled"
                      tabIndex="0"
                      aria-controls="datatable"
                      rowSpan="1"
                      colSpan="1"
                      ariaLabel="Owner: activate to sort column ascending"
                    >
                      <span className="text-muted">Bank Holiday</span>
                    </th>
                    {/* <th
                      className="sorting_disabled d-flex justify-content-end mr-3"
                      rowSpan="1"
                      colSpan="1"
                      ariaLabel="Tasks"
                    >
                      <span className="text-muted d-none d-sm-block mr-3">
                       Date
                      </span>
                    </th>

                    <th
                      className="sorting_disabled d-flex justify-content-end mr-3"
                      rowSpan="1"
                      colSpan="1"
                      ariaLabel="Tasks"
                    >
                      <span className="text-muted d-none d-sm-block mr-3">
                        Actions
                      </span>
                    </th> */}
                  </tr>
                </thead>
                <tbody>
                  {rows?.map((row, i) => {
                    return (
                      <tr className="odd" key={i} role="row">
                        <td
                          style={{ minWidth: "30px", textAlign: "center" }}
                          className="sorting_1"
                        >
                          <label className="ui-check m-0">
                            <input
                              type="checkbox"
                              name="id"
                              checked={isSelected(row?.id)}
                              onChange={() => onItemSelect(row?.id)}
                            />{" "}
                            <i></i>
                          </label>
                        </td>
                        <td
                          style={{ minWidth: "30px", textAlign: "center" }}
                          className="sorting_1"
                        >
                          <small className="text-muted">{i + 1}</small>
                        </td>

                        <td className="">{row?.name}</td>
                        <td className="">{moment(row?.date).format("ddd MMM DD YYYY")}</td>
                        {/* <td className=""></td> */}

                        <td className="d-flex justify-content-end">
                          <FontAwesomeIcon
                            // color="#448bff"
                            icon={faEdit}
                            className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3"
                            onClick={() => {
                              setShowUpdateRow(true);
                              setRowID(row?.id);
                              setRowNameData(row?.name);
                              setRowNameDate(moment(row?.date));
                              setRowNameDay(row?.day);
                            }}
                            size="1x"
                          />
                          <FontAwesomeIcon
                            // color="#FF5603"
                            icon={faTrashAlt}
                            onClick={() => {
                              setShowDeleteRow(true);
                              setRowID(row?.id);
                            }}
                            className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave "
                            size="1x"
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}


        </div>
      </div>

      <ModalComponent
        show={showAddRow}
        header="Add Bank Holiday"
        closeModal={() => setShowAddRow(false)}
      >
        <BankHolidayField
          mode="Add"
          modalType="Description"
          modalType1="Date"
          modalType2="Day"
          closeModal={() => setShowAddRow(false)}
          onCreate={(data) => addRow(data)}
        />
      </ModalComponent>
      
      <ModalComponent
        show={showUpdateRow}
        header="Update Bank Holiday "
        closeModal={() => setShowUpdateRow(false)}
      >
        <BankHolidayField
          mode="Update"
          modalType="Description"
          modalType1="Date"
          modalType2="Day"
          fieldData={rowNameData}
          rowNameData={rowNameDate}
          rowNameDay={rowNameDay}
          closeModal={() => setShowUpdateRow(false)}
          onCreate={(data) => updateRow(data)}
        />
      </ModalComponent>
      {/* <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteRow();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(false)}
      /> */}
      <Delete
        show={showDeleteAll}
        isConfirm={() => {
          deleteAllRows();
          setShowDeleteAll(!showDeleteAll);
        }}
        closeModal={() => setShowDeleteAll(false)}
      />
    </DashboardLayout>
  );
};

export default BankHoliday;