import { useEffect, useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";

export default function useBookingList(
  url,
  filterCustom,
  query,
  pageNumber,
  searchAdvanced
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [rows, setRows] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  // const [checkFilterCustom, setCheckFilterCustom] = useState(null);

  useEffect(() => {
    setRows([]);
  }, [query]);

  // useEffect(() => {
  //   setCheckFilterCustom(filterCustom);
  // }, [filterCustom]);

  useDidMountEffect(() => {
    setRows([]);
    console.log("working");
  }, [searchAdvanced]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      url: url,
      params: { page: pageNumber, offset: 20, q: query },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        setTotalElements(res.data.body.totalElements);
        setRows((prevBooks) => {
          return [...new Set([...prevBooks, ...res.data.body.content])];
        });
        setHasMore(res.data.body.content.length > 0);
        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setError(true);
      });
    return () => cancel();
  }, [searchAdvanced, query, pageNumber]);

  return { loading, error, rows, hasMore, totalElements };
}
