import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { connect } from "react-redux";
import { Line } from "react-chartjs-2";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../components/toastCommon";
import { Label, ButtonGroup, Button } from "reactstrap";
import { DASHBOARD_PERFORMANCE } from "../../configs/api-config";
import { forEach, filter, split, isEqual, uniqWith } from "lodash";
import useDidMountEffect from "../../../src/components/Modal/ModalBody/intialRunUseEffect";

var currentYear = moment().year();
var currentMonth = moment().month();
var currentDate = moment().dayOfYear();

const options = {
  scales: {
    yAxes: [
      {
        type: "linear",
        display: true,
        position: "left",
        id: "y-axis-1",
      },
      // {
      //   type: "linear",
      //   display: true,
      //   position: "right",
      //   id: "y-axis-2",
      //   gridLines: {
      //     drawOnArea: false,
      //   },
      // },
    ],
  },
};
const MultiChart = (props) => {
  const { auth } = props;
  const yearData = [
    String(Number(currentYear) - 9),
    String(Number(currentYear) - 8),
    String(Number(currentYear) - 7),
    String(Number(currentYear) - 6),
    String(Number(currentYear) - 5),
    String(Number(currentYear) - 4),
    String(Number(currentYear) - 3),
    String(Number(currentYear) - 2),
    String(Number(currentYear) - 1),
    String(Number(currentYear)),
  ];

  const monthData = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const weekData = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const { addToast } = useToasts();

  const [rows, setRows] = useState([]);
  const [dataList, setDataList] = useState([]);
  const [initialRows, setInitialRows] = useState([]);
  const [rangeType, setRangeType] = useState("YEARLY");
  const [noOfbookings, setNoOfBookings] = useState([]);
  const [noOfCallLogs, setNoOfCallLogs] = useState([]);
  const [noOfClearances, setNoOfClearances] = useState([]);
  const [dummpyDataBooking, setDummpyDataBooking] = useState();
  const [dummpyDataClearance, setDummpyDataClearance] = useState();
  const [dummpyDataCalls, setDummpyDataCalls] = useState();

  const [startDate, setStartDate] = useState(
    moment(moment().startOf("year").toDate()).format("YYYY/MM/DD")
  );
  const [endDate, setEndDate] = useState(
    moment(moment().endOf("year").toDate()).format("YYYY/MM/DD")
  );

  const datadata = {
    labels: dataList,
    datasets: [
      {
        label: "No. of Bookings",
        data: noOfbookings,
        // data: dummpyDataBooking,   //use this for dummy data
        fill: false,
        backgroundColor: "rgb(255, 99, 132)",
        borderColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y-axis-1",
      },
      {
        label: "No. of Clearances",
        data: noOfClearances,
        // data: dummpyDataClearance,   //use this for dummy data
        fill: false,
        backgroundColor: "rgb(54, 162, 235)",
        borderColor: "rgba(54, 162, 235, 0.5)",
        // yAxisID: 'y-axis-2',
      },
      {
        label: "No. of Calls",
        data: noOfCallLogs,
        // data: dummpyDataCalls,    //use this for dummy data
        fill: false, 
        backgroundColor: "rgb(244, 196, 20)",
        borderColor: "rgba(244, 196, 20, 0.5)",
        // yAxisID: 'y-axis-2',
      },
    ],
  };

  useEffect(() => {
    if (rangeType == "MONTHLY") {
      setDummpyDataBooking([3033, 2020, 3048, 3563, 3530, 5068, 6054]);
      setDummpyDataClearance([23, 48, 88, 63, 56, 65, 69]);
      setDummpyDataCalls([1118, 1265, 1549, 1336, 1560, 1563, 1790]);
    } else if (rangeType == "YEARLY") {
      setDummpyDataBooking([
        28033, 25820, 35248, 33356, 33363, 36030, 34032, 34668, 35670, 36069,
        37550, 45554,
      ]);
      setDummpyDataClearance([
        2223, 2525, 2548, 3560, 5651, 2533, 3330, 3388, 3675, 3863, 3556, 4060,
      ]);
      setDummpyDataCalls([
        15545, 14418, 12648, 15365, 17849, 12535, 14536, 16560, 13579, 15466,
        15660, 14590,
      ]);
    } else if (rangeType == "FULL_YEARLY") {
      setDummpyDataBooking([
        25733, 27720, 35548, 26563, 25530, 35532, 33370, 34469, 35550, 37754,
      ]);
      setDummpyDataClearance([
        2423, 5225, 2548, 3651, 3633, 6488, 6575, 6563, 6756, 7060,
      ]);
      setDummpyDataCalls([
        12445, 13518, 14548, 12565, 14535, 14613, 15479, 15666, 16560, 13690,
      ]);
    }
  }, [rangeType]);

  // const setStartAndEndDates = () => {
  //   if (rangeType == "WEEKLY") {
  //     setStartDate(
  //       moment(moment().startOf("week").toDate()).format("YYYY/MM/DD")
  //     );

  //     setEndDate(moment(moment().endOf("week").toDate()).format("YYYY/MM/DD"));
  //   } else if (rangeType == "MONTHLY") {
  //     setStartDate(
  //       moment(moment().startOf("month").toDate()).format("YYYY/MM/DD")
  //     );
  //     setEndDate(moment(moment().endOf("month").toDate()).format("YYYY/MM/DD"));
  //   } else if (rangeType == "YEARLY") {
  //     setStartDate(
  //       moment(moment().startOf("year").toDate()).format("YYYY/MM/DD")
  //     );
  //     setEndDate(moment(moment().endOf("year").toDate()).format("YYYY/MM/DD"));
  //   }
  // };

  const getAllRows = () => {
    //according to the userrole change the performance data in the chart
    //currently showing the looged in user's data

    // setStartAndEndDates();

    let startTempDate;
    let endTempDate;

    if (rangeType == "MONTHLY") {
      startTempDate = moment(moment().startOf("isoWeek").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(moment().endOf("isoWeek").toDate()).format(
        "YYYY/MM/DD"
      );
      // } else if (rangeType == "MONTHLY") {
      //   startTempDate = moment(moment().startOf("month").toDate()).format(
      //     "YYYY/MM/DD"
      //   );

      //   endTempDate = moment(moment().endOf("month").toDate()).format(
      //     "YYYY/MM/DD"
      //   );
    } else if (rangeType == "YEARLY") {
      startTempDate = moment(moment().startOf("year").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(moment().endOf("year").toDate()).format(
        "YYYY/MM/DD"
      );
    } else {
      startTempDate = moment(moment().startOf("month").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(moment().endOf("month").toDate()).format(
        "YYYY/MM/DD"
      );
    }

    axios
      .get(
        DASHBOARD_PERFORMANCE +
          "performance-report-chart?chartType=" +
          rangeType +
          "&start=" +
          startTempDate +
          "&end=" +
          moment(moment(endTempDate).add(1, "days")).format("YYYY/MM/DD") +
          "&empId=" +
          auth.id
      )
      .then((res) => {
        let datesTemp = [];
        let monthsTemp = [];
        let yearsTemp = [];

        for (
          let m = moment(startTempDate);
          m.isBefore(
            moment(moment(endTempDate).add(1, "days")).format("YYYY/MM/DD")
          );
          m.add(1, "days")
        ) {
          datesTemp.push(m.format("DD"));
          monthsTemp.push(m.format("MM"));
          yearsTemp.push(m.format("YYYY"));
        }

        let months = uniqWith(monthsTemp, isEqual);
        let years = uniqWith(yearsTemp, isEqual);

        if (rangeType == "MONTHLY") {
          arrangeData(res, datesTemp, 2);
          setDataList(weekData);
        } else if (rangeType == "YEARLY") {
          arrangeData(res, months, 1);
          setDataList(monthData);
        } else if (rangeType == "FULL_YEARLY") {
          arrangeData(res, yearData, 0);
          setDataList(yearData);
        }
      })
      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };

  const arrangeData = (res, settingArray, splitBy) => {
    let chartTempDataBookings = [];
    let chartTempDataCallLog = [];
    let chartTempDataClearance = [];

    forEach(settingArray, function (month) {
      let A = filter(res.data.body[0].booking, (item) => {
        let splitedMonthIncomming = split(item.label, "-");
        let monthIncomming = splitedMonthIncomming[splitBy];

        return monthIncomming === month;
      });
      if (A.length > 0) {
        chartTempDataBookings.push(Number(A[0].value));
      } else {
        chartTempDataBookings.push(0);
      }
    });

    forEach(settingArray, function (month) {
      let A = filter(res.data.body[0].call_log, (item) => {
        let splitedMonthIncomming = split(item.label, "-");
        let monthIncomming = splitedMonthIncomming[splitBy];

        return monthIncomming === month;
      });
      if (A.length > 0) {
        chartTempDataCallLog.push(Number(A[0].value));
      } else {
        chartTempDataCallLog.push(0);
      }
    });

    forEach(settingArray, function (month) {
      let A = filter(res.data.body[0].clearance, (item) => {
        let splitedMonthIncomming = split(item.label, "-");
        let monthIncomming = splitedMonthIncomming[splitBy];

        return monthIncomming === month;
      });
      if (A.length > 0) {
        chartTempDataClearance.push(Number(A[0].value));
      } else {
        chartTempDataClearance.push(0);
      }
    });
    setNoOfBookings(chartTempDataBookings);
    setNoOfCallLogs(chartTempDataCallLog);
    setNoOfClearances(chartTempDataClearance);
  };

  useDidMountEffect(() => {
    getAllRows();
  }, [rangeType || startDate || endDate]);

  useEffect(() => {
    //    setStartAndEndDates();
    getAllRows();
  }, []);

  return (
    <div className="card">
      <div className="card-body">
        <Label>Performance Reports. </Label>
        <small> Displayed :</small>
        <ButtonGroup className="ml-3" size="sm">
          <Button
            onClick={() => setRangeType("MONTHLY")}
            className={`${rangeType === "MONTHLY" && "active"}`}
          >
            By Week
          </Button>
          <Button
            onClick={() => setRangeType("YEARLY")}
            className={`${rangeType === "YEARLY" && "active"}`}
          >
            By Month
          </Button>
          <Button
            onClick={() => setRangeType("FULL_YEARLY")}
            className={`${rangeType === "FULL_YEARLY" && "active"}`}
          >
            By Year
          </Button>

          {/* <Button >Yearly</Button> */}
        </ButtonGroup>

        <Line data={datadata} options={options}></Line>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth.item,
  };
}

export default connect(mapStateToProps, {})(MultiChart);
