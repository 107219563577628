import React, { useState, useEffect } from "react";

import axios from "axios";
import { forEach, filter } from "lodash";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../../../components/toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCheck } from "@fortawesome/free-solid-svg-icons";
import { REGISTER_CANDIDATE } from "../../../../../configs/api-config";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";

function identifyObject(objects) {
  let result = {};
  objects.forEach((obj) => {
    result[obj.identifier + "ID"] = obj.id || "";
    result[obj.identifier] = obj.value || "";
  });
  return [result];
}

const NextOfKeen = () => {
  const MexxarApi = REGISTER_CANDIDATE;

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();

  const [data, setData] = useState([]);
  const [editable, setEditable] = useState(false);
  const [candidateID, setCandidateID] = useState([]);

  const handleEdit = () => {
    if (editable) {
      //edit call
    }
    setEditable(!editable);
  };

  const filterData = (data, identifier) => {
    let update_List = [];
    let id = data[identifier + "ID"];
    let value = data[identifier];

    update_List.push({
      id: Number(id),
      identifier: identifier,
      value: value,
      detailCategory: 1,
    });
    return update_List;
  };

  const onSubmit = (formData) => {
    let AdvanceDetailsArray = [];

    // console.log("55555555 angi", formData);

    if (Object.keys(data).length == 0) {
      forEach(formData, function (value, key) {
        // if (value === "") {
        // } else {
          AdvanceDetailsArray.push({
            identifier: key,
            value: value,
            detailCategory: 1,
          });
        // }
      });
    } else {
      let A = filterData(formData, "firstName");
      let B = filterData(formData, "lastName");
      let C = filterData(formData, "middleName");
      let D = filterData(formData, "relationship");
      let E = filterData(formData, "address");
      let F = filterData(formData, "city");
      let G = filterData(formData, "county");
      let H = filterData(formData, "postalCode");
      let I = filterData(formData, "tpMobile");
      let J = filterData(formData, "tpLandLine");
      let K = filterData(formData, "tpOther");

      let updatingArray = A.concat(B, C, D, E, F, G, H, I, J, K);
      AdvanceDetailsArray = updatingArray;
    }

    axios
      .post(
        MexxarApi + "/" + candidateID + "/advance-profile-details",
        AdvanceDetailsArray
      )
      .then((res) => {
        if (res.data.status === "success") {
          // getAllRows();
          // setShowAddRow(!showAddRow);
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err);
        ToastCommon(true, "error", addToast);
      });
  };

  const getData = () => {
    let pathArray = window.location.pathname.split("/");
    const CandidateId = Number(pathArray[3]);
    //**********CandidateId and candidateID have same value but variable names are different*******/
    setCandidateID(CandidateId);
    axios
      .get(
        MexxarApi +
          "/" +
          CandidateId +
          "/advance-profile-details/category/NEXT_OF_KIN"
      )
      .then((res) => {
        let list = res.data.body;
        let result = identifyObject(list);

        // console.log("obj angi",list)

        // setData(result[0]);
        // console.log("results angi  next of keen", result);
        let firstName = filter(list, (item) => {
          return item.identifier === "firstName";
        });
        // console.log("firstName .........", firstName);

        let lastName = filter(list, (item) => {
          return item.identifier === "lastName";
        });
        //console.log("lastName .........", lastName);

        let middleName = filter(list, (item) => {
          return item.identifier === "middleName";
        });

        //console.log("middleName .........", middleName);

        let relationship = filter(list, (item) => {
          return item.identifier === "relationship";
        });

        // console.log("relationship .........", relationship);

        let address = filter(list, (item) => {
          return item.identifier === "address";
        });

        //console.log("address .........", address);

        let city = filter(list, (item) => {
          return item.identifier === "city";
        });

        // console.log("city .........", city);

        let county = filter(list, (item) => {
          return item.identifier === "county";
        });

        // console.log("county .........", county);

        let postalCode = filter(list, (item) => {
          return item.identifier === "postalCode";
        });

        //  console.log("postalCode .........", postalCode);

        let tpMobile = filter(list, (item) => {
          return item.identifier === "tpMobile";
        });

        //  console.log("tpMobile .........", tpMobile);

        let tpLandLine = filter(list, (item) => {
          return item.identifier === "tpLandLine";
        });

        //  console.log("tpLandLine .........", tpLandLine);

        let tpOther = filter(list, (item) => {
          return item.identifier === "tpOther";
        });

        //  console.log("tpOther .........", tpOther);

        let data_List = [];
        data_List.push({
          firstName: firstName[0].value,
          firstNameID: firstName[0].id,
          lastName: lastName[0].value,
          lastNameID: lastName[0].id,
          // middle name and tp other have legth check because those fields arent required fileds hence
          //that will send undefined values to the server when submitting
          middleName: middleName.length === 0 ? "" : middleName[0].value,
          middleNameID: middleName.length === 0 ? "" : middleName[0].id,
          relationship: relationship[0].value,
          relationshipID: relationship[0].id,
          address: address[0].value,
          addressID: address[0].id,
          city: city[0].value,
          cityID: city[0].id,
          county: county[0].value,
          countyID: county[0].id,
          postalCode: postalCode[0].value,
          postalCodeID: postalCode[0].id,
          tpMobile: tpMobile[0].value,
          tpMobileID: tpMobile[0].id,
          tpLandLine: tpLandLine.length === 0 ? "" : tpLandLine[0].value,
          tpLandLineID: tpLandLine.length === 0 ? "" : tpLandLine[0].id,
          tpOther: tpOther.length === 0 ? "" : tpOther[0].value,
          tpOtherID: tpOther.length === 0 ? "" : tpOther[0].id,
        });

        setData(data_List[0]);
      })
      .catch((err) => {
        //  console.log(err);
      });
  };
  // console.log("next of keen old angi", data);
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <div className="col-md-12 d-flex justify-content-between align-items-center">
              <h5>Next Of Kin</h5>
              {/* <FormGroup row>
                <button
                  className={`btn btn-wave btn-raised ${
                    editable ? "bg-warning " : "bg-danger "
                  }`}
                  type="button"
                  onClick={() => handleEdit()}
                >
                  {editable ? "Save" : "Edit"}
                  {editable ? (
                    <FontAwesomeIcon icon={faCheck} className="ml-3" />
                  ) : (
                    <FontAwesomeIcon icon={faEdit} className="ml-3" />
                  )}
                </button>
              </FormGroup> */}
            </div>
          </div>
          <div className="card-body">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <FormGroup row>
                <Col md={3}>
                  <FormGroup>
                    <Label for="firstName">
                      First Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="firstName"
                      id="firstName"
                      defaultValue={data.firstName}
                      placeholder="First Name"
                      innerRef={register({
                        required: {
                          value: true,
                          message: "This field is required!",
                        },
                      })}
                    />
                    <Input
                      type="hidden"
                      name="firstNameID"
                      defaultValue={data.firstNameID}
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="lastName">Middle Name</Label>
                    <Input
                      type="text"
                      name="middleName"
                      id="middleName"
                      defaultValue={data.middleName}
                      placeholder="Middle Name"
                      innerRef={register}
                    />
                    <Input
                      type="hidden"
                      name="middleNameID"
                      defaultValue={data.middleNameID}
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="lastName">
                      Last Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="lastName"
                      id="lastName"
                      defaultValue={data.lastName}
                      placeholder="Last Name"
                      innerRef={register({
                        required: {
                          value: true,
                          message: "This field is required!",
                        },
                      })}
                    />
                    <Input
                      type="hidden"
                      name="lastNameID"
                      defaultValue={data.lastNameID}
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="relationship">
                      Relationship <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="relationship"
                      id="relationship"
                      defaultValue={data.relationship}
                      placeholder="Relationship"
                      innerRef={register({
                        required: {
                          value: true,
                          message: "This field is required!",
                        },
                      })}
                    />
                    <Input
                      type="hidden"
                      name="relationshipID"
                      defaultValue={data.relationshipID}
                      innerRef={register}
                    />
                  </FormGroup>
                </Col>
              </FormGroup>
              <FormGroup>
                <Label for="exampleAddress">
                  Address <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  name="address"
                  id="address"
                  defaultValue={data.address}
                  placeholder="1234 Main St"
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                />
                <Input
                  type="hidden"
                  name="addressID"
                  defaultValue={data.addressID}
                  innerRef={register}
                />
              </FormGroup>

              <FormGroup row>
                <Col md={6}>
                  <Label for="exampleCity">
                    City <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="city"
                    id="city"
                    defaultValue={data.city}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  />
                  <Input
                    type="hidden"
                    name="cityID"
                    defaultValue={data.cityID}
                    innerRef={register}
                  />
                </Col>
                <Col md={4}>
                  <Label for="exampleState">
                    County <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="county"
                    id="county"
                    defaultValue={data.county}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  />
                  <Input
                    type="hidden"
                    name="countyID"
                    defaultValue={data.countyID}
                    innerRef={register}
                  />
                </Col>
                <Col md={2}>
                  <Label for="exampleZip">
                    Postal Code <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="postalCode"
                    id="postalCode"
                    defaultValue={data.postalCode}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  />
                  <Input
                    type="hidden"
                    name="postalCodeID"
                    defaultValue={data.postalCodeID}
                    innerRef={register}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col md={4}>
                  <Label for="tpMobile">
                    Phone Number<span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="tpMobile"
                    id="tpMobile"
                    defaultValue={data.tpMobile}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                      maxLength: {
                        value: 30,
                        message: "This field only allowed only 30 characters",
                      },
                    })}
                  />
                  <Input
                    type="hidden"
                    name="tpMobileID"
                    defaultValue={data.tpMobileID}
                    innerRef={register}
                  />
                </Col>
                <Col md={4}>
                  <Label for="tpLandLine">Land Line Number</Label>
                  <Input
                    type="text"
                    name="tpLandLine"
                    id="tpLandLine"
                    defaultValue={data.tpLandLine}
                    innerRef={register}
                  />
                  <Input
                    type="hidden"
                    name="tpLandLineID"
                    defaultValue={data.tpLandLineID}
                    innerRef={register}
                  />
                </Col>
                <Col md={4}>
                  <Label for="tpOther">Other</Label>
                  <Input
                    type="text"
                    name="tpOther"
                    id="tpOther"
                    defaultValue={data.tpOther}
                    innerRef={register}
                  />
                  <Input
                    type="hidden"
                    name="tpOtherID"
                    defaultValue={data.tpOtherID}
                    innerRef={register}
                  />
                </Col>
              </FormGroup>
              <div className="d-flex justify-content-between">
                <div></div>
                <div>
                  <Button className="mr-2" color="success" type="submit">
                    Save
                  </Button>
                  {/* <Button color="success" onClick={() => {}}>
                    Next
                  </Button> */}
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextOfKeen;
