import React, { useState, useEffect, useRef } from "react";
import {
  CANDIDATE_SPECIALITY,
  FRAMEWORK,
  CONFIGURE_DOCS,
  REGISTER_CANDIDATE,
  HOSTMexxar,
  CompanyName,
  ClientLable,
} from "../../../configs/api-config";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Alert,
  Row,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap";

import axios from "axios";
import moment from "moment";
import "./compliance.style.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  ProfileStatus,
  PermApplicationSendingStatus,
  PermClientApplicationStatus,
} from "../../../util/common";
import { forEach, filter, uniq, uniqBy, isNull } from "lodash";
import {
  faCheck,
  faChevronCircleDown,
  faChevronCircleUp,
  faFileDownload,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import ModalComponent from "../../../components/Modal/modalComponent";
import EmailNav from "../../../components/Modal/ModalBody/emailNav";
import ToastCommon from "../../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import loadingAnimation from "../../../../src/images/puff.svg";
import Select from "react-select";

import IsPrivileged from "../../../util/auth/privilegeHelper.js";
import { useIsMounted } from "../../../customHooks/useIsMounted";
import { FunnelIcon } from "../../../assets/icons/svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { downloadDocs } from "../../../util/common/downloadDocument";
import DocumentDownloading from "../../../components/Modal/ModalBody/documentDownloading";
import CandidateOnlineChecksDataAdding from "../../../components/Modal/ModalBody/candidateOnlineChecksDataAdding";
import WhiteListDoc from "../../../components/Modal/ModalBody/whiteListDoc.js";
import CandidatesChecksHistory from "../../../components/Modal/ModalBody/CandidatesChecksHistory.js";

function ConfigurationWarning({ type, candidateType }) {
  return (
    <>
      {type == "both" && candidateType == "TEMPORARY" && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px" }}
        >
          Please assign a Speciality and a {ClientLable} to the candidate to see
          the document requirement
        </div>
      )}
      {type == "speciality" && candidateType == "TEMPORARY" && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px" }}
        >
          Please assign a Speciality to the candidate to see the document
          requirement
        </div>
      )}
      {type == "client" && candidateType == "TEMPORARY" && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px" }}
        >
          Please assign a {ClientLable} to the candidate to see the document
          requirement
        </div>
      )}

      {type == "noCertificateData" && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px" }}
        >
          Please setup documents from the Admin panel. If you are facing
          difficulties please contact the Mexxar Support Team
        </div>
      )}
    </>
  );
}

const Level = (props) => {
  const {
    WhiteListUploadedDocsCount,
    WhiteListExpDocsCount,
    WhiteListPendingDocsCount,
    candidateId,
    changedFramework,
    candidateRole,
    changedSpecility,
    candidateBand,
    roleName,
    bandName,
    specialityName,
  } = props;
  let userType = props.auth.item.userType;
  let consultantFirstName = props.auth.item.firstName;
  let consultantLastName = props.auth.item.lastName;
  let prefferedClients = props.candidateData?.preferredClients;
  const filteredClients = prefferedClients.filter((item) => item.status === 'ACTIVE');

  // Map the filtered array to the format needed for the Select component
  const prefferedClientsArray = filteredClients.map((item) => ({
    label: item.name,
    id: item.id,
    value: item.name,
    framework: item.framework,
  }));

  const [docsLoading, setDocsLoading] = useState({
    status: true,
    type: "",
    docType: "",
  });

  const checkPermissions = (permission) => {
    let array = filter(props.auth.item.userRole.permissions, (item) => {
      return item.name == permission;
    });
    return array;
  };

  const [selectedType, setSelectedType] = useState("");

  const handleSelectChange = (event) => {
    setSelectedType(event.target.value);
  };

  const [remember, setRemember] = useState(false);

  const saveFilters = (type, data) => {
    localStorage.setItem(type, JSON.stringify(data));
  };



  const clearLocalStorageFilters = () => {
    let emptyObject = {
      id: "",
      value: "",
      label: "",
    };
    localStorage.setItem(
      "rememberFiltersCompliance",
      JSON.stringify("false")
    );
    localStorage.setItem("selectedTypeCompliance", JSON.stringify(""));

  };


  useEffect(() => {
    let emptyObject = {
      id: "",
      value: "",
      label: "",
    };

    let rememberTemp = localStorage.getItem("rememberFiltersCompliance");

    if (rememberTemp == "true") {
      setRemember(true);
    } else {
      setRemember(false);
    }

    if (rememberTemp == "true") {
      const storedSelectedType =
        JSON.parse(localStorage.getItem("selectedTypeCompliance")) || "";

      setSelectedType(storedSelectedType);
    } else {
      resetFilters();
    }
  }, []);

  const resetFilters = () => {
    clearLocalStorageFilters();
    setSelectedType("");
    setRemember(false);
  };

  useDidMountEffect(() => {
    if (!remember) {
      //in default view pagenumber is reset to 1
      resetFilters()
    }
  }, [!remember]);

  const filterByName = (name, dataSet, selectedType) => {
    let filtered = dataSet;

    if (name !== "") {
      filtered = filtered.filter((item) => {
        let searchableString = `${item.certificateName}`;
        return searchableString?.toLowerCase().includes(name?.toLowerCase());
      });
    }

    if (selectedType === "MANDATORY") {
      filtered = filtered.filter((item) => item.type === "MANDATORY");
    } else if (selectedType === "IF_APPLICABLE") {
      filtered = filtered.filter((item) => item.type === "IF_APPLICABLE");
    }

    let lengthofSearch = filtered.length;
    return { filtered, lengthofSearch };
  };

  const abortControllerRef = useRef(new AbortController());

  const getAllDocsTodownload= () => {
    abortControllerRef.current = new AbortController();
    axios({
      method: 'GET',
      url: HOSTMexxar + 'documents/upload/filter?candidateId=' + props.candidateId,
      params: {
        offset: 200,
      },
    })
      .then((res) => {
        if (res.data.status == 'success') {
          if (res.data.body.content.length > 0) {
            downloadDocs({
              doc: res.data.body.content,
              candidateId: props.candidateId,
              candidateServiceType: props.candidateServiceType,
              candidateName: props.candidateName,
              type: 'MULTIPLE',
              docType: 'DEFAULT',
              setDocsLoading,
            }, abortControllerRef.current);
          }
        }
      })
      .catch((error) => {});
  };

  const cancelDownload = () => {
    abortControllerRef.current.abort(); 
    setDocsLoading({ status: false, type: '', docType: '' });
  };
  const [showModalAddCheckDetails, setShowModalAddCheckDetails] = useState({
    bool: false,
    type: null,
  });
  const [showModalHiistoryCheckDetails, setShowModalHiistoryCheckDetails] = useState({
    bool: false,
    type: null,
  });
  const [showModalTemplate, setShowModalTemplate] = useState(false);
  const [changedCheck, setChangedCheck] = useState("NMC");
  const changeCheck = (e) => {
    setChangedCheck(e.target.value);
  };

  const [activeTab, setActiveTab] = useState("all");

  const [showAllView, setShowAllView] = useState(true);
  const [showTrainingView, setShowTrainingView] = useState(false);
  const [showRTWView, setShowRTWView] = useState(false);
  const [showOtherView, setShowOtherView] = useState(false);
  const [showImmuView, setShowImmuView] = useState(false);
  const [showRefView, setShowRefView] = useState(false);
  const [showFTWView, setShowFTWView] = useState(false);

  const toggleView = (view) => {
    setShowAllView(view === "all");
    setShowTrainingView(view === "training");
    setShowRTWView(view === "rtw");
    setShowOtherView(view === "other");
    setShowImmuView(view === "immu");
    setShowRefView(view === "ref");
    setShowFTWView(view === "ftw");
    setActiveTab(view);
  };

  const [menuItemList, setMenuItemList] = useState([]);
  const [currentTab, setCurrentTab] = useState([]);
  const styles = {
    tlItem: {
      display: "flex",
      alignItems: "center",
      marginBottom: "10px",
    },
    tlDot: {
      width: "10px",
      height: "10px",
      borderRadius: "50%",
      marginRight: "10px",
    },
    bSuccess: {
      backgroundColor: "green",
    },
    bWarning: {
      backgroundColor: "yellow",
    },
    tlContent: {
      flex: 1,
    },
  };

  function DocSummaryCard({ certificate, props, onItemSelect, getDocID }) {
    return (
      <div key={certificate.id} className="list list-row block">
        <div className="list-item" data-id="1">
          {showWhiteLabeling && (
            <div className="d-flex align-items-center mr-3 ">
              <span className="mx-2">
                {/* <b className="badge badge-circle sm text-primary"></b> */}
                <label className="ui-check m-0">
                  <input
                    type="checkbox"
                    name="id"
                    id={getDocID(certificate.id)}
                    disabled={removeAll}
                    checked={isSelected(certificate.id) && !removeAll}
                    onChange={(e) => {
                      onItemSelect(
                        parseInt(e.target.id),
                        parseInt(certificate.id)
                      );
                    }}
                  />
                  <i></i>
                </label>
              </span>
            </div>
          )}
          <Link
            to={{
              pathname:
                "/dashboard/docview/" +
                "UP/" +
                props.candidateId +
                "/" +
                certificate.id +
                "/" +
                props.candidateRole +
                "/" +
                props.changedSpecility +
                "/" +
                props.changedFramework,
            }}
          >
            <div
              className={`w-48 avatar d-flex flex-column text-dark`}
              style={{
                backgroundColor: certificate.avtarColor,
              }}
            >
              <div>
                {certificate.daysLeft > 100000 ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  <>
                    {certificate.daysLeft > 364 ? (
                      <small> 1 Y+ </small>
                    ) : (
                      <small> {certificate.daysLeft} </small>
                    )}
                  </>
                )}
              </div>
            </div>
          </Link>
          <div
            className="d-flex flex-column"
            style={{ flex: 4, width: "200px" }}
          >
            <div>{certificate.certificateName}</div>
            <div>
              {certificate?.type === "MANDATORY" && (
                <Badge color="danger">Mandatory</Badge>
              )}
              {certificate?.type === "IF_APPLICABLE" && (
                <Badge color="warning">If Applicable</Badge>
              )}
            </div>
          </div>
          <div className="d-flex flex-column" style={{ flex: 3 }}>
            <div className="text-muted text-sm">
              Exp :{" "}
         
                      {certificate.daysLeft > 100000
            ? "N/A"
            : certificate.expireDate === null
            ? "N/A"
            : certificate.expireDate === "2500-01-01T00:00:00.000+00:00"
            ? "N/A"
            : moment(certificate.expireDate).format("YYYY-MM-DD")}
            </div>
          </div>

          {/************ for temp staffing *************/}
          <IsPrivileged privilege={["EMPLOYEE_TEMP"]}>
            <div
              className="d-flex align-items-center justify-content-center flex-column"
              style={{ flex: 3 }}
            >
              <div style={{ height: "4vh", flex: 1 }}>
                <Label>Confirm Status</Label>
              </div>
              <div style={{ height: "3vh", flex: 1 }}>
                {certificate.confirmation != null &&
                  certificate.confirmation === "PENDING" && (
                    <Badge color="warning">Pending</Badge>
                  )}
                {certificate.confirmation != null &&
                  certificate.confirmation === "APPROVED" && (
                    <Badge color="success">Confirmed</Badge>
                  )}
                {certificate.confirmation != null &&
                  certificate.confirmation === "DECLINED" && (
                    <Badge color="danger">Decline</Badge>
                  )}
              </div>
              <div style={{ height: "3vh", flex: 1 }}></div>
            </div>
            <div
              className="d-flex align-items-center justify-content-center flex-column"
              style={{ flex: 3 }}
            >
              <div style={{ height: "4vh", flex: 1 }}>
                <Label>Audit Status</Label>
              </div>
              <div style={{ height: "3vh", flex: 1 }}>
                {certificate.audition != null &&
                  certificate.audition === "PENDING" && (
                    <Badge color="warning">Pending</Badge>
                  )}
                {certificate.audition != null &&
                  certificate.audition === "APPROVED" && (
                    <Badge color="success">Verified</Badge>
                  )}
                {certificate.audition != null &&
                  certificate.audition === "DECLINED" && (
                    <Badge color="danger">Decline</Badge>
                  )}
              </div>
              <div style={{ height: "3vh", flex: 1 }}></div>
            </div>
          </IsPrivileged>
          {/************ End temp staffing only feature ************/}
          <div
            className="d-flex align-items-center justify-content-center flex-column"
            style={{ flex: 1, width: "100px" }}
          >
            <div className="item-date text-muted text-sm d-md-block">
              <Link
                to={{
                  pathname: `/dashboard/docview/UP/${props.candidateId}/${certificate.id}/${props.candidateRole}/${props.changedSpecility}/${props.changedFramework}`,
                }}
              >
                <Button style={{ width: "100px" }}>
                  {certificate.documentLink != null ? (
                    "View"
                  ) : (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-upload text-primary"
                      >
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                        <polyline points="17 8 12 3 7 8"></polyline>
                        <line x1="12" y1="3" x2="12" y2="15"></line>
                      </svg>
                      &nbsp;Upload
                    </>
                  )}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const { addToast } = useToasts();

  const [showWhiteLabeling, setShowWhiteLabeling] = useState(false);
  const [showWhiteLableDescription, setShowWhiteLableDescription] =
    useState(false);
  const [whiteListPreference, setWhiteListPreference] = useState("CANDIDATE");
  const [previousSavedItemList, setPreviousSavedItemList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [whiteListLoading, setWhiteListLoading] = useState(false);
  const [selectedPrefferedClient, setSelectedPrefferedClient] = useState(null);
  const [whilteLableError, setWhilteLableError] = useState({
    bool: false,
    message: "",
  });
  const [whiteListTypeCandidate, setWhiteListTypeCandidate] = useState([]);
  const [defaultWhiteList, setDefaultWhiteList] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getDocID = (id) => {
    // console.log("Previous Saved Item List:", previousSavedItemList);
    let docId = filter(previousSavedItemList, (item) => {
      return item.rowId == id;
    });

    if (docId.length > 0) {
      return docId[0].id;
    } else {
      return "";
    }
  };

  const isSelected = (id) => {
    let selectedRows = filter(selectedItems, (item) => {
      return item.rowId == id;
    });

    if (selectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const [selectAll, setSelectAll] = useState(false);
  const [removeAll, setRemoveAll] = useState(false);

  const onItemSelect = (id, rowId) => {
    let selectedRows = filter(selectedItems, (item) => {
      return item.rowId == rowId;
    });

    if (selectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item.rowId != rowId;
      });
      setSelectedItems([...removeItems]);
      // setSelectAll(false);
      return false;
    } else {
      setSelectedItems([...selectedItems, { id, rowId }]);
      return true;
    }
  };

  const getAllDocIds = () => {
    let ids = [];
    props.certificateData[0]?.certificates.forEach((item) => {
      const found = selectedItems?.filter(
        (selectedItem) => selectedItem?.rowId === item.id
      );
      if (found.length > 0) {
        ids.push({ id: found[0]?.id, rowId: found[0]?.rowId });
      } else {
        ids.push({ id: parseInt(""), rowId: item.id });
      }
    });
    return ids;
  };
  const toggleWhiteList = () => {
    setDefaultWhiteList(!defaultWhiteList);
  };

  const toggleRemoveAllDocs = () => {
    setRemoveAll(!removeAll);
    if (removeAll) {
      setRemoveAll(false);
      setSelectedItems([...previousSavedItemList]);
    } else {
      setSelectAll(false);
      setRemoveAll(true);

      const ids = getAllDocIds();
      setSelectedItems([...selectedItems, ...ids]);
    }
  };

  const toggleSelectAllDocs = () => {
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([...previousSavedItemList]); //keep previouse items in the list once select all is false. so when adding ids will not miss and will not duplicate in the DB
    } else {
      setRemoveAll(false);
      setSelectAll(true);

      const ids = getAllDocIds();
      setSelectedItems([...selectedItems, ...ids]);
      setSelectAll(true);
    }
  };

  const addWhiteList = () => {
    // Create a set of unique identifiers from array2.

    const setOfIdentifiers = new Set(
      selectedItems?.map((item) => `${item.id}-${item.rowId}`)
    );
    // Find items in array1 that are not present in array2
    const removingDocs = previousSavedItemList.filter(
      (item1) => !setOfIdentifiers.has(`${item1.id}-${item1.rowId}`)
    );

    if (selectedItems.length === 0) {
      setWhilteLableError({
        bool: true,
        message: "Please select at least one Document",
      });
      return;
    }
    if (whiteListPreference === "CLIENT" && !selectedPrefferedClient) {
      setWhilteLableError({
        bool: true,
        message: "Please select at least one Client",
      });
      return;
    }
    if (!whiteListPreference) {
      setWhilteLableError({
        bool: true,
        message: "Please select white list type",
      });
      return;
    }

    const deleteWhiteListDocs = () => {
      setWhiteListLoading(true);

      let axiosPromises = [];

      const setOfIdentifiers = new Set(
        selectedItems.map((item) => `${item.id}-${item.rowId}`)
      );

      // Find items in array1 that are not present in array2
      const removingDocs = removeAll
        ? selectedItems
        : previousSavedItemList.filter(
          (item1) => !setOfIdentifiers.has(`${item1.id}-${item1.rowId}`)
        );

      removingDocs.forEach((item) => {
        if (item.id) {
          axiosPromises.push(
            axios.delete(HOSTMexxar + "frameworkDocWhitelist/" + item.id)
          );
        }
      });

      // Wait for all axios promises to resolve
      Promise.all(axiosPromises)
        .then(() => {
          // All delete requests completed successfully
          // Now, execute getWhiteList
          setWhiteListLoading(false);

          getWhiteList(props.candidateId, whiteListPreference); //refill the states with new set of data
        })
        .catch((error) => {
          // Handle errors if any
          console.error("Error deleting whitelist documents:", error);
        });
    };

    const triggerBookingEligibility = () => {
      axios
        .put(
          HOSTMexxar +
          `candidates/${parseInt(
            props?.candidateId
          )}/update/booking-eligibility`
        )
        .then((res) => {
          ToastCommon(true, "success", addToast);
        })
        .catch((error) => {
          // ToastCommon(true, "error", addToast);
        });
    };

    const createWhiteList = (client) => {
      setWhiteListLoading(false);

      // Create an array to store Axios promises for each client
      let axiosPromises = [];

      deleteWhiteListDocs();

      setWhiteListLoading(true);
      selectedItems.forEach((item) => {
        let whiteList = {
          candidate: parseInt(props.candidateId),
          document: item.rowId,
          client: whiteListPreference === "CLIENT" ? client?.id : null,
          type: whiteListPreference,
          candidateBand: props.candidateData.candidateBand.id,
          candidateRole: props.candidateRole,
          candidateSpeciality: props.changedSpecility,
          level: 1,
          framework:
            whiteListPreference === "CLIENT"
              ? client.framework.id
              : props.changedFramework,
        };

        if (isNaN(item.id)) {
          // Push each Axios promise into the array for the current client
          axiosPromises.push(
            axios.post(HOSTMexxar + "frameworkDocWhitelist", whiteList)
          );
        } else {
          // Push each Axios promise into the array for the current client
          axiosPromises.push(
            axios.put(
              HOSTMexxar + "frameworkDocWhitelist/" + item.id,
              whiteList
            )
          );
        }
      });

      // Use Promise.all() to wait for all Axios promises for the current client to resolve
      Promise.all(axiosPromises)
        .then((responses) => {

          triggerBookingEligibility();
          setWhilteLableError({
            bool: false,
            message: "",
          });
          setWhiteListLoading(false);
          getWhiteList(props.candidateId, whiteListPreference);

        })
        .catch((error) => {
          setWhiteListLoading(false);

        });
    };

    if (whiteListPreference === "CLIENT") {
      // Iterate through each selected preferred client
      removeAll
        ? deleteWhiteListDocs()
        : createWhiteList(selectedPrefferedClient);
    } else {
      removeAll ? deleteWhiteListDocs() : createWhiteList(null); //since no clients added, just pass null and client id will replace with null,resulting create a white list for the candidate
    }
  };

  useDidMountEffect(() => {
    // validateInputs();
    return () => { };
  }, [whiteListPreference, selectedPrefferedClient, selectedItems]);

  const removeWhiteListedDocs = (client) => {
    return new Promise((resolve, reject) => {
      const filteredItems = previousSavedItemList.filter(
        (item) => !selectedItems.includes(item)
      );

      // Check if there are items to delete
      if (filteredItems.length === 0) {
        // No items to delete, resolve immediately
        resolve();
        return;
      }

      // Iterate through selectedItems for each client and create Axios promises
      const deletePromises = filteredItems.map((item) => {
        return axios.delete(HOSTMexxar + "frameworkDocWhitelist/" + item.id);
      });

      // Wait for all delete requests to complete
      Promise.all(deletePromises)
        .then(() => {
          // Deletion completed, resolve the outer promise
          resolve();
        })
        .catch((error) => {
          // Deletion failed, reject the outer promise with the error
          reject(error);
        });
    });
  };

  const getWhiteList = (candidateId, type) => {
    setWhiteListLoading(true);
    setSelectAll(false);
    setRemoveAll(false);
    console.log("Previous Saved Item List is being reset:", []);
    setPreviousSavedItemList([]);

    let url = "";

    if (type === "CANDIDATE") {
      url =
        HOSTMexxar +
        "frameworkDocWhitelist/filter?candidateId=" +
        candidateId +
        "&type=" +
        type +
        "&candidateBandId=" +
        props?.candidateData?.candidateBand?.id +
        "&candidateSpecialityId=" +
        props?.changedSpecility +
        "&candidateRoleId=" +
        props?.candidateRole +
        "&frameworkId=" +
        props?.changedFramework +
        "&levelId=1";
    } else if (type === "CLIENT") {
      url =
        HOSTMexxar +
        "frameworkDocWhitelist/filter?&type=" +
        type +
        "&clientId=" +
        selectedPrefferedClient?.id +
        "&candidateBandId=" +
        props?.candidateData?.candidateBand?.id +
        "&candidateSpecialityId=" +
        props?.changedSpecility +
        "&candidateRoleId=" +
        props?.candidateRole +
        "&frameworkId=" +
        selectedPrefferedClient?.framework?.id +
        "&levelId=1";
    }

    axios
      .get(url, { params: { offset: 200 } })
      .then((res) => {
        let docs = res?.data?.body.content.map(function (item) {
          return { id: item.id, rowId: item.document.id };
        });

        setPreviousSavedItemList(docs);
        setSelectedItems(docs);
        setWhiteListLoading(false);
      })
      .catch((error) => {
        setWhiteListLoading(false);
        ToastCommon(true, "error", addToast);
      });
  };




  useDidMountEffect(() => {
    if (showWhiteLabeling) {
      if (whiteListPreference === "CLIENT" && selectedPrefferedClient) {
        getWhiteList(props.candidateId, whiteListPreference);
      } else if (whiteListPreference === "CANDIDATE") {
        getWhiteList(props.candidateId, whiteListPreference);
      }
    }
  }, [
    whiteListPreference,
    selectedPrefferedClient,
    showWhiteLabeling,
    props.changedSpecility,
    props.changeFramework,
  ]);



  return (
    <div className="row">
      {props.certificateData.map((item, i) => {
        return (
          <div className="col-md-12" key={i}>
            <div key={i} className="card">
              <div className="card-header">
                <div>
                  <div className="d-flex align-items-center ">
                    <h1>Checks </h1>
                    <Badge color="warning" className="mx-3">
                      Beta
                    </Badge>
                  </div>
                  <Form>
                    <FormGroup>
                      <Row>
                        <Col sm={12} md={4}>
                          <Label>DBS</Label>
                        </Col>
                        <Col
                          sm={12}
                          md={2}
                          className="d-flex flex-column align-items-center"
                        >
                          <Label>Status</Label>
                          <Badge color="success">
                            {!isNull(props.candidateData.complianceDbsCheck)
                              ? !isNull(
                                props.candidateData.complianceDbsCheck
                                  ?.dbsCheckStatus
                              )
                                ? props.candidateData.complianceDbsCheck
                                  ?.dbsCheckStatus
                                : "N/A"
                              : "N/A"}
                          </Badge>
                        </Col>
                        <Col
                          sm={12}
                          md={2}
                          className="d-flex flex-column align-items-center"
                        >
                          <Label>Reg no</Label>
                          <Label>
                            {!isNull(props.candidateData.complianceDbsCheck)
                              ? !isNull(
                                props.candidateData.complianceDbsCheck
                                  ?.dbsReference
                              )
                                ? props.candidateData.complianceDbsCheck
                                  ?.dbsReference
                                : "N/A"
                              : "N/A"}
                          </Label>
                        </Col>
                        <Col
                          sm={12}
                          md={2}
                          className="d-flex flex-column align-items-center"
                        >
                          <Label>Last Check Date</Label>
                          <Label>
                            {!isNull(props.candidateData.complianceDbsCheck)
                              ? !isNull(
                                props.candidateData.complianceDbsCheck
                                  ?.dbsCheckDate
                              )
                                ? moment(
                                  props.candidateData.complianceDbsCheck
                                    ?.dbsCheckDate
                                ).format("YYYY-MM-DD")
                                : "N/A"
                              : "N/A"}
                          </Label>
                        </Col>
                        <Col
                          sm={12}
                          md={2}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Button
                            disabled={userType === "EMPLOYEE" ? false : true}
                            onClick={() =>
                              setShowModalAddCheckDetails({
                                bool: true,
                                type: "DBS",
                              })
                            }
                            color="success"
                          >
                            Check
                          </Button>
                          <Button
  className="float-right ml-2"
  disabled={userType === "EMPLOYEE" ? false : true}
  onClick={() =>
    setShowModalHiistoryCheckDetails({
      bool: true,
      type: "DBS",  
    })
  }
  color="success"
>
  History
</Button>

                        </Col>
                      </Row>
                    </FormGroup>
                    <FormGroup>
                      <Row>
                        <Col sm={12} md={changedCheck === "NMC" ? 2 : 4}>
                          <Label>NMC/GMC/HCPC</Label>
                          <Input
                            type="select"
                            id="changedCheck"
                            name="changedCheck"
                            value={changedCheck}
                            onChange={changeCheck}
                          >
                            <option
                              value={"NMC"}
                              key={"NMC"}
                              style={{ color: "#6A6767" }}
                            >
                              NMC
                            </option>
                            <option
                              value={"HCPC"}
                              key={"HCPC"}
                              style={{ color: "#6A6767" }}
                            >
                              HCPC
                            </option>
                            <option
                              value={"GMC"}
                              key={"GMC"}
                              style={{ color: "#6A6767" }}
                            >
                              GMC
                            </option>
                          </Input>
                        </Col>
                        {changedCheck === "NMC" && (
                          <Col
                            sm={12}
                            md={2}
                            className="d-flex flex-column align-items-center"
                          >
                            <Label>Check Status</Label>

                            <Badge color="success">
                              {!isNull(props.candidateData.complianceNmcCheck)
                                ? !isNull(
                                  props.candidateData.complianceNmcCheck
                                    ?.nmcSendStatus
                                )
                                  ? props.candidateData.complianceNmcCheck
                                    ?.nmcSendStatus
                                  : "N/A"
                                : "N/A"}
                            </Badge>
                          </Col>
                        )}
                        <Col
                          sm={12}
                          md={2}
                          className="d-flex flex-column align-items-center"
                        >
                          <Label>Valid Status</Label>
                          {changedCheck === "NMC" &&
                            props.candidateData.complianceNmcCheck?.statusItems
                            ? props.candidateData.complianceNmcCheck?.statusItems?.map(
                              (status) => {
                                return (
                                  <div className="d-flex ">
                                    <Label className="mx-2">
                                      {status.qualification}
                                    </Label>
                                    <Badge
                                      // color={`${
                                      //   !isNull(status.status) &&
                                      //   status.status === "INVALID"
                                      //     ? "danger"
                                      //     : "success"
                                      // }`}
                                      color="success"
                                      className="mb-1"
                                    >
                                      {!isNull(status)
                                        ? !isNull(status.status)
                                          ? status.status
                                          : "N/A"
                                        : "N/A"}
                                    </Badge>
                                  </div>
                                );
                              }
                            )
                            : changedCheck === "NMC" && (
                              <Badge color="danger">N/A</Badge>
                            )}

                          {changedCheck === "HCPC" && (
                            <Badge color="success">
                              {!isNull(props.candidateData.complianceHcpcCheck)
                                ? !isNull(
                                  props.candidateData.complianceHcpcCheck
                                    ?.hcpcCheckStatus
                                )
                                  ? props.candidateData.complianceHcpcCheck
                                    ?.hcpcCheckStatus
                                  : "N/A"
                                : "N/A"}
                            </Badge>
                          )}
                          {changedCheck === "GMC" && (
                            <Badge color="success">
                              {!isNull(props.candidateData.complianceGmcCheck)
                                ? !isNull(
                                  props.candidateData.complianceGmcCheck
                                    ?.gmcCheckStatus
                                )
                                  ? props.candidateData.complianceGmcCheck
                                    ?.gmcCheckStatus
                                  : "N/A"
                                : "N/A"}
                            </Badge>
                          )}
                        </Col>
                        <Col
                          sm={12}
                          md={2}
                          className="d-flex flex-column align-items-center"
                        >
                          <Label>Reg no</Label>
                          {changedCheck === "NMC" && (
                            <Label color="success">
                              {!isNull(props.candidateData.complianceNmcCheck)
                                ? !isNull(
                                  props.candidateData.complianceNmcCheck
                                    ?.nmcPin
                                )
                                  ? props.candidateData.complianceNmcCheck
                                    ?.nmcPin
                                  : "N/A"
                                : "N/A"}
                            </Label>
                          )}
                          {changedCheck === "HCPC" && (
                            <Label color="success">
                              {!isNull(props.candidateData.complianceHcpcCheck)
                                ? !isNull(
                                  props.candidateData.complianceHcpcCheck
                                    ?.hcpcRegistrationNumber
                                )
                                  ? props.candidateData.complianceHcpcCheck
                                    ?.hcpcRegistrationNumber
                                  : "N/A"
                                : "N/A"}
                            </Label>
                          )}
                          {changedCheck === "GMC" && (
                            <Label color="success">
                              {!isNull(props.candidateData.complianceGmcCheck)
                                ? !isNull(
                                  props.candidateData.complianceGmcCheck
                                    ?.gmcCheckStatus
                                )
                                  ? props.candidateData.complianceGmcCheck
                                    ?.gmcCheckStatus
                                  : "N/A"
                                : "N/A"}
                            </Label>
                          )}
                        </Col>
                        <Col
                          sm={12}
                          md={2}
                          className="d-flex flex-column align-items-center"
                        >
                          <Label>Last Check Date</Label>

                          {changedCheck === "NMC" && (
                            <Label color="success">
                              {!isNull(props.candidateData.complianceNmcCheck)
                                ? !isNull(
                                  props.candidateData.complianceNmcCheck
                                    ?.nmcCheckDate
                                )
                                  ? moment(
                                    props.candidateData.complianceNmcCheck
                                      ?.nmcCheckDate
                                  ).format("YYYY-MM-DD")
                                  : "N/A"
                                : "N/A"}
                            </Label>
                          )}
                          {changedCheck === "HCPC" && (
                            <Label color="success">
                              {!isNull(props.candidateData.complianceHcpcCheck)
                                ? !isNull(
                                  props.candidateData.complianceHcpcCheck
                                    ?.hcpcCheckDate
                                )
                                  ? moment(
                                    props.candidateData.complianceHcpcCheck
                                      ?.hcpcCheckDate
                                  ).format("YYYY-MM-DD")
                                  : "N/A"
                                : "N/A"}
                            </Label>
                          )}
                          {changedCheck === "GMC" && (
                            <Label color="success">
                              {!isNull(props.candidateData.complianceGmcCheck)
                                ? !isNull(
                                  props.candidateData.complianceGmcCheck
                                    ?.gmcCheckDate
                                )
                                  ? moment(
                                    props.candidateData.complianceGmcCheck
                                      ?.gmcCheckDate
                                  ).format("YYYY-MM-DD")
                                  : "N/A"
                                : "N/A"}
                            </Label>
                          )}
                        </Col>
                        <Col
                          sm={12}
                          md={2}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <Button
                            disabled={userType === "EMPLOYEE" ? false : true}
                            onClick={() =>
                              setShowModalAddCheckDetails({
                                bool: true,
                                type: changedCheck,
                              })
                            }
                            color="success"
                          >
                            Check
                          </Button>
                        
                      
                          <Button
  className="float-right ml-2"
  disabled={userType === "EMPLOYEE" ? false : true}
  onClick={() => {
    if (changedCheck !== "GMC") {
      setShowModalHiistoryCheckDetails({
        bool: true,
        type: changedCheck,
      });
    }
  }}
  color="success"
>
  History
</Button>
                        </Col>
                        
                      </Row>
                    </FormGroup>
                  </Form>
                </div>
                <hr />
                <div className="m-2 p-2">
                  <Label className="my-2">
                    White list candidate's documents for compliance and booking
                    eligibility
                  </Label>
                  <Badge color="warning" className="mx-2">
                    Beta
                  </Badge>
                  <Button
                    className="float-right mr-2"
                    color="success"
                    onClick={() => {
                      setShowWhiteLabeling(!showWhiteLabeling);
                      setWhiteListPreference("CANDIDATE");
                    }}
                  >
                    White List
                  </Button>
                  {showWhiteLableDescription ? (
                    <FontAwesomeIcon
                      id="whiteLableInfo"
                      className="btn-raised no-shadow pointer mx-2"
                      icon={faChevronCircleUp}
                      onClick={() => setShowWhiteLableDescription(false)}
                    ></FontAwesomeIcon>
                  ) : (
                    <FontAwesomeIcon
                      id="whiteLableInfo"
                      className="btn-raised no-shadow pointer mx-2"
                      icon={faChevronCircleDown}
                      onClick={() => setShowWhiteLableDescription(true)}
                    ></FontAwesomeIcon>
                  )}
                  <UncontrolledTooltip target={"whiteLableInfo"}>
                    How to use this feature
                  </UncontrolledTooltip>
                  {showWhiteLableDescription && (
                    <Alert className="mt-4">
                      <b>Note: </b> Please select documents needed for candidate
                      percentage calculation
                      <b>(Select By Candidate)</b> and booking eligibility
                      <b>(Select By Client)</b> .
                      <br />
                      <br />
                      <small>
                        <b> By Candidate:</b> This feature allows you to
                        configure required documents affecting the candidate's
                        profile percentage. By changing the whitelist, you can
                        define which documents are necessary to affect the
                        candidate's profile percentage.
                      </small>
                      <br />
                      <br />
                      <small>
                        <b> By Client:</b> This feature enables you to control
                        the booking eligibility of candidates for each client.
                        You can select which documents are needed to present the
                        candidate in the booking workspace and allow booking
                        consultants to book shifts for the candidate.
                      </small>
                    </Alert>
                  )}
                  {showWhiteLabeling && (
                    <Form>
                      <FormGroup>
                        <Row>
                          <Col sm={12} md={6}>
                            <FormGroup check>
                              <Input
                                className="pointer"
                                name="radio1"
                                type="radio"
                                defaultChecked={
                                  whiteListPreference === "CANDIDATE"
                                }
                                onChange={() =>
                                  setWhiteListPreference("CANDIDATE")
                                }
                              />
                              <Label check>By Candidate</Label>
                            </FormGroup>
                          </Col>
                          <Col sm={12} md={6}>
                            <FormGroup check>
                              <Input
                                className="pointer"
                                name="radio1"
                                type="radio"
                                onChange={(data) =>
                                  setWhiteListPreference("CLIENT")
                                }
                              />
                              <Label check>By Client</Label>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} md={12}>
                            <FormGroup check>
                              <Input
                                className="pointer"
                                name="selectAll"
                                type="checkbox"
                                checked={selectAll}
                                onChange={toggleSelectAllDocs}
                              />
                              <Label check>Select All Documents</Label>
                            </FormGroup>
                          </Col>{" "}
                          {/* <Col sm={12} md={12}>
                            <FormGroup check>
                              <Input
                                className="pointer"
                                name="removeAll"
                                type="checkbox"
                                checked={removeAll}
                                onChange={toggleRemoveAllDocs}
                              />
                              <Label check>Remove All Documents</Label>
                            </FormGroup>
                          </Col> */}
                          <Col sm={12} md={12}>
                            <FormGroup check>
                              <Input
                                className="pointer"
                                name="removeAll"
                                type="checkbox"
                                checked={removeAll}
                                onChange={toggleRemoveAllDocs}
                              />
                              <Label check>Remove All Documents</Label>
                              {removeAll && (
                                <Alert color="warning" className="m-2">
                                  Please note that you can only remove all selected whitelist documents by choosing the 'Remove All' option.
                                </Alert>
                              )}
                            </FormGroup>
                          </Col>{" "}
                        </Row>
                      </FormGroup>
                      {whiteListPreference === "CLIENT" && (
                        <FormGroup>
                          <Row>
                            <Col sm={12} md={6}>
                              <Label>Select Preffered Client</Label>
                            </Col>
                            <Col sm={12} md={6}>
                              <Select
                                // isMulti
                                name="prefferedClients"
                                id="prefferedClients"
                                // styles={colourStyles}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                onChange={(data) => {
                                  setSelectedPrefferedClient(data);
                                }}
                                value={selectedPrefferedClient}
                                options={prefferedClientsArray}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                      )}

                      {whilteLableError.bool && (
                        <Alert color="warning">
                          {whilteLableError.message}
                        </Alert>
                      )}
                      {whiteListLoading && (
                        <Alert color="warning">
                          White list loading.Please wait ...
                        </Alert>
                      )}

                      <FormGroup>

                        <Button
                          // onClick={addWhiteList}
                          onClick={() => setShowModalTemplate(true)}
                          color="success"
                          className="float-right ml-2"
                        >
                          Template
                        </Button>
                      </FormGroup>
                      {/* </Link> */}

                      <FormGroup>
                        {/* <Button
                          onClick={addWhiteList}
                          color="success"
                          className="float-right  ml-2"
                        >
                          Set Default Combinations
                        </Button> */}
                        <Button
                          onClick={addWhiteList}
                          color="success"
                          className="float-right "
                        >
                          Save
                        </Button>
                      </FormGroup>
                    </Form>
                  )}
                </div>
                <hr className=" my-2 mt-4" />
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <h1>{item.levelName}</h1>
                  </div>
                  <div>
                    <Button
                      onClick={() => getAllDocsTodownload()}
                      color="success"
                    >
                      <FontAwesomeIcon
                        icon={faFileDownload}
                        className="mr-2"
                      ></FontAwesomeIcon>
                      Download All Documents
                    </Button>
                  </div>
                </div>
                <div>
                  {" "}
                  <Label>Overview Summary : </Label>
                  <span
                    style={{ marginLeft: "5px" }}
                    className="badge badge-circle text-info mr-1"
                    data-original-title="Required"
                    data-placement="top"
                    data-toggle="tooltip"
                  ></span>
                  <label>
                    Total : {"       "}
                    {item.uploadedDocsCount + item.pendingDocsCount}
                    {"       "}
                    {" | "}
                  </label>
                  <span
                    style={{ marginLeft: "5px" }}
                    className="badge badge-circle text-success mr-1"
                    data-original-title="Required"
                    data-placement="top"
                    data-toggle="tooltip"
                  ></span>
                  <label>
                    Uploaded : {item.uploadedDocsCount}
                    {"       "}
                    {" | "}
                  </label>
                  <span
                    style={{ marginLeft: "5px" }}
                    className="badge badge-circle"
                    data-original-title="Required"
                    data-placement="top"
                    data-toggle="tooltip"
                  ></span>{" "}
                  <label>
                    Pending : {item.pendingDocsCount}
                    {"       "} {" | "}
                  </label>
                  <span
                    style={{ marginLeft: "5px" }}
                    className="badge badge-circle text-danger"
                    data-original-title="Required"
                    data-placement="top"
                    data-toggle="tooltip"
                  ></span>{" "}
                  <label>
                    Expired : {item.expDocsCount}
                    {"       "}
                  </label>
                </div>{" "}
                <Row className="justify-content-between">
                  <Col sm={8} className="form-inline p-2">
                    <Button
                      // onClick={() => setIsOpenFilter(!isOpenFilter)}
                      className="btn bg-dark-lt text-dark"
                      disabled={true}
                    >
                      <FunnelIcon />
                      <span className="mx-1">Filter</span>
                    </Button>

                    <Input
                      onChange={(e) => handleSearchOnChange(e)}
                      type="text"
                      id="searchBar"
                      className="form-control no-border no-shadow no-bg typeahead tt-input"
                      placeholder="Search Documents..."
                      autoComplete="off"
                      spellCheck="false"
                      dir="auto"
                      style={{
                        position: "relative",
                        verticalAlign: "top",
                        backgroundColor: "transparent",
                        // textTransform: "lowercase",
                      }}
                    />
                  </Col>
                  <Col sm={4} className="d-flex justify-content-center align-items-center">
                    <Label className="mr-2 mt-2" for="candidateSpecialityId">Type</Label>
                    <Input
                      type="select"
                      id="candidateSpecialityId"
                      name="candidateSpecialityId"
                      value={selectedType}
                      onChange={(event) => {
                        const value = event.target.value;
                        setSelectedType(value);
                        saveFilters("selectedTypeCompliance", value);
                      }}
                    >
                      <option value="">All</option>
                      <option value="MANDATORY">Mandatory</option>
                      <option value="IF_APPLICABLE">If Applicable</option>
                    </Input>
                  </Col>
                </Row>
                <br></br>
                <Row>
                  <Col sm={12} className="d-flex justify-content-end mr-3">
                    <Label className="mr-4" for="rememberFiltersCandidatePage">Remember</Label>
                    <Input
                      className="mx-2 pointer"
                      type="checkbox"
                      value="rememberFiltersCandidatePage"
                      checked={remember}
                      onChange={() => {
                        setRemember(!remember);
                        saveFilters(
                          "rememberFiltersCompliance",
                          !remember
                        );
                      }}
                    />
                  </Col>
                </Row>



                <br />
                <div>
                  <Label>Whitelisted Document Summary : </Label>
                  <span
                    style={{ marginLeft: "5px" }}
                    className="badge badge-circle text-info mr-1"
                    data-original-title="Required"
                    data-placement="top"
                    data-toggle="tooltip"
                  ></span>
                  <label>
                    Total : {"       "}
                    {WhiteListUploadedDocsCount + WhiteListPendingDocsCount}
                    {"       "}
                    {" | "}
                  </label>
                  <span
                    style={{ marginLeft: "5px" }}
                    className="badge badge-circle text-success mr-1"
                    data-original-title="Required"
                    data-placement="top"
                    data-toggle="tooltip"
                  ></span>
                  <label>
                    Uploaded :{WhiteListUploadedDocsCount}
                    {"       "}
                    {" | "}
                  </label>
                  <span
                    style={{ marginLeft: "5px" }}
                    className="badge badge-circle"
                    data-original-title="Required"
                    data-placement="top"
                    data-toggle="tooltip"
                  ></span>{" "}
                  <label>
                    Pending : {WhiteListPendingDocsCount}
                    {"       "} {" | "}
                  </label>
                  <span
                    style={{ marginLeft: "5px" }}
                    className="badge badge-circle text-danger"
                    data-original-title="Required"
                    data-placement="top"
                    data-toggle="tooltip"
                  ></span>{" "}
                  <label>
                    Expired : {WhiteListExpDocsCount}
                    {"       "}
                  </label>
                  <FontAwesomeIcon
                    className="mx-2 mt-1 pointer"
                    icon={faInfoCircle}
                    id="whiteListDocSummary"
                  ></FontAwesomeIcon>
                  <UncontrolledTooltip target={"whiteListDocSummary"}>
                    This information is provided based on the selected
                    Role/Band/Specialty of the candidate, and changing them
                    would yield different results.
                  </UncontrolledTooltip>
                </div>
              </div>

              <div class="d-flex flex-row bd-highlight mx-12">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab === "all" ? " active" : ""}
                      onClick={() => toggleView("all")}
                    >
                      All
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={activeTab === "training" ? "active" : ""}
                      onClick={() => toggleView("training")}
                    >
                      Trainings
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={activeTab === "rtw" ? "active" : ""}
                      onClick={() => toggleView("rtw")}
                    >
                      RTW
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={activeTab === "immu" ? "active" : ""}
                      onClick={() => toggleView("immu")}
                    >
                      Immunisation
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "other" ? "active" : ""}
                      onClick={() => toggleView("other")}
                    >
                      General
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={activeTab === "ref" ? "active" : ""}
                      onClick={() => toggleView("ref")}
                    >
                      Reference
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "ftw" ? "active" : ""}
                      onClick={() => toggleView("ftw")}
                    >
                      FTW
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <div className="card-body">

                {showTrainingView && (
                  <div>
                    {filterByName(searchQuery, item?.certificates, selectedType)
                      .lengthofSearch > 0 && (
                        <div>
                          {filterByName(
                            searchQuery,
                            item?.certificates,
                            selectedType
                          ).filtered.reduce((uniqueCertificates, certificate) => {
                            if (
                              certificate.certificateName.endsWith("Training") &&
                              !uniqueCertificates.some(
                                (c) => c.id === certificate.id
                              )
                            ) {
                              uniqueCertificates.push(certificate);
                            }
                            return uniqueCertificates;
                          }, []).length > 0 ? (
                            <>
                              {filterByName(
                                searchQuery,
                                item?.certificates,
                                selectedType
                              )
                                .filtered.reduce(
                                  (uniqueCertificates, certificate) => {
                                    if (
                                      certificate.certificateName.endsWith(
                                        "Training"
                                      ) &&
                                      isSelected(certificate.id) &&
                                      !uniqueCertificates.some(
                                        (c) => c.id === certificate.id
                                      )
                                    ) {
                                      uniqueCertificates.push(certificate);
                                    }
                                    return uniqueCertificates;
                                  },
                                  []
                                )
                                .map((certificate, j) => (
                                  <div
                                    style={{
                                      borderLeft: "2px solid yellow",
                                      height: "100px",
                                      marginLeft: "10px",
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "-6px",
                                        transform: "translateY(-50%)",
                                        width: "12px",
                                        height: "12px",
                                        borderRadius: "50%",
                                        backgroundColor: isSelected(
                                          certificate.id
                                        )
                                          ? "yellow"
                                          : "yellow",
                                      }}
                                    ></div>
                                    <DocSummaryCard
                                      key={j}
                                      certificate={certificate}
                                      props={props}
                                      onItemSelect={onItemSelect}
                                      getDocID={getDocID}
                                    />
                                  </div>
                                ))}

                              {filterByName(
                                searchQuery,
                                item?.certificates,
                                selectedType
                              )
                                .filtered.reduce(
                                  (uniqueCertificates, certificate) => {
                                    if (
                                      certificate.certificateName.endsWith(
                                        "Training"
                                      ) &&
                                      !isSelected(certificate.id) &&
                                      !uniqueCertificates.some(
                                        (c) => c.id === certificate.id
                                      )
                                    ) {
                                      uniqueCertificates.push(certificate);
                                    }
                                    return uniqueCertificates;
                                  },
                                  []
                                )
                                .map((certificate, j) => (
                                  <DocSummaryCard
                                    key={j}
                                    certificate={certificate}
                                    props={props}
                                    onItemSelect={onItemSelect}
                                    getDocID={getDocID}
                                  />
                                ))}
                            </>
                          ) : (
                            <NoSearchResults />
                          )}
                        </div>
                      )}
                  </div>
                )}
                {showAllView && (
                  <>
                    {filterByName(searchQuery, item?.certificates, selectedType)
                      .filtered.length > 0 ? (
                      <>
                        {[
                          ...new Set(
                            filterByName(
                              searchQuery,
                              item?.certificates,
                              selectedType
                            ).filtered.map((document) => document.id)
                          ),
                        ].map((documentId) =>
                          isSelected(documentId) ? (
                            <div
                              style={{
                                borderLeft: "2px solid yellow",
                                height: "100px",
                                marginLeft: "10px",
                                position: "relative",
                              }}
                            >
                              <div
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "-6px",
                                  transform: "translateY(-50%)",
                                  width: "12px",
                                  height: "12px",
                                  borderRadius: "50%",
                                  backgroundColor: isSelected(document.id)
                                    ? "yellow"
                                    : "yellow",
                                }}
                              ></div>
                              <DocSummaryCard
                                key={documentId}
                                certificate={filterByName(
                                  searchQuery,
                                  item?.certificates,
                                  selectedType
                                ).filtered.find((doc) => doc.id === documentId)}
                                props={props}
                                onItemSelect={onItemSelect}
                                getDocID={getDocID}
                              />
                            </div>
                          ) : null
                        )}

                        {[
                          ...new Set(
                            filterByName(
                              searchQuery,
                              item?.certificates,
                              selectedType
                            ).filtered.map((document) => document.id)
                          ),
                        ].map((documentId) =>
                          !isSelected(documentId) ? (
                            <DocSummaryCard
                              key={documentId}
                              certificate={filterByName(
                                searchQuery,
                                item?.certificates,
                                selectedType
                              ).filtered.find((doc) => doc.id === documentId)}
                              props={props}
                              onItemSelect={onItemSelect}
                              getDocID={getDocID}
                            />
                          ) : null
                        )}
                      </>
                    ) : (
                      <NoSearchResults />
                    )}
                  </>
                )}

                {showImmuView && (
                  <div>
                    {filterByName(searchQuery, item?.certificates, selectedType)
                      .lengthofSearch > 0 && (
                        <div>
                          {filterByName(
                            searchQuery,
                            item?.certificates,
                            selectedType
                          ).filtered.reduce((uniqueCertificates, certificate) => {
                            if (
                              certificate.certificateName.endsWith(
                                "Immunisation"
                              ) &&
                              !uniqueCertificates.some(
                                (c) => c.id === certificate.id
                              )
                            ) {
                              uniqueCertificates.push(certificate);
                            }
                            return uniqueCertificates;
                          }, []).length > 0 ? (
                            <>
                              {filterByName(
                                searchQuery,
                                item?.certificates,
                                selectedType
                              )
                                .filtered.reduce(
                                  (uniqueCertificates, certificate) => {
                                    if (
                                      certificate.certificateName.endsWith(
                                        "Immunisation"
                                      ) &&
                                      isSelected(certificate.id) &&
                                      !uniqueCertificates.some(
                                        (c) => c.id === certificate.id
                                      )
                                    ) {
                                      uniqueCertificates.push(certificate);
                                    }
                                    return uniqueCertificates;
                                  },
                                  []
                                )
                                .map((certificate, j) => (
                                  <div
                                    style={{
                                      borderLeft: "2px solid yellow",
                                      height: "100px",
                                      marginLeft: "10px",
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "-6px",
                                        transform: "translateY(-50%)",
                                        width: "12px",
                                        height: "12px",
                                        borderRadius: "50%",
                                        backgroundColor: isSelected(
                                          certificate.id
                                        )
                                          ? "yellow"
                                          : "yellow",
                                      }}
                                    ></div>
                                    <DocSummaryCard
                                      key={j}
                                      certificate={certificate}
                                      props={props}
                                      onItemSelect={onItemSelect}
                                      getDocID={getDocID}
                                    />
                                  </div>
                                  // <DocSummaryCard
                                  //   key={j}
                                  //   certificate={certificate}
                                  //   props={props}
                                  //   onItemSelect={onItemSelect}
                                  //   getDocID={getDocID}
                                  // />
                                ))}

                              {filterByName(
                                searchQuery,
                                item?.certificates,
                                selectedType
                              )
                                .filtered.reduce(
                                  (uniqueCertificates, certificate) => {
                                    if (
                                      certificate.certificateName.endsWith(
                                        "Immunisation"
                                      ) &&
                                      !isSelected(certificate.id) &&
                                      !uniqueCertificates.some(
                                        (c) => c.id === certificate.id
                                      )
                                    ) {
                                      uniqueCertificates.push(certificate);
                                    }
                                    return uniqueCertificates;
                                  },
                                  []
                                )
                                .map((certificate, j) => (
                                  <DocSummaryCard
                                    key={j}
                                    certificate={certificate}
                                    props={props}
                                    onItemSelect={onItemSelect}
                                    getDocID={getDocID}
                                  />
                                ))}
                            </>
                          ) : (
                            <NoSearchResults />
                          )}
                        </div>
                      )}
                  </div>
                )}

                {showFTWView && (
                  <div>
                    {filterByName(searchQuery, item?.certificates, selectedType)
                      .lengthofSearch > 0 && (
                        <div>
                          {filterByName(
                            searchQuery,
                            item?.certificates,
                            selectedType
                          ).filtered.reduce((uniqueCertificates, certificate) => {
                            if (
                              (certificate.certificateName.includes("Fitness") ||
                                certificate.certificateName.includes("FTW")) &&
                              !uniqueCertificates.some(
                                (c) => c.id === certificate.id
                              )
                            ) {
                              uniqueCertificates.push(certificate);
                            }
                            return uniqueCertificates;
                          }, []).length > 0 ? (
                            <>
                              {filterByName(
                                searchQuery,
                                item?.certificates,
                                selectedType
                              )
                                .filtered.reduce(
                                  (uniqueCertificates, certificate) => {
                                    if (
                                      (certificate.certificateName.includes(
                                        "Fitness"
                                      ) ||
                                        certificate.certificateName.includes(
                                          "FTW"
                                        )) &&
                                      isSelected(certificate.id) &&
                                      !uniqueCertificates.some(
                                        (c) => c.id === certificate.id
                                      )
                                    ) {
                                      uniqueCertificates.push(certificate);
                                    }
                                    return uniqueCertificates;
                                  },
                                  []
                                )
                                .map((certificate, j) => (
                                  <DocSummaryCard
                                    key={j}
                                    certificate={certificate}
                                    props={props}
                                    onItemSelect={onItemSelect}
                                    getDocID={getDocID}
                                  />
                                ))}

                              {filterByName(
                                searchQuery,
                                item?.certificates,
                                selectedType
                              )
                                .filtered.reduce(
                                  (uniqueCertificates, certificate) => {
                                    if (
                                      (certificate.certificateName.includes(
                                        "Fitness"
                                      ) ||
                                        certificate.certificateName.includes(
                                          "FTW"
                                        )) &&
                                      !isSelected(certificate.id) &&
                                      !uniqueCertificates.some(
                                        (c) => c.id === certificate.id
                                      )
                                    ) {
                                      uniqueCertificates.push(certificate);
                                    }
                                    return uniqueCertificates;
                                  },
                                  []
                                )
                                .map((certificate, j) => (
                                  <DocSummaryCard
                                    key={j}
                                    certificate={certificate}
                                    props={props}
                                    onItemSelect={onItemSelect}
                                    getDocID={getDocID}
                                  />
                                ))}
                            </>
                          ) : (
                            <NoSearchResults />
                          )}
                        </div>
                      )}
                  </div>
                )}

                {showRefView && (
                  <div>
                    {filterByName(searchQuery, item?.certificates, selectedType)
                      .lengthofSearch > 0 && (
                        <div>
                          {filterByName(
                            searchQuery,
                            item?.certificates,
                            selectedType
                          ).filtered.reduce((uniqueCertificates, certificate) => {
                            if (
                              certificate.certificateName.includes("Reference") &&
                              !uniqueCertificates.some(
                                (c) => c.id === certificate.id
                              )
                            ) {
                              uniqueCertificates.push(certificate);
                            }
                            return uniqueCertificates;
                          }, []).length > 0 ? (
                            <>
                              {filterByName(
                                searchQuery,
                                item?.certificates,
                                selectedType
                              )
                                .filtered.reduce(
                                  (uniqueCertificates, certificate) => {
                                    if (
                                      certificate.certificateName.includes(
                                        "Reference"
                                      ) &&
                                      isSelected(certificate.id) &&
                                      !uniqueCertificates.some(
                                        (c) => c.id === certificate.id
                                      )
                                    ) {
                                      uniqueCertificates.push(certificate);
                                    }
                                    return uniqueCertificates;
                                  },
                                  []
                                )
                                .map((certificate, j) => (
                                  // <DocSummaryCard
                                  <div
                                    style={{
                                      borderLeft: "2px solid yellow",
                                      height: "100px",
                                      marginLeft: "10px",
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "-6px",
                                        transform: "translateY(-50%)",
                                        width: "12px",
                                        height: "12px",
                                        borderRadius: "50%",
                                        backgroundColor: isSelected(
                                          certificate.id
                                        )
                                          ? "yellow"
                                          : "yellow",
                                      }}
                                    ></div>
                                    <DocSummaryCard
                                      key={j}
                                      certificate={certificate}
                                      props={props}
                                      onItemSelect={onItemSelect}
                                      getDocID={getDocID}
                                    />
                                  </div>
                                  //   key={j}
                                  //   certificate={certificate}
                                  //   props={props}
                                  //   onItemSelect={onItemSelect}
                                  //   getDocID={getDocID}
                                  // />
                                ))}

                              {filterByName(
                                searchQuery,
                                item?.certificates,
                                selectedType
                              )
                                .filtered.reduce(
                                  (uniqueCertificates, certificate) => {
                                    if (
                                      certificate.certificateName.includes(
                                        "Reference"
                                      ) &&
                                      !isSelected(certificate.id) &&
                                      !uniqueCertificates.some(
                                        (c) => c.id === certificate.id
                                      )
                                    ) {
                                      uniqueCertificates.push(certificate);
                                    }
                                    return uniqueCertificates;
                                  },
                                  []
                                )
                                .map((certificate, j) => (
                                  <DocSummaryCard
                                    key={j}
                                    certificate={certificate}
                                    props={props}
                                    onItemSelect={onItemSelect}
                                    getDocID={getDocID}
                                  />
                                ))}
                            </>
                          ) : (
                            <NoSearchResults />
                          )}
                        </div>
                      )}
                  </div>
                )}

                {showRTWView && (
                  <div>
                    {filterByName(searchQuery, item?.certificates, selectedType)
                      .lengthofSearch > 0 && (
                        <div>
                          {filterByName(
                            searchQuery,
                            item?.certificates,
                            selectedType
                          ).filtered.reduce((uniqueCertificates, certificate) => {
                            if (
                              certificate.certificateName.includes("RTW") &&
                              !certificate.certificateName.endsWith("Training") &&
                              !uniqueCertificates.some(
                                (c) => c.id === certificate.id
                              )
                            ) {
                              uniqueCertificates.push(certificate);
                            }
                            return uniqueCertificates;
                          }, []).length > 0 ? (
                            <>
                              {filterByName(
                                searchQuery,
                                item?.certificates,
                                selectedType
                              )
                                .filtered.reduce(
                                  (uniqueCertificates, certificate) => {
                                    if (
                                      certificate.certificateName.includes(
                                        "RTW"
                                      ) &&
                                      !certificate.certificateName.endsWith(
                                        "Training"
                                      ) &&
                                      isSelected(certificate.id) &&
                                      !uniqueCertificates.some(
                                        (c) => c.id === certificate.id
                                      )
                                    ) {
                                      uniqueCertificates.push(certificate);
                                    }
                                    return uniqueCertificates;
                                  },
                                  []
                                )
                                .map((certificate, j) => (
                                  <div
                                    style={{
                                      borderLeft: "2px solid yellow",
                                      height: "100px",
                                      marginLeft: "10px",
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "-6px",
                                        transform: "translateY(-50%)",
                                        width: "12px",
                                        height: "12px",
                                        borderRadius: "50%",
                                        backgroundColor: isSelected(
                                          certificate.id
                                        )
                                          ? "yellow"
                                          : "yellow",
                                      }}
                                    ></div>
                                    <DocSummaryCard
                                      key={j}
                                      certificate={certificate}
                                      props={props}
                                      onItemSelect={onItemSelect}
                                      getDocID={getDocID}
                                    />
                                  </div>

                                ))}

                              {filterByName(
                                searchQuery,
                                item?.certificates,
                                selectedType
                              )
                                .filtered.reduce(
                                  (uniqueCertificates, certificate) => {
                                    if (
                                      certificate.certificateName.includes(
                                        "RTW"
                                      ) &&
                                      !certificate.certificateName.endsWith(
                                        "Training"
                                      ) &&
                                      !isSelected(certificate.id) &&
                                      !uniqueCertificates.some(
                                        (c) => c.id === certificate.id
                                      )
                                    ) {
                                      uniqueCertificates.push(certificate);
                                    }
                                    return uniqueCertificates;
                                  },
                                  []
                                )
                                .map((certificate, j) => (
                                  <DocSummaryCard
                                    key={j}
                                    certificate={certificate}
                                    props={props}
                                    onItemSelect={onItemSelect}
                                    getDocID={getDocID}
                                  />
                                ))}
                            </>
                          ) : (
                            <NoSearchResults />
                          )}
                        </div>
                      )}
                  </div>
                )}

                {showOtherView && (
                  <div>
                    {filterByName(searchQuery, item?.certificates, selectedType)
                      .lengthofSearch > 0 && (
                        <div>
                          {filterByName(
                            searchQuery,
                            item?.certificates,
                            selectedType
                          ).filtered.reduce((uniqueCertificates, certificate) => {
                            if (
                              !certificate.certificateName.includes("Training") &&
                              !certificate.certificateName.includes(
                                "Reference"
                              ) &&
                              !certificate.certificateName.includes("RTW") &&
                              !certificate.certificateName.includes("FTW") &&
                              !certificate.certificateName.includes(
                                "Immunisation"
                              ) &&
                              !uniqueCertificates.some(
                                (c) => c.id === certificate.id
                              )
                            ) {
                              uniqueCertificates.push(certificate);
                            }
                            return uniqueCertificates;
                          }, []).length > 0 ? (
                            <>
                              {filterByName(
                                searchQuery,
                                item?.certificates,
                                selectedType
                              )
                                .filtered.reduce(
                                  (uniqueCertificates, certificate) => {
                                    if (
                                      !certificate.certificateName.includes(
                                        "Training"
                                      ) &&
                                      !certificate.certificateName.includes(
                                        "Reference"
                                      ) &&
                                      !certificate.certificateName.includes(
                                        "RTW"
                                      ) &&
                                      !certificate.certificateName.includes(
                                        "FTW"
                                      ) &&
                                      !certificate.certificateName.includes(
                                        "Immunisation"
                                      ) &&
                                      isSelected(certificate.id) &&
                                      !uniqueCertificates.some(
                                        (c) => c.id === certificate.id
                                      )
                                    ) {
                                      uniqueCertificates.push(certificate);
                                    }
                                    return uniqueCertificates;
                                  },
                                  []
                                )
                                .map((certificate, j) => (
                                  <div
                                    style={{
                                      borderLeft: "2px solid yellow",
                                      height: "100px",
                                      marginLeft: "10px",
                                      position: "relative",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "-6px",
                                        transform: "translateY(-50%)",
                                        width: "12px",
                                        height: "12px",
                                        borderRadius: "50%",
                                        backgroundColor: isSelected(
                                          certificate.id
                                        )
                                          ? "yellow"
                                          : "yellow",
                                      }}
                                    ></div>
                                    <DocSummaryCard
                                      key={j}
                                      certificate={certificate}
                                      props={props}
                                      onItemSelect={onItemSelect}
                                      getDocID={getDocID}
                                    />
                                  </div>
                                  // <DocSummaryCard
                                  //   key={j}
                                  //   certificate={certificate}
                                  //   props={props}
                                  //   onItemSelect={onItemSelect}
                                  //   getDocID={getDocID}
                                  // />
                                ))}

                              {filterByName(
                                searchQuery,
                                item?.certificates,
                                selectedType
                              )
                                .filtered.reduce(
                                  (uniqueCertificates, certificate) => {
                                    if (
                                      !certificate.certificateName.includes(
                                        "Training"
                                      ) &&
                                      !certificate.certificateName.includes(
                                        "Reference"
                                      ) &&
                                      !certificate.certificateName.includes(
                                        "RTW"
                                      ) &&
                                      !certificate.certificateName.includes(
                                        "FTW"
                                      ) &&
                                      !certificate.certificateName.includes(
                                        "Immunisation"
                                      ) &&
                                      !isSelected(certificate.id) &&
                                      !uniqueCertificates.some(
                                        (c) => c.id === certificate.id
                                      )
                                    ) {
                                      uniqueCertificates.push(certificate);
                                    }
                                    return uniqueCertificates;
                                  },
                                  []
                                )
                                .map((certificate, j) => (
                                  <DocSummaryCard
                                    key={j}
                                    certificate={certificate}
                                    props={props}
                                    onItemSelect={onItemSelect}
                                    getDocID={getDocID}
                                  />
                                ))}
                            </>
                          ) : (
                            <NoSearchResults />
                          )}
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
       <ModalComponent
  show={
    docsLoading.status &&
    docsLoading.type == "MULTIPLE" &&
    docsLoading.docType == "DEFAULT"
  }
>
<ModalComponent
        show={docsLoading.status && docsLoading.type === 'MULTIPLE' && docsLoading.docType === 'DEFAULT'}
      >
        <DocumentDownloading  onCancel={cancelDownload} />
      </ModalComponent>


</ModalComponent>
      <ModalComponent
        show={showModalAddCheckDetails.bool}
        header={showModalAddCheckDetails.type + " Online Check"}
        closeModal={() =>
          setShowModalAddCheckDetails({ bool: false, type: null })
        }
      >
        <CandidateOnlineChecksDataAdding
          resetCandidate={props.resetCandidate}
          consultantFirstName={consultantFirstName}
          consultantLastName={consultantLastName}
          candidateData={props.candidateData}
          type={showModalAddCheckDetails?.type}
          onClose={() =>
            setShowModalAddCheckDetails({ bool: false, type: null })
          }
        />
      </ModalComponent>

      

      <ModalComponent
        show={showModalHiistoryCheckDetails.bool}
        header={showModalHiistoryCheckDetails.type + " History Check"}
        closeModal={() =>
          setShowModalHiistoryCheckDetails({ bool: false, type: null })
        }
      >
        <CandidatesChecksHistory
          resetCandidate={props.resetCandidate}
          consultantFirstName={consultantFirstName}
          consultantLastName={consultantLastName}
          candidateData={props.candidateData}
          type={showModalHiistoryCheckDetails?.type}
          onClose={() =>
            setShowModalHiistoryCheckDetails({ bool: false, type: null })
          }
         
        />
      </ModalComponent>
      <ModalComponent
        show={showModalTemplate}
        header="White List Document Template"
        closeModal={() => setShowModalTemplate(false)}
        className=" modal-xl"
      >
        <WhiteListDoc
          // rowID={rowID}
          mode="Add"
          closeModal={() => setShowModalTemplate(false)}
          candidateId={candidateId}
          changedFramework={changedFramework}
          candidateRole={candidateRole}
          changedSpecility={changedSpecility}
          candidateBand={candidateBand}
          roleName={roleName}
          bandName={bandName}
          specialityName={specialityName}
          whiteListPreference={whiteListPreference}
          selectedPrefferedClient={selectedPrefferedClient?.id}

        />
      </ModalComponent>
    </div>
  );
};

const Compliance = (props) => {
  const {
    auth,
    resetCandidate,
    WhiteListExpDocsCount,
    WhiteListUploadedDocsCount,
    WhiteListPendingDocsCount,
  } = props;
  const { register, handleSubmit, errors } = useForm();
  const isMounted = useIsMounted();
  const MexxarApiSpeciality = CANDIDATE_SPECIALITY;
  const MexxarApiFramework = FRAMEWORK;
  const MexxarApiCandidate = REGISTER_CANDIDATE;
  const MexxarApiGetDocs = CONFIGURE_DOCS;
  const MexxarUrl = HOSTMexxar;

  const [candidateData, setCandidateData] = useState({});
  const [speciality, setSpeciality] = useState(null);
  const [framework, setFramework] = useState(null);
  const [changedFramework, setChangedFramework] = useState(1);
  const [changedSpecility, setChangedSpecility] = useState(1);
  const [specialityName, setSpecialityName] = useState(null);
  const [roleName, setRoleName] = useState(null);
  const [bandName, setBandName] = useState(null);
  const [candidateRole, setCandidateRole] = useState(null);
  const [candidateBand, setCandidateBand] = useState(null);
  const [certificateData, setCertificateData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [clientAppicationList, setClientAppicationList] = useState([]);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [docIds, setDocIds] = useState([]);
  const [potentialClients, setPotentialClients] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const { addToast } = useToasts();

  const [potentialClientError, setPotentialClientError] = useState(false);
  const [rememberCompliance, setRememberCompliance] = useState(false); /// <--------filter settings options saving and retreving/ if need activate later with below checkbox

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useDidMountEffect(() => {
    let currentClient = props.candidateData.currentlyEmployedAt;

    let currentClientInPotentialClient = [];

    if (currentClient) {
      currentClientInPotentialClient =
        potentialClients &&
        potentialClients.filter(function (client) {
          return client.id === currentClient.id;
        });

      if (
        currentClientInPotentialClient &&
        currentClientInPotentialClient.length > 0
      ) {
        setPotentialClientError(false);
      } else {
        setPotentialClientError(true);
      }
    }
    return () => { };
  }, [potentialClients]);

  const getSpecialiftiesAndFrameworkList = () => {
    let rememberTemp = localStorage.getItem("remember_CompliancePage_Selection");

    axios
      .get(MexxarApiCandidate + "/" + props.candidateId)
      .then((res) => {
        let frameworksTemp = [];

        if (res.data.body[0].preferredClients != undefined) {
          forEach(res.data.body[0].preferredClients, function (item) {
            frameworksTemp.push(item.framework);
          });
        }

        let frameworks = uniqBy(frameworksTemp, "id");

        let specialityTemp =
          res.data.body[0].candidateSpecialities.length === 0
            ? null
            : res.data.body[0].candidateSpecialities;



        if (rememberTemp == "true") {
          const framework = JSON.parse(localStorage.getItem("selected_Framework_CompliancePage")) || "";
          const speciality = JSON.parse(localStorage.getItem("selected_Speciality_CompliancePage")) || "";
          setChangedFramework(parseInt(framework));
          setChangedSpecility(parseInt(speciality));

        } else {
          setChangedFramework(frameworks[0].id);
          setChangedSpecility(specialityTemp[0].id);
        }



        setSpeciality(specialityTemp);
        setFramework(frameworks);
        setSpecialityName(specialityTemp[0]?.name);
        setRoleName(res.data.body[0]?.candidateRole?.name);
        setBandName(res.data.body[0]?.candidateBand?.name);
        setCandidateRole(res.data.body[0].candidateRole.id);
        setCandidateBand(res.data.body[0].candidateBand.id);
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  // *******************************************************************************************//

  const getPermanentDocuments = () => {
    setLoading(true);
    setError(false);
    axios
      .get(MexxarUrl + "permanent/permDocs/status/" + props.candidateId)
      .then((res2) => {
        let doc = res2.data.status === "success" ? res2.data.body : [];

        let data_List_01 = [];
        let A = [];

        let L1expDocs = filter(doc, (item) => {
          return moment(item.expireDate).isSameOrBefore(new Date());
        });

        let L1pendingDocs = filter(doc, (item) => {
          return item.expireDate === null;
        });

        let L1uploadedDocs = filter(doc, (item) => {
          return moment(new Date()).isSameOrBefore(item.expireDate);
        });

        const docStatus = (data) => {
          let expDate = moment(data);
          let today = new Date();

          let diffDays;

          if (data == null) {
            diffDays = "?";
          } else {
            diffDays = Math.floor((expDate - today) / 86400e3);
            // console.log("incoming docStatus data", diffDays);
          }
          // {
          //   data == null
          //     ? ()
          //     : ();
          // }

          let avatarColorCode;
          let avatarSymbol;
          switch (true) {
            case "?" === diffDays:
              avatarColorCode = "#607d8b";
              avatarSymbol = "?";
              // console.log("case 01 less than 0", diffDays);

              break;
            case 0 > diffDays:
              avatarColorCode = "#e91e63";
              avatarSymbol = "X";
              // console.log("case 01 less than 0", diffDays);

              break;

            case 0 == diffDays:
              avatarColorCode = "#e91e63";
              avatarSymbol = "1";
              // console.log("case 02 ...0 days left", diffDays);

              break;

            case 0 < diffDays && diffDays <= 30:
              avatarColorCode = "#ff9800";
              avatarSymbol = diffDays;
              //  console.log("case 03...between 0 and 30", diffDays);

              break;

            case 30 < diffDays && diffDays <= 45:
              avatarColorCode = "#cddc39";
              avatarSymbol = diffDays;
              // console.log("case 04...between 30 and 45 ", diffDays);

              break;
            case 45 < diffDays && diffDays <= 365000:
              avatarColorCode = "#31c971";
              avatarSymbol = diffDays;
              // console.log("case 05...between 45 and 365000", diffDays);

              break;

            default:
              break;
          }
          return {
            avatarColorCode: avatarColorCode,
            avatarSymbol: avatarSymbol,
          };
        };

        forEach(doc, function (item) {
          A.push({
            id: item.document.id,
            certificateName: item.document.name,
            expireDate: item.expireDate,
            issueDate: item.issueDate,
            documentLink: item.documentLink,
            confirmation: item.documentApprovedStatus,
            audition: item.documentApprovedStatus,
            documentApprovedStatus: item.documentApprovedStatus,
            avtarColor: docStatus(item.expireDate).avatarColorCode,
            daysLeft: docStatus(item.expireDate).avatarSymbol,
          });
        });

        data_List_01.push({
          //levelName: "Level 01", //if all 3 levels are activating use this instead
          levelName: "Documents",
          levelId: 1,
          color: "#456543",
          expDocsCount: L1expDocs.length,
          pendingDocsCount: L1pendingDocs.length,
          uploadedDocsCount: L1uploadedDocs.length,
          certificates: A,
        });
        setLoading(false);
        setError(false);
        setCertificateData(data_List_01);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  };

  // Temperary document list
  const getDocuments = () => {
    setLoading(true);
    axios
      .all([
        axios.get(
          MexxarApiCandidate +
          "/" +
          props.candidateId +
          "/documents/framework/" +
          changedFramework +
          "/role/" +
          candidateRole +
          "/speciality/" +
          changedSpecility
        ),

      ])

      .then(
        axios.spread((res, res2) => {
          if (isMounted.current) {


            //************only allowed 3 levels maximum *********************//
            let LEVEL1 = filter(res.data.body, (item) => {
              return item.levelId === 1;
            });
            // console.log("LEVEL 01................", LEVEL1);
            let LEVEL2 = filter(res.data.body, (item) => {
              return item.levelId === 2;
            });
            //   console.log("LEVEL 02................", LEVEL2);
            let LEVEL3 = filter(res.data.body, (item) => {
              return item.levelId === 3;
            });
            //   console.log("LEVEL 03................", LEVEL3);

            let A = [];
            let B = [];
            let C = [];

            //**********Level 1 docs******//

            let L1expDocs = filter(LEVEL1, (item) => {
              return moment(item.expireDate).isSameOrBefore(new Date());
            });

            let L1pendingDocs = filter(LEVEL1, (item) => {
              return item.expireDate === null;
            });

            let L1uploadedDocs = filter(LEVEL1, (item) => {
              return moment(new Date()).isSameOrBefore(item.expireDate);
            });

            //Level 2 docs//
            let L2expDocs = filter(LEVEL2, (item) => {
              return moment(item.expireDate).isSameOrBefore(new Date());
            });

            let L2pendingDocs = filter(LEVEL2, (item) => {
              return item.expireDate === null;
            });

            let L2uploadedDocs = filter(LEVEL2, (item) => {
              return moment(new Date()).isSameOrBefore(item.expireDate);
            });

            //********Level 3 docs***********//

            let L3expDocs = filter(LEVEL3, (item) => {
              return moment(item.expireDate).isSameOrBefore(new Date());
            });

            let L3pendingDocs = filter(LEVEL3, (item) => {
              return item.expireDate === null;
            });

            let L3uploadedDocs = filter(LEVEL3, (item) => {
              return moment(new Date()).isSameOrBefore(item.expireDate);
            });

            let data_List_01 = [];
            let data_List_02 = [];
            let data_List_03 = [];

            const docStatus = (data) => {
              let expDate = moment(data);
              let today = new Date();

              let diffDays;

              if (data == null) {
                diffDays = "?";
              } else {
                diffDays = Math.floor((expDate - today) / 86400e3) + 1;
                // console.log("incoming docStatus data", diffDays);
              }
              // {
              //   data == null
              //     ? ()
              //     : ();
              // }

              let avatarColorCode;
              let avatarSymbol;
              switch (true) {
                case "?" === diffDays:
                  avatarColorCode = "#607d8b";
                  avatarSymbol = "?";
                  // console.log("case 01 less than 0", diffDays);

                  break;
                case 0 > diffDays:
                  avatarColorCode = "#e91e63";
                  avatarSymbol = "X";
                  //  console.log("case 01 less than 0", diffDays);

                  break;

                case 0 == diffDays:
                  avatarColorCode = "#e91e63";
                  avatarSymbol = "1";
                  //  console.log("case 02 ...0 days left", diffDays);

                  break;

                case 0 < diffDays && diffDays <= 30:
                  avatarColorCode = "#ff9800";
                  avatarSymbol = diffDays;
                  //   console.log("case 03...between 0 and 30", diffDays);

                  break;

                case 30 < diffDays && diffDays <= 45:
                  avatarColorCode = "#cddc39";
                  avatarSymbol = diffDays;
                  //     console.log("case 04...between 30 and 45 ", diffDays);

                  break;
                case 45 < diffDays && diffDays <= 365000:
                  avatarColorCode = "#31c971";
                  avatarSymbol = diffDays;
                  //   console.log("case 05...between 45 and 365000", diffDays);

                  break;

                default:
                  break;
              }
              return {
                avatarColorCode: avatarColorCode,
                avatarSymbol: avatarSymbol,
              };
            };

            forEach(LEVEL1, function (item) {
              A.push({
                id: item.documentId,
                certificateName: item.documentName,
                expireDate: item.expireDate,
                issueDate: item.issueDate,
                documentLink: item.documentLink,
                confirmation: item.confirmation,
                audition: item.audition,
                avtarColor: docStatus(item.expireDate).avatarColorCode,
                daysLeft: docStatus(item.expireDate).avatarSymbol,
                type: item.type,
              });
            });

            forEach(LEVEL2, function (item) {
              B.push({
                id: item.documentId,
                certificateName: item.documentName,
                expireDate: item.expireDate,
                issueDate: item.issueDate,
                documentLink: item.documentLink,
                confirmation: item.confirmation,
                audition: item.audition,
                avtarColor: docStatus(item.expireDate).avatarColorCode,
                daysLeft: docStatus(item.expireDate).avatarSymbol,
                type: item.type,
              });
            });

            forEach(LEVEL3, function (item) {
              C.push({
                id: item.documentId,
                certificateName: item.documentName,
                expireDate: item.expireDate,
                issueDate: item.issueDate,
                documentLink: item.documentLink,
                confirmation: item.confirmation,
                audition: item.audition,
                avtarColor: docStatus(item.expireDate).avatarColorCode,
                daysLeft: docStatus(item.expireDate).avatarSymbol,
                type: item.type,
              });
            });

            data_List_01.push({
              //levelName: "Level 01", //if all 3 levels are activating use this instead
              levelName: "Documents",
              levelId: 1,
              color: "#456543",
              expDocsCount: L1expDocs.length,
              pendingDocsCount: L1pendingDocs.length,
              uploadedDocsCount: L1uploadedDocs.length,
              certificates: A,
            });

            data_List_02.push({
              levelName: "Level 02",
              levelId: 2,
              color: "#456543",
              expDocsCount: L2expDocs.length,
              pendingDocsCount: L2pendingDocs.length,
              uploadedDocsCount: L2uploadedDocs.length,
              certificates: B,
            });

            data_List_03.push({
              levelName: "Level 03",
              levelId: 3,
              color: "#456543",
              expDocsCount: L3expDocs.length,
              pendingDocsCount: L3pendingDocs.length,
              uploadedDocsCount: L3uploadedDocs.length,
              certificates: C,
            });

            let rearrangedArray = data_List_01.concat(
              data_List_02,
              data_List_03
            );

            // setCertificateData(rearrangedArray); <<<<<<<<<<< uncomment this to show  levels(Comment down line as well)

            setLoading(false);
            setCertificateData(data_List_01);
          }
        })
      )
      .catch((err) => {
        //console.log("cannot get rearranged certificate array data",err);
        // toastCommon(true, "error");
      });
    return () => { };
  };


  const saveFilters = (type, data) => {
    localStorage.setItem(type, JSON.stringify(data));
  };

  const changeFramework = (e) => {
    setChangedFramework(e.target.value);
    saveFilters("selected_Framework_CompliancePage", e.target.value);
  };
  const changeSpecility = (e) => {
    setChangedSpecility(e.target.value);
    saveFilters("selected_Speciality_CompliancePage", e.target.value);
  };



  const clearLocalStorageFilters = () => {

    localStorage.setItem("rememberFiltersCandidatePage",
      JSON.stringify("false")
    );
    localStorage.setItem("selected_Framework_CompliancePage", JSON.stringify(""));
    localStorage.setItem("selected_Speciality_CompliancePage", JSON.stringify(""));

  };



  useEffect(() => {
    let rememberTemp = localStorage.getItem("remember_CompliancePage_Selection");
    if (rememberTemp == "true") {
      setRememberCompliance(true);
    } else {
      setRememberCompliance(false);
    }

    if (rememberTemp == "true") {
      const framework =
        JSON.parse(localStorage.getItem("selected_Framework_CompliancePage")) || "";
      const speciality =
        JSON.parse(localStorage.getItem("selected_Speciality_CompliancePage")) || "";


      setChangedFramework(framework);
      setChangedSpecility(speciality);

    } else {
      clearLocalStorageFilters()
    }
  }, []);
  console.log("candidateData.............",props.candidateData)
  useDidMountEffect(() => {
    let statusOfPotentialClients = [];

    if (props.candidateData.potentialClients.length > 0) {
      props.candidateData.potentialClients.forEach(function (potentialClient) {
        let clientApplicationSendingStatus;
        let clientApplicationStatus;

        let clientAppication = clientAppicationList.filter(function (item) {
          return item.client_Id == potentialClient.id;
        });

        if (clientAppication.length > 0) {
          clientApplicationSendingStatus =
            clientAppication[0].clientApplicationSendingStatus;
          clientApplicationStatus = clientAppication[0].clientApplicationStatus;
        } else {
          clientApplicationSendingStatus = "NOT_SENT";
          clientApplicationStatus = "PENDING";
        }

        let newData = {
          clientApplicationSendingStatus: clientApplicationSendingStatus,
          clientApplicationStatus: clientApplicationStatus,
        };

        const returnedTarget = Object.assign(newData, potentialClient);

        statusOfPotentialClients.push(returnedTarget);
      });

      setPotentialClients(statusOfPotentialClients);
    }

    return () => { };
  }, [clientAppicationList]);

  useDidMountEffect(() => {
    getClientApplications();
    return () => { };
  }, [certificateData]);

  const getClientApplications = () => {
    setLoading(true);
    setError(false);

    axios
      .get(
        HOSTMexxar +
        "permanent/candidates/" +
        props.candidateId +
        "/sentDocsToClient"
      )
      .then((res) => {
        setLoading(false);
        setClientAppicationList(res.data.body);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  };

  const [clientApplicaitonStatus, setclientApplicaitonStatus] =
    useState("PENDING");
  const [clientApplicaitonStatusClientID, setclientApplicaitonStatusClientID] =
    useState(null);
  const [clientApplicaitonStatusDropDown, setclientApplicaitonStatusDropDown] =
    useState(false);

  const updateClientApplicationStatus = () => {
    axios
      .post(
        HOSTMexxar +
        "permanent/candidates/" +
        props.candidateId +
        "/updateClientApplicationStatus?clientId=" +
        clientApplicaitonStatusClientID +
        "&clientApplicationStatus=" +
        clientApplicaitonStatus
      )
      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "update", addToast);
          getClientApplications();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((error) => {
        ToastCommon(true, "error", addToast);
      });
  };

  useDidMountEffect(() => {
    updateClientApplicationStatus();
    return () => { };
  }, [clientApplicaitonStatus]);

  useEffect(() => {
    getSpecialiftiesAndFrameworkList();
    localStorage.setItem("candidateServiceType", props.candidateServiceType);

    {
      props.candidateServiceType === "PERMANENT" && getPermanentDocuments();
    }

    return () => { };
  }, []);

  useDidMountEffect(() => {
    {
      props.candidateServiceType === "TEMPORARY" &&
        candidateRole &&
        changedSpecility &&
        changedFramework &&
        getDocuments();
    }
    return () => { };
  }, [candidateRole, changedSpecility, changedFramework]);

  const sendEmailToClient = (item) => {
    setSelectedClient(item);
    setShowEmailModal(true);
    let docs = [];
    if (certificateData !== undefined) {
      certificateData[0].certificates.forEach(function (item) {
        docs.push(item.id);
      });
    }

    setDocIds(docs);
  };

  function clientApplicationStatus(sentToClientStatus) {
    if (sentToClientStatus == "SENT") {
      return (
        <DropdownMenu>
          <DropdownItem key={0} onClick={() => setclientApplicaitonStatus(0)}>
            Pending
          </DropdownItem>
          <DropdownItem key={1} onClick={() => setclientApplicaitonStatus(1)}>
            Accepted
          </DropdownItem>
          <DropdownItem key={2} onClick={() => setclientApplicaitonStatus(2)}>
            Declined
          </DropdownItem>
        </DropdownMenu>
      );
    }

    if (sentToClientStatus == "NOT_SENT") {
      return (
        <DropdownMenu>
          <DropdownItem key={0}>
            Please send the documents to the {ClientLable} first
          </DropdownItem>
        </DropdownMenu>
      );
    }
  }
  const toggleRemember = (e) => {
    setRememberCompliance(!rememberCompliance);
    saveFilters("remember_CompliancePage_Selection", !rememberCompliance);
    if (e.target.checked) {
      saveFilters("selected_Framework_CompliancePage", changedFramework);
      saveFilters("selected_Speciality_CompliancePage", changedSpecility);
    } else {
      clearLocalStorageFilters()
    }

  }

  return (
    <div className="p-1">
      <div className="card p-1 pl-3">
        {props.candidateServiceType === "TEMPORARY" &&
          speciality &&
          framework && (
            <>
              <FormGroup row className="p-2">
                <Col sm={5}>
                  <Label for="candidateSpecialityId">Speciality</Label>

                  <Input
                    type="select"
                    id="candidateSpecialityId"
                    name="candidateSpecialityId"
                    value={changedSpecility}
                    onChange={changeSpecility}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  >
                    {speciality.map((items, id) => {
                      return (
                        <option
                          value={items.id}
                          key={id}
                          style={{ color: "#6A6767" }}
                        >
                          {items.name}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
              
                <Col sm={5}>
                  <Label for="frameworkId">Framework</Label>

                  <Input
                    type="select"
                    id="frameworkId"
                    name="frameworkId"
                    value={changedFramework}
                    onChange={changeFramework}
                    innerRef={register({
                      required: {
                        value: true,
                        message: "This field is required!",
                      },
                    })}
                  >
                    {framework.map((items, id) => {
                      return (
                        <option
                          value={items.id}
                          key={id}
                          style={{ color: "#6A6767" }}
                        >
                          {items.name}
                        </option>
                      );
                    })}
                  </Input>
                </Col>
                <Col sm={2}>
                  <Label for="remember">Remember</Label>
                  <Input
                    className="mx-2 pointer"
                    type="checkbox"
                    checked={rememberCompliance}
                    onChange={(e) => { toggleRemember(e) }}
                  ></Input>

                </Col>

              </FormGroup>
              <Alert color="info " style={{ width: '80%' }}>
                  Note: To set a speciality by default, please ensure that it is selected as the first option on the speciality under profile.
                </Alert>
              <hr />
              <Level
                resetCandidate={resetCandidate}
                candidateData={props.candidateData}
                searchQuery={searchQuery}
                auth={auth}
                candidateId={props.candidateId}
                candidateName={
                  props.candidateData?.firstName +
                  " " +
                  props.candidateData?.lastName
                }
                certificateData={certificateData}
                candidateRole={candidateRole}
                candidateBand={candidateBand}
                changedSpecility={changedSpecility}
                changedFramework={changedFramework}
                specialityName={specialityName}
                roleName={roleName}
                bandName={bandName}
                candidateServiceType={props.candidateServiceType}
                WhiteListUploadedDocsCount={WhiteListUploadedDocsCount}
                WhiteListPendingDocsCount={WhiteListPendingDocsCount}
                WhiteListExpDocsCount={WhiteListExpDocsCount}
              />
            </>
          )}

        {!loading &&
          props.candidateServiceType === "TEMPORARY" &&
          isNull(speciality) &&
          isNull(framework) ? (
          <ConfigurationWarning type={"both"} candidateType={"TEMPORARY"} />
        ) : null}
        {!loading &&
          props.candidateServiceType === "TEMPORARY" &&
          isNull(speciality) ? (
          <ConfigurationWarning
            type={"speciality"}
            candidateType={"TEMPORARY"}
          />
        ) : null}
        {!loading &&
          props.candidateServiceType === "TEMPORARY" &&
          isNull(framework) ? (
          <ConfigurationWarning type={"client"} candidateType={"TEMPORARY"} />
        ) : null}

        {!loading &&
          props.candidateServiceType === "TEMPORARY" &&
          speciality &&
          framework &&
          certificateData[0]?.certificates.length == 0 && (
            <ConfigurationWarning type={"noCertificateData"} />
          )}

        {props.candidateServiceType === "PERMANENT" &&
          certificateData.length > 0 && (
            <Level
              candidateData={props.candidateData}
              searchQuery={searchQuery}
              auth={auth}
              candidateServiceType={props.candidateServiceType}
              candidateId={props.candidateId}
              certificateData={certificateData}
              candidateRole={candidateRole}
              changedSpecility={changedSpecility}
              changedFramework={changedFramework}
              specialityName={specialityName}
              roleName={roleName}
              bandName={bandName}
              candidateName={
                props.candidateData?.firstName +
                " " +
                props.candidateData?.lastName
              }
            />
          )}

        {props.candidateServiceType === "PERMANENT" &&
          !loading &&
          certificateData.length == 0 && (
            <ConfigurationWarning type={"noCertificateData"} />
          )}
      </div>

      {props.candidateServiceType === "PERMANENT" && (
        <div className="card p-4 my-4">
          {loading && !error && (
            <div className="d-flex justify-content-center align-items-center">
              <div>
                <img src={loadingAnimation} style={{ height: 100 }}></img>
              </div>
            </div>
          )}

          {isNull(potentialClients) && !loading && !potentialClientError && (
            <div className="d-flex justify-content-center align-items-center">
              <Alert>
                Note: No potential {ClientLable}s have been assigned for this
                candidate. Please add them through the candidate's profile page
              </Alert>
            </div>
          )}

          {potentialClients && potentialClients.length > 0 && (
            <>
              <div className="pb-3">
                <h5>
                  {props.candidateData.firstName}&nbsp;
                  {props.candidateData.lastName}'s application can be sent to
                  {ClientLable}(s) when it's{" "}
                  <span className="text-success">completed </span>
                </h5>
              </div>
              <div className="grid mr-2">
                <div className="row my-2 align-items-center ">
                  <h6 className="col-md-8 col-sm-8 ">{ClientLable}(s)</h6>
                  <h6 className="col-md-2 col-sm-2 d-flex  justify-content-center">
                    {ClientLable} Status
                  </h6>
                  <h6 className="col-md-2 col-sm-2  d-flex  justify-content-end">
                    Actions
                  </h6>
                </div>

                {potentialClients &&
                  potentialClients.map((item, id) => {
                    return (
                      <div
                        key={id}
                        className="row my-2 mt-2 align-items-center "
                      >
                        <div className="col-md-8 col-sm-8">{item.name}</div>

                        <div className="col-md-2 col-sm-2 d-flex  justify-content-center">
                          <Dropdown
                            direction="right"
                            isOpen={
                              clientApplicaitonStatusDropDown &&
                              clientApplicaitonStatusClientID == item.id
                            }
                            toggle={() => {
                              setclientApplicaitonStatusClientID(item.id);
                              setclientApplicaitonStatusDropDown(
                                !clientApplicaitonStatusDropDown
                              );
                            }}
                          >
                            <DropdownToggle
                              caret
                              color={
                                PermClientApplicationStatus(
                                  item.clientApplicationStatus
                                ).badgeColor
                              }
                              style={{
                                // backgroundColor: ApplicationStatus(
                                //   userProfile.candidateApplicationStatus
                                // ).badgeColor,
                                fontSize: "10px",
                                fontWeight: "bold",
                                border: "0",
                                height: "1rem",
                                width: "fit-content",
                              }}
                            >
                              {
                                PermClientApplicationStatus(
                                  item.clientApplicationStatus
                                ).badgeLable
                              }
                            </DropdownToggle>
                            {clientApplicationStatus(
                              item.clientApplicationSendingStatus
                            )}
                          </Dropdown>

                        </div>
                        <div className="col-md-2 col-sm-2 d-flex align-items-center justify-content-end">

                          <Button
                            color={
                              PermApplicationSendingStatus(
                                item.clientApplicationSendingStatus
                              ).badgeColor
                            }
                            className=""
                            onClick={() => sendEmailToClient(item)}
                          >
                            {
                              PermApplicationSendingStatus(
                                item.clientApplicationSendingStatus
                              ).badgeLable
                            }
                          </Button>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </>
          )}

          {!loading && potentialClientError && (
            <div className="d-flex justify-content-start align-items-center ">
              <Alert color="danger" className="w-100">
                Note: Please add{" "}
                {props.candidateData.currentlyEmployedAt &&
                  props.candidateData.currentlyEmployedAt.name}{" "}
                back as a potential {ClientLable} for this candidate to show the{" "}
                {ClientLable}
                status
              </Alert>
            </div>
          )}
        </div>
      )}

      <ModalComponent
        show={showEmailModal}
        header="E mail"
        closeModal={() => setShowEmailModal(false)}
      >
        <EmailNav
          incomingPage="permCompliants"
          closeModal={() => {
            setShowEmailModal(false);
            getClientApplications();
          }}
          user={{
            selectedClient: selectedClient,
            candidate: props.candidateData,
            docIds: docIds,
          }}
        />
      </ModalComponent>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(Compliance);
