import React, { useState, useEffect } from "react";
import { Button, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import ModalComponent from "../../components/Modal/modalComponent";
import TimeSheet from "../../components/Modal/ModalBody/timeSheet";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import { HOSTMexxar, HostMexxarWS } from "../../configs/api-config";
import { Client as StompClient } from "@stomp/stompjs";

const EmployeePayrollWidgetVerified = () => {
  const [certificates, setCertificates] = useState([]);
  const MexxarAPI = HOSTMexxar;
  const [calendarShow, setCalendarShow] = useState(false);
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [data, setData] = useState();

  const toggleShowTimeModal = (data) => {
    setData(data);
    setShowTimeModal(!showTimeModal);
  };

  const getCandidates = () => {
    axios
      .get(MexxarAPI + "workspace/payroll?ps=PENDING&hrs=PENDING&rs=CLEARED")
      .then((candidates) => {
        setCertificates(candidates.data.body);
      });
  };

  useEffect(() => {
    getCandidates();

    let client = new StompClient();
    client.configure({
      brokerURL: HostMexxarWS + "wsc",
      onConnect: () => {
        //console.log("onConnect");
        client.subscribe(
          `/headhunter/${JSON.parse(localStorage.user).id}`,
          (message) => {
            getCandidates();
          }
        );
      },

      //Helps during debugging, remove in production
      debug: (str) => {
        //console.log(new Date(), str);
      },
    });
    client.activate();

    return () => {};

  }, []);

  return (
    <div>
      {certificates.map((post, i) => {
        return (
          <div className="card mb-2 " key={i}>
            <div className="card-body">
              <div className="flex flex-row d-flex justify-content-between ">
                <div className="d-flex  " style={{ width: "250px" }}>
                  <div className="d-flex align-items-center mr-3 ">
                    <span className="mx-2">
                      <b className="badge badge-circle sm text-primary"></b>
                    </span>
                  </div>
                  <div>
                    <div>{post.booking.candidateName}</div>

                    <div className="item-mail text-muted h-1x d-none d-sm-block">
                      <time
                        dateTime="2020-12-20T00:00:00Z"
                        data-local="time"
                        data-format="%B %e, %Y %l:%M%P"
                        title="December 20, 2020 at 5:30am IST"
                        data-localized=""
                        aria-label="December 20, 2020 5:30am"
                      >
                        <small>
                          Booking Date :{" "}
                          {moment(post.booking.bookingDate).format("llll")}
                        </small>
                      </time>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center"
                  style={{ width: "60px" }}
                ></div>

                <div className="textCenter-Custom">
                  <div>Hospital Name</div>
                  <div className="text-muted">{post.hospitalName}</div>
                </div>
                <div className="textCenter-Custom">
                  <div>Submitted Date</div>
                  <div className="text-muted">
                    {" "}
                    {moment(post.submitedDate).format("llll")}
                  </div>
                </div>
                <div>
                  <Button
                    color="success"
                    className="mr-2"
                    onClick={() => {
                      toggleShowTimeModal(post);
                    }}
                  >
                    Show
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <ModalComponent
        show={calendarShow}
        header="Log new call back"
        closeModal={() => handleCalendarShow(false)}
      >
        <SmallCalendar />
        <div className="mt-3">
          {" "}
          <Button color="success">Send</Button>{" "}
          <Button color="secondary" onClick={() => handleCalendarShow(false)}>
            Cancel
          </Button>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showTimeModal}
        header="Time Sheet"
        size="lg"
        closeModal={() => setShowTimeModal(false)}
      >
        <TimeSheet
          data={data}
          closeModal={() => setShowTimeModal(false)}
        ></TimeSheet>
        <div className="mt-4">
          <Col sm={{ size: 8, offset: 8 }}></Col>
        </div>
      </ModalComponent>
    </div>
  );
};

export default EmployeePayrollWidgetVerified;
