import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Label, Input, Form, FormGroup, Col } from "reactstrap";
import { HOSTMexxar, REGISTER_CANDIDATE } from "../../../configs/api-config";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";

function ReferenceSummery(props) {
  const MexxarAPI = HOSTMexxar;

  const { data, closeModal, getCandidates, auth, candidateId } = props;

  const { register, handleSubmit } = useForm();
  const [referenceForm, setReferenceForm] = useState(null);

  const onSubmit = (data) => {};

  // const getReferenceForm = () => {
  //   axios
  //     .get(`${MexxarAPI}reference-form/candidate/${candidateId}`)
  //     .then((res) => {
  //       setReferenceForm(res.data.body[0]);
  //     });
  // };

  // useEffect(() => {
  //   if (candidateId != null && candidateId != undefined) {
  //     getReferenceForm();
  //   }
  // }, [candidateId]);


  return (
    <>
      {referenceForm ? (
        <div className="card-body">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="">
              <div>
                <div className="">
                  <div>
                    <h5>Candidate Employement Details</h5>
                    <hr size="10" width="100%" color="black"></hr>
                  </div>

                  <div className="mt-2">
                    <FormGroup row>
                      <Col sm={12} md={7}>
                        <Label>
                          <div className="p-2"></div>
                          Candidate Name
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="name"
                          name={"candidateName"}
                          placeholder="Name of the Candidate"
                          //  defaultValue={data.rJobTitle}
                          disabled={true}
                          value={referenceForm.candidateName}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                      </Col>
                      <Col sm={12} md={5}>
                        <Label>
                          <div className="p-2"></div>
                          Employed at
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="name"
                          name={"employedAt"}
                          placeholder="Employed at"
                          // defaultValue={data.rJobTitle}
                          disabled={true}
                          value={referenceForm.employedAt}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                      </Col>
                      <Col sm={12} md={4}>
                        <div className="pt-3">
                          <Label>
                            Job Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="name"
                            name={"jobTittle"}
                            placeholder="Job Title "
                            // defaultValue={data.rJobTitle}
                            disabled={true}
                            value={referenceForm.jobTittle}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={6}>
                        <div className="pt-3">
                          <Label>
                            Date of Employment
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="date"
                            name={"dateOfEmployment"}
                            //  placeholder="Date of employment"
                            // defaultValue={data.rDateOfEmployement}
                            disabled={true}
                            value={moment(
                              referenceForm.dateOfEmployment
                            ).format("YYYY-MM-DD")}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={8}>
                        <div className="pt-3"></div>
                        <Label>
                          Reason for Leaving
                          <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="name"
                          name={"reasonForLeaving"}
                          placeholder="Reason.."
                          //  placeholder="Organisation Name"
                          // defaultValue={data.rOrganisationName}
                          disabled={true}
                          value={referenceForm.reasonForLeaving}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                      </Col>
                    </FormGroup>
                    {/* <div className="card-header">
                <div className="col-md-12 d-flex justify-content-between align-items-center">
                  <h5>Candidate Assessment </h5>
                </div>
              </div> */}
                    {/* {newQArray.map((question, id) => { */}
                    {/* {questions.map((question, id) => {
                return (
                  <FormGroup row key={id}>
                    <Col sm={4} md={4}>
                      <Label>
                        {" "}
                        {question.questionNo}. {question.question}
                      </Label>
                    </Col>
                    <Col sm={2} md={2}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            name={question.questionNo}
                            value={0}
                            innerRef={register}
                          />
                          Very Poor
                        </Label>
                      </FormGroup>
                    </Col>

                    <Col sm={1} md={1}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            name={question.questionNo}
                            value={1}
                            innerRef={register}
                          />
                          Poor
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm={2} md={2}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            name={question.questionNo}
                            value={2}
                            innerRef={register}
                          />
                          Satisfactory
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm={1} md={1}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            name={question.questionNo}
                            value={3}
                            innerRef={register}
                          />
                          Good
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col sm={2} md={2}>
                      <FormGroup check>
                        <Label>
                          <Input
                            type="radio"
                            name={question.questionNo}
                            value={4}
                            innerRef={register}
                          />
                          Very Good
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                );
              })} */}

                    <>
                      <div className="pt-3">
                        <h5>How Would You Rate This Candidate Overall?</h5>
                        <hr size="10" width="100%" color="black"></hr>
                      </div>

                      <FormGroup row>
                        <Col sm={3} md={3}>
                          <Label> Rating</Label>
                        </Col>
                        <Col
                        // sm={1} md={2}
                        >
                          <FormGroup check>
                            <Label>
                              <div className="pr-2"></div>
                              <Input
                                type="radio"
                                name={"rating"}
                                value={0}
                                disabled={true}
                                checked={
                                  referenceForm.rating == "VERY_POOR"
                                    ? true
                                    : false
                                }
                                innerRef={register}
                              />
                              Very Poor
                            </Label>
                          </FormGroup>
                        </Col>

                        <Col
                        // sm={2} md={0}
                        >
                          <FormGroup check>
                            <Label>
                              <div className="pr-2"></div>
                              <Input
                                type="radio"
                                name={"rating"}
                                value={1}
                                disabled={true}
                                checked={
                                  referenceForm.rating == "POOR" ? true : false
                                }
                                innerRef={register}
                              />
                              Poor
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col
                        // sm={2} md={2}
                        >
                          <FormGroup check>
                            <Label>
                              <div className="pr-2"></div>
                              <Input
                                type="radio"
                                name={"rating"}
                                value={2}
                                disabled={true}
                                checked={
                                  referenceForm.rating == "SATISFACTORY"
                                    ? true
                                    : false
                                }
                                innerRef={register}
                              />
                              Satisfactory
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col
                        // sm={2} md={1}
                        >
                          <FormGroup check>
                            <Label>
                              <div className="pr-2"></div>
                              <Input
                                type="radio"
                                name={"rating"}
                                value={3}
                                disabled={true}
                                checked={
                                  referenceForm.rating == "GOOD" ? true : false
                                }
                                innerRef={register}
                              />
                              Good
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col sm={2} md={2}>
                          <FormGroup check>
                            <Label>
                              <Input
                                type="radio"
                                name={"rating"}
                                value={4}
                                disabled={true}
                                checked={
                                  referenceForm.rating == "VERY_GOOD"
                                    ? true
                                    : false
                                }
                                innerRef={register}
                              />
                              Very Good
                            </Label>
                          </FormGroup>
                        </Col>
                      </FormGroup>
                    </>
                    {/* <hr /> */}
                    <FormGroup row>
                      <Col sm={8} md={8}>
                        <Label>Would you re-employ this candidate?</Label>
                      </Col>
                      <Col sm={2} md={2}>
                        <FormGroup check>
                          <Label>
                            <Input
                              type="radio"
                              name={"reEmployCandidate"}
                              value={"true"}
                              disabled={true}
                              checked={referenceForm.reEmployCandidate}
                              innerRef={register}
                            />
                            Yes
                          </Label>
                        </FormGroup>
                      </Col>

                      <Col sm={2} md={2}>
                        <FormGroup check>
                          <Label>
                            <Input
                              type="radio"
                              name={"reEmployCandidate"}
                              value={"false"}
                              disabled={true}
                              checked={!referenceForm.reEmployCandidate}
                              innerRef={register}
                            />
                            No
                          </Label>
                        </FormGroup>
                      </Col>
                    </FormGroup>

                    <div>
                      <div className="pt-3"></div>
                      <h5>Your Signature</h5>
                      <hr size="10" width="100%" color="black"></hr>
                    </div>
                    <FormGroup row>
                      <Col sm={12} md={7}>
                        <div className="pt-3">
                          <Label>
                            Referee Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="name"
                            name={"ReferenceName"}
                            placeholder="Referee Name"
                            //defaultValue={data.refereeName}
                            disabled={true}
                            value={referenceForm.referenceName}
                            // value={`${referenceFormRow.candidate.referenceFormSendBy.firstName} ${referenceFormRow.candidate.referenceFormSendBy.lastName}`}
                            innerRef={register({
                              required: {
                                value: true,
                                message: "This field is required!",
                              },
                            })}
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={4}>
                        <div className="pt-3">
                          <Label>
                            Telephone Number{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            name={"telephoneNo"}
                            placeholder="Telephone Number"
                            value={referenceForm.telephoneNo}
                            disabled={true}
                            // innerRef={register({
                            //   required: {
                            //     value: true,
                            //     message: "This field is required!",
                            //   },
                            //   pattern: {
                            //     value:
                            //       /^(((\+44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((\+44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((\+44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/,
                            //     message: "Please enter a valid phone number",
                            //   },
                            // })}
                          />
                        </div>
                      </Col>
                      <Col sm={12} md={4}>
                        <div className="pt-3">
                          <Label>
                            Referee Email <span className="text-danger">*</span>
                          </Label>

                          <Input
                            type="Email"
                            name={"refereeEmail"}
                            placeholder="Referee Email"
                            disabled={true}
                            value={referenceForm.refereeEmail}
                            // defaultValue={referenceFormRow.refereeEmail}
                            // innerRef={register({
                            //   required: {
                            //     value: true,
                            //     message: "This field is required!",
                            //   },
                            //   pattern: {
                            //     value:
                            //       /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            //     message: "Please enter a valid email address!",
                            //   },
                            // })}
                          />
                        </div>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={12} md={8}>
                        <Label>
                          Address <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name={"address"}
                          placeholder="Refree Address"
                          disabled={true}
                          value={referenceForm.address}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                      </Col>
                      <Col sm={12} md={4}>
                        <Label>
                          Post Code <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name={"postCode"}
                          placeholder="Post Code"
                          disabled={true}
                          value={referenceForm.postCode}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                      </Col>
                      <Col sm={12} md={4}>
                        <div className="pt-3"></div>
                        <Label>
                          Band <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name={"band"}
                          placeholder="Band"
                          disabled={true}
                          value={referenceForm.band}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                          })}
                        />
                      </Col>
                    </FormGroup>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between">
                <div></div>
                <div>
                  <Button color="success" onClick={() => closeModal()}>
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      ) : null}
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(ReferenceSummery);
