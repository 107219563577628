import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import { connect } from "react-redux";
import TabNavigationMenu from "../../../util/common/tabNavigation";
import OnboardingWorkspace from "./workspace/onboardingWorkspace.js";
import { UncontrolledTooltip } from "reactstrap";
import ModalComponent from "../../../components/Modal/modalComponent.js";
import DialPad from "../../../components/DialPad/index.js";
import OnboardingCallDelete from "../../../components/Modal/ModalBody/onboardingCallDelete.js";
import OnboardingUnverifiedCandidate from "./workspace/onboardingUnverifiedCandidate.js";
import { faSearchLocation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddMap from "../../../components/Modal/ModalBody/Admin/addMap.js";
import OnboardingMobileCandidates from "./workspace/onboardingMobileCandidates.js";

const Onboarding = (props) => {
  const { auth } = props;

  const [employeeID, setEmployeeID] = useState(auth.item.userTypeId);
  const [currentTab, setCurrentTab] = useState("onboardingWorkspace");
  const [showDialPad, setShowDialPad] = useState(false);
  const [showCallDelete, setShowCallDelete] = useState(false);
  const [showAddMap, setShowMap] = useState(false);
  const toggleN = () => setShowDialPad(!showDialPad);

  const tab = window.location.hash.substring(1);
  useEffect(() => {
    if (tab === "") {
      setCurrentTab("onboardingWorkspace");
    } else {
      setCurrentTab(tab);
    }
  }, [tab]);

  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <>
      <DashboardLayout
        title="Onboarding Workspace"
        subTitle="Acheive Your Daily Targets"
      >
        <div className="d-flex justify-content-end p-2 ml-3">
          <div>
            <button
              style={{ background: "none", border: "none", cursor: "pointer" }}
            >
              <FontAwesomeIcon
                id="hospitalMapToggle"
                color="#00C426"
                icon={faSearchLocation}
                className="fa-flip-horizontal"
                onClick={() => {
                  setShowMap(true);
                }}
              />
              <UncontrolledTooltip target="hospitalMapToggle">
                Hospital Map
              </UncontrolledTooltip>
            </button>
          </div>
        </div>

        <TabNavigationMenu
          activated={currentTab}
          onChangeItem={switchTabHandler}
          itemList={[
            { name: "Create-call", key: "onboardingWorkspace" },
            { name: "Unverified Candidates", key: "unverifiedCandidate" },
            { name: "Mobile Candidates", key: "mobileCandidate" },
          ]}
        />

        <ModalComponent
          show={showCallDelete}
          style={{ width: "50px" }}
          className="modal-dialog modal-sm"
          closeModal={() => setShowCallDelete(false)}
        >
          <OnboardingCallDelete
            closeModal={() => setShowCallDelete(false)}
            modalType="Dates count"
            mode="Delete"
          />
        </ModalComponent>

        <ModalComponent
          size="sm"
          show={showDialPad}
          closeModal={() => toggleN()}
        >
          <DialPad></DialPad>
        </ModalComponent>
        <ModalComponent
          show={showAddMap}
          header="Location"
          closeModal={() => setShowMap(false)}
          className="modal-dialog modal-lg"
        >
          <AddMap
            mode="default"
            closeModal={() => setShowMap(false)}
            // rowID={showRowID1}
          />
        </ModalComponent>

        {currentTab === "onboardingWorkspace" && (
          <OnboardingWorkspace
            // updateTargets={() => getData()}
            employeeID={employeeID}
            candidateStatus="UNVERIFIED"
          />
        )}

        {currentTab === "unverifiedCandidate" && (
          <OnboardingUnverifiedCandidate
            // updateTargets={() => getData()}
            employeeID={employeeID}
            candidateStatus="UNVERIFIED"
          />
        )}

{currentTab === "mobileCandidate" && (
          <OnboardingMobileCandidates
            // updateTargets={() => getData()}
            employeeID={employeeID}
            candidateStatus="UNVERIFIED"
          />
        )}
      </DashboardLayout>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(Onboarding);
