// import "./css/main2.css";
//  import "./css/util.css";
// import "./css/animate.css";
// import "./css/bootstrap.css";
// import Tilt from "react-tilt";
// import "./css/select2.min.css";
// import "./css/hamburgers.min.css";
// import "../fonts/font-awesome-4.7.0/css/font-awesome.min.css";

import "./emailLogin.css";
import emailLoginBackground from "../../../../src/images/emailLogin1.svg";
import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import { Link, useHistory } from "react-router-dom";
import {
  Input,
  Label,
  Form,
  Col,
  Button,
  Row,
  Alert,
  Spinner,
  FormGroup,
} from "reactstrap";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import {
  emailServerCredentials,
  emailBodyPosts,
} from "../../..//redux/actions/emailServerAction";
import store from "../../../redux/store";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { HostMexxarEmail } from "../../../configs/api-config";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import { loginRequest } from "../../../authConfig";
import { callMsGraph } from "../graph";

//**************** Redirecting to the outlook mail box ****************//
function Redirect() {
  const history = useHistory();
  useEffect(() => {
    history.push("/dashboard/email/outlook/inbox");
  }, []);
  return <div>Redirecting...</div>;
}

//**************** End ****************//

const EmailLogin = () => {
  const { register, handleSubmit, watch, errors } = useForm();

  const { instance, accounts } = useMsal();

  const [email, setEmail] = useState();
  const [passWord, setPassWord] = useState();
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const loginCredentialStore = () => {
    setLoading(true);

    axios
      .post(HostMexxarEmail + "email/mail/?page=1&offset=10", {
        email: email,
        password: passWord,
        type: "INBOX",
      })
      .then(function (response) {
        setLoading(false);

        if (response.data.status == "success") {
          setStatus(response.data.status);
          store.dispatch(
            emailServerCredentials({
              email: email,
              password: passWord,
              type: "INBOX",
            })
          );

          store.dispatch(emailBodyPosts(response.data.body));

          localStorage.setItem("email", email);
          localStorage.setItem("password", passWord);
          localStorage.setItem("type", "INBOX");
          localStorage.setItem("totalMails", response.data.body.totalMail);
        } else {
          setStatus(response.data.status);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleLoginOutLook = () => {
    instance
      .loginPopup(loginRequest)
      .then((res) => {})
      .catch((e) => {});
  };

  return (
    <>
      <DashboardLayout>
        <div className="card p-4 ">
          <Form>
            <Row className="d-flex align-items-center justify-content-around">
              <Col sm={6} md={6} lg={6}>
                <img
                  style={{ height: 400 }}
                  src={emailLoginBackground}
                  //  style={{ height: 100 }}
                />
              </Col>
              <Col sm={12} md={6} lg={6} className="">
                {/* <Label>Email Login</Label>
                {status === "Login failed" && (
                  <Alert color="danger">
                    Error: Email or password incorrect
                  </Alert>
                )}
                {status === "success" && (
                  <Alert color="success">Successfully logged in</Alert>
                )}

                {status == "success" ? null : (
                  <>
                    <FormGroup row>
                      <Col sm={12}>
                        <Input
                          type="email"
                          className="mx-2"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          placeholder="Email Address"
                          invalid={errors.email}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            pattern: {
                              value:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Please enter a valid email address!",
                            },
                          })}
                        />
                      </Col>
                    </FormGroup>

                 
                 

                    <FormGroup row>
                      <Col xs={10} sm={10} md={10} lg={10}>
                        <Input
                          className="mx-2"
                          onChange={(e) => setPassWord(e.target.value)}
                          type={showPassword ? "text" : "password"}
                          name="Password"
                          placeholder="Password"
                          invalid={errors.bName}
                          innerRef={register({
                            required: {
                              value: true,
                              message: "This field is required!",
                            },
                            maxLength: {
                              value: 50,
                              message:
                                "This field only allowed only 50 characters",
                            },
                          })}
                        ></Input>
                        {errors.bName && (
                          <ul
                            className="list-unstyled text-sm mt-1 text-muted filled"
                            id="parsley-id-119"
                          >
                            <li className="parsley-required text-danger">
                              {errors.bName?.message}
                            </li>
                          </ul>
                        )}{" "}
                      </Col>

                      <Col
                        xs={10}
                        sm={2}
                        md={2}
                        lg={2}
                        className="d-flex align-items-center  justify-content-start"
                      >
                        {showPassword ? (
                          <FontAwesomeIcon
                            icon={faEye}
                            className=" btn-raised btn-wave"
                            onClick={() => setShowPassword(false)}
                            style={{
                              boxShadow: "none",
                            }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            className=" btn-raised btn-wave"
                            onClick={() => setShowPassword(true)}
                            style={{
                              boxShadow: "none",
                            }}
                          />
                        )}
                      </Col>
                    </FormGroup>
                  </>
                )}

                {status === "success" ? (
                  <Link to="/dashboard/email/inbox">
                    <Button className="m-2" color="success">
                      Go to Inbox
                    </Button>
                  </Link>
                ) : (
                  <>
                    <Button
                      disabled
                      className="m-2"
                      onClick={loginCredentialStore}
                    >
                      {loading && (
                        <Spinner
                          animation="border"
                          style={{
                            height: "20px",
                            width: "20px",
                            marginRight: "5px",
                          }}
                        />
                      )}
                      Login
                    </Button>
                  </>
                )} */}

                <FormGroup row>
                  <Col sm={12}>
                    <Alert>
                      Please log in to your email service provider to access
                      your inbox.
                    </Alert>
                  </Col>
                </FormGroup>

                <FormGroup row className="mt-2 ">
                  <Col md={3}>
                    <Button
                      className="m-2"
                      color="success"
                      onClick={handleLoginOutLook}
                    >
                      Outlook
                    </Button>
                  </Col>
                  <Col md={3}>
                    <Button
                      className="m-2"
                      color="info"
                      disabled
                      onClick={handleLoginOutLook}
                    >
                      Gmail
                    </Button>
                  </Col>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <AuthenticatedTemplate>
                <Redirect />
              </AuthenticatedTemplate>
            </Row>
          </Form>
        </div>
      </DashboardLayout>
    </>
  );
};

export default EmailLogin;
