import React, { useState } from "react";
import axios from "axios";
import { forEach } from "lodash";
import { useEffect } from "react";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  CANDIDATE_ROLE,
  CANDIDATE_SPECIALITY,
  ClientLable,
  HOSTMexxar,
  TRAININGPROVIDERS,
} from "../../../../configs/api-config";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { connect } from "react-redux";
import {
  bandsGetAction,
  clientsGetAction,
  rolesGetAction,
  specialitiesGetAction,
} from "../../../../redux/actions/initialDataGetAction";
import store from "../../../../redux/store";
import useDidMountEffect from "../intialRunUseEffect";

function AddAJob(props) {
  const { reduxClients, reduxSpecialities, reduxBands, reduxRoles } = props;
  const MexxarAPI = HOSTMexxar;
  const MexxarApiRole = CANDIDATE_ROLE;
  const MexxarApiSpeciality = CANDIDATE_SPECIALITY;
  const MexxarApiTrainingProviders = TRAININGPROVIDERS;

  const { register, handleSubmit, errors } = useForm();
  const {
    onCreate,
    closeModal,
    mode,
    modalType,
    fieldData,
    specialityShow,
    specialityIncomingData,
    trainingProviderShow,
    trainingProviderIncomingData,
  } = props;

  const [departmentError, setDepartmentError] = useState(false);

  const [speciality, setSpeciality] = useState([]);
  const [roles, setRoles] = useState([]);
  const [bands, setBands] = useState([]);
  const [clients, setClients] = useState();
  const [departments, setDepartments] = useState([
    { id: 0, name: "Select", label: `Select ${ClientLable} First` },
  ]);

  const [addKillLog, setaddKillLog] = useState(false);
  const [selectedRole, setSlectedRole] = useState(null);
  const [selectedBand, setSelectedBand] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSpecialities, setSelectedSpecialities] = useState(null);
  const [hiringManagerEmail, setSelectedHiringManagerEmail] = useState(null);

  const [submitted, setSubmitted] = useState(false);

  const reArrangeToShowInSelectDropDown = (data) => {
    let reArranged = [];
    forEach(data, function (item) {
      reArranged.push({
        value: item.name,
        label: item.name + `${item?.type=="DATABASE" ? "-DB": ""}`,
        id: item.id,
      });
    });
    return reArranged;
  };

  const getClientDepartments = (data) => {
    if (data) {
      axios
        .get(HOSTMexxar + "client-wards/clients/" + data)
        .then((res) => {
          let tempDep = [];
          forEach(res.data.body, function (department) {
            tempDep.push({
              id: department.wardId,
              name: department.wardName,
              label: department.wardName,
            });
          });
          setDepartments(tempDep);
        })
        .catch((err) => {
          setDepartmentError(err);
          // console.log("err", err);
          // toastCommon(true, "error");
        });
    }
  };

  const [defaultHREmail, setDefaultHREmail] = useState("");
  const [selectedHREmail, setSelectedHREmail] = useState(null);

  useDidMountEffect(() => {
    {
      clients && selectedClient && getClientDepartments(selectedClient.id);
    }

    let defaultHR = "";

    defaultHR = {
      value: selectedClient.value,
      label: selectedClient.value,
      id: selectedClient.id,
    };
    setDefaultHREmail(defaultHR);
  }, [selectedClient]);

  useEffect(() => {
    setSelectedSpecialities(specialityIncomingData);
  }, []);

  useEffect(() => {
    if (!reduxClients) {
      store.dispatch(clientsGetAction());
    }

    if (!reduxSpecialities) {
      store.dispatch(specialitiesGetAction());
    }
    if (!reduxRoles) {
      store.dispatch(rolesGetAction());
    }
    if (!reduxBands) {
      store.dispatch(bandsGetAction());
    }
  }, []);

  useEffect(() => {
    if (reduxClients) {
      let reArranged = [];
      forEach(reduxClients, function (item) {
        reArranged.push({
          value: item.email,
          label: item.name,
          id: item.id,
        });
      });
      setClients(reArranged);
    }
  }, [reduxClients]);

  useEffect(() => {
    if (reduxBands) {
      setBands(reArrangeToShowInSelectDropDown(reduxBands));
    }
  }, [reduxBands]);

  useEffect(() => {
    if (reduxRoles) {
      setRoles(reArrangeToShowInSelectDropDown(reduxRoles));
    }
  }, [reduxRoles]);

  useEffect(() => {
    if (reduxSpecialities) {
      setSpeciality(reArrangeToShowInSelectDropDown(reduxSpecialities));
    }
  }, [reduxSpecialities]);

  const onSubmit = (data) => {
    setaddKillLog(true);
    setSubmitted(true);
    let specilityIDArray = [];
    forEach(selectedSpecialities, function (item) {
      specilityIDArray.push(item.id);
    });

    Object.assign(data, {
      bandId: selectedBand.id,
      roleId: selectedRole.id,
      wardId: selectedDepartment.id,
      hiringManagerEmail: selectedHREmail.value,
      specialityIds: specilityIDArray,
    });

    onCreate(data, selectedClient);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <FormGroup row>
        <Label sm={12}>Job Details</Label>
        <Col sm={4}>
          <Label>
            Title <span className="text-danger ">*</span>
          </Label>
          <Input
            type="text"
            name="jobTitle"
            className="capitalize"
            // defaultValue={fieldData}
            invalid={errors.jobTitle}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
          {errors.jobTitle && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.jobTitle?.message}
              </li>
            </ul>
          )}
        </Col>
        <Col sm={4}>
          <Label>
            No. Positions <span className="text-danger ">*</span>
          </Label>
          <Input
            type="number"
            name="noOfPositionsAvailable"
            // defaultValue={fieldData}
            invalid={errors.noOfPositionsAvailable}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
          {errors.noOfPositionsAvailable && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.noOfPositionsAvailable?.message}
              </li>
            </ul>
          )}
        </Col>
        <Col sm={4}>
          <Label>
            Offered Salary <span className="text-danger ">*</span>
          </Label>
          <Input
            type="text"
            name="offeredSalary"
            className="capitalize"
            // defaultValue={fieldData}
            invalid={errors.offeredSalary}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>

          {errors.offeredSalary && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.offeredSalary?.message}
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Col sm={6}>
          <Label>
            {ClientLable} <span className="text-danger ">*</span>
          </Label>
          <Select
            //  defaultValue={specialityIncomingData}
            //isMulti
            name="cliendId"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(data) => setSelectedClient(data)}
            options={clients}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allowed only 30 characters",
              },
            })}
          />

          {!selectedClient && submitted && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                This field is required!
              </li>
            </ul>
          )}
        </Col>
        <Col sm={6}>
          <Label>
            Department <span className="text-danger ">*</span>
          </Label>
          <Select
            // defaultValue={specialityIncomingData}
            //isMulti
            name="wardId"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(data) => setSelectedDepartment(data)}
            options={departments}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allowed only 30 characters",
              },
            })}
          />

          {!selectedDepartment && submitted && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                This field is required!
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Description </Label>
        <Col sm={8}>
          <Input
            type="textarea"
            name="jobDescription"
            className="capitalize"
            //   defaultValue={fieldData}
            //  invalid={errors.jobDescription}
            innerRef={register({
              required: {
                value: false,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={12}>Hiring Manager</Label>
        <hr />
        <Col sm={4}>
          <Label>
            Name <span className="text-danger ">*</span>
          </Label>
          <Input
            type="text"
            name="hiringManagerName"
            className="capitalize"
            //  defaultValue={fieldData}
            invalid={errors.hiringManagerName}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
          {errors.hiringManagerName && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.hiringManagerName?.message}
              </li>
            </ul>
          )}
        </Col>

        <Col sm={4}>
          <Label className="ml-3">
            Email <span className="text-danger ">*</span>
          </Label>
          <CreatableSelect
            // menuPortalTarget={document.body}
            className="col-12"
            placeholder="Client Email"
            name="clientsEmail"
            isClearable
            options={[defaultHREmail]}
            onChange={(data) => setSelectedHREmail(data)}
          />
          {!selectedHREmail && submitted && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled ml-3"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                This field is required!
              </li>
            </ul>
          )}
        </Col>
        <Col sm={4}>
          <Label>Contact No</Label>
          <Input
            type="text"
            name="hiringManagerContactNo"
            className="capitalize"
            //  defaultValue={fieldData}
            invalid={errors.hiringManagerContactNo}
            innerRef={register({
              required: {
                value: false,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={12}>Required Experience </Label>
        <Col sm={6}>
          <Label>
            Years <span className="text-danger ">*</span>
          </Label>
          <Input
            type="number"
            name="requiredExperienceYears"
            className="capitalize"
            //  defaultValue={fieldData}
            invalid={errors.requiredExperienceYears}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
          {errors.requiredExperienceYears && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                {errors.requiredExperienceYears?.message}
              </li>
            </ul>
          )}
        </Col>
        <Col sm={6}>
          <Label>Months </Label>
          <Input
            type="number"
            name="requiredExperienceMonths"
            className="capitalize"
            //  defaultValue={fieldData}
            invalid={errors.requiredExperienceMonths}
            innerRef={register({
              required: {
                value: false,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={12} for="SkillsAndSpecialities">
          Skills And Specialities
        </Label>
        <Col sm={4}>
          <Label for="bandId">
            Band <span className="text-danger ">*</span>
          </Label>
          <Select
            //  defaultValue={specialityIncomingData}
            //isMulti
            name="bandId"
            className="basic-multi-select"
            classNamePrefix="select"
            // onChange={(data) => setSelectedSpecialities(data)}
            onChange={(data) => setSelectedBand(data)}
            options={bands}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allowed only 30 characters",
              },
            })}
          />
          {!selectedBand && submitted && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                This field is required!
              </li>
            </ul>
          )}
        </Col>
        <Col sm={4}>
          <Label for="bandId">
            Role <span className="text-danger ">*</span>
          </Label>
          <Select
            //  defaultValue={specialityIncomingData}
            //isMulti
            name="roleId"
            className="basic-multi-select"
            classNamePrefix="select"
            // onChange={(data) => setSelectedSpecialities(data)}
            onChange={(data) => setSlectedRole(data)}
            options={roles}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allowed only 30 characters",
              },
            })}
          />
          {!selectedRole && submitted && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                This field is required!
              </li>
            </ul>
          )}
        </Col>
        <Col sm={4}>
          <Label for="bandId">
            Specialities <span className="text-danger ">*</span>
          </Label>
          <Select
            //  defaultValue={specialityIncomingData}
            isMulti
            name="specialityIds"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(data) => setSelectedSpecialities(data)}
            options={speciality}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allowed only 30 characters",
              },
            })}
          />
          {!selectedSpecialities && submitted && (
            <ul
              className="list-unstyled text-sm mt-1 text-muted filled"
              id="parsley-id-119"
            >
              <li className="parsley-required text-danger">
                This field is required!
              </li>
            </ul>
          )}
        </Col>
      </FormGroup>

      <FormGroup row>
        <Label sm={4}>Other Information</Label>
        <Col sm={8}>
          <Input
            type="textarea"
            name="otherInformation"
            className="capitalize"
            //    defaultValue={fieldData}
            invalid={errors.otherInformation}
            innerRef={register({
              required: {
                value: false,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={4}>Expiry Date</Label>
        <Col sm={8}>
          <Input
            type="date"
            name="expiryDate"
            //  defaultValue={fieldData}
            invalid={errors.expiryDate}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 150,
                message: "This field only allowed only 150 characters",
              },
            })}
          ></Input>
        </Col>
      </FormGroup>

      <Row className="justify-content-end">
        <div className="d-flex ">
          {" "}
          <Button
            className="mr-2"
            color="success"
            type="submit"
            onClick={() => setSubmitted(true)}
            disabled={addKillLog}
          >
            {mode}
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    reduxClients: state.initialDataGetReducer.clients,
    reduxSpecialities: state.initialDataGetReducer.specialities,
    reduxBands: state.initialDataGetReducer.bands,
    reduxRoles: state.initialDataGetReducer.roles,
  };
}

export default connect(mapStateToProps, {})(AddAJob);
