import { useEffect, useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";

export default function useCandidateAssignToConsultant(
  url,
  headhunterData,
  number,
  start
) {
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useDidMountEffect(()=>{
    setResults([])
  },[])
  
  useDidMountEffect(() => {
    setIsLoading(true);

    axios({
      method: "GET",
      url: url,
      params: { page: page, offset: 50 },
    })
      .then((res) => {
        let data = res.data.body.content;
        const filteredResults = data.filter(
          (item) =>
            (item.headhunters?.length === 1 &&
              item.headhunters[0] !== headhunterData) ||
            item.headhunters.length === 0
        );

        if (data.length === 0) {
          return;
        }
        if (results.length < number && data.length !== 0) {
          setResults([...results, ...filteredResults]);
          setPage(page + 1);
        } else {
          return;
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [page, start]);

  // console.log("data length angi--------->", results);
  return { isLoading, results };
}
