// import React, { useState, useCallback } from "react";
// import Cropper from "react-easy-crop";
// import { Button, Input } from "reactstrap";
// import getCroppedImg from "./cropImage";

// function ImageEdit(props) {
//   //   const [image, setImage] = useState();
//   const { image,editedImage } = props;

//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [rotation, setRotation] = useState(0);
//   const [zoom, setZoom] = useState(1);
//   const [aspect, setAspect] = useState(400 / 200);

//   const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
//   const [croppedImage, setCroppedImage] = useState(null);

//   const onCropChange = (crop) => {
//     setCrop(crop);
//   };

//   const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
//     setCroppedAreaPixels(croppedAreaPixels);
//   }, []);

//   const showCroppedImage = useCallback(async () => {
//     try {
//       const croppedImage = await getCroppedImg(
//         image,
//         croppedAreaPixels,
//         rotation
//       );
//       console.log("donee", { croppedImage });
//       setCroppedImage({croppedImage});
//       editedImage(croppedImage)
//     } catch (e) {
//       console.error(e);
//     }
//   }, [croppedAreaPixels, rotation]);

//   const onClose = useCallback(() => {
//     setCroppedImage(null);
//   }, []);

//   const onZoomChange = (zoom) => {
//     setZoom(zoom);
//   };

//   return (
//     <div>
//       <div
//         style={{
//           position: "relative",
//           width: "100%",
//           height: 400,
//           background: "#333",
//         }}
//       >
//         <Cropper
//           image={image}
//           crop={crop}
//           aspect={aspect}
//           zoom={zoom}
//           rotation={rotation}
//           onCropChange={onCropChange}
//           onRotationChange={setRotation}
//           onCropComplete={onCropComplete}
//           onZoomChange={onZoomChange}
//           style={{ width: 100 }}
//         />
//       </div>
//       <br></br>
//       Zoom:
//       <Input
//         type="range"
//         min={1}
//         max={100}
//         value={zoom}
//         name="range"
//         onChange={(e) => {
//           setZoom(e.target.value);
//         }}
//       />
//       <br></br>
//       Rotate:{" "}
//       <select value={rotation} onChange={(e) => setRotation(e.target.value)}>
//         <option value="0">0 Degrees</option>
//         <option value="45">45 Degrees</option>
//         <option value="90">90 Degrees</option>
//         <option value="135">135 Degrees</option>
//         <option value="135">180 Degrees</option>
//         <option value="225">225 Degrees</option>
//         <option value="270">270 Degrees</option>
//         <option value="315">315 Degrees</option>
//       </select>
//       <br></br>
//       <Input
//         type="range"
//         min={0}
//         max={360}
//         value={rotation}
//         name="range"
//         onChange={(e) => {
//           setRotation(e.target.value);
//         }}
//       />
//       <Button
//         onClick={showCroppedImage}
//         variant="contained"
//         color="primary"
//         // classes={{ root: classes.cropButton }}
//       >
//         Show Result
//       </Button>
//     </div>
//   );
// }

// export default ImageEdit;

import React, { useEffect, useState } from "react";
import Cropper from "react-cropper";

import "cropperjs/dist/cropper.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faArrowAltCircleLeft,
  faArrowCircleLeft,
  faCaretLeft,
  faChevronCircleLeft,
  faCropAlt,
  faGripHorizontal,
  faPortrait,
} from "@fortawesome/free-solid-svg-icons";
import { Button, UncontrolledTooltip } from "reactstrap";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";

export default function ImageEdit(props) {
  const { image, editedImage } = props;

  let fileReader = new FileReader();
  let cropper = React.createRef();

  const [imageData, setImageData] = useState({
    name: "",
    size: 0,
    type: "",
    imageSrc: "",
  });
  
  const [base64, setBase64] = useState("");
 
  const [croppedImgSrc, setCroppedImgSrc] = useState(null);
  const [crop, setCrop] = useState(1 / 1.4142);

  const handleFileRead = (e) => {
    const binaryData = fileReader.result;
    const base64Data = window.btoa(binaryData);
    setBase64(base64Data);
  };

  const handleChange = (event) => {
    // const imageSrcTemp = URL.createObjectURL(image);

    setImageData({
      name: event.name,
      size: event.size,
      type: event.type,
      imageSrc: event.preview,
    });
    setCroppedImgSrc(null);

    handleFileRead();
    fileReader.readAsBinaryString(event);
  };

  useEffect(() => {
    handleChange(image);
  }, []);

  const handleCropChange = () => {
    console.log("## cropped !");
    const croppedImgData = cropper.current.cropper
      .getCroppedCanvas()
      .toDataURL();
    setCroppedImgSrc(croppedImgData);
  };

  const handleRotate = () => {
    cropper.current.cropper.rotate(90);
    handleCropChange();
  };

  useDidMountEffect(() => {
    editedImage(croppedImgSrc);
  }, [croppedImgSrc]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12 col-md-6">
          <h5>Original Image</h5>
          <Cropper
            // style={{ maxWidth: "600px", height: "400px" }}
            ref={cropper}
            src={imageData.imageSrc}
            cropend={() => handleCropChange()}
            autoCropArea={1}
            viewMode={3}
           />

          <div className="d-flex justify-content-center m-2">
            <Button onClick={() => handleRotate()}>Rotate</Button>
          </div>
        </div>
        <div className="col-sm-12 col-md-6">
          <h5>Cropped Image</h5>
          <img
            src={croppedImgSrc ? croppedImgSrc : imageData.imageSrc}
            style={{width:"100%"}}
           />
        </div>
        {/* <Button onClick={() => setReset(true)}>Reset</Button> */}
      </div>
    </div>
  );
}
