const initState = {
    chatfunkyId: "waiting"
}

const chatReducer = (chatstate = initState, action) => {
    
    if (action.type === 'chatScriptSelection') {

        initState.chatfunkyId = action.chatId
       
        }
        // console.log("Chat action", action);
        return initState;
}
   

export default chatReducer;