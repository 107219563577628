import React, { useState, useEffect } from "react";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  Alert,
  UncontrolledTooltip,
  Row,
} from "reactstrap";
import {
  WARDS,
  REGISTER_CANDIDATE,
  CLIENTS,
  CREATE_BOOKING,
  SHIFTS,
  HOSTMexxar,
  CompanyName,
  ClientLable,
} from "../../../configs/api-config";
import {
  faExclamationCircle,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import moment from "moment";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import { difference, forEach, uniqBy, filter } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { loginRequest } from "../../../authConfig";
import { useMsal } from "@azure/msal-react";
import { sendEmail } from "../../../pages/email/graph";
import { connect } from "react-redux";
import outlookLogo from "../../../../src/images/Outlook-Logo.png";
import { sendNotification } from "../../../util/common/sendNotification";

function BookingSummery(props) {
  const HostMexxarApi = HOSTMexxar;
  const MexxarWards = WARDS;
  const MexxarCandidate = REGISTER_CANDIDATE;
  const MexxarApiClients = CLIENTS;
  const MexxarApiBooking = CREATE_BOOKING;
  const MexxarShift = SHIFTS;

  const {
    candidate,
    onCreate,
    closeModal,
    candidateId,
    type,
    heading,
    dayData,
    reset,
    currentResetState,
    user,
    incomingPage,
  } = props;

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();

  const [ward, setWard] = useState(null);
  const [shifts, setShifts] = useState([]);
  const [client, setClient] = useState(null);
  const [likeWards, setLikeWards] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [summeryData, setSummeryData] = useState(null);
  const [disLikeWards, setDisLikeWards] = useState(null);
  const [wardsInLikeClients, setWardsInLikeClients] = useState(null);
  const [bookingStatusChange, setBookingStatusChange] = useState(null);
  const [outlookLoggedIn, setOutlookLoggedIn] = useState(false);
  const [outlookLoggedInError, setOutlookLoggedInError] = useState(false);

  const getWardData = () => {
    axios
      .all([
        axios.get(MexxarWards),
        axios.get(MexxarCandidate + "/" + candidateId),
      ])

      .then(
        axios.spread((res1, res2, res3) => {
          //  let diffArray = difference(res1.data.body, res2.data.body);

          //get candidat's prefered client's ids

          ////.........Arranging candidate's like ward list.......////
          let likeWardData = [];
          forEach(res2.data.body[0].likeWards, function (item) {
            likeWardData.push({
              value: item.name,
              label: item.name,
              id: item.id,
            });
          });
          setLikeWards(likeWardData);

          ////.........Arranging candidate's Dislike ward list.......////
          let dislikeWardData = [];
          forEach(res2.data.body[0].dislikeWards, function (item) {
            dislikeWardData.push({
              value: item.name,
              label: item.name,
              id: item.id,
            });
          });
          setDisLikeWards(dislikeWardData);

          let preferredClients = res2.data.body[0].preferredClients;

          let mainWardList = [];
          for (let index = 0; index < preferredClients.length; index++) {
            const element = preferredClients[index];

            axios
              .get(MexxarApiClients + "/" + element.id + "/wards")
              .then((res) => {
                if (res.data.status == "success") {
                  let wardList = [];
                  forEach(res.data.body, function (item) {
                    mainWardList.push({
                      value: item.wardName,
                      label: item.wardName,
                      id: item.wardId,
                    });
                  });

                  setWardsInLikeClients(uniqBy(mainWardList, "id"));
                } else {
                  // ToastCommon(true, "duplicate", addToast);
                }
              })
              .catch((err) => {
                //console.log(err)
                ToastCommon(true, "error", addToast);
              });
          }

          //with these ids get the wards of each client
          //arrange it as an array
          //get the difference from above diffarray
          //map that to the input list down.
        })
      )
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };
  

  // const changeBookingStatus = (data) => {
  //   axios
  //     .put(
  //       MexxarApiBooking +
  //         "/" +
  //         updateData.id +
  //         "/attended-status/" +
  //         bookingStatusChange
  //     )
  //     .then((res) => {
  //       if (res.data.status == "success") {
  //         cancelBooking(data);
  //       } else {
  //         ToastCommon(true, "error", addToast);
  //       }
  //     })
  //     .catch((err) => {
  //       ToastCommon(true, "error", addToast);
  //     });
  // };
  const [note, setNote] = useState('');
  const onSubmit = (data) => {
    changeBookingStatus(data, note);
  };
  
  const changeBookingStatus = (data, note) => {
    const requestBody = { note: note }; // Prepare the request body
    axios
      .put(
        `${MexxarApiBooking}/${updateData.id}/attended-status/${bookingStatusChange}`,
        requestBody
      )
      .then((res) => {
        if (res.data.status === "success") {
          cancelBooking(data);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };
  const cancelBooking = (data) => {
    axios
      .delete(MexxarApiBooking + "/" + updateData.id)

      .then((res) => {
        if (res.data.status == "success") {
          ToastCommon(true, "delete", addToast);
          sendBookingConfirmationEmail(dayData, candidate);
          reset(!currentResetState);
          closeModal();
        } else if (res.data.status == "timesheet-attached") {
          ToastCommon(true, "timesheet", addToast);
        } else {
          // ToastCommon(true, "error", addToast);
        }
      })

      .catch((err) => {
        //  ToastCommon(true, "error", addToast);
      });
  };

  //Function : sending the cancellation email to the candidate

  const { instance, accounts } = useMsal();
  const sendBookingConfirmationEmail = (data, candidateWhenClick) => {
    let subject = `Booking Cancellation: ${data?.referenceNo}`;
    let body = `<p>Hello ${
      candidateWhenClick?.firstName + " " + candidateWhenClick?.lastName
    },</p>
                  <p>We are sorry to inform you that your booking with ${CompanyName} for ${moment(
                    data?.bookingDate
                  ).format(
                    "ddd, DD-MM-YYYY"
                  )} has been cancelled. Please find the details below:</p>
                  <ul>
                      <li>Reference no: ${data?.referenceNo}</li>
                      <li>Date: ${moment(data?.bookingDate).format(
                        "ddd, DD-MM-YYYY"
                      )}</li>
                      <li>Hospital: ${data?.hospitalName}</li>
                      <li>Ward: ${data?.wardName}</li>
                      <li>Start time: ${moment
                        .utc(data?.shiftFrom)
                        .local()
                        .format("h:mm A")}</li>
                      <li>End time: ${moment
                        .utc(data?.shiftTo)
                        .local()
                        .format("h:mm A")}</li>
                  </ul>
                  <p>Please feel free to reach out to your dedicated consultant if you have any further inquiries or require clarification regarding this cancellation.</p>
      `;

    let to = [
      {
        id: candidateWhenClick?.id,
        value: candidateWhenClick?.email,
        label:
          candidateWhenClick?.firstName + " " + candidateWhenClick?.lastName,
      },
    ];
    let cc = [];
    let bcc = [];
    let file = [];
    // console.log("user Profile---angi->", subject, to, body);
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        sendEmail(response?.accessToken, to, cc, bcc, subject, body, file, user)
          .then((response) => {
            ToastCommon(true, "sent", addToast);
            closeModal();
          })
          .catch((error) => {});
      })
      .catch((error) => console.log(error));
  };

  // const onSubmit = (data) => {
  //   changeBookingStatus(data);
  // };

  const getBookingSummery = () => {
    axios
      .get(
        MexxarApiBooking +
          "/candidate-booking?candidateId=" +
          candidateId +
          "&bookingDate=" +
          moment(dayData.date).format("YYYY/MM/DD")
      )

      .then((res) => {
        if (res.data.status == "success") {
          setSummeryData(res.data.body[0]);
        } else {
          // ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const getShifts = () => {
    axios.get(MexxarShift).then((res) => {
      setShifts(res.data.body);
    });
  };

  const getClientandWardData = () => {
    axios.get(MexxarApiClients + "/" + dayData.hospitalId).then((res) => {
      setClient(res.data.body[0]);
    });
    axios
      .get(HostMexxarApi + "client-wards/clients/" + dayData.hospitalId)
      .then((res) => {
        let tempWard = filter(res.data.body, (item) => {
          return dayData.wardName === item.wardName;
        });
        let tempSetWard = [];
        if (tempWard.length > 0) {
          tempSetWard.push({
            wardName: tempWard[0].wardName,
            wardId: tempWard[0].wardId,
            phoneNumber:
              tempWard[0].phoneNumbers.length > 0
                ? tempWard[0].phoneNumbers[0].phoneNumber
                : "",
          });
        }
        setWard(tempSetWard);
      });
  };

  function checkOutlookLoginStatus() {
    if (accounts.length > 0) {
      // User is logged in
      setOutlookLoggedIn(true);
    } else {
      // User is not logged in
      setOutlookLoggedIn(false);
    }
  }

  const handleLoginOutLook = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        // Successful login

        if (response && response?.account) {
          setOutlookLoggedIn(true);
        } else {
          setOutlookLoggedIn(false);
          setOutlookLoggedInError(true);
        }
      })
      .catch((error) => {
        // Handle login error
        setOutlookLoggedIn(false);
        setOutlookLoggedInError(true);
      });
  };

  useEffect(() => {
    // Call the function to check the login status
    checkOutlookLoginStatus();
  }, []);

  useEffect(() => {
    setUpdateData({
      id: dayData.id,
      availabilityStatus: dayData.availabilityStatus,
    });

    //   getShifts();
    //  getBookingSummery();
    // getWardData();
    getClientandWardData();
    // getLikeWards();
  }, []);

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Col sm={4}>
            <Label>Ref No</Label>
          </Col>
          <Col sm={1}>
            <Label>:</Label>
          </Col>

          <Col sm={7}>
            <Label>{dayData.referenceNo}</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <Label>Date</Label>
          </Col>
          <Col sm={1}>
            <Label>:</Label>
          </Col>
          <Col sm={7}>
            <Label>{moment(dayData.date).format("L")}</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <Label>Start Time</Label>
          </Col>
          <Col sm={1}>
            <Label>:</Label>
          </Col>
          <Col sm={7}>
            <Label>{moment(dayData.shiftFrom).format("LT")}</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <Label>End Time</Label>
          </Col>
          <Col sm={1}>
            <Label>:</Label>
          </Col>
          <Col sm={7}>
            <Label>{moment(dayData.shiftTo).format("LT")}</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <Label>Shift Type </Label>
          </Col>
          <Col sm={1}>
            <Label>:</Label>
          </Col>
          <Col sm={7}>
            <Label>{dayData.shift.name}</Label>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col sm={4}>
            <Label>
              Department
              <FontAwesomeIcon
                icon={faPhone}
                className="fa-lg ml-2 btn-raised btn-wave text-success  "
                id="callDepartment"
                style={{
                  boxShadow: "none",
                }}
              />
            </Label>
          </Col>
          <UncontrolledTooltip target="callDepartment">
            {ward != null ? ward.phoneNumber : "Not Added"}
          </UncontrolledTooltip>
          <Col sm={1}>
            <Label>:</Label>
          </Col>

          <Col sm={7}>
            <Label> {dayData.wardName}</Label>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Col sm={4}>
            <Label>
              {ClientLable}
              <FontAwesomeIcon
                icon={faPhone}
                className="fa-lg ml-5 btn-raised btn-wave text-success  "
                id="callClient"
                style={{
                  boxShadow: "none",
                }}
              />
              <UncontrolledTooltip target="callClient">
                {client != null ? client.primaryMobile : null}
              </UncontrolledTooltip>
            </Label>
          </Col>
          <Col sm={1}>
            <Label>:</Label>
          </Col>

          <Col sm={7}>
            <Label>{dayData.hospitalName}</Label>
          </Col>
        </FormGroup>

        <hr />

        <FormGroup>
          <Label>Special Rate </Label>

          <FormGroup row>
            <Col sm={3}>
              <Label>Up Lift</Label>
            </Col>
            <Col sm={1}>
              <Label>:</Label>
            </Col>

            <Col sm={2}>
              <Label>£ {dayData.uplift} </Label>
            </Col>

            <Col sm={3}>
              <Label> | Bonus</Label>
            </Col>
            <Col sm={1}>
              <Label>:</Label>
            </Col>

            <Col sm={2}>
              <Label>£ {dayData.bonus} </Label>
            </Col>
          </FormGroup>
        </FormGroup>

        <hr />

        {bookingStatusChange != null ? (
          <Alert color="danger">
            <FontAwesomeIcon icon={faExclamationCircle} className="mx-2" />
            Are you sure you want to change the status of the booking?
          </Alert>
        ) : null}

        {incomingPage == "BOOKING_WORKSPACE" ||
        incomingPage == "CANDIDATE_CALENDAR" ? (
          <FormGroup>
            <Label>Change the status of the booking</Label>
            <hr />

            <FormGroup row className="ml-3 mt-3">
              <Col sm={6}>
                <Input
                  type="radio"
                  name="radio1"
                  value="HealthRoster"
                  // onChange={changeHandler}

                  onChange={() =>
                    setBookingStatusChange("CANCELED_BY_HOSPITAL")
                  }
                />
                <Label>Cancelled by {ClientLable}</Label>
              </Col>
              <Col sm={6}>
                <Input
                  type="radio"
                  name="radio1"
                  onChange={() =>
                    setBookingStatusChange("CANCELED_BY_CANDIDATE")
                  }
                />
                <Label>Cancelled by candidate</Label>
              </Col>
              <br/><br/>


              
            </FormGroup>
            <hr/>
            {bookingStatusChange != null ? (
  <FormGroup>
     <Col >
    <Label>Booking Cancellation Note</Label>
    <Input
      type="textarea"
      name="cancel"
      id="cancel"
      value={note}
      onChange={(e) => setNote(e.target.value)}
    />
    </Col>
  </FormGroup>
) : null}
<hr/>
          </FormGroup>
        ) : null}

        {!outlookLoggedIn && bookingStatusChange != null && (
          <Alert
            className="d-flex align-items-center justify-content-center flex-column "
            color="danger"
          >
            <p>
              To send a booking cancellation via email, kindly ensure that you
              are logged in first
            </p>
            <img src={outlookLogo} style={{ height: 100 }}></img>
            <u className="text-info pointer ml-4" onClick={handleLoginOutLook}>
              Login
            </u>
          </Alert>
        )}
      <div className="d-flex justify-content-end">
          {/* <Button
            className="mr-2"
            color="success"
            // onClick={() => setShowSpecialRates(true)}
          >
            Special Booking
          </Button>{" "} */}

          {bookingStatusChange != null ? (
            <Button
              className="mr-2"
              color="danger"
              type="submit"
              disabled={!outlookLoggedIn}
              onClick={() => {
               
                sendNotification({
                  notificationType: "BOOKING",
                  title: "Booking Status Changed",
                  content: `Your booking has been canceled or rejected.`,
                  candidateId: dayData.id,
                  startDate: new Date(),
                  addToast: addToast
                });
              }}
            >
              Change
            </Button>
          ) : null}

          <Button color="secondary" onClick={closeModal}>
            Close
          </Button>
        </div>
      </Form>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.auth.item,
  };
}

export default connect(mapStateToProps, {})(BookingSummery);
