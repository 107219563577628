import React from "react";
 
const NoOutlookResult = (props) => {
  const { page } = props;
  return (
    <div className="d-flex  flex-column justify-content-center align-items-center ">
      <div className="d-flex flex-column  align-items-center ">
        <h3 className={"  mb-3 text-info font-weight-bold"}>No Emails found</h3>
        <small className={" mb-2 text-color"}>
          {page == "email"
            ? "It seems there are no emails/matches."
            : ` It seems there are no matches. Please try a different
          ${
            page == "reschedule" ? "date" : "keyword/criteria"
          } or refresh the page`}
        </small>
      </div>
      <div>
        <img src="https://cdni.iconscout.com/illustration/premium/thumb/search-result-not-found-2130355-1800920.png" alt="." style={{ height: "250px" }} />
      </div>
    </div>
  );
};

export default NoOutlookResult;