import { Link } from "react-router-dom";
import { HOSTMexxar, HostMexxarURL } from "../../configs/api-config";
import notNull from "./notNull";


 {/*    //add this part later to have a border around the avatr to indicates active status if needed
  <div
                className={(() => {
                  switch (userProfile.status) {
                    case "ACTIVE":
                      return "candidate-active";

                    case "DEACTIVE":
                      return "candidate-de-active";

                    case "DOMENT":
                      return "candidate-doment";

                    default:
                      return null;
                      break;
                  }
                })()}
              ></div> */}

export default function AvatarMexxar(props) {
  const {
    candidateId,
    primaryPhone,
    avatar,
    gender,
    onClick,
    avatarSize = "w-64",
    pointer = true,
    userType="CANDIDATE"
  } = props;

  const avatarDefaultUrl = () => {
    var defaultsAvatarUrls;

    if (notNull(gender)) {
      switch (gender) {
        case "Male":
          defaultsAvatarUrls = HostMexxarURL+"images/maleCandidate.png";
          break;
        case "MALE":
          defaultsAvatarUrls = HostMexxarURL+"images/maleCandidate.png";
          break;
        case "Female":
          defaultsAvatarUrls = HostMexxarURL+"images/femaleCandidate.png";
          break;
        case "FEMALE":
          defaultsAvatarUrls = HostMexxarURL+"images/femaleCandidate.png";
          break;
        default:
          defaultsAvatarUrls = HostMexxarURL+"images/default.jpg";
          break;
      }
    } else {
      defaultsAvatarUrls = HostMexxarURL+"images/default.jpg";
    }
    return defaultsAvatarUrls;
  };

  return (
    <div className="d-flex align-items-center p-1 justify-content-center my-2">
      <Link
        to={{
          pathname:
            "/dashboard/candidate-profile/" + candidateId + "/" + primaryPhone,
        }}
        style={{ cursor: pointer ? "pointer" : "default" }}
      >
        <span
          onClick={onClick}
          className={`${avatarSize} avatar`}
          style={{
            maxHeight: "10rem",
            maxWidth: "10rem",
            borderRadius: "50%",
          }}
        >
          <img
            src={
              avatar == null
                ? avatarDefaultUrl()
                : `${HOSTMexxar}pro-pic/${avatar.split("/")[1]}`
            }
            alt=""
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
              objectFit: "cover",
            }}
          ></img>
        </span>
      </Link>
    </div>
  );
}
