// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import moment from "moment";
// import Select from "react-select";
// import { connect } from "react-redux";
// import { useForm } from "react-hook-form";
// import { result, forEach, filter } from "lodash";
// import useDidMountEffect from "./intialRunUseEffect";
// import { useToasts } from "react-toast-notifications";
// import ToastCommon from "../../toastCommon";
// import {
//   Button,
//   Label,
//   Input,
//   Form,
//   FormGroup,
//   Col,
//   Alert,
//   UncontrolledTooltip,
// } from "reactstrap";
// import {
//   REGISTER_CANDIDATE,
//   WARDS,
//   SHIFTS,
//   CREATE_BOOKING,
//   BOOKING_WORKSPACE_CANDIDATELIST,
//   HOSTMexxar,
//   CLIENTS,
// } from "../../../configs/api-config";
// import { useMsal } from "@azure/msal-react";
// import { loginRequest } from "../../../authConfig";
// import { sendEmail } from "../../../pages/email/graph";
// import outlookLogo from "../../../../src/images/Outlook-Logo.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
// import {
//   bandsGetAction,
//   clientsGetAction,
//   departmentsGetAction,
//   rolesGetAction,
//   specialitiesGetAction,
// } from "../../../redux/actions/initialDataGetAction";
// import store from "../../../redux/store";

// function AddShiftFormModal(props) {
//   const { register, handleSubmit, watch, errors } = useForm();

//   const {
//     onCreate,
//     closeModal,
//     candidateId,
//     reset,
//     currentResetState,
//     dayData,
//     candidateWhenClick,
//     auth,
//     incommingPage,
//     user,
//     reduxSpecialities,
//     reduxClients,
//     reduxDepartments,
//     reduxRoles,
//     reduxBands,
//   } = props;

//   const MexxarApi = HOSTMexxar;
//   const MexxarShift = SHIFTS;
//   const MexxarApiDepartments = WARDS;
//   const MexxarCandidate = REGISTER_CANDIDATE;
//   const MexxarCreateBooking = CREATE_BOOKING;
//   const MexxarApiBookingWorkspace = BOOKING_WORKSPACE_CANDIDATELIST;

//   const { addToast } = useToasts();

//   //drop down lists
//   const [roles, setRoles] = useState(null);
//   const [clients, setClients] = useState(null);
//   const [bands, setBands] = useState(null);
//   const [departments, setDepartments] = useState(null);

//   const [update, setUpdate] = useState(false);
//   const [clientRate, setClientRate] = useState();
//   const [editable, setEditable] = useState(false);
//   const [specialities, setSpecialities] = useState(null);
//   const [clientID, setClientID] = useState(null);

//   const [check, setCheck] = useState(false);
//   const [shifts, setShifts] = useState(null);
//   const [likeWards, setLikeWards] = useState([]);
//   const [candidates, setCandidates] = useState(null);
//   const [currentShift, setCurrentShift] = useState(0);
//   const [selectedClient, setSelectedClient] = useState(0);
//   const [selectedWard, setSelectedWard] = useState(0);
//   const [dataErrorStatus, setDataErrorStatus] = useState(false);
//   const [dateSelected, setDateSelected] = useState();
//   const [showSpecialRates, setShowSpecialRates] = useState(false);
//   const [previousBooking, setPreviousBooking] = useState(false);

//   const [candidateSpecialities, setCandidateSpecialities] = useState();
//   const [disableCreateButton, setDisableCreateButton] = useState(false);
//   const [matchingLikeWardsWithClient, setMatchingLikeWardsWithClient] =
//     useState(null);
//   const [selectedCandidates, setSelectedCandidates] = useState(candidateId);
//   const [outlookLoggedIn, setOutlookLoggedIn] = useState(false);
//   const [outlookLoggedInError, setOutlookLoggedInError] = useState(false);

//   const onSubmit = (data) => {
//     if (!dataErrorStatus) {
//       let submitData = data;
//       let date = new Date(data.date).toISOString();
//       submitData.endTime = data.endTime + ":00";
//       submitData.startTime = data.startTime + ":00";
//       submitData.date = date;
//       submitData.employeeId = user.id;
//       submitData.sex = "";
//       submitData.clientId = parseInt(data.clientId);
//       axios({
//         method: "POST",
//         url: HOSTMexxar + "otherShift/dto",
//         data: submitData,
//       })
//         .then((res) => {
//           if (res.data.status === "success") {
//             ToastCommon(true, "success", addToast);
//             closeModal();
//           }
//         })
//         .catch((error) => {
//           ToastCommon(true, "error", addToast);
//         });
//     }
//   };

//   const { instance, accounts } = useMsal();

//   const getData = () => {
//     axios
//       .all([axios.get(MexxarShift), axios.get(CLIENTS)])
//       .then(
//         axios.spread((res1, res2) => {
//           let preferedClient = [];
//           forEach(res2.data.body[0].preferredClients, function (client) {
//             preferedClient.push({
//               id: client.id,
//               name: client.name,
//             });
//           });

//           let specialities = [];
//           forEach(
//             res2.data.body[0].candidateSpecialities,
//             function (speciality) {
//               specialities.push({
//                 id: speciality.id,
//                 name: speciality.name,
//               });
//             }
//           );

//           setShifts(res1.data.body);
//           setCandidateSpecialities(specialities);
//           //   setSelectedClient(preferedClient[0]);
//         })
//       )
//       .catch((err) => {});
//   };

//   useEffect(() => {
//     setSpecialities(reduxSpecialities);
//     setClients(reduxClients);
//     setRoles(reduxRoles);
//     setBands(reduxBands);
//   }, [
//     reduxSpecialities,
//     reduxClients,
//     reduxDepartments,
//     reduxRoles,
//     reduxBands,
//   ]);

//   useEffect(() => {
//     store.dispatch(specialitiesGetAction());
//     store.dispatch(clientsGetAction());
//     store.dispatch(departmentsGetAction());
//     store.dispatch(rolesGetAction());
//     store.dispatch(bandsGetAction());
//   }, []);

//   const changeHandler = (e) => {
//     if (e.target.value === "CONFIRMED") {
//       setCheck(true);
//     } else if (e.target.value === "UnCONFIRMED") {
//       setCheck(false);
//     }
//   };

//   const getwardsInSelectedClient = () => {
//     axios
//       .get(MexxarApi + "client-wards/clients/" + selectedClient?.id)
//       .then((res) => {
//         let wardsList = [];
//         forEach(res.data.body, function (ward) {
//           wardsList.push({
//             id: ward.wardId,
//             name: ward.wardName,
//             label: ward.wardName,
//           });
//         });

//         setSelectedWard(wardsList[0]);
//         setMatchingLikeWardsWithClient(wardsList);
//       });
//   };

//   function checkOutlookLoginStatus() {
//     if (accounts.length > 0) {
//       // User is logged in
//       setOutlookLoggedIn(true);
//     } else {
//       // User is not logged in
//       setOutlookLoggedIn(false);
//     }
//   }

//   const handleLoginOutLook = () => {
//     instance
//       .loginPopup(loginRequest)
//       .then((response) => {
//         // Successful login

//         if (response && response?.account) {
//           setOutlookLoggedIn(true);
//         } else {
//           setOutlookLoggedIn(false);
//           setOutlookLoggedInError(true);
//         }
//       })
//       .catch((error) => {
//         // Handle login error
//         setOutlookLoggedIn(false);
//         setOutlookLoggedInError(true);
//       });
//   };

//   useEffect(() => {
//     // Call the function to check the login status
//     checkOutlookLoginStatus();
//   }, []);

//   useDidMountEffect(() => {
//     getwardsInSelectedClient();
//     return () => {};
//   }, [selectedClient]);

//   useDidMountEffect(() => {
//     getData();
//     return () => {};
//   }, [selectedCandidates]);

//   useEffect(() => {
//     // getCandidates();
//     getData();
//     return () => {};
//   }, []);

//   return (
//     <>
//       <Form onSubmit={handleSubmit(onSubmit)}>
//         <FormGroup row>
//           <Label sm={3}>Ref No</Label>
//           <Col sm={8}>
//             <Input
//               type="text"
//               invalid={errors.refNo}
//               placeholder="Ref No"
//               name="refNo"
//               innerRef={register({ required: true })}
//             />
//             {errors.refNo && (
//               <ul
//                 className="list-unstyled text-sm mt-1 text-muted filled"
//                 id="parsley-id-119"
//               >
//                 <li className="parsley-required text-danger">
//                   This field is required
//                 </li>
//               </ul>
//             )}
//           </Col>
//         </FormGroup>
//         <FormGroup row>
//           <Label sm={3}>Date</Label>
//           <Col sm={8}>
//             <Input
//               //   defaultValue={moment(new Date()).format("YYYY-MM-DD")}
//               type="date"
//               invalid={errors.date || dataErrorStatus}
//               placeholder="Date"
//               name="date"
//               innerRef={register}
//               onChange={(e) => {
//                 setDataErrorStatus(
//                   moment(e.target.value).format("l") == moment().format("l")
//                     ? false
//                     : moment(e.target.value).isBefore()
//                 );
//                 setDateSelected(e.target.value);
//               }}
//             />
//             {dataErrorStatus && (
//               <ul
//                 className="list-unstyled text-sm mt-1 text-muted filled"
//                 id="parsley-id-119"
//               >
//                 <li className="parsley-required text-danger">Invalid Date</li>
//               </ul>
//             )}
//           </Col>
//         </FormGroup>
//         <FormGroup row>
//           <Label sm={3}>Start Time</Label>
//           <Col sm={8}>
//             <Input
//               type="time"
//               placeholder="Start Time"
//               name="startTime"
//               invalid={errors.startTime}
//               innerRef={register({ required: true })}
//             />
//             {errors.startTime && (
//               <ul
//                 className="list-unstyled text-sm mt-1 text-muted filled"
//                 id="parsley-id-119"
//               >
//                 <li className="parsley-required text-danger">
//                   This field is required
//                 </li>
//               </ul>
//             )}
//           </Col>
//         </FormGroup>
//         <FormGroup row>
//           <Label sm={3}>End Time</Label>
//           <Col sm={8}>
//             <Input
//               type="time"
//               invalid={errors.endTime}
//               placeholder="End Time"
//               name="endTime"
//               innerRef={register({ required: true })}
//             />
//             {errors.endTime && (
//               <ul
//                 className="list-unstyled text-sm mt-1 text-muted filled"
//                 id="parsley-id-119"
//               >
//                 <li className="parsley-required text-danger">
//                   This field is required
//                 </li>
//               </ul>
//             )}
//           </Col>
//         </FormGroup>
//         <FormGroup row>
//           <Label sm={3}>Client</Label>
//           <Col sm={8}>
//             <Input
//               type="select"
//               id="clientId"
//               name="clientId"
//               defaultValue={0}
//               onChange={(e) => {
//                 const selectedOption = clients.find(
//                   (item) => item.id === parseInt(e.target.value)
//                 );
//                 setSelectedClient(selectedOption);
//               }}
//               invalid={errors.clientId}
//               innerRef={register({
//                 required: {
//                   value: true,
//                   message: "This field is required!",
//                 },
//               })}
//             >
//               <option value={0} id={0}>
//                 Select Client
//               </option>
//               {clients?.map((item, id) => {
//                 return (
//                   <option value={item.id} key={id}>
//                     {item.name}
//                   </option>
//                 );
//               })}
//             </Input>
//           </Col>
//         </FormGroup>

//         <FormGroup row>
//           <Label sm={3}>Department</Label>
//           <Col sm={8}>
//             <Input
//               type="select"
//               id="departmentId"
//               name="ward"
//               invalid={errors.ward}
//               defaultValue={0}
//               onChange={(e) => {
//                 const selectedOption = matchingLikeWardsWithClient.find(
//                   (item) => item.id === parseInt(e.target.value)
//                 );
//                 setSelectedWard(selectedOption);
//               }}
//               innerRef={register({
//                 required: {
//                   value: true,
//                   message: "This field is required!",
//                 },
//               })}
//             >
//               <option value={0}>Select Department</option>

//               {matchingLikeWardsWithClient &&
//                 matchingLikeWardsWithClient.map((item, id) => {
//                   return (
//                     <option value={item.name} key={id}>
//                       {item.name}
//                     </option>
//                   );
//                 })}
//             </Input>
//             {errors.ward && (
//               <ul
//                 className="list-unstyled text-sm mt-1 text-muted filled"
//                 id="parsley-id-119"
//               >
//                 <li className="parsley-required text-danger">
//                   This field is required
//                 </li>
//               </ul>
//             )}
//           </Col>
//         </FormGroup>
//         <FormGroup row>
//           <Label sm={3}>Note</Label>
//           <Col sm={8}>
//             <Input
//               type="text"
//               placeholder="Note"
//               name="note"
//               innerRef={register}
//             />
//           </Col>
//         </FormGroup>
//         <FormGroup row>
//           <Label sm={3}>Assignment</Label>
//           <Col sm={8}>
//             <Input
//               type="text"
//               placeholder="Assignment"
//               name="assignment"
//               innerRef={register}
//             />
//           </Col>
//         </FormGroup>
//         <FormGroup row>
//           <Label sm={3}>Secondary Assignment</Label>
//           <Col sm={8}>
//             <Input
//               type="text"
//               placeholder="Secondary Assignment"
//               name="secondaryAssignment"
//               innerRef={register}
//             />
//           </Col>
//         </FormGroup>
//         <FormGroup row>
//           <Label sm={3}>Training</Label>
//           <Col sm={8}>
//             <Input
//               type="text"
//               placeholder="Training"
//               name="training"
//               innerRef={register}
//             />
//           </Col>
//         </FormGroup>



//         {showSpecialRates ? (
//           <FormGroup row>
//             <Label sm={3}>Special Rate (£)</Label>

//             <Col sm={4}>
//               <Input
//                 type="text"
//                 placeholder="Up lift"
//                 name="upLift"
//                 invalid={errors.upLift}
//                 innerRef={register({
//                   required: {
//                     value: true,
//                     message: "This field is required!",
//                   },
//                   maxLength: {
//                     value: 30,
//                     message: "This field only allowed only 30 characters",
//                   },
//                   pattern: {
//                     value: /^[0-9]+$/,
//                     message: "Please input a number!",
//                   },
//                 })}
//               />{" "}
//               {errors.upLift && (
//                 <ul
//                   className="list-unstyled text-sm mt-1 text-muted filled"
//                   id="parsley-id-119"
//                 >
//                   <li className="parsley-required text-danger">
//                     {errors.upLift?.message}
//                   </li>
//                 </ul>
//               )}
//             </Col>
//             <Col sm={4}>
//               <Input
//                 type="text"
//                 placeholder="Bonus"
//                 name="bonus"
//                 invalid={errors.bonus}
//                 innerRef={register({
//                   required: {
//                     value: true,
//                     message: "This field is required!",
//                   },
//                   maxLength: {
//                     value: 30,
//                     message: "This field only allowed only 30 characters",
//                   },
//                   pattern: {
//                     value: /^[0-9]+$/,
//                     message: "Please input a number!",
//                   },
//                 })}
//               />{" "}
//               {errors.bonus && (
//                 <ul
//                   className="list-unstyled text-sm mt-1 text-muted filled"
//                   id="parsley-id-119"
//                 >
//                   <li className="parsley-required text-danger">
//                     {errors.bonus?.message}
//                   </li>
//                 </ul>
//               )}
//             </Col>
//           </FormGroup>
//         ) : null}

//         {!outlookLoggedIn && (
//           <Alert
//             className="d-flex align-items-center justify-content-center flex-column "
//             color="danger"
//           >
//             <p>
//               To send a booking confirmation via email, kindly ensure that you
//               are logged in first
//             </p>
//             <img src={outlookLogo} style={{ height: 100 }}></img>
//             <u className="text-info pointer ml-4" onClick={handleLoginOutLook}>
//               Login
//             </u>
//           </Alert>
//         )}
//         <div className="d-flex justify-content-end">
//           {/* <Button
//             className="mr-2"
//             color="success"
//             onClick={() => setShowSpecialRates(true)}
//           >
//             Special Booking
//           </Button>{" "} */}
//           {previousBooking && (
//             <Button
//               className="mr-2"
//               color="success"
//               type="submit"
//               disabled={disableCreateButton}
//             >
//               Previous Booking
//             </Button>
//           )}

//           {!previousBooking && (
//             <Button
//               className="mr-2"
//               color="success"
//               type="submit"
//               disabled={disableCreateButton || !outlookLoggedIn}
//             >
//               Create
//             </Button>
//           )}
//           <Button color="secondary" onClick={() => closeModal()}>
//             Cancel
//           </Button>
//         </div>
//       </Form>
//     </>
//   );
// }

// function mapStateToProps(state) {
//   return {
//     auth: state.auth,
//     user: state.auth.item,
//     reduxSpecialities: state.initialDataGetReducer.specialities,
//     reduxClients: state.initialDataGetReducer.clients,
//     reduxDepartments: state.initialDataGetReducer.departments,
//     reduxRoles: state.initialDataGetReducer.roles,
//     reduxBands: state.initialDataGetReducer.bands,
//   };
// }

// export default connect(mapStateToProps, {})(AddShiftFormModal);


import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { result, forEach, filter } from "lodash";
// import useDidMountEffect from "Modal/ModalBody/intialRunUseEffect";
import { useToasts } from "react-toast-notifications";
// import ToastCommon from "toastCommon";
import {
    Button,
    Label,
    Input,
    Form,
    FormGroup,
    Col,
    Alert,
    UncontrolledTooltip,
    Row,
} from "reactstrap";
import {
    REGISTER_CANDIDATE,
    WARDS,
    SHIFTS,
    CREATE_BOOKING,
    BOOKING_WORKSPACE_CANDIDATELIST,
    HOSTMexxar,
    CLIENTS,
    ClientLable,
} from "../../../configs/api-config";
import { useMsal } from "@azure/msal-react";
// import { loginRequest } from "/authConfig";
import outlookLogo from "../../../../src/images/Outlook-Logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import {
    bandsGetAction,
    clientsGetAction,
    departmentsGetAction,
    rolesGetAction,
    specialitiesGetAction,
} from "../../../redux/actions/initialDataGetAction";
import store from "../../../redux/store";
import useDidMountEffect from "./intialRunUseEffect";
import { loginRequest } from "../../../authConfig";
import ToastCommon from "../../toastCommon";




function AddShiftFormModal(props) {
    const { register, handleSubmit, watch, errors } = useForm();

    const {
        onCreate,
        closeModal,
        candidateId,
        reset,
        currentResetState,
        dayData,
        candidateWhenClick,
        auth,
        incommingPage,
        user,
        reduxSpecialities,
        reduxClients,
        reduxDepartments,
        reduxRoles,
        reduxBands,
    } = props;

    const MexxarApi = HOSTMexxar;
    const MexxarShift = SHIFTS;
    const MexxarApiDepartments = WARDS;
    const MexxarCandidate = REGISTER_CANDIDATE;
    const MexxarCreateBooking = CREATE_BOOKING;
    const MexxarApiBookingWorkspace = BOOKING_WORKSPACE_CANDIDATELIST;

    const { addToast } = useToasts();

    //drop down lists
    const [roles, setRoles] = useState(null);
    const [clients, setClients] = useState(null);
    const [bands, setBands] = useState(null);
    const [departments, setDepartments] = useState(null);

    const [update, setUpdate] = useState(false);
    const [clientRate, setClientRate] = useState();
    const [editable, setEditable] = useState(false);
    const [specialities, setSpecialities] = useState(null);
    const [clientID, setClientID] = useState(null);

    const [check, setCheck] = useState(false);
    const [shifts, setShifts] = useState(null);
    const [likeWards, setLikeWards] = useState([]);
    const [candidates, setCandidates] = useState(null);
    const [currentShift, setCurrentShift] = useState(0);
    const [selectedClient, setSelectedClient] = useState(0);
    const [selectedWard, setSelectedWard] = useState(0);
    const [dataErrorStatus, setDataErrorStatus] = useState(false);
    const [dateSelected, setDateSelected] = useState();
    const [showSpecialRates, setShowSpecialRates] = useState(false);
    const [previousBooking, setPreviousBooking] = useState(false);

    const [candidateSpecialities, setCandidateSpecialities] = useState();
    const [disableCreateButton, setDisableCreateButton] = useState(false);
    const [matchingLikeWardsWithClient, setMatchingLikeWardsWithClient] =
        useState(null);
    const [selectedCandidates, setSelectedCandidates] = useState(candidateId);
    const [outlookLoggedIn, setOutlookLoggedIn] = useState(false);
    const [outlookLoggedInError, setOutlookLoggedInError] = useState(false);
    const [contactPersons, setContactPersons] = useState([{}]);
    const [selectedTrustIdError, setSelectedTrustIdError] = useState(false);


    const removeMTAddMore = () => {
        contactPersons.pop(contactPersons);
        setContactPersons([...contactPersons]);
    };

    let filtered = contactPersons.filter(
        (item) => Object.keys(item).length === 0
    );

    const onSubmit = (data, id) => {

        console.log("sula onsubmit", data)
        console.log("sula contactPersons", contactPersons)
        const formArray = [];

        // Loop through contact persons and collect form data
        // if (id != null) {
        contactPersons.forEach((data, id) => {
            const startTimeValue = watch(`startTime${id}`);
            const endTimeValue = watch(`endTime${id}`);
            const clientIdValue = watch(`clientId${id}`);
            const dateValue = watch(`date${id}`);

            // Add ":00" to startTime and endTime
            const startTime = startTimeValue + ":00";
            const endTime = endTimeValue + ":00";
            const date = new Date(dateValue).toISOString();
            const clientId = parseInt(clientIdValue);
            const employeeId = user.id;
            const sex = "";


            // submitData.employeeId = user.id;
            //         submitData.sex = "";

            const formValues = {
                refNo: watch(`refNo${id}`),
                // date: watch(`date${id}`),
                date,
                startTime,
                endTime,
                // clientId: watch(`clientId${id}`),
                clientId,
                employeeId,
                sex,
                ward: watch(`ward${id}`),
                note: watch(`note${id}`),
                assignment: watch(`assignment${id}`),
                secondaryAssignment: watch(`secondaryAssignment${id}`),
                training: watch(`training${id}`),
            };

            formArray.push(formValues);
        });
        // } else {
        //     console.error('id is null or undefined');
        //     // Handle this case as needed
        // }
        // Now, formArray contains all the form data with ":00" added to startTime and endTime
        console.log(formArray);


        // Now, formArray contains all the form data with ":00" added to startTime and endTime
        console.log("formArray", formArray);

        // Loop through the form data and make Axios calls
        for (let i = 0; i < formArray.length; i++) {
            try {

                // axios({
                //     method: "POST",
                //     url: HOSTMexxar + "otherShift/dto",
                //     data: formArray[i],
                // })
                console.log("formArray[i]", formArray[i])

                axios({
                    method: "POST",
                    url: HOSTMexxar + "otherShift/dto",
                    data: formArray[i],
                })
                    .then((res) => {
                        if (res.data.status === "success") {
                            ToastCommon(true, "success", addToast);
                            closeModal();
                        }
                    })
                    .catch((error) => {
                        ToastCommon(true, "error", addToast);
                    });
            } catch (error) {
                // Handle errors
                console.error('Error making Axios call:', error);
                ToastCommon(true, "error", addToast);
            }
        }
        let selectedTrustIdErrorTEMP = false;
        let selectedFrameworkErrorTEMP = false;
        let selectedCountyErrorTEMP = false;

        if (parseInt(data.trustId) == 0) {
            selectedTrustIdErrorTEMP = true;
            setSelectedTrustIdError(true);
        } else {
            selectedTrustIdErrorTEMP = false;
            setSelectedTrustIdError(false);
        }
        // Optionally, you can reset the form or perform other actions after submission.
    };


    const { instance, accounts } = useMsal();

    const getData = () => {
        axios
            .all([axios.get(MexxarShift), axios.get(CLIENTS)])
            .then(
                axios.spread((res1, res2) => {
                    let preferedClient = [];
                    forEach(res2.data.body[0].preferredClients, function (client) {
                        preferedClient.push({
                            id: client.id,
                            name: client.name,
                        });
                    });

                    let specialities = [];
                    forEach(
                        res2.data.body[0].candidateSpecialities,
                        function (speciality) {
                            specialities.push({
                                id: speciality.id,
                                name: speciality.name,
                            });
                        }
                    );

                    setShifts(res1.data.body);
                    setCandidateSpecialities(specialities);
                    //   setSelectedClient(preferedClient[0]);
                })
            )
            .catch((err) => { });
    };

    useEffect(() => {
        setSpecialities(reduxSpecialities);
        setClients(reduxClients);
        setRoles(reduxRoles);
        setBands(reduxBands);
    }, [
        reduxSpecialities,
        reduxClients,
        reduxDepartments,
        reduxRoles,
        reduxBands,
    ]);

    useEffect(() => {
        store.dispatch(specialitiesGetAction());
        store.dispatch(clientsGetAction());
        store.dispatch(departmentsGetAction());
        store.dispatch(rolesGetAction());
        store.dispatch(bandsGetAction());
    }, []);

    const changeHandler = (e) => {
        if (e.target.value === "CONFIRMED") {
            setCheck(true);
        } else if (e.target.value === "UnCONFIRMED") {
            setCheck(false);
        }
    };

    const getwardsInSelectedClient = () => {
        axios
            .get(MexxarApi + "client-wards/clients/" + selectedClient?.id)
            .then((res) => {
                let wardsList = [];
                forEach(res.data.body, function (ward) {
                    wardsList.push({
                        id: ward.wardId,
                        name: ward.wardName,
                        label: ward.wardName,
                    });
                });

                setSelectedWard(wardsList[0]);
                setMatchingLikeWardsWithClient(wardsList);
            });
    };

    function checkOutlookLoginStatus() {
        if (accounts.length > 0) {
            // User is logged in
            setOutlookLoggedIn(true);
        } else {
            // User is not logged in
            setOutlookLoggedIn(false);
        }
    }

    const handleLoginOutLook = () => {
        instance
            .loginPopup(loginRequest)
            .then((response) => {
                // Successful login

                if (response && response?.account) {
                    setOutlookLoggedIn(true);
                } else {
                    setOutlookLoggedIn(false);
                    setOutlookLoggedInError(true);
                }
            })
            .catch((error) => {
                // Handle login error
                setOutlookLoggedIn(false);
                setOutlookLoggedInError(true);
            });
    };

    useEffect(() => {
        // Call the function to check the login status
        checkOutlookLoginStatus();
    }, []);

    useDidMountEffect(() => {
        getwardsInSelectedClient();
        return () => { };
    }, [selectedClient]);

    useDidMountEffect(() => {
        getData();
        return () => { };
    }, [selectedCandidates]);

    useEffect(() => {
        // getCandidates();
        getData();
        return () => { };
    }, []);

    return (

        <Form onSubmit={handleSubmit(onSubmit)}>


            {contactPersons.map((data, id) => {
                return (
                    <Row form key={id} onSubmit={handleSubmit(onSubmit)}>

                        <div className="form-table">
                            <div className="form-row">
                                <div className="form-cell" style={{ width: '100px' }}>
                                    <FormGroup>
                                        <Label>Ref No</Label>
                                        <Input
                                            type="text"
                                            invalid={errors.refNo}
                                            placeholder="Ref No"
                                            // name="refNo + id"
                                            name={"refNo" + id}
                                            defaultValue={data && data.refNo}
                                            innerRef={register({ required: true })}
                                        />
                                        {errors.refNo && (
                                            <ul
                                                className="list-unstyled text-sm mt-1 text-muted filled"
                                                id="parsley-id-119"
                                            >
                                                <li className="parsley-required text-danger">
                                                    This field is required
                                                </li>
                                            </ul>
                                        )}
                                    </FormGroup>
                                </div>
                                <div className="form-cell" style={{ width: '100px' }}>
                                    <FormGroup>
                                        <Label>Date</Label>
                                        <Input
                                            type="date"
                                            invalid={errors.date || dataErrorStatus}
                                            placeholder="Date"
                                            // name="date"
                                            name={"date" + id}
                                            defaultValue={data && data.date}
                                            innerRef={register}
                                            onChange={(e) => {
                                                setDataErrorStatus(
                                                    moment(e.target.value).format("l") == moment().format("l")
                                                        ? false
                                                        : moment(e.target.value).isBefore()
                                                );
                                                setDateSelected(e.target.value);
                                            }}
                                        />
                                        {dataErrorStatus && (
                                            <ul
                                                className="list-unstyled text-sm mt-1 text-muted filled"
                                                id="parsley-id-119"
                                            >
                                                <li className="parsley-required text-danger">Invalid Date</li>
                                            </ul>
                                        )}
                                    </FormGroup>
                                </div>
                                <div className="form-cell">
                                    <FormGroup>
                                        <Label>Start Time</Label>
                                        <Input
                                            type="time"
                                            placeholder="Start Time"
                                            // name="startTime"
                                            name={"startTime" + id}
                                            defaultValue={data && data.startTime}
                                            invalid={errors.startTime}
                                            innerRef={register({ required: true })}
                                        />
                                        {errors.startTime && (
                                            <ul
                                                className="list-unstyled text-sm mt-1 text-muted filled"
                                                id="parsley-id-119"
                                            >
                                                <li className="parsley-required text-danger">
                                                    This field is required
                                                </li>
                                            </ul>
                                        )}
                                    </FormGroup>
                                </div>



                                <div className="form-cell">
                                    <FormGroup>
                                        <Label>End Time</Label>
                                        <Input
                                            type="time"
                                            invalid={errors.endTime}
                                            placeholder="End Time"
                                            // name="endTime"
                                            name={"endTime" + id}
                                            defaultValue={data && data.endTime}
                                            innerRef={register({ required: true })}
                                        />
                                        {errors.endTime && (
                                            <ul
                                                className="list-unstyled text-sm mt-1 text-muted filled"
                                                id="parsley-id-119"
                                            >
                                                <li className="parsley-required text-danger">
                                                    This field is required
                                                </li>
                                            </ul>
                                        )}
                                    </FormGroup>
                                </div>

                                <div className="form-cell" style={{ width: '100px' }}>
                                    <FormGroup>
                                        <Label>Client</Label>
                                        {/* <Col sm={8}> */}
                                        <Input
                                            type="select"
                                            id="clientId"
                                            // name="clientId"
                                            // defaultValue={0}
                                            name={"clientId" + id}
                                            defaultValue={data && data.clientId}
                                            onChange={(e) => {
                                                const selectedOption = clients.find(
                                                    (item) => item.id === parseInt(e.target.value)
                                                );
                                                setSelectedClient(selectedOption);
                                            }}
                                            invalid={errors.clientId}
                                            innerRef={register({
                                                required: {
                                                    value: true,
                                                    message: "This field is required!",
                                                },
                                            })}
                                        >
                                            <option value={0} id={0}>
                                                Select Client
                                            </option>
                                            {clients?.map((item, id) => {
                                                return (
                                                    <option value={item.id} key={id}>
                                                        {item.name}
                                                    </option>
                                                );
                                            })}
                                        </Input>
                                        {/* </Col> */}
                                    </FormGroup>
                                </div>



                                <div className="form-cell" style={{ width: '100px' }}>
                                    <FormGroup>
                                        <Label>Department</Label>
                                        {/* <Col sm={8}> */}
                                        <Input
                                            type="select"
                                            id="departmentId"
                                            // name="ward"
                                            invalid={errors.ward}
                                            // defaultValue={0}
                                            name={"ward" + id}
                                            defaultValue={data && data.ward}
                                            onChange={(e) => {
                                                const selectedOption = matchingLikeWardsWithClient.find(
                                                    (item) => item.id === parseInt(e.target.value)
                                                );
                                                setSelectedWard(selectedOption);
                                            }}
                                            innerRef={register({
                                                required: {
                                                    value: true,
                                                    message: "This field is required!",
                                                },
                                            })}
                                        >
                                            <option value={0}>Select Department</option>

                                            {matchingLikeWardsWithClient &&
                                                matchingLikeWardsWithClient.map((item, id) => {
                                                    return (
                                                        <option value={item.name} key={id}>
                                                            {item.name}
                                                        </option>
                                                    );
                                                })}
                                        </Input>
                                        {errors.ward && (
                                            <ul
                                                className="list-unstyled text-sm mt-1 text-muted filled"
                                                id="parsley-id-119"
                                            >
                                                <li className="parsley-required text-danger">
                                                    This field is required
                                                </li>
                                            </ul>
                                        )}
                                        {/* </Col> */}
                                    </FormGroup>
                                </div>

                                <div className="form-cell" style={{ width: '100px' }}>
                                    <FormGroup>
                                        <Label >Note</Label>
                                        {/* <Col sm={8}> */}
                                        <Input
                                            type="text"
                                            placeholder="Note"
                                            // name="note"
                                            name={"note" + id}
                                            defaultValue={data && data.note}
                                            innerRef={register}
                                        />
                                        {/* </Col> */}
                                    </FormGroup>
                                </div>

                                <div className="form-cell" style={{ width: '100px' }}>
                                    <FormGroup>
                                        <Label>Assignment</Label>
                                        {/* <Col sm={8}> */}
                                        <Input
                                            type="text"
                                            placeholder="Assignment"
                                            // name="assignment"
                                            name={"assignment" + id}
                                            defaultValue={data && data.assignment}
                                            innerRef={register}
                                        />
                                        {/* </Col> */}
                                    </FormGroup>
                                </div>


                                <div className="form-cell" style={{ width: '150px' }}>
                                    <FormGroup>
                                        <Label>Secondary Assignment</Label>
                                        {/* <Col sm={8}> */}
                                        <Input
                                            type="text"
                                            placeholder="Secondary Assignment"
                                            // name="secondaryAssignment"
                                            name={"secondaryAssignment" + id}
                                            defaultValue={data && data.secondaryAssignment}
                                            innerRef={register}
                                        />
                                        {/* </Col> */}
                                    </FormGroup>
                                </div>

                                <div className="form-cell" style={{ width: '100px' }}>
                                    <FormGroup>
                                        <Label>Training</Label>
                                        {/* <Col sm={8}> */}
                                        <Input
                                            type="text"
                                            placeholder="Training"
                                            // name="training"
                                            name={"training" + id}
                                            defaultValue={data && data.training}
                                            innerRef={register}
                                        />
                                        {/* </Col> */}
                                    </FormGroup>
                                </div>
                                {/* <!-- Add more fields here in the same way --> */}
                            </div>
                            {/* <hr /> */}



                        </div>

                    </Row>
                );
            })}

            {!outlookLoggedIn && (
                <Alert className="d-flex align-items-center justify-content-center flex-column" color="danger">
                    <p>To send a booking confirmation via email, kindly ensure that you are logged in first</p>
                    <img src={outlookLogo} style={{ height: 100 }}></img>
                    <u className="text-info pointer ml-4" onClick={handleLoginOutLook}>
                        Login
                    </u>
                </Alert>
            )}

{/* <div>
                        <Label>Add shift</Label>
                    </div>
                    <hr></hr> */}
       
                
                    <div className="d-flex justify-content-end">
                    <div className="m-2">
                        <Button
                            color="success font-weight-bold mb-2"
                            style={{ borderRadius: "50px" }}
                            onClick={() => setContactPersons([...contactPersons, {}])}
                        >
                            +
                        </Button>
                        {filtered.length > 1 ? (
                            <Button
                                color="danger font-weight-bold mb-2 mx-2"
                                style={{
                                    borderRadius: "80px",
                                    width: "40px",
                                    height: "40px",
                                }}
                                onClick={() => removeMTAddMore()}
                            >
                                -
                            </Button>
                        ) : null}
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                {previousBooking && (
                    <Button className="mr-2" color="success" type="submit" disabled={disableCreateButton}>
                        Previous Booking
 
                    </Button>
                )}
                {!previousBooking && (
                    <Button className="mr-2" color="success" type="submit" disabled={disableCreateButton || !outlookLoggedIn}>
                        Create
                    </Button>
                )}
             

                <Button color="secondary" onClick={() => closeModal()}>
                    Cancel
                </Button>
            </div>
        </Form>

    );
}

function mapStateToProps(state) {
    return {
        auth: state.auth,
        user: state.auth.item,
        reduxSpecialities: state.initialDataGetReducer.specialities,
        reduxClients: state.initialDataGetReducer.clients,
        reduxDepartments: state.initialDataGetReducer.departments,
        reduxRoles: state.initialDataGetReducer.roles,
        reduxBands: state.initialDataGetReducer.bands,
    };
}

export default connect(mapStateToProps, {})(AddShiftFormModal);

