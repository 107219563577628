import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { useForm } from "react-hook-form";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Row,
  Col,
  Alert,
} from "reactstrap";
import {
  rolesGetAction,
  specialitiesGetAction,
  bandsGetAction,
} from "../../../../redux/actions/initialDataGetAction";
import store from "../../../../redux/store";

function ConfigureAssignments(props) {
  const { register, handleSubmit, errors } = useForm();
  const {
    onCreate,
    closeModal,
    mode,
    modalType,
    fieldData,
    specialities,
    roles,
    bands,
  } = props;

  const [speciality, setSpeciality] = useState([]);
  const [role, setRole] = useState([]);
  const [band, setBand] = useState([]);
  const [error, setError] = useState(false);

  const onSubmit = (data) => {
    if (
      selectedSpecialities &&
      selectedRole &&
      selectedBand &&
      data.assignmentCode
    ) {
      onCreate({
        assignmentCode: data.assignmentCode,
        bandId: selectedBand.id,
        candidateSpecialityId: selectedSpecialities.id,
        candidateRoleId: selectedRole.id,
      });
      setError(false);
    } else {
      setError(true);
    }
  };

  const [selectedSpecialities, setSelectedSpecialities] = useState(
    fieldData
      ? {
          id: fieldData?.speciality.id,
          value: fieldData?.speciality.name,
          label: fieldData?.speciality.name,
        }
      : null
  );
  const [selectedRole, setSelectedRole] = useState(
    fieldData
      ? {
          id: fieldData?.role.id,
          value: fieldData?.role.name,
          label: fieldData?.role.name,
        }
      : null
  );
  const [selectedBand, setSelectedBand] = useState(
    fieldData
      ? {
          id: fieldData?.band.id,
          value: fieldData?.band.name,
          label: fieldData?.band.name,
        }
      : null
  );

  const rearrangeSpecialities = () => {
    let specialitiesTemp = [];
    specialities.forEach((item) => {
      specialitiesTemp.push({
        value: item.name,
        label: item.name + `${item?.type == "DATABASE" ? "-DB" : ""}`,
        id: item.id,
      });
    });
    setSpeciality(specialitiesTemp);
  };
  const rearrangeRoles = () => {
    let rolesTemp = [];
    roles.forEach((item) => {
      rolesTemp.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    setRole(rolesTemp);
  };
  const rearrangeBands = () => {
    let bandsTemp = [];
    bands.forEach((item) => {
      bandsTemp.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    setBand(bandsTemp);
  };

  useEffect(() => {
    if (specialities) {
      rearrangeSpecialities();
    }
    return () => {};
  }, [specialities]);

  useEffect(() => {
    if (roles) {
      rearrangeRoles();
    }
    return () => {};
  }, [roles]);

  useEffect(() => {
    if (bands) {
      rearrangeBands();
    }
    return () => {};
  }, [bands]);

  useEffect(() => {
     store.dispatch(specialitiesGetAction());
    store.dispatch(rolesGetAction());
    store.dispatch(bandsGetAction());
    return () => {};
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mx-3">
      <FormGroup>
        <Row>
          <Col sm={12} md={4}>
            <Label for="candidateRoleId">Role</Label>
            <Select
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                  color: "#333333",
                }),
              }}
              // isMulti
              id={"candidateRole"}
              value={selectedRole}
              name="candidateRole"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => setSelectedRole(data)}
              options={role}
            />
          </Col>
          <Col sm={12} md={4}>
            <Label for="candidateSpecialityId">Speciality</Label>
            <Select
              // isMulti
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                  color: "#333333",
                }),
              }}
              id={"candidateSpeciality"}
              value={selectedSpecialities}
              name="candidateSpeciality"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => setSelectedSpecialities(data)}
              options={speciality}
            />
          </Col>
          <Col sm={12} md={4}>
            <Label for="documentId">Band</Label>
            <Select
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({
                  ...base,
                  zIndex: 9999,
                  color: "#333333",
                }),
              }}
              // isMulti
              value={selectedBand}
              id={"candidateBand"}
              name="candidateBand"
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={(data) => setSelectedBand(data)}
              options={band}
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Row>
          <Col>
            <Label for="middle_Name">Assignment Code</Label>
            <Input
              type="text"
              id="assignmentCode"
              name="assignmentCode"
              defaultValue={fieldData?.code}
              invalid={errors.assignmentCode}
              innerRef={register({
                maxLength: {
                  value: 30,
                  message: "This field only allowed only 30 characters",
                },
              })}
            ></Input>
          </Col>
        </Row>
      </FormGroup>

      <FormGroup>
        <Row>
          <Col>
            {error ? (
              <Alert color="danger">
                All fields are mandatory. Please ensure that all fields are
                filled.
              </Alert>
            ) : null}
          </Col>
        </Row>
      </FormGroup>

      <FormGroup>
        <Row className="justify-content-end">
          <div className="d-flex ">
            <Button className="mr-2" color="success" type="submit">
              {mode}
            </Button>
            <Button color="secondary" onClick={() => closeModal()}>
              Close
            </Button>
          </div>
        </Row>
      </FormGroup>
    </Form>
  );
}

function mapStateToProps(state) {
  return {
    specialities: state.initialDataGetReducer.specialities,
    roles: state.initialDataGetReducer.roles,
    bands: state.initialDataGetReducer.bands,
  };
}

export default connect(mapStateToProps, {})(ConfigureAssignments);
