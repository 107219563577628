import React, { useState } from "react";
import "./chat.style.css";
import ChatTabs from "./ChatTabs";
import { connect } from "react-redux";
import ChatRoom from "./components/chatRoom";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";

const ChatListItem = (props) => {
  const { data, isActive } = props;
  const [certificates, setCertificates] = useState([]);
  const [chatTargetScript, setchatTargetScript] = useState([]);
};

//use to call back charid from chat users

const ChatPage = () => {
  const [chatScriptIdfromChatJS, setChatScriptIdfromChatJS] = useState([]);
  const chatscriptIdHandler = (childData) => {
    setChatScriptIdfromChatJS(childData);
    // console.log(chatScriptIdfromChatJS);
  };
  const [chatList, setChatList] = useState([]);

  var universalChatPack = "";
  // this callback function to grab stuff from the firechat
  const Prechat = (recivedChatPack) => {
    universalChatPack = recivedChatPack;
    // console.log(universalChatPack);
  };

  return (
    <DashboardLayout>
      <div className="flex">
        <div
          className="d-flex flex fixed-content"
          style={{ height: "calc(100vh - 7.72rem)" }}
        >
          <div className="aside aside-sm">
            <div className="d-flex flex-column w-xl modal-dialog bg-body">
              <div className=" hover">
                <div className="list list-row">
                  {/* {chatList.map((item, i) => {
                    return (
                      <ChatListItem
                        key={i}
                        data={item}
                        isActive={item.isActive}
                      ></ChatListItem>
                    );
                  })} */}
                  <ChatTabs />
                </div>
                <div className="no-result hide">
                  <div className="p-4 text-center">No Results</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <ChatRoom />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.auth.item.id,
    // reduxChatScriptId: state.ChatRedux.chatfunkyId,
    ChatDBPost: state.chatDbPostReducer.chatDbPostLoad,
  };
}

function mapDispactchToProps(dispatch) {
  return {
    changeChat: (chatId) => {
      dispatch({ type: "chatScriptSelection", chatId: chatId });
    },
  };
}

export default connect(mapStateToProps, mapDispactchToProps)(ChatPage);
