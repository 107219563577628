import React from "react";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import SMSWIDGET from "../../../src/widgets/dashboard-widgets/sms/sms.js";
import { connect } from "react-redux";

const SMS = (props) => {
  return (
    <>
      <DashboardLayout title="SMS" subTitle="Messages">
        <SMSWIDGET userPhoneNo={props.userPhoneNo}></SMSWIDGET>
      </DashboardLayout>
    </>
  );
};

function mapStateToProps(state) {
  return {
    userPhoneNo: state.auth.item.companyPhoneNumber,
  };
}

// export default UpcommingTasksWidget;
export default connect(mapStateToProps)(SMS);
