import React, { useState, useEffect } from "react";
import {
  Label,
  Form,
  FormGroup,
  Col,
  Button,
  Spinner,
  Input,
  Alert,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import store from "../../redux/store";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { forEach, findIndex, isEmpty, isNull } from "lodash";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../components/toastCommon";
import useCandidateList from "../../customHooks/useCandidateList";
import {
  REGISTER_CANDIDATE,
  HOSTMexxar,
  ClientLable,
} from "../../configs/api-config";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { clientsGetAction } from "../../redux/actions/initialDataGetAction";
import Store from "../../redux/store";
import { saveFiledDataAction } from "../../redux/actions/saveFiledDataAction";
import { updateTimesheetReviewedData } from "../../redux/actions/timesheetBatchScanAction";

function TimesheetDataBatchView(props) {
  const {
    // processTimeSheet,
    processLoading,
    selectedCandidate,
    setSelectedCandidate,
    incommingAIdata,
    systemTimeSheetData,
    timeSheetExist,
    reduxClients,
    retry,
    retry2,
    selectedFile,
    setPayrollData,
    setShowSummary,
    setPayrollError,
    user,
    timesheet,
  } = props;

  const MexxarCandidateApi = REGISTER_CANDIDATE;
  const { register, handleSubmit, errors, clearErrors, reset } = useForm();
  const dispatch = useDispatch();

  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [candidates, setCandidates] = useState([]);
  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  let url = MexxarCandidateApi + "/status/" + filterCustom.value;

  const { loading, error, rows, hasMore } = useCandidateList(
    url,
    filterCustom,
    query,
    pageNumber
  );

  const [departmentError, setDepartmentError] = useState(false);
  const [selectedSpeciality, setSelectedSpeciality] = useState(0);
  const [specialities, setSpecialities] = useState([{ id: 0, name: "Select" }]);

  const [selectedDepartment, setSelectedDepartment] = useState(0);
  const [departments, setDepartments] = useState([{ id: 0, name: "Select" }]);

  const [refNo, setRefNo] = useState(null);
  const [fieldData, setFieldData] = useState(null);

  const [selectedClient, setSelectedClient] = useState(null);
  const [clients, setClients] = useState([{ id: 0, name: "Select" }]);

  const [clientCantFind, setClientCantFind] = useState(false);
  const [departmentCantFind, setDepartmentCantFind] = useState(false);
  const [specialityCantFind, setSpecialityCantFind] = useState(false);

  const [pdfError, setAiError] = useState(false);
  const [aiPending, setAiPending] = useState(false);

  const { addToast } = useToasts();

  const handleInputChange = (e) => {
    setQuery(e);
  };

  //----------prepare candidates to popultate in dorp down select---------//
  const prepareCandidates = () => {
    let listOfCandidates = [];
    forEach(rows, function (row) {
      listOfCandidates.push({
        value: row.id,
        label: `${row.firstName} ${row.lastName}`,
        id: row.id,
        candidate: row,
      });
    });

    if (query != "") {
      setCandidates(listOfCandidates);
      //console.log("angi process 105----------->", listOfCandidates);
      setSelectedCandidate(listOfCandidates[0]);
    } else {
      setSelectedCandidate([]);
      setCandidates([]);
    }
  };

  const getClientDepartments = (data) => {
    if (data) {
      axios
        .get(HOSTMexxar + "client-wards/clients/" + data)
        .then((res) => {
          let tempDep = [];
          forEach(res.data.body, function (department) {
            tempDep.push({
              id: department.wardId,
              name: department.wardName,
            });
          });
          setDepartments(tempDep);
        })
        .catch((err) => {
          setDepartmentError(err);
          // console.log("err", err);
          // toastCommon(true, "error");
        });
    }
  };

  //--------------[process flow no--00]--------------//

  const prepareFiledData = () => {
    let tempFields;

    //when AI scanned ref no is not matching with excisting db bookings data

    if (!timeSheetExist) {
      if (incommingAIdata) {
        tempFields = {
          referenceNo: incommingAIdata.Ref,
          date: incommingAIdata.Date,
          candidate: {
            id: incommingAIdata.CandidateId,
            value: incommingAIdata.CandidateName,
            lable: incommingAIdata.CandidateName,
          },
          candidateId: incommingAIdata.CandidateId,
          startDate: moment(incommingAIdata.Date).format("YYYY-MM-DD"),
          startTime: moment(incommingAIdata.Start, "HH:mm").format("HH:mm"),
          endDate: moment(incommingAIdata.Date).format("YYYY-MM-DD"),
          endTime: moment(incommingAIdata.End, "HH:mm").format("HH:mm"),
          client: {
            // id: incommingAIdata.clientId,
            name: incommingAIdata.HospitalName,
          },
          department: {
            // id: incommingAIdata.wardId,
            name: incommingAIdata.Ward,
          },
          speciality: incommingAIdata.Speciality,
          upLift: 0,
          bonus: 0,
          minsTakenForBreaks: incommingAIdata.Breakmin,
          totalHours: incommingAIdata.Totalhours,
        };
      }
    } else {
      //when AI scanned refno. is matching with excisting system bookings

      if (systemTimeSheetData) {
        tempFields = {
          referenceNo: systemTimeSheetData.referenceNo,
          date: systemTimeSheetData.bookingDate,
          candidate: {
            id: systemTimeSheetData.candidatesId,
            value: systemTimeSheetData.candidateName,
            lable: systemTimeSheetData.candidateName,
          },
          candidateId: systemTimeSheetData?.candidatesId,
          startDate: moment(systemTimeSheetData?.shiftFrom).format(
            "YYYY-MM-DD"
          ),
          startTime: moment(systemTimeSheetData?.shiftFrom).format("HH:mm"),
          endDate: moment(systemTimeSheetData?.shiftFrom).format("YYYY-MM-DD"),
          endTime: moment(systemTimeSheetData?.shiftTo).format("HH:mm"),
          client: {
            id: systemTimeSheetData.clientId,
            name: systemTimeSheetData.clientName,
          },
          department: {
            id: systemTimeSheetData.ward.id,
            name: systemTimeSheetData.ward.name,
          },
          speciality: systemTimeSheetData.speciality,
          upLift: systemTimeSheetData.upLift,
          bonus: systemTimeSheetData.bonus,
        };
      }
    }

    Store.dispatch(saveFiledDataAction(tempFields));
    setFieldData(tempFields);
  };

  const handleCandidateChange = (data) => {
    // console.log("selectedCandidate angi --->data", data);
    setQuery(data.value);
    // setSelectedCandidate(data);
    // setClients(data.candidate?.preferredClients);
    // setSpecialities(data.candidate?.candidateSpecialities);
    // getClientDepartments(data.candidate?.preferredClients[0]?.id);
  };

  //*--------------prepare end date ------------------*//
  //*--------------if the end time is after 12 midnight add one date to the start date ------------------*//

  function prepEndDate(data) {
    let sTime = data.startTime.split(":")[0];
    let eTime = data.endTime.split(":")[0];

    let endDate;

    if (sTime < eTime) {
      endDate = moment(data.startDate).format("DD/MM/YYYY");
    } else {
      endDate = moment(data.startDate).add(1, "days").format("DD/MM/YYYY");
    }
    return endDate;
  }

  //*--------------verify document-------------------*//
  //--------------[process flow no--01]--------------//
  const onSubmit = (data) => {
    console.log("dhanajali");
    console.log("Submitted data:", data);
    // Generate a 4-digit random number
    const randomNumber = Math.floor(1000 + Math.random() * 9000);
    const randomNumber2 = Math.floor(1000 + Math.random() * 9000);
    const firstValue = Math.floor(20 + Math.random() * 8);
    const secondValue = Math.floor(firstValue + 3 + Math.random() * 5);

    let exportObject = {
      PaymentPeriod: "",
      ClientID: clients[data.clientIndex]?.id,
      MeritID: randomNumber,
      ExternalID: candidates[0]?.id,
      MeritPlacementID: randomNumber2,
      ExternalPlacementID: "",
      WorkDescription: "Temp Staff",
      PayDescription: "Basic Pay Temp Staff",
      Quantity: data?.totalHours,
      PayRate: firstValue,
      ChargeRate: secondValue,
      Reference: data?.refNo,
      Ref2: "",
      Startdate: data?.startDate,
      Enddate: "",
      Comments: "",
      EndUserClient: "",
      ComparatorHolidayDays: "",
      PaymentCurrencyCode: "GBP",
      InvoiceCurrencyCode: "",
      VatAdjust: "",
      Consultant: user,
    };

    let timesheet = {
      locumName: candidates[0]?.label,
      locumId: candidates[0]?.id,
      speciality: specialities[data?.specialityIndex]?.id,
      band: candidates[0]?.candidate?.candidateBand?.id,
      hospitalName: clients[data?.clientIndex]?.name,
      dateOfShift: data?.startDate,
      ward: departments[data?.departmentIndex]?.id,
      bookingRef: data?.refNo,
      startTime: data?.startTime,
      endTime: data?.endTime,
      minsTakenForBreaks: data?.minsTakenForBreaks,
      totalHours: data?.totalHours,
      locumDate: data?.startDate,
      authoriseName: "",
      authorisePosition: "",
      authoriseDate: data?.startDate,
      bookingId: systemTimeSheetData?.id,
      hospitalReviewStatus: 0,
      paymentStatus: 0,
      reviewStatus: 4,
    };

    let form = new FormData();
    form.append("file", selectedFile.file);
    let dateTimeNow = new Date();
    axios.post(HOSTMexxar + "timesheets/", timesheet).then((res) => {
      if (res.data.status == "success" && res.data.message == "created") {
        // ToastCommon(true, "success", addToast);

        updateBookingIfNotMatching(data, res, form);

        dispatch(
          updateTimesheetReviewedData(
            selectedFile.file.preview,
            exportObject,
            dateTimeNow
          )
        );
      } else if (
        res.data.status == "success" &&
        res.data.message == "updated"
      ) {
        ToastCommon(true, "update", addToast);
        updateBookingIfNotMatching(data, res, form);
        dispatch(
          updateTimesheetReviewedData(
            selectedFile.file.preview,
            exportObject,
            dateTimeNow
          )
        );
        // uploadTimesheet(data, res, form);
      } else if (res.data.status == "failed") {
        ToastCommon(true, "error", addToast);
      }
    });
  };

  //*--------------Update booking details in the system if even one data is miss matching with verifying data------------------*//
  //--------------[process flow no--02]--------------//

  const updateBookingIfNotMatching = (data, response, form) => {
    let shiftSTime = moment(data.startDate).add(data.startTime).toDate();
    let shiftETime = moment(data.startDate).add(data.endTime).toDate();

    let arrangData = {
      id: systemTimeSheetData.id,
      bookingDate: moment(data.startDate).format("YYYY-MM-DD"),
      referenceNo: data.refNo,
      clientsId: Number(clients[data.clientIndex].id),
      //  employeeId: auth.item.id,
      // specialityId: Number(candidateSpecialities[0].id),
      specialityId: Number(specialities[data.specialityIndex].id),
      wardId: Number(departments[data.departmentIndex].id),
      shiftTo: shiftETime,
      shiftFrom: shiftSTime,
    };

    // if any field is not matching with system booking details, update it

    if (
      systemTimeSheetData.referenceNo != data.refNo ||
      systemTimeSheetData.candidatesId != candidates[0].id ||
      moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
        moment(data.startDate).format("YYYY-MM-DD") ||
      moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
        moment(data.startTime).format("HH:mm") ||
      moment(systemTimeSheetData.shiftTo).format("YYYY-MM-DD") !=
        moment(data.endTime).format("YYYY-MM-DD") ||
      moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
        moment(prepEndDate(data)).format("HH:mm") ||
      systemTimeSheetData.clientId != clients[data.clientIndex].id ||
      systemTimeSheetData.ward.id != departments[data.departmentIndex].id ||
      systemTimeSheetData.speciality.id != specialities[data.specialityIndex].id
    ) {
      axios
        .put(HOSTMexxar + "bookings", arrangData)
        .then((res) => {
          if (res.data.status == "success") {
            ToastCommon(true, "success", addToast);
            uploadTimesheet(data, response, form);
          } else if (res.data.status == "failed") {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {});
    }
    // else if fields  are matching with system booking details, upload the Timesheet
    else {
      uploadTimesheet(data, response, form);
    }
  };

  //*--------------upload timesheet------------------*//

  const uploadTimesheet = (data, res, form) => {
    axios
      .put(
        HOSTMexxar +
          "timesheets/" +
          res.data.body[0].id +
          "/upload-timesheetDocument",
        form
      )
      .then((res) => {
        if (res.data.status == "success" && res.data.message == "fetched") {
          ToastCommon(true, "timesheetUploaded", addToast);
          // processTimesheetData(data);
        } else if (res.data.status == "failed") {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  //*--------------process timesheet for payroll calculations------------------*//
  //--------------[process flow no--04]--------------//

  const processTimesheetData = (item) => {
    setAiPending(true);

    let tempData = [
      {
        bookingReference: item.refNo,
        startTime: item.startTime + ":00",
        endTime: item.endTime + ":00",
        startDate: moment(item.startDate).format("DD/MM/YYYY"),
        endDate: prepEndDate(item),
      },
    ];

    axios
      .post(HOSTMexxar + "workspace/payroll", tempData)
      .then((res) => {
        setAiPending(false);
        if (res.data.status == "success") {
          ToastCommon(true, "timesheetProcessed", addToast);
          setPayrollData(res.data.body);
          setShowSummary(true);
        } else if (res.data.status == "failed") {
          setPayrollError(res.data.status);
          ToastCommon(true, "timesheetProcessedError", addToast);
        }
      })
      .catch((err) => {
        setAiError(true);
      });
  };

  //two types of data coming
  //a.AI data(incommingAIdata)
  //b.DB Data(systemTimeSheetData)
  //*********** Process ***********/
  //1.When systemTimeSheetData comes prepare them to populate in the form
  //     a.Check if timehsheet ref no is can be found in the system -using timeSheetExist flag to determine this
  //     b.If its matching then populate system data directly to the form (fieldData)
  //     c.else populate incommingAIdata to the form (fieldData)
  //         c.0. Since AI Scanned booking reference not available follow below process to find it from the system
  //         c.1. Type the correct ref id in the reference filed and click "Retry"
  //                if : available then populate new found data in the fieldData-  **call prepareFiledData()
  //                   click verufy with other data.This will update the current booking details in the DB
  //                else : user cannot change data.Since no booking is made with given timehseet ref no(No booking Id to update the data in the DB)
  //     d.

  useEffect(() => {
    // console.log("angi process 1");
    prepareFiledData();
  }, [timeSheetExist, systemTimeSheetData]);

  useDidMountEffect(() => {
    // console.log("angi process 2");

    prepareCandidates();
    return () => {
      Store.dispatch(saveFiledDataAction(null));
    };
  }, [rows, timeSheetExist]);

  useDidMountEffect(() => {
    if (fieldData) {
      //  console.log("angi process 3");
      setQuery(fieldData.candidate.value);
    }
  }, [fieldData]);

  useDidMountEffect(() => {
    // console.log("angi process 4----------->", selectedCandidate);
    if (selectedCandidate) {
      //  console.log("angi process 4.1", selectedCandidate?.candidate?.preferredClients);
      setClients(selectedCandidate?.candidate?.preferredClients || []);
      setSpecialities(
        selectedCandidate?.candidate?.candidateSpecialities || []
      );
      getClientDepartments(
        selectedCandidate?.candidate?.preferredClients[0]?.id
      );
    } else {
      //  console.log("angi process 4.2");
      setClients([{ id: 0, name: "Select" }]);
      setSpecialities([{ id: 0, name: "Select" }]);
    }
  }, [selectedCandidate]);

  useDidMountEffect(() => {
    if (fieldData && clients) {
      //  console.log("angi process 5");
      let index = findIndex(clients, (item) => {
        //    console.log("angi clients", item);
        //    console.log("angi fieldData", fieldData);
        return item.name == fieldData.client.name;
      });

      if (index === -1) {
        //   console.log("angi process 5.1");
        setClientCantFind(true);
      } else {
        //   console.log("angi process 5.2");
        setClientCantFind(false);
        setSelectedClient(index);
      }
    }
  }, [clients]);

  useDidMountEffect(() => {
    if (fieldData && specialities) {
      // console.log("angi process 6 ");
      let index = findIndex(specialities, (item) => {
        return item.name == fieldData.speciality?.name;
      });

      if (index === -1) {
        //  console.log("angi process 6.1");
        setSpecialityCantFind(true);
      } else {
        //  console.log("angi process 6.2");
        setSpecialityCantFind(false);
        setSelectedSpeciality(index);
      }
    }
  }, [specialities]);

  useDidMountEffect(() => {
    // console.log("angi process 7");
    store.dispatch(clientsGetAction());
  }, [systemTimeSheetData]);

  useDidMountEffect(() => {
    // {
    //   clients &&
    //     selectedClient &&
    //     clients[selectedClient] != undefined &&
    //    console.log("angi process 8");
    // }
    {
      clients &&
        selectedClient &&
        clients[selectedClient] != undefined &&
        getClientDepartments(clients[selectedClient].id);
    }
  }, [selectedClient]);

  useDidMountEffect(() => {
    if (fieldData && departments) {
      // console.log("angi process 10 ");
      let index = findIndex(departments, (item) => {
        return item.name == fieldData.department.name;
      });

      if (index === -1) {
        //   console.log("angi process 10.1");
        setDepartmentCantFind(true);
      } else {
        //   console.log("angi process 10.2");
        setDepartmentCantFind(false);
        setSelectedDepartment(index);
      }
    }
  }, [departments]);

  useDidMountEffect(() => {
    // console.log("angi process 11 clearing every state");
    setFieldData(null);
    setQuery("");
    setSelectedDepartment(0);
    setDepartments([{ id: 0, name: "Select" }]);
    reset();
  }, [selectedFile.file]);

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#121212",
    }),
  };
  // console.log("selectedCandidate angi", selectedCandidate);
  return (
    <div className="card" style={{ width: "100%" }}>
      <div className="card-body ">
        <Form onSubmit={handleSubmit(onSubmit)} className="col-12">
          <FormGroup
            row
            className="d-flex justify-content-between align-items-center"
          >
            <Label className="ml-3">Process Timesheet </Label>
            <small>
              Please note that the data below is populated against the candidate
              identified from the candidate field.
            </small>
            {/* <Button
              className="m-2"
              color={`${timeSheetExist ?  "success" : "danger" }`}
              onClick={() => {
                processTimeSheet();
              }}
            >
              {processLoading && (
                <Spinner
                  animation="border"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "5px",
                  }}
                />
              )}
              Process
            </Button> */}
          </FormGroup>

          <hr />

          <FormGroup row>
            <Col md={`${timeSheetExist ? 12 : 10}`}>
              <Label>Ref No</Label>
              {/* {console.log("hi angi---?useeffect ", fieldData)} */}
              <Input
                type="text"
                defaultValue={fieldData && fieldData.referenceNo}
                placeholder="Ref No"
                id="refNo"
                name="refNo"
                onChange={(e) => setRefNo(e.target.value)}
                invalid={
                  !timeSheetExist ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.referenceNo != incommingAIdata.Ref)
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
            {!timeSheetExist && (
              <Col md={2} className="d-flex align-items-end ">
                <Button id="retry" color="success" onClick={() => retry(refNo)}>
                  Retry
                </Button>

                <UncontrolledTooltip target="retry">
                  Retry to find reference id
                </UncontrolledTooltip>
              </Col>
            )}
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <Label>Candidate</Label>
              <Select
                id="candidateId"
                name="candidateId"
                className="basic-multi-select"
                classNamePrefix="select"
                value={selectedCandidate}
                onChange={(data) => handleCandidateChange(data)}
                isLoading={loading}
                options={candidates}
                placeholder="Search candidates ..."
                onInputChange={handleInputChange}
                noOptionsMessage={() => "No results found"}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
                styles={colourStyles}
              />
            </Col>
            <Col md={6}>
              <Label>Candidate Id</Label>
              <Input
                type="text"
                // invalid={
                //   (selectedCandidate &&
                //     selectedCandidate[0]?.id != incommingAIdata.CandidateId) ||
                //   (systemTimeSheetData &&
                //     incommingAIdata &&
                //     systemTimeSheetData.candidatesId !=
                //       incommingAIdata.CandidateId)
                // }
                defaultValue={fieldData && fieldData.candidateId}
              />
              {/* <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                {(selectedCandidate &&
                  selectedCandidate[0]?.id != incommingAIdata.CandidateId) ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.candidatesId !=
                      incommingAIdata.CandidateId && (
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.CandidateId}
                      </li>
                    ))}
                {selectedCandidate &&
                  selectedCandidate[0]?.id != incommingAIdata.CandidateId && (
                    <>
                      <li className="parsley-required text-danger">
                        {selectedCandidate?.label}'s id is &nbsp; {selectedCandidate?.id}
                      </li>
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.CandidateId}
                      </li>
                    </>
                  )}
              </ul> */}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <Label>Start Date</Label>
              <Input
                type="Date"
                defaultValue={fieldData && fieldData.startDate}
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
                    moment(incommingAIdata.Date).format("YYYY-MM-DD")
                }
                placeholder="Start Time"
                id="startDate"
                name="startDate"
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftFrom).format("YYYY-MM-DD") !=
                  moment(incommingAIdata.Date).format("YYYY-MM-DD") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>

                      {moment(incommingAIdata.Date).format("L")}
                    </li>
                  </ul>
                )}
            </Col>
            <Col md={6}>
              <Label>Start Time</Label>
              <Input
                type="time"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
                    moment(incommingAIdata.Start, "HH:mm").format("HH:mm")
                }
                defaultValue={fieldData && fieldData.startTime}
                id="startTime"
                name="startTime"
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftFrom).format("HH:mm") !=
                  moment(incommingAIdata.Start, "HH:mm").format("HH:mm") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>
                      {moment(incommingAIdata.Start, "HH:mm").format("LT")}
                    </li>
                  </ul>
                )}
            </Col>{" "}
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <Label>End Date</Label>
              <Input
                type="Date"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftTo).format("YYYY-MM-DD") !=
                    moment(incommingAIdata.Date).format("YYYY-MM-DD")
                }
                defaultValue={fieldData && fieldData.endDate}
                id="endDate"
                name="endDate"
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftTo).format("YYYY-MM-DD") !=
                  moment(incommingAIdata.Date).format("YYYY-MM-DD") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>
                      {moment(incommingAIdata.Date).format("L")}
                    </li>
                  </ul>
                )}
            </Col>
            <Col md={6}>
              <Label>End Time</Label>
              <Input
                type="time"
                invalid={
                  systemTimeSheetData &&
                  incommingAIdata &&
                  moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
                    moment(incommingAIdata.End, "HH:mm").format("HH:mm")
                }
                placeholder="End Time"
                id="endTime"
                name="endTime"
                defaultValue={fieldData && fieldData.endTime}
                innerRef={register({ required: true })}
              />
              {systemTimeSheetData &&
                incommingAIdata &&
                moment(systemTimeSheetData.shiftTo).format("HH:mm") !=
                  moment(incommingAIdata.End, "HH:mm").format("HH:mm") && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      <strong>Detected : </strong>

                      {moment(incommingAIdata.End, "HH:mm").format("LT")}
                    </li>
                  </ul>
                )}
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col md={6}>
              <Label>{ClientLable}</Label>
              <Input
                type="select"
                id="clientIndex"
                name="clientIndex"
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
                invalid={
                  clientCantFind ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.clientName?.toLowerCase() !=
                      incommingAIdata.HospitalName?.toLowerCase())
                }
              >
                {clients.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      style={{ color: "#6A6767" }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Input>

              {clientCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData.clientName?.toLowerCase() !=
                    incommingAIdata.HospitalName?.toLowerCase() && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.HospitalName}
                      </li>
                    </ul>
                  ))}
            </Col>
            <Col md={6}>
              <Label>Department</Label>
              <Input
                type="select"
                id="departmentIndex"
                name="departmentIndex"
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
                invalid={
                  departmentCantFind ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData.ward?.name?.toLowerCase() !=
                      incommingAIdata.Ward?.toLowerCase())
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              >
                {departments.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      style={{ color: "#6A6767" }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Input>

              {departmentCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData.ward?.name?.toLowerCase() !=
                    incommingAIdata.Ward?.toLowerCase() && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.Ward}
                      </li>
                    </ul>
                  ))}
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={12}>
              <Label>Specialities</Label>
              <Input
                type="select"
                id="specialityIndex"
                name="specialityIndex"
                value={selectedSpeciality}
                onChange={(e) => setSelectedSpeciality(e.target.value)}
                invalid={
                  specialityCantFind ||
                  (systemTimeSheetData &&
                    incommingAIdata &&
                    systemTimeSheetData?.speciality?.name?.toLowerCase() !=
                      incommingAIdata?.Speciality?.toLowerCase())
                }
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              >
                {specialities.map((item, index) => {
                  return (
                    <option
                      key={index}
                      value={index}
                      style={{ color: "#6A6767" }}
                    >
                      {item.name}
                    </option>
                  );
                })}
              </Input>

              {specialityCantFind ||
                (systemTimeSheetData &&
                  incommingAIdata &&
                  systemTimeSheetData?.speciality?.name?.toLowerCase() !=
                    incommingAIdata?.Speciality?.toLowerCase() && (
                    <ul
                      className="list-unstyled text-sm mt-1 text-muted filled"
                      id="parsley-id-119"
                    >
                      <li className="parsley-required text-danger">
                        <strong>Detected : </strong>
                        {incommingAIdata.Speciality}
                      </li>
                    </ul>
                  ))}
            </Col>

            {/* <Col sm={3}>
              <Label>Up lift</Label>
              <Input
                type="text"
                placeholder="Up lift"
                id="upLift"
                name="upLift"
                // invalid={errors.upLift}
                defaultValue={fieldData && fieldData.upLift}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                  maxLength: {
                    value: 30,
                    message: "This field only allowed only 30 characters",
                  },
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please input a number!",
                  },
                })}
              />{" "}
               
            </Col> */}

            {/* <Col sm={3}>
              <Label>Bonus</Label>
              <Input
                type="text"
                placeholder="Bonus"
                id="bonus"
                name="bonus"
                defaultValue={fieldData && fieldData.bonus}
                invalid={errors.bonus}
              />{" "}
              {errors.bonus && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {errors.bonus?.message}
                  </li>
                </ul>
              )}
            </Col> */}
          </FormGroup>
          <FormGroup row>
            <Col sm={6}>
              <Label>Break Mins.</Label>
              <Input
                type="text"
                placeholder="Break Mins."
                id="minsTakenForBreaks"
                name="minsTakenForBreaks"
                defaultValue={incommingAIdata && incommingAIdata.Breakmin}
                invalid={errors.minsTakenForBreaks}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
            <Col sm={6}>
              <Label>Total Hours</Label>
              <Input
                type="text"
                placeholder="Total Hours"
                id="totalHours"
                name="totalHours"
                defaultValue={incommingAIdata && incommingAIdata.Totalhours}
                invalid={errors.totalHours}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Col md={12}>
              <Alert color="info">
                <strong className="text-danger">*</strong> The text in{" "}
                <strong className="text-danger">red</strong> represent
                recognized hand written text{" "}
              </Alert>
            </Col>
            <Col sm={12} className="d-flex justify-content-end mr-5 ">
              {isEmpty(systemTimeSheetData) && (
                <Alert color="danger">
                  We couldn't find a booking with the provided reference number.
                  Please ensure that you have the correct reference number for
                  the relevant booking made for above date and update it if it
                  doesn't match with the timesheet.
                </Alert>
              )}
              {isNull(systemTimeSheetData?.referenceNo) && (
                <Alert color="danger" className="mx-2">
                  We couldn't find the booking reference number. Please enter
                  the relevant reference number above.{" "}
                </Alert>
              )}
              <Button color={"success"} type="submit">
                {aiPending && (
                  <Spinner
                    animation="border"
                    style={{
                      height: "20px",
                      width: "20px",
                      marginRight: "5px",
                    }}
                  />
                )}
                Verify
              </Button>
            </Col>
          </FormGroup>
        </Form>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    reduxSpecialities: state.initialDataGetReducer.specialities,
    reduxClients: state.initialDataGetReducer.clients,
    reduxRoles: state.initialDataGetReducer.roles,
    user: state.auth.item.firstName + " " + state.auth.item.lastName,
    timesheet: state.timesheets.timesheets,
  };
}

export default connect(mapStateToProps, {})(TimesheetDataBatchView);
