import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Label,
  Input,
  Form,
  FormGroup,
  Col,
  ModalBody,
  ModalHeader,
  Modal,
} from "reactstrap";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HOSTMexxar, REGISTER_CANDIDATE } from "../../../configs/api-config";
import axios from "axios";
import { forEach } from "lodash";
import moment from "moment";
import ToastCommon from "../../../components/toastCommon";
import { useToasts } from "react-toast-notifications";

export default function Summery(props) {
  const { register, handleSubmit } = useForm();
  const { data, closeModal } = props;
  const { addToast } = useToasts();

  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const [timesheet, setTimesheet] = useState();
  const [likeClients, setLikeClients] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [incomingData, setIncomingData] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [candidateClients, setCandidateClients] = useState();
  const [selectedDocument, setSelectedDocument] = useState();
  const [candidateLikeWards, setCandidateLikeWards] = useState();
  const [modalPreviewPicture, setModalPreviewPicture] = useState();
  const [candidateDocumentUrl, setCandidateDocumentUrl] = useState();
  const [uploadedImageDocument, setUploadedImageDocument] = useState();

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const isDataEdited = true;
  const MexxarAPI = HOSTMexxar;
  const MexxarCandidate = REGISTER_CANDIDATE;

  const onSubmit = (data) => {
    let timesheet = {
      id: incomingData.id,
      locumName: incomingData.locumName,
      locumId: incomingData.locumId,
      speciality: incomingData.speciality,
      band: incomingData.band,
      hospitalName:
        incomingData.hospitalName == data.candidateClientSelect
          ? incomingData.hospitalName
          : data.candidateClientSelect,
      dateOfShift: incomingData.dateOfShift,
      ward:
        incomingData.ward == data.candidateLikeWardsSelect
          ? incomingData.ward
          : data.candidateLikeWardsSelect,
      bookingRef:
        incomingData.bookingRef == data.RefNo
          ? incomingData.bookingRef
          : data.RefNo,
      startTime:
        incomingData.startTime == data.StartTime
          ? incomingData.startTime
          : data.StartTime,
      endTime:
        incomingData.endTime == data.endTime
          ? incomingData.endTime
          : data.endTime,
      minsTakenForBreaks:
        incomingData.minsTakenForBreaks == data.breakTime
          ? incomingData.minsTakenForBreaks
          : data.breakTime,
      totalHours: incomingData.totalHours,
      locumDate: incomingData.locumDate,
      authoriseName: incomingData.authoriseName,
      authorisePosition: incomingData.authorisePosition,
      authoriseDate: incomingData.authoriseDate,
      bookingId: incomingData.booking.id,
      hospitalReviewStatus: incomingData.hospitalReviewStatus,
      paymentStatus: incomingData.paymentStatus,
      reviewStatus: incomingData.reviewStatus,
    };

    axios.put(MexxarAPI + "timesheets/", timesheet).then((res) => {
      if (res.data.status == "success" || res.data.message == "created") {
        ToastCommon(true, "update", addToast);
      } else {
        ToastCommon(true, "duplicate", addToast);
      }

      if (selectedDocument != null || selectedDocument != undefined) {
        axios
          .put(
            MexxarAPI +
              "timesheets/" +
              incomingData.id +
              "/upload-timesheetDocument",
            selectedDocument
          )
          .then((res) => {
            if (res.data.status == "success" || res.data.message == "created") {
              ToastCommon(true, "update", addToast);
              closeModal();
            } else {
              ToastCommon(true, "duplicate", addToast);
            }
          })
          .catch((err) => {
            ToastCommon(true, "error", addToast);
          });
      }
    });
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      //console.log("picture: ", e.target.files[0]);
      let form = new FormData();
      form.append("file", e.target.files[0]);
      setSelectedDocument(form);
    }
  };

  const GetSpecialties = () => {
    axios.get(MexxarCandidate + "/" + data.booking.candidatesId).then((res) => {
      let preferedClient = [];
      forEach(res.data.body[0].preferredClients, function (client) {
        preferedClient.push({
          id: client.id,
          name: client.name,
        });
      });

      setLikeClients(preferedClient);
    });
  };

  const getCandidateUser = (candidateId) => {
    axios.get(MexxarAPI + "candidates/" + candidateId).then((candidate) => {
      let hospitals = candidate.data.body[0].preferredClients;
      if (hospitals != undefined) {
        let candidateHospitals = [];
        for (let i = 0; i < hospitals.length; i++) {
          candidateHospitals.push({
            value: hospitals[i].name,
            label: hospitals[i].name,
            id: hospitals[i].id,
            candidate: candidate,
          });
        }
        setCandidateClients(candidateHospitals);
      }

      let likeWards = candidate.data.body[0].likeWards;
      if (likeWards != undefined) {
        let candidateLikeWards = [];
        for (let i = 0; i < hospitals.length; i++) {
          if (likeWards[i] != undefined) {
            candidateLikeWards.push({
              value: likeWards[i].name,
              label: likeWards[i].name,
              id: likeWards[i].id,
              candidate: candidate,
            });
          }
        }
        setCandidateLikeWards(candidateLikeWards);
      }

      setCandidateDocumentUrl(candidate.data.body[0].documentUrl);
      // setUploadedImageDocument((candidate.data.body[0].documentUrl).split("/")[1]);
    });
  };

  const downloadUploadedDocument = () => {
    axios({
      url:
        MexxarAPI +
        "timesheet-doc/candidates/" +
        incomingData.booking.candidatesId +
        "/" +
        uploadedImageDocument,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      // link.setAttribute("target", "_blank");
      link.setAttribute("download", uploadedImageDocument);
      document.body.appendChild(link);
      link.click();
      // window.open(link);
    });
  };

  const loadModalPreviewPicture = () => {
    if (incomingData != undefined) {
      let url = String(
        MexxarAPI +
          "timesheet-doc/candidates/" +
          incomingData.booking.candidatesId +
          "/" +
          uploadedImageDocument
      );
      const config = { responseType: "blob" };
      axios.get(url, config).then((res) => {
        getBase64(new File([res.data], "sample"), (result) => {
          setModalPreviewPicture(result);
          setOpenModal(true);
          // const link = document.createElement('a');
          // link.href = result;
          // link.setAttribute("target", "_blank");
          // // link.setAttribute('download', uploadedImageDocument);
          // document.body.appendChild(link);
          // link.click();
          // window.open(result);
        });
      });
    }
  };

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      //console.log("Error: ", error);
    };
  }

  useEffect(() => {
    setIncomingData(data);
    data.documentUrl == null
      ? setUploadedImageDocument(null)
      : setUploadedImageDocument(data.documentUrl.split("/")[1]);
    GetSpecialties();
    getCandidateUser(data.booking.candidatesId);
    // getAllTimesheets();
    loadModalPreviewPicture();
  }, []);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {}
        <FormGroup row>
          {}
          <Label sm={2} md={2}>
            Earnings
          </Label>
          <Col sm={9} md={9}>
            <Input
              type="text"
              placeholder="earning"
              disabled={true}
              name="earning"
              id="earningId"
              defaultValue={
                incomingData == undefined || incomingData == null
                  ? ""
                  : String(incomingData.earnings)
              }
              innerRef={register({ required: true })}
            />
          </Col>
          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          {}
          <Label sm={2} md={2}>
            Total Hours
          </Label>
          <Col sm={9} md={9}>
            <Input
              type="text"
              name="totalHours"
              disabled={true}
              innerRef={register({ required: true })}
              value={
                incomingData == undefined || incomingData == null
                  ? ""
                  : String(incomingData.startTime)
              }
            />
          </Col>
          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2} md={2}>
            Start Time
          </Label>
          <Col sm={9} md={9}>
            <Input
              type="time"
              name="startTime"
              disabled={true}
              innerRef={register({ required: true })}
              value={
                incomingData == undefined || incomingData == null
                  ? ""
                  : moment(incomingData.startTime, "hh:mm:ss").format(
                      "hh:mm:ss"
                    )
              }
            />
          </Col>
          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={2} md={2}>
            End Time
          </Label>
          <Col sm={9} md={9}>
            <Input
              type="time"
              name="endTime"
              disabled={true}
              innerRef={register({ required: true })}
              value={
                incomingData == undefined || incomingData == null
                  ? ""
                  : moment(incomingData.endTime, "hh:mm:ss").format("hh:mm:ss")
              }
            />
          </Col>
          <Col sm={1} md={1}>
            {isDataEdited ? (
              <FontAwesomeIcon icon={faExclamationCircle} color="danger" />
            ) : null}
          </Col>
        </FormGroup>
        <div className="mt-4">
          <Col sm={{ size: 8, offset: 8 }}>
            {"  "}
            <Button color="secondary" onClick={() => closeModal()}>
              Close
            </Button>
          </Col>
        </div>
      </Form>
      <Modal
        isOpen={openModal}
        scrollable={false}
        className="modal-dialog modal-lg"
      >
        <Form>
          {"Text" && (
            <ModalHeader className="modal-header bg-primary">
              <span style={{ color: "#ffffff" }}>{"Document Preview"}</span>
            </ModalHeader>
          )}
          <ModalBody className="p-4 ">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <div>
                <label for="file-input">
                  <img
                    src={modalPreviewPicture}
                    width={"700px"}
                    height={"700px"}
                  ></img>
                </label>
              </div>
              <Button
                color="secondary"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </ModalBody>
        </Form>
      </Modal>
    </>
  );
}
