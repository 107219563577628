
// import React, { useEffect, useState } from "react";
// import {
//   HOSTMexxar,
//   MAINTENANCE_WORKSPACE_SUMMARY,
//   REGISTRATION_WORKSPACE_SUMMARY,
//   AUDIT_WORKSPACE_SUMMARY,
//   HEADHUNTER_WORKSPACE_SUMMARY,
// } from "../../configs/api-config";
// import axios from "axios";
// import "./waterball.css";
// import moment from "moment";
// import _, { filter } from "lodash";
// import { connect } from "react-redux";
// import Confetti from "react-confetti";
// import MultiChart from "../chartJS.js/chart";
// import { FeedCardWidget } from "../../widgets";
// import Carousel from "react-bootstrap/Carousel";
// import demoData from "./dashboardData.json";
// import useWindowSize from "react-use-window-size";
// import DoughnutChart from "../chartJS.js/doughnut";
// import { useSpring, animated } from "react-spring";
// import TodoWidget from "../../widgets/todo-widget/todo";
// import ImageEdit from "../../widgets/image-edit/image-edit";
// import { Responsive, WidthProvider } from "react-grid-layout";
// import { TeamMemberWidget } from "../../widgets/dashboard-widgets";
// import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
// import SmsAlert from "../../widgets/dashboard-widgets/alert/alert";
// import DashboardChart from "../../widgets/dashboard-widgets/chart/chart";
// import ProgressBar from "../../widgets/dashboard-widgets/progress-bar/progress-bar";
// import DailyTarget from "../../widgets/dashboard-widgets/daily-target/daily-target";
// import WaterFillingChart from "../../components/water-filling-chart/water-filling-chart";
// import NumberOfCalls from "../../widgets/dashboard-widgets/number-of-calls/number-of-calls";
// import UpcommingTasksWidget from "../../widgets/dashboard-widgets/upcommingTasks/upcommingTasks";
// import TabNavigationMenu from "../../util/common/tabNavigation";

// //ncode-----------------------------------------------------------------------------------------------------

// // { i: "TeamMemberWidget", x: 0, y: 0, w: 9, h: 3, static: true },
// // { i: "SmsAlert", x: 0, y: 0, w: 9, h: 3 },
// // { i: "WaterFillingChart", x: 9, y: 0, w: 3, h: 6, static: true },
// // { i: "DailyTarget", x: 0, y: 3, w: 6, h: 3 },
// // { i: "NumberOfCalls", x: 6, y: 3, w: 3, h: 3 },
// // // { i: "k", x: 0, y: 3, w: 3, h: 2 },
// // // { i: "l", x: 3, y: 3, w: 3, h: 2 },
// // // { i: "m", x: 6, y: 3, w: 3, h: 2 },
// // { i: "DailyPerformanceBar", x: 9, y: 3, w: 3, h: 2 },
// // { i: "WeeklyPerformanceBar", x: 9, y: 4, w: 3, h: 2 },
// // { i: "MonthlyPerformanceBar", x: 9, y: 5, w: 3, h: 2 },
// // { i: "QuarterlyPerformanceBar", x: 9, y: 6, w: 3, h: 2 },
// // { i: "YearlyPerformanceBar", x: 9, y: 7, w: 3, h: 2 },
// // { i: "TabNavigationMenu", x: 0, y: 5, w: 4, h: 7},
// // { i: "MultiChart", x: 4, y: 5, w: 5, h: 7 },
// // // { i: "q", x: 0, y: 6, w: 5, h: 7 },

// // { i: "SmsAlert", x: 0, y: 1, w: 12, h: 3 },
// //   { i: "TeamMemberWidget", x: 0, y: 2, w: 12, h: 3, static: true },
// //   { i: "WaterFillingChart", x: 0, y: 3, w: 12, h: 6, static: true },
// //   { i: "DailyTarget", x: 0, y: 4, w: 12, h: 3 },
// //   { i: "NumberOfCalls", x: 0, y: 5, w: 12, h: 3 },
// //   // { i: "k", x: 0, y: 3, w: 3, h: 2 },
// //   // { i: "l", x: 3, y: 3, w: 3, h: 2 },
// //   // { i: "m", x: 6, y: 3, w: 3, h: 2 },
// //   { i: "DailyPerformanceBar", x: 0, y: 6, w: 12, h: 2 },
// //   { i: "WeeklyPerformanceBar", x: 0, y: 7, w: 12, h: 2 },
// //   { i: "MonthlyPerformanceBar", x: 0, y: 8, w: 12, h: 2 },
// //   { i: "QuarterlyPerformanceBar", x: 0, y: 9, w: 12, h: 2 },
// //   { i: "YearlyPerformanceBar", x: 0, y: 10, w: 12, h: 2 },
// //   { i: "MultiChart", x: 0, y: 11, w: 12, h: 7 },
// //   { i: "TabNavigationMenu", x: 0, y: 12, w: 12, h: 14 },
// //   // { i: "q", x: 0, y: 6, w: 5, h: 7 },

// const ResponsiveReactGridLayout = WidthProvider(Responsive);
// const lg = [
//   { i: "TeamMemberWidget", x: 0, y: 0, w: 9, h: 3, static: true },
//   { i: "SmsAlert", x: 0, y: 3, w: 9, h: 2 },
//   { i: "DailyTarget", x: 0, y: 5, w: 6, h: 3 },
//   { i: "NumberOfCalls", x: 6, y: 5, w: 3, h: 3 },
//   { i: "TabNavigationMenu", x: 0, y: 8, w: 4, h: 7 },
//   { i: "MultiChart", x: 4, y: 8, w: 5, h: 7 },
//   //water chart and perfromance by percentage cards
//   { i: "WaterFillingChart", x: 9, y: 0, w: 3, h: 6, static: true },
//   { i: "DailyPerformanceBar", x: 9, y: 6, w: 3, h: 2 },
//   { i: "WeeklyPerformanceBar", x: 9, y: 8, w: 3, h: 2 },
//   { i: "MonthlyPerformanceBar", x: 9, y: 10, w: 3, h: 2 },
//   { i: "QuarterlyPerformanceBar", x: 9, y: 12, w: 3, h: 2 },
//   { i: "YearlyPerformanceBar", x: 9, y: 14, w: 3, h: 2 },
// ];

// const md = [
//   { i: "TeamMemberWidget", x: 0, y: 0, w: 12, h: 3, static: true },
//   { i: "WaterFillingChart", x: 0, y: 3, w: 3, h: 6, static: true },
//   { i: "SmsAlert", x: 0, y: 9, w: 12, h: 2 },
//   { i: "DailyTarget", x: 0, y: 11, w: 12, h: 3 },
//   { i: "NumberOfCalls", x: 0, y: 14, w: 12, h: 3 },
//   { i: "DailyPerformanceBar", x: 0, y: 17, w: 12, h: 2 },
//   { i: "WeeklyPerformanceBar", x: 0, y: 19, w: 12, h: 2 },
//   { i: "MonthlyPerformanceBar", x: 0, y: 21, w: 12, h: 2 },
//   { i: "QuarterlyPerformanceBar", x: 0, y: 23, w: 12, h: 2 },
//   { i: "YearlyPerformanceBar", x: 0, y: 25, w: 12, h: 2 },
//   { i: "MultiChart", x: 0, y: 27, w: 25, h: 7 },
//   { i: "TabNavigationMenu", x: 0, y: 40, w: 12, h: 7 },
// ];

// const Dashboard = (props) => {
//   const MexxarAPI = HOSTMexxar;

//   const { auth } = props;

//   const [confTimer, setconfTimer] = useState(90);
//   const [confettiCount, setconfettiCount] = useState(90);
//   const [currentTab, setCurrentTab] = useState("upcoming");
//   const [compactType, setCompactType] = useState("vertical");
//   const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
//   const [monthlyPerformance, setmonthlyPerformance] = useState(90);

//   // summery
//   const [userSummery, setUserSummery] = useState({
//     dailyTarget: 0,
//     outgoingAnsweredCount: 0,
//     outgoingMissedCount: 0,
//     callBackCount: 0,
//     incomingMissedCount: 0,
//     incomingAnsweredCount: 0,
//     dailyCleared: 0,
//   });
//   const [auditDataSummery, setAuditSummery] = useState({
//     dailyTarget: 0,
//     verified: 0,
//     declined: 0,
//   });

//   const [targetData, setTargetData] = useState({
//     weeklyTarget: 50,
//     monthlyTarget: 10,
//     bookingTarget: 68,
//   });

//   const [performance, setPerformance] = useState({
//     daily: 0,
//     weekly: 0,
//     monthly: 0,
//     quarterly: 0,
//     yearly: 0,
//   });

//   const [noOfcalls, setNoOfcalls] = useState(null);
//   const [demoSmsCount, setDemoSmsCount] = useState(null);

//   const animationProps = useSpring({
//     opacity: 1,
//     transform: "translate(0px, 0px)",
//     from: { opacity: 0, transform: "translate(0px, 100px)" },
//     config: {
//       duration: 2000,
//     },
//   });

//   const switchTabHandler = (tab) => {
//     setCurrentTab(tab);
//   };

//   const checkPermissions = (permission) => {
//     let array = filter(auth.item.userRole.permissions, (item) => {
//       return item.name == permission;
//     });
//     return array;
//   };

//   const onBreakpointChange = (breakpoint) => {
//     setCurrentBreakpoint({
//       currentBreakpoint: breakpoint,
//     });
//   };

//   const getPerformanceData = () => {
//     axios
//       .all([
//         axios.get(`${MexxarAPI}dashboard/daily-performance`),
//         axios.get(`${MexxarAPI}dashboard/weekly-performance`),
//         axios.get(`${MexxarAPI}dashboard/monthly-performance`),
//         axios.get(`${MexxarAPI}dashboard/quarterly-performance`),
//         axios.get(`${MexxarAPI}dashboard/yearly-performance`),
//       ])
//       .then(
//         axios.spread((...responses) => {
//           if (responses != undefined && responses != null) {
//             const dailyPerformance =
//               responses[0].data.body[0] != undefined &&
//               responses[0].data.body[0] != null
//                 ? responses[0].data.body[0].performance
//                 : 0;
//             const weeklyPerformance =
//               responses[1].data.body[0] != undefined &&
//               responses[1].data.body[0] != null
//                 ? responses[1].data.body[0].performance
//                 : 0;
//             const monthlyPerformance =
//               responses[2].data.body[0] != undefined &&
//               responses[2].data.body[0] != null
//                 ? responses[2].data.body[0].performance
//                 : 0;
//             const quarterlyPerformance =
//               responses[3].data.body[0] != undefined &&
//               responses[3].data.body[0] != null
//                 ? responses[3].data.body[0].performance
//                 : 0;
//             const yearlyPerformance =
//               responses[4].data.body[0] != undefined &&
//               responses[4].data.body[0] != null
//                 ? responses[4].data.body[0].performance
//                 : 0;

//             setPerformance({
//               daily: dailyPerformance,
//               weekly: weeklyPerformance,
//               monthly: monthlyPerformance,
//               quarterly: quarterlyPerformance,
//               yearly: yearlyPerformance,
//             });
//           }
//         })
//       );
//   };

//   const getTargetData = () => {
//     let tempObj = {};
//     checkPermissions(auth);
//     if (
//       checkPermissions("HEADHUNTER").length > 0 ||
//       checkPermissions("ADMIN").length > 0
//     ) {
//       axios
//         .get(HEADHUNTER_WORKSPACE_SUMMARY + "?employeeId=" + props.auth.item.userTypeId)
//         .then((summary) => {

//           if (summary.data.body.length > 0) {
//             tempObj = {
//               dailyTarget: summary.data.body[0]?.dailyTarget,
//               outgoingAnsweredCount: summary.data.body[0]?.outgoingAnsweredCount,
//               outgoingMissedCount: summary.data.body[0]?.outgoingMissedCount,
//               callBackCount: summary.data.body[0]?.callBackCount,
//               incomingMissedCount: summary.data.body[0]?.incomingMissedCount,
//               incomingAnsweredCount: summary.data.body[0]?.incomingAnsweredCount,
//               dailyCleared: summary.data.body[0]?.dailyCleared,
//               clearanceTarget:summary.data.body[0]?.clearanceTarget,
//               dailyCallSpokenTarget:summary.data.body[0]?.dailyCallSpokenTarget,
//               dailyCallingTarget:summary.data.body[0]?.dailyCallingTarget,
//               voicemailCount:summary.data.body[0]?.voicemailCount
//             };
//             setUserSummery(tempObj);
//             setAuditSummery(null);
//           }
//         });
//     } else if (checkPermissions("REGISTRATION").length > 0) {
//       axios
//         .get(
//           REGISTRATION_WORKSPACE_SUMMARY + "?employeeId=" + props.auth.item.userTypeId
//         )
//         .then((summary) => {
//           if (summary.data.body.length > 0) {
//             tempObj = {
//               dailyTarget: summary.data.body[0]?.dailyTarget,
//               outgoingAnsweredCount: summary.data.body[0]?.outgoingAnsweredCount,
//               outgoingMissedCount: summary.data.body[0]?.outgoingMissedCount,
//               callBackCount: summary.data.body[0]?.callBackCount,
//               incomingMissedCount: summary.data.body[0]?.incomingMissedCount,
//               incomingAnsweredCount: summary.data.body[0]?.incomingAnsweredCount,
//               dailyCleared: summary.data.body[0]?.dailyCleared,
//               clearanceTarget:summary.data.body[0]?.clearanceTarget,
//               dailyCallSpokenTarget:summary.data.body[0]?.dailyCallSpokenTarget,
//               dailyCallingTarget:summary.data.body[0]?.dailyCallingTarget,
//               voicemailCount:summary.data.body[0]?.voicemailCount

//             };
//             setUserSummery(tempObj);
//             setAuditSummery(null);
//           }
//         });
//     } else if (checkPermissions("AUDIT").length > 0) {
//       axios
//         .get(AUDIT_WORKSPACE_SUMMARY + "?employeeId=" + props.auth.item.userTypeId)
//         .then((summary) => {
//           if (summary.data.body.length > 0) {
//             tempObj = {
//               dailyTarget: summary.data.body[0]?.dailyTarget,
//               verified: summary.data.body[0]?.verified,
//               declined: summary.data.body[0]?.declined,
//             };
//             setUserSummery(null);
//             setAuditSummery(tempObj);
//           }
//         });
//     } else if (checkPermissions("MAINTENANCE").length > 0) {
//       axios
//         .get(
//           MAINTENANCE_WORKSPACE_SUMMARY + "?employeeId=" + props.auth.item.userTypeId
//         )
//         .then((summary) => {
//           if (summary.data.body.length > 0) {
//             tempObj = {
//               dailyTarget: summary.data.body[0]?.dailyTarget,
//               outgoingAnsweredCount: summary.data.body[0]?.outgoingAnsweredCount,
//               outgoingMissedCount: summary.data.body[0]?.outgoingMissedCount,
//               callBackCount: summary.data.body[0]?.callBackCount,
//               incomingMissedCount: summary.data.body[0]?.incomingMissedCount,
//               incomingAnsweredCount: summary.data.body[0]?.incomingAnsweredCount,
//               dailyCleared: summary.data.body[0]?.dailyCleared,
//               clearanceTarget:summary.data.body[0]?.clearanceTarget,
//               dailyCallSpokenTarget:summary.data.body[0]?.dailyCallSpokenTarget,
//               dailyCallingTarget:summary.data.body[0]?.dailyCallingTarget,
//               voicemailCount:summary.data.body[0]?.voicemailCount
//             };
//             setUserSummery(tempObj);
//             setAuditSummery(null);
//           }
//         });
//     }
//   };

//   const onCompactTypeChange = () => {
//     const { compactType: oldCompactType } = props;
//     const compactType =
//       oldCompactType === "horizontal"
//         ? "vertical"
//         : oldCompactType === "vertical"
//         ? null
//         : "horizontal";
//     setCompactType({ compactType });
//   };

//   //ncode---------------------------------------------------------------------------------------------------
//   const { width, height } = useWindowSize();
//   //ncode---------------------------------------------------------------------------------------------------

//   const getGreetingTime = () => {
//     let currentTime = new Date();

//     const splitAfternoon = 12; // 24hr time to split the afternoon
//     const splitEvening = 15; // 24hr time to split the evening
//     const currentHour = moment(currentTime).format("HH");

//     if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
//       // Between 12 PM and 5PM
//       return "Good Afternoon";
//     } else if (currentHour >= splitEvening) {
//       // Between 5PM and Midnight
//       return "Good Evening";
//     }
//     // Between Dawn and noon
//     return "Good Morning";
//   };

//   const confettiActivator = () => {
//     // if (checkPermissions("PAYROLE").length > 0) {
//     //   timesheetReviewStatus = 1;
//     // } else if (checkPermissions("CANDIDATE").length > 0) {
//     //   timesheetReviewStatus = 0;
//     // }

//     let url = `${MexxarAPI}workspace/maintenance/summary`;

//     axios.get(`${MexxarAPI}workspace/maintenance/summary`).then((res) => {});

//     if (monthlyPerformance == 90) {
//       // setconfettiCount(100);

//       setTimeout(function () {
//         setconfettiCount(180);
//         // setconfTimer(0);
//       }, 30000);

//       setTimeout(function () {
//         setconfettiCount(0);
//         // setconfTimer(0);
//       }, 32000);
//     }
//   };
//   const [partTrick, setPartyTrick] = useState(false);

//   function handleKeyPress(event) {
//     if (event.key === "x") {
//       setPartyTrick(true);

//       setTimeout(() => {
//         setPartyTrick(false);
//       }, 5000);
//     } else if (event.key === "z") {
//       setPerformance(demoData[0].performace);
//       setAuditSummery(demoData[1].audit);
//       setUserSummery(demoData[2].userSummery);
//       setNoOfcalls(demoData[3].noOfCalls);
//       setDemoSmsCount(demoData[4].smsCount);
//     }
//   }

//   useEffect(() => {
//     window.addEventListener("keydown", handleKeyPress);
//     return () => {
//       window.removeEventListener("keydown", handleKeyPress);
//     };
//   }, []);

//   useEffect(() => {
//     getPerformanceData();
//     getTargetData();
//     confettiActivator();
//     return ()=>{}
//   }, []);

//   return (
//     <DashboardLayout
//       title="Dashboard"
//       subTitle={getGreetingTime() + "," + " " + props.auth.item.firstName + " "}
//       dashboardType="main"
//     >
//       {/* party Trick */}
//       {partTrick && (
//         <Confetti
//           width={width}
//           height={"1300%"}
//           numberOfPieces={confettiCount}
//         />
//       )}

//       <ResponsiveReactGridLayout
//         style={{ marginTop: "-25px" }}
//         {...props}
//         layouts={{ lg: lg, sm: md }}
//         onBreakpointChange={onBreakpointChange}
//         measureBeforeMount={false}
//         useCSSTransforms
//         compactType={compactType}
//         preventCollision={!compactType}
//       >
//         <animated.div style={animationProps} key="TeamMemberWidget">
//           <TeamMemberWidget
//             style={{ width: "100%", height: "100%" }}
//           ></TeamMemberWidget>
//         </animated.div>

//         <animated.div
//           style={{
//             ...animationProps,
//             display: "flex",
//             justifyContent: "center",
//           }}
//           key="WaterFillingChart"
//         >
//           <div className="d-flex justify-content-between">
//             {/* <div>&#8592;</div> */}

//             {/* <WaterFillingChart count={monthlyPerformance} style={{height:'100%'}}></WaterFillingChart> */}
//             {/* {performance != null && performance != undefined
//               ? console.log("Performance :", performance)
//               : ""} */}
//             <Carousel indicators={false}>
//               <Carousel.Item>
//                 <WaterFillingChart
//                   count={
//                     performance != undefined && performance != null
//                       ? performance.monthly
//                       : 0
//                   }
//                   displayName={"Monthly"}
//                 ></WaterFillingChart>

//                 <div className="waterFiller" style={{ opacity: "0.0" }}>
//                   Water Filler
//                 </div>
//               </Carousel.Item>
//               <Carousel.Item>
//                 <WaterFillingChart
//                   count={
//                     performance != undefined && performance != null
//                       ? performance.weekly
//                       : 0
//                   }
//                   displayName={"Weekly"}
//                 ></WaterFillingChart>

//                 <div className="waterFiller" style={{ opacity: "0.0" }}>
//                   Water Filler
//                 </div>
//               </Carousel.Item>
//               <Carousel.Item>
//                 <WaterFillingChart
//                   count={
//                     performance != undefined &&
//                     performance != null &&
//                     performance != 0
//                       ? performance.daily
//                       : 0
//                   }
//                   displayName={"Daily"}
//                 ></WaterFillingChart>

//                 <div className="waterFiller" style={{ opacity: "0.0" }}>
//                   Water Filler
//                 </div>
//               </Carousel.Item>
//             </Carousel>

//             {/* <div>&#8594;</div> */}
//           </div>
//         </animated.div>

//         <animated.div style={animationProps} key="DailyTarget">
//           {/* <ProgressBar style={{ width: "100%", height: "100%" }}></ProgressBar> */}
//           <DailyTarget
//             style={{ width: "100%", height: "100%" }}
//             userSummery={userSummery}
//             auditDataSummery={auditDataSummery}
//           ></DailyTarget>
//         </animated.div>

//         <animated.div style={animationProps} key="NumberOfCalls">
//           {/* <ProgressBar style={{ width: "100%", height: "100%" }}></ProgressBar> */}
//           <NumberOfCalls
//             demoData={noOfcalls}
//             style={{ width: "100%", height: "100%" }}
//           ></NumberOfCalls>
//         </animated.div>
//         {/* <div key="k">
//           <ProgressBar
//             title="Weekly Target Archived"
//             percentage={
//               targetData != null && targetData != undefined
//                 ? targetData.weeklyTarget
//                 : null
//             }
//             color="primary"
//             style={{ width: "100%", height: "100%" }}
//           ></ProgressBar>
//         </div>
//         <div key="l">
//           <ProgressBar
//             title="Monthly Target Archived"
//             percentage={
//               targetData != null && targetData != undefined
//                 ? targetData.monthlyTarget
//                 : null
//             }
//             color="danger"
//             style={{ width: "100%", height: "100%" }}
//           ></ProgressBar>
//         </div>
//         <div key="m">
//           <ProgressBar
//             title="Booking Target Archived"
//             percentage={
//               targetData != null && targetData != undefined
//                 ? targetData.bookingTarget
//                 : null
//             }
//             color="warning"
//             style={{ width: "100%", height: "100%" }}
//           ></ProgressBar>
//         </div> */}
//         <animated.div style={animationProps} key="DailyPerformanceBar">
//           <ProgressBar
//             title="Daily Performance"
//             percentage={
//               performance != undefined && performance != null
//                 ? performance.daily
//                 : 0
//             }
//             color="primary"
//             style={{ width: "100%", height: "100%" }}
//           ></ProgressBar>
//         </animated.div>

//         <animated.div style={animationProps} key="WeeklyPerformanceBar">
//           <ProgressBar
//             title="Weekly Performance"
//             percentage={
//               performance != undefined && performance != null
//                 ? performance.weekly
//                 : 0
//             }
//             color="success"
//             style={{ width: "100%", height: "100%" }}
//           ></ProgressBar>
//         </animated.div>

//         <animated.div style={animationProps} key="MonthlyPerformanceBar">
//           <ProgressBar
//             title="Monthly Performance"
//             percentage={
//               performance != undefined && performance != null
//                 ? performance.monthly
//                 : 0
//             }
//             color="danger"
//             style={{ width: "100%", height: "100%" }}
//           ></ProgressBar>
//         </animated.div>

//         <animated.div style={animationProps} key="QuarterlyPerformanceBar">
//           <ProgressBar
//             title="Quarterly Performance"
//             percentage={
//               performance != undefined && performance != null
//                 ? performance.quarterly
//                 : 0
//             }
//             color="warning"
//             style={{ width: "100%", height: "100%" }}
//           ></ProgressBar>
//         </animated.div>

//         <animated.div style={animationProps} key="YearlyPerformanceBar">
//           <ProgressBar
//             title="Yearly Performance"
//             percentage={
//               performance != undefined && performance != null
//                 ? performance.yearly
//                 : 0
//             }
//             color="default"
//             style={{ width: "100%", height: "100%" }}
//           ></ProgressBar>
//         </animated.div>

//         <animated.div style={animationProps} key="TabNavigationMenu">
//           <div className="card " style={{ height: "245px" }}>
//             <TabNavigationMenu
//               activated={currentTab}
//               onChangeItem={switchTabHandler}
//               itemList={[
//                 { name: "Upcoming Tasks", key: "upcoming" },
//                 { name: "To Do", key: "todo" },
//               ]}
//             />
//             {currentTab === "upcoming" && (
//               <UpcommingTasksWidget
//                 style={{ width: "100%", height: "100%" }}
//                 incommingPage={"Dashboard"}
//               />
//             )}
//             {currentTab === "todo" && (
//               <TodoWidget style={{ width: "100%", height: "100%" }} />
//             )}
//           </div>
//         </animated.div>

//         <animated.div style={animationProps} key="SmsAlert">
//           {/* <ProgressBar style={{ width: "100%", height: "100%" }}>2</ProgressBar> */}
//           {/* <SmsAlert
//             demoSms={demoSmsCount}
//             style={{ width: "100%", height: "100%" }}
//           /> */}
//         </animated.div>

//         <animated.div style={animationProps} key="MultiChart">
//           {/* <ProgressBar style={{ width: "100%", height: "100%" }}>3</ProgressBar> */}
//           <MultiChart />
//         </animated.div>
//         {/* <div key="q">
//           <DoughnutChart />
//         </div> */}
//       </ResponsiveReactGridLayout>
//     </DashboardLayout>
//   );
// };

// Dashboard.defaultProps = {
//   className: "layout",
//   rowHeight: 30,
//   cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 },
// };

// function mapStateToProps(state) {
//   return {
//     auth: state.auth,
//     theme: state.theme.theme,
//     headhunter: state.headhunter,
//     auth: state.auth,
//   };
// }

// export default connect(mapStateToProps, {})(Dashboard);
import React, { useEffect, useState } from "react";
import {
  HOSTMexxar,
  MAINTENANCE_WORKSPACE_SUMMARY,
  REGISTRATION_WORKSPACE_SUMMARY,
  AUDIT_WORKSPACE_SUMMARY,
  HEADHUNTER_WORKSPACE_SUMMARY,
} from "../../configs/api-config";
import axios from "axios";
import "./waterball.css";
import moment from "moment";
import _, { filter } from "lodash";
import { connect } from "react-redux";
import Confetti from "react-confetti";
import MultiChart from "../chartJS.js/chart";
import { FeedCardWidget } from "../../widgets";
import Carousel from "react-bootstrap/Carousel";
import demoData from "./dashboardData.json";
import useWindowSize from "react-use-window-size";
import DoughnutChart from "../chartJS.js/doughnut";
import { useSpring, animated } from "react-spring";
import TodoWidget from "../../widgets/todo-widget/todo";
import ImageEdit from "../../widgets/image-edit/image-edit";
import { Responsive, WidthProvider } from "react-grid-layout";
import { TeamMemberWidget } from "../../widgets/dashboard-widgets";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import SmsAlert from "../../widgets/dashboard-widgets/alert/alert";
import DashboardChart from "../../widgets/dashboard-widgets/chart/chart";
import ProgressBar from "../../widgets/dashboard-widgets/progress-bar/progress-bar";
import DailyTarget from "../../widgets/dashboard-widgets/daily-target/daily-target";
import WaterFillingChart from "../../components/water-filling-chart/water-filling-chart";
import NumberOfCalls from "../../widgets/dashboard-widgets/number-of-calls/number-of-calls";
import UpcommingTasksWidget from "../../widgets/dashboard-widgets/upcommingTasks/upcommingTasks";
import TabNavigationMenu from "../../util/common/tabNavigation";

//ncode-----------------------------------------------------------------------------------------------------

// { i: "TeamMemberWidget", x: 0, y: 0, w: 9, h: 3, static: true },
// { i: "SmsAlert", x: 0, y: 0, w: 9, h: 3 },
// { i: "WaterFillingChart", x: 9, y: 0, w: 3, h: 6, static: true },
// { i: "DailyTarget", x: 0, y: 3, w: 6, h: 3 },
// { i: "NumberOfCalls", x: 6, y: 3, w: 3, h: 3 },
// // { i: "k", x: 0, y: 3, w: 3, h: 2 },
// // { i: "l", x: 3, y: 3, w: 3, h: 2 },
// // { i: "m", x: 6, y: 3, w: 3, h: 2 },
// { i: "DailyPerformanceBar", x: 9, y: 3, w: 3, h: 2 },
// { i: "WeeklyPerformanceBar", x: 9, y: 4, w: 3, h: 2 },
// { i: "MonthlyPerformanceBar", x: 9, y: 5, w: 3, h: 2 },
// { i: "QuarterlyPerformanceBar", x: 9, y: 6, w: 3, h: 2 },
// { i: "YearlyPerformanceBar", x: 9, y: 7, w: 3, h: 2 },
// { i: "TabNavigationMenu", x: 0, y: 5, w: 4, h: 7},
// { i: "MultiChart", x: 4, y: 5, w: 5, h: 7 },
// // { i: "q", x: 0, y: 6, w: 5, h: 7 },

// { i: "SmsAlert", x: 0, y: 1, w: 12, h: 3 },
//   { i: "TeamMemberWidget", x: 0, y: 2, w: 12, h: 3, static: true },
//   { i: "WaterFillingChart", x: 0, y: 3, w: 12, h: 6, static: true },
//   { i: "DailyTarget", x: 0, y: 4, w: 12, h: 3 },
//   { i: "NumberOfCalls", x: 0, y: 5, w: 12, h: 3 },
//   // { i: "k", x: 0, y: 3, w: 3, h: 2 },
//   // { i: "l", x: 3, y: 3, w: 3, h: 2 },
//   // { i: "m", x: 6, y: 3, w: 3, h: 2 },
//   { i: "DailyPerformanceBar", x: 0, y: 6, w: 12, h: 2 },
//   { i: "WeeklyPerformanceBar", x: 0, y: 7, w: 12, h: 2 },
//   { i: "MonthlyPerformanceBar", x: 0, y: 8, w: 12, h: 2 },
//   { i: "QuarterlyPerformanceBar", x: 0, y: 9, w: 12, h: 2 },
//   { i: "YearlyPerformanceBar", x: 0, y: 10, w: 12, h: 2 },
//   { i: "MultiChart", x: 0, y: 11, w: 12, h: 7 },
//   { i: "TabNavigationMenu", x: 0, y: 12, w: 12, h: 14 },
//   // { i: "q", x: 0, y: 6, w: 5, h: 7 },

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const lg = [
  { i: "TeamMemberWidget", x: 0, y: 0, w: 9, h: 3, static: true },
  { i: "SmsAlert", x: 0, y: 3, w: 9, h: 2 },
  { i: "DailyTarget", x: 0, y: 5, w: 6, h: 3 },
  { i: "NumberOfCalls", x: 6, y: 5, w: 3, h: 3 },
  { i: "TabNavigationMenu", x: 0, y: 8, w: 4, h: 7 },
  { i: "MultiChart", x: 4, y: 8, w: 5, h: 7 },
  //water chart and perfromance by percentage cards
  { i: "WaterFillingChart", x: 9, y: 0, w: 3, h: 6, static: true },
  { i: "DailyPerformanceBar", x: 9, y: 6, w: 3, h: 2 },
  { i: "WeeklyPerformanceBar", x: 9, y: 8, w: 3, h: 2 },
  { i: "MonthlyPerformanceBar", x: 9, y: 10, w: 3, h: 2 },
  { i: "QuarterlyPerformanceBar", x: 9, y: 12, w: 3, h: 2 },
  { i: "YearlyPerformanceBar", x: 9, y: 14, w: 3, h: 2 },
];

const md = [
  { i: "TeamMemberWidget", x: 0, y: 0, w: 12, h: 3, static: true },
  { i: "WaterFillingChart", x: 0, y: 3, w: 3, h: 6, static: true },
  { i: "SmsAlert", x: 0, y: 9, w: 12, h: 2 },
  { i: "DailyTarget", x: 0, y: 11, w: 12, h: 3 },
  { i: "NumberOfCalls", x: 0, y: 14, w: 12, h: 3 },
  { i: "DailyPerformanceBar", x: 0, y: 17, w: 12, h: 2 },
  { i: "WeeklyPerformanceBar", x: 0, y: 19, w: 12, h: 2 },
  { i: "MonthlyPerformanceBar", x: 0, y: 21, w: 12, h: 2 },
  { i: "QuarterlyPerformanceBar", x: 0, y: 23, w: 12, h: 2 },
  { i: "YearlyPerformanceBar", x: 0, y: 25, w: 12, h: 2 },
  { i: "MultiChart", x: 0, y: 27, w: 25, h: 7 },
  { i: "TabNavigationMenu", x: 0, y: 40, w: 12, h: 7 },
];

const Dashboard = (props) => {
  const MexxarAPI = HOSTMexxar;

  const { auth, auth1 } = props;

  const [confTimer, setconfTimer] = useState(90);
  const [confettiCount, setconfettiCount] = useState(90);
  const [currentTab, setCurrentTab] = useState("upcoming");
  const [compactType, setCompactType] = useState("vertical");
  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [monthlyPerformance, setmonthlyPerformance] = useState(90);

  // summery
  const [userSummery, setUserSummery] = useState({
    dailyTarget: 0,
    outgoingAnsweredCount: 0,
    outgoingMissedCount: 0,
    callBackCount: 0,
    incomingMissedCount: 0,
    incomingAnsweredCount: 0,
    dailyCleared: 0,
  });
  const [auditDataSummery, setAuditSummery] = useState({
    dailyTarget: 0,
    verified: 0,
    declined: 0,
  });

  const [targetData, setTargetData] = useState({
    weeklyTarget: 50,
    monthlyTarget: 10,
    bookingTarget: 68,
  });

  const [performance, setPerformance] = useState({
    daily: 0,
    weekly: 0,
    monthly: 0,
    quarterly: 0,
    yearly: 0,
  });

  const [noOfcalls, setNoOfcalls] = useState(null);
  const [demoSmsCount, setDemoSmsCount] = useState(null);

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(0px, 100px)" },
    config: {
      duration: 2000,
    },
  });

  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  const checkPermissions = (permission) => {
    let array = filter(auth?.userRole?.permissions, (item) => {
      return item.name == permission;
    });
    return array;
  };

  const onBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint({
      currentBreakpoint: breakpoint,
    });
  };

  const getPerformanceData = () => {
    axios
      .all([
        axios.get(`${MexxarAPI}dashboard/daily-performance`),
        axios.get(`${MexxarAPI}dashboard/weekly-performance`),
        axios.get(`${MexxarAPI}dashboard/monthly-performance`),
        axios.get(`${MexxarAPI}dashboard/quarterly-performance`),
        axios.get(`${MexxarAPI}dashboard/yearly-performance`),
      ])
      .then(
        axios.spread((...responses) => {
          if (responses != undefined && responses != null) {
            const dailyPerformance =
              responses[0].data.body[0] != undefined &&
              responses[0].data.body[0] != null
                ? responses[0].data.body[0].performance
                : 0;
            const weeklyPerformance =
              responses[1].data.body[0] != undefined &&
              responses[1].data.body[0] != null
                ? responses[1].data.body[0].performance
                : 0;
            const monthlyPerformance =
              responses[2].data.body[0] != undefined &&
              responses[2].data.body[0] != null
                ? responses[2].data.body[0].performance
                : 0;
            const quarterlyPerformance =
              responses[3].data.body[0] != undefined &&
              responses[3].data.body[0] != null
                ? responses[3].data.body[0].performance
                : 0;
            const yearlyPerformance =
              responses[4].data.body[0] != undefined &&
              responses[4].data.body[0] != null
                ? responses[4].data.body[0].performance
                : 0;

            setPerformance({
              daily: dailyPerformance,
              weekly: weeklyPerformance,
              monthly: monthlyPerformance,
              quarterly: quarterlyPerformance,
              yearly: yearlyPerformance,
            });
          }
        })
      );
  };

  const getTargetData = () => {
    let tempObj = {};
    checkPermissions(auth);
    if (
      checkPermissions("HEADHUNTER").length > 0 ||
      checkPermissions("ADMIN").length > 0
    ) {
      axios
        .get(HEADHUNTER_WORKSPACE_SUMMARY + "?employeeId=" + props.auth?.item?.id)
        .then((summary) => {
          if (summary.data.body.length > 0) {
            console.log(summary.data.body);
            tempObj = {
              dailyTarget: summary.data.body[0]?.dailyTarget,
              outgoingAnsweredCount:
                summary.data.body[0]?.outgoingAnsweredCount,
              outgoingMissedCount: summary.data.body[0]?.outgoingMissedCount,
              callBackCount: summary.data.body[0]?.callBackCount,
              incomingMissedCount: summary.data.body[0]?.incomingMissedCount,
              incomingAnsweredCount:
                summary.data.body[0]?.incomingAnsweredCount,
              dailyCleared: summary.data.body[0]?.dailyCleared,
              clearanceTarget: summary.data.body[0]?.clearanceTarget,
              dailyCallSpokenTarget:
                summary.data.body[0]?.dailyCallSpokenTarget,
              dailyCallingTarget: summary.data.body[0]?.dailyCallingTarget,
              voicemailCount: summary.data.body[0]?.voicemailCount,
            };
            setUserSummery(tempObj);
            setAuditSummery(null);
          }
        });
    } else if (checkPermissions("REGISTRATION").length > 0) {
      axios
        .get(
          REGISTRATION_WORKSPACE_SUMMARY + "?employeeId=" + props.auth?.item?.id
        )
        .then((summary) => {
          if (summary.data.body.length > 0) {
            console.log(summary.data.body);
            tempObj = {
              dailyTarget: summary.data.body[0]?.dailyTarget,
              outgoingAnsweredCount:
                summary.data.body[0]?.outgoingAnsweredCount,
              outgoingMissedCount: summary.data.body[0]?.outgoingMissedCount,
              callBackCount: summary.data.body[0]?.callBackCount,
              incomingMissedCount: summary.data.body[0]?.incomingMissedCount,
              incomingAnsweredCount:
                summary.data.body[0]?.incomingAnsweredCount,
              dailyCleared: summary.data.body[0]?.dailyCleared,
              clearanceTarget: summary.data.body[0]?.clearanceTarget,
              dailyCallSpokenTarget:
                summary.data.body[0]?.dailyCallSpokenTarget,
              dailyCallingTarget: summary.data.body[0]?.dailyCallingTarget,
              voicemailCount: summary.data.body[0]?.voicemailCount,
            };
            setUserSummery(tempObj);
            setAuditSummery(null);
          }
        });
    } else if (checkPermissions("AUDIT").length > 0) {
      axios
        .get(
          AUDIT_WORKSPACE_SUMMARY +
            "?employeeId=" +
            props.auth?.item?.id
        )
        .then((summary) => {
          if (summary.data.body.length > 0) {
            tempObj = {
              dailyTarget: summary.data.body[0]?.dailyTarget,
              verified: summary.data.body[0]?.verified,
              declined: summary.data.body[0]?.declined,
            };
            setUserSummery(null);
            setAuditSummery(tempObj);
          }
        });
    } else if (checkPermissions("MAINTENANCE").length > 0) {
      axios
        .get(
          MAINTENANCE_WORKSPACE_SUMMARY + "?employeeId=" + props.auth?.item?.id
        )
        .then((summary) => {
          if (summary.data.body.length > 0) {
            console.log(summary.data.body);
            tempObj = {
              dailyTarget: summary.data.body[0]?.dailyTarget,
              outgoingAnsweredCount:
                summary.data.body[0]?.outgoingAnsweredCount,
              outgoingMissedCount: summary.data.body[0]?.outgoingMissedCount,
              callBackCount: summary.data.body[0]?.callBackCount,
              incomingMissedCount: summary.data.body[0]?.incomingMissedCount,
              incomingAnsweredCount:
                summary.data.body[0]?.incomingAnsweredCount,
              dailyCleared: summary.data.body[0]?.dailyCleared,
              clearanceTarget: summary.data.body[0]?.clearanceTarget,
              dailyCallSpokenTarget:
                summary.data.body[0]?.dailyCallSpokenTarget,
              dailyCallingTarget: summary.data.body[0]?.dailyCallingTarget,
              voicemailCount: summary.data.body[0]?.voicemailCount,
            };
            setUserSummery(tempObj);
            setAuditSummery(null);
          }
        });
    }
  };

  const onCompactTypeChange = () => {
    const { compactType: oldCompactType } = props;
    const compactType =
      oldCompactType === "horizontal"
        ? "vertical"
        : oldCompactType === "vertical"
        ? null
        : "horizontal";
    setCompactType({ compactType });
  };

  //ncode---------------------------------------------------------------------------------------------------
  const { width, height } = useWindowSize();
  //ncode---------------------------------------------------------------------------------------------------

  const getGreetingTime = () => {
    let currentTime = new Date();

    const splitAfternoon = 12; // 24hr time to split the afternoon
    const splitEvening = 15; // 24hr time to split the evening
    const currentHour = moment(currentTime).format("HH");

    if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
      // Between 12 PM and 5PM
      return "Good Afternoon";
    } else if (currentHour >= splitEvening) {
      // Between 5PM and Midnight
      return "Good Evening";
    }
    // Between Dawn and noon
    return "Good Morning";
  };

  // const confettiActivator = () => {
  //   // if (checkPermissions("PAYROLE").length > 0) {
  //   //   timesheetReviewStatus = 1;
  //   // } else if (checkPermissions("CANDIDATE").length > 0) {
  //   //   timesheetReviewStatus = 0;
  //   // }

  //   let url = `${MexxarAPI}workspace/maintenance/summary`;

  //   axios.get(`${MexxarAPI}workspace/maintenance/summary`).then((res) => {});

  //   if (monthlyPerformance == 90) {
  //     // setconfettiCount(100);

  //     setTimeout(function () {
  //       setconfettiCount(180);
  //       // setconfTimer(0);
  //     }, 30000);

  //     setTimeout(function () {
  //       setconfettiCount(0);
  //       // setconfTimer(0);
  //     }, 32000);
  //   }
  // };
  const [responseData, setResponseData] = useState(null);
  const confettiActivator = () => {
    // Define the API endpoint
    let url = `${MexxarAPI}workspace/maintenance/summary`;
  
    // Make the Axios request
    axios.get(url)
      .then((res) => {
        // Process the response data
        setResponseData(res.data.body);
  console.log("HHHHHHHHHHH",res.data.body)
        // Example: Check if monthlyPerformance is present in the response data
        if (responseData && responseData.monthlyPerformance === 90) {
          // Set confetti count and timings
          setTimeout(() => {
            setconfettiCount(180);
          }, 30000);
  
          setTimeout(() => {
            setconfettiCount(0);
          }, 32000);
        }
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  };
  
  const [partTrick, setPartyTrick] = useState(false);

  function handleKeyPress(event) {
    if (event.key === "x") {
      setPartyTrick(true);

      setTimeout(() => {
        setPartyTrick(false);
      }, 5000);
    } else if (event.key === "z") {
      setPerformance(demoData[0].performace);
      setAuditSummery(demoData[1].audit);
      setUserSummery(demoData[2].userSummery);
      setNoOfcalls(demoData[3].noOfCalls);
      setDemoSmsCount(demoData[4].smsCount);
    }
  }

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    getPerformanceData();
    getTargetData();
    confettiActivator();
    return () => {};
  }, []);

  return (
    <DashboardLayout
      title="Dashboard"
      subTitle={
        getGreetingTime() + "," + " " + props.auth?.item?.firstName + " "
      }
      dashboardType="main"
    >
      {/* party Trick */}
      {partTrick && (
        <Confetti
          width={width}
          height={"1300%"}
          numberOfPieces={confettiCount}
        />
      )}

      <ResponsiveReactGridLayout
        style={{ marginTop: "-25px" }}
        {...props}
        layouts={{ lg: lg, sm: md }}
        onBreakpointChange={onBreakpointChange}
        measureBeforeMount={false}
        useCSSTransforms
        compactType={compactType}
        preventCollision={!compactType}
      >
        <animated.div style={animationProps} key="TeamMemberWidget">
          <TeamMemberWidget
            style={{ width: "100%", height: "100%" }}
          ></TeamMemberWidget>
        </animated.div>

        <animated.div
          style={{
            ...animationProps,
            display: "flex",
            justifyContent: "center",
          }}
          key="WaterFillingChart"
        >
          <div className="d-flex justify-content-between">
            {/* <div>&#8592;</div> */}

            {/* <WaterFillingChart count={monthlyPerformance} style={{height:'100%'}}></WaterFillingChart> */}
            {/* {performance != null && performance != undefined
              ? console.log("Performance :", performance)
              : ""} */}
            <Carousel indicators={false}>
              <Carousel.Item>
                <WaterFillingChart
                  count={
                    performance != undefined && performance != null
                      ? performance.monthly
                      : 0
                  }
                  displayName={"Monthly"}
                ></WaterFillingChart>

                <div className="waterFiller" style={{ opacity: "0.0" }}>
                  Water Filler
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <WaterFillingChart
                  count={
                    performance != undefined && performance != null
                      ? performance.weekly
                      : 0
                  }
                  displayName={"Weekly"}
                ></WaterFillingChart>

                <div className="waterFiller" style={{ opacity: "0.0" }}>
                  Water Filler
                </div>
              </Carousel.Item>
              <Carousel.Item>
                <WaterFillingChart
                  count={
                    performance != undefined &&
                    performance != null &&
                    performance != 0
                      ? performance.daily
                      : 0
                  }
                  displayName={"Daily"}
                ></WaterFillingChart>

                <div className="waterFiller" style={{ opacity: "0.0" }}>
                  Water Filler
                </div>
              </Carousel.Item>
            </Carousel>

            {/* <div>&#8594;</div> */}
          </div>
        </animated.div>

        <animated.div style={animationProps} key="DailyTarget">
          {/* <ProgressBar style={{ width: "100%", height: "100%" }}></ProgressBar> */}
          <DailyTarget
            style={{ width: "100%", height: "100%" }}
            userSummery={userSummery}
            auditDataSummery={auditDataSummery}
            responseData={responseData}
          ></DailyTarget>
        </animated.div>

        <animated.div style={animationProps} key="NumberOfCalls">
          {/* <ProgressBar style={{ width: "100%", height: "100%" }}></ProgressBar> */}
          <NumberOfCalls
            demoData={noOfcalls}
            style={{ width: "100%", height: "100%" }}
          ></NumberOfCalls>
        </animated.div>
        {/* <div key="k">
          <ProgressBar
            title="Weekly Target Archived"
            percentage={
              targetData != null && targetData != undefined
                ? targetData.weeklyTarget
                : null
            }
            color="primary"
            style={{ width: "100%", height: "100%" }}
          ></ProgressBar>
        </div>
        <div key="l">
          <ProgressBar
            title="Monthly Target Archived"
            percentage={
              targetData != null && targetData != undefined
                ? targetData.monthlyTarget
                : null
            }
            color="danger"
            style={{ width: "100%", height: "100%" }}
          ></ProgressBar>
        </div>
        <div key="m">
          <ProgressBar
            title="Booking Target Archived"
            percentage={
              targetData != null && targetData != undefined
                ? targetData.bookingTarget
                : null
            }
            color="warning"
            style={{ width: "100%", height: "100%" }}
          ></ProgressBar>
        </div> */}
        <animated.div style={animationProps} key="DailyPerformanceBar">
          <ProgressBar
            title="Daily Performance"
            percentage={
              performance != undefined && performance != null
                ? performance.daily
                : 0
            }
            color="primary"
            style={{ width: "100%", height: "100%" }}
          ></ProgressBar>
        </animated.div>

        <animated.div style={animationProps} key="WeeklyPerformanceBar">
          <ProgressBar
            title="Weekly Performance"
            percentage={
              performance != undefined && performance != null
                ? performance.weekly
                : 0
            }
            color="success"
            style={{ width: "100%", height: "100%" }}
          ></ProgressBar>
        </animated.div>

        <animated.div style={animationProps} key="MonthlyPerformanceBar">
          <ProgressBar
            title="Monthly Performance"
            percentage={
              performance != undefined && performance != null
                ? performance.monthly
                : 0
            }
            color="danger"
            style={{ width: "100%", height: "100%" }}
          ></ProgressBar>
        </animated.div>

        <animated.div style={animationProps} key="QuarterlyPerformanceBar">
          <ProgressBar
            title="Quarterly Performance"
            percentage={
              performance != undefined && performance != null
                ? performance.quarterly
                : 0
            }
            color="warning"
            style={{ width: "100%", height: "100%" }}
          ></ProgressBar>
        </animated.div>

        <animated.div style={animationProps} key="YearlyPerformanceBar">
          <ProgressBar
            title="Yearly Performance"
            percentage={
              performance != undefined && performance != null
                ? performance.yearly
                : 0
            }
            color="default"
            style={{ width: "100%", height: "100%" }}
          ></ProgressBar>
        </animated.div>

        <animated.div style={animationProps} key="TabNavigationMenu">
          <div className="card " style={{ height: "245px" }}>
            <TabNavigationMenu
              activated={currentTab}
              onChangeItem={switchTabHandler}
              itemList={[
                { name: "Upcoming Tasks", key: "upcoming" },
                { name: "To Do", key: "todo" },
              ]}
            />
            {currentTab === "upcoming" && (
              <UpcommingTasksWidget
                style={{ width: "100%", height: "100%" }}
                incommingPage={"Dashboard"}
              />
            )}
            {currentTab === "todo" && (
              <TodoWidget style={{ width: "100%", height: "100%" }} />
            )}
          </div>
        </animated.div>

        <animated.div style={animationProps} key="SmsAlert">
          {/* <ProgressBar style={{ width: "100%", height: "100%" }}>2</ProgressBar> */}
          {/* <SmsAlert
            demoSms={demoSmsCount}
            style={{ width: "100%", height: "100%" }}
          /> */}
        </animated.div>

        <animated.div style={animationProps} key="MultiChart">
          {/* <ProgressBar style={{ width: "100%", height: "100%" }}>3</ProgressBar> */}
          <MultiChart />
        </animated.div>
        {/* <div key="q">
          <DoughnutChart />
        </div> */}
      </ResponsiveReactGridLayout>
    </DashboardLayout>
  );
};

Dashboard.defaultProps = {
  className: "layout",
  rowHeight: 30,
  cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 },
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    theme: state.theme.theme,
    headhunter: state.headhunter,
  };
}

export default connect(mapStateToProps, {})(Dashboard);
