import React, { useState, useEffect } from "react";
//import "./working-card-widget.style.css";
import { Badge, Card, CardBody, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMars,
  faVenus,
  faPhone,
  faEnvelope,
  faCalendar,
  faPhoneSlash,
  faCalendarAlt,
  faArrowRight,
  faCaretSquareRight,
  faCaretSquareLeft,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import ModalComponent from "../../components/Modal/modalComponent";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";

const CreditControllCandidatesCallBackWidget = (props) => {
  const { userProfile } = props;
  const [calendarShow, setCalendarShow] = useState(false);
  const [showLastContacted, setShowLastContacted] = useState(false);
  const handleShowLastContacted = () =>
    setShowLastContacted(!showLastContacted);
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const [certificates, setCertificates] = useState([]);

  const [showEmailModal, setShowEmailModal] = useState(false);

  const toggleShowEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };

  const toggleM = () => setShowEmailModal(!showEmailModal);

  useEffect(() => {
    axios
      .get("http://207.246.85.110/mockData/call-back-widget.json")
      .then((res) => {
        setCertificates(res.data.data);
      });
  }, []);
  return (
    <div style={{ minWidth: "800px" }}>
      {certificates.map((post, i) => {
        return (
          <div
            key={i}
            className=" card d-flex flex-row main bg-light p-1 "
            style={{ marginBottom: "5px" }}
          >
            <Link
              to={{
                pathname: "/dashboard/candidate-profile/1",
              }}
            >
              <div className="d-flex ">
                {/* <image src={logo} /> */}
                <span className="w-64 avatar ">
                  <img src={post.image} alt="."></img>
                </span>
                {/* <i className="on"></i> */}
              </div>
            </Link>
            <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
              <div
                className="pl-2 d-flex  justify-content-between"
                style={{ maxWidth: "150px" }}
              >
                <div>
                  {post.firstName} {post.lastName}
                </div>

                <div>
                  <FontAwesomeIcon
                    color="#458AF7"
                    icon={post.gender == "male" ? faMars : faVenus}
                  />
                </div>
              </div>
              <div className=" d-flex ">
                <div className="mx-2 ">
                  <Link
                    to={{
                      pathname: "/dashboard/call",
                      aboutParams: {
                        imageLink: post.image,
                      },
                    }}
                  >
                    <RoundIconButton
                      buttonColor="#00C426"
                      icon={faPhone}
                      iconSize="fa-md"
                      buttonSize="25px"
                    ></RoundIconButton>
                  </Link>
                </div>
                <div className="mx-2 ">
                  <RoundIconButton
                    buttonColor="#458AF7"
                    icon={faEnvelope}
                    click={() => toggleShowEmailModal()}
                    buttonSize="25px"
                  ></RoundIconButton>
                </div>

                <div className=" mx-2  d-flex align-items-center ">
                  <FontAwesomeIcon
                    color="#FF5603"
                    icon={faCalendarAlt}
                    onClick={() => handleCalendarShow(true)}
                    className="fa-lg mb-2 btn-raised btn-wave "
                  />
                </div>
              </div>
            </div>

            <div
              className="d-flex align-items-center"
              style={{ flex: 2 }}
            ></div>
            <div style={{ flex: 3 }}></div>
            <div
              className="d-flex align-items-center  flex-column"
              style={{ flex: 3 }}
            >
              <div style={{ height: "4vh" }}>Submitted on</div>

              <div
                className="d-flex justify-content-center "
                style={{ height: "3vh" }}
              >
                <span className="mr-1">
                  <small className="text-muted">{post.date}</small>
                </span>
                <span className="mr-1">
                  <small className="text-muted">{post.time}</small>
                </span>
              </div>
              <div style={{ height: "3vh" }}></div>
            </div>

            {!showLastContacted ? (
              <div className="d-flex flex-column " style={{ flex: 3 }}>
                <div style={{ height: "4vh", justifyContent: "space-between" }}>
                  <div className="d-flex justify-content-between">
                    <lable></lable>
                    <lable>Reschedule</lable>
                    <FontAwesomeIcon
                      icon={faCaretSquareRight}
                      onClick={() => handleShowLastContacted()}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center  flex-column">
                  <div style={{ height: "3vh" }}>{post.callBack}</div>
                  <div
                    className="d-flex justify-content-center "
                    style={{ height: "3vh" }}
                  >
                    <span className="mr-1">
                      <small className="text-muted">{post.date}</small>
                    </span>
                    <span className="mr-1">
                      <small className="text-muted">{post.time}</small>
                    </span>
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex flex-column " style={{ flex: 3 }}>
                <div style={{ height: "4vh" }}>
                  <div className="d-flex justify-content-between">
                    <lable></lable>
                    <lable>Last Contacted By</lable>
                    <FontAwesomeIcon
                      icon={faCaretSquareLeft}
                      onClick={() => handleShowLastContacted()}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-center flex-column">
                  <div style={{ height: "3vh" }}>{post.lastContacted}</div>
                  <div
                    className="d-flex justify-content-center "
                    style={{ height: "3vh" }}
                  >
                    <span className="mr-1">
                      <small className="text-muted">{post.lastDate}</small>
                    </span>
                    <span className="mr-1">
                      <small className="text-muted">@ {post.lastTime}</small>
                    </span>
                  </div>
                </div>
              </div>
            )}
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ flex: 4 }}
            >
              <Button color="success" className="mr-2">
                Show
              </Button>
            </div>
          </div>
        );
      })}
      <ModalComponent
        show={calendarShow}
        header="Reschedule a call"
        closeModal={() => handleCalendarShow(false)}
      >
        <SmallCalendar />{" "}
        <div className="mt-3">
          {" "}
          <div className="d-flex justify-content-end">
            <Button className="mr-2" color="success">
              Send
            </Button>{" "}
            <Button color="secondary" onClick={() => handleCalendarShow(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </ModalComponent>

      <ModalComponent
        show={showEmailModal}
        header="E mail and sms"
        closeModal={toggleM}
      >
        <hr />
        <EmailNav />
        <div className="mt-3">
          {" "}
          <div className="d-flex justify-content-end">
            <Button className="mr-2" color="success">
              Send
            </Button>{" "}
            <Button color="secondary" onClick={toggleM}>
              Cancel
            </Button>
          </div>
        </div>
      </ModalComponent>
    </div>
  );
};

export default CreditControllCandidatesCallBackWidget;
