// import React from "react";
// import { Card } from "reactstrap";
// import {
//   faRocket,
//   faPhone,
//   faCheck,
//   faTimes,
// } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import notNull from "../../../util/common/notNull";

// const DailyTarget = (props) => {
//   const { style, userSummery, auditDataSummery,responseData } = props;
//   console.log("angi user summeries",responseData)








  
//   return (
//     <Card style={style}>
//       <div className="text-center p-2">
//         <div style={{ flex: 1 }}>
//           {notNull(responseData) ? (
//             <>
//               <div className="sub-1 p-1">
//                 <div style={{ flex: 1 }}>Daily Call Targets</div>
//                 <div style={{ flex: 1 }}>Answered Calls</div>
//                 <div style={{ flex: 1 }}>Daily Clearance</div>
//               </div>

//               <div className="sub-1 p-1">
//                 <div style={{ flex: 1 }}>{responseData?.dailyCallingTarget}</div>
//                 <div style={{ flex: 1 }}>
//                   {responseData?.outgoingAnsweredCount}
//                 </div>
//                 <div style={{ flex: 1 }}>{responseData?.dailyCleared}</div>
//               </div>
//               <div className="sub-1 p-1">
//                 <div style={{ flex: 1 }}>
//                   <FontAwesomeIcon
//                     icon={faRocket}
//                     size="2x"
//                     color="#E8B406"
//                   ></FontAwesomeIcon>
//                 </div>
//                 <div style={{ flex: 1 }}>
//                   <FontAwesomeIcon
//                     icon={faPhone}
//                     size="2x"
//                     color="#00C426"
//                   ></FontAwesomeIcon>
//                 </div>
//                 <div style={{ flex: 1 }}>
//                   <FontAwesomeIcon
//                     icon={faCheck}
//                     size="2x"
//                     color="#FE00C5"
//                   ></FontAwesomeIcon>
//                 </div>
//               </div>
//             </>
//           ) : (
//             <>
//               <div className="sub-1 p-1">
//                 <div style={{ flex: 1 }}>Daily Targets</div>
//                 <div style={{ flex: 1 }}>Verified</div>
//                 <div style={{ flex: 1 }}>Declined</div>
//               </div>

//               <div className="sub-1 p-1">
//                 <div style={{ flex: 1, color: "#B9C0D3" }}>
//                   {auditDataSummery.dailyTarget}
//                 </div>
//                 <div style={{ flex: 1, color: "#B9C0D3" }}>
//                   {auditDataSummery.verified}
//                 </div>
//                 <div style={{ flex: 1, color: "#B9C0D3" }}>
//                   {auditDataSummery.declined}
//                 </div>
//               </div>
//               <div className="sub-1 p-1">
//                 <div style={{ flex: 1 }}>
//                   <FontAwesomeIcon
//                     icon={faRocket}
//                     size="2x"
//                     color="#E8B406"
//                   ></FontAwesomeIcon>
//                 </div>
//                 <div style={{ flex: 1 }}>
//                   <FontAwesomeIcon
//                     icon={faCheck}
//                     size="2x"
//                     color="#fc0b03"
//                   ></FontAwesomeIcon>
//                 </div>
//                 <div style={{ flex: 1 }}>
//                   <FontAwesomeIcon
//                     icon={faTimes}
//                     size="2x"
//                     color="#fc0b03"
//                   ></FontAwesomeIcon>
//                 </div>
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//     </Card>
//   );
// };

// export default DailyTarget;
import React from "react";
import { Card } from "reactstrap";
import {
  faRocket,
  faPhone,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import notNull from "../../../util/common/notNull";

const DailyTarget = (props) => {
  const { style, userSummery, auditDataSummery, responseData } = props;


  const data = Array.isArray(responseData) && responseData.length > 0
    ? responseData[0]
    : null;

  return (
    <Card style={style}>
      <div className="text-center p-2">
        <div style={{ flex: 1 }}>
          {notNull(data) ? (
            <>
              <div className="sub-1 p-1">
                <div style={{ flex: 1 }}>Daily Call Targets</div>
                <div style={{ flex: 1 }}>Answered Calls</div>
                <div style={{ flex: 1 }}>Daily Clearance</div>
              </div>

              <div className="sub-1 p-1">
                <div style={{ flex: 1 }}>{data.remainingCallingTarget}/{data.dailyCallingTarget}</div>
                <div style={{ flex: 1 }}>{data.outgoingAnsweredCount}</div>
                <div style={{ flex: 1 }}>{data.dailyCleared}</div>
              </div>
              <div className="sub-1 p-1">
                <div style={{ flex: 1 }}>
                  <FontAwesomeIcon
                    icon={faRocket}
                    size="2x"
                    color="#E8B406"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <FontAwesomeIcon
                    icon={faPhone}
                    size="2x"
                    color="#00C426"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    size="2x"
                    color="#FE00C5"
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="sub-1 p-1">
                <div style={{ flex: 1 }}>Daily Targets</div>
                <div style={{ flex: 1 }}>Verified</div>
                <div style={{ flex: 1 }}>Declined</div>
              </div>

              <div className="sub-1 p-1">
                <div style={{ flex: 1, color: "#B9C0D3" }}>
                  {auditDataSummery.dailyTarget}
                </div>
                <div style={{ flex: 1, color: "#B9C0D3" }}>
                  {auditDataSummery.verified}
                </div>
                <div style={{ flex: 1, color: "#B9C0D3" }}>
                  {auditDataSummery.declined}
                </div>
              </div>
              <div className="sub-1 p-1">
                <div style={{ flex: 1 }}>
                  <FontAwesomeIcon
                    icon={faRocket}
                    size="2x"
                    color="#E8B406"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <FontAwesomeIcon
                    icon={faCheck}
                    size="2x"
                    color="#fc0b03"
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="2x"
                    color="#fc0b03"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default DailyTarget;
