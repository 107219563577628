import React, { useEffect, useState } from "react";
import Text from "./text";
import axios from "axios";
import _, { filter } from "lodash";
import SnapShot from "./snapShot";
import { connect } from "react-redux";
import { Label, Input, FormGroup, Col } from "reactstrap";
import { HOSTMexxar } from "../../../configs/api-config";
import TabNavigationMenu from "../../../util/common/tabNavigation";
import useDidMountEffect from "./intialRunUseEffect";

const SnapShotNav = (props) => {
  const MexxarAPI = HOSTMexxar;

  const { closeBody, user, closeModal, auth } = props;

  const [items, setItems] = useState([]);
  const [currentTab, setCurrentTab] = useState("email");
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [emailTemplateList, setEmailTemplateList] = useState(null);
  const [templateContent, setTemplateContent] = useState("");

  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  const getAllEmailTemplates = () => {
    axios.get(MexxarAPI + "email-templates").then((emailTemplates) => {
      setEmailTemplateList(emailTemplates.data.body);
    });
  };

  // const setData = (data) =>{
  //   closeBody(data);
  // }

  const checkPermissions = (permission) => {
    let array = filter(auth.item.userRole.permissions, (item) => {
      return item.name == permission;
    });
    return array;
  };

  useEffect(() => {
    getAllEmailTemplates();
    if (checkPermissions("CANDIDATE").length > 0) {
      setItems([
        { name: "Email", key: "email" },
        // { name: "Text", key: "text" },
      ]);
    } else if (checkPermissions("EMPLOYEE").length > 0) {
      setItems([{ name: "Email", key: "email" }]);
    }
  }, []);

  useEffect(() => {
    if (emailTemplateList !== null) {
      for (let i = 0; i < emailTemplateList.length; i++) {
        if (emailTemplateList[i].templateName === selectedTemplate) {
          setTemplateContent(emailTemplateList[i].template);
        } else {
          setTemplateContent("");
        }
      }
    }
  }, [emailTemplateList, selectedTemplate]);

  return (
    <>
      <FormGroup row>
        <Label sm={4}>Template</Label>
        <Col sm={8}>
          <Input
            type="select"
            name="template"
            onChange={(template) => setSelectedTemplate(template.target.value)}
          >
            <option value="">Default</option>
            {emailTemplateList === undefined || emailTemplateList === null ? (
              ""
            ) : (
              <>
                {emailTemplateList.map((template, id) => {
                  return (
                    <option value={template.templateName} key={id}>
                      {template.templateName}
                    </option>
                  );
                })}
              </>
            )}
          </Input>
        </Col>
      </FormGroup>
      <TabNavigationMenu
        activated={currentTab}
        onChangeItem={switchTabHandler}
        itemList={items}
      />

      {currentTab === "email" && (
        <SnapShot
          closeModal={closeModal}
          selectedUser={user}
          template={selectedTemplate}
          templateBody={templateContent}
          setTemplateContent={setTemplateContent}
        />
      )}
      {currentTab === "text" && (
        <Text closeModal={closeModal} user={user} template={selectedTemplate} />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(SnapShotNav);
