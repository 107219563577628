import React, { useEffect, useState } from "react";
import "./docView.style.css";
import { split } from "lodash";
import { Document, Page, pdfjs } from "react-pdf";
import { useDropzone } from "react-dropzone";
import "react-dropzone-uploader/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faAngleRight,
  faImage,
  faMinus,
  faPlus,
  faRedo,
  faUndo,
} from "@fortawesome/free-solid-svg-icons";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import {
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Spinner,
} from "reactstrap";
import ImageEdit from "../../../widgets/image-edit/image-edit";
import { connect } from "react-redux";
import { saveGeneratedUrl } from "../../../redux/actions/timesheetBatchScanAction";
import axios from "axios";
import { HOSTMexxar } from "../../../configs/api-config";

const thumbsContainer = {
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  // marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  justify: "center",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 300,
  height: 300,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "100%",
  height: "100%",
};

const dropzoneLarge = {
  display: "flex",
  border: "2px solid #2c3763",
  height: "40rem",
  width: "80%",
  padding: 10,
  justifyContent: "center",
  alignItems: "center",
};

const dropzoneSmall = {
  display: "flex",
  border: "2px solid #2c3763",
  height: "5rem",
  width: "80%",
  padding: 10,
  justifyContent: "center",
  alignItems: "center",
};

function PreviewsBatchTimeSheetsForMobileUploads(props) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const {
    fileUploaded,
    documentUploaded,
    documentPreUploaded,
    expandStatus,
    showUploadSection,
    incomingData,
    showFile,
    review,
    locallySaveTimesheetsReducer,
  } = props;
  const [zoom, setZoom] = useState(1);
  const [files, setFiles] = useState([]);
  const [croppedFile, setCroppedFile] = useState([]);
  const [savePottentialFile, setSavePottentialFile] = useState([]);
  const [imageZoom, setImageZoom] = useState(400);
  const [fileTypeofUpload, setFileTypeofUpload] = useState("");
  const [editModal, setEditmodal] = useState(false);
  const [editableImage, setEditableImage] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [dropZoneActivateStatus, setDropZoneActivateStatus] = useState(false);
  const [error, setError] = useState(false);

  // useDidMountEffect(() => {
  //   if (incomingData == null) {
  //     setFiles([]);
  //   }
  // }, [incomingData]);
  const modalCallback = (type) => {
    if (type == "save") {
      setFiles(croppedFile); //preview the edited image
      props.onUpload(savePottentialFile); //passing to another component to upload to the db
    }
    setEditmodal(false);
  };

  const cropImage = (data) => {
    setEditmodal(data.show);
    setEditableImage(data.image);
    // remove();
  };
  const [generatedUrl, setGeneratedUrl] = useState("");

  const getMobileTimesheets = () => {
    axios
      .get(HOSTMexxar + "workspace/payroll?ps=PENDING&hrs=PENDING&rs=PENDING")
      .then((res) => {
        if (res.data.status === "success") {
          props.onUpload(res.data.body);
        } else if (res.data.status === "failed") {
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
      });
  };

  useEffect(() => {
    getMobileTimesheets();
    return () => {};
  }, []);

  useEffect(() => {
    const storedUrl = localStorage.getItem("generatedUrl");
    if (storedUrl) {
      setGeneratedUrl(storedUrl);
    }
    return () => {};

  }, []);

  useEffect(() => {
    const storedUrl = localStorage.getItem("generatedUrl");

    if (storedUrl) {
      const expirationTime = localStorage.getItem("generatedUrlExpiration");

      if (expirationTime && new Date(expirationTime) > new Date()) {
        setGeneratedUrl(storedUrl);
      } else {
        localStorage.removeItem("generatedUrl");
        localStorage.removeItem("generatedUrlExpiration");
      }
    }
    return () => {};

  }, []);

  const [modalPreviewPicture, setModalPreviewPicture] = useState([]);
  const [uploadedPDFDocument, setUploadedPDFDocument] = useState([]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject(error);
      };
    });
  }

  const [errorTrustIdPDF, setErrorTrustIdPDF] = useState(false);

  const convertToPDF = (base64Array) => {
    const base64toBlob = (base64Data) => {
      const binaryData = atob(base64Data);

      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }
      return new Blob([uint8Array], { type: "application/pdf" });
    };

    // Convert each base64 to blob and set in state
    const blobs = base64toBlob(base64Array);
    setUploadedPDFDocument(blobs);
  };

  const getMobileUploadedTimesheet = (file) => {
    let tempData = showFile?.file;

    if (tempData != undefined || tempData != null) {
      let url = String(
        HOSTMexxar +
          "timesheet-doc/candidates/" +
          tempData.booking.candidatesId +
          "/" +
          file
      );
      if (fileTypeofUpload === "pdf") {
        setUploadedPDFDocument([]);
        axios
          .get(url, {
            responseType: "arraybuffer", // Ensure the response is treated as binary data
            headers: {
              "Content-Type": "application/pdf", // Specify that you expect a PDF file
            },
          })
          .then((response) => {
            if (response.status === 200) {
              setUploadedPDFDocument(response.data);
            } else {
              // Handle error
            }
          })
          .catch((error) => {
            // Handle error
          });
      } else {
        setModalPreviewPicture([]);
        loadImage(url);
      }
    }
  };
  const loadImage = async (url) => {
    const config = { responseType: "blob" };
    try {
      const res = await axios.get(url, config);
      const result = await getBase64(new File([res.data], "sample"));
      setModalPreviewPicture(result);
      // setLoading(false); // Set loading to false after blob is converted
    } catch (error) {
      console.error("Error loading image:", error);
      // setLoading(false); // Set loading to false on error
    }
  };

  useEffect(() => {
    let tempShowFile = showFile?.file?.documentUrl;

    if (tempShowFile != null || tempShowFile != undefined) {
      getMobileUploadedTimesheet(showFile?.file?.documentUrl.split("/")[1]);

      var extension = showFile?.file?.documentUrl.split(".");
      var fileExtension = extension.length > 1 ? extension.pop() : null;

      setFileTypeofUpload(fileExtension);
    }

    return () => {};
  }, [showFile]);

  const [showImageModal, setShowImageModal] = useState([]);

  const toggleModal = (index) => {
    // Toggle the visibility of the clicked row level 2
    if (showImageModal.includes(index)) {
      setShowImageModal(
        showImageModal.filter((rowIndex) => rowIndex !== index)
      );
    } else {
      setShowImageModal([...showImageModal, index]);
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const pdfNextPage = () => {
    if (numPages > pageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const pdfPreviousPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1);
    }
  };

  const setScale = () => {
    setDropZoneActivateStatus(false);
    // if (documentPreUploaded){
    //   setDropZoneActivateStatus(false);
    // }else if (!documentPreUploaded){
    //   setDropZoneActivateStatus(true);
    // }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const editedImagePReviewFunction = (data) => {
    var f = new File(["image"], "image.jpeg", {
      type: "image/jpeg",
      lastModified: new Date(),
    });
    Object.assign(f, {
      preview: data,
      path: "image.jpeg",
    });

    setCroppedFile([f]);
    // setFiles([f]); //preview the edited image

    var fileToUpload = dataURLtoFile(data, editableImage.name);
    setSavePottentialFile([fileToUpload]);
    // props.onUpload([fileToUpload]); //passing to another component to upload to the db
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
    return () => {};

  }, [files]);

  useDidMountEffect(() => {
    if (files.length > 0) {
      fileUploaded(true);
    } else {
      fileUploaded(false);
    }
    return () => {};
  }, [files]);

  useEffect(() => {
    setFiles([]);
    return () => {};

  }, [documentUploaded]);

  const [rotationAngle, setRotationAngle] = useState(0);

  const rotateImage = () => {
    setRotationAngle(rotationAngle + 90);
  };
  const rotateLeft = () => {
    setRotationAngle((angle) => angle + 90);
  };

  const rotateRight = () => {
    setRotationAngle((angle) => angle - 90);
  };
  const handleZoomIn = () => {
    setZoom((prevZoom) => Math.min(parseFloat(prevZoom) + 0.1, 2));
  };

  const handleZoomOut = () => {
    setZoom((prevZoom) => Math.max(parseFloat(prevZoom) - 0.1, 0.5));
  };

  return (
    <>
      {review?.includes(showFile?.file?.bookingRef) && (
        <section className="container d-flex flex-column align-items-center justify-content-center">
          {fileTypeofUpload === "pdf" ? (
            <>
              <div className="justify-content-center d-flex  flex-column align-items-center p-2">
                <div height="1000px">
                  <Document
                    //file={uploadedPDFDocument} //if the pdf is coming with a viewable link use this method
                    file={{ data: uploadedPDFDocument }} //if the pdf file starts as %PDF use this method to show the file.
                    onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      pageNumber={pageNumber}
                      scale={zoom}
                      width={expandStatus ? 700 : 400}
                      height={expandStatus ? 700 : 400}
                      rotate={rotationAngle}
                    />
                  </Document>
                </div>
                <br />

                <div className="justify-content-center d-flex flex-column align-items-center">
                  <div className="" style={{ width: "400px" }}>
                    <Input
                      type="range"
                      min={0.5}
                      max={2}
                      step={0.1}
                      value={zoom}
                      onChange={(e) => setZoom(parseFloat(e.target.value))}
                    />
                  </div>{" "}
                </div>
                <div className="d-flex align-items-center justify-content-center">
                  <div style={{ width: "5px" }}>
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      onClick={pdfPreviousPage}
                      className="  btn-raised btn-wave pointer shadow-none"
                    />
                  </div>
                  <div className="m-2">
                    Page {pageNumber} of {numPages}
                  </div>
                  <div style={{ width: "5px" }}>
                    <FontAwesomeIcon
                      icon={faAngleRight}
                      className="  btn-raised btn-wave pointer shadow-none"
                      onClick={pdfNextPage}
                    />
                  </div>
                </div>
                <br />
                <div className="justify-content-center d-flex flex-column align-items-center">
                  <div style={{ position: "absolute", zIndex: 9999 }}>
                    <Button
                      style={{ borderRadius: "20px" }}
                      color="primary"
                      onClick={rotateLeft}
                    >
                      <FontAwesomeIcon icon={faRedo} />
                    </Button>{" "}
                    <Button
                      style={{ borderRadius: "20px" }}
                      color="primary"
                      onClick={rotateRight}
                    >
                      <FontAwesomeIcon icon={faUndo} />
                    </Button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div style={thumbsContainer}>
              <>
                <br /> <br />
                {modalPreviewPicture && (
                  <img
                    src={modalPreviewPicture}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      transform: `rotate(${rotationAngle}deg)`,
                      objectFit: "contain",
                    }}
                    width={imageZoom}
                    height={imageZoom}
                  />
                )}
                <br />
                <div className="justify-content-center d-flex flex-column align-items-center">
                  <div className="" style={{ width: "300px" }}>
                    <Input
                      type="range"
                      min={400}
                      max={600}
                      value={imageZoom}
                      name="range"
                      onChange={(e) => {
                        // console.log("Zoom :", e.target.value);
                        setImageZoom(e.target.value);
                      }}
                    />
                    {/* <img src={generatedUrl} alt="S3 Image"style={{ width: "500px" }}  /> */}
                  </div>
                </div>
                <br />
                <div className="justify-content-center d-flex flex-column align-items-center">
                  <div style={{ position: "absolute", zIndex: 9999 }}>
                    <Button
                      style={{ borderRadius: "20px" }}
                      color="primary"
                      onClick={rotateLeft}
                    >
                      <FontAwesomeIcon icon={faRedo} />
                    </Button>{" "}
                    <Button
                      style={{ borderRadius: "20px" }}
                      color="primary"
                      onClick={rotateRight}
                    >
                      <FontAwesomeIcon icon={faUndo} />
                    </Button>
                  </div>
                </div>
                {/* <Button onClick={rotateImage}>Rotate Image</Button> */}
              </>
            </div>
          )}
        </section>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {
    reduxSpecialities: state.initialDataGetReducer.specialities,
    reduxClients: state.initialDataGetReducer.clients,
    reduxRoles: state.initialDataGetReducer.roles,
    user: state.auth.item.firstName + " " + state.auth.item.lastName,
    timesheet: state.timesheets.timesheets,
    locallySaveTimesheetsReducer:
      state.locallySaveTimesheetsReducer?.generatedUrl,
  };
}

export default connect(
  mapStateToProps,
  {}
)(PreviewsBatchTimeSheetsForMobileUploads);
