import JSZip from "jszip";
import FileSaver from "file-saver";
import { HOSTMexxar } from "../../configs/api-config";
import { split } from "lodash";

const checkServiceType = (doc, candidateServiceType, docType) => {

  let docLink = "";
  if (candidateServiceType === "PERMANENT") {
    if (docType === "DEFAULT") {
      docLink = HOSTMexxar + "permanent/" + doc.documentLink;
    } else if (docType === "ARCHIVE") {
      docLink =
        HOSTMexxar +
        "permanent/docs/permanent/" +
        split(doc?.documentLink, "/")[3] +
        "?documents=ARCHIVE";
    }
  } else if (candidateServiceType === "TEMPORARY") {
    if (docType === "DEFAULT") {
      docLink = HOSTMexxar + doc.documentLink;
    } else if (docType === "ARCHIVE") {
      docLink =
        HOSTMexxar +
        "docs/certificates/" +
        split(doc?.documentLink, "/")[2] +
        "/?documents=ARCHIVE";
    }
  }

  return docLink;
};


const fetchWithDelay = (docLink, filename, abortController) => {
  return new Promise((resolve, reject) => {
    const timerId = setTimeout(() => {
      fetch(docLink, { signal: abortController.signal })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch ${docLink}`);
          }
          return response.blob();
        })
        .then((blob) => {
          resolve({ filename, blob });
        })
        .catch((error) => {
          reject(error);
        });
    }, 3000); 

   
    abortController.signal.addEventListener('abort', () => {
      clearTimeout(timerId);
      reject(new Error('Request aborted'));
    });
  });
};

export const downloadDocs = (data, abortController) => {
  const {
    doc,
    candidateId,
    candidateServiceType,
    candidateName,
    type,
    docType = "DEFAULT",
    setDocsLoading,
  } = data;

  setDocsLoading({ status: true, type: type, docType: docType });

  if (type === "SINGLE") {
    const file = doc[0];
    let docLink = checkServiceType(file, candidateServiceType, docType);
    let extension;

    if (docType === "DEFAULT") {
      extension = docLink?.split(".").pop().toLowerCase();
    } else if (docType === "ARCHIVE") {
      extension = docLink?.split(".").pop().toLowerCase().split("/")[0];
    }

    const filename =
      file.certificateName +
      "_" +
      candidateName +
      "_ID_" +
      candidateId +
      "." +
      extension;

    if (docLink) {
      fetchWithDelay(docLink, filename, abortController)
        .then(({ filename, blob }) => {
          FileSaver.saveAs(blob, filename);
          setDocsLoading({ status: false, type: "", docType: "" });
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            console.log('Download canceled');
          } else {
            console.error(error);
          }
          setDocsLoading({ status: false, type: "", docType: "" });
        });
    }
  } else if (type === "MULTIPLE") {
    const zip = new JSZip();
    const fetchPromises = doc
      .filter((doc) => doc.documentLink) 
      .map((doc) => {
        let docLink = checkServiceType(doc, candidateServiceType, docType);
        let extension;
        let filename;

        if (docType === "DEFAULT") {
          extension = docLink?.split(".").pop().toLowerCase();
          filename = candidateName + "_" + doc.document_Name + "." + extension;
        } else if (docType === "ARCHIVE") {
          extension = docLink?.split(".").pop().toLowerCase().split("/")[0];
          filename =
            candidateName + "_" + doc.certificateName + "." + extension;
        }

        return fetchWithDelay(docLink, filename, abortController)
          .then(({ filename, blob }) => zip.file(filename, blob))
          .catch((error) => {
            if (error.name === 'AbortError') {
              console.log('Download canceled');
            } else {
              console.error(error);
            }
    
          });
      });

    Promise.all(fetchPromises)
      .then(() => {
        if (!abortController.signal.aborted) {
          zip.generateAsync({ type: "blob" }).then((content) => {
            FileSaver.saveAs(
              content,
              candidateName + "_ID_" + candidateId + "'s Documents.zip"
            );
            setDocsLoading({ status: false, type: "", docType: "" });
          });
        }
      })
      .catch((error) => {
        if (error.name === 'AbortError') {
          console.log('Download canceled');
        }
        setDocsLoading({ status: false, type: "", docType: "" });
      });
  }
};
