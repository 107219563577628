export const CandidateActiveStatusBadge = (data) => {
  let badgeColor;
  let badgeLable;
  switch (data) {
    case "ACTIVE":
      badgeLable = "Active";
      badgeColor = "#31c971";
      break;
    case "DORMANT":
      badgeLable = "Dormant";
      badgeColor = "#ff9800";
      break;
    case "DO_NOT_CALL":
      badgeLable = "DNC";
      badgeColor = "#f54394";
      break;
    case "INACTIVE":
      badgeLable = "Inactive";
      badgeColor = "#9e9e9e";
      break;
    case "UNVERIFIED":
      badgeLable = "Unverified";
      badgeColor = "#ff9800";
      break;
    case "STAND_BY":
      badgeLable = "Standby";
      badgeColor = "#ff9800";
      break;
    default:
      badgeLable = "N/A";
      badgeColor = "#ff9800";
  }

  return { badgeColor: badgeColor, badgeLable: badgeLable };
};
