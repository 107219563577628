import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { Badge } from "reactstrap";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { Button, Input, Form, Label } from "reactstrap";
import { HOSTMexxar } from "../../../configs/api-config";
import ToastCommon from "../../../components/toastCommon";
import ModalComponent from "../../../components/Modal/modalComponent";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoData from "../../errorPages/NoData";

const TicketsAdmin = () => {
  const MexxarApi = HOSTMexxar;

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();

  const [rows, setRows] = useState(null);
  const [showAddRow, setShowAddRow] = useState(false);
  const [loading, setLoading] = useState(false);

  const getAllRows = () => {
    setLoading(true);
    axios
      .get(MexxarApi + "tickets")
      .then((res) => {
        setLoading(false);
        setRows(res.data.body);
        console.log(res.data.body);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const onSubmit = (data) => {
    let templateBody = {
      title: data.title,
      body: data.body,
      createdDate: moment().format(),
      status: "PENDING",
    };
    axios
      .post(MexxarApi + "tickets", templateBody)
      .then((res) => {
        if (res.data.status == "success" || res.data.message == "created") {
          getAllRows();
          ToastCommon(true, "success", addToast);
          setShowAddRow(!showAddRow);
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  useEffect(() => {
    getAllRows();
  }, []);

  return (
    <DashboardLayout title="Tickets" subTitle="Manage Tickets" target="">
      <div>
        {" "}
        <Button
          color="primary"
          size="sm"
          onClick={() => {
            setShowAddRow(true);
          }}
          style={{ margin: "0.5rem" }}
        >
          Add a ticket
        </Button>
      </div>

      <ModalComponent
        show={showAddRow}
        header="Add New Email Template"
        closeModal={() => setShowAddRow(false)}
      >
        <Form className="col-12" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-row mt-3">
            <Label>Title</Label>
            <Input
              type="text"
              placeholder="Template Name"
              name="title"
              innerRef={register({ required: true, max: 35 })}
            />
          </div>
          <div className="form-row mt-3">
            <Label>Description</Label>
            <Input
              type="textarea"
              placeholder="Description"
              className="form-control"
              name="body"
              id="emailBodyId"
              rows="5"
              innerRef={register({ required: true })}
            />
          </div>
          <div className="mt-3">
            {" "}
            <div class="d-flex justify-content-end">
              <Button className="mr-2" color="success" type="submit">
                Send
              </Button>{" "}
              <Button
                color="secondary"
                onClick={() => setShowAddRow(!showAddRow)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Form>
      </ModalComponent>

      <br></br>

      {rows === null && loading && (
        <div className="d-flex justify-content-center align-items-center">
          {/* Loading animation */}
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      {rows == null ? null : (
        <div>
          {rows.map((body) => (
            <div className="card">
              <div className="list list-row">
                <div className="list-item" data-id={body.id}>
                  <div className="flex">
                    <div className="item-feed h-2x">
                      <div className="capitalize">{body.title}</div>

                      <div className="text-muted capitalize">{body.body}</div>
                    </div>
                  </div>
                  <div className="no-wrap">
                    <div className="item-date text-muted text-sm d-none d-md-block">
                      {moment(body.createdDate).format("llll")}
                    </div>
                  </div>
                  <div className="no-wrap">
                    <Badge color="warning " className="btn-sm">
                      {body.status}
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </DashboardLayout>
  );
};

export default TicketsAdmin;
