import React, { useState } from "react";
// import "../css/style.css";
// import "../css/theme.css";
import axios from "axios";
import { ReadMail } from "./All_Mail_Request/MailboxRequest";
import { Link } from "react-router-dom";
import { faEnvelope, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import store from "../../../redux/store";
import {
  emailBodyOtherValues,
  emailBodyPosts,
} from "../../../redux/actions/emailServerAction";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import NoSearchResults from "../../errorPages/NoSearchResults";

const ListofMails = ({
  empty,
  mailList = [],
  checkedMails = [],
  mail,
  credentials,
  // setPosts,
  // setOther,
}) => {
  const setOtherValue = (elements) => {
    let list = [];
    var image;
    var adrs;
    if (mail === "SENT") {
      image = elements.valueto.replace('"', "").charAt(0).toUpperCase();
      list.push(image);
    } else {
      image = elements.valuefrom
        .replace('"', "")
        .replace(')"', ")")
        .charAt(0)
        .toUpperCase();
      list.push(image);
    }

    if (mail === "SENT") {
      adrs = elements.valueto.replace('"', "");
      list.push(adrs);
    } else {
      adrs = elements.valuefrom.replace('"', "").replace(')"', ")");
      list.push(adrs);
    }

    list.push(elements.timestamp);
    list.push(elements.subject);
    list.push(elements.cc);
    list.push(elements.bcc);
    list.push(mail);

    // setOther(list);
    localStorage.setItem("element", JSON.stringify(list));
    store.dispatch(emailBodyOtherValues(list));
  };

  const setingPost = () => {
    setPosts([]);
  };

  const [posts, setPosts] = useState(null);

  // const ReadMail = (creadentials, timestamp) => {
  //   creadentials.timestamp = timestamp;
  //   axios
  //     .post("http://104.238.170.244:8091/email/read", creadentials)
  //     .then(function (response) {
  //       setPosts(response.data.body);
  //       store.dispatch(emailBodyPosts(response.data.body));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  return (
    <div className="scroll-y mx-4 mb-0 card">
      {mailList.length !== 0 && (
        <div className="list list-row ">
          {mailList.mail.map((elements) => (
            <div
              className="list-item"
              style={{ height: "55px" }}
              data-id={elements.id}
              key={elements.id}
            >
              <div className="p-2">
                <label className="ui-check m-0 ">
                  <input
                    type="checkbox"
                    name="id"
                    defaultValue={9}
                    onChange={(e) => SelectEmails(e, elements.id, checkedMails)}
                  />
                  <i />
                </label>
              </div>
              {/* <div>
                {/* {!elements.status && mail === "INBOX" && (
                  <span title="New Mail">
                    <FontAwesomeIcon icon={faEnvelope} color="#FF7F7F" />
                  </span>
                )} */}

              {/* {!elements.status && mail === "INBOX" ? (
                  <div title="New Mail">
                    <FontAwesomeIcon icon={faEnvelope} color="#FF7F7F" />
                  </div>
                ) : (
                  <div style={{ margin: "7.2px" }}></div>
                )}
              </div> */}
              <div>
                {mail === "SENT" && (
                  <span
                    className="w-40 avatar gd-success"
                    data-toggle-class="loading"
                  >
                    {elements.valueto.replace('"', "").charAt(0).toUpperCase()}
                  </span>
                )}
                {mail === "INBOX" && (
                  <span
                    className="w-40 avatar gd-info"
                    data-toggle-class="loading"
                  >
                    {elements.valuefrom
                      .replace('"', "")
                      .replace(')"', ")")
                      .charAt(0)
                      .toUpperCase()}
                  </span>
                )}

                {mail === "DRAFTS" && (
                  <span
                    className="w-40 avatar gd-warning"
                    data-toggle-class="loading"
                  >
                    {elements.valuefrom
                      .replace('"', "")
                      .replace(')"', ")")
                      .charAt(0)
                      .toUpperCase()}
                  </span>
                )}
                {mail === "SPAM" && (
                  <span
                    className="w-40 avatar gd-danger"
                    data-toggle-class="loading"
                  >
                    {elements.valuefrom
                      .replace('"', "")
                      .replace(')"', ")")
                      .charAt(0)
                      .toUpperCase()}
                  </span>
                )}
                {mail === "TRASH" && (
                  <span
                    className="w-40 avatar gd-secondary"
                    data-toggle-class="loading"
                  >
                    {elements.valuefrom
                      .replace('"', "")
                      .replace(')"', ")")
                      .charAt(0)
                      .toUpperCase()}
                  </span>
                )}
              </div>
              <div className="flex ">
                <div className="item-title text-color h-1x ">
                  {elements.subject}
                </div>

                <Link
                  to={
                    "/dashboard/email/" +
                    mail.toLowerCase() +
                    "/view/" +
                    elements.timestamp +
                    "/" +
                    elements.id
                  }
                  className="item-except text-muted text-sm h-1x"
                  onClick={() => {
                    // ReadMail(credentials, elements.timestamp);

                    setOtherValue(elements);
                  }}
                >
                  {mail === "SENT" &&
                    "To: " + elements.valueto.replace('"', "")}
                  {mail !== "SENT" &&
                    elements.valuefrom.replace('"', "").replace(')"', ")")}
                </Link>
              </div>
              <div className="no-wrap">
                <div className="item-date text-muted text-sm d-none d-md-block">
                  {elements.attachments ? (
                    elements.attachments.status && (
                      <div>
                        <FontAwesomeIcon icon={faPaperclip} color="#d1d1d1" />
                      </div>
                    )
                  ) : (
                    <div style={{ margin: "7.2px" }}></div>
                  )}
                </div>
              </div>
              <div>
                {/* {!elements.status && mail === "INBOX" && (
                  <span title="New Mail">
                    <FontAwesomeIcon icon={faEnvelope} color="#FF7F7F" />
                  </span>
                )} */}

                {!elements.status && mail === "INBOX" ? (
                  <div title="New Mail">
                    <FontAwesomeIcon icon={faEnvelope} color="#FF7F7F" />
                  </div>
                ) : (
                  <div style={{ margin: "7.2px" }}></div>
                )}
              </div>

              <div className="no-wrap">
                <div className="item-date text-muted text-sm d-none d-md-block">
                  {DateAndTime(elements.timestamp)}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {empty === -10 && (
        <div className="no-result">
          <div className="p-4 text-center">
            <NoSearchResults page={"email"} />
          </div>
        </div>
      )}

      {mailList.length === 0 && empty !== -10 && (
        <div className="no-result">
          <div className="p-4 text-center">
            Fetching Your Mails Please Wait...
          </div>
        </div>
      )}
    </div>
  );
};

const DateAndTime = (timestamp) => {
  const dateObject = new Date(timestamp);
  const dateandtime = dateObject.toLocaleString();
  const day = dateObject.toLocaleString("en-GB", { weekday: "short" });
  return (
    <span>
      {day} {dateandtime}{" "}
    </span>
  );
};

const SelectEmails = (e, id, checkedMails = []) => {
  if (e.target.checked) {
    checkedMails.push(id);
  } else {
    for (var i = 0; i < checkedMails.length; i++) {
      if (checkedMails[i] === id) {
        checkedMails.splice(i, 1);
      }
    }
  }
};

export { DateAndTime };
export default ListofMails;
