import React from "react";
import { Label } from "reactstrap";
import noConnection from "../../images/noConnection.png";

const NoConnection = (props) => {
  const reload = () => {
    window.location.reload();
  };
  return (
    <div className="d-flex flex-column justify-content-center align-items-center">
      <div>
        <img src={noConnection} alt="." style={{ height: "300px" }} />
      </div>
      <div className="d-flex flex-column  align-items-center">
        <Label className={"font-weight-bold text-color"}>
          {props?.error?.message}
        </Label>

        <small className="text-color">
          It seems there is an issue.Please try again later or{" "}
          <Label
            className="font-weight-bold text-primary "
            style={{ cursor: "pointer" }}
            onClick={() => reload()}
          >
            reload
          </Label>
        </small>
      </div>
    </div>
  );
};

export default NoConnection;
