import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { Button, Label, Input, Form, Spinner } from "reactstrap";
import { ClientLable, HOSTMexxar } from "../../../configs/api-config";
import ToastCommon from "../../toastCommon";
import CreatableSelect from "react-select/creatable";
import useDidMountEffect from "./intialRunUseEffect";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { sendEmail } from "../../../pages/email/graph";

function Email(props) {
  // API
  const MexxarAPI = HOSTMexxar;

  const { template, closeModal, selectedUser, incomingPage,user } = props;

  const { instance, accounts } = useMsal();

  let defaultClient = "";
  if (incomingPage == "permCompliants" || incomingPage == "permJobBoard") {
    defaultClient = {
      value: selectedUser.selectedClient.email,
      label: selectedUser.selectedClient.name,
      id: selectedUser.selectedClient.id,
      client: selectedUser.selectedClient,
    };
  }

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();

  const [employees, setEmployees] = useState(null);

  const [candidates, setCandidates] = useState(null);
  const [templateContent, setTemplateContent] = useState();
  const [selectedBccEmail, setSelectedBccEmail] = useState();

  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [file, setFile] = useState([]);
  const [subject, setSubject] = useState("");

  const [selectedCCCandidates, setSelectedCCCandidates] = useState();

  const [clientEmailNotValid, setClientEmailNotValid] = useState(false);

  const onSubmit = (data) => {
    if (
      incomingPage !== undefined &&
      (incomingPage == "permCompliants" || incomingPage == "permJobBoard")
    ) {
      if (!clientEmailNotValid) {
        //perm staffing applications sending email API call
        setLoading(true);

        let jobId =
          incomingPage == "permCompliants"
            ? 0
            : selectedUser.selectedClient.job.jobListingId;
        let email = {
          docIds: selectedUser.docIds,
          emailSubject: data.subject,
          emailBody: data.emailBody,
          jobId: jobId,
        };

        let clientEmail = selectedClient.value;

        axios
          .post(
            MexxarAPI +
              "permanent/candidates/" +
              selectedUser.candidate.id +
              "/sendDocsToClient?clientId=" +
              defaultClient.id +
              "&clientEmail=" +
              clientEmail,
            email
          )
          .then((res) => {
            setLoading(false);
            if (res.data.status == "success" || res.data.message == "fetched") {
              ToastCommon(true, "sent", addToast);
              closeModal();
            } else if (
              res.data.status == "failed" &&
              res.data.message == "one or more documents is not approved"
            ) {
              ToastCommon(true, "docNotApproved", addToast);
            } else ToastCommon(true, "error", addToast);
          })
          .catch((err) => {
            setLoading(false);
            ToastCommon(true, "error", addToast);
          });
      }
    } else {
      setLoading(true);
      let emailList = [];
      if (selectedEmployees != null) {
        for (let i = 0; i < selectedEmployees.length; i++) {
          emailList.push(selectedEmployees[i].value);
        }
      }

      if (selectedCandidates != null) {
        for (let i = 0; i < selectedCandidates.length; i++) {
          emailList.push(selectedCandidates[i].value);
        }
      }

      let ccEmailList = [];
      if (selectedCCCandidates != null) {
        for (let i = 0; i < selectedCCCandidates.length; i++) {
          ccEmailList.push(selectedCCCandidates[i].value);
        }
      }

      let bccEmail = [];
      if (selectedBccEmail != null) {
        for (let i = 0; i < selectedBccEmail.length; i++) {
          bccEmail.push(selectedBccEmail[i].value);
        }
      }

      let email = {
        emailBody: data.emailBody,
        from: props.user.email,
        to: emailList,
        cc: ccEmailList,
        bcc: bccEmail,
        subject: data.subject,
      };
      //un-comment if needed-old method(check if this workd first)
      // axios
      //   .post(MexxarAPI + "email/send/custom", email)
      //   .then((res) => {
      //     setLoading(false);
      //     if (res.data.status == "success" || res.data.message == "created") {
      //       ToastCommon(true, "sent", addToast);
      //     } else {
      //       ToastCommon(true, "duplicate", addToast);
      //     }
      //   })
      //   .catch((err) => {
      //     setLoading(false);
      //     ToastCommon(true, "error", addToast);
      //   });

      //new method via outlook

      let recipients = selectedCandidates.concat(selectedEmployees);
      
       instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          sendEmail(
            response?.accessToken,
            recipients,
            selectedCCCandidates,
            selectedBccEmail,
            data.subject,
            data.emailBody,
            file,
            user
          )
            .then((response) => {
              setLoading(false);
              ToastCommon(true, "sent", addToast);
              closeModal();
            })
            .catch((error) => {
              setLoading(false);
            });
        })
        .catch((error) => console.log(error));
    }
  };

  const getCandidates = () => {
    axios.get(MexxarAPI + "candidates/my").then((res) => {
      let listOfCandidates = [];
      for (let i = 0; i < res.data.body.length; i++) {
        listOfCandidates.push({
          value: res.data.body[i].email,
          label: res.data.body[i].firstName + " " + res.data.body[i].lastName,
          id: res.data.body[i].id,
          candidate: res.data.body[i],
        });
      }
      setCandidates(listOfCandidates);
    });
  };

  const getEmployees = () => {
    axios.get(MexxarAPI + "employees").then((res) => {
      let listOfEmployees = [];
      for (let i = 0; i < res.data.body.length; i++) {
        listOfEmployees.push({
          value: res.data.body[i].email,
          label: res.data.body[i].firstName + " " + res.data.body[i].lastName,
          id: res.data.body[i].id,
          employee: res.data.body[i],
        });
      }
      setEmployees(listOfEmployees);
    });
  };
  const checkSelectedUser = (user) => {
    if (incomingPage == "permCompliants" || incomingPage == "permJobBoard") {
      let tempArray = {
        value: user.email,
        label: user.name,
        id: user.id,
        client: user,
      };
      setSelectedClient(tempArray);
    } else {
      if (user.employeeRole == undefined) {
        let tempArray = {
          value: user.email,
          label: `${
            user.candidateFirstName == undefined
              ? user.firstName
              : user.candidateFirstName
          } ${
            user.candidateLastName == undefined
              ? user.lastName
              : user.candidateLastName
          }`,
          id: user.candidateId == undefined ? user.id : user.candidateId,
          candidate: user,
        };
        setSelectedCandidates([tempArray]);
      } else if (user.employeeRole != undefined) {
        let tempArray = {
          value: user.email,
          label: user.firstName + " " + user.lastName,
          id: user.id,
          employee: user,
        };
        setSelectedEmployees([tempArray]);
      }
    }
  };

  function mutliStringReplace(object, string) {
    var val = string;
    var entries = Object.entries(object);
    entries.forEach((para) => {
      var find = "{" + para[0] + "}";
      var regExp = new RegExp(find, "g");
      val = val.replace(regExp, para[1]);
    });
    return val;
  }

  const updateTemplate = () => {
    if (template != undefined && template != null) {
      let emailList = [];
      for (let x = 0; x < selectedCandidates.length; x++) {
        emailList.push(selectedCandidates[x].label);
      }

      for (let x = 0; x < selectedEmployees.length; x++) {
        emailList.push(selectedEmployees[x].label);
      }

      if (emailList.length >= 2) {
        emailList = ["Dear All,"];
      }
      let temp;
      if (incomingPage == "permJobBoard") {
        temp = template.template.replace(
          "{toName}",
          String(selectedUser.selectedClient.job.hiringManagerName)
        );
      } else {
        temp = template.template.replace("{toName}", String(emailList));
      }

      let temp1;
      if (incomingPage == "permCompliants") {
        var obj = {
          candidateName:
            selectedUser.candidate.firstName +
            " " +
            selectedUser.candidate.lastName,
          clientName: selectedUser.selectedClient.name,
        };

        temp1 = mutliStringReplace(obj, temp);
      } else if (incomingPage == "permJobBoard") {
        var obj = {
          candidateName: selectedUser.candidate.label,
          clientName: selectedUser.selectedClient.name,
          jobTitle: selectedUser.selectedClient.job.jobTitle,
        };

        temp1 = mutliStringReplace(obj, temp);
      } else {
        temp1 = temp;
      }

      let temp2 = temp1.replace(
        "{fromName}",
        `- ${props.user.firstName}${" "}${props.user.lastName}`
      );

      setTemplateContent(temp2);
    }

    //update subject
    if (template) {
      setSubject(template.templateSubject);
    }
  };

  useEffect(() => {
    if (incomingPage == "permCompliants" || incomingPage == "permJobBoard") {
      checkSelectedUser(selectedUser.selectedClient);
    } else {
      checkSelectedUser(selectedUser);
    }

    getCandidates();
    getEmployees();
  }, []);

  useEffect(() => {
    if (template != null && template != undefined)
      // loadTemplateForUser(selectedUser);
      updateTemplate();
  }, [template]);

  useEffect(() => {
    updateTemplate();
  }, [selectedCandidates]);

  useEffect(() => {
    updateTemplate();
  }, [selectedEmployees]);

  useDidMountEffect(() => {
    const regexClientEmail = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    regexClientEmail.exec(selectedClient && selectedClient.value)
      ? setClientEmailNotValid(false)
      : setClientEmailNotValid(true);

    return () => {};
  }, [selectedClient]);

  return (
    <Form className="col-12" onSubmit={handleSubmit(onSubmit)}>
      {incomingPage == "permCompliants" ||
      incomingPage == "permJobBoard" ? null : (
        <>
          <div className="form-row mt-3">
            <Label>To Employees</Label>
            {employees != undefined && employees != null ? (
              <Select
                type="select"
                isMulti
                placeholder="Employee Email"
                name="employeeEmail"
                defaultValue={
                  selectedEmployees != null && selectedEmployees != undefined
                    ? selectedEmployees
                    : null
                }
                className="col-12"
                onChange={(data) => {
                  data == null
                    ? setSelectedEmployees([])
                    : setSelectedEmployees(data);
                  // selectedUsersFromSelecters(data);
                  // setSelectedEmployees(data);
                }}
                options={employees}
                innerRef={register({
                  required: {
                    value: true,
                    message: "Email required",
                  },
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email address!",
                  },
                })}
              />
            ) : null}
            {errors.email && <p>{errors.email.message}</p>}
          </div>
          <div className="form-row mt-3">
            <Label>To Candidates : </Label>
            {candidates != undefined && candidates != null ? (
              <Select
                type="select"
                isMulti
                placeholder="Candidate Email"
                name="candidatesEmail"
                className="col-12"
                defaultValue={
                  selectedCandidates != null && selectedCandidates != undefined
                    ? selectedCandidates
                    : null
                }
                onChange={(data) => {
                  data == null
                    ? setSelectedCandidates([])
                    : setSelectedCandidates(data);
                }}
                options={candidates}
                innerRef={register({
                  required: {
                    value: true,
                    message: "Email required",
                  },
                  pattern: {
                    value:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email address!",
                  },
                })}
              />
            ) : null}
            {errors.email && <p>{errors.email.message}</p>}
          </div>
        </>
      )}

      {incomingPage == "permCompliants" ||
        (incomingPage == "permJobBoard" && (
          <div className="form-row mt-3">
            <Label>
              To {ClientLable} : {defaultClient.label} -{" "}
              {selectedClient && selectedClient.value}
            </Label>

            <CreatableSelect
              // menuPortalTarget={document.body}
              // styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              className="col-12"
              placeholder="Client Emails"
              name="clientsEmail"
              isClearable
              //  isMulti
              defaultValue={defaultClient}
              options={[defaultClient]}
              onChange={(data) => setSelectedClient(data)}
            />

            {clientEmailNotValid && (
              <span className="text-danger ml-1 text-sm">
                Please enter a valid email address!
              </span>
            )}
          </div>
        ))}

      {incomingPage == "permCompliants" ||
      incomingPage == "permJobBoard" ? null : (
        <>
          <div className="form-row mt-3">
            <Label>Cc</Label>
            <Select
              type="select"
              isMulti
              placeholder="cc"
              name="ccEmail"
              className="col-12"
              onChange={(data) => setSelectedCCCandidates(data)}
              options={employees}
              innerRef={register({
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter a valid email address!",
                },
              })}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>
          <div className="form-row mt-3">
            <Label>Bcc</Label>
            <Select
              type="select"
              isMulti
              placeholder="bcc"
              name="bccEmail"
              className="col-12"
              onChange={(data) => setSelectedBccEmail(data)}
              options={employees}
              innerRef={register({
                pattern: {
                  value:
                    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: "Please enter a valid email address!",
                },
              })}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>
        </>
      )}
      <div className="form-row mt-3">
        <Label>Subject</Label>
        <Input
          type="text"
          placeholder="Subject"
          name="subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          invalid={errors.subject}
          innerRef={register({
            required: true,
            max: 35,
            message: "Please enter a valid email address!",
          })}
        />
        {errors.subject && (
          <span className="text-danger ml-1 text-sm">
            Please enter a subject
          </span>
        )}
      </div>
      <div className="form-row mt-3">
        <Label>Body</Label>
        <Input
          type="textarea"
          defaultValue={
            template === undefined || template === null
              ? ""
              : templateContent != undefined || templateContent != null
              ? templateContent
              : ""
          }
          className="form-control"
          name="emailBody"
          id="emailBodyId"
          rows="20"
          innerRef={register({ required: true })}
        />

        {/* <input
          className="mt-2"
          type="file"
          multiple
          onChange={(event) => setFile(event.target.files)}
        /> */}
      </div>
      <div className="mt-3">
        <div className="d-flex justify-content-end">
          <Button className="mr-2" color="success" type="submit">
            {loading ? (
              <>
                <Spinner
                  animation="border"
                  style={{
                    height: "20px",
                    width: "20px",
                    marginRight: "5px",
                  }}
                />
                Sending
              </>
            ) : (
              <>Send</>
            )}
          </Button>{" "}
          <Button color="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
}

function mapStatetoProps(state) {
  return {
    user: state.auth.item,
  };
}

export default connect(mapStatetoProps, {})(Email);
