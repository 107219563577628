import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import {
  CLIENTS,
  ClientLable,
  FRAMEWORK,
  HOSTMexxar,
  REGISTER_CANDIDATE,
  TRUSTS,
} from "../../../configs/api-config";
import { Button } from "reactstrap";
import useDidMountEffect from "./intialRunUseEffect";
import { connect } from "react-redux";
import { filter, forEach } from "lodash";
import Delete from "./delete";
import moment from "moment";
import ModalComponent from "../modalComponent";
import { useIsMounted } from "../../../customHooks/useIsMounted";
import TemplateUpdate from "./Admin/updateTemplate";

const WhiteListDoc = (props) => {
  const {
    closeModal,
    success,
    candidateId,
    changedFramework,
    candidateRole,
    changedSpecility,
    candidateBand,
    roleName,
    bandName,
    specialityName,
    whiteListPreference,
    selectedPrefferedClient,
  } = props;
  const MexxarApiCandidate = REGISTER_CANDIDATE;

  const { addToast } = useToasts();
  const [templateAlreadySet, setTemplateAlreadySet] = useState(false);


  const isMounted = useIsMounted();
  const [loading, setLoading] = useState(false);
  const [certificateData, setCertificateData] = useState([]);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState([]);
  const [templateIdList, setTemplateIdList] = useState([]);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [templateId, setTemplateId] = useState([]);


  const getDocuments = () => {
    setLoading(true);
    axios
      .all([
        axios.get(
          MexxarApiCandidate +
            "/" +
            candidateId +
            "/documents/framework/" +
            changedFramework +
            "/role/" +
            candidateRole +
            "/speciality/" +
            changedSpecility
        ),
      
      ])

      .then(
        axios.spread((res, res2) => {
          if (isMounted.current) {
            //************only allowed 3 levels maximum *********************//
            let LEVEL1 = filter(res.data.body, (item) => {
              return item.levelId === 1;
            });
            // console.log("LEVEL 01................", LEVEL1);
            let LEVEL2 = filter(res.data.body, (item) => {
              return item.levelId === 2;
            });
            //   console.log("LEVEL 02................", LEVEL2);
            let LEVEL3 = filter(res.data.body, (item) => {
              return item.levelId === 3;
            });
            //   console.log("LEVEL 03................", LEVEL3);

            let A = [];
            let B = [];
            let C = [];

            //**********Level 1 docs******//

            let L1expDocs = filter(LEVEL1, (item) => {
              return moment(item.expireDate).isSameOrBefore(new Date());
            });

            let L1pendingDocs = filter(LEVEL1, (item) => {
              return item.expireDate === null;
            });

            let L1uploadedDocs = filter(LEVEL1, (item) => {
              return moment(new Date()).isSameOrBefore(item.expireDate);
            });

            //Level 2 docs//
            let L2expDocs = filter(LEVEL2, (item) => {
              return moment(item.expireDate).isSameOrBefore(new Date());
            });

            let L2pendingDocs = filter(LEVEL2, (item) => {
              return item.expireDate === null;
            });

            let L2uploadedDocs = filter(LEVEL2, (item) => {
              return moment(new Date()).isSameOrBefore(item.expireDate);
            });

            //********Level 3 docs***********//

            let L3expDocs = filter(LEVEL3, (item) => {
              return moment(item.expireDate).isSameOrBefore(new Date());
            });

            let L3pendingDocs = filter(LEVEL3, (item) => {
              return item.expireDate === null;
            });

            let L3uploadedDocs = filter(LEVEL3, (item) => {
              return moment(new Date()).isSameOrBefore(item.expireDate);
            });

            let data_List_01 = [];
            let data_List_02 = [];
            let data_List_03 = [];

            const docStatus = (data) => {
              let expDate = moment(data);
              let today = new Date();

              let diffDays;

              if (data == null) {
                diffDays = "?";
              } else {
                diffDays = Math.floor((expDate - today) / 86400e3) + 1;
                // console.log("incoming docStatus data", diffDays);
              }
              // {
              //   data == null
              //     ? ()
              //     : ();
              // }

              let avatarColorCode;
              let avatarSymbol;
              switch (true) {
                case "?" === diffDays:
                  avatarColorCode = "#607d8b";
                  avatarSymbol = "?";
                  // console.log("case 01 less than 0", diffDays);

                  break;
                case 0 > diffDays:
                  avatarColorCode = "#e91e63";
                  avatarSymbol = "X";
                  //  console.log("case 01 less than 0", diffDays);

                  break;

                case 0 == diffDays:
                  avatarColorCode = "#e91e63";
                  avatarSymbol = "1";
                  //  console.log("case 02 ...0 days left", diffDays);

                  break;

                case 0 < diffDays && diffDays <= 30:
                  avatarColorCode = "#ff9800";
                  avatarSymbol = diffDays;
                  //   console.log("case 03...between 0 and 30", diffDays);

                  break;

                case 30 < diffDays && diffDays <= 45:
                  avatarColorCode = "#cddc39";
                  avatarSymbol = diffDays;
                  //     console.log("case 04...between 30 and 45 ", diffDays);

                  break;
                case 45 < diffDays && diffDays <= 365000:
                  avatarColorCode = "#31c971";
                  avatarSymbol = diffDays;
                  //   console.log("case 05...between 45 and 365000", diffDays);

                  break;

                default:
                  break;
              }
              return {
                avatarColorCode: avatarColorCode,
                avatarSymbol: avatarSymbol,
              };
            };

            forEach(LEVEL1, function (item) {
              A.push({
                id: item.documentId,
                certificateName: item.documentName,
                expireDate: item.expireDate,
                issueDate: item.issueDate,
                documentLink: item.documentLink,
                confirmation: item.confirmation,
                audition: item.audition,
                avtarColor: docStatus(item.expireDate).avatarColorCode,
                daysLeft: docStatus(item.expireDate).avatarSymbol,
                type: item.type,
              });
            });

            forEach(LEVEL2, function (item) {
              B.push({
                id: item.documentId,
                certificateName: item.documentName,
                expireDate: item.expireDate,
                issueDate: item.issueDate,
                documentLink: item.documentLink,
                confirmation: item.confirmation,
                audition: item.audition,
                avtarColor: docStatus(item.expireDate).avatarColorCode,
                daysLeft: docStatus(item.expireDate).avatarSymbol,
                type: item.type,
              });
            });

            forEach(LEVEL3, function (item) {
              C.push({
                id: item.documentId,
                certificateName: item.documentName,
                expireDate: item.expireDate,
                issueDate: item.issueDate,
                documentLink: item.documentLink,
                confirmation: item.confirmation,
                audition: item.audition,
                avtarColor: docStatus(item.expireDate).avatarColorCode,
                daysLeft: docStatus(item.expireDate).avatarSymbol,
                type: item.type,
              });
            });

            data_List_01.push({
              //levelName: "Level 01", //if all 3 levels are activating use this instead
              levelName: "Documents",
              levelId: 1,
              color: "#456543",
              expDocsCount: L1expDocs.length,
              pendingDocsCount: L1pendingDocs.length,
              uploadedDocsCount: L1uploadedDocs.length,
              certificates: A,
            });

            data_List_02.push({
              levelName: "Level 02",
              levelId: 2,
              color: "#456543",
              expDocsCount: L2expDocs.length,
              pendingDocsCount: L2pendingDocs.length,
              uploadedDocsCount: L2uploadedDocs.length,
              certificates: B,
            });

            data_List_03.push({
              levelName: "Level 03",
              levelId: 3,
              color: "#456543",
              expDocsCount: L3expDocs.length,
              pendingDocsCount: L3pendingDocs.length,
              uploadedDocsCount: L3uploadedDocs.length,
              certificates: C,
            });

            let rearrangedArray = data_List_01.concat(
              data_List_02,
              data_List_03
            );

            // setCertificateData(rearrangedArray); <<<<<<<<<<< uncomment this to show  levels(Comment down line as well)

            setLoading(false);
            setCertificateData(data_List_01[0]?.certificates);
          }
        })
      )
      .catch((err) => {
        //console.log("cannot get rearranged certificate array data",err);
        // toastCommon(true, "error");
      });
    return () => {};
  };

  useEffect(() => {
    getDocuments();

    return () => {};
  }, []);





  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const onItemSelect = (id) => {
    if (templateAlreadySet) {
      // If templateAlreadySet is true, append the item to selectedItems if it's not already present
      if (!selectedItems.includes(id)) {
        setSelectedItems([...selectedItems, id]);
      }
    } else {
      // Original logic for toggling item selection
      if (selectedItems.includes(id)) {
        const updatedItems = selectedItems.filter((item) => item !== id);
        setSelectedItems(updatedItems);
        setSelectAll(false);
      } else {
        setSelectedItems([...selectedItems, id]);
      }
    }
  };
  
  


  const addTemplate = (data, status) => {
    const documentIdList = selectedItems;

    const payload = {
      templateName: `Role: ${roleName} | Band: ${bandName} | Speciality: ${specialityName}`,
      documentIdList: documentIdList,
      roleId: candidateRole,
      specialityId: changedSpecility,
      bandId: candidateBand,
      frameworkWhitelistTemplateType: whiteListPreference,
      clientId: selectedPrefferedClient,
    };

    axios
      .post(HOSTMexxar + "frameworktemplate", payload)
      .then((res) => {
        if (res.data.status === "success") {
          // getAllRows();
          // setShowSaveRow(!showSaveRow);
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
      closeModal();
  };

  const UpdateTemplate = (data, status) => {
    const documentIdList = selectedItems;
  
    // Initialize payload with the required fields
    const payload = {
      templateName: `Role: ${roleName} | Band: ${bandName} | Speciality: ${specialityName}`,
      documentIdList: documentIdList,
      roleId: candidateRole,
      specialityId: changedSpecility,
      bandId: candidateBand,
      frameworkWhitelistTemplateType: whiteListPreference,
    };
  
    // Conditionally add selectedPrefferedClient if it is available
    if (selectedPrefferedClient) {
      payload.clientId = selectedPrefferedClient;
    }
  
    // Send the PUT request with the payload
    axios
      .put(`${HOSTMexxar}frameworktemplate/${templateId}`, payload)
      .then((res) => {
        if (res.data.status === "success") {
          // Uncomment the following line if you have a function to fetch all rows
          // getAllRows();
          ToastCommon(true, "success", addToast);
        } else {
          ToastCommon(true, "duplicate", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
      closeModal();
  };
  

  const getTemplateById = () => {
    let url =
      HOSTMexxar +
      "frameworktemplate/filter?specialityId=" +
      changedSpecility +
      "&bandId=" +
      candidateBand +
      "&roleId=" +
      candidateRole +
      "&type=" +
      whiteListPreference;

    if (selectedPrefferedClient) {
      url += "&clientId=" + selectedPrefferedClient;
    }

    axios
      .get(url)
      .then((res) => {
        if (res.data.body.content[0].documentList !== null) {
          setTemplateAlreadySet(!templateAlreadySet);
        }

        setTemplateId(res?.data?.body?.content[0]?.id);
        const documentList = res?.data?.body?.content[0]?.documentList;

        if (Array.isArray(documentList)) {
          const ids = documentList.map((item) => item.id);

          setTemplateIdList(ids);
        } else {
          throw new Error("Invalid response format");
        }
      })
      .catch((err) => {
        console.error("Error fetching templates:", err);
      });
  };

  const deleteTemplate = () => {
    axios
      .delete(HOSTMexxar + "frameworktemplate/" + templateId)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "delete", addToast);
          getTemplateById();
          closeModal()
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "update", addToast);
      });
  };

  const getSelectedTemplate = () => {
    axios
      .get(HOSTMexxar + "frameworktemplate/" + selectedTemplate?.value)
      .then((res) => {
        if (Array.isArray(res?.data?.body?.documentList)) {
          const ids = res.data.body.documentList.map((item) => item.id);
          setSelectedTemplateId(ids);
        } else {
          throw new Error("Invalid response format");
        }
      })
      .catch((err) => {
         console.error("Error fetching templates:", err);
        // setAllTemplate([]);
      });
  };

  useEffect(() => {
    getTemplateById();

    return () => {};
  }, []);

  useDidMountEffect(() => {
    getSelectedTemplate();

    return () => {};
  }, [selectedTemplate]);

  return (
    <>
      <div className="d-flex justify-content-between p-2 ml-3">
        <h5>
          Speciality:{specialityName} | Role:{roleName} | Band:{bandName}
        </h5>
      </div>
      <div className="d-flex justify-content-between p-2 ml-3">
        <div className="mr-3">
          <Button
            color={templateAlreadySet ? "primary" : "warning"}
            size="sm"
            onClick={templateAlreadySet ? UpdateTemplate : addTemplate}
          >
            {templateAlreadySet ? "Update Template" : "Save Template"}
          </Button>

          <Button
            color="danger"
            size="sm"
            className="float-right ml-2"
            onClick={() => {
              setShowDeleteRow(true);
              
            }}
          >
            Delete Template
          </Button>
        </div>
      </div>

      <div
        style={{
          backgroundColor: "white",
          color: "black",
          border: "none",
          boxShadow: "none",
        }}
      >
        <div className="table-responsive">
          <table className="table table-bordered ">
            <tbody>
              {certificateData.map((item, index) => (
                <tr key={index} className="custom-table-row">
                  <td style={{ minWidth: "30px", textAlign: "center" }}>
                    <label className="ui-check m-0">
                      <input
                        type="checkbox"
                        checked={
                          isSelected(item.id) ||
                          templateIdList.includes(item.id)
                        }
                        onChange={() => onItemSelect(item.id)}
                      />
                      <i></i>
                    </label>
                  </td>
                  <td>{item.certificateName}</td>
                  <td>
                    {item?.type === "MANDATORY" && <span>Mandatory</span>}
                    {item?.type === "IF_APPLICABLE" && (
                      <span>If Applicable</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ModalComponent
        show={showUpdateRow}
        header="Update Template"
        closeModal={() => setShowUpdateRow(false)}
      >
        <TemplateUpdate
          mode="Update"
          modalType="Template Name"
          closeModal={() => setShowUpdateRow(false)}
          onCreate={(data) => UpdateTemplate(data)}
        />
      </ModalComponent>

      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteTemplate();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(false)}
      />
    </>
  );
};

function mapStateToProps(state) {
  return {
    counties: state.initialDataGetReducer.counties,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(WhiteListDoc);