import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Label, Form, Col, Row } from "reactstrap";
import { HOSTMexxar } from "../../../../configs/api-config";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WardsList = (props) => {
  const MexxarApi = HOSTMexxar;
  const { onCreate, closeModal, rowID, mode } = props;
  
  const [rowDatas, setRowDatas] = useState([]);
  const [contactPersons, setContactPersons] = useState([{}]);
  const [showContactDetails, setShowContactDetails] = useState([]);

  const geTRowData = () => {
    axios
      .get(MexxarApi + "client-wards/" + rowID)
      .then((res) => {
        setRowDatas(res.data.body[0]);
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  useEffect(() => {
    geTRowData();
  }, []);

  return (
    <Form className="col-12">
      <Row>
        <Col md={6}>
          <Label>Department Name</Label>
        </Col>
        <Col md={6}>
          <Label>Contacting Personal</Label>
        </Col>
      </Row>
      {rowDatas.length == 0 ? (
        <>Loading</>
      ) : (
        <>
          {rowDatas.map((row, i) => {
            return (
              <Row key={i}>
                <Col md={6}>
                  <Label>{row.wardName}</Label>
                </Col>

                <Col md={6}>
                  <Label>Contact Details</Label>
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    onClick={setShowContactDetails(!showContactDetails)}
                  />
                </Col>

                <Col>
                  <div className="d-flex justify-content-between mb-2">
                    <div></div>
                    <div>
                      <Button
                        color="success font-weight-bold "
                        style={{ borderRadius: "50px" }}
                        onClick={() =>
                          setContactPersons([...contactPersons, {}])
                        }
                      >
                        +
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}
        </>
      )}

      <Row className="justify-content-end">
        <div className="d-flex ">
          {" "}
          <Button className="mr-2" color="success" type="submit">
            Save
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
};

export default WardsList;
