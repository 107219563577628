import {
  SWITCH_SIDE_NAVIGATION,
  SWITCH_DASHBOARD,
  UPDATING_WINDOW_SIZE,
  UPDATING_WINDOW_HEIGHT,
} from "../actions/types";

const initialState = {
  isSideNavigationOpen: true,
  isDashboardOpen: true,
  oldwindowSize: null,
  windowHeight: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SWITCH_SIDE_NAVIGATION:
      return {
        ...state,
        isSideNavigationOpen: action.payload,
      };

    case SWITCH_DASHBOARD:
      return {
        ...state,
        isDashboardOpen: action.payload,
      };
    case UPDATING_WINDOW_SIZE:
      return {
        ...state,
        oldwindowSize: action.payload,
      };
    case UPDATING_WINDOW_HEIGHT:
      return {
        ...state,
        windowHeight: action.payload,
      };

    default:
      return state;
  }
}
