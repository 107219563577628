import {
  SWITCH_SIDE_NAVIGATION,
  SWITCH_DASHBOARD,
  UPDATING_WINDOW_SIZE,
  UPDATING_WINDOW_HEIGHT,
} from "./types";

export const changeSideNavigation = (data) => (dispatch) => {
  dispatch({
    type: SWITCH_SIDE_NAVIGATION,
    payload: data,
  });
};

export const changeDashboard = (data) => (dispatch) => {
  dispatch({
    type: SWITCH_DASHBOARD,
    payload: data,
  });
};

export const updateWindowSize = (data) => (dispatch) => {
  dispatch({
    type: UPDATING_WINDOW_SIZE,
    payload: data,
  });
};

export const updateWindowHeight = (data) => (dispatch) => {
  dispatch({
    type: UPDATING_WINDOW_HEIGHT,
    payload: data,
  });
};
