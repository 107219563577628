import React, { useState, useEffect } from "react";
import { Badge, Button, Col } from "reactstrap";
import axios from "axios";
import moment from "moment";
import ModalComponent from "../../../components/Modal/modalComponent";
import TimeSheet from "../../../components/Modal/ModalBody/timeSheet";
import SmallCalendar from "../../../components/Modal/ModalBody/smallCalendar";
import { HOSTMexxar } from "../../../configs/api-config";
import TimeSheetUpload from "../../../components/Modal/ModalBody/timeSheetUpload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

const CreditWidget = (props) => {
  const { userProfile, refreshPage } = props;
  const [calendarShow, setCalendarShow] = useState(false);
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const [showTimeModal, setShowTimeModal] = useState(false);
  const [data, setData] = useState();
  const [rows, setRows] = useState(null);
  const [allCredits, setRowsCredit] = useState(null);
  const MexxarApi = HOSTMexxar;

  const toggleShowTimeModal = (data) => {
    setData(data);
    setShowTimeModal(!showTimeModal);
  };


  const getAllCredits = () => {
    axios
      .get(MexxarApi + "credit")
      .then((res) => {
        setRowsCredit(res.data.body);

      })
      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };



  useEffect(() => {
    getAllCredits();
  }, []);
  console.log("userProfile", userProfile);
  return (

    <>
      {allCredits && allCredits.map((allCredits) => (
        <div className="card" key={allCredits.id}>
          <div className="list list-row">
            <div className="list-item" data-id={allCredits.id}>
              {/* <div className="flex"> */}
                {/* <div className="item-feed h-5x"> */}
                  {/* <div className="capitalize">{allCredits.id}</div> */}


                  <div
                    className="d-flex justify-content-left align-items-left flex-column"
                    style={{ flex: 2 }}
                  >
                    <div>Credit Balance</div>

                    <div>
                      {allCredits?.creditBalance}
                    </div></div>


                  <div
                    className="d-flex justify-content-center align-items-center flex-column"
                    style={{ flex: 2 }}
                  >
                    <div>Agency Name :</div>

                    <div>
                      {allCredits?.agency?.name}
                    </div></div>

                  <div
                    className="d-flex justify-content-center align-items-center flex-column"
                    style={{ flex: 2 }}
                  >
                    <div>Email :</div>

                    <div>
                      {allCredits?.agency?.email}
                    </div></div>

                  <div
                    className="d-flex justify-content-center align-items-center flex-column"
                    style={{ flex: 2 }}
                  >
                    <div>Credit service Type :</div>

                    <div>
                      {/* {allCredits?.creditServiceType} */}
                         {/* {userProfile.creditServiceType} */}



                         {(() => {
                                        switch (allCredits?.creditServiceType) {
                                            case "CLIENT_MAP":
                                                return <Badge className="bg-secondary">Client Map</Badge>;

                                            case "CHAT":
                                                return <Badge className="bg-secondary">Chat</Badge>;

                                            case "EMAIL":
                                                return <Badge className="bg-secondary">Email</Badge>;
                                            case "MESSAGE":
                                                return <Badge className="bg-secondary">Message</Badge>;
                                            case "TIME_SHEET":
                                                return <Badge className="bg-secondary">Time Sheet</Badge>;
                                            default:
                                                return null;
                                                break;
                                        }
                                    })()}
                    </div></div>
                  {/* <div className="capitalize">credit Balance : {allCredits.creditBalance}</div>
                  <div className="text-muted capitalize">Agency name: {allCredits?.agency?.name}</div>
                  <div className="text-muted capitalize">Email: {allCredits?.agency?.email}</div>
                  <div className="text-muted capitalize">Credit service Type: {allCredits?.creditServiceType}</div> */}
                  {/* <div className="text-muted capitalize">{allCredits.transactionType}</div> */}
                </div>
              </div>

              <div>

              </div>
            {/* </div>               </div> */}
         </div>
      ))}

      <ModalComponent
        show={calendarShow}
        header="Log new call back"
        closeModal={() => handleCalendarShow(false)}
      >
        <SmallCalendar />
        <div className="mt-3">
          {" "}
          <Button color="success">Send</Button>{" "}
          <Button color="secondary" onClick={() => handleCalendarShow(false)}>
            Cancel
          </Button>
        </div>
      </ModalComponent>
      <ModalComponent
        show={showTimeModal}
        header="Upload Time Sheet"
        size="lg"
        closeModal={() => setShowTimeModal(false)}
      >
        <TimeSheetUpload
          data={data}
          closeModal={() => setShowTimeModal(false)}
          getCandidates={refreshPage}
        ></TimeSheetUpload>
      </ModalComponent>
    </>

  );
};

export default CreditWidget;
