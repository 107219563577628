import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { forEach, split, filter } from "lodash";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../../../components/toastCommon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCheck } from "@fortawesome/free-solid-svg-icons";
import { REGISTER_CANDIDATE } from "../../../../../configs/api-config";
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import useDidMountEffect from "../../../../../components/Modal/ModalBody/intialRunUseEffect";

const SkillsExperience = () => {
  const MexxarApi = REGISTER_CANDIDATE;

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();

  const [dataList, setDataList] = useState([]);
  const [errorHQ, setErrorHQ] = useState(false);
  const [errorTR, setErrorTR] = useState(false);
  const [errorMH, setErrorMH] = useState(false);
  const [updateHQ, setUpdateHQ] = useState(false);
  const [updateTR, setUpdateTR] = useState(false);
  const [updateMH, setUpdateMH] = useState(false);
  const [errorNRC, setErrorNRC] = useState(false);
  const [updateNRC, setUpdateNRC] = useState(false);
  const [candidateID, setCandidateID] = useState([]);
  const [editableNRC, setEditableNRC] = useState(false);
  const [editableMental, setEditableMental] = useState(false);
  const [mhUpdateQuestion, setMhUpdateQuestion] = useState([]);
  const [editableTheatre, setEditableTheatre] = useState(false);
  const [nrcUpdateQuestion, setNrcUpdateQuestion] = useState([]);
  const [editableHospitals, setEditableHospitals] = useState(false);
  const [theatreUpdateQuestion, setTheatreUpdateQuestion] = useState([]);
  const [hospitalUpdateQuestion, setHospitalUpdateQuestion] = useState([]);

  const [hospitalQuestion, setHospitalQuestion] = useState([
    { id: 0, value: 0, questionNo: 0, question: "A&E" },
    { id: 1, value: 0, questionNo: 1, question: "Intensive Care Unit" },
    { id: 2, value: 0, questionNo: 2, question: "High Dependency Unit" },
    { id: 3, value: 0, questionNo: 3, question: "PICU" },
    { id: 4, value: 0, questionNo: 4, question: "Care of the Elderly" },
    { id: 5, value: 0, questionNo: 5, question: "Oncology" },
    { id: 6, value: 0, questionNo: 6, question: "Medical Assessment" },
    { id: 7, value: 0, questionNo: 7, question: "Haemotology" },
    { id: 8, value: 0, questionNo: 8, question: "Medical" },
    { id: 9, value: 0, questionNo: 9, question: "Surgical" },
    { id: 10, value: 0, questionNo: 10, question: "Out Patients" },
    { id: 11, value: 0, questionNo: 11, question: "Gynaecology" },
    { id: 12, value: 0, questionNo: 12, question: "Orthopaedics" },
    { id: 13, value: 0, questionNo: 13, question: "Chemotherapy" },
    { id: 14, value: 0, questionNo: 14, question: "Neonatal" },
    { id: 15, value: 0, questionNo: 15, question: "Cardiac" },
    { id: 16, value: 0, questionNo: 16, question: "Senior Duties (In Charge)" },
    { id: 17, value: 0, questionNo: 17, question: "Ante Natal" },
    { id: 18, value: 0, questionNo: 18, question: "Midwifery" },
    { id: 19, value: 0, questionNo: 19, question: "Paediatric" },
  ]);
  const [theatreQuestion, setTheatreQuestion] = useState([
    { id: 0, value: 0, questionNo: 0, question: "General Theatre" },
    { id: 1, value: 0, questionNo: 1, question: "Anaesthetic Trained" },
    { id: 2, value: 0, questionNo: 2, question: "High Dependency Unit" },
    { id: 3, value: 0, questionNo: 3, question: "ODP/ODA" },
    { id: 4, value: 0, questionNo: 4, question: "Recovery" },
    { id: 5, value: 0, questionNo: 5, question: "Day Surgery" },
    { id: 6, value: 0, questionNo: 6, question: "Radiology" },
    { id: 7, value: 0, questionNo: 7, question: "Scrub" },
  ]);

  const [nrcQuestion, setNrcQuestion] = useState([
    { id: 0, value: 0, questionNo: 0, question: "Nursing Home" },
    { id: 1, value: 0, questionNo: 1, question: "Residential Home" },
    { id: 2, value: 0, questionNo: 2, question: "EMI" },
    { id: 3, value: 0, questionNo: 3, question: "Day Care Centre" },
    { id: 4, value: 0, questionNo: 4, question: "Practice Nurse" },
    { id: 5, value: 0, questionNo: 5, question: "Health Visitor" },
    { id: 6, value: 0, questionNo: 6, question: "Termination Clinic" },
    { id: 7, value: 0, questionNo: 7, question: "District Nursing" },
    { id: 8, value: 0, questionNo: 8, question: "Family Planning" },
    { id: 9, value: 0, questionNo: 9, question: "Prions" },
  ]);

  const [mhQuestion, setMHQuestion] = useState([
    { id: 0, value: 0, questionNo: 0, question: "Secure Forensic" },
    { id: 1, value: 0, questionNo: 1, question: "Learning Disabilities" },
    { id: 2, value: 0, questionNo: 2, question: "Challenging Behaviour" },
    { id: 3, value: 0, questionNo: 3, question: "Drug/Alcohol Abuse" },
    { id: 4, value: 0, questionNo: 4, question: "Adolescents" },
    { id: 5, value: 0, questionNo: 5, question: "ITU" },
    { id: 6, value: 0, questionNo: 6, question: "Community" },
    { id: 7, value: 0, questionNo: 7, question: "Hospitals" },
    { id: 8, value: 0, questionNo: 8, question: "121's" },
  ]);
  const handleEditHospital = () => {
    if (editableHospitals) {
      //edit call
    }
    setEditableHospitals(!editableHospitals);
  };
  const handleEditTheatre = () => {
    if (editableTheatre) {
      //edit call
    }
    setEditableTheatre(!editableTheatre);
  };
  const handleEditNRC = () => {
    if (editableNRC) {
      //edit call
    }
    setEditableNRC(!editableNRC);
  };
  const handleEditMental = () => {
    if (editableMental) {
      //edit call
    }
    setEditableMental(!editableMental);
  };
  ////........get candidate id from url and save in a state........////
  const getCandidateID = () => {
    let pathArray = window.location.pathname.split("/");
    const CandidateId = Number(pathArray[3]);
    //**********CandidateId and candidateID have same value but variable names are different*******/
    setCandidateID(CandidateId);
  };
  const getHospitalData = () => {
    axios
      .get(
        MexxarApi +
          "/" +
          candidateID +
          "/advance-profile-details/category/SKILLS_AND_EXPERIENCE"
      )
      .then((res) => {
        if (res.data.status === "success") {
          let response = res.data.body;

          //....check if this a new form filling or form update.....//
          //...chekIFnew array check if the incoming response has any hospital data by checking the identifier witch includes "HQ"........//

          let checkIFnew = filter(response, (item) => {
            return item.identifier.includes("HQ");
          });

          if (checkIFnew.length === 0) {
            //....new form filling.......//
            setHospitalUpdateQuestion(hospitalQuestion);
          } else {
            //....update form filling.......//
            let hospitalQ = [];
            forEach(checkIFnew, function (item) {
              let splitedquestinNo = split(item.identifier, "Q");

              let questionResult = filter(hospitalQuestion, (item) => {
                return item.questionNo === Number(splitedquestinNo[1]);
              });

              hospitalQ.push({
                id: item.id,
                question: questionResult[0].question,
                questionNo: Number(splitedquestinNo[1]),
                value: item.value,
              });
            });

            setHospitalUpdateQuestion(hospitalQ);
            setUpdateHQ(true);
          }
        } else {
          setErrorHQ(true);
        }
      })
      .catch((err) => {
        //   console.log(err);
        // toastCommon(true, "error");
      });
  };

  const getTheatreData = () => {
    axios
      .get(
        MexxarApi +
          "/" +
          candidateID +
          "/advance-profile-details/category/SKILLS_AND_EXPERIENCE"
      )
      .then((res) => {
        if (res.data.status === "success") {
          let response = res.data.body;

          //....check if this a new form filling or form update.....//
          //...chekIFnew array check if the incoming response has any theratre data by checking the identifier witch includes "TR"........//
          let checkIFnew = filter(response, (item) => {
            return item.identifier.includes("TR");
          });

          if (checkIFnew.length === 0) {
            //....new form filling.......//
            setTheatreUpdateQuestion(theatreQuestion);
          } else {
            //....update form filling.......//
            let temperyArray = [];
            forEach(checkIFnew, function (item) {
              let splitedquestinNo = split(item.identifier, "R");

              let questionResult = filter(theatreQuestion, (item) => {
                return item.questionNo === Number(splitedquestinNo[1]);
              });

              temperyArray.push({
                id: item.id,
                question: questionResult[0].question,
                questionNo: Number(splitedquestinNo[1]),
                value: item.value,
              });
            });

            setTheatreUpdateQuestion(temperyArray);
            setUpdateTR(true);
          }
        } else {
          setErrorTR(true);
        }
      })
      .catch((err) => {
        //   console.log(err);
        // toastCommon(true, "error");
      });
  };

  const getNRCData = () => {
    axios
      .get(
        MexxarApi +
          "/" +
          candidateID +
          "/advance-profile-details/category/SKILLS_AND_EXPERIENCE"
      )
      .then((res) => {
        if (res.data.status === "success") {
          let response = res.data.body;

          //....check if this a new form filling or form update.....//
          //...chekIFnew array check if the incoming response has any hospital data by checking the identifier witch includes "NRC"........//

          let checkIFnew = filter(response, (item) => {
            return item.identifier.includes("NRC");
          });

          if (checkIFnew.length === 0) {
            //....new form filling.......//
            setNrcUpdateQuestion(nrcQuestion);
          } else {
            //....update form filling.......//
            let hospitalQ = [];
            forEach(checkIFnew, function (item) {
              let splitedquestinNo = split(item.identifier, "C");

              let questionResult = filter(hospitalQuestion, (item) => {
                return item.questionNo === Number(splitedquestinNo[1]);
              });

              hospitalQ.push({
                id: item.id,
                question: questionResult[0].question,
                questionNo: Number(splitedquestinNo[1]),
                value: item.value,
              });
            });

            setNrcUpdateQuestion(hospitalQ);
            setUpdateNRC(true);
          }
        } else {
          setErrorNRC(true);
        }
      })
      .catch((err) => {
        //   console.log(err);
        // toastCommon(true, "error");
      });
  };

  const getMHData = () => {
    axios
      .get(
        MexxarApi +
          "/" +
          candidateID +
          "/advance-profile-details/category/SKILLS_AND_EXPERIENCE"
      )
      .then((res) => {
        if (res.data.status === "success") {
          let response = res.data.body;

          //....check if this a new form filling or form update.....//
          //...chekIFnew array check if the incoming response has any hospital data by checking the identifier witch includes "H"........//

          let checkIFnew = filter(response, (item) => {
            return item.identifier.includes("MH");
          });
          //  console.log("checkIFnew....... MH", checkIFnew);

          if (checkIFnew.length === 0) {
            //....new form filling.......//
            setMhUpdateQuestion(mhQuestion);
          } else {
            //....update form filling.......//
            let hospitalQ = [];
            forEach(checkIFnew, function (item) {
              let splitedquestinNo = split(item.identifier, "H");

              let questionResult = filter(hospitalQuestion, (item) => {
                return item.questionNo === Number(splitedquestinNo[1]);
              });

              hospitalQ.push({
                id: item.id,
                question: questionResult[0].question,
                questionNo: Number(splitedquestinNo[1]),
                value: item.value,
              });
            });

            setMhUpdateQuestion(hospitalQ);
            setUpdateMH(true);
          }
        } else {
          setErrorMH(true);
        }
      })
      .catch((err) => {
        //   console.log(err);
        // toastCommon(true, "error");
      });
  };

  const onSubmitHospitals = (data) => {
    let hospitalQ = [];

    if (updateHQ === false) {
      for (let i = 0; i < 20; i++) {
        if (!data["HQ" + i]) {
          break;
        }
        let identifier = data["HQ" + i];

        hospitalQ.push({
          identifier: "HQ" + i,
          value: identifier,
          detailCategory: 3,
        });
      }
      setUpdateHQ(true);
    } else {
      for (let i = 0; i < 20; i++) {
        if (!data["HQ" + i]) {
          break;
        }
        let identifier = data["HQ" + i];

        let ID = data["HQID" + i];

        hospitalQ.push({
          id: Number(ID),
          identifier: "HQ" + i,
          value: identifier,
          detailCategory: 3,
        });
      }
      //setUpdate(!update);
    }

    axios
      .post(
        MexxarApi + "/" + candidateID + "/advance-profile-details",
        hospitalQ
      )
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "success", addToast);
          getHospitalData();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        // console.log("system erro .......", err);
        ToastCommon(true, "error", addToast);
      });
  };

  const onSubmitTheatre = (data) => {
    let temperyArray = [];

    if (updateTR === false) {
      for (let i = 0; i < 20; i++) {
        if (!data["TR" + i]) {
          break;
        }
        let identifier = data["TR" + i];

        temperyArray.push({
          identifier: "TR" + i,
          value: identifier,
          detailCategory: 3,
        });
      }
      setUpdateTR(true);
    } else {
      for (let i = 0; i < 20; i++) {
        if (!data["TR" + i]) {
          break;
        }
        let identifier = data["TR" + i];

        let ID = data["TRID" + i];

        temperyArray.push({
          id: Number(ID),
          identifier: "TR" + i,
          value: identifier,
          detailCategory: 3,
        });
      }
    }

    axios
      .post(
        MexxarApi + "/" + candidateID + "/advance-profile-details",
        temperyArray
      )
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "success", addToast);
          getTheatreData();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        // console.log("system erro .......", err);
        ToastCommon(true, "error", addToast);
      });
  };

  const onSubmitNRC = (data) => {
    let temperyArray = [];

    if (updateNRC === false) {
      for (let i = 0; i < 20; i++) {
        if (!data["NRC" + i]) {
          break;
        }
        let identifier = data["NRC" + i];

        temperyArray.push({
          identifier: "NRC" + i,
          value: identifier,
          detailCategory: 3,
        });
      }
      setUpdateNRC(true);
    } else {
      for (let i = 0; i < 20; i++) {
        if (!data["NRC" + i]) {
          break;
        }
        let identifier = data["NRC" + i];

        let ID = data["NRCID" + i];

        temperyArray.push({
          id: Number(ID),
          identifier: "NRC" + i,
          value: identifier,
          detailCategory: 3,
        });
      }
    }

    axios
      .post(
        MexxarApi + "/" + candidateID + "/advance-profile-details",
        temperyArray
      )
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "success", addToast);
          getNRCData();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  const onSubmitMH = (data) => {
    let temperyArray = [];

    if (updateMH === false) {
      for (let i = 0; i < 10; i++) {
        if (!data["MH" + i]) {
          break;
        }
        let identifier = data["MH" + i];

        temperyArray.push({
          identifier: "MH" + i,
          value: identifier,
          detailCategory: 3,
        });
      }
      setUpdateMH(true);
    } else {
      for (let i = 0; i < 20; i++) {
        if (!data["MH" + i]) {
          break;
        }
        let identifier = data["MH" + i];

        let ID = data["MHID" + i];

        temperyArray.push({
          id: Number(ID),
          identifier: "MH" + i,
          value: identifier,
          detailCategory: 3,
        });
      }
    }

    axios
      .post(
        MexxarApi + "/" + candidateID + "/advance-profile-details",
        temperyArray
      )
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "success", addToast);
          getMHData();
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        // console.log("system erro .......", err);
        ToastCommon(true, "error", addToast);
      });
  };

  useDidMountEffect(() => {
    getHospitalData();
    getTheatreData();
    getNRCData();
    getMHData();
  }, [candidateID]);

  useEffect(() => {
    getCandidateID();
    return () => {};
  }, []);

  return (
    <div className="row ">
      <div className="col-md-6">
        <div className="card">
          <Form onSubmit={handleSubmit(onSubmitHospitals)}>
            <div className="card-header">
              <div className="col-md-12 d-flex align-items-center  justify-content-between ">
                <h5>Hospitals</h5>
                {/* <FormGroup row>
                <button
                  className={`btn btn-wave btn-raised ${
                    editableHospitals ? "bg-warning " : "bg-danger "
                  }`}
                  type="button"
                  onClick={() => handleEditHospital()}
                >
                  {editableHospitals ? "Save" : "Edit"}
                  {editableHospitals ? (
                    <FontAwesomeIcon icon={faCheck} className="ml-3" />
                  ) : (
                    <FontAwesomeIcon icon={faEdit} className="ml-3" />
                  )}
                </button>
              </FormGroup> */}

                <div className="d-flex justify-content-between">
                  <div></div>
                  <div>
                    <Button className="" color="success" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            {/* ////....check if the internet connection or data is not reciving....////  */}
            {errorHQ ? (
              <div>
                Error.Please check your internet connection.If this happens more
                than once please contact us
              </div>
            ) : (
              <div className="card-body">
                {hospitalUpdateQuestion.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div>Loading...</div>
                  </div>
                ) : (
                  <div>
                    {hospitalUpdateQuestion.map((question, id) => {
                      return (
                        //  hospitalUpdateQuestion.length>0 ? null:null
                        <FormGroup row key={id}>
                          <Label
                            for="exampleSelect"
                            className="col-md-7 col-sm-12"
                          >
                            {question.question}
                          </Label>
                          <div className="col-md-5 col-sm-12">
                            <Input
                              type="select"
                              name={"HQ" + question.questionNo}
                              innerRef={register}
                              defaultValue={question.value}
                            >
                              <option value={0}>Less than 1 Year</option>
                              <option value={1}>1-2 Years</option>
                              <option value={2}>2-3 Years</option>
                              <option value={3}>3+ Years</option>
                            </Input>
                            <Input
                              type="hidden"
                              name={"HQID" + question.questionNo}
                              defaultValue={question.id}
                              innerRef={register}
                            />
                          </div>
                        </FormGroup>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </Form>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card">
          <Form onSubmit={handleSubmit(onSubmitTheatre)}>
            <div className="card-header">
              <div className="col-md-12 d-flex align-items-center  justify-content-between align-items-center">
                <h5>Theatre</h5>
                {/* <FormGroup row>
                  <button
                    className={`btn btn-wave btn-raised ${
                      editableTheatre ? "bg-warning " : "bg-danger "
                    }`}
                    type="button"
                    onClick={() => handleEditTheatre()}
                  >
                    {editableTheatre ? "Save" : "Edit"}
                    {editableTheatre ? (
                      <FontAwesomeIcon icon={faCheck} className="ml-3" />
                    ) : (
                      <FontAwesomeIcon icon={faEdit} className="ml-3" />
                    )}
                  </button>
                </FormGroup> */}
                <div className="d-flex justify-content-between">
                  <div></div>
                  <div>
                    <Button className="" color="success" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            {/* ////....check if the internet connection or data is not reciving....////  */}
            {errorTR ? (
              <div>
                Error.Please check your internet connection.If this happens more
                than once please contact us
              </div>
            ) : (
              <div className="card-body">
                {theatreUpdateQuestion.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div>Loading...</div>
                  </div>
                ) : (
                  <div>
                    {theatreUpdateQuestion.map((question, id) => {
                      return (
                        <FormGroup row key={id}>
                          <Label
                            for="exampleSelect"
                            className="col-md-7 col-sm-12"
                          >
                            {question.question}
                          </Label>
                          <div className="col-md-5 col-sm-12">
                            <Input
                              type="select"
                              name={"TR" + question.questionNo}
                              innerRef={register}
                              defaultValue={question.value}
                            >
                              <option value={0}>Less than 1 Year</option>
                              <option value={1}>1-2 Years</option>
                              <option value={2}>2-3 Years</option>
                              <option value={3}>3+ Years</option>
                            </Input>
                            <Input
                              type="hidden"
                              name={"TRID" + question.questionNo}
                              defaultValue={question.id}
                              innerRef={register}
                            />
                          </div>
                        </FormGroup>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </Form>
        </div>
        <div className="card">
          <Form onSubmit={handleSubmit(onSubmitNRC)}>
            <div className="card-header">
              <div className="col-md-12 d-flex align-items-center  justify-content-between align-items-center">
                <h5>Nursing, Residential & Community </h5>
                {/* <FormGroup row>
                  <button
                    className={`btn btn-wave btn-raised ${
                      editableNRC ? "bg-warning " : "bg-danger "
                    }`}
                    type="button"
                    onClick={() => handleEditNRC()}
                  >
                    {editableNRC ? "Save" : "Edit"}
                    {editableNRC ? (
                      <FontAwesomeIcon icon={faCheck} className="ml-3" />
                    ) : (
                      <FontAwesomeIcon icon={faEdit} className="ml-3" />
                    )}
                  </button>
                </FormGroup> */}
                <div className="d-flex justify-content-between">
                  <div></div>
                  <div>
                    <Button className="" color="success" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {/* ////....check if the internet connection or data is not reciving....////  */}
            {errorNRC ? (
              <div>
                Error.Please check your internet connection.If this happens more
                than once please contact us
              </div>
            ) : (
              <div className="card-body">
                {nrcUpdateQuestion.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div>Loading...</div>
                  </div>
                ) : (
                  <div>
                    {nrcUpdateQuestion.map((question, id) => {
                      return (
                        <FormGroup row key={id}>
                          <Label
                            for="exampleSelect"
                            className="col-md-7 col-sm-12"
                          >
                            {question.question}
                          </Label>
                          <div className="col-md-5 col-sm-12">
                            <Input
                              type="select"
                              name={"NRC" + question.questionNo}
                              innerRef={register}
                              defaultValue={question.value}
                            >
                              <option value={0}>Less than 1 Year</option>
                              <option value={1}>1-2 Years</option>
                              <option value={2}>2-3 Years</option>
                              <option value={3}>3+ Years</option>
                            </Input>
                            <Input
                              type="hidden"
                              name={"NRCID" + question.questionNo}
                              defaultValue={question.id}
                              innerRef={register}
                            />
                          </div>
                        </FormGroup>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </Form>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card">
          <Form onSubmit={handleSubmit(onSubmitMH)}>
            <div className="card-header">
              <div className="col-md-12 d-flex align-items-center justify-content-between align-items-center">
                <h5>Mental Health</h5>
                {/* <FormGroup row>
                  <button
                    className={`btn btn-wave btn-raised ${
                      editableMental ? "bg-warning " : "bg-danger "
                    }`}
                    type="button"
                    onClick={() => handleEditMental()}
                  >
                    {editableMental ? "Save" : "Edit"}
                    {editableMental ? (
                      <FontAwesomeIcon icon={faCheck} className="ml-3" />
                    ) : (
                      <FontAwesomeIcon icon={faEdit} className="ml-3" />
                    )}
                  </button>
                </FormGroup> */}
                <div className="d-flex justify-content-between">
                  <div></div>
                  <div>
                    <Button className="" color="success" type="submit">
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {/* ////....check if the internet connection or data is not reciving....////  */}
            {errorMH ? (
              <div>
                Error.Please check your internet connection.If this happens more
                than once please contact us
              </div>
            ) : (
              <div className="card-body">
                {mhUpdateQuestion.length === 0 ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <div>Loading...</div>
                  </div>
                ) : (
                  <div>
                    {mhUpdateQuestion.map((question, id) => {
                      return (
                        <FormGroup row key={id}>
                          <Label
                            for="exampleSelect"
                            className="col-md-7 col-sm-12"
                          >
                            {question.question}
                          </Label>
                          <div className="col-md-5 col-sm-12">
                            <Input
                              type="select"
                              name={"MH" + question.questionNo}
                              innerRef={register}
                              defaultValue={question.value}
                            >
                              <option value={0}>Less than 1 Year</option>
                              <option value={1}>1-2 Years</option>
                              <option value={2}>2-3 Years</option>
                              <option value={3}>3+ Years</option>
                            </Input>
                            <Input
                              type="hidden"
                              name={"MHID" + question.questionNo}
                              defaultValue={question.id}
                              innerRef={register}
                            />
                          </div>
                        </FormGroup>
                      );
                    })}
                  </div>
                )}
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default SkillsExperience;
