import React, { useState, useEffect } from "react";
import axios from "axios";
import { isNull } from "lodash";
import filter from "lodash/filter";
import { Button, Input, Form, FormGroup, Label, Col, Alert } from "reactstrap";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../../components/toastCommon";
import {
  CANDIDATE_BAND,
  HostEmailServerConfig,
} from "../../../configs/api-config";
import FormCheckLabel from "react-bootstrap/esm/FormCheckLabel";
import Delete from "../../../components/Modal/ModalBody/delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import NewOneField from "../../../components/Modal/ModalBody/Admin/newOneField";

// const ToastDemo = ({ content }) => {
//   const { addToast } = useToasts()
//   return (
//     <Button onClick={() => addToast(content, {
//       appearance: 'success',
//       autoDismiss: true,
//     })}>
//       Add Toast
//     </Button>
//   )
// }

function ConfigurationForm(row, reload, mode) {
  const [showDeleteRow, setShowDeleteRow] = useState(false);

  const deleteRow = (rowID) => {
    axios
      .delete(HostEmailServerConfig + "delete?id=" + rowID)
      .then((res) => {
        if (res.data.status === "success") {
          // ToastCommon(true, "delete", addToast);
          reload();
        } else {
          // ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "update", addToast);
      });
  };

  const updateConfiguration = (row) => {
    let updateData = {
      pop3_host: row.pop3_host,
      pop3_port: row.pop3_port,
      smtp_host: row.smtp_host,
      transport_protocol: row.transport_protocol,
      smtp_port: row.smtp_port,
      session_store: row.session_store,
      mailbox: row.mailbox,
    };
    axios
      .post(HostEmailServerConfig + "edit?id=" + row.id, updateData)
      .then((res) => {
        if (res.data.status === "success") {
          // ToastCommon(true, "delete", addToast);
          reload();
        } else {
          // ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "update", addToast);
      });
  };

  const updateConfigurationMailBox = (row) => {
    let updateData = {
      inbox: row.inbox,
      trash: row.trash,
      spam: row.spam,
      junk: row.junk,
      drafts: row.drafts,
      sent: row.sent,
    };

    axios
      .post(HostEmailServerConfig + "mailbox/edit?id=" + updateData)
      .then((res) => {
        if (res.data.status === "success") {
          // ToastCommon(true, "delete", addToast);
          reload();
        } else {
          // ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "update", addToast);
      });
  };

  const addConfiguration = (row) => {
    let addData = {
      pop3_host: row.pop3_host,
      pop3_port: row.pop3_port,
      smtp_host: row.smtp_host,
      transport_protocol: row.transport_protocol,
      smtp_port: row.smtp_port,
      session_store: row.session_store,
      mailbox: row.mailbox,
    };
    axios
      .post(HostEmailServerConfig + "add", addData)
      .then((res) => {
        if (res.data.status === "success") {
          // ToastCommon(true, "delete", addToast);
          reload();
        } else {
          // ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "update", addToast);
      });
  };

  const addConfigurationMailBox = (row) => {
    let updateData = {
      inbox: row.inbox,
      trash: row.trash,
      spam: row.spam,
      junk: row.junk,
      drafts: row.drafts,
      sent: row.sent,
    };

    axios
      .post(HostEmailServerConfig + "mailbox/edit?id=" + updateData)
      .then((res) => {
        if (res.data.status === "success") {
          // ToastCommon(true, "delete", addToast);
          reload();
        } else {
          // ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "update", addToast);
      });
  };

  console.log("mode", mode);
  return (
    <div className={`${mode == "Update" ? "card p-5" : "p-1"}`}>
      <Form>
        {mode == "Add" && (
          <>
            <FormGroup row>
              <div className="d-flex justify-content-between">
                <Label
                  for={`heading_1${row.id}`}
                  className="mr-sm-2 ml-2 text-md"
                >
                  Email info
                </Label>
              </div>
            </FormGroup>
            <FormGroup row>
              <Col sm={12} md={6} lg={6} className="p-2">
                <Label for={`emailUserName${row.id}`} className="mr-sm-2">
                  Email
                </Label>
                <Input
                  type="email"
                  name={`emailUserName${row.id}`}
                  id={`emailUserName${row.id}`}
                  defaultValue={row.pop3_host}
                  placeholder="premium18.web-hosting.com"
                />
              </Col>
              <Col sm={12} md={6} lg={6} className="p-2">
                <Label for={`password${row.id}`} className="mr-sm-2">
                  Password
                </Label>
                <Input
                  type="password"
                  name={`Password${row.id}`}
                  id={`password${row.id}`}
                  defaultValue={row.pop3_port}
                  placeholder="110"
                />
              </Col>
            </FormGroup>

            <hr />
          </>
        )}
        <FormGroup row>
          <div className="d-flex justify-content-between">
            <Label for={`heading_1${row.id}`} className="mr-sm-2 ml-2 text-md">
              Configure your email client
            </Label>
          </div>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Col sm={12} md={6} lg={6} className="p-2">
            <Label for={`pop3Host${row.id}`} className="mr-sm-2">
              Pop3 Host
            </Label>
            <Input
              type="text"
              name={`pop3Host${row.id}`}
              id={`pop3Host${row.id}`}
              defaultValue={row.pop3_host}
              placeholder="premium18.web-hosting.com"
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="p-2">
            <Label for={`pop3Port${row.id}`} className="mr-sm-2">
              Pop3 Port
            </Label>
            <Input
              type="text"
              name={`pop3Port${row.id}`}
              id={`pop3Port${row.id}`}
              defaultValue={row.pop3_port}
              placeholder="110"
            />
          </Col>
          <Col sm={12} md={6} lg={6} className="p-2">
            <Label for={`smtpHost${row.id}`} className="mr-sm-2">
              SMTP Host
            </Label>
            <Input
              type="text"
              name={`smtpHost${row.id}`}
              id={`smtpHost${row.id}`}
              defaultValue={row.smtp_host}
              placeholder="premium18.web-hosting.com"
            />
          </Col>{" "}
          <Col sm={12} md={6} lg={6} className="p-2">
            <Label for={`smtpPort${row.id}`} className="mr-sm-2">
              SMPT Port
            </Label>
            <Input
              type="text"
              name={`smtpPort${row.id}`}
              id={`smtpPort${row.id}`}
              defaultValue={row.smtp_port}
              placeholder="587"
            />
          </Col>{" "}
          <Col sm={12} md={6} lg={4} className="p-2">
            <Label for={`transportProtocol${row.id}`} className="mr-sm-2">
              Transport Protocol
            </Label>
            <Input
              type="text"
              name={`transportProtocol${row.id}`}
              id={`transportProtocol${row.id}`}
              defaultValue={row.transport_protocol}
              placeholder="smtp"
            />
          </Col>{" "}
          <Col sm={12} md={6} lg={4} className="p-2">
            <Label for={`sessionStore${row.id}`} className="mr-sm-2">
              Session Store
            </Label>
            <Input
              type="text"
              name={`sessionStore${row.id}`}
              id={`sessionStore${row.id}`}
              defaultValue={row.session_store}
              placeholder="imaps"
            />
          </Col>{" "}
          <Col sm={12} md={6} lg={4} className="p-2">
            <Label for={`mailbox${row.id}`} className="mr-sm-2">
              Mailbox
            </Label>
            <Input
              type="text"
              name={`mailbox${row.id}`}
              id={`mailbox${row.id}`}
              defaultValue={row.mailbox}
              placeholder="cpanel"
            />
          </Col>
        </FormGroup>
        <div className="d-flex justify-content-end">
          {mode == "Add" && (
            <Button
              color="info"
              className="mx-2"
              onClick={() => addConfiguration(row)}
            >
              Add
            </Button>
          )}{" "}
          {mode == "Update" && (
            <Button
              color="info"
              className="mx-2"
              onClick={() => updateConfiguration(row)}
            >
              Update
            </Button>
          )}
        </div>
      </Form>
      <br />
      <Form>
        <FormGroup row>
          <Label for={`heading_2${row.id}`} className="mr-sm-2 ml-2 text-md">
            Mailbox configuration
          </Label>
        </FormGroup>
        <hr />
        <FormGroup row>
          <Col sm={12} md={6} lg={4} className="p-2">
            <Label for={`inbox${row.id}`} className="mr-sm-2">
              Inbox
            </Label>
            <Input
              type="text"
              name={`inbox${row.id}`}
              id={`inbox${row.id}`}
              defaultValue={row.inbox}
              placeholder="INBOX"
            />
          </Col>
          <Col sm={12} md={6} lg={4} className="p-2">
            <Label for={`trash${row.id}`} className="mr-sm-2">
              Trash
            </Label>
            <Input
              type="text"
              name={`trash${row.id}`}
              id={`trash${row.id}`}
              defaultValue={row.trash}
              placeholder="INBOX.Trash"
            />
          </Col>
          <Col sm={12} md={6} lg={4} className="p-2">
            <Label for={`spam${row.id}`} className="mr-sm-2">
              Spam
            </Label>
            <Input
              type="text"
              name={`spam${row.id}`}
              id={`spam${row.id}`}
              defaultValue={row.spam}
              placeholder="INBOX.spam"
            />
          </Col>
          <Col sm={12} md={6} lg={4} className="p-2">
            <Label for={`junk${row.id}`} className="mr-sm-2">
              Junk
            </Label>
            <Input
              type="text"
              name={`junk${row.id}`}
              id={`junk${row.id}`}
              defaultValue={row.junk}
              placeholder="INBOX.Junk"
            />
          </Col>
          <Col sm={12} md={6} lg={4} className="p-2">
            <Label for={`drafts${row.id}`} className="mr-sm-2">
              Drafts
            </Label>
            <Input
              type="text"
              name={`drafts${row.id}`}
              id={`drafts${row.id}`}
              defaultValue={row.drafts}
              placeholder="INBOX.Drafts"
            />
          </Col>
          <Col sm={12} md={6} lg={4} className="p-2">
            <Label for={`sent${row.id}`} className="mr-sm-2">
              Sent
            </Label>
            <Input
              type="text"
              name={`sent${row.id}`}
              id={`sent${row.id}`}
              defaultValue={row.sent}
              placeholder="INBOX.Sent"
            />
          </Col>
        </FormGroup>

        <div className="d-flex justify-content-end">
          {mode == "Add" && (
            <Button
              color="info"
              className="mx-2"
              onClick={() => addConfigurationMailBox(row)}
            >
              Add
            </Button>
          )}{" "}
          {mode == "Update" && (
            <Button
              color="info"
              className="mx-2"
              onClick={() => updateConfigurationMailBox(row)}
            >
              Update
            </Button>
          )}
          <Button
            color="info"
            className="mx-2"
            onClick={() => updateConfigurationMailBox(row)}
          >
            Update
          </Button>
        </div>
      </Form>
      <hr />
      <div className="d-flex justify-content-between mr-2 mt-2">
        <Alert>
          Deleting configuration settings may cause intruptions in email
          services
        </Alert>
        <Button color="danger" onClick={() => showDeleteRow(true)}>
          Delete
        </Button>
      </div>

      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteRow(row.id);
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(!showDeleteRow)}
      />
    </div>
  );
}

const EmailConfigure = () => {
  const MexxarApi = CANDIDATE_BAND;

  const { addToast } = useToasts();

  const [add, setAdd] = useState(0);
  const [rows, setRows] = useState([]);

  const [rowID, setRowID] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [rowNameData, setRowNameData] = useState([]);
  const [showAddRow, setShowAddRow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);

  const addRow = (data) => {
    let duplicate_items = filter(rows, (item) => {
      return data.rowName == item.name;
    });
    if (duplicate_items.length > 0) {
      ToastCommon(true, "duplicate", addToast);
    } else {
      // toastSuccess()
      axios
        .post(MexxarApi, {
          name: data.rowName,
        })
        .then((res) => {
          if (res.data.status == "success") {
            getAllRows();
            setShowAddRow(!showAddRow);
            ToastCommon(true, "success", addToast);
          } else {
            ToastCommon(true, "duplicate", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    }
  };

  const deleteAllRows = () => {
    selectedItems.forEach((item) => {
      axios
        .delete(MexxarApi + "/" + item)
        .then((res) => {
          getAllRows();
          setSelectedItems([]);
          setSelectAll(false);
        })
        .catch((err) => {
          //console.log(err)
        });
    });
    ToastCommon(true, "delete", addToast);
  };

  const updateRow = (data) => {
    let duplicate_items = filter(rows, (item) => {
      return data.rowName == item.name;
    });

    if (duplicate_items.length > 0) {
      ToastCommon(true, "duplicate", addToast);
    } else {
      axios
        .put(MexxarApi, {
          id: rowID,
          name: data.rowName,
          status: 1,
        })
        .then((res) => {
          if (res.data.status == "success") {
            getAllRows();
            setShowUpdateRow(!showUpdateRow);
            ToastCommon(true, "update", addToast);
          } else {
            ToastCommon(true, "duplicate", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    }
  };

  const getAllRows = () => {
    axios
      .get(HostEmailServerConfig)
      .then((res) => {
        setRows(res.data.body);
        setAdd(rows.length);
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
    } else {
      setSelectedItems([...selectedItems, rowId]);
    }
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  useEffect(() => {
    getAllRows();
  }, []);

  return (
    <DashboardLayout
      title="Email Configuration"
      subTitle="Setup Your Email Clients"
      target=""
    >
      <div className=" d-flex justify-content-end pb-2">
        <Button color="primary" onClick={() => setShowAddRow(true)}>
          Add
        </Button>
      </div>

      {rows &&
        rows.map((row, id) => {
          return (
            <ConfigurationForm
              key={id}
              data={row}
              reload={getAllRows}
              mode={"Update"}
            />
          );
        })}

      <ModalComponent
        show={showAddRow}
        header="New Email Configuration"
        closeModal={() => setShowAddRow(false)}
      >
        <ConfigurationForm data={rows} reload={getAllRows} mode={"Add"} />
      </ModalComponent>
      <ModalComponent
        show={showUpdateRow}
        header="Update Band"
        closeModal={() => setShowUpdateRow(false)}
      >
        <NewOneField
          mode="Update"
          modalType="Email Configuration"
          fieldData={rowNameData}
          closeModal={() => setShowUpdateRow(false)}
          onCreate={(data) => updateRow(data)}
        />
      </ModalComponent>
    </DashboardLayout>
  );
};

export default EmailConfigure;
