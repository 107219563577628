import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Card } from "reactstrap";
import axios from "axios";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { HOSTMexxar, REGISTER_CANDIDATE } from "../../../configs/api-config";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";

const NumberOfCalls = (props) => {
  const { style, demoData } = props;

  const MexxarApi = HOSTMexxar;
  const [employeeCallDetails, setEmployeeCallDetails] = useState([]);

  const getData = () => {};

  useEffect(() => {
    axios.get(MexxarApi + "employee/get-employee-call-count/").then((res) => {
      // console.log("Respond for employee shit: ");

      let data_List = [
        res.data.body[0].monthlyCount,
        res.data.body[0].todayCount,
        res.data.body[0].weeklyCount,
      ];

      setEmployeeCallDetails(data_List);
    });
  }, []);

  useDidMountEffect(() => {
    if (demoData) {
      setEmployeeCallDetails([
        demoData[1].monthlyCount,
        demoData[2].todayCount,
        demoData[0].weeklyCount,
      ]);
    }
  }, [demoData]);

  return (
    <>
      <Card style={style}>
        <div className="p-2">
          <div style={{ flex: 1 }}>
            <div className="text-center">
              <FontAwesomeIcon icon={faPhone} color="#009B1E"></FontAwesomeIcon>
              &nbsp; Number of Calls
            </div>
            <div className="sub-1 p-2 text-center">
              <div style={{ flex: 1 }}>{employeeCallDetails[2]}</div>
              <div style={{ flex: 1 }}>{employeeCallDetails[0]}</div>
              <div style={{ flex: 1 }}>{employeeCallDetails[1]}</div>
            </div>
            <div className="sub-1 p-2 text-center">
              <div style={{ flex: 1 }}>Weekly</div>
              <div style={{ flex: 1 }}>Monthly</div>
              <div style={{ flex: 1 }}>Today</div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default NumberOfCalls;
