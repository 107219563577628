// // import { createStore, applyMiddleware, compose } from "redux";
// // import thunk from "redux-thunk";
// // import rootReducer from "./reducers/index";

// // const initialState = {};
// // const middleware = [thunk];

// // const store = createStore(
// //   rootReducer,
// //   initialState,
// //   // eslint-disable-next-line no-undef
// //   compose(
// //     applyMiddleware(...middleware),
// //     window.__REDUX_DEVTOOLS_EXTENSION__
// //       ? window.__REDUX_DEVTOOLS_EXTENSION__()
// //       : (noop) => noop
// //   )
// // );
// // export default store;

// import { createStore, applyMiddleware, compose } from "redux";
// import { persistStore, persistReducer } from "redux-persist";
// import storage from "redux-persist/lib/storage";
// import thunk from "redux-thunk";
// import rootReducer from "./reducers/index";

// const initialState = {};
// const middleware = [thunk];

// // Configure persist options
// const persistConfig = {
//   key: "root", // the key for the root of your reducer object
//   storage, // the storage engine to use (e.g., localStorage, sessionStorage)
// };

// // Create persisted reducer
// const persistedReducer = persistReducer(persistConfig, rootReducer);

// // Create store
// const store = createStore(
//   persistedReducer,
//   initialState,
//   compose(
//     applyMiddleware(...middleware),
//     window.__REDUX_DEVTOOLS_EXTENSION__
//       ? window.__REDUX_DEVTOOLS_EXTENSION__()
//       : (noop) => noop
//   )
// );
// const persistedStore = persistStore(store);

// export default store; // Default export the store
// export { persistedStore }; // Named export the persisted store

// import { createStore, applyMiddleware, compose } from "redux";
// import thunk from "redux-thunk";
// import rootReducer from "./reducers/index";

// const initialState = {};
// const middleware = [thunk];

// const store = createStore(
//   rootReducer,
//   initialState,
//   // eslint-disable-next-line no-undef
//   compose(
//     applyMiddleware(...middleware),
//     window.__REDUX_DEVTOOLS_EXTENSION__
//       ? window.__REDUX_DEVTOOLS_EXTENSION__()
//       : (noop) => noop
//   )
// );
// export default store;

import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";


const initialState = {};
const middleware = [thunk];

// Configure persist options
const persistConfig = {
  key: "root", // the key for the root of your reducer object
  storage, // the storage engine to use (e.g., localStorage, sessionStorage)
  whitelist: ['customHeaderReducer','callSummaryReducer'],
  // blacklist:["auth","heahunter","appSettings","ChatRedux","theme","chatDbGetReducer","authReducer1"]
};



// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create store
const store = createStore(
  persistedReducer,
  initialState,
  compose(
    applyMiddleware(...middleware),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (noop) => noop
  )
);

const persistedStore = persistStore(store);

export default store; // Default export the store
export { persistedStore }; // Named export the persisted store
