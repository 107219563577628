import { faMobileAlt, faPhone, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { Alert, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form, Modal, Row } from "reactstrap";
import RoundIconButton from "../../RoundIconButton/round-icon-button";
import { UncontrolledTooltip } from "reactstrap/lib";
import { connect, useDispatch } from "react-redux";
import { HOSTMexxar } from "../../../configs/api-config";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";


const OnboardingCall = (props) => {

    const {
        onCreate,
        closeModal,
        mode,
        modalType,
        modalType1,
        modalType2,
        fieldData,
        rowNameData,
        rowNameDay,
        custom,
        rowID,
        lanndLine,
        candidatePhoneNo,
        secondary,
        userprofile,
        resetFilters
    } = props;
    var HorizonApplicationId = custom?.customHeaders?.horizonApplicationID;

    const [addKillLog, setaddKillLog] = useState(false);
    const [isCalling, setIsCalling] = useState(false);
    const [phoneNumberType, setPhoneNumberType] = useState("Primary");

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const { addToast } = useToasts();






    console.log("userprofile", userprofile?.secondaryMobile)
    console.log("rowID", rowID)
    console.log("candidatePhoneNo", candidatePhoneNo)
    console.log("secondary", secondary)
    console.log("lanndLine", lanndLine)
    const headers = new Headers();
    headers.append("ApplicationId", HorizonApplicationId);
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    const handleOnClick = async (event) => {
        event.preventDefault(); // Prevent the default form submission behavior

        if (isCalling) {
            setIsCalling(false);
        } else {
            setIsCalling(true);

            console.log("phoneNumberType:", phoneNumberType);
            console.log("candidatePhoneNo:", candidatePhoneNo);
            console.log("secondary:", secondary);
            console.log("lanndLine:", lanndLine);
            const params = new URLSearchParams();
            if (phoneNumberType === "Primary") {
                params.append("destination", candidatePhoneNo);
            }
            if (phoneNumberType === "Secondary") {
                params.append("destination", userprofile?.secondaryMobile);
            }
            if (phoneNumberType === "Land") {
                params.append("destination", lanndLine);
            }

            const requestOptions = {
                method: "POST",
                headers: headers,
                body: params,
            };

            try {
                const response = await fetch(
                    "https://localhost:10443/api/v1/MakeCall",
                    requestOptions
                );
                const data = await response.json();

                console.log(data.UniqueId);
                console.log("last contacted", data)
                // setCallId(data.UniqueId);
                localStorage.setItem("callId", data.UniqueId);
            } catch (error) {
                setIsCalling(false);
                console.error("Error Call Hangup:", error);
            }
        }
    };

    const startTimeString = localStorage.getItem("callHistory");
    const durationString = localStorage.getItem("callHistoryDuration");
    const startDate = new Date(startTimeString);
    console.log("startDate", startDate);
    const endDate = new Date(startDate.getTime() + durationString * 1000);
    const formattedEndTime = moment(endDate).toISOString();
    let lastCallStartedTime = formattedEndTime === null ? new Date() : new Date(); //use when no softphone is assigned

    //let lastCallEndedTime = formattedEndTime === null ? new Date() : endDate; //uncomment for Horizon softphone

    let lastCallEndedTime = new Date(); //When no softphone is integrated please use this
    console.log("lastCallEndedTime", lastCallEndedTime)
    console.log("formattedEndTime", formattedEndTime)


    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const displayPhoneNumber = (phoneNumber) => {
        if (typeof phoneNumber !== "number" || isNaN(phoneNumber)) {
            return "N/A";
        }
        return phoneNumber.toString();
    };

    const onSubmit = (data) => {
        console.log(data)
        setaddKillLog(true);


        let arrangedData = {
            rowName: data.rowName,
            rowDate: data.rowDate,
            rowDay: data.rowDay,
        };
        onCreate(arrangedData);

    };
    const handlePhoneNumberType = (type) => {
        setPhoneNumberType(type);
        console.log("mobile type", type);

    };


      const savingFunction = () => {
        axios
          .put(
            `${HOSTMexxar}onboarding/lastContact/${userprofile?.id}/${userprofile?.employee?.id}`,
          )
          .then((res) => {
            ToastCommon(true, "success", addToast);
            resetFilters()
          })
          .catch((err) => {
            console.log("Error:", err);
            // ToastCommon(true, "error", addToast);
          });
      };
    return (
        <Form>
            {/* <h5>Call User</h5> */}
            <Row className="justify-content-center">
                <div className="mx-1" id="callCandidate" style={{ marginRight: '50px' }}>
                    <RoundIconButton
                        buttonColor="#31c971"
                        icon={faPhone}
                        iconSize="fa-sm"
                        buttonSize="btn-sm"
                        click={handleOnClick}
                        aria-label="Call Candidate"
                    />
                    <UncontrolledTooltip placement="right" target="callCandidate">
                        Call
                    </UncontrolledTooltip>
                </div>

                <div className="mx-1" id="phoneTypeDropdown" style={{ marginLeft: '50px' }}>
                    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="custom-dropdown">
                        <DropdownToggle caret color="success" className="dropdown-toggle" aria-label="Select Phone Type">
                            {phoneNumberType}
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu p-2">
                            <DropdownItem
                                onClick={() => handlePhoneNumberType("Primary")}
                                className="dropdown-item d-flex align-items-center"
                                style={{
                                    backgroundColor: phoneNumberType === "Primary" ? '#d4edda' : '',
                                    fontWeight: phoneNumberType === "Primary" ? 'bold' : 'normal',
                                    cursor: 'pointer'
                                }}>
                                <FontAwesomeIcon icon={faMobileAlt} className="icon mr-2" />
                                <span className="option-text">Primary</span>: {displayPhoneNumber(candidatePhoneNo)}
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => handlePhoneNumberType("Land")}
                                className="dropdown-item d-flex align-items-center"
                                style={{
                                    backgroundColor: phoneNumberType === "Land" ? '#d4edda' : '',
                                    fontWeight: phoneNumberType === "Land" ? 'bold' : 'normal',
                                    cursor: 'pointer'
                                }}>
                                <FontAwesomeIcon icon={faPhoneAlt} className="icon mr-2" />
                                <span className="option-text">Landline</span>: {displayPhoneNumber(lanndLine)}
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => handlePhoneNumberType("Secondary")}
                                className="dropdown-item d-flex align-items-center"
                                style={{
                                    backgroundColor: phoneNumberType === "Secondary" ? '#d4edda' : '',
                                    fontWeight: phoneNumberType === "Secondary" ? 'bold' : 'normal',
                                    cursor: 'pointer'
                                }}>
                                <FontAwesomeIcon icon={faMobileAlt} className="icon mr-2" />
                                <span className="option-text">Secondary</span>: {displayPhoneNumber(userprofile?.secondaryMobile)}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                    <UncontrolledTooltip placement="right" target="phoneTypeDropdown">
                        Select Phone Type
                    </UncontrolledTooltip>
                </div>

            </Row>
            <br></br>
            <Row className="justify-content-end">
                <div className="my-1 ">
                    {/* <Button
                        color="info"
                        size="sm"
                        className="mr-2"
                      >
                        Go Back
                      </Button> */}
                    <Link
                        to="#"
                    // to={{
                    //   pathname: "/dashboard/headhunter",
                    // }}
                    >
                        <Button
                            color="info"
                            size="sm"
                          onClick={() => {
                            savingFunction();
                          }}
                        >
                            Update Last Contact
                        </Button>
                    </Link>
                </div>

            </Row>

        </Form>


    );
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        callState: state.websocketData?.Payload,
        reason1: state?.callSummaryReducer,
        custom: state.customHeaderReducer,

    };
}

export default connect(mapStateToProps, {})(OnboardingCall);
