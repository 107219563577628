import Axios from "axios";

export default function setAuthorizationToken(token,type="default") {
  if (token && type=="default") {
    Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else if (type=="email"){
    Axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }
  
  else{
    delete Axios.defaults.headers.common["Authorization"];
  }
}
