import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";

import axios from "axios";
import "./candidate.style.css";
import Select from "react-select";
import { connect } from "react-redux";
import { filter, find, forEach, isNull } from "lodash";
import Store from "../../../redux/store";
import NoData from "../../errorPages/NoData";
// import { BulletList } from "react-content-loader";
import { useToasts } from "react-toast-notifications";
import NoConnection from "../../errorPages/NoConnection";
import ToastCommon from "../../../components/toastCommon";
import {
  faExclamationTriangle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CANDIDATE_ROLE,
  CANDIDATE_SPECIALITY,
  ExportCSVGrantEmail,
  HOSTMexxar,
  HostMexxarURL,
  REGISTER_CANDIDATE,
} from "../../../configs/api-config";
import useCandidateList from "../../../customHooks/useCandidateList";
import ModalComponent from "../../../components/Modal/modalComponent";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import SnapShotNav from "../../../components/Modal/ModalBody/snapShotNav";
import {
  rolesGetAction,
  specialitiesGetAction,
  bandsGetAction,
  addressWardGetAction,
  countiesGetAction,
  districtsGetAction,
  cvSourcesGetAction,
} from "../../../redux/actions/initialDataGetAction";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import WorkingCardWidget from "../../../widgets/working-card-widget/working-card-widget";
import { CandidateListLoader } from "../../../components/ContentLoaders/SVGLoaders";
import { FunnelIcon } from "../../../assets/icons/svg";

import AssignCandidatesToConsultant from "../../../components/Modal/ModalBody/assignCandidatesToConsultant";

const Candidates = (props) => {
  const {
    // specialities,
    // roles,
    bands,
    addressWards,
    counties,
    districts,
    cvSources,
  } = props;
  const MexxarApi = REGISTER_CANDIDATE;
  const MexxarApiRole = CANDIDATE_ROLE;
  const MexxarApiSpeciality = CANDIDATE_SPECIALITY;
  const { addToast } = useToasts();

  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  //change the url
  // let url = MexxarApi + "/status/" + filterCustom.value;
  /***************** Search filter ******************/
  const [query, setQuery] = useState(null);
  const [reset, setReset] = useState(false);
  const [offset, setOffset] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [remember, setRemember] = useState(false); /// <--------filter settings options saving and retreving/ if need activate later with below checkbox
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchAdvanced, setSearchAdvanced] = useState(false);
  const [callResponse, setCallResponse] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");
  const [profileStatus, setProfileStatus] = useState("ACTIVE");

  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showSnapshotModal, setShowSnapshotModal] = useState(false);
  const [selectedItemsForSnapshot, setSelectedItemsForSnapshot] = useState();

  const [searchQuery, setSearchQuery] = useState("");

  const [speciality, setSpeciality] = useState([]);
  const [role, setRole] = useState([]);
  const [band, setBand] = useState([]);

  const [county, setCounty] = useState([]);
  const [ward, setWard] = useState([]);
  const [district, setDistrict] = useState([]);
  const [cvSource, setCvSource] = useState([]);
  const [workflow, setWorkflow] = useState([
    { id: "HEADHUNTER", value: "HEADHUNTER", label: "Headhunting" },
    { id: "REGISTRATION", value: "REGISTRATION", label: "Registration" },
    { id: "AUDIT", value: "AUDIT", label: "Audit" },
    { id: "MAINTENANCE", value: "MAINTENANCE", label: "Maintenance" },
  ]);
  const [serviceType, setServiceType] = useState([
    {
      id: 0,
      value: "PERMANENT",
      label: "PERMANENT",
    },
    {
      id: 1,
      value: "TEMPORARY",
      label: "TEMPORARY",
    },
  ]);

  const [selectedSpecialities, setSelectedSpecialities] = useState({
    id: "",
    value: "",
    label: "",
  });
  const [selectedRole, setSelectedRole] = useState({
    id: "",
    value: "",
    label: "",
  });
  const [selectedBand, setSelectedBand] = useState({
    id: "",
    value: "",
    label: "",
  });

  const [selectedCounty, setSelectedCounty] = useState({
    id: "",
    value: "",
    label: "",
  });
  const [selectedWard, setSelectedWard] = useState({
    id: "",
    value: "",
    label: "",
  });
  const [selectedDistrict, setSelectedDistrict] = useState({
    id: "",
    value: "",
    label: "",
  });
  const [selectedCVSource, setSelectedCVSource] = useState({
    id: "",
    value: "",
    label: "",
  });
  const [selectedWorkflow, setSelectedWorkflow] = useState({
    id: "",
    value: "",
    label: "",
  });
  const [selectedServiceType, setSelectedServiceType] = useState({
    id: 1,
    value: "TEMPORARY",
    label: "TEMPORARY",
  });

  console.log("specialities", speciality);
  console.log("selectedSpecialities", selectedSpecialities);
  console.log("selectedRole", selectedRole?.value);

  let urlForPrint = `${HOSTMexxar}candidates/download/csv/?status=${profileStatus}&speciality=${
    selectedSpecialities?.value
  }&role=${selectedRole?.value}&band=${selectedBand.id}&workflow=${
    selectedWorkflow.value
  }&source=${selectedCVSource.id}&serviceType=${selectedServiceType.value}${
    selectedCounty.id !== "" ? `&pc_county=${selectedCounty.id}` : ""
  }`;

  let url = `${HOSTMexxar}candidates/filter/?status=${profileStatus}&speciality=${
    selectedSpecialities?.value
  }&role=${selectedRole?.value}&band=${selectedBand.id}&workflow=${
    selectedWorkflow.value
  }&source=${selectedCVSource.id}&serviceType=${selectedServiceType.value}${
    selectedCounty.id !== "" ? `&pc_county=${selectedCounty.id}` : ""
  }`;

  let urlForGetSelectAllCandidatesEmails = `${HOSTMexxar}candidates/filter/emails/?status=${profileStatus}&speciality=${
    selectedSpecialities?.value
  }&role=${selectedRole?.value}&band=${selectedBand.id}&workflow=${
    selectedWorkflow.value
  }&source=${selectedCVSource.id}&serviceType=${selectedServiceType.value}${
    selectedCounty.id !== "" ? `&pc_county=${selectedCounty.id}` : ""
  }`;

  const [resetResults, setResetResults] = useState(false);
  const [searchStatus, setSearchStatus] = useState({
    status: false,
    data: null,
  });

  const toggleFilter = () => {
    setSelectAll(false);
    setOffset(20);
    setIsOpenFilter(!isOpenFilter);
  };

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleSnapshotModal = () => {
    // let selectedCandidates = filter(rows, (item) => {
    //   return item.candidateId === "SPECIAL";
    // });
    let tempArray = [];

    if (!selectAll) {
      for (let i = 0; i < rows.length; i++) {
        for (let x = 0; x < selectedItems.length; x++) {
          if (selectedItems[x] == rows[i].id) {
            tempArray.push(rows[i].email);
          }
        }
      }
    } else if (selectAll) {
      for (let i = 0; i < rows.length; i++) {
        tempArray.push(rows[i].email);
      }
    }

    console.log("tempArray", tempArray);

    setSelectedItemsForSnapshot(tempArray);
    setShowSnapshotModal(!showSnapshotModal);
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
      return false;
    } else {
      setSelectedItems([...selectedItems, rowId]);
      return true;
    }
  };

  const [emialListLoading, setEmailListLoading] = useState(false);
  const [emialListError, setEmailListError] = useState(null);

  const getSelectAllEmailAddresses = async () => {
    setEmailListLoading(true);
    setEmailListError(null);
    try {
      let cancel;
      const res = await axios({
        method: "GET",
        url: urlForGetSelectAllCandidatesEmails,
        params: { page: pageNumber, offset: offset, q: query },
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
      });
      setEmailListLoading(false);

      return res.data;
    } catch (error) {
      console.error("Error fetching email addresses:", error);
      setEmailListError(error.message); // Set the error message directly
      setEmailListLoading(false);

      return [];
    }
  };

  const checkAll = async () => {
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      try {
        const emails = await getSelectAllEmailAddresses();
        setSelectedItems(emails);
        setSelectAll(true);
      } catch (error) {
        console.error("Error checking all:", error);
      }
    }
  };

  console.log("angi selectedItems test--->", selectedItems);
  console.log("angi emialListError test--->", emialListError);

  // useDidMountEffect(() => {
  //   let ids = [];
  //   if (selectAll) {
  //     rows.forEach((item) => {
  //       ids.push(item.id);
  //     });
  //     setSelectedItems(ids);
  //   }
  // }, [selectAll]);

  const handleSearchAdvance = () => {
    let w = selectedSpecialities.length > 0 ? 1 : 0;

    let result_3 = [];

    if (w > 0) {
      forEach(selectedSpecialities, function (item) {
        forEach(rows, function (row) {
          let A = filter(row.candidateSpecialities, (value) => {
            return value.id === item.id;
          });

          if (A.length !== 0) {
            result_3.push(row);
          }
        });
      });

      setSearchStatus({ status: true, data: result_3 });
      // setRows(result_3);
    }
  };

  //....reset function......//

  const resetFilters = () => {
    setQuery("");
    clearLocalStorageFilters();
    setSearchStatus({ status: false, data: null });
    setResetResults(!resetResults);
    setSelectedRole({ id: "", value: "", label: "" });
    setSelectedBand({ id: "", value: "", label: "" });
    setSelectedWard({ id: "", value: "", label: "" });
    setSelectedCounty({ id: "", value: "", label: "" });
    setSelectedDistrict({ id: "", value: "", label: "" });
    setSelectedCVSource({ id: "", value: "", label: "" });
    setSelectedWorkflow({ id: "", value: "", label: "" });
    setSelectedServiceType({
      id: 1,
      value: "TEMPORARY",
      label: "TEMPORARY",
    });
    setSelectedSpecialities({ id: "", value: "", label: "" });

    setCallResponse("");
    setApplicationStatus("");
    setProfileStatus("ACTIVE");
    setPageNumber(1);
    setOffset(20);
    setRemember(false);
  };

  const getSpecialities = () => {
    axios
      .get(HOSTMexxar + "candidate-specialities/role/" + selectedRole?.value)
      .then((res) => {
        const formattedSpecialities = res.data.body.map((speciality) => ({
          value: speciality.id,
          label: speciality.name,
        }));
        setSpeciality(formattedSpecialities);
        console.log("sssssssssssssl;skdskd", res.data.body);
      })
      .catch((err) => {
        // ToastCommon(true, "error", addToast);
      });
  };
  const getRoles = () => {
    axios
      .get(MexxarApiRole)
      .then((res) => {
        setRole(res.data.body);
      })
      .catch((err) => {
        // ToastCommon(true, "error", addToast);
      });
  };
  // const rearrangeSpecialities = () => {
  //   let specialitiesTemp = [];
  //   speciality.forEach((item) => {
  //     specialitiesTemp.push({
  //       value: item.name,
  //       label: item.name + `${item?.type == "DATABASE" ? "-DB" : ""}`,
  //       id: item.id,
  //     });
  //   });
  //   setSpeciality(specialitiesTemp);
  // };
  const rearrangeRoles = () => {
    let rolesTemp = [];
    role.forEach((item) => {
      rolesTemp.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    setRole(rolesTemp);
  };
  const rearrangeBands = () => {
    let bandsTemp = [];
    bands.forEach((item) => {
      bandsTemp.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    setBand(bandsTemp);
  };

  const rearrangeAddressWards = () => {
    let bandsTemp = [];
    addressWards.forEach((item) => {
      bandsTemp.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    setBand(bandsTemp);
  };

  useEffect(() => {
    getRoles();
  }, []);
  useDidMountEffect(() => {
    getSpecialities();
    setSelectedSpecialities({ id: "", value: "", label: "" });
  }, [selectedRole]);
  // useEffect(() => {
  //   getAllRows();
  // }, [filterCustom]);

  const getBands = () => {
    axios.get(HOSTMexxar + "candidate-bands").then((bands) => {
      rearrangeBands(bands.data.body);
    });
  };

  const { loading, error, rows, hasMore, totalElements, totalPages } =
    useCandidateList(
      url,
      filterCustom,
      query,
      pageNumber,
      searchAdvanced,
      resetResults,
      offset
    );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
          saveFilters("pageNumber", pageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function handleSearch(e) {
    setQuery(e.target.value);
    setPageNumber(1);
  }

  /******* Use to set page number to default value of 1 when changing the employee or search advance filters  *******/
  useDidMountEffect(() => {
    if (!contactView && !remember) {
      //in default view pagenumber is reset to 1
      setPageNumber(1);
    }
  }, [searchAdvanced]);
  /*******End *******/

  useEffect(() => {
    Store.dispatch(specialitiesGetAction());
    Store.dispatch(rolesGetAction());
    Store.dispatch(bandsGetAction());

    // Store.dispatch(addressWardGetAction());
    Store.dispatch(countiesGetAction());
    // Store.dispatch(districtsGetAction());
    Store.dispatch(cvSourcesGetAction());

    return () => {};
  }, []);

  // useEffect(() => {
  //   if (specialities) {
  //     rearrangeSpecialities();
  //   }
  //   return () => {};
  // }, []);

  // useEffect(() => {
  //   if (roles) {
  //     rearrangeRoles();
  //   }
  //   return () => {};
  // }, [roles]);

  useEffect(() => {
    if (bands) {
      rearrangeBands();
    }
    return () => {};
  }, [bands]);

  const reArrangeToShowInSelectDropDown = (data) => {
    let reArranged = [];
    forEach(data, function (item) {
      reArranged.push({
        value: item,
        label: item,
        id: item,
      });
    });
    return reArranged;
  };

  const reArrangeCVSources = (data) => {
    let reArranged = [];
    forEach(data, function (item) {
      reArranged.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    return reArranged;
  };

  useEffect(() => {
    if (addressWards) {
      setWard(reArrangeToShowInSelectDropDown(addressWards));
    }
    return () => {};
  }, [addressWards]);

  useEffect(() => {
    if (counties) {
      setCounty(reArrangeToShowInSelectDropDown(counties));
    }
    return () => {};
  }, [counties]);

  useEffect(() => {
    if (districts) {
      setDistrict(reArrangeToShowInSelectDropDown(districts));
    }
    return () => {};
  }, [districts]);

  useEffect(() => {
    if (cvSources) {
      setCvSource(reArrangeCVSources(cvSources));
    }
    return () => {};
  }, [cvSources]);

  const shortCutKey = (event) => {
    if (event.key === "Shift + F") {
    }
  };
  const [showAssignConsultant, setShowAssignConsultant] = useState(false);
  const toggleAssignConsultant = () => {
    setShowAssignConsultant(!showAssignConsultant);
  };
  const [contactView, setContactView] = useState(false);
  const toggleView = () => {
    setContactView(!contactView);
  };

  const print = () => {
    axios({
      method: "GET",
      url: urlForPrint,
      params: { page: pageNumber, offset: offset, q: query },
      responseType: "blob", // set the response type to blob
    })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `candidates-Page${pageNumber}.csv`); // set the desired file name
        document.body.appendChild(link);
        link.click(); // programmatically click on the link to trigger the download
      })
      .catch();
  };

  const saveFilters = (type, data) => {
    localStorage.setItem(type, JSON.stringify(data));
  };

  const clearLocalStorageFilters = () => {
    let emptyObject = {
      id: "",
      value: "",
      label: "",
    };
    localStorage.setItem(
      "rememberFiltersCandidatePage",
      JSON.stringify("false")
    );
    localStorage.setItem("selectedSpecialities", JSON.stringify(emptyObject));
    localStorage.setItem("selectedRole", JSON.stringify(emptyObject));
    localStorage.setItem("selectedBand", JSON.stringify(emptyObject));
    localStorage.setItem("selectedCounty", JSON.stringify(emptyObject));
    localStorage.setItem("selectedCVSource", JSON.stringify(emptyObject));
    localStorage.setItem("selectedWorkflow", JSON.stringify(emptyObject));
    localStorage.setItem("pageNumber", JSON.stringify(1));
    localStorage.setItem("profileStatus", JSON.stringify("ACTIVE"));
    localStorage.setItem(
      "selectedServiceType",
      JSON.stringify({
        id: 1,
        value: "TEMPORARY",
        label: "TEMPORARY",
      })
    );
  };

  useEffect(() => {
    setSelectAll(false);
  }, [rows]);

  useEffect(() => {
    let emptyObject = {
      id: "",
      value: "",
      label: "",
    };

    let rememberTemp = localStorage.getItem("rememberFiltersCandidatePage");

    if (rememberTemp == "true") {
      setRemember(true);
    } else {
      setRemember(false);
    }

    if (rememberTemp == "true") {
      const storedSelectedSpecialities =
        JSON.parse(localStorage.getItem("selectedSpecialities")) || emptyObject;
      const storedSelectedRole =
        JSON.parse(localStorage.getItem("selectedRole")) || emptyObject;
      const storedSelectedBand =
        JSON.parse(localStorage.getItem("selectedBand")) || emptyObject;
      const storedSelectedCounty =
        JSON.parse(localStorage.getItem("selectedCounty")) || emptyObject;
      const storedSelectedCVSource =
        JSON.parse(localStorage.getItem("selectedCVSource")) || emptyObject;
      const storedSelectedWorkflow =
        JSON.parse(localStorage.getItem("selectedWorkflow")) || emptyObject;
      const storedPageNumber =
        JSON.parse(localStorage.getItem("pageNumber")) || 1;
      const storedProfileStatus =
        JSON.parse(localStorage.getItem("profileStatus")) || "ACTIVE";
      const storedSelectedServiceType = JSON.parse(
        localStorage.getItem("selectedServiceType")
      ) || {
        id: 1,
        value: "TEMPORARY",
        label: "TEMPORARY",
      };

      setSelectedSpecialities(storedSelectedSpecialities);
      setSelectedRole(storedSelectedRole);
      setSelectedBand(storedSelectedBand);
      setSelectedCounty(storedSelectedCounty);
      setSelectedCVSource(storedSelectedCVSource);
      setSelectedWorkflow(storedSelectedWorkflow);
      setSelectedServiceType(storedSelectedServiceType);
      setSearchAdvanced(true);
      setPageNumber(storedPageNumber);
      setProfileStatus(storedProfileStatus);
    } else {
      resetFilters();
    }
  }, []);

  useDidMountEffect(() => {
    setSelectAll(false);
    if (selectAll) {
      setSelectedItems([]);
    }
    return () => {};
  }, [rows]);

  return (
    <>
      <DashboardLayout title="Candidates" subTitle="Candidate Monitoring">
        <FontAwesomeIcon icon={faUsers} className={"mr-3"} />
        <Label>
          {totalElements}{" "}
          {totalElements.length == 1 ? "Candidate" : "Candidates"}
        </Label>
        <div className="form-inline">
          {/* <Button
            color="success"
            className="mr-2"
            onClick={toggleAssignConsultant}
          >
            Assign Consultants
          </Button> */}
          <Button
            color="success"
            id="toggleView"
            className="mr-2"
            onClick={toggleView}
          >
            {contactView ? "Contact View" : "Default View"}
          </Button>
          <UncontrolledTooltip target="toggleView">
            Toggle to view Default/Contact View
          </UncontrolledTooltip>
          {contactView && props.auth.item?.email === ExportCSVGrantEmail && (
            <>
              <Button
                color="success"
                id="print"
                className="mr-2"
                onClick={print}
              >
                Export .csv
              </Button>
              <UncontrolledTooltip target="print">
                Export candidate list to a .csv file
              </UncontrolledTooltip>
            </>
          )}

          <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
            <FunnelIcon fill={remember} />
            <span className={`mx-1 ${remember && "text-danger"}`}>Filter</span>
          </Button>
          <Input
            value={query}
            onChange={handleSearch}
            // onChange={(e) => handleSearchOnChange(e)}
            type="text"
            id="searchbar"
            className="form-control no-border no-shadow no-bg typeahead tt-input"
            placeholder="Search Candidates..."
            autoComplete="off"
            spellCheck="false"
            dir="auto"
            style={{
              position: "relative",
              verticalAlign: "top",
              backgroundColor: "transparent",
            }}
            onKeyDown={(e) => shortCutKey(e)}
          />
        </div>
        <UncontrolledTooltip target="searchbar">
          Search candidates by ID / Email / Phone No. / Post Code / Region /
          Work Flow Status
        </UncontrolledTooltip>
        <br></br>
        <Collapse isOpen={isOpenFilter}>
          <Card>
            <CardBody>
              <Row>
                <Col sm={12} md={6} lg={3}>
                  <p>Profile Status</p>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="ACTIVE"
                        checked={profileStatus === "ACTIVE"}
                        onChange={(e) => {
                          setProfileStatus(e.target.value);
                          saveFilters("profileStatus", e.target.value);
                        }}
                      />
                      <Badge color="success">Active</Badge>
                    </Label>
                  </FormGroup>

                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="INACTIVE"
                        checked={profileStatus === "INACTIVE"}
                        onChange={(e) => {
                          setProfileStatus(e.target.value);
                          saveFilters("profileStatus", e.target.value);
                        }}
                      />
                      <Badge color="secondary">Inactive</Badge>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="DORMANT"
                        checked={profileStatus === "DORMANT"}
                        onChange={(e) => {
                          setProfileStatus(e.target.value);
                          saveFilters("profileStatus", e.target.value);
                        }}
                      />
                      <Badge color="warning">Dormant</Badge>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="DO_NOT_CALL"
                        checked={profileStatus === "DO_NOT_CALL"}
                        onChange={(e) => {
                          setProfileStatus(e.target.value);
                          saveFilters("profileStatus", e.target.value);
                        }}
                      />
                      <Badge color="danger">Do not call</Badge>
                    </Label>
                  </FormGroup>

                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="UNVERIFIED"
                        checked={profileStatus === "UNVERIFIED"}
                        onChange={(e) => {
                          setProfileStatus(e.target.value);
                          saveFilters("profileStatus", e.target.value);
                        }}
                      />
                      <Badge color="warning">Unverified</Badge>
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        value="STAND_BY"
                        checked={profileStatus === "STAND_BY"}
                        onChange={(e) => {
                          setProfileStatus(e.target.value);
                          saveFilters("profileStatus", e.target.value);
                        }}
                      />
                      <Badge color="warning">Standby</Badge>
                    </Label>
                  </FormGroup>
                </Col>

                <Col sm={12} md={6} lg={3}>
                  <Label for="candidateRole">Role</Label>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        color: "#333333",
                      }),
                    }}
                    // isMulti
                    id={"candidateRole"}
                    value={selectedRole}
                    name="candidateRole"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(data) => {
                      setSelectedRole(data);
                      saveFilters("selectedRole", data);
                    }}
                    options={role?.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />

                  <Label for="candidateSpeciality" className="mt-2">
                    Speciality
                  </Label>
                  <Select
                    // isMulti
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        color: "#333333",
                      }),
                    }}
                    id={"candidateSpeciality"}
                    value={selectedSpecialities}
                    name="candidateSpeciality"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(data) => {
                      setSelectedSpecialities(data);
                      saveFilters("selectedSpecialities", data);
                    }}
                    options={speciality}
                  />
                </Col>
                <Col sm={12} md={6} lg={3}>
                  <Label for="candidateBand">Band </Label>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        color: "#333333",
                      }),
                    }}
                    // isMulti
                    value={selectedBand}
                    id={"candidateBand"}
                    name="candidateBand"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(data) => {
                      setSelectedBand(data);
                      saveFilters("selectedBand", data);
                    }}
                    options={band}
                  />

                  <Label for="cvsource" className="mt-2">
                    CV Source
                  </Label>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        color: "#333333",
                      }),
                    }}
                    // isMulti
                    value={selectedCVSource}
                    id={"cvsource"}
                    name="cvsource"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(data) => {
                      setSelectedCVSource(data);
                      saveFilters("selectedCVSource", data);
                    }}
                    options={cvSource}
                  />
                </Col>
                <Col sm={12} md={6} lg={3}>
                  <Label for="workflow">Workflow </Label>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        color: "#333333",
                      }),
                    }}
                    // isMulti
                    value={selectedWorkflow}
                    id={"workflow"}
                    name="workflow"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(data) => {
                      setSelectedWorkflow(data);
                      saveFilters("selectedWorkflow", data);
                    }}
                    options={workflow}
                  />

                  <Label for="serviceType" className="mt-2">
                    Service Type
                  </Label>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        color: "#333333",
                      }),
                    }}
                    // isMulti
                    value={selectedServiceType}
                    id={"serviceType"}
                    name="serviceType"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(data) => {
                      setSelectedServiceType(data);
                      saveFilters("selectedServiceType", data);
                    }}
                    options={serviceType}
                  />
                </Col>
              </Row>
              <Row className="mt-2">
                {" "}
                <Col sm={12} md={12} lg={12}>
                  <Label>By Area </Label>
                  <hr className="mb-2  mt-0" />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6} lg={3}>
                  <Label for="county">County</Label>
                  <Select
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                        color: "#333333",
                      }),
                    }}
                    // isMulti
                    value={selectedCounty}
                    id={"county"}
                    name="county"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(data) => {
                      setSelectedCounty(data);
                      saveFilters("selectedCounty", data);
                    }}
                    options={county}
                  />
                </Col>
                {contactView ? (
                  <>
                    <Col sm={12} md={6} lg={3}>
                      {" "}
                      <Label for="Offset">Offset</Label>
                      <Input
                        max={100}
                        min={20}
                        id="Offset"
                        value={offset}
                        onChange={(e) => setOffset(e.target.value)}
                        type="number"
                      ></Input>
                      {(offset > 500 || offset < 20) && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            This field only accepts numbers between 0 and 500.
                          </li>
                        </ul>
                      )}
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <Label for="Pagenumber">Page Number</Label>
                      <Input
                        max={totalPages}
                        id="Pagenumber"
                        value={pageNumber}
                        onChange={(e) => setPageNumber(e.target.value)}
                        type="number"
                      ></Input>
                      {(pageNumber > totalPages || pageNumber < 0) && (
                        <ul
                          className="list-unstyled text-sm mt-1 text-muted filled"
                          id="parsley-id-119"
                        >
                          <li className="parsley-required text-danger">
                            This field only accepts numbers between 0 and{" "}
                            {totalPages}.
                          </li>
                        </ul>
                      )}
                      {<small> Total Pages: {totalPages} </small>}
                    </Col>
                  </>
                ) : (
                  <>
                    <Col sm={12} md={6} lg={3}></Col>
                    <Col sm={12} md={6} lg={3}></Col>
                  </>
                )}

                {/* <Col sm={12} md={6} lg={3}>
                  <Label for="ward">Ward </Label>
                  <Select
                    // isMulti
                    value={selectedWard}
                    id={"ward"}
                    name="ward"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(data) => setSelectedWard(data)}
                    options={ward}
                  />
                </Col>
                <Col sm={12} md={6} lg={3}>
                  <Label for="district">District</Label>
                  <Select
                    // isMulti
                    value={selectedDistrict}
                    id={"district"}
                    name="district"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(data) => setSelectedDistrict(data)}
                    options={district}
                  />
                </Col> */}
                <Col sm={12} md={6} lg={3}>
                  <div className="d-flex flex-column align-items-end mt-1 mr-3">
                    <div>
                      <small>
                        Remember
                        <Input
                          className="mx-2 pointer"
                          type="checkbox"
                          value="rememberFiltersCandidatePage"
                          checked={remember}
                          onChange={() => {
                            setRemember(!remember);
                            saveFilters(
                              "rememberFiltersCandidatePage",
                              !remember
                            );
                          }}
                        ></Input>
                      </small>
                    </div>
                    <Button
                      color="success "
                      style={{ width: "70px" }}
                      className="my-1 btn-sm"
                      onClick={() => {
                        setSearchAdvanced(!searchAdvanced);
                        handleSearchAdvance();
                      }}
                    >
                      Search
                    </Button>
                    <Button
                      onClick={() => resetFilters()}
                      color="danger "
                      className="my-1 btn-sm"
                      style={{ width: "70px" }}
                    >
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Collapse>
        <label className="ui-check ml-1">
          <Input
            type="checkbox"
            name="id"
            checked={selectAll}
            disabled={emialListLoading}
            onChange={() => checkAll()}
          />

          <i></i>
        </label>
        <Label className="text-color text-sm" id="selectAll">
          Select all ( {totalElements}{" "}
          {totalElements.length == 1 ? "Candidate" : "Candidates"} )
          {emialListLoading && (
            <small>
              Please wait while we fetch email addresses...{" "}
              <Spinner
                animation="border"
                className="mx-2"
                style={{
                  height: "10px",
                  width: "10px",
                }}
              />
            </small>
          )}
        </Label>
        <UncontrolledTooltip placement="right" target="selectAll">
          E-Shot
        </UncontrolledTooltip>
        {emialListError && (
          <>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              color="danger"
              className="mx-2"
            />
            <small className="text-danger mx-2">
              {emialListError}-Please refresh or try again later.
            </small>
          </>
        )}
        {selectedItems.length > 0 ? (
          <div className="mb-2">
            <Button
              id="E-Shot"
              className="bg-success "
              disabled={emialListLoading}
              onClick={() => toggleSnapshotModal()}
            >
              E-Shot
              {emialListLoading && (
                <>
                  <Spinner
                    animation="border"
                    className="mx-2"
                    style={{
                      height: "20px",
                      width: "20px",
                    }}
                  />
                  <small>
                    Loading...Once we collected all the candidate you will be
                    able to click the button
                  </small>
                </>
              )}
            </Button>
            <UncontrolledTooltip placement="right" target="E-Shot">
              {loading
                ? "Please wait while we gather all the selected candidates..."
                : "Send bulk emails to candidates"}
            </UncontrolledTooltip>
          </div>
        ) : null}

        {rows?.map((row, index) => {
          if (rows.length === index + 1) {
            return (
              <div ref={lastBookElementRef} key={row.email}>
                <WorkingCardWidget
                  userProfile={row}
                  isSelected={(id) => isSelected(id)}
                  onItemSelect={(id) => onItemSelect(id)}
                  selectAll={selectAll}
                  contactView={contactView}
                ></WorkingCardWidget>
              </div>
            );
          } else {
            return (
              <div key={row.email} style={{ width: "100%", minWidth: "800px" }}>
                <WorkingCardWidget
                  userProfile={row}
                  isSelected={(id) => isSelected(id)}
                  onItemSelect={(id) => onItemSelect(id)}
                  selectAll={selectAll}
                  contactView={contactView}
                ></WorkingCardWidget>
              </div>
            );
          }
        })}
        {loading && !error && (
          <div style={{ width: "100%", minWidth: "800px" }}>
            <CandidateListLoader />
          </div>
        )}
        {rows.length === 0 && !loading && !error && <NoSearchResults />}
        {error && !loading && (
          <div>
            <NoData></NoData>
          </div>
        )}
      </DashboardLayout>
      {showSnapshotModal ? (
        <ModalComponent
          className="modal-md"
          show={showSnapshotModal}
          header="E-Shot"
          closeModal={() => setShowSnapshotModal(false)}
          disableBackdropClick 
        >
          <SnapShotNav
            closeModal={() => setShowSnapshotModal(false)}
            user={selectedItems}
          />
        </ModalComponent>
      ) : null}

      <ModalComponent
        show={showAssignConsultant}
        header="Assign Consultants"
        className="modal-lg"
        closeModal={() => setShowAssignConsultant(false)}
      >
        <AssignCandidatesToConsultant
          onCreate={(data) => console.log("test")}
          workflowToBe="HEADHUNTER"
          closeModal={() => setShowAssignConsultant(false)}
        />
      </ModalComponent>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    specialities: state.initialDataGetReducer.specialities,
    roles: state.initialDataGetReducer.roles,
    bands: state.initialDataGetReducer.bands,
    // addressWards: state.initialDataGetReducer.addressWards,
    counties: state.initialDataGetReducer.counties,
    // districts: state.initialDataGetReducer.districts,
    cvSources: state.initialDataGetReducer.cvSources,
  };
}

export default connect(mapStateToProps, {})(Candidates);
