const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;


//......Candy API
export const Version="Version(PT) 1.1.8.4"
export const CompanyName="Candy Healthcare"
export const CompanyPhoneNumber="0203 011 3800"
export const CompanyAddress="The Hop Exchange | 24 Southwark Street, London"
export const CompanyWebsite=" www.candyhealthcare.co.uk"
export const CommonEmail="common@candyhealthcare.co.uk"
export const GoogleMapKey=googleApiKey;
export const HOSTMexxar = "https://candyapi.mexxar.co.uk/";
export const HostMexxarEmail = "https://mailapi.mexxar.com/";
export const HostMexxarURL = "https://candy.mexxar.co.uk/"; 
export const HostMexxarWS = "wss://candyapi.mexxar.co.uk/";
export const HostEmailServerConfig="https://mailapi.mexxar.com/config/" 
export const ExportCSVGrantEmail="conordavis@candyhealthcare.co.uk"
export const CommonEmailReplySignatureEmail="t.corbyn@candyhealthcare.co.uk"
export const CommonEmailReplySignaturePosition=""
export const CommonEmailReplySignatureName="The Candy Healthcare Team"
export const EmailSignatureFooter="This email is confidential and may be privileged. It cannot be guaranteed to be secure of free from error or virus. All email communication with Candy Healthcare Recruitment Limited is monitored and a record is kept. If you are not the intended recipient please accept out apologies, it is intended for the addressee only. If you are not the addressee please do not use it in any way. If received in error please inform the sender and delete all copies. Thank you for your cooperation. Anything in this email which does not relate to Candy Healthcare Recruitment Limited official business is neither given nor endorsed by us. To the extent permitted by law, Candy Healthcare Recruitment Limited does not accept any responsibility for any use by anyone other than the intended addressee. CV and personal information submitted via email will be kept secure, Candy Healthcare Recruitment Limited adhere to all requirement of the GDPR. Please read out Privacy Policy. Please contact info@candyhealthcare.co.uk for any GDPR queries. Interviewing or engagement of a candidate introduce by Candy Healthcare Recruitment Limited shall be deemed to be an acceptance of our Terms and Conditions of Business. These terms are available at any time upon request info@candyhealthcare.co.uk"
export const ReferenceFormCompanyDetails="CANDY HEALTHCARE REFERENCE REQUEST FORM -INFO@CANDYHEALTHCARE.CO.UK | WWW.CANDYHEALTHCARE.CO.UK | CANDYHEALTHCARE RECRUITMENT LIMITED IS REGISTERED IN ENGLAND AND WALES| REGISTERED NUMBER: 14060495 | REGISTERED OFFICE ADDRESS: WESTWING THE HOP EXCHANGE. 24 SOUTHWARK STREET, LONDON, SE1 1TY"
export const ClientLable="Hospital"
export const specialEmailPermissionsEmails=["admin@candyhealthcare.co.uk","t.corbyn@candyhealthcare.co.uk","sharaz@ccmsltd.com","s.haraz@ccmsltd.com","conordavis@candyhealthcare.co.uk"]  // All only these accounts to tag Daniella-(Temporay Audit Officer)
export const danielle="Danielle Webb"   // This feature is only avilable to Candy Health Care
export const BotURL="https://booking.mexxar.com/"

//API Endpoints
export const SIGN_IN = `${HOSTMexxar}token/generate-token`;

export const REGISTER_CANDIDATE = `${HOSTMexxar}candidates`;
export const SEND_MESSAGE = `${HOSTMexxar}chat/send`;
export const CHAT_ROOT = `${HOSTMexxar}chat`;
export const POSTAL_CODES = `${HOSTMexxar}postal-codes`;
export const PING = `${HOSTMexxar}ping`;

export const SEND_SMS = `${HOSTMexxar}twillio/message`;
export const Add_New_DOCUMENT = `${HOSTMexxar}documents`;
export const Add_NEW_EMPLOYEE = `${HOSTMexxar}employees`;
export const EMPLOYEE_PERMISSIONS = `${HOSTMexxar}employee`;

export const CANDIDATE_BAND = `${HOSTMexxar}candidate-bands`;
export const EMAIL_TEMPLATE = `${HOSTMexxar}email-templates`;
export const CANDIDATE_ROLE = `${HOSTMexxar}candidate-roles`;
export const CONSULTANT_ROLE = `${HOSTMexxar}user-role`;
export const FRAMEWORK = `${HOSTMexxar}frameworks`;
export const LEVEL = `${HOSTMexxar}framework-levels`;
export const CANDIDATE_SPECIALITY = `${HOSTMexxar}candidate-specialities`;
export const CONFIGURE_DOCS = `${HOSTMexxar}documents/combine-docs`;
export const TRUSTS = `${HOSTMexxar}trusts`;
export const CLIENTS = `${HOSTMexxar}clients`;
export const WARDS = `${HOSTMexxar}wards`;
export const USERROLES = `${HOSTMexxar}user-role`;
export const SHIFTS = `${HOSTMexxar}shifts`;
export const CONFIGURE_USER_ROLES_PERMISSIONS = `${HOSTMexxar}user-role/get-all-permissions`;
export const HEADHUNTER_WORKSPACE_DATA = `${HOSTMexxar}workspace/headhunter`;
export const REGISTRATION_WORKSPACE_DATA = `${HOSTMexxar}workspace/registration`;
export const AUDIT_WORKSPACE_DATA = `${HOSTMexxar}workspace/audit`;
export const MAINTENANCE_WORKSPACE_DATA = `${HOSTMexxar}workspace/maintenance`;
export const BOOKING_ASSIGMENT_CODES = `${HOSTMexxar}nhs-configuration/code/`;
export const DASHBOARD_PERFORMANCE = `${HOSTMexxar}dashboard/`;

export const CLIENT_RATE_DATA = `${HOSTMexxar}client-rates`;

export const CANDIDATE_RATE_DATA = `${HOSTMexxar}candidate-rates`;
export const CANDIDATES_IN_REGISTRATION_STATUS = `${HOSTMexxar}workspace/headhunter/in-registration`;
export const CALL_LOGS = `${HOSTMexxar}callLogs`;
export const TODO = `${HOSTMexxar}todo`;
export const RESCHEDULE = `${HOSTMexxar}reshedules`;
export const PERSONAL_NOTE = `${HOSTMexxar}personalnote`;

export const HEADHUNTER_WORKSPACE_SUMMARY = `${HOSTMexxar}workspace/headhunter/summary`;
export const REGISTRATION_WORKSPACE_SUMMARY = `${HOSTMexxar}workspace/registration/summary`;
export const AUDIT_WORKSPACE_SUMMARY = `${HOSTMexxar}workspace/audit/summary`;
export const MAINTENANCE_WORKSPACE_SUMMARY = `${HOSTMexxar}workspace/maintenance/summary`;
export const PAYROLL_WORKSPACE_SUMMARY = `${HOSTMexxar}workspace/payroll/summary`;

export const BOOKING_WORKSPACE_CANDIDATELIST = `${HOSTMexxar}workspace/booking/`;

export const CREATE_BOOKING = `${HOSTMexxar}bookings`;

export const GET_CHATROOM = `${HOSTMexxar}chat/chat-room`;
export const READ_MESSAGES = `${HOSTMexxar}chat/read`;

export const EMPLOYEE_TEAMS = `${HOSTMexxar}team`;

export const RESCHEDULE_HEADHUNTER_WORKSPACE = `${HOSTMexxar}workspace/headhunter/reschedule`;
export const RESCHEDULE_REGISTRATION_WORKSPACE = `${HOSTMexxar}workspace/registration/reschedule`;
export const RESCHEDULE_MAINTENANCE_WORKSPACE = `${HOSTMexxar}workspace/maintenance/reschedule`;

export const SCHEDULE_TRAINING = `${HOSTMexxar}training-booking`;
export const TRAININGS = `${HOSTMexxar}trainings`;
export const TRAININGPROVIDERS = `${HOSTMexxar}trainingProviders`;
export const CVSOURCE = `${HOSTMexxar}candidate-source`;
export const ADD_PERM_DOCS = `${HOSTMexxar}permanent/permDocs/types`;

export const CANDIDATE_CV_COURCES= `${HOSTMexxar}candidate-source`;

//Job board apis
export const JOB_BOARD_WORKSPACE = `${HOSTMexxar}jobs`;
export const JOB_BOARD = `${HOSTMexxar}jobs/`;
export const JOB_BOARD_BY_CLIENT = `${JOB_BOARD}client/`;

//POSTALCODE APIs
export const COUNTIES = `${HOSTMexxar}postal-codes/county`;
export const DISTRICTS = `${HOSTMexxar}postal-codes/district`;
export const ADDRESSWARDS = `${HOSTMexxar}postal-codes/ward`;

