import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import axios from "axios";
// import logo from "../../../images/ramsyLogo.png";
import logo from "../../../images/candiHealthCare.jpg";
// import logo from "../../../images/mexxarLogo.png";
import {
  Button,
  Input,
  Form,
  FormGroup,
  Modal,
  Label,
  Spinner,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";
import { HOSTMexxar } from "../../../configs/api-config";
import ForgotPasswordResetPassword from "./forgot-password-reset-password";

export const ForgotPasswordPage = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const history = useHistory();
  const [data, setData] = useState(String);
  const [code, setCode] = useState("");
  const [verifyCode, setVerifyCode] = useState("");
  const [successStatus, setSuccessStatus] = useState(false);
  const [setOTP] = useState("");
  const { register, handleSubmit, errors } = useForm();
  const [id, setId] = useState(null);

  const onSubmitClicked = async () => {
    try {
      await axios.post(
        HOSTMexxar + "guest/password/forgot?email=" + emailValue
      );
      setSuccess(true);
      setTimeout(() => {
        // history.push('/login');
      }, 4000);
    } catch (e) {
      setErrorMessage(e.message);
    }
  };
  const onSubmitVerify = () => {
    console.log(verifyCode);
    try {
      axios
        .post(
          HOSTMexxar + "guest/password/forgot/verify-code?code=" + verifyCode
        )
        .then((res) => {
          if (res.data.status == "success") {
            setId(res.data.body.id);
            // console.log("id recieved",res.data.body.id)
            setSuccess(true);
            setSuccessStatus(true);
            console.log(success);
          } else {
            setSuccessStatus(false);
            console.log(errors);
          }
        });
    } catch (e) {
      setErrorMessage(e.message);
      console.log(e);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickToOpen = () => {
    setOpen(true);
  };

  const handleToClose = () => {
    setOpen(false);
  };

  //const handleChange = (code) => setCode(code);
  console.log("id check", id);
  return success ? (
    <div className="content-container">
      <div className="container-fluid">
        <Row></Row>
        <div className="side-left">
          {/* <img className="img2" src={img2} height="600px" width="700px"></img> */}
        </div>
        <div className="side-right ">
          <div className="logo-container p-4">
            <img className="logo-center" src={logo} alt="LOGO"></img>
          </div>
          {!successStatus ? (
            <>
              <div className="align center">
                <h4>Verification sent!</h4>
              </div>
              <p>Please enter the verification code sent to your email</p>
              <div>
                <Input
                  value={verifyCode}
                  onChange={(e) => setVerifyCode(e.target.value)}
                  invalid={errors.onSubmitVerify}
                ></Input>
              </div>
              <Row className="justify-content-end">
                <div className="d-flex pt-4 ">
                  {" "}
                  <Button
                    className="mr-2"
                    color={`${
                      !successStatus && verifyCode ? "danger" : "success"
                    }`}
                    type="submit"
                    disabled={!verifyCode}
                    onClick={onSubmitVerify}
                  >
                    Verify
                  </Button>{" "}
                </div>
              </Row>
            </>
          ) : (
            <ForgotPasswordResetPassword id={id} />
          )}

          <div className="copyright text-center p-4">
            <p>Copyright {new Date().getFullYear()} @ Mexxar</p>
            <p>Powered by Mexxar</p>
          </div>
        </div>
      </div>
      {/* <h2>Success</h2>
            <p>Check your email for a reset link</p> */}
    </div>
  ) : (
    <div className="content-container">
      <div className="container-fluid">
        <Row></Row>
        <div className="side-left">
          {/* <img className="img2" src={img2} height="600px" width="700px"></img> */}
        </div>
        <div className="side-right ">
          <div className="logo-container p-4">
            <img className="logo-center" src={logo} alt="LOGO"></img>
          </div>
          <div className="">
            <h4>Forgot Password</h4>
          </div>
          <p>Enter your email and we'll send you a reset link</p>
          {errorMessage && <div className="fail">{errorMessage}</div>}
          <Input
            value={emailValue}
            onChange={(e) => setEmailValue(e.target.value)}
            placeholder="youremail@gmail.com"
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              pattern: {
                value:
                  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "Please enter a valid email address!",
              },
            })}
          />
          <FormGroup className="pt-3">
            <Button
              size="sm"
              className="w-50 btn-cyan"
              color="primary"
              type="submit"
              disabled={!emailValue}
              onClick={onSubmitClicked}
            >
              Send Reset Link
            </Button>
            <Link className="mx-2" to={`/auth/login`}>
              Go back to login
            </Link>
          </FormGroup>
          <div className="copyright text-center p-4">
            <p>Copyright {new Date().getFullYear()} @ Mexxar</p>
            <p>Powered by Mexxar</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPasswordPage;
