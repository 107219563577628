import React from "react";
import { Card } from "reactstrap";

const ProgressBar = (props) => {
  const { percentage, title, color, style } = props;
  return (
    <Card style={style}>
      <div className="p-2">
        <small>
          {title}
          <strong className={`text-${color}`}> {percentage}%</strong>
        </small>
        <div className="progress my-3 circle" style={{ height: 6 }}>
          <div
            className={`progress-bar circle gd-${color}`}
            style={{ width: `${percentage}%` }}
          ></div>
        </div>
      </div>
    </Card>
  );
};

export default ProgressBar;
