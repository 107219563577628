import React from "react";

export const ProfileStatus = (data) => {
  let badgeColor;
  let badgeLable;

  switch (data) {
    case "DECLINED":
      badgeLable = "Declined";
      badgeColor = "danger";
      break;
      case "DECLINE":
        badgeLable = "Declined";
        badgeColor = "danger";
        break;
    case "PENDING" :
      badgeLable = "Pending";
      badgeColor = "warning";
      break;
    case null:
      badgeLable = "Pending";
      badgeColor = "warning";
      break;
    case "APPROVED":
      badgeLable = "Confirmed";
      badgeColor = "success";
      break;
      case "APPROVE":
        badgeLable = "Confirmed";
        badgeColor = "success";
        break;
    case "CONFIRMED":
      badgeLable = "Confirmed";
      badgeColor = "success";
      break;
      case "CONFIRM":
        badgeLable = "Confirmed";
        badgeColor = "success";
        break;
  }

  return { badgeColor: badgeColor, badgeLable: badgeLable };
};
