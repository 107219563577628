import React, { useEffect, useState } from "react";
import Text from "./text";
import axios from "axios";
import Email from "./email";
import { filter, split } from "lodash";
import { connect } from "react-redux";
import { HOSTMexxar } from "../../../configs/api-config";
import { Label, Input, FormGroup, Col } from "reactstrap";
import useDidMountEffect from "./intialRunUseEffect";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import outlookLogo from "../../../../src/images/Outlook-Logo.png";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import ToastCommon from "../../toastCommon";
import { useToasts } from "react-toast-notifications";
import TabNavigationMenuModal from "../../../util/common/tabNavigationForModals.js";

const EmailNav = (props) => {
  const { closeBody, user, closeModal, auth, incomingPage } = props;
  const MexxarAPI = HOSTMexxar;
  const { instance, accounts } = useMsal();
  const { addToast } = useToasts();

  const [items, setItems] = useState([]);
  const [currentTab, setCurrentTab] = useState("email");
  const [outlookLoggedIn, setOutlookLoggedIn] = useState(false);
  const [outlookLoggedInError, setOutlookLoggedInError] = useState(false);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [emailTemplateList, setEmailTemplateList] = useState(null);

  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  const getAllEmailTemplates = () => {
    axios.get(MexxarAPI + "email-templates").then((emailTemplates) => {
      setEmailTemplateList(emailTemplates.data.body);

      if (incomingPage == "permCompliants" || incomingPage == "permJobBoard") {
        let permCompliantsList = emailTemplates.data.body.filter(function (
          template
        ) {
          return split(template.templateName, ":")[1] == "SDTCPS"; //use this ":SDTCPS" as an identification for templates, use in the name of the email template
        });
        setSelectedTemplate(permCompliantsList[0]);
      } else {
        setSelectedTemplate(emailTemplates.data.body[0]);
      }
    });
  };

  // const setData = (data) =>{
  //   closeBody(data);
  // }

  const checkPermissions = (permission) => {
    let array = filter(auth.item.userRole.permissions, (item) => {
      return item.name == permission;
    });
    return array;
  };

  useEffect(() => {
    getAllEmailTemplates();

    //<<<<<<<< Uncomment this incase candidate need to send sms
    // if (checkPermissions("CANDIDATE").length > 0   ) {
    //   setItems([
    //     { name: "Email", key: "email" },
    //     { name: "Text", key: "text" },
    //   ]);
    // } else if (checkPermissions("EMPLOYEE").length > 0) {
    //   setItems([{ name: "Email", key: "email" }]);
    // }

    if (incomingPage == "permCompliants") {
      setItems([{ name: "Email", key: "email" }]);
    } else {
      setItems([
        { name: "Email", key: "email" },
        { name: "Text", key: "text" },
      ]);
    }

    return () => {};
  }, []);

  const filterSelectedTemplate = (id) => {
    let tempSelectedEmailTemplate = emailTemplateList.filter(function (
      template
    ) {
      return template.id == id;
    });
    setSelectedTemplate(tempSelectedEmailTemplate[0]);
  };

  function checkOutlookLoginStatus() {
    if (accounts.length > 0) {
      // User is logged in
      setOutlookLoggedIn(true);
    } else {
      // User is not logged in
      setOutlookLoggedIn(false);
      instance.acquireTokenPopup(loginRequest).then((response) => {
        setOutlookLoggedIn(true);
      });
    }
  }
  const handleLoginOutLook = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        // Successful login

        if (response && response?.account) {
          setOutlookLoggedIn(true);
        } else {
          setOutlookLoggedIn(false);
          setOutlookLoggedInError(true);
        }
      })

      .catch((error) => {
        setOutlookLoggedIn(false);
        setOutlookLoggedInError(true);
        if (error instanceof InteractionRequiredAuthError) {
          // Token refresh failed, user interaction is required
          try {
            // Acquire token interactively
            instance.acquireTokenPopup(loginRequest).then((response) => {
              setOutlookLoggedIn(true);
            });

            // Use the new token for API calls
            // Example: sendEmailUsingToken(newToken);
          } catch (error) {
            // Failed to acquire token interactively
            ToastCommon(true, "error", addToast);
            console.error("Failed to acquire token interactively:", error);
          }
        } else {
          // Failed to refresh token
          ToastCommon(true, "error", addToast);
          console.error("Failed to refresh token:", error);
        }
      });
  };

  useEffect(() => {
    // Call the function to check the login status
    checkOutlookLoginStatus();

    return () => {};
  }, []);
  return (
    <>
      {outlookLoggedIn ? (
        <>
          <FormGroup row>
            <Label sm={4}>Template</Label>
            <Col sm={8}>
              <Input
                type="select"
                name="template"
                onChange={(template) =>
                  filterSelectedTemplate(template.target.value)
                }
              >
                {/* <option value="">Default</option> */}
                {emailTemplateList === undefined ||
                emailTemplateList === null ? (
                  ""
                ) : (
                  <>
                    {emailTemplateList.map((template, id) => {
                      return (
                        <option value={template.id} key={id}>
                          {template.templateName}
                        </option>
                      );
                    })}
                  </>
                )}
              </Input>
            </Col>
          </FormGroup>
          <TabNavigationMenuModal
            activated={currentTab}
            onChangeItem={switchTabHandler}
            itemList={items}
          />
          {currentTab === "email" && (
            <Email
              closeModal={closeModal}
              selectedUser={user}
              template={selectedTemplate}
              incomingPage={incomingPage}
            />
          )}
          {currentTab === "text" && (
            <Text
              closeModal={closeModal}
              user={user}
              template={selectedTemplate}
              incomingPage={incomingPage}
            />
          )}
        </>
      ) : (
        <div className="d-flex align-items-center justify-content-center flex-column">
          <p>To send an email, kindly ensure that you are logged in first</p>
          <img src={outlookLogo} style={{ height: 100 }}></img>
          <u className="text-info pointer ml-4" onClick={handleLoginOutLook}>
            Login
          </u>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(EmailNav);
