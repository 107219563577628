import firebase from 'firebase/app'
import "firebase/database";

var firebaseConfig = {
    apiKey: "AIzaSyA-jvYyoFzHLj3ukmhIiOWFJiKz1BbNINA",
    authDomain: "chatboi-c40c8.firebaseapp.com",
    databaseURL: "https://chatboi-c40c8-default-rtdb.firebaseio.com",
    projectId: "chatboi-c40c8",
    storageBucket: "chatboi-c40c8.appspot.com",
    messagingSenderId: "887093227076",
    appId: "1:887093227076:web:28d61ff7f892c3f62d5611",
    measurementId: "G-5DJPWXNPFX"
  };
  
  firebase.initializeApp(firebaseConfig);
  export default firebase.database();



  