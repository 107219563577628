import { useState } from "react";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "reactstrap";
import ModalComponent from "../../components/Modal/modalComponent";
import AddAJob from "../../components/Modal/ModalBody/Admin/addAJob";
import { HOSTMexxar, JOB_BOARD_BY_CLIENT } from "../../configs/api-config";
import axios from "axios";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import JobBoardWorkspace from "./workspace/workspace.js";
import TabNavigationMenu from "../../util/common/tabNavigation";

const JobBoardPerm = (props) => {
  const { auth } = props;
  const MexxarJobApi = JOB_BOARD_BY_CLIENT;
  const [currentTab, setCurrentTab] = useState("jobBoard");
  const [showJobModal, setShowJobModal] = useState(false);
  const [employeeID, setEmployeeID] = useState(auth.item.userTypeId);
  const [summery, setSummery] = useState({});

  const { addToast } = useToasts();

  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };
  function createAJob(data, client) {
    axios
      .post(HOSTMexxar + "jobs/" + client.id + "/add", data)
      .then((res) => {
        ToastCommon(true, "success", addToast);
        setShowJobModal(false);
      })
      .catch((error) => {});
  }

  const getData = () => {
    if (employeeID) {
      // axios
      //   .get(MexxarJobApi + "?employeeId=" + Number(employeeID))
      //   .then((res) => {
      //     if (isMounted.current) {
      //       setSummery(res.data.body[0]);
      //     }
      //   })
      //   .catch((err) => {
      //     //   console.log(err);
      //     // toastCommon(true, "error");
      //   });
    }
  };

  return (
    <>
      <DashboardLayout
        title="Job Board Permanent Staffing"
        subTitle="Manage Permanent Jobs"
      >
        <div className="d-flex aligh-items-end justify-content-end ">
          {" "}
          <Button
            color="info"
            onClick={() => {
              setShowJobModal(true);
            }}
          >
            Add a Job
          </Button>
        </div>

        <TabNavigationMenu
          activated={currentTab}
          onChangeItem={switchTabHandler}
          itemList={[
            { name: "Job Board", key: "jobBoard" },
            // { name: "Filled", key: "filled" },
          ]}
        />

        {currentTab === "jobBoard" && (
          <JobBoardWorkspace
            updateTargets={() => getData()}
            employeeID={employeeID}
          />
        )}
        {/* {currentTab === "reschedule" && (
          <CallBackPage
            updateTargets={() => getData()}
            employeeID={employeeID}
            page="HEADHUNTING"
          />
         
        )} */}
      </DashboardLayout>

      <ModalComponent
        show={showJobModal}
        header="Add New Job"
        closeModal={() => setShowJobModal(false)}
        className="modal-lg"
      >
        <AddAJob
          mode="Add"
          modalType="Band"
          closeModal={() => setShowJobModal(false)}
          onCreate={(data, client) => createAJob(data, client)}
        />
      </ModalComponent>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(JobBoardPerm);
