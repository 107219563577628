// reducers.js
const initialState = {
  customHeaders:  [],
  // other initial state properties...
};

const customHeaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CUSTOM_HEADERS':
      console.log("custom headers",action.payload)
      return {
        ...state,
        customHeaders: action.payload,
      };
    // handle other actions...
    default:
      return state;
  }
};

export default customHeaderReducer;
