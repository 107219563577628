import { useEffect, useState } from "react";
import axios from "axios";
import useDidMountEffect from "../components/Modal/ModalBody/intialRunUseEffect";
import { HOSTMexxar } from "../configs/api-config";

export default function useAssigmentCodesFilter(
  url,
  query,
  pageNumber,
 searchAdvanced,
  reset
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [assigmentCodes, setAssigmentCodes] = useState([]);
  const [totalElements, setTotalElements] = useState(0);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setAssigmentCodes([]);
  }, [query]);

  // useDidMountEffect(() => {
  //   setAssigmentCodes([]);
  // }, [searchAdvanced]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    axios({
      method: "GET",
      url: url,
      params: {
        // page: pageNumber,
        // offset: 20,
        // q: query,
      },
      cancelToken: new axios.CancelToken((c) => (cancel = c)),
    })
      .then((res) => {
        if (res.data.status == "success") {
          // setTotalElements(res.data.body.totalElements);
          // setAssigmentCodes((prevBooks) => {
          //   return [...new Set([...prevBooks, ...res.data.body])];
          // });
          // setHasMore(res.data.body.content.length > 0);
          setAssigmentCodes(res.data.body);
        } else {
          setError(true);
        }

        setLoading(false);
      })
      .catch((e) => {
        if (axios.isCancel(e)) return;
        setLoading(false);
        setError(true);
      });
    return () => cancel();
  }, [ pageNumber, reset]);

  return { loading, error, assigmentCodes, hasMore, totalElements };
}
