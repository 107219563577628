import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import {
  AUDIT_WORKSPACE_DATA,
  HOSTMexxar,
  HostMexxarWS,
} from "../../../configs/api-config";

import { connect } from "react-redux";
import { forEach, filter } from "lodash";
import { useSpring, animated } from "react-spring";
import { useToasts } from "react-toast-notifications";
import { Client as StompClient } from "@stomp/stompjs";
import NoConnection from "../../errorPages/NoConnection";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useWorkspaceFetch from "../../../customHooks/useWorkspaceFetch";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import WorkingCardAuditWidget from "../../../widgets/working-card-widget/working-card-audit-widget ";
import { FunnelIcon } from "../../../assets/icons/svg";
import useWorkspaceWithFilters from "../../../customHooks/useWorkspaceWithFilters";
import useWebSocketClient from "../../../customHooks/useWebSocketClient";

const AuditWorkspace = (props) => {
  const { updateTargets, employeeID } = props;
  var workflow = "AUDIT";

  const { addToast } = useToasts();

  const [query, setQuery] = useState(null);
  const [reset, setReset] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [remember, setRemember] = useState(true); /// <--------filter settings options saving and retreving/ if need activate later with below checkbox
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchAdvanced, setSearchAdvanced] = useState(false);
  const [auditStatus, setAuditStatus] = useState("PENDING");
  const [callResponse, setCallResponse] = useState("");
  const [applicationStatus, setApplicationStatus] = useState(null);

  let url =
    HOSTMexxar +
    "workspace/filter?&workflow=" +
    workflow +"&candidateStatus=ACTIVE";
  
  // const { resetWorkspace } = useWebSocketClient("audit");

  const { loading, error, rows, hasMore, totalElements } =
    useWorkspaceWithFilters(
      url,
      query,
      pageNumber,
      searchAdvanced,
      reset,
      employeeID
    );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function handleSearch(e) {
    if (e.target.value == "") {
      setQuery(null);
    } else {
      setQuery(e.target.value);
    }
    setPageNumber(1);
  }

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);
  //....reset function......//
  const resetFilters = () => {
    setReset(!reset);
    setCallResponse("");
    setApplicationStatus("");
    // setSelectedDocuments([]);
    // setHTE({ value: "", bool: false });
    // setCSS({ value: "", bool: false });
    // setCPP({ value: "", bool: false });
    // setRGN({ value: "", bool: false });
    // setRMN({ value: "", bool: false });
    // setPENDING({ value: "", bool: false });
    // setVERIFIED({ value: "", bool: false });
    // setDECLINED({ value: "", bool: false });

    // setSearchStatus({ status: false, data: null });

    // setRows(initialRows);
  };

  const refreshWorkflow = (data) => {
    if (data) {
      setReset(true);
      // getAllRows();
    }
  };

  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [initialRows, setInitialRows] = useState([]);
  const [hte, setHTE] = useState({ value: "", bool: false });
  const [cpp, setCPP] = useState({ value: "", bool: false });
  const [css, setCSS] = useState({ value: "", bool: false });
  const [rgn, setRGN] = useState({ value: "", bool: false });
  const [rmn, setRMN] = useState({ value: "", bool: false });
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [pending, setPENDING] = useState({ value: "", bool: false });
  const [verified, setVERIFIED] = useState({ value: "", bool: false });
  const [declined, setDECLINED] = useState({ value: "", bool: false });

  const [searchStatus, setSearchStatus] = useState({
    status: false,
    data: null,
  });

  // const handleCheck = (e) => {
  //   if (e.target.checked) {
  //     let data = e.target.value;
  //     switch (data) {
  //       case "HTE":
  //         setHTE({ value: data, bool: true });
  //         break;
  //       case "CPP":
  //         setCPP({ value: data, bool: true });
  //         break;
  //       case "CSS":
  //         setCSS({ value: data, bool: true });
  //         break;
  //       case "RGN":
  //         setRGN({ value: data, bool: true });
  //         break;
  //       case "RMN":
  //         setRMN({ value: data, bool: true });
  //         break;
  //       case "PENDING":
  //         setPENDING({ value: data, bool: true });
  //         break;
  //       case "VERIFIED":
  //         setVERIFIED({ value: data, bool: true });
  //         break;
  //       case "DECLINED":
  //         setDECLINED({ value: data, bool: true });
  //         break;

  //       // case "REMEMBER":
  //       //   setRemember(e.target.checked);
  //       //   break;
  //     }
  //   } else {
  //     let data = e.target.value;

  //     switch (data) {
  //       case "HTE":
  //         setHTE({ value: "", bool: false });
  //         break;
  //       case "CPP":
  //         setCPP({ value: "", bool: false });
  //         break;
  //       case "CSS":
  //         setCSS({ value: "", bool: false });
  //         break;
  //       case "RGN":
  //         setRGN({ value: "", bool: false });
  //         break;
  //       case "RMN":
  //         setRMN({ value: "", bool: false });
  //         break;
  //       case "PENDING":
  //         setPENDING({ value: "", bool: false });
  //         break;
  //       case "VERIFIED":
  //         setVERIFIED({ value: "", bool: false });
  //         break;
  //       case "DECLINED":
  //         setDECLINED({ value: "", bool: false });
  //         break;
  //       // case "REMEMBER":
  //       //   setRemember(false);
  //       //   break;
  //     }
  //   }
  // };

  useDidMountEffect(() => {
    let result = [];

    // forEach(res.data.body, function (candidate) {
    //   let FCF = filter(candidate.frameworkPercentages, (item) => {
    //     return item.completePercentage == 100;
    //   });

    //   console.log("LEngth result", candidate.frameworkPercentages);
    //   console.log("LEngth of FCF", FCF.length);

    //   if (FCF.length != 0) {
    //     result.push({
    //       candidateId:candidate.candidateId,
    //       candidateName: candidate.candidateName,
    //       employeeId: candidate.employeeId,
    //       gender: candidate.gender,
    //       pro_pic: candidate.pro_pic,
    //       compliantOn: candidate.compliantOn,
    //       profileStatus: candidate.profileStatus,
    //       framework: FCF.frameworkName,
    //     });
    //   }
    // });

    forEach(rows, function (candidate) {
      result.push({
        candidateId: candidate.candidateId,
        candidateName: candidate.candidateName,
        employeeId: candidate.employeeId,
        gender: candidate.gender,
        pro_pic: candidate.pro_pic,
        compliantOn: candidate.compliantOn,
        profileStatus: candidate.profileStatus,
      });
    });
    setData(result);
  }, [rows]);

  /****************websocket***********/
  // useEffect(() => {
  //   setReset(true);
  // }, [resetWorkspace]);
  /*******End*******/

  useEffect(() => {
    updateTargets();
  }, [employeeID]);

  /******* Use to set page number to default value of 1 when changing the employee or search advance filters  *******/
  useDidMountEffect(() => {
    setPageNumber(1);
  }, [searchAdvanced, employeeID]);
  /*******End *******/
  
  function sortFunction(a, b) {
    var dateA = new Date(a.compliantOn).getTime();
    var dateB = new Date(b.compliantOn).getTime();
    return dateA > dateB ? 1 : -1;
  }

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  return (
    <>
      <div className="form-inline">
        <FontAwesomeIcon icon={faUsers} className={"ml-2 mr-3 "} />
        <Label className={"mr-3"}>
          {totalElements}{" "}
          {totalElements.length == 1 ? "Candidate" : "Candidates"}
        </Label>
        <Button
          onClick={toggleFilter}
          disabled={true}
          className="btn bg-dark-lt text-dark"
        >
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={(e) => handleSearch(e)}
          type="text"
          className="form-control no-border no-shadow no-bg typeahead tt-input "
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
          id="searchBar"
        />

        <UncontrolledTooltip target="searchBar">
          Search by Candidate name/Id
        </UncontrolledTooltip>
      </div>
      <br></br>
      {/* <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label>Framework</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="HTE"
                      checked={hte.bool}
                      onChange={handleCheck}
                    />
                    <Badge href="#" color="primary">
                      HTE
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="CSS"
                      checked={css.bool}
                      onChange={handleCheck}
                    />{" "}
                    <Badge href="#" color="primary">
                      CSS
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="CPP"
                      checked={cpp.bool}
                      onChange={handleCheck}
                    />{" "}
                    <Badge href="#" color="primary">
                      CPP
                    </Badge>
                  </Label>
                </FormGroup>
              </Col>
              <Col></Col>
              <Col>
                <Label>Role</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="RGN"
                      checked={rgn.bool}
                      onChange={handleCheck}
                    />
                    <Badge href="#" color="info">
                      RGN
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="RMN"
                      checked={rmn.bool}
                      onChange={handleCheck}
                    />{" "}
                    <Badge href="#" color="info">
                      RMN
                    </Badge>
                  </Label>
                </FormGroup>
              </Col>
              <Col></Col>
              <Col>
                <Label>Status</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="PENDING"
                      checked={pending.bool}
                      onChange={handleCheck}
                    />
                    <Badge href="#" color="warning">
                      pending
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="VERIFIED"
                      checked={verified.bool}
                      onChange={handleCheck}
                    />
                    <Badge href="#" color="success">
                      verified
                    </Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="DECLINED"
                      checked={declined.bool}
                      onChange={handleCheck}
                    />{" "}
                    <Badge href="#" color="danger">
                      Declined
                    </Badge>
                  </Label>
                </FormGroup>
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button color="success m-1 btn-sm" style={{ width: "70px" }}>
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={resetFilters}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse> */}

      {!loading && error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}
      {rows?.sort(sortFunction).map((row, i) => {
        if (rows.length === i + 1) {
          return (
            <animated.div
              style={animationProps}
              ref={lastBookElementRef}
              key={row.email}
            >
              <WorkingCardAuditWidget
                userProfile={row}
              ></WorkingCardAuditWidget>
            </animated.div>
          );
        } else {
          return (
            <animated.div style={animationProps} key={row.email}>
              <WorkingCardAuditWidget
                userProfile={row}
              ></WorkingCardAuditWidget>
            </animated.div>
          );
        }
      })}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
      {!loading && !error && rows.length == 0 && <NoSearchResults />}
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(AuditWorkspace);
