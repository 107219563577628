import React, { useState, useEffect } from "react";

import {
  CANDIDATE_SPECIALITY,
  CANDIDATE_ROLE,
  LEVEL,
  FRAMEWORK,
  Add_New_DOCUMENT,
} from "../../../../configs/api-config";

import axios from "axios";
import { useForm } from "react-hook-form";
import { Button, Label, Input, Form, FormGroup, Row, Col } from "reactstrap";
import ToastCommon from "../../../toastCommon";
import { useToasts } from "react-toast-notifications";

export default function ConfigureDoc(props) {
  const { register, handleSubmit, errors } = useForm();
  const { onCreate, closeModal, mode, modalType, fieldData } = props;

  const MexxarApiLevel = LEVEL;
  const MexxarApiRole = CANDIDATE_ROLE;
  const MexxarApiFramework = FRAMEWORK;
  const MexxarApiDocument = Add_New_DOCUMENT;
  const MexxarApiSpeciality = CANDIDATE_SPECIALITY;

  const { addToast } = useToasts();

  const onSubmit = (data) => {
    onCreate(data);
  };

  const [speciality, setSpeciality] = useState([]);
  const [level, setLevel] = useState([]);
  const [role, setRole] = useState([]);
  const [framework, setFramework] = useState([]);
  const [document, setDocument] = useState([]);
  const [type, setType] = useState([
    { id: "MANDATORY", lable: "Mandatory", value: "MANDATORY" },
    { id: "IF_APPLICABLE", lable: "If applicable", value: "IF_APPLICABLE" },
  ]);

  useEffect(() => {
    getSpecialities();
    getLevels();
    getRoles();
    getFrameworks();
    getDocuments();
  }, []);

  const getSpecialities = () => {
    axios
      .get(MexxarApiSpeciality)
      .then((res) => {
        setSpeciality(res.data.body);
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };
  const getRoles = () => {
    axios
      .get(MexxarApiRole)
      .then((res) => {
        setRole(res.data.body);
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };
  const getFrameworks = () => {
    axios
      .get(MexxarApiFramework)
      .then((res) => {
        setFramework(res.data.body);
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };
  const getLevels = () => {
    axios
      .get(MexxarApiLevel)
      .then((res) => {
        setLevel(res.data.body);
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };
  const getDocuments = () => {
    axios
      .get(MexxarApiDocument)
      .then((res) => {
        setDocument(res.data.body);
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="mx-3">
      <Row className="justify-content-between">
        <FormGroup>
          <Label for="candidateRoleId">Role</Label>

          <Input
            type="select"
            id="candidateRoleId"
            name="candidateRoleId"
            defaultValue={0}
            invalid={errors.candidateRoleId}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allowed only 30 characters",
              },
            })}
          >
            <option value={0}>Select</option>
            {role.map((items, id) => {
              return (
                <option value={items.id} key={id}>
                  {items.name}
                </option>
              );
            })}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="candidateSpecialityId">Speciality</Label>

          <Input
            type="select"
            id="candidateSpecialityId"
            name="candidateSpecialityId"
            defaultValue={0}
            invalid={errors.candidateSpecialityId}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allowed only 30 characters",
              },
            })}
          >
            <option value={0}>Select</option>
            {speciality.map((items, id) => {
              return (
                <option value={items.id} key={id}>
                  {items.name}
                </option>
              );
            })}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="frameworkId">Framework</Label>

          <Input
            type="select"
            id="frameworkId"
            name="frameworkId"
            defaultValue={0}
            invalid={errors.frameworkId}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allowed only 30 characters",
              },
            })}
          >
            <option value={0}>Select</option>
            {framework.map((items, id) => {
              return (
                <option value={items.id} key={id}>
                  {items.name}
                </option>
              );
            })}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="levelId">Level</Label>

          <Input
            type="select"
            id="levelId"
            name="levelId"
            defaultValue={0}
            invalid={errors.levelId}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
              maxLength: {
                value: 30,
                message: "This field only allowed only 30 characters",
              },
            })}
          >
            <option value={0}>Select</option>
            {level.map((items, id) => {
              return (
                <option value={items.id} key={id}>
                  {items.name}
                </option>
              );
            })}
          </Input>
        </FormGroup>
        <FormGroup row>
          <Col sm={12} md={6}>
            <Label for="documentId">Document</Label>
            <Input
              type="select"
              id="documentId"
              name="documentId"
              defaultValue={0}
              invalid={errors.documentId}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
                maxLength: {
                  value: 30,
                  message: "This field only allowed only 30 characters",
                },
              })}
            >
              <option value={0}>Select</option>
              {document.map((items, id) => {
                return (
                  <option value={items.id} key={id}>
                    {items.name}
                  </option>
                );
              })}
            </Input>
          </Col>
          <Col sm={12} md={6}>
            <Label for="documentType">Type</Label>
            <Input
              type="select"
              id="documentType"
              name="documentType"
              defaultValue={0}
              invalid={errors.documentType}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
                maxLength: {
                  value: 30,
                  message: "This field only allowed only 30 characters",
                },
              })}
            >
              <option value={0}>Select</option>
              {type.map((items, id) => {
                return (
                  <option value={items.id} key={id}>
                    {items.lable}
                  </option>
                );
              })}
            </Input>
          </Col>
        </FormGroup>
      </Row>
      <Row className="justify-content-end">
        <div className="d-flex ">
          <Button className="mr-2" color="success" type="submit">
            {mode}
          </Button>{" "}
          <Button color="secondary" onClick={() => closeModal()}>
            Close
          </Button>
        </div>
      </Row>
    </Form>
  );
}
