import { Client } from "@microsoft/microsoft-graph-client";
import { graphConfig } from "../../authConfig";
import { forEach } from "lodash";
import emailSignature from "../../../src/images/candyHelathCareEmailSignature.png";
import {
  CommonEmailReplySignatureName,
  CommonEmailReplySignaturePosition,
  CommonEmailReplySignatureEmail,
  CompanyName,
  EmailSignatureFooter,
  CompanyAddress,
  CompanyWebsite,
  CompanyPhoneNumber,
} from "../../configs/api-config";
/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function callMsGraph(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function sendEmail(
  accessToken,
  to,
  cc,
  bcc,
  subject,
  body,
  file,
  sender
) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  // signature HTML content
  // <img src=${emailSignature} alt="Signature Image"/>     <----will add later
  let sendingPersonal = sender
    ? sender
    : {
        name: `${CommonEmailReplySignatureName} | ${CommonEmailReplySignaturePosition}}`,
        email: { CommonEmailReplySignatureEmail },
        companyPhoneNumber: { CompanyPhoneNumber },
      };
  const signature = `
  <div>
     <p style="margin: 0; line-height: 1.5;">Best regards,</p>
      <p style="margin: 0; line-height: 1.5;">${
        sendingPersonal?.firstName + " " + sendingPersonal?.lastName
      }</p>
      <p style="margin: 0; line-height: 1.5;">${CompanyName} | ${CompanyAddress}</p>
      <p style="margin: 0; line-height: 1.5;">${
        sendingPersonal?.email
      } | ${CompanyWebsite}</p>
      <p style="margin: 0; line-height: 1.5;">${
        sendingPersonal?.companyPhoneNumber
      }</p>
      <br/>
      <small style="text-align: justify;">         
      ${EmailSignatureFooter}
      </small>
  </div>
`;

  const toRecipients = [
    {
      emailAddress: {
        address: to[0]?.value,
      },
    },
  ];

  if (Array.isArray(to) && to?.length > 1) {
    const additionalRecipients = to.slice(1).map((recipient) => ({
      emailAddress: {
        address: recipient.value,
      },
    }));
    toRecipients.push(...additionalRecipients);
  }

  function readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
    });
  }

  const attachments = [];

  if (file.length > 0) {
    for (const item of file) {
      const base64Data = await readFileAsBase64(item);
      attachments.push({
        "@odata.type": "#microsoft.graph.fileAttachment",
        name: item.name,
        contentType: item.type,
        contentBytes: base64Data,
      });
    }
  }

  const sendMail = {
    message: {
      subject: subject,
      body: {
        contentType: "HTML",
        content: `${body}<br><br>${signature}`,
      },
      toRecipients,
      attachments,
    },
  };

  if (cc) {
    const ccRecipients = cc.map((recipient) => ({
      emailAddress: {
        address: recipient.value,
      },
    }));
    sendMail.message.ccRecipients = ccRecipients;
  }

  if (bcc) {
    const bccRecipients = bcc.map((recipient) => ({
      emailAddress: {
        address: recipient.value,
      },
    }));
    sendMail.message.bccRecipients = bccRecipients;
  }

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(sendMail),
  };

  return fetch(graphConfig.sendEmailMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function replyEmail(
  accessToken,
  to,
  cc,
  bcc,
  subject,
  body,
  file
) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  const toRecipients = [{ emailAddress: { address: to[0]?.value } }];

  if (Array.isArray(to) && to.length > 1) {
    const additionalRecipients = to.slice(1).map((recipient) => ({
      emailAddress: {
        address: recipient.value,
      },
    }));
    toRecipients.push(...additionalRecipients);
  }

  function readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
    });
  }

  const attachments = [];
  if (file) {
    const base64Data = await readFileAsBase64(file);
    attachments.push({
      "@odata.type": "#microsoft.graph.fileAttachment",
      name: file.name,
      contentType: file.type,
      contentBytes: base64Data,
    });
  }

  const replyMail = {
    message: {
      subject: subject,
      body: {
        contentType: "HTML",
        content: body,
      },
      toRecipients,
      attachments,
    },
  };

  if (cc) {
    replyMail.message.ccRecipients = [
      {
        emailAddress: {
          address: cc,
        },
      },
    ];
  }

  if (bcc) {
    replyMail.message.bccRecipients = [
      {
        emailAddress: {
          address: bcc,
        },
      },
    ];
  }

  headers.append("Authorization", bearer);
  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(replyMail),
  };

  // return fetch(graphConfig.replyEmailMeEndpoint, options)
  //   .then((response) => response.json())
  //   .catch((error) => console.log(error));
}

export async function deleteEmail(accessToken, deleteditems) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  const deleteMail = {
    message: {
      destinationId: deleteditems,
    },
  };

  headers.append("Authorization", bearer);

  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(deleteMail),
  };

  // return fetch(graphConfig.sendEmailMeEndpoint, options)
  //   .then((response) => response.json())
  //   .catch((error) => console.log(error));
}

export async function updateEmail(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  const updateMail = {
    subject: "",
    body: {
      contentType: "",
      content: "",
    },
    inferenceClassification: "",
  };

  headers.append("Authorization", bearer);

  headers.append("Content-Type", "application/json");

  const options = {
    method: "PATCH",
    headers: headers,
    body: JSON.stringify(updateMail),
  };

  // return fetch(graphConfig.sendEmailMeEndpoint, options)
  //   .then((response) => response.json())
  //   .catch((error) => console.log(error));
}

export async function forwardEmail(
  accessToken,
  to,
  cc,
  bcc,
  subject,
  body,
  file
) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  const toRecipients = [{ emailAddress: { address: to[0]?.value } }];

  if (Array.isArray(to) && to.length > 1) {
    const additionalRecipients = to.slice(1).map((recipient) => ({
      emailAddress: {
        address: recipient.value,
      },
    }));
    toRecipients.push(...additionalRecipients);
  }

  function readFileAsBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
    });
  }

  const attachments = [];
  if (file) {
    const base64Data = await readFileAsBase64(file);
    attachments.push({
      "@odata.type": "#microsoft.graph.fileAttachment",
      name: file.name,
      contentType: file.type,
      contentBytes: base64Data,
    });
  }

  const fowardMail = {
    message: {
      subject: subject,
      body: {
        contentType: "HTML",
        content: body,
      },
      toRecipients,

      attachments,
    },
  };

  if (cc) {
    fowardMail.message.ccRecipients = [
      {
        emailAddress: {
          address: cc,
        },
      },
    ];
  }

  if (bcc) {
    fowardMail.message.bccRecipients = [
      {
        emailAddress: {
          address: bcc,
        },
      },
    ];
  }

  headers.append("Authorization", bearer);

  headers.append("Content-Type", "application/json");

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(fowardMail),
  };

  // return fetch(graphConfig.replyEmailMeEndpoint, options)
  //   .then((response) => response.json())
  //   .catch((error) => console.log(error));
}

export async function getInbox(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.getMessagesMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function getNextPage(accessToken, url) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export async function getMailFolders(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.getMailFoldersEndpoint, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error("Failed to fetch mail folders.");
      }
      return response.json();
      //response.json()
    })
    .catch((error) => console.log(error));
}

export async function getEmailItem(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  return fetch(graphConfig.getMailFoldersEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}
