import React from "react";
import { Label } from "reactstrap";
import noData from "../../images/noData.png";

const NoData = () => {
  return (
    <div className="d-flex  flex-column justify-content-center align-items-center">
      <div>
        <img src={noData} alt="." style={{ height: "300px" }} />
      </div>
      <div className="d-flex flex-column  align-items-center">
        <Label className={"font-weight-bold "}>Oops No data</Label>
        <small>It seems there is no data, add new data or please try again later</small>
      </div>
    </div>
  );
};

export default NoData;
