import React from "react";
import ContentLoader from "react-content-loader";

export const CandidateListLoader = (props) => {
  return (
    <ContentLoader
      backgroundColor={"#333"}
      speed={2}
      width="auto"
      height="auto"
      viewBox="0 0 1051 82"
      {...props}
    >
      <circle cx="91" cy="42" r="22" />
      <circle cx="162" cy="45" r="13" />
      <circle cx="211" cy="45" r="13" />
      <rect x="149" y="20" rx="4" ry="4" width="130" height="7" />
      <rect x="458" y="20" rx="4" ry="4" width="122" height="7" />
      <rect x="485" y="36" rx="3" ry="3" width="69" height="6" />
      <rect x="671" y="20" rx="4" ry="4" width="122" height="7" />
      <rect x="698" y="36" rx="3" ry="3" width="69" height="6" />
      <rect x="884" y="20" rx="4" ry="4" width="122" height="7" />
      <rect x="911" y="36" rx="3" ry="3" width="69" height="6" />
      <rect x="14" y="37" rx="2" ry="2" width="10" height="10" />
      <circle cx="351" cy="41" r="7" />
    </ContentLoader>
  );
};

export const WorkspaceListLoader = (props) => {
  return (
    <ContentLoader
      backgroundColor={"#333"}
      speed={1}
      width="auto"
      height="auto"
      viewBox="0 0 1051 82"
      {...props}
    >
      <circle cx="91" cy="42" r="22" />
      <circle cx="162" cy="45" r="13" />
      <circle cx="211" cy="45" r="13" />
      <circle cx="254" cy="45" r="13" />
      <rect x="149" y="20" rx="4" ry="4" width="130" height="7" />
      <rect x="458" y="20" rx="4" ry="4" width="527" height="7" />
      <rect x="489" y="34" rx="5" ry="5" width="465" height="14" />
      <rect x="14" y="37" rx="2" ry="2" width="10" height="10" />
      <circle cx="351" cy="41" r="7" />
    </ContentLoader>
  );
};

export default { WorkspaceListLoader, CandidateListLoader };
