// reducer.js

const initialState = {
  authToken: null,
  loggedInUser: null,
};

const authReducer1 = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_AUTH_TOKEN':
      console.log(action.payload)
      return {
        ...state,
        authToken: action.payload,
      };
    case 'SET_LOGGED_IN_USER':
      return {
        ...state,
        loggedInUser: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer1;