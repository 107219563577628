
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Alert, Badge, Button, Card, CardBody, Col, Collapse, FormGroup, Input, Label, Row } from "reactstrap";
import { useMsal } from "@azure/msal-react";
import NoOutlookResult from "./NoOutlookResult";
import { loginRequest } from "./../../authConfig";
import OutlookBodySection from "./outlookBodySection";
import { callMsGraph, getInbox, getMailFolders, getNextPage } from "./graph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { faSearch, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import setAuthorizationToken from "../../util/auth/SetAuthorizationToken";
import { useHistory } from "react-router";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import loadingAnimation from "../../../src/images/puff.svg";
import NoConnection from "../errorPages/NoConnection";
import { generateAvatar } from "../../util/common/letterAvatar";
import CreatableSelect from "react-select/creatable";
import { FunnelIcon } from "../../assets/icons/svg";
import { CommonEmail } from "../../configs/api-config";

/////////////////////////////////////////////////////////////////////////////


////////////////////////////////////////////////////////////////////////

const OutlooksMainBodyOfMailsEmail = ({ folderName, selectedFolder,candidateData }) => {

  const { instance, accounts } = useMsal();
  const [nextLink, setNextLink] = useState();
  const [emailItems, setEmailItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [showMainBody, setShowMainBody] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [searchQuery2, setSearchQuery2] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [page, setPage] = useState(0);


  const handleItemClick = (item) => {
    if (item.hasAttachments) {
      RequestEmailGetAddAttachment(item);
    }
    setSelectedItem(item);
    setShowMainBody(false);

    item.isRead = true;

    localStorage.setItem("emailItems", JSON.stringify(emailItems));
    const listItem = document.getElementById(`item-${item.id}`);

    if (listItem) {
      listItem.classList.add("bg-primary");
      listItem.querySelector(".item-title").classList.add("text-white");
    }

    RequestEmailItemUpdate(item);
  };
  const handleBackClick = () => {
    setShowMainBody(true);
  };


  console.log("candidateData.......",candidateData)
  const login2ndAcc = () => {

    instance
      .loginPopup(loginRequest)
      .then((res) => {
        console.log("angi res outlook --->", res);
      })
      .catch((e) => {});
  };

  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  let cancelTokenSource = axios.CancelToken.source();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageEnd, setPageEnd] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const [subject, setSubject] = useState(false);
  const [senderEmailAddress, setsenderEmailAddress] = useState(false);

  let pageSize = 10;

  function getEmailList(pageNumber) {
    setLoading(true);
    setError(false);

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        // const url =
        //   `https://graph.microsoft.com/v1.0/me/messages`;

            const url = `https://graph.microsoft.com/v1.0/me/messages?$filter=from/emailAddress/address eq '${candidateData?.email}'`
        const headers = {
          Authorization: "Bearer " + response?.accessToken,
        };
        // const params = {
        //   $toRecipients:candidateData?.email,
        //   // $top: pageSize,
        //   // $skip: (pageNumber - 1) * pageSize,
        // };

        // if (searchQuery) {
        //   params.$search = searchQuery2;
        // }
        // if (searchQuery) {
        //   params.$search = searchQuery3;
        // }
        // if (searchQuery) {

        //   params.$search = `from:${searchQuery}`;
        // }

        axios
          .get(url, {
            headers,
            // params,
            cancelToken: cancelTokenSource.token,
          })
          .then((response) => {
            if (response) {
              setTotalElements(response?.data?.body?.totalElements);
              setTotalPages(response?.data?.body?.totalPages);

              setEmailItems(response?.data?.value);
            }
            //Check if there is a next page
            const nextLink = response?.data["@odata.nextLink"];
            if (nextLink) {
              setPageEnd(false);
            } else {
              setPageEnd(true);
            }

            setLoading(false);
          })
          .catch((error) => {
            if (axios.isCancel(error)) {
              console.log("Request canceled:", error.message);
            } else {
              setLoading(false);
              setError(true);
            }
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  function RequestGetNextPage(next = true) {
    if (page === 1 && !next) {
      return;
    }
    let skip = page * 8;
    if (!next && page > 0) {
      skip -= 16;
      setPage(page - 1);
    }
    const url = `https://graph.microsoft.com/v1.0/me/messages?$top=8&$skip=${skip}`;
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        getNextPage(response.accessToken, url).then((response) => {
          if (response) {
            setEmailItems(response?.value);
            const nextLink = response["@odata.nextLink"];
            if (nextLink) {
              setNextLink(nextLink);
            } else {
              setNextLink(null);
            }
            if (next) {
              setPage(page + 1);
            }
          }
        });
      });
  }

  //************Email read reciption request ************//

  function RequestEmailItemUpdate(item) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        axios
          .patch(
            `https://graph.microsoft.com/v1.0/me/messages/${item.id}`,
            {
              isRead: true,
            },
            {
              headers: {
                Authorization: "Bearer " + response?.accessToken,
              },
            }
          )
          .then((response) => { })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  function base64toBlob(base64String, contentType) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  function RequestEmailGetAddAttachment(item) {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        setAuthorizationToken(response?.accessToken, "email");
        // Add a "loading" message to the page
        document.getElementById("attached-files").innerHTML =
          "Downloading Attachments...";
        axios
          .get(
            `https://graph.microsoft.com/v1.0/me/messages/${item.id}/attachments`
          )

          .then((response) => {
            const attachments = response?.data?.value;

            const attachmentLinks = attachments.map((attachment) => {
              if (attachment.contentType === "application/pdf") {
                const base64String = attachment.contentBytes;
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                  type: "application/pdf",
                });
                const url = URL.createObjectURL(blob);
                return `<a href="${url}" download="${attachment.name}">${attachment.name}</a>`;
              } else if (attachment.contentType.startsWith("image/")) {
                const base64String = attachment.contentBytes;
                const blob = base64toBlob(base64String, "image/png");
                const url = URL.createObjectURL(blob);
                return `<a href="${url}" download="${attachment.name}">${attachment.name}</a>`;
                // return `<a href="${url}" download="${attachment.name}"><img src="${url}" alt="${attachment.name}"/></a>`;
              } else if (
                attachment.contentType ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              ) {
                const base64String = attachment.contentBytes;
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                  type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                });
                const url = URL.createObjectURL(blob);
                return `<a href="${url}" download="${attachment.name}">${attachment.name}</a>`;
              } else if (
                attachment.contentType ===
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              ) {
                const base64String = attachment.contentBytes;
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });
                const url = URL.createObjectURL(blob);
                return `<a href="${url}" download="${attachment.name}">${attachment.name}</a>`;
              } else if (
                attachment.contentType === "application/octet-stream"
              ) {
                const base64String = attachment.contentBytes;
                const byteCharacters = atob(base64String);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {
                  type: "application/octet-stream",
                });
                const url = URL.createObjectURL(blob);
                return `<a href="${url}" download="${attachment.name}">${attachment.name}</a>`;
              } else {
                return `<a href="${attachment["@microsoft.graph.downloadUrl"]}" download="${attachment.name}">${attachment.name}</a>`;
              }
            });
            const attachedFiles = attachmentLinks.join(", ");
            document.getElementById("attached-files").innerHTML = attachedFiles;
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }

  const history = useHistory();
  function RequestProfileData() {
    try {
      instance
        .acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        })
        .then((response) => {
          callMsGraph(response.accessToken).then((response) => {
            history.push("/dashboard/email/inbox/profiledata");
          });
        });
    } catch (error) {
      console.error(error);
    }
  }

  // Call this function to cancel the ongoing request
  function cancelRequest() {
    cancelTokenSource.cancel("Request canceled");
    cancelTokenSource = axios.CancelToken.source(); // Create a new CancelToken source
  }

  useEffect(() => {
    getEmailList(1);
    setShowMainBody(true);
    setCurrentPage(1);
    setPageEnd(false);
    RequestEmailSearch()
    RequestEmailSearch2()
    // setPage(1);
    return () => {
      cancelRequest(); // Cancel the request when the component unmounts or when a new request is made
    };
  }, [selectedFolder, searchQuery2]);

  function goToPage(pageNumber) {
    if (pageNumber >= 1) {
      setCurrentPage(pageNumber);
    }
  }

  useEffect(() => {
    getEmailList(currentPage);
  }, [currentPage]);


  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);


  //************Email Search Function************//



  function RequestEmailSearch2() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        const headers = {
          Authorization: `Bearer ${response?.accessToken}`,
          "Content-Type": "application/json",
        };


        axios
          .get(`https://graph.microsoft.com/v1.0/me/messages?$search="${searchQuery2}"`, {
            headers,
          })
          .then((response) => {

            const searchResults = response.data.value;
            console.log("Search results:", searchResults);

            console.log("Search results:", searchResults);
            setEmailItems(searchResults);

            const sortedResults = searchResults.sort((a, b) =>
              a.from.emailAddress.name.localeCompare(b.from.emailAddress.name)
            );

            console.log("Sorted results:", sortedResults);
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }





  // function RequestEmailSearch() {
  //   instance
  //     .acquireTokenSilent({
  //       ...loginRequest,
  //       account: accounts[0],
  //     })
  //     .then((response) => {
  //       const headers = {
  //         Authorization: `Bearer ${response?.accessToken}`,
  //         "Content-Type": "application/json",
  //       };


  //       // const filterQuery = `subject eq '${searchQuery2}' or sender/emailAddress/address eq '${searchQuery2}' or receivedDateTime ge 2023-10-07T07:28:08Z`;
  //       const filterQuery = `receivedDateTime ge 2023-10-01T00:00:00Z and from/emailAddress/address eq 'sulakshanamadu31@gmail.com'`;

  //       axios
  //         .get(`https://graph.microsoft.com/v1.0/me/messages?$filter=${filterQuery}`, {
  //           headers,
  //         })
  //         .then((response) => {
  //           const searchResults = response.data.value;
  //           console.log("Search results:", searchResults);
  //           setEmailItems(searchResults);

  //         })
  //         .catch((error) => {
  //           console.log("API error:", error);
  //         });
  //     })
  //     .catch((error) => {
  //       console.log("Token error:", error);
  //     });
  // }






  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchQuery3, setSearchQuery3] = useState('');
  const [dateTo, setDateTo] = useState('');
  console.log("searchQuery:", searchQuery);
  console.log("dateTo:", dateTo);

  function RequestEmailSearch() {

    const searchQuery = document.getElementById("searchBar").value;
    const dateTo = document.getElementById("dateTo").value;

    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then((response) => {
        const headers = {
          Authorization: `Bearer ${response?.accessToken}`,
          "Content-Type": "application/json",
        };
        let filterQuery = `receivedDateTime ge ${dateTo}T00:00:00Z`;
        if (searchQuery3) {
          filterQuery += ` and from/emailAddress/address eq '${searchQuery3}'`;
        }
        axios
          .get(`https://graph.microsoft.com/v1.0/me/messages?$filter=${filterQuery}`, {
            headers,
          })
          .then((response) => {
            const searchResults = response.data.value;
            console.log("Search results:", searchResults);
            setEmailItems(searchResults);
          })
          .catch((error) => {
            console.log("API error:", error);
          });
      })
      .catch((error) => {
        console.log("Token error:", error);
      });
  }
  console.log("Accounts:", accounts);

  return (
    <div className="d-flex w-100" id="content-body">
      <div className="d-flex flex-column flex" id="mail-list">
        <div className="p-2">
          <div className="d-flex flex-row-reverse bd-highlight">
            <div className="p-2 font-weight-bold ">
              <div>


              </div>

            </div>
          </div>
          <div className="toolbar mx-2">
            <div className="flex input-group mx-2">

              {/* <Button onClick={toggleFilter} className="btn bg-transparent text-dark">

                <span className="mx-1 text-white">Filter</span>

              </Button>


              <Input
                type="text"
                id="searchBar"
                className="form-control no-border no-shadow  typeahead tt-input "
                placeholder="Search by email..."
                autoComplete="off"
                spellCheck="false"
                dir="auto"
                style={{
                  position: "relative",
                  verticalAlign: "top",
                  backgroundColor: "transparent",
                }}
                value={searchQuery2}
                onChange={(e) => setSearchQuery2(e.target.value)}


              /> */}


              {/* <span className="input-group-append">
                <span className="d-flex text-muted">
                  <FontAwesomeIcon icon={faSearch} onClick={() => RequestEmailSearch2()} />
                  
                </span>


              </span> */}








            </div>
          </div>
        </div>

        <Collapse isOpen={isOpenFilter}>
          <Card>
            <CardBody>
              <Row>
                <Col>
                  <Label >Enter Email</Label>
                  <Input
                    type="text"
                    id="searchBar"
                    className="form-control border no-shadow  typeahead tt-input "
                    placeholder="Searasrch by email..."
                    autoComplete="off"
                    spellCheck="false"
                    dir="auto"
                    style={{
                      position: "relative",
                      verticalAlign: "top",
                      backgroundColor: "transparent",
                    }}
                    value={searchQuery3}
                    onChange={(e) => setSearchQuery3(e.target.value)}

                  />
                </Col>

                <Col>
                  <Label for="exampleDate">Filter Date</Label>
                  <Input
                    type="date"
                    name="dateTo"
                    id="dateTo"
                    value={dateTo}
                    onChange={(e) => setDateTo(e.target.value)}
                  />
                </Col>


                <Col>
                  <div className="d-flex flex-column align-items-end mt-4">
                    <Button onClick={() => RequestEmailSearch()}
                      color="success m-1 btn-sm"
                      style={{ width: "70px" }}
                    >

                      Search
                    </Button>

                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Collapse>

        <div style={{ height: "100vh" }}>
  {accounts && accounts?.length == 0 && (
    <Alert color="danger">
      Note: Please login to {CommonEmail} first to use this feature
      <Button className="ml-2 btn-success" onClick={login2ndAcc}>
        Login
      </Button>
    </Alert>
  )}
          {showMainBody && loading && !error && (
            <div className="d-flex justify-content-center align-items-center" >
              <div>
                <img src={loadingAnimation} style={{ height: 100 }}></img>
              </div>
            </div>
          )}

          {!showMainBody && error && (
            <div>
              <NoConnection error={error}></NoConnection>
            </div>
          )}

          {!showMainBody && (
            <OutlookBodySection
              item={selectedItem}
              handleBackClick={handleBackClick}
            />
          )}
          {showMainBody && !loading && !error && emailItems?.length === 0 && (
            <NoOutlookResult />
          )}

          {showMainBody && !loading && !error && emailItems?.length > 0 && (
            <div className="d-grid gap-2 ml-3 scroll-y mx-4 mb-0 card">
              {emailItems.map((item, index) => (
                <div className="list list-row pointer " key={index}>
                  <div
                    className={`p-2 bg-light border  ${!item.isRead ? "bg-danger text-white" : ""
                      }`}
                  >
                    <div
                      className="list-item  "
                      style={{ height: "55px" }}
                      onClick={() => handleItemClick(item)}
                      key={item.id}
                    >
                      <div>
                        <span
                          className={`w-40 avatar ${generateAvatar(
                            item.from?.emailAddress?.name,
                            folderName
                          ).colorClass
                            }`}
                          data-toggle-class="loading"
                        >
                          {
                            generateAvatar(
                              item.from?.emailAddress?.name,
                              folderName
                            ).initials
                          }
                        </span>
                      </div>
                      <div className="flex   ">


                        <div
                          className={`item-title h-1x font-bold mt-2 ${item.isRead
                            ? "bg-light"
                            : "text-info font-weight-bold"
                            }`}
                        >
                          {item.sender?.emailAddress?.name}
                        </div>

                        <div
                          className={`item-title h-1x ${item.isRead
                            ? "bg-light"
                            : "text-info font-weight-bold"
                            }`}
                        >
                          {item.subject}
                        </div>
                      </div>

                      <div>
                        <div>
                          {item.hasAttachments && (
                            <FontAwesomeIcon
                              icon={faPaperclip}
                              color="#d1d1d1"
                            />
                          )}
                        </div>
                      </div>

                      <div className="no-wrap">
                        <div className="item-date text-muted text-sm d-none d-md-block">
                          {/* {new Date(item.sentDateTime).toLocaleDateString()}
                           */}

                          {new Date(item.sentDateTime).toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="px-3 py-3 mt-auto">

              </div>
              {/* Pagination buttons */}
              <div className="px-3 py-3 mt-auto">
                <div className="d-flex align-items-center">
                  <div className="d-flex">
                    {currentPage > 1 && (
                      <Button
                        className="mx-2"
                        style={{ boxShadow: "none" }}
                        onClick={() => goToPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </Button>
                    )}

                    <Label className="mt-2">{currentPage}</Label>
                    {!pageEnd && (
                      <Button
                        className="mx-2"
                        style={{ boxShadow: "none" }}
                        onClick={() => goToPage(currentPage + 1)}
                        disabled={pageEnd}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </Button>
                    )}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          )}
        </div>
        <style js>{`
        .img {
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      `}</style>
      </div>
    </div>
  );
};

export default OutlooksMainBodyOfMailsEmail;