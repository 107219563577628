import React, { useState, useEffect } from "react";
import store from "../../../redux/store";
import EmailSideNav from "./emailSideNav";
import MainBodyOfMails from "./MainBodyOfMails";
import Pagination from "./pagination/pagination";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import { employeesGetAction } from "../../../redux/actions/initialDataGetAction";
import { Label } from "reactstrap";
import { MailBox } from "./All_Mail_Request/MailboxRequest";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import { emailServerCredentials } from "../../../redux/actions/emailServerAction";

// const creadentials = {
//   email: "abdullai@mexxar.com",
//   password: "MexxarAb1",
//   type: "INBOX",
// };

const EmailBody = () => {
  //Mail Box Type
  const [mailType, setType] = useState("INBOX");
  // All the listed mails
  const [mail, setMail] = useState([]);
  // Pagination Page number
  const [page, setPage] = useState(1);
  // Pagination active status
  const [active, setactive] = useState(0);

  const [posts, setPost] = useState([]);
  const [other, setOther] = useState([]);
  const [creadentials, setCreadentials] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const toggleRefresh = () => {
    setRefresh(!refresh);
  };
  // useDidMountEffect(() => {
  //   MailBox(creadentials, mailType, setMail, 1);
  // }, [creadentials]);

  useEffect(() => {
    var tempCreadentials = {
      email: localStorage.getItem("email"),
      password: localStorage.getItem("password"),
      type: "INBOX",
    };
    setCreadentials(tempCreadentials);

    store.dispatch(emailServerCredentials(tempCreadentials));

    MailBox(tempCreadentials, mailType, setMail, 1);
  }, [refresh]);

  useDidMountEffect(() => {
    localStorage.setItem("type", mailType);
  }, [mailType]);

  useEffect(() => {
    store.dispatch(employeesGetAction());
  }, []);

  return (
    <DashboardLayout>
      <div id="main" className="layout-column flex emailMainView">
        <div id="content" className="flex">
          <div className="d-flex flex fixed-content mt-3">
            <EmailSideNav
              setMail={setMail}
              unRead={mail.unread}
              creadentials={creadentials}
              setType={setType}
              page={setPage}
              active={setactive}
              toggleRefresh={toggleRefresh}
            />
            <MainBodyOfMails
              page={page}
              empty={mail}
              mails={mail.body}
              mail={mailType}
              credentials={creadentials}
              setPost={setPost}
              setOther={setOther}
              setMail={setMail}
            >
              <Pagination
                // totalMails={mail.body}
                // crededentials={creadentials}
                setMail={setMail}
                mailtype={mailType}
                page={page}
                setPage={setPage}
                active={active}
                setactive={setactive}
              />
            </MainBodyOfMails>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
export default EmailBody;
