import React from "react";
import noData from "../../images/noData.png";

const NoSearchResults = (props) => {
  const { page } = props;
  return (
    <div className="d-flex  flex-column justify-content-center align-items-center">
      <div className="d-flex flex-column  align-items-center">
        <h3 className={"  mb-3 text-color"}>No results found</h3>
        <small className={" mb-2 text-color"}>
          {page == "email"
            ? "It seems there are no emails/matches."
            : ` It seems there are no matches. Please try a different
          ${
            page == "reschedule" ? "date" : "keyword/criteria"
          } or refresh the page`}
        </small>
      </div>
      <div>
        <img src={noData} alt="." style={{ height: "250px" }} />
      </div>
    </div>
  );
};

export default NoSearchResults;
