import { STORE_SOFTPHONE_WEBSOCKET_DATA } from "./types";

// export const storeWebSocketData = (data) => ({
//     type: STORE_SOFTPHONE_WEBSOCKET_DATA,
//     payload: data,
//   });

  export const storeWebSocketData = (data) => (dispatch) => {
    dispatch({
      type: STORE_SOFTPHONE_WEBSOCKET_DATA,
      payload: data,
    });
  };
  