import {
  SYSTEM_ERROR,
  REGISTER_NEW_CANDIDATE_INPROGRESS,
  CANDIDATE_EXISTS,
  CANDIDATE_REGISTRATION_SUCCESS,
  FETCH_TODAY_HEADHUNTER_CANDIDATES,
  FETCH_TODAY_HEADHUNTER_CANDIDATES_INPROGRESS,
} from "./types";
import Axios from "axios"; 
import {
  REGISTER_CANDIDATE,
} from "../../configs/api-config";

export const registerNewCandidate = (data) => (dispatch) => {
  // alert(localStorage.jwtToken);
  dispatch({
    type: REGISTER_NEW_CANDIDATE_INPROGRESS,
    payload: true,
  });

  Axios.post(REGISTER_CANDIDATE, { ...data, pCode: 1 })
    .then((res) => {
      // console.log(res);
      if (res.data.status) {
        dispatch({
          type: CANDIDATE_REGISTRATION_SUCCESS,
          payload: false,
        });
        dispatch({});
      } else {
        dispatch({
          type: SYSTEM_ERROR,
          payload: {},
        });
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 400) {
          // Already exists candidate
          dispatch({
            type: CANDIDATE_EXISTS,
            payload: {},
          });
        } else if (error.response.status === 401) {
          // Unauthorized call
          dispatch({
            type: SYSTEM_ERROR,
            payload: {},
          });
        } else {
          dispatch({
            type: SYSTEM_ERROR,
            payload: {},
          });
        }
      }
    });
};

export const fetchTodayHeadhuntCandidates = () => (dispatch) => {
  // console.log("Inside action");
  dispatch({
    type: FETCH_TODAY_HEADHUNTER_CANDIDATES_INPROGRESS,
    payload: true,
  });

  // Axios.get(HEADHUNTER_GET_TODAY_CANDIDATES)
  //   .then((res) => {
  //     dispatch({
  //       type: FETCH_TODAY_HEADHUNTER_CANDIDATES,
  //       payload: res.data,
  //     });
  //   })
  //   .catch((error) => {
  //     if (error.response) {
  //       if (error.response.status === 401) {
  //         // Unauthorized call
  //         dispatch({
  //           type: SYSTEM_ERROR,
  //           payload: {},
  //         });
  //       } else {
  //         dispatch({
  //           type: SYSTEM_ERROR,
  //           payload: {},
  //         });
  //       }
  //     }
  //   });
};
