import React, { useEffect, useState } from "react";
import { Card, Popover, Button, PopoverHeader, PopoverBody } from "reactstrap";
import ScrollMenu from "react-horizontal-scrolling-menu";
import RoundIconButton from "../../../components/RoundIconButton/round-icon-button";
import {
  faChevronLeft,
  faChevronRight,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Axios from "axios";
import { set } from "lodash";
import { HOSTMexxar, HostMexxarURL } from "../../../configs/api-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import EmailNav from "../../../components/Modal/ModalBody/emailNav";

const HostMexxar = HOSTMexxar;
const MexxarUrl = HostMexxarURL;

const RoundAvatar = (props) => {
  const { user } = props;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const toggleM = () => {
    setPopoverOpen(false);
    setShowEmailModal(!showEmailModal);
  };

  return (
    <div
      onMouseEnter={() => setPopoverOpen(true)}
      onMouseLeave={() => setPopoverOpen(false)}
    >
      <Link key={user.id} to={`/dashboard/employee-profile/${user.id}`}>
        <span
          className="avatar w-48 ml-2 mt-1"
          style={{ margin: "-2px", border: "2px solid #fff" }}
          id={`avatar${user.id}`}
        >
          <img
            src={
              user.avatar === null ||
              user.avatar === undefined ||
              user.avatar === ""
                ? user.gender === "MALE"
                  ? MexxarUrl + "images/maleEmployee.png"
                  : MexxarUrl + "images/femaleEmployee.png"
                : user.avatar == null
                ? ""
                : `${HostMexxar}pro-pic/${user.avatar.split("/")[1]}`
            }
            alt=""
            style={{
              height: "2.7rem",
              width: "2.7rem",
              borderRadius: "50%",
              objectFit: "cover",
            }}

            //style={{ objectFit:"cover" }}
          />
        </span>
      </Link>
      <Popover
        placement="bottom"
        isOpen={popoverOpen}
        target={`avatar${user.id}`}
        onMouseEnter={() => setPopoverOpen(true)}
        style={{ width: "10rem" }}
      >
        <PopoverHeader>{`${user.firstName} ${user.lastName}`}</PopoverHeader>
        <PopoverBody>
          <div style={{ width: "100%" }}>
            <div
              className="d-flex row"
              style={{ marginLeft: "auto", marginRight: "auto" }}
            >
              <div
                className="col-sm-3"
                style={{ marginLeft: "12px", marginRight: "6px" }}
              >
                {/* <Link to={"/dashboard/call/" + user.id}> */}
                <RoundIconButton
                  buttonColor="#00C426"
                  icon={faPhone}
                  iconSize="fa-md"
                  buttonSize="25px"
                ></RoundIconButton>
              </div>

              <div
                className="col-sm-3"
                style={{ marginLeft: "6px", marginRight: "6px" }}
              >
                {/* </Link> */}
                {/* <span onClick={() => toggleM()}>
                  <RoundIconButton
                    buttonColor="#458AF7"
                    icon={faEnvelope}
                    iconSize="fa-md"
                    buttonSize="25px"
                  ></RoundIconButton>
                </span> */}
              </div>
            </div>
          </div>
        </PopoverBody>
      </Popover>
      <ModalComponent
        show={showEmailModal}
        header="E mail Modal"
        closeModal={toggleM}
      >
        <EmailNav closeModal={() => toggleM()} user={user}></EmailNav>
      </ModalComponent>
    </div>
  );
};
const Menu = (list) =>
  list.map((el, i) => {
    // const {name} = el;

    return <RoundAvatar user={el} key={i} />;
  });

const TeamMember = (props) => {
  const { key, dataGrid, style } = props;

  const [teamMembers, setTeamMembers] = useState([]);
  useEffect(() => {
    Axios.get(`${HostMexxar}employee/team-members`)
      .then((res) => {
        setTeamMembers(res.data.body);
      })
      .catch((e) => {});
  }, []);

  return (
    <Card {...props} style={{ ...style }}>
      <div style={{ padding: 10 }}>
        <p>Team Members</p>
        <div>
          <ScrollMenu
            dragging={true}
            alignCenter={false}
            data={teamMembers != undefined ? Menu(teamMembers) : null}
            arrowLeft={
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="fa-lg btn-raised btn-wave pointer "
                style={{
                  boxShadow: "none",
                }}
              ></FontAwesomeIcon>
            }
            arrowRight={
              <FontAwesomeIcon
                icon={faChevronRight}
                className="fa-lg btn-raised btn-wave pointer ml-2"
                style={{
                  boxShadow: "none",
                }}
              ></FontAwesomeIcon>
            }
          />
        </div>
      </div>
    </Card>
  );
};

export default TeamMember;
