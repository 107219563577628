import React, { useState, useEffect } from "react";
import "./working-card-widget.style.css";
import { Badge, Card, CardBody, Button } from "reactstrap";
import logo from "../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMars,
  faVenus,
  faPhone,
  faEnvelope,
  faCalendar,
  faPhoneSlash,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import ModalComponent from "../../components/Modal/modalComponent";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import {
  CANDIDATES_IN_REGISTRATION_STATUS,
  HOSTMexxar,
  HostMexxarURL,
} from "../../configs/api-config";
import ToastCommon from "../../components/toastCommon";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import {
  ApplicationStatus,
  GenderSwitch,
  ConversationResponse,
} from "../../util/common";
import {
  CandidateStatus,
  CandidateActiveStatus,
} from "../../util/common/candidateStatus";
import AvatarMexxar from "../../util/common/avatarMexxar";

const HeadHunterRegistrationStatusWidget = (props) => {
  const { userProfile } = props;
  const MexxarApi = CANDIDATES_IN_REGISTRATION_STATUS;
  const MexxarUrl = HostMexxarURL;

  const handleSelectedCandidate = (userProfile) =>
    setSelectedCandidate(userProfile);
  const [selectedCandidate, setSelectedCandidate] = useState({});

  const [calendarShow, setCalendarShow] = useState(false);
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [rows, setRows] = useState([]);
  const { addToast } = useToasts();
  const [profilePicture, setProfilePicture] = useState();
  const MexxarHostAPI = HOSTMexxar;

  const toggleShowEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };

  const toggleM = () => setShowEmailModal(!showEmailModal);

  return (
    <>
      <div
        className=" card d-flex flex-row main bg-light p-1 "
        style={{ minWidth: "900px", marginBottom: "5px" }}
      > 
        <AvatarMexxar
          candidateId={userProfile?.candidateId}
          primaryPhone={userProfile?.primaryPhone}
          avatar={userProfile?.proPic}
          gender={userProfile?.gender}
        />

        <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
          <div className="d-flex ml-1 mb-1">
            <div>
              {userProfile.candidateFirstName}&nbsp;
              {userProfile.candidateLastName}{" "}
            </div>
          </div>
          <div className=" d-flex ">
            <div className="mx-2 ">
              <Link to={"/dashboard/call/" + userProfile.candidateId}>
                <RoundIconButton
                  buttonColor="#00C426"
                  icon={faPhone}
                  iconSize="fa-md"
                  buttonSize="25px"
                ></RoundIconButton>
              </Link>
            </div>
            <div className="mx-2 ">
              <RoundIconButton
                buttonColor="#458AF7"
                icon={faEnvelope}
                click={() => {
                  toggleShowEmailModal();
                  handleSelectedCandidate(userProfile);
                }}
                buttonSize="25px"
              ></RoundIconButton>
            </div>
            <div className=" mx-2  d-flex align-items-center ">
              <FontAwesomeIcon
                color="#FF5603"
                icon={faCalendarAlt}
                onClick={() => handleCalendarShow(true)}
                className="fa-lg mb-2 btn-raised btn-wave "
              />
            </div>
          </div>
        </div>
        <div className="d-flex  align-items-center" style={{ flex: 1 }}>
          <FontAwesomeIcon
            // color={userProfile.gender == "1" ? "#458AF7" : "#f54394"}
            color={GenderSwitch(userProfile.gender).genderColor}
            // icon={userProfile.gender == "1" ? faMars : faVenus}
            icon={GenderSwitch(userProfile.gender).genderSymbol}
          />
        </div>

        <div
          className="d-flex align-items-center flex-column"
          style={{ flex: 3 }}
        >
          <div style={{ height: "3vh", flex: 1 }}>Calling Reponse</div>
          <div style={{ height: "3vh", flex: 1 }}>
            <Badge
              color={
                ConversationResponse(userProfile.candidateCallResponseStatus)
                  .badgeColor
              }
            >
              {
                ConversationResponse(userProfile.candidateCallResponseStatus)
                  .badgeLable
              }
            </Badge>
          </div>

          <div style={{ height: "3vh", flex: 1 }}></div>
        </div>

        <div
          className="d-flex align-items-center flex-column"
          style={{ flex: 3 }}
        >
          <div style={{ height: "4vh", flex: 1 }}>Work Flow Status</div>
          <div style={{ height: "3vh", flex: 1 }}>
            {/* <Badge
                  href="#"
                  color={(() => {
                    switch (userProfile.registrationCompletedStatus) {
                      case "processing":
                        return "warning";

                      case "completed":
                        return "success";

                      case "doment":
                        return "danger";

                      default:
                        return null;
                        break;
                    }
                  })()}
                >
                  {userProfile.registrationCompletedStatus}
                </Badge> */}
            <Badge
              style={{
                backgroundColor: CandidateStatus(userProfile.candidateWorkFlow)
                  .badgeColor,
              }}
            >
              {CandidateStatus(userProfile.candidateWorkFlow).badgeLable}
            </Badge>
            <Badge
              style={{
                backgroundColor: CandidateActiveStatus(
                  userProfile.candidateWorkFlow
                ).badgeColor,
              }}
            >
              {CandidateActiveStatus(userProfile.candidateWorkFlow).badgeLable}
            </Badge>
          </div>
          <div style={{ height: "3vh", flex: 1 }}></div>
        </div>
        <div style={{ flex: 1 }}>
          <div style={{ height: "4vh", flex: 1 }}></div>
          <div style={{ height: "3vh", flex: 1 }}></div>
          <div style={{ height: "3vh", flex: 1 }}></div>
        </div>
        <div
          className="d-flex align-items-center flex-column "
          style={{ flex: 4 }}
        >
          <div style={{ height: "4vh" }}>Last Contacted By</div>
          {userProfile.lastContactedUserName === null ? null : (
            <>
              <div style={{ height: "3vh" }}>
                {userProfile.lastContactedUserName}
              </div>

              <div className="d-flex justify-content-center ">
                <span className="mr-1">
                  <small className="text-muted">
                    {moment(userProfile.lastContactedDate).format(
                      "ddd MMM DD YYYY"
                    )}
                  </small>
                </span>
                <span className="mr-1">
                  <small className="text-muted">
                    {" "}
                    {moment(userProfile.lastContactedDate).format("hh:mm a")}
                  </small>
                </span>
              </div>
            </>
          )}
        </div>
        <div style={{ flex: 4 }}>
          <div
            className="d-flex justify-content-center mb-1 "
            style={{ maxHeight: "6vh" }}
          >
            Number of Calls
          </div>
          <div
            className=" d-flex justify-content-around "
            style={{ height: "3vh" }}
          >
            <div>
              <FontAwesomeIcon
                color="#00C426"
                icon={faPhone}
                className="fa-flip-horizontal"
              />
            </div>
            <div>
              <FontAwesomeIcon
                color="#F80055"
                icon={faPhoneSlash}
                className="fa-flip-horizontal"
              />
            </div>
          </div>
          <div
            className="d-flex justify-content-around"
            style={{ height: "3vh" }}
          >
            <div>{userProfile.answeredCount}</div>
            <div>{userProfile.notAnsweredCount}</div>
          </div>
        </div>
        <div
          className={`modal fade bd-example-modal-sm  ${
            calendarShow ? "show" : ""
          }`}
          id="exampleModalCenter"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          style={{ display: `${calendarShow ? "block" : "none"}` }}
          aria-hidden={!calendarShow}
        ></div>
      </div>
      <ModalComponent
        show={showEmailModal}
        header="E mail and sms"
        closeModal={() => toggleM()}
      >
        <EmailNav closeModal={() => toggleM()} user={selectedCandidate} />
      </ModalComponent>

      <ModalComponent
        show={calendarShow}
        header="Reschedule a call"
        closeModal={() => setCalendarShow(false)}
      >
        <SmallCalendar closeModal={() => setCalendarShow(false)} />
      </ModalComponent>
    </>
  );
};
export default HeadHunterRegistrationStatusWidget;
