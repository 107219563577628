import React, { useState, useEffect, useRef, useCallback } from "react";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import { connect } from "react-redux";
import { addFilteredData } from "../../../redux/actions/headhunterFilteredAction";
import { ClientLable, HostMexxarWS, JOB_BOARD_WORKSPACE } from "../../../configs/api-config";
import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
import NoData from "../../errorPages/NoData";
import { Client as StompClient } from "@stomp/stompjs";
import NoConnection from "../../errorPages/NoConnection";
import { faBriefcase, faUsers } from "@fortawesome/free-solid-svg-icons";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import SnapShotNav from "../../../components/Modal/ModalBody/snapShotNav";
import { forEach, filter, without, split, isNull, toLower } from "lodash";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import SortFunctionAscending from "../../../components/sortFunction/sortByDateAscending";
import JobBoardWidget from "../jobBoard-widget";
import { useSpring, animated } from "react-spring";
import { WorkspaceListLoader } from "../../../components/ContentLoaders/SVGLoaders";
import useWorkspaceFetchJobBoard from "../../../customHooks/useWorkspaceFetchJobBoard";
import {
  specialitiesGetAction,
  rolesGetAction,
  clientsGetAction,
  bandsGetAction,
} from "../../../redux/actions/initialDataGetAction";
import store from "../../../redux/store";
import useCandidateList from "../../../customHooks/useCandidateList";
import { FunnelIcon } from "../../../assets/icons/svg";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((element) => {
      let item = element;
      let searchableString = `${item.jobTitle} ${item.jobListingId}  `;
      let p = searchableString;
      return p.toLowerCase().includes(name.toLowerCase());
    });
  }
  let lengthofSearch = filtered.length;
  return { filtered, lengthofSearch };
};

const JobBoardWorkspace = (props) => {
  const {
    addFilteredData,
    updateTargets,
    employeeID,
    reduxSpecialities,
    reduxRoles,
    reduxClients,
    reduxBands,
  } = props;

  const [selectAll, setSelectAll] = useState(false);
  const [searchAdvanced, setsearchAdvanced] = useState(false);

  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [resetResults, setResetResults] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [showSnapshotModal, setShowSnapshotModal] = useState(false);

  const [speciality, setSpeciality] = useState([]);
  const [clients, setClients] = useState([]);
  const [role, setRoles] = useState([]);
  const [band, setBands] = useState([]);

  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  const [selectedSpecialities, setSelectedSpecialities] = useState({
    id: 0,
    value: "",
    label: "",
  });
  const [selectedRole, setSelectedRole] = useState({
    id: 0,
    value: "",
    label: "",
  });
  const [selectedBand, setSelectedBand] = useState({
    id: 0,
    value: "",
    label: "",
  });
  const [selectedClient, setSelectedClient] = useState({
    id: 0,
    value: "",
    label: "",
  });

  ///......filter settings options saving and retreving .......///
  const [remember, setRemember] = useState(true);

  //Perm Workspace GET hook
  let url =
    JOB_BOARD_WORKSPACE +
    "?clientId=" +
    selectedClient.id +
    "&specialityId=" +
    selectedSpecialities.id +
    "&roleId=" +
    selectedRole.id +
    "&bandId=" +
    selectedBand.id;

  // const { loadingPerm, errorPerm,rowsPerms, hasMore, totalElements } = useWorkspaceFetchJobBoard(
  //     urlPerm,
  //     searchQuery,
  //     pageNumber,
  //     searchAdvanced,
  //     resetResults
  //   );

  const { loading, error, rows, hasMore, totalElements } =
    useWorkspaceFetchJobBoard(
      url,
      filterCustom,
      query,
      pageNumber,
      searchAdvanced,
      resetResults
    );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  function handleSearch(e) {
    setQuery(e.target.value);
    setPageNumber(1);
  }
  useDidMountEffect(() => {
    setPageNumber(1);
  }, [searchAdvanced]);

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  const reset = () => {
    setResetResults(!resetResults);
    setSelectedSpecialities({ id: 0, value: "", label: "" });
    setSelectedRole({ id: 0, value: "", label: "" });
    setSelectedBand({ id: 0, value: "", label: "" });
    setSelectedClient({ id: 0, value: "", label: "" });
    setPageNumber(1);
    //  setRows(initialRows);
  };

  const refreshWorkflow = (data) => {
    if (data) {
      setResetResults(true);

      // getAllRows();
    }
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
      return false;
    } else {
      setSelectedItems([...selectedItems, rowId]);
      return true;
    }
  };

  useDidMountEffect(() => {
    let ids_Workspace = [];
    forEach(rows, function (item) {
      ids_Workspace.push({
        candidateId: item.candidateId,
        primaryPhone: item.primaryPhone,
      });
    });
    addFilteredData(ids_Workspace);
  }, [rows]);

  /****************websocket***********/
  useEffect(() => {
    //    getAllRows();
    updateTargets();

    let client = new StompClient();
    client.configure({
      brokerURL: HostMexxarWS + "wsc",
      onConnect: () => {
        //console.log("onConnect");
        client.subscribe(
          `/headhunter/${JSON.parse(localStorage.user).id}`,
          () => {
            setResetResults(true);

            //getAllRows();
          }
        );
      },
    });
    client.activate();
  }, []);

  const reArrangeToShowInSelectDropDown = (data) => {
    let reArranged = [];
    forEach(data, function (item) {
      reArranged.push({
        value: item.name,
        label: item.name + `${item?.type=="DATABASE" ? "-DB": ""}`,
        id: item.id,
      });
    });
    return reArranged;
  };

  useEffect(() => {
    if (reduxBands) {
      setBands(reArrangeToShowInSelectDropDown(reduxBands));
    }
  }, [reduxBands]);

  useEffect(() => {
    if (reduxRoles) {
      setRoles(reArrangeToShowInSelectDropDown(reduxRoles));
    }
  }, [reduxRoles]);

  useEffect(() => {
    if (reduxSpecialities) {
      setSpeciality(reArrangeToShowInSelectDropDown(reduxSpecialities));
    }
  }, [reduxSpecialities]);

  useEffect(() => {
    if (!reduxClients) {
      store.dispatch(clientsGetAction());
    }

    if (!reduxSpecialities) {
      store.dispatch(specialitiesGetAction());
    }
    if (!reduxRoles) {
      store.dispatch(rolesGetAction());
    }
    if (!reduxBands) {
      store.dispatch(bandsGetAction());
    }

    // setUrlPerm(JOB_BOARD)
    return () => {};
  }, []);

  useEffect(() => {
    if (reduxClients) {
      let reArranged = [];
      forEach(reduxClients, function (item) {
        reArranged.push({
          value: item.email,
          label: item.name,
          id: item.id,
        });
      });
      setClients(reArranged);
    }
  }, [reduxClients]);

  const sortFunctionAscending = (a, b) => {
    var dateA = new Date(a.createdDate).getTime();
    var dateB = new Date(b.createdDate).getTime();
    return dateA > dateB ? 1 : -1;
  };

  return (
    <>
      <div className="form-inline ">
        <FontAwesomeIcon icon={faBriefcase} className={"ml-2 mr-3  "} />
        <Label className={"mr-3"}>
          {totalElements}&nbsp;
          {totalElements.length == 1 ? "Job" : "Jobs"}
        </Label>

        <Label className={"mr-3"}></Label>

        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={handleSearch}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
        <UncontrolledTooltip target="searchBar">
          Search by Job Title/Job Id
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <p>{ClientLable} </p>

                <Select
                  // isMulti
                  value={selectedClient}
                  name="candidateSpecialityId"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => setSelectedClient(data)}
                  options={clients}
                />
              </Col>
              <Col>
                <p>Speciality </p>

                <Select
                  // isMulti
                  value={selectedSpecialities}
                  name="candidateSpecialityId"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => setSelectedSpecialities(data)}
                  options={speciality}
                />
              </Col>
              <Col>
                <p>Role </p>

                <Select
                  // isMulti
                  value={selectedRole}
                  name="candidateSpecialityId"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => setSelectedRole(data)}
                  options={role}
                />
              </Col>
              <Col>
                <p>Band </p>

                <Select
                  // isMulti
                  value={selectedBand}
                  name="candidateSpecialityId"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => setSelectedBand(data)}
                  options={band}
                />
              </Col>
              <Col>
                <div className="d-flex flex-column align-items-end">
                  <div>
                    <Button
                      color="success m-1 btn-sm"
                      style={{ width: "90px" }}
                      onClick={() => {
                        setsearchAdvanced(!searchAdvanced);
                      }}
                    >
                      Search
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="danger m-1 btn-sm"
                      style={{ width: "90px" }}
                      onClick={() => reset()}
                    >
                      Reset
                    </Button>
                  </div>
                  {/* <div className="mr-2">
                    <small>
                      <Input
                        type="checkbox"
                        value="REMEMBER"
                        checked={remember}
                        onChange={handleCheck}
                      ></Input>
                      Remember
                    </small>
                  </div> */}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>
      {rows &&
        rows.map((row, index) => {
          if (rows.length === index + 1) {
            return (
              <div ref={lastBookElementRef} key={row.email}>
                <JobBoardWidget
                  // key={i}
                  rowId={index}
                  job={row}
                  docStatus={row.candidatePermDocStatuses}
                  workflowStatusUpdated={(data) => refreshWorkflow(data)}
                  isSelected={(id) => isSelected(id)}
                  onItemSelect={(id) => onItemSelect(id)}
                  selectAll={selectAll}
                ></JobBoardWidget>
              </div>
            );
          } else {
            return (
              <div key={row.email} style={{ width: "100%", minWidth: "800px" }}>
                <JobBoardWidget
                  // key={i}
                  rowId={index}
                  job={row}
                  docStatus={row.candidatePermDocStatuses}
                  workflowStatusUpdated={(data) => refreshWorkflow(data)}
                  isSelected={(id) => isSelected(id)}
                  onItemSelect={(id) => onItemSelect(id)}
                  selectAll={selectAll}
                ></JobBoardWidget>
              </div>
            );
          }
        })}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      {!loading && error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}
      {rows.length === 0 && !loading && !error && <NoSearchResults />}
    </>
  );
};

function mapStateToProps(state) {
  return {
    headhunter: state.headhunter,
    heahunterFiltered: state.heahunterFiltered,
    auth: state.auth,
    reduxClients: state.initialDataGetReducer.clients,
    reduxSpecialities: state.initialDataGetReducer.specialities,
    reduxBands: state.initialDataGetReducer.bands,
    reduxRoles: state.initialDataGetReducer.roles,
  };
}

export default connect(mapStateToProps, {
  addFilteredData,
})(JobBoardWorkspace);
