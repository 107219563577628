import React from "react";

export const PermClientApplicationStatus = (data) => {
  let badgeColor;
  let badgeLable;
  switch (data) {
    case "PENDING":
      badgeLable = "pending";
      badgeColor = "warning";
      break;
    case "ACCEPTED":
      badgeLable = "accepted";
      badgeColor = "success";
      break;
    case "DECLINED":
      badgeLable = "declined";
      badgeColor = "danger";
      break;
  }

  return { badgeColor: badgeColor, badgeLable: badgeLable };
};
