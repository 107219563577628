import React, { useState, useEffect } from "react";

import { TRUSTS, HOSTMexxar, ClientLable } from "../../../configs/api-config";

import axios from "axios";
import Select from "react-select";
import { useForm } from "react-hook-form";
import _, { forEach } from "lodash";
import { Button, Label, Form, FormGroup, Col } from "reactstrap";
import useDidMountEffect from "./intialRunUseEffect";

export default function LikeTrustClientAddAdvance(props) {
  const { register, handleSubmit, errors } = useForm();

  const { onCreate, closeModal, candidateData } = props;

  const [trusts, setTrusts] = useState(null);
  const [selectedTrust, setSelectedTrust] = useState(null);
  const [trustClients, setTrustClients] = useState(null);
  const [selectedClients, setSelectedClients] = useState(null);

  const [loadingTrusts, setLoadingTrusts] = useState(false);
  const [loadingTrustsClients, setLoadingTrustsClients] = useState(false);

  const onsubmitAll = () => {
    //prepare preffred Trusts/Clients

    let trustsList = candidateData[0]?.preferredTrusts;
    // Check if the new object already exists in the array
    const existingTrust = candidateData[0]?.preferredTrusts.find(
      (item) => item.id === selectedTrust.id
    );

    if (!existingTrust) {
      // If not found, add the new object to the array
      trustsList.push(selectedTrust);
    }

    // Extract the ids from the updated array
    const resultTrustsIds = trustsList.map((item) => item.id);

    const prefferedClients = candidateData[0]?.preferredClients;

    // Iterate through the second list
    trustClients.forEach((trustClient) => {
      // Check if an item with the same name already exists in the first list
      const exists = prefferedClients.some(
        (prefferedClient) => prefferedClient.id === trustClient.id
      );

      // If the item doesn't exist, add it to the first list
      if (!exists) {
        prefferedClients.push(trustClient);
      }
    });

    // Extract the ids from the final array
    const clientIds = prefferedClients.map((item) => item.id);

    onCreate({
      trust: resultTrustsIds,
      clients: clientIds,
    });
    closeModal();
  };

  const onsubmit = () => {
    //prepare preffred Trusts/Clients

    let trustsList = candidateData[0]?.preferredTrusts;
    // Check if the new object already exists in the array
    const existingTrust = candidateData[0]?.preferredTrusts.find(
      (item) => item.id === selectedTrust.id
    );

    if (!existingTrust) {
      // If not found, add the new object to the array
      trustsList.push(selectedTrust);
    }

    // Extract the ids from the updated array
    const resultTrustsIds = trustsList?.map((item) => item.id);

    const prefferedClients = candidateData[0]?.preferredClients;

    // Iterate through the second list
    selectedClients?.forEach((selectedClient) => {
      // Check if an item with the same name already exists in the first list
      const exists = prefferedClients?.some(
        (prefferedClient) => prefferedClient.id === selectedClient.id
      );

      // If the item doesn't exist, add it to the first list
      if (!exists) {
        prefferedClients.push(selectedClient);
      }
    });

    // Extract the ids from the final array
    const clientIds = prefferedClients?.map((item) => item.id);

    onCreate({
      trust: resultTrustsIds,
      clients: clientIds,
    });
    closeModal();
  };

  const getTrusts = () => {
    setLoadingTrusts(true);
    let trustsArray = [];

    axios
      .get(TRUSTS)
      .then((res) => {
        setLoadingTrusts(false);

        forEach(res.data.body, function (item) {
          trustsArray.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });
        setTrusts(trustsArray);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getTrusts();
  }, []);

  useDidMountEffect(() => {
    setLoadingTrustsClients(true);

    let clientArray = [];
    axios
      .get(HOSTMexxar + "/clients/trust/" + selectedTrust.id)
      .then((res) => {
        setLoadingTrustsClients(false);

        forEach(res.data.body, function (item) {
          clientArray.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });
        setTrustClients(clientArray);
      })
      .catch((err) => {});
  }, [selectedTrust]);

  return (
    <Form handleSubmit={onsubmit}>
      <FormGroup row>
        <Label sm={3}>Preferred Trust</Label>
        <Col sm={8}>
          <Select
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                color: "#333333",
              }),
            }}
            // defaultValue={selectedClient}
            // isMulti
            name="prefferedTrusts"
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={(data) => setSelectedTrust(data)}
            options={trusts}
            isLoading={loadingTrusts}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={3}>Preferred {ClientLable}</Label>
        <Col sm={8}>
          <Select
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 9999,
                color: "#333333",
              }),
            }}
            value={selectedClients}
            isMulti
            name="prefferedClients"
            className="basic-multi-select"
            classNamePrefix="select"
            isLoading={loadingTrustsClients}
            onChange={(data) => setSelectedClients(data)}
            options={trustClients}
            innerRef={register({
              required: {
                value: true,
                message: "This field is required!",
              },
            })}
          />
        </Col>
      </FormGroup>
      <div className="d-flex justify-content-end">
        <Button className="mr-2" color="success" onClick={onsubmitAll}>
          Add All As Preferred
        </Button>
        <Button className="mr-2" color="success" onClick={onsubmit}>
          Add
        </Button>
        <Button color="secondary" onClick={() => closeModal()}>
          Close
        </Button>
      </div>
    </Form>
  );
}
