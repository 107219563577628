import React, { useEffect, useState } from "react";
import axios from "axios";
import FileDownload from "js-file-download";
import { Link } from "react-router-dom";
import { Progress } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { HostMexxarEmail } from "../../../../configs/api-config";

const DownloadAttachment = (props) => {
  const { attachments = [], body, mailtype, timestamp } = props;

  return (
    <>
      {attachments !== null && attachments.length !== 0 && (
        <div className="p-2 px-md-5 py-md-4">
          <h6 className="mb-2">Attached files:</h6>
          <AttachmentRender
            attachments={attachments}
            body={body}
            mailtype={mailtype}
            timestamp={timestamp}
          />
        </div>
      )}
    </>
  );
};

const AttachmentRender = ({ attachments = [], body, mailtype, timestamp }) => {
  const regex = new RegExp(/(?:\.([^.]+))?$/);
  const [count, setCount] = useState(-1);

  // useEffect(() => {
  //   document.getElementById("download");
  // }, []);

  return (
    <div>
      {attachments.map((item) => (
        <div className="d-flex flex-row">
          <Link
            to="#"
            /// to={"/email/" + mailtype + "/view/" + timestamp}
            onClick={() => Download(body, item, timestamp, setCount)}
            className="d-block mb-1"
            style={{ width: 200 }}
            key={item}
          >
            {/* <li className="badge badge-outline text-muted">
            {regex.exec(item.name.split("$#$")[0])[1].toUpperCase()}
          </li>
          <span> {item.name.split("$#$")[0]}</span>{" "}
          <small className="ml-2 text-muted">{item.name.split("$#$")[1]}</small> */}
            <div className="d-flex justify-content-center align-items-center p-1 m-1">
              {regex.exec(item.name.split("$#$")[0])[1] === "zip" ? (
                <>
                  <FontAwesomeIcon icon={faFileAlt} className="mx-2" />
                  <span> {item.name.split("$#$")[0]}</span>{" "}
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={faFileArchive} className="mx-2" />
                  <span> {item.name.split("$#$")[0]}</span>{" "}
                </>
              )}
            </div>
          </Link>
        </div>
      ))}
      {count === 0 && (
        <span id="download">
          <div className="text-center" color="warning">
            0%
          </div>
          <div className="text-center" color="warning">
            Please wait download initiated
          </div>

          <Progress color="warning" />
        </span>
      )}
      {count > 0 && (
        <span id="download">
          <div className="text-center" color="warning">
            {count}%
          </div>
          <div className="text-center" color="warning">
            Downloaded
          </div>

          <Progress color="warning" />
        </span>
      )}
      {count === 100 && <span id="download"></span>}
    </div>
  );
};

const Download = (request, filename, timestamp, setCount) => {
  setCount(0);
  const value = request;
  value.attachment = filename.name;
  value.timestamp = timestamp;
  let size = filename.size;

  axios({
    url: HostMexxarEmail + "email/download",
    data: value,
    method: "POST",
    responseType: "blob",
    onDownloadProgress: (progressEvent) => {
      let percentCompleted = Math.round(
        (progressEvent.loaded / (size * 1000)) * 100
      );
      setCount(percentCompleted);
    },
  }).then((response) => {
    FileDownload(response.data, filename.name);
    setCount(-1);
  });
};

export default DownloadAttachment;
