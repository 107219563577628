import React, { useEffect, useState } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import { Button, Label, Input, Form, Alert, Spinner } from "reactstrap";
import ToastCommon from "../../toastCommon";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import {
  HOSTMexxar,
  CommonEmailReplySignatureName,
  CommonEmailReplySignaturePosition,
  CommonEmailReplySignatureEmail,
  CompanyPhoneNumber,
  CompanyName,
  CompanyAddress,
  EmailSignatureFooter,
  CompanyWebsite,
} from "../../../configs/api-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { UncontrolledTooltip } from "reactstrap/lib";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    [
      "bold",
      "italic",
      "underline",
      "strike",
      "link",
      "image",
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "link",
  "image",
  "list",
  "bullet",
  "indent",
];

function SnapShot(props) {
  const {
    template,
    closeModal,
    selectedUser,
    templateBody,
    setTemplateContent,
  } = props;

  const { addToast } = useToasts();
  const { register, handleSubmit, errors } = useForm();

  const [body, setBody] = useState(templateBody);
  const [selectedBccEmail, setSelectedBccEmail] = useState();
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectedCCCandidates, setSelectedCCCandidates] = useState();
  const [removeDefaultSignature, setRemoveDefaultSignature] = useState(false);
  const [showDefaultSignature, setShowDefaultSignature] = useState(false);

  // New state variables
  const [isLoading, setIsLoading] = useState(false);

  const toggleShowSignature = () => {
    setShowDefaultSignature(!showDefaultSignature);
  };

  let sendingPersonal = {
    name: `${CommonEmailReplySignatureName} | ${CommonEmailReplySignaturePosition}`,
    email: { CommonEmailReplySignatureEmail },
    companyPhoneNumber: { CompanyPhoneNumber },
  };

  const signature = removeDefaultSignature
    ? `
    <div>
        <p style="margin: 0; line-height: 1.5;">${CompanyName} | ${CompanyAddress}</p>
        <p style="margin: 0; line-height: 1.5;"> ${CompanyWebsite}</p>
        <br/>
        <small style="text-align: justify;">         
        ${EmailSignatureFooter}
        </small>
    </div>
  `
    : `
  <div>
     <p style="margin: 0; line-height: 1.5;">Best regards,</p>
      <p style="margin: 0; line-height: 1.5;">${sendingPersonal?.name}</p>
      <p style="margin: 0; line-height: 1.5;">${CompanyName} | ${CompanyAddress}</p>
      <p style="margin: 0; line-height: 1.5;">${CompanyWebsite}</p>
      <p style="margin: 0; line-height: 1.5;">${sendingPersonal?.companyPhoneNumber?.CompanyPhoneNumber}</p>
      <br/>
      <small style="text-align: justify;">         
      ${EmailSignatureFooter}
      </small>
  </div>
`;

  const onSubmit = (data) => {
    setIsLoading(true); // Disable button and show spinner

    let emailList = [];
    if (selectedEmployees != null) {
      for (let i = 0; i < selectedEmployees.length; i++) {
        emailList.push(selectedEmployees[i].candidate);
      }
    }

    if (selectedCandidates != null) {
      for (let i = 0; i < selectedCandidates.length; i++) {
        emailList.push(selectedCandidates[i].candidate);
      }
    }

    let ccEmailList = [];
    if (selectedCCCandidates != null) {
      for (let i = 0; i < selectedCCCandidates.length; i++) {
        ccEmailList.push(selectedCCCandidates[i].candidate);
      }
    }

    let bccEmail = [];
    if (selectedBccEmail != null) {
      for (let i = 0; i < selectedBccEmail.length; i++) {
        bccEmail.push(selectedBccEmail[i].candidate);
      }
    }

    let emailBody = `
    <div>
      ${templateBody} 
      ${signature}
    </div>`;
    let email = {
      emailBody: emailBody,
      // from: props.user.email,
      to: emailList,
      cc: ccEmailList,
      bcc: bccEmail,
      subject: data.subject,
    };

    let url = HOSTMexxar + "email/send/ses";

    axios
      .post(url, email)
      .then((res) => {
        if (res.data.status === "success" || res.data.message === "created") {
          ToastCommon(true, "sent", addToast);
          closeModal();
        } else {
          ToastCommon(false, "error", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(false, "error", addToast);
      })
      .finally(() => {
        setIsLoading(false); // Re-enable button and hide spinner
      });
  };

  const checkSelectedUser = (user) => {
    let tempArray = [];
    for (let c = 0; c < user.length; c++) {
      tempArray.push({
        value: user[c].email,
        label: user[c].candidateFirstName + " " + user[c].candidateLastName,
        id: user[c].candidateId,
        candidate: user[c],
      });
    }
    setSelectedEmployees(tempArray);
  };

  useEffect(() => {
    checkSelectedUser(selectedUser);
    setBody(templateBody);
  }, []);

  const toggleDefaultSignature = () => {
    setRemoveDefaultSignature(!removeDefaultSignature);
  };

  return (
    <Form className="col-12" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row mt-3">
        <Label>Subject</Label>
        <Input
          type="text"
          name="subject"
          defaultValue={template}
          innerRef={register({ required: true, max: 35 })}
        />
      </div>
      <div className="form-row my-4">
        <ReactQuill
          value={templateBody}
          onChange={setTemplateContent}
          className="email-body-editor mb-4"
          style={{ width: "100%" }}
          modules={modules}
          formats={formats}
        />
      </div>

      <div className="form-row mt-4 ml-3">
        <Label>
          <Input
            className="pointer"
            type="checkbox"
            name="type-radio"
            value="RemoveDefaultSignature"
            checked={removeDefaultSignature}
            innerRef={register}
            onChange={(e) => toggleDefaultSignature(e)}
          />
          Remove default signature
        </Label>
        {!showDefaultSignature ? (
          <>
            <FontAwesomeIcon
              id="showDefaultSignature"
              className="mx-4 pointer btn-raised no-shadow"
              icon={faChevronDown}
              onClick={toggleShowSignature}
            ></FontAwesomeIcon>
            <UncontrolledTooltip target="showDefaultSignature">
              Show Default Signature
            </UncontrolledTooltip>
          </>
        ) : (
          <>
            <FontAwesomeIcon
              id="showDefaultSignature"
              className="mx-4 pointer  btn-raised no-shadow"
              icon={faChevronUp}
              onClick={toggleShowSignature}
            ></FontAwesomeIcon>
            <UncontrolledTooltip target="showDefaultSignature">
              Hide Default Signature
            </UncontrolledTooltip>
          </>
        )}
        {removeDefaultSignature && (
          <Alert color="warning">
            <small className="">
              This email will not use the default signature. Therefore, please
              be kind enough to add your email signature in the Body section of
              the email.
            </small>
          </Alert>
        )}
        {showDefaultSignature && (
          <Label className="mt-2">
            <small>Default Signature</small>
            <hr />
            <small>Best Regards,</small>
            <br />
            <small>{sendingPersonal?.name}</small>
            <br />
            <small>{CompanyAddress}</small>
            <br />
            <small>{CompanyWebsite}</small>
            <br />
            <small>{sendingPersonal?.companyPhoneNumber?.CompanyPhoneNumber}</small>
          </Label>
        )}
      </div>
      <div className="mt-4">
        <div className="d-flex justify-content-end">
        <Button color="success" type="submit" disabled={isLoading} className="mr-2">
            {isLoading ? (
              <>
                <Spinner size="sm" color="light" /> Sending...
              </>
            ) : (
              "Send"
            )}
          </Button>
          <Button color="secondary" onClick={closeModal} disabled={isLoading} >
            Cancel
          </Button>
        
        </div>
      </div>

    </Form>
  );
}

function mapStatetoProps(state) {
  return {
    user: state.auth.item,
  };
}

export default connect(mapStatetoProps, {})(SnapShot);
