export const CandidateStage = (workFlow) => {
  // const {workFlow } = props;
  let badgeLable;
  let badgeColor;
  switch (workFlow) {
    case "HEADHUNTER":
      badgeLable = "Headhunting";
      badgeColor = "#ffc107";
      break;
    case "REGISTRATION":
      badgeLable = "Registration";
      badgeColor = "#ff9800";
      break;
    case "AUDIT":
      badgeLable = "Audit";
      badgeColor = "#ffc107";
      break;
    case "MAINTENANCE":
      badgeLable = "Maintenance";
      badgeColor = "#4caf50";
      break;
    case "BOOKING":
      badgeLable = "Booking";
      badgeColor = "#03a9f4";
      break;
  }

  return { badgeColor: badgeColor, badgeLable: badgeLable };
};
