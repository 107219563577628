import React, { Component, useEffect, useState } from "react";
import { render } from "react-dom";
import {
  EditorState,
  convertToRaw,
  ContentState,
  Modifier,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { connect } from "react-redux";
import store from "../../../../redux/store";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "./editor.css";
import { Button, Spinner } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import useDidMountEffect from "../../../../components/Modal/ModalBody/intialRunUseEffect";
import DOMPurify from "dompurify";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../../authConfig";
const RichTextEditor = (props) => {
  const {
    composeType,
    emailBody,
    editorActive,
    sendingEmailBody,
    bodyTags,
    showHideFileUpload,
    sending,
  } = props;

  const [state, setState] = useState(null);
  const [done, setDone] = useState(false);
  const { instance, accounts } = useMsal();

  let contentType = emailBody?.contentType;

  const convertion = () => {
    const receivingBody =
      composeType != "NEW" && emailBody ? emailBody.content : "";

    // Check the content type
    if (contentType === "text") {
      const combinedBody = bodyTags + receivingBody; // Combine the two inputs

      // Render the content inside a <pre> tag
      const contentState = ContentState.createFromText(combinedBody);
      const editorStateWithContent =
        EditorState.createWithContent(contentState);
      setState(editorStateWithContent);
    } else {
      const contentBlock = convertFromHTML(receivingBody);

      if (contentBlock && contentBlock.contentBlocks.length > 0) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorStateTemp = EditorState.createWithContent(contentState);

        setState(editorStateTemp);
      } else {
        setState(EditorState.createEmpty());
      }
    }
    setDone(!done);
  };

  const onEditorStateChange = (editorState) => {
    setState(editorState);
    editorActive();
  };

  const prepareEmail = () => {
    if (state) {
      sendingEmailBody({
        body: draftToHtml(convertToRaw(state.getCurrentContent())),
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (emailBody && bodyTags) {
        convertion();
      }
    };

    fetchData();
  }, [emailBody, bodyTags]);

  const addPartsinside = () => {
    if (contentType !== "text") {
      const data = bodyTags;
      let { contentBlocks, entityMap } = htmlToDraft(data);
      let contentState = Modifier.replaceWithFragment(
        state?.getCurrentContent(),
        state?.getSelection(),
        ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        ).getBlockMap()
      );

      let temp2 = EditorState.push(state, contentState, "insert-fragment");
      setState(temp2);
    }
  };

  useDidMountEffect(() => {
    addPartsinside();
  }, [done]);

  return (
    <div className="editor ">
      <Editor
        className="text"
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        editorState={state}
        onEditorStateChange={onEditorStateChange}
        toolbarOnFocus
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "emoji",
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
        }}
      ></Editor>
      {/* <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        /> */}
      <Button color="success" className="my-2 mr-2" onClick={prepareEmail}>
        {sending && (
          <Spinner
            animation="border"
            style={{
              height: "20px",
              width: "20px",
              marginRight: "5px",
            }}
          />
        )}
        Send
      </Button>
      {/* <Button className="my-2 mr-2">
        <FontAwesomeIcon icon={faTrashAlt} className=" " />
      </Button> */}
      <Button className="my-2 mr-2" onClick={() => showHideFileUpload(true)}>
        <FontAwesomeIcon icon={faPaperclip} />
      </Button>
    </div>
  );
};

export default RichTextEditor;
