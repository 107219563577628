import React, { useEffect, useState } from "react";
import "./timeline.style.css";
import { PopupBox } from "../../../components";
import axios from "axios";
import { Badge } from "reactstrap";
import moment from "moment";

const Timeline = (props) => {
  const { candidate } = props;
  const [data, setData] = useState(null);

  // const getData = () => {
  //   let url;
  //   axios
  //     .get(url)
  //     .then((res) => {})
  //     .catch((error) => {});
  // };

  // useEffect(() => {
  //   getData();
  //   return () => {};
  // }, []);

  return (
    <>
      <div className="card p-4">
        <div className="timeline animates animates-fadeInUp">
          {/* 
          {.items(item,id)=>{
            return (
              <div className="tl-item">
            <div className="tl-dot"></div>
            <div className="tl-content">
              <div className="">
                <a href="#">{item.ActivityHeading}</a> {item.activityBody}
              </div>
              <div className="tl-date text-muted mt-1">{item.activityDate}</div>
            </div>
          </div>

            )
          }} */}
          {/* <div className="tl-item">
            <div className="tl-dot"></div>
            <div className="tl-content">
              <div className="">
                <a href="#">@IOSapp</a> Registred
              </div>
              <div className="tl-date text-muted mt-1">Today</div>
            </div>
          </div> */}
          {/* <div className="tl-item">
            <div className="tl-dot"></div>
            <div className="tl-content">
              <div className="">
                Phone App link sent <a href="#">@IOSapps</a>
              </div>
              <div className="tl-date text-muted mt-1">1 day ago</div>
            </div>
          </div> */}
          {/* <div className="tl-item">
            <div className="tl-dot"></div>
            <div className="tl-content">
              <div className="">
                DBS online setuped<a href="#"> </a>
              </div>
              <div className="tl-date text-muted mt-1">24 Aug 2020</div>
            </div>
          </div> */}

          <div className="tl-item">
            <div
              className={`tl-dot ${
                candidate?.bookingEligibleStatus === "ELIGIBLE"
                  ? "b-success"
                  : "b-warning"
              }`}
            ></div>{" "}
            <div className="tl-content ">
              <div className="">Profile created</div>

              <div className=" text-muted mt-1">
                {candidate?.bookingEligibleStatus === "ELIGIBLE" ? (
                  <Badge color="success">Eligible</Badge>
                ) : (
                  <Badge color="warning">Not Eligible</Badge>
                )}
              </div>
            </div>
          </div>
          <div className="tl-item">
            <div
              className={`tl-dot ${
                candidate?.lastContactedRef !== null ? "b-success" : "b-warning"
              }`}
            ></div>{" "}
            <div className="tl-content">
              <div className="">Last contacted date</div>

              <div className=" text-muted mt-1">
                {candidate?.lastContactedRef !== null ? (
                  <div className="d-flex justify-content-center text-sm">
                    <span className="mr-1">
                      <small className="text-muted">
                        {moment(candidate?.lastContactedRef).format(
                          "ddd MMM DD YYYY"
                        )}
                      </small>
                    </span>
                    <span className="mr-1">
                      <small className="text-muted">
                        @{" "}
                        {moment(candidate?.lastContactedRef).format("hh:mm a")}
                      </small>
                    </span>
                  </div>
                ) : (
                  <Badge color="warning">Not Contacted</Badge>
                )}
              </div>
            </div>
          </div>
          <div className="tl-item">
            <div
              className={`tl-dot ${
                candidate?.referenceFormSubmittedDate0 !== null
                  ? "b-success"
                  : "b-warning"
              }`}
            ></div>
            <div className="tl-content">
              <div className="">Refree 1 completed status</div>

              <div className=" text-muted mt-1">
                {candidate?.referenceFormSubmittedDate0 !== null ? (
                  <>
                    <div className="d-flex justify-content-center text-sm">
                      <span className="mr-1">
                        <small className="text-muted">
                          {moment(
                            candidate?.referenceFormSubmittedDate0
                          ).format("ddd MMM DD YYYY")}
                        </small>
                      </span>
                      <span className="mr-1">
                        <small className="text-muted">
                          @{" "}
                          {moment(
                            candidate?.referenceFormSubmittedDate0
                          ).format("hh:mm a")}
                        </small>
                      </span>
                    </div>
                    <Badge color="success">Completed</Badge>
                  </>
                ) : (
                  <Badge color="warning">Not Completed</Badge>
                )}
              </div>
            </div>
          </div>
          <div className="tl-item">
            <div
              className={`tl-dot ${
                candidate?.referenceFormSubmittedDate1 !== null
                  ? "b-success"
                  : "b-warning"
              }`}
            ></div>
            <div className="tl-content">
              <div className="">Refree 2 completed status</div>

              <div className=" text-muted mt-1">
                {candidate?.referenceFormSubmittedDate1 !== null ? (
                  <>
                    <div className="d-flex justify-content-center text-sm">
                      <span className="mr-1">
                        <small className="text-muted">
                          {moment(
                            candidate?.referenceFormSubmittedDate1
                          ).format("ddd MMM DD YYYY")}
                        </small>
                      </span>
                      <span className="mr-1">
                        <small className="text-muted">
                          @{" "}
                          {moment(
                            candidate?.referenceFormSubmittedDate1
                          ).format("hh:mm a")}
                        </small>
                      </span>
                    </div>
                    <Badge color="success">Completed</Badge>
                  </>
                ) : (
                  <Badge color="warning">Not Sent</Badge>
                )}
              </div>
            </div>
          </div>
          <div className="tl-item">
            <div
              className={`tl-dot ${
                candidate?.referenceFormSubmittedDate2 !== null
                  ? "b-success"
                  : "b-warning"
              }`}
            ></div>
            <div className="tl-content">
              <div className="">Refree 3 completed status</div>

              <div className=" text-muted mt-1">
                {candidate?.referenceFormSubmittedDate2 !== null ? (
                  <>
                    <div className="d-flex justify-content-center text-sm">
                      <span className="mr-1">
                        <small className="text-muted">
                          {moment(
                            candidate?.referenceFormSubmittedDate1
                          ).format("ddd MMM DD YYYY")}
                        </small>
                      </span>
                      <span className="mr-1">
                        <small className="text-muted">
                          @{" "}
                          {moment(
                            candidate?.referenceFormSubmittedDate2
                          ).format("hh:mm a")}
                        </small>
                      </span>
                    </div>
                    <Badge color="success">Completed</Badge>
                  </>
                ) : (
                  <Badge color="warning">Not Completed</Badge>
                )}
              </div>
            </div>
          </div>
          <div className="tl-item">
            <div
              className={`tl-dot ${
                candidate?.referenceFormSendDate0 !== null
                  ? "b-info"
                  : "b-success"
              }`}
            ></div>
            <div className="tl-content">
              <div className="">Refree form 1 sent status</div>

              <div className=" text-muted mt-1">
                {candidate?.referenceFormSendDate0 !== null ? (
                  <div className="d-flex justify-content-center text-sm">
                    <span className="mr-1">
                      <small className="text-muted">
                        {moment(candidate?.referenceFormSendDate0).format(
                          "ddd MMM DD YYYY"
                        )}
                      </small>
                    </span>
                    <span className="mr-1">
                      <small className="text-muted">
                        @{" "}
                        {moment(candidate?.referenceFormSendDate0).format(
                          "hh:mm a"
                        )}
                      </small>
                    </span>
                  </div>
                ) : (
                  <Badge color="warning">Not Sent</Badge>
                )}
              </div>
            </div>
          </div>
          <div className="tl-item">
            <div
              className={`tl-dot ${
                candidate?.referenceFormSendDate1 !== null
                  ? "b-info"
                  : "b-success"
              }`}
            ></div>{" "}
            <div className="tl-content">
              <div className="">Refree form 2 sent status</div>

              <div className=" text-muted mt-1">
                {candidate?.referenceFormSendDate1 !== null ? (
                  <div className="d-flex justify-content-center text-sm">
                    <span className="mr-1">
                      <small className="text-muted">
                        {moment(candidate?.referenceFormSendDate1).format(
                          "ddd MMM DD YYYY"
                        )}
                      </small>
                    </span>
                    <span className="mr-1">
                      <small className="text-muted">
                        @{" "}
                        {moment(candidate?.referenceFormSendDate1).format(
                          "hh:mm a"
                        )}
                      </small>
                    </span>
                  </div>
                ) : (
                  <Badge color="warning">Not Sent</Badge>
                )}
              </div>
            </div>
          </div>
          <div className="tl-item">
            <div
              className={`tl-dot ${
                candidate?.referenceFormSendDate2 !== null
                  ? "b-info"
                  : "b-success"
              }`}
            ></div>{" "}
            <div className="tl-content">
              <div className="">Refree form 3 sent status</div>

              <div className=" text-muted mt-1">
                {candidate?.referenceFormSendDate2 !== null ? (
                  <div className="d-flex justify-content-center text-sm">
                    <span className="mr-1">
                      <small className="text-muted">
                        {moment(candidate?.referenceFormSendDate2).format(
                          "ddd MMM DD YYYY"
                        )}
                      </small>
                    </span>
                    <span className="mr-1">
                      <small className="text-muted">
                        @{" "}
                        {moment(candidate?.referenceFormSendDate2).format(
                          "hh:mm a"
                        )}
                      </small>
                    </span>
                  </div>
                ) : (
                  <Badge color="warning">Not Sent</Badge>
                )}
              </div>
            </div>
          </div>
          <div className="tl-item">
            <div
              className={`tl-dot ${
                candidate?.profileCreated ? "b-success" : "b-warning"
              }`}
            ></div>{" "}
            <div className="tl-content ">
              <div className="">Profile created</div>

              <div className=" text-muted mt-1">
                {candidate?.profileCreated ? (
                  <Badge color="success">Completed</Badge>
                ) : (
                  <Badge color="warning">Not Created</Badge>
                )}
              </div>
            </div>
          </div>
          <div className="tl-item">
            <div
              className={`tl-dot ${
                candidate?.appPackCompletedDate !== null
                  ? "b-success"
                  : "b-warning"
              }`}
            ></div>{" "}
            <div className="tl-content ">
              <div className="">App pack completed status</div>

              <div className=" text-muted mt-1">
                {candidate?.appPackCompletedDate !== null ? (
                  <>
                    {" "}
                    <div className="d-flex justify-content-center text-sm">
                      <span className="mr-1">
                        <small className="text-muted">
                          {moment(candidate?.appPackCompletedDate).format(
                            "ddd MMM DD YYYY"
                          )}
                        </small>
                      </span>
                      <span className="mr-1">
                        <small className="text-muted">
                          @{" "}
                          {moment(candidate?.appPackCompletedDate).format(
                            "hh:mm a"
                          )}
                        </small>
                      </span>
                    </div>
                    <Badge color="success">Completed</Badge>
                  </>
                ) : (
                  <Badge color="warning">Not Completed</Badge>
                )}
              </div>
            </div>
          </div>
          <div className="tl-item">
            <div
              className={`tl-dot ${
                candidate?.appPackSentDate !== null ? "b-info" : "b-success"
              }`}
            ></div>{" "}
            <div className="tl-content">
              <div className="">App pack sent status</div>

              <div className=" text-muted mt-1">
                {candidate?.appPackSentDate !== null ? (
                  <div className="d-flex justify-content-center text-sm">
                    <span className="mr-1">
                      <small className="text-muted">
                        {moment(candidate?.appPackSentDate).format(
                          "ddd MMM DD YYYY"
                        )}
                      </small>
                    </span>
                    <span className="mr-1">
                      <small className="text-muted">
                        @ {moment(candidate?.appPackSentDate).format("hh:mm a")}
                      </small>
                    </span>
                  </div>
                ) : (
                  <Badge color="warning">Not Sent</Badge>
                )}
              </div>
            </div>
          </div>
          {/* <div className="tl-item">
            <div className="tl-dot"></div>
            <div className="tl-content">
              <div className="">
                Register as a new candidate
                <a href="#">@IOSapps</a>
              </div>
              <div className="tl-date text-muted mt-1">01 July 2020</div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Timeline;
