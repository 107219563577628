import {
  GET_EMPLOYEES,
  GET_SPECIALITIES,
  GET_CLIENTS,
  GET_DEPARTMENTS,
  GET_ROLES,
  GET_BANDS,
  GET_COUNTIES,
  GET_ADDRESS_WARDS,
  GET_DISTRICTS,
  GET_CV_SOURCES,
} from "../actions/types";

const initialState = {
  employees: null,
  specialities: null,
  clients: null,
  departments: null,
  roles: null,
  bands: null,
  addressWards: null,
  counties: null,
  districts: null,
  cvSources: null,
 
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EMPLOYEES:
      console.log(action.payload)
      return {
        ...state,
        employees: action.payload,
      };
    case GET_SPECIALITIES:
      return {
        ...state,
        specialities: action.payload,
      };
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
      };
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };
    case GET_ROLES:
      return {
        ...state,
        roles: action.payload,
      };
    case GET_BANDS:
      return {
        ...state,
        bands: action.payload,
      };
    case GET_COUNTIES:
      return {
        ...state,
        counties: action.payload,
      };
    case GET_ADDRESS_WARDS:
      return {
        ...state,
        addressWards: action.payload,
      };
    case GET_DISTRICTS:
      return {
        ...state,
        districts: action.payload,
      };
    case GET_CV_SOURCES:
      return {
        ...state,
        cvSources: action.payload,
      };

    default:
      return state;
  }
}
