import React, { useEffect, useState } from "react";
import ChatDataService from "../services/chat.service";
import { connect } from "react-redux";
import "./support/ncode.css";
import _, { isNull, last, toLower } from "lodash";
import { Input } from "reactstrap";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
//import {} from "../../../configs/api-config";
import Avatar from "react-avatar";
import { useRef } from "react";
import moment from "moment";
import NoSearchResults from "../../errorPages/NoSearchResults";

const ChatUsers = (props) => {
  const { chatPostLoad } = props;

  const [chatarray, setChatarray] = useState([]);
  const [activeChat, setActiveChat] = useState(0);
  const [searchQuery, setSarchQuery] = useState("");
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [currentTutorial, setCurrentTutorial] = useState(null);
  const [chatTargetScript, setChatTargetScript] = useState("");
  const [chatTargetIdState, setChatTargetIdState] = useState(0);

  // this will make an uniqu id for each chat script you chat..

  const chatScriptIdCreator = (user1, user2, chatTargetId) => {
    if (props.userId != user1) {
      chatTargetId = user1;
    } else {
      chatTargetId = user2;
    }
    setActiveChat(chatTargetId);

    //dispatch to activeChatId
    //props.activeChatId(state.activeChat);
    props.activeChatId(chatTargetId);

    if (props.userId < chatTargetId) {
      setChatTargetScript(props.userId + "_" + chatTargetId);
      // console.log("Inside if");
      // props.changeChat(chatTargetScript);
    } else {
      setChatTargetScript(chatTargetId + "_" + props.userId);
      //console.log("Inside else");
      props.changeChat(chatTargetScript);
    }
  };

  const onDataChange = (items) => {
    let secondaryChatArray = [];

    // console.log(props.reduxChatScriptId)
    items.forEach((item) => {
      let key = item.key;
      let data = item.val();
      secondaryChatArray.push({
        key: key,
        id: data.id,
        name: data.name,
        image: data.imgUrl,
        lastMessage: data.lastMessage,
        isOnline: data.isOnline,
        isActive: data.isActive,
      });
    });

    setChatarray(secondaryChatArray);
  };

  // const refreshList=()=> {
  //   setCurrentTutorial(null);
  //   setCurrentIndex(-1);
  // }

  // const setActiveTutorial=(chatmsg, index)=> {
  //   setCurrentTutorial(chatmsg);
  //   setCurrentIndex(index);
  // }

  // const removeAllTutorials=()=> {
  //   ChatDataService.deleteAll()
  //     .then(() => {
  //       refreshList();
  //     })
  //     .catch((e) => {
  //       // console.log(e);
  //     });
  // }

  const handleSearchOnChange = (e) => {
    setSarchQuery(e.target.value);
  };

  const filterByName = (name, dataSet) => {
    let filtered = dataSet;
    if (name !== "") {
      filtered = dataSet.filter((item) => {
        let searchableString = `${item.user1sFirstName} ${item.user1sLastName} ${item.user2sFirstName} ${item.user2sLastName}`;

        let p = toLower(searchableString);
        return p.includes(name);
      });
    }
    let lengthofSearch = filtered.length;
    return { filtered, lengthofSearch };
  };

  useDidMountEffect(() => {
    chatScriptIdCreator(chatPostLoad[0].user1sId, chatPostLoad[0].user2sId, 0);
  }, [props.chatPostLoad]);

  useEffect(() => {
    ChatDataService.getAllUsers();

    if (props.chatPostLoad.length > 0 || props.chatPostLoad == !undefined) {
      chatScriptIdCreator(
        props.chatPostLoad[0].user1sId,
        props.chatPostLoad[0].user2sId,
        0
      );
    }
  }, []);

  const timeFromNow = (data) => {
    let dateTime = _.last(data).date;
    let lastMexxageDate = moment(dateTime);
    let today = moment(new Date());
    let fromNow;

    let diffDays;

    if (dateTime == null || dateTime === undefined) {
      diffDays = null;
    } else {
      diffDays = Math.floor((today - lastMexxageDate) / 86400e3);
    }

    switch (true) {
      case 0 == diffDays:
        fromNow = moment(dateTime).format("LT");

        break;

      case 0 < diffDays && diffDays <= 7:
        fromNow = moment(dateTime).format("dddd");

        break;

      case 7 < diffDays:
        fromNow = moment(dateTime).format("L");

        break;

      default:
        break;
    }
    return fromNow;
  };

  return (
    <div>
      <div className="navbar">
        <div className="input-group flex bg-light rounded b-t-accent">
          <Input
            onChange={(e) => handleSearchOnChange(e)}
            type="text"
            className="form-control no-border no-shadow no-bg typeahead tt-input "
            placeholder="Search Chats"
            autoComplete="off"
            spellCheck="false"
            dir="auto"
            style={{
              position: "relative",
              verticalAlign: "top",
              backgroundColor: "transparent",
            }}
          />
          <span className="input-group-append">
            <button className="btn no-bg no-shadow" type="button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-search text-fade"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </button>
          </span>
        </div>
      </div>
      <div style={{ height: "252px", overflowY: "auto" }}>
        {chatPostLoad !== undefined &&
        filterByName(searchQuery, chatPostLoad).lengthofSearch > 0 ? (
          filterByName(searchQuery, chatPostLoad)
            .filtered.sort(function compare(a, b) {
              var dateA = new Date(_.last(a.messages).date);
              var dateB = new Date(_.last(b.messages).date);
              return dateB - dateA;
            })
            .map(
              (chatmsg, index) =>
                chatmsg.user1sId != chatmsg.user2sId && (
                  <div key={index}>
                    <div
                      className={`list-item  chatBoxes pointer ${
                        activeChat == chatmsg.user2sId && "card my-0"
                      }`}
                      onClick={() =>
                        chatScriptIdCreator(
                          chatmsg.user1sId,
                          chatmsg.user2sId,
                          0
                        )
                      }
                    >
                      {/* <div>
                        <span
                          className={`avatar-status ${
                            chatmsg.isOnline ? "on" : "off"
                          }`}
                        ></span>
                      </div> */}
                      <div>
                        <Avatar
                          name={chatmsg.user2sFirstName}
                          size="40"
                          round={true}
                          textSizeRatio={1.75}
                        />

                        {/* <span className="w-40 avatar gd-info">
                    <img
                      src={
                        chatmsg.proPic == null
                          ? "/images/maleCandidate.png"
                          : chatmsg.proPic
                      }
                      alt={`${chatmsg.user1sLastName} Pic`}
                    />
                   </span> */}
                      </div>
                      <div
                        className="d-flex flex-column"
                        // style={
                        //   props.theme==="bg-light" ? { color: "#fff" } : { color: "#192039" }
                        // }
                        style={{
                          width: "80%",
                          height: "100%",
                        }}
                      >
                        <div
                          className={`item-author d-flex justify-content-between ${
                            chatmsg.isActive ? "text-white" : "text-color"
                          }`}
                        >
                          <div className="text-truncate">
                            {props.userId != chatmsg.user1sId
                              ? `${chatmsg.user1sFirstName} ${chatmsg.user1sLastName}`
                              : `${chatmsg.user2sFirstName} ${chatmsg.user2sLastName}`}
                          </div>

                          <div
                            className={`item-except ${
                              chatmsg.isActive ? "text-muted" : "text-color"
                            } text-sm h-1`}
                          >
                            {timeFromNow(chatmsg.messages)}
                          </div>
                        </div>

                        <div
                          className={`item-except text-truncate ${
                            chatmsg.isActive ? "text-color" : "text-muted"
                          } text-sm h-1`}
                        >
                          {_.last(chatmsg.messages).message}
                        </div>
                      </div>
                    </div>

                    {activeChat !== chatmsg.user2sId && (
                      <hr
                        style={{
                          borderTop: "1px solid #5e676f",
                          margin: "0px 10px 0px 80px",
                        }}
                      />
                    )}
                  </div>
                )
            )
        ) : (
          <div className="d-flex justify-content-center ">
            No matching results
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.auth.item.id,
    theme: state.theme.theme,
    chatPostLoad: state.chatDbPostReducer.chatDbPostLoad,
    reduxChatScriptId: state.ChatRedux.chatfunkyId,
  };
}

function mapDispactchToProps(dispatch) {
  return {
    changeChat: (chatId) => {
      dispatch({ type: "chatScriptSelection", chatId: chatId });
    },
    activeChatId: (chatActiveDispatch) => {
      dispatch({
        type: "chatActiveSelection",
        chatActiveDispatch: chatActiveDispatch,
      });
    },
  };
}

export default connect(mapStateToProps, mapDispactchToProps)(ChatUsers);
