import React, { useEffect, useState } from "react";
import {
  EMPLOYEE_PERMISSIONS,
  EMPLOYEE_TEAMS,
  HOSTMexxar,
} from "../../configs/api-config";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { Bar } from "react-chartjs-2";
import { Label, Button, ButtonGroup, Col, Row, Input } from "reactstrap";
import { forEach, uniqWith, isEqual, filter, isNull, sumBy } from "lodash";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";

const DailyData = {
  labels: [
    "Janet",
    "Leeny",
    "Tom",
    "Matt",
    "Natasha",
    "James",
    "Janet",
    "Leeny",
    "Tom",
    "Matt",
    "Natasha",
    "James",
    "Janet",
    "Leeny",
    "Tom",
    "Matt",
    "Natasha",
    "James",
  ],
  datasets: [
    {
      label: "No of Clearence",
      data: [2, 1, 3, 4, 2, 4, 1, 0, 2, 5, 2, 3, 9, 8, 2, 1, 2, 3],
      backgroundColor: [
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
        "rgba(255, 99, 132, 0.2)",
      ],
      borderColor: [
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
        "rgba(255, 99, 132, 1)",
      ],
      borderWidth: 1,
    },
  ],
};

const VerticalBar = (props) => {
  const MexxarApi = HOSTMexxar;
  const MexxarTeams = EMPLOYEE_TEAMS;
  const MexxarTeamMembers = EMPLOYEE_PERMISSIONS;

  const datatype = props;

  const [team, setTeam] = useState(null);
  const [dateTo, setDateTo] = useState();
  const [range, setRange] = useState(false);
  const [dateFrom, setDateFrom] = useState();
  const [dataList, setDataList] = useState([]);
  const [rangeType, setRangeType] = useState("YEARLY");
  const [noOfbookings, setNoOfBookings] = useState([]);
  const [noOfCallLogs, setNoOfCallLogs] = useState([]);
  const [teamMembers, setTeamMembers] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [noOfClearances, setNoOfClearances] = useState([]);
  const [dummpyDataBooking, setDummpyDataBooking] = useState();
  const [dummpyDataClearance, setDummpyDataClearance] = useState();
  const [dummpyDataCalls, setDummpyDataCalls] = useState();

  const yearData = [
    String(Number(currentYear) - 9),
    String(Number(currentYear) - 8),
    String(Number(currentYear) - 7),
    String(Number(currentYear) - 6),
    String(Number(currentYear) - 5),
    String(Number(currentYear) - 4),
    String(Number(currentYear) - 3),
    String(Number(currentYear) - 2),
    String(Number(currentYear) - 1),
    String(Number(currentYear)),
  ];

  const monthData = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const weekData = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  // data: noOfbookings,

  // data: noOfCallLogs,

  // data: noOfClearances,

  const data = {
    labels: dataList,
    datasets: [
      {
        label: "No of Bookings",
        // data: dummpyDataBooking,  //use this for dummy data
        data:noOfbookings,
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
      {
        label: "No of Clearances",
        // data: dummpyDataClearance, //use this for dummy data
        data:noOfClearances,
        backgroundColor: "rgb(54, 162, 235)",
        borderColor: "rgba(54, 162, 235, 0.2)",
        // yAxisID: 'y-axis-2',
      },
      {
        label: "No of Calls",
        // data: dummpyDataCalls,     //use this for dummy data   
        data:noOfCallLogs,
        backgroundColor: "rgb(244, 196, 20)",
        borderColor: "rgba(244, 196, 20, 0.2)",
        // yAxisID: 'y-axis-2',
      },
    ],
  };

  //*--------------dummy data---------- *//
  // console.log(
  //   "bookings---------->",
  //   dummpyDataBooking,
  //   rangeType,
  //   selectedTeam
  // );
  useEffect(() => {
    if (selectedTeam) {
      if (selectedTeam.id === 1) {
        if (rangeType == "MONTHLY") {
          setDummpyDataBooking([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
          setDummpyDataClearance([23, 48, 88, 63, 56, 65, 69, 63, 56, 32]);
          setDummpyDataCalls([
            1330, 1265, 1349, 1400, 1450, 1363, 1290, 1259, 1360, 1250,
          ]);
        } else if (rangeType == "YEARLY") {
          setDummpyDataBooking([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
          setDummpyDataClearance([
            223, 325, 148, 260, 351, 133, 130, 188, 275, 263, 256, 260,
          ]);
          setDummpyDataCalls([
            14445, 13118, 15248, 14665, 14349, 13235, 16536, 15360, 13579,
            16566, 13160, 16590,
          ]);
        } else if (rangeType == "WEEKLY") {
          setDummpyDataBooking([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
          setDummpyDataClearance([23, 48, 30, 23, 26, 35, 29, 33, 36, 23]);
          setDummpyDataCalls([
            330, 265, 349, 400, 450, 363, 290, 259, 360, 250,
          ]);
        } else if (rangeType == "DAILY") {
          setDummpyDataBooking([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
          setDummpyDataClearance([3, 5, 4, 5, 3, 5, 7, 8, 5, 2]);
          setDummpyDataCalls([45, 18, 48, 65, 35, 36, 79, 66, 60, 90]);
        }
      } else if (selectedTeam.id === 2) {
        if (rangeType == "MONTHLY") {
          setDummpyDataBooking([0, 0]);
          setDummpyDataClearance([23, 48]);
          setDummpyDataCalls([1218, 1365]);
        } else if (rangeType == "YEARLY") {
          setDummpyDataBooking([0, 0]);
          setDummpyDataClearance([123, 125]);
          setDummpyDataCalls([11225, 11318]);
        } else if (rangeType == "WEEKLY") {
          setDummpyDataBooking([0, 0]);
          setDummpyDataClearance([23, 32]);
          setDummpyDataCalls([230, 318]);
        } else if (rangeType == "DAILY") {
          setDummpyDataBooking([0, 0]);
          setDummpyDataClearance([2, 5]);
          setDummpyDataCalls([25, 18]);
        }
      } else if (selectedTeam.id === 3) {
        if (rangeType == "MONTHLY") {
          setDummpyDataBooking([0, 0, 0]);
          setDummpyDataClearance([23, 48, 69]);
          setDummpyDataCalls([198, 165, 190]);
        } else if (rangeType == "YEARLY") {
          setDummpyDataBooking([0, 0, 0]);
          setDummpyDataClearance([2225, 1556, 1460]);
          setDummpyDataCalls([545, 618, 490]);
        } else if (rangeType == "WEEKLY") {
          setDummpyDataBooking([0, 0, 0]);
          setDummpyDataClearance([33, 88, 75]);
          setDummpyDataCalls([45, 18, 48]);
        } else if (rangeType == "DAILY") {
          setDummpyDataBooking([0, 0, 0]);
          setDummpyDataClearance([20, 30, 12]);
          setDummpyDataCalls([2, 5, 4, 5]);
        }
      } else if (selectedTeam.id === 4) {
        if (rangeType == "MONTHLY") {
          setDummpyDataBooking([0, 0, 0]);
          setDummpyDataClearance([125, 156, 169]);
          setDummpyDataCalls([118, 163, 190]);
        } else if (rangeType == "YEARLY") {
          setDummpyDataBooking([0, 0, 0]);
          setDummpyDataClearance([1123, 2525, 2533]);
          setDummpyDataCalls([12500, 12550, 12090]);
        } else if (rangeType == "WEEKLY") {
          setDummpyDataBooking([0, 0, 0]);
          setDummpyDataClearance([15, 20, 25]);
          setDummpyDataCalls([245, 266, 290]);
        } else if (rangeType == "DAILY") {
          setDummpyDataBooking([0, 0, 0]);
          setDummpyDataClearance([2, 2, , 3]);
          setDummpyDataCalls([45, 60, 50]);
        }
      } else if (selectedTeam.id === 5) {
        if (rangeType == "MONTHLY") {
          setDummpyDataBooking([523, 456, 669, 888]);
          setDummpyDataClearance([0, 0, 0, 0]);
          setDummpyDataCalls([18, 56, 90, 67]);
        } else if (rangeType == "YEARLY") {
          setDummpyDataBooking([10223, 8056, 9069, 10588]);
          setDummpyDataClearance([0, 0, 0, 0]);
          setDummpyDataCalls([12245, 12049, 13090, 13534]);
        } else if (rangeType == "WEEKLY") {
          setDummpyDataBooking([1233, 2044, 1256, 2589]);
          setDummpyDataClearance([0, 0, 0, 0]);
          setDummpyDataCalls([88, 55, 67, 88]);
        } else if (rangeType == "DAILY") {
          setDummpyDataBooking([133, 144, 156, 189]);
          setDummpyDataClearance([0, 0, 0, 0]);
          setDummpyDataCalls([88, 55, 67, 88]);
        }
      } else if (selectedTeam.id === 6) {
        if (rangeType == "MONTHLY") {
          setDummpyDataBooking([0, 0, 0]);
          setDummpyDataClearance([23, 56, 69]);
          setDummpyDataCalls([1118, 1163, 1290]);
        } else if (rangeType == "YEARLY") {
          setDummpyDataBooking([0, 0, 0]);
          setDummpyDataClearance([300, 356, 500]);
          setDummpyDataCalls([12545, 10049, 9090]);
        } else if (rangeType == "WEEKLY") {
          setDummpyDataBooking([0, 0, 0]);
          setDummpyDataClearance([10, 15, 20]);
          setDummpyDataCalls([345, 566, 590]);
        } else if (rangeType == "DAILY") {
          setDummpyDataBooking([0, 0, 0]);
          setDummpyDataClearance([3, 5, , 8]);
          setDummpyDataCalls([45, 60, 90]);
        }
      }
    }
  }, [rangeType, selectedTeam]);

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  var currentYear = moment().year();

  const getTeams = () => {
    axios
      .get(MexxarTeams)
      .then((res) => {
        let teamArray = [];

        forEach(res.data.body, function (item) {
          teamArray.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });

        setTeam(teamArray);
      })
      .catch((err) => {
        //console.log("cannot get specialities ",err)
        // toastCommon(true, "error");
      });
  };

  const getTeamMembers = () => {
    axios
      .get(MexxarTeamMembers + "/team-members/" + selectedTeam.id)
      .then((res) => {
        let teamMembersArray = [];

        if (res.data.status == "success") {
          forEach(res.data.body, function (item) {
            teamMembersArray.push(item.firstName + " " + item.lastName);
          });

          setTeamMembers(teamMembersArray);
          setDataList(teamMembersArray);
        }
      })
      .catch((err) => {
        //console.log("cannot get specialities ",err)
        // toastCommon(true, "error");
      });
  };

  const [startDateShowOnly, setStartDateShowOnly] = useState();
  const [endDateShowOnly, setEndDateShowOnly] = useState();

  const getAllRows = () => {
    //according to the userrole change the performance data in the chart
    //currently showing the looged in user's data

    // setStartAndEndDates();

    let startTempDate;
    let endTempDate;

    // if (rangeType == "DAILY") {
    //   startTempDate = moment(startDate).format("YYYY/MM/DD");
    // } else

    if (rangeType == "MONTHLY" && range == true) {
      startTempDate = moment(dateFrom).format("YYYY/MM/DD");

      endTempDate = moment(dateTo).format("YYYY/MM/DD");
    } else if (rangeType == "DAILY") {
      startTempDate = moment(dateFrom).format("YYYY/MM/DD");

      endTempDate = moment(dateFrom).format("YYYY/MM/DD");
    } else if (rangeType == "WEEKLY") {
      startTempDate = moment(moment().startOf("isoWeek").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(moment().endOf("isoWeek").toDate()).format(
        "YYYY/MM/DD"
      );
    } else if (rangeType == "MONTHLY") {
      startTempDate = moment(moment().startOf("month").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(moment().endOf("month").toDate()).format(
        "YYYY/MM/DD"
      );
    } else if (rangeType == "YEARLY") {
      startTempDate = moment(moment().startOf("year").toDate()).format(
        "YYYY/MM/DD"
      );

      endTempDate = moment(moment().endOf("year").toDate()).format(
        "YYYY/MM/DD"
      );
    }
    setStartDateShowOnly(startTempDate);
    setEndDateShowOnly(endTempDate);
    axios
      .get(
        MexxarApi +
          "charts-and-reports/team-performance-report-chart/team/" +
          selectedTeam.id +
          "?chartType=" +
          rangeType +
          "&start=" +
          startTempDate +
          "&end=" +
          moment(moment(endTempDate).add(1, "days")).format("YYYY/MM/DD")
      )
      .then((res) => {
        let datesTemp = [];
        let monthsTemp = [];
        let yearsTemp = [];

        for (
          let m = moment(startTempDate);
          m.isBefore(
            moment(moment(endTempDate).add(1, "days")).format("YYYY/MM/DD")
          );
          m.add(1, "days")
        ) {
          datesTemp.push(m.format("DD"));
          monthsTemp.push(m.format("MM"));
          yearsTemp.push(m.format("YYYY"));
        }

        let months = uniqWith(monthsTemp, isEqual);
        let years = uniqWith(yearsTemp, isEqual);

        setNoOfBookings(getIndividualResults(res.data.body[0].booking));
        setNoOfCallLogs(getIndividualResults(res.data.body[0].call_log));
        setNoOfClearances(getIndividualResults(res.data.body[0].clearance));
      })
      .catch((err) => {
        //console.log(err)
        // ToastCommon(true, "error", addToast);
      });
  };

  const getIndividualResults = (response) => {
    let arrangedDataTemp = [];
    let valuesTemp = [];
    forEach(teamMembers, function (member) {
      let A = filter(response, (item) => {
        let checkName = item.firstName + " " + item.lastName;

        return checkName === member;
      });

      let total = sumBy(A, function (item) {
        return item.value;
      });

      arrangedDataTemp.push({
        label: member,
        value: A.length > 0 ? total : 0,
      });

      valuesTemp.push(A.length > 0 ? total : 0);
    });
    return valuesTemp;
  };

  useDidMountEffect(() => {
    getAllRows();
  }, [teamMembers]);

  useDidMountEffect(() => {
    getAllRows();
  }, [dateFrom]);

  useDidMountEffect(() => {
    getAllRows();
  }, [dateTo]);

  useDidMountEffect(() => {
    getTeamMembers();
  }, [selectedTeam]);

  useDidMountEffect(() => {
    getTeamMembers();
  }, [rangeType]);

  useEffect(() => {
    getTeams();
  }, []);
  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#6A6767",
    }),
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="header">
          <Row>
            <Col>
              <Label>Team Performance </Label>
              <ButtonGroup
                className="ml-3"
                size="sm"
                style={{
                  zIndex: 0,
                }}
              >
                <Button
                  disabled={isNull(selectedTeam) ? true : false}
                  onClick={() => {
                    setRangeType("DAILY");
                    setRange(false);
                  }}
                  className={`${rangeType === "DAILY" && "active"}`}
                >
                  Daily
                </Button>
                <Button
                  disabled={isNull(selectedTeam) ? true : false}
                  onClick={() => {
                    setRangeType("WEEKLY");
                    setRange(false);
                  }}
                  className={`${rangeType === "WEEKLY" && "active"}`}
                >
                  Weekly
                </Button>
                <Button
                  disabled={isNull(selectedTeam) ? true : false}
                  onClick={() => {
                    setRangeType("MONTHLY");
                    setRange(false);
                  }}
                  className={`${rangeType === "MONTHLY" && "active"}`}
                >
                  Monthly
                </Button>
                <Button
                  disabled={isNull(selectedTeam) ? true : false}
                  onClick={() => {
                    setRangeType("YEARLY");
                    setRange(false);
                  }}
                  className={`${rangeType === "YEARLY" && "active"}`}
                >
                  Yearly
                </Button>
                <Button
                  disabled={isNull(selectedTeam) ? true : false}
                  onClick={() => {
                    setRangeType("MONTHLY");
                    setRange(true);
                  }}
                  className={`${rangeType === "MONTHLY" && "active"}`}
                >
                  Range
                </Button>

                {/* <Button >Yearly</Button> */}
              </ButtonGroup>
              {rangeType != "DAILY" && range == false ? (
                <>
                  <Label className="mx-3">From : {startDateShowOnly}</Label>
                  <Label className="mx-3">To : {endDateShowOnly} </Label>
                </>
              ) : null}
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6} xl={6}>
              <Label>Team </Label>
              {isNull(selectedTeam) ? (
                <Label className="mx-3">
                  * Please select a team to load the chart
                </Label>
              ) : null}

              <Select
                // isMulti
                name="candidateSpecialityId"
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(data) => setSelectedTeam(data)}
                options={team}
                styles={colourStyles}
              />
            </Col>
            {rangeType == "DAILY" || range == true ? (
              <Col>
                {rangeType == "DAILY" ? (
                  <Label for="exampleDate">Date</Label>
                ) : (
                  <Label for="exampleDate">Start Date</Label>
                )}

                <Input
                  type="date"
                  name="dateFrom"
                  id="dateFrom"
                  onChange={(e) => setDateFrom(e.target.value)}
                  placeholder=""
                  defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                />
              </Col>
            ) : null}

            {range == true ? (
              <Col>
                <Label for="exampleDate">End Date</Label>
                <Input
                  type="date"
                  name="dateTo"
                  id="dateTo"
                  onChange={(e) => setDateTo(e.target.value)}
                  placeholder=""
                  defaultValue={moment(new Date()).format("YYYY-MM-DD")}
                />
              </Col>
            ) : null}
          </Row>

          <div className="links"></div>
        </div>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default VerticalBar;
