import React from "react";

export const CandidateStatus = (data) => {
  let badgeColor;
  let badgeLable;
  switch (data) {
    case "HEADHUNTER":
      badgeLable = "Headhunting";
      badgeColor = "#ff9800";
      break;
    case "REGISTRATION":
      badgeLable = "Registration";
      badgeColor = "#ffc107";
      break;
    case "AUDIT":
      badgeLable = "Audit";
      badgeColor = "#8bc34a";
      break;
    case "MAINTENANCE":
      badgeLable = "Maintenance";
      badgeColor = "#31c971";
      break;
    case "BOOKING":
      badgeLable = "Booking";
      badgeColor = "teal";
      break;
  }

  return { badgeColor: badgeColor, badgeLable: badgeLable };
};

export const CandidateActiveStatus = (data) => {
  let badgeColor;
  let badgeLable;
  switch (data) {
    case "ACTIVE":
      badgeLable = "Active";
      badgeColor = "#4caf50";
      break;
    case "DORMANT":
      badgeLable = "Dormant";
      badgeColor = "#ffc107";
      break;
    case "DO_NOT_CALL":
      badgeLable = "DNT";
      badgeColor = "#8bc34a";
      break;
    case "INACTIVE":
      badgeLable = "Inactive";
      badgeColor = "#535c78";
      break;
  }

  return { badgeColor: badgeColor, badgeLable: badgeLable };
};
