import {
  faInbox,
  faArchive,
  faPaperPlane,
  faRadiation,
  faTrash,
  faHistory,
  faOutdent,
  faExclamationCircle,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";

export const OutlookSwitchIcon = (data) => {
  let outlookSymbol;
  let outlookColor;
  switch (data) {
    case "Archive":
      outlookSymbol = faArchive;
      outlookColor = "";
      break;
    case "Conversation History":
      outlookSymbol = faHistory;
      outlookColor = "";
      break;
    case "Deleted Items":
      outlookSymbol = faTrash;
      outlookColor = "";
      break;
    case "Drafts":
      outlookSymbol = faRadiation;
      outlookColor = "";
      break;
    case "Inbox":
      outlookSymbol = faInbox;
      outlookColor = "";
      break;
    case "Junk Email":
      outlookSymbol = faExclamationCircle;
      outlookColor = "";
      break;
    case "Outbox":
      outlookSymbol = faOutdent;
      outlookColor = "";
      break;
    case "Sent Items":
      outlookSymbol = faPaperPlane;
      outlookColor = "";
      break;
    default:
      outlookSymbol = faFolder; // Default icon
      outlookColor = ""; // Default color
      break;
  }

  return { outlookSymbol: outlookSymbol, outlookColor: outlookColor };
};
