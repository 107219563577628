import React, { useState, useEffect } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.style.css";

import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  Input,
  Button,
  Label,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import _, { omit, forEach } from "lodash";
import { connect } from "react-redux";
import { HOSTMexxar } from "../../configs/api-config";
import { Calendar, momentLocalizer } from "react-big-calendar";
import ModalComponent from "../../components/Modal/modalComponent";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import BookTrainNav from "../../components/Modal/ModalBody/bookTrainNav";
import EventPreviewModal from "../../components/Modal/EventPreviewModal";
import calendarEvent from "../../components/Modal/ModalBody/calendarEvent";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import BookingSummery from "../../components/Modal/ModalBody/bookingSummery";

const localizer = momentLocalizer(moment);
const MexxarApi = HOSTMexxar;
const CalendarCustom = (props) => {
  const [show, setShow] = useState(false);
  const [events, setEvents] = useState([]);
  const [eventsForAgendaView, setEventsForAgendaView] = useState([]);

  const [tempRange, setTempRange] = useState(null);
  const [showPopUp, setshowPopUp] = useState({
    showModal: false,
    events: null,
  });
  const [viewProps, setViewProps] = useState("month");
  const [selectedEvent, setSelectedEvent] = useState();
  const [eventPreview, setEventPreview] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);

  const [displayEvents, setDisplayEvents] = useState(true);
  const [setSelectedDateArray, selectedDateArray] = useState([]);
  const [eventDates, setEventDates] = useState({ start: "", end: "" });
  const [getStartDate, setGetStartDate] = useState(
    moment(new Date()).startOf("month").startOf("week").format("YYYY/MM/DD")
  );
  const [getEndDate, setGetEndDate] = useState(
    moment(new Date()).endOf("month").endOf("week").format("YYYY/MM/DD")
  );

  const getEvents = () => {
    let eventsArray = [];
    axios
      .get(
        MexxarApi +
          "events/range?userId=" +
          props.auth.item.userTypeId +
          "&start=" +
          moment(getStartDate).subtract(1, "d").format("YYYY/MM/DD") +
          "&end=" +
          moment(getEndDate).add(7, "d").format("YYYY/MM/DD") +
          "&type=" +
          props.auth.item.userType
      )
      .then((res) => {
        for (let i = 0; i < res.data.body.length; i++) {
          let startDate = moment(res.data.body[i].startDateTime).format(
            "YYYY-MM-DD"
          );
          let startTime = moment(res.data.body[i].startDateTime).format(
            "HH:mm:ss"
          );
          let endDate = moment(res.data.body[i].endDateTime).format(
            "YYYY-MM-DD"
          );
          let endTime = moment(res.data.body[i].endDateTime).format("HH:mm:ss");

          eventsArray.push({
            start: moment(startDate).add(startTime).toDate(),
            end: moment(endDate).add(endTime).toDate(),
            title: "Event: " + res.data.body[i].name,
            event: res.data.body[i],
            body: {
              EventName: res.data.body[i].name,
              EventStartDate: startDate,
              EventStartTime: startTime,
              EventEndDate: endDate,
              EventEndTime: endTime,
              recordedEmployeeName: res.data.body[i].recordedEmployeeName,
            },
          });
        }
        // this.setState({events:[...eventsArray, ...this.state.events]});

        axios
          .get(
            MexxarApi +
              "training-booking/range?userId=" +
              props.auth.item.userTypeId +
              "&start=" +
              moment(getStartDate).subtract(1, "d").format("YYYY/MM/DD") +
              "&end=" +
              moment(getEndDate).add(7, "d").format("YYYY/MM/DD") +
              "&type=" +
              props.auth.item.userType
          )
          .then((res) => {
            for (let i = 0; i < res.data.body.length; i++) {
              let startDate = moment(res.data.body[i].startDate).format(
                "YYYY-MM-DD"
              );
              let startTime = moment(res.data.body[i].startDate).format(
                "HH:mm:ss"
              );
              let endDate = moment(res.data.body[i].endDate).format(
                "YYYY-MM-DD"
              );
              let endTime = moment(res.data.body[i].endDate).format("HH:mm:ss");

              let title = "";
              if (
                res.data.body[i].trainings[0] != undefined ||
                res.data.body[i].trainings[0] != null
              ) {
                if (res.data.body[i].trainings.length > 1) {
                  title = "Multiple Trainings";
                } else {
                  title = res.data.body[i].trainings[0].name;
                }
              }

              let trainingList = [];
              for (let x = 0; x < res.data.body[i].trainings.length; x++) {
                trainingList.push(res.data.body[i].trainings[x].name);
              }

              // eventsArray = [];
              eventsArray.push({
                start: moment(startDate).add(startTime).toDate(),
                end: moment(endDate).add(endTime).toDate(),
                title: "Training: " + title,
                event: res.data.body[i],
                trainings: trainingList,
                body: {
                  EventStartDate: startDate,
                  EventStartTime: startTime,
                  EventEndDate: endDate,
                  EventEndTime: endTime,
                  recordedEmployeeName: res.data.body[i].recordedEmployeeName,
                },
              });
            }

            axios
              .get(
                MexxarApi +
                  "bookings/employee-booking?start=" +
                  moment(getStartDate).subtract(1, "d").format("YYYY/MM/DD") +
                  "&end=" +
                  moment(getEndDate).add(7, "d").format("YYYY/MM/DD")
              )
              .then((res) => {
                for (let i = 0; i < res.data.body.length; i++) {
                  // let bookingDate = moment(res.data.body[i].shiftTo).format('YYYY-MM-DD');
                  let startDate = moment(res.data.body[i].bookingDate).format(
                    "YYYY-MM-DD"
                  );
                  let startTime = moment(res.data.body[i].shiftFrom).format(
                    "HH:mm:ss"
                  );
                  // let endDate = moment(res.data.body[i].shiftTo).format('YYYY-MM-DD');
                  let endTime = moment(res.data.body[i].shiftTo).format(
                    "HH:mm:ss"
                  );

                  eventsArray.push({
                    start: moment(startDate).add(startTime).toDate(),
                    end: moment(startDate).add(endTime).toDate(),
                    // title: "Booking: " + res.data.body[i].referenceNo,
                    title: "Booking",
                    event: res.data.body[i],
                    body: {
                      EventStartDate: startDate,
                      EventStartTime: startTime,
                      EventEndDate: startDate,
                      EventEndTime: endTime,
                      recordedEmployeeName: res.data.body[i].employeeName,
                    },
                  });
                }
                setEvents([...eventsArray]);
                console.log("kkkkkkkkkkkkkkkkkkkkkkkk",eventsArray)

                let eventsForDayView = [];
                forEach(eventsArray, function (item) {
                  eventsForDayView.push(_.omit(item, ["start", "end"]));
                });
                setEventsForAgendaView([...eventsForDayView]);
              });
          });
      });
  };
  const Event = ({ event }) => {
    return (
      <span className="d-flex flex-column ">
        {viewProps !== "day" && (
          <div style={{ fontSize: "10.2px" }} className="text-muted">
            {event.body.EventStartTime == "12:00:00" &&
            event.body.EventEndTime == "12:00:00" ? (
              "All Day"
            ) : (
              <>
                {moment(event.start).format("hh:mm a")}-
                {moment(event.end).format("hh:mm a")}
              </>
            )}
          </div>
        )}
        <div className="d-flex justify-content-between">
          <Label className="text-color font-weight-bold text-capitalize">
            {event.title}{" "}
          </Label>
          <small className="text-muted">{event.body.Shift}</small>
        </div>
        <div className="d-flex justify-content-between">
          <small className="text-muted">
            {event.body.Hospital}
            {/* {event.body.EventName} */}
          </small>
          <small className="text-muted">{event.body.Ward}</small>
        </div>
        <div className="d-flex justify-content-between">
          <small className="text-muted">
            {event.body.Ref}
            {event.body.EventStartDate}
          </small>
        </div>
        {/* <div className="d-flex justify-content-between">
          <small className="text-muted">
            {event.body.StartTime}
            {event.body.Training}
            {event.body.EventEndDate}
          </small>
          <small className="text-muted">
            {event.body.EndTime}
            {event.body.Training_Time}
            {event.body.EventEndTime}
          </small>
        </div> */}
        {event.desc && ":  " + event.desc}
      </span>
    );
  };

  // funtion to disable previous days
  const handleSelect = ({ start, end, slots }) => {
    const title = " test";

    let today = new Date();
    let diffDays = Math.floor((start - today) / 86400e3);

    if (diffDays < -1) {
      setShow(false);
    } else {
      setShow(true);
    }

    setEventDates({ start: start, end: end });

    //setSelectedDateArray(slots);
  };

  const toggleModalShow = (data) => {
    setShow(data);
    setEventDates({ start: "", end: "" });
  };

  const onDataSaved = (data) => {
    // console.log(data);
    // console.log("shit from event : ", data);
    let event_array = _.filter(events, {
      start: moment(data.body.StartDate).add(data.body.StartTime).toDate(),
    });

    //add to candidate's calendar
    //event_array.length==!1 ? console.log("no overlapping"):console.log("overlapping")

    {
      (() => {
        switch (data.tab.split(":")[0]) {
          case "Booking":
            return setEvents([
              ...events,
              {
                // start: this.state.eventDates.start,
                start: moment(data.body.Booking_Date)
                  .add(data.body.Start_Time)
                  .toDate(),
                end: moment(data.body.Booking_Date)
                  .add(data.body.End_Time)
                  .toDate(),
                title: "Booking: " + data.Ref_No,
                body: {
                  Ref: data.body.Ref_No,
                  StartTime: data.body.Start_Time,
                  EndTime: data.body.End_Time,
                  Hospital: data.body.hospital,
                  Shift: data.body.shift,
                  Ward: data.body.ward,
                },
              },
            ]);

          case "Training":
            return setEvents([
              ...events,
              {
                start: moment(data.body.StartDate)
                  .add(data.body.StartTime)
                  .toDate(),
                // start: this.state.eventDates.start,
                // end: this.state.eventDates.end,
                end: moment(data.body.EndDate).add(data.body.EndTime).toDate(),
                title: "Training: " + data.tab,
                body: {
                  Training: data.body.Training,
                  Training_Time: data.body.Training_Time,
                },
              },
            ]);

          case "Event":
            return setEvents([
              ...events,
              {
                start: moment(data.body.StartDate)
                  .add(data.body.StartTime)
                  .toDate(),
                end: moment(data.body.EndDate).add(data.body.EndTime).toDate(),
                title: "Event: " + data.body.EventName,
                body: {
                  EventName: data.body.EventName,
                  EventStartDate: data.body.StartDate,
                  EventStartTime: data.body.StartTime,
                  EventEndDate: data.body.EndDate,
                  EventEndTime: data.body.EndTime,
                },
              },

              // console.log("Date: ",  moment(data.body.StartDate).add(data.body.StartTime).toDate())

              //console.log(moment(moment(data.body.EndDate ).add(moment.duration(data.body.EndTime))).toDate())
            ]);

          default:
            return null;
            break;
        }
      })();
    }
    getEvents();
  };
  const customEventpropGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#" + event.hexColor;
    var styleCustom;

    switch (event.title.split(":")[0]) {
      case "Booking":
        styleCustom = {
          opacity: 1,
          borderTop: "2px",
          borderTopColor: "#14bae4",
          borderStyle: "solid",
          display: "block",
        };
        break;

      case "Training":
        styleCustom = {
          opacity: 1,
          borderTop: "2px",
          borderTopColor: "#f4c414",
          borderStyle: "solid",
          display: "block",
        };
        break;

      case "Event":
        styleCustom = {
          opacity: 1,
          borderTop: "2px",
          borderTopColor: "#f54394",
          borderStyle: "solid",
          display: "block",
        };
        break;

      default:
        break;
    }
    // console.log(styleCustom);

    return {
      style: styleCustom,
    };
  };

  const showEvents = (checkbox) => {
    if (checkbox.checked) {
      setDisplayEvents(true);
    } else {
      setDisplayEvents(false);
    }
  };

  const getEventInRangeOnly = (data) => {
    // console.log("viewProps", viewProps);

    // console.log("range", data);

    if (viewProps == "month") {
      setGetStartDate(moment(tempRange.start).format("YYYY/MM/DD"));
      setGetEndDate(
        moment(moment(tempRange.end).add(1, "days")).format("YYYY/MM/DD")
      );
    } else if (viewProps == "week") {
      setGetStartDate(moment(tempRange[0]).format("YYYY/MM/DD"));
      setGetEndDate(
        moment(moment(tempRange[6]).add(1, "days")).format("YYYY/MM/DD")
      );
    } else if (viewProps == "day") {
      setGetStartDate(moment(tempRange[0]).format("YYYY/MM/DD"));
      setGetEndDate(
        moment(moment(tempRange[0]).add(1, "days")).format("YYYY/MM/DD")
      );
    } else if (viewProps == "agenda") {
      setGetStartDate(moment(tempRange.start).format("YYYY/MM/DD"));
      setGetEndDate(
        moment(moment(tempRange.end).add(1, "days")).format("YYYY/MM/DD")
      );
    }
  };

  const eventModalFunction = (event) => {
    if (event.title === "Booking") {
      let tempData = {
        availabilityStatus: "BOOKED",
        bonus: event.event.bonus,
        bookingAttendedStatus: event.event.bookingAttendedStatus,
        bookingCallStatus: "",
        bookingDate: event.event.bookingDate,
        date: event.event.bookingDate,
        hospitalId: event.event.clientId,
        hospitalName: event.event.clientName,
        id: event.event.id,
        referenceNo: event.event.referenceNo,
        shift: event.event.shift,
        shiftFrom: event.event.shiftFrom,
        shiftTo: event.event.shiftTo,
        uplift: event.event.upLift,
        wardId: event.event.ward.id,
        wardName: event.event.ward.name,
      };
      setSelectedEvent(tempData);
      setShowBookingModal(true);
    } else {
      setSelectedEvent(event);
      setEventPreview(true);
    }
  };

  useDidMountEffect(() => {
    {
      tempRange && getEventInRangeOnly();
    }
    return () => {};
  }, [tempRange]);

  useEffect(() => {
    setViewProps("month");

    return () => {};
  }, []);



  useEffect(() => {
    getEvents();
    console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhh",
    events)
    return () => {};
  }, [getStartDate, getEndDate]);


                
  return (
    <>
      <DashboardLayout title="Calendar" subTitle="Calendar Events">
        {eventDates.start != "" ? (
          <ModalComponent
            show={show}
            header="Add to calendar "
            closeModal={() => {
              toggleModalShow(false);
              // getEvents();
            }}
          >
            <BookTrainNav
              
              incommingPage={"employeeCalendar"}
              // dayData={null}
              closeModal={() => {
                toggleModalShow(false);
                // getEvents();
              }}
              onCreate={onDataSaved}
              start={eventDates.start}
              end={eventDates.end}
              event={selectedEvent}
              //  selectedDateArray={this.state.selectedDateArray}
            ></BookTrainNav>
          </ModalComponent>
        ) : null}
        <div className="card">
          <div className="col-sm-6">
            <div className="md-form-group">
              <label
                id="showEvents"
                htmlFor="eventVisibility"
                style={{ width: "16ch" }}
              >
                Show Events : 
              </label>
              <Input
                className="mt-1 pointer"
                type="checkbox"
                name="eventVisibility"
                checked={displayEvents}
                onClick={(checkbox) => showEvents(checkbox.target)}
              />
            </div>
            <UncontrolledTooltip target="showEvents">
              Show events in the calendar
            </UncontrolledTooltip>
          </div>
          <Calendar
            localizer={localizer}
            popup={true}
            onShowMore={(events, date) =>
              setshowPopUp({ showModal: true, events: events })
            }
            defaultDate={new Date()}
            defaultView={viewProps}
            events={displayEvents ? events : []}
            //  dayPropGetter={customDayPropGetter}
            eventPropGetter={customEventpropGetter}
            style={{ height: "100vh" }}
            onSelectEvent={(event) => {
              // console.log("====================================");
              // console.log("event : ", event);
              // console.log("====================================");
              eventModalFunction(event);
            }}
            onSelectSlot={handleSelect}
            selectable
            onRangeChange={(data) => setTempRange(data)}
            onView={(data) => setViewProps(data)}
            timeslots={1}
            // date={this.state.selectedDay}
            // onNavigate={date => {
            //   this.setState({ selectedDay: date });
            // }}
            components={{
              event: Event,
              // agenda: {
              //   event: eventsForAgendaView, // with the agenda view use a different component to render events
              // },
            }}
            messages={{
              noEventsInRange: (
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ height: "400px" }}
                >
                  <div>There are no events in this range.</div>{" "}
                </div>
              ),
            }}
          />

          {eventPreview && (
            <EventPreviewModal
              event={selectedEvent}
              show={eventPreview}
              closeModal={() => {
                setEventPreview(false);
              }}
              refresh={() => getEvents()}
            />
          )}

          <ModalComponent
            show={showBookingModal}
            header="Booking Summary"
            closeModal={() => setShowBookingModal(false)}
          >
            <BookingSummery
              // currentResetState={resetSheetData}
              //reset={(e) => setResetSheetData(e)}
              candidateId={0}
              dayData={selectedEvent}
              heading="Booking summary"
              closeModal={() => setShowBookingModal(false)}
              reset={getEvents}
              currentResetState={getEvents}
            />{" "}
          </ModalComponent>
        </div>
      </DashboardLayout>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(CalendarCustom);
