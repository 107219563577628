import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { useForm } from "react-hook-form";
import ToastCommon from "../../toastCommon";
import useDidMountEffect from "./intialRunUseEffect";
import { useToasts } from "react-toast-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCandidateList from "../../../customHooks/useCandidateList";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Button, Label, Input, Form, FormGroup, Col, Alert } from "reactstrap";
import {
  HOSTMexxar,
  SHIFTS,
  REGISTER_CANDIDATE,
} from "../../../configs/api-config";

export default function Availibility(props) {
  const MexxarApi = HOSTMexxar;
  const MexxarApiShifts = SHIFTS;
  const MexxarCandidateApi = REGISTER_CANDIDATE;

  const { register, handleSubmit, errors } = useForm();
  const {
    onCreate,
    closeModal,
    start,
    end,
    dayData,
    successStatus,
    reset,
    currentResetState,
    candidateWhenClick,
  } = props;

  const { addToast } = useToasts();

  const isDateNotAvailable = false;
  const [query, setQuery] = useState("");
  const [date, setDate] = useState(false);
  const [shifts, setShifts] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [shiftType, setShiftType] = useState(null);
  const [candidates, setCandidates] = useState(null);
  const [endDateFlag, setEndDateFlag] = useState(null);
  const [updateorNot, setUpdateorNot] = useState(null);
  const [startDateFlag, setStartDateFlag] = useState(null);
  const [bookingStatus, setBookingStatus] = useState(null);
  const [dataErrorStatus, setDataErrorStatus] = useState(false);
  const [availibility, setAvailibility] = useState("AVAILABLE");
  const [endDataErrorStatus, setEndDataErrorStatus] = useState(false);
  const [selectedCandidates, setSelectedCandidates] = useState(null);
  const [incommingAvailibilityLog, setIncommingAvailibilityLog] =
    useState(null);
  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  let url = MexxarCandidateApi + "/status/" + filterCustom.value;

  const { loading, error, rows, hasMore } = useCandidateList(
    url,
    filterCustom,
    query,
    pageNumber
  );

  const getCandidates = () => {
    let listOfCandidates = [];
    for (let i = 0; i < rows.length; i++) {
      listOfCandidates.push({
        value: rows[i].id,
        label: `${rows[i].firstName} ${rows[i].lastName}`,
        id: rows[i].id,
        candidate: rows[i],
      });
    }

    if (query != "") {
      setCandidates(listOfCandidates);
    } else {
      setCandidates([]);
    }
  };

  const handleInputChange = (e) => {
    setQuery(e);
  };

  var getDaysBetweenDates = function (startDate, endDate) {
    var now = startDate.clone(),
      dates = [];

    while (now.isSameOrBefore(endDate)) {
      dates.push(now.format("MM/DD/YYYY"));
      now.add(1, "days");
    }
    return dates;
  };

  const addAvailibility = (data) => {
    if (availibility == "AVAILABLE") {
      let array = {
        candidateId: selectedCandidates.id,
        shiftId: shiftType,
        date: moment(data.date).format("YYYY-MM-DD"),
        bookingCallStatus: bookingStatus == null ? "0" : bookingStatus,
        availabilityBookingLogStatus: "0",
      };

      axios
        .post(MexxarApi + "candidate-booking-logs", array)
        .then((res) => {
          if (res.data.status == "success") {
            closeModal();

            ToastCommon(true, "success", addToast);
            reset(!currentResetState);
            successStatus();
          } else if (res.data.status == "duplicate") {
            ToastCommon(true, "duplicate", addToast);
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          // console.log("error", err);
        });
    } else if (availibility == "VACATION") {
      let array = {
        candidateId: selectedCandidates.id,
        shiftId: "2",
        date: moment(data.date).format("YYYY-MM-DD"),
        bookingCallStatus: "0",
        availabilityBookingLogStatus: "1",
      };

      axios
        .post(MexxarApi + "candidate-booking-logs", array)
        .then((res) => {
          if (res.data.status == "success") {
            closeModal();

            ToastCommon(true, "success", addToast);
            reset(!currentResetState);
            successStatus();
          } else {
            ToastCommon(true, "duplicate", addToast);
          }
        })
        .catch((err) => {
          //console.log("error", err);
          //ToastCommon(true, "error", addToast);
        });
    }
  };

  const updateAvailibility = (data) => {
    if (
      (availibility == "AVAILABLE" &&
        data.availabilityBookingLogStatus == "NOT_AVAILABLE") ||
      (availibility == "AVAILABLE" &&
        data.availabilityBookingLogStatus == "AVAILABLE")
    ) {
      let array = {
        id: data.updateId,
        candidateId: selectedCandidates.id,
        shiftId: data.data.shift_type,
        date: moment(data.date).format("YYYY-MM-DD"),
        bookingCallStatus: data.data.Confirmstatus,
        availabilityBookingLogStatus: "0",
      };

      axios
        .put(MexxarApi + "candidate-booking-logs", array)
        .then((res) => {
          if (res.data.status == "success") {
            closeModal();

            ToastCommon(true, "update", addToast);
            reset(!currentResetState);
            successStatus();
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          //console.log("error", err);
          // ToastCommon(true, "error", addToast);
        });
    } else if (
      (availibility == "DELETE" &&
        data.availabilityBookingLogStatus == "AVAILABLE") ||
      (availibility == "DELETE" &&
        data.availabilityBookingLogStatus == "NOT_AVAILABLE")
    ) {
      axios
        .delete(MexxarApi + "candidate-booking-logs/" + data.updateId)
        .then((res) => {
          if (res.data.status == "success") {
            closeModal();

            ToastCommon(true, "delete", addToast);
            reset(!currentResetState);
            successStatus();
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          //console.log("error", err);
          // ToastCommon(true, "error", addToast);
        });
    } else if (
      availibility == "VACATION" &&
      data.availabilityBookingLogStatus == "AVAILABLE"
    ) {
      let array = {
        id: data.updateId,
        candidateId: selectedCandidates.id,
        shiftId: "2",
        date: moment(data.date).format("YYYY-MM-DD"),
        bookingCallStatus: "0",
        availabilityBookingLogStatus: "1",
      };

      axios
        .put(MexxarApi + "candidate-booking-logs", array)
        .then((res) => {
          if (res.data.status == "success") {
            closeModal();

            ToastCommon(true, "success", addToast);
            reset(!currentResetState);
            successStatus();
          } else {
            ToastCommon(true, "duplicate", addToast);
          }
        })
        .catch((err) => {
          //console.log("error", err);
          //ToastCommon(true, "error", addToast);
        });
    }
  };

  const onSubmit = (data) => {
    let datesBetween = getDaysBetweenDates(
      moment(data.StartDate),
      moment(data.EndDate)
    );

    for (let i = 0; i <= datesBetween.length - 1; i++) {
      axios
        .get(
          MexxarApi +
            "candidate-booking-logs/candidate-booking?candidateId=" +
            selectedCandidates.id +
            "&bookingDate=" +
            moment(datesBetween[i]).format("YYYY/MM/DD")
        )
        .then((res) => {
          if (res.data.body.length > 0) {
            updateAvailibility({
              updateId: res.data.body[0].id,
              data: data,
              date: res.data.body[0].date,
              candidatesName: res.data.body[0].candidatesName,
              availabilityBookingLogStatus:
                res.data.body[0].availabilityBookingLogStatus,
            });
          } else {
            addAvailibility({ data: data, date: datesBetween[i] });
          }
        });
    }
  };

  const checkDate = () => {
    let startDateTemp = "";
    if (startDateFlag != null && startDateFlag != undefined) {
      startDateTemp = startDateFlag;
    } else {
      startDateTemp = startDate;
    }

    let endDateTemp = "";
    if (endDateFlag != null && endDateFlag != undefined) {
      endDateTemp = endDateFlag;
    } else {
      endDateTemp = endDate;
    }

    setDate(moment(endDateTemp).isBefore(startDateTemp));
  };

  const getAllRows = () => {
    axios
      .get(MexxarApiShifts)
      .then((res) => {
        setShifts(res.data.body);
      })
      .catch((err) => {
        //console.log(err)
        ToastCommon(true, "error", addToast);
      });
  };

  useDidMountEffect(() => {
    if (selectedCandidates != undefined) {
      axios
        .get(
          MexxarApi +
            "candidate-booking-logs/candidate-booking?candidateId=" +
            selectedCandidates.id +
            "&bookingDate=" +
            moment(start).format("YYYY/MM/DD")
        )
        .then((res) => {
          if (res.data.body.length > 0) {
            setAvailibility(res.data.body[0].availabilityBookingLogStatus);
            setIncommingAvailibilityLog(res.data.body[0]);
          } else {
            setAvailibility("AVAILABLE");
          }
          getAllRows();
        });
    }
    return () => {};
  }, [selectedCandidates]);

  useEffect(() => {
    getCandidates();
    return () => {};
  }, [rows]);

  useEffect(() => {
    getAllRows();
    setUpdateorNot({
      id: dayData.id,
      availabilityStatus: dayData.availabilityStatus,
    });
    setStartDate(moment(start).format("YYYY-MM-DD"));
    setEndDate(moment(end).format("YYYY-MM-DD"));
    return () => {};
  }, []);

  useEffect(() => {
    setSelectedCandidates(candidateWhenClick);
    return () => {};
  }, [candidateWhenClick]);

  useEffect(() => {
    checkDate();
    return () => {};
  }, [startDate, endDate, startDateFlag, endDateFlag]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Label sm={2}>Date</Label>
          <Col sm={5}>
            <Input
              type="date"
              name="StartDate"
              innerRef={register}
              defaultValue={startDate != null ? startDate : ""}
              invalid={dataErrorStatus}
              onChange={(e) => {
                setDataErrorStatus(
                  moment(e.target.value).format("l") == moment().format("l")
                    ? false
                    : moment(e.target.value).isBefore()
                );
                setStartDateFlag(e.target.value);
              }}
            />
            {dataErrorStatus ||
              (date && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className="mx-1 bg"
                    />
                    Invalid Date
                  </li>
                </ul>
              ))}
          </Col>
          <Col sm={5}>
            <Input
              type="date"
              name="EndDate"
              innerRef={register}
              invalid={endDataErrorStatus || date}
              defaultValue={endDate != null ? endDate : ""}
              onChange={(e) => {
                setEndDataErrorStatus(
                  moment(e.target.value).format("l") == moment().format("l")
                    ? false
                    : moment(e.target.value).isBefore()
                );
                setEndDateFlag(e.target.value);
              }}
            />
            {endDataErrorStatus ||
              (date && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    <FontAwesomeIcon
                      icon={faExclamationCircle}
                      className="mx-1 bg"
                    />
                    Invalid Date
                  </li>
                </ul>
              ))}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col sm={3}>
            <Label>Tag Candidates</Label>
          </Col>
          <Col sm={9}>
            {/* {candidates != null ? (
              <Select
                name="candidateId"
                // isMulti
                defaultValue={
                  candidateWhenClick != null ? candidateWhenClick : ""
                }
                options={candidates}
                onChange={(data) => setSelectedCandidates(data)}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />

            ) : null} */}

            {candidates != null ? (
              <Select
                //isMulti
                name="candidateId"
                className="basic-multi-select"
                classNamePrefix="select"
                defaultValue={candidateWhenClick ? candidateWhenClick : ""}
                onChange={(data) => setSelectedCandidates(data)}
                isLoading={loading}
                options={candidates}
                placeholder="Search candidates ..."
                onInputChange={handleInputChange}
                noOptionsMessage={() => "No results found"}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              />
            ) : null}
          </Col>
        </FormGroup>
        <div className="mt-3 ">
          <Label>Set / Change Availibility </Label>
        </div>
        <FormGroup row className="  ml-1">
          {/* <Col sm={3}>
            <Label>Availibility</Label>
          </Col> */}
          {availibility != "NOT_AVAILABLE" ? (
            <Col sm={4}>
              {incommingAvailibilityLog != null ? (
                <Input
                  type="radio"
                  name="radio1"
                  defaultChecked={
                    incommingAvailibilityLog.availabilityBookingLogStatus ==
                      undefined ||
                    incommingAvailibilityLog.availabilityBookingLogStatus ==
                      "AVAILABLE"
                      ? true
                      : false
                  }
                  onChange={() => {
                    // console.log("changing availibility");
                    setAvailibility("AVAILABLE");
                  }}
                />
              ) : (
                <Input
                  type="radio"
                  name="radio1"
                  defaultChecked={availibility == "AVAILABLE" ? true : false}
                  onChange={() => {
                     setAvailibility("AVAILABLE");
                  }}
                />
              )}
              <Label>Available</Label>
            </Col>
          ) : null}

          {dayData.availabilityStatus == "AVAILABLE" ||
          dayData.availabilityStatus == "VACATION" ? (
            <Col sm={4}>
              <Input
                type="radio"
                name="radio1"
                onChange={() => setAvailibility("DELETE")}
              />
              <Label>Delete Availibility</Label>
            </Col>
          ) : null}

          <Col sm={4}>
            {incommingAvailibilityLog != null ? (
              <Input
                type="radio"
                name="radio1"
                defaultChecked={
                  incommingAvailibilityLog.availabilityBookingLogStatus ==
                    undefined ||
                  incommingAvailibilityLog.availabilityBookingLogStatus ==
                    "NOT_AVAILABLE"
                    ? true
                    : false
                }
                onChange={() => setAvailibility("VACATION")}
              />
            ) : (
              <Input
                type="radio"
                name="radio1"
                onChange={() => setAvailibility("VACATION")}
              />
            )}
            <Label>Unavailable</Label>
          </Col>
        </FormGroup>
        {/* {()=>{}} */}

        {availibility == "AVAILABLE" ||
        availibility == "NOT_MARKED" ||
        availibility == undefined ? (
          <>
            {/* {shifts != null
              ? 
              : null} */}
            <FormGroup row>
              <Col sm={2}>
                <Label>Shift Type</Label>
              </Col>
              {shifts ? (
                <>
                  {shifts.map((shift, id) => {
                    return (
                      <Col sm={2} key={id}>
                        <Label>
                          <Input
                            type="radio"
                            name="shift_type"
                            defaultChecked={
                              (dayData.shift != null &&
                                dayData.shift.id == shift.id) ||
                              (incommingAvailibilityLog != null &&
                                incommingAvailibilityLog.shift != null &&
                                incommingAvailibilityLog.shift.id == shift.id)
                                ? true
                                : false
                            }
                            value={shift.id}
                            innerRef={register}
                            onChange={(e) => {
                              setShiftType(e.target.value);
                            }}
                          />
                          {shift.name}
                        </Label>
                      </Col>
                    );
                  })}
                </>
              ) : null}
            </FormGroup>

            <FormGroup row>
              <Col sm={4}>
                <Label>Confirm Status</Label>
              </Col>
              <Col sm={4}>
                <Label>
                  {incommingAvailibilityLog != null ? (
                    <Input
                      type="radio"
                      name="Confirmstatus"
                      value={0}
                      defaultChecked={
                        incommingAvailibilityLog.bookingCallStatus ==
                        "CALL_ME_BEFORE_BOOKING"
                          ? true
                          : false
                      }
                      innerRef={register}
                      onChange={(e) => {
                        setBookingStatus(e.target.value);
                      }}
                    />
                  ) : (
                    <Input
                      type="radio"
                      name="Confirmstatus"
                      value={0}
                      defaultChecked={bookingStatus == null ? true : false}
                      innerRef={register}
                      onChange={(e) => {
                        setBookingStatus(e.target.value);
                      }}
                    />
                  )}
                  Call Before Booking
                </Label>
              </Col>
              <Col sm={4}>
                <Label>
                  {incommingAvailibilityLog != null ? (
                    <Input
                      type="radio"
                      name="Confirmstatus"
                      value={1}
                      defaultChecked={
                        incommingAvailibilityLog.bookingCallStatus ==
                        "DO_NOT_CALL_ME_BEFORE_BOOKING"
                          ? true
                          : false
                      }
                      innerRef={register}
                      onChange={(e) => {
                        setBookingStatus(e.target.value);
                      }}
                    />
                  ) : (
                    <Input
                      type="radio"
                      name="Confirmstatus"
                      value={1}
                      innerRef={register}
                      onChange={(e) => {
                        setBookingStatus(e.target.value);
                      }}
                    />
                  )}
                  Book Directly
                </Label>
              </Col>
            </FormGroup>
          </>
        ) : null}

        {isDateNotAvailable ? (
          <Alert>Date cannot be allocate. Please select a different date</Alert>
        ) : null}

        <div className="d-flex justify-content-end">
          <Button className="mr-2" color="success" type="submit">
            Create
          </Button>
          <Button color="secondary" onClick={() => closeModal()}>
            Cancel
          </Button>
        </div>
      </Form>
    </>
  );
}
