import React, { useState, useEffect } from "react";
import axios from "axios";
import filter from "lodash/filter";
import { Button, Col, Form, Input, Row, FormGroup, Label } from "reactstrap";
import { CVSOURCE } from "../../../configs/api-config";
import { useToasts } from "react-toast-notifications";
import loading from "../../../../src/images/puff.svg";
import ToastCommon from "../../../components/toastCommon";
import Delete from "../../../components/Modal/ModalBody/delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalComponent from "../../../components/Modal/modalComponent";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import DashboardLayout from "../../../layouts/dashboard-layout/dashboard";
import NewOneField from "../../../components/Modal/ModalBody/Admin/newOneField";
import BankHolidayField from "../../../components/Modal/ModalBody/Admin/bankHolidayFields";
import moment from "moment";

import TrustModal from "../../../components/Modal/ModalBody/Admin/trustModal";
import NotificationModal2 from "../../../components/Modal/ModalBody/Admin/notificationModal2";
import "./notification.style.css";
import {
  HOSTMexxar,
  SHIFTS,
  REGISTER_CANDIDATE,
} from "../../../configs/api-config";
const NotificationSetting = () => {
  // const MexxarApi = CVSOURCE;
  const MexxarApi = HOSTMexxar;
  const { addToast } = useToasts();

  const [rows, setRows] = useState([]);
  const [rowID, setRowID] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [rowNameData, setRowNameData] = useState([]);
  const [rowNameDate, setRowNameDate] = useState([]);
  const [rowNameDay, setRowNameDay] = useState([]);
  const [showAddRow, setShowAddRow] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);


  const [rowNameDataalert1, setRowNameDataalert1] = useState([]);
  const [rowNameDataalert2, setRowNameDataalert2] = useState([]);
  const [rowNameDataalert3, setRowNameDataalert3] = useState([]);
  const [rowNameDatrestrictedDays, setRowNameDatarestrictedDays] = useState([]);
  const [rowNameDataheadhunterStatus, setRowNameDataheadhunterStatus] = useState([]);

  const [rowNameDatabookingStatus, setRowNameDatabookingStatus] = useState([]);
  const [rowNameDataregistrationStatus, setRowNameDataregistrationStatus] = useState([]);

  const [rowNameDataauditStatus, setRowNameDataauditStatus] = useState([]);
  const [rowNameDatamaintenanceStatus, setRowNameDatamaintenanceStatus] = useState([]);

  const [rowNameDatahcpcLiveCheckTimer, setRowNameDatahcpcLiveCheckTimer] = useState([]);

  const [rowNameDatanmcLiveCheckTimer, setRowNameDatanmcLiveCheckTimer] = useState([]);
  const [rowNameDatadbsLiveCheckTimer, setRowNameDatadbsLiveCheckTimer] = useState([]);
  const [rowNameDatarestrictedDaysRtw, setRowNameDataRestrictedDaysRtw] = useState([]);




  const [rowNameDatauploadDBSPdf, setRowNameDatauploadDBSPdf] = useState([]);
  const [rowNameDatauploadHCPCPdf, setRowNameDatauploadHCPCPdf] = useState([]);
  const [rowNameDatauploadNMCPdf, setRowNameDatauploadNMCPdf] = useState([]);







  const getAllRows = () => {
    setSelectedRow(null);
    axios
      // .get("https://permapi.mexxar.com/api/frequency")
      .get(MexxarApi + "api/frequency")
      .then((res) => {
        setRows(res.data.body);
        console.log(res.data.body);
      })
      .catch((err) => {

        ToastCommon(true, "error", addToast);

      });
  };


  //   const handleInputChange = (e) => {
  //     const {  alert1, value } = e.target;
  //     setFormData({ ...formData, [ alert1]: value });
  //   }
  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     addRow();
  // };
  const [formData, setFormData] = useState({
    alert1: "",
    alert2: "",
    alert3: "",
    restrictedDays: "",
    headhunterStatus: "",
    bookingStatus: "",
    registrationStatus: "",
    auditStatus: "",
    maintenanceStatus: "",

  });







  const addRow = (data) => {
    console.log('adding data', data);
    let duplicate_items = filter(rows, (item) => {
      return (
        data.rowAlert1 === item.alert1 &&
        data.rowAlert2 === item.alert2 &&
        data.rowAlert3 === item.alert3 &&
        data.rowRestrictedDays === item.restrictedDays &&
        // data.rowHeadhunterStatus === item.headhunterStatus &&
        // data.rowBookingStatus === item.bookingStatus &&
        // data.rowRegistrationStatus === item.registrationStatus &&
        // data.rowAuditStatus === item.auditStatus &&
        // data.rowMaintenanceStatus === item.maintenanceStatus &&
        data.rowDbsLiveCheckTimer === item.dbsLiveCheckTimer &&
        data.rowNmcLiveCheckTimer === item.nmcLiveCheckTimer &&
        data.rowHcpcLiveCheckTimer === item.hcpcLiveCheckTimer &&
        data.rowuploadDBSPdf === item.uploadDBSPdf &&
        data.rowuploadHCPCPdf === item.uploadHCPCPdf &&
        data.rowuploadNMCPdf === item.uploadNMCPdf &&
        data.rowRestrictedDaysRtw === item.restrictedDaysRtw
      );
    });

    if (duplicate_items.length > 0) {
      ToastCommon(true, "duplicate", addToast);
    } else {
      axios.post(MexxarApi + "api/frequency/createFrequency", {
        // axios.post("https://permapi.mexxar.com/api/frequency/createFrequency", {
        alert1: data.rowAlert1,
        alert2: data.rowAlert2,
        alert3: data.rowAlert3,
        restrictedDays: data.rowRestrictedDays,
        // headhunterStatus: data.rowHeadhunterStatus,
        // bookingStatus: data.rowBookingStatus,
        // registrationStatus: data.rowRegistrationStatus,
        // auditStatus: data.rowAuditStatus,
        // maintenanceStatus: data.rowMaintenanceStatus,
        dbsLiveCheckTimer: data.rowDbsLiveCheckTimer,
        nmcLiveCheckTimer: data.rowNmcLiveCheckTimer,
        hcpcLiveCheckTimer: data.rowHcpcLiveCheckTimer,
        restrictedDaysRtw: data.rowRestrictedDaysRtw,

        uploadDBSPdf: data.rowuploadDBSPdf,
        uploadHCPCPdf: data.rowuploadDBSPdf,
        uploadNMCPdf: data.rowuploadNMCPdf
      })
        .then((res) => {
          if (res.data.status === "success") {
            getAllRows();
            setShowAddRow(!showAddRow);
            ToastCommon(true, "success", addToast);
          } else {
            ToastCommon(true, "duplicate", addToast);
          }
        })
        .catch((err) => {
          console.error(err);
          ToastCommon(true, "error", addToast);
        });
    }
  };

  const deleteRow = () => {
    axios
      .delete(`${MexxarApi}/api/frequency/${rowID}`)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "delete", addToast);
          getAllRows();
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        ToastCommon(true, "error", addToast);
      });
  };
  console.log('babab', rowNameDataalert1)

  const updateRow = (data) => {
    let duplicate_items = filter(rows, (item) => {
      return data?.alert1 == item.alert1;
    });

    if (duplicate_items.length > 1) {
      ToastCommon(true, "duplicate", addToast);
    } else {
      // axios.put("https://permapi.mexxar.com/api/frequency", {
      axios.put(MexxarApi + "api/frequency", {
        alert1: data.rowAlert1,
        alert2: data.rowAlert2,
        alert3: data.rowAlert3,
        restrictedDays: data.rowRestrictedDays,
        // headhunterStatus: data.rowHeadhunterStatus,
        // bookingStatus: data.rowBookingStatus,
        // registrationStatus: data.rowRegistrationStatus,
        // auditStatus: data.rowAuditStatus,
        // maintenanceStatus: data.rowMaintenanceStatus,
        dbsLiveCheckTimer: data.rowDbsLiveCheckTimer,
        nmcLiveCheckTimer: data.rowNmcLiveCheckTimer,
        hcpcLiveCheckTimer: data.rowHcpcLiveCheckTimer,
        restrictedDaysRtw: data.rowRestrictedDaysRtw,
        uploadDBSPdf: data.rowuploadDBSPdf,
        uploadHCPCPdf: data.rowuploadDBSPdf,
        uploadNMCPdf: data.rowuploadNMCPdf


      })
        .then((res) => {
          if (res.data.status == "success") {
            getAllRows();
            setShowUpdateRow(false);
            ToastCommon(true, "update", addToast);
          } else {
            ToastCommon(true, "cannot", addToast);
          }
        })
        .catch((err) => {
          console.error(err);
          ToastCommon(true, "error", addToast);
        });
    }
  };



  const deleteAllRows = () => {
    selectedItems.forEach((item) => {
      axios
        .delete(MexxarApi + "/" + item)
        .then((res) => {
          getAllRows();
          setSelectedItems([]);
          setSelectAll(false);
        })
        .catch((err) => {
          //console.log(err)
        });
    });
    ToastCommon(true, "delete", addToast);
  };
  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
    } else {
      setSelectedItems([...selectedItems, rowId]);
    }
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  useEffect(() => {
    getAllRows();
  }, []);

  return (
    <DashboardLayout title="Notification Settings" subTitle="Manage Your Notifications" target="">




      <div className="d-flex justify-content-between p-2 ml-3">

        {rows.length === 0 && (
          <div className="d-flex justify-content-between p-2 ml-3">
            <div>
              {" "}
              <Button
                color="primary"
                size="sm"
                onClick={() => {
                  setShowAddRow(true);
                }}
              >
                Create
              </Button>
            </div>
          </div>
        )}

        {rows.map((row, i) => (
          <div key={i} className="col-md-4 mb-4">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div></div>
                  <div>
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="shadow-none fa-lg mb-2 btn-raised btn-wave mx-3 pointer"
                      onClick={() => {
                        setShowUpdateRow(true);
                        setRowID(row?.id);
                        setRowNameDataalert1(row?.alert1)
                        setRowNameDataalert2(row?.alert2)
                        setRowNameDataalert3(row?.alert3)
                        setRowNameDatarestrictedDays(row?.restrictedDays)
                        //                 setRowNameDataheadhunterStatus(row?.headhunterStatus)
                        // setRowNameDatabookingStatus(row?.bookingStatus)
                        // setRowNameDataregistrationStatus(row?.registrationStatus)
                        // setRowNameDataauditStatus(row?.auditStatus)
                        // setRowNameDatamaintenanceStatus(row?.maintenanceStatus)
                        setRowNameDatahcpcLiveCheckTimer(row?.hcpcLiveCheckTimer)
                        setRowNameDatanmcLiveCheckTimer(row?.nmcLiveCheckTimer)
                        setRowNameDatadbsLiveCheckTimer(row?.dbsLiveCheckTimer)
                        setRowNameDataRestrictedDaysRtw(row?.restrictedDaysRtw)
                        setRowNameDatauploadDBSPdf(row?.uploadDBSPdf)
                        setRowNameDatauploadHCPCPdf(row?.uploadHCPCPdf)
                        setRowNameDatauploadNMCPdf(row?.uploadNMCPdf)


                      }}


                      size="1x"
                    />
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      onClick={() => {
                        setShowDeleteRow(true);
                        setRowID(row.id);
                      }}
                      className="shadow-none fa-lg mb-2 mx-3 btn-raised btn-wave pointer"
                      size="1x"
                    />




                  </div>
                </div>


                <br />

                <div className="card-header ">
                  <h5 className="card-title" style={{ fontSize: '16px', color: 'green' }}>Alert 1 , Alert 2 , Alert 3 are frequencies used to add the number of days  the docs
                    are due to expire </h5>

                </div>

                <div className="card-header d-flex justify-content-between">
                  <h5 className="card-title " style={{ fontSize: '16px' }}>Alert 1</h5>
                  <div>
                    <p className="card-text" style={{ fontSize: '16px' }}> {row.alert1}</p>
                  </div>
                </div>

                <div className="card-header d-flex justify-content-between">
                  <h2 className="card-title " style={{ fontSize: '16px' }}>Alert 2</h2>
                  <div>
                    <p className="card-text"> {row.alert2}</p>
                  </div>
                </div>
                <div className="card-header d-flex justify-content-between">
                  <h5 className="card-title" style={{ fontSize: '16px' }}>Alert 3 </h5>
                  <div>
                    <p className="card-text" style={{ fontSize: '16px' }}> {row.alert3}</p>
                  </div>
                </div>


                <div className="card-header ">
                  <h5 className="card-title" style={{ fontSize: '16px', color: 'green' }}>Restricted days RTW is used to configure how a delay in sending emails for
                    candidates ( ex - send emails each and every 5 days) </h5>

                </div>




                <div className="card-header d-flex justify-content-between">
                  <h5 className="card-title " style={{ fontSize: '16px' }}>Restricted Days</h5>
                  <div>
                    <p className="card-text" style={{ fontSize: '16px' }}> {row.restrictedDays}</p>
                  </div>
                </div>
                {/* <div className="card-header d-flex justify-content-between">
                <h5 className="card-title"style={{ fontSize: '16px' }}>Headhunter Status</h5>
                <div>
                <p className="card-text"style={{ fontSize: '16px' }}> {row.headhunterStatus}</p>
                </div>
              </div> */}
                <div className="card-header d-flex justify-content-between">
                  <h5 className="card-title " style={{ fontSize: '16px' }}>Upload DBSPdf Status</h5>
                  <div>
                    <p className="card-text" style={{ fontSize: '16px' }}> {row.uploadDBSPdf}</p>
                  </div>
                </div>
                <div className="card-header d-flex justify-content-between">
                  <h5 className="card-title " style={{ fontSize: '16px' }}>Upload NMCPdf Status</h5>
                  <div>
                    <p className="card-text" style={{ fontSize: '16px' }}> {row.uploadNMCPdf}</p>
                  </div>
                </div>
                <div className="card-header d-flex justify-content-between">
                  <h5 className="card-title " style={{ fontSize: '16px' }}>Upload HCPCPdf Status</h5>
                  <div>
                    <p className="card-text" style={{ fontSize: '16px' }}> {row.uploadHCPCPdf}</p>

                  </div>
                </div>



                <div className="card-header ">
                  <h5 className="card-title" style={{ fontSize: '16px', color: 'green' }}>These settings are designed to initiate live background checks with DBS, NMC, and HCPC. When configuring these options, these checks will be scheduled based on a specified number of months. For example, you can set it to trigger checks in 2 months or 6 months. This ensures that the system calculates and performs the necessary verifications at the chosen intervals. </h5>

                </div>


                <div className="card-header d-flex justify-content-between">
                  <h5 className="card-title " style={{ fontSize: '16px' }}>DBS Live Check Timer</h5>
                  <div>
                    <p className="card-text" style={{ fontSize: '16px' }}> {row.dbsLiveCheckTimer}</p>

                  </div>
                </div>
                <div className="card-header d-flex justify-content-between">
                  <h5 className="card-title" style={{ fontSize: '16px' }}>NMC Live Check Timer</h5>
                  <div>
                    <p className="card-text" style={{ fontSize: '16px' }}> {row.nmcLiveCheckTimer}</p>
                  </div>
                </div>
                <div className="card-header d-flex justify-content-between">

                  <h5 className="card-title " style={{ fontSize: '16px' }}>HCP Live Check Timer</h5>
                  <div>
                    <p className="card-text" style={{ fontSize: '16px' }}> {row.hcpcLiveCheckTimer}</p>
                  </div>
                </div>
                <div className="card-header d-flex justify-content-between">

                  <h5 className="card-title " style={{ fontSize: '16px' }}>Email Drip Interval</h5>
                  <div>
                    <p className="card-text" style={{ fontSize: '16px' }}> {row.restrictedDaysRtw}</p>
                  </div>
                </div>


              </div>
            </div>
          </div>
        ))}

      </div>

      <ModalComponent
        show={showAddRow}
        header="Add Notification"
        closeModal={() => setShowAddRow(false)}
      >
        <NotificationModal2
          mode="Add"

          modalType1="Alert1342"
          modalType2="Alert2"
          modalType3="Alert3"
          modalType4="Restricted Days"
          modalType5="Upload DBS Pdf Status"
          modalType6="Upload HCPC Pdf Status"
          modalType7="Upload NMC Pdf Status"
          // modalType8="Audit Status"
          // modalType9="Maintenance Status"
          modalType10="Dbs LiveCheckTimer"
          modalType11="Nmc LiveCheckTimer"
          modalType12="Hcpc LiveCheckTimer"
          modalType13="Restricted Days Rtw"

          closeModal={() => setShowAddRow(false)}
          onCreate={(data) => addRow(data)}
        />
      </ModalComponent>

      <ModalComponent
        show={showUpdateRow}
        header="Update Notification "
        closeModal={() => setShowUpdateRow(false)}
      >
        <NotificationModal2
          mode="Update"
          modalType1="Alert1"
          modalType2="Alert2"
          modalType3="Alert3"
          modalType4="Restricted Days"
          modalType5="Headhunter Status"
          modalType6="Booking Status"
          modalType7="Registration Status"
          modalType8="Audit Status"
          modalType9="Maintenance Status"
          modalType10="Dbs LiveCheckTimer"
          modalType11="Nmc LiveCheckTimer"
          modalType12="Hcpc LiveCheckTimer"
          rowN
          rowNameDataalert1={rowNameDataalert1}
          rowNameDataalert2={rowNameDataalert2}
          rowNameDataalert3={rowNameDataalert3}
          rowNameDatrestrictedDays={rowNameDatrestrictedDays}
          rowNameDataheadhunterStatus={rowNameDataheadhunterStatus}

          rowNameDatabookingStatus={rowNameDatabookingStatus}
          rowNameDataregistrationStatus={rowNameDataregistrationStatus}

          rowNameDataauditStatus={rowNameDataauditStatus}
          rowNameDatamaintenanceStatus={rowNameDatamaintenanceStatus}

          rowNameDatahcpcLiveCheckTimer={rowNameDatahcpcLiveCheckTimer}
          rowNameDatanmcLiveCheckTimer={rowNameDatanmcLiveCheckTimer}
          rowNameDatadbsLiveCheckTimer={rowNameDatadbsLiveCheckTimer}
          rowNameDatarestrictedDaysRtw={rowNameDatarestrictedDaysRtw}
          rowNameDatauploadDBSPdf={rowNameDatauploadDBSPdf}
          rowNameDatauploadHCPCPdf={rowNameDatauploadHCPCPdf}
          rowNameDatauploadNMCPdf={rowNameDatauploadNMCPdf}



          closeModal={() => setShowUpdateRow(false)}
          onCreate={(data) => updateRow(data)}
        />
      </ModalComponent>

      <Delete
        show={showDeleteRow}
        isConfirm={() => {
          deleteRow();
          setShowDeleteRow(!showDeleteRow);
        }}
        closeModal={() => setShowDeleteRow(false)}
      />
    </DashboardLayout>
  );
};

export default NotificationSetting;