import React, { useState, useRef, useCallback } from "react";
import axios from "axios";
import filter from "lodash/filter";
import { Link } from "react-router-dom";
import NoConnection from "../errorPages/NoConnection";
import { useToasts } from "react-toast-notifications";
import ToastCommon from "../../components/toastCommon";
import NoSearchResults from "../errorPages/NoSearchResults";
import loadingAnimation from "../../../src/images/puff.svg";
import Delete from "../../components/Modal/ModalBody/delete";
import { useIsMounted } from "../../customHooks/useIsMounted";
import { CLIENTS, ClientLable, HOSTMexxar } from "../../configs/api-config";
import { faEye, faMailBulk, faNotEqual, faPoundSign, faStickyNote, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewClient from "../../components/Modal/ModalBody/newClient";
import ModalComponent from "../../components/Modal/modalComponent";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Button, Input, Badge, UncontrolledTooltip, Label, Collapse, Card, CardBody, Row, Col, FormGroup } from "reactstrap";
import WardsList from "../../components/Modal/ModalBody/Admin/wardsList";
import { CandidateActiveStatus } from "../../util/common/candidateStatus";
import ClientRateModal from "../../components/Modal/ModalBody/clientRateModal";
import ConfigureWards from "../../components/Modal/ModalBody/Admin/configureWards";
import { FunnelIcon } from "../../assets/icons/svg";
import useClientsFetch from "../../customHooks/useClientsFetch";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import { useSpring, animated } from "react-spring";
import HospitalNote from "../../components/Modal/ModalBody/hospitalNote";
import WorkingCardWidget from "../../widgets/working-card-widget/working-card-widget";
import BulkCardWidget from "../../widgets/working-card-widget/bulk-card-widget";
import useBulkFetch from "../../customHooks/useBulkFetch";
import moment from "moment";

const BulkEmailReports = () => {
  const MexxarApi = CLIENTS;
  const MexxarHost = HOSTMexxar;

  const { addToast } = useToasts();

  /***************** Search filter ******************/
  const [query, setQuery] = useState(null);
  const [reset, setReset] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [remember, setRemember] = useState(true); /// <--------filter settings options saving and retreving/ if need activate later with below checkbox
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchAdvanced, setSearchAdvanced] = useState(false);
  const [callResponse, setCallResponse] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");

  const [mode, setMode] = useState("");
  const [rowID, setRowID] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [showWards, setShowWards] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [showAddRow, setShowAddRow] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showUpdateRow, setShowUpdateRow] = useState(false);
  const [showHospitalNote, setShowHospitalNote] = useState(false);
  const [showDeleteRow, setShowDeleteRow] = useState(false);
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [showRateModal, setShowRateModal] = useState(false);
  const [showConfigWards, setShowConfigWards] = useState(false);
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [search, setSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");


  const filterByName = (name, dataSet) => {
    let filtered = dataSet;
    if (name !== "") {
      filtered = dataSet.filter((item) => {
        console.log("item", item)
        let searchableString = `${item?.candidate?.firstName} ${item?.candidate?.lastName} ${item?.user?.firstName} ${item?.user?.lastName} ${item?.toEmail} ${item?.user?.email}  ${item?.emailContent?.subject} ${item?.emailContent?.type}   `;
        let p = searchableString;
        return p.toLowerCase().includes(name.toLowerCase());
      });
    }
    let lengthofSearch = filtered.length;
    return { filtered, lengthofSearch };
  };


  const currentDate = new Date();

  const yesterDay = moment(moment(currentDate).subtract(7, "days")).format(
    "YYYY-MM-DD"
  );
  const tommorow = moment(moment(currentDate).add(7, "days")).format(
    "YYYY-MM-DD"
  );
  const [notifications, setNotification] = useState({ value: "", bool: false });
  const [getStartDate, setGetStartDate] = useState(yesterDay);
  const [getEndDate, setGetEndDate] = useState(tommorow);
  const [resetState, setResetState] = useState(false);


  function handleSearch(e) {
    if (e.target.value == "") {
      setQuery(null);
    } else {
      setQuery(e.target.value);
    }
    setPageNumber(1);
  }
  console.log("status", status)
  console.log("type", type)
  console.log("getStartDate", getStartDate)
  console.log("getEndDate", getEndDate)


  // let url = HOSTMexxar + "email/emailSentLog?status="+status+"&search=Jef&type="+type+"&startDate="+getStartDate+"&endDate="+getEndDate;
  let url = HOSTMexxar + "email/emailSentLog";





  const { loading, error, rows, hasMore, totalElements } = useBulkFetch(
    url,
    query,
    pageNumber,
    searchAdvanced,
    reset,
    status,
    type,
    getEndDate,
    getStartDate,
    search
  );



  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  console.log("rows", rows)

  const addNewWard = (data) => {
    axios
      .post(MexxarHost + "client-wards", data)
      .then((res) => {
        if (res.data.status == "success") {
          setShowConfigWards(!showConfigWards);

          ToastCommon(true, "success", addToast);
        } else {
          //toastCommon(true, "duplicate");
        }
      })
      .catch((err) => {
        //console.log(err)
        // toastCommon(true, "error");
      });
  };

  const deleteRow = () => {
    axios
      .delete(MexxarApi + "/" + rowID)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "delete", addToast);
          setReset(!reset);
        } else {
          ToastCommon(true, "cannot", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const deleteAllRows = () => {
    //console.log(rowID);
    selectedItems.forEach((item) => {
      axios
        .delete(MexxarApi + "/" + item)
        .then((res) => {
          setReset(!reset);
          setSelectedItems([]);
          setSelectAll(false);
        })
        .catch((err) => {
          //console.log(err)
        });
    });
  };


  const resetFilters = () => {
    setReset(!reset);
    setStatus("");
    setType("");
    setPageNumber(1);
    setGetStartDate(yesterDay)
    setGetEndDate(tommorow)
    // setSelectedSpecialities({ id: "", value: "", label: "" });
    // setSelectedRole({ id: "", value: "", label: "" });
    // setSelectedBand({ id: "", value: "", label: "" });
  };

  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
    } else {
      setSelectedItems([...selectedItems, rowId]);
    }
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const changeStatus = (id, status) => {
    let setStatus;
    if (status == "ACTIVE") {
      setStatus = "INACTIVE";
    } else {
      setStatus = "ACTIVE";
    }

    axios
      .put(MexxarApi + "/" + id + "/status/" + setStatus)
      .then((res) => {
        if (res.data.status === "success") {
          ToastCommon(true, "update", addToast);
          setReset(!reset);
        } else {
          ToastCommon(true, "error", addToast);
        }
      })
      .catch((err) => {
        //console.log(err)
      });
  };




  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  function handleSearch(e) {
    if (e.target.value == "") {
      setQuery(null);
    } else {
      setQuery(e.target.value);
    }
    setPageNumber(1);
  }

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);

  /******* Use to set page number to default value of 1 when changing the employee or search advance filters  *******/
  useDidMountEffect(() => {
    setPageNumber(1);
    return () => { };
  }, [searchAdvanced, reset]);
  /*******End *******/

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });


  const sortFunctionAscending = (a, b) => {
    var dateA = new Date(a.shiftFrom).getTime();
    var dateB = new Date(b.shiftFrom).getTime();
    return dateA > dateB ? 1 : -1;
  };

  return (
    <>
      <div className="form-inline ">

        <FontAwesomeIcon icon={faMailBulk} className={"ml-2 mr-3  "} />
        <Label className={"mr-3"}>
          {totalElements}{" "}
          {/* {totalElements.length == 1 ? "Candidate" : "Candidates"} */}
        </Label>
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={handleSearch}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Documents..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
        <UncontrolledTooltip target="searchBar">
          Search Reports by Candidate Name / User Name / Receiver Email / Sender Email/ Email Subject /Email Content
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label for="exampleDate">Start Date</Label>
                <Input
                  id="startDate"
                  type="date"
                  defaultValue={getStartDate}
                  value={getStartDate}
                  onChange={(e) => setGetStartDate(e.target.value)}
                  style={{ cursor: "pointer" }}
                />
              </Col>
              <Col>
                <Label for="exampleDate">End Date</Label>
                <Input
                  id="endDate"
                  type="date"
                  value={getEndDate}
                  onChange={(e) => setGetEndDate(e.target.value)}
                  style={{ cursor: "pointer" }}
                />
              </Col>

              <Col>
                <Label>Status</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value=""
                      checked={status === ""}
                      onChange={(e) => setStatus(e.target.value)}
                    />
                    <Badge color="info">All</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="SENT"
                      checked={status === "SENT"}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="danger">Sent</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="DELIVERED"
                      checked={status === "DELIVERED"}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="secondary">DELIVERED</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="BOUNCED"
                      checked={status === "BOUNCED"}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="primary">BOUNCED</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="COMPLAINT"
                      checked={status === "COMPLAINT"}
                      onChange={(e) => setStatus(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="success">COMPLAINT</Badge>
                  </Label>
                </FormGroup>

              </Col>


              <Col>
                <Label>Type</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value=""
                      checked={type === ""}
                      onChange={(e) => setType(e.target.value)}
                    />
                    <Badge color="info">All</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="ESHOT"
                      checked={type === "ESHOT"}
                      onChange={(e) => setType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="info">Eshot</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="COMMON"
                      checked={type === "COMMON"}
                      onChange={(e) => setType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="danger">Common</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="NOTIFICATION"
                      checked={type === "NOTIFICATION"}
                      onChange={(e) => setType(e.target.value)}
                      style={{ cursor: "pointer" }}
                    />
                    <Badge color="secondary">Notifications</Badge>
                  </Label>
                </FormGroup>

              </Col>


              <Col></Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    // onClick={handleSearch}
                    onClick={() => {
                      setSearchAdvanced(!searchAdvanced);
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    onClick={() => {
                      resetFilters();
                    }}
                    style={{ width: "70px" }}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
            <Row></Row>
          </CardBody>
        </Card>
      </Collapse>
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {/* {rows?.sort(SortFunctionAscending).map((row, i) => { */}
      {rows?.map((row, i) => {
        if (rows.length === i + 1) {
          return (
            <animated.div
              style={animationProps}
              ref={lastBookElementRef}
              key={row.email}
            >
              <BulkCardWidget
                userProfile={row}
                isSelected={(id) => isSelected(id)}
                onItemSelect={(id) => onItemSelect(id)}
                selectAll={selectAll}
                totalElements={totalElements}

              />
            </animated.div>
          );
        } else {
          return (
            <animated.div style={animationProps} key={row.email}>
              <BulkCardWidget
                userProfile={row}
                isSelected={(id) => isSelected(id)}
                onItemSelect={(id) => onItemSelect(id)}
                selectAll={selectAll}
                totalElements={totalElements}

              />
            </animated.div>
          );
        }
      })}
      {
        rows && !loading ? (
          filterByName(searchQuery, rows).length > 0 ? (
            filterByName(searchQuery, rows).map((row, i) => (
              <BulkCardWidget
                key={i}
                userProfile={row}
                status={status}
                reset={() => setResetState(prevState => !prevState)}
              />
            ))
          ) : null
        ) : null
      }


      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      {!loading && !error && rows.length == 0 && <NoSearchResults />}

      {/* {showSnapshotModal ? (
        <ModalComponent
          show={showSnapshotModal}
          header="Mailshot"
          closeModal={() => setShowSnapshotModal(false)}
        >
          <SnapShotNav
            closeModal={() => setShowSnapshotModal(false)}
            user={selectedItemsForSnapshot}
          />
        </ModalComponent>
      ) : null} */}
    </>
  );
};

export default BulkEmailReports;
