import React from "react";
import { faMars, faVenus, faVenusMars } from "@fortawesome/free-solid-svg-icons";
export const GenderSwitch = (data) => {
  let genderSymbol;
  let genderColor;
  switch (data) {
    case "MALE":
      genderSymbol = faMars;
      genderColor = "#458AF7";
      break;
    case "Male":
      genderSymbol = faMars;
      genderColor = "#458AF7";
      break;
    case "FEMALE":
      genderSymbol = faVenus;
      genderColor = "#f54394";
      break;
    case "Female":
      genderSymbol = faVenus;
      genderColor = "#f54394";
      break;
    case "OTHER":
      genderSymbol = faVenusMars;
      genderColor = "";
      break;
    case "UNKNOWN":
      genderSymbol = faVenusMars;
      genderColor = "";
      break;
    case "Unknown":
      genderSymbol = faVenusMars;
      genderColor = "";
      break;
  }

  return { genderSymbol: genderSymbol, genderColor: genderColor };
};
