import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ModalComponent from "../../modalComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Label, Input, Form, FormGroup, Col, Row } from "reactstrap";
import { faEye, faEyeSlash, faKey } from "@fortawesome/free-solid-svg-icons";
import PasswordReset from "../../../../components/Modal/ModalBody/Admin/passwordReset";
import useDidMountEffect from "../intialRunUseEffect";

export default function CreateNewUser(props) {
  const { register, handleSubmit, errors } = useForm();

  const {
    onCreate,
    closeModal,
    mode,
    id,
    employeeData,
    incomingRoles,
    consultantTeams,
    consultantRoles,
  } = props;

  const [showAddRow, setShowAddRow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedConsultantRole, setSelectedConsultantRole] = useState(null);
  const [selectedConsultantTeam, setSelectedConsultantTeam] = useState(null);
  const [genderTypes, setGenderTypes] = useState(["Select", "MALE", "FEMALE"]);

  const onSubmit = (data) => {
    onCreate(data);
  };

  useDidMountEffect(() => {
    setSelectedConsultantRole(employeeData?.employeeRoleId);
    setSelectedConsultantTeam(employeeData?.teamId);
  }, [employeeData]);

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup row>
          <Label sm={4}>First name</Label>
          <Col sm={8}>
            <Input
              // value={value}
              // onChange={changeHandler}
              type="text"
              name="firstName"
              defaultValue={
                employeeData != undefined ? employeeData.firstName : ""
              }
              invalid={errors.firstName}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
                maxLength: {
                  value: 30,
                  message: "This field only allowed only 30 characters",
                },
              })}
            ></Input>
            {errors.firstName && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.firstName?.message}
                </li>
              </ul>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>Last name</Label>
          <Col sm={8}>
            <Input
              // onChange={changeHandler}
              type="text"
              name="lastName"
              defaultValue={
                employeeData != undefined ? employeeData.lastName : ""
              }
              invalid={errors.lastName}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
                maxLength: {
                  value: 30,
                  message: "This field only allowed only 30 characters",
                },
              })}
            ></Input>
            {errors.lastName && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.lastName?.message}
                </li>
              </ul>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>Gender </Label>
          <Col sm={8}>
            {(mode == "Update" && genderTypes.length > 0 && employeeData) ||
            (mode == "Add" && genderTypes.length > 0) ? (
              <Input
                type="select"
                id="gender"
                name="gender"
                defaultValue={employeeData && employeeData.gender}
                invalid={errors.gender}
                innerRef={register({
                  required: {
                    value: true,
                    message: "This field is required!",
                  },
                })}
              >
                {genderTypes.map((genderType, id) => {
                  return (
                    <option value={genderType} key={id}>
                      {genderType}
                    </option>
                  );
                })}
              </Input>
            ) : null}

            {errors.gender && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.gender?.message}
                </li>
              </ul>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>Email </Label>
          <Col sm={8}>
            <Input
              // onChange={changeHandler}
              disabled={mode == "Update" ? true : false}
              type="email"
              name="email"
              defaultValue={employeeData != undefined ? employeeData.email : ""}
              invalid={errors.email}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
                maxLength: {
                  value: 60,
                  message: "This field only allowed only 60 characters",
                },
              })}
            ></Input>
            {errors.email && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.email?.message}
                </li>
              </ul>
            )}
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4}>Email Password </Label>
          <Col sm={7}>
            <Input
              //  onChange={changeHandler}
              type={showPassword ? "text" : "password"}
              name="emailPassword"
              defaultValue={
                employeeData != undefined ? employeeData.emailPassword : ""
              }
              invalid={errors.password}
              innerRef={register({
                required: {
                  value: mode === "Update" ? false : true,
                  message: "This field is required!",
                },
                maxLength: {
                  value: 30,
                  message: "This field only allowed only 30 characters",
                },
              })}
            ></Input>
            {errors.password && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.password?.message}
                </li>
              </ul>
            )}
          </Col>
          <Col
            sm={1}
            className="d-flex align-items-center  justify-content-start"
          >
            {showPassword ? (
              <FontAwesomeIcon
                icon={faEye}
                className=" btn-raised btn-wave"
                onClick={() => setShowPassword(false)}
                style={{
                  boxShadow: "none",
                }}
              />
            ) : (
              <FontAwesomeIcon
                icon={faEyeSlash}
                className=" btn-raised btn-wave"
                onClick={() => setShowPassword(true)}
                style={{
                  boxShadow: "none",
                }}
              />
            )}
          </Col>
        </FormGroup>
        {mode != "Update" ? (
          <FormGroup row>
            <Label sm={4}>Account Password </Label>
            <Col sm={7}>
              <Input
                //  onChange={changeHandler}
                type={"text"}
                name="password"
                invalid={errors.password}
                innerRef={register({
                  required: {
                    value: mode === "Update" ? false : true,
                    message: "This field is required!",
                  },
                  maxLength: {
                    value: 30,
                    message: "This field only allowed only 30 characters",
                  },
                  pattern: {
                    value:
                      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                    message:
                      "Password must contain at least one Capital, Lower, Number, Special Character and 8 characters",
                  },
                })}
              ></Input>

              {errors.password && (
                <ul
                  className="list-unstyled text-sm mt-1 text-muted filled"
                  id="parsley-id-119"
                >
                  <li className="parsley-required text-danger">
                    {errors.password?.message}
                  </li>
                </ul>
              )}
            </Col>
            <Col
              sm={1}
              className="d-flex align-items-center  justify-content-start"
            ></Col>
          </FormGroup>
        ) : (
          <FormGroup row>
            <Label sm={4}>Account Password </Label>
            <Col sm={7}>
              <FontAwesomeIcon
                onClick={() => setShowAddRow(true)}
                icon={faKey}
                className="mr-2 btn-raised btn-wave"
                style={{
                  boxShadow: "none",
                }}
              ></FontAwesomeIcon>
              Reset
            </Col>
            <Col
              sm={1}
              className="d-flex align-items-center  justify-content-start"
            ></Col>
          </FormGroup>
        )}
        <FormGroup row>
          <Label sm={4}>Company T.P </Label>
          <Col sm={8}>
            <Input
              // onChange={changeHandler}
              type="text"
              name="companyPhoneNumber"
              defaultValue={
                employeeData != undefined ? employeeData.companyPhoneNumber : ""
              }
              invalid={errors.companyPhoneNumber}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
                maxLength: {
                  value: 30,
                  message: "This field only allowed only 30 characters",
                },
              })}
            ></Input>
            {errors.companyPhoneNumber && (
              <ul
                className="list-unstyled text-sm mt-1 text-muted filled"
                id="parsley-id-119"
              >
                <li className="parsley-required text-danger">
                  {errors.companyPhoneNumber?.message}
                </li>
              </ul>
            )}
          </Col>
        </FormGroup>

        <FormGroup row>
          <Label sm={4} for="userRoleId">
            Consultant role
          </Label>
          <Col sm={8}>
            <Input
              type="select"
              id="userRoleId"
              name="userRoleId"
              value={selectedConsultantRole}
              onChange={(e) => {
                setSelectedConsultantRole(e.target.value);
              }}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            >
              <option value={0}>Select</option>
              {consultantRoles?.map((item, id) => {
                return (
                  <option value={item.id} key={id}>
                    {item.name}
                  </option>
                );
              })}
            </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={4} for="userRoleId">
            Team
          </Label>
          <Col sm={8}>
            <Input
              type="select"
              id="teamId"
              name="teamId"
              value={selectedConsultantTeam}
              onChange={(e) => setSelectedConsultantTeam(e.target.value)}
              innerRef={register({
                required: {
                  value: true,
                  message: "This field is required!",
                },
              })}
            >
              <option value={0}>Select</option>
              {consultantTeams?.map((item, id) => {
                return (
                  <option value={item.id} key={id}>
                    {item.name}
                  </option>
                );
              })}
            </Input>
          </Col>
        </FormGroup>
        <Row className="justify-content-end">
          <div className="d-flex ">
            <Button className="mr-2" color="success" type="submit">
              {mode}
            </Button>
            <Button color="secondary" onClick={() => closeModal()}>
              Close
            </Button>
          </div>
        </Row>
      </Form>

      <ModalComponent
        show={showAddRow}
        header="Reset Password"
        closeModal={() => setShowAddRow(false)}
      >
        <PasswordReset
          mode="Add"
          id={id}
          type={"employee"}
          closeModal={() => setShowAddRow(false)}
          //          onCreate={(data) => addRow(data)}
        />
      </ModalComponent>
    </>
  );
}
