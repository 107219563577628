import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, Label } from "reactstrap";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";

const IncomingcallModal = (props) => {
  const { closeModal, show = false, directionData, headers1 } = props;
  var HorizonApplicationId = localStorage.getItem("HorizonApplicationId");

  console.log(directionData.IncomingUniqueId);

  const headers = new Headers();
  headers.append("ApplicationId", HorizonApplicationId);
  headers.append("Content-Type", "application/x-www-form-urlencoded");

  const handleAnswer = async () => {
    const params = new URLSearchParams();
    params.append("callId", directionData.IncomingUniqueId);

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: params,
    };

    try {
      const response = await fetch(
        "https://localhost:10443/api/v1/Answer",
        requestOptions
      );
      const data = await response.json();

      // console.log(data.UniqueId); // Ensure this prints the correct value

      // Update state within the scope of the async function
      // setCallId(data.UniqueId);
      //  localStorage.setItem('callIdIncoming', data.UniqueId);
      // setCallBackShow(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // const callIdIncoming = localStorage.getItem('callIdIncoming');

  // console.log("sssssssssss",callIdIncoming)
  const handleHangupCall = async () => {
    // setIsCalling(false);

    const params = new URLSearchParams();

    // console.log("Call Id",callId)
    params.append("callId", directionData.IncomingUniqueId);

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: params, // Convert to string
    };

    console.log("hangup", headers);

    fetch("https://localhost:10443/api/v1/Hangup", requestOptions)
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error("Error:", error));

    // Handle the call functionality here
    // console.log("Calling number:", input);
    // Handle the call functionality here
    // console.log("Calling number:", input);
  };
  function handleHangupAndCloseModal() {
    handleHangupCall();
    closeModal(false);
  }

  return (
    <>
      <Modal
        isOpen={show}
        className="text-gray"
        centered={true}
        style={{ width: "350px" }}
        backdrop={true}
        keyboard={true}
      >
        <div className="p-3">
          <div>
            <div>
              {/* <p>{headers1}</p> */}
              {/* <ul>
    {Array.from(headers1.entries()).map(([key, value]) => (
      <li key={key}>{`${key}: ${value}`}</li>
    ))}
  </ul> */}
            </div>

            {/* {directionData?.Direction === 'Incoming' ? ( */}
            <div className="d-flex justify-content-center">
              <Label>Incoming Call</Label>
            </div>
            {/* // ) : (
            //   <div className="d-flex justify-content-center">
            //     <Label>Missed Call</Label>
            //   </div>
            // )} */}
            <div className="d-flex justify-content-center">
              <p>{directionData?.DialString}</p>
              <p>{directionData?.DisplayName}</p>
              {/* Display other call data properties similarly */}
            </div>

            <div
              className="mt-2 d-flex justify-content-center"
              style={{ flex: 2 }}
            >
              <div
                className="d-flex justify-content-center"
                style={{ flex: 1 }}
              >
                <Button
                  color="secondary"
                  onClick={() => handleHangupAndCloseModal()}
                >
                  Cancel
                </Button>
              </div>
              <div
                className="d-flex justify-content-center"
                style={{ flex: 1 }}
              >
                {/* <Link to={`/dashboard/sms`}> */}
                <Button color="primary" onClick={() => handleAnswer()}>
                  Answer
                </Button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default IncomingcallModal;
