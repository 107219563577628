import React from "react";

export const PermApplicationSendingStatus = (data) => {

  let badgeColor;
  let badgeLable;

   switch (data) {
    case "NOT_SENT":
      badgeLable = "Send";
      badgeColor = "warning";
      break;
    case "SENT":
      badgeLable = "Sent";
      badgeColor = "success";
      break;
  }

  return { badgeColor: badgeColor, badgeLable: badgeLable };
};
