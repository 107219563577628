import React, { useState, useEffect } from "react";
import "./working-card-widget.style.css";
import { Badge, Card, CardBody, Button, Label } from "reactstrap";
import logo from "../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

import {
  faMars,
  faVenus,
  faPhone,
  faEnvelope,
  faCalendar,
  faPhoneSlash,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import ModalComponent from "../../components/Modal/modalComponent";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import {
  HOSTMexxar,
  RESCHEDULE,
  HostMexxarURL,
} from "../../configs/api-config";
import {
  CandidateStage,
  GenderSwitch,
  ConversationResponse,
  CandidateActiveStatusBadge,
} from "../../util/common";
import {
  CandidateActiveStatus,
  CandidateStatus,
} from "../../util/common/candidateStatus";

import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { UncontrolledTooltip } from "reactstrap";
import AvatarMexxar from "../../util/common/avatarMexxar";

const WorkingCardWidget = (props) => {
  const MexxarUrl = HostMexxarURL;
  const MexxarUrlHost = HOSTMexxar;

  const MexxarApiReschedule = RESCHEDULE;
  const { addToast } = useToasts();

  const {
    userProfile,
    auth,
    isSelected,
    onItemSelect,
    selectAll,
    contactView,
  } = props;
  const [calendarShow, setCalendarShow] = useState(false);
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [candidateUser, setCandidateUser] = useState();
  const [selectedCandidate, setSelectedCandidate] = useState({});

  const toggleShowEmailModal = (user) => {
    setShowEmailModal(!showEmailModal);
    setCandidateUser(user);
  };
  const [profilePicture, setProfilePicture] = useState();
  const MexxarHostAPI = HOSTMexxar;

  const resheduleCall = (data) => {
    let rescheduleDate = moment(data.date).add(data.time).toISOString();
    //  console.log("erschedule data and time",data)
    axios
      .post(MexxarApiReschedule, {
        callerId: auth.item.id,
        receiverId: userProfile.id,
        callDate: moment(new Date()).toISOString(),
        rescheduleDate: rescheduleDate,
      })
      .then((res) => {
        //  setCandidateId(res.data.body);
        ToastCommon(true, "success", addToast);
        setCalendarShow(!calendarShow);
      })
      .catch((err) => {
        //console.log("eror in getting response", err);
        // ToastCommon(true, "error", addToast);
      });
  };

  // function getBase64(file, cb) {
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = function () {
  //     cb(reader.result);
  //   };
  //   reader.onerror = function (error) {
  //     console.log("Error: ", error);
  //   };
  // }

  const toggleM = () => setShowEmailModal(!showEmailModal);

  // useEffect(() => {
  //   loadCandidateData();
  // }, []);

  return (
    <>
      <div
        className=" card d-flex flex-row main bg-light p-1 "
        style={{ marginBottom: "5px" }}
      >
        {!selectAll ? (
          <div className="d-flex align-items-center mr-3 ">
            <span className="mx-2">
              {/* <b className="badge badge-circle sm text-primary"></b> */}
              <label className="ui-check m-0">
                <input
                  type="checkbox"
                  name="id"
                  checked={isSelected(userProfile?.email)}
                  onChange={() => onItemSelect(userProfile?.email)}
                />{" "}
                <i></i>
              </label>
            </span>
          </div>
        ) : null}

        <AvatarMexxar
          candidateId={userProfile?.id}
          primaryPhone={userProfile?.primaryPhone}
          avatar={userProfile?.avatar}
          gender={userProfile?.gender}
        />

        <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
          <div className="d-flex ml-1 mb-1" id={`candidateID${userProfile.id}`}>
            <Label>
              {userProfile.firstName}&nbsp;{userProfile.lastName}
            </Label>
          </div>
          <UncontrolledTooltip
            className="d-flex flex-column justify-content-start align-items-center p-2"
            target={`candidateID${userProfile.id}`}
          >
            <div className="d-flex border-bottom mb-2 ">Show more</div>
            <span className="d-flex">
              ID:&nbsp; <Badge className="p-1 m-0">{userProfile.id}</Badge>
            </span>
            <div>
              <Label className="text-left">
                Full name: {userProfile.firstName}&nbsp;{userProfile.middleName}
                &nbsp;
                {userProfile.lastName}{" "}
              </Label>
            </div>
          </UncontrolledTooltip>
          <div className=" d-flex ">
            <div className="mx-2 ">
              {/* <Link
                to={{
                  pathname: "/dashboard/call",
                  aboutParams: {
                    imageLink: userProfile.image,
                  },
                }}
              > */}

              <Link to={"/dashboard/call/" + userProfile.id}>
                <RoundIconButton
                  buttonColor="#00C426"
                  icon={faPhone}
                  iconSize="fa-md"
                  buttonSize="25px"
                ></RoundIconButton>
              </Link>

              {/* </Link> */}
            </div>
            {/* <div className="mx-2 ">
              <RoundIconButton
                buttonColor="#458AF7"
                icon={faEnvelope}
                click={() => toggleShowEmailModal(userProfile)}
                buttonSize="25px"
              ></RoundIconButton>
            </div> */}
            {/* <div className=" mx-2  d-flex align-items-center ">
              <FontAwesomeIcon
                color="#FF5603"
                icon={faCalendarAlt}
                onClick={() => handleCalendarShow(true)}
                className="fa-lg mb-2 btn-raised btn-wave "
              />
            </div> */}
          </div>
          <div>
            <Badge color={"warning"}>{userProfile.postalCode?.county}</Badge>
          </div>
        </div>
        <div className="d-flex align-items-center" style={{ flex: 1 }}>
          <FontAwesomeIcon
            // color={userProfile.gender == "1" ? "#458AF7" : "#f54394"}
            color={GenderSwitch(userProfile.gender).genderColor}
            // icon={userProfile.gender == "1" ? faMars : faVenus}
            icon={GenderSwitch(userProfile.gender).genderSymbol}
          />
        </div>
        <div
          className="d-flex align-items-center justify-content-center flex-column"
          style={{ flex: 3 }}
        >
          <div style={{ height: "4vh", flex: 1 }}>
            {contactView ? (
              <Label>Email Address</Label>
            ) : (
              <Label>Call Response</Label>
            )}
          </div>
          <div style={{ height: "3vh", flex: 1 }}>
            {contactView ? (
              <Label>{userProfile.email}</Label>
            ) : (
              <Label>
                <Badge
                  color={
                    ConversationResponse(userProfile.callResponseStatus)
                      .badgeColor
                  }
                >
                  {
                    ConversationResponse(userProfile.callResponseStatus)
                      .badgeLable
                  }
                </Badge>
              </Label>
            )}
          </div>
          <div style={{ height: "3vh", flex: 1 }}></div>
        </div>
        <div
          className="d-flex flex-column align-items-center "
          style={{ flex: 3 }}
        >
          <div style={{ height: "4vh" }}>
            {contactView ? (
              <Label>Phone no</Label>
            ) : (
              <Label>Work Flow Status</Label>
            )}
          </div>
          <div style={{ height: "3vh", flex: 1 }}>
            {contactView ? (
              <div className="d-flex justify-content-center flex-column">
                <Label>{userProfile?.primaryMobile}</Label>
              </div>
            ) : (
              <Label>
                <Badge
                  style={{
                    backgroundColor: CandidateStatus(userProfile.workFlowStatus)
                      .badgeColor,
                  }}
                >
                  {CandidateStatus(userProfile.workFlowStatus).badgeLable}
                </Badge>
              </Label>
            )}
          </div>
          <div style={{ height: "3vh" }}></div>
        </div>
        {/* <div style={{ flex: 2 }}>
          <div style={{ height: "4vh", flex: 1 }}></div>
          <div style={{ height: "3vh", flex: 1 }}></div>
          <div style={{ height: "3vh", flex: 1 }}></div>
        </div> */}


        {/* <div
          className="d-flex align-items-center flex-column "
          style={{ flex: 3 }}
        >
          <div style={{ height: "4vh" }}>
            {contactView ? (
              <Label>County</Label>
            ) : (
              <Label>Profile Status</Label>
            )}
          </div>
          <div style={{ height: "3vh" }}>
            {contactView ? (
              <div className="d-flex flex-column align-items-center">
                <Label>{userProfile?.postalCode?.county}</Label>
                <Label>{userProfile?.postalCode?.code}</Label>
              </div>
            ) : (
              <Label>
                <Badge
                  style={{
                    backgroundColor: CandidateActiveStatusBadge(
                      userProfile.status
                    ).badgeColor,
                  }}
                >
                  {CandidateActiveStatusBadge(userProfile.status).badgeLable}
                </Badge>
              </Label>
            )}
          </div> */}


        <div className="d-flex align-items-center flex-column" style={{ flex: 3 }}>
          <div style={{ height: "4vh" }}>
            {contactView ? (
              <Label>County</Label>
            ) : (
              <Label>Profile Status</Label>
            )}
          </div>
          <div style={{ height: "3vh" }}>
            {contactView ? (
              <div className="d-flex flex-column align-items-center">
                <Label>{userProfile?.postalCode?.county}</Label>
                <Label>{userProfile?.postalCode?.code}</Label>
              </div>
            ) : (
              <>
                <Label>
                  <Badge
                    style={{
                      backgroundColor: CandidateActiveStatusBadge(
                        userProfile.status
                      ).badgeColor,
                    }}
                  >
                    {CandidateActiveStatusBadge(userProfile.status).badgeLable}
                  </Badge>
                </Label>

                {/* {userProfile.status === "DORMANT" && (
                  <Label className="text-left">

                    {userProfile.headhunters.map((headhunter, index) => (
                      <span key={index}>{headhunter.usersName}</span>
                    ))}
                  </Label>
                )} */}
              </>
            )}
          </div>
        </div>

        <div
          className="d-flex justify-content-center "
          style={{ height: "3vh" }}
        >
          <span className="mr-1">
            <small className="text-muted">{userProfile.date}</small>
          </span>
          <span className="mr-1">
            <small className="text-muted">{userProfile.time}</small>
          </span>
        </div>
      </div>
      <div>
        {/* <Label className="text-left">
  Employee Names: 
  {userProfile.headhunters.map((headhunter, index) => (
    <span key={index}>{headhunter.usersName}</span>
  ))}
</Label> */}

        {/* </div> */}

      </div>
      <ModalComponent
        show={calendarShow}
        header="Reschedule a call"
        closeModal={() => setCalendarShow(false)}
      >
        <SmallCalendar
          onCreate={(data) => resheduleCall(data)}
          closeModal={() => setCalendarShow(false)}
        />
      </ModalComponent>

      <ModalComponent
        show={showEmailModal}
        header="E mail and sms"
        closeModal={() => toggleM()}
      >
        <EmailNav
          closeModal={() => toggleM()}
          user={candidateUser}
          incomingPage="FullCandidateList"
        />
      </ModalComponent>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(WorkingCardWidget);
