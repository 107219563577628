import React from "react";
import { Badge } from "reactstrap";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import EmployeeUpcommingTasks from '../../widgets/dashboard-widgets/upcommingTasks/employeeUpcommingTasks';

const Trainings = () => {
  return (
    <>
      {" "}
      <DashboardLayout title="Trainings" subTitle="Trainings">
          {/* <div className="card"> */}
            <EmployeeUpcommingTasks></EmployeeUpcommingTasks>
          {/* </div> */}
      </DashboardLayout>
    </>
  );
};

export default Trainings;
