import React, { Component, useRef } from "react";

import {
  faFileAlt,
  faChevronCircleLeft,
  faChevronCircleRight,
  faTimes,
  faDownload,
  faImage,
  faRedoAlt,
  faCheckDouble,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import {
  ModalBody,
  ModalFooter,
  ModalHeader,
  Modal,
  Label,
  Badge,
} from "reactstrap";
import axios from "axios";
import moment from "moment";
import { forEach, split } from "lodash";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import ChatService from "../services/chat.service";
import ScrollableFeed from "react-scrollable-feed";
import loading from "../../../../src/images/puff.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HOSTMexxar, READ_MESSAGES } from "../../../configs/api-config";

var windowHeight = window.innerHeight;
var windowWidth = window.innerWidth;

const ChatConversation = (props) => {
  const [chatarray, setChatarray] = useState([]);
  const [showPhoto, setShowPhoto] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [chatReadList, setChatReadList] = useState([]);
  const [downloadShowFile, setDownloadShowFile] = useState();
  // const [messagesEndRef, setMessagesEndRef] = useState(null);
  const [currentTutorial, setCurrentTutorial] = useState(null);
  const [photoData, setPhotoData] = useState({ data: "", url: "" });
  const [fileLoadingStatus, setFileLoadingStatus] = useState("success");

  const fileShowDownload = (file) => {
    setFileLoadingStatus("pending");
    setPhotoData({ data: file, url: "" });

    let docType = split(file.docUrl, ".");
    let type = docType[1];

    if (
      type == "png" ||
      type == "jpg" ||
      type == "JPG" ||
      type == "jpeg" ||
      type == "JPEG"
    ) {
      setShowPhoto(true);
    } else {
      ///download the file
      axios({
        url: HOSTMexxar + "chat-doc/" + Number(file.id),
        method: "GET",
        responseType: "blob", // important
      }).then(
        (res) => {
          console.log("res.status", res.status);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "Download (" + moment(new Date()).format("LLLL") + ")." + type
          );
          document.body.appendChild(link);
          link.click();

          setFileLoadingStatus("success");
        },
        (error) => {
          setFileLoadingStatus("failed");
        }
      );
    }
  };

  // useEffect(() => {
  //   // if (messagesEndRef != null) {
  //   //   messagesEndRef.scrollIntoView({ behavior: "smooth", block: "end" });
  //   // }
  // });

  const getChatList = () => {
    // console.log(props.reduxChatScriptId);
    // ChatService.getAll(props.reduxChatScriptId).on("value", onDataChange);
  };

  const onDataChange = (items) => {
    let secondaryChatArray = [];

    items.forEach((item) => {
      let key = item.key;
      let data = item.val();
      secondaryChatArray.push({
        key: key,
        title: data.title,
        time: data.time,
        description: data.description,
        published: data.published,
        isActive: data.isActive,
        isOnline: data.isOnline,
        sender: data.sender,
      });
    });

    setChatarray(secondaryChatArray);
  };

  const refreshList = () => {
    setCurrentTutorial(null);
    setCurrentIndex(-1);
  };

  const setActiveTutorial = (chatmsg, index) => {
    setCurrentTutorial(chatmsg);
    setCurrentIndex(index);
  };

  const removeAllTutorials = () => {
    ChatService.deleteAll();
    refreshList();
  };

  // const updateMessageAsRead = (id) =>{
  //   setChatReadList([...chatReadList, id])
  // }

  useEffect(() => {
    setTimeout(function () {
      axios
        .post(READ_MESSAGES, chatReadList)
        .then((res) => {})
        .catch((err) => {});
    }, 5000);
  }, [chatReadList]);

  useEffect(() => {
    ChatService.getAll(props.reduxChatScriptId).on("value", onDataChange);
    return () => {
      ChatService.getAll(props.reduxChatScriptId).off("value", onDataChange);
    };
  }, [props.reduxChatScriptId]);

  // useEffect(() => {
  //   ChatService.getAll(props.reduxChatScriptId).on("value", onDataChange);
  //   return () => {
  //     ChatService.getAll(props.reduxChatScriptId).off("value", onDataChange);
  //   };
  // }, []);

  const getPhoto = (data) => {
    axios({
      url: HOSTMexxar + "chat-doc/" + Number(data.id),
      method: "GET",
      responseType: "blob", // important
    }).then(
      (res) => {
        // props.hideAlertAction();
        const url = window.URL.createObjectURL(new Blob([res.data]));
        setPhotoData({ data: data, url: url });
      },
      (error) => {}
    );
    // window.open(`${DOWNLOAD_CV}${id}`, '_blank').focus();
  };
  const downloadPhotoFile = () => {
    let docType = split(photoData.data.docUrl, ".");
    let type = docType[1];

    const link = document.createElement("a");
    link.href = photoData.url;
    link.setAttribute(
      "download",
      "Download (" + moment(new Date()).format("LLLL") + ")." + type
    );
    document.body.appendChild(link);
    link.click();
  };

  // const changeDetectionFilter = (previousProps, newProps) => {
  //   const prevChildren = previousProps.children;
  //   const newChildren = newProps.children;

  //   return (
  //     prevChildren !== newChildren &&
  //     prevChildren[prevChildren.length - 1] !==
  //       newChildren[newChildren.length - 1]
  //   );
  // };
  const [isAtBottom, setIsAtBottom] = useState(true);
  const scrollableRef = useRef(null);

  function updateIsAtBottomState(result) {
    setIsAtBottom(result);
  }

  useEffect(() => {
    scrollableRef.current.scrollToBottom();
  }, [props.activeChat]);

  // useDidMountEffect(() => {
  //   let activeChat = find(chatPostLoad, function (item) {
  //     return item.user2sId == activeChat;
  //   });

  //   forEach(activeChat, function (item) {

  //   });
  // }, [props.activeChat]);

  return (
    <div
    // ref={(el) => {
    //   setMessagesEndRef(el);
    // }}
    >
      {1 == 0 ? (
        <div className="align-items-center d-flex">
          <div
            className="mx-auto col-md-4 "
            style={{ textAlign: "center", marginTop: "10rem" }}
          >
            Please double click on a chat or create a new chat to start
            messaging.
          </div>
        </div>
      ) : (
        <div style={{ height: "65vh" }}>
          <ScrollableFeed
            ref={scrollableRef}
            onScroll={(isAtBottom) => updateIsAtBottomState(isAtBottom)}
          >
            {props.chatPostLoad == undefined
              ? null
              : props.chatPostLoad.map((chatmsg, index) => (
                  <div key={index}>
                    {chatmsg.messages == undefined
                      ? null
                      : chatmsg.messages
                          .sort(function compare(a, b) {
                            var dateA = new Date(a.date);
                            var dateB = new Date(b.date);
                            return dateA - dateB;
                          })
                          .map((chatmsg2, index) => (
                            <div key={index}>
                              <div>
                                {/* {chatmsg.user1sId == props.userId ? ( */}
                                <div>
                                  {/* {chatmsg2.sendersId !== props.userId?updateMessageAsRead(chatmsg2.id):null} */}

                                  {chatmsg.user2sId == props.activeChat ? (
                                    <div
                                      className={`chat-item d-flex ${
                                        chatmsg2.sendersId == props.userId
                                          ? "justify-content-end"
                                          : "justify-content-start"
                                      }`}
                                      style={{
                                        visibility: "visible",
                                        transform: "none",
                                        opacity: 1,
                                        transition:
                                          "transform 0.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0s, opacity 0.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0s",
                                      }}
                                    >
                                      <div className="chat-body ">
                                        <div
                                          // className="chat-content rounded msg"
                                          className="chat-content rounded msg bg-body"

                                          // style={{
                                          //   backgroundColor:
                                          //     chatmsg2.sendersId ==
                                          //     props.userId
                                          //       ? "#151824"
                                          //       : "#191e2e",
                                          // }}
                                        >
                                          {chatmsg2.messageType == "FILE" ? (
                                            split(chatmsg2.docUrl, ".")[1] ==
                                              "jpg" ||
                                            split(chatmsg2.docUrl, ".")[1] ==
                                              "jpeg" ||
                                            split(chatmsg2.docUrl, ".")[1] ==
                                              "JPG" ||
                                            split(chatmsg2.docUrl, ".")[1] ==
                                              "JPEG" ||
                                            split(chatmsg2.docUrl, ".")[1] ==
                                              "png" ? (
                                              <>
                                                <FontAwesomeIcon
                                                  icon={faImage}
                                                  className="fa-2x btn-raised"
                                                  onClick={() => {
                                                    fileShowDownload(chatmsg2);
                                                    getPhoto(chatmsg2);
                                                  }}
                                                ></FontAwesomeIcon>
                                              </>
                                            ) : (
                                              <>
                                                <Label>Attachment</Label>
                                                {fileLoadingStatus ==
                                                  "pending" &&
                                                chatmsg2.id ==
                                                  photoData.data.id ? (
                                                  <img
                                                    className="mx-1"
                                                    src={loading}
                                                    style={{ height: 20 }}
                                                  ></img>
                                                ) : fileLoadingStatus ==
                                                    "failed" &&
                                                  chatmsg2.id ==
                                                    photoData.data.id ? (
                                                  <FontAwesomeIcon
                                                    icon={faRedoAlt}
                                                    className="mx-1 mt-1 fa-sm btn-raised"
                                                    style={{
                                                      boxShadow: "none",
                                                    }}
                                                    onClick={() => {
                                                      fileShowDownload(
                                                        chatmsg2
                                                      );
                                                    }}
                                                  />
                                                ) : (
                                                  <FontAwesomeIcon
                                                    className="btn-raised mx-1"
                                                    onClick={() => {
                                                      fileShowDownload(
                                                        chatmsg2
                                                      );
                                                    }}
                                                    icon={faDownload}
                                                    style={{
                                                      boxShadow: "none",
                                                    }}
                                                  />
                                                )}
                                              </>
                                            )
                                          ) : (
                                            <>
                                              {chatmsg2.message}
                                              <div className="chat-date date ml-1 mt-2">
                                                {moment(
                                                  chatmsg2.date,
                                                  "YYYY-MM-DD h:mm:ss a "
                                                ).fromNow()}
                                                <FontAwesomeIcon
                                                  color={
                                                    chatmsg2.messageRead ===
                                                    true
                                                      ? "#458AF7"
                                                      : "#cccccc"
                                                  }
                                                  className="ml-1 "
                                                  icon={
                                                    chatmsg2.messageDelivered ===
                                                    true
                                                      ? faCheckDouble
                                                      : faCheck
                                                  }
                                                  style={{
                                                    boxShadow: "none",
                                                  }}
                                                />
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                {/* ) : null} */}
                              </div>

                              <div>
                                {chatmsg.user2sId == props.userId ? (
                                  <div>
                                    {chatmsg.user1sId == props.activeChat ? (
                                      <div
                                        className={`chat-item d-flex ${
                                          chatmsg2.sendersId == props.userId
                                            ? "justify-content-end "
                                            : "justify-content-start"
                                        }`}
                                        style={{
                                          visibility: "visible",
                                          transform: "none",
                                          opacity: 1,
                                          transition:
                                            "transform 0.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0s, opacity 0.5s cubic-bezier(0.6, 0.2, 0.1, 1) 0s",
                                        }}
                                      >
                                        <div className="chat-body">
                                          <div
                                            className="chat-content rounded msg bg-body"
                                            //  // className="chat-content rounded msg  "
                                            //   style={{
                                            //     backgroundColor:
                                            //       chatmsg2.sendersId ==
                                            //       props.userId
                                            //         ? "#151824"
                                            //         : "#191e2e",
                                            //   }}
                                          >
                                            {chatmsg2.messageType == "FILE" ? (
                                              split(chatmsg2.docUrl, ".")[1] ==
                                                "jpg" ||
                                              split(chatmsg2.docUrl, ".")[1] ==
                                                "jpeg" ||
                                              split(chatmsg2.docUrl, ".")[1] ==
                                                "JPG" ||
                                              split(chatmsg2.docUrl, ".")[1] ==
                                                "JPEG" ||
                                              split(chatmsg2.docUrl, ".")[1] ==
                                                "png" ? (
                                                <>
                                                  <FontAwesomeIcon
                                                    icon={faImage}
                                                    className="fa-2x btn-raised"
                                                    onClick={() => {
                                                      fileShowDownload(
                                                        chatmsg2
                                                      );
                                                      getPhoto(chatmsg2);
                                                    }}
                                                  ></FontAwesomeIcon>
                                                </>
                                              ) : (
                                                <>
                                                  <Label>Attachment</Label>
                                                  {fileLoadingStatus ==
                                                    "pending" &&
                                                  chatmsg2.id ==
                                                    photoData.data.id ? (
                                                    <img
                                                      className="mx-1"
                                                      src={loading}
                                                      style={{ height: 20 }}
                                                    ></img>
                                                  ) : fileLoadingStatus ==
                                                      "failed" &&
                                                    chatmsg2.id ==
                                                      photoData.data.id ? (
                                                    <FontAwesomeIcon
                                                      icon={faRedoAlt}
                                                      className="mx-1 mt-1 fa-sm btn-raised"
                                                      style={{
                                                        boxShadow: "none",
                                                      }}
                                                      onClick={() => {
                                                        fileShowDownload(
                                                          chatmsg2
                                                        );
                                                      }}
                                                    />
                                                  ) : (
                                                    <FontAwesomeIcon
                                                      className="btn-raised mx-1"
                                                      onClick={() => {
                                                        fileShowDownload(
                                                          chatmsg2
                                                        );
                                                      }}
                                                      icon={faDownload}
                                                      style={{
                                                        boxShadow: "none",
                                                      }}
                                                    />
                                                  )}
                                                </>
                                              )
                                            ) : (
                                              chatmsg2.message
                                            )}
                                          </div>
                                          <div className="chat-date date justify-content-end">
                                            {moment(
                                              chatmsg2.date,
                                              "YYYY-MM-DD h:mm:ss a"
                                            ).fromNow()}
                                            <FontAwesomeIcon
                                            className="mx-1"
                                              color={
                                                chatmsg2.messageRead === true
                                                  ? "#458AF7"
                                                  : "#cccccc"
                                              }
                                              // onClick={() => {
                                              //   fileShowDownload(chatmsg2);
                                              // }}
                                              icon={
                                                chatmsg2.messageDelivered ===
                                                true
                                                  ? faCheckDouble
                                                  : faCheck
                                              }
                                              style={{
                                                boxShadow: "none",
                                              }}
                                            /> 
                                          </div>
                                        </div>
                                      </div>
                                    ) : null}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ))}
                  </div>
                ))}
          </ScrollableFeed>
        </div>
      )}

      <Modal
        isOpen={showPhoto}
        style={{ opacity: 0.96, width: windowWidth }}
        className="d-flex  m-1"
      >
        <div
          style={{
            height: "35px",
            width: windowWidth - 5,
            backgroundColor: "#ebebec",
          }}
          className="d-flex align-items-center justify-content-between flex-row mr-1"
        >
          <div> </div>
          <div></div>
          <div>
            <FontAwesomeIcon
              icon={faTimes}
              className="fa-lg mx-3 btn-raised"
              onClick={() => setShowPhoto(false)}
              style={{
                boxShadow: "none",
              }}
            />
          </div>
        </div>
        <div
          className="d-flex align-items-center justify-content-between flex-row
          "
          style={{
            height: windowHeight - 85,
            width: windowWidth - 5,
            backgroundColor: "#fff",
          }}
        >
          <div>
            {/* <FontAwesomeIcon
              className="fa-3x feather feather-more-vertical text-fade mx-3"
              icon={faChevronCircleLeft}
            /> */}
          </div>
          <div>
            <div className={"m-1"}>
              <div>
                <div className="d-flex justify-items-center align-items-center flex-column">
                  <div style={{ width: "100%", height: "100%" }}>
                    <img src={photoData.url} width="100%" height="100%" />
                  </div>

                  {/* {photo.name.length > 10 ? (
                    "Image " + photo.id
                  ) : (
                    <Label>{photo.name}</Label>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* <FontAwesomeIcon
              className="fa-3x feather feather-more-vertical text-fade  mx-3"
              icon={faChevronCircleRight}
            /> */}
          </div>
        </div>
        <div
          style={{
            height: "50px",
            width: windowWidth - 5,
            backgroundColor: "#ebebec",
          }}
          className="d-flex align-items-center justify-content-between flex-row "
        >
          <div> </div>
          <div>
            <FontAwesomeIcon
              icon={faDownload}
              onClick={downloadPhotoFile}
              style={{
                boxShadow: "none",
              }}
              className="fa-lg ml-3 btn-raised "
            />
          </div>
          <div></div>
        </div>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userId: state.auth.item.id,
    chatPostLoad: state.chatDbPostReducer.chatDbPostLoad,
    reduxChatScriptId: state.ChatRedux.chatfunkyId,
    activeChat: state.chatActiveIdReducer.chatActive,
  };
}

export default connect(mapStateToProps, {})(ChatConversation);
