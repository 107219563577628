const initState = {
    chatActive: null
}

const chatReducer = (chatstate = initState, action) => {
    if (action.type === 'chatActiveSelection') {

        initState.chatActive = action.chatActiveDispatch
       
        }
        return initState;
}
   

export default chatReducer;