import React, { useState } from "react";
import { Button, Modal } from "reactstrap";

export default function RestrictGoBack(props) {
  const { isConfirm, closeModal, show = false } = props;
  return (
    <Modal
      isOpen={show}
      className="text-gray "
      centered={true}
      style={{ width: "350px" }}
      backdrop={true}
      keyboard={true}
    >
      <div className="p-3">
        <p className="font-weight-bold">No Call Log</p>
        <p className="text-align-center">
          Please add a call log before exiting the page. Otherwise, the call log will not be recorded.
        </p>
        <div className="float-right">
          <Button
            color="danger"
            className="mr-1"
            onClick={() => isConfirm(true)}
          >
            Close
          </Button>
          {/* <Button color="secondary" onClick={() => isConfirm(false)}>
            Close
          </Button> */}
        </div>
      </div>
    </Modal>
  );
}
