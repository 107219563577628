import { Badge, Label, UncontrolledTooltip } from "reactstrap";
import loading from "../../../../src/images/puff.svg";
import { connect } from "react-redux";

function NHSPMagicResultsTable(props) {
  const {
    theme,
    groupRows,
    addBooking,
    setBookedReferenceNos,
    bookedReferenceNos,
    magicWardsMatch,
    magicWardsNotMatch,
  } = props;
  return (
    <>
      <div>
        <table>
          <thead>
            {groupRows !== undefined ? (
              <>
                <tr className="booking-tr-th">
                  <th
                    className={`${
                      theme === "bg-dark"
                        ? "booking-th booking-tr-th-child-one"
                        : "w-booking-th w-booking-tr-th-child-one"
                    }`}
                  >
                    Bank Req Num
                  </th>
                  <th
                    className={`${
                      theme === "bg-dark"
                        ? "booking-th booking-tr-th-child-two"
                        : "w-booking-th w-booking-tr-th-child-two"
                    }`}
                  >
                    Date
                  </th>
                  <th
                    className={`${
                      theme === "bg-dark"
                        ? "booking-th booking-tr-th-child-three"
                        : "w-booking-th w-booking-tr-th-child-three"
                    }`}
                  >
                    Start Time
                  </th>
                  <th
                    className={`${
                      theme === "bg-dark"
                        ? "booking-th booking-tr-th-child-three"
                        : "w-booking-th w-booking-tr-th-child-three"
                    }`}
                  >
                    End Time
                  </th>

                  <th
                    className={`${
                      theme === "bg-dark"
                        ? "booking-th booking-tr-th-child-three"
                        : "w-booking-th w-booking-tr-th-child-three"
                    }`}
                  >
                    Ward
                  </th>
                  <th
                    className={`${
                      theme === "bg-dark"
                        ? "booking-th booking-tr-th-child-three"
                        : "w-booking-th w-booking-tr-th-child-three"
                    }`}
                  >
                    Assignment
                  </th>
                  <th
                    className={`${
                      theme === "bg-dark"
                        ? "booking-th booking-tr-th-child-three"
                        : "w-booking-th w-booking-tr-th-child-three"
                    }`}
                  >
                    Training
                  </th>
                  <th
                    className={`${
                      theme === "bg-dark"
                        ? "booking-th booking-tr-th-child-three"
                        : "w-booking-th w-booking-tr-th-child-three"
                    }`}
                  >
                    sex
                  </th>
                  <th
                    className={`${
                      theme === "bg-dark"
                        ? "booking-th booking-tr-th-child-three"
                        : "w-booking-th w-booking-tr-th-child-three"
                    }`}
                  >
                    Secondary Assignment
                  </th>
                  <th
                    className={`${
                      theme === "bg-dark"
                        ? "booking-th booking-tr-th-child-three"
                        : "w-booking-th w-booking-tr-th-child-three"
                    }`}
                  >
                    Candidates
                  </th>
                </tr>
              </>
            ) : (
              <div></div>
            )}
          </thead>
          <tbody>
            {groupRows != undefined ? (
              <>
                {groupRows.map((groupRow, id) => (
                  <tr key={id}>
                    <td
                      // key={id}
                      className={`${
                        theme === "bg-dark"
                          ? "booking-td booking-td-th-child-one"
                          : "w-booking-td w-booking-td-th-child-one"
                      }`}
                    >
                      {groupRow.refNo}
                    </td>
                    <td
                      className={`d-flex justify-content-start ${
                        theme === "bg-dark"
                          ? "booking-td booking-td-th-child-two"
                          : "w-booking-td w-booking-td-th-child-two"
                      }`}
                    >
                      {/* <div className="d-flex justify-content-start"> */}
                      {groupRow.shiftDate}
                      {/* </div> */}
                    </td>

                    <td
                      className={`${
                        theme === "bg-dark"
                          ? "booking-td booking-td-th-child-three"
                          : "w-booking-td w-booking-td-th-child-three"
                      }`}
                    >
                      <div className="d-flex justify-content-start">
                        {groupRow.startTime}
                      </div>
                    </td>
                    <td
                      className={`${
                        theme === "bg-dark"
                          ? "booking-td booking-td-th-child-three"
                          : "w-booking-td w-booking-td-th-child-three"
                      }`}
                    >
                      <div className="d-flex justify-content-start">
                        {groupRow.endTime}
                      </div>
                    </td>

                    {/* <td
                      className={`${
                        theme === "bg-dark"
                          ? "booking-td booking-td-th-child-three"
                          : "w-booking-td w-booking-td-th-child-three "
                      }`}
                    >
                      <div className="d-flex justify-content-start">
                        {groupRow.ward}
                      </div>
                    </td> */}
                    <td
  className={`${theme === 'bg-dark' ? 'booking-td' : 'w-booking-td'}`}
  style={{
    color: magicWardsNotMatch.includes(groupRow.ward) ? 'blue' : 'inherit',
    // Add more inline styles as needed
  }}
>
  {groupRow.ward}
</td>

                    <td
                      className={`${
                        theme === "bg-dark"
                          ? "booking-td booking-td-th-child-three"
                          : "w-booking-td w-booking-td-th-child-three"
                      }`}
                    >
                      <div className="d-flex justify-content-start">
                        {groupRow.assignment}
                      </div>
                    </td>
                    <td
                      className={`${
                        theme === "bg-dark"
                          ? "booking-td booking-td-th-child-three"
                          : "w-booking-td w-booking-td-th-child-three"
                      }`}
                    >
                      <div className="d-flex justify-content-start">
                        {groupRow.training}
                      </div>
                    </td>
                    <td
                      className={`${
                        theme === "bg-dark"
                          ? "booking-td booking-td-th-child-three"
                          : "w-booking-td w-booking-td-th-child-three"
                      }`}
                    >
                      <div className="d-flex justify-content-start">
                        {groupRow.sex}
                      </div>
                    </td>
                    <td
                      className={`${
                        theme === "bg-dark"
                          ? "booking-td booking-td-th-child-three"
                          : "w-booking-td w-booking-td-th-child-three"
                      }`}
                    >
                      <div className="d-flex justify-content-start">
                        {groupRow.secondaryAssignemnt}
                      </div>
                    </td>

                    <td
                      className={`${
                        theme === "bg-dark"
                          ? "booking-td booking-td-th-child-three last"
                          : "w-booking-td w-booking-td-th-child-three last"
                      }`}
                    >
                      {groupRow.AvailableCandidates.map((item, id) => (
                        <Badge
                          key={id}
                          className="m-1 btn btn-raised "
                          style={{ backgroundColor: "#457dd9" }}
                          onClick={() => {
                            addBooking(groupRow, item);
                            setBookedReferenceNos([
                              ...bookedReferenceNos,
                              groupRow.refNo,
                            ]);
                          }}
                        >
                          {item.firstName}&nbsp;{item.lastName}
                          {/* B:{ward.band} */}
                        </Badge>
                      ))}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <div>
                {/* Loading animation */}
                <div className="d-flex justify-content-center justify-items-center">
                  <div>
                    <img src={loading} style={{ height: 100 }}></img>
                  </div>
                </div>
              </div>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

function mapStateToProps(state) {
  return {
    theme: state.theme.theme,
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(NHSPMagicResultsTable);
