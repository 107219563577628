import React from "react";
import "./invitations.style.css";
import { Alert, Button } from "reactstrap";
import { EditableInputWidget } from "../../../widgets";

const Invitations = () => {
  // const isLinkStatus = post.linkStatus;
  return (
    <>
      {/* 
     
     isLinkStatus ? not yet sent show allert 
     <Alert color="info">
        <h5>Send an application join link to candidate?</h5>
        <p>
          Candidates aren't automatically invited into the system as you may
          need additional time before filling up the candidate information. Once
          you're ready to invite a candidate into the system, please use the
          form below to send an invitation. They will get and email invitation.
        </p>
      </Alert>
      
 isLinkStatus ? not yet clicked by the candidate show allert 
 <Alert color="info">
        <h5>Resend an application join link to candidate?</h5>
        <p>
           Are  you sure you want to resend an invitation to a candidate ? 
           please click
          below button to send an invitation. 
        </p>
      </Alert>

      isLinkStatus ? expired show allert 
     <Alert color="danger">
        <h5>Resend an application join link to candidate?</h5>
        <p>
          Candidates aren't automatically invited into the system if the link is expired.Once
          you're ready to resend an invitation to a candidate, please click
          below button to send an invitation. 
        </p>
      </Alert>
    
     */}
      <Alert color="warning">
        <h5>Resend an application join link to candidate?</h5>
        <p>
          Candidates aren't automatically invited into the system if the link is
          expired.Once you're ready to resend an invitation to a candidate,
          please click below button to send an invitation.
        </p>
      </Alert>

      <div className="card">
        <div className="card-header">Generate and send link ?</div>
        <div className="card-body">
          <p>
            {" "}
            Please make sure that the email address of the candidate is correct
            and updated. After sending this link, you can cancel the sent link
            anytime. Invitation will be sent to this email:
            {/* {post.candidate.email} */} tharukawapnet@gmail.com
          </p>
          <Button color="success">Send Invitation</Button>
        </div>
      </div>
    </>
  );
};

export default Invitations;
