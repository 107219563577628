import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EmailLogoutButton = ({
  setShowLogOutConfirmation,
  showLogOutConfirmation,
}) => {
  const { instance, accounts } = useMsal();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const handleLogoutRedirect = (account) => {
    setShowConfirmation(false); // Hide confirmation modal

    // Logout only the selected account
    instance.logoutRedirect({
      account, // Target this specific account for logout
      postLogoutRedirectUri: "/dashboard/outlook/loginPage",
    });
  };

  const handleLogoutClick = (account) => {
    setSelectedAccount(account); // Set the account to be logged out
    setShowConfirmation(true); // Show confirmation modal
  };

  return (
    <>
      <small
        className="link pointer"
        onClick={() => {
          handleLogoutClick(accounts[0]);
          setShowLogOutConfirmation(!showLogOutConfirmation);
        }}
      >
        Logout
      </small>
      {accounts?.length > 1 && showLogOutConfirmation ? (
        <div>
          <p>Select an account to log out:</p>
          {accounts.map((account, index) => (
            <div key={index}>
              <span>{account?.username}</span>
              <Button
                onClick={() => handleLogoutClick(account)}
                className="btn-sm ml-1 d-flex align-item-end "
              >
                Logout
              </Button>
            </div>
          ))}
        </div>
      ) : null}

      {showConfirmation && selectedAccount && (
        <div className="confirmation-modal text-sm">
          <hr />
          <p className="text-danger">
            Log out from
            <br /> {selectedAccount.username}?
          </p>
          <Button
            onClick={() => handleLogoutRedirect(selectedAccount)}
            className="btn-sm mr-1 btn-success"
          >
            Yes
          </Button>
          <Button onClick={() => setShowConfirmation(false)} className="btn-sm">
            Cancel
          </Button>
        </div>
      )}
    </>
  );
};

export default EmailLogoutButton;
