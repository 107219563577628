import React, { useState, useEffect } from "react";
// import "./working-card-widget.style.css";
import { Badge, Card, CardBody, Button } from "reactstrap";
import logo from "../../images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMars,
  faVenus,
  faPhone,
  faEnvelope,
  faCalendar,
  faPhoneSlash,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import RoundIconButton from "../../components/RoundIconButton/round-icon-button";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import ModalComponent from "../../components/Modal/modalComponent";
import SmallCalendar from "../../components/Modal/ModalBody/smallCalendar";
import EmailNav from "../../components/Modal/ModalBody/emailNav";
import loading from "../../../src/images/puff.svg";
import { GenderSwitch } from "../../util/common";
import ToastCommon from "../../components/toastCommon";
import { useToasts } from "react-toast-notifications";
import { RESCHEDULE, HostMexxarURL } from "../../configs/api-config";
import { connect } from "react-redux";
import AvatarMexxar from "../../util/common/avatarMexxar";

const AuditRejectedWidget = (props) => {
  const { userProfile, auth } = props;
  const [calendarShow, setCalendarShow] = useState(false);
  let isStatus = "pending";
  const MexxarApiReschedule = RESCHEDULE;
  const [rowID, setRowID] = useState(false);
  const { addToast } = useToasts();

  const handleDocView = () => {};
  const handleCalendarShow = (calendarShow) => setCalendarShow(calendarShow);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const handleSelectedCandidate = (userProfile) =>
    setSelectedCandidate(userProfile);
  const [selectedCandidate, setSelectedCandidate] = useState({});

  const toggleShowEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };

  const toggleM = () => setShowEmailModal(!showEmailModal);

  const resheduleCall = (data) => {
    let rescheduleDate = moment(data.date).add(data.time).toISOString();
    //  console.log("erschedule data and time",data)
    axios
      .post(MexxarApiReschedule, {
        callerId: auth.item.id,
        receiverId: userProfile.id,
        callDate: moment(new Date()).toISOString(),
        rescheduleDate: rescheduleDate,
      })
      .then((res) => {
        //  setCandidateId(res.data.body);
        ToastCommon(true, "success", addToast);
        setCalendarShow(!calendarShow);
      })
      .catch((err) => {
        //console.log("eror in getting response", err);
        // ToastCommon(true, "error", addToast);
      });
  };

  return (
    <>
      <div
        className=" card d-flex flex-row main bg-light p-1 "
        style={{ minWidth: "900px", marginBottom: "5px" }}
        onClick={() => handleDocView()}
      >
        <AvatarMexxar
          candidateId={userProfile?.id}
          primaryPhone={userProfile?.primaryPhone}
          avatar={userProfile?.avatar}
          gender={userProfile?.gender}
        />
        <div className="pl-2 d-flex flex-column " style={{ flex: 3 }}>
          <div className="d-flex ml-1 mb-1">
            <div>
              {userProfile.firstName}&nbsp;{userProfile.lastName}{" "}
            </div>
          </div>
          <div className=" d-flex ">
            <div className="mx-2 ">
              <Link to={"/dashboard/call/" + userProfile.id}>
                <RoundIconButton
                  buttonColor="#00C426"
                  icon={faPhone}
                  iconSize="fa-md"
                  buttonSize="25px"
                ></RoundIconButton>
              </Link>
            </div>
            <div className="mx-2 ">
              <RoundIconButton
                buttonColor="#458AF7"
                icon={faEnvelope}
                buttonSize="25px"
                click={() => {
                  toggleShowEmailModal();
                  handleSelectedCandidate(userProfile);
                }}
              ></RoundIconButton>
            </div>
            <div className=" mx-2  d-flex align-items-center ">
              <FontAwesomeIcon
                color="#FF5603"
                icon={faCalendarAlt}
                onClick={() => {
                  setCalendarShow(!calendarShow);
                  setRowID(userProfile.candidateId);
                }}
                className="fa-lg mb-2 btn-raised btn-wave "
              />
            </div>
          </div>
        </div>
        <div className="d-flex  align-items-center" style={{ flex: 1 }}>
          <FontAwesomeIcon
            // color={userProfile.gender == "1" ? "#458AF7" : "#f54394"}
            color={GenderSwitch(userProfile.gender).genderColor}
            // icon={userProfile.gender == "1" ? faMars : faVenus}
            icon={GenderSwitch(userProfile.gender).genderSymbol}
          />
        </div>
        <div
          className=" d-flex align-items-center  justify-content-center  flex-column "
          style={{ flex: 3 }}
        >
          <div>
            {" "}
            <Badge color="primary">{userProfile.framework}</Badge>
          </div>
          <div>
            {" "}
            <Badge color="primary">{userProfile.role}</Badge>
          </div>
        </div>

        {/* <div
            className={`d-flex align-items-center
              ${
                percentage >= 90
                  ? "text-success"
                  : percentage >= 80
                  ? "text-warning"
                  : "text-danger"
              }
              
              `}
            style={{ flex: 2 }}
          >
            {userProfile.percentage}%
          </div> */}

        <div
          className="d-flex flex-column  align-items-center "
          style={{ flex: 3 }}
        >
          <div style={{ height: "4vh" }}>Profile Status</div>
          <div style={{ height: "3vh" }}>
            {/* {(() => {
                  switch (userProfile.status) {
                    case "pending":
                      return (
                        <Badge href="#" className="bg-warning">
                          pending
                        </Badge>
                      );

                    case "confirmed":
                      return (
                        <Badge href="#" className="bg-success">
                          confirmed
                        </Badge>
                      );

                    case "declined":
                      return (
                        <Badge href="#" className="bg-danger">
                          declined
                        </Badge>
                      );
                    case "verified":
                      return (
                        <Badge href="#" className="bg-success">
                          verified
                        </Badge>
                      );

                    default:
                      return null;
                      break;
                  }
                })()} */}
            <Badge href="#" className="bg-danger">
              Declined
            </Badge>
          </div>
          <div style={{ height: "3vh" }}></div>
        </div>
        <div
          className="d-flex flex-column  align-items-center "
          style={{ flex: 4 }}
        >
          <div style={{ height: "4vh" }}>Audited on</div>

          <div className="d-flex justify-content-center ">
            <span className="mr-1">
              <small className="text-muted">
                {moment(userProfile.auditedOn).format("ddd MMM DD YYYY")}
              </small>
            </span>
            <span className="mr-1">
              <small className="text-muted">
                @ {moment(userProfile.auditedOn).format("hh:mm a")}
              </small>
            </span>
          </div>
          <div style={{ height: "3vh" }}></div>
        </div>
        <div
          className="d-flex align-items-center justify-content-center "
          style={{ flex: 2 }}
        >
          <Link
            to={{
              pathname:
                "/dashboard/candidate-profile/" +
                userProfile.id +
                "/+4545454545/compliance",
            }}
          >
            <Button
              style={{ width: "70px" }}
              className="bg-success text-light no-border"
            >
              Show
            </Button>
          </Link>
        </div>
      </div>

      <ModalComponent
        show={calendarShow}
        header="Reschedule a call"
        closeModal={() => setCalendarShow(false)}
      >
        <SmallCalendar
          onCreate={(data) => resheduleCall(data)}
          closeModal={() => setCalendarShow(false)}
        />
      </ModalComponent>

      <ModalComponent
        show={showEmailModal}
        header="E mail and sms"
        closeModal={() => toggleM()}
      >
        <EmailNav closeModal={() => toggleM()} user={selectedCandidate} />
      </ModalComponent>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, {})(AuditRejectedWidget);
