import React, { useState, useEffect } from "react";

import {
  AUDIT_WORKSPACE_SUMMARY,
  Add_NEW_EMPLOYEE,
} from "../../configs/api-config";
import {
  faArrowAltCircleDown,
  faArrowAltCircleUp,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";
import { connect } from "react-redux";
import Store from "../../redux/store";
import AuditWorkSpace from "./workspace/auditWorkSpace";
import { useIsMounted } from "../../customHooks/useIsMounted";
import { Label, UncontrolledTooltip, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DashboardLayout from "../../layouts/dashboard-layout/dashboard";
import { employeesGetAction } from "../../redux/actions/initialDataGetAction";
import useDidMountEffect from "../../components/Modal/ModalBody/intialRunUseEffect";
import TabNavigationMenu from "../../util/common/tabNavigation";

const Audit = (props) => {
  const { auth, employees } = props;
  const isMounted = useIsMounted();

  const MexxarApi = AUDIT_WORKSPACE_SUMMARY;
  const MexxarApiEmployee = Add_NEW_EMPLOYEE;

  const [summery, setSummery] = useState({});
  const [employee, setEmployee] = useState([]);
  const [showEmployee, setShowEmployee] = useState(false);
  const [currentTab, setCurrentTab] = useState("auditWorkspace");
  const [rememberEmployee, setRememberEmployee] = useState(false);
  const [employeeID, setEmployeeID] = useState(auth.item.userTypeId);

  const switchTabHandler = (tab) => {
    setCurrentTab(tab);
  };

  const getData = () => {
    if (employeeID) {
      axios
        .get(MexxarApi + "?employeeId=" + employeeID)
        .then((res) => {
          if (isMounted.current) {
            setSummery(res.data.body[0]);
          }
        })
        .catch((err) => {
          //   console.log(err);
        });
    }
  };

  const getEmployees = (workspaceEmployeeSave, employeeId) => {
    let names = [];
    employees.forEach((item) => {
      names.push({
        id: item.id,
        firstName: item.firstName,
        lastName: item.lastName,
      });
    });
    setEmployee(names);
  };

  useDidMountEffect(() => {
    localStorage.setItem("WorkspaceEmployeeSave", rememberEmployee);
    localStorage.setItem("WorkspaceEmployeeId", employeeID);
  }, [rememberEmployee]);

  useDidMountEffect(() => {
    localStorage.setItem("WorkspaceEmployeeSave", rememberEmployee);
    localStorage.setItem(
      "WorkspaceEmployeeId",
      employeeID == 0 ? auth.item.userTypeId : employeeID
    );
    getData();
  }, [employeeID]);

  useDidMountEffect(() => {
    const workspaceEmployeeSave = localStorage.getItem("WorkspaceEmployeeSave");
    const employeeId = localStorage.getItem("WorkspaceEmployeeId");

    getEmployees(workspaceEmployeeSave, employeeId);
  }, [employees]);

  useEffect(() => {
    const workspaceEmployeeSave = localStorage.getItem("WorkspaceEmployeeSave");
    const employeeId = localStorage.getItem("WorkspaceEmployeeId");

    Store.dispatch(employeesGetAction());

    if (employees != null) {
      getEmployees(workspaceEmployeeSave, employeeId);
    }

    if (workspaceEmployeeSave == "true") {
      setRememberEmployee(true);
      setShowEmployee(true);
      setEmployeeID(Number(employeeId));
    } else {
      localStorage.setItem("WorkspaceEmployeeSave", false);
      localStorage.setItem("WorkspaceEmployeeId", auth.item.userTypeId);
      setEmployeeID(auth.item.userTypeId);
    }
  }, []);

  return (
    <>
      <DashboardLayout
        title="Audit Department"
        subTitle="Acheive Your Daily Targets"
        target=""
        showAuditTarget={true}
        summeryData={summery}
      >
        <div className="d-flex align-items-end flex-column">
          {showEmployee ? (
            <div>
              <FontAwesomeIcon
                icon={faArrowAltCircleUp}
                className="btn-raised"
                id="arrowUp"
                style={{ boxShadow: "none", cursor: "pointer" }}
                onClick={() => setShowEmployee(false)}
              ></FontAwesomeIcon>
            </div>
          ) : (
            <div>
              <FontAwesomeIcon
                icon={faArrowAltCircleDown}
                id="arrowDown"
                className="btn-raised"
                style={{ boxShadow: "none", cursor: "pointer" }}
                onClick={() => setShowEmployee(true)}
              ></FontAwesomeIcon>{" "}
              <UncontrolledTooltip placement="left" target="arrowDown">
                Click to show/hide consultants
              </UncontrolledTooltip>
            </div>
          )}

          {showEmployee ? (
            <div>
              <Label className={"mr-3"}>Get Candidates by Employee</Label>
              {employee.length > 0 ? (
                <Input
                  type="select"
                  id="assigneeId"
                  style={{ width: "200px" }}
                  name="assigneeId"
                  value={employeeID.id}
                  defaultValue={employeeID}
                  onChange={(e) => setEmployeeID(e.target.value)}
                >
                  {/* <option value={0}>Select</option> */}

                  {employee.map((item, id) => {
                    return (
                      <option key={id} value={item.id}>
                        {item.firstName} {item.lastName}
                      </option>
                    );
                  })}
                </Input>
              ) : null}
              <div>
                <small>
                  Remember
                  <Input
                    className="mx-2 pointer"
                    type="checkbox"
                    value="REMEMBEREMPLOYEE"
                    checked={rememberEmployee}
                    onChange={() => setRememberEmployee(!rememberEmployee)}
                  ></Input>
                </small>
              </div>
            </div>
          ) : null}
        </div>
        <TabNavigationMenu
          activated={currentTab}
          onChangeItem={switchTabHandler}
          itemList={[
            { name: "Workspace", key: "auditWorkspace" },
            // { name: "More", key: "more" },
          ]}
        />

        {currentTab === "auditWorkspace" && (
          <AuditWorkSpace
            updateTargets={() => getData()}
            employeeID={employeeID}
          />
        )}
      </DashboardLayout>
    </>
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    employees: state.initialDataGetReducer.employees,
  };
}

export default connect(mapStateToProps)(Audit);
