import React, { useEffect, useState } from "react";
import { Alert, Badge, Button, Col, Label, Modal, Row } from "reactstrap";
import Select from "react-select";
import useCandidateList from "../../../customHooks/useCandidateList";
import { HOSTMexxar, REGISTER_CANDIDATE } from "../../../configs/api-config";
import ModalComponent from "../modalComponent";
import EmailNav from "./emailNav";
import useDidMountEffect from "./intialRunUseEffect";
import axios from "axios";
import { Link } from "react-router-dom";

export default function ShowJob(props) {
  const { closeModal, show = false, job } = props;
  const [showCandidates, setShowCandidates] = useState(false);

  const [candidates, setCandidates] = useState([]);
  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [showEmailModal, setShowEmailModal] = useState(false);

  const [filterCustom, setFilterCustom] = useState({
    value: "ACTIVE",
    bool: true,
  });

  const MexxarCandidateApi = REGISTER_CANDIDATE;

  let url = MexxarCandidateApi + "/status/" + filterCustom.value;

  const { loading, error, rows, hasMore } = useCandidateList(
    url,
    filterCustom,
    query,
    pageNumber
  );

  const getCandidates = () => {
    let listOfCandidates = [];
    for (let i = 0; i < rows.length; i++) {
      listOfCandidates.push({
        value: rows[i].id,
        label: `${rows[i].firstName} ${rows[i].lastName}`,
        id: rows[i].id,
        candidate: rows[i],
      });
    }

    if (query != "") {
      setCandidates(listOfCandidates);
    } else {
      setCandidates([]);
    }
  };

  const handleInputChange = (e) => {
    setQuery(e);
  };

  const [oneDocNotUploaded, setOneDocNotUploaded] = useState(false);
  const [oneDocNotApproved, setOneDocNotApproved] = useState(false);
  const [docIds, setDocIds] = useState(null);

  function getPermDocs() {
    setOneDocNotUploaded(false);
    setOneDocNotApproved(false);
    setDocIds(null);
    if (selectedCandidate) {
      axios
        .get(HOSTMexxar + "permanent/permDocs/status/" + selectedCandidate.id)
        .then((res) => {
          res.data.body.forEach(function (doc) {
            if (doc.documentStatus != "UPLOADED") {
              setOneDocNotUploaded(true);
              return;
            }

            return;
          });

          res.data.body.forEach(function (doc) {
            if (doc.documentApprovedStatus != "APPROVE") {
              setOneDocNotApproved(true);
              return;
            }

            return;
          });

          let DocumentIds = [];
          res.data.body.forEach(function (doc) {
            DocumentIds.push(doc.document.id);
          });

          setDocIds(DocumentIds);
        })
        .catch((error) => {});
    }
  }
  useDidMountEffect(() => {
    getPermDocs();
    return () => {};
  }, [selectedCandidate]);

  useEffect(() => {
    getCandidates();
    return () => {};
  }, [rows]);

  function showAlert() {
    if (oneDocNotUploaded && oneDocNotApproved) {
      return (
        <Alert color="warning" className="mt-2">
          Please <b>Upload</b> and <b>Approve</b>&nbsp;
          {selectedCandidate.label}'s documents before sending an application to
          the client.
        </Alert>
      );
    } else if (oneDocNotUploaded) {
      return (
        <Alert color="warning" className="mt-2">
          Please <b>Upload</b>&nbsp;
         {selectedCandidate.label}'s documents before sending an application to
          the client.
        </Alert>
      );
    } else if (oneDocNotApproved) {
      return (
        <Alert color="warning" className="mt-2">
          Please <b>Approve</b>&nbsp;
          {selectedCandidate.label}'s documents before sending an application to
          the client.
        </Alert>
      );
    } else {
      return <></>;
    }
  }

  return (
    <div className="p-1">
      <div className="d-flex justify-content-between">
        <div className="font-weight-bold p-1 pb-3">
          <label className="capitalize ">
            {job.jobTitle} @ {job.clientName}
          </label>
        </div>
        <div className="font-weight-bold p-1 pb-3">
          <label className=" ">
            {job.noOfPositionsAvailable} &nbsp;
            {job.noOfPositionsAvailable == 1 ? "Position" : "Positions"}
          </label>
        </div>
      </div>
      <div className="p-1 d-flex align-items-between justify-content-between">
        <div className="">
          <label className="mr-1">Required Experience:</label>
          <small>
            {job.requiredExperienceYears}
            &nbsp;Years&nbsp;
            {job.requiredExperienceMonths > 0 &&
              `and ${job.requiredExperienceMonths} Months`}{" "}
          </small>
        </div>
        <div className="">
          Offered Salary (£):&nbsp; <small>{job.offeredSalary}</small>
        </div>
      </div>
      <div className="p-1">
        <label className="mr-1">Role and Band:</label>
        <Badge color="success">{job.requiredRoleName} </Badge>
        &nbsp;
        <Badge color="success">{job.requiredBandName} </Badge>
      </div>
      <div className="d-flex p-1">
        <label className="mr-1">Specialities:</label>

        <p>
          {job.requiredSpecialities &&
            job.requiredSpecialities.map((item, id) => {
              return (
                <div key={id}>
                  <Badge color="warning" className="mr-1">
                    {item.name}
                  </Badge>
                </div>
              );
            })}
        </p>
      </div>

      <div className="p-1 d-flex">
        <label className="  mr-1 ">Description:</label>{" "}
        <p className="text-justify">
          <small>{job.jobDescription}</small>
        </p>
      </div>

      <div className="p-1 d-flex">
        <label className="  mr-1">Other Information:</label>{" "}
        <p className="text-justify">
          <small>{job.otherInformation}</small>
        </p>
      </div>

      <div className="p-1  d-flex">
        <label className="mr-1">Hiring Manager:</label>
        <p className="  d-flex flex-column">
          <small>Name:&nbsp;{job.hiringManagerName}</small>
          <small>Email:&nbsp;{job.hiringManagerEmail}</small>
          <small>Contact Number:&nbsp;{job.hiringManagerContactNo}</small>
        </p>
      </div>
      <div className="p-1 d-flex align-items-between justify-content-between">
        <div className="">
          Created Date:&nbsp; <small>{job.createdDate}</small>
        </div>
        <div className=" ">
          Expiry Date:&nbsp; <small>{job.expiryDate}</small>
        </div>
      </div>

      <div className="p-2">
        {job.noOfPositionsAvailable == 0 && (
          <Alert color="danger">
            Note: This job no longer accept applications.{" "}
          </Alert>
        )}
      </div>
      {showCandidates && (
        <div>
          <Row style={{ marginLeft: "0px" }}>
            <Col sm={12}>
              <Label>Add Candidate</Label>
            </Col>
            <Col sm={7}>
              {candidates != null ? (
                <Select
                  // isMulti
                  name="candidateId"
                  //   defaultValue={candidateWhenClick ? candidateWhenClick : ""}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => setSelectedCandidate(data)}
                  isLoading={loading}
                  options={candidates}
                  placeholder="Search candidates ..."
                  onInputChange={handleInputChange}
                  noOptionsMessage={() => "No results found"}
                />
              ) : null}
            </Col>
            {!oneDocNotUploaded && !oneDocNotApproved ? (
              <Col sm={5}>
                <Button
                  color="success"
                  className=" mx-1"
                  disabled={!selectedCandidate}
                  onClick={() => {
                    setShowEmailModal(true);
                  }}
                >
                  Send Application
                </Button>
              </Col>
            ) : (
              selectedCandidate && (
                <Col sm={5}>
                  <Link
                    to={{
                      pathname:
                        "/dashboard/candidate-profile/" +
                        selectedCandidate.id +
                        "/" +
                        "+44",
                    }}
                  >
                    <Button color="success" className=" mx-1">
                      Goto Profile
                    </Button>
                  </Link>
                </Col>
              )
            )}
          </Row>
          {showAlert()}
        </div>
      )}

      <div className="float-right  mt-4">
        <Button
          color="success"
          className=" mx-1"
          onClick={() => {
            setShowCandidates(true);
          }}
        >
          Add a Candidate
        </Button>
        <Button color="danger" onClick={() => closeModal()}>
          Close
        </Button>
      </div>

      <ModalComponent
        show={showEmailModal}
        header="E mail"
        closeModal={() => setShowEmailModal(false)}
      >
        <EmailNav
          incomingPage="permJobBoard"
          closeModal={() => {
            setShowEmailModal(false);
            // getClientApplications();
          }}
          user={{
            selectedClient: {
              email: job.hiringManagerEmail,
              name: job.clientName,
              id: job.clientId,
              job: job,
            },
            candidate: selectedCandidate,
            docIds: docIds,
          }}
        />
      </ModalComponent>
    </div>
  );
}
