import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import RegistrationWorkingCardWidget from "../../../widgets/working-card-widget/working-card-registration-widget";
import {
  rolesGetAction,
  specialitiesGetAction,
  bandsGetAction,
  addressWardGetAction,
  countiesGetAction,
  districtsGetAction,
  cvSourcesGetAction,
} from "../../../redux/actions/initialDataGetAction";

import axios from "axios";
import ModalComponent from "../../../components/Modal/modalComponent";
import SnapShotNav from "../../../components/Modal/ModalBody/snapShotNav";
import { connect } from "react-redux";
import { forEach, filter, split } from "lodash";
import { useSpring, animated } from "react-spring";
import HeadhunterWorkingCardWidget from "../../../widgets/working-card-widget/working-card-headhunter-widget";
import NoConnection from "../../errorPages/NoConnection";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { useIsMounted } from "../../../customHooks/useIsMounted";
import {
  CANDIDATES_IN_REGISTRATION_STATUS,
  HOSTMexxar,
} from "../../../configs/api-config";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import HeadHunterRegistrationStatusWidget from "../../../widgets/working-card-widget/headhunter-registration-status-widgets";
import { FunnelIcon } from "../../../assets/icons/svg";
import useWorkspaceWithFilters from "../../../customHooks/useWorkspaceWithFilters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import store from "../../../redux/store";
import Select from "react-select";

const HeadHunterRegistrationStatus2 = (props) => {
  const { updateTargets, employeeID, specialities, roles, bands } = props;
  var workflow = "REGISTRATION";

  /***************** Search filter ******************/
  const [query, setQuery] = useState(null);
  const [reset, setReset] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [remember, setRemember] = useState(true); /// <--------filter settings options saving and retreving/ if need activate later with below checkbox
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [searchAdvanced, setSearchAdvanced] = useState(false);
  const [callResponse, setCallResponse] = useState("");
  const [applicationStatus, setApplicationStatus] = useState("");

  const [selectAll, setSelectAll] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [showSnapshotModal, setShowSnapshotModal] = useState(false);
  const [selectedItemsForSnapshot, setSelectedItemsForSnapshot] = useState();
  const [workFlow, setWorkFlow] = useState("");

  const [speciality, setSpeciality] = useState([]);
  const [role, setRole] = useState([]);
  const [band, setBand] = useState([]);

  const [selectedSpecialities, setSelectedSpecialities] = useState({
    id: "",
    value: "",
    label: "",
  });
  const [selectedRole, setSelectedRole] = useState({
    id: "",
    value: "",
    label: "",
  });
  const [selectedBand, setSelectedBand] = useState({
    id: "",
    value: "",
    label: "",
  });

  let url = `${HOSTMexxar}workspace/filter?&speciality=${selectedSpecialities.id}&role=${selectedRole.id}&band=${selectedBand.id}&workflow=${workflow}`;

  // if the above url does work properly delete below url

  // let url =
  //   HOSTMexxar +
  //   "workspace/filter?&callResponse=" +
  //   callResponse +
  //   "&applicationStatus=" +
  //   applicationStatus +
  //   "&workflow=" +
  //   workflow;

  // const { resetWorkspace } = useWebSocketClient("registration");

  const { loading, error, rows, hasMore, totalElements } =
    useWorkspaceWithFilters(
      url,
      query,
      pageNumber,
      searchAdvanced,
      reset,
      employeeID
    );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  function handleSearch(e) {
    if (e.target.value == "") {
      setQuery(null);
    } else {
      setQuery(e.target.value);
    }
    setPageNumber(1);
  }

  const toggleFilter = () => setIsOpenFilter(!isOpenFilter);
  //....reset function......//
  const resetFilters = () => {
    setReset(!reset);
    setCallResponse("");
    setApplicationStatus("");
    setPageNumber(1);
    setSelectedSpecialities({ id: "", value: "", label: "" });
    setSelectedRole({ id: "", value: "", label: "" });
    setSelectedBand({ id: "", value: "", label: "" });
  };

  const rearrangeSpecialities = () => {
    let specialitiesTemp = [];
    specialities.forEach((item) => {
      specialitiesTemp.push({
        value: item.name,
        label: item.name + `${item?.type == "DATABASE" ? "-DB" : ""}`,
        id: item.id,
      });
    });
    setSpeciality(specialitiesTemp);
  };
  const rearrangeRoles = () => {
    let rolesTemp = [];
    roles.forEach((item) => {
      rolesTemp.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    setRole(rolesTemp);
  };
  const rearrangeBands = () => {
    let bandsTemp = [];
    bands.forEach((item) => {
      bandsTemp.push({
        value: item.name,
        label: item.name,
        id: item.id,
      });
    });
    setBand(bandsTemp);
  };

  useEffect(() => {
    store.dispatch(specialitiesGetAction());
    store.dispatch(rolesGetAction());
    store.dispatch(bandsGetAction());

    return () => {};
  }, []);

  useEffect(() => {
    if (specialities) {
      rearrangeSpecialities();
    }
    return () => {};
  }, [specialities]);

  useEffect(() => {
    if (roles) {
      rearrangeRoles();
    }
    return () => {};
  }, [roles]);

  useEffect(() => {
    if (bands) {
      rearrangeBands();
    }
    return () => {};
  }, [bands]);

  const toggleSnapshotModal = () => {
    // let selectedCandidates = filter(rows, (item) => {
    //   return item.candidateId === "SPECIAL";
    // });
    let tempArray = [];

    if (!selectAll) {
      for (let i = 0; i < rows.length; i++) {
        for (let x = 0; x < selectedItems.length; x++) {
          if (selectedItems[x] == rows[i].candidateId) {
            tempArray.push(rows[i]);
          }
        }
      }
    } else if (selectAll) {
      for (let i = 0; i < rows.length; i++) {
        tempArray.push(rows[i]);
      }
    }

    setSelectedItemsForSnapshot(tempArray);
    setShowSnapshotModal(!showSnapshotModal);
  };

  const isSelected = (id) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == id;
    });

    if (slectedRows.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const checkAll = () => {
    let ids = [];
    if (selectAll) {
      setSelectAll(false);
      setSelectedItems([]);
    } else {
      rows.forEach((item) => {
        ids.push(item.id);
      });
      setSelectedItems(ids);
      setSelectAll(true);
    }
  };

  const onItemSelect = (rowId) => {
    let slectedRows = filter(selectedItems, (item) => {
      return item == rowId;
    });

    if (slectedRows.length > 0) {
      let removeItems = filter(selectedItems, (item) => {
        return item != rowId;
      });
      setSelectedItems([...removeItems]);
      setSelectAll(false);
      return false;
    } else {
      setSelectedItems([...selectedItems, rowId]);
      return true;
    }
  };

  // useDidMountEffect(() => {
  //   let ids_Workspace = [];
  //   forEach(rows, function (item) {
  //     ids_Workspace.push({
  //       candidateId: item.candidateId,
  //       primaryPhone: item.primaryPhone,
  //     });
  //   });
  //   addFilteredData(ids_Workspace);
  // }, [rows]);

  /****************websocket***********/
  // useEffect(() => {
  //   setReset(true);
  // }, [resetWorkspace]);
  /*******End*******/

  // useEffect(() => {
  //   updateTargets();
  // }, [employeeID]);

  /******* Use to set page number to default value of 1 when changing the employee or search advance filters  *******/
  useDidMountEffect(() => {
    setPageNumber(1);
  }, [searchAdvanced, employeeID]);
  /*******End *******/

  const animationProps = useSpring({
    opacity: 1,
    transform: "translate(0px, 0px)",
    from: { opacity: 0, transform: "translate(-100px, 0px)" },
  });

  return (
    <>
      <div className="form-inline ">
        <FontAwesomeIcon icon={faUsers} className={"ml-2 mr-3  "} />
        <Label className={"mr-3"}>
          {totalElements}{" "}
          {totalElements.length == 1 ? "Candidate" : "Candidates"}
        </Label>
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={handleSearch}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />
        <UncontrolledTooltip target="searchBar">
          Search candidates by Name/Id/Email/Phone No.
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
                <Col>
                <Label>Workflow</Label>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value=""
                      checked={workFlow === ""}
                      onChange={(e) => setWorkFlow(e.target.value)}
                    />
                    <Badge color="info">All</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="HEADHUNTER"
                      checked={workFlow === "HEADHUNTER"}
                      onChange={(e) => setWorkFlow(e.target.value)}
                    />
                    <Badge color="warning">Headhunting</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="REGISTRATION"
                      checked={workFlow === "REGISTRATION"}
                      onChange={(e) => setWorkFlow(e.target.value)}
                    />{" "}
                    <Badge color="success">Registation</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="AUDIT"
                      checked={workFlow === "AUDIT"}
                      onChange={(e) => setWorkFlow(e.target.value)}
                    />{" "}
                    <Badge style={{ backgroundColor: "#8bc34a" }}>Audit</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="MAINTENANCE"
                      checked={workFlow === "MAINTENANCE"}
                      onChange={(e) => setWorkFlow(e.target.value)}
                    />{" "}
                    <Badge color="danger">Maintenance</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="BOOKING"
                      checked={workFlow === "BOOKING"}
                      onChange={(e) => setWorkFlow(e.target.value)}
                    />{" "}
                    <Badge color="success">Booking</Badge>
                  </Label>
                </FormGroup>
              </Col>
              <Col sm={12} md={6} lg={3}>
                <Label for="candidateRole">Role</Label>
                <Select
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      color: "#333333",
                    }),
                  }}
                  // isMulti
                  id={"candidateRole"}
                  value={selectedRole}
                  name="candidateRole"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => {
                    setSelectedRole(data);
                  }}
                  options={role}
                />
                <Label for="candidateSpeciality" className="mt-2">
                  Speciality
                </Label>
                <Select
                  // isMulti
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      color: "#333333",
                    }),
                  }}
                  id={"candidateSpeciality"}
                  value={selectedSpecialities}
                  name="candidateSpeciality"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => {
                    setSelectedSpecialities(data);
                  }}
                  options={speciality}
                />

                <Label for="candidateBand" className="mt-2">
                  Band{" "}
                </Label>
                <Select
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                      color: "#333333",
                    }),
                  }}
                  // isMulti
                  value={selectedBand}
                  id={"candidateBand"}
                  name="candidateBand"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(data) => {
                    setSelectedBand(data);
                  }}
                  options={band}
                />
              </Col>
              <Col></Col>

              <Col></Col>
              <Col>
                <div className="d-flex flex-column align-items-end">
                  <div>
                    <Button
                      color="success m-1 btn-sm"
                      style={{ width: "90px" }}
                      onClick={() => {
                        setSearchAdvanced(!searchAdvanced);
                      }}
                    >
                      Search
                    </Button>
                  </div>
                  <div>
                    <Button
                      color="danger m-1 btn-sm"
                      style={{ width: "90px" }}
                      onClick={() => {
                        resetFilters();
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                  {/* <div className="mr-2">
                      <small>
                        <Input
                          type="checkbox"
                          value="REMEMBER"
                          checked={remember}
                          onChange={handleCheck}
                        ></Input>
                        Remember
                      </small>
                    </div> */}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>

      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {/* {rows?.sort(SortFunctionAscending).map((row, i) => { */}
      {rows?.map((row, i) => {
        if (rows.length === i + 1) {
          return (
            <animated.div
              style={animationProps}
              ref={lastBookElementRef}
              key={row.email}
            >
              <HeadHunterRegistrationStatusWidget
                // key={i}
                rowId={i}
                userProfile={row}
                isSelected={(id) => isSelected(id)}
                onItemSelect={(id) => onItemSelect(id)}
                selectAll={selectAll}
              />
            </animated.div>
          );
        } else {
          return (
            <animated.div style={animationProps} key={row.email}>
              <HeadHunterRegistrationStatusWidget
                // key={i}
                rowId={i}
                userProfile={row}
                isSelected={(id) => isSelected(id)}
                onItemSelect={(id) => onItemSelect(id)}
                selectAll={selectAll}
              />
            </animated.div>
          );
        }
      })}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}

      {!loading && !error && rows.length == 0 && <NoSearchResults />}

      {showSnapshotModal ? (
        <ModalComponent
          show={showSnapshotModal}
          header="Mailshot"
          closeModal={() => setShowSnapshotModal(false)}
        >
          <SnapShotNav
            closeModal={() => setShowSnapshotModal(false)}
            user={selectedItemsForSnapshot}
          />
        </ModalComponent>
      ) : null}
    </>
  );
};

// function mapStateToProps(state) {
//   return {
//     auth: state.auth,
//   };
// }
function mapStateToProps(state) {
  return {
    auth: state.auth,
    specialities: state.initialDataGetReducer.specialities,
    roles: state.initialDataGetReducer.roles,
    bands: state.initialDataGetReducer.bands,
  };
}

export default connect(mapStateToProps)(HeadHunterRegistrationStatus2);
