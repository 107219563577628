import moment from "moment";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { HOSTMexxar } from "../../../configs/api-config";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoConnection from "../../../pages/errorPages/NoConnection";
import NoSearchResults from "../../../pages/errorPages/NoSearchResults";
import { isNull } from "lodash";

const SMSTab = (props) => {
  const { display, userPhoneNo } = props;

  const MexxarAPI = HOSTMexxar;

  // DataList
  const [dataList, setDataList] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getReceivedMessages = (number) => {
    setLoading(true);
    axios
      .get(`${MexxarAPI}chat/get-by-from/${number}`)
      .then((receivedSMSRes) => {
        let receivedSMS = receivedSMSRes.data.body;

        let receivedSMSList = [];
        for (let i = 0; i < receivedSMS.length; i++) {
          receivedSMSList.push({
            smsFrom: receivedSMS[i].smsFrom,
            smsTo: receivedSMS[i].smsTo,
            message: receivedSMS[i].message,
            date: receivedSMS[i].date,
          });
        }
        setLoading(false);
        setDataList(receivedSMSList);
      })
      .catch(() => {
        setError(true);
      });
  };

  const getSendMessages = (number) => {
    setLoading(true);
    axios
      .get(`${MexxarAPI}chat/get-by-to/${number}`)
      .then((sendSMSRes) => {
        let sendSMS = sendSMSRes.data.body;

        let sendSMSList = [];
        for (let i = 0; i < sendSMS.length; i++) {
          sendSMSList.push({
            smsFrom: sendSMS[i].smsFrom,
            smsTo: sendSMS[i].smsTo,
            message: sendSMS[i].message,
            date: sendSMS[i].date,
          });
        }
        setLoading(false);
        setDataList(sendSMSList);
      })
      .catch(() => {
        setError(true);
      });
  };

  const getUser = () => {
    if (display == "RECEIVED") {
      getReceivedMessages(userPhoneNo);
    } else {
      getSendMessages(userPhoneNo);
    }
  };

  useEffect(() => {
    getUser();
    return () => {};
  }, [display]);

  return (
    <div className=" p-2 " style={{ overflow: "auto" }}>
      {dataList
        ? dataList.reverse().map((post, i) => {
            return (
              <div className="card mb-3 p-3 " key={i}>
                <div className=" list-item " style={{ width: "100%" }}>
                  <div className="timeline timeline-theme animates animates-fadeInUp">
                    <div className="tl-item active">
                      <div className="tl-dot"></div>
                      <div className="tl-content">
                        {display == "RECEIVED" ? (
                          <small className=" ">{post.smsTo}</small>
                        ) : (
                          <small className=" ">{post.smsFrom}</small>
                        )}
                        <div className="tl-content ">{post.message}</div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex justify-content-end mb-1 mr-1"
                    style={{ width: "100%" }}
                  >
                    {moment(post.date).startOf("minute").fromNow()}
                  </div>
                </div>
              </div>
            );
          })
        : null}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <img src={loadingAnimation} style={{ height: 100 }}></img>
        </div>
      )}

      {!loading && !error && dataList && dataList.length == 0 && (
        <NoSearchResults />
      )}
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}
    </div>
  );
};

export default SMSTab;
