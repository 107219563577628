import React from "react";
import "./supportRequest.style.css";
import { Button } from "reactstrap";

const SupportRequest = () => {
  return (
    <>
      <div className="card">
        <div className="card-header">Support Requests</div>
        <div className="card">
          <div className="list list-row">
            <div className="list-item" data-id="19">
              <div className="flex">
                <div className="item-feed h-2x">
                  Booking notification error
                  <a href="#">@IOSapps</a>
                </div>
              </div>
              <div className="no-wrap">
                <div className="item-date text-muted text-sm d-none d-md-block">
                  13/12 18
                </div>
              </div>
              <div className="no-wrap">
                <Button color="success " className="btn-sm">
                  Fix
                </Button>
              </div>
            </div>
            <div className="list-item" data-id="6">
              <div className="flex">
                <div className="item-feed h-2x">
                  Document failed to upload <a href="#">@IosApps</a> uploading
                  failed several times
                </div>
              </div>
              <div className="no-wrap">
                <div className="item-date text-muted text-sm d-none d-md-block">
                  13/12 18
                </div>
              </div>
              <div className="no-wrap">
                <Button color="success " className="btn-sm">
                  Fix
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportRequest;
