import { useEffect, useState } from "react";
import axios from "axios";
import { useIsMounted } from "./useIsMounted";

export default function useWorkspaceFetchPerm(urlPerm, searchStatus, employeeID) {
  const isMounted = useIsMounted();
  const [loadingPerm, setLoadingPerm] = useState(true);
  const [errorPerm, setErrorPerm] = useState(false);
  const [rowsPerms, setRowsPerms] = useState([]);


  // console.log("urlPerm angi",urlPerm + employeeID+"&cstatus="+"ACTIVE"+"&cvUploadStatus="+"UPLOADED" )
  const getData = () => {
    setLoadingPerm(true);
    setErrorPerm(false);
    axios({
      method: "GET",
      url: urlPerm + employeeID+"&cstatus="+"ACTIVE"+"&cvUploadStatus="+"NOT_UPLOADED"    //no need to send the employee
    })
      .then((res) => {
        setLoadingPerm(false);
        if (isMounted.current) {
          setRowsPerms(res.data.body);
          
          setErrorPerm(false);
        }
      })
      .catch((e) => {
        setLoadingPerm(false);
        setErrorPerm(true);
      });
  };


  useEffect(() => {
    if (!searchStatus.status && employeeID != undefined) {
      getData();
    } else {
      setRowsPerms(searchStatus.data);
    }
  }, [urlPerm, searchStatus, employeeID]);
  
  return { loadingPerm, errorPerm, rowsPerms };
}
