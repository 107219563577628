import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { HOSTMexxar, TRUSTS } from "../../../../configs/api-config";
import ToastCommon from "../../../toastCommon";
import axios from "axios";
import { useToasts } from "react-toast-notifications";
import { filter } from "lodash";
import useDidMountEffect from "../intialRunUseEffect";

export default function TrustModal({
  show,
  mode,
  closeModal,
  rows,
  onCreate,
  fieldData,
}) {
  const { register, handleSubmit, errors, reset } = useForm();
  const { addToast } = useToasts();

  const [showtrustform, setTrustForm] = useState(false);
  const [errorPostCode, setErrorPostCode] = useState(false);
  const [selectedCounty, setSelectedCounty] = useState({
    id: "",
    value: "",
    label: "",
  });

  const [formData, setFormData] = useState(null);

  const onSubmit = () => {
    if (errorPostCode) {
    } else {
      onCreate(formData);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const addRow = (data) => {
    let duplicate_items = filter(rows, (item) => {
      return data.rowName == item.name;
    });

    if (duplicate_items.length > 0) {
      ToastCommon(true, "duplicate", addToast);
    } else {
      axios
        .post(TRUSTS, {
          name: data.name,
          houseNumber: data.houseNumber,
          streetName: data.streetName,
          city: data.city,
          state: data.state,
          postalCode: data.postalCode,
          contactPersonName: data.contactPersonName,
          contactPersonDesignation: data.contactPersonDesignation,
          contactPersonEmail: data.contactPersonEmail,
          website: data.website,
        })
        .then((res) => {
          if (res.data.status == "success") {
            ToastCommon(true, "success", addToast);
            closeModal();
          } else {
            ToastCommon(true, "error", addToast);
          }
        })
        .catch((err) => {
          //console.log(err)
          ToastCommon(true, "error", addToast);
        });
    }
  };

  const checkPostalCode = (postalCode) => {
    axios.get(HOSTMexxar + "postal-codes/code/" + postalCode).then((res) => {
      if (res.data.body[0]) {
        setErrorPostCode(false);
        setSelectedCounty(res.data.body[0]?.county);
      } else {
        setErrorPostCode(true);
        setSelectedCounty("");
      }
    });
  };

  useDidMountEffect(() => {
    if (mode === "Update") {
      setFormData(fieldData);
    } else {
      setFormData(null);
    }
  }, [fieldData]);

  return (
    <Modal size={"lg"} isOpen={show} closeModal={() => closeModal()}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader
          className="modal-header bg-primary"
          close={
            <button className="close" onClick={closeModal}>
              ×
            </button>
          }
        >
          {mode} Trust
        </ModalHeader>
        <ModalBody className="p-4">
          <FormGroup>
            <Row>
              <Col md={4}>
                <Label>
                  Name
                  <span className="text-danger ml-1">*</span>
                </Label>
              </Col>
              <Col md={8}>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  value={formData?.name}
                  onChange={handleInputChange}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                />
                {errors.name && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      {errors.name?.message}
                    </li>
                  </ul>
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={4}>
                <Label>
                  Building No
                  <span className="text-danger ml-1">*</span>
                </Label>
              </Col>
              <Col md={8}>
                <Input
                  type="text"
                  id="houseNumber"
                  name="houseNumber"
                  value={formData?.houseNumber}
                  onChange={handleInputChange}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                />
                {errors.houseNumber && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      {errors.houseNumber?.message}
                    </li>
                  </ul>
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={4}>
                <Label>
                  Street Name
                  <span className="text-danger ml-1">*</span>
                </Label>
              </Col>
              <Col md={8}>
                <Input
                  type="text"
                  id="streetName"
                  name="streetName"
                  value={formData?.streetName}
                  onChange={handleInputChange}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                />
                {errors.streetName && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      {errors.streetName?.message}
                    </li>
                  </ul>
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={4}>
                <Label>
                  City
                  <span className="text-danger ml-1">*</span>
                </Label>
              </Col>
              <Col md={8}>
                <Input
                  type="text"
                  id="city"
                  name="city"
                  value={formData?.city}
                  onChange={handleInputChange}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                />
                {errors.city && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      {errors.city?.message}
                    </li>
                  </ul>
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={4}>
                <Label>
                  County <span className="text-danger ml-1">*</span>
                </Label>
              </Col>
              <Col md={8}>
                <Input
                  type="text"
                  id="state"
                  name="state"
                  value={formData?.state}
                  onChange={handleInputChange}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                />
                {errors.state && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      {errors.state?.message}
                    </li>
                  </ul>
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={4}>
                <Label>
                  Postal Code -("EC1A 2BP")
                  <span className="text-danger ml-1">*</span>
                </Label>
              </Col>
              <Col md={8}>
                <Input
                  type="text"
                  name="postalCode"
                  id="postalCode"
                  className="capitalizeUpperCase"
                  placeholder="EC1A 2BP"
                  value={formData?.postalCode?.code}
                  onChange={(e) => {
                    checkPostalCode(e.target.value);
                    handleInputChange(e);
                  }}
                  invalid={errors.postCode || errorPostCode}
                  // defaultValue={rowDatas && rowDatas?.postalCode?.code}
                  innerRef={register({
                    required: {
                      value: true,
                      message: "This field is required!",
                    },
                  })}
                ></Input>
                {errors.postCode && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      {errors.postCode?.message}
                    </li>
                  </ul>
                )}
                {errorPostCode && (
                  <ul
                    className="list-unstyled text-sm mt-1 text-muted filled"
                    id="parsley-id-119"
                  >
                    <li className="parsley-required text-danger">
                      Invalid postal code format.
                    </li>
                  </ul>
                )}
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={4}>
                <Label>Contact Person Name</Label>
              </Col>
              <Col md={8}>
                <Input
                  type="text"
                  id="contactPersonName"
                  name="contactPersonName"
                  value={formData?.contactPersonName}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={4}>
                <Label>Contact Person Designation</Label>
              </Col>
              <Col md={8}>
                <Input
                  type="text"
                  id="contactPersonDesignation"
                  name="contactPersonDesignation"
                  value={formData?.contactPersonDesignation}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col md={4}>
                <Label>Contact Person Email</Label>
              </Col>
              <Col md={8}>
                <Input
                  type="text"
                  id="contactPersonEmail"
                  name="contactPersonEmail"
                  value={formData?.contactPersonEmail}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
          </FormGroup>{" "}
          <FormGroup>
            <Row>
              <Col md={4}>
                <Label>Website</Label>
              </Col>
              <Col md={8}>
                <Input
                  type="text"
                  id="website"
                  name="website"
                  value={formData?.website}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <div className="d-flex justify-content-end ">
            <Button className="mr-2" color="success" type="submit">
              {mode}
            </Button>
            <Button onClick={closeModal}>Close</Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
}
