import React from "react";

export const ApplicationStatus = (data) => {
  let badgeColor;
  let badgeLable;
  switch (data) {
    case "NOT_SENT":
      badgeLable = "Not sent";
      badgeColor = "teal";
      break;
    case "PENDING":
      badgeLable = "Pending";
      badgeColor = "#f4c414";
      break;
    case "EXPIRED":
      badgeLable = "Expired";
      badgeColor = "#f54394";
      break;
    case "PROCESSING":
      badgeLable = "Processing";
      badgeColor = "#8bc34a";
      break;
    case "COMPLETED":
      badgeLable = "Completed";
      badgeColor = "#31c971";
      break;
  }

  return { badgeColor: badgeColor, badgeLable: badgeLable };
};

