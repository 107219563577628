import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class IsPrivileged extends Component {
  state = {
    isPriviledged: false,
  };
  componentDidMount() {
    let privilege = this.props.privilege;
    let userPermissions = this.props.auth.item?.userRole?.permissions;

    if (userPermissions) {
      let pvl = userPermissions.some((ai) => {
        for (let i = 0; i < privilege.length; i++) {
          return privilege[i].includes(ai.name);
        }
      });
      // let pvl = userPermissions.some((ai) => {
      //   for (let i = 0; i < privilege.length; i++) {
      //     return privilege[i].match(ai.name);
      //   }
      // });

      this.setState({
        isPriviledged: pvl,
      });
    } else {
      this.setState({
        isPriviledged: false,
      });
    }
  }

  PropTypes = {
    router: PropTypes.object,
  };
  render() {
    return this.state.isPriviledged ? this.props.children : <></>;
  }
}

function mapStateToProps(state) {
  return { auth: state.auth };
}

export default connect(mapStateToProps)(IsPrivileged);
