import React, { useState, useEffect } from "react";

import {
  Collapse,
  Card,
  CardBody,
  FormGroup,
  Badge,
  Label,
  Input,
  Button,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import axios from "axios";
import Select from "react-select";
import { connect } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { forEach, isNull, filter, uniqBy } from "lodash";
import NoConnection from "../../errorPages/NoConnection";
import ToastCommon from "../../../components/toastCommon";
import loadingAnimation from "../../../../src/images/puff.svg";
import NoSearchResults from "../../errorPages/NoSearchResults";
import { useIsMounted } from "../../../customHooks/useIsMounted";
import { HOSTMexxar, Add_New_DOCUMENT } from "../../../configs/api-config";
import useDidMountEffect from "../../../components/Modal/ModalBody/intialRunUseEffect";
import RecivedDocsWidget from "../../../widgets/working-card-widget/recived-docs-widget";
import { FunnelIcon } from "../../../assets/icons/svg";

const filterByName = (name, dataSet) => {
  let filtered = dataSet;
  if (name !== "") {
    filtered = dataSet.filter((item) => {
      let fullName =
        item.candidateFirstName +
        item.candidateMiddleName +
        item.candidateLastName;
      let firstLastName = item.candidateFirstName + item.candidateLastName;

      let searchableString = `${fullName} ${firstLastName} ${item.candidateFirstName} ${item.candidateId} ${item.documentName} ${item.candidateLastName} ${item.email} ${item.primaryPhone}`;

      let p = searchableString;
      return p.toLowerCase().includes(name.toLowerCase());
    });
  }
  let lengthofSearch = filtered.length;
  return { filtered, lengthofSearch };
};

const RecivedDocs = (props) => {
  const { updateTargets, incomingPage, employeeID } = props;
  const isMounted = useIsMounted();
  const { addToast } = useToasts();

  const MexxarAPI = HOSTMexxar;
  const MexxarApiDocList = Add_New_DOCUMENT;

  const [rows, setRows] = useState([]);
  const [error, setError] = useState(null);
  const [reset, setReset] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialRows, setInitialRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [documentsList, setDocumentsList] = useState([]);
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [docStatus, setDocStatus] = useState("PENDING");
  const [docError, setDocError] = useState(false);

  const toggleFilter = () => {
    setIsOpenFilter(!isOpenFilter);
    getDocList();
  };

  const toggleShowEmailModal = () => {
    setShowEmailModal(!showEmailModal);
  };
  const toggleM = () => setShowEmailModal(!showEmailModal);

  const handleSearch = () => {
    //...filtered candidates according to the document filter......//

    if (selectedDocuments.length == 0) {
      setDocError(true);
    } else {
      setDocError(false);
    }
    let documentTemp = [];
    let A = filter(initialRows, (row) => {
      return row.documentId === selectedDocuments.id;
    });
    if (A.length !== 0) {
      documentTemp.push(...A);
    }

    let uniqdocumentTempArray = uniqBy(documentTemp, "candidateId");
    setRows(uniqdocumentTempArray);
  };

  const handleSearchOnChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getDocList = () => {
    axios
      .get(MexxarApiDocList)
      .then((res) => {
        let docListArray = [];

        forEach(res.data.body, function (item) {
          docListArray.push({
            value: item.name,
            label: item.name,
            id: item.id,
          });
        });

        if (isMounted.current) {
          setDocumentsList(docListArray);
        }
      })
      .catch((err) => {
        //console.log(err)
      });
  };

  const getAllRows = () => {
    axios
      .get(
        MexxarAPI +
          "workspace/documents/new-submitted?confirmStatus=" +
          docStatus +
          "&workflow=" +
          incomingPage
      )
      .then(async (res) => {
        if (isMounted.current) {
          setLoading(false);
          setRows(res.data.body);
          setInitialRows(res.data.body);
        }
      })
      .catch((err) => {
        //console.log(err)
        setError(err);
        //ToastCommon(true, "error", addToast);
      });
  };

  //....reset function......//
  const resetFilters = () => {
    setDocStatus("PENDING");

    setSelectedDocuments([]);
    setRows(initialRows);
    setReset(!reset);
  };

  useDidMountEffect(() => {
    //    handleSearch();
    return () => {
      setReset(!reset);
    };
  }, [reset]);

  useDidMountEffect(() => {
    getAllRows();
  }, [docStatus]);

  useEffect(() => {
    getAllRows();
    updateTargets();
    return () => {};
  }, []);

  useDidMountEffect(() => {
    setDocError(false);
    return () => {};
  }, [selectedDocuments]);

  const handleCheck = (e) => {
    setDocError(false);
    setSelectedDocuments([]);
    setDocStatus(e);
  };

  function sortFunction(a, b) {
    var dateA = new Date(a.uploadedDate).getTime();
    var dateB = new Date(b.uploadedDate).getTime();
    return dateA < dateB ? 1 : -1;
  }

  const colourStyles = {
    option: (provided, state) => ({
      ...provided,
      color: "#6A6767",
    }),
  };

  // useEffect(() => {
  //   getAllRows();
  //   getDocList();
  //   updateTargets();
  // }, [employeeID]);

  return (
    <>
      <div className="form-inline ">
        <Button onClick={toggleFilter} className="btn bg-dark-lt text-dark">
          <FunnelIcon />
          <span className="mx-1">Filter</span>
        </Button>
        <Input
          onChange={(e) => handleSearchOnChange(e)}
          type="text"
          id="searchBar"
          className="form-control no-border no-shadow no-bg typeahead tt-input"
          placeholder="Search Candidates..."
          autoComplete="off"
          spellCheck="false"
          dir="auto"
          style={{
            position: "relative",
            verticalAlign: "top",
            backgroundColor: "transparent",
            // textTransform: "lowercase",
          }}
        />

        <UncontrolledTooltip target="searchBar">
          Search candidates by Name/Id/Email/Phone No. and Document
        </UncontrolledTooltip>
      </div>
      <br></br>
      <Collapse isOpen={isOpenFilter}>
        <Card>
          <CardBody>
            <Row>
              <Col>
                <Label>Document</Label>
                <Select
                  value={selectedDocuments}
                  // isMulti
                  onChange={(data) => setSelectedDocuments(data)}
                  name="documentName"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  options={documentsList}
                  styles={colourStyles}
                />
                {docError && (
                  <span className="text-danger">
                    Please select a document to show results
                  </span>
                )}
              </Col>
              <Col></Col>
              <Col>
                <Label>Status</Label>
                {/* <FormGroup check>
                  <Label check>
                    <Input
                      value="ALL"
                      checked={filterCustom.value === "ALL" ? true : false}
                      onChange={(e) => handleCheck(e)}
                      type="checkbox"
                    />{" "}
                    <Badge color="success">all</Badge>
                  </Label>
                </FormGroup> */}
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="PENDING"
                      checked={docStatus === "PENDING"}
                      onChange={(e) => handleCheck(e.target.value)}
                    />
                    <Badge color="warning">pending</Badge>
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input
                      value="APPROVE"
                      checked={docStatus === "APPROVE"}
                      onChange={(e) => handleCheck(e.target.value)}
                      type="checkbox"
                    />{" "}
                    <Badge color="success">confirmed</Badge>
                  </Label>
                </FormGroup>
                {/*<FormGroup check>
                   <Label check>
                    <Input type="checkbox"  value="VERIFIED"
                      checked={verified.bool}
                      onChange={handleCheck}/>{" "}
                    <Badge
                     
                      color="success"
                    >
                      verified
                    </Badge>
                  </Label>
                </FormGroup> */}
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      value="DECLINE"
                      checked={docStatus === "DECLINE"}
                      onChange={(e) => handleCheck(e.target.value)}
                    />{" "}
                    <Badge color="danger">Declined</Badge>
                  </Label>
                </FormGroup>
              </Col>

              <Col>
                <div className="d-flex flex-column align-items-end">
                  <Button
                    color="success m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={handleSearch}
                  >
                    Search
                  </Button>
                  <Button
                    color="danger m-1 btn-sm"
                    style={{ width: "70px" }}
                    onClick={resetFilters}
                  >
                    Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Collapse>
      {error && (
        <div>
          <NoConnection error={error}></NoConnection>
        </div>
      )}

      {rows && !loading ? (
        filterByName(searchQuery, rows).lengthofSearch > 0 ? (
          filterByName(searchQuery, rows.sort(sortFunction)).filtered.map(
            (row, i) => {
              return (
                <RecivedDocsWidget
                  key={i}
                  userProfile={row}
                ></RecivedDocsWidget>
              );
            }
          )
        ) : (
          <NoSearchResults />
        )
      ) : null}

      {loading && !error && (
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <img src={loadingAnimation} style={{ height: 100 }}></img>
          </div>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    headhunter: state.headhunter,
  };
}

export default connect(mapStateToProps, {})(RecivedDocs);
